import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { eEnrollmentRoleType } from 'app/core/models';

@Component({
  selector: 'ng4h-modal-edit-custom-file',
  templateUrl: './modal-edit-custom-file.component.html',
  styleUrls: ['./modal-edit-custom-file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalEditCustomFileComponent implements OnInit {

  public form: FormGroup;
  public eEnrollmentRoleType = eEnrollmentRoleType;

  constructor(private dialogRef: MatDialogRef<any>, private fb: FormBuilder, @Inject(MAT_DIALOG_DATA) private data: any) { }

  public ngOnInit() {
    this.form = this.fb.group({
      fileControlName: [this.data.file.fileControlName, [Validators.required]],
      fileControlDescription: [this.data.file.fileControlDescription],
      managerOnly: [this.data.file.managerOnly, [Validators.required]],
      enrollmentRoles: this.fb.group({
        Volunteer: [this.data.file.enrollmentRoles.Volunteer],
        ClubMember: [this.data.file.enrollmentRoles.ClubMember],
        CloverBud: [this.data.file.enrollmentRoles.CloverBud],
      }),
      // acceptedFileExtensions: [this.data.file.acceptedFileExtensions.map(type => type.fileExtension)],
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  public update(): void {
    this.dialogRef.close(this.form.value);
  }

  public delete() {
    this.dialogRef.close({ delete: true });
  }
}
