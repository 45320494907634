import { createAction, props } from '@ngrx/store';
import { IAddressDto, IAddressVerificationResultDto } from 'app/core/models';

export const VerifyAddressAction = createAction(
    '[address-verification] Verify Address',
    props<{ institutionId: string, familyId: string, address: IAddressDto }>()
);

export const VerifyAddressSuccessAction = createAction(
    '[address-verification] Verify Address success',
    props<{ institutionId: string, familyId: string, res: IAddressVerificationResultDto }>()
);

export const VerifyAddressErrorAction = createAction(
    '[address-verification] Verify Address error',
    props<{ institutionId: string, familyId: string, address: IAddressDto, error?: any }>()
);
