<div class="modal-header">
    <h5 class="modal-title">Select Family Member</h5>
    <button (click)="close()" type="button" class="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<mat-horizontal-stepper #stepper [linear]="true">
    <mat-step [completed]="selectedFamilyMember != null">
        <ng-template matStepLabel>Family Member</ng-template>
        <div class="modal-body">
            <mat-list>
                <ng-container *ngFor="let memberEvent of familyEvent.members">
                    <mat-list-item
                        *ngIf="memberEvent?.registeredInEvent != true || memberEvent.allowMultipleRegistrations "
                        (click)="rowClicked(memberEvent)"
                        [ngClass]="{'selected': selectedFamilyMember?.memberId === memberEvent.memberId}">
                        <h3 matListItemLine>{{memberEvent.firstName}} {{memberEvent.lastName}}
                        </h3>
                        <!-- <h6 matListItemLine>{{memberEvent.memberRole}}</h6> -->
                        <!--<p matListItemLine class="text-muted"> Enrolled: {{event.dateOfEvent | date}}, Approved:{{event.dateApproved | date}}</p> -->

                    </mat-list-item>
                    <mat-divider></mat-divider>
                </ng-container>
            </mat-list>
        </div>


        <div class="modal-footer">
            <button class="btn btn-primary" [disabled]="selectedFamilyMember == null" matStepperNext>Select</button>
            <button class="btn btn-secondary" (click)="close()">Cancel</button>
        </div>

    </mat-step>

    <mat-step [completed]="selectedRegistrationType != null">
        <ng-template matStepLabel>Session/Type</ng-template>
        <div class="modal-body">

            <table mat-table [dataSource]="availableRegistrationTypes">

                <ng-container matColumnDef="registrationTypeName">
                    <th mat-header-cell *matHeaderCellDef>Name</th>
                    <td mat-cell *matCellDef="let element"> {{element.registrationTypeName}} </td>
                </ng-container>
                <ng-container matColumnDef="registrationRole">
                    <th mat-header-cell *matHeaderCellDef>Role</th>
                    <td mat-cell *matCellDef="let element"> {{element.registrationRole}} </td>
                </ng-container>

                <ng-container matColumnDef="registrationTypeStartDate">
                    <th mat-header-cell *matHeaderCellDef>Registration Start Date</th>
                    <td mat-cell *matCellDef="let element"> {{element.registrationStartDate | date}} </td>
                </ng-container>
                <ng-container matColumnDef="registrationTypeEndDate">
                    <th mat-header-cell *matHeaderCellDef>Registration End Date</th>
                    <td mat-cell *matCellDef="let element"> {{element.registrationEndDate | date}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="registrationTypeColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: registrationTypeColumns;"
                    [ngClass]="{'ng4h-selected': selectedRegistrationType&& selectedRegistrationType.registrationTypeId === row.registrationTypeId}"
                    (click)="registrationTypeSelected(row)">
                </tr>
            </table>


            <div class="modal-footer">
                <button class="btn btn-secondary float-right" matStepperPrevious>Back</button>
                <button class="btn btn-primary my-2 my-sm-0 float-right" [disabled]="selectedRegistrationType == null"
                    (click)="startRegistration()">Start
                    Registration</button>
            </div>
        </div>
    </mat-step>
</mat-horizontal-stepper>