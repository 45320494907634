import { eColumnGroup, eColumnValueType, eFilterCategory } from '..';
import { eFilterOperator } from '../enums';

export interface IReportColumnDto {
    columnId?: string;
    label?: string;
    category: eColumnGroup;
    columnValueType: eColumnValueType;
    filterCategory: eFilterCategory;
    availableFilterOperators?: eFilterOperator[];
    filterable: boolean;
}
