import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { AppState, getHealthForms } from 'app/app.reducers';
import {
  eFormControlType,
  eHealthFormType,
  EMAIL_REGEX,
  eStandardFieldStatus,
  IInstitutionMemberProgramParams,
  IMemberHealthFormDto,
  IMemberHealthFormSubmitDto,
  IMemberHealthMedicationSubmitDto,
  PHONE_REGEX
} from 'app/core/models';
import { MemberHealthFormsActions } from 'app/shared/member-health-form';
import { downloadFile } from 'app/shared/utils';
import { filter, map, Observable, of, take, tap } from 'rxjs';

import { CommonToastrService } from '.';
import { IStandardFormColumnDto } from '../models';
import { customRegexValidator, DateValidators } from '../validators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MemberHealthFormService {

  constructor(
    private store: Store<AppState>,
    private httpClient: HttpClient,
    private dispatcher: ActionsSubject,
    private toastrService: CommonToastrService
  ) {

  }

  public toFormGroup(fields: { [category: string]: IStandardFormColumnDto[] }) {
    const form = new FormGroup({});
    if (fields == null) {
      return form;
    }
    const keys = Object.keys(fields);
    keys.forEach(key => {
      const sectionForm = new FormGroup({});
      fields[key].forEach(input => {
        const inputValidators = [];
        if (input.inputState === eStandardFieldStatus.Required) {
          inputValidators.push(Validators.required);
        }
        if (input.inputType === eFormControlType.Email) {
          inputValidators.push(Validators.email);
          inputValidators.push(Validators.pattern(EMAIL_REGEX));
        }
        if (input.inputType === eFormControlType.Phone) {
          inputValidators.push(Validators.pattern(PHONE_REGEX));
        }

        if (input.inputType === eFormControlType.WholeNumber) {
          inputValidators.push(Validators.max(120));
        }
        if (input.inputType === eFormControlType.Date) {
          inputValidators.push(DateValidators.AppMin);
          inputValidators.push(DateValidators.AppMax);
        }
        if (input.validationRegex != null) {
          inputValidators.push(customRegexValidator(input.validationRegex, input.validationViolationMessage));
        }
        sectionForm.addControl(input.inputName, new FormControl(input.userResponse, inputValidators));
      });
      form.addControl(key, sectionForm);
    });
    return form;
  }

  public loadHealthFormsEffect({
    institutionId,
    memberId,
    programId,
    healthFormType
  }: IInstitutionMemberProgramParams & { healthFormType: eHealthFormType }): Observable<IMemberHealthFormDto> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/health-forms?type=${healthFormType}`) as Observable<IMemberHealthFormDto>;
  }

  // eHealthFormType.Standard always for enrollment, events have setting
  public getHealthForm(params: IInstitutionMemberProgramParams & { healthFormType: eHealthFormType }): Observable<IMemberHealthFormDto> {

    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getHealthForms(params)),
      tap(invoice => {
        if (invoice === undefined) {
          this.store.dispatch(MemberHealthFormsActions.HealthFormLoadAction(params));
        }
      }),
      filter(files => files != null)
    );
  }

  public saveHealthFormResponses(params: IInstitutionMemberProgramParams & { healthFormType: eHealthFormType, healthForm: IMemberHealthFormSubmitDto }) {
    this.store.dispatch(MemberHealthFormsActions.HealthFormSaveResponsesAction(params));

    return this.dispatcher.pipe(
      ofType(MemberHealthFormsActions.HealthFormSaveResponsesSuccessAction, MemberHealthFormsActions.HealthFormSaveResponsesErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberHealthFormsActions.HealthFormSaveResponsesSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public saveHealthFormResponsesEffect({
    institutionId,
    memberId,
    programId,
    healthFormType,
    healthForm
  }: IInstitutionMemberProgramParams & { healthFormType: eHealthFormType, healthForm: IMemberHealthFormSubmitDto }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/health-forms?type=${healthFormType}`, healthForm);
  }

  public addMemberMedication(params: IInstitutionMemberProgramParams & { medication: IMemberHealthMedicationSubmitDto }): void {
    this.store.dispatch(MemberHealthFormsActions.MemberHealthFormAddMedicationAction(params));
  }

  public addMemberMedicationEffect({
    institutionId,
    memberId,
    programId,
    medication
  }: IInstitutionMemberProgramParams & { medication: IMemberHealthMedicationSubmitDto }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/health-forms/medications`, medication);
  }

  public updateMemberMedication(params: IInstitutionMemberProgramParams & { profileHealthFormMedicationId: string, medication: IMemberHealthFormSubmitDto }) {
    this.store.dispatch(MemberHealthFormsActions.MemberHealthFormUpdateMedicationAction(params));
  }

  public updateMemberMedicationEffect({
    institutionId,
    memberId,
    programId,
    profileHealthFormMedicationId,
    medication
  }: IInstitutionMemberProgramParams & { profileHealthFormMedicationId: string, medication: IMemberHealthFormSubmitDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/health-forms/medications/${profileHealthFormMedicationId}`, medication);
  }

  public deleteMemberMedication(params: IInstitutionMemberProgramParams & { profileHealthFormMedicationId: string }) {
    this.store.dispatch(MemberHealthFormsActions.MemberHealthFormDeleteMedicationAction(params));
  }

  public deleteMemberMedicationEffect({
    institutionId,
    memberId,
    programId,
    profileHealthFormMedicationId
  }: IInstitutionMemberProgramParams & { profileHealthFormMedicationId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/health-forms/medications/${profileHealthFormMedicationId}`);
  }

  public downloadExcel({ institutionId, memberId, programId }: IInstitutionMemberProgramParams) {
    downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/health-forms/excel`, this.toastrService);
  }

  public downloadPdf({ institutionId, memberId, programId }: IInstitutionMemberProgramParams) {
    downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/health-forms/pdf`, this.toastrService);
  }
}
