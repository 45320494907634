<ng4h-card>
  <ng4h-card-content>
    <form [formGroup]="form">
      <div class="d-flex flex-column" formArrayName="items" *ngFor="let item of filtersArray.controls; let i = index">
        <div class="d-flex flex-row mb-4">
          <span class="align-self-center">
            Match ANY of the following filters.
          </span>

          <div class="ml-auto">
            <button type="button" class="btn btn-sm btn-outline-primary" (click)="addFilter()">Add Filter</button>
          </div>
        </div>
        <div class="form-group d-flex flex-row mb-4">
          <div [formGroupName]="i" class="d-flex flex-row">
            <div class="m-2">
              <select class="form-control" formControlName="column">
                <option [value]="column" *ngFor="let column of columns"> {{ column.Label }} </option>
              </select>
            </div>

            <div class="m-2">
              <select class="form-control" formControlName="filter">
                <option [value]="filter" *ngFor="let filter of filters"> {{ filter.Id }} </option>
              </select>
            </div>

            <div class="m-2">
              <input type="text" class="form-control">
            </div>

            <div class="ml-auto align-self-center">
              <button type="button" class="btn btn-danger btn-sm">Delete</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ng4h-card-content>
</ng4h-card>
