import { Pipe, PipeTransform } from '@angular/core';
import { eHierarchyNodeType } from '../models';

@Pipe({
    name: 'managerProgramHierarchyType',
    pure: true
})
export class ManagerProgramHierarchyPipe implements PipeTransform {
    public transform(value: { [programId: string]: eHierarchyNodeType }, programId: string): eHierarchyNodeType {
        if (programId == null) {
            return Object.values(value)[0];
        }
        return value[programId];
    }
}
