import { eInvoiceDiscountType } from '..';
import { IDiscountFundDonorDto } from './IDiscountFundDonorDto';

export interface IDiscountFundDonationDescriptorDto {
  discountFundDonationId: string;
  donor: IDiscountFundDonorDto;
  donationAmount: number;
  description?: string;
  restrictTo?: eInvoiceDiscountType;
}
