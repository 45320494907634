import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import {
  getMemberVolunteerHours,
  getMemberVolunteerHoursProjects
} from 'app/core/containers/member/hours/hours-feature.reducers';
import { MemberHoursActions } from 'app/core/containers/member/hours/store';
import { MemberHoursState } from 'app/core/containers/member/hours/store/hours.reducer';
import {
  IInstitutionMemberProgramEnrollmentParams,
  IVolunteerHoursCreateDto,
  IVolunteerHoursDto,
  IVolunteerHoursProjectsDto,
  IVolunteerHoursUpdateDto
} from 'app/core/models';
import { filter, map, Observable, of, take, tap } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MemberHoursService {

  constructor(
    private httpClient: HttpClient,
    private store: Store<MemberHoursState>,
    private dispatcher: ActionsSubject
  ) {
  }

  public getEnrollmentVolunteerHours(params: IInstitutionMemberProgramEnrollmentParams) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getMemberVolunteerHours(params)),
      tap(enrollment => {
        if (enrollment === undefined) {
          this.store.dispatch(MemberHoursActions.GetVolunteerHoursAction(params));
        }
      }),
      filter(enrollment => enrollment != null)
    );
  }

  public getEnrollmentVolunteerHoursEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId
  }: IInstitutionMemberProgramEnrollmentParams) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/volunteer-hours`) as Observable<IVolunteerHoursDto[]>;
  }

  public addEnrollmentVolunteerHours(params: IInstitutionMemberProgramEnrollmentParams & { volunteerHoursCreate: IVolunteerHoursCreateDto }) {
    this.store.dispatch(MemberHoursActions.AddVolunteerHoursAction(params));

    return this.dispatcher.pipe(
      ofType(MemberHoursActions.AddVolunteerHoursSuccessAction, MemberHoursActions.AddVolunteerHoursErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberHoursActions.AddVolunteerHoursSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public addEnrollmentVolunteerHoursEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    volunteerHoursCreate
  }: IInstitutionMemberProgramEnrollmentParams & { volunteerHoursCreate: IVolunteerHoursCreateDto }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/volunteer-hours`, volunteerHoursCreate);
  }

  public updateEnrollmentVolunteerHours(params: IInstitutionMemberProgramEnrollmentParams & { enrollmentVolunteerHoursId: string, update: IVolunteerHoursUpdateDto }) {
    this.store.dispatch(MemberHoursActions.UpdateVolunteerHoursAction(params));

    return this.dispatcher.pipe(
      ofType(MemberHoursActions.UpdateVolunteerHoursSuccessAction, MemberHoursActions.UpdateVolunteerHoursErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberHoursActions.UpdateVolunteerHoursSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );

  }

  public updateEnrollmentVolunteerHoursEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    enrollmentVolunteerHoursId,
    update
  }: IInstitutionMemberProgramEnrollmentParams & { enrollmentVolunteerHoursId: string, update: IVolunteerHoursUpdateDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/volunteer-hours/${enrollmentVolunteerHoursId}`, update);
  }

  public deleteEnrollmentVolunteerHours(params: IInstitutionMemberProgramEnrollmentParams & { enrollmentVolunteerHoursId: string }) {
    this.store.dispatch(MemberHoursActions.DeleteVolunteerHoursAction(params));

    return this.dispatcher.pipe(
      ofType(MemberHoursActions.DeleteVolunteerHoursSuccessAction, MemberHoursActions.DeleteVolunteerHoursErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberHoursActions.DeleteVolunteerHoursSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteEnrollmentVolunteerHoursEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    enrollmentVolunteerHoursId
  }: IInstitutionMemberProgramEnrollmentParams & { enrollmentVolunteerHoursId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/volunteer-hours/${enrollmentVolunteerHoursId}`);
  }

  public getHoursProjects(params: IInstitutionMemberProgramEnrollmentParams): Observable<IVolunteerHoursProjectsDto> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getMemberVolunteerHoursProjects(params)),
      tap(enrollment => {
        if (enrollment === undefined) {
          this.store.dispatch(MemberHoursActions.GetVolunteerHoursProjectsAction(params));
        }
      }),
      filter(enrollment => enrollment != null)
    );
  }

  public getHoursProjectsEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId
  }: IInstitutionMemberProgramEnrollmentParams) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/volunteer-hours-projects`) as Observable<IVolunteerHoursProjectsDto>;
  }

}
