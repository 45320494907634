import { HttpErrorResponse as MemberUnitActions } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  IEnrollmentUnitDto,
  IInstitutionMemberProgramEnrollmentParams,
  IInstitutionMemberProgramParams,
  IMemberSearchResultDto,
} from 'app/core/models';


export const UnitsLoadAction = createAction(
  '[units] Load',
  props<IInstitutionMemberProgramEnrollmentParams & { countyAreaId: string }>()
);
export const UnitsLoadSuccessAction = createAction(
  '[units] Load success',
  props<IInstitutionMemberProgramEnrollmentParams & { countyAreaId: string, units: IEnrollmentUnitDto[] }>()
);
export const UnitsLoadErrorAction = createAction(
  '[units] Load error',
  props<{ error: MemberUnitActions }>()
);


export const UnitLeaderLoadMembersAction = createAction(
  '[units] Unit leader members',
  props<IInstitutionMemberProgramParams & { unitId: string }>()
);
export const UnitLeaderLoadMembersSuccessAction = createAction(
  '[units] Unit leader members success',
  props<IInstitutionMemberProgramParams & { unitId: string, members: IMemberSearchResultDto[] }>()
);
export const UnitLeaderLoadMembersErrorAction = createAction(
  '[units] Unit leader members error',
  props<{ error: MemberUnitActions }>()
);
