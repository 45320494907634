import { IMemberEventRegistrationSummaryDto } from '.';
import { eMemberStatus } from '..';
import { IMemberProgramSummaryDto } from './IMemberProgramSummaryDto';

export interface IMemberDashboardSummaryDto {
    memberId: string;
    status: eMemberStatus;
    birthDate: string;
    firstName?: string;
    lastName?: string;
    memberPrograms?: { [programId: string]: IMemberProgramSummaryDto };
    eventRegistrations?: IMemberEventRegistrationSummaryDto[];
}
