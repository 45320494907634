import { eAccessControlListRoleType, ePermissionState, IManagerPermissions } from 'app/core/models';
import { IHierarchyACLDto } from 'app/core/models/serverDTOs/IHierarchyACLDto';

// lowest number wins
function rankAccessControlType(accessControlType: eAccessControlListRoleType): number {
    switch (accessControlType) {
        case eAccessControlListRoleType.Superuser: {
            return 0;
        }
        case eAccessControlListRoleType.SystemManager: {
            return 1;
        }
        case eAccessControlListRoleType.HierarchyAdmin: {
            return 2;
        }
        case eAccessControlListRoleType.HierarchyStaff: {
            return 3;
        }
        case eAccessControlListRoleType.HierarchyVolunteer: {
            return 4;
        }
    }
    return null;
}

// Lowest wins
export function rankPermissionState(permissionState: ePermissionState): number {
    switch (permissionState) {
        case ePermissionState.Write: {
            return 0;
        }
        case ePermissionState.Read: {
            return 1;
        }
        case ePermissionState.None: {
            return 2;
        }
    }
    return null;
}

function mergePermissions(gp1: IManagerPermissions, gp2: IManagerPermissions): IManagerPermissions {
    const mergedPermissions = {};
    Object.keys(gp1).forEach(key => {
        mergedPermissions[key] = rankPermissionState(gp1[key]) < rankPermissionState(gp2[key]) ? gp1[key] : gp2[key];
    });
    return mergedPermissions as IManagerPermissions;
}

// Highest access wins
export function mergeAcl(acl1: IHierarchyACLDto, acl2: IHierarchyACLDto): IHierarchyACLDto {
    if (acl1 == null || acl2 == null) {
        if (acl1 == null && acl2 == null) {
            return null;
        } else if (acl1 == null) {
            return acl2;
        } else if (acl2 == null) {
            return acl1;
        }
    }
    const mergedPermissionRole = rankAccessControlType(acl1.permissionRole) < rankAccessControlType(acl2.permissionRole) ? acl1.permissionRole : acl2.permissionRole;
    const mergedAcl: IHierarchyACLDto = {
        permissionRole: mergedPermissionRole,
        grantedPermissions: mergedPermissionRole === eAccessControlListRoleType.HierarchyStaff
            || mergedPermissionRole === eAccessControlListRoleType.HierarchyVolunteer ? mergePermissions(acl1.grantedPermissions, acl2.grantedPermissions) : null
    };
    return mergedAcl;
}
