import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AutoUnsubscribe } from 'app/core/models';
import { eLogLevel, LoggingService, UserService } from 'app/core/services';
import { filter, map, Observable, switchMap } from 'rxjs';

@Component({
  selector: 'ng4h-modal-http-error',
  templateUrl: './modal-http-error.component.html',
  styleUrls: ['./modal-http-error.component.scss']
})
export class ModalHttpErrorComponent extends AutoUnsubscribe implements OnInit {

  public superUserId$: Observable<string>;
  public backendErrors$: Observable<any[]>;

  public backendError$: Observable<any>;

  public modalData: { error: HttpErrorResponse, req: HttpRequest<any> };

  constructor(private dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) _modalData: { error: HttpErrorResponse, req: HttpRequest<any> }, private userService: UserService,
    private loggingService: LoggingService) {
    super();
    this.modalData = _modalData;
  }

  ngOnInit() {
    this.superUserId$ = this.userService.userAccount$.pipe(
      filter(ua => ua != null),
      map(userAccount => userAccount.superuserId),
      filter(superuserId => superuserId != null)
    );
    this.backendErrors$ = this.superUserId$.pipe(switchMap(superUserId => this.loggingService.getBackEndErrors(superUserId, eLogLevel.Error)));

    this.backendError$ = this.backendErrors$.pipe(
      map(backendErrors => {
        if (this.modalData.error.url.includes(backendErrors[0].referringPage)) {
          return backendErrors[0];
        }
        return null;
      })
    );
  }

  public close() {
    this.dialogRef.close();
  }

}
