import { Component } from '@angular/core';

import { ModalService } from '../../services';
import { ModalAntiDiscriminationComponent } from '../modal-anti-discrimination/modal-anti-discrimination.component';

@Component({
    selector: 'ng4h-member-footer',
    templateUrl: './member-footer.component.html',
    styleUrls: ['./member-footer.component.scss']
})
export class MemberFooterComponent {

    constructor(private modalService: ModalService) {
    }

    public view() {
        this.modalService.openModal(ModalAntiDiscriminationComponent);
    }
}
