import { eGroupStatus } from '..';

export interface IGroupCreateDto {
    status: eGroupStatus;
    label?: string;
    description?: string;
    hierarchyNodeId: string;
    deliveryModeId: string;
    allowMembersToRegister?: boolean;
}
