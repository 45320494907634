<div class="modal-header">
    <h5 class="modal-title">Add Bank Account</h5>
    <button (click)="close()" type="button" class="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="form" class="row" *ngIf="form != null">
        <div class="form-group col-sm-12 row">
            <div class="form-control-label col-4">Bank Name</div>
            <input formControlName="bankAccountName" type="text" class="form-control col-8">
        </div>
        <div class="form-group col-sm-12 row">
            <div class="form-control-label col-4">Bank Description</div>
            <input formControlName="description" type="text" class="form-control col-8">
        </div>
        <div class="form-group col-sm-12 row">
            <div class="form-control-label col-4">Default</div>
            <!-- <input formControlName="defaultBankAccount" type="text" class="form-control col-8"> -->
            <mat-checkbox formControlName="defaultBankAccount" class="col-8"></mat-checkbox>
        </div>
        <div class="form-group col-sm-12 row">
            <div class="form-control-label col-4">Active</div>
            <!-- <input formControlName="active" type="text" class="form-control col-8"> -->
            <mat-checkbox formControlName="active" class="col-8"></mat-checkbox>
        </div>


    </form>
</div>
<div class="modal-footer">
    <button [disabled]="form == null || form.invalid" (click)="save()" class="btn btn-primary my-2 my-sm-0"
        role="button" aria-pressed="true">Save</button>
</div>
