import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  eEnrollmentRoleType,
  IInstitutionSystemManagerProgramParams,
  IProjectCreateDto,
  IProjectDto,
} from 'app/core/models';
import { IProjectUpdateDto } from 'app/core/models/serverDTOs/IProjectUpdateDto';


export const SystemManagerCreateProjectAction = createAction(
  '[system manager projects] create project',
  props<IInstitutionSystemManagerProgramParams & { project: IProjectCreateDto }>()
);

export const SystemManagerCreateProjectSuccessAction = createAction(
  '[system manager projects] create project success',
  props<IInstitutionSystemManagerProgramParams & { project: IProjectCreateDto, newProject: IProjectDto }>()
);

export const SystemManagerCreateProjectErrorAction = createAction(
  '[system manager projects] create project error',
  props<{ error: HttpErrorResponse }>()
);

export const SystemManagerGetProjectsAction = createAction(
  '[system manager projects] get projects',
  props<{ institutionId: string, systemManagerId: string, programId: string }>()
);

export const SystemManagerGetProjectsSuccessAction = createAction(
  '[system manager projects] get projects success',
  props<IInstitutionSystemManagerProgramParams & { projects: IProjectDto[] }>()
);

export const SystemManagerGetProjectsErrorAction = createAction(
  '[system manager projects] get projects error',
  props<{ error: HttpErrorResponse }>()
);

export const SystemManagerUpdateProjectAction = createAction(
  '[system manager projects] update project',
  props<IInstitutionSystemManagerProgramParams & { projectId: string, project: IProjectUpdateDto }>()
);

export const SystemManagerUpdateProjectSuccessAction = createAction(
  '[system manager projects] update project success',
  props<IInstitutionSystemManagerProgramParams & { projectId: string, project: IProjectUpdateDto }>()
);

export const SystemManagerUpdateProjectErrorAction = createAction(
  '[system manager projects] update project error',
  props<{ error: HttpErrorResponse }>()
);

export const SystemManagerAddProjectConsentAction = createAction(
  '[system manager projects] add project consent',
  props<IInstitutionSystemManagerProgramParams & { projectId: string, consentTypeId: string, enrollmentRole: eEnrollmentRoleType }>()
);

export const SystemManagerAddProjectConsentSuccessAction = createAction(
  '[system manager projects] add project consent success',
  props<IInstitutionSystemManagerProgramParams & { projectId: string, consentTypeId: string }>()
);

export const SystemManagerAddProjectConsentErrorAction = createAction(
  '[system manager projects] add project consent error',
  props<{ error: HttpErrorResponse }>()
);

export const SystemManagerDeleteProjectConsentAction = createAction(
  '[system manager projects] delete project consent',
  props<IInstitutionSystemManagerProgramParams & { projectId: string, consentTypeId: string, enrollmentRole: eEnrollmentRoleType }>()
);

export const SystemManagerDeleteProjectConsentSuccessAction = createAction(
  '[system manager projects] delete project consent success',
  props<IInstitutionSystemManagerProgramParams & { projectId: string, consentTypeId: string }>()
);

export const SystemManagerDeleteProjectConsentErrorAction = createAction(
  '[system manager projects] delete project consent error',
  props<{ error: HttpErrorResponse }>()
);

export const SystemManagerGetProjectAction = createAction(
  '[system manager projects] get project',
  props<IInstitutionSystemManagerProgramParams & { projectId: string }>()
);

export const SystemManagerGetProjectSuccessAction = createAction(
  '[system manager projects] get project success',
  props<IInstitutionSystemManagerProgramParams & { projectId: string, project: IProjectDto }>()
);

export const SystemManagerGetProjectErrorAction = createAction(
  '[system manager projects] get project error',
  props<{ error: HttpErrorResponse }>()
);


export const SystemManagerDeleteInstitutionProjectAction = createAction(
  '[system manager projects] delete institution project',
  props<IInstitutionSystemManagerProgramParams & { projectId: string }>()
);

export const SystemManagerDeleteInstitutionProjectSuccessAction = createAction(
  '[system manager projects] delete institution project success',
  props<IInstitutionSystemManagerProgramParams & { projectId: string }>()
);

export const SystemManagerDeleteInstitutionProjectErrorAction = createAction(
  '[system manager projects] delete institution project error',
  props<{ error: HttpErrorResponse }>()
);

