import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ICountyDescriptorDto, IInstitution } from 'app/core/models';

export const InstitutionsLoadAction = createAction(
  '[Institutions] load'
);
export const InstitutionsLoadSuccessAction = createAction(
  '[Institutions] load success',
  props<{ institutions: IInstitution[] }>()
);
export const InstitutionsLoadErrorAction = createAction(
  '[Institutions] load error',
  props<{ error: HttpErrorResponse }>()
);


export const InstitutionLoadCountiesAction = createAction(
  '[institution] load counties',
  props<{ institutionId: string }>()
);
export const InstitutionLoadCountiesSuccessAction = createAction(
  '[institution] load counties success',
  props<{ institutionId: string, counties: ICountyDescriptorDto[] }>()
);
export const InstitutionLoadCountiesErrorAction = createAction(
  '[institution] load counties error',
  props<{ error: HttpErrorResponse }>()
);


export const InstitutionLoadAllCountiesAction = createAction(
  '[institution] load all counties',
  props<{ institutionId: string }>()
);
export const InstitutionLoadAllCountiesSuccessAction = createAction(
  '[institution] load all counties success',
  props<{ institutionId: string, allCounties: ICountyDescriptorDto[] }>()
);
export const InstitutionLoadAllCountiesErrorAction = createAction(
  '[institution] load all counties error',
  props<{ error: HttpErrorResponse }>()
);

export const InstitutionGetProgramsAction = createAction(
  '[institution] get programs',
  props<{ institutionId: string }>()
);

export const InstitutionGetProgramsSuccessAction = createAction(
  '[institution] get programs success',
  props<{ institutionId: string, programs: any }>()
);

export const InstitutionGetProgramsErrorAction = createAction(
  '[institution] get programs error',
  props<{ error: string }>()
);
