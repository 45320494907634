<div class="modal-header">
  <h5 class="modal-title">Add State Project</h5>
  <button (click)="close()" type="button" class="close" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form" class="row" [ngClass]="wasValidated ? 'was-validated' : 'needs-validation'" novalidate>
    <div class="form-group col-sm-12 row">
      <label class="form-control-label col-4">State Project</label>
      <input formControlName="projectName" type="text" class="form-control col-8">
    </div>
    <div class="form-group col-sm-12 row">
      <label class="form-control-label col-4">Project Description</label>
      <input formControlName="projectDescription" type="text" class="form-control col-8">
    </div>
    <div class="form-group col-sm-12 row">
      <label class="form-control-label col-4">Project URL</label>
      <input formControlName="url" type="text" class="form-control col-8">
    </div>
  </form>
</div>
<div class="modal-footer">
  <button (click)="add()" class="btn btn-primary my-2 my-sm-0" role="button" aria-pressed="true">Add</button>
</div>