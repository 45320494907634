import { Action, createReducer, on } from '@ngrx/store';
import { IEnrollmentDto, IEnrollmentFileControlDto, IInstitutionVolunteerDto } from 'app/core/models';
import { IInstitutionMemberProgramEnrollmentModel } from 'app/core/models/function-parameters';
import { mergeImmutable } from 'app/shared/utils';

import { EnrollmentActions } from '.';

export interface EnrollmentState {
  enrollment: IInstitutionMemberProgramEnrollmentModel<IEnrollmentDto>;
  // payableInvoice: IInstitutionMemberProgramEnrollmentModel<IPayableInvoiceDto>;
  dynamicQuestionFormInputs: IInstitutionMemberProgramEnrollmentModel<any>;
  customFiles: IInstitutionMemberProgramEnrollmentModel<IEnrollmentFileControlDto[]>;
  volunteerTypes: IInstitutionMemberProgramEnrollmentModel<IInstitutionVolunteerDto[]>;
}


const initialState: EnrollmentState = {
  enrollment: undefined,
  // payableInvoice: undefined,
  dynamicQuestionFormInputs: undefined,
  customFiles: undefined,
  volunteerTypes: undefined
};

const reducer = createReducer(
  initialState,
  on(EnrollmentActions.EnrollmentLoadSuccessAction,
    EnrollmentActions.EnrollmentCreateSuccessAction,
    (state, { institutionId, memberId, programId, enrollment }) => {
      const demographics = {
        ...enrollment.demographics,
        schoolGrade: enrollment.schoolGrade,
        schoolName: enrollment.schoolName,
        schoolType: enrollment.schoolType,
        militaryService: enrollment.militaryService,
        yearsInProgram: enrollment.yearsInProgram,
      };
      const newEnrollment = mergeImmutable({
        [institutionId]: {
          [memberId]: {
            [programId]: {
              [enrollment.enrollmentId]: {
                ...enrollment,
                demographics: demographics
              }
            }
          }
        }
      },
        state.enrollment);

      return {
        ...state,
        enrollment: newEnrollment,
      };
    }),
  // on(EnrollmentActions.EnrollmentLoadPayableInvoiceSuccessAction, (state, { institutionId, memberId, programId, enrollmentId, invoice }) => {
  //   const newPayableInvoice = mergeImmutable(
  //     { [institutionId]: { [memberId]: { [programId]: { [enrollmentId]: invoice } } } },
  //     state.payableInvoice
  //   );
  //   return {
  //     ...state,
  //     payableInvoice: newPayableInvoice
  //   };
  // }),
  // on(EnrollmentActions.EnrollmentPayableInvoiceInvalidateCacheAction, (state, payload) => {
  //   return {
  //     ...state,
  //     payableInvoice: undefined
  //   };
  // }),
  on(EnrollmentActions.EnrollmentAddVolunteerTypesAction, (state, { institutionId, memberId, programId, enrollmentId, volunteerType }) => {
    let volunteerTypes = state.enrollment[institutionId][memberId][programId][enrollmentId].volunteerTypes;
    if (volunteerTypes == null) {
      volunteerTypes = [];
    }
    const existingIndex = volunteerTypes.findIndex(vt => vt.volunteerTypeId === volunteerType.volunteerTypeId);
    if (existingIndex > -1) {
      return state;
    }
    const newVolunteerTypes: Partial<IEnrollmentDto> = { volunteerTypes: [...volunteerTypes, volunteerType] };
    const newEnrollment = mergeImmutable(
      { [institutionId]: { [memberId]: { [programId]: { [enrollmentId]: newVolunteerTypes } } } },
      state.enrollment
    );
    return {
      ...state,
      enrollment: newEnrollment
    };
  }),
  on(EnrollmentActions.EnrollmentRemoveVolunteerTypesAction, (state, { institutionId, memberId, programId, enrollmentId, volunteerType }) => {
    let volunteerTypes = state.enrollment[institutionId][memberId][programId][enrollmentId].volunteerTypes;
    if (volunteerTypes == null) {
      volunteerTypes = [];
    }
    const indexToRemove = volunteerTypes.findIndex(vt => vt.volunteerTypeId === volunteerType.volunteerTypeId);
    if (indexToRemove === -1) {
      return state;
    }

    const splicedVolunteerTypes = [...volunteerTypes];
    splicedVolunteerTypes.splice(indexToRemove, 1);
    const newVolunteerTypes: Partial<IEnrollmentDto> = {
      volunteerTypes: splicedVolunteerTypes,
    };
    const newEnrollment = mergeImmutable(
      { [institutionId]: { [memberId]: { [programId]: { [enrollmentId]: newVolunteerTypes } } } },
      state.enrollment
    );

    return {
      ...state,
      enrollment: newEnrollment
    };
  }),
  on(EnrollmentActions.EnrollmentAddClubAction, (state, { institutionId, memberId, programId, enrollmentId, club }) => {
    let clubs = state.enrollment[institutionId][memberId][programId][enrollmentId].units;
    if (clubs == null) {
      clubs = [];
    }
    const existingIndex = clubs.findIndex(c => {
      return c.unitId === club.unitId;
    });
    if (existingIndex > -1) {
      return state;
    }
    let newClub = club;
    let newSelectedClubs = [...clubs];
    if (newClub.primary) {
      newSelectedClubs = newSelectedClubs.map(club => {
        return {
          ...club,
          primary: false
        };
      });
    } else if (clubs.length === 0) {
      newClub = {
        ...newClub,
        primary: true
      };
    }
    const newClubs: Partial<IEnrollmentDto> = { units: newSelectedClubs.concat([newClub]) };
    const newEnrollment = mergeImmutable(
      { [institutionId]: { [memberId]: { [programId]: { [enrollmentId]: newClubs } } } },
      state.enrollment
    );
    return {
      ...state,
      enrollment: newEnrollment
    };
  }),
  on(EnrollmentActions.EnrollmentRemoveClubAction, (state, { institutionId, memberId, programId, enrollmentId, unitId }) => {
    const clubs = state.enrollment[institutionId][memberId][programId][enrollmentId].units;
    const indexToRemove = clubs.findIndex((club => {
      return club.unitId === unitId;
    }));
    const newSelectedClubs = [...clubs];
    newSelectedClubs.splice(indexToRemove, 1);

    if (newSelectedClubs.length > 0 && !newSelectedClubs.some(club => club.primary)) {
      newSelectedClubs[0] = {
        ...newSelectedClubs[0],
        primary: true
      };
    }
    const newClubs: Partial<IEnrollmentDto> = { units: newSelectedClubs };
    const newEnrollment = mergeImmutable(
      { [institutionId]: { [memberId]: { [programId]: { [enrollmentId]: newClubs } } } },
      state.enrollment
    );

    return {
      ...state,
      enrollment: newEnrollment
    };
  }),
  on(EnrollmentActions.EnrollmentChangePrimaryClubAction, (state, { institutionId, memberId, programId, enrollmentId, club }) => {
    const clubs = state.enrollment[institutionId][memberId][programId][enrollmentId].units;
    const newSelectedClubs = clubs.map(mappedClub => {
      if (mappedClub.unitId === club.unitId) {
        return {
          ...mappedClub,
          primary: true
        };
      }
      return {
        ...mappedClub,
        primary: false
      };

    });
    const newClubs: Partial<IEnrollmentDto> = { units: newSelectedClubs };
    const newEnrollment = mergeImmutable(
      { [institutionId]: { [memberId]: { [programId]: { [enrollmentId]: newClubs } } } },
      state.enrollment
    );
    return {
      ...state,
      enrollment: newEnrollment,
    };
  }),
  // on(EnrollmentActions.EnrollmentAddProjectAction, (state, { institutionId, memberId, programId, enrollmentId, enrollmentProject }) => {
  //   const projects = state.enrollment[institutionId][memberId][programId][enrollmentId].projects;
  //   const existingProjectIndex = projects.findIndex(project => project.projectId === enrollmentProject.projectId);
  //   if (existingProjectIndex !== -1) {
  //     return state;
  //   }
  //   const newProjects: Partial<IEnrollmentDto> = { projects: projects.concat([enrollmentProject]) };
  //   const newEnrollment = mergeImmutable(
  //     { [institutionId]: { [memberId]: { [programId]: { [enrollmentId]: newProjects } } } },
  //     state.enrollment
  //   );

  //   return {
  //     ...state,
  //     enrollment: newEnrollment,
  //   };
  // }),
  on(EnrollmentActions.EnrollmentDeleteProjectSuccessAction, (state, { institutionId, memberId, programId, enrollmentId, projectId }) => {
    const projects = state.enrollment[institutionId][memberId][programId][enrollmentId].projects;
    const existingProjectIndex = projects.findIndex(project => project.projectId === projectId);
    if (existingProjectIndex === -1) {
      return state;
    }
    const newProjects: Partial<IEnrollmentDto> = { projects: [...projects] };
    newProjects.projects.splice(existingProjectIndex, 1);
    const newEnrollment = mergeImmutable(
      { [institutionId]: { [memberId]: { [programId]: { [enrollmentId]: newProjects } } } },
      state.enrollment
    );

    return {
      ...state,
      enrollment: newEnrollment
    };
  }),
  on(EnrollmentActions.EnrollmentLoadDynamicFormSuccessAction, (state, { institutionId, memberId, programId, enrollmentId, dynamicForm }) => {
    const newDynamicQuestionFormInputs = mergeImmutable(
      { [institutionId]: { [memberId]: { [programId]: { [enrollmentId]: dynamicForm } } } },
      state.dynamicQuestionFormInputs
    );
    return {
      ...state,
      dynamicQuestionFormInputs: newDynamicQuestionFormInputs
    };
  }),
  on(EnrollmentActions.EnrollmentInvalidateCacheAction, (state, { }) => {
    return {
      ...state,
      enrollment: undefined
    };
  }),
  on(EnrollmentActions.EnrollmentInvalidateDynamicFormsCacheAction, (state, { }) => {
    return {
      ...state,
      dynamicQuestionFormInputs: undefined
    };
  }),
  on(EnrollmentActions.EnrollmentLoadCustomFileSuccessAction, (state, { institutionId, memberId, programId, enrollmentId, customFiles }) => {
    return {
      ...state,
      customFiles: mergeImmutable({
        [institutionId]: { [memberId]: { [programId]: { [enrollmentId]: customFiles } } }
      }, state.customFiles)
    };
  }),
  on(EnrollmentActions.EnrollmentLoadAvailableVolunteerTypesSuccessAction, (state, { institutionId, memberId, programId, enrollmentId, volunteerTypes }) => {
    return {
      ...state,
      volunteerTypes: mergeImmutable({
        [institutionId]: { [memberId]: { [programId]: { [enrollmentId]: volunteerTypes } } }
      }, state.volunteerTypes)
    };
  }),
  on(EnrollmentActions.EnrollmentInvalidateCustomFilesCache, (state, { }) => {
    return {
      ...state,
      customFiles: undefined
    };
  })
);

export function enrollmentReducer(state: EnrollmentState | undefined, actions: Action) {
  return reducer(state, actions);
}

export const enrollment = (state: EnrollmentState) => state.enrollment;
export const dynamicQuestionFormInputs = (state: EnrollmentState) => state.dynamicQuestionFormInputs;
// export const payableInvoice = (state: EnrollmentState) => state.payableInvoice;

export const enrollmentCustomFiles = (state: EnrollmentState) => state.customFiles;
export const enrollmentVolunteerTypes = (state: EnrollmentState) => state.volunteerTypes;
