import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { AppState, getAppSettings } from 'app/app.reducers';
import { ProgramSettingsActions } from 'app/shared/program-settings';
import { filter, map, Observable, of, take, tap } from 'rxjs';

import {
  ePageMessage,
  IGatewayConfigurationDto,
  IInstitutionProgramParams,
  IInstitutionSystemManagerProgramParams,
  IProgramPageMessageDto,
  IProgramSettingsDto,
  IProgramSettingsSuperUserUpdateDto,
  ISuperuserInstitutionProgramParams
} from '../models';
import { IInstitutionSuperuserProgramParams } from '../models/function-parameters/institution-superuser-program';
import { IProgramSettingsUpdateDto } from '../models/serverDTOs/IProgramSettingsUpdateDto';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProgramSettingsService {

  constructor(private store: Store<AppState>, private httpClient: HttpClient, private dispatcher: ActionsSubject) {

  }

  public getProgramLogoSrc({ institutionId, programId }: IInstitutionProgramParams): string {
    return `${environment.apiUri}/api/institutions/${institutionId}/programs/${programId}/logo`;
  }

  public getProgramNullableSettings(params: IInstitutionProgramParams): Observable<IProgramSettingsDto> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getAppSettings(params)),
      tap(invoice => {
        if (invoice === undefined) {
          this.store.dispatch(ProgramSettingsActions.ProgramSettingsLoadAction(params));
        }
      })
    );
  }

  public getProgramSettings(params: IInstitutionProgramParams): Observable<IProgramSettingsDto> {
    return this.getProgramNullableSettings(params).pipe(
      filter(settings => settings != null)
    );
  }

  public loadProgramSettingsEffect({ institutionId, programId }: IInstitutionProgramParams) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/programs/${programId}/settings`);
  }

  public updateSystemManagerProgramSettings(params: IInstitutionSystemManagerProgramParams & { updatedProgramSettings: IProgramSettingsUpdateDto }): Observable<IProgramSettingsUpdateDto> {
    this.store.dispatch(ProgramSettingsActions.ProgramSettingsSystemManagerUpdateAction(params));
    return this.dispatcher.pipe(
      ofType(ProgramSettingsActions.ProgramSettingsSystemManagerUpdateSuccessAction, ProgramSettingsActions.ProgramSettingsSystemManagerUpdateErrorAction),
      take(1),
      map(action => {
        if (action.type === ProgramSettingsActions.ProgramSettingsSystemManagerUpdateSuccessAction.type) {
          return action.updatedProgramSettings;
        } else {
          throw action.error;
        }
      })
    );
  }

  public saveSystemManagerProgramSettingsEffect({
    institutionId,
    systemManagerId,
    programId,
    updatedProgramSettings
  }: IInstitutionSystemManagerProgramParams & { updatedProgramSettings: IProgramSettingsUpdateDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/settings`, updatedProgramSettings);
  }

  public updateSuperuserProgramSettings(params: IInstitutionSuperuserProgramParams & { updatedProgramSettings: IProgramSettingsSuperUserUpdateDto }) {

    this.store.dispatch(ProgramSettingsActions.ProgramSettingsSuperuserUpdateAction(params));
    return this.dispatcher.pipe(
      ofType(ProgramSettingsActions.ProgramSettingsSuperuserUpdateSuccessAction, ProgramSettingsActions.ProgramSettingsSuperuserUpdateErrorAction),
      take(1),
      map(action => {
        if (action.type === ProgramSettingsActions.ProgramSettingsSuperuserUpdateSuccessAction.type) {
          return action.updatedProgramSettings;
        } else {
          throw action.error;
        }
      })
    );
  }

  public saveSuperuserProgramSettingsEffect({
    superuserId,
    institutionId,
    programId,
    updatedProgramSettings
  }: ISuperuserInstitutionProgramParams & { updatedProgramSettings: IProgramSettingsSuperUserUpdateDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/super-users/${superuserId}/institutions/${institutionId}/programs/${programId}/settings`, updatedProgramSettings);
  }

  public getPageMessageByType({
    institutionId,
    programId,
    typeId
  }: IInstitutionProgramParams & { typeId: ePageMessage }): Observable<IProgramPageMessageDto> {
    return this.getProgramSettings({ institutionId, programId }).pipe
    (map(settings => {
        return settings.pageMessages[typeId];
      })
    );
  }

  public getPaymentGatewayConfiguration(params: IInstitutionProgramParams): Observable<IGatewayConfigurationDto> {
    return this.getProgramSettings(params).pipe
    (map(settings => {
        return settings.paymentGateway;
      })
    );
  }

  public canBeYouthOrAdult(birthDate: string, programSettings: IProgramSettingsDto, isManager: boolean): { canBeYouth: boolean, canBeAdult: boolean } {
    const dob = new Date(birthDate);
    const youthBirthDateLowerLimitKey = isManager ? 'youthManagerBirthDateLowerLimit' : 'youthBirthDateLowerLimit';
    const canBeAdult = dob <= new Date(programSettings.adultBirthDateUpperLimit) && programSettings.program_EnableAdults;
    const canBeYouth = programSettings.program_EnableYouth && (
      (programSettings.enable_CloverBud &&
        dob <= new Date(programSettings.cloverBudBirthDateUpperLimit) &&
        dob >= new Date(programSettings[youthBirthDateLowerLimitKey])) ||
      (dob <= new Date(programSettings.youthBirthDateUpperLimit) &&
        dob >= new Date(programSettings[youthBirthDateLowerLimitKey])));
    return { canBeYouth, canBeAdult };
  }
}
