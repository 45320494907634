export interface IUnitUpdateCharacteristicsDto {
    isClub?: boolean;
    ein?: string;
    integrated?: boolean;
    raciallyMixed?: boolean;
    stateWide?: boolean;
    chartered?: boolean;
    chargeFee?: boolean;
    deliveryModeId: string;
    countyAreaId: string;
    unitName?: string;
}
