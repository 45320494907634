import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { stateList } from 'app/shared/utils';

@Component({
    selector: 'ng4h-state-dropdown',
    templateUrl: './state-dropdown.component.html',
    styleUrls: ['./state-dropdown.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => StateDropdownComponent),
            multi: true
        }
    ]
})
export class StateDropdownComponent implements ControlValueAccessor {

    public stateList = stateList;

    @Input() value = '';

    onChange: any = () => {
    };
    onTouched: any = () => {
    };

    constructor() {
    }

    registerOnChange(fn) {
        this.onChange = fn;
    }

    registerOnTouched(fn) {
        this.onTouched = fn;
    }

    sortNull() {
    }

    writeValue(value) {
        if (value != null) {
            this.value = value;
            this.onChange(value);
            this.onTouched();
        }
    }
}
