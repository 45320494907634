import { ePaymentStatus, IPaymentMethodDto } from '..';
import { IPaymentTransactionDto } from '../IPaymentTransactionDto';

export interface IPaymentDto {
    paymentId: string;
    amount: number;
    dateCreated: string;
    status: ePaymentStatus;
    reasonForDecline?: string;
    paymentMethod: IPaymentMethodDto;
    canUpdateTransaction: boolean;
    transactions?: IPaymentTransactionDto[];
}
