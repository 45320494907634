import { IHierarchyNodeDto, IProfileDescriptorDto } from '.';
import { eFairEntryIntegrationStatus, eFourHDataIntegrationDecisionType } from '..';

export interface IFairEntryIntegrationDto {
    fairEntryIntegrationId: string;
    dateRequested: string;
    hierarchyNode: IHierarchyNodeDto;
    programId: string;
    programYearId: string;
    fairId: number;
    approved?: boolean;
    approvedBy: IProfileDescriptorDto;
    approvedDate?: string;
    denied?: boolean;
    deniedBy: IProfileDescriptorDto;
    deniedDate?: string;
    canceled?: boolean;
    canceledBy: IProfileDescriptorDto;
    canceledDate?: string;
    decisionTaken?: eFourHDataIntegrationDecisionType;
    fairName?: string;
    fairOrganizationContact?: string;
    fairAdminRequester?: string;
    fairAdminRequesterUserToken: string;
    fairOrganizationContactEmail?: string;
    fairOrganizationContactPhone?: string;
    fairOrganizationContactCity?: string;
    fairOrganizationContactStreet?: string;
    fairOrganizationContactState?: string;
    fairOrganizationContactZipCode?: string;
    notes?: string;
    status: eFairEntryIntegrationStatus;
}
