import { IAliasDto } from '.';
import { eAppSettingsAlias } from '..';
import { IProgramSettingsUpdateDto } from './IProgramSettingsUpdateDto';

export interface IProgramSettingsSuperUserUpdateDto extends IProgramSettingsUpdateDto {
    aliases?: { [alias in eAppSettingsAlias]: IAliasDto };
    enrollment_StartDay?: number;
    enrollment_StartMonth?: number;
    programAge_Calculation_StartDay?: number;
    programAge_Calculation_StartMonth?: number;
    program_StartDay?: number;
    program_StartMonth?: number;
    enable_HealthForm_Youth: boolean;
    enable_HealthForm_Adults: boolean;
    gender_Birth_On: boolean;
    gender_Identity_On: boolean;
    enable_CloverBud: boolean;
    enable_Participant: boolean;
    enable_Districts: boolean;
    enable_ClubChartering: boolean;
    enable_Regions: boolean;
    enable_FamilyLogins: boolean;
    member_Edit_ActivitiesAndGroups: boolean;
    member_RestrictClubSelectionToFamilyCounty: boolean;
    enrollment_LinkProjectsToClubs: boolean;
    enrollment_AllProjectsAvailableToClubs: boolean;
    permission_RestrictToInstitution_ClubManagement: boolean;
    permission_RestrictToInstitution_ClubChartering: boolean;
    permission_RestrictToInstitution_VolunteerScreening: boolean;
    permission_RestrictToSystemManager_ManagerManagement: boolean;
    countyCredits_ReceivedByInstitutionForApproval: boolean;
    financial_On?: boolean;
    localization_SupportedLocalizations?: any[];
    program_EnableProjects: boolean;
    program_EnableCountyProjects: boolean;
    program_EnableUnits: boolean;
    program_EnableUnitLeaders: boolean;
    countyCredits_On: boolean;
    memberCredits_On: boolean;
    memberCreditsOwedToInstitutionOnly_On: boolean;
    memberCreditsRequireTermsOfPayment: boolean;
    countyEvents_On: boolean;
    districtEvents_On: boolean;
    regionEvents_On: boolean;
    programEvents_On: boolean;
    county_SelectionOfPaymentMethods_On: boolean;
    county_Events_ElectronicPaymentMethods_On: boolean;
    nonElectronicPaymentSubmissionDetails?: string;
    enrollment_FeeEnable: boolean;
    enrollment_ClubFeeEnable: boolean;
    enrollment_FamilyDiscount_On: boolean;
    enrollment_FamilyDiscount_CloverBud_On: boolean;
    enrollment_FamilyDiscount_AdultVolunteer_On: boolean;
    enrollment_FamilyDiscount_County_On: boolean;
    enrollment_MaxClubs: number;
    enrollment_MaxProjects: number;
    enrollmentFee_LateFee_StartDay?: number;
    enrollmentFee_LateFee_StartMonth?: number;
    enrollment_PrePopulate_Clubs_And_Projects_For_ReEnrollment: boolean;
    enrollment_PrePopulate_EnrollmentForm_From_PreviousYear: boolean;
    enrollment_Always_Show_Unit_And_Project_Selection: boolean;
    enrollment_Member_FeeWaiver_Request_On: boolean;
    groupEnrollment_Modification_LastDay?: number;
    groupEnrollment_Modification_LastMonth?: number;
    programAge_Adult_Minimum?: number;
    programAge_CloverBud_Minimum?: number;
    programAge_Youth_Minimum?: number;
    programAge_Youth_Maximum?: number;



    programName?: string;
    programDescription?: string;
    programAge_Manager_Youth_Maximum?: number;
    member_Show_Address: boolean;
    member_Show_Second_Family: boolean;
    member_Show_Second_EmergencyContact: boolean;
    systemEmails_SendToFamily_OnManagerAction: boolean;
    systemEmails_SendToManager: boolean;

    enrollment_Show_InstitutionProjects_In_Addition_To_CountyProjects: boolean;

    program_NonES237Only?: boolean;

    event_PaymentOptions_OverrideCountyOptions: boolean;
    memberEditProjects: boolean;
    memberEditProjectsEndDay: number;
    memberEditProjectsEndMonth: number;
    memberEditUnits: boolean;
    memberEditUnitsEndDay: number;
    memberEditUnitsEndMonth: number;

    electronicPayments_Enrollment_On: boolean;
    electronicPayments_Events_On: boolean;
    electronicPayments_Animals_On: boolean;

    stripeAccounts_CountyHierarchy_On: boolean;
    stripeAccounts_DistrictHierarchy_On: boolean;


    genderAliases?: { [alias: string]: string };

    program_EnableAdults: boolean;
    program_EnableYouth: boolean;

    memberShowExternalScreeningOption: boolean;

    program_AdultBased: boolean;
    program_AllowMembersToJoin: boolean;
    enrollment_CreditCardFeeRecoveryEnable: boolean;

    program_CountyBased: boolean;
}
