import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AutoUnsubscribe, eEnrollmentRoleType, eSchoolGradeType, IMemberDto } from 'app/core/models';
import { CommonToastrService, EnrollmentService, MemberService, ProfileService, RouterService } from 'app/core/services';
import { ProgramSettingsService } from 'app/core/services/program-settings.service';
import { combineLatest, filter, Observable, take, takeUntil } from 'rxjs';


@Component({
  selector: 'ng4h-modal-enroll',
  templateUrl: './modal-enroll.component.html',
  styleUrls: ['./modal-enroll.component.scss']
})
export class ModalEnrollComponent extends AutoUnsubscribe implements OnInit {


  public eSchoolGradeType = eSchoolGradeType;
  public eEnrollmentRoleType = eEnrollmentRoleType;
  public joinProgramFormGroup: FormGroup;
  public selectARoleFormGroup: FormGroup;
  public youthGradeFormGroup: FormGroup;

  private member$: Observable<IMemberDto>;
  public institutionId: string;
  public showRoleSelect = null;
  public error = null;

  public isManager$: Observable<boolean>;


  public loading = true;

  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: { memberId: string, programId: string },
    private routerService: RouterService,
    private formBuilder: FormBuilder,
    private enrollmentService: EnrollmentService,
    private programSettingsService: ProgramSettingsService,
    private memberService: MemberService,
    private toastrService: CommonToastrService,
    private profileService: ProfileService,
    private cdr: ChangeDetectorRef
  ) {
    super();
    this.institutionId = this.routerService.institutionId;

    this.member$ = this.memberService.getMember({ institutionId: this.routerService.institutionId, memberId: this.data.memberId }).pipe(takeUntil(this.autoUnsubscribe));

    this.joinProgramFormGroup = this.formBuilder.group({
      program: [null, Validators.required]
    });

    this.selectARoleFormGroup = this.formBuilder.group({
      role: [null, Validators.required]
    });

    this.youthGradeFormGroup = this.formBuilder.group({
      grade: [null, [Validators.required]]
    });


  }

  ngOnInit() {
    this.isManager$ = this.profileService.activeUserInstitutionProfileIsManager$;
    this.programSelected(this.data.programId);
  }


  programSelected(programId: string) {
    this.joinProgramFormGroup.controls['program'].setValue(programId);

    combineLatest([this.member$, this.programSettingsService.getProgramSettings({ institutionId: this.routerService.institutionId, programId }), this.isManager$]).pipe(
      filter(combined => combined.every(c => c != null)),
      take(1)
    ).subscribe(([member, programSettings, isManager]) => {

      const { canBeYouth, canBeAdult } = this.programSettingsService.canBeYouthOrAdult(member.birthDate, programSettings, isManager);

      if (canBeYouth && canBeAdult) {
        this.showRoleSelect = true;
      } else if (canBeYouth) {
        this.selectARoleFormGroup.controls['role'].setValue(eEnrollmentRoleType.ClubMember);
        this.showRoleSelect = false;
      } else if (canBeAdult) {
        this.showRoleSelect = false;
        this.selectARoleFormGroup.controls['role'].setValue(eEnrollmentRoleType.Volunteer);
      } else {
        this.error = 'This member does not meet the age requirements for this program';
      }
      this.loading = false;

    });
  }

  roleSelected(roleName: string) {
    this.selectARoleFormGroup.controls['role'].setValue(roleName);
  }


  enroll() {


    const role = this.selectARoleFormGroup.controls['role'].value;

    this.enrollmentService.createEnrollment({
      institutionId: this.routerService.institutionId,
      memberId: this.data.memberId,
      programId: this.joinProgramFormGroup.controls['program'].value,
      enrollmentRoleType: role,
      schoolGrade: this.youthGradeFormGroup.value.grade
    })
      .subscribe({
        next: enrollment => {
          this.toastrService.success('Enrollment Created');
          this.routerService.Go([
            this.routerService.userAccountId, this.routerService.institutionId, this.routerService.loggedInInstitutionProfileId, 'member', this.data.memberId, this.joinProgramFormGroup.controls['program'].value, 'enrollments', enrollment.enrollmentId, 'enroll', enrollment.enrollmentRole, 'register']);
          this.close();
        }, error: (error) => {
          this.toastrService.error('Could not create enrollment', error);
        }
      });
  }

  close() {
    this.dialogRef.close();
  }

  public retainOrder() {
    return 0;
  }

}
