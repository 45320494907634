<form [formGroup]="addManager" novalidate>
    <div class="form-group col-sm-12">
        <label class="form-control-label">Email or Name</label>
        <input class="form-control" [matAutocomplete]="auto" formControlName="email" type="email">
        <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let manager of managers$ | async; let i = index" [value]="manager.email"
                style="height: 65px; line-height: 25px" (click)="autoCompleteClicked(manager)">
                <div>{{manager.email}}</div>
                <small>{{manager.lastName}}, {{manager.firstName}}</small>
            </mat-option>
        </mat-autocomplete>
    </div>
</form>