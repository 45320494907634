import { Action, createReducer, on } from '@ngrx/store';
import { IInstitutionManagerProgramModel, ITrainingDescriptorDto } from 'app/core/models';
import { mergeImmutable } from 'app/shared/utils';

import { ManagerTrainingsActions } from '.';

export interface ManagerTrainingsState {
    trainings: IInstitutionManagerProgramModel<{ [hierarchyNodeId: string]: ITrainingDescriptorDto[] }>
}

const initialState: ManagerTrainingsState = {
    trainings: undefined
};

const reducer = createReducer(
    initialState,
    on(ManagerTrainingsActions.LoadTrainingsSuccessAction, (state, { institutionId, managerId, programId, hierarchyNodeId, trainings }) => {
        const newTrainings = mergeImmutable(
            { [institutionId]: { [managerId]: { [programId]: { [hierarchyNodeId]: trainings } } } },
            state.trainings
        );
        return {
            ...state,
            trainings: newTrainings
        };
    })
);

export function managerTrainingsReducer(state: ManagerTrainingsState | undefined, action: Action) {
    return reducer(state, action);
}

export const trainings = (state: ManagerTrainingsState) => state.trainings;

