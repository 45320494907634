import { Pipe, PipeTransform } from '@angular/core';
import { filter, map, Observable } from 'rxjs';

import { IEventRegistrationTypeDto } from '../models/serverDTOs/IEventRegistrationTypeDto';
import { EventsManagerService, RouterService } from '../services';

@Pipe({
    name: 'mangerRegistrationType$',
    pure: true
})

export class ManagerRegistrationTypePipe implements PipeTransform {

    constructor(private eventsManagerService: EventsManagerService, private routerService: RouterService) {
    }

    transform(registrationTypeId: string,
        institutionId: string = this.routerService.institutionId,
        managerId: string = this.routerService.managerId,
        programId: string = this.routerService.programId,
        eventId: string = this.routerService.eventId
    ): Observable<IEventRegistrationTypeDto> {

        return this.eventsManagerService.getEvent({ institutionId, managerId, programId, eventId }).pipe(
            filter(event => event != null),
            map(event => event.registrationTypes.find(rt => rt.registrationTypeId === registrationTypeId))
        );

    }
}
