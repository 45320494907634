<div class="modal-section">
  <div class="modal-header">
    <h5 class="modal-title">Edit Discount</h5>
    <button (click)="cancel()" type="button" class="close" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="form">
      <div class="form-group row mr-0">
        <label class="form-control-label col-6">Discount for Second Child</label>
        <input class="form-control col-6" type="number" formControlName="DiscountFor2">
      </div>
      <div class="form-group row mr-0">
        <label class="form-control-label col-6">Discount for Third Child</label>
        <input class="form-control col-6" type="number" formControlName="DiscountFor3">
      </div>
      <div class="form-group row mr-0">
        <label class="form-control-label col-6">Discount for Fourth Child</label>
        <input class="form-control col-6" type="number" formControlName="DiscountFor4">
      </div>
      <div class="form-group row mr-0">
        <label class="form-control-label col-6">Discount for Fifth Child and Additional Children</label>
        <input class="form-control col-6" type="number" formControlName="DiscountFor5orMore">
      </div>
    </form>
  </div>

  <div class="modal-footer" *ngIf="form">
    <button id (click)="cancel()" class="btn btn-secondary my-2 my-sm-0" role="button"
      aria-pressed="true">Cancel</button>
    <button [disabled]="form.invalid" (click)="update()" class="btn btn-primary my-2 my-sm-0" role="button"
      aria-pressed="true">Update</button>
  </div>
</div>
