import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  eEventEmailType,
  eProgramEmailType,
  IInstitutionManagerProgramEventParams,
  IInstitutionSystemManagerProgramParams,
  IProgramEmailTemplateUpdateDto,
} from 'app/core/models';

export const EmailTemplateProgramLoadAction = createAction(
  '[email template] Program load email templates',
  props<IInstitutionSystemManagerProgramParams>()
);

export const EmailTemplateProgramLoadSuccessAction = createAction(
  '[email template] Program load email templates success',
  props<IInstitutionSystemManagerProgramParams & { templates: any }>()
);

export const EmailTemplateProgramLoadErrorAction = createAction(
  '[email template] Program load email templates error',
  props<{ error: HttpErrorResponse }>()
);

export const EmailTemplateProgramUpdateAction = createAction(
  '[email template] Program Update email template',
  props<IInstitutionSystemManagerProgramParams & { typeId: eProgramEmailType, template: IProgramEmailTemplateUpdateDto }>()
);

export const EmailTemplateProgramUpdateSuccessAction = createAction(
  '[email template] Program Update email template success',
  props<IInstitutionSystemManagerProgramParams & { typeId: eProgramEmailType, template: IProgramEmailTemplateUpdateDto }>()
);

export const EmailTemplateProgramUpdateErrorAction = createAction(
  '[email template] Program Update email template error',
  props<{ error: HttpErrorResponse }>()
);

export const EmailTemplateProgramInvalidateCache = createAction(
  '[email template] Program Invalidate email template cache'
);


export const EmailTemplateEventLoadAction = createAction(
  '[email template] Event load email templates',
  props<IInstitutionManagerProgramEventParams>()
);

export const EmailTemplateEventLoadSuccessAction = createAction(
  '[email template] Event load email templates success',
  props<IInstitutionManagerProgramEventParams & { templates: any }>()
);

export const EmailTemplateEventLoadErrorAction = createAction(
  '[email template] Event load email templates error',
  props<{ error: HttpErrorResponse }>()
);

export const EmailTemplateEventUpdateAction = createAction(
  '[email template] Event Update email template',
  props<IInstitutionManagerProgramEventParams & { eventType: eEventEmailType, template: IProgramEmailTemplateUpdateDto }>()
);

export const EmailTemplateEventUpdateSuccessAction = createAction(
  '[email template] Event Update email template success',
  props<IInstitutionManagerProgramEventParams & { eventType: eEventEmailType, template: IProgramEmailTemplateUpdateDto }>()
);

export const EmailTemplateEventUpdateErrorAction = createAction(
  '[email template] Event Update email template error',
  props<{ error: HttpErrorResponse }>()
);

export const EmailTemplateEventDeleteAction = createAction(
  '[email template] Event Delete email template',
  props<IInstitutionManagerProgramEventParams & { eventType: eEventEmailType }>()
);

export const EmailTemplateEventDeleteSuccessAction = createAction(
  '[email template] Event Delete email template success',
  props<IInstitutionManagerProgramEventParams & { eventType: eEventEmailType }>()
);

export const EmailTemplateEventDeleteErrorAction = createAction(
  '[email template] Event Delete email template error',
  props<{ error: HttpErrorResponse }>()
);

export const EmailTemplateEventInvalidateCache = createAction(
  '[email template] Event Invalidate email template cache'
);




export const EmailTemplateWrapperLoadAction = createAction(
  '[email template] load template wrapper',
  props<{ institutionId: string, systemManagerId: string, programId: string }>()
);

// TODO: update wrapper type once known
export const EmailTemplateWrapperLoadSuccessAction = createAction(
  '[email template] load template wrapper success',
  props<{ institutionId: string, systemManagerId: string, programId: string, wrapper: any }>()
);

export const EmailTemplateWrapperLoadErrorAction = createAction(
  '[email template] load template wrapper error',
  props<{ error: HttpErrorResponse }>()
);
