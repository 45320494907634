<div class="modal-header">
    <h5 class="modal-title">Selection</h5>
    <button (click)="close()" type="button" class="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <!-- <pre>{{data | json}}</pre> -->
    <div class="row">
        <div class="div-group col-12">
            <label class="form-control-label">Search</label>
            <input type="text" class="form-control" [formControl]="searchInputControl">
        </div>
    </div>

    <ng-container *ngFor="let option of filteredOptions$ | async; let first = first; trackBy: displayTrackBy">
        <div *ng4hVar="isSelected(option) as isSelected" class="ng4h-border-bottom ng4h-padding"
            [ngClass]="{'ng4h-border-top': first}">
            <mat-checkbox [checked]="isSelected" (change)="change(option, $event.checked)">
                {{option[data.displaySelector]}}</mat-checkbox>
        </div>
    </ng-container>
</div>
<div class="modal-footer">
    <button (click)="confirm()" class="btn btn-primary">Confirm</button>
    <button (click)="close()" class="btn btn-secondary">Cancel</button>
</div>