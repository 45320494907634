import { state } from '@angular/animations';
import { Action, createReducer, on } from '@ngrx/store';
import {
    IConsentTypeDto,
    ICreditDto,
    IEventCouponDto,
    IEventDescriptorDto,
    IEventDto,
    IEventRegistrationPendingDto,
    IEventRegistrationSearchResultsDto,
    IInstitutionManagerProgramEventModel,
    IInstitutionManagerProgramHierarchyModel,
    IInstitutionManagerProgramModel
} from 'app/core/models';
import { mergeImmutable } from 'app/shared/utils';

import { EventsManagerActions } from '.';
import { EventsManagerLoadCrossEventRegistrations, EventsManagerLoadEventRegistrations } from './events-manager.actions';

export interface EventsManagerState {
    event: IInstitutionManagerProgramEventModel<IEventDto>;
    groupEnrollments: IInstitutionManagerProgramEventModel<any>;
    events: IInstitutionManagerProgramModel<{ [hierarchyNodeId: string]: { [programYear: string]: IEventDescriptorDto[] } }>;
    submittedEventRegistrations: IInstitutionManagerProgramModel<{ [hierarchyNodeId: string]: IEventRegistrationPendingDto[] }>;
    eventRegistrations: IInstitutionManagerProgramEventModel<IEventRegistrationSearchResultsDto>;
    crossEventRegistrations: IInstitutionManagerProgramHierarchyModel<IEventRegistrationSearchResultsDto>;
    eventConsents: IInstitutionManagerProgramEventModel<{ [eventRegistrationTypeId: string]: IConsentTypeDto[] }>;
    outstandingCredits: IInstitutionManagerProgramEventModel<ICreditDto[]>;
    countyCredits: IInstitutionManagerProgramModel<{ [countyAreaId: string]: ICreditDto[] }>;
    registrationSearchSearching: boolean;
    eventCoupons: IInstitutionManagerProgramEventModel<IEventCouponDto[]>;
    eventCoupon: IInstitutionManagerProgramEventModel<{ [institutionDiscountId: string]: IEventCouponDto }[]>;
}

const initialState: EventsManagerState = {
    event: undefined,
    events: undefined,
    groupEnrollments: undefined,
    submittedEventRegistrations: undefined,
    eventRegistrations: undefined,
    crossEventRegistrations: undefined,
    eventConsents: undefined,
    outstandingCredits: undefined,
    countyCredits: undefined,
    registrationSearchSearching: false,
    eventCoupons: undefined,
    eventCoupon: undefined
};

const reducer = createReducer(
    initialState,
    on(EventsManagerActions.EventsManagerCreateSuccessAction, EventsManagerActions.EventsManagerCopySuccessAction, (stx, {}) => {
        return stx;
    }),
    on(EventsManagerActions.EventsManagerLoadSuccessAction, (stx, { institutionId, managerId, programId, eventId, event: evt }) => {
        const newEvent = mergeImmutable(
            { [institutionId]: { [managerId]: { [programId]: { [eventId]: evt } } } },
            stx.event
        );
        return {
            ...stx,
            event: newEvent
        };
    }),
    on(EventsManagerActions.EventsManagerLoadManySuccessAction, (stx, {
        institutionId,
        managerId,
        programId,
        hierarchyNodeId,
        programYearId,
        events: evts
    }) => {
        const newEvents = mergeImmutable(
            { [institutionId]: { [managerId]: { [programId]: { [hierarchyNodeId]: { [programYearId]: evts } } } } },
            stx.events
        );
        return {
            ...stx,
            events: newEvents
        };
    }),
    // on(EventsManagerActions.EventsManagerLoadSubmittedRegistrationsSuccessAction, (state, { institutionId, managerId, programId, hierarchyNodeId, submittedEventRegistrations }) => {
    //     const newSubmittedEventRegistrations = mergeImmutable(
    //         { [institutionId]: { [managerId]: { [programId]: { [hierarchyNodeId]: submittedEventRegistrations } } } },
    //         state.submittedEventRegistrations
    //     );
    //     return {
    //         ...state,
    //         submittedEventRegistrations: newSubmittedEventRegistrations
    //     };
    // }),
    on(EventsManagerActions.EventRegistrationSearchSuccessAction,
        EventsManagerActions.EventsManagerLoadEventRegistrationsSuccess,
        (stx, { institutionId, managerId, programId, eventId, eventRegistrations: registrations }) => {
            return {
                ...stx,
                eventRegistrations: { [institutionId]: { [managerId]: { [programId]: { [eventId]: registrations } } } },
                registrationSearchSearching: false
            };
        }),
    on(EventsManagerActions.EventRegistrationSearchAction,
        EventsManagerActions.EventRegistrationClearSearchAction,
        EventsManagerActions.EventsManagerLoadEventRegistrations,
        EventsManagerActions.CrossEventRegistrationClearSearchAction,
        EventsManagerActions.CrossEventRegistrationSearchAction,
        EventsManagerActions.EventsManagerLoadCrossEventRegistrations, (stx, {}) => {
            return {
                ...stx,
                registrationSearchSearching: true
            };
        }),
    on(
        EventsManagerActions.EventRegistrationSearchErrorAction,
        EventsManagerActions.EventRegistrationClearSearchErrorAction,
        EventsManagerActions.CrossEventRegistrationClearSearchErrorAction,
        EventsManagerActions.CrossEventRegistrationSearchErrorAction, (stx, {}) => {
            return {
                ...stx,
                registrationSearchSearching: false
            };
        }),
    on(EventsManagerActions.CrossEventRegistrationSearchSuccessAction,
        EventsManagerActions.EventsManagerLoadCrossEventRegistrationsSuccess,
        (stx, { institutionId, managerId, programId, hierarchyNodeId, eventRegistrations: registrations }) => {
            return {
                ...stx,
                crossEventRegistrations: { [institutionId]: { [managerId]: { [programId]: { [hierarchyNodeId]: registrations } } } },
                registrationSearchSearching: false
            };
        }),
    on(EventsManagerActions.EventsManagerLoadConsentsSuccessAction,
        (stx, { institutionId, managerId, programId, eventId, eventRegistrationTypeId, consents }) => {
            return {
                ...stx,
                eventConsents: mergeImmutable(
                    { [institutionId]: { [managerId]: { [programId]: { [eventId]: { [eventRegistrationTypeId]: consents } } } } },
                    stx.eventConsents
                )
            };
        }),
    on(EventsManagerActions.EventsManagerInvalidateEventsCacheAction,
        (stx, {}) => {
            return {
                ...stx,
                events: undefined
            };
        }),
    on(EventsManagerActions.EventsManagerInvalidateEventCacheAction,
        EventsManagerActions.EventsManagerCreateSessionSuccessAction,
        EventsManagerActions.EventsManagerUpdateSessionSuccessAction,
        EventsManagerActions.EventsManagerDeleteSessionSuccessAction,
        EventsManagerActions.EventsManagerUpdateEventSuccessPermissions,
        EventsManagerActions.UpdateEventHierarchyRestrictionsSuccessAction,
        EventsManagerActions.EventsManagerAddVanityDomainSuccessAction,
        EventsManagerActions.EventsManagerDeleteVanityDomainSuccessAction,
        EventsManagerActions.EventsManagerUpdateVanityDomainSuccessAction,
        (stx, {}) => {
            return {
                ...stx,
                event: undefined,
                events: undefined
            };
        }),
    on(EventsManagerActions.EventsManagerDeleteEventSuccessAction,
        (stx, {}) => {
            return {
                ...stx,
                events: undefined
            };
        }),
    on(EventsManagerActions.EventManagerInvalidateConsentsAction,
        (stx, {}) => {
            return {
                ...stx,
                eventConsents: undefined
            };
        }),
    on(EventsManagerActions.GetOutstandingCreditSuccessAction, (stx, {
        institutionId,
        managerId,
        programId,
        eventId,
        outstandingCredits: credits
    }) => {
        return {
            ...stx,
            outstandingCredits: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [eventId]: credits } } } },
                stx.outstandingCredits
            )
        };
    }),
    on(EventsManagerActions.GetOutstandingCountyCreditsSuccessAction, (stx, {
        institutionId,
        managerId,
        programId,
        countyAreaId,
        outstandingCredits: credits
    }) => {
        return {
            ...stx,
            countyCredits: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [countyAreaId]: credits } } } },
                stx.countyCredits
            )
        };
    }),
    on(EventsManagerActions.ConfirmCreditCollectedSuccessAction, (stx, {}) => {
        return {
            ...stx,
            outstandingCredits: undefined,
            countyCredits: undefined
        };
    }),
    on(EventsManagerActions.GetEventCouponsSuccessAction, (stx, { institutionId, managerId, programId, eventId, coupons }) => {
        return {
            ...stx,
            eventCoupons: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [eventId]: coupons } } } },
                stx.eventCoupons
            )
        };
    }),
    on(EventsManagerActions.GetEventCouponSuccessAction, (stx, { institutionId, managerId, programId, eventId, institutionDiscountId, coupon }) => {
        return {
            ...stx,
            eventCoupon: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [eventId]: { [institutionDiscountId]: coupon } } } } },
                stx.eventCoupon
            )
        };
    }),
    on(EventsManagerActions.DeleteEventCouponSuccessAction, EventsManagerActions.UpdateEventCouponSuccessAction, (stx, {
        institutionId,
        managerId,
        programId,
        eventId,
        institutionDiscountId
    }) => {
        return {
            ...stx,
            eventCoupons: undefined,
            eventCoupon: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [eventId]: { [institutionDiscountId]: null } } } } },
                stx.eventCoupon
            )
        };
    }),
    on(EventsManagerActions.UpdateEventCouponSuccessAction, (stx, {}) => {
        return {
            ...stx,
            eventCoupons: undefined,
            eventCoupon: undefined
        };
    }),
    on(EventsManagerActions.CreateEventCouponSuccessAction, (stx, {}) => {
        return {
            ...stx,
            eventCoupons: undefined
        };
    }),
    on(EventsManagerActions.EventsManagerGetGroupEnrollmentSuccessAction, (stx, {
        institutionId,
        managerId,
        programId,
        eventId,
        groupEnrollments: groupEnrollment
    }) => {
        const newGroupEnrollments = mergeImmutable(
            { [institutionId]: { [managerId]: { [programId]: { [eventId]: groupEnrollment } } } },
            stx.groupEnrollments
        );
        return {
            ...stx,
            groupEnrollments: newGroupEnrollments
        };
    }),
    on(EventsManagerActions.EventsManagerCreateGroupEnrollmentSuccessAction, (stx, {}) => {
        return {
            ...stx,
            groupEnrollments: undefined
        };
    })
);

export function eventsManagerReducer(state: EventsManagerState | undefined, actions: Action) {
    return reducer(state, actions);
}

export const event = (state: EventsManagerState) => state.event;
export const groupEnrollments = (state: EventsManagerState) => state.groupEnrollments;
export const events = (state: EventsManagerState) => state.events;
export const submittedEventRegistrations = (state: EventsManagerState) => state.submittedEventRegistrations;
export const eventRegistrations = (state: EventsManagerState) => state.eventRegistrations;
export const crossEventRegistrations = (state: EventsManagerState) => state.crossEventRegistrations;
export const eventConsents = (state: EventsManagerState) => state.eventConsents;
export const outstandingCredits = (state: EventsManagerState) => state.outstandingCredits;
export const countyCredits = (state: EventsManagerState) => state.countyCredits;
export const registrationSearchSearching = (state: EventsManagerState) => state.registrationSearchSearching;
export const eventCoupons = (state: EventsManagerState) => state.eventCoupons;
export const eventCoupon = (state: EventsManagerState) => state.eventCoupon;
