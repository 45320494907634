import {
  IEventDescriptorDto,
  IEventRegistrationConsentDto,
  IEventRegistrationSessionDto,
  IEventRegistrationTypeDescriptorDto,
  IHierarchyNodeDto,
  IIMessage,
  IInvoiceDto,
} from '.';
import { eEventRegistrationInvoiceItemFeeType, eEventRegistrationStatus } from '..';
import { IEnrollmentAnimalDescriptorDto } from './IEnrollmentAnimalDescriptorDto';
import { IEventRegistrationManagerReviewEntityDto } from './IEventRegistrationManagerReviewEntityDto';
import { IEventRegistrationStateDto } from './IEventRegistrationStateDto';

export interface IEventRegistrationDto {
  consents?: IEventRegistrationConsentDto[];
  registrationFee?: number;
  registrationFeeType?: eEventRegistrationInvoiceItemFeeType;
  registrationDate?: string;
  invoices?: IInvoiceDto[];
  canDelete: boolean;
  canSubmit: boolean;
  unableToSubmitReason: IIMessage;
  hasFees: boolean;
  hasPendingPayment: boolean;
  paymentRequired: boolean;
  payableInvoice: IInvoiceDto;
  electronicPaymentsEnabled: boolean;
  creditsEnabled: boolean;
  creditCollectionRequired: boolean;
  showRefundOption: boolean;
  currentlyPendingApprovalLevel: IHierarchyNodeDto;
  requiredHierarchyApprovals?: any[];
  managerApprovals?: IEventRegistrationManagerReviewEntityDto[];
  registrationSessions?: IEventRegistrationSessionDto[];
  registrationId: string;
  registrationIdentifier: number;
  registrationStatus: eEventRegistrationStatus;
  eventDescription: IEventDescriptorDto;
  registrationType: IEventRegistrationTypeDescriptorDto;
  registrationState: IEventRegistrationStateDto;
  animal: IEnrollmentAnimalDescriptorDto;

  requireDeposit: boolean;
  depositFee?: number;

  allowPartialPayment: boolean;
  enrollmentId: string;

}

