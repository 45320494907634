import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DynamicExternalJavascriptService {

  constructor() { }

  public loadScripts(scripts: string | string[]): Observable<any>[] {

    const observables: Observable<any>[] = [];

    if (scripts == null) {
      return observables;
    }
    let newScripts = Array.isArray(scripts) ? scripts : [scripts];
    newScripts = newScripts.filter(script => script != null);

    const existingScripts = document.getElementsByTagName('script');

    for (let j = 0; j < newScripts.length; ++j) {
      let found = false;
      for (let i = 0; i < existingScripts.length; ++i) {
        const src = existingScripts[i].getAttribute('src');
        if (src != null && src === newScripts[j]) {
          found = true;
          break;
        }
      }
      if (!found) {
        const node = document.createElement('script');
        node.src = newScripts[j];
        node.type = 'text/javascript';
        node.async = false;
        node.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node);


        const observable = new Observable(observer => {
          node.addEventListener('load', () => {
            observer.next(true);
            observer.complete();
          });
          // node.onload = () => {
          //   observer.next(true);
          //   observer.complete();
          // };
        });

        observables.push(observable);

      }
    }
    return observables;

  }
}
