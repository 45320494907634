import { eProgramType } from '../enums';

export interface IProgramSummaryDto {
    institutionProgramId: string;
    programId: string;
    institutionProgramName?: string;
    institutionProgramDescription?: string;
    programType: eProgramType;
    deleted: boolean;
    active: boolean;
    nonES237Only: boolean;
}
