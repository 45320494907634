import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { AdminActions } from 'app/core/containers/admin';
import {
  IHierarchySummaryDto,
  IInstitutionManagerParams,
  IInstitutionManagerProgramHierarchyParams,
  IInstitutionManagerProgramParams,
  IInstitutionSystemManagerProgramParams,
  IManagerCreateDto,
  IManagerDescriptorDto,
  IManagerInstitutionProfile,
  IManagerUpdateDto,
  IPagination,
  IUserAccountDto,
  IUserAccountPasswordDto
} from 'app/core/models';
import { filter, map, Observable, of, take, tap } from 'rxjs';

import {
  AdminFeatureStore,
  getDeletedManagers,
  getHierarchySummary,
  getManager,
  getManagerPermissionTypes,
  getManagers,
  getManagersSearchResult
} from '../containers/admin/admin-feature.reducers';
import { IPermissionType } from '../models';
import {
  IManagerProgramHierarchyAssociationUpdateDto
} from '../models/serverDTOs/IManagerProgramHierarchyAssociationUpdateDto';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ManagerService {

  public managersSearchResult$: Observable<IPagination<IManagerInstitutionProfile>>;

  constructor(
    private store: Store<AdminFeatureStore>,
    private httpClient: HttpClient,
    private dispatcher: ActionsSubject
  ) {
    this.managersSearchResult$ = this.store.pipe(select(getManagersSearchResult));
  }

  public getManagersByIds({
    institutionId,
    managerIds
  }: { institutionId: string, managerIds: string[] }): Observable<IManagerInstitutionProfile[]> {
    if (!Array.isArray(managerIds)) {
      return of([]);
    }
    return this.getManagers({ institutionId }).pipe(
      filter(managers => {
        return Array.isArray(managers);
      }),
      map(managers => {
        return managers.filter(manager => managerIds.includes(manager.managerId));
      }));
  }

  public getManagers(params: { institutionId: string }): Observable<IManagerInstitutionProfile[]> {

    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getManagers(params)),
      tap(managers => {
        if (managers === undefined) {
          this.store.dispatch(AdminActions.AdminGetManagersAction(params));
        }
      }),
      filter(managers => managers != null)
    );
  }

  public loadManagersEffect({ institutionId }: { institutionId: string }): Observable<IManagerInstitutionProfile[]> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers`) as Observable<IManagerInstitutionProfile[]>;
  }

  public getManager(params: { institutionId: string, managerId: string }): Observable<IManagerInstitutionProfile> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getManager(params)),
      tap(manager => {
        if (manager === undefined) {
          this.store.dispatch(AdminActions.AdminGetManagerAction(params));
        }
      }),
      filter(manager => manager != null)
    );
  }

  public loadManagerEffect({
    institutionId,
    managerId
  }: { institutionId: string, managerId: string }): Observable<IManagerInstitutionProfile> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}`) as Observable<IManagerInstitutionProfile>;
  }

  public getPermissionTypes() {
    return this.store.pipe(
      select(getManagerPermissionTypes({})),
      tap(permissionTypes => {
        if (permissionTypes === undefined) {
          this.store.dispatch(AdminActions.AdminGetManagerPermissionTypesAction());
        }
      }),
      filter(permissionTypes => permissionTypes != null)
    );
  }

  public loadManagerPermissionTypesEffect(): Observable<IPermissionType[]> {
    return this.httpClient.get(`${environment.apiUri}/api/managers/permission-types`) as Observable<IPermissionType[]>;
  }

  // Gets managers for a hierarchy node, and the managers under that node
  public getAggregatedManagers(params: { institutionId: string, hierarchyNodeId: string }) {
    if (params.hierarchyNodeId === '') {
      this.store.dispatch(AdminActions.AdminClearManagersSearchAction());
    } else {
      this.store.dispatch(AdminActions.AdminSearchManagersAction(params));
    }
  }

  public getAggregatedManagerEffect({
    institutionId,
    managerId
  }: IInstitutionManagerParams): Observable<IManagerInstitutionProfile> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}`) as Observable<IManagerInstitutionProfile>;
  }

  public searchManagersEffect({
    institutionId,
    hierarchyNodeId
  }: { institutionId: string, hierarchyNodeId: string }): Observable<IPagination<IManagerInstitutionProfile>> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${hierarchyNodeId}?pagesize=0&pageindex=0`) as Observable<IPagination<IManagerInstitutionProfile>>;
  }

  public createManagerAsSystemManager(params: IInstitutionSystemManagerProgramParams & { newManager: IManagerCreateDto }) {
    this.store.dispatch(AdminActions.AdminCreateAsSystemManagerAction(params));
    return this.dispatcher.pipe(
      ofType(AdminActions.AdminCreateAsSystemManagerSuccessAction, AdminActions.AdminCreateAsSystemManagerErrorAction),
      take(1),
      map(action => {
        if (action.type === AdminActions.AdminCreateAsSystemManagerSuccessAction.type) {
          return action.newManager;
        } else {
          throw action.error;
        }
      })
    );
  }

  public createManagerAsSystemManagerEffect({
    institutionId,
    systemManagerId,
    programId,
    newManager
  }: IInstitutionSystemManagerProgramParams & { newManager: IManagerCreateDto }): Observable<Object> {
    return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/managers`, newManager);
  }

  public updateManager(params: { institutionId: string, managerId: string, hierarchyAdminId: string, managerUpdate: IManagerUpdateDto }) {
    this.store.dispatch(AdminActions.AdminUpdateManagerAction(params));
    return this.dispatcher.pipe(
      ofType(AdminActions.AdminUpdateManagerSuccessAction, AdminActions.AdminUpdateManagerErrorAction),
      take(1),
      map(action => {
        if (action.type === AdminActions.AdminUpdateManagerSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateManagerEffect({
    institutionId,
    managerId,
    hierarchyAdminId,
    managerUpdate
  }: { institutionId: string, managerId: string, hierarchyAdminId: string, managerUpdate: IManagerUpdateDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}?hierarchyAdminId=${hierarchyAdminId}`, managerUpdate);
  }

  public updateManagerAsSystemManager(params: IInstitutionSystemManagerProgramParams & { managerId: string, managerUpdate: IManagerUpdateDto }) {
    this.store.dispatch(AdminActions.AdminUpdateManagerAsSystemManagerAction(params));
    return this.dispatcher.pipe(
      ofType(AdminActions.AdminUpdateManagerAsSystemManagerSuccessAction, AdminActions.AdminUpdateManagerAsSystemManagerErrorAction),
      take(1),
      map(action => {
        if (action.type === AdminActions.AdminUpdateManagerAsSystemManagerSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateManagerAsSystemManagerEffect({
    institutionId,
    systemManagerId,
    programId,
    managerId,
    managerUpdate
  }: IInstitutionSystemManagerProgramParams & { managerId: string, managerUpdate: IManagerUpdateDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/managers/${managerId}`, managerUpdate);
  }

  public updateManagerPermissionsAsSystemManager(params: IInstitutionSystemManagerProgramParams & { managerId: string, hierarchyNodeId: string, update: IManagerProgramHierarchyAssociationUpdateDto }) {
    this.store.dispatch(AdminActions.AdminUpdateManagerPermissionsAsSystemManagerAction(params));
    return this.dispatcher.pipe(
      ofType(AdminActions.AdminUpdateManagerPermissionsAsSystemManagerSuccessAction, AdminActions.AdminUpdateManagerPermissionsAsSystemManagerErrorAction),
      take(1),
      map(action => {
        if (action.type === AdminActions.AdminUpdateManagerPermissionsAsSystemManagerSuccessAction.type) {
          return action.update;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateManagerPermissionsAsSystemManagerEffect({
    institutionId,
    systemManagerId,
    programId,
    managerId,
    hierarchyNodeId,
    update
  }: IInstitutionSystemManagerProgramParams & { managerId: string, hierarchyNodeId: string, update: IManagerProgramHierarchyAssociationUpdateDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/managers/${managerId}/permissions?hierarchyNodeId=${hierarchyNodeId}`, update);
  }

  public canAddManager(email: string): Observable<IUserAccountDto> {
    this.store.dispatch(AdminActions.AdminCanAddManagerAction({ email: email }));
    return this.dispatcher.pipe(
      ofType(AdminActions.AdminCanAddManagerSuccessAction, AdminActions.AdminCanAddManagerErrorAction),
      take(1),
      map(action => {
        if (action.type === AdminActions.AdminCanAddManagerSuccessAction.type) {
          return action.user;
        } else {
          throw action.error;
        }
      })
    );
  }

  public canAddManagerEffect(email: string): Observable<IUserAccountDto> {
    return this.httpClient.get(`${environment.apiUri}/api/user-accounts/lookup?email=${email}`) as Observable<IUserAccountDto>;
  }

  public removeManagerFromHierarchy(params: IInstitutionSystemManagerProgramParams & { managerId: string, hierarchyNodeId: string }) {
    this.store.dispatch(AdminActions.AdminRemoveManagerFromHierarchyAction(params));
    return this.dispatcher.pipe(
      ofType(AdminActions.AdminRemoveManagerFromHierarchySuccessAction, AdminActions.AdminRemoveManagerFromHierarchyErrorAction),
      take(1),
      map(action => {
        if (action.type === AdminActions.AdminRemoveManagerFromHierarchySuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteManagerFromHierarchyEffect({
    institutionId,
    systemManagerId,
    programId,
    managerId,
    hierarchyNodeId
  }: IInstitutionSystemManagerProgramParams & { managerId: string, hierarchyNodeId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/managers/${managerId}/permissions?hierarchyNodeId=${hierarchyNodeId}`);
  }

  public resendEmail(params: IInstitutionSystemManagerProgramParams & { managerId: string }) {
    this.store.dispatch(AdminActions.AdminResendEmailAction(params));
  }

  public resendEmailEffect({
    institutionId,
    systemManagerId,
    programId,
    managerId
  }: IInstitutionSystemManagerProgramParams & { managerId: string }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/managers/${managerId}/welcome-email`, {});
  }

  public resetPassword(params: IInstitutionSystemManagerProgramParams & { managerId: string }) {
    this.store.dispatch(AdminActions.AdminResetPasswordAction(params));

    return this.dispatcher.pipe(
      ofType(AdminActions.AdminResetPasswordSuccessAction, AdminActions.AdminResetPasswordErrorAction),
      take(1),
      map(action => {
        if (action.type === AdminActions.AdminResetPasswordSuccessAction.type) {
          return action.newPasswordDto.newPassword;
        } else {
          throw action.error;
        }
      })
    );
  }

  public resetPasswordEffect({
    institutionId,
    systemManagerId,
    programId,
    managerId
  }: IInstitutionSystemManagerProgramParams & { managerId: string }): Observable<IUserAccountPasswordDto> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/managers/${managerId}/reset-password`) as Observable<IUserAccountPasswordDto>;
  }

  public removeManager(params: IInstitutionSystemManagerProgramParams & { managerId: string }) {
    this.store.dispatch(AdminActions.AdminRemoveManagerAction(params));
  }

  public removeManagerEffect({
    institutionId,
    systemManagerId,
    programId,
    managerId
  }: IInstitutionSystemManagerProgramParams & { managerId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/managers/${managerId}`);
  }

  public removeManagerHierarchyAssociation(params: IInstitutionManagerProgramHierarchyParams & { hierarchyAdminId: string }) {
    this.store.dispatch(AdminActions.AdminRemoveManagerHierarchyAssociationAction(params));
  }

  public removeManagerHierarchyAssociationEffect({
    institutionId,
    managerId,
    programId,
    hierarchyNodeId,
    hierarchyAdminId
  }: IInstitutionManagerProgramHierarchyParams & { hierarchyAdminId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/permissions?hierarchyNodeId=${hierarchyNodeId}&hierarchyAdminId=${hierarchyAdminId}`);
  }

  public removeManagerPayment(params: IInstitutionManagerProgramParams & { paymentMethodId: string }) {
    this.store.dispatch(AdminActions.AdminRemoveManagerPaymentAction(params));
  }

  public removeManagerPaymentEffect({
    institutionId,
    managerId,
    programId,
    paymentMethodId
  }: IInstitutionManagerProgramParams & { paymentMethodId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/paymentmethods/${paymentMethodId}`);
  }

  public getHierarchySummary(params: { institutionId: string, programId: string, hierarchyNodeId: string, programYearId: string }) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getHierarchySummary(params)),
      tap(summary => {
        if (summary === undefined) {
          this.store.dispatch(AdminActions.GetHierarchySummaryAction(params));
        }
      }),
      filter(summary => summary != null)
    );
  }

  public getHierarchySummaryEffect({
    institutionId,
    programId,
    hierarchyNodeId,
    programYearId
  }: { institutionId: string, programId: string, hierarchyNodeId: string, programYearId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${hierarchyNodeId}/programs/${programId}/summary/${programYearId}`) as Observable<IHierarchySummaryDto>;
  }

  public getDeletedManagers(params: { institutionId: string }) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getDeletedManagers(params)),
      tap(managers => {
        if (managers === undefined) {
          this.store.dispatch(AdminActions.GetDeletedManagersAction(params));
        }
      }),
      filter(managers => managers != null)
    );
  }

  public getDeletedManagersEffect({ institutionId }: { institutionId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/deleted`) as Observable<IManagerDescriptorDto[]>;
  }

  public reinstateManager(params: IInstitutionSystemManagerProgramParams & { managerId: string }) {
    this.store.dispatch(AdminActions.ReinstateManagerAction(params));

    return this.dispatcher.pipe(
      ofType(AdminActions.ReinstateManagerSuccessAction, AdminActions.ReinstateManagerErrorAction),
      take(1),
      map(action => {
        if (action.type === AdminActions.ReinstateManagerSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public reinstateManagerEffect({
    institutionId,
    systemManagerId,
    programId,
    managerId
  }: IInstitutionSystemManagerProgramParams & { managerId: string }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/managers/${managerId}/reinstate`, {});
  }

  public refreshAPIKey({ institutionId, managerId }: IInstitutionManagerParams) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/api-key`, {});
  }
}
