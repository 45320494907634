import { eAccessControlListRoleType, eHierarchyNodeType } from '..';

export interface IManagerHierarchyAssociationDto {
    hierarchy?: string;
    hierarchyNodeType: eHierarchyNodeType;
    hierarchyNodeName?: string;
    managerPrograms?: object;
    programId: string;
    hierarchyNodeId: string;
    primary: boolean;
    permissionRole: eAccessControlListRoleType;
}
