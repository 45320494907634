import { IInstitutionVolunteerDto } from '.';

export interface IEnrollmentActivityDto {
    enrollmentActivityId: string;
    enrollmentId: string;
    activityDate: string;
    activityId: string;
    activityName?: string;
    activityDescription?: string;
    volunteerType: IInstitutionVolunteerDto;
    displayLabel?: string;
}
