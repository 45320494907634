import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { eBankAccountType, IBankAccountDto, IBankAccountUpdateDto } from 'app/core/models';
import { BankAccountsService, RouterService } from 'app/core/services';
import { filter, map, Observable, take } from 'rxjs';

@Component({
  selector: 'ng4h-modal-edit-bank-account',
  templateUrl: './modal-edit-bank-account.component.html',
  styleUrls: ['./modal-edit-bank-account.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalEditBankAccountComponent implements OnInit {

  public eBankAccountType = eBankAccountType;
  public form: FormGroup;
  public bankAccount$: Observable<IBankAccountDto>;

  constructor(private dialogRef: MatDialogRef<any>, private fb: FormBuilder, private bankAccountsService: BankAccountsService, private routerService: RouterService) { }

  ngOnInit() {
    this.bankAccount$ = this.bankAccountsService.getSystemManagerBankAccounts({
      institutionId: this.routerService.institutionId,
      systemManagerId: this.routerService.systemManagerId,
      programId: this.routerService.programId
    }).pipe(
      filter(bankAccounts => bankAccounts != null && Array.isArray(bankAccounts)),
      map(bankAccounts => bankAccounts.find(bankAccount => bankAccount.bankAccountId === this.routerService.bankAccountId))
    );

    this.bankAccount$.pipe(take(1)).subscribe(bankAccount => {

      this.form = this.fb.group({
        bankAccountName: [bankAccount.bankAccountName, [Validators.required]],
        description: [bankAccount.description, [Validators.required]],
        defaultBankAccount: [bankAccount.defaultBankAccount, [Validators.required]],
        active: [bankAccount.active, [Validators.required]],
      });

    });
  }

  public close(updatedBankAccount?: IBankAccountUpdateDto) {
    this.dialogRef.close(updatedBankAccount);
  }

  public save() {
    this.close(this.form.value);
  }
}
