import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'ng4h-number-select',
    templateUrl: './number-select.component.html',
    styleUrls: ['./number-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NumberSelectComponent),
            multi: true
        }
    ]
})
export class NumberSelectComponent implements OnInit, ControlValueAccessor {

    @Input() private maxNumber = 10;
    @Input() private minNumber = 0;
    @Input() public disabled: boolean;

    public value: number;
    public numberList: number[] = [];

    onChange: any = () => {
    };
    onTouched: any = () => {
    };

    writeValue(value: number): void {
        if (value !== null) {
            this.value = value;
            this.onChange(value);
            this.onTouched();
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    ngOnInit() {
        for (let i = this.minNumber; i < this.maxNumber; i++) {
            this.numberList.push(i + 1);
        }
    }
}
