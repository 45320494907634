import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { AppState } from 'app/app.reducers';
import { SystemManagerSchoolsActions } from 'app/core/containers/admin/system-manager/store/schools';
import {
  getSystemManagerSchoolDistrict,
  getSystemManagerSchoolDistricts
} from 'app/core/containers/admin/system-manager/system-manager-feature.reducers';
import {
  IInstitutionSystemManagerParams,
  ISchoolCreateDto,
  ISchoolDistrictCreateDto,
  ISchoolDistrictDto,
  ISchoolDistrictUpdateDto,
  ISchoolUpdateDto
} from 'app/core/models';
import { filter, map, Observable, of, take, tap } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SystemManagerSchoolsService {

  constructor(private store: Store<AppState>, private httpClient: HttpClient, private dispatcher: ActionsSubject) {

  }

  // public getSchools(params: IInstitutionSystemManagerParams) {
  //     if (Object.keys(params).find(key => params[key] == null) != null) {
  //         return of(null);
  //     }
  //     return this.store.pipe(
  //         select(getSystemManagerSchools(params)),
  //         tap(districtsWithSchools => {
  //             if (districtsWithSchools === undefined) {
  //                 this.store.dispatch(SystemManagerSchoolsActions.SystemManagerGetSchoolsAction(params));
  //             }
  //         }),
  //         filter(districtsWithSchools => districtsWithSchools != null),
  //     );
  // }
  // public getSchoolsEffect({ institutionId, systemManagerId }: IInstitutionSystemManagerParams) {
  //     return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/schools`) as Observable<ISchoolDistrictDto[]>;
  // }

  // public getSchool(params: IInstitutionSystemManagerParams & { schoolId: string }) {
  //     if (Object.keys(params).find(key => params[key] == null) != null) {
  //         return of(null);
  //     }
  //     return this.store.pipe(
  //         select(getSystemManagerSchool(params)),
  //         tap(school => {
  //             if (school === undefined) {
  //                 this.store.dispatch(SystemManagerSchoolsActions.SystemManagerGetSchoolAction(params));
  //             }
  //         }),
  //         filter(school => school != null),
  //     );
  // }
  // public getSchoolEffect({ institutionId, systemManagerId, schoolId }: IInstitutionSystemManagerParams & { schoolId: string }) {
  //     return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/schools/${schoolId}`) as Observable<ISchoolDto>;
  // }

  public createSchool(params: IInstitutionSystemManagerParams & { schoolCreateDo: ISchoolCreateDto }) {
    this.store.dispatch(SystemManagerSchoolsActions.SystemManagerCreateSchoolAction(params));
    return this.dispatcher.pipe(
      ofType(SystemManagerSchoolsActions.SystemManagerCreateSchoolSuccessAction, SystemManagerSchoolsActions.SystemManagerCreateSchoolErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerSchoolsActions.SystemManagerCreateSchoolSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public createSchoolEffect({
    institutionId,
    systemManagerId,
    schoolCreateDo
  }: IInstitutionSystemManagerParams & { schoolCreateDo: ISchoolCreateDto }) {
    return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/schools`, schoolCreateDo);
  }

  public updateSchool(params: IInstitutionSystemManagerParams & { schoolId: string, schoolUpdateDto: ISchoolUpdateDto }) {
    this.store.dispatch(SystemManagerSchoolsActions.SystemManagerUpdateSchoolAction(params));
    return this.dispatcher.pipe(
      ofType(SystemManagerSchoolsActions.SystemManagerUpdateSchoolSuccessAction, SystemManagerSchoolsActions.SystemManagerUpdateSchoolErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerSchoolsActions.SystemManagerUpdateSchoolSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateSchoolEffect({
    institutionId,
    systemManagerId,
    schoolId,
    schoolUpdateDto
  }: IInstitutionSystemManagerParams & { schoolId: string, schoolUpdateDto: ISchoolUpdateDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/schools/${schoolId}`, schoolUpdateDto);
  }

  public deleteSchool(params: IInstitutionSystemManagerParams & { schoolId: string }) {
    this.store.dispatch(SystemManagerSchoolsActions.SystemManagerDeleteSchoolAction(params));
    return this.dispatcher.pipe(
      ofType(SystemManagerSchoolsActions.SystemManagerDeleteSchoolSuccessAction, SystemManagerSchoolsActions.SystemManagerDeleteSchoolErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerSchoolsActions.SystemManagerDeleteSchoolSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteSchoolEffect({
    institutionId,
    systemManagerId,
    schoolId
  }: IInstitutionSystemManagerParams & { schoolId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/schools/${schoolId}`);
  }

  public getSchoolDistricts(params: IInstitutionSystemManagerParams) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getSystemManagerSchoolDistricts(params)),
      tap(districts => {
        if (districts === undefined) {
          this.store.dispatch(SystemManagerSchoolsActions.SystemManagerGetDistrictsAction(params));
        }
      }),
      filter(districts => districts != null)
    );
  }

  public getSchoolDistrictsEffect({ institutionId, systemManagerId }: IInstitutionSystemManagerParams) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/schools/districts`) as Observable<ISchoolDistrictDto[]>;
  }

  public getSchoolDistrict(params: IInstitutionSystemManagerParams & { schoolDistrictId: string }) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getSystemManagerSchoolDistrict(params)),
      tap(district => {
        if (district === undefined) {
          this.store.dispatch(SystemManagerSchoolsActions.SystemManagerGetDistrictAction(params));
        }
      }),
      filter(district => district != null)
    );
  }

  public getSchoolDistrictEffect({
    institutionId,
    systemManagerId,
    schoolDistrictId
  }: IInstitutionSystemManagerParams & { schoolDistrictId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/schools/districts/${schoolDistrictId}`) as Observable<ISchoolDistrictDto>;
  }

  public createSchoolDistrict(params: IInstitutionSystemManagerParams & { schoolDistrictCreateDto: ISchoolDistrictCreateDto }) {
    this.store.dispatch(SystemManagerSchoolsActions.SystemManagerCreateDistrictAction(params));
    return this.dispatcher.pipe(
      ofType(SystemManagerSchoolsActions.SystemManagerCreateDistrictSuccessAction, SystemManagerSchoolsActions.SystemManagerCreateDistrictErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerSchoolsActions.SystemManagerCreateDistrictSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public createSchoolDistrictEffect({
    institutionId,
    systemManagerId,
    schoolDistrictCreateDto
  }: IInstitutionSystemManagerParams & { schoolDistrictCreateDto: ISchoolDistrictCreateDto }) {
    return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/schools/districts`, schoolDistrictCreateDto,
      { observe: 'response' });
  }

  public updateSchoolDistrict(params: IInstitutionSystemManagerParams & { schoolDistrictId: string, schoolDistrictUpdateDto: ISchoolDistrictUpdateDto }) {
    this.store.dispatch(SystemManagerSchoolsActions.SystemManagerUpdateDistrictAction(params));
    return this.dispatcher.pipe(
      ofType(SystemManagerSchoolsActions.SystemManagerUpdateDistrictSuccessAction, SystemManagerSchoolsActions.SystemManagerUpdateDistrictErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerSchoolsActions.SystemManagerUpdateDistrictSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateSchoolDistrictEffect({
    institutionId,
    systemManagerId,
    schoolDistrictId,
    schoolDistrictUpdateDto
  }: IInstitutionSystemManagerParams & { schoolDistrictId: string, schoolDistrictUpdateDto: ISchoolDistrictUpdateDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/schools/districts/${schoolDistrictId}`, schoolDistrictUpdateDto);
  }

  public deleteSchoolDistrict(params: IInstitutionSystemManagerParams & { schoolDistrictId: string }) {
    this.store.dispatch(SystemManagerSchoolsActions.SystemManagerDeleteDistrictAction(params));
    return this.dispatcher.pipe(
      ofType(SystemManagerSchoolsActions.SystemManagerDeleteDistrictSuccessAction, SystemManagerSchoolsActions.SystemManagerDeleteDistrictErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerSchoolsActions.SystemManagerDeleteDistrictSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteSchoolDistrictEffect({
    institutionId,
    systemManagerId,
    schoolDistrictId
  }: IInstitutionSystemManagerParams & { schoolDistrictId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/schools/districts/${schoolDistrictId}`);
  }
}
