import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ePaymentGatewayType, IGatewayConfigurationDto, ITokenizedCardDto } from 'app/core/models';
import { DynamicExternalJavascriptService } from 'app/core/services';
import { luhnChecksum } from 'app/shared/utils';
import { combineLatest, map, Observable } from 'rxjs';

declare var LitlePayPage: any;

@Component({
  selector: 'ng4h-vantiv-credit-card-input',
  templateUrl: './vantiv-credit-card-input.component.html',
  styleUrls: ['./vantiv-credit-card-input.component.scss']
})
export class VantivCreditCardInputComponent implements OnInit, OnDestroy {

  @Input() gatewayConfig: IGatewayConfigurationDto;

  @Output() tokenizationSuccess: EventEmitter<ITokenizedCardDto>;

  public cardExpirationYear: number[];

  public paymentScriptsLoaded$: Observable<boolean>;
  public message = '';
  public buttonText = 'Add Credit Card';
  public buttonDisabled = false;
  public ready$: Observable<boolean>;

  constructor(
    private cd: ChangeDetectorRef,
    private dynamicExternalJavascriptService: DynamicExternalJavascriptService
  ) {
    this.cardExpirationYear = Array(10).fill((new Date).getFullYear(), 0, 10).map((x, i) => x + i);
    this.tokenizationSuccess = new EventEmitter();
  }

  ngOnInit() {
    const scripts$ = this.dynamicExternalJavascriptService.loadScripts(['https://ajax.googleapis.com/ajax/libs/jquery/2.2.4/jquery.min.js', this.gatewayConfig.tokenizationJavaScriptLibraryUrl]);
    this.ready$ = combineLatest(scripts$).pipe(
      map((loaded) => {
        return loaded.every(load => load === true);
      }),
    );

    window.my = {
      vantivCreditCardTokenizer: {
        errorCallback: this.errorCallback.bind(this),
        successCallback: this.successCallback.bind(this),
        timeoutCallback: this.timeoutCallback.bind(this)
      }
    };

  }

  ngOnDestroy() {
    window.my.vantivCreditCardTokenizer = null;
  }

  public tokenizeCardAccount(e) {
    e.preventDefault();

    // if (this.billingAddress == null) {
    //   this.message = 'Invalid billing address';
    //   return;
    // }

    if (document.getElementById('regmax-vantiv-cardholdername')['value'].length < 1) {
      this.message = 'You must add a name';
      return;
    }

    const expirationMonth = parseInt(document.getElementById('regmax-vantiv-cardexpirationmonth')['value'], 10); // 1-12
    const expirationYear = parseInt(document.getElementById('regmax-vantiv-cardexpirationyear')['value'], 10);

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // getMonth is 0-11
    const currentYear = currentDate.getFullYear();

    if (expirationYear < currentYear) {
      this.message = 'Credit Card is expired';
      return;
    } else if (expirationYear === currentYear && expirationMonth < currentMonth) {
      this.message = 'Credit Card is expired';
      return;
    } else if (expirationYear === currentYear && (expirationMonth === currentMonth || (expirationMonth - 1) === currentMonth)) {
      this.message = 'Credit Card will expire soon and will not be processed until approval, this may take up to 30 days. Please choose another card.';
      return;
    }

    const creditCardNumber = document.getElementsByClassName('regmax-vantiv-cardnumber')[0]['value'];

    const isAmex = this.isAMEX(creditCardNumber);
    const isDisc = this.isDISC(creditCardNumber);
    const isMc = this.isMC(creditCardNumber);
    const isVisa = this.isVISA(creditCardNumber);
    if (!isAmex && !isDisc && !isMc && !isVisa) {
      this.message = 'Card number is unrecognized, please check the number and try again.';
      return;
    } else if (isAmex && !this.gatewayConfig.amexEnabled) {
      this.message = 'American Express is not accepted.';
      return;
    } else if (isDisc && !this.gatewayConfig.discEnabled) {
      this.message = 'Discover is not accepted.';
      return;
    } else if (isMc && !this.gatewayConfig.mcEnabled) {
      this.message = 'MasterCard is not accepted.';
      return;
    } else if (isVisa && !this.gatewayConfig.visaEnabled) {
      this.message = 'VISA is not accepted.';
      return;
    }

    const litleRequest = {
      'paypageId': this.gatewayConfig.subMerchantPayPageId,
      'reportGroup': '4HOnlineV2AddCard',
      'orderId': this.gatewayConfig.generatedVaultIdsForCreditCardTokenizer.orderId,
      'id': this.gatewayConfig.generatedVaultIdsForCreditCardTokenizer.requestId,
      // 'url': gateway.tokenizationRequestSubmissionUrl
    };
    const formFields = {
      'accountNum': document.getElementsByClassName('regmax-vantiv-cardnumber')[0],
      'cvv2': document.getElementsByClassName('regmax-vantiv-cvv')[0],
      'paypageRegistrationId': document.getElementById('response_paypageRegistrationId'),
      'bin': document.getElementById('response_bin')
    };

    this.message = '';
    this.buttonText = 'Please wait ...';
    this.buttonDisabled = true;

    const success = function (response) {
      if (window.my.vantivCreditCardTokenizer) {
        window.my.vantivCreditCardTokenizer.successCallback(response);
      }
    };
    const error = function (response) {
      if (window.my.vantivCreditCardTokenizer) {
        window.my.vantivCreditCardTokenizer.errorCallback(response);
      }
    };
    const timeout = function (response) {
      if (window.my.vantivCreditCardTokenizer) {
        window.my.vantivCreditCardTokenizer.timeoutCallback(response);
      }
    };

    // run this async so as to not block the UI while we wait for a response from Vantiv
    new LitlePayPage().sendToLitle(
      litleRequest,
      formFields,
      success,
      error,
      timeout,
      10000);

    this.sendingToLitle();
  }

  private sendingToLitle() {

  }

  private successCallback(response) {
    this.buttonDisabled = false;
    this.buttonText = 'Add Credit Card';
    if (response.response === '870') { // 870 == Success
      const card: ITokenizedCardDto = {
        gateway: ePaymentGatewayType.Vantiv,
        cardBrand: response.type,
        vaultTokenRegistrationId: response.paypageRegistrationId,
        cardholderName: this.escapeQuotes(document.getElementById('regmax-vantiv-cardholdername')['value']),
        expirationMonth: parseInt(document.getElementById('regmax-vantiv-cardexpirationmonth')['value'], 10),
        expirationYear: parseInt(document.getElementById('regmax-vantiv-cardexpirationyear')['value'], 10),
        address: null
      };
      this.tokenizationSuccess.emit(card);
    } else {
      console.error('Something went wrong with successCallback.');
      console.error(response);
    }
    this.cd.detectChanges();
  }
  private errorCallback(response) {
    this.buttonDisabled = false;
    this.buttonText = 'Add Credit Card';
    switch (response.response) {
      case '871':
      case '872':
      case '873':
      case '874':
      case '876':
        this.message = 'Invalid card number. Check and retry.';
        break;
      case '875':
        this.message = 'We are experiencing technical difficulties. Please try again later.';
        break;
      case '881':
      case '882':
      case '883':
        this.message = 'Invalid card validation code. Check and retry.';
        break;
      case '889':
        this.message = 'Unknown error.';
        break;
      default:
        console.error(response);
        this.buttonText = 'Try again';
        break;
    }
    this.cd.detectChanges();
  }
  private timeoutCallback() {
    // The Vantiv API is timing out
    this.buttonDisabled = false;
    this.buttonText = 'Add Credit Card';
    this.message = 'We are experiencing technical difficulties (timeout). Please try again later.';
    this.cd.detectChanges();
  }

  // o’reilly, o’brien etc.
  private escapeQuotes(v) {
    if (typeof v === 'string') {
      return v.toString().replace(/'/g, '\\u0027').replace(/"/g, '\\u0022');
    } else {
      return v;
    }
  }
  private isAMEX(number) {
    return this.isCreditCard('AMEX', number);
  }

  private isDISC(number) {
    return this.isCreditCard('DISC', number);
  }

  private isMC(number) {
    return this.isCreditCard('MC', number);
  }

  private isVISA(number) {
    return this.isCreditCard('VISA', number);
  }

  private isCreditCard(cardType: string, creditCardNumber: string): boolean {
    let status = false;
    creditCardNumber = creditCardNumber.trim();
    if (creditCardNumber.length > 12) {
      if (creditCardNumber.search(' ') > 0) {
        creditCardNumber = creditCardNumber.split(' ').join('');
      }
      if (creditCardNumber.search('-') > 0) {
        creditCardNumber = creditCardNumber.split('-').join('');
      }
      switch (cardType) {
        case 'VISA':
          if (creditCardNumber.substr(0, 1) === '4') {
            status = luhnChecksum(creditCardNumber);
          }
          break;
        case 'MC':
          if (creditCardNumber.substr(0, 1) === '5') {
            status = luhnChecksum(creditCardNumber);
          }
          break;
        case 'DISC':
          if (creditCardNumber.substr(0, 1) === '6') {
            status = luhnChecksum(creditCardNumber);
          }
          break;
        case 'AMEX':
          if (creditCardNumber.substr(0, 2) === '37') {
            status = luhnChecksum(creditCardNumber);
          }
          break;
        default:
          break;
      }
    }
    return status;
  }


}


