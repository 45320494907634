import { IMemberSearchParametersDto } from './IMemberSearchParametersDto';
import { IMemberSearchResultDto } from './IMemberSearchResultDto';

export interface IMemberSearchResultsDto {
  resultsCount: number;
  pageSize: number;
  pageIndex: number;
  pageCount: number;
  startingResultRangeRecordIndex: number;
  endingResultRangeRecordIndex: number;
  searchParameters: IMemberSearchParametersDto;
  results: IMemberSearchResultDto[];
}
