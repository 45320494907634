<ng4h-dynamic-options-root [formDisplayTypes]="formDisplayTypes" [control]="control"
  (rootInputModified)="controlModified.emit($event)" (validatorAddOrModify)="validatorAddOrModify.emit($event)"
  (validatorRemove)="validatorRemove.emit($event)">
  <!-- <div class="form-group row">
    <label class="form-control-label col-md-3">Report Label</label>
    <div class="input-group col-md-9">
      <input class="form-control" type="text" [ngModel]="control.items[0].reportValue"
        (ngModelChange)="reportLabelChange($event, control.items[0].itemId)">
    </div>
  </div> -->
  <div class="form-group row">
    <label class="col-md-3 form-control-label">Label</label>
    <div class="col-md-9">
      <input type="text" class="form-control" [ngModel]="control.items[0].label"
        (ngModelChange)="changeItemLabel(control.items[0].itemId, $event)" aria-label="add-option-to-dropdown"
        aria-describedby="trash">
    </div>
  </div>
  <div class="form-group row" *ngIf="formType == eFormType.Registration">
    <label class="form-control-label col-md-3">Fee</label>
    <div class="col-md-9">

      <input class="form-control" type="number" [ngModel]="control.items[0].rate" step="0.01"
        (ngModelChange)="changeItemRate(control.items[0].itemId,$event)">
    </div>
  </div>
</ng4h-dynamic-options-root>