import { Observable } from 'rxjs';


export function stringKeys<T>(payload: T): string {
    return Object.keys(payload).map(key => {
        return payload[key];
    }).reduce((a, b) => {
        if (typeof b === 'string') {
            return a + b;
        }
        return a;
    }, '');
}



export const filterArrayOfNulls = () => (source: Observable<any>) =>
    new Observable(observer => {
        return source.subscribe({
            next(array: any[]) {
                if (Array.isArray(array)) {
                    if (array.every(entry => entry != null)) {
                        observer.next(array);
                    }
                }
            },
            error(err) { observer.error(err); },
            complete() { observer.complete(); }
        });
    });
