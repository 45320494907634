import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { IEnrollmentAnimalDto } from 'app/core/models';

@Component({
  selector: 'ng4h-manager-animal-card',
  templateUrl: './manager-animal-card.component.html',
  styleUrls: ['./manager-animal-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManagerAnimalCardComponent implements OnInit, OnChanges {


  @Input() animal: IEnrollmentAnimalDto;


  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.animal && changes.animal.currentValue != null) {
      this.animal = changes.animal.currentValue;
    }
  }
}
