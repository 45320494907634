import { Action, createReducer, on } from '@ngrx/store';
import { IEnrollmentFileControlDto } from 'app/core/models';
import { mergeImmutable } from 'app/shared/utils';

import { SystemManagerCustomFilesActions } from '.';

export interface SystemManagerCustomFilesState {
  // TODO: update custom file type when known
  customFiles: { [institutionId: string]: { [systemManagerId: string]: { [programId: string]: IEnrollmentFileControlDto[] } } };
}

const initialState: SystemManagerCustomFilesState = {
  customFiles: undefined
};

const reducer = createReducer(
  initialState,
  on(SystemManagerCustomFilesActions.SystemManagerGetEnrollmentCustomFilesSuccessAction, (state, payload) => {
    return {
      ...state,
      customFiles: mergeImmutable(
        { [payload.institutionId]: { [payload.systemManagerId]: { [payload.programId]: payload.customFiles } } },
        state.customFiles
      )
    };
  }),
  on(SystemManagerCustomFilesActions.SystemManagerCreateEnrollmentCustomFileSuccessAction,
    SystemManagerCustomFilesActions.SystemManagerReorderEnrollmentCustomFilesSuccessAction,
    SystemManagerCustomFilesActions.SystemManagerUpdateEnrollmentCustomFileSuccessAction,
    SystemManagerCustomFilesActions.SystemManagerTemplateFileUploadSuccessAction,
    SystemManagerCustomFilesActions.SystemManagerTemplateFileDeleteSuccessAction,
    SystemManagerCustomFilesActions.SystemManagerCustomFileDeleteSuccessAction,
    (state, payload) => {
      return {
        ...state,
        customFiles: mergeImmutable(
          { [payload.institutionId]: { [payload.systemManagerId]: { [payload.programId]: undefined } } },
          state.customFiles
        )
      };
    }),
);

export function systemManagerCustomFilesReducer(state: SystemManagerCustomFilesState | undefined, action: Action) {
  return reducer(state, action);
}

export const customFiles = (state: SystemManagerCustomFilesState) => state.customFiles;
