<div class="modal-section" *ngIf="consent">
  <div class="modal-header">
    <h5 class="modal-title">Consent - {{consent.consentName}}</h5>
    <button (click)="close()" type="button" class="close" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ng4h-admin-consent [consent]="consent" [language]="eLocalizationType.US_English"></ng4h-admin-consent>
  </div>

  <div class="modal-footer" *ngIf="allowDelete">
    <button (click)="delete()" class="btn btn-danger my-2 my-sm-0" role="button"
      aria-pressed="true">Delete</button>
  </div>
</div>
