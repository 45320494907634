<form [formGroup]="form" class="d-flex flex-row">
  <div class="m-2">
    <select class="form-control" formControlName="column">
      <option [value]="column" *ngFor="let column of columns"> {{ column.Label }} </option>
    </select>
  </div>

  <div class="m-2 align-self-center">
    <mat-radio-group formControlName="order">
      <mat-radio-button value="asc">Ascending</mat-radio-button>
      <mat-radio-button value="des">Descending</mat-radio-button>
    </mat-radio-group>
  </div>

  <div class="ml-auto align-self-center">
    <button type="button" class="btn btn-danger btn-sm" (click)="onDelete()">Delete</button>
  </div>
</form>
