import { IAddressDto, ICountyDescriptorDto, IHierarchyNodeDto } from '.';
import { eProfileType } from '..';

export interface IFamilyDescriptorDto {
    familyId: string;
    familyName?: string;
    addressVerified: boolean;
    requireCountySelection: boolean;
    institutionName?: string;
    countyArea: IHierarchyNodeDto;
    county: ICountyDescriptorDto;
    address: IAddressDto;
    familyIdent: number;
    profileId: string;
    profileType: eProfileType;
    email?: string;
    institutionId: string;
    institutionProfileId: string;
    profileToken: string;
    sitePassword?: string;
    institutionIdent: number;
    profileIdent: number;
    institutionProfileIdent: number;
}
