import { DomPortal, Portal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ng4h-modal-fullscreen',
  templateUrl: './modal-fullscreen.component.html',
  styleUrls: ['./modal-fullscreen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalFullscreenComponent implements OnInit {

  selectedPortal: Portal<any>;

  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: HTMLDivElement
  ) { }

  ngOnInit(): void {
    this.selectedPortal = new DomPortal(this.data);
  }

  public close() {
    this.dialogRef.close(false);
  }
}
