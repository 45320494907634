import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  IInstitutionProgramParams,
  IInstitutionSystemManagerProgramParams,
  IProgramSettingsSuperUserUpdateDto,
} from 'app/core/models';
import { IInstitutionSuperuserProgramParams } from 'app/core/models/function-parameters/institution-superuser-program';
import { IProgramSettingsUpdateDto } from 'app/core/models/serverDTOs/IProgramSettingsUpdateDto';

export const ProgramSettingsLoadAction = createAction(
    '[program-settings] Load',
    props<IInstitutionProgramParams>()
);
export const ProgramSettingsLoadSuccessAction = createAction(
    '[program-settings] Load success',
    props<IInstitutionProgramParams & { settings: any }>()
);

export const ProgramSettingsLoadErrorAction = createAction(
    '[program-settings] Load error',
    props<{ error: HttpErrorResponse }>()
);
export const ProgramSettingsInvalidateCacheAction = createAction(
    '[program-settings] Invalidate cache',
    props<IInstitutionProgramParams>()
);

export const ProgramSettingsSystemManagerUpdateAction = createAction(
    '[program-settings] Update system manager',
    props<IInstitutionSystemManagerProgramParams & { updatedProgramSettings: IProgramSettingsUpdateDto }>()
);
export const ProgramSettingsSystemManagerUpdateSuccessAction = createAction(
    '[program-settings] Update system manager success',
    props<IInstitutionSystemManagerProgramParams & { updatedProgramSettings: IProgramSettingsUpdateDto }>()
);
export const ProgramSettingsSystemManagerUpdateErrorAction = createAction(
    '[program-settings] Update system manager error',
    props<{ error: HttpErrorResponse }>()
);

export const ProgramSettingsSuperuserUpdateAction = createAction(
    '[program-settings] Update Superuser',
    props<IInstitutionSuperuserProgramParams & { updatedProgramSettings: IProgramSettingsSuperUserUpdateDto }>()
);
export const ProgramSettingsSuperuserUpdateSuccessAction = createAction(
    '[program-settings] Update Superuser success',
    props<IInstitutionSuperuserProgramParams & { updatedProgramSettings: IProgramSettingsSuperUserUpdateDto }>()
);
export const ProgramSettingsSuperuserUpdateErrorAction = createAction(
    '[program-settings] Update Superuser error',
    props<{ error: HttpErrorResponse }>()
);
