import { IHierarchyNodeDto } from '.';

export interface IHierarchySummaryDto {
    hierarchyNodeId: string;
    hierarchyNodePrimary?: boolean;
    enrollmentsIncomplete: number;
    enrollmentsSubmitted: number;
    enrollmentsApproved: number;
    enrollmentsResubmit: number;
    enrollmentsRejectAndLocked: number;
    eventRegistrationsToReview: number;
    screeningsToReview: number;
    sitePassword: string;
    cloverBuds: IHierarchyEnrollmentRoleSummaryDto;
    clubMembers: IHierarchyEnrollmentRoleSummaryDto;
    volunteers: IHierarchyEnrollmentRoleSummaryDto;
    registrations: IHierarchySummaryRegistrationSummaryDto[];
}

export interface IHierarchyEnrollmentRoleSummaryDto {
  enrollmentsIncomplete: number;
  enrollmentsSubmitted: number;
  enrollmentsApproved: number;
  enrollmentsResubmit: number;
  enrollmentsRejectAndLocked: number;
}

export interface IHierarchySummaryRegistrationSummaryDto {
  eventId: string;
  eventName: string;
  count: number;
}
