import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { DEFAULT_CURRENCY_CODE, ErrorHandler, Injectable, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MemberHealthFormEffects } from 'app/shared/member-health-form/member-health-form.effects';
import { PaymentsEffects } from 'app/shared/payments/payments.effects';
import { environment } from 'environments/environment';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { ToastContainerDirective, ToastrModule } from 'ngx-toastr';
import { StripeModule } from 'stripe-angular';

import { AppComponent } from './app.component';
import { APP_INITIAL_STATE, metaReducers, reducers } from './app.reducers';
import { appRoutes } from './app.routes';
import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { SharedComponentModule } from './core/components/shared-component.module';
import { EnrollmentEffects } from './core/containers/member/enrollment/enrollment.effects';
import { MemberEffects } from './core/containers/member/member.effects';
import { MemberAnimalsEffects } from './core/containers/member/store/animals/member-animals.effects';
import { AddressVerificationEffects } from './core/containers/user/address-verification/address-verification.effects';
import { ProfileEffects } from './core/containers/user/profile.effects';
import { UserEffects } from './core/containers/user/user.effects';
import { Ng4hHttpInterceptor } from './core/services/http-interceptor';
import { PrimeNGModule } from './primeng.module';
import { ConsentsEffects } from './shared/consents/consents.effects';
import { CountyAreasEffects } from './shared/county-areas/county-areas.effects';
import { CreditCardEffects } from './shared/credit-card/credit-card.effects';
import { DeliveryModeEffects } from './shared/delivery-mode/delivery-mode.effects';
import { DistrictEffects } from './shared/district/district.effects';
import { DocumentationEffects } from './shared/documentation/documentation.effects';
import { EmailTemplateEffect } from './shared/email-template/email-template.effects';
import { AppErrorHandler } from './shared/error/app-error-handler';
import { ErrorEffects } from './shared/error/error.effects';
import { EventCustomFilesEffects } from './shared/event-custom-files/event-custom-files.effects';
import { EventsManagerEffects } from './shared/events-manager/events-manager.effects';
import { EventsMemberEffects } from './shared/events-member/events-member.effects';
import { HierarchyEffects } from './shared/hierarchy/hierarchy.effects';
import { InstitutionsEffects } from './shared/institution/institution.effects';
import { PageMessageEffects } from './shared/page-message/page-message.effects';
import { ProgramSettingsEffects } from './shared/program-settings/program-settings.effects';
import { ProgramYearEffects } from './shared/program-year/program-year.effects';
import { ProgramEffects } from './shared/program/program.effects';
import { ProjectsEffects } from './shared/projects/projects.effects';
import { SchoolEffects } from './shared/school/school.effects';
import { MemberScreeningsEffects } from './shared/screenings/screenings.effects';
import { SystemManagerEffects } from './shared/system-manager/system-manager.effects';
import { TicketingEffects } from './shared/ticketing/ticketing.effects';
import { ToastrEffects } from './shared/toastr/toastr.effects';
import { UnitEffects } from './shared/units/units.effects';
import { CustomRouterStateSerializer } from './shared/utils';



// import { ModalModule } from 'ngx-bootstrap';

// import { TrainingsEffects } from './shared/trainings/trainings.effects';
const ngrxEffects = [
  ProgramSettingsEffects,
  ErrorEffects,
  UserEffects,
  ProfileEffects,
  EnrollmentEffects,
  MemberEffects,
  InstitutionsEffects,
  HierarchyEffects,
  UnitEffects,
  ProjectsEffects,
  CreditCardEffects,
  ProgramYearEffects,
  CountyAreasEffects,
  PaymentsEffects,
  MemberHealthFormEffects,
  // TrainingsEffects,
  ConsentsEffects,
  DocumentationEffects,
  SchoolEffects,
  EventsManagerEffects,
  EventsMemberEffects,
  EmailTemplateEffect,
  PageMessageEffects,
  SystemManagerEffects,
  DistrictEffects,
  ToastrEffects,
  EventCustomFilesEffects,
  AddressVerificationEffects,
  ProgramEffects,
  DeliveryModeEffects,
  MemberScreeningsEffects,
  MemberAnimalsEffects,
  TicketingEffects
];

@Injectable()
export class HammerConfig extends HammerGestureConfig {
  overrides = {
    pan: {
      direction: 6
    },
    pinch: {
      enable: false
    },
    rotate: {
      enable: false
    }
  };
}
@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(appRoutes, { useHash: true }),
    // ModalModule.forRoot(),
    MatDialogModule,

    ToastrModule.forRoot({
      preventDuplicates: true,
      positionClass: 'toast-bottom-right'
    }),
    ToastContainerDirective,

    StoreModule.forRoot(reducers, {
      metaReducers,
      initialState: APP_INITIAL_STATE,
      // These checks are all opt-in and will automatically be disabled in production builds.
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        // strictStateSerializability: true,
        // strictActionSerializability: true,
      }
    }),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomRouterStateSerializer,
      stateKey: 'router' // name of reducer key
    }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot(ngrxEffects),

    NgProgressModule,
    NgProgressHttpModule,
    // NgProgressRouterModule,
    SharedComponentModule,
    StripeModule.forRoot(''),
    PrimeNGModule,
    RecaptchaV3Module,

  ],
  providers: [
    { provide: ErrorHandler, useClass: AppErrorHandler },
    // { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Ng4hHttpInterceptor,
      multi: true
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig
    },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'USD' },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LeT898mAAAAAA1PiofIG20I_HZhQJEQCuPrrc5H' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }


