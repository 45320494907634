import { IClubCharterDto, IProgramYearDto } from '.';
import { eUnitStatus } from '..';

export interface IUnitProgramYearDto {
  unitProgramYearId: string;
  programYearId: string;
  programYear: IProgramYearDto;
  status: eUnitStatus;
  charterInfo: IClubCharterDto;
  deliveryModeId: string;
  maximumEnrollments?: number;
  allowMembersToRegister: boolean;
  allowMembersToSeeTheirRegistration: boolean;
}
