import { IMemberConsentSubmitDto } from '.';

export interface IEnrollmentProjectSubmissionDto {
  unitId: string;
  projectId: string;
  yearsInProject?: number;
  volunteerTypeId: string;
  countyAreaId: string;
  consents?: IMemberConsentSubmitDto[];
}
