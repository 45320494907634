import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import {
    getManagerBroadcastEmailById,
    getManagerBroadcastEmails,
    getManagerBroadcastNavigation,
    ManagerFeatureStore
} from 'app/core/containers/admin/manager/manager-feature.reducer';
import { ManagerBroadcastActions } from 'app/core/containers/admin/manager/store/broadcast';
import {
    IBroadcastEmailCreateDto,
    IBroadcastEmailDto,
    IBroadcastEmailQueueDepthDto,
    IBroadcastEmailRecipientDto,
    IBroadcastEmailUpdateDto,
    IBroadcastTargetEmailDto, IInstitutionManagerProgramHierarchyParams,
    IInstitutionManagerProgramParams,
    IInstitutionProgramParams,
    ISearchResult
} from 'app/core/models';
import { downloadFile, downloadFileWithBody } from 'app/shared/utils';
import { filter, map, Observable, of, switchMap, take, tap } from 'rxjs';

import { CommonToastrService } from '../../common-toastr.service';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ManagerBroadcastService {

    // The wrapper is also duplicated on the server so we don't have to save it every time.
    // If you want to update it, don't forget to update

    public wrapperStart = `<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office" >
   <head>
    <title>Email template</title>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0 " />
    <meta name="format-detection" content="telephone=no" />
     <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800" rel="stylesheet" />
     <style type="text/css">  </style>
     <style type="text/css"> body { margin: 0 !important; padding: 0 !important; -webkit-text-size-adjust: 100% !important; -ms-text-size-adjust: 100% !important; -webkit-font-smoothing: antialiased !important; } img { border: 0 !important; outline: none !important; } p { margin: 0px !important; padding: 0px !important; } table { border-collapse: collapse; mso-table-lspace: 0px; mso-table-rspace: 0px; } td, a, span { border-collapse: collapse; mso-line-height-rule: exactly; } .ExternalClass * { line-height: 100%; } .em_defaultlink a { color: inherit !important; text-decoration: none !important; } span.MsoHyperlink { mso-style-priority: 99; color: inherit; } span.MsoHyperlinkFollowed { mso-style-priority: 99; color: inherit; } @media only screen and (min-width: 481px) and (max-width: 699px) { .em_main_table { width: 100% !important; } .em_wrapper { width: 100% !important; } .em_hide { display: none !important; } .em_img { width: 100% !important; height: auto !important; } .em_h20 { height: 20px !important; } .em_padd { padding: 20px 10px !important; } } @media screen and (max-width: 480px) { .em_main_table { width: 100% !important; } .em_wrapper { width: 100% !important; } .em_hide { display: none !important; } .em_img { width: 100% !important; height: auto !important; } .em_h20 { height: 20px !important; } .em_padd { padding: 20px 10px !important; } .em_text1 { font-size: 16px !important; line-height: 24px !important; } u + .em_body .em_full_wrap { width: 100% !important; width: 100vw !important; } } </style>
     </head> <body class="em_body" style="margin:0px; padding:0px;" bgcolor="#efefef">
     <table class="em_full_wrap" valign="top" width="100%" cellspacing="0" cellpadding="0" border="0" bgcolor="#efefef" align="center" > <tbody> <tr> <td valign="top" align="center" style="padding: 50px"> <table class="em_main_table" style="width:700px;" width="700" cellspacing="0" cellpadding="0" border="0" align="center" > <!--Header section--> <tbody> <tr> <td style="padding:15px;" class="em_padd" valign="top" bgcolor="#ffffff" align="center" > <table width="100%" cellspacing="0" cellpadding="0" border="0" align="center" > <tbody> <tr> <td style="font-family:Arial, sans-serif; font-size:12px; line-height:15px; color:#0d1121;" valign="top" align="center" > Test Email Sample | <a href="#" target="_blank" style="color:#0d1121; text-decoration:underline;" >View Online</a > </td> </tr> </tbody> </table> </td> </tr> <!--//Header section--> <!--Banner section--> <tr> <td valign="top" align="center"> <table width="100%" cellspacing="0" cellpadding="0" border="0" align="center" > <tbody> <tr></tr> </tbody> </table> </td> </tr> <!--//Banner section--> <!--Content Text Section--> <tr> <td style="padding:35px 70px 30px;" class="em_padd" valign="top" bgcolor="#ffffff" align="center" > <table style="border-spacing:0;border-collapse:collapse;vertical-align:top;text-align:left;width:580px;margin:0 auto;padding:0" class="m_-=4996886940149489571twelve m_-4996886940149489571columns" >

     <tbody> <tr align="left" style="vertical-align:top;text-align:l=eft;padding:0" >
     <td valign="top" align="left" style="word-break:break-word;border-collap=se:collapse!important;vertical-align:top;text-align:left;color:#666;font-fa=mily:&#39;Helvetica&#39;,&#39;Arial&#39;,sans-serif;font-weight:normal;line=-height:25px;font-size:16px;margin:0;padding:0px 30px 10px" class="m_-499=6886940149489571text-pad" >
     <div bgcolor="#fff" style="background-color: #ffffff" >
     `;

    public wrapperEnd = `
  </div>
  </td>
   <td valign="top" align="left" style="word-break:break-word;border-collapse:collapse!important;vertical-align:top;text-align:left;width:0=px;color:#666;font-family:&#39;Helvetica&#39;,&#39;Arial&#39;,sans-serif;fo=nt-weight:normal;line-height:25px;font-size:16px;margin:0;padding:0" class="m_-4996886940149489571expander" ></td>
   </tr>
    </tbody>
    </table>
    </td> </tr>
     <tr>
     <td style="padding:38px 30px;" class="em_padd" valign="top" bgcolor="#f6f7f8" align="center" > <table width="100%" cellspacing="0" cellpadding="0" border="0" align="center" >
     <tbody> <tr> <td style="padding-bottom:16px;" valign="top" align="center" >
      <table cellspacing="0" cellpadding="0" border="0" align="center" >
       <tbody>
        <tr>
           </tr>
           </tbody>
          </table>
           </td>
           </tr>
           <tr>
            <td style="font-family:Arial, sans-serif; font-size:11px; line-height:18px; color:#999999;" valign="top" align="center" >
          <a href="#" target="_blank" style="color:#999999; text-decoration:underline;" >PRIVACY STATEMENT</a > | <a href="#" target="_blank" style="color:#999999; text-decoration:underline;" >TERMS OF SERVICE</a > | <a href="#" target="_blank" style="color:#999999; text-decoration:underline;" >RETURNS</a ><br /> © 2017 Companyname. All Rights Reserved.<br /> If you do not wish to receive any further emails from us, please <a href="#" target="_blank" style="text-decoration:none; color:#999999;" >unsubscribe</a >
          </td>
          </tr>
          </tbody>
           </table> </td> </tr> <tr>
            <td class="em_hide" style="line-height:1px;min-width:700px;background-color:#ffffff;" > <img alt="" src="" style="max-height:1px; min-height:1px; display:block; width:700px; min-width:700px;" width="700" border="0" height="1" /> </td> </tr> </tbody> </table> </td> </tr>
            </tbody> </table> <div class="em_hide" style="white-space: nowrap; display: none; font-size:0px; line-height:0px;" > &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </div> </body> </html>`;

    constructor(private store: Store<ManagerFeatureStore>,
        private httpClient: HttpClient,
        private dispatcher: ActionsSubject,
        private toastrService: CommonToastrService
    ) {
    }

    public navigateBroadcast(page: string, id: string, name: string) {
        this.store.dispatch(ManagerBroadcastActions.ManagerNavigateBroadcastAction({ page, id, name }));
    }

    public getNavigation() {
        return this.store.select(getManagerBroadcastNavigation).pipe(take(1));
    }

    public getBroadcastEmails(params: IInstitutionManagerProgramParams): Observable<IBroadcastEmailDto[]> {
        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }

        return this.store.pipe(
            select(getManagerBroadcastEmails(params)),
            tap(emails => {
                if (emails === undefined) {
                    this.store.dispatch(ManagerBroadcastActions.ManagerGetBroadcastAction(params));
                }
            })
        );
    }

    public loadBroadcastEmailsEffect({
        institutionId,
        managerId,
        programId
    }: IInstitutionManagerProgramParams): Observable<IBroadcastEmailDto[]> {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/broadcast-emails`) as Observable<IBroadcastEmailDto[]>;
    }

    public getBroadcastEmailById(params: IInstitutionManagerProgramParams & { broadcastEmailId: string }) {
        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getManagerBroadcastEmailById(params)),
            tap(emails => {
                if (emails === undefined) {
                    this.store.dispatch(ManagerBroadcastActions.ManagerGetBroadcastByIdAction(params));
                }
            }),
            filter(email => email != null)
        );
    }

    public loadBroadcastEmailByIdEffect({
        institutionId,
        managerId,
        programId,
        broadcastEmailId
    }: IInstitutionManagerProgramParams & { broadcastEmailId: string }): Observable<IBroadcastEmailDto> {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/broadcast-emails/${broadcastEmailId}`) as Observable<IBroadcastEmailDto>;
    }

    public updateBroadcastEmail(params: IInstitutionManagerProgramParams & { broadcastEmailId: string, broadcastEmail: IBroadcastEmailUpdateDto }) {
        this.store.dispatch(ManagerBroadcastActions.ManagerUpdateBroadcastEmailAction(params));

        return this.dispatcher.pipe(
            ofType(ManagerBroadcastActions.ManagerUpdateBroadcastEmailSuccessAction, ManagerBroadcastActions.ManagerUpdateBroadcastEmailErrorAction),
            map(action => {
                if (action.type === ManagerBroadcastActions.ManagerUpdateBroadcastEmailSuccessAction.type) {
                    return action;
                } else {
                    throw action;
                }
            })
        );
    }

    public updateBroadcastEmailEffect({
        institutionId,
        managerId,
        programId,
        broadcastEmailId,
        broadcastEmail
    }: IInstitutionManagerProgramParams & { broadcastEmailId: string, broadcastEmail: IBroadcastEmailUpdateDto }) {
        return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/broadcast-emails/${broadcastEmailId}`, broadcastEmail);
    }

    public createBroadcastEmailClub(params: IInstitutionManagerProgramParams & { unitId: string, broadcastEmail: IBroadcastEmailCreateDto }) {
        this.store.dispatch(ManagerBroadcastActions.ManagerCreateBroadcastEmailClubAction(params));

        return this.dispatcher.pipe(
            ofType(ManagerBroadcastActions.ManagerCreateBroadcastEmailClubSuccessAction, ManagerBroadcastActions.ManagerCreateBroadcastEmailClubErrorAction),
            map(action => {
                if (action.type === ManagerBroadcastActions.ManagerCreateBroadcastEmailClubSuccessAction.type) {
                    return action.newEmail;
                } else {
                    throw action;
                }
            })
        );
    }

    public createBroadcastEmailClubEffect({
        institutionId,
        managerId,
        programId,
        unitId,
        broadcastEmail
    }: IInstitutionManagerProgramParams & { unitId: string, broadcastEmail: IBroadcastEmailCreateDto }): Observable<any> {
        return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/broadcast-emails/${unitId}`, broadcastEmail, { observe: 'response' })
            .pipe(
                switchMap((res: HttpResponse<any>) => this.httpClient.get(res.headers.get('location')))
            );
    }

    public createBroadcastEmailCountyArea(params: IInstitutionManagerProgramParams & { countyAreaId: string, email: IBroadcastEmailCreateDto }) {
        this.store.dispatch(ManagerBroadcastActions.ManagerCreateBroadcastEmailCountyAreaAction(params));

        return this.dispatcher.pipe(
            ofType(ManagerBroadcastActions.ManagerCreateBroadcastEmailCountyAreaSuccessAction, ManagerBroadcastActions.ManagerCreateBroadcastEmailCountyAreaErrorAction),
            map(action => {
                if (action.type === ManagerBroadcastActions.ManagerCreateBroadcastEmailCountyAreaSuccessAction.type) {
                    return action.newEmail;
                } else {
                    throw action;
                }
            })
        );
    }

    public createBroadcastEmailCountyAreaEffect({
        institutionId,
        managerId,
        programId,
        countyAreaId,
        email
    }: IInstitutionManagerProgramParams & { countyAreaId: string, email: IBroadcastEmailCreateDto }) {
        return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/broadcast-emails/${countyAreaId}`, email, { observe: 'response' })
            .pipe(
                switchMap((res: HttpResponse<any>) => this.httpClient.get(res.headers.get('location')))
            ) as Observable<IBroadcastEmailDto>;
    }

    public createBroadcastEmailEvent(params: IInstitutionManagerProgramParams & { eventId: string, email: IBroadcastEmailCreateDto }) {
        this.store.dispatch(ManagerBroadcastActions.ManagerCreateBroadcastEmailEventAction(params));

        return this.dispatcher.pipe(
            ofType(ManagerBroadcastActions.ManagerCreateBroadcastEmailEventSuccessAction, ManagerBroadcastActions.ManagerCreateBroadcastEmailEventErrorAction),
            map(action => {
                if (action.type === ManagerBroadcastActions.ManagerCreateBroadcastEmailEventSuccessAction.type) {
                    return action.newEmail;
                } else {
                    throw action;
                }
            })
        );
    }

    public createBroadcastEmailEventEffect({
        institutionId,
        managerId,
        programId,
        eventId,
        email
    }: IInstitutionManagerProgramParams & { eventId: string, email: IBroadcastEmailCreateDto }) {
        return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/broadcast-emails/${eventId}`, email, { observe: 'response' })
            .pipe(
                switchMap((res: HttpResponse<any>) => this.httpClient.get(res.headers.get('location')))
            ) as Observable<IBroadcastEmailDto>;
    }

    public createBroadcastEmailEventRegistration(params: IInstitutionManagerProgramParams & { eventRegistrationTypeId: string, email: IBroadcastEmailCreateDto }) {
        this.store.dispatch(ManagerBroadcastActions.ManagerCreateBroadcastEmailEventRegistrationAction(params));

        return this.dispatcher.pipe(
            ofType(ManagerBroadcastActions.ManagerCreateBroadcastEmailEventRegistrationSuccessAction, ManagerBroadcastActions.ManagerCreateBroadcastEmailEventRegistrationErrorAction),
            map(action => {
                if (action.type === ManagerBroadcastActions.ManagerCreateBroadcastEmailEventRegistrationSuccessAction.type) {
                    return action.newEmail;
                } else {
                    throw action;
                }
            })
        );
    }

    public createBroadcastEmailEventRegistrationEffect({
        institutionId,
        managerId,
        programId,
        eventRegistrationTypeId,
        email
    }: IInstitutionManagerProgramParams & { eventRegistrationTypeId: string, email: IBroadcastEmailCreateDto }) {
        return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/broadcast-emails/${eventRegistrationTypeId}`, email, { observe: 'response' })
            .pipe(
                switchMap((res: HttpResponse<any>) => this.httpClient.get(res.headers.get('location')))
            ) as Observable<IBroadcastEmailDto>;
    }

    public createBroadcastEmailSearch(params: IInstitutionManagerProgramParams & { searchId: string, email: IBroadcastEmailCreateDto }) {
        this.store.dispatch(ManagerBroadcastActions.ManagerCreateBroadcastEmailSearchAction(params));

        return this.dispatcher.pipe(
            ofType(ManagerBroadcastActions.ManagerCreateBroadcastEmailSearchSuccessAction, ManagerBroadcastActions.ManagerCreateBroadcastEmailSearchSuccessAction),
            map(action => {
                if (action.type === ManagerBroadcastActions.ManagerCreateBroadcastEmailSearchSuccessAction.type) {
                    return action.newEmail;
                } else {
                    throw action;
                }
            })
        );
    }

    public createBroadcastEmailSearchEffect({
        institutionId,
        managerId,
        programId,
        searchId,
        email
    }: IInstitutionManagerProgramParams & { searchId: string, email: IBroadcastEmailCreateDto }) {
        return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/broadcast-emails/${searchId}`, email, { observe: 'response' })
            .pipe(
                switchMap((res: HttpResponse<any>) => this.httpClient.get(res.headers.get('location')))
            ) as Observable<IBroadcastEmailDto>;
    }

    public createBroadcastEmailCustomReports(params: IInstitutionManagerProgramParams & { hierarchyNodeId: string, programYearId: string, customReportId: string, email: IBroadcastEmailCreateDto }) {
        this.store.dispatch(ManagerBroadcastActions.ManagerCreateBroadcastEmailCustomReportsAction(params));

        return this.dispatcher.pipe(
            ofType(ManagerBroadcastActions.ManagerCreateBroadcastEmailCustomReportsSuccessAction, ManagerBroadcastActions.ManagerCreateBroadcastEmailCustomReportsErrorAction),
            map(action => {
                if (action.type === ManagerBroadcastActions.ManagerCreateBroadcastEmailCustomReportsSuccessAction.type) {
                    return action.newEmail;
                } else {
                    throw action;
                }
            })
        );
    }

    public createBroadcastEmailCustomReportsEffect({
        institutionId,
        managerId,
        programId,
        hierarchyNodeId,
        programYearId,
        customReportId,
        email
    }: IInstitutionManagerProgramParams & { hierarchyNodeId: string, programYearId: string, customReportId: string, email: IBroadcastEmailCreateDto }) {
        return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/broadcast-emails/${customReportId}?hierarchyNodeId=${hierarchyNodeId}&programYearId=${programYearId}`, email, { observe: 'response' })
            .pipe(
                switchMap((res: HttpResponse<any>) => this.httpClient.get(res.headers.get('location')))
            ) as Observable<IBroadcastEmailDto>;
    }

    public sendBroadcastEmail(params: IInstitutionManagerProgramParams & { broadcastEmailId: string, hierarchyNodeId: string }) {
        this.store.dispatch(ManagerBroadcastActions.ManagerSendBroadcastEmailAction(params));

        return this.dispatcher.pipe(
            ofType(ManagerBroadcastActions.ManagerSendBroadcastEmailSuccessAction, ManagerBroadcastActions.ManagerSendBroadcastEmailErrorAction),
            map(action => {
                if (action.type === ManagerBroadcastActions.ManagerSendBroadcastEmailSuccessAction.type) {
                    return action;
                } else {
                    throw action;
                }
            })
        );
    }

    public sendBroadcastEmailEffect({
        institutionId,
        managerId,
        programId,
        broadcastEmailId,
        hierarchyNodeId
    }: IInstitutionManagerProgramParams & { broadcastEmailId: string, hierarchyNodeId: string }) {
        return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/broadcast-emails/${broadcastEmailId}/send?hierarchyNodeId=${hierarchyNodeId}`, {});
    }

    // public getQueuedBroadcastRecipients(params: IInstitutionManagerProgramParams & { broadcastEmailId: string, pagesize: string, pageindex: string }) {
    //   this.store.dispatch(ManagerBroadcastActions.GetQueuedBroadcastRecipientsAction(params));
    // }
    public getPendingBroadcastRecipientsEffect({
        institutionId,
        managerId,
        programId,
        broadcastEmailId,
        hierarchyNodeId,
        pagesize,
        pageindex
    }: IInstitutionManagerProgramParams & { broadcastEmailId: string, hierarchyNodeId: string, pagesize: string, pageindex: string }) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/broadcast-emails/${broadcastEmailId}/recipients/pending?hierarchyNodeId=${hierarchyNodeId}&pagesize=${pagesize}&pageindex=${pageindex}`) as Observable<ISearchResult<IBroadcastTargetEmailDto>>;
    }

    public getQueuedBroadcastRecipients(params: IInstitutionManagerProgramParams & { broadcastEmailId: string, pagesize: string, pageindex: string }) {
        this.store.dispatch(ManagerBroadcastActions.GetQueuedBroadcastRecipientsAction(params));
    }

    public getQueuedBroadcastRecipientsEffect({
        institutionId,
        managerId,
        programId,
        broadcastEmailId,
        pagesize,
        pageindex
    }: IInstitutionManagerProgramParams & { broadcastEmailId: string, pagesize: string, pageindex: string }) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/broadcast-emails/${broadcastEmailId}/recipients/queued?pagesize=${pagesize}&pageindex=${pageindex}`) as Observable<ISearchResult<IBroadcastEmailRecipientDto>>;
    }

    public getSentBroadcastRecipients(params: IInstitutionManagerProgramParams & { broadcastEmailId: string, pagesize: string, pageindex: string }) {
        this.store.dispatch(ManagerBroadcastActions.GetSentBroadcastRecipientsAction(params));
    }

    public getSentBroadcastRecipientsEffect({
        institutionId,
        managerId,
        programId,
        broadcastEmailId,
        pagesize,
        pageindex
    }: IInstitutionManagerProgramParams & { broadcastEmailId: string, pagesize: string, pageindex: string }) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/broadcast-emails/${broadcastEmailId}/recipients/sent?pagesize=${pagesize}&pageindex=${pageindex}`) as Observable<ISearchResult<IBroadcastEmailRecipientDto>>;
    }

    public getFailedBroadcastRecipients(params: IInstitutionManagerProgramParams & { broadcastEmailId: string, pagesize: string, pageindex: string }) {
        this.store.dispatch(ManagerBroadcastActions.GetFailedBroadcastRecipientsAction(params));
    }

    public getFailedBroadcastRecipientsEffect({
        institutionId,
        managerId,
        programId,
        broadcastEmailId,
        pagesize,
        pageindex
    }: IInstitutionManagerProgramParams & { broadcastEmailId: string, pagesize: string, pageindex: string }) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/broadcast-emails/${broadcastEmailId}/recipients/failed?pagesize=${pagesize}&pageindex=${pageindex}`) as Observable<ISearchResult<IBroadcastEmailRecipientDto>>;
    }

    public getDeliveredBroadcastRecipients(params: IInstitutionManagerProgramParams & { broadcastEmailId: string, pagesize: string, pageindex: string }) {
        this.store.dispatch(ManagerBroadcastActions.GetDeliveredBroadcastRecipientsAction(params));
    }

    public getDeliveredBroadcastRecipientsEffect({
        institutionId,
        managerId,
        programId,
        broadcastEmailId,
        pagesize,
        pageindex
    }: IInstitutionManagerProgramParams & { broadcastEmailId: string, pagesize: string, pageindex: string }) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/broadcast-emails/${broadcastEmailId}/recipients/delivered?pagesize=${pagesize}&pageindex=${pageindex}`) as Observable<ISearchResult<IBroadcastEmailRecipientDto>>;
    }

    public getQueueDepthEffect({ institutionId, managerId, programId }: IInstitutionManagerProgramParams) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/broadcast-emails/queue-depth`) as Observable<IBroadcastEmailQueueDepthDto>;
    }

    // public getEmailAttachment(params: IInstitutionManagerProgramParams & { broadcastEmailId: string, fileId: string }) {
    //   this.store.dispatch(ManagerBroadcastActions.GetEmailAttachmentAction(params));

    //   return this.dispatcher.pipe(
    //     ofType(ManagerBroadcastActions.GetEmailAttachmentSuccessAction, ManagerBroadcastActions.GetEmailAttachmentErrorAction),
    //     map(action => {
    //       if (action.type === ManagerBroadcastActions.GetEmailAttachmentSuccessAction.type) {
    //         return action;
    //       } else {
    //         throw action;
    //       }
    //     })
    //   );

    // }
    public getEmailAttachmentEffect({
        institutionId,
        managerId,
        programId,
        broadcastEmailId,
        fileId
    }: IInstitutionManagerProgramParams & { broadcastEmailId: string, fileId: string }) {
        downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/broadcast-emails/${broadcastEmailId}/attachments/${fileId}`);
    }

    public uploadEmailAttachment(params: IInstitutionManagerProgramParams & { broadcastEmailId: string, attachment: FormData }) {
        this.store.dispatch(ManagerBroadcastActions.UploadEmailAttachmentAction(params));

        return this.dispatcher.pipe(
            ofType(ManagerBroadcastActions.UploadEmailAttachmentSuccessAction, ManagerBroadcastActions.UploadEmailAttachmentErrorAction),
            map(action => {
                if (action.type === ManagerBroadcastActions.UploadEmailAttachmentSuccessAction.type) {
                    return action;
                } else {
                    throw action;
                }
            })
        );
    }

    public uploadEmailAttachmentEffect({
        institutionId,
        managerId,
        programId,
        broadcastEmailId,
        attachment
    }: IInstitutionManagerProgramParams & { broadcastEmailId: string, attachment: FormData }) {
        return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/broadcast-emails/${broadcastEmailId}/attachments`, attachment) as Observable<any>;
    }

    public deleteEmailAttachment(params: IInstitutionManagerProgramParams & { broadcastEmailId: string, fileId: string }) {
        this.store.dispatch(ManagerBroadcastActions.DeleteEmailAttachmentAction(params));

        return this.dispatcher.pipe(
            ofType(ManagerBroadcastActions.DeleteEmailAttachmentSuccessAction, ManagerBroadcastActions.DeleteEmailAttachmentErrorAction),
            map(action => {
                if (action.type === ManagerBroadcastActions.DeleteEmailAttachmentSuccessAction.type) {
                    return action;
                } else {
                    throw action;
                }
            })
        );
    }

    public deleteEmailAttachmentEffect({
        institutionId,
        managerId,
        programId,
        broadcastEmailId,
        fileId
    }: IInstitutionManagerProgramParams & { broadcastEmailId: string, fileId: string }) {
        return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/broadcast-emails/${broadcastEmailId}/attachments/${fileId}`);
    }

    public deleteEmail(params: IInstitutionManagerProgramParams & { broadcastEmailId: string }) {
        this.store.dispatch(ManagerBroadcastActions.DeleteEmailAction(params));

        return this.dispatcher.pipe(
            ofType(ManagerBroadcastActions.DeleteEmailSuccessAction, ManagerBroadcastActions.DeleteEmailErrorAction),
            map(action => {
                if (action.type === ManagerBroadcastActions.DeleteEmailSuccessAction.type) {
                    return action;
                } else {
                    throw action;
                }
            })
        );
    }

    public deleteEmailEffect({
        institutionId,
        managerId,
        programId,
        broadcastEmailId
    }: IInstitutionManagerProgramParams & { broadcastEmailId: string }) {
        return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/broadcast-emails/${broadcastEmailId}`);
    }

    public sendToSelfEffect({
        institutionId,
        managerId,
        programId,
        broadcastEmailId
    }: IInstitutionManagerProgramParams & { broadcastEmailId: string }) {
        return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/broadcast-emails/${broadcastEmailId}/send-preview-to-self`, {});
    }

    public sendToHierarchyEffect({
        institutionId,
        managerId,
        programId,
        broadcastEmailId,
        hierarchyNodeId
    }: IInstitutionManagerProgramParams & { broadcastEmailId: string, hierarchyNodeId: string }) {
        return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/broadcast-emails/${broadcastEmailId}/send-preview-to-hierarchy/${hierarchyNodeId}`, {});
    }

    public broadcastToManagers(params: IInstitutionManagerProgramParams & { hierarchyNodeId: string, broadcastEmail: IBroadcastEmailCreateDto }) {
        this.store.dispatch(ManagerBroadcastActions.BroadcastToManagersAction(params));

        return this.dispatcher.pipe(
            ofType(ManagerBroadcastActions.BroadcastToManagersSuccessAction, ManagerBroadcastActions.BroadcastToManagersErrorAction),
            map(action => {
                if (action.type === ManagerBroadcastActions.BroadcastToManagersSuccessAction.type) {
                    return action.newEmail;
                } else {
                    throw action;
                }
            })
        );
    }

    public broadcastToManagersEffect({
        institutionId,
        managerId,
        programId,
        hierarchyNodeId,
        broadcastEmail
    }: IInstitutionManagerProgramParams & { hierarchyNodeId: string, broadcastEmail: IBroadcastEmailCreateDto }): Observable<any> {
        return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/broadcast-emails/${hierarchyNodeId}`, broadcastEmail, { observe: 'response' })
            .pipe(
                switchMap((res: HttpResponse<any>) => this.httpClient.get(res.headers.get('location')))
            );
    }

    public downloadEmailUnsubscribedMembers({
        institutionId,
        managerId,
        programId,
        hierarchyNodeId
    }: IInstitutionManagerProgramParams & { hierarchyNodeId: string }) {
        downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/quick-exports/email-unsubscribed/${hierarchyNodeId}`, this.toastrService);
    }

    public uploadEmailImage({ institutionId, programId, image }: IInstitutionProgramParams & { image: FormData }) {
        return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/programs/${programId}/images`, image) as Observable<{ url: string }>;
    }

    public downloadRecipients({
        institutionId,
        managerId,
        programId,
        broadcastEmailId
    }: IInstitutionManagerProgramHierarchyParams & { broadcastEmailId: string, hierarchyNodeId: string }) {
        downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/broadcast-emails/${broadcastEmailId}/recipients/excel`);
    }

    public downloadTargetMembers({
        institutionId,
        managerId,
        programId,
        broadcastEmailId
    }: IInstitutionManagerProgramHierarchyParams & { broadcastEmailId: string, hierarchyNodeId: string }) {
        downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/broadcast-emails/${broadcastEmailId}/target-members/excel`);
    }
}
