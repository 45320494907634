import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { DynamicControl, DynamicItem, DynamicOption, eFormControlItemType, eFormControlType } from 'app/core/models';
import { DynamicFormService } from 'app/core/services';
import { uuidv4 } from 'app/shared/utils';

@Component({
  selector: 'ng4h-modal-dynamic-add-control',
  templateUrl: './modal-dynamic-add-control.component.html',
  styleUrls: ['./modal-dynamic-add-control.component.scss']
})
export class ModalDynamicAddControlComponent implements OnInit {

  public selectedIndex = 0;

  public availableControls: DynamicControl[] = [];
  public availableControlsDisplay: { class: string, name: string, hasRate?: boolean }[] = [];

  public form: FormGroup;
  public inputs = [];

  constructor(private dialogRef: MatDialogRef<any>, private dynamicFormService: DynamicFormService) {

  }

  ngOnInit() {

    Object.keys(eFormControlType).forEach(key => {
      this.inputs.push(eFormControlType[key]);
    });
    this.addInputs();

    this.form = this.dynamicFormService.toFormGroup(this.availableControls);
  }

  public close(dialogResult?: any) {
    this.dialogRef.close(dialogResult);
  }

  public addControl() {
    this.close(this.availableControls[this.selectedIndex]);
  }

  private addInputs() {
    const checkboxControl = new DynamicControl(uuidv4(), eFormControlType.Checkbox);
    checkboxControl.label = 'Check Box';
    const checkboxItem = new DynamicItem(uuidv4(), eFormControlItemType.Checkbox, 'Checkbox Question');
    checkboxControl.items.push(checkboxItem);

    const datePickerControl = new DynamicControl(uuidv4(), eFormControlType.Date);
    datePickerControl.label = 'Date Picker Input';
    datePickerControl.items.push(new DynamicItem(uuidv4(), eFormControlItemType.TextInput));


    const singleChoiceDropdownControl = new DynamicControl(uuidv4(), eFormControlType.SingleChoice);
    singleChoiceDropdownControl.label = 'Dropdown';
    const singleChoiceItem = new DynamicItem(uuidv4(), eFormControlItemType.DropDown);
    singleChoiceItem.options = [
      new DynamicOption(uuidv4(), 'Option 1'),
      new DynamicOption(uuidv4(), 'Option 2'),
      new DynamicOption(uuidv4(), 'Option 3')
    ];
    singleChoiceDropdownControl.items.push(singleChoiceItem);

    const decimalControl = new DynamicControl(uuidv4(), eFormControlType.Decimal);
    decimalControl.label = 'Number Input';
    const decimalItem = new DynamicItem(uuidv4(), eFormControlItemType.TextInput);
    decimalItem.attributes.placeholder = 'Number input placeholder';
    decimalControl.items.push(decimalItem);

    const singleLineTextControl = new DynamicControl(uuidv4(), eFormControlType.SingleLineText);
    singleLineTextControl.label = 'Text Input';
    const singleLineTextItem = new DynamicItem(uuidv4(), eFormControlItemType.TextInput);
    singleLineTextItem.attributes.placeholder = 'Text input placeholder';
    singleLineTextControl.items.push(singleLineTextItem);

    const singleChoiceRadioGroupControl = new DynamicControl(uuidv4(), eFormControlType.SingleChoice);
    singleChoiceRadioGroupControl.label = 'Radio Group';
    const singleChoiceRadioGroupItem = new DynamicItem(uuidv4(), eFormControlItemType.RadioGroup);
    singleChoiceRadioGroupItem.options = [
      new DynamicOption(uuidv4(), 'Radio 1'),
      new DynamicOption(uuidv4(), 'Radio 2'),
      new DynamicOption(uuidv4(), 'Radio 3')
    ];
    singleChoiceRadioGroupControl.items.push(singleChoiceRadioGroupItem);

    const textContentBlockControl = new DynamicControl(uuidv4(), eFormControlType.TextContentBlock);
    textContentBlockControl.label = 'Control Text';
    const textContentBlockItem = new DynamicItem(uuidv4(), eFormControlItemType.HTML);
    textContentBlockItem.label = 'Item Text';
    textContentBlockControl.items.push(textContentBlockItem);

    const multipleLineTextControl = new DynamicControl(uuidv4(), eFormControlType.MultipleLineText);
    multipleLineTextControl.label = 'Text Area Input';
    const multipleLineTextItem = new DynamicItem(uuidv4(), eFormControlItemType.TextArea);
    multipleLineTextItem.attributes.rows = 2;
    multipleLineTextControl.items.push(multipleLineTextItem);

    const multipleChoiceControl = new DynamicControl(uuidv4(), eFormControlType.MultipleChoice);
    multipleChoiceControl.label = 'Multiple Choice';
    multipleChoiceControl.items = [
      new DynamicItem(uuidv4(), eFormControlItemType.Checkbox, 'Choice 1'),
      new DynamicItem(uuidv4(), eFormControlItemType.Checkbox, 'Choice 2'),
      new DynamicItem(uuidv4(), eFormControlItemType.Checkbox, 'Choice 3'),
    ];

    const quantityControl = new DynamicControl(uuidv4(), eFormControlType.Quantity);
    quantityControl.label = 'Quantity';
    const quantityControlItem = new DynamicItem(uuidv4(), eFormControlItemType.DropDown);
    quantityControl.items.push(quantityControlItem);

    if (this.inputs.includes(eFormControlType.Checkbox)) {
      this.availableControls.push(checkboxControl);
      this.availableControlsDisplay.push(({ class: 'far fa-check-square', name: 'Check Box', hasRate: true }));
    }
    if (this.inputs.includes(eFormControlType.TextContentBlock)) {
      this.availableControls.push(textContentBlockControl);
      this.availableControlsDisplay.push(({ class: 'fas fa-font', name: 'Content Block' }));
    }
    if (this.inputs.includes(eFormControlType.Date)) {
      this.availableControls.push(datePickerControl);
      this.availableControlsDisplay.push(({ class: 'far fa-calendar', name: 'Date Picker' }));
    }
    if (this.inputs.includes(eFormControlType.SingleChoice)) {
      this.availableControls.push(singleChoiceDropdownControl);
      this.availableControlsDisplay.push(({ class: 'far fa-caret-square-down', name: 'Dropdown', hasRate: true }));
    }
    if (this.inputs.includes(eFormControlType.MultipleLineText)) {
      this.availableControls.push(multipleLineTextControl);
      this.availableControlsDisplay.push(({ class: 'fas fa-text-height', name: 'Multi-Line Text' }));
    }
    if (this.inputs.includes(eFormControlType.MultipleChoice)) {
      this.availableControls.push(multipleChoiceControl);
      this.availableControlsDisplay.push(({ class: 'fas fa-list-ul', name: 'Multiple Choice', hasRate: true }));
    }
    if (this.inputs.includes(eFormControlType.Decimal)) {
      this.availableControls.push(decimalControl);
      this.availableControlsDisplay.push(({ class: 'fas fa-hashtag', name: 'Number Input' }));
    }
    if (this.inputs.includes(eFormControlType.SingleChoice)) {
      this.availableControls.push(singleChoiceRadioGroupControl);
      this.availableControlsDisplay.push(({ class: 'fas fa-ellipsis-v', name: 'Radio Group', hasRate: true }));
    }
    if (this.inputs.includes(eFormControlType.SingleLineText)) {
      this.availableControls.push(singleLineTextControl);
      this.availableControlsDisplay.push(({ class: 'fas fa-text', name: 'Single-Line Text' }));
    }
    if (this.inputs.includes(eFormControlType.Quantity)) {
      this.availableControls.push(quantityControl);
      this.availableControlsDisplay.push(({ class: 'fa fa-list-ol', name: 'Quantity Control', hasRate: true }));
    }
  }
}
