import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { AppState } from 'app/app.reducers';
import { HierarchyInvoiceActions } from 'app/core/containers/admin/manager/enrollments/enrollment-fees/store';
import {
  getManagerClubInvoices,
  getManagerInvoice,
  getManagerInvoices
} from 'app/core/containers/admin/manager/manager-feature.reducer';
import {
  IClubEnrollmentHierarchyInvoiceItemsDto,
  IEnrollmentHierarchyInvoiceItemsDto,
  IEnrollmentInvoiceItemSubmitDto,
  IInstitutionManagerProgramParams,
  IInvoiceDto
} from 'app/core/models';
import { IPaymentTransactionUpdateDto } from 'app/core/models/serverDTOs/IPaymentTransactionUpdateDto';
import { filter, map, Observable, of, take, tap } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class InvoiceService {

  constructor(private httpClient: HttpClient,
    private store: Store<AppState>,
    private dispatcher: ActionsSubject) {

  }

  public getEnrollmentFees(params: IInstitutionManagerProgramParams & { programYearId: string }): Observable<IEnrollmentHierarchyInvoiceItemsDto[]> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getManagerInvoices(params)),
      tap(invoices => {
        if (invoices === undefined) {
          this.store.dispatch(HierarchyInvoiceActions.ManagerLoadEnrollmentFeesAction(params));
        }
      }),
      filter(invoices => invoices != null)
    );
  }

  public loadEnrollmentFeesEffect({
    institutionId,
    managerId,
    programId,
    programYearId
  }: IInstitutionManagerProgramParams & { programYearId: string }): Observable<IEnrollmentHierarchyInvoiceItemsDto[]> {

    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/invoice-items/${programYearId}`)
    .pipe(filter(data => data !== null)) as Observable<IEnrollmentHierarchyInvoiceItemsDto[]>;
  }

  public updateEnrollmentFees(params: IInstitutionManagerProgramParams & { programYearId: string, hierarchyNodeId: string, invoice: IEnrollmentInvoiceItemSubmitDto }) {
    this.store.dispatch(HierarchyInvoiceActions.ManagerUpdateEnrollmentFeesAction(params));

    return this.dispatcher.pipe(
      ofType(HierarchyInvoiceActions.ManagerUpdateEnrollmentFeesSuccessAction, HierarchyInvoiceActions.ManagerUpdateEnrollmentFeesErrorAction),
      take(1),
      map(action => {
        if (action.type === HierarchyInvoiceActions.ManagerUpdateEnrollmentFeesSuccessAction.type) {
          return action.invoice;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateEnrollmentFeesEffect({
    institutionId,
    managerId,
    programId,
    programYearId,
    hierarchyNodeId,
    invoice
  }: IInstitutionManagerProgramParams & { programYearId: string, hierarchyNodeId: string, invoice: IEnrollmentInvoiceItemSubmitDto }): Observable<IEnrollmentHierarchyInvoiceItemsDto> {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/invoice-items/${programYearId}?hierarchyNodeId=${hierarchyNodeId}`, invoice) as Observable<IEnrollmentHierarchyInvoiceItemsDto>;
  }

  public getClubInvoices(params: IInstitutionManagerProgramParams & { countyAreaId: string, programYearId: string }): Observable<IClubEnrollmentHierarchyInvoiceItemsDto[]> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getManagerClubInvoices(params)),
      tap(clubInvoices => {
        if (clubInvoices === undefined) {
          this.store.dispatch(HierarchyInvoiceActions.ManagerLoadClubInvoicesAction(params));
        }
      }),
      filter(clubInvoices => clubInvoices != null)
    );
  }

  public loadClubInvoicesEffect({
    institutionId,
    managerId,
    programId,
    countyAreaId,
    programYearId
  }: IInstitutionManagerProgramParams & { countyAreaId: string, programYearId: string }): Observable<IClubEnrollmentHierarchyInvoiceItemsDto[]> {

    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/county-areas/${countyAreaId}/club-invoice-items/${programYearId}`) as Observable<IClubEnrollmentHierarchyInvoiceItemsDto[]>;
  }

  public updateClubInvoice(params: IInstitutionManagerProgramParams & { countyAreaId: string, programYearId: string, unitId: string, clubInvoice: IEnrollmentInvoiceItemSubmitDto }) {
    this.store.dispatch(HierarchyInvoiceActions.ManagerUpdateClubInvoiceAction(params));

    return this.dispatcher.pipe(
      ofType(HierarchyInvoiceActions.ManagerUpdateClubInvoiceSuccessAction, HierarchyInvoiceActions.ManagerUpdateClubInvoiceErrorAction),
      take(1),
      map(action => {
        if (action.type === HierarchyInvoiceActions.ManagerUpdateClubInvoiceSuccessAction.type) {
          return action.clubInvoice;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateClubInvoiceEffect({
    institutionId,
    managerId,
    programId,
    countyAreaId,
    programYearId,
    unitId,
    clubInvoice
  }: IInstitutionManagerProgramParams & { countyAreaId: string, programYearId: string, unitId: string, clubInvoice: IEnrollmentInvoiceItemSubmitDto }): Observable<any> {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/county-areas/${countyAreaId}/club-invoice-items/${programYearId}?unitId=${unitId}`, clubInvoice);
  }

  public getInvoice(params: IInstitutionManagerProgramParams & { invoiceId: string }): Observable<IInvoiceDto> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getManagerInvoice(params)),
      tap(invoice => {
        if (invoice === undefined) {
          this.store.dispatch(HierarchyInvoiceActions.ManagerLoadInvoiceAction(params));
        }
      }),
      filter(invoice => invoice != null)
    );

  }

  public getInvoiceEffect({
    institutionId,
    managerId,
    programId,
    invoiceId
  }: IInstitutionManagerProgramParams & { invoiceId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/invoices/${invoiceId}`) as Observable<IInvoiceDto>;
  }

  public updateTransaction(params: IInstitutionManagerProgramParams & { invoiceId: string, paymentTransactionId: string, update: IPaymentTransactionUpdateDto }) {
    this.store.dispatch(HierarchyInvoiceActions.ManagerUpdateTransactionAction(params));

    return this.dispatcher.pipe(
      ofType(HierarchyInvoiceActions.ManagerUpdateTransactionSuccessAction, HierarchyInvoiceActions.ManagerUpdateTransactionErrorAction),
      take(1),
      map(action => {
        if (action.type === HierarchyInvoiceActions.ManagerUpdateTransactionSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateTransactionEffect({
    institutionId,
    managerId,
    programId,
    invoiceId,
    paymentTransactionId,
    update
  }: IInstitutionManagerProgramParams & { invoiceId: string, paymentTransactionId: string, update: IPaymentTransactionUpdateDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/invoices/${invoiceId}/transactions/${paymentTransactionId}`, update);
  }
}
