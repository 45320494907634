import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { eLocalizationType, IConsentVersionDto } from 'app/core/models';

@Component({
  selector: 'ng4h-modal-preview-consent',
  templateUrl: './modal-preview-consent.component.html',
  styleUrls: ['./modal-preview-consent.component.scss']
})
export class ModalPreviewConsentComponent implements OnInit {

  public consent: IConsentVersionDto;
  public allowDelete: boolean = false;

  public eLocalizationType = eLocalizationType;

  constructor(private dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.consent = this.data.consent;
    this.allowDelete = this.data.delete;
  }

  public close(): void {
    this.dialogRef.close();
  }

  public delete(): void {
    this.dialogRef.close({ delete: true });
  }
}
