<div class="modal-header">
  <h5 class="modal-title" id="enrollModalLabel">Create Coupon</h5>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <div class="col-12 row mb-2">
      <label class="ng4h-required form-control-label col-3">Description</label>
      <div class="col-9">
        <input type="text" class="form-control" formControlName="description">
      </div>
    </div>

    <div class="col-12 row mb-2">
      <label class="ng4h-required form-control-label col-3">Fund</label>
      <div class="col-9">
        <select class="form-control" formControlName="fundId">
          <option *ngFor="let fund of funds$ | async" class="form-control" [value]="fund.discountFundId">
            {{ fund.fundCode }}</option>
        </select>
      </div>
    </div>

    <div class="col-12 row mb-2">
      <label class="ng4h-required form-control-label col-3">Role</label>
      <div class="col-9">
        <select class="form-control" formControlName="enrollmentRole">
          <option [value]="eEnrollmentRoleType.Volunteer">
            {{(eEnrollmentRoleType.Volunteer | programAlias$ | async)?.singular}}</option>
          <option [value]="eEnrollmentRoleType.ClubMember">
            {{(eEnrollmentRoleType.ClubMember | programAlias$ | async)?.singular}}</option>
          <option [value]="eEnrollmentRoleType.CloverBud">
            {{(eEnrollmentRoleType.CloverBud | programAlias$ | async)?.singular}}</option>
        </select>

      </div>
    </div>

    <div class="col-12 row mb-2">
      <label class="ng4h-required form-control-label col-3">Discount Type</label>
      <div class="col-9">
        <select formControlName="institutionDiscountType" class="form-control">
          <option class="form-control" [value]="eInstitutionDiscountType.ClubBased">
            {{eInstitutionDiscountType.ClubBased | camelToTitleCase}}</option>
          <option class="form-control" [value]="eInstitutionDiscountType.CouponBased">
            {{eInstitutionDiscountType.CouponBased | camelToTitleCase}}</option>
        </select>
      </div>
    </div>
    <div class="col-12 row mb-2">
      <label class="ng4h-required form-control-label col-3">Apply To</label>
      <div class="col-9">
        <select formControlName="invoiceDiscountType" class="form-control">
          <option class="form-control" [value]="eInvoiceDiscountType.CountyEnrollmentFee">
            {{eInvoiceDiscountType.CountyEnrollmentFee | camelToTitleCase}}</option>
          <option class="form-control" [value]="eInvoiceDiscountType.InstitutionEnrollmentFee">
            {{eInvoiceDiscountType.InstitutionEnrollmentFee | camelToTitleCase}}</option>
        </select>
      </div>
    </div>

    <div class="col-12 row mb-2">
      <label class="ng4h-required form-control-label col-3">Fee Percentage</label>
      <div class="col-9">
        <select formControlName="feePercentage" class="form-control">
          <option class="form-control" [value]="type.key"
            *ngFor="let type of eInvoiceDiscountFeePercentage | keyvalue: retainOrder">
            {{type.key | feePercent}}</option>
        </select>
      </div>
    </div>

    <div class="col-12 row mb-2">
      <label class="ng4h-required form-control-label col-3">Status</label>
      <div class="col-9">
        <select formControlName="status" class="form-control">
          <option class="form-control" [value]="type.key" *ngFor="let type of eInstitutionDiscountStatus | keyvalue">
            {{type.key | camelToTitleCase}}</option>
        </select>
      </div>
    </div>



  </form>

</div>

<div class="modal-footer">
  <button (click)="create()" [disabled]="form.invalid" class="btn btn-primary my-2 my-sm-0" role="button"
    aria-pressed="true">Create</button>
</div>