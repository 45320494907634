import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  AutoUnsubscribe,
  eAppSettingsAlias,
  eHierarchyNodeType,
  IHierarchyAny,
  IHierarchyCountyArea,
  IHierarchyDistrictArea,
  IHierarchyDto,
  IHierarchyInstitution,
  IHierarchyRegionArea,
  IUnitDto,
} from 'app/core/models';
import { HierarchyService, RouterService } from 'app/core/services';
import { LocalStorageService } from 'app/core/services/local-storage.service';
import { combineLatest, map, Observable, shareReplay, startWith, takeUntil } from 'rxjs';

@Component({
  selector: 'ng4h-hierarchy-picker',
  templateUrl: './hierarchy-picker.component.html',
  styleUrls: ['./hierarchy-picker.component.scss']
})
export class HierarchyPickerComponent extends AutoUnsubscribe implements OnInit {
  public eAppSettingsAlias = eAppSettingsAlias;
  public eHierarchyNodeType = eHierarchyNodeType;

  private hierarchy$: Observable<IHierarchyDto>;
  private filteredHierarchy$: Observable<IHierarchyAny[]>;

  public selectedLeafNode$: Observable<IHierarchyAny>;

  public selectedInstitutionArea$: Observable<IHierarchyInstitution>;
  public selectedRegionArea$: Observable<IHierarchyRegionArea>;
  public selectedDistrictArea$: Observable<IHierarchyDistrictArea>;
  public selectedCountyArea$: Observable<IHierarchyCountyArea>;
  public selectedClub$: Observable<IUnitDto>;

  public selectedInstitutionAreas$: Observable<IHierarchyInstitution[]>;
  public selectedRegionAreas$: Observable<IHierarchyRegionArea[]>;
  public selectedDistrictAreas$: Observable<IHierarchyDistrictArea[]>;
  public selectedCountyAreas$: Observable<IHierarchyCountyArea[]>;
  public selectedClubs$: Observable<IUnitDto[]>;

  public searchForm: FormGroup;

  constructor(private hierarchyService: HierarchyService, private routerService: RouterService, private fb: FormBuilder,
    private localStorageService: LocalStorageService) {
    super();

    this.hierarchy$ = this.hierarchyService.activeHierarchy$.pipe(takeUntil(this.autoUnsubscribe));

    this.selectedInstitutionArea$ = this.hierarchyService.selectedInstitutionArea$.pipe(takeUntil(this.autoUnsubscribe));
    this.selectedRegionArea$ = this.hierarchyService.selectedRegionArea$.pipe(takeUntil(this.autoUnsubscribe));
    this.selectedDistrictArea$ = this.hierarchyService.selectedDistrictArea$.pipe(takeUntil(this.autoUnsubscribe));
    this.selectedCountyArea$ = this.hierarchyService.selectedCountyArea$.pipe(takeUntil(this.autoUnsubscribe));

    this.selectedLeafNode$ = this.hierarchyService.selectedLeafNode$.pipe(takeUntil(this.autoUnsubscribe));

    this.searchForm = this.fb.group({
      searchText: [null]
    });

    this.filteredHierarchy$ = combineLatest(
      this.hierarchy$,
      this.searchForm.controls.searchText.valueChanges.pipe(startWith(this.searchForm.controls.searchText.value)))
      .pipe(
        map(combined => {
          const searchText = combined[1]?.replace(/[^\w\s]/g, '');
          const hierarchy = combined[0];

          const filteredHierarchy = [];
          Object.keys(hierarchy).forEach(key => {
            if (searchText == null || searchText === '' || new RegExp(searchText, 'gi').test(hierarchy[key].hierarchyNodeName) || hierarchy[key].hierarchyNodeId === searchText) {
              filteredHierarchy.push(hierarchy[key]);
            }
          });
          return filteredHierarchy;
        }),
        shareReplay({ bufferSize: 1, refCount: true })
      );

    this.selectedCountyAreas$ = this.filteredHierarchy$.pipe(
      map(hierarchy => {
        return hierarchy.filter(node => node.hierarchyNodeType === eHierarchyNodeType.CountyArea) as IHierarchyCountyArea[];
      })
    );

    this.selectedDistrictAreas$ = this.filteredHierarchy$.pipe(
      map(hierarchy => {
        return hierarchy.filter(node => node.hierarchyNodeType === eHierarchyNodeType.DistrictArea) as IHierarchyDistrictArea[];
      })
    );

    this.selectedRegionAreas$ = this.filteredHierarchy$.pipe(
      map(hierarchy => {
        return hierarchy.filter(node => node.hierarchyNodeType === eHierarchyNodeType.RegionArea) as IHierarchyRegionArea[];
      })
    );

    this.selectedInstitutionAreas$ = this.filteredHierarchy$.pipe(
      map(hierarchy => {
        return hierarchy.filter(node => node.hierarchyNodeType === eHierarchyNodeType.Institution) as IHierarchyInstitution[];
      })
    );
  }

  ngOnInit() {

  }

  public nodeChanged(hierarchyNode: IHierarchyAny) {
    this.searchForm.controls.searchText.setValue(null);
    this.hierarchyService.selectedHierarchyNodeChanged(hierarchyNode, this.routerService.institutionId, this.routerService.programId, this.routerService.managerId);
    this.localStorageService.setHierarchyNodeId({ institutionId: this.routerService.institutionId, managerId: this.routerService.managerId, hierarchyNodeId: hierarchyNode.hierarchyNodeId });
  }
}
