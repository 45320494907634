import { Action, createReducer, on } from '@ngrx/store';
import { MemberActions } from 'app/core/containers/member';
import { IMemberSearchResultsDto } from 'app/core/models/serverDTOs/IMemberSearchResultsDto';

import { TrainingsSearchActions } from '.';

export interface TrainingSearchState {

    trainingSearchSearching: boolean;
    trainingsSearchResult: IMemberSearchResultsDto;
}

const initialState: TrainingSearchState = {
    trainingSearchSearching: false,
    trainingsSearchResult: undefined
};

const reducer = createReducer(
    initialState,
    on(TrainingsSearchActions.GetTrainingSearchResultsAction, (state, payload) => {
        return {
            ...state,
            trainingSearchSearching: true,
        };
    }),
    on(TrainingsSearchActions.GetTrainingSearchResultsSuccessAction, (state, payload) => {
        return {
            ...state,
            trainingSearchSearching: false,
            trainingsSearchResult: payload.trainingsSearchResult
        };
    }),
    on(TrainingsSearchActions.GetTrainingSearchResultsErrorAction, (state, payload) => {
        return {
            ...state,
            trainingSearchSearching: false,
        };
    }),

    on(TrainingsSearchActions.SearchTrainingsAction, (state, payload) => {
        return {
            ...state,
            trainingSearchSearching: true,
        };
    }),
    on(TrainingsSearchActions.SearchTrainingsSuccessAction, (state, payload) => {
        return {
            ...state,
            trainingSearchSearching: false,
            trainingsSearchResult: payload.trainingsSearchResult
        };
    }),
    on(TrainingsSearchActions.SearchTrainingsErrorAction, (state, payload) => {
        return {
            ...state,
            trainingSearchSearching: false,
        };
    }),

    on(TrainingsSearchActions.ClearTrainingSearchAction, (state, payload) => {
        return {
            ...state,
            trainingSearchSearching: true,
        };
    }),
    on(TrainingsSearchActions.ClearTrainingSearchErrorAction, (state, payload) => {
        return {
            ...state,
            trainingSearchSearching: false,
        };
    }),
    on(MemberActions.MemberFlagAction, MemberActions.MemberFlagDeleteErrorAction, (state, { institutionId, managerId, programId, hierarchyNodeId, memberId }) => {
        if (state.trainingsSearchResult?.results == null) {
            return state;
        }
        const updatedResults = state.trainingsSearchResult.results.map(result => {
            if (result.memberId === memberId) {
                return {
                    ...result,
                    flagged: true
                };
            }
            return result;
        });
        return {
            ...state,
            trainingsSearchResult: {
                ...state.trainingsSearchResult,
                results: updatedResults
            }
        };
    }),
    on(MemberActions.MemberFlagErrorAction, MemberActions.MemberFlagDeleteAction, (state, { institutionId, managerId, programId, hierarchyNodeId, memberId }) => {
        if (state.trainingsSearchResult?.results == null) {
            return state;
        }
        const updatedResults = state.trainingsSearchResult.results.map(result => {
            if (result.memberId === memberId) {
                return {
                    ...result,
                    flagged: false
                };
            }
            return result;
        });
        return {
            ...state,
            trainingsSearchResult: {
                ...state.trainingsSearchResult,
                results: updatedResults
            }
        };
    }),

);

export function trainingSearchReducer(state: TrainingSearchState | undefined, action: Action) {
    return reducer(state, action);
}

export const trainingsSearchResult = (state: TrainingSearchState) => state.trainingsSearchResult;
export const trainingSearchSearching = (state: TrainingSearchState) => state.trainingSearchSearching;