import { IBroadcastEmailOptionsDto } from './IBroadcastEmailOptionsDto';

export interface IBroadcastEmailUpdateDto {
  replyTo?: string;
  htmlBody?: string;
  textBody?: string;
  options: IBroadcastEmailOptionsDto;
  title?: string;
  subject?: string;
}
