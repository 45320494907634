import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import {
  AppState,
  getHierarchy,
  getManagerHierarchyNode,
  getSelectedCountyArea,
  getSelectedDistrictArea,
  getSelectedInstitutionArea,
  getSelectedRegionArea
} from 'app/app.reducers';
import {
  eHierarchyNodeType,
  eProfileType,
  ICountyAreaHierarchyNodeCreateDto,
  IHierarchyAny,
  IHierarchyCountyArea,
  IHierarchyDistrictArea,
  IHierarchyDto,
  IHierarchyInstitution,
  IHierarchyNodeCreateDto,
  IHierarchyNodeUpdateDto,
  IHierarchyRegionArea,
  IManagerInstitutionProfile,
  IManagerProgramHierarchyForDisplayDto,
  ISuperuserInstitutionParams
} from 'app/core/models';
import { HierarchyActions } from 'app/shared/hierarchy';
import { mergeImmutable } from 'app/shared/utils';
import {
  combineLatest,
  expand,
  filter,
  map,
  Observable,
  of,
  reduce,
  shareReplay,
  switchMap,
  take,
  takeWhile,
  tap
} from 'rxjs';

import { RouterService } from '.';
import { ProfileService } from './profile.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HierarchyService {

  public activeHierarchy$: Observable<IHierarchyDto>;

  public selectedCountyArea$: Observable<IHierarchyCountyArea>;
  public selectedDistrictArea$: Observable<IHierarchyDistrictArea>;
  public selectedRegionArea$: Observable<IHierarchyRegionArea>;
  public selectedInstitutionArea$: Observable<IHierarchyInstitution>;

  public selectedLeafNode$: Observable<IHierarchyAny>;

  public managerHierarchyNode$: Observable<IHierarchyAny>;

  private managerHierarchyStreams: { [institutionId: string]: { [programId: string]: { [managerId: string]: Observable<IHierarchyDto> } } };
  private fullHierarchyStreams: { [institutionId: string]: Observable<IHierarchyDto> } = {};

  constructor(
    private store: Store<AppState>,
    private httpClient: HttpClient,
    private routerService: RouterService,
    private profileService: ProfileService,
    private dispatcher: ActionsSubject
  ) {

    this.selectedCountyArea$ = this.store.pipe(select(getSelectedCountyArea));
    this.selectedDistrictArea$ = this.store.pipe(select(getSelectedDistrictArea));
    this.selectedRegionArea$ = this.store.pipe(select(getSelectedRegionArea));
    this.selectedInstitutionArea$ = this.store.pipe(select(getSelectedInstitutionArea));

    this.routerService.institutionId$.pipe(
      filter(institutionId => institutionId != null)
    ).subscribe(institutionId => {
      this.store.dispatch(HierarchyActions.HierarchyInstitutionChangedAction());
    });

    this.selectedLeafNode$ = combineLatest([this.selectedCountyArea$, this.selectedDistrictArea$, this.selectedRegionArea$, this.selectedInstitutionArea$]).pipe(
      map(combined => {
        return combined.find(node => node != null);
      })
    );

    this.activeHierarchy$ = this.profileService.actingAsInstitutionProfile.pipe(
      filter(profile => profile != null),
      switchMap(profile => {
        if (profile.profileType === eProfileType.Manager) {
          const hierarchyAssociations = profile.managerPrograms[this.routerService.programId];
          return this.getManagerHierarchy({
            institutionId: this.routerService.institutionId,
            programId: this.routerService.programId,
            managerId: profile.managerId,
            hierarchyAssociations
          });
        }
        return this.getHierarchy({ institutionId: this.routerService.institutionId });
      }),
      filter(hierarchy => {
        return hierarchy != null;
      })
    );

    this.managerHierarchyNode$ = combineLatest([this.routerService.managerHierarchyId$, this.activeHierarchy$]).pipe(
      filter(([managerHierarchyId, activeHierarchy]) => {
        return managerHierarchyId != null && activeHierarchy != null;
      }),
      map(([managerHierarchyId, activeHierarchy]) => {
        return activeHierarchy[managerHierarchyId];
      })
    );

    combineLatest([this.profileService.actingAsInstitutionProfile, this.routerService.programId$]).pipe(
      filter(([actingOnBehalfOf, programId]) => actingOnBehalfOf != null && actingOnBehalfOf.profileType === eProfileType.Manager && programId != null),
      switchMap(([actingOnBehalfOf, programId]) => {
        const profile = actingOnBehalfOf as IManagerInstitutionProfile;
        return this.getManagerHierarchy({
          institutionId: profile.institutionId,
          programId,
          managerId: profile.managerId,
          hierarchyAssociations: profile.managerPrograms[programId]
        });
      }),
      filter(managerHierarchy => managerHierarchy != null)
    ).subscribe(managerHierarchy => {
      this.selectedHierarchyNodeChanged(managerHierarchy[this.routerService.managerHierarchyId], this.routerService.institutionId, this.routerService.programId, this.routerService.managerId);
    });

  }

  public getHierarchyNodeAndParents(params: { institutionId: string, hierarchyNodeId: string }): Observable<{ [hierarchyNodeType: string]: IHierarchyAny }> {
    return this.getHierarchyNode(params).pipe(
      filter(node => node != null),
      expand(node => {
        return this.getHierarchyNode({
          institutionId: this.routerService.institutionId,
          hierarchyNodeId: node.parentHierarchyNodeId
        });
      }),
      takeWhile(node => node != null),
      reduce((acc, newVal) => {
        if (acc == null) {
          return {
            [newVal.hierarchyNodeType]: newVal
          };
        }
        return {
          ...acc,
          [newVal.hierarchyNodeType]: newVal
        };
      }, null)
    );
  }

  public getHierarchyNodeAnChildren(params: { institutionId: string, hierarchyNodeId: string }): Observable<IHierarchyAny[]> {
    return combineLatest([this.getHierarchy({ institutionId: params.institutionId }), this.getHierarchyNode(params)]).pipe(
      filter(([hierarchy, selectedHierarchyNode]) => {
        return hierarchy != null && selectedHierarchyNode != null;
      }),
      map(([hierarchy, selectedHierarchyNode]) => {
        const children = this.getChildren(hierarchy, selectedHierarchyNode.childrenHierarchyNodeIds);
        return [selectedHierarchyNode, ...children];
      })
    );
  }

  private getChildren(hierarchy: IHierarchyDto, childrenNodeIds: string[]): IHierarchyAny[] {
    if (!Array.isArray(childrenNodeIds) || childrenNodeIds.length < 1) {
      return [];
    }
    const children = childrenNodeIds.map(id => {
      return hierarchy[id];
    });

    const allChildren = children.reduce((pre, cur, curIndex, arr) => {
      const subChildren = this.getChildren(hierarchy, cur.childrenHierarchyNodeIds);
      return [...pre, ...subChildren];
    }, []);

    return [...children, ...allChildren];
  }

  public getCountyAreas({
    institutionId,
    hierarchyNode
  }: { institutionId: string, hierarchyNode: IHierarchyAny }): Observable<IHierarchyCountyArea[]> {
    return this.getHierarchy({ institutionId }).pipe(
      map(hierarchy => {
        const countyAreas: IHierarchyCountyArea[] = [];
        switch (hierarchyNode.hierarchyNodeType) {
          case eHierarchyNodeType.Institution: {
            hierarchyNode.childrenHierarchyNodeIds.forEach(regionAreaId => {
              hierarchy[regionAreaId].childrenHierarchyNodeIds.forEach(districtAreaId => {
                hierarchy[districtAreaId].childrenHierarchyNodeIds.forEach(countyAreaId => {
                  countyAreas.push(hierarchy[countyAreaId] as IHierarchyCountyArea);
                });
              });
            });
            return countyAreas;
          }
          case eHierarchyNodeType.RegionArea: {
            hierarchyNode.childrenHierarchyNodeIds.forEach(districtAreaId => {
              hierarchy[districtAreaId].childrenHierarchyNodeIds.forEach(countyAreaId => {
                countyAreas.push(hierarchy[countyAreaId] as IHierarchyCountyArea);
              });
            });
            return countyAreas;
          }
          case eHierarchyNodeType.DistrictArea: {
            hierarchyNode.childrenHierarchyNodeIds.forEach(countyAreaId => {
              countyAreas.push(hierarchy[countyAreaId] as IHierarchyCountyArea);
            });
            return countyAreas;
          }
          case eHierarchyNodeType.CountyArea: {
            return [hierarchyNode];
          }
        }
      })
    );
  }

  public getManagerHierarchy(params: { institutionId: string, programId: string, managerId: string, hierarchyAssociations: IManagerProgramHierarchyForDisplayDto[] }): Observable<IHierarchyDto> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }

    if (this.managerHierarchyStreams?.[params.institutionId]?.[params.programId]?.[params.managerId] == null) {
      const stream$ = this.getHierarchy({ institutionId: params.institutionId }).pipe(
        filter(hierarchy => hierarchy != null),
        take(1),
        switchMap(hierarchy => {
          return this.store.pipe(select(getManagerHierarchyNode(params)));
        }),
        tap(managerHierarchy => {
          if (managerHierarchy === undefined) {
            this.store.dispatch(HierarchyActions.HierarchySetManagerAction(params));
          }
        }),
        filter(managerHierarchy => managerHierarchy != null),
        shareReplay({ refCount: true, bufferSize: 1 })
      );
      this.managerHierarchyStreams = mergeImmutable(
        { [params.institutionId]: { [params.programId]: { [params.managerId]: stream$ } } },
        this.managerHierarchyStreams
      );

    }
    return this.managerHierarchyStreams[params.institutionId][params.programId][params.managerId];
  }

  public getHierarchyNode({
    institutionId,
    hierarchyNodeId
  }: { institutionId: string, hierarchyNodeId: string }): Observable<IHierarchyAny> {
    return this.getHierarchy({ institutionId }).pipe(
      filter(hierarchy => hierarchy != null),
      map(hierarchy => hierarchy[hierarchyNodeId])
    );
  }

  public getHierarchy(params: { institutionId: string }): Observable<IHierarchyDto> {

    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    if (this.fullHierarchyStreams?.[params.institutionId] == null) {
      const stream$ = this.store.pipe(
        select(getHierarchy(params)),
        tap(enrollment => {
          if (enrollment === undefined) {
            this.store.dispatch(HierarchyActions.HierarchyLoadAction(params));
          }
        }),
        shareReplay({ refCount: true, bufferSize: 1 })
      );
      this.fullHierarchyStreams = mergeImmutable(
        { [params.institutionId]: stream$ },
        this.fullHierarchyStreams
      );
    }
    return this.fullHierarchyStreams[params.institutionId];
  }

  public loadHierarchyEffect(institutionId: string): Observable<IHierarchyDto> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/hierarchy`) as Observable<IHierarchyDto>;
  }

  public selectedHierarchyNodeChanged(hierarchyNode: IHierarchyAny, institutionId: string, programId?: string, managerId?: string) {
    if (hierarchyNode == null) {
      return;
    }
    switch (hierarchyNode.hierarchyNodeType) {
      case eHierarchyNodeType.CountyArea: {
        this.store.dispatch(HierarchyActions.HierarchySelectCountyAreaAction({
          institutionId,
          programId,
          managerId,
          selectedCountyArea: hierarchyNode
        }));
        break;
      }
      case eHierarchyNodeType.DistrictArea: {
        this.store.dispatch(HierarchyActions.HierarchySelectDistrictAreaAction({
          institutionId,
          programId,
          managerId,
          selectedDistrictArea: hierarchyNode
        }));
        break;
      }
      case eHierarchyNodeType.RegionArea: {
        this.store.dispatch(HierarchyActions.HierarchySelectRegionAreaAction({
          institutionId,
          programId,
          managerId,
          selectedRegionArea: hierarchyNode
        }));
        break;
      }
      case eHierarchyNodeType.Institution: {
        this.store.dispatch(HierarchyActions.HierarchySelectInstitutionAction({ selectedInstitutionArea: hierarchyNode }));
        break;
      }
    }
  }

  public updateHierarchyNode(params: ISuperuserInstitutionParams & { hierarchyNodeId: string, update: IHierarchyNodeUpdateDto }) {
    this.store.dispatch(HierarchyActions.UpdateHierarchyNodeAction(params));

    return this.dispatcher.pipe(
      ofType(HierarchyActions.UpdateHierarchyNodeSuccessAction, HierarchyActions.UpdateHierarchyNodeErrorAction),
      take(1),
      map(action => {
        if (action.type === HierarchyActions.UpdateHierarchyNodeSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateHierarchyNodeEffect({
    superuserId,
    institutionId,
    hierarchyNodeId,
    update
  }: ISuperuserInstitutionParams & { hierarchyNodeId: string, update: IHierarchyNodeUpdateDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/super-users/${superuserId}/institutions/${institutionId}/hierarchy/${hierarchyNodeId}`, update);
  }

  public changeHierarchyNodeParent(params: ISuperuserInstitutionParams & { hierarchyNodeId: string, moveToParentHierarchyNodeId: string }) {
    this.store.dispatch(HierarchyActions.ChangeHierarchyNodeParentAction(params));

    return this.dispatcher.pipe(
      ofType(HierarchyActions.ChangeHierarchyNodeParentSuccessAction, HierarchyActions.ChangeHierarchyNodeParentErrorAction),
      take(1),
      map(action => {
        if (action.type === HierarchyActions.ChangeHierarchyNodeParentSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public changeHierarchyNodeParentEffect({
    superuserId,
    institutionId,
    hierarchyNodeId,
    moveToParentHierarchyNodeId
  }: ISuperuserInstitutionParams & { hierarchyNodeId: string, moveToParentHierarchyNodeId: string }) {
    return this.httpClient.patch(`${environment.apiUri}/api/super-users/${superuserId}/institutions/${institutionId}/hierarchy/${hierarchyNodeId}?moveToParentHierarchyNodeId=${moveToParentHierarchyNodeId}`, {});
  }

  public addCounty(params: ISuperuserInstitutionParams & { districtAreaId: string, nodeCreate: ICountyAreaHierarchyNodeCreateDto }) {
    this.store.dispatch(HierarchyActions.AddCountyAction(params));

    return this.dispatcher.pipe(
      ofType(HierarchyActions.AddCountySuccessAction, HierarchyActions.AddCountyErrorAction),
      take(1),
      map(action => {
        if (action.type === HierarchyActions.AddCountySuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public addCountyEffect({
    superuserId,
    institutionId,
    districtAreaId,
    nodeCreate
  }: ISuperuserInstitutionParams & { districtAreaId: string, nodeCreate: ICountyAreaHierarchyNodeCreateDto }) {
    return this.httpClient.put(`${environment.apiUri}/api/super-users/${superuserId}/institutions/${institutionId}/county-areas?districtAreaId=${districtAreaId}`, nodeCreate);
  }

  public addDistrict(params: ISuperuserInstitutionParams & { regionAreaId: string, nodeCreate: IHierarchyNodeCreateDto }) {
    this.store.dispatch(HierarchyActions.AddDistrictAction(params));

    return this.dispatcher.pipe(
      ofType(HierarchyActions.AddDistrictSuccessAction, HierarchyActions.AddDistrictErrorAction),
      take(1),
      map(action => {
        if (action.type === HierarchyActions.AddDistrictSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public addDistrictEffect({
    superuserId,
    institutionId,
    regionAreaId,
    nodeCreate
  }: ISuperuserInstitutionParams & { regionAreaId: string, nodeCreate: IHierarchyNodeCreateDto }) {
    return this.httpClient.put(`${environment.apiUri}/api/super-users/${superuserId}/institutions/${institutionId}/districts?regionAreaId=${regionAreaId}`, nodeCreate);
  }

  public addRegion(params: ISuperuserInstitutionParams & { nodeCreate: IHierarchyNodeCreateDto }) {
    this.store.dispatch(HierarchyActions.AddRegionAction(params));

    return this.dispatcher.pipe(
      ofType(HierarchyActions.AddRegionSuccessAction, HierarchyActions.AddRegionErrorAction),
      take(1),
      map(action => {
        if (action.type === HierarchyActions.AddRegionSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public addRegionEffect({
    superuserId,
    institutionId,
    nodeCreate
  }: ISuperuserInstitutionParams & { nodeCreate: IHierarchyNodeCreateDto }) {
    return this.httpClient.put(`${environment.apiUri}/api/super-users/${superuserId}/institutions/${institutionId}/regions`, nodeCreate);
  }

  public archiveHierarchyNode(params: ISuperuserInstitutionParams & { hierarchyNodeId: string }) {
    this.store.dispatch(HierarchyActions.ArchiveNodeAction(params));

    return this.dispatcher.pipe(
      ofType(HierarchyActions.ArchiveNodeSuccessAction, HierarchyActions.ArchiveNodeErrorAction),
      take(1),
      map(action => {
        if (action.type === HierarchyActions.ArchiveNodeSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public archiveHierarchyNodeEffect({
    superuserId,
    institutionId,
    hierarchyNodeId
  }: ISuperuserInstitutionParams & { hierarchyNodeId: string }) {
    return this.httpClient.patch(`${environment.apiUri}/api/super-users/${superuserId}/institutions/${institutionId}/hierarchy/${hierarchyNodeId}/archive`, {});
  }

  public activateHierarchyNode(params: ISuperuserInstitutionParams & { hierarchyNodeId: string }) {
    this.store.dispatch(HierarchyActions.ActivateNodeAction(params));

    return this.dispatcher.pipe(
      ofType(HierarchyActions.ActivateNodeSuccessAction, HierarchyActions.ActivateNodeErrorAction),
      take(1),
      map(action => {
        if (action.type === HierarchyActions.ActivateNodeSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public activateHierarchyNodeEffect({
    superuserId,
    institutionId,
    hierarchyNodeId
  }: ISuperuserInstitutionParams & { hierarchyNodeId: string }) {
    return this.httpClient.patch(`${environment.apiUri}/api/super-users/${superuserId}/institutions/${institutionId}/hierarchy/${hierarchyNodeId}/activate`, {});
  }

  public deleteHierarchyNode(params: ISuperuserInstitutionParams & { hierarchyNodeId: string }) {
    this.store.dispatch(HierarchyActions.DeleteNodeAction(params));

    return this.dispatcher.pipe(
      ofType(HierarchyActions.DeleteNodeSuccessAction, HierarchyActions.DeleteNodeErrorAction),
      take(1),
      map(action => {
        if (action.type === HierarchyActions.DeleteNodeSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteHierarchyNodeEffect({
    superuserId,
    institutionId,
    hierarchyNodeId
  }: ISuperuserInstitutionParams & { hierarchyNodeId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/super-users/${superuserId}/institutions/${institutionId}/hierarchy/${hierarchyNodeId}`);
  }

  public getAllHierarchyEffect({ institutionId }: { institutionId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/hierarchy-all`);
  }
}
