<div class="modal-header">
    <h5 class="modal-title" id="enrollModalLabel">Change Program Year</h5>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <ng-container *ngIf="currentProgramYear$ | async as currentProgramYear">
        <div class="row" *ngIf="programYears$ | async as programYears; else loading">
            <div class="col-12">
                <ng-container *ngFor="let programYear of programYears">
                    <div class="ng4h-list-row" (click)="programYearSelected(programYear)">
                        {{programYear.programYearForDisplay}} <span
                            *ngIf="currentProgramYear.programYearId === programYear.programYearId"> - Current
                            Year</span></div>
                </ng-container>
            </div>
        </div>
    </ng-container>
    <ng-template #loading>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </ng-template>
</div>