<div class="modal-header">
    <h5 class="modal-title">Event Ticketing</h5>
    <button (click)="close()" type="button" class="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">

</div>

<div class="modal-footer">
    <button (click)="enable()" class="btn btn-primary my-2 my-sm-0" role="button" aria-pressed="true">Enable
        Ticketing</button>
</div>