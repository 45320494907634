import { createAction, props } from '@ngrx/store';


export const CloseSidebarAction = createAction(
  '[sidebar] Close'
);
export const CloseManagerSidebarAction = createAction(
  '[sidebar] Close manager'
);
export const ToggleSideBarAction = createAction(
  '[sidebar] Toggle'
);
export const ToggleManagerSideBarAction = createAction(
  '[sidebar] Toggle manager'
);

export const OpenSidebarIfDesktopAction = createAction(
  '[sidebar] Open if desktop'
);

export const CloseSidebarIfMobileSidebarAction = createAction(
  '[sidebar] Close if mobile',
);



export const WindowWidthInitAction = createAction(
  '[sidebar] Window Width Init',
  props<{ width: number }>()
);

export const WindowWidthChangedAction = createAction(
  '[sidebar] Window Width Changed',
  props<{ width: number }>()
);


