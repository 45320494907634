<div class="modal-header">
  <h5 class="modal-title">Edit {{data.sectionKey}}</h5>
  <button (click)="close()" type="button" class="close" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="ng4h-panel-body">
    <div class="row">
      <ng-container *ngFor="let input of newFields;trackBy:trackByFn; let inputIndex = index">

        <mat-radio-group class="col-12" [ngModel]="input.inputState"
          (ngModelChange)="inputStateChanged(inputIndex, $event)">
          <div class="row tr ng4h-border-bottom">
            <label
              class="col-6 td">{{input.inputName.includes('PageMessage') ? 'Page Message': input.inputText}}</label>
            <div class="col-2 td">
              <mat-radio-button *ngIf="input.inputType != eFormControlType.TextContentBlock" value="Required">Required
              </mat-radio-button>
            </div>
            <div class="col-2 td">
              <mat-radio-button value="Visible">Visible</mat-radio-button>
            </div>
            <div class="col-2 td">
              <mat-radio-button value="Hidden">Hidden</mat-radio-button>
            </div>
          </div>
        </mat-radio-group>
      </ng-container>

    </div>
  </div>
</div>
<div class="modal-footer">
  <button (click)="save()" class="btn btn-primary my-2 my-sm-0" role="button">Save</button>
  <button class="btn btn-secondary" (click)="close()">Close</button>
</div>