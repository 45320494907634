<div class="modal-section">
  <div class="modal-header">
    <h5 class="modal-title">Email</h5>
    <button (click)="cancel()" type="button" class="close" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h3>Information</h3>
    <form [formGroup]="form" class="col-12">
      <div class="form-group">
        <label class="form-control-label ng4h-required">Title</label>
        <input class="form-control" formControlName="title">
        <label class="help-text">This title doesn't display in your emails. It's to help you identify each unique
          email.</label>
      </div>
      <div class="form-group">
        <label class="form-control-label ng4h-required">Subject</label>
        <input class="form-control" formControlName="subject">
        <label class="help-text">Appears as your email subject line.</label>
      </div>
    </form>
    <form [formGroup]="optionsForm">

      <h3>Recipients</h3>
      <div class="col-12">
        <div
          *ngIf="optionsForm.controls.recipientType.value != eBroadcastEmailRecipientType.Manager; else managerRecipient">
          <mat-radio-group formControlName="recipientType" class="form-group">
            <mat-radio-button [value]="eBroadcastEmailRecipientType.Family">Send to Families
            </mat-radio-button>
            <br>
            <label class="help-text">Sends to primary families of all selected profiles</label>
            <br>
            <mat-radio-button [value]="eBroadcastEmailRecipientType.Member">Send to Members
            </mat-radio-button>
            <br>
            <label class="help-text">Sends to each available member that has an email address on record, if
              an email address does not exist, the primary family email is used</label>
          </mat-radio-group>
        </div>


        <ng-template #managerRecipient>
          <mat-radio-group formControlName="recipientType" class="form-group">
            <mat-radio-button [value]="eBroadcastEmailRecipientType.Manager">Manager</mat-radio-button>
          </mat-radio-group>
        </ng-template>

      </div>

      <h3>Configuration</h3>
      <div class="col-12">



        <ng-container
          *ngIf="optionsForm.controls.recipientType.value != eBroadcastEmailRecipientType.Manager; else managerRecipientConfig">
          <div class="form-group">
            <mat-checkbox formControlName="includeAllFamilies">Send to All Families</mat-checkbox>
            <br>
            <label class="help-text">Sends to families of selected profiles</label>
          </div>


          <div class="form-group">
            <mat-checkbox formControlName="includeEmergencyContact">Send to Emergency Contacts</mat-checkbox>
            <br>
            <label class="help-text">Sends to emergency contacts of selected profiles</label>
          </div>

          <div class="form-group">
            <mat-checkbox formControlName="includeGuardian">Send to Guardians</mat-checkbox>
            <br>
            <label class="help-text">Sends to guardians of selected profiles</label>
          </div>


          <div class="form-group">
            <mat-checkbox formControlName="includeUnsubscribed">Send to Unsubscribed</mat-checkbox>
            <br>
            <label class="help-text">Sends an email to all profiles including those that have opted-out of broadcast
              emails (unsubscribe option will not appear in the email)</label>
          </div>


          <div class="form-group">
            <mat-checkbox formControlName="userManagerEmailForReplyTo">Use My Email as Reply-To</mat-checkbox>
            <br>
            <label class="help-text">Email will be sent out using your email address as the reply-to email
              address</label>
          </div>

          <div class="form-group">
            <mat-checkbox formControlName="sendTextMessageNotification">Send Text Message Notification</mat-checkbox>
            <br>
            <label class="help-text">Sends a text message notification that the member has an email from 4-H
              Online.</label>
          </div>
        </ng-container>

        <ng-template #managerRecipientConfig>
          <div class="form-group" *ng4hManagerHierarchyType="[eHierarchyNodeType.Institution]">
            <mat-checkbox formControlName="managersIncludeRegions">Include Region Managers</mat-checkbox>
          </div>

          <div class="form-group"
            *ng4hManagerHierarchyType="[eHierarchyNodeType.Institution, eHierarchyNodeType.RegionArea]">
            <mat-checkbox formControlName="managersIncludeDistricts">Include District Managers</mat-checkbox>
          </div>

          <div class="form-group"
            *ng4hManagerHierarchyType="[eHierarchyNodeType.Institution, eHierarchyNodeType.RegionArea, eHierarchyNodeType.DistrictArea]">
            <mat-checkbox formControlName="managersIncludeCounties">Include County Managers</mat-checkbox>
          </div>

          <div class="form-group">
            <mat-checkbox formControlName="managersIncludeClubs">Include Club Managers</mat-checkbox>
          </div>
        </ng-template>


      </div>
    </form>
  </div>

  <div class="modal-footer" *ngIf="form">
    <button id (click)="cancel()" class="btn btn-secondary my-2 my-sm-0" role="button"
      aria-pressed="true">Cancel</button>
    <button [disabled]="form.invalid || optionsForm.invalid" (click)="create()" class="btn btn-primary my-2 my-sm-0"
      role="button" aria-pressed="true">Create</button>
  </div>
</div>