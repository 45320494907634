<div class="modal-header">
    <div class="modal-title">Change Status</div>
    <button (click)="close()" type="button" class="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" *ngIf="form != null">
    <form [formGroup]="form">

        <div class="form-group row">
            <label class="form-control-label col-md-3">Message</label>
            <div class="col-md-9">
                <textarea formControlName="message" class="form-control">
                    </textarea>
            </div>
        </div>

        <div class="form-group row">
            <label class="form-control-label col-md-3">Allow System Managers</label>
            <div class="col-md-9">
                <mat-checkbox formControlName="allowSystemManagers"></mat-checkbox>
            </div>
        </div>

        <div class="form-group row">
            <label class="form-control-label col-md-3">Allow Managers</label>
            <div class="col-md-9">
                <mat-checkbox formControlName="allowManagers"></mat-checkbox>
            </div>
        </div>

        <div class="form-group row">
            <label class="form-control-label col-md-3 ">Offline Date</label>
            <div class="input-group col-md-9">
                <input class="form-control" formControlName="offlineDate" [matDatepicker]="startDate"
                    [max]="form.controls.onlineDate.value">
                <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
            </div>
            <mat-datepicker #startDate></mat-datepicker>
        </div>
        <div class="form-group row">
            <label class="form-control-label col-md-3 ">Online Date</label>
            <div class="input-group col-md-9">
                <input class="form-control" formControlName="onlineDate" [matDatepicker]="endDate"
                    [min]="form.controls.offlineDate.value">
                <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
            </div>
            <mat-datepicker #endDate></mat-datepicker>
        </div>


    </form>
</div>
<div class="modal-footer">
    <button (click)="close()" class="btn btn-secondary" role="button" aria-pressed="true">Cancel</button>
    <button [disabled]="form?.invalid" (click)="save()" class="btn btn-primary" role="button"
        aria-pressed="true">Save</button>
</div>