import { Action, createReducer, on } from '@ngrx/store';
import { IInstitutionManagerProgramModel, IUnitDto } from 'app/core/models';
import { HierarchyActions } from 'app/shared/hierarchy';
import { mergeImmutable } from 'app/shared/utils';

import { ManagerClubActions } from '.';


export interface ManagerUnitsState {
  units: IInstitutionManagerProgramModel<IUnitDto[]>;
  unitsByProgramYear: IInstitutionManagerProgramModel<{ [programYearId: string]: { [countyAreaId: string]: IUnitDto[] } }>;
  unitsNotInProgramYear: IInstitutionManagerProgramModel<{ [programYearId: string]: { [countyAreaId: string]: IUnitDto[] } }>;
  unit: IInstitutionManagerProgramModel<{ [unitId: string]: IUnitDto }>;
  activeUnits: IInstitutionManagerProgramModel<{ [programYearId: string]: IUnitDto[] }>;
  clubSearchSelectedCountyAreaId: string;
}

const initialState: ManagerUnitsState = {
  units: undefined,
  unitsByProgramYear: undefined,
  unitsNotInProgramYear: undefined,
  unit: undefined,
  activeUnits: undefined,
  clubSearchSelectedCountyAreaId: undefined
};

const reducer = createReducer(
  initialState,
  on(ManagerClubActions.ManagerLoadUnitsByProgramYearSuccessAction, (state, { institutionId, managerId, programId, programYearId, countyAreaId, units }) => {
    return {
      ...state,
      unitsByProgramYear: mergeImmutable(
        { [institutionId]: { [managerId]: { [programId]: { [programYearId]: { [countyAreaId]: units } } } } },
        state.unitsByProgramYear
      )
    };
  }),
  on(ManagerClubActions.ManagerLoadUnitsNotInProgramYearSuccessAction, (state, { institutionId, managerId, programId, programYearId, countyAreaId, units }) => {
    return {
      ...state,
      unitsNotInProgramYear: mergeImmutable(
        { [institutionId]: { [managerId]: { [programId]: { [programYearId]: { [countyAreaId]: units } } } } },
        state.unitsNotInProgramYear
      )
    };
  }),

  on(ManagerClubActions.ManagerLoadUnitsSuccessAction, (state, { institutionId, managerId, programId, units }) => {
    return {
      ...state,
      units: mergeImmutable(
        { [institutionId]: { [managerId]: { [programId]: units } } },
        state.units
      )
    };
  }),
  on(ManagerClubActions.ManagerLoadUnitSuccessAction, (state, { institutionId, managerId, programId, unitId, unit }) => {
    return {
      ...state,
      unit: mergeImmutable(
        { [institutionId]: { [managerId]: { [programId]: { [unitId]: unit } } } },
        state.unit
      )
    };
  }),
  on(ManagerClubActions.ManagerInvalidateCacheAction,
    ManagerClubActions.ManagerUpdateUnitLeaderPermissionsSuccessAction,
    ManagerClubActions.ManagerInvalidateUnitCacheAction,
    ManagerClubActions.ManagerDeleteUnitSuccessAction,
    ManagerClubActions.ManagerLinkUnitToProgramYearSuccessAction,
    ManagerClubActions.ManagerUnLinkUnitToProgramYearSuccessAction,
    (state, { }) => {
      return initialState;
    }),
  on(ManagerClubActions.ManagerGetActiveUnitsSuccessAction, (state, { institutionId, managerId, programId, programYearId, units }) => {
    return {
      ...state,
      activeUnits: mergeImmutable(
        { [institutionId]: { [managerId]: { [programId]: { [programYearId]: units } } } },
        state.activeUnits
      )
    };
  }),
  on(ManagerClubActions.SetClubSearchCountyAreaIdAction, (state, { countyAreaId }) => {
    return {
      ...state,
      clubSearchSelectedCountyAreaId: countyAreaId
    };
  }),
  on(HierarchyActions.HierarchySelectInstitutionAction,
    HierarchyActions.HierarchySelectRegionAreaAction,
    HierarchyActions.HierarchySelectDistrictAreaAction,
    HierarchyActions.HierarchySelectCountyAreaAction, (state, { }) => {
      return {
        ...state,
        clubSearchSelectedCountyAreaId: undefined
      };
    })
);

export function managerUnitReducer(state: ManagerUnitsState | undefined, action: Action) {
  return reducer(state, action);
}

export const unitsByProgramYear = (state: ManagerUnitsState) => state.unitsByProgramYear;
export const unitsNotInProgramYear = (state: ManagerUnitsState) => state.unitsNotInProgramYear;
export const unit = (state: ManagerUnitsState) => state.unit;
export const units = (state: ManagerUnitsState) => state.units;

export const activeClubs = (state: ManagerUnitsState) => state.activeUnits;
export const clubSearchSelectedCountyAreaId = (state: ManagerUnitsState) => state.clubSearchSelectedCountyAreaId;
