<form *ngIf="form != null" [formGroup]="form" class="needs-validation" novalidate>
  <div
    [ngClass]="{'alert-manager-control-warning': !control.archived && control.managerField, 'alert-manager-control-danger': control.archived }"
    class="alert alert-manager-control-warning" [formGroupName]="control.controlId">

    <ng-container [ngSwitch]="control.type">

      <div *ngSwitchCase="eFormControlType.SingleLineText" class="form-group full-width">
        <ng-container *ngFor="let item of control.items">
          <label class="form-control-label" [for]="control.controlId"
            [ngClass]="{'ng4h-required': control.attributes.required}">{{control.label}}</label>
          <input class="form-control" [formControlName]="item.itemId" [id]="control.controlId" [type]="item.type"
            [placeholder]="item.attributes.placeholder">
        </ng-container>
      </div>

      <div *ngSwitchCase="eFormControlType.Decimal" class="form-group full-width">
        <ng-container *ngFor="let item of control.items">
          <label class="form-control-label" [for]="control.controlId"
            [ngClass]="{'ng4h-required': control.attributes.required}">{{control.label}}
          </label>
          <input OnlyNumber="true" class="form-control" [formControlName]="item.itemId" [id]="control.controlId"
            type="number" [min]="control.attributes.min"
            [max]="control.attributes.max > item.attributes.quantityAvailable ? item.attributes.quantityAvailable: control.attributes.max"
            [placeholder]="item.attributes.placeholder" step="any">
          <span *ngIf="item.attributes.quantityAvailable != null">{{item.attributes.quantityAvailable
            + ' available'}}</span>
        </ng-container>
      </div>

      <div *ngSwitchCase="eFormControlType.Quantity" class="form-group full-width">
        <ng-container *ngFor="let item of control.items;">
          <ng-container [ngSwitch]="item.type">
            <ng-container *ngSwitchCase="eFormControlItemType.DropDown">
              <label class="form-control-label" [ngClass]="{'ng4h-required': control.attributes.required}"
                [for]="item.itemId">{{control.label}} <span
                  *ngIf="item.attributes?.quantityAvailable > -1">({{item.attributes.quantityAvailable}}
                  available)</span></label>


              <select class="form-control" [id]="item.itemId" [formControlName]="item.itemId">
                <option value=""></option>
                <ng-container
                  *ngFor="let dummy of ' '.repeat(control.items[0]?.attributes?.quantityAvailable ? control.items[0].attributes.quantityAvailable + 1 : 100).split(''); let i = index; let zero = first">
                  <option *ngIf="!zero" [ngValue]="i.toString()">
                    {{i}}
                    {{item.rate ? ' ('+(item.rate * i | currency) + ')' : ''}}
                  </option>
                </ng-container>
              </select>

            </ng-container>
          </ng-container>
        </ng-container>
      </div>


      <div *ngSwitchCase="eFormControlType.Date" class="form-group full-width">
        <ng-container *ngFor="let item of control.items; let i = index;">

          <label class="form-control-label" [for]="item.itemId"
            [ngClass]="{'ng4h-required': control.attributes.required}">{{control.label}}</label>
          <div class="input-group">
            <input class="form-control" [id]="item.itemId" [formControlName]="item.itemId"
              [min]="item.attributes.minDate" [max]="item.attributes.maxDate" [matDatepicker]="picker"
              placeholder="Choose a date">
            <mat-datepicker-toggle [for]="picker"></mat-datepicker-toggle>
          </div>
          <mat-datepicker #picker></mat-datepicker>

        </ng-container>
      </div>

      <div *ngSwitchCase="eFormControlType.MultipleLineText" class="form-group full-width">
        <ng-container *ngFor="let item of control.items">
          <label [for]="item.itemId" class="form-control-label"
            [ngClass]="{'ng4h-required': control.attributes.required}">{{control.label}}</label>

          <textarea [id]="item.itemId" class="form-control" [rows]="item.attributes.rows"
            [formControlName]="item.itemId"></textarea>

        </ng-container>
      </div>

      <div *ngSwitchCase="eFormControlType.Checkbox" class="form-group full-width">
        <ng-container *ngFor="let item of control.items">
          <label [for]="item.itemId" class="form-control-label"
            [ngClass]="{'ng4h-required': control.attributes.required}">{{control.label}}</label>
          <div>
            <mat-checkbox [id]="item.itemId" [formControlName]="item.itemId">{{item.label}} <span *ngIf="item.rate">
                {{item.rate | currency}}</span>
            </mat-checkbox>
          </div>
        </ng-container>
      </div>

      <div *ngSwitchCase="eFormControlType.TextContentBlock" class="form-group full-width">
        <label class="form-control-label"
          [ngClass]="{'ng4h-required': control.attributes.required}">{{control.label}}</label>

        <div class="text-area" [innerHtml]="control.items[0].label | safeHtml"></div>


      </div>

      <div *ngSwitchCase="eFormControlType.MultipleChoice" class="form-group full-width">
        <label class="form-control-label"
          [ngClass]="{'ng4h-required': control.attributes.required}">{{control.label}}</label>
        <div class="col-12">
          <div class="row ng4h-border ng4h-border-radius ng4h-padding-small"
            [ngClass]="{'ng-invalid': form.controls[control.controlId].invalid}">
            <ng-container *ngFor="let item of control.items">
              <label [for]="item.itemId" class="sr-only"> {{item.label}}</label>
              <mat-checkbox [id]="item.itemId" class="col-12" [formControlName]="item.itemId">
                {{item.label}}
                <span
                  *ngIf="item.attributes != null && item.attributes.quantityAvailable != null && item.attributes.quantityAvailable > -1">({{item.attributes.quantityAvailable}}
                  available)</span>
                <span *ngIf="item.rate"> {{item.rate | currency}}</span>
              </mat-checkbox>
            </ng-container>
            <small style="color: #ff0019" *ngIf="control.attributes.multipleChoiceMaxAllowedSelection != null">Limit:
              {{control.attributes.multipleChoiceMaxAllowedSelection}} Choices</small>
          </div>
        </div>
      </div>

      <div *ngSwitchCase="eFormControlType.TimeSlot" class="form-group full-width">
        <label class="form-control-label"
          [ngClass]="{'ng4h-required': control.attributes.required}">{{control.label}}</label>

        <div class="row">
          <ng-container *ngFor="let item of control.items">
            <label [for]="item.itemId" class="sr-only"> {{item.label}}</label>
            <mat-checkbox [id]="item.itemId" class="col-12" [formControlName]="item.itemId">{{item.label}}
              <span
                *ngIf="item.attributes != null && item.attributes.quantityAvailable != null && item.attributes.quantityAvailable > -1">({{item.attributes.quantityAvailable}}
                spots available)</span>
            </mat-checkbox>
          </ng-container>
        </div>
      </div>

      <div *ngSwitchCase="eFormControlType.SingleChoice" class="form-group full-width">
        <ng-container *ngFor="let item of control.items;">
          <ng-container [ngSwitch]="item.type">
            <ng-container *ngSwitchCase="eFormControlItemType.DropDown">
              <label class="form-control-label"
                [ngClass]="{'ng4h-required': control.attributes.required}">{{control.label}}</label>

              <label [for]="item.itemId" class="sr-only"> {{item.label}}</label>
              <select class="form-control" [id]="item.itemId" [formControlName]="item.itemId">
                <option value=""></option>
                <option *ngFor="let option of item.options;" [ngValue]="option.optionId">
                  {{option.label}}
                  {{(option.attributes != null && option.attributes.quantityAvailable != null) ? '(' +
                  option.attributes.quantityAvailable
                  + ' available)':''}}
                  <span *ngIf="option.rate"> {{option.rate | currency}}</span>
                </option>
              </select>

            </ng-container>
            <ng-container *ngSwitchCase="eFormControlItemType.RadioGroup">
              <label class="form-control-label"
                [ngClass]="{'ng4h-required': control.attributes.required}">{{control.label}}</label>
              <div class="ng4h-border ng4h-padding ng4h-border-radius col-12">
                <mat-radio-group [formControlName]="item.itemId">
                  <ng-container *ngFor="let option of item.options">
                    <label [for]="option.optionId" class="sr-only"> {{option.label}}</label>
                    <mat-radio-button [id]="option.optionId" [value]="option.optionId">{{option.label}}
                      <span *ngIf="option.attributes?.quantityAvailable > -1">({{option.attributes?.quantityAvailable}}
                        available)</span>
                      <span *ngIf="option.rate"> {{option.rate | currency}}</span>
                    </mat-radio-button>
                  </ng-container>
                </mat-radio-group>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
    <ng-content></ng-content>
  </div>

</form>