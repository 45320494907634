import { NgModule } from '@angular/core';
import { ChartModule } from 'primeng/chart';
import { EditorModule } from 'primeng/editor';

const PrimeNGModules = [
    EditorModule,
    ChartModule
];

@NgModule({
    // providers: [
    //     { provide: RECAPTCHA_V3_SITE_KEY, useValue: "<YOUR_SITE_KEY>" }
    // ],
    imports: [
        ...PrimeNGModules,
    ],
    exports: [
        ...PrimeNGModules,
    ]
})
export class PrimeNGModule { }
