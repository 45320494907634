import { Action, createReducer, on } from '@ngrx/store';
import {
  IClubEnrollmentHierarchyInvoiceItemsDto,
  IEnrollmentHierarchyInvoiceItemsDto,
  IInstitutionManagerProgramModel,
} from 'app/core/models';
import { mergeImmutable } from 'app/shared/utils';

import { HierarchyInvoiceActions } from '.';

export interface ManagerInvoicesState {
  enrollmentFees: IInstitutionManagerProgramModel<{ [programYearId: string]: IEnrollmentHierarchyInvoiceItemsDto[] }>;
  clubInvoices: IInstitutionManagerProgramModel<{ [programYearId: string]: IClubEnrollmentHierarchyInvoiceItemsDto[] }>;
  invoice: any
}

const initialState: ManagerInvoicesState = {
  enrollmentFees: undefined,
  clubInvoices: undefined,
  invoice: undefined
};


const reducer = createReducer(
  initialState,
  on(HierarchyInvoiceActions.ManagerLoadEnrollmentFeesSuccessAction, (state, { institutionId, managerId, programId, programYearId, invoices }) => {
    const newInvoices = mergeImmutable(
      { [institutionId]: { [managerId]: { [programId]: { [programYearId]: invoices } } } },
      state.enrollmentFees
    );

    return {
      ...state,
      enrollmentFees: newInvoices
    };
  }),

  on(HierarchyInvoiceActions.ManagerLoadClubInvoicesSuccessAction, (state, { institutionId, managerId, programId, countyAreaId, programYearId, clubInvoices }) => {
    const newInvoices = mergeImmutable(
      { [institutionId]: { [managerId]: { [programId]: { [countyAreaId]: { [programYearId]: clubInvoices } } } } },
      state.clubInvoices
    );

    return {
      ...state,
      clubInvoices: newInvoices
    };
  }),
  on(HierarchyInvoiceActions.ManagerLoadInvoiceSuccessAction, (state, { institutionId, managerId, programId, invoiceId, invoice }) => {
    const newInvoices = mergeImmutable(
      { [institutionId]: { [managerId]: { [programId]: { [invoiceId]: invoice } } } },
      state.clubInvoices
    );
    return {
      ...state,
      invoice: newInvoices
    };
  }),
  on(HierarchyInvoiceActions.ManagerUpdateEnrollmentFeesSuccessAction,
    HierarchyInvoiceActions.ManagerUpdateClubInvoiceSuccessAction, (state, { }) => {
      return initialState;
    })
);

export function enrollmentInvoiceReducer(state: ManagerInvoicesState | undefined, actions: Action) {
  return reducer(state, actions);
}


export const enrollmentFees = (state: ManagerInvoicesState) => state.enrollmentFees;
export const clubInvoices = (state: ManagerInvoicesState) => state.clubInvoices;
export const invoice = (state: ManagerInvoicesState) => state.invoice;
