import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducers';
import { ToastrActions } from 'app/shared/toastr';

@Injectable({
  providedIn: 'root'
})
export class CommonToastrService {

  constructor(private store: Store<AppState>) {
  }

  public success(message: string) {
    message = message == null ? 'Operation completed successfully' : message;
    this.store.dispatch(ToastrActions.ToastrShowSuccessAction({ message }));
  }

  public error(message: string = 'An error occurred', error?: Error | HttpErrorResponse) {
    if (error) {
      console.error(error);
      if (error instanceof HttpErrorResponse) {
        if (error?.error?.body != null) {
          message += '  ' + error.error.body;
        } else if (error?.error?.message != null) {
          message += '  ' + error.error.message;
        } else if (error?.statusText) {
          message += '  ' + error.statusText;
        } else {
          message += ' Error: Unknown';
        }
      } else if (error instanceof Error) {
        message += '  ' + error.message;
      }
    }
    this.store.dispatch(ToastrActions.ToastrShowErrorAction({ message }));
  }
}
