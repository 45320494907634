import { IConsentVersionDto, IMemberProgramConsentDto, IProjectConsentDto, IProjectLeaderDto } from '.';
import { eEnrollmentRoleType, eProjectType } from '../enums';
import { IHierarchyNodeDto } from './Hierarchy/IHierarchyNodeDto';
import { IInstitutionProgramTrainingDto } from './IInstitutionProgramTrainingDto';

export interface IProjectDto {
  id: string;
  code?: string;
  number?: string;
  type: eProjectType;
  countyArea: IHierarchyNodeDto;
  consentVersions?: IConsentVersionDto[];
  consents?: IMemberProgramConsentDto[];
  consentAssociations?: IProjectConsentDto[];
  trainings?: IInstitutionProgramTrainingDto[];
  restrictedToRoleType?: eEnrollmentRoleType;
  allowMembersToRegister: boolean;
  projectLeaders?: IProjectLeaderDto[];
  parentProject: IProjectDto;
  projectName?: string;
  projectDescription?: string;
  url?: string;
  yearsInProjectForEnrollment: number;
  nationalProjectCategoryId: string;
}
