import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { AppState } from 'app/app.reducers';
import { SystemManagerCustomFilesActions } from 'app/core/containers/admin/system-manager/store/custom-files';
import { getSystemManagerCustomFiles } from 'app/core/containers/admin/system-manager/system-manager-feature.reducers';
import {
  IEnrollmentFileControlCreateDto,
  IEnrollmentFileControlDto,
  IEnrollmentFileControlUpdateDto,
  IInstitutionSystemManagerProgramParams
} from 'app/core/models';
import { filter, map, Observable, of, take, tap } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SystemManagerCustomFileService {

  constructor(private store: Store<AppState>, private httpClient: HttpClient, private dispatcher: ActionsSubject) {
  }

  public getCustomFiles(params: IInstitutionSystemManagerProgramParams): Observable<IEnrollmentFileControlDto[]> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }

    return this.store.pipe(
      select(getSystemManagerCustomFiles(params)),
      tap(files => {
        if (files === undefined) {
          this.store.dispatch(SystemManagerCustomFilesActions.SystemManagerGetEnrollmentCustomFilesAction(params));
        }
      }),
      filter(files => files != null)
    );

  }

  public loadCustomFilesEffect({
    institutionId,
    systemManagerId,
    programId
  }: IInstitutionSystemManagerProgramParams): Observable<IEnrollmentFileControlDto[]> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/enrollment-file-controls`) as Observable<IEnrollmentFileControlDto[]>;
  }

  public createCustomFile({
    institutionId,
    systemManagerId,
    programId,
    customFile
  }: IInstitutionSystemManagerProgramParams & { customFile: IEnrollmentFileControlCreateDto }): void {
    this.store.dispatch(SystemManagerCustomFilesActions.SystemManagerCreateEnrollmentCustomFileAction({
      institutionId,
      systemManagerId,
      programId,
      customFile
    }));
  }

  public createCustomFileEffect({
    institutionId,
    systemManagerId,
    programId,
    customFile
  }: IInstitutionSystemManagerProgramParams & { customFile: IEnrollmentFileControlCreateDto }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/enrollment-file-controls`, customFile) as Observable<any>;
  }

  public updateCustomFile({
    institutionId,
    systemManagerId,
    programId,
    fileControlId,
    customFile
  }: IInstitutionSystemManagerProgramParams & { fileControlId: string, customFile: IEnrollmentFileControlUpdateDto }) {
    this.store.dispatch(SystemManagerCustomFilesActions.SystemManagerUpdateEnrollmentCustomFileAction({
      institutionId,
      systemManagerId,
      programId,
      fileControlId,
      customFile
    }));
  }

  public updateCustomFileEffect({
    institutionId,
    systemManagerId,
    programId,
    fileControlId,
    customFile
  }: IInstitutionSystemManagerProgramParams & { fileControlId: string, customFile: IEnrollmentFileControlUpdateDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/enrollment-file-controls/${fileControlId}`, customFile);
  }

  public reorderCustomFiles(params: IInstitutionSystemManagerProgramParams & { customFileIds: string[] }) {
    this.store.dispatch(SystemManagerCustomFilesActions.SystemManagerReorderEnrollmentCustomFilesAction(params));
  }

  public reorderCustomFilesEffect({
    institutionId,
    systemManagerId,
    programId,
    customFileIds
  }: IInstitutionSystemManagerProgramParams & { customFileIds: string[] }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/enrollment-file-controls/set-order`, customFileIds) as Observable<object>;
  }

  public deleteFileControl(params: IInstitutionSystemManagerProgramParams & { fileControlId: string }) {
    this.store.dispatch(SystemManagerCustomFilesActions.SystemManagerCustomFileDeleteAction(params));

    return this.dispatcher.pipe(
      ofType(SystemManagerCustomFilesActions.SystemManagerCustomFileDeleteSuccessAction, SystemManagerCustomFilesActions.SystemManagerCustomFileDeleteErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerCustomFilesActions.SystemManagerCustomFileDeleteSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteFileControlEffect({
    institutionId,
    systemManagerId,
    programId,
    fileControlId
  }: IInstitutionSystemManagerProgramParams & { fileControlId: string }): Observable<any> {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/enrollment-file-controls/${fileControlId}`) as Observable<any>;
  }

  public uploadFileTemplate(params: IInstitutionSystemManagerProgramParams & { fileControlId: string, formData: FormData }) {
    this.store.dispatch(SystemManagerCustomFilesActions.SystemManagerTemplateFileUploadAction(params));
  }

  public uploadFileTemplateEffect({
    institutionId,
    systemManagerId,
    programId,
    fileControlId,
    formData
  }: IInstitutionSystemManagerProgramParams & { fileControlId: string, formData: FormData }) {
    return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/enrollment-file-controls/${fileControlId}/template-file`, formData);
  }

  public deleteFileTemplate(params: IInstitutionSystemManagerProgramParams & { fileControlId: string }) {
    this.store.dispatch(SystemManagerCustomFilesActions.SystemManagerTemplateFileDeleteAction(params));
  }

  public deleteFileTemplateEffect({
    institutionId,
    systemManagerId,
    programId,
    fileControlId
  }: IInstitutionSystemManagerProgramParams & { fileControlId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/enrollment-file-controls/${fileControlId}/template-file`, {});
  }
}
