import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import {
  getParticipationSearchSearching,
  getParticipationsSearchResult,
  ManagerFeatureStore
} from 'app/core/containers/admin/manager/manager-feature.reducer';
import { ParticipationSearchActions } from 'app/core/containers/admin/manager/store/searches/participation';
import {
  IBulkDownloadCreateDto,
  IInstitutionManagerProgramHierarchyParams,
  IParticipationSearchParametersDto
} from 'app/core/models';
import { IMemberSearchResultsDto } from 'app/core/models/serverDTOs/IMemberSearchResultsDto';
import { anyNull, downloadFile, downloadFileWithBody } from 'app/shared/utils';
import { map, Observable, switchMap, take } from 'rxjs';

import { CommonToastrService } from '../../common-toastr.service';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ParticipationSearchService {
  public participationsSearchResult$: Observable<IMemberSearchResultsDto>;
  public participationSearchSearching$: Observable<boolean>;

  constructor(private store: Store<ManagerFeatureStore>, private httpClient: HttpClient, private toastrService: CommonToastrService, private dispatcher: ActionsSubject) {
    this.participationSearchSearching$ = this.store.pipe(select(getParticipationSearchSearching));
    this.participationsSearchResult$ = this.store.pipe(select(getParticipationsSearchResult));
  }

  public getParticipationSearchResults({
    institutionId,
    managerId,
    programId,
    hierarchyNodeId,
    pageSize,
    pageIndex
  }: IInstitutionManagerProgramHierarchyParams & { pageSize: string, pageIndex: string }) {
    if (anyNull(institutionId, managerId, programId, hierarchyNodeId)) {
      return;
    }
    this.store.dispatch(ParticipationSearchActions.GetParticipationSearchResultsAction({
      institutionId,
      managerId,
      programId,
      hierarchyNodeId,
      pageSize,
      pageIndex
    }));
  }

  public getParticipationSearchResultsEffect({
    institutionId,
    managerId,
    programId,
    hierarchyNodeId,
    pageSize,
    pageIndex
  }: IInstitutionManagerProgramHierarchyParams & { pageSize: string, pageIndex: string }): Observable<IMemberSearchResultsDto> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/${hierarchyNodeId}/search-participation?pagesize=${pageSize}&pageindex=${pageIndex}`) as Observable<IMemberSearchResultsDto>;
  }

  public searchParticipations(params: IInstitutionManagerProgramHierarchyParams & { searchParameters: IParticipationSearchParametersDto }) {
    this.store.dispatch(ParticipationSearchActions.SearchParticipationsAction(params));
  }

  public searchParticipationsEffect({
    institutionId,
    managerId,
    programId,
    hierarchyNodeId,
    searchParameters
  }: IInstitutionManagerProgramHierarchyParams & { searchParameters: IParticipationSearchParametersDto }): Observable<IMemberSearchResultsDto> {
    return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/${hierarchyNodeId}/search-participation`, searchParameters, { observe: 'response' }).pipe(
      switchMap((res: HttpResponse<any>) => this.httpClient.get(res.headers.get('location')))
    ) as Observable<IMemberSearchResultsDto>;
  }

  public clearParticipationSearch(params: IInstitutionManagerProgramHierarchyParams) {
    this.store.dispatch(ParticipationSearchActions.ClearParticipationSearchAction(params));
  }

  public clearParticipationSearchEffect({
    institutionId,
    managerId,
    programId,
    hierarchyNodeId
  }: IInstitutionManagerProgramHierarchyParams) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/${hierarchyNodeId}/search-participation`);
  }

  public downloadExcelFile({
    institutionId,
    managerId,
    programId,
    hierarchyNodeId
  }: IInstitutionManagerProgramHierarchyParams) {
    downloadFileWithBody(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/${hierarchyNodeId}/search-participation/excel`, {});
  }

  public downloadHealthExcel({
    institutionId,
    managerId,
    programId,
    hierarchyNodeId
  }: IInstitutionManagerProgramHierarchyParams) {
    downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/${hierarchyNodeId}/search-participation/health-forms-excel`, this.toastrService);
  }

  public downloadHealthPdf({
    institutionId,
    managerId,
    programId,
    hierarchyNodeId
  }: IInstitutionManagerProgramHierarchyParams) {
    downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/${hierarchyNodeId}/search-participation/health-forms-pdf`, this.toastrService);
  }

  public downloadParticipationSearchFiles(params: IInstitutionManagerProgramHierarchyParams & { bulkDownloadCreate: IBulkDownloadCreateDto }) {
    this.store.dispatch(ParticipationSearchActions.DownloadBulkFilesAction(params));

    return this.dispatcher.pipe(
      ofType(ParticipationSearchActions.DownloadBulkFilesSuccessAction, ParticipationSearchActions.DownloadBulkFilesErrorAction),
      take(1),
      map(action => {
        if (action.type === ParticipationSearchActions.DownloadBulkFilesSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public downloadParticipationSearchFilesEffect({
    institutionId,
    managerId,
    programId,
    hierarchyNodeId,
    bulkDownloadCreate
  }: IInstitutionManagerProgramHierarchyParams & { bulkDownloadCreate: IBulkDownloadCreateDto }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/${hierarchyNodeId}/download-participation-search-files`, bulkDownloadCreate);
  }
}
