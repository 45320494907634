import { Action, createReducer, on } from '@ngrx/store';
import { IGroupDto, IInstitutionManagerProgramModel } from 'app/core/models';
import { mergeImmutable } from 'app/shared/utils';

import { ManagerGroupsActions } from '.';

export interface ManagerGroupsState {
    groups: IInstitutionManagerProgramModel<{ [hierarchyNodeId: string]: IGroupDto[] }>;
    group: IInstitutionManagerProgramModel<{ [hierarchyNodeId: string]: IGroupDto }>
}

const initialState: ManagerGroupsState = {
    groups: undefined,
    group: undefined
};

const reducer = createReducer(
    initialState,
    on(ManagerGroupsActions.LoadGroupsSuccessAction, (state, { institutionId, managerId, programId, hierarchyNodeId, groups }) => {
        const newGroups = mergeImmutable(
            { [institutionId]: { [managerId]: { [programId]: { [hierarchyNodeId]: groups } } } },
            state.groups
        );
        return {
            ...state,
            groups: newGroups
        };
    }),
    on(ManagerGroupsActions.LoadGroupSuccessAction, (state, { institutionId, managerId, programId, groupId, group }) => {
        const newGroup = mergeImmutable(
            { [institutionId]: { [managerId]: { [programId]: { [groupId]: group } } } },
            state.group
        );
        return {
            ...state,
            group: newGroup
        };
    }),
    on(ManagerGroupsActions.CreateGroupSuccessAction,
        ManagerGroupsActions.UpdateGroupSuccessAction,
        ManagerGroupsActions.DeleteGroupSuccessAction, (state, { }) => {
            return initialState;
        })
);

export function managerGroupsReducer(state: ManagerGroupsState | undefined, action: Action) {
    return reducer(state, action);
}

export const groups = (state: ManagerGroupsState) => state.groups;
export const group = (state: ManagerGroupsState) => state.group;

