export enum eMilitaryBranchType {
    NotApplicable = 'NotApplicable',
    AirForce = 'Air Force',
    Army = 'Army',
    CoastGuard = 'Coast Guard',
    DODCivilian = 'DODCivilian',
    Marines = 'Marines',
    Navy = 'Navy',
    SpaceForce = 'Space Force'
}
