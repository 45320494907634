import { IDemographicsDto, IEventRegistrationDescriptorDto, IMemberProgramDto, IProfileVolunteerScreeningDto } from '.';
import { eGender, eMemberStatus, eProfileType } from '..';
import { eMemberRole } from '../enums/eMemberRole';
import { IInstitutionProfile } from './IInstitutionProfile';
import { IMemberFamilyDescriptorDto } from './IMemberFamilyDescriptorDto';
import { IProfileTrainingDto } from './IProfileTrainingDto';

export interface IMemberDto extends IInstitutionProfile {
  memberId: string;
  profileToken: string;
  profileId: string;
  profileType: eProfileType.Member;
  institutionId: string;
  institutionProfileId: string;
  gender?: eGender;
  status: eMemberStatus;
  memberRole?: eMemberRole;
  fullNameFirstLast?: string;
  fullNameLastFirst?: string;
  eventRegistrations?: IEventRegistrationDescriptorDto[];
  families?: IMemberFamilyDescriptorDto[];
  canArchive: boolean;
  canDelete: boolean;
  memberPrograms?: { [programId: string]: IMemberProgramDto };
  screenings?: IProfileVolunteerScreeningDto[];
  currentScreeningId: string;
  trainings?: IProfileTrainingDto[];
  jobTitle?: string;
  employer?: string;
  workPhone?: string;
  workExt?: string;
  emergencyContact?: string;
  emergencyContactPhone?: string;
  emergencyContactEmail?: string;
  emergencyContactRelationship?: string;
  guardian1FirstName?: string;
  guardian1LastName?: string;
  guardian1Phone?: string;
  guardian1WorkPhone?: string;
  guardian1WorkExt?: string;
  guardian2FirstName?: string;
  guardian2LastName?: string;
  guardian2Phone?: string;
  guardian2WorkPhone?: string;
  guardian2WorkExt?: string;
  email?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  preferredName?: string;
  primaryPhone?: string;
  mobilePhone?: string;
  birthDate: string;

  isArchived: boolean;
  isDeleted: boolean;
  programId: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  emergencyContact2?: string;
  emergencyContact2Phone?: string;
  emergencyContact2Email?: string;
  emergencyContact2Relationship?: string;
  secondFamilyName?: string;
  secondFamilyFirstNames?: string;
  secondFamilyEmail?: string;
  secondFamilyPhone?: string;
  secondFamilyAddressLine1?: string;
  secondFamilyAddressLine2?: string;
  secondFamilyCity?: string;
  secondFamilyState?: string;
  secondFamilyPostalCode?: string;
  demographics: IDemographicsDto;
  youthYearsInProgram?: number;
  adultYearsInProgram?: number;
  memberIdent: number;
  sitePassword?: string;
  membershipIdent: number;
  age?: number;
}

