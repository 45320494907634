import { eDayOfWeek, eEnrollmentRoleType } from '..';
import { IAddressDto } from './IAddressDto';

export interface IUnitUpdateDto {
  isClub?: boolean;
  ein?: string;
  integrated?: boolean;
  raciallyMixed?: boolean;
  stateWide?: boolean;
  chartered?: boolean;
  chargeFee?: boolean;
  deliveryModeId: string;
  countyAreaId: string;
  unitName?: string;
  contactName?: string;
  contactEmailAddress?: string;
  contactAddress: IAddressDto;
  primaryPhone?: string;
  mobilePhone?: string;
  websiteUrl?: string;
  meetingAddress: IAddressDto;
  meetingLocation?: string;
  meetingDay?: eDayOfWeek;
  meetingTime?: string;
  restrictedToRoleType?: eEnrollmentRoleType;
  formResponses?: object;

  notes?: string;
  leaderLoginCode?: string;

}
