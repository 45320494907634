import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  eAccessControlListRoleType,
  ePermissionState,
  IManagerInstitutionProfile,
  IManagerProgramHierarchyForDisplayDto,
} from 'app/core/models';
import { IPermissionType } from 'app/core/models/IPermissionType';
import {
  IManagerProgramHierarchyAssociationUpdateDto,
} from 'app/core/models/serverDTOs/IManagerProgramHierarchyAssociationUpdateDto';
import { CommonToastrService, ManagerService, RouterService } from 'app/core/services';
import { Observable, take } from 'rxjs';

@Component({
  selector: 'ng4h-modal-manager-permissions',
  templateUrl: './modal-manager-permissions.component.html',
  styleUrls: ['./modal-manager-permissions.component.scss']
})
export class ModalManagerPermissionsComponent implements OnInit {


  public permissionForm: FormGroup;
  public permissionTypes$: Observable<IPermissionType[]>;

  public hierarchyAssociation: IManagerProgramHierarchyForDisplayDto;
  public eAccessControlListRoleType = eAccessControlListRoleType;

  public primary: boolean;
  public lockPrimary: boolean;
  public role: eAccessControlListRoleType;

  public ePermissionState = ePermissionState;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { manager: IManagerInstitutionProfile },
    private dialogRef: MatDialogRef<any>,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private routerService: RouterService,
    private managerService: ManagerService,
    private toastrService: CommonToastrService
  ) {
  }


  ngOnInit() {
    let grantedPermissions = {};
    this.hierarchyAssociation = this.data.manager.managerPrograms[this.routerService.programId].find(h => h.hierarchyNodeId === this.routerService.hierarchyNodeId);


    this.primary = this.hierarchyAssociation.primary === true;
    this.lockPrimary = this.hierarchyAssociation.primary === true;
    this.role = this.hierarchyAssociation.acl.permissionRole;

    this.permissionForm = this.fb.group({});
    this.permissionTypes$ = this.managerService.getPermissionTypes();

    if (this.hierarchyAssociation.acl.permissionRole === eAccessControlListRoleType.HierarchyStaff) {
      grantedPermissions = this.hierarchyAssociation.acl.grantedPermissions;
      this.permissionTypes$.pipe(take(1)).subscribe(permissionTypes => {
        permissionTypes.forEach(permissionType => {
          this.permissionForm.addControl(permissionType.permission, this.fb.control(grantedPermissions[permissionType.permission] == null ? ePermissionState.None : grantedPermissions[permissionType.permission], [Validators.required]));
        });
        this.cdr.detectChanges();
      });
    } else {
      this.permissionTypes$.pipe(take(1)).subscribe(permissionTypes => {
        permissionTypes.forEach(permissionType => {
          this.permissionForm.addControl(permissionType.permission, this.fb.control(ePermissionState.Write, [Validators.required]));
        });
        this.permissionForm.disable();
        this.cdr.detectChanges();
      });
    }
  }

  public close() {
    this.dialogRef.close();
  }

  public save() {

    const update: IManagerProgramHierarchyAssociationUpdateDto = {
      primary: this.primary,
      acl: {
        permissionRole: this.role,
        grantedPermissions: this.permissionForm.value
      }
    };
    this.managerService.updateManagerPermissionsAsSystemManager(
      {
        institutionId: this.routerService.institutionId,
        systemManagerId: this.routerService.systemManagerId,
        programId: this.routerService.programId,
        managerId: this.data.manager.managerId,
        hierarchyNodeId: this.routerService.hierarchyNodeId,
        update
      }).subscribe({
        next: () => {
          this.toastrService.success('manager permissions updated');
          this.close();
        }, error: (error) => {
          this.toastrService.error('manager permissions update failed');
        }
      });

  }

  public selectAll(permission: ePermissionState) {
    for (const key in this.permissionForm.controls) {
      if (this.permissionForm.controls.hasOwnProperty(key)) {
        this.permissionForm.controls[key].setValue(permission);
      }
    }
  }
}
