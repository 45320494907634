import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { InstitutionService } from 'app/core/services';
import { catchError, exhaustMap, groupBy, map, mergeMap, of } from 'rxjs';

import { InstitutionActions } from '.';
import { stringKeys } from '../rxjs.utils';

@Injectable()
export class InstitutionsEffects {

  loadInstitutions = createEffect(() => this.actions
    .pipe(
      ofType(InstitutionActions.InstitutionsLoadAction),
      groupBy(stringKeys),
      mergeMap(groupedBy => {
        return groupedBy.pipe(
          exhaustMap(payload => {
            return this.institutionService.getInstitutionsEffect().pipe(
              map(institutions => InstitutionActions.InstitutionsLoadSuccessAction({ institutions })),
              catchError(error => of(InstitutionActions.InstitutionsLoadErrorAction({ error })))
            );
          })
        );
      })
    ));


  loadCounties = createEffect(() => this.actions
    .pipe(
      ofType(InstitutionActions.InstitutionLoadCountiesAction),
      groupBy(stringKeys),
      mergeMap(groupedBy => {
        return groupedBy.pipe(
          exhaustMap(payload => {
            return this.institutionService.loadInstitutionCountiesEffect(payload).pipe(
              map(counties => InstitutionActions.InstitutionLoadCountiesSuccessAction({ ...payload, counties: counties ? counties : [] })),
              catchError(error => of(InstitutionActions.InstitutionLoadCountiesErrorAction({ error })))
            );
          })
        );
      })
    ));


  loadAllCounties = createEffect(() => this.actions
    .pipe(
      ofType(InstitutionActions.InstitutionLoadAllCountiesAction),
      groupBy(stringKeys),
      mergeMap(groupedBy => {
        return groupedBy.pipe(
          exhaustMap(payload => {
            return this.institutionService.loadAllCountiesEffect(payload).pipe(
              map(allCounties => InstitutionActions.InstitutionLoadAllCountiesSuccessAction({ ...payload, allCounties })),
              catchError(error => of(InstitutionActions.InstitutionLoadAllCountiesErrorAction({ error })))
            );
          })
        );
      })
    ));

  constructor(private actions: Actions, private institutionService: InstitutionService) { }
}
