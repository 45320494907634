import { Action, createReducer, on } from '@ngrx/store';
import {
  IDiscountFundDescriptorDto,
  IDiscountFundDonationDescriptorDto,
  IDiscountFundDonorDto,
  IInstitutionManagerProgramHierarchyModel,
  IInstitutionManagerProgramModel,
} from 'app/core/models';
import { IInstitutionDiscountDto } from 'app/core/models/serverDTOs/IInstitutionDiscountDto';
import { mergeImmutable } from 'app/shared/utils';

import { ManagerFinanceActions } from '.';
import { SystemManagerFinanceActions } from '../../../system-manager/store/finances';

export interface ManagerFinancesState {
  funds: IInstitutionManagerProgramModel<IDiscountFundDescriptorDto[]>;
  hierarchyFunds: IInstitutionManagerProgramHierarchyModel<IDiscountFundDescriptorDto[]>;
  fund: IInstitutionManagerProgramModel<{ [discountFundId: string]: IDiscountFundDescriptorDto }>;

  donors: IInstitutionManagerProgramModel<IDiscountFundDescriptorDto[]>;
  donor: IInstitutionManagerProgramModel<{ [discountFundDonorId: string]: IDiscountFundDonorDto }>;

  donations: IInstitutionManagerProgramModel<{ [discountFundId: string]: IDiscountFundDonationDescriptorDto[] }>;
  donation: IInstitutionManagerProgramModel<{ [discountFundId: string]: { [discountFundDonationId: string]: IDiscountFundDonationDescriptorDto } }>;

  countyDonations: IInstitutionManagerProgramModel<{ [discountFundId: string]: { [countyAreaId: string]: IDiscountFundDonationDescriptorDto[] } }>;

  countyDonors: IInstitutionManagerProgramModel<{ [countyAreaId: string]: IDiscountFundDescriptorDto[] }>;
  countyDonor: IInstitutionManagerProgramModel<{ [discountFundDonorId: string]: { [countyAreaId: string]: IDiscountFundDonorDto } }>;

  countyFunds: IInstitutionManagerProgramModel<{ [countyAreaId: string]: IDiscountFundDescriptorDto[] }>;
  countyFund: IInstitutionManagerProgramModel<{ [discountFundId: string]: { [countyAreaId: string]: IDiscountFundDescriptorDto } }>;

  discounts: IInstitutionManagerProgramModel<{ [programYearId: string]: IInstitutionDiscountDto[] }>;
  discount: IInstitutionManagerProgramModel<{ [institutionDiscountId: string]: IInstitutionDiscountDto }>;
}

const initialState: ManagerFinancesState = {
  funds: undefined,
  hierarchyFunds: undefined,
  fund: undefined,

  donors: undefined,
  donor: undefined,

  donations: undefined,
  donation: undefined,

  countyDonations: undefined,

  countyDonors: undefined,
  countyDonor: undefined,

  countyFunds: undefined,
  countyFund: undefined,

  discounts: undefined,
  discount: undefined
};

const reducer = createReducer(
  initialState,
  on(ManagerFinanceActions.ManagerGetFundsSuccessAction, (state, payload) => {
    return {
      ...state,
      funds: mergeImmutable(
        { [payload.institutionId]: { [payload.managerId]: { [payload.programId]: payload.funds } } },
        state.funds
      )
    };
  }),
  on(ManagerFinanceActions.ManagerHierarchyGetFundsSuccessAction, (state, { institutionId, managerId, programId, hierarchyNodeId, hierarchyFunds }) => {
    return {
      ...state,
      hierarchyFunds: mergeImmutable(
        { [institutionId]: { [managerId]: { [programId]: { [hierarchyNodeId]: hierarchyFunds } } } },
        state.hierarchyFunds
      )
    };
  }),
  on(SystemManagerFinanceActions.SystemManagerAddFundSuccessAction, (state, payload) => {
    return {
      ...state,
      funds: undefined
    };
  }),
  on(ManagerFinanceActions.ManagerGetFundByIdSuccessAction, (state, payload) => {
    return {
      ...state,
      fund: mergeImmutable(
        { [payload.institutionId]: { [payload.managerId]: { [payload.programId]: { [payload.discountFundId]: payload.fund } } } },
        state.fund
      )
    };
  }),
  on(ManagerFinanceActions.ManagerGetDonorsSuccessAction, (state, payload) => {
    return {
      ...state,
      donors: mergeImmutable(
        { [payload.institutionId]: { [payload.managerId]: { [payload.programId]: payload.donors } } },
        state.donors
      )
    };
  }),
  on(ManagerFinanceActions.ManagerGetDonorByIdSuccessAction, (state, payload) => {
    return {
      ...state,
      donor: mergeImmutable(
        { [payload.institutionId]: { [payload.managerId]: { [payload.programId]: { [payload.discountFundDonorId]: payload.donor } } } },
        state.donor
      )
    };
  }),
  on(ManagerFinanceActions.ManagerGetFundDonationsSuccessAction, (state, payload) => {
    return {
      ...state,
      donations: mergeImmutable(
        { [payload.institutionId]: { [payload.managerId]: { [payload.programId]: { [payload.discountFundId]: payload.donations } } } },
        state.donations
      )
    };
  }),
  on(ManagerFinanceActions.ManagerGetCountyFundDonationsSuccessAction, (state, payload) => {
    return {
      ...state,
      countyDonations: mergeImmutable(
        { [payload.institutionId]: { [payload.managerId]: { [payload.programId]: { [payload.discountFundId]: { [payload.countyAreaId]: payload.donations } } } } },
        state.countyDonations
      )
    };
  }),

  on(ManagerFinanceActions.ManagerGetDonationByIdSuccessAction, (state, payload) => {
    return {
      ...state,
      donation: mergeImmutable(
        { [payload.institutionId]: { [payload.managerId]: { [payload.programId]: { [payload.discountFundId]: { [payload.discountFundDonationId]: payload.donation } } } } },
        state.donation
      )
    };
  }),
  on(ManagerFinanceActions.ManagerGetCountyDonorsSuccessAction, (state, payload) => {
    return {
      ...state,
      countyDonors: mergeImmutable(
        { [payload.institutionId]: { [payload.managerId]: { [payload.programId]: { [payload.countyAreaId]: payload.donors } } } },
        state.countyDonors
      )
    };
  }),
  on(ManagerFinanceActions.ManagerGetCountyDonorByIdSuccessAction, (state, payload) => {
    return {
      ...state,
      countyDonor: mergeImmutable(
        { [payload.institutionId]: { [payload.managerId]: { [payload.programId]: { [payload.discountFundDonorId]: { [payload.countyAreaId]: payload.donor } } } } },
        state.countyDonor
      )
    };
  }),
  on(ManagerFinanceActions.ManagerGetCountyFundsSuccessAction, (state, payload) => {
    return {
      ...state,
      countyFunds: mergeImmutable(
        { [payload.institutionId]: { [payload.managerId]: { [payload.programId]: { [payload.countyAreaId]: payload.funds } } } },
        state.countyFunds
      )
    };
  }),
  on(ManagerFinanceActions.ManagerGetCountyFundByIdSuccessAction, (state, payload) => {
    return {
      ...state,
      countyFund: mergeImmutable(
        { [payload.institutionId]: { [payload.managerId]: { [payload.programId]: { [payload.discountFundId]: { [payload.countyAreaId]: payload.fund } } } } },
        state.countyFund
      )
    };
  }),
  // Invalidate cache on add
  on(ManagerFinanceActions.ManagerAddFundDonationSuccessAction, ManagerFinanceActions.ManagerEditFundDonationSuccessAction, ManagerFinanceActions.ManagerDeleteFundDonationSuccessAction, (state, payload) => {
    return {
      ...state,
      donations: mergeImmutable(
        { [payload.institutionId]: { [payload.managerId]: { [payload.programId]: { [payload.discountFundId]: undefined } } } },
        state.donations
      ),
      donation: undefined
    };
  }),
  on(ManagerFinanceActions.ManagerAddDonorSuccessAction, (state, payload) => {
    return {
      ...state,
      donors: mergeImmutable(
        { [payload.institutionId]: { [payload.managerId]: { [payload.programId]: undefined } } },
        state.donors
      )
    };
  }),
  on(ManagerFinanceActions.UpdateFundDonorSuccessAction, (state, payload) => {
    return {
      ...state,
      donors: undefined,
      donor: undefined
    };
  }),
  on(ManagerFinanceActions.ManagerAddCountyDonorSuccessAction, (state, payload) => {
    return {
      ...state,
      countyDonors: mergeImmutable(
        { [payload.institutionId]: { [payload.managerId]: { [payload.programId]: { [payload.countyAreaId]: undefined } } } },
        state.countyDonors
      )
    };
  }),
  on(ManagerFinanceActions.ManagerAddDiscountSuccessAction,
    ManagerFinanceActions.ManagerUpdateDiscountSuccessAction,
    ManagerFinanceActions.ManagerDeleteDiscountSuccessAction, (state, payload) => {
      return {
        ...state,
        discount: undefined,
        discounts: undefined
      };
    }),
  on(ManagerFinanceActions.ManagerGetDiscountsSuccessAction, (state, payload) => {
    return {
      ...state,
      discounts: mergeImmutable(
        { [payload.institutionId]: { [payload.managerId]: { [payload.programId]: { [payload.programYearId]: payload.discounts } } } },
        state.discounts
      )
    };
  }),
  on(ManagerFinanceActions.ManagerGetDiscountSuccessAction, (state, payload) => {
    return {
      ...state,
      discount: mergeImmutable(
        { [payload.institutionId]: { [payload.managerId]: { [payload.programId]: { [payload.institutionDiscountId]: payload.discount } } } },
        state.discount
      )
    };
  }),

  on(ManagerFinanceActions.ManagerAddClubsSuccessAction, (state, payload) => {
    return {
      ...state,
      discount: undefined
    };
  })
);

export function managerFinancesReducer(state: ManagerFinancesState | undefined, action: Action) {
  return reducer(state, action);
}

export const funds = (state: ManagerFinancesState) => state.funds;
export const hierarchyFunds = (state: ManagerFinancesState) => state.hierarchyFunds;
export const fund = (state: ManagerFinancesState) => state.fund;

export const donors = (state: ManagerFinancesState) => state.donors;
export const donor = (state: ManagerFinancesState) => state.donor;

export const donations = (state: ManagerFinancesState) => state.donations;
export const donation = (state: ManagerFinancesState) => state.donation;

export const countyDonations = (state: ManagerFinancesState) => state.countyDonations;
export const countyDonors = (state: ManagerFinancesState) => state.countyDonors;
export const countyDonor = (state: ManagerFinancesState) => state.countyDonor;

export const countyFunds = (state: ManagerFinancesState) => state.countyFunds;
export const countyFund = (state: ManagerFinancesState) => state.countyFund;

export const discounts = (state: ManagerFinancesState) => state.discounts;
export const discount = (state: ManagerFinancesState) => state.discount;
