import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { eConsentStatus, IEventRegistrationConsentDto, IMemberProgramConsentDto } from 'app/core/models';


@Component({
  selector: 'ng4h-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss']
})
export class ConsentComponent implements OnInit, OnChanges {

  @Input() consents: (IMemberProgramConsentDto | IEventRegistrationConsentDto)[];
  @Input() showDateOfBirth = false;
  @Input() hideGuardianSignature = false;
  @Input() showAsManagerConsent = false;
  @Output() allSigned: EventEmitter<IMemberProgramConsentDto[]>;

  public form: FormGroup;
  public eConsentStatus = eConsentStatus;

  constructor(private fb: FormBuilder) {
    this.allSigned = new EventEmitter();
  }

  ngOnInit() {

    let consentArray = [];

    if (this.consents != null && this.consents.length > 0) {

      consentArray = this.consents.map(recordedConsent => {

        if (this.showAsManagerConsent) {
          return this.fb.group(
            {
              consentStatus: [recordedConsent.consentStatus, recordedConsent.consentVersion.acceptanceRequired ? [Validators.required, Validators.pattern(eConsentStatus.Accepted)] : [Validators.required]],
              consentVersionId: [recordedConsent.consentVersion.consentVersionId],
              recordedManagerName: [recordedConsent.recordedManagerName, Validators.required]
            }
          );
        } else {
          return this.fb.group(
            {
              consentStatus: [recordedConsent.consentStatus, recordedConsent.consentVersion.acceptanceRequired ? [Validators.required, Validators.pattern(eConsentStatus.Accepted)] : [Validators.required]],
              consentVersionId: [recordedConsent.consentVersion.consentVersionId],
              recordedMemberName: [recordedConsent.recordedMemberName, recordedConsent.consentVersion.electronicSignatureRequired ? Validators.required : null],
              recordedParentGuardianName: [recordedConsent.recordedParentGuardianName, recordedConsent.consentVersion.electronicSignatureRequired && !this.hideGuardianSignature ? Validators.required : null],
            }
          );
        }
      });
    } else {
      consentArray = (this.consents as IMemberProgramConsentDto[]).map(consent => {
        if (this.showAsManagerConsent) {
          return this.fb.group(
            {
              consentStatus: [consent.consentStatus, consent.consentVersion.acceptanceRequired ? [Validators.required, Validators.pattern(eConsentStatus.Accepted)] : [Validators.required]],
              consentVersionId: [consent.consentVersion.consentVersionId],
              recordedManagerName: [null, [Validators.required]],
            }
          );
        } else {
          return this.fb.group(
            {
              consentStatus: [consent.consentStatus, consent.consentVersion.acceptanceRequired ? [Validators.required, Validators.pattern(eConsentStatus.Accepted)] : [Validators.required]],
              consentVersionId: [consent.consentVersion.consentVersionId],
              recordedMemberName: [null, consent.consentVersion.electronicSignatureRequired ? [Validators.required, Validators.minLength(1)] : null],
              recordedParentGuardianName: [null, consent.consentVersion.electronicSignatureRequired && !this.hideGuardianSignature ? [Validators.required, Validators.minLength(1)] : null],
            }
          );
        }
      });
    }

    this.form = this.fb.group({
      consents: this.fb.array(consentArray)
    });

    this.form.valueChanges.subscribe(() => {
      this.emitValid();
    });
    this.emitValid();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.consents) {
      this.consents = changes.consents.currentValue;
      this.ngOnInit();
    }
  }

  public emitValid() {
    if (this.form.valid) {
      const recordedConsents = [];
      for (let i = 0; i < this.consents.length; i++) {
        const consent = { ...this.consents[i] };
        const partialRecordedConsent = this.form.controls.consents.value[i];
        consent.recordedMemberName = partialRecordedConsent.recordedMemberName;
        consent.recordedParentGuardianName = partialRecordedConsent.recordedParentGuardianName;
        consent.consentStatus = partialRecordedConsent.consentStatus;
        consent.recordedManagerName = partialRecordedConsent.recordedManagerName;
        recordedConsents.push(consent);
      }
      this.allSigned.emit(recordedConsents);
    } else {
      this.allSigned.emit(null);
    }
  }

  public controlTrackBy(item: any) {
    return item;
  }


}
