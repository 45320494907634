import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PaymentsService } from 'app/core/services';
import { catchError, exhaustMap, groupBy, map, mergeMap, of } from 'rxjs';

import { PaymentsActions } from '.';
import { stringKeys } from '../rxjs.utils';

@Injectable()
export class PaymentsEffects {

  loadPayments = createEffect(() => this.actions
    .pipe(
      ofType(PaymentsActions.PaymentsLoadAction),
      groupBy(stringKeys),
      mergeMap(groupedBy => {
        return groupedBy.pipe(
          exhaustMap(payload => {
            return this.paymentsService.loadPaymentsEffect(payload).pipe(
              map(payments => payments == null ? [] : payments),
              map(payments => PaymentsActions.PaymentsLoadSuccessAction({ ...payload, payments })),
              catchError(error => of(PaymentsActions.PaymentsLoadErrorAction({ error })))
            );
          })
        );
      })
    ));

  loadPaymentsByProgram = createEffect(() => this.actions
    .pipe(
      ofType(PaymentsActions.PaymentsByProgramLoadAction),
      groupBy(stringKeys),
      mergeMap(groupedBy => {
        return groupedBy.pipe(
          exhaustMap(payload => {
            return this.paymentsService.loadProgramPaymentsEffect(payload).pipe(
              map(payments => PaymentsActions.PaymentsByProgramLoadSuccessAction({ ...payload, payments })),
              catchError(error => of(PaymentsActions.PaymentsByProgramLoadErrorAction({ error })))
            );
          })
        );
      })
    ));
  constructor(private actions: Actions, private paymentsService: PaymentsService) { }
}
