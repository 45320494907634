<div class="modal-header">
  <h5 class="modal-title">Project Consents</h5>
  <button (click)="close()" type="button" class="close" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <ng4h-consent [consents]="data.project.consents" (allSigned)="consentsSigned($event)"
    [showAsManagerConsent]="isManager$ | async"
    [hideGuardianSignature]="(enrollmentRole$ | async) === eEnrollmentRoleType.Volunteer"></ng4h-consent>
</div>
<div class="modal-footer">
  <button (click)="close()" class="btn btn-secondary my-2 my-sm-0" role="button" aria-pressed="true">Decline</button>
  <button [disabled]="consents == null" (click)="accept()" class="btn btn-primary my-2 my-sm-0" role="button"
    aria-pressed="true">Accept</button>
  <!-- <button (click)="accept()" class="btn btn-primary my-2 my-sm-0" role="button" aria-pressed="true">Accept</button> -->
</div>