import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationExtras, NavigationStart, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import {
    AppState,
    getAccountId,
    getActivityId,
    getAnimalTypeId,
    getAwardId,
    getCode,
    getCountyAreaId,
    getCountyCreditBillId,
    getCurrentUrl,
    getEnrollmentAnimalId,
    getEventSessionId,
    getFairEntryIntegrationId,
    getFileControlId,
    getFundsTransferId,
    getGroupEnrollmentId,
    getGroupId,
    getLastRouteParams,
    getLoggedInInstitutionProfileId,
    getRouterBankAccountId,
    getRouterBatchId,
    getRouterBreadcrumbs,
    getRouterBroadcastEmailId,
    getRouterConsentTypeId,
    getRouterConsentVersionId,
    getRouterCustomReportId,
    getRouterDeliveryModeId,
    getRouterDiscountFundDonorId,
    getRouterDiscountFundId,
    getRouterDonationId,
    getRouterEmailTemplateId,
    getRouterEnrollmentId,
    getRouterEventEmailTemplateType,
    getRouterEventId,
    getRouterEventRegistrationId,
    getRouterFamilyId,
    getRouterFIleId,
    getRouterHierarchyNodeId,
    getRouterInstitutionDiscountId,
    getRouterInstitutionId,
    getRouterLoginModalBody,
    getRouterLoginModalTitle,
    getRouterManagerHierarchyId,
    getRouterManagerId,
    getRouterMemberId,
    getRouterPageMessageId,
    getRouterParams,
    getRouterParticipation,
    getRouterProfileScreeningId,
    getRouterProfileTrainingId,
    getRouterProgramId,
    getRouterProgramYearId,
    getRouterProjectId,
    getRouterRecoveryAccountCode,
    getRouterRecoveryAccountId,
    getRouterRegistrationTypeId,
    getRouterReportConsumerId,
    getRouterRole,
    getRouterSelectedFamilyId,
    getRouterSelectedMemberId,
    getRouterSystemManagerId,
    getRouterTrainingId,
    getRouterUnitId,
    getRouterVolunteerTypeId,
    getRouterWizardStep,
    getSchoolDistrictId,
    getSchoolId,
    getScreeningType,
    getStandardReportId,
    getSuperuserId,
    getSwitchInstitution,
    getTicketingSystemId,
    getTrainingCourseId,
    getTrainingLessonId,
    getUserAccountId
} from 'app/app.reducers';
import { eEnrollmentRoleType, eScreeningType } from 'app/core/models';
import { combineLatest, distinctUntilChanged, filter, map, Observable, take } from 'rxjs';

import { IBreadCrumb } from '../models/IBreadCrumb';

@Injectable({
    providedIn: 'root'
})
export class RouterService {
    private params$: Observable<any>;

    public breadcrumbs$: Observable<IBreadCrumb[]>;

    public get breadcrumbs(): IBreadCrumb[] {
        let bc: IBreadCrumb[];
        this.breadcrumbs$.pipe(take(1)).subscribe(breadcrumbs => bc = breadcrumbs);
        return bc;
    }

    public superuserId$: Observable<string>;

    public get superuserId(): string {
        let superuserId: string;
        this.superuserId$.pipe(take(1)).subscribe(id => superuserId = id);
        return superuserId;
    }

    public loggedInInstitutionProfileId$: Observable<string>;

    public get loggedInInstitutionProfileId(): string {
        let loggedInInstitutionProfileId: string;
        this.loggedInInstitutionProfileId$.pipe(take(1)).subscribe(id => loggedInInstitutionProfileId = id);
        return loggedInInstitutionProfileId;
    }

    public activeUrl$: Observable<string>;
    public activeUrlArray$: Observable<string[]>;

    public get activeUrlArray(): string {
        let activeUrlArray;
        this.activeUrlArray$.pipe(take(1)).subscribe(id => activeUrlArray = id);
        return activeUrlArray;
    }

    public lastRouteParams$: Observable<string[]>;
    public lastRouteParams: string[];
    public institutionId$: Observable<string>;

    public get institutionId(): string {
        let institutionId: string;
        this.institutionId$.pipe(take(1)).subscribe(id => institutionId = id);
        return institutionId;
    }

    public managerHierarchyId$: Observable<string>;

    public get managerHierarchyId(): string {
        let managerHierarchyId: string;
        this.managerHierarchyId$.pipe(take(1)).subscribe(id => managerHierarchyId = id);
        return managerHierarchyId;
    }

    public programId$: Observable<string>;

    public get programId(): string {
        let programId: string;
        this.programId$.pipe(take(1)).subscribe(id => programId = id);
        return programId;
    }

    public programYearId$: Observable<string>;

    public get programYearId(): string {
        let programYearId: string;
        this.programYearId$.pipe(take(1)).subscribe(id => programYearId = id);
        return programYearId;
    }

    public role$: Observable<eEnrollmentRoleType>;
    public participation$: Observable<string>;
    public memberId$: Observable<string>;

    public get memberId(): string {
        let memberId: string;
        this.memberId$.pipe(take(1)).subscribe(id => memberId = id);
        return memberId;
    }

    public selectedMemberId$: Observable<string>;

    public get selectedMemberId(): string {
        let selectedMemberId: string;
        this.selectedMemberId$.pipe(take(1)).subscribe(id => selectedMemberId = id);
        return selectedMemberId;
    }

    public enrollmentId$: Observable<string>;

    public get enrollmentId(): string {
        let enrollmentId: string;
        this.enrollmentId$.pipe(take(1)).subscribe(id => enrollmentId = id);
        return enrollmentId;
    }

    public eventRegistrationId$: Observable<string>;

    public get eventRegistrationId(): string {
        let eventRegistrationId: string;
        this.eventRegistrationId$.pipe(take(1)).subscribe(id => eventRegistrationId = id);
        return eventRegistrationId;
    }

    public managerId$: Observable<string>;

    public get managerId(): string {
        let managerId: string;
        this.managerId$.pipe(take(1)).subscribe(id => managerId = id);
        return managerId;
    }

    public reportConsumerId$: Observable<string>;

    public get reportConsumerId(): string {
        let reportConsumerId: string;
        this.reportConsumerId$.pipe(take(1)).subscribe(id => reportConsumerId = id);
        return reportConsumerId;
    }

    public familyId$: Observable<string>;

    public get familyId(): string {
        let familyId: string;
        this.familyId$.pipe(take(1)).subscribe(id => familyId = id);
        return familyId;
    }

    public selectedFamilyId$: Observable<string>;

    public get selectedFamilyId(): string {
        let selectedFamilyId: string;
        this.selectedFamilyId$.pipe(take(1)).subscribe(id => selectedFamilyId = id);
        return selectedFamilyId;
    }

    public eventId$: Observable<string>;

    public get eventId(): string {
        let eventId: string;
        this.eventId$.pipe(take(1)).subscribe(id => eventId = id);
        return eventId;
    }

    public hierarchyNodeId$: Observable<string>;

    public get hierarchyNodeId(): string {
        let hierarchyNodeId: string;
        this.hierarchyNodeId$.pipe(take(1)).subscribe(id => hierarchyNodeId = id);
        return hierarchyNodeId;
    }

    public registrationTypeId$: Observable<string>;

    public get registrationTypeId(): string {
        let registrationTypeId: string;
        this.registrationTypeId$.pipe(take(1)).subscribe(id => registrationTypeId = id);
        return registrationTypeId;
    }

    public pageMessageId$: Observable<number>;

    public get pageMessageId(): number {
        let pageMessageId: number;
        this.pageMessageId$.pipe(take(1)).subscribe(id => pageMessageId = id);
        return pageMessageId;
    }

    public unitId$: Observable<string>;

    public get unitId(): string {
        let unitId: string;
        this.unitId$.pipe(take(1)).subscribe(id => unitId = id);
        return unitId;
    }

    public emailTemplateId$: Observable<number>;

    public get emailTemplateId(): number {
        let emailTemplateId: number;
        this.emailTemplateId$.pipe(take(1)).subscribe(id => emailTemplateId = id);
        return emailTemplateId;
    }

    public fileId$: Observable<string>;

    public get fileId(): string {
        let fileId: string;
        this.fileId$.pipe(take(1)).subscribe(id => fileId = id);
        return fileId;
    }

    public projectId$: Observable<string>;

    public get projectId(): string {
        let projectId: string;
        this.projectId$.pipe(take(1)).subscribe(id => projectId = id);
        return projectId;
    }

    public profileScreeningId$: Observable<string>;

    public get profileScreeningId(): string {
        let profileScreeningId: string;
        this.profileScreeningId$.pipe(take(1)).subscribe(id => profileScreeningId = id);
        return profileScreeningId;
    }

    public profileTrainingId$: Observable<string>;

    public get profileTrainingId(): string {
        let profileTrainingId: string;
        this.profileTrainingId$.pipe(take(1)).subscribe(id => profileTrainingId = id);
        return profileTrainingId;
    }

    public batchId$: Observable<string>;

    public get batchId(): string {
        let batchId: string;
        this.batchId$.pipe(take(1)).subscribe(id => batchId = id);

        return batchId;
    }

    public customReportId$: Observable<string>;

    public get customReportId(): string {
        let customReportId: string;
        this.customReportId$.pipe(take(1)).subscribe(id => customReportId = id);

        return customReportId;
    }

    public consentTypeId$: Observable<string>;

    public get consentTypeId(): string {
        let consentTypeId: string;
        this.consentTypeId$.pipe(take(1)).subscribe(id => consentTypeId = id);

        return consentTypeId;
    }

    public consentVersionId$: Observable<string>;

    public get consentVersionId(): string {
        let consentVersionId: string;
        this.consentVersionId$.pipe(take(1)).subscribe(id => consentVersionId = id);

        return consentVersionId;
    }

    public systemManagerId$: Observable<string>;

    public get systemManagerId(): string {
        let systemManagerId: string;
        this.systemManagerId$.pipe(take(1)).subscribe(id => systemManagerId = id);

        return systemManagerId;
    }

    public trainingLessonId$: Observable<string>;

    public get trainingLessonId(): string {
        let trainingLessonId: string;
        this.trainingLessonId$.pipe(take(1)).subscribe(id => trainingLessonId = id);

        return trainingLessonId;
    }

    public trainingCourseId$: Observable<string>;

    public get trainingCourseId(): string {
        let trainingCourseId: string;
        this.trainingCourseId$.pipe(take(1)).subscribe(id => trainingCourseId = id);

        return trainingCourseId;
    }

    public trainingId$: Observable<string>;

    public get trainingId(): string {
        let trainingId: string;
        this.trainingId$.pipe(take(1)).subscribe(id => trainingId = id);

        return trainingId;
    }

    public recoveryAccountCode$: Observable<string>;

    public get recoveryAccountCode(): string {
        let recoveryAccountCode: string;
        this.recoveryAccountCode$.pipe(take(1)).subscribe(id => recoveryAccountCode = id);

        return recoveryAccountCode;
    }

    public accountId$: Observable<string>;

    public get accountId(): string {
        let accountId: string;
        this.accountId$.pipe(take(1)).subscribe(id => accountId = id);

        return accountId;
    }

    public code$: Observable<string>;

    public get code(): string {
        let code: string;
        this.code$.pipe(take(1)).subscribe(id => code = id);

        return code;
    }

    public recoveryAccountId$: Observable<string>;

    public get recoveryAccountId(): string {
        let recoveryAccountId: string;
        this.recoveryAccountId$.pipe(take(1)).subscribe(id => recoveryAccountId = id);

        return recoveryAccountId;
    }

    public broadcastEmailId$: Observable<string>;

    public get broadcastEmailId(): string {
        let broadcastEmailId: string;

        this.broadcastEmailId$.pipe(take(1)).subscribe(id => broadcastEmailId = id);

        return broadcastEmailId;
    }

    public discountFundId$: Observable<string>;

    public get discountFundId(): string {
        let discountFundId: string;

        this.discountFundId$.pipe(take(1)).subscribe(id => discountFundId = id);

        return discountFundId;
    }

    public discountFundDonorId$: Observable<string>;

    public get discountFundDonorId(): string {
        let discountFundDonorId: string;

        this.discountFundDonorId$.pipe(take(1)).subscribe(id => discountFundDonorId = id);

        return discountFundDonorId;
    }

    public donationId$: Observable<string>;

    public get donationId(): string {
        let donationId: string;

        this.donationId$.pipe(take(1)).subscribe(id => donationId = id);

        return donationId;
    }

    public eventEmailTemplateType$: Observable<string>;

    public get eventEmailTemplateType(): string {
        let eventEmailTemplateType: string;
        this.eventEmailTemplateType$.pipe(take(1)).subscribe(type => eventEmailTemplateType = type);
        return eventEmailTemplateType;
    }

    public volunteerTypeId$: Observable<string>;

    public get volunteerTypeId(): string {
        let volunteerTypeId: string;
        this.volunteerTypeId$.pipe(take(1)).subscribe(type => volunteerTypeId = type);
        return volunteerTypeId;
    }

    public deliveryModeId$: Observable<string>;

    public get deliveryModeId(): string {
        let deliveryModeId: string;
        this.deliveryModeId$.pipe(take(1)).subscribe(id => deliveryModeId = id);

        return deliveryModeId;
    }

    public institutionDiscountId$: Observable<string>;

    public get institutionDiscountId(): string {
        let institutionDiscountId: string;
        this.institutionDiscountId$.pipe(take(1)).subscribe(id => institutionDiscountId = id);

        return institutionDiscountId;
    }

    public bankAccountId$: Observable<string>;

    public get bankAccountId(): string {
        let bankAccountId: string;
        this.bankAccountId$.pipe(take(1)).subscribe(id => bankAccountId = id);

        return bankAccountId;
    }

    public groupEnrollmentId$: Observable<string>;

    public get groupEnrollmentId(): string {
        let groupEnrollmentId: string;
        this.groupEnrollmentId$.pipe(take(1)).subscribe(id => groupEnrollmentId = id);
        return groupEnrollmentId;
    }

    public eventSessionId$: Observable<string>;

    public get eventSessionId(): string {
        let eventSessionId: string;
        this.eventSessionId$.pipe(take(1)).subscribe(id => eventSessionId = id);
        return eventSessionId;
    }

    public userAccountId$: Observable<string>;

    public get userAccountId(): string {
        let userAccountId: string;
        this.userAccountId$.pipe(take(1)).subscribe(id => userAccountId = id);
        return userAccountId;
    }

    public awardId$: Observable<string>;

    public get awardId(): string {
        let awardId: string;
        this.awardId$.pipe(take(1)).subscribe(id => awardId = id);
        return awardId;
    }

    public activityId$: Observable<string>;

    public get activityId(): string {
        let activityId: string;
        this.activityId$.pipe(take(1)).subscribe(id => activityId = id);
        return activityId;
    }

    public animalTypeId$: Observable<string>;

    public get animalTypeId(): string {
        let animalTypeId: string;
        this.animalTypeId$.pipe(take(1)).subscribe(id => animalTypeId = id);
        return animalTypeId;
    }

    public screeningType$: Observable<eScreeningType>;

    public get screeningType(): eScreeningType {
        let screeningType: eScreeningType;
        this.screeningType$.pipe(take(1)).subscribe(id => screeningType = id);
        return screeningType;
    }

    public groupId$: Observable<string>;

    public get groupId(): string {
        let groupId: string;
        this.groupId$.pipe(take(1)).subscribe(id => groupId = id);
        return groupId;
    }

    public countyCreditBillId$: Observable<string>;

    public get countyCreditBillId(): string {
        let countyCreditBillId: string;
        this.countyCreditBillId$.pipe(take(1)).subscribe(id => countyCreditBillId = id);
        return countyCreditBillId;
    }

    public wizardStep$: Observable<string>;

    public get wizardStep(): string {
        let wizardStep: string;
        this.wizardStep$.pipe(take(1)).subscribe(id => wizardStep = id);
        return wizardStep;
    }

    public loginModalTitle$: Observable<string>;

    public get loginModalTitle(): string {
        let loginModalTitle: string;
        this.loginModalTitle$.pipe(take(1)).subscribe(id => loginModalTitle = id);
        return loginModalTitle;
    }

    public loginModalBody$: Observable<string>;

    public get loginModalBody(): string {
        let loginModalBody: string;
        this.loginModalBody$.pipe(take(1)).subscribe(id => loginModalBody = id);
        return loginModalBody;
    }

    public switchInstitution$: Observable<string>;

    public get switchInstitution(): string {
        let switchInstitution: string;
        this.switchInstitution$.pipe(take(1)).subscribe(id => switchInstitution = id);
        return switchInstitution;
    }

    public enrollmentAnimalId$: Observable<string>;

    public get enrollmentAnimalId(): string {
        let enrollmentAnimalId: string;
        this.enrollmentAnimalId$.pipe(take(1)).subscribe(id => enrollmentAnimalId = id);
        return enrollmentAnimalId;
    }

    public fileControlId$: Observable<string>;

    public get fileControlId(): string {
        let fileControlId: string;
        this.fileControlId$.pipe(take(1)).subscribe(id => fileControlId = id);
        return fileControlId;
    }

    public standardReportId$: Observable<string>;

    public get standardReportId(): string {
        let standardReportId: string;
        this.standardReportId$.pipe(take(1)).subscribe(id => standardReportId = id);
        return standardReportId;
    }

    public fundsTransferId$: Observable<string>;

    public get fundsTransferId(): string {
        let fundsTransferId: string;
        this.fundsTransferId$.pipe(take(1)).subscribe(id => fundsTransferId = id);
        return fundsTransferId;
    }

    public fairEntryIntegrationId$: Observable<string>;

    public get fairEntryIntegrationId(): string {
        let fairEntryIntegrationId: string;
        this.fairEntryIntegrationId$.pipe(take(1)).subscribe(id => fairEntryIntegrationId = id);
        return fairEntryIntegrationId;
    }

    public countyAreaId$: Observable<string>;

    public get countyAreaId(): string {
        let countyAreaId: string;
        this.countyAreaId$.pipe(take(1)).subscribe(id => countyAreaId = id);
        return countyAreaId;
    }

    public schoolId$: Observable<string>;

    public get schoolId(): string {
        let schoolId: string;
        this.schoolId$.pipe(take(1)).subscribe(id => schoolId = id);
        return schoolId;
    }

    public schoolDistrictId$: Observable<string>;

    public get schoolDistrictId(): string {
        let schoolDistrictId: string;
        this.schoolDistrictId$.pipe(take(1)).subscribe(id => schoolDistrictId = id);
        return schoolDistrictId;
    }

    public ticketingSystemId$: Observable<string>;

    public get ticketingSystemId(): string {
        let ticketingSystemId: string;
        this.ticketingSystemId$.pipe(take(1)).subscribe(id => ticketingSystemId = id);
        return ticketingSystemId;
    }

    public currentRoute: string;
    public lastRoute: string;

    constructor(private store: Store<AppState>, private router: Router, private titleService: Title) {

        this.router.events.pipe(
            filter(event => event instanceof NavigationStart)
        ).subscribe(({ url }: NavigationStart) => {
            this.lastRoute = this.currentRoute;
            this.currentRoute = url;
        });
        this.breadcrumbs$ = this.store.pipe(select(getRouterBreadcrumbs));
        this.superuserId$ = this.store.pipe(select(getSuperuserId), distinctUntilChanged());
        this.loggedInInstitutionProfileId$ = this.store.pipe(select(getLoggedInInstitutionProfileId), distinctUntilChanged());
        this.activeUrl$ = this.store.pipe(select(getCurrentUrl), filter(url => url != null), distinctUntilChanged());
        this.activeUrlArray$ = this.activeUrl$
        .pipe(map(activeUrl => activeUrl.split('/')
        .filter(s => s !== '')));
        this.params$ = this.store.pipe(select(getRouterParams));
        this.lastRouteParams$ = this.store.pipe(select(getLastRouteParams))
        .pipe(map(lastRouteParams => lastRouteParams == null ? null : lastRouteParams.split('/')
        .filter(i => i !== '')));
        this.lastRouteParams$.subscribe(lastRouteParams => this.lastRouteParams = lastRouteParams);
        this.institutionId$ = this.store.pipe(select(getRouterInstitutionId), distinctUntilChanged());
        this.memberId$ = this.store.pipe(select(getRouterMemberId), distinctUntilChanged());
        this.selectedMemberId$ = this.store.pipe(select(getRouterSelectedMemberId), distinctUntilChanged());
        this.managerId$ = this.store.pipe(select(getRouterManagerId), distinctUntilChanged());
        this.familyId$ = this.store.pipe(select(getRouterFamilyId), distinctUntilChanged());
        this.reportConsumerId$ = this.store.pipe(select(getRouterReportConsumerId), distinctUntilChanged());
        this.selectedFamilyId$ = this.store.pipe(select(getRouterSelectedFamilyId), distinctUntilChanged());
        this.managerHierarchyId$ = this.store.pipe(select(getRouterManagerHierarchyId), distinctUntilChanged());
        this.programId$ = this.store.pipe(select(getRouterProgramId), distinctUntilChanged());
        this.programYearId$ = this.store.pipe(select(getRouterProgramYearId), distinctUntilChanged());
        this.role$ = this.store.pipe(select(getRouterRole));
        this.participation$ = this.store.pipe(select(getRouterParticipation), distinctUntilChanged());
        this.enrollmentId$ = this.store.pipe(select(getRouterEnrollmentId), distinctUntilChanged());
        this.eventRegistrationId$ = this.store.pipe(select(getRouterEventRegistrationId), distinctUntilChanged());
        this.hierarchyNodeId$ = this.store.pipe(select(getRouterHierarchyNodeId), distinctUntilChanged());
        this.eventId$ = this.store.pipe(select(getRouterEventId), distinctUntilChanged());
        this.registrationTypeId$ = this.store.pipe(select(getRouterRegistrationTypeId), distinctUntilChanged());
        this.pageMessageId$ = this.store.pipe(select(getRouterPageMessageId), map(id => parseInt(id, 10)), distinctUntilChanged());
        this.emailTemplateId$ = this.store.pipe(select(getRouterEmailTemplateId), map(id => parseInt(id, 10)), distinctUntilChanged());
        this.unitId$ = this.store.pipe(select(getRouterUnitId), distinctUntilChanged());
        this.projectId$ = this.store.pipe(select(getRouterProjectId), distinctUntilChanged());
        this.consentTypeId$ = this.store.pipe(select(getRouterConsentTypeId), distinctUntilChanged());
        this.consentVersionId$ = this.store.pipe(select(getRouterConsentVersionId), distinctUntilChanged());
        this.trainingId$ = this.store.pipe(select(getRouterTrainingId), distinctUntilChanged());
        this.fileId$ = this.store.pipe(select(getRouterFIleId), distinctUntilChanged());
        this.profileScreeningId$ = this.store.pipe(select(getRouterProfileScreeningId), distinctUntilChanged());
        this.profileTrainingId$ = this.store.pipe(select(getRouterProfileTrainingId), distinctUntilChanged());
        this.batchId$ = this.store.pipe(select(getRouterBatchId), distinctUntilChanged());
        this.customReportId$ = this.store.pipe(select(getRouterCustomReportId), distinctUntilChanged());
        this.systemManagerId$ = this.store.pipe(select(getRouterSystemManagerId), distinctUntilChanged());
        this.trainingLessonId$ = this.store.pipe(select(getTrainingLessonId), distinctUntilChanged());
        this.trainingCourseId$ = this.store.pipe(select(getTrainingCourseId), distinctUntilChanged());
        this.recoveryAccountCode$ = this.store.pipe(select(getRouterRecoveryAccountCode));
        this.accountId$ = this.store.pipe(select(getAccountId));
        this.code$ = this.store.pipe(select(getCode));
        this.recoveryAccountId$ = this.store.pipe(select(getRouterRecoveryAccountId));
        this.broadcastEmailId$ = this.store.pipe(select(getRouterBroadcastEmailId));
        this.discountFundId$ = this.store.pipe(select(getRouterDiscountFundId), distinctUntilChanged());
        this.discountFundDonorId$ = this.store.pipe(select(getRouterDiscountFundDonorId), distinctUntilChanged());
        this.donationId$ = this.store.pipe(select(getRouterDonationId), distinctUntilChanged());
        this.eventEmailTemplateType$ = this.store.pipe(select(getRouterEventEmailTemplateType), distinctUntilChanged());
        this.volunteerTypeId$ = this.store.pipe(select(getRouterVolunteerTypeId), distinctUntilChanged());
        this.deliveryModeId$ = this.store.pipe(select(getRouterDeliveryModeId), distinctUntilChanged());
        this.institutionDiscountId$ = this.store.pipe(select(getRouterInstitutionDiscountId), distinctUntilChanged());
        this.bankAccountId$ = this.store.pipe(select(getRouterBankAccountId), distinctUntilChanged());
        this.groupEnrollmentId$ = this.store.pipe(select(getGroupEnrollmentId), distinctUntilChanged());
        this.eventSessionId$ = this.store.pipe(select(getEventSessionId), distinctUntilChanged());
        this.userAccountId$ = this.store.pipe(select(getUserAccountId), distinctUntilChanged());
        this.awardId$ = this.store.pipe(select(getAwardId), distinctUntilChanged());
        this.activityId$ = this.store.pipe(select(getActivityId), distinctUntilChanged());
        this.animalTypeId$ = this.store.pipe(select(getAnimalTypeId), distinctUntilChanged());
        this.screeningType$ = this.store.pipe(select(getScreeningType), distinctUntilChanged());
        this.groupId$ = this.store.pipe(select(getGroupId), distinctUntilChanged());
        this.countyCreditBillId$ = this.store.pipe(select(getCountyCreditBillId), distinctUntilChanged());
        this.wizardStep$ = this.store.pipe(select(getRouterWizardStep), distinctUntilChanged());
        this.loginModalTitle$ = this.store.pipe(select(getRouterLoginModalTitle), distinctUntilChanged());
        this.loginModalBody$ = this.store.pipe(select(getRouterLoginModalBody), distinctUntilChanged());
        this.switchInstitution$ = this.store.pipe(select(getSwitchInstitution), distinctUntilChanged());
        this.enrollmentAnimalId$ = this.store.pipe(select(getEnrollmentAnimalId), distinctUntilChanged());
        this.fileControlId$ = this.store.pipe(select(getFileControlId), distinctUntilChanged());
        this.standardReportId$ = this.store.pipe(select(getStandardReportId), distinctUntilChanged());
        this.fundsTransferId$ = this.store.pipe(select(getFundsTransferId), distinctUntilChanged());
        this.fairEntryIntegrationId$ = this.store.pipe(select(getFairEntryIntegrationId), distinctUntilChanged());
        this.countyAreaId$ = this.store.pipe(select(getCountyAreaId), distinctUntilChanged());
        this.schoolId$ = this.store.pipe(select(getSchoolId), distinctUntilChanged());
        this.schoolDistrictId$ = this.store.pipe(select(getSchoolDistrictId), distinctUntilChanged());
        this.ticketingSystemId$ = this.store.pipe(select(getTicketingSystemId), distinctUntilChanged());
    }

    public init() {
        this.breadcrumbs$.subscribe(breadcrumbs => {
            if (Array.isArray(breadcrumbs) && breadcrumbs.length > 0) {
                this.titleService.setTitle(breadcrumbs[breadcrumbs.length - 1].label);
            } else if (this.titleService.getTitle() !== '4-H Online') {
                this.titleService.setTitle('4-H Online');
            }
        });
    }

    public Go(path: Array<string | number>, extras?: NavigationExtras) {
        return this.router.navigate(path, extras);
    }

    public replaceUrlParam(parameterName: string, newValue: string) {
        combineLatest([this.activeUrl$, this.params$]).pipe(
            take(1)
        ).subscribe(([activeUrl, params]) => {
            const currentValue = params[parameterName];
            const newUrl = activeUrl.replace(currentValue, newValue);
            if (activeUrl === newUrl) {
                return;
            }
            this.router.navigateByUrl(newUrl);
        });
    }
}
