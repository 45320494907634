import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EMAIL_REGEX, IManagerInstitutionProfile } from 'app/core/models';
import { ManagerService, RouterService } from 'app/core/services';
import { filter, map, Observable, switchMap, tap } from 'rxjs';

@Component({
  selector: 'ng4h-manager-search-autocomplete',
  templateUrl: './manager-search-autocomplete.component.html',
  styleUrls: ['./manager-search-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManagerSearchAutocompleteComponent implements OnInit {

  public addManager: FormGroup;
  public managers$: Observable<IManagerInstitutionProfile[]>;

  @Output() managerSelected: EventEmitter<IManagerInstitutionProfile> = new EventEmitter();
  private selectedManager: IManagerInstitutionProfile;

  constructor(
    private routerService: RouterService,
    private fb: FormBuilder,
    private managerService: ManagerService
  ) { }

  ngOnInit(): void {
    this.addManager = this.fb.group({
      email: ['', [Validators.required, Validators.email, Validators.pattern(EMAIL_REGEX)]],
    });

    this.managers$ = this.addManager.controls['email'].valueChanges.pipe(
      tap(val => {
        if (this.selectedManager != null) {
          this.selectedManager = null;
          this.managerSelected.emit(null);
        }
      }),
      map((val: string) => val.toUpperCase()),
      switchMap(value => {
        return this.managerService.getManagers({ institutionId: this.routerService.institutionId }).pipe(
          filter(managers => managers != null),
          map(managers => {
            return managers.filter(manager => {
              const testMatch = manager.email.toUpperCase().includes(value) ||
                manager.firstName?.toUpperCase().includes(value) ||
                manager.lastName?.toUpperCase().includes(value) ||
                (manager.lastName + ' ' + manager.firstName).toUpperCase().includes(value) ||
                (manager.lastName + ', ' + manager.firstName).toUpperCase().includes(value) ||
                // This is more for devs to search by id, maybe remove?
                manager.managerId.toUpperCase().includes(value);
              return testMatch;
            });
          })
        );
      }),

    );


  }

  public autoCompleteClicked(manager: IManagerInstitutionProfile) {
    this.managerSelected.emit(manager);
    this.selectedManager = manager;
  }

}
