import {
    IEventRegistrationMergeReviewDto,
    IFamilyDescriptorDto,
    IMemberProgramYearMergeReviewDto,
    IProfileScreeningMergeReviewDto,
    IProfileTrainingsMergeReviewDto,
} from '.';

export interface IMemberMergeReviewDto {
    families?: IFamilyDescriptorDto[];
    takeFamilies?: string[];
    memberProgramYears?: IMemberProgramYearMergeReviewDto[];
    screenings?: IProfileScreeningMergeReviewDto[];
    trainings?: IProfileTrainingsMergeReviewDto[];
    eventRegistrations?: IEventRegistrationMergeReviewDto[];
}
