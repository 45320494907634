import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { eProfileType } from 'app/core/models';
import { ProfileService, RouterService } from 'app/core/services';
import { map, Observable } from 'rxjs';

@Component({
  selector: 'ng4h-modal-superuser-permissions',
  templateUrl: './modal-superuser-permissions.component.html',
  styleUrls: ['./modal-superuser-permissions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalSuperuserPermissionsComponent implements OnInit {

  public acl$: Observable<any>;
  constructor(private dialogRef: MatDialogRef<any>, private routerService: RouterService, private profileService: ProfileService) {
    this.acl$ = this.profileService.actingAsInstitutionProfile.pipe(
      map(profile => {

        switch (profile.profileType) {
          case eProfileType.Manager: {
            return profile.managerPrograms[this.routerService.programId].find(mp => mp.hierarchyNodeId === this.routerService.managerHierarchyId).acl;
          }
          case eProfileType.SystemManager: {
            return profile.acl;
          }
        }
      })
    );
  }

  ngOnInit() {
  }

  public close(): void {
    this.dialogRef.close();
  }
}
