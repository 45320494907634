import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'enrollmentFee',
    pure: true
})
export class EnrollmentFeePipe implements PipeTransform {
    public transform(value: number): number {
        if (value == null) {
            return 0;
        }
        return value;
    }
}
