import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  IInstitutionSystemManagerProgramParams,
  IInstitutionVolunteerCreateDto,
  IInstitutionVolunteerDto,
  IInstitutionVolunteerUpdateDto,
} from 'app/core/models';

export const VolunteerTypesLoadAction = createAction(
    '[volunteer types] Load volunteer type',
    props<IInstitutionSystemManagerProgramParams>()
);
export const VolunteerTypesLoadSuccessAction = createAction(
    '[volunteer types] Load volunteer success type',
    props<IInstitutionSystemManagerProgramParams & { volunteerTypes: IInstitutionVolunteerDto[] }>()
);
export const VolunteerTypesLoadErrorAction = createAction(
    '[volunteer types] Load volunteer error type',
    props<{ error: HttpErrorResponse }>()
);

export const VolunteerTypesCreateAction = createAction(
    '[volunteer types] Create volunteer type',
    props<IInstitutionSystemManagerProgramParams & { volunteerType: IInstitutionVolunteerCreateDto }>()
);
export const VolunteerTypesCreateSuccessAction = createAction(
    '[volunteer types] Create volunteer success type',
    props<IInstitutionSystemManagerProgramParams & { volunteerType: IInstitutionVolunteerCreateDto }>()
);
export const VolunteerTypesCreateErrorAction = createAction(
    '[volunteer types] Create volunteer error type',
    props<{ error: HttpErrorResponse }>()
);

export const VolunteerTypesUpdateAction = createAction(
    '[volunteer types] Update volunteer type',
    props<IInstitutionSystemManagerProgramParams & { institutionVolunteerId: string, volunteerType: IInstitutionVolunteerUpdateDto }>()
);
export const VolunteerTypesUpdateSuccessAction = createAction(
    '[volunteer types] Update volunteer success type',
    props<IInstitutionSystemManagerProgramParams & { institutionVolunteerId: string, volunteerType: IInstitutionVolunteerUpdateDto }>()
);
export const VolunteerTypesUpdateErrorAction = createAction(
    '[volunteer types] Update volunteer error type',
    props<{ error: HttpErrorResponse }>()
);


export const VolunteerTypesDeleteAction = createAction(
    '[volunteer types] Delete volunteer type',
    props<IInstitutionSystemManagerProgramParams & { institutionVolunteerId: string }>()
);
export const VolunteerTypesDeleteSuccessAction = createAction(
    '[volunteer types] Delete volunteer success type',
    props<IInstitutionSystemManagerProgramParams & { institutionVolunteerId: string }>()
);
export const VolunteerTypesDeleteErrorAction = createAction(
    '[volunteer types] Delete volunteer error type',
    props<{ error: HttpErrorResponse }>()
);


export const VolunteerTypesAssociateConsentAction = createAction(
    '[volunteer types] Associate consent volunteer type',
    props<IInstitutionSystemManagerProgramParams & { institutionVolunteerId: string, consentTypeId: string }>()
);
export const VolunteerTypesAssociateConsentSuccessAction = createAction(
    '[volunteer types] Associate consent volunteer success type',
    props<IInstitutionSystemManagerProgramParams & { institutionVolunteerId: string, consentTypeId: string }>()
);
export const VolunteerTypesAssociateConsentErrorAction = createAction(
    '[volunteer types] Associate consent volunteer error type',
    props<{ error: HttpErrorResponse }>()
);

export const VolunteerTypesRemoveConsentAssociationAction = createAction(
    '[volunteer types] Remove consent association volunteer type',
    props<IInstitutionSystemManagerProgramParams & { institutionVolunteerId: string, consentTypeId: string }>()
);
export const VolunteerTypesRemoveConsentAssociationSuccessAction = createAction(
    '[volunteer types] Remove consent association volunteer success type',
    props<IInstitutionSystemManagerProgramParams & { institutionVolunteerId: string, consentTypeId: string }>()
);
export const VolunteerTypesRemoveConsentAssociationErrorAction = createAction(
    '[volunteer types] Remove consent association volunteer error type',
    props<{ error: HttpErrorResponse }>()
);