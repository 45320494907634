import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { IUnitDto } from 'app/core/models';
import { BehaviorSubject, combineLatest, map, Observable } from 'rxjs';

@Component({
  selector: 'ng4h-clubs-multiselect-autocomplete',
  templateUrl: './clubs-multiselect-autocomplete.component.html',
  styleUrls: ['./clubs-multiselect-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClubsMultiselectAutocompleteComponent implements OnInit, OnChanges {

  @Input() clubs$: Observable<IUnitDto[]>;
  @Input() selectedClubs: string[]; // hierarchy node ids
  @Input() disabled: boolean;
  @Output() selectedChanged: EventEmitter<IUnitDto[]> = new EventEmitter();

  public selectedClubs$: Observable<string[]>;

  private _selectedClubs$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  ngOnInit() {

    this.selectedClubs$ = combineLatest([this.clubs$, this._selectedClubs$]).pipe(
      map(([clubs, selectedClubs]) => {
        if (clubs == null || selectedClubs == null || clubs.length < 1) {
          return [];
        }
        const sc = clubs.filter(club => selectedClubs.includes(club.hierarchyNodeId));
        this.selectedChanged.emit(sc);
        return sc.map(club => club.unitName);
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedClubs) {
      this._selectedClubs$.next(changes.selectedClubs.currentValue);
    }
  }
}
