import { ICreditDto } from './ICreditDto';

export interface ICreditSearchResultsDto {
    keyword?: string;
    results?: ICreditDto[];
    resultsCount: number;
    pageSize?: number;
    pageIndex?: number;
    pageCount: number;
    startingResultRangeRecordIndex?: number;
    endingResultRangeRecordIndex?: number;
}