import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  eEnrollmentRoleType,
  eUnitType,
  eVolunteerType,
  IEnrollmentDto,
  IEnrollmentProjectDto,
  IInstitutionVolunteerDto,
} from 'app/core/models';
import { CommonToastrService, EnrollmentService, ModalService, RouterService } from 'app/core/services';
import { combineLatest, filter, map, Observable, switchMap, take } from 'rxjs';

@Component({
  selector: 'ng4h-modal-manager-edit-enrollment-project',
  templateUrl: './modal-manager-edit-enrollment-project.component.html',
  styleUrls: ['./modal-manager-edit-enrollment-project.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalManagerEditEnrollmentProjectComponent implements OnInit {
  public eEnrollmentRoleType = eEnrollmentRoleType;
  public eUnitType = eUnitType;
  public enrollment$: Observable<IEnrollmentDto>;
  public volunteerTypes$: Observable<IInstitutionVolunteerDto[]>;
  private volunteerCategories$: Observable<IInstitutionVolunteerDto[]>;
  public volunteerType: IInstitutionVolunteerDto;
  public yearsInProject: number;

  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: { project: IEnrollmentProjectDto, canEdit?: boolean },
    private routerService: RouterService,
    private enrollmentService: EnrollmentService,
    private modalService: ModalService,
    private toastrService: CommonToastrService
  ) { }

  ngOnInit(): void {
    this.yearsInProject = this.data.project.yearsInProject;

    this.enrollment$ = this.enrollmentService.getEnrollment({
      institutionId: this.routerService.institutionId,
      memberId: this.routerService.memberId ? this.routerService.memberId : this.routerService.selectedMemberId,
      programId: this.routerService.programId,
      enrollmentId: this.routerService.enrollmentId
    });
    this.volunteerCategories$ = this.enrollmentService.getVolunteerTypes({
      institutionId: this.routerService.institutionId,
      memberId: this.routerService.memberId ? this.routerService.memberId : this.routerService.selectedMemberId,
      programId: this.routerService.programId,
      enrollmentId: this.routerService.enrollmentId
    });

    this.volunteerTypes$ = combineLatest([this.enrollment$, this.volunteerCategories$]).pipe(
      map(([enrollment, volunteerCategories]) => {
        if (enrollment.enrollmentRole === eEnrollmentRoleType.Volunteer) {
          return enrollment.volunteerTypes.filter(v => {
            if (enrollment.enrollmentRole === eEnrollmentRoleType.Volunteer) {
              return v.volunteerTypeDescriptor.volunteerType === eVolunteerType.AdultProjectVolunteer;
            }
          });
        } else if (enrollment.enrollmentRole === eEnrollmentRoleType.ClubMember) {
          return volunteerCategories.filter(volunteerType => {
            return volunteerType.volunteerTypeDescriptor.volunteerType === eVolunteerType.YouthProjectVolunteer;
          });
        }
      })
    );

    this.enrollment$.pipe(
      filter(enrollment => enrollment.enrollmentRole === eEnrollmentRoleType.Volunteer),
      switchMap(() => this.volunteerTypes$),
      filter(volunteerTypes => Array.isArray(volunteerTypes) && volunteerTypes.length > 0),
      take(1))
      .subscribe(volunteerTypes => {
        if (Array.isArray(volunteerTypes) && volunteerTypes.length === 1) {
          this.volunteerTypeChanged(volunteerTypes[0]);
        }
      });
  }

  public save() {


    this.enrollmentService.saveProject({
      institutionId: this.routerService.institutionId,
      memberId: this.routerService.memberId ? this.routerService.memberId : this.routerService.selectedMemberId,
      programId: this.routerService.programId,
      enrollmentId: this.routerService.enrollmentId,
      projectSubmission: {
        countyAreaId: null,
        projectId: this.data.project.projectId,
        unitId: this.data.project.unitId,
        volunteerTypeId: this.volunteerType?.volunteerTypeId,
        consents: [],
        yearsInProject: this.yearsInProject
      }
    }).pipe(
      take(1),
      switchMap(() => this.enrollmentService.refreshEnrollment()),
      take(1),
      this.modalService.showProgressSpinnerModalUntilComplete(),
    ).subscribe({
      next: () => {
        this.close();
        this.toastrService.success('Saved');
      }, error: (error) => {
        this.toastrService.error('Could not save.', error);
      }
    });
  }


  public close(result?: any): void {
    this.dialogRef.close(result);
  }

  public volunteerTypeChanged(volunteerType: IInstitutionVolunteerDto) {
    this.volunteerType = volunteerType;
  }
}
