import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { getGroup, getGroups, ManagerFeatureStore } from 'app/core/containers/admin/manager/manager-feature.reducer';
import { ManagerGroupsActions } from 'app/core/containers/admin/manager/store/groups';
import {
  IGroupCreateDto,
  IGroupDto,
  IGroupUpdateDto,
  IInstitutionManagerProgramHierarchyParams,
  IInstitutionManagerProgramParams
} from 'app/core/models';
import { filter, map, Observable, of, take, tap } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ManagerGroupsService {

  constructor(private httpClient: HttpClient, private store: Store<ManagerFeatureStore>, private dispatcher: ActionsSubject) {
  }

  public getAllGroups(params: IInstitutionManagerProgramHierarchyParams): Observable<IGroupDto[]> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getGroups(params)),
      tap(groups => {
        if (groups === undefined) {
          this.store.dispatch(ManagerGroupsActions.LoadGroupsAction(params));
        }
      }),
      filter(groups => groups != null)
    );
  }

  public getAllGroupsEffect({
    institutionId,
    managerId,
    programId,
    hierarchyNodeId
  }: IInstitutionManagerProgramHierarchyParams) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/groups?hierarchyNodeId=${hierarchyNodeId}`) as Observable<IGroupDto[]>;
  }

  public getGroup(params: IInstitutionManagerProgramParams & { groupId: string }): Observable<IGroupDto> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getGroup(params)),
      tap(group => {
        if (group === undefined) {
          this.store.dispatch(ManagerGroupsActions.LoadGroupAction(params));
        }
      }),
      filter(group => group != null)
    );
  }

  public getGroupEffect({
    institutionId,
    managerId,
    programId,
    groupId
  }: IInstitutionManagerProgramParams & { groupId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/groups/${groupId}`) as Observable<IGroupDto>;
  }

  public createGroup(params: IInstitutionManagerProgramParams & { groupCreate: IGroupCreateDto }) {
    this.store.dispatch(ManagerGroupsActions.CreateGroupAction(params));
    return this.dispatcher.pipe(
      ofType(ManagerGroupsActions.CreateGroupSuccessAction, ManagerGroupsActions.CreateGroupErrorAction),
      take(1),
      map(action => {
        if (action.type === ManagerGroupsActions.CreateGroupSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public createGroupEffect({
    institutionId,
    managerId,
    programId,
    groupCreate
  }: IInstitutionManagerProgramParams & { groupCreate: IGroupCreateDto }) {
    return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/groups`, groupCreate);
  }

  public updateGroup(params: IInstitutionManagerProgramParams & { groupId: string, groupUpdate: IGroupUpdateDto }) {
    this.store.dispatch(ManagerGroupsActions.UpdateGroupAction(params));
    return this.dispatcher.pipe(
      ofType(ManagerGroupsActions.UpdateGroupSuccessAction, ManagerGroupsActions.UpdateGroupErrorAction),
      take(1),
      map(action => {
        if (action.type === ManagerGroupsActions.UpdateGroupSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateGroupEffect({
    institutionId,
    managerId,
    programId,
    groupId,
    groupUpdate
  }: IInstitutionManagerProgramParams & { groupId: string, groupUpdate: IGroupUpdateDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/groups/${groupId}`, groupUpdate);
  }

  public deleteGroup(params: IInstitutionManagerProgramParams & { groupId: string }) {
    this.store.dispatch(ManagerGroupsActions.DeleteGroupAction(params));
    return this.dispatcher.pipe(
      ofType(ManagerGroupsActions.DeleteGroupSuccessAction, ManagerGroupsActions.DeleteGroupErrorAction),
      take(1),
      map(action => {
        if (action.type === ManagerGroupsActions.DeleteGroupSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteGroupEffect({
    institutionId,
    managerId,
    programId,
    groupId
  }: IInstitutionManagerProgramParams & { groupId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/groups/${groupId}`);
  }
}
