import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';

@Component({
  selector: 'ng4h-filter-group',
  templateUrl: './filter-group.component.html',
  styleUrls: ['./filter-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterGroupComponent implements OnInit {

  @Input() columns: Array<any>;
  @Input() filters: Array<any>;

  public form: FormGroup;
  public filtersForm: FormArray;

  get filtersArray() {
    return this.form.get('items') as FormArray;
  }

  constructor(private fb: FormBuilder) { }

  public ngOnInit() {

    this.form = this.fb.group({
      items: this.fb.array([
        this.createFilter()
      ])
    });

    this.filtersForm = this.fb.array([
      this.createFilter()
    ]);
  }

  public createFilter(): FormGroup {
    return this.fb.group({
      column: '',
      filter: ''
    });
  }

  public addFilter(): void {
    this.filtersArray.push(this.createFilter());
  }
}
