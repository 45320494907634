<form [formGroup]="form">
  <div>
    <h4 class="ng4h-bg-secondary">
      Details
    </h4>
    <div class="ng4h-panel-body">
      <div class="row">
        <div>
          <label class="form-control-label">Name</label>
          <input class="form-control" type="text" formControlName="trainingName">
        </div>
        <div>
          <label class="form-control-label">Mode</label>
          <input class="form-control" type="text" formControlName="trainingMode">
        </div>
      </div>
    </div>
  </div>

  <div>
    <h4 class="ng4h-bg-secondary">
      Lessons
    </h4>
    <div class="ng4h-panel-body">
      <div class="lessons">
        <div *ngIf="training.courses.length === 0 || training.courses[0].lessons.length === 0">
          No Lessons
        </div>
        <div *ngIf="training.courses.length > 0 && training.courses[0].lessons.length > 0" cdkDropList>
          <div class="d-flex flex-column lesson-row" *ngFor="let lesson of training.courses[0].lessons"
            (click)="lessonRowClick(lesson)">
            {{ lesson.trainingLessonTitle }}
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

<div class="modal-footer" *ngIf="!readonly">
  <button id (click)="cancel()" class="btn btn-secondary my-2 my-sm-0" role="button"
    aria-pressed="true">Cancel</button>
  <button [disabled]="form.invalid" (click)="save()" class="btn btn-primary my-2 my-sm-0" role="button"
    aria-pressed="true">Save</button>
</div>
