import { Action, createReducer, on } from '@ngrx/store';
import { IInstitutionMemberProgramModel, IMemberHealthFormDto } from 'app/core/models';

import { MemberHealthFormsActions } from '.';
import { mergeImmutable } from '../utils';



export interface MemberHealthFormsState {
  healthForms: IInstitutionMemberProgramModel<{ [healthFormType: string]: IMemberHealthFormDto }>;
}

const initialState: MemberHealthFormsState = {
  healthForms: undefined,
};

const reducer = createReducer(
  initialState,
  on(MemberHealthFormsActions.HealthFormLoadSuccessAction, (state, { institutionId, memberId, programId, healthFormType, healthForm }) => {
    const newHealthFormByProgram = mergeImmutable(
      { [institutionId]: { [memberId]: { [programId]: { [healthFormType]: healthForm } } } },
      state.healthForms
    );
    return {
      ...state,
      healthForms: newHealthFormByProgram
    };
  }),
  on(MemberHealthFormsActions.HealthFormInvalidateCacheAction, (state, { institutionId, memberId, programId, healthFormType }) => {
    const newHealthFormByProgram = mergeImmutable(
      { [institutionId]: { [memberId]: { [programId]: { [healthFormType]: undefined } } } },
      state.healthForms
    );
    return {
      ...state,
      healthForms: newHealthFormByProgram
    };
  }),
  on(MemberHealthFormsActions.HealthFormInvalidateMedicationCacheAction, (state, { }) => {
    return {
      ...state,
      healthForms: undefined
    };
  })
);

export function memberHealthFormReducer(state: MemberHealthFormsState | undefined, actions: Action) {
  return reducer(state, actions);
}
export const healthForms = (state: MemberHealthFormsState) => state.healthForms;
