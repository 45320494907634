import { IMemberDashboardProgram } from '..';

export interface IMemberDashboard {
  memberId: string;
  status: string;
  birthDate: string;
  firstName: string;
  lastName: string;
  memberPrograms: IMemberDashboardProgram[];
}
