import { Pipe, PipeTransform } from '@angular/core';
import { IProgramYearDto } from 'app/core/models';
import { programYearLookup } from 'app/shared/utils';

@Pipe({
    name: 'programYear',
    pure: true
})
export class ProgramYearPipe implements PipeTransform {
    constructor() {
    }

    public transform(programYearId: string): IProgramYearDto {
        return programYearLookup(programYearId);
    }
}
