import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ITrainingDto, eTrainingMode } from 'app/core/models';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'ng4h-modal-edit-training',
  templateUrl: './modal-edit-training.component.html',
  styleUrls: ['./modal-edit-training.component.scss']
})

export class ModalEditTrainingComponent implements OnInit {

  public training: ITrainingDto;
  public form: FormGroup;

  public eTradingMode = eTrainingMode;

  constructor(private dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder) { }

  ngOnInit() {
    this.training = this.data.training;

    this.form = this.fb.group({
      trainingId: [this.training.trainingId],
      trainingName: [this.training.trainingName],
      trainingMode: [this.training.trainingMode]
    });
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public save(): void {
    this.dialogRef.close(this.form.value);
  }
}
