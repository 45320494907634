import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AutoUnsubscribe, eAccessControlListRoleType, IHierarchyAny, IManagerCreateDto } from 'app/core/models';
import { HierarchyService, ManagerService, RouterService } from 'app/core/services';
import { CommonToastrService } from 'app/core/services/common-toastr.service';
import { filter, map, Observable, switchMap, take, takeUntil } from 'rxjs';

@Component({
  selector: 'ng4h-modal-add-manager',
  templateUrl: './modal-add-manager.component.html',
  styleUrls: ['./modal-add-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalAddManagerComponent extends AutoUnsubscribe implements OnInit {


  public userForm: FormGroup;
  public permissionForm: FormGroup;

  public email: string;

  // public institutionNode$: Observable<IHierarchyAny>;
  public selectedLeafNode$: Observable<IHierarchyAny>;

  public eAccessControlListRoleType = eAccessControlListRoleType;

  constructor(private dialogRef: MatDialogRef<any>, private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { email: string, useraccounttoken?: string, firstName?: string, lastName?: string }, private hierarchyService: HierarchyService, private managerService: ManagerService,
    private routerService: RouterService, private toastrService: CommonToastrService) {
    super();
  }

  public ngOnInit(): void {

    // this.institutionNode$ = this.hierarchyService.selectedInstitutionArea$.pipe(takeUntil(this.autoUnsubscribe));
    this.selectedLeafNode$ = this.hierarchyService.selectedLeafNode$.pipe(takeUntil(this.autoUnsubscribe));

    this.userForm = this.fb.group({
      email: [{ value: this.data.email, disabled: true }],
      firstName: [this.data.firstName, Validators.required],
      lastName: [this.data.lastName, Validators.required],
      institutionId: [''],
      programId: [''],
      role: [eAccessControlListRoleType.HierarchyStaff, [Validators.required]]
    });

    // this.permissionForm = this.fb.group({
    //   animalIdentification: [],
    //   healthForm: [],
    //   volunteerScreening: [],
    //   enrollmentForm: [],
    //   programSettings: [],
    //   managers: [],
    //   consents: [],
    //   trainings: [],
    //   units: [],
    //   projects: []
    // });
  }

  public close(): void {
    this.dialogRef.close();
  }

  public add(): void {
    const formValue = this.userForm.value;

    this.selectedLeafNode$.pipe(
      filter(node => node != null),
      take(1),
      map(selectedNode => selectedNode.hierarchyNodeId),
      switchMap(hierarchyNodeId => {
        const manager: IManagerCreateDto = {
          email: this.data.email,
          firstName: formValue.firstName,
          lastName: formValue.lastName,
          useraccountToken: this.data.useraccounttoken,
          initialHierarchyNode: {
            hierarchyNodeId: hierarchyNodeId,
            permissionRole: formValue.role,
            programId: this.routerService.programId
          }
        };
        return this.managerService.createManagerAsSystemManager({
          institutionId: this.routerService.institutionId,
          systemManagerId: this.routerService.systemManagerId,
          programId: this.routerService.programId,
          newManager: manager
        });
      })
    ).subscribe({
      next: () => {
        this.toastrService.success('manager created');
        this.close();
      }, error: (error) => {
        this.toastrService.error('manager creation failed');
      }
    });

  }
}
