import { IEventRegistrationTypeDescriptorDto, IInvoiceItemDto } from '.';
import { eEventSessionType } from '..';

export interface IEventSessionDto {
    eventSessionId: string;
    currentRegistrations: number;
    fee: IInvoiceItemDto;
    registrationTypes?: IEventRegistrationTypeDescriptorDto[];
    maximumRegistrations?: number;
    label?: string;
    description?: string;
    type: eEventSessionType;
}
