import { IHierarchyNodeDto, IInstitutionProfile, IMemberProgramYearApprovalStateDto } from '.';
import {
    eEnrollmentRoleType,
    eEnrollmentStatus,
    eMemberProgramStatus,
    eMemberProgramYearStatus,
    eProfileType,
    eSchoolGradeType
} from '..';
import { eMemberParticipationStatus, eMemberRole } from '../enums';
import { IProgramYearDto } from './IProgramYearDto';

export interface IMemberSearchResultDto extends IInstitutionProfile {
    flagged: boolean;
    familyMemberId: string;
    familyId: string;
    familyName?: string;
    middleName?: string;
    fullNameFirstLast?: string;
    fullNameLastFirst?: string;
    preferredName?: string;
    mobilePhone?: string;
    workPhone?: string;
    emergencyContact1Email?: string;
    emergencyContact1Phone?: string;
    guardian1FirstName?: string;
    guardian1LastName?: string;
    guardian1Phone?: string;
    guardian1WorkPhone?: string;
    guardian2FirstName?: string;
    guardian2LastName?: string;
    guardian2Phone?: string;
    guardian2WorkPhone?: string;
    enrollmentCounty: IHierarchyNodeDto;
    enrollmentId: string;
    enrollmentRole?: eEnrollmentRoleType;
    enrollmentStatus?: eEnrollmentStatus;
    lastActiveEnrollmentProgramYear: IProgramYearDto;
    lastActiveEnrollmentStatus?: eEnrollmentStatus;
    memberProgramStatus?: eMemberProgramStatus;
    memberProgramYearStatus?: eMemberProgramYearStatus;
    programAge?: number;
    primaryUnitId: string;
    primaryUnitName?: string;
    enrollmentDateSubmitted?: string;
    enrollmentDateResubmitted?: string;
    enrollmentDateApproved?: string;
    enrollmentDateSentBack?: string;
    enrollmentDateRejected?: string;
    approvalState: IMemberProgramYearApprovalStateDto;
    participationStatus?: eMemberParticipationStatus;
    memberId: string;
    firstName?: string;
    lastName?: string;
    memberRole: eMemberRole;
    birthDate: string;
    memberIdent: number;
    profileId: string;
    profileType: eProfileType;
    email?: string;
    institutionId: string;
    institutionProfileId: string;
    profileToken: string;
    institutionProfileIdent: number;

    membershipId: number;
    approvalCounty: IHierarchyNodeDto;
    familyCounty: IHierarchyNodeDto;
    programYear: IProgramYearDto;
    schoolGrade?: eSchoolGradeType;
    memberIdInt: number;
    membershipIdent: number;
    sitePassword?: string;
    institutionIdent: number;
    profileIdent: number;
}
