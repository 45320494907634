import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { filter, map, Observable } from 'rxjs';

@Pipe({
    name: 'image$',
    pure: true
})
export class ImagePipe implements PipeTransform {
    constructor(private httpClient: HttpClient, private sanitizer: DomSanitizer) {
    }

    public transform(path: string): Observable<SafeUrl> {
        return this.httpClient.get(path, { responseType: 'blob' }).pipe(
            filter(blob => blob != null),
            map(blob => {
                return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));
            })
        );
    }
}
