import { eActiveRecordModificationRule, eAnimalMarketCategory, eAnimalSpecies } from '..';

export interface IAnimalTypeCreateDto {
    animalSpecies: eAnimalSpecies;
    marketCategory: eAnimalMarketCategory;
    animalTypeLabel?: string;
    animalTypeDescription?: string;
    identifierField?: string;
    weightIn: boolean;
    persistAcrossYears: boolean;
    turnOnStateShowEligibility: boolean;
    // requireMemberVerification: boolean;
    allowMembersToAddRecords: boolean;
    allowMemberToModifyRecords: boolean;
    activeRecordsMayNotBeModifiedByMember: boolean;
    activeRecordsMayNotBeModifiedByMemberRule?: eActiveRecordModificationRule;
    allowMemberToChangeAssociatedMember: boolean;
    memberModificationStartMonth: number;
    memberModificationStartDay: number;
    memberModificationEndMonth: number;
    memberModificationEndDay: number;
    activeRecordsMayNotBeModifiedByManager: boolean;
    activeRecordsMayNotBeModifiedByManagerRule?: eActiveRecordModificationRule;
    allowManagerToChangeAssociatedMember: boolean;
    managerModificationStartMonth: number;
    managerModificationStartDay: number;
    managerModificationEndMonth: number;
    managerModificationEndDay: number;


    autoApproveOnSubmit: boolean;
    animalMaxPerMember: number;

    pageMessage_Manager?: string;
    pageMessage_Member?: string;
}
