import { eFormControlType } from '.';
import { IDynamicItem } from './IDynamicItem';
import { eHierarchyNodeType } from './serverDTOs/Hierarchy/eHierarchyNodeType';



export interface IDynamicControl {
  controlId: string;
  type: eFormControlType;
  label: string;
  displayTo: number[];
  attributes: {
    required?: boolean,
    class?: string,
    min?: number,
    max?: number,
    multipleChoiceMinAllowedSelection?: number,
    multipleChoiceMaxAllowedSelection?: number
  };
  validators: any[];
  items: IDynamicItem[];
  managerField: boolean;
  managerField_MinimumViewHierarchyType?: eHierarchyNodeType;
  managerField_MinimumEditHierarchyType?: eHierarchyNodeType;
  reportValue?: string;
  archived: boolean;
}
