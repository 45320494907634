<div *ngIf="programYear$ | async">
  <div class="modal-header">
    <h5 class="modal-title">Add Event</h5>
    <button (click)="close()" type="button" class="close" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form *ng4hPlaceholder="form; size: [100, 200]" [formGroup]="form">
      <div class="form-group row">
        <label class="col-md-4 form-control-label ng4h-required">Event Name</label>
        <div class="col-md-8">
          <input formControlName="eventName" type="text" class="form-control">
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-4 form-control-label ng4h-required">Event Description</label>
        <div class="col-md-8">
          <textarea formControlName="eventDescription" class="form-control"></textarea>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-4 form-control-label ng4h-required">Program Year</label>
        <div class="col-md-8">
          <ng4h-program-year-dropdown formControlName="programYear"></ng4h-program-year-dropdown>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-4 form-control-label ng4h-required">Start Date</label>
        <div class="input-group col-md-8">
          <input class="form-control" formControlName="startDate" [matDatepicker]="startDate"
            placeholder="Event start date">
          <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
        </div>
        <mat-datepicker #startDate></mat-datepicker>
      </div>
      <div class="form-group row ">
        <label class="col-md-4 form-control-label ng4h-required">End Date</label>
        <div class="input-group col-md-8">
          <input class="form-control" formControlName="endDate" [matDatepicker]="endDate" placeholder="Event end date">
          <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
        </div>
        <mat-datepicker #endDate></mat-datepicker>
      </div>

    </form>
  </div>
  <div class="modal-footer">
    <button [disabled]="form.invalid" (click)="add()" class="btn btn-primary my-2 my-sm-0" role="button"
      aria-pressed="true">Add</button>
  </div>
</div>