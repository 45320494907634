import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { eRegistrationRoleType } from 'app/core/models';

@Component({
  selector: 'ng4h-registration-role-dropdown',
  templateUrl: './registration-role-dropdown.component.html',
  styleUrls: ['./registration-role-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RegistrationRoleDropdownComponent),
    multi: true
  }]
})
export class RegistrationRoleDropdownComponent implements ControlValueAccessor, AfterViewInit {

  @Input() value = '';
  @Input() formClass = '';
  public eRegistrationRoleType = eRegistrationRoleType;

  onChange: any = () => { };
  onTouched: any = () => { };

  constructor(private cdref: ChangeDetectorRef) { }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  writeValue(value) {
    if (value != null) {
      this.value = value;
      this.onChange(value);
      this.onTouched();
    }
  }

  ngAfterViewInit() {
    if (this.value) {
      // This is a total hack but I can't get it to not throw ExpressionChangedAfterItHasBeenCheckedError without it
      setTimeout(() => {
        this.onChange(this.value);
        this.cdref.detectChanges();
      }, 0);
    }
  }

}
