import { eItemType } from './eItemType';

export interface IItemCreateDto {
    itemType: eItemType;
    itemName: string;
    itemDescription: string;
    itemStartDateTime: string;
    itemEndDateTime: string;
    quantity: number;
    location?: string;
    costInBaseUnit: number;
}
