import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { ProfileService, RouterService } from 'app/core/services';
import { filter, map, Observable, switchMap, take } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProfileGuard implements CanActivate {
  constructor(private profileService: ProfileService, private routerService: RouterService) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.routerService.loggedInInstitutionProfileId$.pipe(
      switchMap(loggedInInstitutionProfileId => {
        return this.profileService.getInstitutionProfileAuthToken(loggedInInstitutionProfileId);
      }),
      filter(token => {
        return token != null;
      }),
      map(token => true),
      take(1)
    );
  }
}
