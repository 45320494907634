import { Pipe, PipeTransform } from '@angular/core';
import { eSchoolGradeType } from 'app/core/models';

@Pipe({
    name: 'schoolGrade',
    pure: true
})
export class SchoolGradePipe implements PipeTransform {
    constructor() {
    }

    public transform(schoolGrade: eSchoolGradeType): string {
        switch (schoolGrade) {
            case eSchoolGradeType.PreK:
                return 'Pre-K';
            case eSchoolGradeType.Kindergarten:
                return 'Kindergarten';
            case eSchoolGradeType.NotInSchool:
                return 'Not In School';
            case eSchoolGradeType.PostHighSchoolEducation:
                return 'Post Highschool Education';
            case eSchoolGradeType.Special:
                return 'Special';
            case eSchoolGradeType._1:
                return '1';
            case eSchoolGradeType._2:
                return '2';
            case eSchoolGradeType._3:
                return '3';
            case eSchoolGradeType._4:
                return '4';
            case eSchoolGradeType._5:
                return '5';
            case eSchoolGradeType._6:
                return '6';
            case eSchoolGradeType._7:
                return '7';
            case eSchoolGradeType._8:
                return '8';
            case eSchoolGradeType._9:
                return '9';
            case eSchoolGradeType._10:
                return '10';
            case eSchoolGradeType._11:
                return '11';
            case eSchoolGradeType._12:
                return '12';
        }
    }
}
