<div class="modal-header">
    <h5 class="modal-title">Invoice</h5>
    <button (click)="close()" type="button" class="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" *ngIf="invoice$ | async as invoice">
    <ng-container *ngIf="member$ | async as member">
        <h2 class="ng4h-center-text">{{ member.firstName }} {{ member.lastName }}</h2>

        <ng-container *ngIf="eventRegistration$ | async as eventRegistration">
            <h3 class="ng4h-center-text">
                {{ (eventRegistration.eventDescription.programYear | programYear).programYearForDisplay }}{{ eventRegistration.eventDescription.eventName }}</h3>
            <h4 class="ng4h-center-text ng4h-margin-bottom">{{ invoice.dateCreated | date }}</h4>


            <div class="row">
                <div class="col-6">
                    <h2><b>Balance Due: {{ invoice.amountOutstanding | currency }}</b></h2>
                </div>
                <div class="col-6" *ngIf="invoice.amountDue > 0">
                    <button *ngIf="eventRegistration.creditsEnabled == true" class="btn btn-primary float-right"
                            (click)="applyCredit(invoice, member)">Pay By Check</button>
                    <button class="btn btn-secondary float-right ng4h-margin-right" (click)="waiveFees()">Waive
                        Fees</button>
                </div>
            </div>
        </ng-container>
    </ng-container>


    <div class="ng4h-margin-top">
        <h4>Line Items</h4>
        <ng4h-table [columns]="lineItemColumns" [dataSource]="lineItems$" (rowClicked)="rowClicked($event)"
                    [columnTransforms]="lineItemColumnTransforms"></ng4h-table>
    </div>

    <div class="ng4h-margin-top">
        <h4>Payments</h4>
        <ng4h-table [columns]="paymentsColumns" [dataSource]="payments$" (rowClicked)="rowClicked($event)"
                    [columnTransforms]="paymentsColumnTransforms"></ng4h-table>
    </div>

    <form *ngIf="invoice.amountDue > 0" [formGroup]="couponCodeForm" class="ng4h-margin-top">
        <div class="form-group row">
            <div class="col-12">
                <div class="row no-gutters coupon-input-row">
                    <label class="form-control-label col-md-3">Coupon Code</label>
                    <div class="col-md-6">
                        <input formControlName="couponCode" class="form-control" type="text">
                    </div>
                    <div class="col-3">
                        <button class="btn btn-primary float-right" (click)="applyCoupon()"
                                [disabled]="couponCodeForm.invalid">Apply</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
  
  <!-- <form [formGroup]="form">
      <div class="form-group row">
          <label class="form-control-label col-md-3 ng4h-required">Coupon Code</label>
          <div class="col-md-9">
              <input formControlName="couponCode" class="form-control" type="text">
          </div>
      </div>
  </form> -->
  <!-- <pre>
  {{invoice$ | async | json}}
</pre> -->
</div>
<div class="modal-footer">
    <!-- <button class="btn btn-primary" (click)="save()">Close</button> -->
    <button *ngIf="pendingTransactions$ | async" class="btn btn-primary" (click)="processPayment()">Process Payment</button>
    <button class="btn btn-secondary" (click)="close()">Close</button>
</div>