<div class="modal-section" *ngIf="form">
    <div class="modal-header">
        <h5 class="modal-title">Edit Enrollment Restrictions</h5>
        <button (click)="close()" type="button" class="close" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="form">
            <div class="form-group row">
                <label class="form-control-label col-md-3">Maximum Enrollments</label>
                <div class="col-md-9">
                    <input class="form-control" type="number" formControlName="maximumEnrollments">
                </div>
            </div>
            <div class="form-group row">
                <label class="form-control-label col-md-3">Allow Members To Register</label>
                <mat-checkbox class="col-md-Edit Unit
9" formControlName="allowMembersToRegister">
                </mat-checkbox>
            </div>
            <div class="form-group row">
                <label class="form-control-label col-md-3">Allow Members To See Their Registration</label>
                <mat-checkbox class="col-md-Edit Unit
9" formControlName="allowMembersToSeeTheirRegistration">
                </mat-checkbox>
            </div>
        </form>
    </div>


    <div class="modal-footer">
        <button id (click)="close()" class="btn btn-secondary my-2 my-sm-0" role="button"
            aria-pressed="true">Cancel</button>
        <button [disabled]="form.invalid" (click)="save()" class="btn btn-primary my-2 my-sm-0" role="button"
            aria-pressed="true">Save</button>
    </div>
</div>