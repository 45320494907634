import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { AppState } from 'app/app.reducers';
import {
  getAnimalTypes,
  getSubmittedEnrollmentAnimals
} from 'app/core/containers/admin/manager/manager-feature.reducer';
import { ManagerAnimalActions } from 'app/core/containers/admin/manager/store/animals';
import {
  IAnimalManagerCommentDto,
  IAnimalTypeDto,
  IInstitutionManagerProgramParams,
  IInstitutionMemberProgramEnrollmentParams,
  IInstitutionProgramParams
} from 'app/core/models';
import { IEnrollmentAnimalDescriptorDto } from 'app/core/models/serverDTOs/IEnrollmentAnimalDescriptorDto';
import { filter, map, Observable, of, take, tap } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ManagerAnimalsService {

  constructor(
    private store: Store<AppState>,
    private httpClient: HttpClient,
    private dispatcher: ActionsSubject
  ) {
  }

  public getAnimalTypes(params: IInstitutionProgramParams) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getAnimalTypes(params)),
      tap(animalTypes => {
        if (animalTypes === undefined) {
          this.store.dispatch(ManagerAnimalActions.ManagerGetAnimalTypesAction(params));
        }
      }),
      filter(animalTypes => animalTypes != null)
    );
  }

  public getAnimalTypesEffect({ institutionId, programId }: IInstitutionProgramParams) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/programs/${programId}/animal-types`) as Observable<IAnimalTypeDto[]>;
  }

  public approveAnimal(params: IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string, managerId: string, comments: IAnimalManagerCommentDto }) {
    this.store.dispatch(ManagerAnimalActions.ApproveAnimalAction(params));

    return this.dispatcher.pipe(
      ofType(ManagerAnimalActions.ApproveAnimalSuccessAction, ManagerAnimalActions.ApproveAnimalErrorAction),
      take(1),
      map(action => {
        if (action.type === ManagerAnimalActions.ApproveAnimalSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public approveAnimalEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    enrollmentAnimalId,
    managerId,
    comments
  }: IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string, managerId: string, comments: IAnimalManagerCommentDto }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/animals/${enrollmentAnimalId}/approve?managerId=${managerId}`, comments);
  }

  public sendAnimalBack(params: IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string, managerId: string, comments: IAnimalManagerCommentDto }) {
    this.store.dispatch(ManagerAnimalActions.SendBackAnimalAction(params));

    return this.dispatcher.pipe(
      ofType(ManagerAnimalActions.SendBackAnimalSuccessAction, ManagerAnimalActions.SendBackAnimalErrorAction),
      take(1),
      map(action => {
        if (action.type === ManagerAnimalActions.SendBackAnimalSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public sendBackAnimalEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    enrollmentAnimalId,
    managerId,
    comments
  }: IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string, managerId: string, comments: IAnimalManagerCommentDto }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/animals/${enrollmentAnimalId}/send-back?managerId=${managerId}`, comments);
  }

  // public rejectAnimal(params: IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string, managerId: string, comments: IAnimalManagerCommentDto }) {
  //   this.store.dispatch(ManagerAnimalActions.RejectAnimalAction(params));

  //   return this.dispatcher.pipe(
  //     ofType(ManagerAnimalActions.RejectAnimalSuccessAction, ManagerAnimalActions.RejectAnimalErrorAction),
  //     take(1),
  //     map(action => {
  //       if (action.type === ManagerAnimalActions.RejectAnimalSuccessAction.type) {
  //         return action;
  //       } else {
  //         throw action.error;
  //       }
  //     })
  //   );
  // }
  // public rejectAnimalEffect({ institutionId, memberId, programId, enrollmentId, enrollmentAnimalId, managerId, comments }: IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string, managerId: string, comments: IAnimalManagerCommentDto }) {
  //   return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/animals/${enrollmentAnimalId}/reject?managerId=${managerId}`, comments);
  // }

  public getSubmittedEnrollmentAnimals(params: IInstitutionManagerProgramParams & { countyAreaId: string }): Observable<IEnrollmentAnimalDescriptorDto[]> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getSubmittedEnrollmentAnimals(params)),
      tap(submittedAnimal => {
        if (submittedAnimal === undefined) {
          this.store.dispatch(ManagerAnimalActions.GetSubmittedEnrollmentAnimalsAction(params));
        }
      }),
      filter(submittedAnimal => submittedAnimal != null)
    );
  }

  public getSubmittedEnrollmentAnimalsEffect({
    institutionId,
    managerId,
    programId,
    countyAreaId
  }: IInstitutionManagerProgramParams & { countyAreaId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/animals/submitted?countyAreaId=${countyAreaId}`) as Observable<IEnrollmentAnimalDescriptorDto[]>;
  }

  public updateAnimalComment(params: IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string, managerComment: IAnimalManagerCommentDto }) {
    this.store.dispatch(ManagerAnimalActions.UpdateAnimalCommentAction(params));

    return this.dispatcher.pipe(
      ofType(ManagerAnimalActions.UpdateAnimalCommentSuccessAction, ManagerAnimalActions.UpdateAnimalCommentErrorAction),
      take(1),
      map(action => {
        if (action.type === ManagerAnimalActions.UpdateAnimalCommentSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateAnimalCommentEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    enrollmentAnimalId,
    managerComment
  }: IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string, managerComment: IAnimalManagerCommentDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/animals/${enrollmentAnimalId}/comment`, managerComment);
  }

  public refundAnimal(params: IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string, paymentId: string }) {
    this.store.dispatch(ManagerAnimalActions.RefundAnimalAction(params));

    return this.dispatcher.pipe(
      ofType(ManagerAnimalActions.RefundAnimalSuccessAction, ManagerAnimalActions.RefundAnimalErrorAction),
      take(1),
      map(action => {
        if (action.type === ManagerAnimalActions.RefundAnimalSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public refundAnimalEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    enrollmentAnimalId,
    paymentId
  }: IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string, paymentId: string }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/animals/${enrollmentAnimalId}/refund-payments/${paymentId}`, {});
  }
}
