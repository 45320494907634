import { Pipe, PipeTransform } from '@angular/core';
import { CustomReportsService, EventCustomReportsService, ProgramYearService, RouterService } from 'app/core/services';
import { filter, map, Observable, of, switchMap } from 'rxjs';

import { eFilterOperator } from '../models';

@Pipe({
    name: 'availableFilterValue$',
    pure: true
})
export class AvailableFilterValuePipe implements PipeTransform {
    constructor(
        private customReportsService: CustomReportsService,
        private eventCustomReportService: EventCustomReportsService,
        private routerService: RouterService,
        private programYearService: ProgramYearService) {
    }

    public transform(
        reportColumnId: string,
        filterOperator: eFilterOperator,
        institutionId: string = this.routerService.institutionId,
        managerId: string = this.routerService.managerId,
        programId: string = this.routerService.programId,
        eventId: string = this.routerService.eventId,
        customReportId: string = this.routerService.customReportId,
        hierarchyNodeId: string = this.routerService.managerHierarchyId,

    ): Observable<{ [key: string]: string }[]> {
        if (filterOperator == null || reportColumnId == null) {
            return null;
        } else if ([eFilterOperator.IsNull, eFilterOperator.IsTrue, eFilterOperator.NotNull, eFilterOperator.NotTrue].includes(filterOperator)) {
            return null;
        } else if ([eFilterOperator.EqualAvailableOptions, eFilterOperator.NotEqualAvailableOptions].includes(filterOperator)) {
            if (eventId == null) {
                return this.programYearService.getUsersProgramYear({
                    institutionId,
                    programId,
                    institutionProfileId: this.routerService.loggedInInstitutionProfileId
                }).pipe(
                    filter(py => py != null),
                    map(py => py.programYearId),
                    switchMap(programYearId => {
                        return this.customReportsService.getAvailableFilterValues({
                            institutionId,
                            managerId,
                            programId,
                            customReportId,
                            hierarchyNodeId,
                            reportColumnId,
                            programYearId
                        }).pipe(map(availableFilterValues => {
                            return Object.keys(availableFilterValues).sort().map(key => {
                                return {
                                    key,
                                    value: availableFilterValues[key]
                                };
                            });
                        }));
                    })
                );
            } else {
                return this.eventCustomReportService.getAvailableFilterValues({
                    institutionId,
                    managerId,
                    programId,
                    eventId,
                    customReportId,
                    hierarchyNodeId,
                    reportColumnId,
                }).pipe(map(availableFilterValues => {
                    return Object.keys(availableFilterValues).sort().map(key => {
                        return {
                            key,
                            value: availableFilterValues[key]
                        };
                    });
                }));
            }
        }
        return of([]);
    }
}
