import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import {
  AppState,
  getAnimalCustomFiles,
  getAnimalForm,
  getAnimalTypes,
  getAvailableFamilyAnimals,
  getEnrollmentAnimal,
  getEnrollmentAnimals
} from 'app/app.reducers';
import { MemberAnimalsActions } from 'app/core/containers/member/store/animals';
import {
  IAnimalFormDto,
  IAnimalTypeDto,
  IEnrollmentAnimalDto,
  IEnrollmentAnimalSubmitDto,
  IEnrollmentAnimalUpdateDto,
  IInstitutionMemberProgramEnrollmentParams,
  IInstitutionMemberProgramParams,
  IMemberConsentSubmitDto,
  IMemberEnrollmentFileControlDto
} from 'app/core/models';
import { IEnrollmentAnimalDescriptorDto } from 'app/core/models/serverDTOs/IEnrollmentAnimalDescriptorDto';
import { filter, map, Observable, of, switchMap, take, tap } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AnimalsService {

  constructor(
    private store: Store<AppState>,
    private httpClient: HttpClient,
    private dispatcher: ActionsSubject
  ) {
  }

  public getAnimalTypes(params: IInstitutionMemberProgramParams) {

    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getAnimalTypes(params)),
      tap(animalTypes => {
        if (animalTypes === undefined) {

          this.store.dispatch(MemberAnimalsActions.LoadAnimalTypesAction(params));
        }
      }),
      filter(animalTypes => animalTypes != null)
    );
  }

  public getAnimalTypesEffect({ institutionId, memberId, programId }: IInstitutionMemberProgramParams) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/animal-types`) as Observable<IAnimalTypeDto[]>;
  }

  public getForm(params: IInstitutionMemberProgramParams & { animalTypeId: string }): Observable<IAnimalFormDto> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getAnimalForm(params)),
      tap(form => {
        if (form === undefined) {
          this.store.dispatch(MemberAnimalsActions.LoadAnimalFormAction(params));
        }
      }),
      filter(form => form != null)
    );
  }

  public getFormEffect({
    institutionId,
    memberId,
    programId,
    animalTypeId
  }: IInstitutionMemberProgramParams & { animalTypeId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/animal-forms/${animalTypeId}`) as Observable<IAnimalFormDto>;
  }

  public addExistingAnimalToEnrollment(params: IInstitutionMemberProgramEnrollmentParams & { animalId: string }) {
    this.store.dispatch(MemberAnimalsActions.AddAnimalToEnrollmentAction(params));

    return this.dispatcher.pipe(
      ofType(MemberAnimalsActions.AddAnimalToEnrollmentSuccessAction, MemberAnimalsActions.AddAnimalToEnrollmentErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberAnimalsActions.AddAnimalToEnrollmentSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public addExistingAnimalToEnrollmentEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    animalId
  }: IInstitutionMemberProgramEnrollmentParams & { animalId: string }) {
    return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/animals/${animalId}`, {},
      { observe: 'response' }).pipe(
      switchMap((res: HttpResponse<any>) => {
        return this.httpClient.get(res.headers.get('location'));
      })
    ) as Observable<IEnrollmentAnimalDto>;
  }

  public getEnrollmentAnimal(params: IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string }): Observable<IEnrollmentAnimalDto> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getEnrollmentAnimal(params)),
      tap(animalTypes => {
        if (animalTypes === undefined) {

          this.store.dispatch(MemberAnimalsActions.LoadAEnrollmentAnimalAction(params));
        }
      }),
      filter(animalTypes => animalTypes != null)
    );
  }

  public getEnrollmentAnimalEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    enrollmentAnimalId
  }: IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/animals/${enrollmentAnimalId}`) as Observable<IEnrollmentAnimalDto>;
  }

  public updateEnrollmentAnimalForm(params: IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string, enrollmentAnimalSubmit: IEnrollmentAnimalUpdateDto }) {
    this.store.dispatch(MemberAnimalsActions.UpdateEnrollmentAnimalFormAction(params));

    return this.dispatcher.pipe(
      ofType(MemberAnimalsActions.UpdateEnrollmentAnimalFormSuccessAction, MemberAnimalsActions.UpdateEnrollmentAnimalFormErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberAnimalsActions.UpdateEnrollmentAnimalFormSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateEnrollmentAnimalFormEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    enrollmentAnimalId,
    enrollmentAnimalSubmit
  }: IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string, enrollmentAnimalSubmit: IEnrollmentAnimalUpdateDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/animals/${enrollmentAnimalId}`, enrollmentAnimalSubmit);
  }

  public getEnrollmentAnimalCustomFiles(params: IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string }): Observable<IMemberEnrollmentFileControlDto[]> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getAnimalCustomFiles(params)),
      tap(customFiles => {
        if (customFiles === undefined) {
          this.store.dispatch(MemberAnimalsActions.LoadAnimalFilesAction(params));
        }
      }),
      filter(customFiles => customFiles !== undefined),
      map(customFiles => Array.isArray(customFiles) ? customFiles : [])
    );
  }

  public getEnrollmentAnimalCustomFilesEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    enrollmentAnimalId
  }: IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/animals/${enrollmentAnimalId}/animal-file-controls`) as Observable<IMemberEnrollmentFileControlDto[]>;
  }

  public saveEnrollmentAnimalCustomFile(params: IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string, fileControlId: string, formData: FormData }) {
    this.store.dispatch(MemberAnimalsActions.SaveEnrollmentAnimalCustomFileAction(params));

    return this.dispatcher.pipe(
      ofType(MemberAnimalsActions.SaveEnrollmentAnimalCustomFileSuccessAction, MemberAnimalsActions.SaveEnrollmentAnimalCustomFileErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberAnimalsActions.SaveEnrollmentAnimalCustomFileSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public saveEnrollmentAnimalCustomFileEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    enrollmentAnimalId,
    fileControlId,
    formData
  }: IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string, fileControlId: string, formData: FormData }) {
    return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/animals/${enrollmentAnimalId}/animal-file-controls/${fileControlId}`, formData);
  }

  public deleteEnrollmentCustomFile(params: IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string, fileControlId: string }) {
    this.store.dispatch(MemberAnimalsActions.DeleteEnrollmentAnimalCustomFileAction(params));

    return this.dispatcher.pipe(
      ofType(MemberAnimalsActions.DeleteEnrollmentAnimalCustomFileSuccessAction, MemberAnimalsActions.DeleteEnrollmentAnimalCustomFileErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberAnimalsActions.DeleteEnrollmentAnimalCustomFileSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteEnrollmentCustomFileEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    enrollmentAnimalId,
    fileControlId
  }: IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string, fileControlId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/animals/${enrollmentAnimalId}/animal-file-controls/${fileControlId}/file`);
  }

  public streamCustomFileEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    enrollmentAnimalId,
    fileId
  }: IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string, fileId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/animals/${enrollmentAnimalId}/animal-files/${fileId}/stream`);
  }

  public downloadCustomFileEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    enrollmentAnimalId,
    fileId
  }: IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string, fileId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/animals/${enrollmentAnimalId}/animal-files/${fileId}/download`);
  }

  public getCustomFileThumbnailEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    enrollmentAnimalId,
    fileId
  }: IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string, fileId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/animals/${enrollmentAnimalId}/animal-files/${fileId}/thumbnail`);
  }

  public getCustomFilePreviewEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    enrollmentAnimalId,
    fileId
  }: IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string, fileId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/animals/${enrollmentAnimalId}/animal-files/${fileId}/preview`);
  }

  public getSelectedAnimalsForEnrollment(params: IInstitutionMemberProgramEnrollmentParams): Observable<IEnrollmentAnimalDto[]> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getEnrollmentAnimals(params)),
      tap(animals => {
        if (animals === undefined) {
          this.store.dispatch(MemberAnimalsActions.LoadAEnrollmentAnimalsAction(params));
        }
      })
    );
  }

  public getSelectedAnimalsForEnrollmentEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId
  }: IInstitutionMemberProgramEnrollmentParams) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/animals`) as Observable<IEnrollmentAnimalDto[]>;
  }

  public createAnimal(params: IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalSubmit: IEnrollmentAnimalSubmitDto }) {
    this.store.dispatch(MemberAnimalsActions.CreateAnimalAction(params));

    return this.dispatcher.pipe(
      ofType(MemberAnimalsActions.CreateAnimalSuccessAction, MemberAnimalsActions.CreateAnimalErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberAnimalsActions.CreateAnimalSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public createAnimalEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    enrollmentAnimalSubmit
  }: IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalSubmit: IEnrollmentAnimalSubmitDto }) {
    return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/animals`, enrollmentAnimalSubmit,
      { observe: 'response' }).pipe(
      switchMap((res: HttpResponse<any>) => {
        return this.httpClient.get(res.headers.get('location'));
      })
    ) as Observable<IEnrollmentAnimalDto>;
  }

  public getAvailableAnimals(params: IInstitutionMemberProgramEnrollmentParams): Observable<IEnrollmentAnimalDescriptorDto[]> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getAvailableFamilyAnimals(params)),
      tap(animals => {
        if (animals === undefined) {
          this.store.dispatch(MemberAnimalsActions.LoadAvailableAnimalsAction(params));
        }
      })
    );
  }

  public getAvailableAnimalsEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId
  }: IInstitutionMemberProgramEnrollmentParams) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/available-animals`) as Observable<IEnrollmentAnimalDescriptorDto[]>;
  }

  public submitAnimal(params: IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string }) {
    this.store.dispatch(MemberAnimalsActions.SubmitAnimalAction(params));

    return this.dispatcher.pipe(
      ofType(MemberAnimalsActions.SubmitAnimalSuccessAction, MemberAnimalsActions.SubmitAnimalErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberAnimalsActions.SubmitAnimalSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public submitAnimalEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    enrollmentAnimalId
  }: IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/animals/${enrollmentAnimalId}/submit`, {});
  }

  public deleteEnrollmentAnimal(params: IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string }) {
    this.store.dispatch(MemberAnimalsActions.DeleteEnrollmentAnimalAction(params));

    return this.dispatcher.pipe(
      ofType(MemberAnimalsActions.DeleteEnrollmentAnimalSuccessAction, MemberAnimalsActions.DeleteEnrollmentAnimalErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberAnimalsActions.DeleteEnrollmentAnimalSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteEnrollmentAnimalEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    enrollmentAnimalId
  }: IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/animals/${enrollmentAnimalId}`);
  }

  public refreshAnimalRegistration() {
    this.store.dispatch(MemberAnimalsActions.EnrollmentAnimalInvalidateCacheAction());

    return this.dispatcher.pipe(
      ofType(MemberAnimalsActions.LoadAEnrollmentAnimalSuccessAction, MemberAnimalsActions.LoadAEnrollmentAnimalErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberAnimalsActions.LoadAEnrollmentAnimalSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public applyPayment(params: IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string, invoiceId: string, paymentMethodId: string, memberProgramConsentSubmitDto: IMemberConsentSubmitDto }) {
    this.store.dispatch(MemberAnimalsActions.EnrollmentAnimalApplyPaymentAction(params));

    return this.dispatcher.pipe(
      ofType(MemberAnimalsActions.EnrollmentAnimalApplyPaymentSuccessAction, MemberAnimalsActions.EnrollmentAnimalApplyPaymentErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberAnimalsActions.EnrollmentAnimalApplyPaymentSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public applyPaymentEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    enrollmentAnimalId,
    invoiceId,
    paymentMethodId,
    memberProgramConsentSubmitDto
  }: IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string, invoiceId: string, paymentMethodId: string, memberProgramConsentSubmitDto: IMemberConsentSubmitDto }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/animals/${enrollmentAnimalId}/invoices/${invoiceId}?paymentMethodId=${paymentMethodId}`, memberProgramConsentSubmitDto);
  }

  public applyAdditionalPayment(params: IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string, invoiceId: string, paymentMethodId: string, paymentConsentResponse: IMemberConsentSubmitDto }) {
    this.store.dispatch(MemberAnimalsActions.EnrollmentAnimalApplyAdditionalPaymentAction(params));

    return this.dispatcher.pipe(
      ofType(MemberAnimalsActions.EnrollmentAnimalApplyAdditionalPaymentSuccessAction, MemberAnimalsActions.EnrollmentAnimalApplyAdditionalPaymentErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberAnimalsActions.EnrollmentAnimalApplyAdditionalPaymentSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public applyAdditionalPaymentEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    enrollmentAnimalId,
    invoiceId,
    paymentMethodId,
    paymentConsentResponse
  }: IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string, invoiceId: string, paymentMethodId: string, paymentConsentResponse: IMemberConsentSubmitDto }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/animals/${enrollmentAnimalId}/invoices/${invoiceId}/additional-payment?paymentMethodId=${paymentMethodId}`, paymentConsentResponse);
  }
}
