import { Action, createReducer, on } from '@ngrx/store';
import {
    IInstitutionManagerProgramModel,
    IPendingScreeningDto,
    IProfileVolunteerScreeningDto,
    IScreeningBatchDto,
} from 'app/core/models';
import { mergeImmutable } from 'app/shared/utils';

import { ManagerScreeningsActions } from '.';

export interface ManagerScreeningsState {
  // TODO: update type when screenings type is known
  screeningsAvailableForBatch: IInstitutionManagerProgramModel<IPendingScreeningDto[]>;
  batches: IInstitutionManagerProgramModel<IScreeningBatchDto[]>;
  batchScreenings: IInstitutionManagerProgramModel<{ [profileScreeningBatchId: string]: IProfileVolunteerScreeningDto[] }>;
  submittedScreenings: IInstitutionManagerProgramModel<{ [hierarchyNodeId: string]: IProfileVolunteerScreeningDto[] }>;

  screeningsSearchResult: any;
  screeningSearchSearching: any;
}

const initialState: ManagerScreeningsState = {
  screeningsAvailableForBatch: undefined,
  batches: undefined,
  batchScreenings: undefined,
  submittedScreenings: undefined,

  screeningsSearchResult: undefined,
  screeningSearchSearching: undefined,
};

const reducer = createReducer(
  initialState,
  on(ManagerScreeningsActions.ManagerGetBatchesSuccessAction, (state, payload) => {
    return {
      ...state,
      batches: mergeImmutable(
        { [payload.institutionId]: { [payload.managerId]: { [payload.programId]: payload.batches } } },
        state.batches
      )
    };
  }),
  on(ManagerScreeningsActions.ManagerGetScreeningsAvailableForBatchSuccessAction, (state, payload) => {
    return {
      ...state,
      screeningsAvailableForBatch: mergeImmutable(
        { [payload.institutionId]: { [payload.managerId]: { [payload.programId]: payload.screenings } } },
        state.screeningsAvailableForBatch
      )
    };
  }),
  on(ManagerScreeningsActions.ManagerGetBatchScreeningsSuccessAction, (state, payload) => {
    return {
      ...state,
      batchScreenings: mergeImmutable(
        { [payload.institutionId]: { [payload.managerId]: { [payload.programId]: { [payload.profileScreeningBatchId]: payload.screenings } } } },
        state.batchScreenings
      )
    };
  }),
  on(ManagerScreeningsActions.ManageGetSubmittedScreeningsSuccessAction, (state, { institutionId, managerId, programId, hierarchyNodeId, submittedScreenings }) => {
    return {
      ...state,
      submittedScreenings: mergeImmutable(
        { [institutionId]: { [managerId]: { [programId]: { [hierarchyNodeId]: submittedScreenings } } } },
        state.submittedScreenings
      )
    };
  }),
  on(ManagerScreeningsActions.ManagerCreateScreeningsBatchSuccessAction,
    ManagerScreeningsActions.ManagerScreeningsUpdateBatchSuccessAction,
    ManagerScreeningsActions.ManagerScreeningsUpdateScreeningsStatusSuccessAction,
    ManagerScreeningsActions.ManagerScreeningsUpdateScreeningStatusSuccessAction,
    ManagerScreeningsActions.SendBackScreeningSuccessAction,
    ManagerScreeningsActions.DeleteScreeningSuccessAction,
    (state, { }) => {
      return initialState;
    }),
  // on(ManagerScreeningsActions.ManagerScreeningsInvalidateScreeningsCacheAction, (state, payload) => {
  //   return {
  //     ...state,
  //     screenings: undefined
  //   };
  // }),
  // on(ManagerScreeningsActions.ManagerScreeningsInvalidateBatcheCacheAction, (state, payload) => {
  //   return {
  //     ...state,
  //     batches: undefined
  //   };
  // })
  on(ManagerScreeningsActions.GetScreeningSearchResultsAction, (state, payload) => {
    return {
      ...state,
      screeningSearchSearching: true,
    };
  }),
  on(ManagerScreeningsActions.GetScreeningSearchResultsSuccessAction, (state, payload) => {
    return {
      ...state,
      screeningSearchSearching: false,
      screeningsSearchResult: payload.screeningsSearchResult
    };
  }),
  on(ManagerScreeningsActions.GetScreeningSearchResultsErrorAction, (state, payload) => {
    return {
      ...state,
      screeningSearchSearching: false,
    };
  }),

  on(ManagerScreeningsActions.SearchScreeningsAction, (state, payload) => {
    return {
      ...state,
      screeningSearchSearching: true,
    };
  }),
  on(ManagerScreeningsActions.SearchScreeningsSuccessAction, (state, payload) => {
    return {
      ...state,
      screeningSearchSearching: false,
      screeningsSearchResult: payload.screeningsSearchResult
    };
  }),
  on(ManagerScreeningsActions.SearchScreeningsErrorAction, (state, payload) => {
    return {
      ...state,
      screeningSearchSearching: false,
    };
  }),

  on(ManagerScreeningsActions.ClearScreeningSearchAction, (state, payload) => {
    return {
      ...state,
      screeningSearchSearching: true,
    };
  }),
  on(ManagerScreeningsActions.ClearScreeningSearchErrorAction, (state, payload) => {
    return {
      ...state,
      screeningSearchSearching: false,
    };
  }),
);

export function managerScreeningsReducer(state: ManagerScreeningsState | undefined, action: Action) {
  return reducer(state, action);
}

export const screeningsAvailableForBatch = (state: ManagerScreeningsState) => state.screeningsAvailableForBatch;
export const managerBatches = (state: ManagerScreeningsState) => state.batches;
export const managerBatchScreenings = (state: ManagerScreeningsState) => state.batchScreenings;
export const submittedScreenings = (state: ManagerScreeningsState) => state.submittedScreenings;

export const screeningsSearchResult = (state: ManagerScreeningsState) => state.screeningsSearchResult;
export const screeningSearchSearching = (state: ManagerScreeningsState) => state.screeningSearchSearching;