import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { filter, map, Observable, startWith } from 'rxjs';

import { ModalConfirmComponent } from '../../modal-confirm/modal-confirm.component';

@Component({
  selector: 'ng4h-modal-multiselect-autocomplete',
  templateUrl: './modal-multiselect-autocomplete.component.html',
  styleUrls: ['./modal-multiselect-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalMultiselectAutocompleteComponent implements OnInit {

  public filteredOptions$: Observable<any[]>;
  public searchInputControl = new FormControl();
  constructor(
    private dialogRef: MatDialogRef<ModalConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      sort: boolean,
      options: any[],
      selected: any[],
      displaySelector: string,
      valueSelector: string
    }) {

  }

  ngOnInit() {



    this.filteredOptions$ = this.searchInputControl.valueChanges
      .pipe(
        startWith(''),
        filter(value => typeof value === 'string'),
        map(value => value.trim() === '' ? '' : value),
        map(value => this.filterValue(value)),
      );

  }

  public close() {
    this.dialogRef.close();
  }
  public confirm() {
    this.dialogRef.close(this.data.selected);
  }

  public isSelected(option): boolean {
    return this.data.selected.findIndex(s => s[this.data.valueSelector] === option[this.data.valueSelector]) !== -1;
  }

  public change(option, checked: boolean) {
    if (checked) {
      this.data.selected = [...this.data.selected, option];
    } else {
      this.data.selected = this.data.selected.filter(s => s[this.data.valueSelector] !== option[this.data.valueSelector]);
    }
  }

  private filterValue(value: string): { display: string, key: string }[] {

    if (Array.isArray(this.data.options) === false) {
      return [];
    }
    const filterValue = value.toLowerCase();
    const options = this.data.options.filter(option => {
      return option?.[this.data.displaySelector]?.toLowerCase().indexOf(filterValue) !== -1;
    });
    if (this.data.sort === true) {
      return options.sort((a, b) => a?.[this.data.displaySelector].localeCompare(b?.[this.data.displaySelector]));
    } else {
      return options;
    }

  }

  public displayTrackBy(index: number, option: any) {
    return index;
  }
}
