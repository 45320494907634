import { createAction, props } from '@ngrx/store';
import { IAnyInstitutionProfile, IInstitutionProfileAuthDto } from 'app/core/models';
import { AuthenticateAction } from './user.actions';

export const GetProfileAuthAction = createAction(
    '[profile] Get institutionProfileAuthToken',
    props<{ institutionProfileId: string }>()
);
export const GetProfileAuthSuccessAction = createAction(
    '[profile] Get institutionProfileAuthToken success',
    props<{ institutionProfileId: string, institutionProfileAuth: IInstitutionProfileAuthDto }>()
);
export const GetProfileAuthErrorAction = createAction(
    '[profile] Get institutionProfileAuthToken error'
);

export const HistoryAddAction = createAction(
    '[profile] History Add',
    props<{ currentActingOnBehalfOfProfile: IAnyInstitutionProfile, route: string[], savedState: { actingOnBehalfOfProfile: IAnyInstitutionProfile, route: string[] }[] }>()
);

export const HistoryAddSuccessAction = createAction(
    '[profile] History Add success',
    props<{ auth: IInstitutionProfileAuthDto, profileHistory: { actingOnBehalfOfProfile: IAnyInstitutionProfile, route: string[] }[] }>()
);

export const AddClubPINAction = createAction(
    '[profile] Add Club PIN',
    props<{ institutionProfileId: string, unitId: string, PIN: string }>()
);
