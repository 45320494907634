import { IMemberSearchParametersDto, ISearchDateRangeDto } from '.';

export interface ITrainingSearchParametersDto extends IMemberSearchParametersDto {
    trainingIds?: any[];
    trainingStatus?: any[];
    trainingDates: ISearchDateRangeDto;
    // searchId: string;
    programYear: string;
    searchTerm?: string;
    flagged?: boolean;
    hierarchyNodeIds?: any[];
    memberRoles?: any[];
    enrollmentRole?: any[];
    enrollmentStatus?: any[];
    enrollmentDates: ISearchDateRangeDto;
    memberProgramStatus?: any[];
    memberProgramYearStatus?: any[];

}
