<ng4h-card>
    <!-- <ng4h-card-header>
  
        </ng4h-card-header> -->
    <ng4h-card-content>
        <div class="row">

            <i style="display: inline-block; height: 80px; width: 110px; margin-left: 15px;"
                class="fa-5x fal fa-pig"></i>


            <div style="display: inline-block;">
                <div>
                    <h4>{{animal.animalIdentifier}}</h4>
                </div>

                <div>{{animal.animalTypeLabel}}</div>
                <div>
                    <ng-content></ng-content>
                </div>

            </div>
        </div>


        <!-- <pre>{{memberDashboard | json}}</pre> -->
    </ng4h-card-content>
</ng4h-card>