<div class="modal-header">
    <h5 class="modal-title">Add Additional Payment</h5>
    <button (click)="close()" type="button" class="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="row">
        <div class="col-12">

            <ng4h-sidebar-cart class="col-lg-4 col-md-12" [invoice]="payableInvoice">
            </ng4h-sidebar-cart>

            <div class="row">
                <ng-container *ngIf="(isManager$ | async) == false">
                    <!-- <h4 class="ng4h-danger col-md-12" *ngIf="paymentMethod == null">Select a payment method</h4> -->
                    <ng4h-add-payment-option class="col-md-12" [invoice]="payableInvoice"
                        [paymentMethod]="paymentMethod" (paymentMethodChange)="paymentChanged($event)">
                    </ng4h-add-payment-option>
                </ng-container>


            </div>

            <div class="row" *ngIf="paymentMethod != null && payableInvoice?.termsOfPaymentConsent != null">
                <ng4h-consent class="col-md-12" [consents]="[payableInvoice.termsOfPaymentConsent]"
                    (allSigned)="paymentSigned($event)">
                </ng4h-consent>
            </div>


        </div>
    </div>
</div>

<div class="modal-footer">
    <button class="btn btn-primary"
        [disabled]="paymentMethod == null || paymentConsentSigned == false || payableInvoice?.termsOfPaymentConsent == null"
        (click)="apply()">Apply</button>
    <button (click)="close()" class="btn btn-secondary my-2 my-sm-0" role="button" aria-pressed="true">Cancel</button>
</div>