import { Action, createReducer, on } from '@ngrx/store';
import { IInstitutionFamilyProgramModel } from 'app/core/models';

import { CreditCardActions } from '.';
import { mergeImmutable } from '../utils';



export interface CreditCardState {
  // paymentGatewayConfiguration: { [institutionId: string]: { [programId: string]: IGatewayConfiguration } };
  authorizeToken: IInstitutionFamilyProgramModel<{ token: any }>;

}

const initialState: CreditCardState = {
  // paymentGatewayConfiguration: undefined,
  authorizeToken: undefined
};

const reducer = createReducer(
  initialState,
  // on(CreditCardActions.CreditCardLoadGatewayConfigurationSuccessAction, (state, { institutionId, programId, config }) => {
  //   const newConfigByProgram = mergeImmutable(
  //     { [institutionId]: { [programId]: config } },
  //     state.paymentGatewayConfiguration
  //   );

  //   return {
  //     ...state,
  //     paymentGatewayConfiguration: newConfigByProgram
  //   };
  // }),
  on(CreditCardActions.GetAuthorizeNetSuccessToken, (state, { institutionId, familyId, programId, token }) => {
    const newAuthorizeToken = mergeImmutable(
      { [institutionId]: { [familyId]: { [programId]: token } } },
      state.authorizeToken
    );

    return {
      ...state,
      authorizeToken: newAuthorizeToken
    };
  })
);

export function creditCardReducer(state: CreditCardState | undefined, actions: Action) {
  return reducer(state, actions);
}


// export const creditCardGatewayConfiguration = (state: CreditCardState) => state.paymentGatewayConfiguration;
export const authorizeToken = (state: CreditCardState) => state.authorizeToken;

