import { Action, createReducer, on } from '@ngrx/store';
import { IInstitutionSystemManagerModel, ISchoolDistrictDto } from 'app/core/models';
import { mergeImmutable } from 'app/shared/utils';

import { SystemManagerSchoolsActions } from '.';

export interface SystemManagerSchoolsState {
    // districtsWithSchools: IInstitutionSystemManagerModel<ISchoolDto[]>;
    // school: IInstitutionSystemManagerModel<{ [schoolId: string]: ISchoolDto }>;

    districts: IInstitutionSystemManagerModel<ISchoolDistrictDto[]>;
    district: IInstitutionSystemManagerModel<{ [schoolDistrictId: string]: ISchoolDistrictDto }>;
}

const initialState: SystemManagerSchoolsState = {
    // districtsWithSchools: undefined,
    // school: undefined,

    districts: undefined,
    district: undefined
};

const reducer = createReducer(
    initialState,
    // on(SystemManagerSchoolsActions.SystemManagerGetSchoolsSuccessAction, (state, { institutionId, systemManagerId, districtsWithSchools }) => {
    //     return {
    //         ...state,
    //         districtsWithSchools: mergeImmutable(
    //             { [institutionId]: { [systemManagerId]: districtsWithSchools } },
    //             state.districtsWithSchools
    //         )
    //     };
    // }),
    // on(SystemManagerSchoolsActions.SystemManagerGetSchoolSuccessAction, (state, { institutionId, systemManagerId, schoolId, school }) => {
    //     return {
    //         ...state,
    //         school: mergeImmutable(
    //             { [institutionId]: { [systemManagerId]: { [schoolId]: school } } },
    //             state.school
    //         )
    //     };
    // }),
    on(SystemManagerSchoolsActions.SystemManagerCreateSchoolSuccessAction,
        SystemManagerSchoolsActions.SystemManagerUpdateSchoolSuccessAction,
        SystemManagerSchoolsActions.SystemManagerDeleteSchoolSuccessAction,
        (state, { }) => {
            return {
                ...state,
                district: undefined
            };
        }),

    on(SystemManagerSchoolsActions.SystemManagerGetDistrictsSuccessAction, (state, { institutionId, systemManagerId, districts }) => {
        return {
            ...state,
            districts: mergeImmutable(
                { [institutionId]: { [systemManagerId]: districts } },
                state.districts
            )
        };
    }),
    on(SystemManagerSchoolsActions.SystemManagerGetDistrictSuccessAction, (state, { institutionId, systemManagerId, schoolDistrictId, district }) => {
        return {
            ...state,
            district: mergeImmutable(
                { [institutionId]: { [systemManagerId]: { [schoolDistrictId]: district } } },
                state.district
            )
        };
    }),
    on(SystemManagerSchoolsActions.SystemManagerCreateDistrictSuccessAction, (state, { }) => {
        return {
            ...state,
            districts: undefined
        };
    }),



    on(SystemManagerSchoolsActions.SystemManagerUpdateDistrictSuccessAction, (state, { institutionId, systemManagerId, schoolDistrictId }) => {
        return {
            ...state,
            district: mergeImmutable(
                { [institutionId]: { [systemManagerId]: { [schoolDistrictId]: undefined } } },
                state.district
            ),
            districts: undefined
        };
    }),
    on(SystemManagerSchoolsActions.SystemManagerDeleteDistrictSuccessAction, (state, { institutionId, systemManagerId, schoolDistrictId }) => {
        return {
            ...state,
            district: mergeImmutable(
                { [institutionId]: { [systemManagerId]: { [schoolDistrictId]: null } } },
                state.district
            ),
            districts: undefined
        };
    }),
);

export function systemManagerSchoolsReducer(state: SystemManagerSchoolsState | undefined, action: Action) {
    return reducer(state, action);
}

// export const districtsWithSchools = (state: SystemManagerSchoolsState) => state.districtsWithSchools;
// export const school = (state: SystemManagerSchoolsState) => state.school;
export const districts = (state: SystemManagerSchoolsState) => state.districts;
export const district = (state: SystemManagerSchoolsState) => state.district;
