import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { IInstitutionVolunteerDto, IVolunteerScreeningDto } from 'app/core/models';
import { AdminScreeningsService, RouterService, SystemManagerVolunteerTypesService } from 'app/core/services';
import { map, Observable, take } from 'rxjs';

@Component({
  selector: 'ng4h-modal-edit-volunteer-type',
  templateUrl: './modal-edit-volunteer-type.component.html',
  styleUrls: ['./modal-edit-volunteer-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalEditVolunteerTypeComponent implements OnInit {

  public form: FormGroup;

  public volunteerType$: Observable<IInstitutionVolunteerDto>;
  public screenings$: Observable<IVolunteerScreeningDto[]>;
  constructor(
    private dialogRef: MatDialogRef<any>,
    private fb: FormBuilder,
    private systemManagerVolunteerTypesService: SystemManagerVolunteerTypesService,
    private routerService: RouterService,
    private adminScreeningsService: AdminScreeningsService
  ) { }

  ngOnInit() {
    this.volunteerType$ = this.systemManagerVolunteerTypesService.getVolunteerTypes({ institutionId: this.routerService.institutionId, systemManagerId: this.routerService.systemManagerId, programId: this.routerService.programId }).pipe(
      map(volunteerTypes => volunteerTypes.find(volunteerType => volunteerType.volunteerTypeId === this.routerService.volunteerTypeId)),
    );
    this.volunteerType$.pipe(take(1)).subscribe(volunteerType => {
      this.form = this.fb.group({
        volunteerTypeLabel: [volunteerType.volunteerTypeLabel, Validators.required],
        volunteerTypeDescription: [volunteerType.volunteerTypeDescription, Validators.required],
        screeningId: [volunteerType.screeningId, Validators.required]
      });
    });

    this.screenings$ = this.adminScreeningsService.getScreenings({
      institutionId: this.routerService.institutionId,
      programId: this.routerService.programId
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  public save(): void {
    const volunteerType = this.form.value;

    this.systemManagerVolunteerTypesService.updateVolunteerTypes({
      institutionId: this.routerService.institutionId,
      systemManagerId: this.routerService.systemManagerId,
      programId: this.routerService.programId,
      institutionVolunteerId: this.routerService.volunteerTypeId,
      volunteerType
    }).pipe(take(1)).subscribe(res => {
      this.close();
    });

  }
}
