import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IInstitutionManagerProgramParams, IStandardReportDescriptorDto } from 'app/core/models';


export const GetStandardReportsAction = createAction(
    '[standard-reports] get reports',
    props<IInstitutionManagerProgramParams>()
);

export const GetStandardReportsSuccessAction = createAction(
    '[standard-reports] get reports success',
    props<IInstitutionManagerProgramParams & { reports: IStandardReportDescriptorDto[] }>()
);

export const GetStandardReportsErrorAction = createAction(
    '[standard-reports] get reports error',
    props<{ error: HttpErrorResponse }>()
);
