<div class="modal-header">
    <h5 class="modal-title">Add Event Email Template</h5>
    <button (click)="close()" type="button" class="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="form">
        <div class="form-group row">
            <label class="col-md-3 form-control-label">Display Caption</label>
            <div class="col-md-9">
                <input formControlName="displayCaption" class="form-control" type="test">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 form-control-label">Triggering Event Action</label>
            <div class="col-md-9">
                <select class="form-control" formControlName="type">
                    <option [value]="emailType" *ngFor="let emailType of emailTypes">
                        {{ emailType | camelToTitleCase}} </option>
                </select>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button [disabled]="form.invalid" (click)="add()" class="btn btn-primary my-2 my-sm-0" role="button"
        aria-pressed="true">Add</button>
</div>