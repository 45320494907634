import { Action, createReducer, on } from '@ngrx/store';
import { EnrollmentActions } from 'app/core/containers/member/enrollment';
import {
  ICountyCreditBillMemberDto,
  ICountyCreditBillPreviewDto,
  ICreditDto,
  IGroupEnrollmentDto,
  IInstitutionManagerProgramModel,
  IMemberEnrollmentEntityDto,
  IProgramCountyAreaSettingsDto,
} from 'app/core/models';
import { ICountyCreditBillDto } from 'app/core/models/serverDTOs/ICountyCreditBillDto';
import { EventsManagerActions } from 'app/shared/events-manager';
import { mergeImmutable } from 'app/shared/utils';

import { ManagerEnrollmentActions } from '.';

export interface ManagerEnrollmentState {
    submittedEnrollments: IInstitutionManagerProgramModel<{ [countyAreaId: string]: IMemberEnrollmentEntityDto[] }>;
    groupEnrollments: IInstitutionManagerProgramModel<{ [countyAreaId: string]: { [programYearId: string]: IGroupEnrollmentDto[] } }>;
    outstandingCredits: IInstitutionManagerProgramModel<{ [countyAreaId: string]: ICreditDto[] }>;
    collectedCredits: IInstitutionManagerProgramModel<{ [countyAreaId: string]: ICreditDto[] }>;

    allCountyCreditBills: IInstitutionManagerProgramModel<ICountyCreditBillDto[]>;
    countyCreditBillsByProgramYear: IInstitutionManagerProgramModel<{ [programYearId: string]: ICountyCreditBillDto[] }>;
    countyCreditBills: IInstitutionManagerProgramModel<{ [countyAreaId: string]: ICountyCreditBillDto[] }>;
    countyCreditBill: IInstitutionManagerProgramModel<{ [countyCreditBillId: string]: ICountyCreditBillDto }>;
    memberCredits: IInstitutionManagerProgramModel<{ [countyCreditBillId: string]: ICountyCreditBillMemberDto[] }>;
    enrollmentBillPreview: IInstitutionManagerProgramModel<{ [countyAreaId: string]: ICountyCreditBillPreviewDto }>;
    registrationBillPreview: IInstitutionManagerProgramModel<{ [countyAreaId: string]: ICountyCreditBillPreviewDto }>;
    enrollmentFeeWaiverRequests: any;
    countyAreaEnrollmentSettings: IInstitutionManagerProgramModel<{ [countyAreaId: string]: IProgramCountyAreaSettingsDto }>;
    managerUnitRequests: IInstitutionManagerProgramModel<any>;
}

const initialState: ManagerEnrollmentState = {
    submittedEnrollments: undefined,
    groupEnrollments: undefined,
    outstandingCredits: undefined,
    collectedCredits: undefined,

    allCountyCreditBills: undefined,
    countyCreditBillsByProgramYear: undefined,
    countyCreditBills: undefined,
    countyCreditBill: undefined,
    memberCredits: undefined,
    enrollmentBillPreview: undefined,
    registrationBillPreview: undefined,
    enrollmentFeeWaiverRequests: undefined,
    countyAreaEnrollmentSettings: undefined,
    managerUnitRequests: undefined
};


const reducer = createReducer(
    initialState,
    on(ManagerEnrollmentActions.GetManagerGetEnrollmentsSuccess, (state, { institutionId, managerId, programId, countyAreaId, enrollments }) => {
        const newSubmittedEnrollments = mergeImmutable(
            {
                [institutionId]: { [managerId]: { [programId]: { [countyAreaId]: enrollments } } }
            },
            state.submittedEnrollments
        );
        return {
            ...state,
            submittedEnrollments: newSubmittedEnrollments
        };
    }),
    on(ManagerEnrollmentActions.GetGroupEnrollmentsSuccessAction, (state, { institutionId, managerId, programId, countyAreaId, programYearId, groupEnrollments }) => {
        const newGroupEnrollments = mergeImmutable(
            {
                [institutionId]: {
                    [managerId]: { [programId]: { [countyAreaId]: { [programYearId]: groupEnrollments } } }
                }
            },
            state.groupEnrollments
        );
        return {
            ...state,
            groupEnrollments: newGroupEnrollments
        };
    }),
    on(ManagerEnrollmentActions.CreateGroupEnrollmentsSuccessAction,
        ManagerEnrollmentActions.UpdateGroupEnrollmentsSuccessAction,
        ManagerEnrollmentActions.DeleteGroupEnrollmentsSuccessAction, (state, { }) => {
            return {
                ...state,
                groupEnrollments: undefined
            };
        }),
    on(ManagerEnrollmentActions.ManagerGetOutstandingCreditsSuccessAction, (state, { institutionId, managerId, programId, countyAreaId, programYearId, outstandingCredits }) => {
        const newOutstandingCredits = mergeImmutable(
            {
                [institutionId]: { [managerId]: { [programId]: { [countyAreaId]: { [programYearId]: outstandingCredits } } } }
            },
            state.outstandingCredits
        );
        return {
            ...state,
            outstandingCredits: newOutstandingCredits
        };
    }),
    on(ManagerEnrollmentActions.ManagerGetCollectedCreditsSuccessAction, (state, { institutionId, managerId, programId, countyAreaId, programYearId, collectedCredits }) => {
        const newCollectedCredits = mergeImmutable(
            {
                [institutionId]: { [managerId]: { [programId]: { [countyAreaId]: { [programYearId]: collectedCredits } } } }
            },
            state.collectedCredits
        );
        return {
            ...state,
            collectedCredits: newCollectedCredits
        };
    }),
    // on(ManagerEnrollmentActions.GetAllCountyCreditBillsSuccessAction, (state, { institutionId, managerId, programId, bills }) => {
    //     const newBills = mergeImmutable(
    //         {
    //             [institutionId]: { [managerId]: { [programId]: bills } }
    //         },
    //         state.allCountyCreditBills
    //     );
    //     return {
    //         ...state,
    //         allCountyCreditBills: newBills
    //     };
    // }),
    on(ManagerEnrollmentActions.GetCountyCreditBillsForProgramYearSuccessAction, (state, { institutionId, managerId, programId, programYearId, bills }) => {
        const newBills = mergeImmutable(
            {
                [institutionId]: { [managerId]: { [programId]: { [programYearId]: bills } } }
            },
            state.countyCreditBillsByProgramYear
        );
        return {
            ...state,
            countyCreditBillsByProgramYear: newBills
        };
    }),
    on(ManagerEnrollmentActions.GetCountyCreditBillsSuccessAction, (state, { institutionId, managerId, programId, countyAreaId, bills }) => {
        const newBills = mergeImmutable(
            {
                [institutionId]: { [managerId]: { [programId]: { [countyAreaId]: bills } } }
            },
            state.countyCreditBills
        );
        return {
            ...state,
            countyCreditBills: newBills
        };
    }),
    on(ManagerEnrollmentActions.GetCountyCreditBillSuccessAction, (state, { institutionId, managerId, programId, countyCreditBillId, bill }) => {
        const newBills = mergeImmutable(
            {
                [institutionId]: { [managerId]: { [programId]: { [countyCreditBillId]: bill } } }
            },
            state.countyCreditBill
        );
        return {
            ...state,
            countyCreditBill: newBills
        };
    }),
    on(ManagerEnrollmentActions.GetMemberCreditsOnBillSuccessAction, (state, { institutionId, managerId, programId, countyCreditBillId, memberCredits }) => {
        const newMemberCredits = mergeImmutable(
            {
                [institutionId]: { [managerId]: { [programId]: { [countyCreditBillId]: memberCredits } } }
            },
            state.memberCredits
        );
        return {
            ...state,
            memberCredits: newMemberCredits
        };
    }),
    on(ManagerEnrollmentActions.GetEnrollmentBillPreviewSuccessAction, (state, { institutionId, managerId, programId, countyAreaId, memberCredits }) => {
        const newEnrollmentBillPreview = mergeImmutable(
            {
                [institutionId]: { [managerId]: { [programId]: { [countyAreaId]: memberCredits } } }
            },
            state.enrollmentBillPreview
        );
        return {
            ...state,
            enrollmentBillPreview: newEnrollmentBillPreview
        };
    }),
    on(ManagerEnrollmentActions.GetRegistrationBillPreviewSuccessAction, (state, { institutionId, managerId, programId, countyAreaId, memberCredits }) => {
        const newRegistrationBillPreview = mergeImmutable(
            {
                [institutionId]: { [managerId]: { [programId]: { [countyAreaId]: memberCredits } } }
            },
            state.registrationBillPreview
        );
        return {
            ...state,
            registrationBillPreview: newRegistrationBillPreview
        };
    }),
    on(ManagerEnrollmentActions.LoadEnrollmentFeeWaiverSuccessRequests, (state, { institutionId, managerId, programId, programYearId, waiverRequests }) => {

        const newEnrollmentFeeWaivers = mergeImmutable(
            { [institutionId]: { [managerId]: { [programId]: { [programYearId]: waiverRequests } } } },
            state.enrollmentFeeWaiverRequests
        );
        return {
            ...state,
            enrollmentFeeWaiverRequests: newEnrollmentFeeWaivers
        };
    }),
    on(ManagerEnrollmentActions.LoadCountyAreaEnrollmentSuccessSettings, (state, { institutionId, managerId, programId, countyAreaId, settings }) => {
        const newCountyAreaEnrollmentSettings = mergeImmutable(
            { [institutionId]: { [managerId]: { [programId]: { [countyAreaId]: settings } } } },
            state.countyAreaEnrollmentSettings
        )
        return {
            ...state,
            countyAreaEnrollmentSettings: newCountyAreaEnrollmentSettings
        };
    }),
    on(ManagerEnrollmentActions.UpdateCountyAreaEnrollmentSuccessSettings, (state, { }) => {
        return {
            ...state,
            countyAreaEnrollmentSettings: undefined
        };
    }),
    on(ManagerEnrollmentActions.GetUnitRequestsSuccessAction, (state, { institutionId, managerId, programId, countyAreaId, unitRequest }) => {
        const newUnitRequests = mergeImmutable(
            { [institutionId]: { [managerId]: { [programId]: { [countyAreaId]: unitRequest } } } },
            state.countyAreaEnrollmentSettings
        );
        return {
            ...state,
            managerUnitRequests: newUnitRequests
        };
    }),
    on(EnrollmentActions.RequestAddUnitSuccessAction,
        (state, { }) => {
            return {
                ...state,
                managerUnitRequests: undefined
            };
        }),
    on(ManagerEnrollmentActions.ConfirmCountyCreditSuccessAction,
        ManagerEnrollmentActions.ManagerConfirmCollectedCreditsSuccessAction,
        ManagerEnrollmentActions.CreateCountyBillFromApprovedSuccessAction,
        ManagerEnrollmentActions.CreateCountyBillFromCreditsSuccessAction,
        ManagerEnrollmentActions.InvalidateManagerEnrollmentsCache,
        // TO DO move this over to events with registrationBillPreview
        EventsManagerActions.CreateCountyBillForEventSuccessAction,
        EventsManagerActions.CreateCountyBillFromCreditsSuccessAction,
        ManagerEnrollmentActions.WaiveEnrollmentFeesSuccessAction,
        ManagerEnrollmentActions.DeclineWaiverRequestSuccessAction,
        EnrollmentActions.EnrollmentRequestFeeWaiverSuccessAction,
        (state, { }) => {
            return initialState;
        }),
);

export function enrollmentManagerReducer(state: ManagerEnrollmentState | undefined, action: Action) {
    return reducer(state, action);
}
export const submittedEnrollments = (state: ManagerEnrollmentState) => state.submittedEnrollments;
export const groupEnrollments = (state: ManagerEnrollmentState) => state.groupEnrollments;
export const outstandingCredits = (state: ManagerEnrollmentState) => state.outstandingCredits;
export const collectedCredits = (state: ManagerEnrollmentState) => state.collectedCredits;

export const allCountyCreditBills = (state: ManagerEnrollmentState) => state.allCountyCreditBills;
export const countyCreditBillsByProgramYear = (state: ManagerEnrollmentState) => state.countyCreditBillsByProgramYear;
export const countyCreditBills = (state: ManagerEnrollmentState) => state.countyCreditBills;
export const countyCreditBill = (state: ManagerEnrollmentState) => state.countyCreditBill;
export const memberCredits = (state: ManagerEnrollmentState) => state.memberCredits;
export const enrollmentBillPreview = (state: ManagerEnrollmentState) => state.enrollmentBillPreview;
export const registrationBillPreview = (state: ManagerEnrollmentState) => state.registrationBillPreview;
export const enrollmentFeeWaiverRequests = (state: ManagerEnrollmentState) => state.enrollmentFeeWaiverRequests;
export const countyAreaEnrollmentSettings = (state: ManagerEnrollmentState) => state.countyAreaEnrollmentSettings;
export const managerUnitRequests = (state: ManagerEnrollmentState) => state.managerUnitRequests;
