import { ICouponFundingStateDto, IDiscountFundDescriptorDto } from '.';
import {
    eInstitutionDiscountStatus,
    eInstitutionDiscountType,
    eInvoiceDiscountFeePercentage,
    eInvoiceDiscountType,
} from '..';

export interface IEventCouponDto {
    institutionDiscountId: string;
    code?: string;
    fundId: string;
    programYearId: string;
    institutionDiscountType: eInstitutionDiscountType;
    feePercentage?: eInvoiceDiscountFeePercentage;
    invoiceDiscountType: eInvoiceDiscountType;
    registrationTypes?: any[];
    fund: IDiscountFundDescriptorDto;
    fundingState: ICouponFundingStateDto;
    enrollmentRoles?: any[];
    description?: string;
    status: eInstitutionDiscountStatus;
}
