import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IInstitutionVolunteerDto, IMemberProgramConsentDto } from 'app/core/models';
import { EnrollmentService, ProfileService, RouterService } from 'app/core/services';
import { Observable } from 'rxjs';

@Component({
  selector: 'ng4h-modal-enrollment-volunteer-types-consent',
  templateUrl: './modal-enrollment-volunteer-types-consent.component.html',
  styleUrls: ['./modal-enrollment-volunteer-types-consent.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalEnrollmentVolunteerTypesConsentComponent implements OnInit {

  public isManager$: Observable<boolean>;
  public signedConsents: IMemberProgramConsentDto[];

  constructor(private dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: { volunteerType: IInstitutionVolunteerDto },
    private enrollmentService: EnrollmentService, private routerService: RouterService, private profileService: ProfileService) { }

  ngOnInit() {
    this.isManager$ = this.profileService.activeUserInstitutionProfileIsManager$;
  }

  public consentsSigned(signedConsents?: IMemberProgramConsentDto[]) {
    this.signedConsents = signedConsents;

  }

  public accept() {
    this.enrollmentService.addVolunteerType({
      institutionId: this.routerService.institutionId,
      programId: this.routerService.programId,
      memberId: this.routerService.memberId ? this.routerService.memberId : this.routerService.selectedMemberId,
      enrollmentId: this.routerService.enrollmentId,
      volunteerType: { ...this.data.volunteerType, consents: this.signedConsents }
    });
    this.close();
  }

  public close() {
    this.dialogRef.close();
  }
}
