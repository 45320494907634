import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  eEnrollmentRoleType,
  IEnrollmentProjectSubmissionDto,
  IInstitutionMemberProgramEnrollmentParams,
  IMemberConsentSubmitDto,
  IMemberProgramConsentDto,
  IProjectDto,
} from 'app/core/models';
import { CommonToastrService, EnrollmentService, ModalService, ProfileService, RouterService } from 'app/core/services';
import { map, Observable, switchMap, take } from 'rxjs';

@Component({
  selector: 'ng4h-modal-projects-consent',
  templateUrl: './modal-projects-consent.component.html',
  styleUrls: ['./modal-projects-consent.component.scss']
})
export class ModalProjectsConsentComponent implements OnInit {

  public eEnrollmentRoleType = eEnrollmentRoleType;
  public enrollmentRole$: Observable<eEnrollmentRoleType>;
  public isManager$: Observable<boolean>;

  public consents: IMemberConsentSubmitDto[];
  private institutionMemberProgramEnrollmentParams: IInstitutionMemberProgramEnrollmentParams;

  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: { project: IProjectDto, submission: IEnrollmentProjectSubmissionDto },
    private enrollmentService: EnrollmentService,
    private routerService: RouterService,
    private profileService: ProfileService,
    private modalService: ModalService,
    private toastrService: CommonToastrService
  ) {
    this.institutionMemberProgramEnrollmentParams = {
      institutionId: this.routerService.institutionId,
      memberId: this.routerService.memberId ? this.routerService.memberId : this.routerService.selectedMemberId,
      programId: this.routerService.programId,
      enrollmentId: this.routerService.enrollmentId
    };

    this.enrollmentRole$ = this.enrollmentService.getEnrollment(this.institutionMemberProgramEnrollmentParams).pipe(
      map(enrollment => enrollment.enrollmentRole)
    );
  }

  ngOnInit() {

    this.isManager$ = this.profileService.activeUserInstitutionProfileIsManager$;


  }

  public consentsSigned(signedConsents?: IMemberProgramConsentDto[]) {
    if (signedConsents != null) {
      this.consents = signedConsents.map(signedConsent => {
        return {
          consentStatus: signedConsent.consentStatus,
          consentVersionId: signedConsent.consentVersion.consentVersionId,
          recordedMemberName: signedConsent.recordedMemberName,
          recordedParentGuardianName: signedConsent.recordedParentGuardianName,
          recordedManagerName: signedConsent.recordedManagerName,
          memberProgramConsentId: signedConsent.memberProgramConsentId,
          consentedByProfileId: signedConsent.consentedByProfileId,
          consentVersion: signedConsent.consentVersion
        };
      });
    } else {
      this.consents = null;
    }
  }

  public accept() {

    this.enrollmentService.saveProject({
      ...this.institutionMemberProgramEnrollmentParams,
      projectSubmission: {
        ...this.data.submission,
        consents: this.consents.map(consent => {
          return {
            consentVersionId: consent.consentVersionId,
            recordedMemberName: consent.recordedMemberName,
            recordedParentGuardianName: consent.recordedParentGuardianName,
            recordedManagerName: consent.recordedManagerName ?? '',
            consentStatus: consent.consentStatus
          } as IMemberConsentSubmitDto;
        })
      }
    }).pipe(
      take(1),
      switchMap(() => this.enrollmentService.refreshEnrollment()),
      take(1),
      this.modalService.showProgressSpinnerModalUntilComplete()
    ).subscribe({
      next: () => {
        this.toastrService.success('Project Saved');
        this.close();
      }, error: (error) => this.toastrService.error('Could not save project', error)
    });
    this.close();
  }

  public close() {
    this.dialogRef.close();
  }

}
