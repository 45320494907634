import { Action, createReducer, on } from '@ngrx/store';
import {
    eFilterGroupOperator,
    ICustomReportDto,
    ICustomReportFilterDto,
    ICustomReportFilterGroupDto,
    IInstitutionManagerProgramModel,
    IQueuedReportDto,
    IReportColumnDto,
    IReportFolderDto,
} from 'app/core/models';
import { mergeImmutable } from 'app/shared/utils';

import { CustomReportActions } from '.';

export interface CustomReportState {
    customReports: IInstitutionManagerProgramModel<IReportFolderDto[]>;
    customReport: IInstitutionManagerProgramModel<{ [customReportId: string]: ICustomReportDto }>;
    availableColumns: IInstitutionManagerProgramModel<{ [customReportId: string]: IReportColumnDto[] }>;
    availableFilterValues: IInstitutionManagerProgramModel<{ [customReportId: string]: { [reportColumnId: string]: { [hierarchyNodeId: string]: { [programYearId: string]: { [key: string]: string } } } } }>;
    queuedReports: IInstitutionManagerProgramModel<{ [hierarchyNodeId: string]: IQueuedReportDto[] }>;
    queuedReport: IInstitutionManagerProgramModel<{ [queueToken: string]: IQueuedReportDto }>;
}

const initialState: CustomReportState = {
    customReport: undefined,
    customReports: undefined,
    availableColumns: undefined,
    availableFilterValues: undefined,
    queuedReports: undefined,
    queuedReport: undefined,
};

const reducer = createReducer(
    initialState,
    on(CustomReportActions.CustomReportInvalidateCacheAction, (state) => {
        return initialState;
    }),
    on(CustomReportActions.CustomReportsLoadReportsSuccessAction, (state, { institutionId, managerId, programId, hierarchyNodeId, reports }) => {
        return {
            ...state,
            customReports: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [hierarchyNodeId]: reports } } } },
                state.customReports
            )
        };
    }),
    on(CustomReportActions.CustomReportsLoadReportSuccessAction, (state, { institutionId, managerId, programId, customReportId, report }) => {
        return {
            ...state,
            customReport: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [customReportId]: report } } } },
                state.customReport
            )
        };
    }),
    on(CustomReportActions.CustomReportsCreateReportSuccessAction, (state, { institutionId, managerId, programId, report }) => {
        return {
            ...state,
            customReports: undefined,
            customReport: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [report.reportId]: report } } } },
                state.customReport
            )
        };
    }),
    on(CustomReportActions.UpdateReportFolderStateAction, (state, { institutionId, managerId, programId, hierarchyNodeId, customReportFolderId, folderState }) => {
        const reports = state.customReports[institutionId][managerId][programId][hierarchyNodeId].map((reportFolder: IReportFolderDto) => {
            if (reportFolder.reportFolderId === customReportFolderId) {
                return {
                    ...reportFolder,
                    reportFolderState: folderState.reportFolderState
                };
            } else {
                return reportFolder;
            }
        });
        return {
            ...state,
            customReports: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [hierarchyNodeId]: reports } } } },
                state.customReports
            )
        };
    }),
    on(CustomReportActions.CreateReportFolderSuccessAction,
        CustomReportActions.UpdateReportFolderSuccessAction,
        CustomReportActions.DeleteReportFolderSuccessAction, (state, { }) => {
            return {
                ...state,
                customReports: undefined
            };
        }),
    on(CustomReportActions.CustomReportsLoadAvailableColumnsSuccessAction, (state, { institutionId, managerId, programId, customReportId, availableColumns }) => {
        return {
            ...state,
            availableColumns: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [customReportId]: availableColumns } } } },
                state.availableColumns
            )
        };
    }),
    on(CustomReportActions.CustomReportsAddColumnToReport, (state, { institutionId, managerId, programId, customReportId, column }) => {
        let newReport = state.customReport[institutionId][managerId][programId][customReportId];
        newReport = {
            ...newReport,
            columns: [...newReport.columns, column]
        };
        return {
            ...state,
            customReport: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [customReportId]: newReport } } } },
                state.customReport
            )
        };
    }),
    on(CustomReportActions.CustomReportsDeleteColumnToReport, (state, { institutionId, managerId, programId, customReportId, column }) => {
        let newReport = state.customReport[institutionId][managerId][programId][customReportId];
        newReport = {
            ...newReport,
            columns: newReport.columns.filter(existingColumn => existingColumn.columnId !== column.columnId)
        };
        return {
            ...state,
            customReport: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [customReportId]: newReport } } } },
                state.customReport
            )
        };
    }),
    on(CustomReportActions.CustomReportsUpdateReportSuccessAction,
        CustomReportActions.CustomReportsDeleteReportSuccessAction,
        CustomReportActions.CustomReportsRestoreReportSuccessAction,
        CustomReportActions.CustomReportsCopyReportSuccessAction,
        CustomReportActions.CustomReportsShareReportSuccessAction,
        CustomReportActions.CustomReportsUnShareReportSuccessAction,
        (state, { institutionId, managerId, programId, customReportId }) => {
            return {
                ...state,
                customReports: undefined,
                customReport: mergeImmutable(
                    { [institutionId]: { [managerId]: { [programId]: { [customReportId]: undefined } } } },
                    state.customReport
                )
            };
        }),
    on(CustomReportActions.CustomReportsAddFilterGroup, (state, { institutionId, managerId, programId, customReportId, filterGroup }) => {

        let newReport = state.customReport[institutionId][managerId][programId][customReportId];
        const filterGroups = nullLastFilterGroupOperator([...newReport.filterGroups, filterGroup]);
        newReport = {
            ...newReport,
            filterGroups
        };
        return {
            ...state,
            customReport: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [customReportId]: newReport } } } },
                state.customReport
            )
        };
    }),
    on(CustomReportActions.CustomReportsRemoveFilterGroup, (state, { institutionId, managerId, programId, customReportId, filterGroupIndex }) => {
        let newReport = state.customReport[institutionId][managerId][programId][customReportId];
        const filterGroups = nullLastFilterGroupOperator(newReport.filterGroups.filter((filterGroup, index) => index !== filterGroupIndex));
        newReport = {
            ...newReport,
            filterGroups
        };
        return {
            ...state,
            customReport: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [customReportId]: newReport } } } },
                state.customReport
            )
        };
    }),
    on(CustomReportActions.CustomReportsReorderColumns, (state, { institutionId, managerId, programId, customReportId, columns }) => {
        const newReport = {
            ...state.customReport[institutionId][managerId][programId][customReportId],
            columns
        };
        return {
            ...state,
            customReport: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [customReportId]: newReport } } } },
                state.customReport
            )
        };
    }),
    on(CustomReportActions.CustomReportsReorderFilterGroup, (state, { institutionId, managerId, programId, customReportId, filterGroups }) => {

        filterGroups = nullLastFilterGroupOperator(filterGroups);
        let newReport = state.customReport[institutionId][managerId][programId][customReportId];

        newReport = {
            ...newReport,
            filterGroups: filterGroups
        };
        return {
            ...state,
            customReport: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [customReportId]: newReport } } } },
                state.customReport
            )
        };
    }),
    on(CustomReportActions.CustomReportsAddFilter, (state, { institutionId, managerId, programId, customReportId, filterGroupIndex, filter }) => {
        let newReport = state.customReport[institutionId][managerId][programId][customReportId];
        const filterGroup = newReport.filterGroups[filterGroupIndex];

        const filters = nullLastFilterOperator([...filterGroup.filters, filter]);

        const newFilterGroups = newReport.filterGroups.map((gf, fgi) => {
            if (filterGroupIndex !== fgi) {
                return gf;
            } else {
                return {
                    ...gf,
                    filters
                };
            }
        });
        newReport = {
            ...newReport,
            filterGroups: newFilterGroups
        };
        return {
            ...state,
            customReport: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [customReportId]: newReport } } } },
                state.customReport
            )
        };
    }),
    on(CustomReportActions.CustomReportsUpdateFilterValue, (state, { institutionId, managerId, programId, customReportId, filterGroupIndex, filterIndex, filter }) => {
        let newReport = state.customReport[institutionId][managerId][programId][customReportId];
        const filterGroup = newReport.filterGroups[filterGroupIndex];

        // const filters = [
        //     ...filterGroup.filters.slice(0, filterIndex),
        //     filter,
        //     ...filterGroup.filters.slice(filterIndex + 1)
        // ];

        const filters = filterGroup.filters.map((f, index) => {
            if (index !== filterIndex) {
                return f;
            }
            return filter;
        });

        const newFilterGroups = newReport.filterGroups.map((gf, fgi) => {
            if (filterGroupIndex !== fgi) {
                return gf;
            } else {
                return {
                    ...gf,
                    filters
                };
            }
        });
        newReport = {
            ...newReport,
            filterGroups: newFilterGroups
        };
        return {
            ...state,
            customReport: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [customReportId]: newReport } } } },
                state.customReport
            )
        };
    }),
    on(CustomReportActions.CustomReportsRemoveFilter, (state, { institutionId, managerId, programId, customReportId, filterGroupIndex, filterIndex }) => {
        let newReport = state.customReport[institutionId][managerId][programId][customReportId];
        const filterGroup = newReport.filterGroups[filterGroupIndex];

        const filters = nullLastFilterOperator(filterGroup.filters.filter((f, index) => index !== filterIndex));

        const newFilterGroups = newReport.filterGroups.map((gf, fgi) => {
            if (filterGroupIndex !== fgi) {
                return gf;
            } else {
                return {
                    ...gf,
                    filters
                };
            }
        });
        newReport = {
            ...newReport,
            filterGroups: newFilterGroups
        };
        return {
            ...state,
            customReport: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [customReportId]: newReport } } } },
                state.customReport
            )
        };
    }),
    on(CustomReportActions.CustomReportsReorderFilters, (state, { institutionId, managerId, programId, customReportId, filterGroupIndex, filters }) => {

        filters = nullLastFilterOperator(filters);

        let newReport = state.customReport[institutionId][managerId][programId][customReportId];

        const newFilterGroups = newReport.filterGroups.map((gf, fgi) => {
            if (fgi !== filterGroupIndex) {
                return gf;
            } else {
                return {
                    ...gf,
                    filters: filters
                };
            }
        });
        newReport = {
            ...newReport,
            filterGroups: newFilterGroups
        };

        return {
            ...state,
            customReport: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [customReportId]: newReport } } } },
                state.customReport
            )
        };
    }),
    on(CustomReportActions.CustomReportsLoadAvailableFilterValuesSuccessAction, (state, { institutionId, managerId, programId, customReportId, reportColumnId, hierarchyNodeId, programYearId, availableFilterValues }) => {
        return {
            ...state,
            availableFilterValues: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [customReportId]: { [reportColumnId]: { [hierarchyNodeId]: { [programYearId]: availableFilterValues } } } } } } },
                state.availableFilterValues
            )
        };
    }),
    on(CustomReportActions.CustomReportsUpdateReportSuccessAction, (state, { institutionId, managerId, programId, customReportId }) => {
        return {
            ...state,
            availableFilterValues: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [customReportId]: undefined } } } },
                state.availableFilterValues
            )
        };
    }),
    on(CustomReportActions.CustomReportsAddOrderByAction, (state, { institutionId, managerId, programId, customReportId, orderBy }) => {
        let newReport = state.customReport[institutionId][managerId][programId][customReportId];
        newReport = {
            ...newReport,
            orderBy: [...newReport.orderBy, orderBy]
        };
        return {
            ...state,
            customReport: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [customReportId]: newReport } } } },
                state.customReport
            )
        };
    }),
    on(CustomReportActions.CustomReportsUpdateOrderByAction, (state, { institutionId, managerId, programId, customReportId, orderByIndex, orderBy }) => {
        let newReport = state.customReport[institutionId][managerId][programId][customReportId];
        newReport = {
            ...newReport,
            orderBy: newReport.orderBy.map((ob, obi) => {
                if (obi !== orderByIndex) {
                    return ob;
                }
                return orderBy;
            })
        };
        return {
            ...state,
            customReport: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [customReportId]: newReport } } } },
                state.customReport
            )
        };
    }),
    on(CustomReportActions.CustomReportsReorderOrderByAction, (state, { institutionId, managerId, programId, customReportId, orderBys }) => {
        let newReport = state.customReport[institutionId][managerId][programId][customReportId];
        newReport = {
            ...newReport,
            orderBy: orderBys
        };
        return {
            ...state,
            customReport: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [customReportId]: newReport } } } },
                state.customReport
            )
        };
    }),
    on(CustomReportActions.CustomReportsRemoveOrderByAction, (state, { institutionId, managerId, programId, customReportId, orderByIndex }) => {
        let newReport = state.customReport[institutionId][managerId][programId][customReportId];
        newReport = {
            ...newReport,
            orderBy: newReport.orderBy.filter((ob, index) => index !== orderByIndex)
        };
        return {
            ...state,
            customReport: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [customReportId]: newReport } } } },
                state.customReport
            )
        };
    }),
    on(CustomReportActions.CustomReportsAddGroupByAction, (state, { institutionId, managerId, programId, customReportId, groupBy }) => {
        let newReport = state.customReport[institutionId][managerId][programId][customReportId];
        newReport = {
            ...newReport,
            groupBy: [...newReport.groupBy, groupBy]
        };
        return {
            ...state,
            customReport: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [customReportId]: newReport } } } },
                state.customReport
            )
        };
    }),
    on(CustomReportActions.CustomReportsUpdateGroupByAction, (state, { institutionId, managerId, programId, customReportId, groupByIndex, groupBy }) => {
        let newReport = state.customReport[institutionId][managerId][programId][customReportId];
        newReport = {
            ...newReport,
            groupBy: newReport.groupBy.map((ob, obi) => {
                if (obi !== groupByIndex) {
                    return ob;
                }
                return groupBy;
            })
        };
        return {
            ...state,
            customReport: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [customReportId]: newReport } } } },
                state.customReport
            )
        };
    }),
    on(CustomReportActions.CustomReportsReorderGroupByAction, (state, { institutionId, managerId, programId, customReportId, groupBys }) => {
        let newReport = state.customReport[institutionId][managerId][programId][customReportId];
        newReport = {
            ...newReport,
            groupBy: groupBys
        };
        return {
            ...state,
            customReport: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [customReportId]: newReport } } } },
                state.customReport
            )
        };
    }),
    on(CustomReportActions.CustomReportsRemoveGroupByAction, (state, { institutionId, managerId, programId, customReportId, groupByIndex }) => {
        let newReport = state.customReport[institutionId][managerId][programId][customReportId];
        newReport = {
            ...newReport,
            groupBy: newReport.groupBy.filter((ob, index) => index !== groupByIndex)
        };
        return {
            ...state,
            customReport: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [customReportId]: newReport } } } },
                state.customReport
            )
        };
    }),
    on(CustomReportActions.CustomReportsLoadQueuedReportsSuccessAction, (state, { institutionId, managerId, programId, hierarchyNodeId, queuedReports }) => {
        return {
            ...state,
            queuedReports: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [hierarchyNodeId]: queuedReports } } } },
                state.queuedReports
            )
        };
    }),
    // on(CustomReportActions.CustomReportsLoadQueuedReportSuccessAction, (state, { institutionId, managerId, programId, queueToken, queuedReport }) => {
    //     return {
    //         ...state,
    //         queuedReport: mergeImmutable(
    //             { [institutionId]: { [managerId]: { [programId]: { [queueToken]: queuedReport } } } },
    //             state.queuedReport
    //         )
    //     };
    // })
);

function nullLastFilterGroupOperator(filterGroups: ICustomReportFilterGroupDto[]) {
    return filterGroups.map((filterGroup, index) => {
        const isLast = (index + 1) === filterGroups.length;
        if (isLast) {
            return {
                ...filterGroup,
                outerJoinOperator: null
            };
        } else {
            return {
                ...filterGroup,
                outerJoinOperator: filterGroup.outerJoinOperator == null ? eFilterGroupOperator.OR : filterGroup.outerJoinOperator
            };
        }
    });
}

function nullLastFilterOperator(filters: ICustomReportFilterDto[]) {
    return filters.map((f, index) => {
        const isLast = (index + 1) === filters.length;
        if (isLast) {
            return {
                ...f,
                joinOperator: null
            };
        } else {
            return {
                ...f,
                joinOperator: f.joinOperator == null ? eFilterGroupOperator.OR : f.joinOperator
            };
        }
    });
}

export function customReportsReducer(state: CustomReportState | undefined, action: Action) {
    return reducer(state, action);
}

export const customReports = (state: CustomReportState) => state.customReports;
export const customReport = (state: CustomReportState) => state.customReport;
export const availableColumns = (state: CustomReportState) => state.availableColumns;
export const availableFilterValues = (state: CustomReportState) => state.availableFilterValues;
export const queuedReports = (state: CustomReportState) => state.queuedReports;
// export const queuedReport = (state: CustomReportState) => state.queuedReport;
