import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { eHierarchyNodeType, IHierarchyAny, IHierarchyNodeDto } from 'app/core/models';
import { CommonToastrService, HierarchyService, ModalService, RouterService } from 'app/core/services';
import { map, Observable, of, switchMap, take } from 'rxjs';

@Component({
  selector: 'ng4h-modal-edit-hierarchy',
  templateUrl: './modal-edit-hierarchy.component.html',
  styleUrls: ['./modal-edit-hierarchy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalEditHierarchyComponent implements OnInit {

  public form: FormGroup;

  public hierarchyNode$: Observable<IHierarchyNodeDto>;
  public parentListToPickFrom$: Observable<IHierarchyAny[]>;

  constructor(
    private dialogRef: MatDialogRef<any>,
    private fb: FormBuilder,
    public routerService: RouterService,
    private hierarchyService: HierarchyService,
    private modalService: ModalService,
    private toastrService: CommonToastrService
  ) { }

  public ngOnInit() {

    this.form = this.fb.group({
      moveToParentHierarchyNodeId: [null, Validators.required]
    });

    this.hierarchyNode$ = this.hierarchyService.getHierarchyNode({
      institutionId: this.routerService.institutionId,
      hierarchyNodeId: this.routerService.hierarchyNodeId
    });

    this.parentListToPickFrom$ = this.hierarchyService.getHierarchyNodeAndParents({
      institutionId: this.routerService.institutionId,
      hierarchyNodeId: this.routerService.hierarchyNodeId
    }).pipe(
      take(1),
      switchMap(nodeAndParents => {
        let requiredHierarchyType = null;
        if (nodeAndParents[eHierarchyNodeType.CountyArea] != null) {
          requiredHierarchyType = eHierarchyNodeType.DistrictArea;
        } else if (nodeAndParents[eHierarchyNodeType.DistrictArea] != null) {
          requiredHierarchyType = eHierarchyNodeType.RegionArea;
        } else if (nodeAndParents[eHierarchyNodeType.RegionArea] != null) {
          requiredHierarchyType = eHierarchyNodeType.Institution;
        }

        if (requiredHierarchyType == null) {
          return of([]);
        }

        return this.hierarchyService.getHierarchy({ institutionId: this.routerService.institutionId }).pipe(
          take(1),
          map(hierarchy => {
            const listToPickFrom = [];
            Object.keys(hierarchy).forEach(key => {
              if (hierarchy[key].hierarchyNodeType === requiredHierarchyType && hierarchy[key].hierarchyNodeId !== nodeAndParents[requiredHierarchyType].hierarchyNodeId) {
                listToPickFrom.push(hierarchy[key]);
              }
            });
            return listToPickFrom;
          })
        );
      })
    );



  }

  public close(res?: any): void {
    this.dialogRef.close(res);
  }

  public save(): void {
    this.hierarchyService.changeHierarchyNodeParent({
      superuserId: this.routerService.superuserId,
      institutionId: this.routerService.institutionId,
      hierarchyNodeId: this.routerService.hierarchyNodeId,
      moveToParentHierarchyNodeId: this.form.value.moveToParentHierarchyNodeId
    }).pipe(
      take(1),
      this.modalService.showProgressSpinnerModalUntilComplete()
    ).subscribe({
      next: () => {
        this.toastrService.success('Parent changed');
        this.close(true);
      }, error: error => {
        this.toastrService.error('Could not change parent', error);
      }
    });
  }
}
