import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
    AutoUnsubscribe,
    eProfileType,
    IAnyInstitutionProfile,
    IFamilyInstitutionProfile,
    IManagerInstitutionProfile,
    IMemberDto,
    IMemberInstitutionProfile,
    ISuperUserInstitutionProfile,
    ISystemManagerInstitutionProfile
} from 'app/core/models';
import { CommonToastrService, ProfileService, RouterService, SidebarService, UserService } from 'app/core/services';
import { rankProfileLevelWithFamily } from 'app/shared/utils';
import { combineLatest, map, Observable, take, takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.reducers';

@Component({
    selector: 'ng4h-manager-sidebar',
    templateUrl: './manager-sidebar.component.html',
    styleUrls: ['./manager-sidebar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManagerSidebarComponent extends AutoUnsubscribe implements OnInit {

    public eProfileType = eProfileType;
    public managerSidebarOpen$: Observable<boolean>;
    public sidebarMode: Observable<string>;
    public actingOnBehalfOf$: Observable<IAnyInstitutionProfile>;
    public sortedActiveProfileHistory$: Observable<{ actingOnBehalfOfProfile: IAnyInstitutionProfile, route: string[] }[]>;

    public activeProfileIsAUserProfile: Observable<boolean>;

    public managerHierarchyId$: Observable<string>;
    public managerId$: Observable<string>;
    public institutionId$: Observable<string>;
    public programId$: Observable<string>;
    public activeUserInstitutionProfile$: Observable<IAnyInstitutionProfile>;

    constructor(
        private sidebarService: SidebarService,
        private profileService: ProfileService,
        private routerService: RouterService,
        private userService: UserService,
        private toastr: CommonToastrService,
        private store: Store<AppState>
    ) {
        super();
        this.activeUserInstitutionProfile$ = this.profileService.loggedInAsInstitutionProfile;
        this.actingOnBehalfOf$ = this.profileService.actingAsInstitutionProfile;

        this.managerSidebarOpen$ = combineLatest([this.sidebarService.managerSidebarOpen$, this.profileService.loggedInAsInstitutionProfile, this.userService.userAccount$]).pipe(
            map(([managerSidebarOpen, activeUserInstitutionProfile, userAccount]) => {
                if (managerSidebarOpen && userAccount?.superuserId != null) {
                    return true;
                }
                if (activeUserInstitutionProfile == null) {
                    return false;
                }
                return managerSidebarOpen && [eProfileType.Superuser, eProfileType.Manager].includes(activeUserInstitutionProfile.profileType);
            })
        );

        this.sidebarMode = this.sidebarService.sidebarMode$;

        // this.actingOnBehalfOf$ = this.profileService.actingOnBehalfOf$.pipe(takeUntil(this.autoUnsubscribe));

        this.sortedActiveProfileHistory$ = this.profileService.activeProfileHistory$.pipe(
            map(history => {
                return [...history].sort((a, b) => {
                    const aLevel = rankProfileLevelWithFamily(a.actingOnBehalfOfProfile.profileType);
                    const bLevel = rankProfileLevelWithFamily(b.actingOnBehalfOfProfile.profileType);
                    if (aLevel === bLevel) {
                        if (a.actingOnBehalfOfProfile.profileType === eProfileType.Family) {
                            return a.actingOnBehalfOfProfile.familyName.toLocaleLowerCase().localeCompare((b.actingOnBehalfOfProfile as any).familyName.toLocaleLowerCase());
                        } else {
                            return a.actingOnBehalfOfProfile.firstName?.toLocaleLowerCase().localeCompare((b.actingOnBehalfOfProfile as any).firstName.toLocaleLowerCase());
                        }
                    }
                    return rankProfileLevelWithFamily(a.actingOnBehalfOfProfile.profileType) - rankProfileLevelWithFamily(b.actingOnBehalfOfProfile.profileType);
                });
            })
        );

        this.institutionId$ = this.routerService.institutionId$.pipe(takeUntil(this.autoUnsubscribe));
        this.programId$ = this.routerService.programId$.pipe(takeUntil(this.autoUnsubscribe));
        this.managerHierarchyId$ = this.routerService.managerHierarchyId$.pipe(takeUntil(this.autoUnsubscribe));
    }

    ngOnInit() {
    }

    public yourProfileClicked(profile: IAnyInstitutionProfile) {
        this.profileService.changeToProfile(profile);
    }

    public historicalProfileClicked(historicProfile: { actingOnBehalfOfProfile: IAnyInstitutionProfile, route: string[] }) {
        // if (this.routerService.managerId) {
        //   this.profileService.managerHome = this.routerService.currentRoute;
        // }
        // this.profileService.addToHistory(historicProfile.actingOnBehalfOfProfile, historicProfile.route);

        const route = historicProfile.route.concat();
        // Always go the member dashboard
        if (route.find(r => r === 'member')) {
            if (route[route.length - 1] !== 'dashboard') {
                route[route.length - 1] = 'dashboard';
            }
        }
        this.routerService.Go(route).then(_ => {
            const message = `Changing to ${historicProfile.actingOnBehalfOfProfile.profileType.toLowerCase()}-${historicProfile.actingOnBehalfOfProfile.name}`;
            this.toastr.success(message);
        });
    }

    public getMemberPrimaryCountyAreaId(profile: IMemberDto) {
        return profile.families.find(family => family.primary).countyAreaHierarchyNodeId;
    }

    // public change() {
    //   this.modalService.openModal(ModalChangeProfileComponent, { width: '700px' });
    // }

    // Workaround for union types in template
    public asManager(manager: IManagerInstitutionProfile): IManagerInstitutionProfile {
        return manager;
    }

    // Workaround for union types in template
    public asFamily(manager: IFamilyInstitutionProfile): IFamilyInstitutionProfile {
        return manager;
    }

    // Workaround for union types in template
    public asMember(manager: any): IMemberInstitutionProfile {
        return manager;
    }

    public asSystemManager(systemManager: any): ISystemManagerInstitutionProfile {
        return systemManager;
    }

    public asSuperuser(superuser: any): ISuperUserInstitutionProfile {
        return superuser;
    }

    public swiperight() {
        combineLatest([this.managerSidebarOpen$, this.sidebarMode]).pipe(take(1)).subscribe(combined => {
            if (combined[0] && combined[1] === 'over') {
                this.sidebarService.closeManager();
            }
        });
    }

    public sidebarClosing() {
        this.sidebarService.closeManager();
    }

    protected readonly XMLDocument = XMLDocument;
}
