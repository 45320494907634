import { Component, Input, OnInit } from '@angular/core';
import { eInvoiceStatus, ePaymentStatus, IPayableInvoiceDto } from 'app/core/models';
import { Observable } from 'rxjs';

@Component({
    selector: 'ng4h-sidebar-cart',
    templateUrl: './sidebar-cart.component.html',
    styleUrls: ['./sidebar-cart.component.scss']
})
export class SidebarCartComponent implements OnInit {

    public ePaymentStatus = ePaymentStatus;
    public eInvoiceStatus = eInvoiceStatus;
    @Input() invoice: IPayableInvoiceDto;
    @Input() showStatus = false;
    @Input() fullView = false;

    public hasCreditCollections = false;

    constructor() {
    }

    ngOnInit() {
        this.hasCreditCollections = this.invoice.credits.some(c => c.collected);
    }
}
