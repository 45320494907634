import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  IInstitutionProgramRolloverDescriptorDto,
  IInstitutionSystemManagerProgramParams,
  ISuperuserInstitutionParams,
  ISuperuserInstitutionSystemManagerParams,
  ISuperuserInstitutionSystemManagerProgramParams,
  ISystemManagerACLDto,
  ISystemManagerCreateDto,
  ISystemManagerInstitutionProfile,
  ISystemManagerUpdateDto,
} from 'app/core/models';
import { IPermissionType } from 'app/core/models/IPermissionType';


export const GetSystemManagersAction = createAction(
  '[system-manager] Get system managers',
  props<{ institutionId: string }>()
);

export const GetSystemManagersSuccessAction = createAction(
  '[system-manager] Get system managers success',
  props<{ institutionId: string, systemManagers: ISystemManagerInstitutionProfile[] }>()
);

export const GetSystemManagersErrorAction = createAction(
  '[system-manager] Get system managers error',
  props<{ error: HttpErrorResponse }>()
);

export const GetSystemManagerAction = createAction(
  '[system-manager] Get system manager',
  props<ISuperuserInstitutionSystemManagerParams>()
);

export const GetSystemManagerSuccessAction = createAction(
  '[system-manager] Get system manager success',
  props<ISuperuserInstitutionSystemManagerParams & { systemManager: ISystemManagerInstitutionProfile }>()
);

export const GetSystemManagerErrorAction = createAction(
  '[system-manager] Get system manager error',
  props<{ error: HttpErrorResponse }>()
);

export const UpdateSystemManagerAction = createAction(
  '[system-manager] Update system manager',
  props<{ institutionId: string, systemManagerId: string, systemManagerUpdate: ISystemManagerUpdateDto }>()
);

export const UpdateSystemManagerSuccessAction = createAction(
  '[system-manager] Update system manager success',
  props<{ institutionId: string, systemManagerId: string, systemManagerUpdate: ISystemManagerUpdateDto }>()
);

export const UpdateSystemManagerErrorAction = createAction(
  '[system-manager] Update system manager error',
  props<{ error: HttpErrorResponse }>()
);

export const GetSystemManagerPermissionTypesAction = createAction(
  '[system-manager] Get system manager permission types'
);

export const GetSystemManagerPermissionTypesSuccessAction = createAction(
  '[system-manager] Get manager system permission types success',
  props<{ types: IPermissionType[] }>()
);

export const GetSystemManagerPermissionTypesErrorAction = createAction(
  '[system-manager] Get manager system permission types error',
  props<{ error: HttpErrorResponse }>()
);

export const UpdateSystemManagerPermissionsAction = createAction(
  '[system-manager] update system manager permissions',
  props<ISuperuserInstitutionSystemManagerProgramParams & { permissions: ISystemManagerACLDto }>()
);

export const UpdateSystemManagerPermissionsSuccessAction = createAction(
  '[system-manager] update system manager permissions success',
  props<ISuperuserInstitutionSystemManagerProgramParams & { permissions: ISystemManagerACLDto }>()
);

export const UpdateSystemManagerPermissionsErrorAction = createAction(
  '[system-manager] update system manager permissions error',
  props<{ error: HttpErrorResponse }>()
);

export const CreateSystemManagerAction = createAction(
  '[system-manager] Create System Manager',
  props<ISuperuserInstitutionParams & { managerCreate: ISystemManagerCreateDto }>()
);

export const CreateSystemManagerSuccessAction = createAction(
  '[system-manager] Create System Manager success',
  props<ISuperuserInstitutionParams & { managerCreate: ISystemManagerCreateDto }>()
);

export const CreateSystemManagerErrorAction = createAction(
  '[system-manager] Create System Manager error',
  props<any>()
);


export const GetSystemManagerRolloversAction = createAction(
  '[system-manager] Get system manager rollover',
  props<IInstitutionSystemManagerProgramParams>()
);

export const GetSystemManagerRolloversSuccessAction = createAction(
  '[system-manager] Get system manager rollover success',
  props<IInstitutionSystemManagerProgramParams & { rollovers: IInstitutionProgramRolloverDescriptorDto[] }>()
);

export const GetSystemManagerRolloversErrorAction = createAction(
  '[system-manager] Get system manager rollover error',
  props<any>()
);

export const StartSystemManagerRolloverAction = createAction(
  '[system-manager] Start system manager rollover',
  props<IInstitutionSystemManagerProgramParams & { charterStatusUnderReview: boolean }>()
);

export const StartSystemManagerRolloverSuccessAction = createAction(
  '[system-manager] Start system manager rollover success',
  props<IInstitutionSystemManagerProgramParams & { charterStatusUnderReview: boolean }>()
);

export const StartSystemManagerRolloverErrorAction = createAction(
  '[system-manager] Start system manager rollover error',
  props<any>()
);

export const RemoveFromProgramAction = createAction(
  '[system-manager] Remove from program',
  props<ISuperuserInstitutionSystemManagerProgramParams>()
);

export const RemoveFromProgramSuccessAction = createAction(
  '[system-manager] Remove from program success',
  props<ISuperuserInstitutionSystemManagerProgramParams>()
);

export const RemoveFromProgramErrorAction = createAction(
  '[system-manager] Remove from program error',
  props<any>()
);

