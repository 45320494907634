import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reverse',
  pure: false
})
export class ReversePipe implements PipeTransform {
  transform(array: any[]) {
    if (array) {
      return [...array].reverse();
    }
  }
}
