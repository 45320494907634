import { IConsentAssociationDto, IConsentVersionDto, IProfileDescriptorDto } from '.';

export interface IConsentTypeDto {
  consentTypeId: string;
  effectiveConsentVersionId: string;
  consentName?: string;
  acceptanceRequired: boolean;
  electronicSignatureRequired: boolean;
  dateModified?: string;

  system: boolean;
  modifiedBy: IProfileDescriptorDto;
  versions?: IConsentVersionDto[];
  associations?: IConsentAssociationDto[];
}
