<div class="modal-header">
    <h5 class="modal-title">Edit Projects</h5>
    <button (click)="close()" type="button" class="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <ng4h-add-projects class="col-lg-8 col-md-12">
    </ng4h-add-projects>
</div>
<div class="modal-footer">
    <!-- <button class="btn btn-primary" (click)="save()">Save</button> -->
    <button class="btn btn-secondary" (click)="close()">Close</button>
</div>