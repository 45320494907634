import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IInstitutionProgramParams, IVolunteerScreeningDto } from 'app/core/models';



export const AdminScreeningsLoadAction = createAction(
    '[admin-screenings] load screenings',
    props<IInstitutionProgramParams>()
);

export const AdminScreeningsLoadSuccessAction = createAction(
    '[admin-screenings] load screenings success',
    props<IInstitutionProgramParams & { screenings: IVolunteerScreeningDto[] }>()
);

export const AdminScreeningsLoadErrorAction = createAction(
    '[admin-screenings] load screenings error',
    props<{ error: HttpErrorResponse }>()
);