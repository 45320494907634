import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AutoUnsubscribe, eFormControlType, eStandardFieldStatus, IStandardFormFieldConfigDto } from 'app/core/models';

@Component({
  selector: 'ng4h-modal-setup-standard-form',
  templateUrl: './modal-setup-standard-form.component.html',
  styleUrls: ['./modal-setup-standard-form.component.scss']
})
export class ModalSetupStandardFormComponent extends AutoUnsubscribe implements OnInit {

  public newFields: IStandardFormFieldConfigDto[];
  private oldFields: IStandardFormFieldConfigDto[];
  public eFormControlType = eFormControlType;

  constructor(private dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: { sectionKey: string, fields: IStandardFormFieldConfigDto[] }) {
    super();
    this.newFields = [...this.data.fields];
    this.oldFields = [...this.data.fields];
  }

  ngOnInit() {

  }

  public save() {
    const oldInputs = this.oldFields;
    const newInputs = this.newFields;
    const modifiedFields: { [inputName: string]: eStandardFieldStatus } = {};
    for (let i = 0; i < oldInputs.length; i++) {
      if (oldInputs[i].inputState !== newInputs[i].inputState) {
        modifiedFields[newInputs[i].inputName] = newInputs[i].inputState;
      }
    }
    if (Object.keys(modifiedFields).length > 0) {
      this.close(modifiedFields);
    } else {
      this.close();
    }
  }
  public close(modifiedFields?: { [inputName: string]: eStandardFieldStatus }) {
    this.dialogRef.close(modifiedFields);
  }

  public trackByFn(input: IStandardFormFieldConfigDto) {
    return input.inputName;
  }
  public inputStateChanged(inputIndex: number, state: eStandardFieldStatus) {

    const newInput = { ...this.newFields[inputIndex] };
    newInput.inputState = state;
    const newInputs = [...this.newFields];
    newInputs.splice(inputIndex, 1, newInput);

    this.newFields = newInputs;
  }

}
