export enum eHierarchyNodeType {

    National = 'National',
    UniversitySystem = 'UniversitySystem',
    Institution = 'Institution',
    Extension = 'Extension',
    RegionArea = 'RegionArea',
    DistrictArea = 'DistrictArea',
    CountyArea = 'CountyArea',
    Unit = 'Unit'
}
