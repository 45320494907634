<div class="modal-header">
    <h5 class="modal-title">Change Event Status</h5>
    <button (click)="close()" type="button" class="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div *ngFor="let keyValue of eEventStatus | keyvalue" class="ng4h-list-row" (click)="newStatus = keyValue.value"
        [ngClass]="{'ng4h-bg-secondary': keyValue.value == newStatus}">
        {{keyValue.value}}
    </div>
</div>
<div class="modal-footer">
    <button [disabled]="newStatus == null || newStatus == oldStatus" class="btn btn-primary"
        (click)="save()">Save</button>
    <button class="btn btn-secondary" (click)="close()">Cancel</button>
</div>