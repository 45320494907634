<ng4h-card *ngIf="consentText">
  <ng4h-card-header>
    <h5 class="ng4h-card-header-title">{{ consentText.consentTitle }}</h5>
  </ng4h-card-header>

  <ng4h-card-content>
    <p [innerHTML]="consentText.consentText | safeHtml"></p>
  </ng4h-card-content>
  <ng4h-card-footer>
    <mat-radio-group class="col-12">
      <mat-radio-button [value]="true">
        {{ consentText.agreeOptionText }}
      </mat-radio-button>
      <hr *ngIf="!consent.acceptanceRequired" />
      <mat-radio-button *ngIf="!consent.acceptanceRequired">
        {{ consentText.disagreeOptionText }}
      </mat-radio-button>
    </mat-radio-group>
  </ng4h-card-footer>
</ng4h-card>