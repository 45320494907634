import { Action, createReducer, on } from '@ngrx/store';
import { IMemberSearchResultsDto } from 'app/core/models/serverDTOs/IMemberSearchResultsDto';

import { ParticipationSearchActions } from '.';

export interface ParticipationSearchState {

    participationSearchSearching: boolean;
    participationsSearchResult: IMemberSearchResultsDto;
}

const initialState: ParticipationSearchState = {
    participationSearchSearching: false,
    participationsSearchResult: undefined
};

const reducer = createReducer(
    initialState,
    on(ParticipationSearchActions.GetParticipationSearchResultsAction, (state, payload) => {
        return {
            ...state,
            participationSearchSearching: true,
        };
    }),
    on(ParticipationSearchActions.GetParticipationSearchResultsSuccessAction, (state, payload) => {
        return {
            ...state,
            participationSearchSearching: false,
            participationsSearchResult: payload.participationsSearchResult
        };
    }),
    on(ParticipationSearchActions.GetParticipationSearchResultsErrorAction, (state, payload) => {
        return {
            ...state,
            participationSearchSearching: false,
        };
    }),

    on(ParticipationSearchActions.SearchParticipationsAction, (state, payload) => {
        return {
            ...state,
            participationSearchSearching: true,
        };
    }),
    on(ParticipationSearchActions.SearchParticipationsSuccessAction, (state, payload) => {
        return {
            ...state,
            participationSearchSearching: false,
            participationsSearchResult: payload.participationsSearchResult
        };
    }),
    on(ParticipationSearchActions.SearchParticipationsErrorAction, (state, payload) => {
        return {
            ...state,
            participationSearchSearching: false,
        };
    }),

    on(ParticipationSearchActions.ClearParticipationSearchAction, (state, payload) => {
        return {
            ...state,
            participationSearchSearching: true,
        };
    }),
    on(ParticipationSearchActions.ClearParticipationSearchErrorAction, (state, payload) => {
        return {
            ...state,
            participationSearchSearching: false,
        };
    }),

);

export function participationSearchReducer(state: ParticipationSearchState | undefined, action: Action) {
    return reducer(state, action);
}

export const participationsSearchResult = (state: ParticipationSearchState) => state.participationsSearchResult;
export const participationSearchSearching = (state: ParticipationSearchState) => state.participationSearchSearching;