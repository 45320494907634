export enum eProgramEmailType {
  Enrollment_Submitted = 1,
  Enrollment_Approved = 2,
  Enrollment_Declined = 3,
  Enrollment_SentBack = 4,
  Enrollment_ReEnroll = 5,
  PaymentGateway_CreditCardDeclined = 6,
  PaymentGateway_CreditCardApproved = 7,
  EventDefault_RegistrationSubmitted = 8,
  EventDefault_RegistrationMoveToNextLevel = 9,
  EventDefault_RegistrationApproved = 10,
  EventDefault_RegistrationDeclined = 11,
  EventDefault_RegistrationSentBack = 12,
  EventDefault_PaymentCreditCardDeclined = 13,
  EventDefault_PaymentCreditCardApproved = 14,
  PaymentGateway_CreditCardRefunded = 15,
}
