<div class="modal-section">
  <div class="modal-header">
    <h5 class="modal-title">Edit Medication</h5>
    <button (click)="close()" type="button" class="close" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <ng4h-display-standard-form [form]="form" [fields]="medicationFields" [showHeader]="false">
    </ng4h-display-standard-form>
  </div>

  <div class="modal-footer">
    <div *ngIf="form.disabled; else enabled">
      <button [disabled]="form.invalid" (click)="edit()" class="btn btn-primary float-right">Edit</button>
      <button (click)="delete()" class="btn btn-danger float-right ng4h-margin-right">Delete</button>
    </div>
    <ng-template #enabled>
      <button (click)="cancel()" class="btn btn-secondary float-right">Cancel</button>
      <button [disabled]="form.invalid" (click)="save()"
        class="btn btn-primary float-right ng4h-margin-right">Save</button>
    </ng-template>
  </div>
</div>