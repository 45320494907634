import { Pipe, PipeTransform } from '@angular/core';

import { eHierarchyNodeType } from '../models';


@Pipe({
    name: 'hierarchyNodeType',
    pure: true
})

export class HierarchyNodeTypePipe implements PipeTransform {

    transform(value: eHierarchyNodeType): any {
        switch (value) {
            case (eHierarchyNodeType.CountyArea): {
                return 'County';
            }
            case (eHierarchyNodeType.DistrictArea): {
                return 'District';
            }
            case (eHierarchyNodeType.Extension): {
                return 'Extension';
            }
            case (eHierarchyNodeType.Institution): {
                return 'Institution';
            }
            case (eHierarchyNodeType.National): {
                return 'National';
            }
            case (eHierarchyNodeType.RegionArea): {
                return 'Region';
            }
            case (eHierarchyNodeType.Unit): {
                return 'Unit';
            }
            case (eHierarchyNodeType.UniversitySystem): {
                return 'University';
            }
        }
    }
}
