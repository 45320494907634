import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IBulkDownloadCreateDto, IInstitutionManagerProgramHierarchyParams } from 'app/core/models';
import { IAnimalSearchParametersDto } from 'app/core/models/serverDTOs/IAnimalSearchParametersDto';
import { IMemberSearchResultsDto } from 'app/core/models/serverDTOs/IMemberSearchResultsDto';


export const SearchAnimalsAction = createAction(
    '[animals-search] Search animals',
    props<IInstitutionManagerProgramHierarchyParams & { searchParameters: IAnimalSearchParametersDto }>()
);

export const SearchAnimalsSuccessAction = createAction(
    '[animals-search] Search animals success',
    props<IInstitutionManagerProgramHierarchyParams & { searchParameters: IAnimalSearchParametersDto, animalsSearchResult: IMemberSearchResultsDto }>()
);

export const SearchAnimalsErrorAction = createAction(
    '[animals-search] Search animals error',
    props<{ error: HttpErrorResponse }>()
);


export const GetAnimalSearchResultsAction = createAction(
    '[animals-search] get animals search results',
    props<IInstitutionManagerProgramHierarchyParams & { pageSize: string, pageIndex: string }>()
);

export const GetAnimalSearchResultsSuccessAction = createAction(
    '[animals-search] get animals search results success',
    props<IInstitutionManagerProgramHierarchyParams & { pageSize: string, pageIndex: string, animalsSearchResult: IMemberSearchResultsDto }>()
);

export const GetAnimalSearchResultsErrorAction = createAction(
    '[animals-search] get animals search results error',
    props<{ error: HttpErrorResponse }>()
);


export const ClearAnimalSearchAction = createAction(
    '[animals-search] clear animals search',
    props<IInstitutionManagerProgramHierarchyParams>()
);

export const ClearAnimalSearchSuccessAction = createAction(
    '[animals-search] clear animals search success',
    props<IInstitutionManagerProgramHierarchyParams>()
);

export const ClearAnimalSearchErrorAction = createAction(
    '[animals-search] clear animals search error',
    props<{ error: HttpErrorResponse }>()
);


export const DownloadBulkAnimalFilesAction = createAction(
    '[animals-search] Download bulk animalFiles',
    props<IInstitutionManagerProgramHierarchyParams & { bulkDownloadCreate: IBulkDownloadCreateDto }>()
);
export const DownloadBulkAnimalFilesSuccessAction = createAction(
    '[animals-search] Download bulk animalFiles success',
    props<IInstitutionManagerProgramHierarchyParams & { bulkDownloadCreate: IBulkDownloadCreateDto }>()
);
export const DownloadBulkAnimalFilesErrorAction = createAction(
    '[animals-search] Download bulk animalFiles error',
    props<{ error: HttpErrorResponse }>()
);
