<div class="modal-header">
  <h5 class="modal-title" id="enrollModalLabel">Edit Control</h5>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div *ngIf="(controlBeingEdited$ | async) as control" class="control-options">
    <div [ngSwitch]="control.type" class="sidebar-container">
      <ng4h-dynamic-date-input-options *ngSwitchCase="eFormControlType.Date" [control]="control"
        [formDisplayTypes]="formDisplayTypes" (validatorAddOrModify)="validatorLogicModified($event)"
        (validatorRemove)="validatorLogicRemove($event)" (controlModified)="modifyControl($event)"
        (itemModified)="modifyItem($event)">
      </ng4h-dynamic-date-input-options>
      <ng4h-dynamic-text-area-input-options (validatorAddOrModify)="validatorLogicModified($event)"
        (validatorRemove)="validatorLogicRemove($event)" *ngSwitchCase="eFormControlType.MultipleLineText"
        [control]="control" [formDisplayTypes]="formDisplayTypes" (controlModified)="modifyControl($event)"
        (itemModified)="modifyItem($event)"></ng4h-dynamic-text-area-input-options>
      <ng4h-dynamic-text-area-options (validatorAddOrModify)="validatorLogicModified($event)"
        (validatorRemove)="validatorLogicRemove($event)" *ngSwitchCase="eFormControlType.TextContentBlock"
        [control]="control" [formDisplayTypes]="formDisplayTypes" (controlModified)="modifyControl($event)"
        (itemModified)="modifyItem($event)"></ng4h-dynamic-text-area-options>
      <ng4h-dynamic-text-input-options (validatorAddOrModify)="validatorLogicModified($event)"
        (validatorRemove)="validatorLogicRemove($event)" *ngSwitchCase="eFormControlType.SingleLineText"
        [control]="control" [formDisplayTypes]="formDisplayTypes" (controlModified)="modifyControl($event)"
        (itemModified)="modifyItem($event)"></ng4h-dynamic-text-input-options>
      <ng4h-dynamic-number-input-options (validatorAddOrModify)="validatorLogicModified($event)"
        (validatorRemove)="validatorLogicRemove($event)" *ngSwitchCase="eFormControlType.Decimal" [control]="control"
        [formDisplayTypes]="formDisplayTypes" [formType]="formType$ | async" (controlModified)="modifyControl($event)"
        (itemModified)="modifyItem($event)">
      </ng4h-dynamic-number-input-options>
      <ng4h-dynamic-multiple-choice-question-options (validatorAddOrModify)="validatorLogicModified($event)"
        (validatorRemove)="validatorLogicRemove($event)" *ngSwitchCase="eFormControlType.MultipleChoice"
        [control]="control" [formDisplayTypes]="formDisplayTypes" [formType]="formType$ | async"
        (controlModified)="modifyControl($event)" (addItem)="addItem($event)" (removeItem)="removeItem($event)"
        (itemModified)="modifyItem($event)">
      </ng4h-dynamic-multiple-choice-question-options>
      <ng4h-dynamic-dropdown-input-options (validatorAddOrModify)="validatorLogicModified($event)"
        (validatorRemove)="validatorLogicRemove($event)" *ngSwitchCase="eFormControlType.SingleChoice"
        [control]="control" [formDisplayTypes]="formDisplayTypes" [formType]="formType$ | async"
        (controlModified)="modifyControl($event)" (addOption)="addOption($event)" (removeOption)="removeOption($event)"
        (optionModified)="modifyOption($event)">
      </ng4h-dynamic-dropdown-input-options>
      <ng4h-dynamic-quantity-control-options (validatorAddOrModify)="validatorLogicModified($event)"
        (validatorRemove)="validatorLogicRemove($event)" *ngSwitchCase="eFormControlType.Quantity" [control]="control"
        [formDisplayTypes]="formDisplayTypes" [formType]="formType$ | async" (controlModified)="modifyControl($event)"
        (itemModified)="modifyItem($event)">
      </ng4h-dynamic-quantity-control-options>
      <ng4h-dynamic-checkbox-options (validatorAddOrModify)="validatorLogicModified($event)"
        (validatorRemove)="validatorLogicRemove($event)" *ngSwitchCase="eFormControlType.Checkbox" [control]="control"
        [formDisplayTypes]="formDisplayTypes" (controlModified)="modifyControl($event)" (addItem)="addItem($event)"
        (removeItem)="removeItem($event)" (itemModified)="modifyItem($event)" [formType]="formType$ | async">
      </ng4h-dynamic-checkbox-options>
      <!-- <ng4h-dynamic-options-root [formDisplayTypes]="formDisplayTypes" *ngSwitchDefault
        (validatorAddOrModify)="validatorLogicModified($event)" (validatorRemove)="validatorLogicRemove($event)"
        [control]="control" [formDisplayTypes]="formDisplayTypes" (rootInputModified)="modifyControl($event)">
      </ng4h-dynamic-options-root> -->
    </div>
  </div>
</div>
<div class="modal-footer">
  <button [disabled]="savingForm" class="btn btn-danger float-right" (click)="delete()">Delete</button>
  <button [disabled]="savingForm" class="btn btn-secondary float-right" (click)="close()">Cancel</button>
  <button [disabled]="(valid$ | async) == false || savingForm" class="btn btn-primary float-right"
    (click)="save()">Save</button>

</div>