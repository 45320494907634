import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SchoolService } from 'app/core/services';
import { catchError, exhaustMap, groupBy, map, mergeMap, of } from 'rxjs';

import { SchoolActions } from '.';
import { stringKeys } from '../rxjs.utils';


@Injectable()
export class SchoolEffects {


    loadSchoolDistricts = createEffect(() => this.actions
        .pipe(
            ofType(SchoolActions.SchoolDistrictsLoadByCountyAction),
            groupBy(stringKeys),
            mergeMap(groupedBy => {
                return groupedBy.pipe(
                    exhaustMap(payload => {
                        return this.schoolService.loadSchoolDistrictsByCountyEffect(payload).pipe(
                            map(districts => districts == null ? [] : districts),
                            map(districts => SchoolActions.SchoolDistrictsLoadByCountySuccessAction({ ...payload, districts: districts })),
                            catchError(error => of(SchoolActions.SchoolDistrictsLoadByCountyErrorAction({ error })))
                        );
                    })
                );
            })
        ));

    loadSchoolsByDistrict = createEffect(() => this.actions
        .pipe(
            ofType(SchoolActions.SchoolsLoadByDistrictAction),
            groupBy(stringKeys),
            mergeMap(groupedBy => {
                return groupedBy.pipe(
                    exhaustMap(payload => {
                        return this.schoolService.loadSchoolsByDistrictEffect(payload).pipe(
                            map(district => SchoolActions.SchoolsLoadByDistrictSuccessAction({ ...payload, schools: district.schools })),
                            catchError(error => of(SchoolActions.SchoolsLoadByDistrictErrorAction({ error })))
                        );
                    })
                );
            })
        ));

    constructor(private actions: Actions, private schoolService: SchoolService) { }
}
