import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { AppState, getMemberScreenings } from 'app/app.reducers';
import {
  IInstitutionMemberParams,
  IMemberScreeningFormSaveDto,
  IMemberScreeningFormSubmitDto,
  IProfileVolunteerScreeningDto
} from 'app/core/models';
import { MemberScreeningActions } from 'app/shared/screenings';
import { filter, map, Observable, of, take, tap } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MemberScreeningsService {

  constructor(private store: Store<AppState>, private httpClient: HttpClient, private dispatcher: ActionsSubject) {

  }

  public getVolunteerScreening(params: IInstitutionMemberParams & { profileScreeningId: string }): Observable<IProfileVolunteerScreeningDto> {

    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getMemberScreenings(params)),
      tap(enrollment => {
        if (enrollment === undefined) {
          this.store.dispatch(MemberScreeningActions.MemberScreeningLoadAction(params));
        }
      }),
      filter(enrollment => enrollment != null)
    );
  }

  public loadScreeningsEffect({
    institutionId,
    memberId,
    profileScreeningId
  }: IInstitutionMemberParams & { profileScreeningId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/volunteer-screenings/${profileScreeningId}`) as Observable<IProfileVolunteerScreeningDto>;
  }

  public saveScreenings(params: IInstitutionMemberParams & { profileScreeningId: string, screening: IMemberScreeningFormSubmitDto }) {
    this.store.dispatch(MemberScreeningActions.MemberScreeningSaveAction(params));

    return this.dispatcher.pipe(
      ofType(MemberScreeningActions.MemberScreeningSaveSuccessAction, MemberScreeningActions.MemberScreeningSaveErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberScreeningActions.MemberScreeningSaveSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public saveScreeningsEffect({
    institutionId,
    memberId,
    profileScreeningId,
    screening
  }: IInstitutionMemberParams & { profileScreeningId: string, screening: IMemberScreeningFormSubmitDto }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/volunteer-screenings/${profileScreeningId}`, screening) as Observable<IMemberScreeningFormSaveDto>;
  }

  public submitScreenings(params: IInstitutionMemberParams & { profileScreeningId: string, screening: IMemberScreeningFormSubmitDto }) {
    this.store.dispatch(MemberScreeningActions.MemberScreeningSubmitAction(params));

    return this.dispatcher.pipe(
      ofType(MemberScreeningActions.MemberScreeningSubmitSuccessAction, MemberScreeningActions.MemberScreeningSubmitErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberScreeningActions.MemberScreeningSubmitSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public submitScreeningsEffect({
    institutionId,
    memberId,
    profileScreeningId,
    screening
  }: IInstitutionMemberParams & { profileScreeningId: string, screening: IMemberScreeningFormSubmitDto }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/volunteer-screenings/${profileScreeningId}/submit`, screening);
  }

  public updateScreenings(params: IInstitutionMemberParams & { profileScreeningId: string }) {
    this.store.dispatch(MemberScreeningActions.MemberScreeningUpdateExternal(params));
  }
}
