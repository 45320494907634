<div class="modal-header">
    <h5 class="modal-title">Edit Volunteer Types</h5>
    <button (click)="cancel()" type="button" class="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <ng4h-add-volunteer-types class="col-lg-8 col-md-12"></ng4h-add-volunteer-types>
</div>
<div class="modal-footer">
    <button class="btn btn-primary" (click)="save()">Save</button>
    <button class="btn btn-secondary" (click)="cancel()">Cancel</button>
</div>