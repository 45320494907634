import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { IRegistrationTypeFileControlCreateDto } from 'app/core/models';
import { CommonToastrService, EventCustomFilesService, EventsManagerService, RouterService } from 'app/core/services';
import { filter, map, take } from 'rxjs';

@Component({
  selector: 'ng4h-modal-add-event-custom-file',
  templateUrl: './modal-add-event-custom-file.component.html',
  styleUrls: ['./modal-add-event-custom-file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalAddEventCustomFileComponent implements OnInit {

  public form: FormGroup;


  constructor(
    private customFileService: EventCustomFilesService,
    private dialogRef: MatDialogRef<any>,
    private fb: FormBuilder,
    private eventsManagerService: EventsManagerService,
    private routerService: RouterService,
    private toastrService: CommonToastrService
  ) { }

  public ngOnInit() {

    this.eventsManagerService.getEvent({
      institutionId: this.routerService.institutionId,
      managerId: this.routerService.managerId,
      programId: this.routerService.programId,
      eventId: this.routerService.eventId
    }).pipe(
      filter(data => data != null),
      map(event => {
        return event.registrationTypes;
      }),
      take(1)).subscribe(rts => {

        this.form = this.fb.group({
          fileControlName: [null, Validators.required],
          //  acceptedFileExtensions: [null, Validators.required],
          fileControlDescription: [null, Validators.required],

          registrationTypes: this.fb.array(
            rts.map(item => {
              return this.fb.group({
                registrationTypeId: item.registrationTypeId,
                registrationTypeName: item.registrationTypeName,
                selected: false
              });
            })
          )
        });

      });

  }

  public close(): void {
    this.dialogRef.close();
  }

  public add(): void {
    const form = this.form.value;
    form.registrationTypes = form.registrationTypes.map(registrationType => {
      if (registrationType.selected == null) {
        return {};
      }
      return { [registrationType.registrationTypeId]: registrationType.selected };
    }).reduce((pre, cur, index) => {
      return { ...pre, ...cur };
    });
    // this.dialogRef.close(this.form.value);


    // const fileTypes = [];


    // if (form.acceptedFileExtensions === 'image') {
    //   fileTypes.push('jpeg');
    //   fileTypes.push('gif');
    //   fileTypes.push('png');
    // } else if (form.acceptedFileExtensions === 'pdf') {
    //   fileTypes.push('pdf');
    // } else if (form.acceptedFileExtensions === 'hdImage') {
    //   fileTypes.push('tiff');
    // }



    const newEventFile: IRegistrationTypeFileControlCreateDto = {
      fileControlName: form.fileControlName,
      fileControlDescription: form.fileControlDescription,
      // acceptedFileExtensions: fileTypes,
      registrationTypes: form.registrationTypes,
    };

    this.customFileService.addCustomFile({
      institutionId: this.routerService.institutionId,
      managerId: this.routerService.managerId,
      programId: this.routerService.programId,
      eventId: this.routerService.eventId,
      customFile: newEventFile
    }).subscribe({
      next: () => {
        this.toastrService.success('custom file added');
        this.close();
      }, error: (error) => {
        this.toastrService.error('custom file add failed');
      }
    });
  }


}
