<div *ngIf="dynamicFormInputs != null">
  <div class="row"
    *ngFor="let group of dynamicFormInputs.groups; let groupIndex = index; trackBy: dynamicGroupsTrackBy">
    <div [ngClass]="control.attributes.class ? control.attributes.class : 'col-lg-12'"
      *ngFor="let control of group.controls; let controlIndex = index; trackBy: dynamicControlTrackBy">
      <ng-container *ngIf="control.managerField_MinimumEditHierarchyType != null; else show">

        <ng4h-dynamic-form-input [form]="dynamicForm" [control]="control"
          [readOnly]="readOnly || ((hasPermission$(control.managerField_MinimumEditHierarchyType) | async) === false)">
        </ng4h-dynamic-form-input>

      </ng-container>
      <ng-template #show>
        <ng4h-dynamic-form-input [form]="dynamicForm" [control]="control" [readOnly]="readOnly">
        </ng4h-dynamic-form-input>
      </ng-template>
    </div>
  </div>
</div>