import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AutoUnsubscribe, eEnrollmentRoleType, IInstitutionProgramTrainingCreateDto, ITrainingDto } from 'app/core/models';
import { RouterService, SystemManagerTrainingsService } from 'app/core/services';
import { filter, map, Observable, takeUntil } from 'rxjs';

@Component({
  selector: 'ng4h-modal-add-enrollment-training',
  templateUrl: './modal-add-enrollment-training.component.html',
  styleUrls: ['./modal-add-enrollment-training.component.scss']
})
export class ModalAddEnrollmentTrainingComponent extends AutoUnsubscribe implements OnInit {

  public eEnrollmentRoleType = eEnrollmentRoleType;
  public trainings$: Observable<ITrainingDto[]>;
  public form: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<any>,
    private fb: FormBuilder,
    private systemManagerTrainingService: SystemManagerTrainingsService,
    private routerService: RouterService) {
    super();
  }

  ngOnInit() {
    this.trainings$ = this.systemManagerTrainingService.getSystemManagerTrainings({
      institutionId: this.routerService.institutionId,
      systemManagerId: this.routerService.systemManagerId,
      programId: this.routerService.programId
    }).pipe(
      filter(trainings => Array.isArray(trainings)),
      map(trainings => {
        return trainings.slice().sort((a: ITrainingDto, b: ITrainingDto) => {

          if (a.trainingName < b.trainingName) {
            return -1;
          } else if (a.trainingName > b.trainingName) {
            return 1;
          }
          return 0;
        });
      }),
      takeUntil(this.autoUnsubscribe)
    );
    this.form = this.fb.group({
      enrollmentRole: [null, Validators.required],
      trainingId: [null, Validators.required],
      renewalInterval: [1, [Validators.required, Validators.min(1)]],
      required: [false]
    });

  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public save(): void {
    const association: IInstitutionProgramTrainingCreateDto = {
      renewalInterval: this.form.value.renewalInterval,
      required: this.form.value.required
    };
    this.systemManagerTrainingService.saveRequiredEnrollmentTraining({ institutionId: this.routerService.institutionId, systemManagerId: this.routerService.systemManagerId, programId: this.routerService.programId, enrollmentRole: this.form.value.enrollmentRole, trainingId: this.form.value.trainingId, programTraining: association }).subscribe(() => {
      this.dialogRef.close(this.form.value);
    });

  }

}
