import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IEventDto, IProgramYearDto } from 'app/core/models';
import { ProgramYearService, RouterService } from 'app/core/services';
import { filter, Observable, take } from 'rxjs';

@Component({
  selector: 'ng4h-modal-add-event',
  templateUrl: './modal-add-event.component.html',
  styleUrls: ['./modal-add-event.component.scss']
})
export class ModalAddEventComponent implements OnInit {

  public form: FormGroup;
  public programYear$: Observable<IProgramYearDto>;

  constructor(
    private dialogRef: MatDialogRef<ModalAddEventComponent>,
    private fb: FormBuilder,
    private routerService: RouterService,
    private programYearService: ProgramYearService,
    @Inject(MAT_DIALOG_DATA) public fromEvent?: IEventDto
  ) {
  }

  ngOnInit() {
    this.programYear$ = this.programYearService.getCurrentProgramYear(
      {
        institutionId: this.routerService.institutionId,
        programId: this.routerService.programId
      })
      .pipe(
        filter(py => py != null),
        take(1)
      );


    this.programYear$.subscribe(currentProgramYear => {
      this.form = this.fb.group({
        eventName: [this.fromEvent?.eventName, Validators.required],
        eventDescription: [this.fromEvent?.eventDescription, Validators.required],
        programYear: [currentProgramYear.programYearId, Validators.required],
        startDate: [this.fromEvent?.startDate, Validators.required],
        endDate: [this.fromEvent?.endDate, Validators.required]
      });
    });


  }

  public close(result?: any) {
    this.dialogRef.close(result);
  }

  public add() {


    this.close(this.form.value);

  }
}
