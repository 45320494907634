import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  IAwardCreateDto,
  IAwardDto,
  IAwardUpdateDto,
  IInstitutionManagerProgramHierarchyParams,
  IInstitutionManagerProgramParams,
} from 'app/core/models';

export const LoadAwardsAction = createAction(
    '[manager awards] Load awards',
    props<IInstitutionManagerProgramHierarchyParams>()
);
export const LoadAwardsSuccessAction = createAction(
    '[manager awards] Load awards success',
    props<IInstitutionManagerProgramHierarchyParams & { awards: IAwardDto[] }>()
);
export const LoadAwardsErrorAction = createAction(
    '[manager awards] Load awards error',
    props<{ error: HttpErrorResponse }>()
);

export const LoadAwardAction = createAction(
    '[manager activities] Load award',
    props<IInstitutionManagerProgramParams & { awardId: string }>()
);
export const LoadAwardSuccessAction = createAction(
    '[manager activities] Load award success',
    props<IInstitutionManagerProgramParams & { awardId: string, award: IAwardDto }>()
);
export const LoadAwardErrorAction = createAction(
    '[manager activities] Load award error',
    props<{ error: HttpErrorResponse }>()
);


export const CreateAwardAction = createAction(
    '[manager activities] Create award',
    props<IInstitutionManagerProgramParams & { awardCreate: IAwardCreateDto }>()
);
export const CreateAwardSuccessAction = createAction(
    '[manager activities] Create award success',
    props<IInstitutionManagerProgramParams & { awardCreate: IAwardCreateDto }>()
);
export const CreateAwardErrorAction = createAction(
    '[manager activities] Create award error',
    props<{ error: HttpErrorResponse }>()
);

export const UpdateAwardAction = createAction(
    '[manager activities] Update award',
    props<IInstitutionManagerProgramParams & { awardId: string, awardUpdate: IAwardUpdateDto }>()
);
export const UpdateAwardSuccessAction = createAction(
    '[manager activities] Update award success',
    props<IInstitutionManagerProgramParams & { awardId: string, awardUpdate: IAwardUpdateDto }>()
);
export const UpdateAwardErrorAction = createAction(
    '[manager activities] Update award error',
    props<{ error: HttpErrorResponse }>()
);

export const DeleteAwardAction = createAction(
    '[manager activities] Delete award',
    props<IInstitutionManagerProgramParams & { awardId: string }>()
);
export const DeleteAwardSuccessAction = createAction(
    '[manager activities] Delete award success',
    props<IInstitutionManagerProgramParams & { awardId: string }>()
);
export const DeleteAwardErrorAction = createAction(
    '[manager activities] Delete award error',
    props<{ error: HttpErrorResponse }>()
);
