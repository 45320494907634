import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { eVolunteerType, IInstitutionVolunteerCreateDto } from 'app/core/models';
import { RouterService, SystemManagerVolunteerTypesService } from 'app/core/services';
import { take } from 'rxjs';

@Component({
  selector: 'ng4h-modal-add-volunteer-type',
  templateUrl: './modal-add-volunteer-type.component.html',
  styleUrls: ['./modal-add-volunteer-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalAddVolunteerTypeComponent implements OnInit {

  public form: FormGroup;

  public eVolunteerType = eVolunteerType;
  constructor(
    private dialogRef: MatDialogRef<any>,
    private fb: FormBuilder,
    private systemManagerVolunteerTypesService: SystemManagerVolunteerTypesService,
    private routerService: RouterService) { }

  ngOnInit() {
    this.form = this.fb.group({
      volunteerType: [null, Validators.required],
      volunteerTypeLabel: [null, Validators.required],
      volunteerTypeDescription: [null, Validators.required],
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  public save(): void {
    const volunteerType: IInstitutionVolunteerCreateDto = this.form.value;

    this.systemManagerVolunteerTypesService.addVolunteerType({
      institutionId: this.routerService.institutionId,
      systemManagerId: this.routerService.systemManagerId,
      programId: this.routerService.programId,
      volunteerType
    }).pipe(take(1)).subscribe(res => {
      this.close();
    });

  }

}
