import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalConfirmComponent } from 'app/core/components/modal-confirm/modal-confirm.component';
import { ModalCreditConsentComponent } from 'app/core/components/modal-credit-consent/modal-credit-consent.component';
import {
    eHierarchyNodeType,
    ePaymentStatus,
    ePermissionState,
    eProfileType,
    IEnrollmentDto,
    IHierarchyAny,
    IInvoiceDto,
    IInvoiceLineItemDto,
    IManagerPermissions,
    IMemberConsentSubmitDto,
    IMemberDto,
    IPaymentDto
} from 'app/core/models';
import {
    CommonToastrService,
    EnrollmentService,
    HierarchyService,
    ManagerFinancesService,
    MemberService,
    ModalService,
    ProfileService,
    RouterService
} from 'app/core/services';
import { ManagerEnrollmentService } from 'app/core/services/manager-enrollment.service';
import { environment } from 'environments/environment';
import { filter, map, Observable, switchMap, take } from 'rxjs';

@Component({
    selector: 'ng4h-modal-manager-invoice',
    templateUrl: './modal-manager-enrollment-invoice.component.html',
    styleUrls: ['./modal-manager-enrollment-invoice.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalManagerEnrollmentInvoiceComponent implements OnInit {

    public ePermissionState = ePermissionState;
    public eHierarchyNodeType = eHierarchyNodeType;
    public enrollment$: Observable<IEnrollmentDto>;
    // public primaryClub$: Observable<IEnrollmentClubDto>;

    public invoice$: Observable<IInvoiceDto>;
    public lineItems$: Observable<IInvoiceLineItemDto[]>;
    public lineItemColumns: Map<string, string> = new Map();
    public lineItemColumnTransforms = new Map<string, Function>();

    public payments$: Observable<IPaymentDto[]>;
    public pendingTransactions$: Observable<boolean>;
    public paymentsColumns: Map<string, string> = new Map();
    public paymentsColumnTransforms = new Map<string, Function>();
    public environment = environment;

    public member$: Observable<IMemberDto>;

    public couponCodeForm: FormGroup;

    public waiveFeePermission: Partial<IManagerPermissions> = {
        Financial_Invoicing: ePermissionState.Write
    };
    public selectedNode$: Observable<IHierarchyAny>;

    public isInstitutionManager$: Observable<boolean>;

    constructor(
        private dialogRef: MatDialogRef<any>,
        private fb: FormBuilder,
        private memberEnrollmentService: EnrollmentService,
        private managerEnrollmentService: ManagerEnrollmentService,
        private routerService: RouterService,
        @Inject(MAT_DIALOG_DATA) private invoiceId: string,
        private memberService: MemberService,
        private toastrService: CommonToastrService,
        private managerFinanceService: ManagerFinancesService,
        private modalService: ModalService,
        private hierarchyService: HierarchyService,
        private profileService: ProfileService
    ) {

        this.isInstitutionManager$ = this.profileService.loggedInAsInstitutionProfile.pipe(
            filter(profile => profile != null),
            map(profile => {
                if (profile.profileType === eProfileType.Superuser) {
                    return true;
                }
                if (profile.profileType === eProfileType.Manager) {
                    return profile.managerPrograms?.[this.routerService.programId]?.find(managerProgram => managerProgram.hierarchyNodeType === eHierarchyNodeType.Institution) != null;
                }
                return false;
            })
        );

        this.selectedNode$ = this.routerService.managerHierarchyId$.pipe(filter(id => id != null), switchMap(managerHierarchyId => this.hierarchyService.getHierarchyNode({
            institutionId: this.routerService.institutionId,
            hierarchyNodeId: this.routerService.managerHierarchyId
        })));

        this.enrollment$ = this.memberEnrollmentService.getEnrollment({
            institutionId: this.routerService.institutionId,
            memberId: this.routerService.selectedMemberId,
            programId: this.routerService.programId,
            enrollmentId: this.routerService.enrollmentId
        });

        this.invoice$ = this.enrollment$.pipe(map(enrollment => enrollment.invoices.find(invoice => invoice.invoiceId === this.invoiceId)));
        this.lineItems$ = this.invoice$.pipe(map(invoice => invoice.lineItems));
        this.payments$ = this.invoice$.pipe(map(invoice => invoice.payments));
        this.pendingTransactions$ = this.invoice$.pipe(map(invoice => invoice.payments.some(p => p.status === ePaymentStatus.Pending)));
        this.member$ = this.memberService.getMember({
            institutionId: this.routerService.institutionId,
            memberId: this.routerService.selectedMemberId
        });

        this.couponCodeForm = this.fb.group({
            couponCode: [null, [Validators.required, Validators.pattern(`^[a-zA-Z0-9]{6}$`)]]
        });
    }

    ngOnInit() {
        this.lineItemColumns.set('description', 'DESCRIPTION');
        this.lineItemColumns.set('rate', 'RATE');
        this.lineItemColumns.set('quantity', 'QUANTITY');
        this.lineItemColumns.set('amount', 'AMOUNT');
        this.lineItemColumnTransforms.set('amount', this.currency);
        this.paymentsColumns.set('paymentMethod.type', 'TYPE');
        this.paymentsColumns.set('status', 'Status');
        this.paymentsColumns.set('amount', 'AMOUNT');
        this.paymentsColumnTransforms.set('amount', this.currency);
    }

    private currency(amount: string) {
        if (amount == null) {
            amount = '0';
        }
        // return new CurrencyPipe('en-US').transform(amount);
        return amount;
    }

    public close(result?: any): void {
        this.dialogRef.close(result);
    }

    public save(): void {
        this.close();
    }

    public rowClicked(lineItem: IInvoiceLineItemDto) {

    }

    public applyCoupon() {
        const couponCode = this.couponCodeForm.value.couponCode;

        this.memberEnrollmentService.applyCouponCode({
            institutionId: this.routerService.institutionId,
            memberId: this.routerService.selectedMemberId,
            programId: this.routerService.programId,
            enrollmentId: this.routerService.enrollmentId,
            couponCode
        }).pipe(
            take(1)
        ).subscribe({
            next: res => {
                this.toastrService.success('Coupon Applied');
                this.couponCodeForm.controls.couponCode.setValue(null);
                this.couponCodeForm.controls.couponCode.updateValueAndValidity();
                this.memberEnrollmentService.refreshEnrollment();
            }, error: (error) => {
                this.toastrService.error('Could not apply coupon', error);
            }
        });
    }

    public applyCredit(invoice: IInvoiceDto, member: IMemberDto) {

        this.modalService.openModal(ModalCreditConsentComponent, { data: { invoice, member } }).pipe(
            take(1),
            filter(res => res !== false)
        ).subscribe((consent: IMemberConsentSubmitDto) => {
            return this.memberEnrollmentService.applyCredit({
                institutionId: this.routerService.institutionId,
                memberId: this.routerService.selectedMemberId,
                programId: this.routerService.programId,
                enrollmentId: this.routerService.enrollmentId,
                consent
            }).pipe(
                take(1),
                switchMap(() => this.memberEnrollmentService.refreshEnrollment()),
                take(1),
                this.modalService.showProgressSpinnerModalUntilComplete()
            ).subscribe({
                next: res => {
                    this.toastrService.success('Credit Applied');
                }, error: (error) => {
                    this.toastrService.error('Could not apply credit', error);
                }
            });
        });

    }

    public waiveFees() {
        this.modalService.openModal(ModalConfirmComponent, {
            data: {
                header: 'Waive Fees',
                body: `Are you sure you would like to waive all fees for this invoice?.`
            }
        }).pipe(
            filter(confirm => confirm),
            switchMap(() => this.lineItems$),
            take(1),
            switchMap(lineItems => {
                return this.managerFinanceService.applyEnrollmentWaiver({
                    institutionId: this.routerService.institutionId,
                    memberId: this.routerService.selectedMemberId,
                    programId: this.routerService.programId,
                    enrollmentId: this.routerService.enrollmentId,
                    invoiceId: this.invoiceId,
                    invoiceWaiver: {
                        description: '',
                        invoiceItemIds: lineItems.map(lineItem => lineItem.invoiceItemId)
                    }
                });
            })
        ).subscribe({
            next: lineItems => {
                this.toastrService.success('Fees Waived');
                this.memberEnrollmentService.refreshEnrollment();
            }, error: (error) => {
                this.toastrService.error('Error waiving fees', error);
            }
        });
    }

    public processPayment() {
        this.managerEnrollmentService.processPaymentsEffect({
            institutionId: this.routerService.institutionId,
            memberId: this.routerService.selectedMemberId,
            programId: this.routerService.programId,
            enrollmentId: this.routerService.enrollmentId,
            managerId: this.routerService.managerId
        }).pipe(take(1)).subscribe(res => {

        });
    }

}
