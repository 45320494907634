<ng-container *ngIf="true">
  <div class="modal-header">
    <h5 class="modal-title">Interactions</h5>
    <button (click)="close()" type="button" class="close" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
<form [formGroup]="form" class="row" [ngClass]="wasValidated ? 'was-validated' : 'needs-validation'" novalidate>
    <div class="form-group col-sm-12 row">
      <label class="form-control-label col-4 ng4h-required">Lesson Title</label>
      <input formControlName="trainingLessonTitle" type="email" class="form-control col-8">
    </div>
    <div class="form-group col-sm-12 row">

    </div>
  </form>
  </div>

  <div class="modal-footer">
    <button (click)="close()" class="btn btn-secondary" role="button" aria-pressed="true">Close</button>
    <button (click)="save()" class="btn btn-primary" role="button" aria-pressed="true">Save</button>
  </div>
</ng-container>
