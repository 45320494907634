import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IFamilyEventDto, IFamilyMemberDto, IMemberEventDescriptorDto } from 'app/core/models';
import { IEventRegistrationTypeDto } from 'app/core/models/serverDTOs/IEventRegistrationTypeDto';
import { CommonToastrService, EventsMemberService, RouterService } from 'app/core/services';
import { Observable, take } from 'rxjs';

@Component({
  selector: 'ng4h-modal-register-for-promoted-event',
  templateUrl: './modal-register-for-promoted-event.component.html',
  styleUrls: ['./modal-register-for-promoted-event.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalRegisterForPromotedEventComponent implements OnInit {

  public familyMembers$: Observable<IFamilyMemberDto[]>;
  public selectedFamilyMember: IMemberEventDescriptorDto;
  public availableRegistrationTypes: IEventRegistrationTypeDto[];
  public selectedRegistrationType: IEventRegistrationTypeDto;

  public registrationTypeColumns = ['registrationTypeName', 'registrationRole', 'registrationTypeStartDate', 'registrationTypeEndDate'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public familyEvent: IFamilyEventDto,
    private dialogRef: MatDialogRef<any>,
    // private familyService: FamilyService,
    private routerService: RouterService,
    private eventsService: EventsMemberService,
    private toasterService: CommonToastrService
  ) {
    console.log(familyEvent)
  }

  ngOnInit() {
    // this.familyMembers$ = this.familyService.getFamily({
    //   institutionId: this.routerService.institutionId,
    //   familyId: this.routerService.familyId
    // }).pipe(
    //   filter(family => family != null),
    //   map(family => {
    //     return family.familyMembers;
    //   })
    // );
  }

  public rowClicked(familyMember: IMemberEventDescriptorDto) {
    this.selectedFamilyMember = familyMember;
    this.availableRegistrationTypes = this.familyEvent.event.registrationTypes.filter(registrationType => {
      return this.selectedFamilyMember.availableRegistrationTypes.find(art => art.registrationTypeId === registrationType.registrationTypeId);
    });
    if (this.availableRegistrationTypes?.length === 1) {
      this.registrationTypeSelected(this.availableRegistrationTypes[0]);
    } else {
      this.registrationTypeSelected(null);
    }
  }
  // public select() {
  //   this.close(this.selectedFamilyMember);
  // }
  public close(familyMember?: IMemberEventDescriptorDto) {
    this.dialogRef.close(familyMember);
  }

  public registrationTypeSelected(registrationType?: IEventRegistrationTypeDto) {
    this.selectedRegistrationType = registrationType;
  }
  public startRegistration() {
    this.eventsService.registerForEvent({
      institutionId: this.routerService.institutionId,
      memberId: this.selectedFamilyMember.memberId,
      programId: this.familyEvent.event.programId,
      eventRegistrationCreate: { registrationTypeId: this.selectedRegistrationType.registrationTypeId }
    }).pipe(
      take(1)
    ).subscribe({
      next: eventRegistration => {
        if (eventRegistration == null || eventRegistration.registrationId == null) {
          this.toasterService.error('Could not find the created event registration');
        } else {
          this.routerService.Go([
            this.routerService.userAccountId,
            this.routerService.institutionId,
            this.routerService.loggedInInstitutionProfileId,
            'member',
            this.selectedFamilyMember.memberId,
            this.familyEvent.event.programId,
            'events',
            eventRegistration.registrationId,
            'register',
            this.familyEvent.event.eventId,
            this.selectedRegistrationType.registrationTypeId
          ]);
          this.close();
        }
      }, error: error => {
        this.toasterService.error('Could not register for event.', error);
      }
    });
  }


}
