<ng-container *ngIf="volunteerTypes$ | async as volunteerTypes">
    <ng-container *ngIf="(volunteerTypesPageMessage$ | async) == null">
        <div class="col-12">
            <table>
                <thead>
                    <th>Type</th>
                    <th>Role</th>
                    <th *ngIf="allowEdit">Remove</th>
                </thead>
                <tbody>
                    <tr *ngFor="let volunteerType of volunteerTypes">
                        <td>
                            {{volunteerType.volunteerTypeDescriptor.volunteerType | camelToTitleCase | replace: 'Adult
                            ': '' | replace: 'Club' : ('Club' | programAlias$
                            | async)?.singular}}
                        </td>
                        <td>{{volunteerType.volunteerTypeLabel}}</td>
                        <td *ngIf="allowEdit" class="center">
                            <span aria-label="Remove" class="remove-project"
                                (click)="removeVolunteerType(volunteerType)"><i class="fal fa-trash-alt"></i></span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-12" *ngIf="allowEdit">
            <button class="btn ng4h-margin-top ng4h-margin-bottom"
                [ngClass]="volunteerTypes.length > 0 ? 'btn-secondary' : 'btn-primary'"
                (click)="addVolunteerTypes()">Select
                Volunteer Types</button>
        </div>
    </ng-container>
</ng-container>
<ng-container *ngIf="volunteerTypesPageMessage$ | async as message">
    <ng4h-in-app-help class="selection-intro">
        <div ng4h-in-app-help-header>Volunteer Types</div>
        <div ng4h-in-app-help-body>
            <div [innerHtml]="message.message | safeHtml"></div>
        </div>
        <div ng4h-in-app-help-footer>
            <button (click)="closeHelp()" class="btn btn-primary">Select Volunteer Types</button>
        </div>
    </ng4h-in-app-help>
</ng-container>