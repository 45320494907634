import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState, getUnitMembers, getUnits } from 'app/app.reducers';
import {
  IEnrollmentUnitDto,
  IInstitutionMemberProgramEnrollmentParams,
  IInstitutionMemberProgramParams,
  IMemberSearchResultDto
} from 'app/core/models';
import { MemberUnitActions } from 'app/shared/units';
import { downloadFile } from 'app/shared/utils';
import { filter, Observable, of, tap } from 'rxjs';

import { CommonToastrService } from '.';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClubsService {

  constructor(private store: Store<AppState>, private httpClient: HttpClient, private toastrService: CommonToastrService) {
    // this.clubs$ = this.store.pipe(select(getClubs));
  }

  public getClubs(params: IInstitutionMemberProgramEnrollmentParams & { countyAreaId: string }): Observable<IEnrollmentUnitDto[]> {

    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getUnits(params)),
      tap(clubs => {
        if (clubs === undefined) {
          this.store.dispatch(MemberUnitActions.UnitsLoadAction(params));
        }
      }),
      filter(clubs => clubs != null)
    );
  }

  public loadClubsEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    countyAreaId
  }: IInstitutionMemberProgramEnrollmentParams & { countyAreaId: string }): Observable<IEnrollmentUnitDto[]> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/units?countyAreaId=${countyAreaId}`) as Observable<IEnrollmentUnitDto[]>;
  }

  public clubLeaderGetMembers(params: IInstitutionMemberProgramParams & { unitId: string }) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getUnitMembers(params)),
      tap(members => {
        if (members === undefined) {
          this.store.dispatch(MemberUnitActions.UnitLeaderLoadMembersAction(params));
        }
      }),
      filter(members => members != null)
    );
  }

  public clubLeaderGetMembersEffect({
    institutionId,
    memberId,
    programId,
    unitId
  }: IInstitutionMemberProgramParams & { unitId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/unit-members/${unitId}`) as Observable<IMemberSearchResultDto[]>;
  }

  public downloadClubLeaderRoster({
    institutionId,
    memberId,
    programId,
    unitId
  }: IInstitutionMemberProgramParams & { unitId: string }) {
    downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/unit-members/${unitId}/roster`, this.toastrService);
  }
}
