import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  eHealthFormType,
  IInstitutionMemberProgramParams,
  IMemberHealthFormDto,
  IMemberHealthFormSubmitDto,
  IMemberHealthMedicationSubmitDto,
  IMemberProgramConsentDto,
} from 'app/core/models';


export const HealthFormLoadAction = createAction(
  '[health form] Load',
  props<IInstitutionMemberProgramParams & { healthFormType: eHealthFormType }>()
);
export const HealthFormLoadSuccessAction = createAction(
  '[health form] Load success',
  props<{ institutionId: string, memberId: string, programId: string, healthFormType: eHealthFormType, healthForm: IMemberHealthFormDto }>()
);
export const HealthFormLoadErrorAction = createAction(
  '[health form] Load error',
  props<{ error: HttpErrorResponse }>()
);

export const HealthFormConsentSaveAction = createAction(
  '[health form] Consent save',
  props<{ institutionId: string, programId: string, healthFormConsent: IMemberProgramConsentDto }>()
);
export const HealthFormConsentSaveSuccessAction = createAction(
  '[health form] Consent save success'
);
export const HealthFormConsentSaveErrorAction = createAction(
  '[health form] Consent save error',
  props<{ error: HttpErrorResponse }>()
);
export const HealthFormSaveResponsesAction = createAction(
  '[health form] Save health form',
  props<{ institutionId: string, memberId: string, programId: string, healthFormType: eHealthFormType, healthForm: IMemberHealthFormSubmitDto }>()
);
export const HealthFormSaveResponsesSuccessAction = createAction(
  '[health form] Save health form success',
  props<{ institutionId: string, memberId: string, programId: string, healthFormType: eHealthFormType, healthForm: IMemberHealthFormSubmitDto }>()
);
export const HealthFormSaveResponsesErrorAction = createAction(
  '[health form] Save health form error',
  props<{ error: HttpErrorResponse }>()
);
export const HealthFormInvalidateCacheAction = createAction(
  '[health form] Invalidate cache',
  props<{ institutionId: string, memberId: string, programId: string, healthFormType: eHealthFormType }>()
);
export const MemberHealthFormAddMedicationAction = createAction(
  '[health form] member add medication',
  props<{ institutionId: string, memberId: string, programId: string, medication: IMemberHealthMedicationSubmitDto }>()
);
export const MemberHealthFormAddMedicationSuccessAction = createAction(
  '[health form] member add medication success',
  props<{ institutionId: string, memberId: string, programId: string, medication: IMemberHealthMedicationSubmitDto }>()
);
export const MemberHealthFormAddMedicationErrorAction = createAction(
  '[health form] member add medication error',
  props<{ error: HttpErrorResponse }>()
);
export const MemberHealthFormUpdateMedicationAction = createAction(
  '[health form] member update medication',
  props<{ institutionId: string, memberId: string, programId: string, profileHealthFormMedicationId: string, medication: IMemberHealthFormSubmitDto }>()
);
export const MemberHealthFormUpdateMedicationSuccessAction = createAction(
  '[health form] member update medication success',
  props<{ institutionId: string, memberId: string, programId: string, profileHealthFormMedicationId: string, medication: IMemberHealthFormSubmitDto }>()
);
export const MemberHealthFormUpdateMedicationErrorAction = createAction(
  '[health form] member update medication error',
  props<{ error: HttpErrorResponse }>()
);
export const MemberHealthFormDeleteMedicationAction = createAction(
  '[health form] member delete medication',
  props<{ institutionId: string, memberId: string, programId: string, profileHealthFormMedicationId: string }>()
);
export const MemberHealthFormDeleteMedicationSuccessAction = createAction(
  '[health form] member delete medication success',
  props<{ institutionId: string, memberId: string, programId: string, profileHealthFormMedicationId: string }>()
);
export const MemberHealthFormDeleteMedicationErrorAction = createAction(
  '[health form] member delete medication error',
  props<{ error: HttpErrorResponse }>()
);
export const HealthFormInvalidateMedicationCacheAction = createAction(
  '[health form] invalidate medication cache'
);
