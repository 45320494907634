<div class="form-group row" *ngIf="control.label != undefined">
  <label class="form-control-label col-md-3">Question</label>
  <div class="col-md-9">
    <input [ngModel]="control.label" (ngModelChange)="labelChange($event)" type="text" class="form-control">
  </div>
</div>

<div class="form-group row" *ngIf="showReportLabel">
  <label class="form-control-label col-md-3">Report Label</label>
  <div class="input-group col-md-9">
    <input class="form-control" type="text" [ngModel]="control.reportValue"
      (ngModelChange)="reportLabelChange($event, control.reportValue)">
  </div>
</div>

<ng-content></ng-content>

<div class="ng4h-border ng4h-padding ng4h-border-radius ng4h-margin-bottom-small">
  <div class="form-group row">
    <label class="form-control-label col-md-3">Archived:</label>
    <mat-checkbox class="col-md-9" [ngModel]="control.archived" (ngModelChange)="archivedChange($event)">

    </mat-checkbox>
  </div>
</div>

<div class="ng4h-border ng4h-padding ng4h-border-radius ng4h-margin-bottom-small" *ngIf="showRequired">
  <div class="form-group row">
    <label class="form-control-label col-md-3">Required:</label>
    <mat-checkbox class="col-md-9" [ngModel]="required" (ngModelChange)="logicRequiredChanged($event)">

    </mat-checkbox>
  </div>
</div>

<div class="ng4h-border ng4h-padding ng4h-border-radius">
  <div class="form-group row">
    <label class="form-control-label col-md-3 ng4h-required">Can be viewed by:</label>
    <div class="col-md-9">
      <div *ngFor="let displayType of formDisplayTypes | async" class="row">
        <div class="col-12">
          <mat-checkbox [checked]="control.displayTo.indexOf(displayType.id) != -1"
            (change)="displayToChange($event.checked,displayType.id)">{{displayType.name}}</mat-checkbox>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="manager-section ng4h-border ng4h-padding ng4h-border-radius">
  <div class="row">
    <label class="form-control-label col-md-3">Manager Only</label>
    <div class="col-9">
      <mat-checkbox [checked]="control.managerField" (change)="managerOnly($event.checked)"> True </mat-checkbox>
    </div>
  </div>

  <div class="row">
    <label class="form-control-label col-md-3" [ngClass]="{'ng4h-required': control.managerField}">Minimum Hierarchy
      View</label>
    <div class="col-9">
      <select [disabled]="control.managerField == false" class="form-control col-12"
        (change)="managerMinView($event.target.value)" [value]="control.managerField_MinimumViewHierarchyType">
        <option [ngValue]="null"></option>
        <option value="Institution">Institution</option>
        <option value="CountyArea">County</option>
      </select>
    </div>
  </div>

  <div class="row">
    <label class="form-control-label col-md-3" [ngClass]="{'ng4h-required': control.managerField}">Minimum Hierarchy
      Edit</label>
    <div class="col-9">
      <select [disabled]="control.managerField == false" class="form-control col-12"
        (change)="managerMinEdit($event.target.value)" [value]="control.managerField_MinimumEditHierarchyType">
        <option [ngValue]="null"></option>
        <option value="Institution">Institution</option>
        <option value="CountyArea">County</option>
      </select>
    </div>
  </div>
</div>