import { eInvoiceItemType } from '..';

export interface IInvoiceLineItemDto {
    invoiceLineItemId: string;
    invoiceItemId: string;
    invoiceItemType: eInvoiceItemType;
    description?: string;
    rate: number;
    quantity: number;
    amount: number;
    institutionDiscountId: string;
}
