import { Action, createReducer, on } from '@ngrx/store';
import { eFormType, IDynamicForm, IDynamicOption } from 'app/core/models';
import { IDynamicControl } from 'app/core/models/IDynamicControl';
import { IDynamicItem } from 'app/core/models/IDynamicItem';

import { DynamicFormActions } from '.';

export interface DynamicFormState {
  form: IDynamicForm;
  groupBeingEditedIndex: number;
  controlBeingEditedIndex: number;
  controlBeingEdited: IDynamicControl;
  formType: eFormType;
}

const initialState: DynamicFormState = {
  form: undefined,
  groupBeingEditedIndex: 0,
  controlBeingEditedIndex: 0,
  formType: undefined,
  controlBeingEdited: undefined
};

const reducer = createReducer(
  initialState,
  on(DynamicFormActions.DynamicFormLoadEventFormSuccessAction,
    DynamicFormActions.DynamicFormLoadManagerEnrollmentFormSuccessAction,
    DynamicFormActions.DynamicFormLoadSystemManagerEnrollmentFormSuccessAction, (state, { form }) => {
      const newForm = form;
      if (newForm == null) {
        return state;
      }
      const newGroups = [...newForm.groups];
      if (newGroups.length < 1) {
        newGroups.push({
          controls: [],
          groupId: undefined,
          label: ''
        });
      }
      return {
        ...state,
        form: {
          ...newForm,
          groups: newGroups
        }
      };
    }),
  on(DynamicFormActions.DynamicFormAddPageBreakAction, (state, { }) => {
    const newGroups = [...state.form.groups];
    newGroups.push({
      controls: [],
      groupId: undefined,
      label: ''
    });
    return {
      ...state,
      form: {
        ...state.form,
        groups: newGroups
      }
    };
  }),
  on(DynamicFormActions.DynamicFormRemovePageBreakAction, (state, { groupIndex }) => {
    const newGroups = [...state.form.groups];
    newGroups.splice(groupIndex, 1);
    return {
      ...state,
      form: {
        ...state.form,
        groups: newGroups
      }
    };
  }),
  on(DynamicFormActions.DynamicFormReorderGroupsAction, (state, { order }) => {
    const newGroups = new Array(state.form.groups.length);
    for (let i = 0; i < state.form.groups.length; i++) {
      newGroups[i] = state.form.groups[order[i]];
    }
    return {
      ...state,
      form: {
        ...state.form,
        groups: newGroups
      }
    };
  }),
  on(DynamicFormActions.DynamicSelectedControlsChangedAction, (state, { groupIndex, controls }) => {
    const newGroups = [
      ...state.form.groups.slice(0, groupIndex),
      {
        ...state.form.groups[groupIndex], controls: controls
      },
      ...state.form.groups.slice(groupIndex + 1)
    ];

    return {
      ...state,
      form: {
        ...state.form,
        groups: newGroups
      }
    };
  }),
  on(DynamicFormActions.DynamicFormChangeActiveGroupAction, (state, { groupIndex }) => {
    return {
      ...state,
      groupBeingEditedIndex: groupIndex,
      controlBeingEditedIndex: null
    };
  }),
  on(DynamicFormActions.DynamicEditControlAction, (state, { groupIndex, controlIndex, formType }) => {
    return {
      ...state,
      groupBeingEditedIndex: groupIndex,
      controlBeingEditedIndex: controlIndex,
      controlBeingEdited: state.form.groups[groupIndex].controls[controlIndex],
      formType
    };
  }),
  on(DynamicFormActions.DynamicItemAddAction, (state, { item }) => {
    const newItems = [...state.controlBeingEdited.items];
    newItems.push(item);
    return addItems(state, newItems);
  }),
  on(DynamicFormActions.DynamicItemRemoveAction, (state, { itemId }) => {
    const newItems = [...state.controlBeingEdited.items];
    const itemToRemoveIndex = newItems.findIndex(item => {
      return item.itemId === itemId;
    });
    if (itemToRemoveIndex > -1) {
      newItems.splice(itemToRemoveIndex, 1);
    }
    return addItems(state, newItems);
  }),
  on(DynamicFormActions.DynamicItemModifyOptionAction, (state, { partialItem }) => {
    const newItems = [...state.controlBeingEdited.items];
    const itemToModifyIndex = newItems.findIndex(item => {
      return item.itemId === partialItem.itemId;
    });
    const oldItem = newItems[itemToModifyIndex];


    // add old attributes to new item so we don't overwrite old attributes with undefined
    if (partialItem.attributes != null && oldItem.attributes != null) {
      partialItem = {
        ...partialItem,
        attributes: { ...oldItem.attributes, ...partialItem.attributes }
      };
    }
    newItems[itemToModifyIndex] = { ...oldItem, ...partialItem };
    return addItems(state, newItems);
  }),
  on(DynamicFormActions.DynamicOptionAddAction, (state, { option }) => {
    const newOptions = [...state.controlBeingEdited.items[0].options];
    newOptions.push(option);
    return addOptions(state, newOptions);
  }),
  on(DynamicFormActions.DynamicOptionRemoveAction, (state, { optionId }) => {
    const newOptions = [...state.controlBeingEdited.items[0].options];
    const optionToRemoveIndex = newOptions.findIndex(option => {
      return option.optionId === optionId;
    });
    if (optionToRemoveIndex > -1) {
      newOptions.splice(optionToRemoveIndex, 1);
    }
    return addOptions(state, newOptions);
  }),
  on(DynamicFormActions.DynamicOptionModifyAction, (state, { partialOption }) => {
    const newOptions = [...state.controlBeingEdited.items[0].options];
    const optionToModifyIndex = newOptions.findIndex(option => {
      return option.optionId === partialOption.optionId;
    });
    const oldOption = newOptions[optionToModifyIndex];

    if (partialOption.attributes != null && oldOption.attributes != null) {
      partialOption = {
        ...partialOption,
        attributes: { ...oldOption.attributes, ...partialOption.attributes }
      };
    }
    newOptions[optionToModifyIndex] = { ...oldOption, ...partialOption };
    return addOptions(state, newOptions);
  }),
  on(DynamicFormActions.DynamicModifyControlAction, (state, action) => {
    const { type, ...payload } = action;
    let newControl = null;
    if (payload.attributes == null) {
      newControl = {
        ...state.controlBeingEdited,
        ...payload
      };
    } else {
      const newAttributes = {
        ...state.controlBeingEdited.attributes,
        ...payload.attributes
      };
      newControl = {
        ...state.controlBeingEdited,
        attributes: newAttributes
      };
    }
    return {
      ...state,
      controlBeingEdited: newControl
    };
  }),
  on(DynamicFormActions.DynamicValidatorRemoveAction, (state, { validator }) => {
    let newControl = state.controlBeingEdited;
    const newValidators = [...newControl.validators];

    const validatorToRemoveIndex = newValidators.findIndex(newValidator => {
      return validator.name === newValidator.name;
    });
    if (validatorToRemoveIndex > -1) {
      newValidators.splice(validatorToRemoveIndex, 1);
    }
    newControl = {
      ...newControl,
      validators: newValidators
    };
    return {
      ...state,
      controlBeingEdited: newControl
    };
  }),
  on(DynamicFormActions.DynamicValidatorAddOrModifyAction, (state, { validator }) => {
    let newValidators = [];
    if (Array.isArray(state.controlBeingEdited.validators)) {
      newValidators = [...state.controlBeingEdited.validators];

      const validatorToModifyIndex = newValidators.findIndex(newValidator => {
        return validator.name === newValidator.name;
      });


      if (validatorToModifyIndex > -1) {
        const newValidator = {
          ...newValidators[validatorToModifyIndex],
          arg: validator.arg
        };
        newValidators.splice(validatorToModifyIndex, 1, newValidator);
      } else {
        newValidators.push(validator);
      }
    } else {
      newValidators.push(validator);
    }

    const newControl = {
      ...state.controlBeingEdited,
      validators: newValidators
    };
    return {
      ...state,
      controlBeingEdited: newControl
    };
  }),
  on(DynamicFormActions.DynamicSaveControlEditsAction, (state, { }) => {
    const newControls = [
      ...state.form.groups[state.groupBeingEditedIndex].controls.slice(0, state.controlBeingEditedIndex),
      state.controlBeingEdited,
      ...state.form.groups[state.groupBeingEditedIndex].controls.slice(state.controlBeingEditedIndex + 1)
    ];

    const newGroups = [
      ...state.form.groups.slice(0, state.groupBeingEditedIndex),
      {
        ...state.form.groups[state.groupBeingEditedIndex],
        controls: newControls
      },
      ...state.form.groups.slice(state.groupBeingEditedIndex + 1)
    ];

    return {
      ...state,
      form: {
        ...state.form,
        groups: newGroups
      }
    };
  }),
  on(DynamicFormActions.DynamicFormResetAction, (state, { }) => {
    return initialState;
  })
);

export function dynamicFormsReducer(state: DynamicFormState | undefined, actions: Action) {
  return reducer(state, actions);
}



function addOptions(state: DynamicFormState, options: IDynamicOption[]): DynamicFormState {
  const newItem = {
    ...state.controlBeingEdited.items[0],
    options: options
  };
  return addItems(state, [newItem]);
}

function addItems(state: DynamicFormState, items: IDynamicItem[]): DynamicFormState {
  const newControl = {
    ...state.controlBeingEdited,
    items: items
  };
  return {
    ...state,
    controlBeingEdited: newControl
  };
}


export const form = (state: DynamicFormState) => state.form;
export const groupBeingEditedIndex = (state: DynamicFormState) => state.groupBeingEditedIndex;
export const controlBeingEditedIndex = (state: DynamicFormState) => state.controlBeingEditedIndex;
export const controlBeingEdited = (state: DynamicFormState) => state.controlBeingEdited;
export const formType = (state: DynamicFormState) => state.formType;