import { IMemberDescriptorDto } from '.';
import { IEnrollmentUnitAdditionRequestCreateDto } from './IEnrollmentUnitAdditionRequestCreateDto';
import { IProfileDescriptorDto } from './IProfileDescriptorDto';
import { IUnitDto } from './IUnitDto';

export interface IEnrollmentUnitAdditionRequestDto extends IEnrollmentUnitAdditionRequestCreateDto {
    enrollmentUnitProgramYearRequestId: string;
    dateCreated: string;
    enrollmentId: string;
    member: IMemberDescriptorDto;
    unit: IUnitDto;
    approved: boolean;
    reviewed: boolean;
    reviewedBy: IProfileDescriptorDto;
    managerComment: string;
}
