import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IFundsTransferSummaryDto, ISuperuserInstitutionProgramParams } from 'app/core/models';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SuperuserFundsService {

  constructor(private httpClient: HttpClient) {
  }

  public getFundTransfers({
    superuserId,
    institutionId,
    programId,
    hierarchyNodeId
  }: ISuperuserInstitutionProgramParams & { hierarchyNodeId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/super-users/${superuserId}/institutions/${institutionId}/programs/${programId}/funds-transfers?hierarchyNodeId=${hierarchyNodeId}`) as Observable<IFundsTransferSummaryDto[]>;
  }

  public getFundTransfer({
    superuserId,
    institutionId,
    programId,
    fundsTransferId
  }: ISuperuserInstitutionProgramParams & { fundsTransferId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/super-users/${superuserId}/institutions/${institutionId}/programs/${programId}/funds-transfers/${fundsTransferId}`) as Observable<IFundsTransferSummaryDto>;
  }

  public setupFundTransfers({ superuserId, institutionId, programId }: ISuperuserInstitutionProgramParams) {
    return this.httpClient.patch(`${environment.apiUri}/api/super-users/${superuserId}/institutions/${institutionId}/programs/${programId}/funds-transfers/set-up`, {});
  }

  public fundsTransfer({
    superuserId,
    institutionId,
    programId,
    fundsTransferId
  }: ISuperuserInstitutionProgramParams & { fundsTransferId: string }) {
    return this.httpClient.patch(`${environment.apiUri}/api/super-users/${superuserId}/institutions/${institutionId}/programs/${programId}/funds-transfers/${fundsTransferId}`, {});
  }
}
