import { eEnrollmentRoleType, eSchoolGradeType } from 'app/core/models';

import { eMemberParticipationStatus } from '../enums/eMemberParticipationStatus';
import { eMemberRole } from './../enums/eMemberRole';
import { ISearchDateRangeDto } from './ISearchDateRangeDto';

export interface IMemberSearchParametersDto {
    searchId?: string;
    programYear: string;
    searchTerm?: string;
    hierarchyNodeIds?: string[];
    enrollmentRole?: eEnrollmentRoleType[];
    enrollmentStatus?: eMemberParticipationStatus[];
    memberRoles?: eMemberRole[];
    memberProgramStatus?: any[];
    memberProgramYearStatus?: any[];
    enrollmentDates?: ISearchDateRangeDto;
    flagged?: boolean;
    schoolGrade?: eSchoolGradeType[];

}
