import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MemberHealthFormService } from 'app/core/services';
import { catchError, exhaustMap, groupBy, map, mergeMap, of } from 'rxjs';

import { MemberHealthFormsActions } from '.';
import { stringKeys } from '../rxjs.utils';


@Injectable()
export class MemberHealthFormEffects {

  loadHealthForm = createEffect(() => this.actions
    .pipe(
      ofType(MemberHealthFormsActions.HealthFormLoadAction),
      groupBy(stringKeys),
      mergeMap(groupedBy => {
        return groupedBy.pipe(
          exhaustMap(payload => {
            return this.healthFormService.loadHealthFormsEffect(payload).pipe(
              map(healthForm => MemberHealthFormsActions.HealthFormLoadSuccessAction({ ...payload, healthForm: healthForm })),
              catchError(error => of(MemberHealthFormsActions.HealthFormLoadErrorAction({ error })))
            );
          })
        );
      })
    ));

  public saveHealthFormResponses = createEffect(() => this.actions.pipe(
    ofType(MemberHealthFormsActions.HealthFormSaveResponsesAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.healthFormService.saveHealthFormResponsesEffect(payload).pipe(
            map(healthForm => MemberHealthFormsActions.HealthFormSaveResponsesSuccessAction(payload)),
            catchError(error => of(MemberHealthFormsActions.HealthFormSaveResponsesErrorAction({ error })))
          );
        })
      );
    })
  ));

  public addHealthFormMedication = createEffect(() => this.actions.pipe(
    ofType(MemberHealthFormsActions.MemberHealthFormAddMedicationAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.healthFormService.addMemberMedicationEffect(payload).pipe(
            map(medication => MemberHealthFormsActions.MemberHealthFormAddMedicationSuccessAction(payload)),
            catchError(error => of(MemberHealthFormsActions.MemberHealthFormAddMedicationErrorAction({ error })))
          );
        })
      );
    })
  ));

  public updateHealthFormMedication = createEffect(() => this.actions.pipe(
    ofType(MemberHealthFormsActions.MemberHealthFormUpdateMedicationAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.healthFormService.updateMemberMedicationEffect(payload).pipe(
            map(medication => MemberHealthFormsActions.MemberHealthFormUpdateMedicationSuccessAction(payload)),
            catchError(error => of(MemberHealthFormsActions.MemberHealthFormUpdateMedicationErrorAction({ error })))
          );
        })
      );
    })
  ));

  public deleteHealthFormMedication = createEffect(() => this.actions.pipe(
    ofType(MemberHealthFormsActions.MemberHealthFormDeleteMedicationAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.healthFormService.deleteMemberMedicationEffect(payload).pipe(
            map(medication => MemberHealthFormsActions.MemberHealthFormDeleteMedicationSuccessAction(payload)),
            catchError(error => of(MemberHealthFormsActions.MemberHealthFormDeleteMedicationErrorAction({ error })))
          );
        })
      );
    })
  ));

  public invalidateHealthFormCache = createEffect(() => this.actions.pipe(
    ofType(MemberHealthFormsActions.HealthFormSaveResponsesSuccessAction),
    map(payload => MemberHealthFormsActions.HealthFormInvalidateCacheAction({ institutionId: payload.institutionId, memberId: payload.memberId, programId: payload.programId, healthFormType: payload.healthFormType }))
  ));

  public invalidateHealthFormMedication = createEffect(() => this.actions.pipe(
    ofType(MemberHealthFormsActions.MemberHealthFormAddMedicationSuccessAction,
      MemberHealthFormsActions.MemberHealthFormUpdateMedicationSuccessAction,
      MemberHealthFormsActions.MemberHealthFormDeleteMedicationSuccessAction),
    map(payload => MemberHealthFormsActions.HealthFormInvalidateMedicationCacheAction())
  ));

  constructor(private actions: Actions, private healthFormService: MemberHealthFormService) { }
}
