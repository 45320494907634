<div class="modal-header">
  <h5 class="modal-title">Add State Projects</h5>
  <button (click)="close()" type="button" class="close" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" *ngIf="enrollmentRole$ | async as enrollmentRole">
  <div class="row" *ngIf="selectedProject == null">
    <div class="col-12">
      <div class="form-group">
        <label class="form-control-label">{{(eUnitType.Club | programAlias$ | async)?.singular}}</label>
        <select class="form-control" [(ngModel)]="selectedUnitId" (ngModelChange)="clubSelectionChanged$.emit($event)">
          <option *ngFor="let unit of selectedUnits$ | async" class="form-control" [value]="unit.unitId">
            {{unit.unitName}}</option>
        </select>
      </div>
      <div class="form-group">
        <!-- <label class="form-control-label">Search</label> -->
        <input [(ngModel)]="projectFilterString" class="form-control" type="text" placeholder="Type to Search...">
      </div>

    </div>
  </div>

  <div class="row" [hidden]="selectedProject != null">
    <div class="col-12">
      <div *ngIf="(remainingProjects$ | async) as remainingProjects" class="card project-card">
        <ul class="list-group list-group-flush">
          <li class="list-group-item"
            *ngFor="let project of remainingProjects | searchfilter: 'projectName' : projectFilterString">
            <div>
              {{project.projectName}}
              <button type="button" class="btn btn-primary float-right add-project"
                (click)="selectProject(project)">Select</button>
            </div>
          </li>
          <li class="list-group-item" *ngIf="remainingProjects.length == 0">
            No projects available
          </li>
        </ul>
      </div>
      <div *ngIf="selectedUnitId == null">
        Please select a project
      </div>
    </div>

  </div>
  <ng-container *ngIf="selectedProject != null">

    <div class="row">
      <div class="col-12">

        <h4>Selected Project </h4>
        <button class="btn btn-secondary ng4h-margin-bottom" (click)="selectedProject = null">Deselect</button>
        <div class="ng4h-table ng4h-margin-bottom">
          <div class="col-12">


            <div class="row">
              <div class="col-4 ng4h-bg-secondary"><b>Project Alias</b></div>
              <div class="col-8">{{selectedProject.projectName}}</div>
            </div>
            <div class="row">
              <div class="col-4 ng4h-bg-secondary"><b>Description</b></div>
              <div class="col-8">{{selectedProject.projectDescription}}</div>
            </div>
            <div class="row" *ngIf="selectedProject.parentProject != null">
              <div class="col-4 ng4h-bg-secondary"><b>State Project</b></div>
              <div class="col-8">{{selectedProject.parentProject.projectName}}</div>
            </div>
          </div>
        </div>



        <div class="form-group">
          <label class="form-control-label ng4h-required">Years In Project</label>
          <ng4h-number-select [(ngModel)]="yearsInProject" [maxNumber]="99"></ng4h-number-select>
        </div>

        <ng-container *ngIf="enrollmentRole !== eEnrollmentRoleType.CloverBud">
          <ng-container *ngIf="volunteerTypes$ | async as vts">
            <ng-container *ngIf="vts.length > 0">
              <div class="form-group">
                <label class="form-control-label"
                  [ngClass]="{'ng4h-required': enrollmentRole === eEnrollmentRoleType.Volunteer}">{{enrollmentRole ===
                  eEnrollmentRoleType.Volunteer ? '' :
                  'Youth'}} Volunteer Type</label>
                <select class="form-control" [ngModel]="volunteerType" (ngModelChange)="volunteerTypeChanged($event)">
                  <option *ngIf="enrollmentRole !== eEnrollmentRoleType.Volunteer" [ngValue]="null"></option>
                  <option *ngFor="let vt of vts" [ngValue]="vt">
                    {{ vt.volunteerTypeLabel }}
                  </option>
                </select>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>



      </div>
    </div>

  </ng-container>
</div>
<div class="modal-footer">
  <button [disabled]="selectedProject == null || yearsInProject == null" class="btn btn-primary ng4h-margin-left"
    (click)="addProject(selectedProject)">Add</button>
  <button (click)="close()" class="btn btn-secondary my-2 my-sm-0" role="button" aria-pressed="true">Cancel</button>
</div>