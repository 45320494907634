import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBulkDownloadDto, IInstitutionManagerProgramParams } from 'app/core/models';
import { downloadFile } from 'app/shared/utils';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BulkDownloadsService {

  constructor(
    private httpClient: HttpClient
  ) {
  }

  public getBulkDownloadsEffect({ institutionId, managerId, programId }: IInstitutionManagerProgramParams) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/bulk-downloads`) as Observable<IBulkDownloadDto[]>;
  }

  public getBulkDownloadFilesEffect({
    institutionId,
    managerId,
    programId,
    bulkDownloadId,
    bulkDownloadZipFileId
  }: IInstitutionManagerProgramParams & { bulkDownloadId: string, bulkDownloadZipFileId: string }) {
    downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/bulk-downloads/${bulkDownloadId}/files/${bulkDownloadZipFileId}`);
  }
}
