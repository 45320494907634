import { eScreeningMode, eScreeningType, IStandardFormColumnDto } from '..';
import { eScreeningSource } from '../enums/eScreeningSource';

export interface IVolunteerScreeningDto {
    fields: {
        [category: string]: IStandardFormColumnDto[]
    };
    screeningId: string;
    screeningType: eScreeningType;
    source: eScreeningSource;
    screeningName?: string;
    active: eScreeningMode;
    renewalInterval: number;
}
