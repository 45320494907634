import { eEnrollmentRoleType, eProgramYouthDivisionType, eSchoolGradeType } from '..';

export interface IProgramYouthDivisionDto {
    youthDivisionId: string;
    roleType: eEnrollmentRoleType;
    youthDivisionType: eProgramYouthDivisionType;
    youthDivisionLabel?: string;
    minAge?: number;
    maxAge?: number;
    minGrade?: eSchoolGradeType;
    maxGrade?: eSchoolGradeType;
    gradeExceptionMinAge?: number;
    ageExceptionMinGrade?: eSchoolGradeType;
}
