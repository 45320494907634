import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'idDecode',
    pure: true
})


export class IdDecodePipe implements PipeTransform {

    transform(id: string): number {
        const number = id.split('_')[1].toUpperCase();

        let num = 0;
        let num2 = 1;
        for (let num3 = number.length - 1; num3 >= 0; num3--) {
            const value = number[num3];
            const num4 = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'.indexOf(value);
            if (num4 === -1) {
                return -1;
            }

            num += num4 * num2;
            num2 *= 36;
        }
        return num;
    }
}
