import { eEventRegistrationStatus, eHierarchyNodeType } from '..';

export interface IEventRegistrationPendingDto {
    memberId: string;
    firstName?: string;
    lastName?: string;
    eventId: string;
    eventName?: string;
    registrationId: string;
    registrationStatus: eEventRegistrationStatus;
    registrationTypeId: string;
    registrationType?: string;
    pendingAtHierarchyNodeType?: eHierarchyNodeType;
}
