import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { EventCustomReportActions } from 'app/core/containers/admin/manager/events/reports/store';
import {
  getEventAvailableColumns,
  getEventAvailableFilterValues,
  getEventCustomReport,
  getEventCustomReports,
  getEventQueuedReports,
  ManagerFeatureStore
} from 'app/core/containers/admin/manager/manager-feature.reducer';
import {
  ICustomReportCreateDto,
  ICustomReportDto,
  ICustomReportFilterDto,
  ICustomReportFilterGroupDto,
  ICustomReportGroupByDto,
  ICustomReportMailingLabelOptionsDto,
  ICustomReportOrderByDto,
  ICustomReportUpdateDto,
  IDirectDataAccessRequestDto,
  IInstitutionManagerProgramEventParams,
  IQueuedReportDto,
  IReportColumnDto,
  IReportFolderCreateDto,
  IReportFolderDto,
  IReportFolderUpdateDto,
  IReportFolderUpdateStateDto
} from 'app/core/models';
import { downloadFile, downloadFileWithBody } from 'app/shared/utils';
import { filter, map, Observable, of, switchMap, take, tap } from 'rxjs';

import { CommonToastrService } from '../..';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EventCustomReportsService {

  constructor(
    private store: Store<ManagerFeatureStore>,
    private httpClient: HttpClient,
    private dispatcher: ActionsSubject,
    private toastrService: CommonToastrService
  ) {
  }

  public getCustomReports(params: IInstitutionManagerProgramEventParams): Observable<IReportFolderDto[]> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getEventCustomReports(params)),
      tap(reports => {
        if (reports === undefined) {
          this.store.dispatch(EventCustomReportActions.CustomReportsLoadReportsAction(params));
        }
      }),
      filter(reports => reports != null)
    );
  }

  public getCustomReportsEffect({
    institutionId,
    managerId,
    programId,
    eventId
  }: IInstitutionManagerProgramEventParams): Observable<IReportFolderDto[]> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/custom-reports`) as Observable<IReportFolderDto[]>;
  }

  public getCustomReport(params: IInstitutionManagerProgramEventParams & { customReportId: string }) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getEventCustomReport(params)),
      tap(report => {
        if (report === undefined) {
          this.store.dispatch(EventCustomReportActions.CustomReportsLoadReportAction(params));
        }
      }),
      filter(report => report != null)
    );
  }

  public getCustomReportEffect({
    institutionId,
    managerId,
    programId,
    eventId,
    customReportId
  }: IInstitutionManagerProgramEventParams & { customReportId: string }): Observable<ICustomReportDto> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/custom-reports/${customReportId}`) as Observable<ICustomReportDto>;
  }

  public addFilterGroupToReport(params: IInstitutionManagerProgramEventParams & { customReportId: string, filterGroup: ICustomReportFilterGroupDto }) {
    this.store.dispatch(EventCustomReportActions.CustomReportsAddFilterGroup(params));
  }

  public reorderSelectedColumns(params: IInstitutionManagerProgramEventParams & { customReportId: string, columns: IReportColumnDto[] }) {
    this.store.dispatch(EventCustomReportActions.CustomReportsReorderColumns(params));
  }

  public reorderFilterGroups(params: IInstitutionManagerProgramEventParams & { customReportId: string, filterGroups: ICustomReportFilterGroupDto[] }) {
    this.store.dispatch(EventCustomReportActions.CustomReportsReorderFilterGroup(params));
  }

  public removeFilterGroupFromReport(params: IInstitutionManagerProgramEventParams & { customReportId: string, filterGroupIndex: number }) {
    this.store.dispatch(EventCustomReportActions.CustomReportsRemoveFilterGroup(params));
  }

  public addFilterToFilterGroup(params: IInstitutionManagerProgramEventParams & { customReportId: string, filterGroupIndex: number, filter: ICustomReportFilterDto }) {
    this.store.dispatch(EventCustomReportActions.CustomReportsAddFilter(params));
  }

  public updateFilterValue(params: IInstitutionManagerProgramEventParams & { customReportId: string, filterGroupIndex: number, filterIndex: number, filter: ICustomReportFilterDto }) {
    this.store.dispatch(EventCustomReportActions.CustomReportsUpdateFilterValue(params));
  }

  public reorderFilters(params: IInstitutionManagerProgramEventParams & { customReportId: string, filterGroupIndex: number, filters: ICustomReportFilterDto[] }) {
    this.store.dispatch(EventCustomReportActions.CustomReportsReorderFilters(params));
  }

  public removeFilterFromFilterGroup(params: IInstitutionManagerProgramEventParams & { customReportId: string, filterGroupIndex: number, filterIndex: number }) {
    this.store.dispatch(EventCustomReportActions.CustomReportsRemoveFilter(params));
  }

  public getAvailableColumns(params: IInstitutionManagerProgramEventParams & { customReportId: string }): Observable<IReportColumnDto[]> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getEventAvailableColumns(params)),
      tap(report => {
        if (report === undefined) {
          this.store.dispatch(EventCustomReportActions.CustomReportsLoadAvailableColumnsAction(params));
        }
      }),
      filter(report => report != null)
    );

  }

  public getAvailableColumnsEffect({
    institutionId,
    managerId,
    programId,
    eventId,
    customReportId
  }: IInstitutionManagerProgramEventParams & { customReportId: string }): Observable<IReportColumnDto[]> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/custom-reports/${customReportId}/available-columns`) as Observable<IReportColumnDto[]>;
  }

  public addColumnToReport(params: IInstitutionManagerProgramEventParams & { customReportId: string, column: IReportColumnDto }) {
    this.store.dispatch(EventCustomReportActions.CustomReportsAddColumnToReport(params));
  }

  public removeColumnToReport(params: IInstitutionManagerProgramEventParams & { customReportId: string, column: IReportColumnDto }) {
    this.store.dispatch(EventCustomReportActions.CustomReportsDeleteColumnToReport(params));
  }

  public getExcelEffect({
    institutionId,
    managerId,
    programId,
    eventId,
    customReportId,
    hierarchyNodeId
  }: IInstitutionManagerProgramEventParams & { customReportId: string, hierarchyNodeId: string }) {
    downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/custom-reports/${customReportId}/excel?hierarchyNodeId=${hierarchyNodeId}`, this.toastrService);
  }

  public getPdfEffect({
    institutionId,
    managerId,
    programId,
    eventId,
    customReportId,
    hierarchyNodeId,
    orientation,
    pagesize
  }: IInstitutionManagerProgramEventParams & { customReportId: string, hierarchyNodeId: string, orientation: string, pagesize: string | number }) {
    downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/custom-reports/${customReportId}/pdf?hierarchyNodeId=${hierarchyNodeId}&orientation=${orientation}&pagesize=${pagesize}`, this.toastrService);
  }

  public createCustomReport(params: IInstitutionManagerProgramEventParams & { customReportFolderId: string, newReport: ICustomReportCreateDto }) {
    this.store.dispatch(EventCustomReportActions.CustomReportsCreateReportAction(params));

    return this.dispatcher.pipe(
      ofType(EventCustomReportActions.CustomReportsCreateReportSuccessAction, EventCustomReportActions.CustomReportsCreateReportErrorAction),
      take(1),
      map(action => {
        if (action.type === EventCustomReportActions.CustomReportsCreateReportSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public createCustomReportEffect({
    institutionId,
    managerId,
    programId,
    customReportFolderId,
    eventId,
    newReport
  }: IInstitutionManagerProgramEventParams & { customReportFolderId: string, newReport: ICustomReportCreateDto }) {
    return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/custom-report-folder/${customReportFolderId}`, newReport, { observe: 'response' }).pipe(
      switchMap((res: HttpResponse<any>) => this.httpClient.get(res.headers.get('location')))
    ) as Observable<ICustomReportDto>;
  }

  public updateCustomReport(params: IInstitutionManagerProgramEventParams & { customReportId: string, customReportUpdate: ICustomReportUpdateDto }) {
    this.store.dispatch(EventCustomReportActions.CustomReportsUpdateReportAction(params));

    return this.dispatcher.pipe(
      ofType(EventCustomReportActions.CustomReportsUpdateReportSuccessAction, EventCustomReportActions.CustomReportsUpdateReportErrorAction),
      take(1),
      map(action => {
        if (action.type === EventCustomReportActions.CustomReportsUpdateReportSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateCustomReportEffect({
    institutionId,
    managerId,
    programId,
    eventId,
    customReportId,
    customReportUpdate
  }: IInstitutionManagerProgramEventParams & { customReportId: string, customReportUpdate: ICustomReportUpdateDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/custom-reports/${customReportId}`, customReportUpdate);
  }

  public trashCustomReport(params: IInstitutionManagerProgramEventParams & { customReportId: string }) {
    this.store.dispatch(EventCustomReportActions.CustomReportsDeleteReportAction(params));

    return this.dispatcher.pipe(
      ofType(EventCustomReportActions.CustomReportsDeleteReportSuccessAction, EventCustomReportActions.CustomReportsDeleteReportErrorAction),
      take(1),
      map(action => {
        if (action.type === EventCustomReportActions.CustomReportsDeleteReportSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public trashCustomReportEffect({
    institutionId,
    managerId,
    programId,
    eventId,
    customReportId
  }: IInstitutionManagerProgramEventParams & { customReportId: string }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/custom-reports/${customReportId}/trash`, {});
  }

  public createCustomReportFolder(params: IInstitutionManagerProgramEventParams & { newFolder: IReportFolderCreateDto }) {
    this.store.dispatch(EventCustomReportActions.CreateReportFolderAction(params));

    return this.dispatcher.pipe(
      ofType(EventCustomReportActions.CreateReportFolderSuccessAction, EventCustomReportActions.CreateReportFolderErrorAction),
      take(1),
      map(action => {
        if (action.type === EventCustomReportActions.CreateReportFolderSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public createCustomReportFolderEffect({
    institutionId,
    managerId,
    programId,
    eventId,
    newFolder
  }: IInstitutionManagerProgramEventParams & { newFolder: IReportFolderCreateDto }) {
    return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/custom-report-folder`, newFolder);
  }

  public updateCustomReportFolder(params: IInstitutionManagerProgramEventParams & { customReportFolderId: string, folderUpdate: IReportFolderUpdateDto }) {
    this.store.dispatch(EventCustomReportActions.UpdateReportFolderAction(params));

    return this.dispatcher.pipe(
      ofType(EventCustomReportActions.UpdateReportFolderSuccessAction, EventCustomReportActions.UpdateReportFolderErrorAction),
      take(1),
      map(action => {
        if (action.type === EventCustomReportActions.UpdateReportFolderSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateCustomReportFolderEffect({
    institutionId,
    managerId,
    programId,
    eventId,
    customReportFolderId,
    folderUpdate
  }: IInstitutionManagerProgramEventParams & { customReportFolderId: string, folderUpdate: IReportFolderUpdateDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/custom-report-folder/${customReportFolderId}`, folderUpdate);
  }

  public updateCustomReportFolderState(params: IInstitutionManagerProgramEventParams & { customReportFolderId: string, folderState: IReportFolderUpdateStateDto }) {
    this.store.dispatch(EventCustomReportActions.UpdateReportFolderStateAction(params));
  }

  public updateCustomReportFolderStateEffect({
    institutionId,
    managerId,
    programId,
    eventId,
    customReportFolderId,
    folderState
  }: IInstitutionManagerProgramEventParams & { customReportFolderId: string, folderState: IReportFolderUpdateStateDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/custom-report-folder/${customReportFolderId}/state`, folderState);
  }

  public deleteCustomReportFolder(params: IInstitutionManagerProgramEventParams & { customReportFolderId: string }) {
    this.store.dispatch(EventCustomReportActions.DeleteReportFolderAction(params));

    return this.dispatcher.pipe(
      ofType(EventCustomReportActions.DeleteReportFolderSuccessAction, EventCustomReportActions.DeleteReportFolderErrorAction),
      take(1),
      map(action => {
        if (action.type === EventCustomReportActions.DeleteReportFolderSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteCustomReportFolderEffect({
    institutionId,
    managerId,
    programId,
    eventId,
    customReportFolderId
  }: IInstitutionManagerProgramEventParams & { customReportFolderId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/custom-report-folder/${customReportFolderId}`);
  }

  public restoreCustomReport(params: IInstitutionManagerProgramEventParams & { customReportId: string }) {
    this.store.dispatch(EventCustomReportActions.CustomReportsRestoreReportAction(params));

    return this.dispatcher.pipe(
      ofType(EventCustomReportActions.CustomReportsRestoreReportSuccessAction, EventCustomReportActions.CustomReportsRestoreReportErrorAction),
      take(1),
      map(action => {
        if (action.type === EventCustomReportActions.CustomReportsRestoreReportSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public restoreCustomReportEffect({
    institutionId,
    managerId,
    programId,
    eventId,
    customReportId
  }: IInstitutionManagerProgramEventParams & { customReportId: string }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/custom-reports/${customReportId}/restore`, {});
  }

  public shareCustomReport(params: IInstitutionManagerProgramEventParams & { customReportId: string }) {
    this.store.dispatch(EventCustomReportActions.CustomReportsShareReportAction(params));

    return this.dispatcher.pipe(
      ofType(EventCustomReportActions.CustomReportsShareReportSuccessAction, EventCustomReportActions.CustomReportsShareReportErrorAction),
      take(1),
      map(action => {
        if (action.type === EventCustomReportActions.CustomReportsShareReportSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public shareCustomReportEffect({
    institutionId,
    managerId,
    programId,
    eventId,
    customReportId
  }: IInstitutionManagerProgramEventParams & { customReportId: string }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/custom-reports/${customReportId}/share`, {});
  }

  public unShareCustomReport(params: IInstitutionManagerProgramEventParams & { customReportId: string }) {
    this.store.dispatch(EventCustomReportActions.CustomReportsUnShareReportAction(params));

    return this.dispatcher.pipe(
      ofType(EventCustomReportActions.CustomReportsUnShareReportSuccessAction, EventCustomReportActions.CustomReportsUnShareReportErrorAction),
      take(1),
      map(action => {
        if (action.type === EventCustomReportActions.CustomReportsUnShareReportSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public unShareCustomReportEffect({
    institutionId,
    managerId,
    programId,
    eventId,
    customReportId
  }: IInstitutionManagerProgramEventParams & { customReportId: string }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/custom-reports/${customReportId}/un-share`, {});
  }

  public sendCustomReport(params: IInstitutionManagerProgramEventParams & { customReportId: string, destinationManagerId: string }) {
    this.store.dispatch(EventCustomReportActions.CustomReportsSendReportAction(params));

    return this.dispatcher.pipe(
      ofType(EventCustomReportActions.CustomReportsSendReportSuccessAction, EventCustomReportActions.CustomReportsSendReportErrorAction),
      take(1),
      map(action => {
        if (action.type === EventCustomReportActions.CustomReportsSendReportSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public sendCustomReportEffect({
    institutionId,
    managerId,
    programId,
    eventId,
    customReportId,
    destinationManagerId
  }: IInstitutionManagerProgramEventParams & { customReportId: string, destinationManagerId: string }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/custom-reports/${customReportId}/send?destinationManagerId=${destinationManagerId}`, {});
  }

  public copyCustomReport(params: IInstitutionManagerProgramEventParams & { customReportId: string }) {
    this.store.dispatch(EventCustomReportActions.CustomReportsCopyReportAction(params));

    return this.dispatcher.pipe(
      ofType(EventCustomReportActions.CustomReportsCopyReportSuccessAction, EventCustomReportActions.CustomReportsCopyReportErrorAction),
      take(1),
      map(action => {
        if (action.type === EventCustomReportActions.CustomReportsCopyReportSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public copyCustomReportEffect({
    institutionId,
    managerId,
    programId,
    eventId,
    customReportId
  }: IInstitutionManagerProgramEventParams & { customReportId: string }) {
    return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/custom-reports/${customReportId}/copy`, {});
  }

  public getAvailableFilterValues(params: IInstitutionManagerProgramEventParams & { customReportId: string, reportColumnId: string, hierarchyNodeId: string }) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getEventAvailableFilterValues(params)),
      tap(report => {
        if (report === undefined) {
          this.store.dispatch(EventCustomReportActions.CustomReportsLoadAvailableFilterValuesAction(params));
        }
      }),
      filter(report => report != null)
    );
  }

  public getAvailableFilterValuesEffect({
    institutionId,
    managerId,
    programId,
    eventId,
    customReportId,
    reportColumnId,
    hierarchyNodeId
  }: IInstitutionManagerProgramEventParams & { customReportId: string, reportColumnId: string, hierarchyNodeId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/custom-reports/${customReportId}/available-filter-values/${reportColumnId}?hierarchyNodeId=${hierarchyNodeId}`) as Observable<{ key: string, value: string }>;
  }

  public addOrderBy(params: IInstitutionManagerProgramEventParams & { customReportId: string, orderBy: ICustomReportOrderByDto }) {
    this.store.dispatch(EventCustomReportActions.CustomReportsAddOrderByAction(params));
  }

  public updateOrderBy(params: IInstitutionManagerProgramEventParams & { customReportId: string, orderByIndex: number, orderBy: ICustomReportOrderByDto }) {
    this.store.dispatch(EventCustomReportActions.CustomReportsUpdateOrderByAction(params));
  }

  public reorderOrderBy(params: IInstitutionManagerProgramEventParams & { customReportId: string, orderBys: ICustomReportOrderByDto[] }) {
    this.store.dispatch(EventCustomReportActions.CustomReportsReorderOrderByAction(params));
  }

  public removeOrderBy(params: IInstitutionManagerProgramEventParams & { customReportId: string, orderByIndex: number }) {
    this.store.dispatch(EventCustomReportActions.CustomReportsRemoveOrderByAction(params));
  }

  public addGroupBy(params: IInstitutionManagerProgramEventParams & { customReportId: string, groupBy: ICustomReportGroupByDto }) {
    this.store.dispatch(EventCustomReportActions.CustomReportsAddGroupByAction(params));
  }

  public updateGroupBy(params: IInstitutionManagerProgramEventParams & { customReportId: string, groupByIndex: number, groupBy: ICustomReportGroupByDto }) {
    this.store.dispatch(EventCustomReportActions.CustomReportsUpdateGroupByAction(params));
  }

  public reorderGroupBy(params: IInstitutionManagerProgramEventParams & { customReportId: string, groupBys: ICustomReportGroupByDto[] }) {
    this.store.dispatch(EventCustomReportActions.CustomReportsReorderGroupByAction(params));
  }

  public removeGroupBy(params: IInstitutionManagerProgramEventParams & { customReportId: string, groupByIndex: number }) {
    this.store.dispatch(EventCustomReportActions.CustomReportsRemoveGroupByAction(params));
  }

  public getPreviewEffect({
    institutionId,
    managerId,
    programId,
    eventId,
    customReportId,
    hierarchyNodeId,
    pageIndex,
    pageSize
  }: IInstitutionManagerProgramEventParams & { customReportId: string, hierarchyNodeId: string, pageIndex: string, pageSize: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/custom-reports/${customReportId}/preview?hierarchyNodeId=${hierarchyNodeId}&pageIndex=${pageIndex}&pageSize=${pageSize}`);
  }

  public getQueuedReports(params: IInstitutionManagerProgramEventParams) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getEventQueuedReports(params)),
      tap(reports => {
        if (reports === undefined) {
          this.store.dispatch(EventCustomReportActions.CustomReportsLoadQueuedReportsAction(params));
        }
      }),
      filter(reports => reports != null)
    );
  }

  public getQueuedReportsEffect({
    institutionId,
    managerId,
    programId,
    eventId
  }: IInstitutionManagerProgramEventParams) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/custom-reports/queued`) as Observable<IQueuedReportDto[]>;
  }

  // public getQueuedReport(params: IInstitutionManagerProgramEventParams & { queueToken: string }) {
  //     if (Object.keys(params).find(key => params[key] == null) != null) {
  //         return of(null);
  //     }
  //     return this.store.pipe(
  //         select(getEventQueuedReport(params)),
  //         tap(report => {
  //             if (report === undefined) {
  //                 this.store.dispatch(EventCustomReportActions.CustomReportsLoadQueuedReportAction(params));
  //             }
  //         }),
  //         filter(report => report != null),
  //     );
  // }

  // public getQueuedReportEffect({ institutionId, managerId, programId, eventId, queueToken }: IInstitutionManagerProgramEventParams & { queueToken: string }) {
  //     return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/custom-reports/${queueToken}`) as Observable<IQueuedReportDto>;
  // }

  public invalidateCache() {
    this.store.dispatch(EventCustomReportActions.CustomReportInvalidateCacheAction());
  }

  public downloadQueuedReport({
    institutionId,
    managerId,
    programId,
    eventId,
    queueToken
  }: IInstitutionManagerProgramEventParams & { queueToken: string }) {
    downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/custom-reports/${queueToken}`);
  }

  public downloadMailingLabels({
    institutionId,
    managerId,
    programId,
    eventId,
    customReportId,
    hierarchyNodeId,
    programYearId,
    mailingLabelOptions
  }: IInstitutionManagerProgramEventParams & { customReportId: string, hierarchyNodeId: string, programYearId: string, mailingLabelOptions: ICustomReportMailingLabelOptionsDto }) {
    downloadFileWithBody(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/custom-reports/${customReportId}/mailing-labels?hierarchyNodeId=${hierarchyNodeId}&programYearId=${programYearId}`, mailingLabelOptions);
  }

  public requestApiAccess(params: IInstitutionManagerProgramEventParams & { customReportId: string, directAccessDto: IDirectDataAccessRequestDto }) {
    this.store.dispatch(EventCustomReportActions.CustomReportsRequestApiAccessAction(params));

    return this.dispatcher.pipe(
      ofType(EventCustomReportActions.CustomReportsRequestApiAccessSuccessAction, EventCustomReportActions.CustomReportsRequestApiAccessErrorAction),
      take(1),
      map(action => {
        if (action.type === EventCustomReportActions.CustomReportsRequestApiAccessSuccessAction.type) {
          return action.location;
        } else {
          throw action.error;
        }
      })
    );
  }

  public requestApiAccessEffect({
    institutionId,
    managerId,
    programId,
    eventId,
    customReportId,
    directAccessDto
  }: IInstitutionManagerProgramEventParams & { customReportId: string, directAccessDto: IDirectDataAccessRequestDto }) {
    return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/custom-reports/${customReportId}/request-direct-access`, directAccessDto,
      { observe: 'response' }).pipe(
      map((res: HttpResponse<any>) => {
        return res.headers.get('location');
      })
    ) as Observable<string>;
  }
}
