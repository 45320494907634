import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { APP_INITIAL_STATE, AppState, getLoginCredentials, getUserAccount } from 'app/app.reducers';
import {
    IFamilyCreateDto,
    IFamilyDto,
    IFamilyUserAccountCreateDto,
    IInstitutionManagerProgramParams,
    ILoginCredentials,
    IRecoverAccount,
    IUserAccountCreationResponseDto,
    IUserAccountDto
} from 'app/core/models';
import { map, Observable, of, shareReplay, switchMap, take, tap } from 'rxjs';

import { UserActions } from '../containers/user';
import { UserAccountSetPasswordDto } from '../models/serverDTOs/UserAccountSetPasswordDto';
import { LocalStorageService } from './local-storage.service';
import { RouterService } from './router.service';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    private loginCredentials$: Observable<ILoginCredentials>;
    public userAccount$: Observable<IUserAccountDto>;

    constructor(
        private store: Store<AppState>,
        private httpClient: HttpClient,
        private localStorageService: LocalStorageService,
        private dispatcher: ActionsSubject,
        private routerService: RouterService
    ) {
        this.loginCredentials$ = this.store.pipe(select(getLoginCredentials));
        this.userAccount$ = this.store.pipe(select(getUserAccount));
    }

    private getAuthenticationHeaders(): Observable<ILoginCredentials> {
        return this.loginCredentials$.pipe(
            map(credentials => {
                if (credentials == null) {
                    const localCredentials = this.localStorageService.getLoginCredentials(this.routerService.userAccountId);
                    if (localCredentials != null) {
                        // if (localCredentials.expiresAt != null) {
                        // We'll make them login again if the jwt expires in less than an hour
                        // expiresAt is formatted in seconds
                        // if ((((new Date()).getTime() / 1000) + ONE_HOUR_IN_SECONDS) < localCredentials.expiresAt) {
                        if (localCredentials.passwordChangeRequired === false && (localCredentials['2FARequired'] == null || (localCredentials['2FARequired'] != null && localCredentials['2FAVerified'] === true))) {
                            this.store.dispatch(UserActions.AuthenticationSuccessAction({ credentials: localCredentials }));
                        }
                        return localCredentials;
                        // }
                        // }
                    }
                    return null;
                }
                return credentials;
            }),
            shareReplay({ bufferSize: 1, refCount: true })
        );
    }

    public getLoginCredentials(): Observable<ILoginCredentials> {
        return this.getAuthenticationHeaders().pipe(
            map(credentials => {
                if (credentials != null && credentials['2FARequired'] != null && credentials['2FAVerified'] === false) {
                    return null;
                }
                return credentials;
            }),
            shareReplay({ bufferSize: 1, refCount: true })
        );
    }

    // public loginWithoutProfiles(credentials: { email: string, password: string } | IRecoverAccount): Observable<ILoginCredentials> {
    //   this.store.dispatch(UserActions.AuthenticateAction({ credentials, getProfiles: false }));

    //   return this.dispatcher.pipe(
    //     ofType(UserActions.AuthenticationErrorAction, UserActions.AuthenticationSuccessAction),
    //     take(1),
    //     map(action => {
    //       if (action.type === UserActions.AuthenticationSuccessAction.type) {
    //         return action.credentials;
    //       } else {
    //         throw action.error;
    //       }
    //     })
    //   );

    // }

    public loginAndGetProfiles(credentials: { email: string, password: string } | IRecoverAccount): Observable<IUserAccountDto> {
        this.store.dispatch(UserActions.AuthenticateAction({ credentials }));

        return this.dispatcher.pipe(
            ofType(UserActions.AuthenticationErrorAction, UserActions.GetUserSuccessAction, UserActions.GetUserErrorAction),
            take(1),
            map(action => {
                if (action.type === UserActions.GetUserSuccessAction.type) {
                    return action.user;
                } else {
                    throw action;
                }
            })
        );
    }

    public magicLinkAndGetProfiles(credentials: { securityStamp: string, code: string }): Observable<IUserAccountDto> {
        this.store.dispatch(UserActions.MagicLinkAuthenticateAction(credentials));

        return this.dispatcher.pipe(
            ofType(UserActions.AuthenticationErrorAction, UserActions.GetUserSuccessAction, UserActions.GetUserErrorAction),
            take(1),
            map(action => {
                if (action.type === UserActions.GetUserSuccessAction.type) {
                    return action.user;
                } else {
                    throw action;
                }
            })
        );
    }

    public authenticateEffect(credentials: { email?: string, password?: string, securityStamp?: string, loginAuditToken?: string }): Observable<ILoginCredentials> {
        const formData: FormData = new FormData();
        if (credentials.securityStamp && credentials.loginAuditToken) {
            formData.append('securityStamp', credentials.securityStamp);
            formData.append('loginAuditToken', credentials.loginAuditToken);
        } else {
            formData.append('email', credentials.email);
            formData.append('password', credentials.password);
        }
        return this.httpClient.post(`${environment.apiUri}/api/auths/user-accounts`, formData) as Observable<ILoginCredentials>;
    }

    public refreshUser() {
        return this.userAccount$.pipe(take(1),
            tap(user => this.store.dispatch(UserActions.GetUserAction({ userAccountToken: user.token }))),
            switchMap(user => {
                return this.dispatcher.pipe(
                    ofType(UserActions.GetUserSuccessAction, UserActions.GetUserErrorAction),
                    take(1),
                    map(action => {
                        if (action.type === UserActions.GetUserSuccessAction.type) {
                            return action.user;
                        } else {
                            throw action.error;
                        }
                    })
                );
            })
        );
    }

    public getUserEffect(userAccountToken: string): Observable<IUserAccountDto> {
        return this.httpClient.get(`${environment.apiUri}/api/user-accounts/${userAccountToken}`) as Observable<IUserAccountDto>;
    }

    public createUser(familyAccount: IFamilyUserAccountCreateDto): Observable<IUserAccountCreationResponseDto> {
        this.store.dispatch(UserActions.SignUpAction({ familyAccount: familyAccount }));

        return this.dispatcher.pipe(
            ofType(UserActions.SignUpSuccessAction, UserActions.SignUpErrorAction),
            take(1),
            map(action => {
                if (action.type === UserActions.SignUpSuccessAction.type) {
                    return action.user;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public createUserEffect(familyAccount: IFamilyUserAccountCreateDto): Observable<IUserAccountCreationResponseDto> {
        return this.httpClient.post(`${environment.apiUri}/api/user-accounts`, familyAccount) as Observable<IUserAccountCreationResponseDto>;
    }

    public signOut() {
        this.store.dispatch(UserActions.SignOutAction({ userAccountId: this.routerService.userAccountId }));
        return of(true);
    }

    public signOutEffect(): Observable<boolean> {
        this.localStorageService.removeProfileHistory(this.routerService.userAccountId);
        this.localStorageService.removeLoginCredentials(this.routerService.userAccountId);
        this.localStorageService.removeExpiredAuthCredentials();
        return of(true);
    }

    public changePassword(newPassword: string) {
        this.store.dispatch(UserActions.ChangePasswordAction({
            passwordChange: {
                newPassword,
                newPasswordConfirmation: newPassword
            }
        }));
        return this.dispatcher.pipe(
            ofType(UserActions.ChangePasswordSuccessAction, UserActions.ChangePasswordErrorAction),
            take(1),
            map(action => {
                if (action.type === UserActions.ChangePasswordSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public changePasswordEffect(passwordChange: UserAccountSetPasswordDto) {
        return this.httpClient.post(`${environment.apiUri}/api/user-accounts/set-password`, passwordChange);
    }

    public managerCreateFamily(params: IInstitutionManagerProgramParams & { familyCreateDto: IFamilyCreateDto }) {
        this.store.dispatch(UserActions.FamilyCreateAction(params));
        return this.dispatcher.pipe(
            ofType(UserActions.FamilyCreateSuccessAction, UserActions.FamilyCreateErrorAction),
            take(1),
            map(action => {
                if (action.type === UserActions.FamilyCreateSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public managerCreateFamilyEffect({
        institutionId,
        managerId,
        programId,
        familyCreateDto
    }: IInstitutionManagerProgramParams & { familyCreateDto: IFamilyCreateDto }) {
        return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/families?managerId=${managerId}&programId=${programId}`, familyCreateDto,
            { observe: 'response' }).pipe(
            switchMap((res: HttpResponse<any>) => this.httpClient.get(res.headers.get('location')))
        ) as Observable<IFamilyDto>;
    }

    public createNewFamilyForInstitution(params: { institutionId: string, familyCreate: IFamilyCreateDto }) {
        this.store.dispatch(UserActions.CreateNewFamilyForInstitutionAction(params));
        return this.dispatcher.pipe(
            ofType(UserActions.CreateNewFamilyForInstitutionSuccessAction, UserActions.CreateNewFamilyForInstitutionErrorAction),
            take(1),
            map(action => {
                if (action.type === UserActions.CreateNewFamilyForInstitutionSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public createNewFamilyForInstitutionEffect({
        institutionId,
        familyCreate
    }: { institutionId: string, familyCreate: IFamilyCreateDto }) {
        return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/families`, familyCreate);
    }

    public emailOptIn(params: { institutionId: string, institutionProfileId: string }) {
        this.store.dispatch(UserActions.EmailOptInAction(params));
        return this.dispatcher.pipe(
            ofType(UserActions.EmailOptInSuccessAction, UserActions.EmailOptInErrorAction),
            take(1),
            map(action => {
                if (action.type === UserActions.EmailOptInSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public emailOptInEffect({
        institutionId,
        institutionProfileId
    }: { institutionId: string, institutionProfileId: string }) {
        return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/profiles/${institutionProfileId}/email-opt-in`, {});
    }

    public emailOptOut(params: { institutionId: string, institutionProfileId: string }) {
        this.store.dispatch(UserActions.EmailOptOutAction(params));
        return this.dispatcher.pipe(
            ofType(UserActions.EmailOptOutSuccessAction, UserActions.EmailOptOutErrorAction),
            take(1),
            map(action => {
                if (action.type === UserActions.EmailOptOutSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public emailOptOutEffect({
        institutionId,
        institutionProfileId
    }: { institutionId: string, institutionProfileId: string }) {
        return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/profiles/${institutionProfileId}/email-opt-out`, {});
    }

    // https://v2-dev.4honline.com/#/user/magic-link-login?accountId=0c8eff3a517e241f8b51e0b3c211e784ded3cd487adbee1b62d5ebf4c9223606&code=403D404436534B344B564462324C4639453073642B4D722F71744C6A4C39666B4C32526D65586A5343644B63645A416E6757794878464430496E73673242414E5174794455475A5748326853734D49654E686E4D512F59596946767753476A6267346D6B413943696E3941516A3063685539455668476E4D4A54706E6E61735A5A727643536C5A646266312B2B543767465A754D6266657356494D4A51653043516C6950523154786E6D706C6652455568492F2F45343932324D556B5A4253763553652F414A6168444A7A69306D39615359444C624779446B5A5A7639426678325634764D7537436C444E484A3945586C5A736A5131536D676E794A4D6849346E4E43
    public magicLinkLoginEffect({ securityStamp, code }: { securityStamp: string, code: string }) {
        return this.httpClient.get(`${environment.apiUri}/api/user-accounts/magic-link-login/${securityStamp}?code=${code}`) as Observable<ILoginCredentials>;
    }
}
