import { eProfileType } from '..';
import { eMemberRole } from '../enums/eMemberRole';

export interface IMemberDescriptorDto {
    memberId: string;
    firstName?: string;
    lastName?: string;
    memberRole: eMemberRole;
    profileId: string;
    profileType: eProfileType;
    email?: string;
    institutionId: string;
    institutionProfileId: string;
    profileToken: string;
    registeredInEvent: boolean;
}
