import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, groupBy, map, mergeMap, of } from 'rxjs';

import { CreditCardActions } from '.';
import { CreditCardService } from '../../core/services/credit-card.service';
import { PaymentsActions } from '../payments';
import { stringKeys } from '../rxjs.utils';

@Injectable()
export class CreditCardEffects {

  // loadGatewayConfiguration = createEffect(() => this.actions
  //   .pipe(
  //     ofType(CreditCardActions.CreditCardLoadGatewayConfigurationAction),
  //     groupBy(stringKeys),
  //     mergeMap(groupedBy => {
  //       return groupedBy.pipe(
  //         exhaustMap(payload => {
  //           return this.creditCardService.loadGatewayConfigurationEffect(payload).pipe(
  //             map(config => CreditCardActions.CreditCardLoadGatewayConfigurationSuccessAction({ ...payload, config: config })),
  //             catchError(error => of(CreditCardActions.CreditCardLoadGatewayConfigurationErrorAction({ error })))
  //           );
  //         })
  //       );
  //     })
  //   ));

  saveFamilyCreditCard = createEffect(() => this.actions
  .pipe(
    ofType(CreditCardActions.CreditCardSaveFamilyCardAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.creditCardService.saveFamilyCardEffect(payload).pipe(
            map(paymentMethod => CreditCardActions.CreditCardSaveFamilyCardSuccessAction({
              ...payload,
              paymentMethod
            })),
            catchError(error => of(CreditCardActions.CreditCardSaveFamilyCardErrorAction({ error })))
          );
        })
      );
    })
  ));

  saveFamilyCreditCardSuccess = createEffect(() => this.actions
  .pipe(
    ofType(CreditCardActions.CreditCardSaveFamilyCardSuccessAction, CreditCardActions.CreditCardRemoveFamilyPaymentMethodSuccessAction),
    map(payload => PaymentsActions.PaymentsInvalidateCache({}))
  ));

  removeFamilyPaymentMethod = createEffect(() => this.actions
  .pipe(
    ofType(CreditCardActions.CreditCardRemoveFamilyPaymentMethodAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.creditCardService.removeFamilyPaymentMethodEffect(payload).pipe(
            map(() => CreditCardActions.CreditCardRemoveFamilyPaymentMethodSuccessAction(payload)),
            catchError(error => of(CreditCardActions.CreditCardRemoveFamilyPaymentMethodErrorAction({ error })))
          );
        })
      );
    })
  ));

  loadAuthorizeToken = createEffect(() => this.actions
  .pipe(
    ofType(CreditCardActions.GetAuthorizeNetToken),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.creditCardService.getAuthorizeNetTokenEffect(payload).pipe(
            map((token) => CreditCardActions.GetAuthorizeNetSuccessToken({ ...payload, token })),
            catchError(error => of(CreditCardActions.GetAuthorizeNetErrorToken({ error })))
          );
        })
      );
    })
  ));

  constructor(private actions: Actions, private creditCardService: CreditCardService) {
  }
}
