import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';

import { RegistrationmaxTicketingService } from '../../core/services';

@Injectable()
export class TicketingEffects {


    constructor(
        private actions: Actions,
        private registrationmaxTicketingService: RegistrationmaxTicketingService
    ) { }
}