import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import {
    IEventDto,
    IFamilyEventDto,
    IInstitutionFamilyParams,
    IMemberCreateDto,
    IMemberEventDescriptorDto,
} from '../../models';

export const PublicLoadEventAction = createAction(
    '[public] Load family event',
    props<{ institutionId: string, eventId: string }>()
);
export const PublicLoadEventSuccessAction = createAction(
    '[public] Load family event success',
    props<{ institutionId: string, eventId: string, event: IEventDto }>()
);
export const PublicLoadEventErrorAction = createAction(
    '[public] Load family event error',
    props<{ error: HttpErrorResponse }>()
);

export const PublicLoadEventEligibilityAction = createAction(
    '[public] Load family event eligibility',
    props<IInstitutionFamilyParams & { eventId: string }>()
);
export const PublicLoadEventEligibilitySuccessAction = createAction(
    '[public] Load family event eligibility success',
    props<IInstitutionFamilyParams & { eventId: string, events: IFamilyEventDto }>()
);
export const PublicLoadEventEligibilityErrorAction = createAction(
    '[public] Load family event eligibility error',
    props<{ error: HttpErrorResponse }>()
);

export const PublicRegistrationLookupAction = createAction(
    '[public] Registration lookup',
    props<IInstitutionFamilyParams & { eventId: string }>()
);
export const PublicRegistrationLookupSuccessAction = createAction(
    '[public] Registration lookup success',
    props<IInstitutionFamilyParams & { eventId: string, events: IFamilyEventDto[] }>()
);
export const PublicRegistrationLookupErrorAction = createAction(
    '[public] Registration lookup error',
    props<{ error: HttpErrorResponse }>()
);

export const MemberCreateAction = createAction(
    '[public] Create member',
    props<{ institutionId: string, familyId: string, eventId: string, member: IMemberCreateDto }>()
);
export const MemberCreateSuccessAction = createAction(
    '[public] Create member success',
    props<{ institutionId: string, familyId: string, eventId: string, member: IMemberEventDescriptorDto }>()
);
export const MemberCreateErrorAction = createAction(
    '[public] Create member error',
    props<{ error: HttpErrorResponse }>()
);

