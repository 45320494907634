import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import {
    eMimeType,
    IFamilyAssociateWithUserAccountEmailDto,
    IFamilyDashboardSummaryDto,
    IFamilyDescriptorDto,
    IFamilyDto,
    IFamilyEmailDto,
    IFamilyEventDto,
    IFamilyUpdateDto,
    IInstitutionFamilyParams,
    IInstitutionFamilyProgramParams,
    IManagerNoteCreateDto,
    IManagerNoteDto,
    IManagerNoteUpdateDto,
    IUserAccountLogin,
    IUserAccountPasswordDto
} from 'app/core/models';
import { filter, map, Observable, of, shareReplay, take, tap } from 'rxjs';

import { FamilyActions } from '../containers/family';
import {
    FamilyFeatureStore,
    getFamilies,
    getFamiliesDashboard,
    getFamiliesDescriptor,
    getFamilyEmails,
    getFamilyEventCatalog,
    getFamilyLogins,
    getFamilyProfileNotes
} from '../containers/family/family-feature.reducers';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class FamilyService {

    constructor(
        private store: Store<FamilyFeatureStore>,
        private httpClient: HttpClient,
        private dispatcher: ActionsSubject,
        private sanitizer: DomSanitizer
    ) {

    }

    public getFamilyDashboard(params: IInstitutionFamilyParams) {
        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getFamiliesDashboard(params)),
            tap(dashboard => {
                if (dashboard === undefined) {
                    this.store.dispatch(FamilyActions.FamilyLoadDashboardAction(params));
                }
            }),
            filter(dashboard => dashboard != null),
            shareReplay({ bufferSize: 1, refCount: true })
        );
    }

    public loadFamilyDashboardEffect({ institutionId, familyId }: IInstitutionFamilyParams) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/families/${familyId}/dashboard`) as Observable<IFamilyDashboardSummaryDto>;
    }

    public getFamily(params: IInstitutionFamilyParams): Observable<IFamilyDto> {
        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getFamilies(params)),
            tap(family => {
                if (family === undefined) {
                    this.store.dispatch(FamilyActions.FamilyLoadAction(params));
                }
            }),
            filter(family => family != null)
        );
    }

    public loadFamilyEffect({ institutionId, familyId }: IInstitutionFamilyParams): Observable<IFamilyDto> {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/families/${familyId}`) as Observable<IFamilyDto>;
    }

    public getFamilyDescriptor(params: IInstitutionFamilyParams) {
        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getFamiliesDescriptor(params)),
            tap(descriptor => {
                if (descriptor === undefined) {
                    this.store.dispatch(FamilyActions.FamilyLoadDescriptorAction(params));
                }
            }),
            filter(dashboard => dashboard != null),
            shareReplay({ bufferSize: 1, refCount: true })
        );
    }

    public loadFamilyDescriptorEffect({ institutionId, familyId }: IInstitutionFamilyParams) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/families/${familyId}/descriptor`) as Observable<IFamilyDescriptorDto>;
    }

    public updateFamily(params: IInstitutionFamilyParams & { familyUpdate: IFamilyUpdateDto }) {
        this.store.dispatch(FamilyActions.FamilyUpdateAction(params));
        return this.dispatcher.pipe(
            ofType(FamilyActions.FamilyUpdateSuccessAction, FamilyActions.FamilyUpdateErrorAction),
            take(1),
            map(action => {
                if (action.type === FamilyActions.FamilyUpdateSuccessAction.type) {
                    return action.familyUpdate;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public updateFamilyEffect({
        institutionId,
        familyId,
        familyUpdate
    }: IInstitutionFamilyParams & { familyUpdate: IFamilyUpdateDto }) {
        return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/families/${familyId}`, familyUpdate);
    }

    public refreshFamily() {
        this.store.dispatch(FamilyActions.FamilyInvalidateCacheAction());
    }

    public getEventsCatalog(params: IInstitutionFamilyParams) {
        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getFamilyEventCatalog(params)),
            tap(events => {
                if (events === undefined) {
                    this.store.dispatch(FamilyActions.FamilyLoadEventCatalogAction(params));
                }
            }),
            filter(events => events != null)
        );
    }

    public getEventsCatalogEffect({ institutionId, familyId }: IInstitutionFamilyParams) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/families/${familyId}/available-events`) as Observable<IFamilyEventDto[]>;
    }

    public updateFamilyCounty(params: IInstitutionFamilyParams & { countyId: string, countyAreaId: string }) {
        this.store.dispatch(FamilyActions.FamilyUpdateCountyAction(params));
        return this.dispatcher.pipe(
            ofType(FamilyActions.FamilyUpdateCountySuccessAction, FamilyActions.FamilyUpdateCountyErrorAction),
            take(1),
            map(action => {
                if (action.type === FamilyActions.FamilyUpdateCountySuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public updateFamilyCountyEffect({
        institutionId,
        familyId,
        countyId,
        countyAreaId
    }: IInstitutionFamilyParams & { countyId: string, countyAreaId: string }) {
        if (countyAreaId == null) {
            return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/families/${familyId}/county-only?countyId=${countyId}`, {});
        } else {
            return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/families/${familyId}/county?countyId=${countyId}&countyAreaId=${countyAreaId}`, {});
        }
    }

    public managerResetPassword({
        institutionId,
        familyId
    }: IInstitutionFamilyParams): Observable<IUserAccountPasswordDto> {
        return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/families/${familyId}/reset-password`, null) as Observable<IUserAccountPasswordDto>;
    }

    public associateWithUserAccount(params: IInstitutionFamilyParams & { associateWithUserAccountEmail: IFamilyAssociateWithUserAccountEmailDto }) {
        this.store.dispatch(FamilyActions.FamilyAssociateWithUserAccountAction(params));
        return this.dispatcher.pipe(
            ofType(FamilyActions.FamilyAssociateWithUserAccountSuccessAction, FamilyActions.FamilyAssociateWithUserAccountErrorAction),
            take(1),
            map(action => {
                if (action.type === FamilyActions.FamilyAssociateWithUserAccountSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public associateWithUserAccountEffect({
        institutionId,
        familyId,
        associateWithUserAccountEmail
    }: IInstitutionFamilyParams & { associateWithUserAccountEmail: IFamilyAssociateWithUserAccountEmailDto }) {
        return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/families/${familyId}/associate-with-user-account`, associateWithUserAccountEmail);
    }

    public getFamilyLogins(params: IInstitutionFamilyParams): Observable<IUserAccountLogin[]> {
        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getFamilyLogins(params)),
            tap(logins => {
                if (logins === undefined) {
                    this.store.dispatch(FamilyActions.LoadFamilyLoginsAction(params));
                }
            }),
            filter(logins => logins != null)
        );
    }

    public getFamilyLoginsEffect({ institutionId, familyId }: IInstitutionFamilyParams) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/families/${familyId}/logins`) as Observable<IUserAccountLogin[]>;
    }

    public getFamilyEmails(params: IInstitutionFamilyParams): Observable<IFamilyEmailDto[]> {
        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getFamilyEmails(params)),
            tap(emails => {
                if (emails === undefined) {
                    this.store.dispatch(FamilyActions.LoadFamilyEmailsAction(params));
                }
            }),
            filter(emails => emails != null)
        );
    }

    public getFamilyEmailsEffect({ institutionId, familyId }: IInstitutionFamilyParams) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/families/${familyId}/emails`) as Observable<IFamilyEmailDto[]>;
    }

    public getFamilyNotes(params: IInstitutionFamilyProgramParams) {
        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getFamilyProfileNotes(params)),
            tap(profileNotes => {
                if (profileNotes === undefined) {
                    this.store.dispatch(FamilyActions.GetFamilyNotesAction(params));
                }
            }),
            filter(profileNotes => profileNotes != null)
        );
    }

    public getFamilyNotesEffect({
        institutionId,
        familyId,
        programId
    }: IInstitutionFamilyProgramParams): Observable<IManagerNoteDto[]> {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/families/${familyId}/programs/${programId}/notes`) as Observable<IManagerNoteDto[]>;
    }

    public createFamilyNote(params: IInstitutionFamilyProgramParams & { noteCreateDto: IManagerNoteCreateDto }) {
        this.store.dispatch(FamilyActions.CreateFamilyNotesAction(params));
        return this.dispatcher.pipe(
            ofType(FamilyActions.CreateFamilyNotesSuccessAction, FamilyActions.CreateFamilyNotesErrorAction),
            take(1),
            map(action => {
                if (action.type === FamilyActions.CreateFamilyNotesSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public createFamilyNoteEffect({
        institutionId,
        familyId,
        programId,
        noteCreateDto
    }: IInstitutionFamilyProgramParams & { noteCreateDto: IManagerNoteCreateDto }) {
        return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/families/${familyId}/programs/${programId}/notes`, noteCreateDto);
    }

    public updateFamilyNote(params: IInstitutionFamilyProgramParams & { profileNoteId: string, noteUpdateDto: IManagerNoteUpdateDto }) {
        this.store.dispatch(FamilyActions.UpdateFamilyNotesAction(params));
        return this.dispatcher.pipe(
            ofType(FamilyActions.UpdateFamilyNotesSuccessAction, FamilyActions.UpdateFamilyNotesErrorAction),
            take(1),
            map(action => {
                if (action.type === FamilyActions.UpdateFamilyNotesSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public updateFamilyNoteEffect({
        institutionId,
        familyId,
        programId,
        profileNoteId,
        noteUpdateDto
    }: IInstitutionFamilyProgramParams & { profileNoteId: string, noteUpdateDto: IManagerNoteUpdateDto }) {
        return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/families/${familyId}/programs/${programId}/notes/${profileNoteId}`, noteUpdateDto);
    }

    public deleteFamilyNote(params: IInstitutionFamilyProgramParams & { profileNoteId: string }) {
        this.store.dispatch(FamilyActions.DeleteFamilyNotesAction(params));
        return this.dispatcher.pipe(
            ofType(FamilyActions.DeleteFamilyNotesSuccessAction, FamilyActions.DeleteFamilyNotesErrorAction),
            take(1),
            map(action => {
                if (action.type === FamilyActions.DeleteFamilyNotesSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public deleteFamilyNoteEffect({
        institutionId,
        familyId,
        programId,
        profileNoteId
    }: IInstitutionFamilyProgramParams & { profileNoteId: string }) {
        return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/families/${familyId}/programs/${programId}/notes/${profileNoteId}`);
    }

    /////

    public downloadFamilyNoteFile(params: IInstitutionFamilyProgramParams & { profileNoteId: string }) {
        this.store.dispatch(FamilyActions.DownloadFamilyNotesFileAction(params));
        return this.dispatcher.pipe(
            ofType(FamilyActions.DownloadFamilyNotesFileSuccessAction, FamilyActions.DownloadFamilyNotesFileErrorAction),
            take(1),
            map(action => {
                if (action.type === FamilyActions.DownloadFamilyNotesFileSuccessAction.type) {
                    return action.file;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public downloadFamilyNoteFileEffect({
        institutionId,
        familyId,
        programId,
        profileNoteId
    }: IInstitutionFamilyProgramParams & { profileNoteId: string }) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/families/${familyId}/programs/${programId}/notes/${profileNoteId}/file`, { responseType: 'blob' });
    }

    public deleteFamilyNoteFile(params: IInstitutionFamilyProgramParams & { profileNoteId: string }) {
        this.store.dispatch(FamilyActions.DeleteFamilyNotesAction(params));
        return this.dispatcher.pipe(
            ofType(FamilyActions.DeleteFamilyNotesSuccessAction, FamilyActions.DeleteFamilyNotesErrorAction),
            take(1),
            map(action => {
                if (action.type === FamilyActions.DeleteFamilyNotesSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public deleteFamilyNoteFileEffect({
        institutionId,
        familyId,
        programId,
        profileNoteId
    }: IInstitutionFamilyProgramParams & { profileNoteId: string }) {
        return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/families/${familyId}/programs/${programId}/notes/${profileNoteId}/file`);
    }

    public addFamilyNoteFile(params: IInstitutionFamilyProgramParams & { profileNoteId: string, formData: FormData }) {
        this.store.dispatch(FamilyActions.AddFamilyNotesFileAction(params));
        return this.dispatcher.pipe(
            ofType(FamilyActions.AddFamilyNotesFileSuccessAction, FamilyActions.AddFamilyNotesFileErrorAction),
            take(1),
            map(action => {
                if (action.type === FamilyActions.AddFamilyNotesFileSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public addFamilyNoteFileEffect({
        institutionId,
        familyId,
        programId,
        profileNoteId,
        formData
    }: IInstitutionFamilyProgramParams & { profileNoteId: string, formData: FormData }) {
        return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/families/${familyId}/programs/${programId}/notes/${profileNoteId}/file`, formData);
    }

    public getFamilyNoteThumbnailEffect({
        institutionId,
        familyId,
        programId,
        profileNoteId
    }: IInstitutionFamilyProgramParams & { profileNoteId: string }) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/families/${familyId}/programs/${programId}/notes/${profileNoteId}/file/thumbnail`, { responseType: 'blob' }).pipe(
            map(blob => blob.type === eMimeType.PDF ? '/assets/images/pdf.png' : this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob)))
        );
    }

}
