import { eConsentStatus } from '../enums';
import { IConsentVersionDto } from './IConsentVersionDto';

export interface IMemberProgramConsentDto {
  memberProgramConsentId: string;
  consentedByProfileId: string;
  consentVersion: IConsentVersionDto;
  dateOfConsent?: string;
  recordedMemberName?: string;
  recordedParentGuardianName?: string;
  recordedManagerName?: string;
  consentStatus: eConsentStatus;
}
