import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';
import {
  getMemberSearchSearching,
  getMembersSearchResult,
  ManagerFeatureStore
} from 'app/core/containers/admin/manager/manager-feature.reducer';
import { MemberSearchActions } from 'app/core/containers/admin/manager/store/searches/member';
import { IInstitutionManagerProgramHierarchyParams, IMemberSearchParametersDto } from 'app/core/models';
import { IMemberSearchResultsDto } from 'app/core/models/serverDTOs/IMemberSearchResultsDto';
import { downloadFile, downloadFileWithBody } from 'app/shared/utils';
import { Observable, switchMap } from 'rxjs';

import { CommonToastrService } from '../../common-toastr.service';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MemberSearchService {
  public membersSearchResult$: Observable<IMemberSearchResultsDto>;
  public memberSearchSearching$: Observable<boolean>;

  constructor(private store: Store<ManagerFeatureStore>, private httpClient: HttpClient, private dispatcher: ActionsSubject, private toastrService: CommonToastrService) {
    this.memberSearchSearching$ = this.store.pipe(select(getMemberSearchSearching));
    this.membersSearchResult$ = this.store.pipe(select(getMembersSearchResult));
  }

  public getMemberSearchResults(params: IInstitutionManagerProgramHierarchyParams & { pageSize: string, pageIndex: string }) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return;
    }
    this.store.dispatch(MemberSearchActions.GetMemberSearchResultsAction(params));
  }

  public getMemberSearchResultsEffect({
    institutionId,
    managerId,
    programId,
    hierarchyNodeId,
    pageSize,
    pageIndex
  }: IInstitutionManagerProgramHierarchyParams & { pageSize: string, pageIndex: string }): Observable<IMemberSearchResultsDto> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/${hierarchyNodeId}/search?pagesize=${pageSize}&pageindex=${pageIndex}`) as Observable<IMemberSearchResultsDto>;
  }

  public searchMembers(params: IInstitutionManagerProgramHierarchyParams & { searchParameters: IMemberSearchParametersDto }) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return;
    }
    this.store.dispatch(MemberSearchActions.SearchMembersAction(params));
  }

  public searchMembersEffect({
    institutionId,
    managerId,
    programId,
    hierarchyNodeId,
    searchParameters
  }: IInstitutionManagerProgramHierarchyParams & { searchParameters: IMemberSearchParametersDto }): Observable<IMemberSearchResultsDto> {
    return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/${hierarchyNodeId}/search`, searchParameters, { observe: 'response' }).pipe(
      switchMap((res: HttpResponse<any>) => this.httpClient.get(res.headers.get('location')))
    ) as Observable<IMemberSearchResultsDto>;
  }

  public clearMemberSearch(params: IInstitutionManagerProgramHierarchyParams) {
    this.store.dispatch(MemberSearchActions.ClearMemberSearchAction(params));
  }

  public clearMemberSearchEffect({
    institutionId,
    managerId,
    programId,
    hierarchyNodeId
  }: IInstitutionManagerProgramHierarchyParams) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/${hierarchyNodeId}/search`);
  }

  public downloadExcel({
    institutionId,
    managerId,
    programId,
    hierarchyNodeId
  }: IInstitutionManagerProgramHierarchyParams) {
    downloadFileWithBody(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/${hierarchyNodeId}/search/excel`, {});

  }

  public downloadHealthExcel({
    institutionId,
    managerId,
    programId,
    hierarchyNodeId
  }: IInstitutionManagerProgramHierarchyParams) {
    downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/${hierarchyNodeId}/search/health-forms-excel`, this.toastrService);
  }

  public downloadHealthPdf({
    institutionId,
    managerId,
    programId,
    hierarchyNodeId
  }: IInstitutionManagerProgramHierarchyParams) {
    downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/${hierarchyNodeId}/search/health-forms-pdf`, this.toastrService);
  }

  public downloadHealthZip({
    institutionId,
    managerId,
    programId,
    hierarchyNodeId
  }: IInstitutionManagerProgramHierarchyParams) {
    downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/${hierarchyNodeId}/search/health-forms-zip`, this.toastrService);
  }

  public participationHealthFormAuditPdf({
    institutionId,
    managerId,
    programId,
    hierarchyNodeId,
    asOfDate
  }: IInstitutionManagerProgramHierarchyParams & { asOfDate: string }) {
    downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/${hierarchyNodeId}/search/health-forms-pdf/audit?asOfDate=${asOfDate}`, this.toastrService);
  }

  public participationHealthFormAuditExcel({
    institutionId,
    managerId,
    programId,
    hierarchyNodeId,
    asOfDate
  }: IInstitutionManagerProgramHierarchyParams & { asOfDate: string }) {
    downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/${hierarchyNodeId}/search/health-forms-excel/audit?asOfDate=${asOfDate}`, this.toastrService);
  }

  public participationHealthFormAuditZip({
    institutionId,
    managerId,
    programId,
    hierarchyNodeId,
    asOfDate
  }: IInstitutionManagerProgramHierarchyParams & { asOfDate: string }) {
    downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/${hierarchyNodeId}/search/health-forms-zip/audit?asOfDate=${asOfDate}`, this.toastrService);
  }
}
