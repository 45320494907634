import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { MemberService } from 'app/core/services';
import { ManagerEnrollmentService } from 'app/core/services/manager-enrollment.service';
import { stringKeys } from 'app/shared/rxjs.utils';
import { catchError, exhaustMap, groupBy, map, mergeMap, Observable, of } from 'rxjs';

import { MemberActions } from '.';
import { FamilyActions } from '../family';
import { EnrollmentActions } from './enrollment';


@Injectable()
export class MemberEffects {

  memberLoad$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(MemberActions.MemberLoadAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.memberService.loadMemberEffect(payload).pipe(
            map(member => MemberActions.MemberLoadSuccessAction({ ...payload, member: member })),
            catchError(error => of(MemberActions.MemberLoadErrorAction({ error })))
          );
        })
      );
    })
  ));

  memberUpdate$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(MemberActions.MemberUpdateAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.memberService.updateMemberEffect(payload).pipe(
            map(() => MemberActions.MemberUpdateSuccessAction(payload)),
            catchError(error => of(MemberActions.MemberUpdateErrorAction({ error })))
          );
        })
      );
    })
  ));
  memberUpdateSuccess$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(MemberActions.MemberUpdateSuccessAction),
    map((payload) => MemberActions.MemberLoadAction({ institutionId: payload.institutionId, memberId: payload.memberId }))
  ));
  memberLoadDashboard$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(MemberActions.MemberLoadDashboardAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.memberService.loadMemberDashboardEffect(payload).pipe(
            map(dashboard => MemberActions.MemberLoadDashboardSuccessAction({ ...payload, memberDashboard: dashboard })),
            catchError(error => of(MemberActions.MemberLoadDashboardErrorAction({ error })))
          );
        })
      );
    })
  ));

  public memberSaveNew$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(MemberActions.MemberCreateAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.memberService.createNewMemberEffect(payload).pipe(
            mergeMap(member => {
              return [
                MemberActions.MemberCreateSuccessAction({ ...payload, memberId: member.memberId, member: member }),
                FamilyActions.FamilyInvalidateCacheAction(),
              ];
            }),
            catchError(error => of(MemberActions.MemberCreateErrorAction({ error })))
          );
        })
      );
    })
  ));



  public memberArchive$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(MemberActions.MemberArchiveAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.memberService.managerArchiveMemberEffect(payload).pipe(
            map(() => MemberActions.MemberArchiveSuccessAction(payload)),
            catchError(error => of(MemberActions.MemberArchiveErrorAction({ error })))
          );
        })
      );
    })
  ));

  public memberUnArchive$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(MemberActions.MemberUnArchiveAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.memberService.managerUnArchiveMemberEffect(payload).pipe(
            map(() => MemberActions.MemberUnArchiveSuccessAction(payload)),
            catchError(error => of(MemberActions.MemberUnArchiveErrorAction({ error })))
          );
        })
      );
    })
  ));


  public memberMarkDeceased$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(MemberActions.MemberMarkDeceasedAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.memberService.managerMarkMemberDeceasedEffect(payload).pipe(
            map(() => MemberActions.MemberMarkDeceasedSuccessAction(payload)),
            catchError(error => of(MemberActions.MemberMarkDeceasedErrorAction({ error })))
          );
        })
      );
    })
  ));

  public memberRemove$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(MemberActions.MemberRemoveAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.memberService.managerDeleteMemberEffect(payload).pipe(
            map(() => MemberActions.MemberRemoveSuccessAction(payload)),
            catchError(error => of(MemberActions.MemberRemoveErrorAction({ error })))
          );
        })
      );
    })
  ));

  public memberRestore$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(MemberActions.MemberRestoreAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.memberService.managerRestoreMemberEffect(payload).pipe(
            map(() => MemberActions.MemberRestoreSuccessAction(payload)),
            catchError(error => of(MemberActions.MemberRestoreErrorAction({ error })))
          );
        })
      );
    })
  ));

  public memberDismiss$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(MemberActions.MemberDismissAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.memberService.managerDismissMemberEffect(payload).pipe(
            map(() => MemberActions.MemberDismissSuccessAction(payload)),
            catchError(error => of(MemberActions.MemberDismissErrorAction({ error })))
          );
        })
      );
    })
  ));
  public memberReinstate$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(MemberActions.MemberReinstateAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.memberService.managerReinstateMemberEffect(payload).pipe(
            map(() => MemberActions.MemberReinstateSuccessAction(payload)),
            catchError(error => of(MemberActions.MemberReinstateErrorAction({ error })))
          );
        })
      );
    })
  ));

  public memberDelete$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(MemberActions.MemberDeleteAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.memberService.deleteMemberEffect(payload).pipe(
            map(() => MemberActions.MemberDeleteSuccessAction(payload)),
            catchError(error => of(MemberActions.MemberDeleteErrorAction({ error })))
          );
        })
      );
    })
  ));

  public invalidateMemberAndFamilyCaches$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(
      MemberActions.MemberDismissSuccessAction,
      MemberActions.MemberReinstateSuccessAction,
      MemberActions.MemberRemoveSuccessAction,
      MemberActions.MemberRestoreSuccessAction,
      MemberActions.MemberDeleteSuccessAction,
      MemberActions.MemberArchiveSuccessAction,
      MemberActions.MemberUnArchiveSuccessAction,
      MemberActions.MoveToFamilySuccessAction,
      MemberActions.MergeMemberSuccessAction,
      MemberActions.TogglePrimaryFamilySuccessAction,
      MemberActions.RemoveMemberFromNonPrimaryFamilySuccessAction,
      MemberActions.MemberMarkDeceasedSuccessAction,
      MemberActions.ParticipatingEnrollmentSuccessAction,
      EnrollmentActions.EnrollmentSaveDemographicsSuccessAction
    ),
    mergeMap(payload => {
      return [
        FamilyActions.FamilyInvalidateCacheAction(),
        MemberActions.MemberInvalidateMemberCache(),
        EnrollmentActions.EnrollmentInvalidateCacheAction()
      ];
    })
  ));

  flagMember$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(MemberActions.MemberFlagAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.memberService.flagMemberEffect(payload).pipe(
            map(() => MemberActions.MemberFlagSuccessAction(payload)),
            catchError(error => of(MemberActions.MemberFlagErrorAction({ ...payload, error })))
          );
        })
      );
    })
  ));

  deleteFlagMember$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(MemberActions.MemberFlagDeleteAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.memberService.deleteMemberFlagEffect(payload).pipe(
            map(() => MemberActions.MemberFlagDeleteSuccessAction(payload)),
            catchError(error => of(MemberActions.MemberFlagDeleteErrorAction({ ...payload, error })))
          );
        })
      );
    })
  ));


  flagAllResults$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(MemberActions.MemberFlagAllResultsAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.memberService.flagAllResultsEffect(payload).pipe(
            map(() => MemberActions.MemberFlagAllResultsSuccessAction(payload)),
            catchError(error => of(MemberActions.MemberFlagAllResultsErrorAction({ ...payload, error })))
          );
        })
      );
    })
  ));

  addFlaggedToActivity$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(MemberActions.MemberAddFlaggedToActivityAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.memberService.addFlaggedToActivityEffect(payload).pipe(
            map(() => MemberActions.MemberAddFlaggedToActivitySuccessAction(payload)),
            catchError(error => of(MemberActions.MemberAddFlaggedToActivityErrorAction({ ...payload, error })))
          );
        })
      );
    })
  ));
  addFlaggedToAward$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(MemberActions.MemberAddFlaggedToAwardAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.memberService.addFlaggedToAwardEffect(payload).pipe(
            map(() => MemberActions.MemberAddFlaggedToAwardSuccessAction(payload)),
            catchError(error => of(MemberActions.MemberAddFlaggedToAwardErrorAction({ ...payload, error })))
          );
        })
      );
    })
  ));
  addFlaggedToGroup$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(MemberActions.MemberAddFlaggedToGroupAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.memberService.addFlaggedToGroupEffect(payload).pipe(
            map(() => MemberActions.MemberAddFlaggedToGroupSuccessAction(payload)),
            catchError(error => of(MemberActions.MemberAddFlaggedToGroupErrorAction({ ...payload, error })))
          );
        })
      );
    })
  ));
  addFlaggedToTraining$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(MemberActions.MemberAddFlaggedToTrainingAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.memberService.addFlaggedToTrainingEffect(payload).pipe(
            map(() => MemberActions.MemberAddFlaggedToTrainingSuccessAction(payload)),
            catchError(error => of(MemberActions.MemberAddFlaggedToTrainingErrorAction({ ...payload, error })))
          );
        })
      );
    })
  ));

  clearAllFlaggedResults$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(MemberActions.MemberClearAllFlagsAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.memberService.clearAllFlagsEffect(payload).pipe(
            map(() => MemberActions.MemberClearAllFlagsSuccessAction(payload)),
            catchError(error => of(MemberActions.MemberClearAllFlagsErrorAction({ ...payload, error })))
          );
        })
      );
    })
  ));

  mergeMember$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(MemberActions.MergeMemberAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.memberService.mergeMemberEffect(payload).pipe(
            map(member => MemberActions.MergeMemberSuccessAction(payload)),
            catchError(error => of(MemberActions.MergeMemberErrorAction({ error })))
          );
        })
      );
    })
  ));

  togglePrimaryFamily$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(MemberActions.TogglePrimaryFamilyAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.memberService.togglePrimaryFamilyEffect(payload).pipe(
            map(member => MemberActions.TogglePrimaryFamilySuccessAction(payload)),
            catchError(error => of(MemberActions.TogglePrimaryFamilyErrorAction({ error })))
          );
        })
      );
    })
  ));

  removeMemberFromNonPrimaryFamily$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(MemberActions.RemoveMemberFromNonPrimaryFamilyAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.memberService.removeMemberFromNonPrimaryFamilyEffect(payload).pipe(
            map(member => MemberActions.RemoveMemberFromNonPrimaryFamilySuccessAction(payload)),
            catchError(error => of(MemberActions.RemoveMemberFromNonPrimaryFamilyErrorAction({ error })))
          );
        })
      );
    })
  ));

  public getFamiliesByCounty$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(MemberActions.GetFamiliesForCountyAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.memberService.getFamiliesForCountyEffect(payload).pipe(
            map(families => MemberActions.GetFamiliesForCountySuccessAction({ ...payload, families })),
            catchError(error => of(MemberActions.GetFamiliesForCountyErrorAction({ error })))
          );
        })
      );
    })
  ));

  public updateYears$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(MemberActions.UpdateYearsInProgramAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.memberService.updateYearsInProgramEffect(payload).pipe(
            map(() => MemberActions.UpdateYearsInProgramSuccessAction(payload)),
            catchError(error => of(MemberActions.UpdateYearsInProgramErrorAction({ error })))
          );
        })
      );
    })
  ));
  public moveToFamily$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(MemberActions.MoveToFamilyAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.memberService.moveToFamilyEffect(payload).pipe(
            map(() => MemberActions.MoveToFamilySuccessAction(payload)),
            catchError(error => of(MemberActions.MoveToFamilyErrorAction({ error })))
          );
        })
      );
    })
  ));

  public participating$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(MemberActions.ParticipatingEnrollmentAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap((payload) => {
          return this.enrollmentService.participatingEnrollmentEffect(payload).pipe(
            map(() => MemberActions.ParticipatingEnrollmentSuccessAction(payload)),
            catchError(error => of(MemberActions.ParticipatingEnrollmentErrorAction({ error })))
          );
        })
      );
    })
  ));

  public unBlockEnrollment$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(MemberActions.EnrollmentUnBlockAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap((payload) => {
          return this.enrollmentService.unBlockEffect(payload).pipe(
            map(() => MemberActions.EnrollmentUnBlockSuccessAction(payload)),
            catchError(error => of(MemberActions.EnrollmentUnBlockErrorAction({ error })))
          );
        })
      );
    })
  ));

  public getProfileNote$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(MemberActions.GetProfileNotesAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.memberService.getProfileNotesEffect(payload).pipe(
            map((profileNotes) => MemberActions.GetProfileNotesSuccessAction({ profileNotes, ...payload })),
            catchError(error => of(MemberActions.GetProfileNotesErrorAction({ ...payload, error })))
          );
        })
      );
    })
  ));

  public createProfileNote$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(MemberActions.CreateProfileNotesAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.memberService.createProfileNoteEffect(payload).pipe(
            map(() => MemberActions.CreateProfileNotesSuccessAction(payload)),
            catchError(error => of(MemberActions.CreateProfileNotesErrorAction({ ...payload, error })))
          );
        })
      );
    })
  ));

  public updateProfileNote$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(MemberActions.UpdateProfileNotesAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.memberService.updateProfileNoteEffect(payload).pipe(
            map(() => MemberActions.UpdateProfileNotesSuccessAction(payload)),
            catchError(error => of(MemberActions.UpdateProfileNotesErrorAction({ ...payload, error })))
          );
        })
      );
    })
  ));



  public DeleteProfileNote$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(MemberActions.DeleteProfileNotesAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.memberService.deleteProfileNoteEffect(payload).pipe(
            map(() => MemberActions.DeleteProfileNotesSuccessAction(payload)),
            catchError(error => of(MemberActions.DeleteProfileNotesErrorAction({ ...payload, error })))
          );
        })
      );
    })
  ));


  public DownloadProfileNoteFile$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(MemberActions.DownloadProfileNotesFileAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.memberService.downloadProfileNoteFileEffect(payload).pipe(
            map((file) => MemberActions.DownloadProfileNotesFileSuccessAction({ file, ...payload })),
            catchError(error => of(MemberActions.DownloadProfileNotesFileErrorAction({ ...payload, error })))
          );
        })
      );
    })
  ));

  public DeleteProfileNoteFile$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(MemberActions.DeleteProfileNotesFileAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.memberService.deleteProfileNoteFileEffect(payload).pipe(
            map(() => MemberActions.DeleteProfileNotesFileSuccessAction(payload)),
            catchError(error => of(MemberActions.DeleteProfileNotesFileErrorAction({ ...payload, error })))
          );
        })
      );
    })
  ));

  public AddProfileNoteFile$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(MemberActions.AddProfileNotesFileAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.memberService.addProfileNoteFileEffect(payload).pipe(
            map(() => MemberActions.AddProfileNotesFileSuccessAction(payload)),
            catchError(error => of(MemberActions.AddProfileNotesFileErrorAction({ ...payload, error })))
          );
        })
      );
    })
  ));

  public loadv1files$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(MemberActions.MemberLoadV1FilesAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.memberService.loadMemberV1FilesEffect(payload).pipe(
            map((files) => MemberActions.MemberLoadV1FilesSuccessAction({ files: files ? files : [], ...payload })),
            catchError(error => of(MemberActions.MemberLoadV1FilesErrorAction({ ...payload, error })))
          );
        })
      );
    })
  ));


  updateMemberProgramVolunteerHours$ = createEffect(() => this.actions.pipe(
    ofType(MemberActions.UpdateMemberProgramVolunteerHoursAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.memberService.updateMemberProgramVolunteerHoursEffect(payload).pipe(
            map(() => MemberActions.UpdateMemberProgramVolunteerHoursSuccessAction(payload)),
            catchError(error => of(MemberActions.UpdateMemberProgramVolunteerHoursErrorAction({ error })))
          );
        })
      );
    })
  ));

  constructor(private actions: Actions, private memberService: MemberService, private enrollmentService: ManagerEnrollmentService) {

  }
}
