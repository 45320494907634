<ng-container *ngIf="memberProgramIds$ | async as memberProgramIds">

    <div *ng4hVar="programId$ | async as currentProgramId">
        <mat-button-toggle-group [ngClass]="{'disabled': memberProgramIds.length == 1}" class="ng4h-margin-bottom"
            [ngModel]="currentProgramId" (ngModelChange)="selectProgram($event)">
            <mat-button-toggle [value]="memberProgramId" *ngFor="let memberProgramId of memberProgramIds">
                <!-- <i class="material-icons ng4h-margin-right-small">grid_on</i> -->
                <!-- <img [src]="volunteerProgram | programLogoSrc"> -->
                {{memberProgramId | program$ | async}}


                <!-- <img [src]="volunteerProgram | programLogoSrc">  -->
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>

</ng-container>