import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'orderBy',
    pure: true
})

export class OrderByPipe implements PipeTransform {
    transform(array: any[], property: string): any[] {
        return array.sort((a, b) => {
            const x = a[property].toLowerCase();
            const y = b[property].toLowerCase();
            if (x < y) { return -1; }
            if (x > y) { return 1; }
            return 0;
        });
    }
}
