import { eProgramEmailType } from '../enums/eProgramEmailType';
import { IEmailMergeFieldDto } from './IEmailMergeFieldDto';

export interface IProgramEmailTemplateDto {
  emailTemplateId: number;
  type: eProgramEmailType;
  category?: string;
  displayCaption?: string;
  template?: string;
  mergeFields?: IEmailMergeFieldDto[];
}
