import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { getFund, getFundCredits, getFunds, ManagerFeatureStore } from 'app/core/containers/admin/manager/manager-feature.reducer';
import { ManagerFundingActions } from 'app/core/containers/admin/manager/store/funding';
import {
    IFundsTransferSummaryDto,
    IInstitutionManagerProgramHierarchyParams,
    IInstitutionManagerProgramParams
} from 'app/core/models';
import { downloadFile } from 'app/shared/utils';
import { filter, Observable, of, tap } from 'rxjs';
import { GetMangerFundCreditsAction } from '../../../containers/admin/manager/store/funding/manager-funding.actions';
import { IFundsTransferCreditSummaryDto } from '../../../models/serverDTOs/IFundsTransferCreditSummaryDto';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ManagerFundingService {

    constructor(
        private httpClient: HttpClient,
        private store: Store<ManagerFeatureStore>
    ) {
    }

    public getFundTransfers(params: IInstitutionManagerProgramHierarchyParams): Observable<IFundsTransferSummaryDto[]> {
        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getFunds(params)),
            tap(funds => {
                if (funds === undefined) {
                    this.store.dispatch(ManagerFundingActions.GetMangerFundTransfersAction(params));
                }
            }),
            filter(funds => funds != null)
        );
    }

    public getFundTransfersEffect({
        institutionId,
        managerId,
        programId,
        hierarchyNodeId
    }: IInstitutionManagerProgramHierarchyParams) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/funds-transfers?hierarchyNodeId=${hierarchyNodeId}`) as Observable<IFundsTransferSummaryDto[]>;
    }

    public getFundCredits(params: IInstitutionManagerProgramHierarchyParams): Observable<IFundsTransferCreditSummaryDto[]> {
        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getFundCredits(params)),
            tap(funds => {
                if (funds === undefined) {
                    this.store.dispatch(ManagerFundingActions.GetMangerFundCreditsAction(params));
                }
            }),
            filter(funds => funds != null)
        );
    }

    public getFundCreditsEffect({
        institutionId,
        managerId,
        programId,
        hierarchyNodeId
    }: IInstitutionManagerProgramHierarchyParams) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/funds-credits?hierarchyNodeId=${hierarchyNodeId}`) as Observable<IFundsTransferSummaryDto[]>;
    }

    public getFundTransfer(params: IInstitutionManagerProgramParams & { fundsTransferId: string }): Observable<IFundsTransferSummaryDto> {
        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getFund(params)),
            tap(fund => {
                if (fund === undefined) {
                    this.store.dispatch(ManagerFundingActions.GetMangerFundTransferAction(params));
                }
            }),
            filter(fund => fund != null)
        );
    }

    public getFundTransferEffect({
        institutionId,
        managerId,
        programId,
        fundsTransferId
    }: IInstitutionManagerProgramParams & { fundsTransferId: string }) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/funds-transfers/${fundsTransferId}`) as Observable<IFundsTransferSummaryDto>;
    }

    public downloadFundTransferExcel({
        institutionId,
        managerId,
        programId,
        fundsTransferId
    }: IInstitutionManagerProgramParams & { fundsTransferId: string }) {
        downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/quick-exports/funds-transfers/${fundsTransferId}`);
    }
}
