export enum eMilitaryFamilyServiceType {
    IAmServingInTheMilitary = 'IAmServingInTheMilitary',
    NoOneInMyFamilyIsServingInTheMilitary = 'NoOneInMyFamilyIsServingInTheMilitary',

    IHaveAParentServingInTheMilitary = 'IHaveAParentServingInTheMilitary',
    IHaveASiblingServingInTheMilitary = 'IHaveASiblingServingInTheMilitary',
    IHaveAParentWhoServedInTheMilitary = 'IHaveAParentWhoServedInTheMilitary',
    IHaveAParentWhoRetiredFromTheMilitary = 'IHaveAParentWhoRetiredFromTheMilitary',

    IHaveAFamilyMemberWhoRetiredFromTheMilitary = 'IHaveAFamilyMemberWhoRetiredFromTheMilitary',
    IHaveAFamilyMemberServingInTheMilitary = 'IHaveAFamilyMemberServingInTheMilitary',
    IHaveRetiredFromTheMilitary = 'IHaveRetiredFromTheMilitary'
}
