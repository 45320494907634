export enum eMemberParticipationStatus {
  Participant = 'Participant',
  Incomplete = 'Incomplete',
  Submitted = 'Submitted',
  ScreeningPendingApproval = 'ScreeningPendingApproval',
  Approved = 'Approved',
  Resubmit = 'Resubmit',
  DeclinedAndBlocked = 'DeclinedAndBlocked',
  Deleted = 'Deleted',
  PaymentDue = 'PaymentDue',
  AwaitingReview = 'AwaitingReview',
  TrainingsNotCompleted = 'TrainingsNotCompleted',
  Dismissed = 'Dismissed',
  NotEnrolled = 'NotEnrolled',
  Archived = 'Archived',
  PaymentNotReceivedByInstitution = 'PaymentNotReceivedByInstitution',
  NotEnrolledActiveLastYear = 'NotEnrolledActiveLastYear',
  NotParticipating = 'NotParticipating',
}
