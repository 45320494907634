import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  IEnrollmentFileControlCreateDto,
  IEnrollmentFileControlDto,
  IEnrollmentFileControlUpdateDto,
  IInstitutionSystemManagerProgramParams,
} from 'app/core/models';

export const SystemManagerGetEnrollmentCustomFilesAction = createAction(
  '[system-manager-custom-files] get enrollment custom files',
  props<{ institutionId: string, systemManagerId: string, programId: string }>()
);

export const SystemManagerGetEnrollmentCustomFilesSuccessAction = createAction(
  '[system-manager-custom-files] get enrollment custom files success',
  props<IInstitutionSystemManagerProgramParams & { customFiles: IEnrollmentFileControlDto[] }>()
);

export const SystemManagerGetEnrollmentCustomFilesErrorAction = createAction(
  '[system-manager-custom-files] get enrollment custom files error',
  props<IInstitutionSystemManagerProgramParams & { error?: any }>()
);

export const SystemManagerCreateEnrollmentCustomFileAction = createAction(
  '[system-manager-custom-files] create enrollment custom file',
  props<IInstitutionSystemManagerProgramParams & { customFile: IEnrollmentFileControlCreateDto }>()
);

export const SystemManagerCreateEnrollmentCustomFileSuccessAction = createAction(
  '[system-manager-custom-files] create enrollment custom file success',
  props<IInstitutionSystemManagerProgramParams & { customFile: IEnrollmentFileControlCreateDto }>()
);

export const SystemManagerCreateEnrollmentCustomFileErrorAction = createAction(
  '[system-manager-custom-files] create enrollment custom file error',
  props<IInstitutionSystemManagerProgramParams & { customFile: IEnrollmentFileControlCreateDto, error: HttpErrorResponse }>()
);

export const SystemManagerUpdateEnrollmentCustomFileAction = createAction(
  '[system-manager-custom-files] update enrollment custom file',
  props<IInstitutionSystemManagerProgramParams & { fileControlId: string, customFile: IEnrollmentFileControlUpdateDto }>()
);

export const SystemManagerUpdateEnrollmentCustomFileSuccessAction = createAction(
  '[system-manager-custom-files] update enrollment custom file success',
  props<IInstitutionSystemManagerProgramParams & { fileControlId: string, customFile: IEnrollmentFileControlUpdateDto }>()
);

export const SystemManagerUpdateEnrollmentCustomFileErrorAction = createAction(
  '[system-manager-custom-files] update enrollment custom file error',
  props<{ error: HttpErrorResponse }>()
);

export const SystemManagerReorderEnrollmentCustomFilesAction = createAction(
  '[system-manager-custom-files] reorder enrollment custom files',
  props<IInstitutionSystemManagerProgramParams & { customFileIds: string[] }>()
);
export const SystemManagerReorderEnrollmentCustomFilesSuccessAction = createAction(
  '[system-manager-custom-files] reorder enrollment custom files success',
  props<IInstitutionSystemManagerProgramParams & { customFileIds: string[] }>()
);
export const SystemManagerReorderEnrollmentCustomFilesErrorAction = createAction(
  '[system-manager-custom-files] reorder enrollment custom files error',
  props<{ error: HttpErrorResponse }>()
);

export const SystemManagerCustomFileDeleteAction = createAction(
  '[system-manager-custom-files] delete file',
  props<IInstitutionSystemManagerProgramParams & { fileControlId: string }>()
);

export const SystemManagerCustomFileDeleteSuccessAction = createAction(
  '[system-manager-custom-files] delete file success',
  props<IInstitutionSystemManagerProgramParams & { fileControlId: string }>()
);

export const SystemManagerCustomFileDeleteErrorAction = createAction(
  '[system-manager-custom-files] delete file error',
  props<{ error: HttpErrorResponse }>()
);


export const SystemManagerTemplateFileUploadAction = createAction(
  '[system-manager-custom-files] upload template',
  props<IInstitutionSystemManagerProgramParams & { fileControlId: string, formData: FormData }>()
);

export const SystemManagerTemplateFileUploadSuccessAction = createAction(
  '[system-manager-custom-files] upload template success',
  props<IInstitutionSystemManagerProgramParams & { fileControlId: string, formData: FormData }>()
);

export const SystemManagerTemplateFileUploadErrorAction = createAction(
  '[system-manager-custom-files] upload template error',
  props<{ error: HttpErrorResponse }>()
);

export const SystemManagerTemplateFileDeleteAction = createAction(
  '[system-manager-custom-files] delete template',
  props<IInstitutionSystemManagerProgramParams & { fileControlId: string }>()
);

export const SystemManagerTemplateFileDeleteSuccessAction = createAction(
  '[system-manager-custom-files] delete template success',
  props<IInstitutionSystemManagerProgramParams & { fileControlId: string }>()
);

export const SystemManagerTemplateFileDeleteErrorAction = createAction(
  '[system-manager-custom-files] delete template error',
  props<{ error: HttpErrorResponse }>()
);
