import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { EMAIL_REGEX, eProfileType } from 'app/core/models';
import { ManagerService } from 'app/core/services';

@Component({
  selector: 'ng4h-modal-verify-email',
  templateUrl: './modal-verify-email.component.html',
  styleUrls: ['./modal-verify-email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalVerifyEmailComponent implements OnInit {

  public form: FormGroup;

  public emailValid = false;
  public errorMessage: string;
  public wasValidated = false;

  constructor(private dialogRef: MatDialogRef<any>,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private managerService: ManagerService) { }

  public ngOnInit(): void {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email, Validators.pattern(EMAIL_REGEX)]],
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  public add(): void {
    if (this.form.invalid) {
      this.wasValidated = true;
      return;
    }
    this.managerService.canAddManager(this.form.controls['email'].value).subscribe(user => {
      if (user != null) {
        if (user.institutionProfiles.find(profile => profile.profileType === eProfileType.Family) != null) {
          this.emailValid = false;
          this.errorMessage = 'This email is already associated with a family profile.';
        } else if (user.institutionProfiles.find(profile => profile.profileType === eProfileType.Manager) != null) {
          this.emailValid = false;
          this.errorMessage = 'This email is already associated with a manager profile.';
        } else {
          const existingUser = {
            email: this.form.value.email,
            useraccounttoken: user.token,
            firstName: user.firstName,
            lastName: user.lastName,
          };

          this.dialogRef.close(existingUser);
        }
      } else {
        this.emailValid = true;
        this.errorMessage = null;

        const newUser = {
          email: this.form.value.email,
        };

        this.dialogRef.close(newUser);
      }

      this.cdr.detectChanges();
    });
  }
}
