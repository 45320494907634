import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import {
    getActivities,
    getActivity,
    ManagerFeatureStore
} from 'app/core/containers/admin/manager/manager-feature.reducer';
import { ManagerActivitiesActions } from 'app/core/containers/admin/manager/store/activities';
import {
    IActivityDto,
    IActivityUpdateDto,
    IInstitutionManagerProgramHierarchyParams,
    IInstitutionManagerProgramParams
} from 'app/core/models';
import { IActivityCreateDto } from 'app/core/models/serverDTOs/IActivityCreateDto';
import { filter, map, Observable, of, take, tap } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ManagerActivitiesService {

    constructor(private httpClient: HttpClient, private store: Store<ManagerFeatureStore>, private dispatcher: ActionsSubject) {
    }

    public getAllActivities(params: IInstitutionManagerProgramHierarchyParams) {
        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getActivities(params)),
            tap(activities => {
                if (activities === undefined) {
                    this.store.dispatch(ManagerActivitiesActions.LoadActivitiesAction(params));
                }
            }),
            filter(activities => activities != null)
        );
    }

    public getAllActivitiesEffect({
        institutionId,
        managerId,
        programId,
        hierarchyNodeId
    }: IInstitutionManagerProgramHierarchyParams) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/activities?hierarchyNodeId=${hierarchyNodeId}`) as Observable<IActivityDto[]>;
    }

    public getActivity(params: IInstitutionManagerProgramParams & { activityId: string }) {

        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getActivity(params)),
            tap(activity => {
                if (activity === undefined) {
                    this.store.dispatch(ManagerActivitiesActions.LoadActivityAction(params));
                }
            }),
            filter(activity => activity != null)
        );
    }

    public getActivityEffect({
        institutionId,
        managerId,
        programId,
        activityId
    }: IInstitutionManagerProgramParams & { activityId: string }) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/activities/${activityId}`) as Observable<IActivityDto>;
    }

    public createActivity(params: IInstitutionManagerProgramParams & { activityCreate: IActivityCreateDto }) {
        this.store.dispatch(ManagerActivitiesActions.CreateActivityAction(params));
        return this.dispatcher.pipe(
            ofType(ManagerActivitiesActions.CreateActivitySuccessAction, ManagerActivitiesActions.CreateActivityErrorAction),
            take(1),
            map(action => {
                if (action.type === ManagerActivitiesActions.CreateActivitySuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public createActivityEffect({
        institutionId,
        managerId,
        programId,
        activityCreate
    }: IInstitutionManagerProgramParams & { activityCreate: IActivityCreateDto }) {
        return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/activities`, activityCreate);
    }

    public updateActivity(params: IInstitutionManagerProgramParams & { activityId: string, activityUpdate: IActivityUpdateDto }) {
        this.store.dispatch(ManagerActivitiesActions.UpdateActivityAction(params));
        return this.dispatcher.pipe(
            ofType(ManagerActivitiesActions.UpdateActivitySuccessAction, ManagerActivitiesActions.UpdateActivityErrorAction),
            take(1),
            map(action => {
                if (action.type === ManagerActivitiesActions.UpdateActivitySuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public updateActivityEffect({
        institutionId,
        managerId,
        programId,
        activityId,
        activityUpdate
    }: IInstitutionManagerProgramParams & { activityId: string, activityUpdate: IActivityUpdateDto }) {
        return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/activities/${activityId}`, activityUpdate);
    }

    public deleteActivity(params: IInstitutionManagerProgramParams & { activityId: string }) {
        this.store.dispatch(ManagerActivitiesActions.DeleteActivityAction(params));
        return this.dispatcher.pipe(
            ofType(ManagerActivitiesActions.DeleteActivitySuccessAction, ManagerActivitiesActions.DeleteActivityErrorAction),
            take(1),
            map(action => {
                if (action.type === ManagerActivitiesActions.DeleteActivitySuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public deleteActivityEffect({
        institutionId,
        managerId,
        programId,
        activityId
    }: IInstitutionManagerProgramParams & { activityId: string }) {
        return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/activities/${activityId}`);
    }
}
