import { Action, createReducer, on } from '@ngrx/store';
import {
  IEnrollmentAnimalDto,
  IEventDto,
  IEventRegistrationDto,
  IInstitutionMemberEventModel,
  IInstitutionMemberModel,
  IInstitutionProgramModel,
  IRegistrationTypeFileControlDto,
} from 'app/core/models';
import {
  IInstitutionMemberProgramEventRegistrationModel,
} from 'app/core/models/function-parameters/institution-member-program-event-registration';

import { EventMemberActions } from '.';
import { EventsManagerActions } from '../events-manager';
import { mergeImmutable } from '../utils';

export interface EventsMemberState {
    event: IInstitutionMemberEventModel<IEventDto>;
    events: IInstitutionMemberModel<IEventDto[]>;
    eventRegistrations: IInstitutionMemberProgramEventRegistrationModel<IEventRegistrationDto>;
    // payableInvoice: IInstitutionMemberProgramEventRegistrationModel<IPayableInvoiceDto>;
    dynamicQuestionFormInputs: IInstitutionProgramModel<{ [eventId: string]: any }>;
    customFiles: IInstitutionMemberProgramEventRegistrationModel<IRegistrationTypeFileControlDto[]>;
    registrationAnimals: IInstitutionMemberProgramEventRegistrationModel<IEnrollmentAnimalDto[]>;
}

const initialState: EventsMemberState = {
    event: undefined,
    events: undefined,
    eventRegistrations: undefined,
    // payableInvoice: undefined,
    dynamicQuestionFormInputs: undefined,
    customFiles: undefined,
    registrationAnimals: undefined
};

const reducer = createReducer(
    initialState,
    on(EventsManagerActions.EventsManagerSendBackSuccessAction,
        EventsManagerActions.EventsManagerBlockSuccessAction,
        EventsManagerActions.EventsManagerApproveSuccessAction,
        EventsManagerActions.CancelRegistrationSuccessAction,
        EventMemberActions.EventsMemberSubmitSuccessAction, (state, { }) => {
            return initialState;
        }),
    on(EventMemberActions.EventMemberLoadSuccessAction, (state, { institutionId, memberId, eventId, event }) => {
        const newEvent = mergeImmutable(
            { [institutionId]: { [memberId]: { [eventId]: event } } },
            state.event
        );
        return {
            ...state,
            event: newEvent
        };
    }),
    on(EventMemberActions.EventsMemberLoadSuccessAction, (state, { institutionId, memberId, events }) => {
        const newEvents = mergeImmutable(
            { [institutionId]: { [memberId]: events } },
            state.events
        );
        return {
            ...state,
            events: newEvents
        };
    }),
    on(EventMemberActions.EventsMemberLoadRegistrationSuccessAction, (state, { institutionId, memberId, programId, eventRegistrationId, eventRegistration }) => {
        const newEventRegistrations = mergeImmutable(
            { [institutionId]: { [memberId]: { [programId]: { [eventRegistrationId]: eventRegistration } } } },
            state.eventRegistrations
        );

        return {
            ...state,
            eventRegistrations: newEventRegistrations,
        };
    }),
    // on(EventMemberActions.EventsMemberLoadPayableInvoiceSuccessAction, (state, { institutionId, memberId, programId, eventRegistrationId, invoice }) => {
    //     const newPayableInvoice = mergeImmutable(
    //         { [institutionId]: { [memberId]: { [programId]: { [eventRegistrationId]: invoice } } } },
    //         state.payableInvoice
    //     );
    //     return {
    //         ...state,
    //         payableInvoice: newPayableInvoice
    //     };
    // }),
    on(EventMemberActions.EventsMemberLoadDynamicFormSuccessAction, (state, { institutionId, memberId, programId, eventRegistrationId, dynamicForm }) => {
        const newDynamicQuestionFormInputs = mergeImmutable(
            { [institutionId]: { [memberId]: { [programId]: { [eventRegistrationId]: dynamicForm } } } },
            state.dynamicQuestionFormInputs
        );
        return {
            ...state,
            dynamicQuestionFormInputs: newDynamicQuestionFormInputs
        };
    }),
    on(EventMemberActions.EventsMemberInvalidateEventRegistrationsAction, (state, { }) => {
        return {
            ...state,
            eventRegistrations: undefined
        };
    }),
    on(EventMemberActions.EventsMemberLoadCustomFilesSuccessAction, (state, { institutionId, memberId, programId, eventRegistrationId, files }) => {
        const newCustomFiles = mergeImmutable(
            { [institutionId]: { [memberId]: { [programId]: { [eventRegistrationId]: files } } } },
            state.customFiles
        );
        return {
            ...state,
            customFiles: newCustomFiles
        };
    }),
    // on(EventMemberActions.EventsMemberPayableInvoiceInvalidateCacheAction, (state, { }) => {
    //     return {
    //         ...state,
    //         payableInvoice: undefined
    //     };
    // }),
    on(EventMemberActions.EventsMemberInvalidateDynamicFormsCacheAction, (state, { }) => {
        return {
            ...state,
            dynamicQuestionFormInputs: undefined
        };
    }),
    on(EventMemberActions.EventsMemberInvalidateCustomFilesCache, (state, { }) => {
        return {
            ...state,
            customFiles: undefined
        };
    }),
    on(EventMemberActions.EventsMemberAddSessionAction, (state, { institutionId, memberId, programId, eventRegistrationId, eventSessionId }) => {

        const oldRegistration = state.eventRegistrations[institutionId][memberId][programId][eventRegistrationId];
        const oldEventSessions = oldRegistration.registrationSessions ? oldRegistration.registrationSessions : [];
        const newRegistration: IEventRegistrationDto = {
            ...oldRegistration,
            registrationSessions: [...oldEventSessions, { eventSessionId, registrationSessionId: null, fee: null, label: null, description: null }]
        };

        const newEventRegistrations = mergeImmutable(
            { [institutionId]: { [memberId]: { [programId]: { [eventRegistrationId]: newRegistration } } } },
            state.eventRegistrations
        );

        return {
            ...state,
            eventRegistrations: newEventRegistrations,
        };
    }),
    on(EventMemberActions.EventsMemberRemoveSessionAction, (state, { institutionId, memberId, programId, eventRegistrationId, eventSessionId }) => {

        const oldRegistration = state.eventRegistrations[institutionId][memberId][programId][eventRegistrationId];
        const oldEventSessions = oldRegistration.registrationSessions ? oldRegistration.registrationSessions : [];
        const newRegistration = {
            ...oldRegistration,
            registrationSessions: oldEventSessions.filter(oldSessions => oldSessions.eventSessionId !== eventSessionId)
        };

        const newEventRegistrations = mergeImmutable(
            { [institutionId]: { [memberId]: { [programId]: { [eventRegistrationId]: newRegistration } } } },
            state.eventRegistrations
        );

        return {
            ...state,
            eventRegistrations: newEventRegistrations,
        };
    }),
    on(EventMemberActions.EventsMemberGetEventRegistraitonAninimalsSuccessAction, (state, { institutionId, memberId, programId, eventRegistrationId, animals }) => {
        const newAnimals = mergeImmutable(
            { [institutionId]: { [memberId]: { [programId]: { [eventRegistrationId]: animals } } } },
            state.registrationAnimals
        );

        return {
            ...state,
            registrationAnimals: newAnimals,
        };
    })
);

export function memberEventReducer(state: EventsMemberState | undefined, action: Action) {
    return reducer(state, action);
}

export const event = (state: EventsMemberState) => state.event;
export const events = (state: EventsMemberState) => state.events;
export const eventRegistrations = (state: EventsMemberState) => state.eventRegistrations;
// export const payableInvoice = (state: EventsMemberState) => state.payableInvoice;
export const dynamicQuestionFormInputs = (state: EventsMemberState) => state.dynamicQuestionFormInputs;
export const customFiles = (state: EventsMemberState) => state.customFiles;
export const registrationAnimals = (state: EventsMemberState) => state.registrationAnimals;
