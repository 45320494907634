import { IProjectDto } from '.';

export interface IVolunteerHoursDto {
    volunteerHoursId: string;
    project: IProjectDto;
    projectId: string;
    volunteerHours: number;
    continuingEducationHours: number;
    milesDriven: number;
    totalTravelTimeMinutes: number;
    comment?: string;
    volunteerDate: string;
}
