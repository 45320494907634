import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { IAddressVerificationResultDto } from 'app/core/models';
import { RouterService, UserService } from 'app/core/services';
import { AddressVerificationService } from 'app/core/services/address-verification.service';
import { catchError, map, of, switchMap } from 'rxjs';

import { AddressVerificationActions } from '.';

@Injectable()
export class AddressVerificationEffects {

    verifyAddress = createEffect(() =>
        this.actions$.pipe(
            ofType(AddressVerificationActions.VerifyAddressAction),
            switchMap(payload => {
                return this.addressVerificationService.verifyEffect({ institutionId: payload.institutionId, familyId: payload.familyId, address: payload.address }).pipe(
                    map((res: IAddressVerificationResultDto) => {
                        return AddressVerificationActions.VerifyAddressSuccessAction({ institutionId: payload.institutionId, familyId: payload.familyId, res: res });
                    }),
                    catchError(error => of(AddressVerificationActions.VerifyAddressErrorAction({ ...payload, error })))
                );
            })
        ));


    constructor(private actions$: Actions, private addressVerificationService: AddressVerificationService, private routerService: RouterService, private userService: UserService) { }
}
