// export enum eFormControlType {
//   Unknown = '',
//   CheckBox = 'Checkbox',
//   DropDown = 'dropdown',
//   Number = 'numberinput',
//   TextAreaInput = 'textareainput',
//   TextArea = 'textarea',
//   Text = 'textinput',
//   Date = 'dateinput',
//   RadioGroup = 'radioGroup',

//   MultipleChoice = 'multipleChoiceQuestion',
//   TimeSlot = 'timeSlotQuestion',

//   PageBreak = 'PageBreak'
// }

export enum eFormControlType {
  TextContentBlock = 'TextContentBlock',
  SingleLineText = 'SingleLineText',
  MultipleLineText = 'MultipleLineText',
  SingleChoice = 'SingleChoice',
  MultipleChoice = 'MultipleChoice',
  WholeNumber = 'WholeNumber',
  Decimal = 'Decimal',
  Currency = 'Currency',
  Date = 'Date',
  Time = 'Time',
  Phone = 'Phone',
  Email = 'Email',
  Hyperlink = 'Hyperlink',
  TimeSlot = 'TimeSlot',
  Quantity = 'Quantity',
  Checkbox = 'Checkbox',
  Matrix = 'Matrix',
  DependentMultipleLineText = 'DependentMultipleLineText'
}
