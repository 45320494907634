<div class="modal-header">
  <h5 class="modal-title">Edit Project</h5>
  <button (click)="close()" type="button" class="close" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" *ngIf="enrollment$ | async as enrollment">
  <table class="ng4h-margin-bottom" [ngClass]="{'read-only': data.canEdit === false}">
    <thead>
      <th>Project</th>
      <th>Years In Project</th>
      <th>State Project</th>
      <th>{{ (eUnitType.Club | programAlias$ | async)?.singular }}</th>
      <th>Volunteer Role</th>
    </thead>
    <tbody>
      <tr>
        <td> {{ data.project.projectName }}</td>
        <td>
          <ng4h-number-select [(ngModel)]="yearsInProject" [maxNumber]="99"></ng4h-number-select>
        </td>
        <td> {{ data.project.parentProjectName }}</td>
        <td> {{ data.project.unitName }}</td>
        <td>
          <ng-container *ngIf="enrollment.enrollmentRole !== eEnrollmentRoleType.CloverBud">
            <ng-container *ngIf="volunteerTypes$ | async as vts">
              <ng-container *ngIf="vts.length > 0">
                <div>
                  <select class="form-control" [ngModel]="volunteerType" (ngModelChange)="volunteerTypeChanged($event)">
                    <option *ngIf="enrollment.enrollmentRole !== eEnrollmentRoleType.Volunteer" [ngValue]="null"></option>
                    <option *ngFor="let vt of vts" [ngValue]="vt">
                      {{ vt.volunteerTypeLabel }}
                    </option>
                  </select>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </td>

      </tr>
    </tbody>
  </table>
  <ng4h-card>
    <ng4h-card-header>
      <h5>Consents</h5>
    </ng4h-card-header>
    <ng4h-card-content>

      <div class="ng4h-table" *ngIf="data.project.consents.length > 0; else noConsents">
        <div class="col-12" *ngFor="let recordedConsent of data.project.consents">
          <h3>{{ recordedConsent.consentVersion.consentName }}</h3>
          <div class="row">
            <div class="col-4 ng4h-bg-secondary"><b>Status</b></div>
            <div class="col-8">{{ recordedConsent.consentStatus }}</div>
          </div>
          <div class="row" *ngIf="recordedConsent.recordedManagerName != null">
            <div class="col-4 ng4h-bg-secondary"><b>Recorded Manager Name</b></div>
            <div class="col-8">{{ recordedConsent.recordedManagerName }}</div>
          </div>
          <div class="row" *ngIf="recordedConsent.recordedMemberName != null">
            <div class="col-4 ng4h-bg-secondary"><b>Recorded Member Name</b></div>
            <div class="col-8">{{ recordedConsent.recordedMemberName }} (Full Legal)</div>
          </div>
          <div class="row" *ngIf="recordedConsent.recordedParentGuardianName != null">
            <div class="col-4 ng4h-bg-secondary"><b>Recorded Parent Guardian Name</b></div>
            <div class="col-8">{{ recordedConsent.recordedParentGuardianName }} (Full Legal)</div>
          </div>
        </div>
      </div>

      <ng-template #noConsents>
        <h4>No Consents</h4>
      </ng-template>
    </ng4h-card-content>
  </ng4h-card>
</div>
<div class="modal-footer">
  <button [disabled]="data.canEdit === false" class="btn btn-primary" (click)="save()">Save</button>
  <button class="btn btn-secondary" (click)="close()">Cancel</button>
</div>