import { eEmailDeliveryStatus } from '..';

export interface IManagerNoteEmailDto {
    emailId: string;
    emailAddress?: string;
    emailSubject?: string;
    status: eEmailDeliveryStatus;
    statusMessage?: string;
    processedDate?: string;
}
