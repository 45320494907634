import { eEventSessionType, eEventStatus } from '../enums';

export interface IEventUpdateDto {
    status: eEventStatus;
    // bankAccountId: string;
    enableSessions: boolean;
    sessionType?: eEventSessionType;
    requireCountyAreaApproval: boolean;
    requireDistrictAreaApproval: boolean;
    requireRegionAreaApproval: boolean;
    requireProgramLevelApproval: boolean;
    eventName?: string;
    eventDescription?: string;
    programYear: string;
    startDate: string;
    endDate: string;


    allowMultipleRegistrations?: boolean;
    allowRegistrationByManagerOnly?: boolean;
    paymentMethod_Credits_On: boolean;
    paymentMethod_Electronic_On: boolean;
    disableAutomatedEmails?: boolean;

    enableTicketing?: boolean;
    creditCardFeeRecovery?: boolean;
    eventInstructions: string;
}

