<form *ngIf="form != null && consents != null" [formGroup]="form" novalidate>
  <div formArrayName="consents">
    <div *ngFor="let item of form.controls['consents'].controls; let i = index; trackBy: controlTrackBy">
      <ng4h-card>
        <ng4h-card-header>
          <h5>{{ consents[i].consentVersion.consentText?.consentTitle }}</h5>
        </ng4h-card-header>

        <ng4h-card-content>
          <div [innerHTML]="consents[i].consentVersion.consentText?.consentText | safeHtml"></div>
        </ng4h-card-content>
        <ng4h-card-footer>
          <div [formGroupName]="i">
            <label [for]="consents[i].consentVersion.consentTypeId + '_consentStatus'" class="sr-only">Consent
              Status</label>
            <mat-radio-group class="col-12" formControlName="consentStatus"
                             [id]="consents[i].consentVersion.consentTypeId + '_consentStatus'">
              <span class="row">
                <mat-radio-button class="col-12" [value]="eConsentStatus.Accepted">
                  <span>{{ consents[i].consentVersion.consentText?.agreeOptionText }}</span>
                </mat-radio-button>
              </span>
              <hr *ngIf="consents[i].consentVersion?.acceptanceRequired != true" />
              <span class="row">
                <mat-radio-button *ngIf="consents[i].consentVersion?.acceptanceRequired != true" class="col-12"
                                  [value]="eConsentStatus.Declined">
                  {{ consents[i].consentVersion.consentText?.disagreeOptionText }}</mat-radio-button>
              </span>
            </mat-radio-group>
            <ng-container
              *ngIf="!showAsManagerConsent || form.controls['consents'].controls[i].recordedManagerName != null; else managerConsent">
              <div class="form-group col-12" *ngIf="consents[i].consentVersion.electronicSignatureRequired">
                <label [for]="consents[i].consentVersion.consentTypeId + '_recordedMemberName'"
                       class="form-control-label ng4h-required">Member Name (Full Legal)</label>
                <input type="text" class="form-control" formControlName="recordedMemberName"
                       [id]="consents[i].consentVersion.consentTypeId + '_recordedMemberName'">
              </div>
              <div class="form-group col-12"
                   *ngIf="consents[i].consentVersion.electronicSignatureRequired && !hideGuardianSignature">
                <label [for]="consents[i].consentVersion.consentTypeId + '_recordedParentGuardianName'"
                       class="form-control-label ng4h-required">Parent / Guardian
                  Name (Full Legal)</label>
                <input type="text" class="form-control" formControlName="recordedParentGuardianName"
                       [id]="consents[i].consentVersion.consentTypeId + '_recordedParentGuardianName'">
              </div>
            </ng-container>
            <ng-template #managerConsent>
              <div class="form-group col-12">
                <label [for]="consents[i].consentVersion.consentTypeId + '_recordedManagerName'"
                       class="form-control-label ng4h-required">Manager Name Certifying Paper
                  Consent</label>
                <input type="text" class="form-control" formControlName="recordedManagerName"
                       [id]="consents[i].consentVersion.consentTypeId + '_recordedManagerName'">
              </div>
            </ng-template>

          </div>
        </ng4h-card-footer>
      </ng4h-card>
    </div>
  </div>
</form>