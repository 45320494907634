import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  AutoUnsubscribe,
  eEnrollmentRoleType,
  eUnitType,
  eVolunteerType,
  IEnrollmentDto,
  IEnrollmentUnitDto,
  IHierarchyCountyArea,
  IInstitutionMemberParams,
  IInstitutionMemberProgramEnrollmentParams,
  IInstitutionVolunteerDto,
  IMemberVolunteerDto,
  IProgramSettingsDto,
} from 'app/core/models';
import {
  ClubsService,
  EnrollmentService,
  HierarchyService,
  MemberService,
  ProfileService,
  RouterService,
} from 'app/core/services';
import { ProgramSettingsService } from 'app/core/services/program-settings.service';
import { combineLatest, filter, map, Observable, switchMap, take, takeUntil } from 'rxjs';

@Component({
  selector: 'ng4h-modal-add-clubs',
  templateUrl: './modal-add-clubs.component.html',
  styleUrls: ['./modal-add-clubs.component.scss']
})
export class ModalAddClubsComponent extends AutoUnsubscribe implements OnInit {

  public eUnitType = eUnitType;
  public eEnrollmentRoleType = eEnrollmentRoleType;

  public programSettings$: Observable<IProgramSettingsDto>;
  // public clubFilterString: string;
  public selectedCountyAreaId: string;

  public remainingClubs$: Observable<IEnrollmentUnitDto[]>;
  // public countyAreas: Observable<{ [programId: string]: IHierarchyCountyArea[] }>;

  private enrollment$: Observable<IEnrollmentDto>;

  // private filterChanged: Subject<string>;
  public enrollmentRole$: Observable<eEnrollmentRoleType>;

  public volunteerTypes$: Observable<IInstitutionVolunteerDto[]>;

  private volunteerCategories$: Observable<IInstitutionVolunteerDto[]>;
  public volunteerType: IInstitutionVolunteerDto;
  public isManager$: Observable<boolean>;

  private institutionMemberParams: IInstitutionMemberParams;
  private institutionMemberProgramEnrollmentParams: IInstitutionMemberProgramEnrollmentParams;

  public alwaysShowUnitAndProjects$: Observable<boolean>;
  constructor(
    private dialogRef: MatDialogRef<any>,
    private clubsService: ClubsService,
    private enrollmentService: EnrollmentService,
    private routerService: RouterService,
    private memberService: MemberService,
    private programSettingsService: ProgramSettingsService,
    private hierarchyService: HierarchyService,
    private profileService: ProfileService
  ) {
    super();

    this.institutionMemberParams = {
      institutionId: this.routerService.institutionId,
      memberId: this.routerService.memberId ? this.routerService.memberId : this.routerService.selectedMemberId,
    };

    this.institutionMemberProgramEnrollmentParams = {
      ...this.institutionMemberParams,
      programId: this.routerService.programId,
      enrollmentId: this.routerService.enrollmentId
    };

    this.isManager$ = this.profileService.activeUserInstitutionProfileIsManager$;
    this.programSettings$ = this.programSettingsService.getProgramSettings({
      institutionId: this.routerService.institutionId,
      programId: this.routerService.programId
    });


    this.enrollment$ = this.enrollmentService.getEnrollment(this.institutionMemberProgramEnrollmentParams).pipe(
      takeUntil(this.autoUnsubscribe)
    );

    this.volunteerCategories$ = this.enrollmentService.getVolunteerTypes(this.institutionMemberProgramEnrollmentParams);

    this.volunteerTypes$ = combineLatest([this.enrollment$, this.volunteerCategories$]).pipe(
      map(([enrollment, volunteerCategories]) => {
        if (enrollment.enrollmentRole === eEnrollmentRoleType.Volunteer) {
          return enrollment.volunteerTypes.filter(v => {
            if (enrollment.enrollmentRole === eEnrollmentRoleType.Volunteer) {
              return v.volunteerTypeDescriptor.volunteerType === eVolunteerType.AdultClubVolunteer;
            }
          });
        } else if (enrollment.enrollmentRole === eEnrollmentRoleType.ClubMember) {
          return volunteerCategories.filter(volunteerType => {
            return volunteerType.volunteerTypeDescriptor.volunteerType === eVolunteerType.YouthClubVolunteer;
          });
        }
      })
    );

    this.enrollmentRole$ = this.enrollment$.pipe(map(enrollment => enrollment.enrollmentRole));

    this.enrollmentRole$.pipe(
      filter(enrollmentRole => enrollmentRole === eEnrollmentRoleType.Volunteer),
      switchMap(() => this.volunteerTypes$),
      filter(volunteerTypes => Array.isArray(volunteerTypes) && volunteerTypes.length > 0),
      take(1))
      .subscribe(volunteerTypes => {
        if (Array.isArray(volunteerTypes) && volunteerTypes.length === 1) {
          this.volunteerTypeChanged(volunteerTypes[0]);
        }
      });






    this.memberService.getMember(this.institutionMemberParams).pipe(
      filter(member => member != null),
      take(1),
      map(member => member.families.find(family => family.primary).countyAreaHierarchyNodeId),
      switchMap(countyAreaHierarchyNodeId => this.hierarchyService.getHierarchyNode({ institutionId: this.routerService.institutionId, hierarchyNodeId: countyAreaHierarchyNodeId })),
      filter(node => node != null),
      take(1),
      map((node: IHierarchyCountyArea) => node.countyAreaId)
    ).subscribe(countyAreaId => {
      this.countyAreaIdChanged(countyAreaId);
    });
  }

  ngOnInit() {
    this.alwaysShowUnitAndProjects$ = this.programSettings$.pipe(
      map(programSettings => programSettings.enrollment_Always_Show_Unit_And_Project_Selection === true)
    );
  }

  private calculateRemainingClubs(selectedCountyAreaId) {
    const clubs = this.clubsService.getClubs({
      institutionId: this.institutionMemberProgramEnrollmentParams.institutionId,
      memberId: this.institutionMemberProgramEnrollmentParams.memberId,
      programId: this.institutionMemberProgramEnrollmentParams.programId,
      enrollmentId: this.institutionMemberProgramEnrollmentParams.enrollmentId,
      countyAreaId: selectedCountyAreaId
    });
    this.remainingClubs$ = combineLatest([clubs, this.enrollment$.pipe(map(enrollment => enrollment))]).pipe(
      filter(combined => {
        return combined.every(c => c != null);
      }),
      map(combined => {
        return combined[0].filter(club => {
          if (combined[1].units == null) {
            return true;
          }
          const selectedClubIndex = combined[1].units.findIndex(selectedClub => {
            return selectedClub.unitId === club.unitId;
          });
          return selectedClubIndex === -1;
        });
      }),
    );
  }

  public countyAreaIdChanged(selectedCountyAreaId) {
    if (selectedCountyAreaId !== '') {
      this.selectedCountyAreaId = selectedCountyAreaId;
      this.calculateRemainingClubs(selectedCountyAreaId);
    }
  }

  public volunteerTypeChanged(volunteerType: IInstitutionVolunteerDto) {
    this.volunteerType = volunteerType;
  }

  close() {
    this.dialogRef.close();
  }

  addClub(clubToAdd: IEnrollmentUnitDto) {
    const newVolunteerType: IMemberVolunteerDto = {
      ...this.volunteerType,
      project: null,
      unit: null
    };
    const club = {
      ...clubToAdd,
      volunteerType: newVolunteerType
    };
    this.enrollmentService.addClub({ ...this.institutionMemberProgramEnrollmentParams, club });
    this.close();
  }


}
