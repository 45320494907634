import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IProgramYearDto } from 'app/core/models';
import { ProgramYearService, RouterService } from 'app/core/services';
import { combineLatest, filter, map, Observable, of, switchMap } from 'rxjs';

@Component({
    selector: 'ng4h-program-year-dropdown',
    templateUrl: './program-year-dropdown.component.html',
    styleUrls: ['./program-year-dropdown.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ProgramYearDropdownComponent),
            multi: true
        }
    ]
})
export class ProgramYearDropdownComponent implements OnInit, ControlValueAccessor {

    @Input() value = '';
    @Input() programId: string;
    @Input() disabled: boolean;
    @Input() thisAndPreviousYearOnly: boolean;

    public programYears$: Observable<IProgramYearDto[]>;
    public currentProgramYear$: Observable<IProgramYearDto>;
    private availableProgramYears$: Observable<IProgramYearDto[]>;

    onChange: any = () => {

    }
    onTouched: any = () => {

    }

    constructor(private programYearService: ProgramYearService, private routerService: RouterService) {

    }

    registerOnChange(fn) {
        this.onChange = fn;
    }

    registerOnTouched(fn) {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    ngOnInit() {
        const params = combineLatest([this.routerService.institutionId$, this.programId != null ? of(this.programId) : this.routerService.programId$]);

        this.currentProgramYear$ = params.pipe(
            switchMap(([institutionId, programId]) => {
                return this.programYearService.getCurrentProgramYear({ institutionId, programId });
            }));

        this.availableProgramYears$ = params.pipe(
            switchMap(([institutionId, programId]) => {
                return this.programYearService.getAvailableProgramYears({ institutionId, programId });
            }));

        if (this.thisAndPreviousYearOnly === true) {

            this.programYears$ = combineLatest([
                this.availableProgramYears$,
                this.currentProgramYear$
            ]).pipe(
                filter(combined => combined.every(c => c != null)),
                map(([availableProgramYears, currentProgramYear]) => {
                    return availableProgramYears.filter(availableProgramYear => availableProgramYear.programYear === currentProgramYear.programYear || availableProgramYear.programYear === currentProgramYear.programYear - 1);
                })
            );

        } else {
            this.programYears$ = params.pipe(
                switchMap(([institutionId, programId]) => {
                    return this.programYearService.getAvailableProgramYears({ institutionId, programId });
                }));
        }

        // Initialize
        // this.programYears$.pipe(filter(py => py != null), take(1)).subscribe(programYears => {
        //   this.writeValue(programYears[0].programYearId);
        // });
    }

    changed(value) {
        this.writeValue(value);
        this.onTouched();
    }

    writeValue(value) {
        if (value != null) {
            this.value = value;
            this.onChange(value);
        }
    }
}
