<div id="searchResultsCount">
  <span *ngIf="resultCount > 0">
    Displaying results {{ startIndex }} - {{ endIndex }} of {{ resultCount }}
  </span>
  <span *ngIf="resultCount == null">
    No results found
  </span>
  <div>
    <div class="btn-group">
      <button class="btn btn-secondary" [disabled]="pageIndex === 1 || pageCount < 2" (click)="back()"><i
          class="fas fa-chevron-left no-margin" style="color: #5099DE;"></i></button>
      <button class="btn btn-secondary" [disabled]="!pageCount || pageIndex >= pageCount || pageCount < 2"
        (click)="forward()"><i class="fas fa-chevron-right no-margin" style="color: #5099DE;"></i></button>
    </div>
  </div>
</div>