import { eEventRegistrationTypeYouthRestrictionType, eHealthFormType, eRegistrationRoleType } from '..';

export interface IEventRegistrationTypeUpdateDto {
    registrationTypeName?: string;
    registrationRole?: eRegistrationRoleType;
    healthFormType?: eHealthFormType;
    //enrollmentRoleActiveProgramYear: string;
    maximumRegistrations?: number;
    allowActiveInPreviousProgramYear?: boolean;
    registrationFee?: number;
    registrationStartDate: string;
    registrationEndDate: string;
    allowEarlyRegistration?: boolean;
    earlyRegistrationFee?: number;
    earlyRegistrationStartDate?: string;
    earlyRegistrationEndDate?: string;
    allowLateRegistration?: boolean;
    lateRegistrationFee?: number;
    lateRegistrationStartDate?: string;
    lateRegistrationEndDate?: string;
    depositRequired: boolean;
    depositAmount?: number;
    depositFullPaymentDueDate?: string;
    earlyBirdDiscountEnabled: boolean;
    earlyBirdDiscountAmount?: number;
    earlyBirdDiscountCutoffDate?: string;
    enableSession: boolean;
    restrictionType: eEventRegistrationTypeYouthRestrictionType;
    programYouthDivisions?: any[];
    programAges?: any[];
    schoolGrades?: any[];
    initiateRegistrationByManagerOnly?: boolean;

    registrationTypeDescription?: string;
    animalTypes?: string[];
    ages?: any[];
}
