import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { AppState } from 'app/app.reducers';
import { SystemManagerProjectActions } from 'app/core/containers/admin/system-manager/enrollment/project/store';
import {
  getSystemManagerProject,
  getSystemManagerProjects
} from 'app/core/containers/admin/system-manager/system-manager-feature.reducers';
import {
  eEnrollmentRoleType,
  IInstitutionSystemManagerProgramParams,
  IProjectCreateDto,
  IProjectDto
} from 'app/core/models';
import { IProjectUpdateDto } from 'app/core/models/serverDTOs/IProjectUpdateDto';
import { map, Observable, of, switchMap, take, tap } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SystemManagerProjectsService {

  constructor(private store: Store<AppState>, private http: HttpClient, private dispatcher: ActionsSubject) {

  }

  public getSystemManagerProjects(params: IInstitutionSystemManagerProgramParams) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getSystemManagerProjects(params)),
      tap(projects => {
        if (projects === undefined) {
          this.store.dispatch(SystemManagerProjectActions.SystemManagerGetProjectsAction(params));
        }
      })
    );
  }

  public loadSystemManagerProjectsEffect({
    institutionId,
    systemManagerId,
    programId
  }: IInstitutionSystemManagerProgramParams): Observable<IProjectDto[]> {
    return this.http.get(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/projects`) as Observable<IProjectDto[]>;
  }

  public getSystemManagerProjectById(params: IInstitutionSystemManagerProgramParams & { projectId: string }): Observable<IProjectDto> {

    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getSystemManagerProject(params)),
      tap(project => {
        if (project === undefined) {
          this.store.dispatch(SystemManagerProjectActions.SystemManagerGetProjectAction(params));
        }
      })
    );

  }

  public getSystemManagerProjectsByIdEffect({
    institutionId,
    systemManagerId,
    programId,
    projectId
  }: IInstitutionSystemManagerProgramParams & { projectId: string }): Observable<IProjectDto> {
    return this.http.get(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/projects/${projectId}`) as Observable<IProjectDto>;
  }

  public updateSystemManagerProject(params: IInstitutionSystemManagerProgramParams & { projectId: string, project: IProjectUpdateDto }) {
    this.store.dispatch(SystemManagerProjectActions.SystemManagerUpdateProjectAction(params));

    return this.dispatcher.pipe(
      ofType(SystemManagerProjectActions.SystemManagerUpdateProjectSuccessAction, SystemManagerProjectActions.SystemManagerUpdateProjectErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerProjectActions.SystemManagerUpdateProjectSuccessAction.type) {
          return action.project;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateSystemManagerProjectEffect({
    institutionId,
    systemManagerId,
    programId,
    projectId,
    project
  }: IInstitutionSystemManagerProgramParams & { projectId: string, project: IProjectUpdateDto }) {
    return this.http.patch(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/projects/${projectId}`, project);
  }

  public addSystemManagerProject(params: IInstitutionSystemManagerProgramParams & { project: IProjectCreateDto }) {
    this.store.dispatch(SystemManagerProjectActions.SystemManagerCreateProjectAction(params));

    return this.dispatcher.pipe(
      ofType(SystemManagerProjectActions.SystemManagerCreateProjectSuccessAction, SystemManagerProjectActions.SystemManagerCreateProjectErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerProjectActions.SystemManagerCreateProjectSuccessAction.type) {
          return action.newProject;
        } else {
          throw action.error;
        }
      })
    );
  }

  public addSystemManagerProjectEffect({
    institutionId,
    systemManagerId,
    programId,
    project
  }: IInstitutionSystemManagerProgramParams & { project: IProjectCreateDto }) {
    return this.http.post(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/projects`, project, { observe: 'response' })
    .pipe(
      switchMap((res: HttpResponse<any>) => this.http.get(res.headers.get('location')))
    ) as Observable<IProjectDto>;
  }

  public addSystemManagerProjectConsent(params: IInstitutionSystemManagerProgramParams & { projectId: string, consentTypeId: string, enrollmentRole: eEnrollmentRoleType }) {
    this.store.dispatch(SystemManagerProjectActions.SystemManagerAddProjectConsentAction(params));

    return this.dispatcher.pipe(
      ofType(SystemManagerProjectActions.SystemManagerAddProjectConsentSuccessAction, SystemManagerProjectActions.SystemManagerAddProjectConsentErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerProjectActions.SystemManagerAddProjectConsentSuccessAction.type) {
          return action.consentTypeId;
        } else {
          throw action.error;
        }
      })
    );
  }

  public addSystemManagerProjectConsentEffect({
    institutionId,
    systemManagerId,
    programId,
    projectId,
    consentTypeId,
    enrollmentRole
  }: IInstitutionSystemManagerProgramParams & { projectId: string, consentTypeId: string, enrollmentRole: eEnrollmentRoleType }) {
    return this.http.put(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/projects/${projectId}/consents?consentTypeId=${consentTypeId}&role=${enrollmentRole}`, {});
  }

  public deleteSystemManagerProjectConsent(params: IInstitutionSystemManagerProgramParams & { projectId: string, consentTypeId: string, enrollmentRole: eEnrollmentRoleType }) {
    this.store.dispatch(SystemManagerProjectActions.SystemManagerDeleteProjectConsentAction(params));

    return this.dispatcher.pipe(
      ofType(SystemManagerProjectActions.SystemManagerDeleteProjectConsentSuccessAction, SystemManagerProjectActions.SystemManagerDeleteProjectConsentErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerProjectActions.SystemManagerDeleteProjectConsentSuccessAction.type) {
          return action.consentTypeId;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteSystemManagerProjectConsentEffect({
    institutionId,
    systemManagerId,
    programId,
    projectId,
    consentTypeId,
    enrollmentRole
  }: IInstitutionSystemManagerProgramParams & { projectId: string, consentTypeId: string, enrollmentRole: eEnrollmentRoleType }) {
    return this.http.delete(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/projects/${projectId}/consents?consentTypeId=${consentTypeId}&role=${enrollmentRole}`, {});
  }

  public deleteInstitutionProject(params: IInstitutionSystemManagerProgramParams & { projectId: string }): Observable<IInstitutionSystemManagerProgramParams & { projectId: string }> {
    this.store.dispatch(SystemManagerProjectActions.SystemManagerDeleteInstitutionProjectAction(params));

    return this.dispatcher.pipe(
      ofType(SystemManagerProjectActions.SystemManagerDeleteInstitutionProjectSuccessAction, SystemManagerProjectActions.SystemManagerDeleteInstitutionProjectErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerProjectActions.SystemManagerDeleteInstitutionProjectSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteInstitutionProjectEffect({
    institutionId,
    systemManagerId,
    programId,
    projectId
  }: IInstitutionSystemManagerProgramParams & { projectId: string }) {
    return this.http.delete(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/projects/${projectId}`);
  }
}

