import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import {
  eEnrollmentRoleType,
  eInstitutionDiscountStatus,
  eInstitutionDiscountType,
  eInvoiceDiscountFeePercentage,
  eInvoiceDiscountType,
  IInstitutionDiscountDto,
  IInstitutionDiscountUpdateDto,
} from 'app/core/models';
import { ManagerFinancesService, RouterService } from 'app/core/services';
import { Observable, take } from 'rxjs';

@Component({
  selector: 'ng4h-modal-edit-coupon',
  templateUrl: './modal-edit-coupon.component.html',
  styleUrls: ['./modal-edit-coupon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalEditCouponComponent implements OnInit {


  public discount$: Observable<IInstitutionDiscountDto>;

  public form: FormGroup;
  public enrollmentRole: eEnrollmentRoleType;
  // public selectedRoles = [];

  public eInstitutionDiscountType = eInstitutionDiscountType;
  public eInvoiceDiscountFeePercentage = eInvoiceDiscountFeePercentage;
  public eInvoiceDiscountType = eInvoiceDiscountType;
  public eInstitutionDiscountStatus = eInstitutionDiscountStatus;

  public eEnrollmentRoleType = eEnrollmentRoleType;

  constructor(private dialogRef: MatDialogRef<any>,
    private fb: FormBuilder,
    private financeService: ManagerFinancesService,
    private routerService: RouterService) {

  }

  ngOnInit() {
    this.discount$ = this.financeService.getDiscount({
      institutionId: this.routerService.institutionId,
      managerId: this.routerService.managerId,
      programId: this.routerService.programId,
      institutionDiscountId: this.routerService.institutionDiscountId
    });


    this.discount$.pipe(take(1)).subscribe(discount => {
      this.form = this.fb.group({
        status: [discount.status, Validators.required],
        description: [discount.description, Validators.required],
      });

    });
  }

  public close(coupon?: IInstitutionDiscountUpdateDto): void {
    this.dialogRef.close(coupon);
  }

  public save(): void {
    const { ...coupon } = this.form.value;
    this.close({ ...coupon });
  }

  public retainOrder() {
    return 0;
  }

}
