import { IAddressDto, IAliasDto, IProgramYouthDivisionDto } from '.';
import { IGatewayConfigurationDto } from '..';
import {
    eAppSettingsAlias,
    eEnrollmentStatus,
    eMemberProgramYearStatus,
    eMilitaryFamilyServiceType,
    ePageMessage,
    eProgramYouthDivisionType
} from '../enums';
import { eMemberProgramStatus } from '../enums';
import { IVolunteerScreeningDto } from './IIVolunteerScreeningDto';
import { IProgramPageMessageDto } from './IProgramPageMessageDto';

export interface IProgramSettingsDto {
    aliases?: { [alias in eAppSettingsAlias]: IAliasDto };
    enrollment_FeeEnable: boolean;
    enrollment_FamilyDiscount_On: boolean;
    enrollment_FamilyDiscount_CloverBud_On: boolean;
    enrollment_FamilyDiscount_AdultVolunteer_On: boolean;
    enrollment_FamilyDiscount_County_On: boolean;
    enrollment_MaxClubs?: number;
    enrollment_MaxProjects?: number;
    enrollment_StartDay?: number;
    enrollment_StartMonth?: number;
    enrollmentFee_LateFee_StartDay?: number;
    enrollmentFee_LateFee_StartMonth?: number;
    enrollment_PrePopulate_Clubs_And_Projects_For_ReEnrollment: boolean;
    enrollment_AllProjectsAvailableToClubs: boolean;
    enable_HealthForm_Youth: boolean;
    enable_HealthForm_Adults: boolean;
    gender_Identity_On: boolean;
    enable_CloverBud: boolean;
    enable_Districts: boolean;
    enable_Regions: boolean;
    enable_FamilyLogins: boolean;
    enable_ClubChartering: boolean;
    groupEnrollment_Modification_LastDay?: number;
    groupEnrollment_Modification_LastMonth?: number;
    member_Edit_ActivitiesAndGroups: boolean;
    member_RestrictClubSelectionToFamilyCounty: boolean;
    program_StartDay?: number;
    program_StartMonth?: number;
    programAge_Adult_Minimum?: number;
    programAge_CloverBud_Minimum?: number;
    programAge_Youth_Minimum?: number;
    programAge_Youth_Maximum?: number;
    programAge_Calculation_StartDay?: number;
    programAge_Calculation_StartMonth?: number;
    cloverBudBirthDateUpperLimit: string;
    youthBirthDateUpperLimit: string;
    youthBirthDateLowerLimit: string;
    adultBirthDateUpperLimit: string;
    permission_RestrictToInstitution_VolunteerScreening: boolean;
    permission_RestrictToSystemManager_ManagerManagement: boolean;
    volunteerScreeningConfigs: IVolunteerScreeningDto[];
    youthDivisions?: IProgramYouthDivisionDto[];
    enrollmentStatuses: { [status in eEnrollmentStatus]: string };
    memberProgramStatuses: { [status in eMemberProgramStatus]: string };
    memberProgramYearStatuses: { [status in eMemberProgramYearStatus]: string };
    militaryFamilyServiceTypes: { [status in eMilitaryFamilyServiceType]: string };

    enrollmentRoles?: object;

    gender_Birth_On: boolean;

    permission_RestrictToInstitution_ClubChartering: boolean;

    localization_SupportedLocalizations?: any[];

    enrollment_LinkProjectsToClubs: boolean;

    program_CountyBased: boolean;
    program_EnableProjects: boolean;
    program_EnableUnits: boolean;
    program_EnableUnitLeaders: boolean;
    countyCredits_On: boolean;
    memberCredits_On: boolean;

    permission_RestrictToInstitution_ClubManagement: boolean;
    program_EnableCountyProjects: boolean;
    memberCreditsOwedToInstitutionOnly_On: boolean;

    countyCredits_ReceivedByInstitutionForApproval: boolean;
    enrollment_ClubFeeEnable: boolean;

    enable_Participant: boolean;
    financial_On?: boolean;
    nonElectronicPaymentSubmissionDetails?: string;
    enrollment_PrePopulate_EnrollmentForm_From_PreviousYear: boolean;
    enrollment_Always_Show_Unit_And_Project_Selection: boolean;

    enrollment_Member_FeeWaiver_Request_On: boolean;

    pageMessages?: { [message in ePageMessage]: IProgramPageMessageDto };
    paymentGateway: IGatewayConfigurationDto;

    memberCreditsRequireTermsOfPayment: boolean;
    countyEvents_On: boolean;
    districtEvents_On: boolean;
    regionEvents_On: boolean;
    programEvents_On: boolean;
    county_SelectionOfPaymentMethods_On: boolean;
    county_Events_ElectronicPaymentMethods_On: boolean;

    billingAddress: IAddressDto;
    mailingAddress: IAddressDto;

    programName?: string;
    programDescription?: string;

    programAge_Manager_Youth_Maximum?: number;
    member_Show_Address: boolean;
    member_Show_Second_Family: boolean;
    member_Show_Second_EmergencyContact: boolean;
    systemEmails_SendToFamily_OnManagerAction: boolean;
    systemEmails_SendToManager: boolean;

    enrollment_Show_InstitutionProjects_In_Addition_To_CountyProjects: boolean;

    youthManagerBirthDateLowerLimit: string;
    youthSelectionMessage: string;
    volunteerSelectionMessage: string;

    programYouthDivisionType: eProgramYouthDivisionType;

    memberProjectEditEndDate: string;
    memberUnitEditEndDate: string;

    paymentGatewayEnabled: boolean;

    memberEditProjects: boolean;
    memberEditProjectsEndDay: number;
    memberEditProjectsEndMonth: number;
    memberEditUnits: boolean;
    memberEditUnitsEndDay: number;
    memberEditUnitsEndMonth: number;

    groupEnrollmentAllowYearChange: boolean;

    enrollmentYearStartDate: string;
    enrollmentYearEndDate: string;
    programYearStartDate: string;
    programYearEndDate: string;
    groupEnrollmentModificationEndDate: string;

    allowRollover: boolean;

    hasStripeAccount: boolean;

    stripeConfigurationError?: string;

    program_NonES237Only?: boolean;

    event_PaymentOptions_OverrideCountyOptions: boolean;
    memberShowExternalScreeningOption: boolean;

    electronicPayments_Enrollment_On: boolean;
    electronicPayments_Events_On: boolean;
    electronicPayments_Animals_On: boolean;

    stripePaymentGateway: IGatewayConfigurationDto;

    stripeAccounts_CountyHierarchy_On: boolean;
    stripeAccounts_DistrictHierarchy_On: boolean;

    genderAliases?: { [alias: string]: string };
    program_EnableAdults: boolean;
    program_EnableYouth: boolean;
    program_EnableAnimals: boolean;

    program_AdultBased: boolean;
    program_AllowMembersToJoin: boolean;

    program_CollectDemographics: boolean;

    program_EnableEnrollment: boolean;
    program_EnableEvents: boolean;
    program_EnableGroupEnrollment: boolean;
    program_EnableFinancialCountyBills: boolean;
    program_EnableFinancialCoupons: boolean;
    program_EnableFinancialDiscounts: boolean;
    program_EnableFinancialDonations: boolean;
    program_EnableMemberHealth: boolean;
    program_EnableMemberScreenings: boolean;
    program_EnableMemberTrainings: boolean;
    program_EnableParticipationActivities: boolean;
    program_EnableParticipationAwards: boolean;
    program_EnableParticipationGroups: boolean;

    enrollment_CreditCardFeeRecoveryEnable: boolean;
}
