import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import {
  IInstitutionManagerProgramHierarchyParams,
  IInstitutionManagerProgramParams,
  IInstitutionProgramParams,
  IInstitutionSystemManagerProgramParams,
} from 'app/core/models';
import { createSelector } from 'reselect';

import * as admin from './admin.reducer';
import * as bankAccounts from './shared/bank-accounts/bank-accounts.reducer';
import * as dynamicForm from './shared/dynamic-forms/dynamic-forms.reducer';
import * as adminScreenings from './shared/screenings/admin-screenings.reducer';

export const ADMIN_FEATURE = 'ADMIN_FEATURE';


export interface AdminFeatureStore {
    admin: admin.AdminState;
    dynamicForm: dynamicForm.DynamicFormState;
    bankAccounts: bankAccounts.BankAccountsState;

    adminScreenings: adminScreenings.AdminScreeningsState;
}

export const adminReducers: ActionReducerMap<AdminFeatureStore> = {
    admin: admin.adminReducers,
    dynamicForm: dynamicForm.dynamicFormsReducer,
    bankAccounts: bankAccounts.bankAccountsReducer,
    adminScreenings: adminScreenings.adminScreeningsReducer
};


export const getAdminFeatureStore = createFeatureSelector<AdminFeatureStore>(ADMIN_FEATURE);

export const getAdminState = createSelector(getAdminFeatureStore, (state: AdminFeatureStore) => state?.admin);
export const getManagerPermissionTypes = ({ }) => createSelector(
    createSelector(getAdminState, admin.managerPermissionTypes),
    permissionTypes => permissionTypes
);
export const getManagersSearchResult = createSelector(getAdminState, admin.managersSearchResult);


export const getManagers = ({ institutionId }) => createSelector(
    createSelector(getAdminState, admin.managers),
    managers => managers?.[institutionId]
);
export const getManager = ({ institutionId, managerId }) => createSelector(
    createSelector(getAdminState, admin.manager),
    manager => manager?.[institutionId]?.[managerId]
);
export const getHierarchySummary = ({ institutionId, hierarchyNodeId, programYearId }: { institutionId: string, hierarchyNodeId: string, programYearId: string }) => createSelector(
    createSelector(getAdminState, admin.hierarchySummary),
    hierarchySummary => hierarchySummary?.[institutionId]?.[hierarchyNodeId]?.[programYearId]
);
export const getDeletedManagers = ({ institutionId }: { institutionId: string }) => createSelector(
    createSelector(getAdminState, admin.deletedManagers),
    managers => managers?.[institutionId]
);

export const getDynamicFormState = createSelector(getAdminFeatureStore, (state: AdminFeatureStore) => state.dynamicForm);
export const getDynamicFormPresentState = createSelector(getAdminFeatureStore, (state: AdminFeatureStore) => state.dynamicForm);
export const getDynamicForm = createSelector(getDynamicFormPresentState, dynamicForm.form);
export const getGroupBeingEditedIndex = createSelector(getDynamicFormPresentState, dynamicForm.groupBeingEditedIndex);
export const getControlBeingEditedIndex = createSelector(getDynamicFormPresentState, dynamicForm.controlBeingEditedIndex);
export const getControlBeingEdited = createSelector(getDynamicFormPresentState, dynamicForm.controlBeingEdited);
export const getFormType = createSelector(getDynamicFormPresentState, dynamicForm.formType);

export const getBankAccountsState = createSelector(getAdminFeatureStore, (state: AdminFeatureStore) => state.bankAccounts);
export const getManagerBankAccounts = ({ institutionId, managerId, programId, hierarchyNodeId }: IInstitutionManagerProgramHierarchyParams) => createSelector(
    createSelector(getBankAccountsState, bankAccounts.managerBankAccounts),
    managerBankAccounts => managerBankAccounts?.[institutionId]?.[managerId]?.[programId]?.[hierarchyNodeId]
);
export const getManagerBankAccount = ({ institutionId, managerId, programId, bankAccountId }: IInstitutionManagerProgramParams & { bankAccountId: string }) => createSelector(
    createSelector(getBankAccountsState, bankAccounts.managerBankAccount),
    managerBankAccount => managerBankAccount?.[institutionId]?.[managerId]?.[programId]?.[bankAccountId]
);
export const getSystemManagerBankAccounts = ({ institutionId, systemManagerId, programId }: IInstitutionSystemManagerProgramParams) => createSelector(
    createSelector(getBankAccountsState, bankAccounts.systemManagerBankAccounts),
    systemManagerBankAccounts => systemManagerBankAccounts?.[institutionId]?.[systemManagerId]?.[programId]
);

export const getScreeningsState = createSelector(getAdminFeatureStore, (state: AdminFeatureStore) => state.adminScreenings);

export const getAdminScreenings = ({ institutionId, programId }: IInstitutionProgramParams) => createSelector(
    createSelector(getScreeningsState, adminScreenings.screenings),
    screenings => screenings?.[institutionId]?.[programId]
);

