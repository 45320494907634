import { Action, createReducer, on } from '@ngrx/store';
import { IMemberSearchResultsDto } from 'app/core/models/serverDTOs/IMemberSearchResultsDto';

import { EnrollmentSearchActions } from '.';

export interface EnrollmentSearchState {

    enrollmentSearchSearching: boolean;
    enrollmentsSearchResult: IMemberSearchResultsDto;
}

const initialState: EnrollmentSearchState = {
    enrollmentSearchSearching: false,
    enrollmentsSearchResult: undefined
};

const reducer = createReducer(
    initialState,
    on(EnrollmentSearchActions.GetEnrollmentSearchResultsAction, (state, payload) => {
        return {
            ...state,
            enrollmentSearchSearching: true,
        };
    }),
    on(EnrollmentSearchActions.GetEnrollmentSearchResultsSuccessAction, (state, payload) => {
        return {
            ...state,
            enrollmentSearchSearching: false,
            enrollmentsSearchResult: payload.enrollmentsSearchResult
        };
    }),
    on(EnrollmentSearchActions.GetEnrollmentSearchResultsErrorAction, (state, payload) => {
        return {
            ...state,
            enrollmentSearchSearching: false,
        };
    }), on(EnrollmentSearchActions.SearchEnrollmentsAction, (state, payload) => {
        return {
            ...state,
            enrollmentSearchSearching: true,
        };
    }),
    on(EnrollmentSearchActions.SearchEnrollmentsSuccessAction, (state, payload) => {
        return {
            ...state,
            enrollmentSearchSearching: false,
            enrollmentsSearchResult: payload.enrollmentsSearchResult
        };
    }),
    on(EnrollmentSearchActions.SearchEnrollmentsErrorAction, (state, payload) => {
        return {
            ...state,
            memberSearchSearching: false,
        };
    }),
    on(EnrollmentSearchActions.ClearEnrollmentSearchAction, (state, payload) => {
        return {
            ...state,
            enrollmentSearchSearching: true,
        };
    }),
    on(EnrollmentSearchActions.ClearEnrollmentSearchErrorAction, (state, payload) => {
        return {
            ...state,
            enrollmentSearchSearching: false,
        };
    })
);

export function enrollmentSearchReducer(state: EnrollmentSearchState | undefined, action: Action) {
    return reducer(state, action);
}

export const enrollmentsSearchResult = (state: EnrollmentSearchState) => state.enrollmentsSearchResult;
export const enrollmentSearchSearching = (state: EnrollmentSearchState) => state.enrollmentSearchSearching;