import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IMemberScreeningFormSaveDto, IMemberScreeningFormSubmitDto, IProfileVolunteerScreeningDto } from 'app/core/models';

export const MemberScreeningLoadAction = createAction(
    '[member] load screenings',
    props<{ institutionId: string, memberId: string, profileScreeningId: string }>()
);

export const MemberScreeningLoadSuccessAction = createAction(
    '[member] load screenings success',
    props<{ institutionId: string, memberId: string, profileScreeningId: string, screening: IProfileVolunteerScreeningDto }>()
);

export const MemberScreeningLoadErrorAction = createAction(
    '[member] load screenings error',
    props<{ institutionId: string, memberId: string, profileScreeningId: string, error: HttpErrorResponse }>()
);

export const MemberScreeningSaveAction = createAction(
    '[member] Save screenings',
    props<{ institutionId: string, memberId: string, profileScreeningId: string, screening: IMemberScreeningFormSubmitDto }>()
);

export const MemberScreeningSaveSuccessAction = createAction(
    '[member] Save screenings success',
    props<{ institutionId: string, memberId: string, profileScreeningId: string, screening: IMemberScreeningFormSubmitDto, result: IMemberScreeningFormSaveDto }>()
);

export const MemberScreeningSaveErrorAction = createAction(
    '[member] Save screenings error',
    props<{ error: HttpErrorResponse }>()
);

export const MemberScreeningSubmitAction = createAction(
    '[member] Submit screenings',
    props<{ institutionId: string, memberId: string, profileScreeningId: string, screening: IMemberScreeningFormSubmitDto }>()
);

export const MemberScreeningSubmitSuccessAction = createAction(
    '[member] Submit screenings success',
    props<{ institutionId: string, memberId: string, profileScreeningId: string, screening: IMemberScreeningFormSubmitDto }>()
);

export const MemberScreeningSubmitErrorAction = createAction(
    '[member] Submit screenings error',
    props<{ error: HttpErrorResponse }>()
);

export const MemberScreeningInvalidateCacheAction = createAction(
    '[member] Invalidate screenings cache'
);

export const MemberScreeningUpdateExternal = createAction(
    '[member] Update external',
    props<{ institutionId: string, memberId: string, profileScreeningId: string }>()
);

