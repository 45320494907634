import { Component, OnInit, Input } from '@angular/core';

import { IInputError } from 'app/core/models';

@Component({
    selector: 'ng4h-input-error',
    templateUrl: './input-error.component.html',
    styleUrls: ['./input-error.component.scss']
})
export class InputErrorComponent implements OnInit {

    @Input() error: IInputError;

    constructor() {
    }

    ngOnInit() {
    }
}
