import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
    IHierarchySummaryDto,
    IInstitutionManagerProgramHierarchyParams,
    IInstitutionManagerProgramParams,
    IInstitutionSystemManagerProgramParams,
    IManagerCreateDto,
    IManagerDescriptorDto,
    IManagerDto,
    IManagerInstitutionProfile,
    IManagerUpdateDto,
    IPagination,
    IUserAccountDto,
    IUserAccountPasswordDto,
} from 'app/core/models';
import { IPermissionType } from 'app/core/models/IPermissionType';
import {
    IManagerProgramHierarchyAssociationUpdateDto,
} from 'app/core/models/serverDTOs/IManagerProgramHierarchyAssociationUpdateDto';

// clear search
export const AdminClearManagersSearchAction = createAction(
  '[admin] Clear managers search'
);

export const AdminClearMembersSearchAction = createAction(
  '[admin] Clear members search',
  props<{ any }>()
);

export const AdminSearchManagersAction = createAction(
  '[admin] Search managers',
  props<{ institutionId: string, hierarchyNodeId: string }>()
);

export const AdminSearchManagersSuccessAction = createAction(
  '[admin] Search managers success',
  props<{ institutionId: string, hierarchyNodeId: string, managersSearchResult: IPagination<IManagerInstitutionProfile> }>()
);

export const AdminSearchManagersErrorAction = createAction(
  '[admin] Search managers error',
  props<{ error: HttpErrorResponse }>()
);



export const AdminGetManagersAction = createAction(
  '[admin] Get managers',
  props<({ institutionId: string })>()
);

export const AdminGetManagersSuccessAction = createAction(
  '[admin] Get managers success',
  props<({ institutionId: string, managers: IManagerInstitutionProfile[] })>()
);

export const AdminGetManagersErrorAction = createAction(
  '[admin] Get managers error',
  props<({ error: HttpErrorResponse })>()
);

export const AdminGetManagerAction = createAction(
  '[admin] Get manager',
  props<({ institutionId: string, managerId: string })>()
);

export const AdminGetManagerSuccessAction = createAction(
  '[admin] Get manager success',
  props<({ institutionId: string, managerId: string, manager: IManagerInstitutionProfile })>()
);

export const AdminGetManagerErrorAction = createAction(
  '[admin] Get manager error',
  props<({ error: HttpErrorResponse })>()
);

export const AdminCreateAsSystemManagerAction = createAction(
  '[admin] Create Manager as system manager',
  props<IInstitutionSystemManagerProgramParams & { newManager: IManagerCreateDto }>()
);

export const AdminCreateAsSystemManagerSuccessAction = createAction(
  '[admin] Create Manager as system manager success',
  props<IInstitutionSystemManagerProgramParams & { newManager: IManagerCreateDto }>()
);

export const AdminCreateAsSystemManagerErrorAction = createAction(
  '[admin] Create Manager as system manager error',
  props<IInstitutionSystemManagerProgramParams & { newManager: IManagerCreateDto, error: HttpErrorResponse }>()
);

export const AdminUpdateManagerAction = createAction(
  '[admin] Manager update',
  props<{ institutionId: string, managerId: string, hierarchyAdminId: string, managerUpdate: IManagerUpdateDto }>()
);

export const AdminUpdateManagerSuccessAction = createAction(
  '[admin] Manager update success',
  props<{ institutionId: string, managerId: string, hierarchyAdminId: string, managerUpdate: IManagerUpdateDto }>()
);

export const AdminUpdateManagerErrorAction = createAction(
  '[admin] Manager update error',
  props<{ error: HttpErrorResponse }>()
);

export const AdminUpdateManagerAsSystemManagerAction = createAction(
  '[admin] Manager update as system manager',
  props<IInstitutionSystemManagerProgramParams & { managerId: string, managerUpdate: IManagerUpdateDto }>()
);

export const AdminUpdateManagerAsSystemManagerSuccessAction = createAction(
  '[admin] Manager update as system manager success',
  props<IInstitutionSystemManagerProgramParams & { managerId: string, managerUpdate: IManagerUpdateDto }>()
);

export const AdminUpdateManagerAsSystemManagerErrorAction = createAction(
  '[admin] Manager update as system manager error',
  props<{ error: HttpErrorResponse }>()
);

export const AdminGetManagerPermissionTypesAction = createAction(
  '[admin] Get manager permission types'
);

export const AdminGetManagerPermissionTypesSuccessAction = createAction(
  '[admin] Get manager permission types success',
  props<{ types: IPermissionType[] }>()
);

export const AdminGetManagerPermissionTypesErrorAction = createAction(
  '[admin] Get manager permission types error',
  props<{ error: HttpErrorResponse }>()
);

export const AdminSearchManagersByEmailAction = createAction(
  '[admin] Search managers by email',
  props<{ institutionId: string, email: string }>()
);

export const AdminSearchManagersByEmailSuccessAction = createAction(
  '[admin] Search managers by email success',
  props<{ institutionId: string, email: string, managers: IManagerDto[] }>()
);

export const AdminSearchManagersByEmailErrorAction = createAction(
  '[admin] Search managers by email error',
  props<{ error: HttpErrorResponse }>()
);

export const AdminUpdateManagerPermissionsAsSystemManagerAction = createAction(
  '[admin] Search system manager',
  props<IInstitutionSystemManagerProgramParams & { hierarchyNodeId: string, managerId: string, update: IManagerProgramHierarchyAssociationUpdateDto }>()
);

export const AdminUpdateManagerPermissionsAsSystemManagerSuccessAction = createAction(
  '[admin] Search system manager success',
  props<IInstitutionSystemManagerProgramParams & { hierarchyNodeId: string, managerId: string, update: IManagerProgramHierarchyAssociationUpdateDto }>()
);

export const AdminUpdateManagerPermissionsAsSystemManagerErrorAction = createAction(
  '[admin] Search system manager error',
  props<{ error: HttpErrorResponse }>()
);

export const AdminCanAddManagerAction = createAction(
  '[admin] Can add manager',
  props<{ email: string }>()
);

export const AdminCanAddManagerSuccessAction = createAction(
  '[admin] Can add manager success',
  props<{ email: string, user: IUserAccountDto }>()
);

export const AdminCanAddManagerErrorAction = createAction(
  '[admin] Can add manager error',
  props<{ error: HttpErrorResponse }>()
);

export const AdminRemoveManagerFromHierarchyAction = createAction(
  '[admin] Remove manager from hierarchy',
  props<IInstitutionSystemManagerProgramParams & { managerId: string, hierarchyNodeId: string }>()
);

export const AdminRemoveManagerFromHierarchySuccessAction = createAction(
  '[admin] Remove manager from hierarchy success',
  props<IInstitutionSystemManagerProgramParams & { managerId: string, hierarchyNodeId: string }>()
);

export const AdminRemoveManagerFromHierarchyErrorAction = createAction(
  '[admin] Remove manager from hierarchy error',
  props<{ error: HttpErrorResponse }>()
);

export const AdminInvalidateManagersCacheAction = createAction(
  '[admin] Invalidate managers cache'
);




export const AdminResendEmailAction = createAction(
  '[admin] resend welcome email',
  props<IInstitutionSystemManagerProgramParams & { managerId: string }>()
);

export const AdminResendEmailSuccessAction = createAction(
  '[admin] resend welcome email success',
  props<IInstitutionSystemManagerProgramParams & { managerId: string }>()
);

export const AdminResendEmailErrorAction = createAction(
  '[admin] resend welcome email error',
  props<{ error: HttpErrorResponse }>()
);


export const AdminResetPasswordAction = createAction(
  '[admin] reset manager password',
  props<IInstitutionSystemManagerProgramParams & { managerId: string }>()
);

export const AdminResetPasswordSuccessAction = createAction(
  '[admin] reset manager password success',
  props<IInstitutionSystemManagerProgramParams & { managerId: string, newPasswordDto: IUserAccountPasswordDto }>()
);

export const AdminResetPasswordErrorAction = createAction(
  '[admin] reset manager password error',
  props<{ error: HttpErrorResponse }>()
);

export const AdminRemoveManagerAction = createAction(
  '[admin] remove manager',
  props<IInstitutionSystemManagerProgramParams & { managerId: string }>()
);

export const AdminRemoveManagerSuccessAction = createAction(
  '[admin] remove manager success',
  props<IInstitutionSystemManagerProgramParams & { managerId: string }>()
);

export const AdminRemoveManagerErrorAction = createAction(
  '[admin] remove manager error',
  props<{ error: HttpErrorResponse }>()
);




export const AdminRemoveManagerHierarchyAssociationAction = createAction(
  '[admin] remove manager hierarchy association',
  props<IInstitutionManagerProgramHierarchyParams & { hierarchyAdminId: string }>()
);

export const AdminRemoveManagerHierarchyAssociationSuccessAction = createAction(
  '[admin] remove manager hierarchy association success',
  props<IInstitutionManagerProgramHierarchyParams & { hierarchyAdminId: string }>()
);

export const AdminRemoveManagerHierarchyAssociationErrorAction = createAction(
  '[admin] remove manager hierarchy association error',
  props<{ error: HttpErrorResponse }>()
);

export const AdminRemoveManagerPaymentAction = createAction(
  '[admin] remove manager payment',
  props<IInstitutionManagerProgramParams & { paymentMethodId: string }>()
);

export const AdminRemoveManagerPaymentSuccessAction = createAction(
  '[admin] remove manager payment success',
  props<IInstitutionManagerProgramParams & { paymentMethodId: string }>()
);

export const AdminRemoveManagerPaymentErrorAction = createAction(
  '[admin] remove manager payment error',
  props<{ error: HttpErrorResponse }>()
);


export const GetHierarchySummaryAction = createAction(
  '[admin] Get hierarchy summary',
  props<{ institutionId: string, programId: string, hierarchyNodeId: string, programYearId: string }>()
);
export const GetHierarchySummarySuccessAction = createAction(
  '[admin] Get hierarchy summary success',
  props<{ institutionId: string, programId: string, hierarchyNodeId: string, programYearId: string, summary: IHierarchySummaryDto }>()
);
export const GetHierarchySummaryErrorAction = createAction(
  '[admin] Get hierarchy summary error',
  props<{ error: HttpErrorResponse }>()
);

export const GetDeletedManagersAction = createAction(
  '[admin] Get deleted managers action',
  props<{ institutionId: string }>()
);
export const GetDeletedManagersSuccessAction = createAction(
  '[admin] Get deleted managers action success',
  props<{ institutionId: string, managers: IManagerDescriptorDto[] }>()
);
export const GetDeletedManagersErrorAction = createAction(
  '[admin] Get deleted managers action error',
  props<{ error: HttpErrorResponse }>()
);
export const ReinstateManagerAction = createAction(
  '[admin] Reinstate manager action',
  props<IInstitutionSystemManagerProgramParams & { managerId: string }>()
);
export const ReinstateManagerSuccessAction = createAction(
  '[admin] Reinstate manager action success',
  props<IInstitutionSystemManagerProgramParams & { managerId: string }>()
);
export const ReinstateManagerErrorAction = createAction(
  '[admin] Reinstate manager action error',
  props<{ error: HttpErrorResponse }>()
);
