import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducers';
import { SidebarActions } from 'app/shared/sidebar';
import { fromEvent, Observable, throttleTime } from 'rxjs';

import { RouterService } from './router.service';

@Injectable({
  providedIn: 'root'
})
export class WindowService {

  private windowResize$: Observable<any>;

  constructor(private store: Store<AppState>, private routerService: RouterService) {
    // setup observable so we can debounce it
    this.windowResize$ = fromEvent(window, 'resize');

    this.windowResize$.pipe(throttleTime(100)).subscribe(event => {
      this.windowResized(event.target.innerWidth);
    });
  }

  init() {
    this.store.dispatch(SidebarActions.WindowWidthInitAction({ width: window.innerWidth }));
  }

  windowResized(width: number) {
    if (this.routerService.loggedInInstitutionProfileId != null || this.routerService.superuserId != null) {
      this.store.dispatch(SidebarActions.WindowWidthChangedAction({ width: width }));
    } else {
      this.init();
    }
  }

  public openSidebarsIfDesktop() {
    this.store.dispatch(SidebarActions.OpenSidebarIfDesktopAction());
  }
}
