import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IDiscountFundDescriptorDto } from 'app/core/models';

@Component({
  selector: 'ng4h-modal-edit-fund',
  templateUrl: './modal-edit-fund.component.html',
  styleUrls: ['./modal-edit-fund.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalEditFundComponent implements OnInit {

  public form: FormGroup;

  constructor(private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) private data: { fund: IDiscountFundDescriptorDto },
    private fb: FormBuilder) { }

  ngOnInit() {
    const fund = this.data.fund;

    this.form = this.fb.group({
      fundCode: [fund.fundCode],
      description: [fund.description],
    });
  }

  public close() {
    this.dialogRef.close();
  }

  public save() {
    this.dialogRef.close(this.form.value);
  }
}
