import { Action, createReducer, on } from '@ngrx/store';
import { IActivityDto, IInstitutionManagerProgramModel } from 'app/core/models';
import { mergeImmutable } from 'app/shared/utils';

import { ManagerActivitiesActions } from '.';

export interface ManagerActivitiesState {
    activities: IInstitutionManagerProgramModel<{ [hierarchyNodeId: string]: IActivityDto[] }>;
    activity: IInstitutionManagerProgramModel<{ [activityId: string]: IActivityDto }>;
}

const initialState: ManagerActivitiesState = {
    activities: undefined,
    activity: undefined
};

const reducer = createReducer(
    initialState,
    on(ManagerActivitiesActions.LoadActivitiesSuccessAction, (state, { institutionId, managerId, programId, hierarchyNodeId, activities }) => {
        const newActivities = mergeImmutable(
            { [institutionId]: { [managerId]: { [programId]: { [hierarchyNodeId]: activities } } } },
            state.activities
        );
        return {
            ...state,
            activities: newActivities
        };
    }),
    on(ManagerActivitiesActions.LoadActivitySuccessAction, (state, { institutionId, managerId, programId, activityId, activity }) => {
        const newActivity = mergeImmutable(
            { [institutionId]: { [managerId]: { [programId]: { [activityId]: activity } } } },
            state.activity
        );
        return {
            ...state,
            activity: newActivity
        };
    }),
    on(ManagerActivitiesActions.CreateActivitySuccessAction,
        ManagerActivitiesActions.UpdateActivitySuccessAction,
        ManagerActivitiesActions.DeleteActivitySuccessAction, (state, { }) => {
            return initialState;
        })
);

export function managerActivitiesReducer(state: ManagerActivitiesState | undefined, action: Action) {
    return reducer(state, action);
}

export const activities = (state: ManagerActivitiesState) => state.activities;
export const activity = (state: ManagerActivitiesState) => state.activity;

