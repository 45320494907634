import { IInstitutionProgramTrainingDto, IMemberProgramConsentDto, IVolunteerTypeDto } from '.';
import { eVolunteerType } from '..';

export interface IInstitutionVolunteerDto {
    volunteerTypeId: string;
    volunteerTypeDescriptor: IVolunteerTypeDto;
    volunteerType: eVolunteerType;
    consentTypeIds?: string[];
    consents?: IMemberProgramConsentDto[];
    screeningId: string;
    volunteerTypeLabel?: string;
    volunteerTypeDescription?: string;
    trainings: IInstitutionProgramTrainingDto[];
}
