import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { eEventStatus, IEventDto } from 'app/core/models';
import { CommonToastrService, EventsManagerService, RouterService } from 'app/core/services';

@Component({
  selector: 'ng4h-modal-change-event-status',
  templateUrl: './modal-change-event-status.component.html',
  styleUrls: ['./modal-change-event-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalChangeEventStatusComponent implements OnInit {

  public eEventStatus = eEventStatus;

  public oldStatus = null;
  public newStatus = null;

  constructor(
    private dialogRef: MatDialogRef<ModalChangeEventStatusComponent>,
    @Inject(MAT_DIALOG_DATA) public event: IEventDto, private routerService: RouterService,
    private eventsManagerService: EventsManagerService, private toastrService: CommonToastrService) {
    this.oldStatus = event.eventStatus;
    this.newStatus = event.eventStatus;
  }

  ngOnInit() {
  }

  public close() {
    this.dialogRef.close();
  }

  public save() {
    this.eventsManagerService.updateEventStatus({
      institutionId: this.routerService.institutionId,
      managerId: this.routerService.managerId,
      programId: this.routerService.programId,
      eventId: this.routerService.eventId,
      eventStatusUpdateDto: {
        status: this.newStatus
      }
    }).subscribe({
      next: () => {
        this.toastrService.success('update event success');
        this.close();
      }, error: (error) => {
        this.toastrService.error('event update failed');
      }
    });
  }
}
