import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IProgramYearDto } from 'app/core/models';
import { ProgramYearService, RouterService } from 'app/core/services';
import { combineLatest, filter, map, Observable } from 'rxjs';

@Component({
  selector: 'ng4h-modal-change-program-year',
  templateUrl: './modal-change-program-year.component.html',
  styleUrls: ['./modal-change-program-year.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalChangeProgramYearComponent implements OnInit {

  public programYears$: Observable<IProgramYearDto[]>;
  private availableProgramYears$: Observable<IProgramYearDto[]>;
  public currentProgramYear$: Observable<IProgramYearDto>;

  constructor(private routerService: RouterService, private dialogRef: MatDialogRef<any>, private programYearService: ProgramYearService) {

    this.availableProgramYears$ = this.programYearService.getAvailableProgramYears({
      institutionId: this.routerService.institutionId,
      programId: this.routerService.programId
    });

    this.currentProgramYear$ = this.programYearService.getCurrentProgramYear({
      institutionId: this.routerService.institutionId,
      programId: this.routerService.programId
    });

    this.programYears$ = combineLatest([
      this.availableProgramYears$,
      this.currentProgramYear$
    ]).pipe(
      filter(combined => combined.every(c => c != null)),
      map(([availableProgramYears, currentProgramYear]) => {
        return availableProgramYears.filter(availableProgramYear => availableProgramYear.programYear >= currentProgramYear.programYear);
      })
    );
  }

  ngOnInit() {
  }

  public close() {
    this.dialogRef.close();
  }

  public programYearSelected(programYear: IProgramYearDto) {
    this.programYearService.setUserProgramYear({
      institutionId: this.routerService.institutionId,
      programId: this.routerService.programId,
      institutionProfileId: this.routerService.loggedInInstitutionProfileId,
      programYear
    });
    this.close();
  }
}
