import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ManagerScreeningsActions } from 'app/core/containers/admin/manager/store/screenings';
import { FamilyActions } from 'app/core/containers/family';
import { MemberActions } from 'app/core/containers/member';
import { MemberScreeningsService } from 'app/core/services';
import { catchError, exhaustMap, groupBy, map, mergeMap, Observable, of } from 'rxjs';

import { MemberScreeningActions } from '.';
import { stringKeys } from '../rxjs.utils';



@Injectable()
export class MemberScreeningsEffects {

    public loadMemberScreenings$: Observable<Action> = createEffect(() => this.actions
        .pipe(
            ofType(MemberScreeningActions.MemberScreeningLoadAction),
            groupBy(stringKeys),
            mergeMap(groupedBy => {
                return groupedBy.pipe(
                    exhaustMap(payload => {
                        return this.memberScreeningsService.loadScreeningsEffect(payload).pipe(
                            map((screening) => MemberScreeningActions.MemberScreeningLoadSuccessAction({ ...payload, screening })),
                            catchError(error => of(MemberScreeningActions.MemberScreeningLoadErrorAction({ ...payload, error })))
                        );
                    })
                );
            })
        ));
    public saveMemberScreenings$: Observable<Action> = createEffect(() => this.actions
        .pipe(
            ofType(MemberScreeningActions.MemberScreeningSaveAction),
            groupBy(stringKeys),
            mergeMap(groupedBy => {
                return groupedBy.pipe(
                    exhaustMap(payload => {
                        return this.memberScreeningsService.saveScreeningsEffect(payload).pipe(
                            map((result) => MemberScreeningActions.MemberScreeningSaveSuccessAction({ ...payload, result })),
                            catchError(error => of(MemberScreeningActions.MemberScreeningSaveErrorAction({ error })))
                        );
                    })
                );
            })
        ));

    public submitMemberScreenings$: Observable<Action> = createEffect(() => this.actions
        .pipe(
            ofType(MemberScreeningActions.MemberScreeningSubmitAction),
            groupBy(stringKeys),
            mergeMap(groupedBy => {
                return groupedBy.pipe(
                    exhaustMap(payload => {
                        return this.memberScreeningsService.submitScreeningsEffect(payload).pipe(
                            map(() => MemberScreeningActions.MemberScreeningSubmitSuccessAction({ ...payload })),
                            catchError(error => of(MemberScreeningActions.MemberScreeningSubmitErrorAction({ error })))
                        );
                    })
                );
            })
        ));

    public submitMemberScreeningSuccess$: Observable<Action> = createEffect(() => this.actions
        .pipe(
            ofType(ManagerScreeningsActions.ManagerScreeningsChangeScreeningLevelSuccessAction,
                MemberScreeningActions.MemberScreeningSubmitSuccessAction,
                MemberScreeningActions.MemberScreeningSaveSuccessAction,
                MemberScreeningActions.MemberScreeningUpdateExternal,
                ManagerScreeningsActions.SendBackScreeningSuccessAction,
                ManagerScreeningsActions.DeleteScreeningSuccessAction
            ),
            mergeMap(() => [
                MemberScreeningActions.MemberScreeningInvalidateCacheAction(),
                FamilyActions.FamilyInvalidateCacheAction(),
                MemberActions.MemberInvalidateMemberCache()
            ])
        ));

    constructor(private actions: Actions, private memberScreeningsService: MemberScreeningsService) { }
}
