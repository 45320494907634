import { Action, createReducer, on } from '@ngrx/store';
import { IAnimalFormDto, IAnimalTypeDto, IInstitutionSystemManagerProgramModel } from 'app/core/models';
import { mergeImmutable } from 'app/shared/utils';

import { SystemManagerAnimalsActions } from '.';

export interface SystemManagerAnimalsState {
    animalTypes: IInstitutionSystemManagerProgramModel<IAnimalTypeDto[]>;
    animalType: IInstitutionSystemManagerProgramModel<{ [animalTypeId: string]: IAnimalTypeDto }>;
    animalForm: IInstitutionSystemManagerProgramModel<{ [animalTypeId: string]: IAnimalFormDto }>;
    animalFileControlTypes: IInstitutionSystemManagerProgramModel<any>;
    animalBreeds: any;
}

const initialState: SystemManagerAnimalsState = {
    animalTypes: undefined,
    animalType: undefined,
    animalForm: undefined,
    animalFileControlTypes: undefined,
    animalBreeds: undefined
};

const reducer = createReducer(
    initialState,
    on(SystemManagerAnimalsActions.SystemManagerAnimalsGetTypesSuccessAction, (state, { institutionId, systemManagerId, programId, animalTypes }) => {
        const newAnimalTypes = mergeImmutable(
            { [institutionId]: { [systemManagerId]: { [programId]: animalTypes } } },
            state.animalTypes
        );
        return {
            ...state,
            animalTypes: newAnimalTypes
        };
    }),
    on(SystemManagerAnimalsActions.SystemManagerAnimalsGetTypeSuccessAction, (state, { institutionId, systemManagerId, programId, animalTypeId, animalType }) => {
        const newAnimalType = mergeImmutable(
            { [institutionId]: { [systemManagerId]: { [programId]: { [animalTypeId]: animalType } } } },
            state.animalType
        );
        return {
            ...state,
            animalType: newAnimalType
        };
    }),
    on(SystemManagerAnimalsActions.SystemManagerAnimalsGetFormSuccessAction, (state, { institutionId, systemManagerId, programId, animalTypeId, animalForm }) => {
        const newAnimalForm = mergeImmutable(
            { [institutionId]: { [systemManagerId]: { [programId]: { [animalTypeId]: animalForm } } } },
            state.animalForm
        );
        return {
            ...state,
            animalForm: newAnimalForm
        };
    }),
    on(SystemManagerAnimalsActions.SystemManagerAnimalsGetFileControlsSuccessAction,
        (state, { institutionId, systemManagerId, programId, fileControls }) => {
            const animalFileControlTypes = mergeImmutable(
                { [institutionId]: { [systemManagerId]: { [programId]: fileControls } } },
                state.animalFileControlTypes
            );
            return {
                ...state,
                animalFileControlTypes: animalFileControlTypes
            };
        }),
    on(SystemManagerAnimalsActions.SystemManagerAnimalsGetBreedsSuccessAction, (state, { institutionId, programId, animalTypeId, breeds }) => {
        const newAnimalBreeds = mergeImmutable(
            { [institutionId]: { [programId]: { [animalTypeId]: breeds } } },
            state.animalBreeds
        );
        return {
            ...state,
            animalBreeds: newAnimalBreeds
        };
    }),

    on(SystemManagerAnimalsActions.SystemManagerAnimalsCreateTypeSuccessAction,
        SystemManagerAnimalsActions.SystemManagerAnimalsSaveFormSuccessAction,
        SystemManagerAnimalsActions.SystemManagerAnimalsUpdateTypeSuccessAction,
        SystemManagerAnimalsActions.SystemManagerAnimalsAddFileControlSuccessAction,
        SystemManagerAnimalsActions.SystemManagerAnimalsDeleteFileControlSuccessAction,
        SystemManagerAnimalsActions.SystemManagerAnimalsUpdateFileControlSuccessAction,
        SystemManagerAnimalsActions.SystemManagerAnimalsUpdateBreedsSuccessAction,
        SystemManagerAnimalsActions.SystemManagerAnimalsDeleteBreedsSuccessAction,
        SystemManagerAnimalsActions.SystemManagerAnimalsDeleteTypeSuccessAction,
        SystemManagerAnimalsActions.SystemManagerAnimalsSaveTemplateSuccessAction,
        SystemManagerAnimalsActions.SystemManagerAnimalsDeleteTemplateSuccessAction,
        SystemManagerAnimalsActions.SystemManagerAnimalsUpdateFeesSuccessAction,
        SystemManagerAnimalsActions.SystemManagerAnimalsDeleteFeesSuccessAction,
        (state, { }) => {
            return initialState;
        })
);


export function systemManagerAnimalTypesReducer(state: SystemManagerAnimalsState | undefined, action: Action) {
    return reducer(state, action);
}

export const animalTypes = (state: SystemManagerAnimalsState) => state.animalTypes;
export const animalBreeds = (state: SystemManagerAnimalsState) => state.animalBreeds;
export const animalType = (state: SystemManagerAnimalsState) => state.animalType;

export const animalForm = (state: SystemManagerAnimalsState) => state.animalForm;
export const animalFileControlTypes = (state: SystemManagerAnimalsState) => state.animalFileControlTypes;