import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import {
  eLocalizationType,
  IConsentAddLocalizationDto,
  IConsentCreateDto,
  IConsentTypeDto,
  IConsentTypeUpdateDto,
  IConsentVersionCreateDto,
  IConsentVersionUpdateDto,
  IInstitutionProgramParams,
  IInstitutionSystemManagerProgramParams
} from 'app/core/models';
import { ConsentsActions } from 'app/shared/consents';
import { filter, map, Observable, of, take, tap } from 'rxjs';

import { AppState, getConsents } from '../../app.reducers';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConsentsService {
  constructor(private store: Store<AppState>, private httpClient: HttpClient, private dispatcher: ActionsSubject) {

  }

  public createConsent(params: IInstitutionSystemManagerProgramParams & { consent: IConsentCreateDto }) {
    this.store.dispatch(ConsentsActions.ConsentsCreateAction(params));

    return this.dispatcher.pipe(
      ofType(ConsentsActions.ConsentsCreateSuccessAction, ConsentsActions.ConsentsCreateErrorAction),
      take(1),
      map(action => {
        if (action.type === ConsentsActions.ConsentsCreateSuccessAction.type) {
          return action.consent;
        } else {
          throw action.error;
        }
      })
    );
  }

  public createConsentEffect({
    institutionId,
    systemManagerId,
    programId,
    consent
  }: IInstitutionSystemManagerProgramParams & { consent: IConsentCreateDto }) {
    return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/consent-types`, consent);
  }

  public updateConsentType(params: IInstitutionSystemManagerProgramParams & { consentTypeId: string, consent: IConsentTypeUpdateDto }) {
    this.store.dispatch(ConsentsActions.UpdateConsentTypeAction(params));

    return this.dispatcher.pipe(
      ofType(ConsentsActions.UpdateConsentTypeSuccessAction, ConsentsActions.UpdateConsentTypeErrorAction),
      take(1),
      map(action => {
        if (action.type === ConsentsActions.UpdateConsentTypeSuccessAction.type) {
          return action.consent;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateConsentTypeEffect({
    institutionId,
    systemManagerId,
    programId,
    consentTypeId,
    consent
  }: IInstitutionSystemManagerProgramParams & { consentTypeId: string, consent: IConsentTypeUpdateDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/consent-types/${consentTypeId}`, consent);
  }

  public deleteConsentType(params: IInstitutionSystemManagerProgramParams & { consentTypeId: string }) {
    this.store.dispatch(ConsentsActions.DeleteConsentTypeAction(params));

    return this.dispatcher.pipe(
      ofType(ConsentsActions.DeleteConsentTypeSuccessAction, ConsentsActions.DeleteConsentTypeErrorAction),
      take(1),
      map(action => {
        if (action.type === ConsentsActions.DeleteConsentTypeSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteConsentTypeEffect({
    institutionId,
    systemManagerId,
    programId,
    consentTypeId
  }: IInstitutionSystemManagerProgramParams & { consentTypeId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/consent-types/${consentTypeId}`);
  }

  public updateConsentVersion(params: IInstitutionSystemManagerProgramParams & { consentVersionId: string, consent: IConsentVersionUpdateDto }) {
    this.store.dispatch(ConsentsActions.UpdateConsentVersionAction(params));

    return this.dispatcher.pipe(
      ofType(ConsentsActions.UpdateConsentVersionSuccessAction, ConsentsActions.UpdateConsentVersionErrorAction),
      take(1),
      map(action => {
        if (action.type === ConsentsActions.UpdateConsentVersionSuccessAction.type) {
          return action.consent;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateConsentVersionEffect({
    institutionId,
    systemManagerId,
    programId,
    consentVersionId,
    consent
  }: IInstitutionSystemManagerProgramParams & { consentVersionId: string, consent: IConsentVersionUpdateDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/consent-versions/${consentVersionId}`, consent);
  }

  public createConsentVersion(params: IInstitutionSystemManagerProgramParams & { consentTypeId: string, consent: IConsentVersionCreateDto }) {
    this.store.dispatch(ConsentsActions.CreateConsentVersionAction(params));

    return this.dispatcher.pipe(
      ofType(ConsentsActions.CreateConsentVersionSuccessAction, ConsentsActions.CreateConsentVersionErrorAction),
      take(1),
      map(action => {
        if (action.type === ConsentsActions.CreateConsentVersionSuccessAction.type) {
          return action.consent;
        } else {
          throw action.error;
        }
      })
    );
  }

  public createConsentVersionEffect({
    institutionId,
    systemManagerId,
    programId,
    consentTypeId,
    consent
  }: IInstitutionSystemManagerProgramParams & { consentTypeId: string, consent: IConsentVersionCreateDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/consent-types/${consentTypeId}/versions`, consent);
  }

  // public deleteEnrollmentConsent(params: IInstitutionSystemManagerProgramParams & { consentTypeId: string }) {
  //   this.store.dispatch(ConsentsActions.ConsentsDeleteEnrollmentAction(params));

  //   return this.dispatcher.pipe(
  //     ofType(ConsentsActions.ConsentsDeleteEnrollmentSuccessAction, ConsentsActions.ConsentsDeleteEnrollmentErrorAction),
  //     take(1),
  //     map(action => {
  //       if (action.type === ConsentsActions.ConsentsDeleteEnrollmentSuccessAction.type) {
  //         return action.consentTypeId;
  //       } else {
  //         throw action.error;
  //       }
  //     })
  //   );
  // }

  // public deleteEnrollmentConsentEffect({ institutionId, systemManagerId, programId, consentTypeId }: IInstitutionSystemManagerProgramParams & { consentTypeId: string }) {
  //   return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/consents/${consentTypeId}`);
  // }

  public getConsents(params: IInstitutionProgramParams): Observable<IConsentTypeDto[]> {

    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getConsents(params)),
      tap(consents => {
        if (consents === undefined) {
          this.store.dispatch(ConsentsActions.ConsentsGetConsentAction(params));
        }
      }),
      filter(consents => consents != null)
    );

  }

  getNonSystemConsents(params: IInstitutionProgramParams): Observable<IConsentTypeDto[]> {
    return this.getConsents(params).pipe(
      map(consents => {
        return consents.filter(consent => consent.system === false);
      })
    );
  }

  public loadConsentsEffect({ institutionId, programId }: IInstitutionProgramParams) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/programs/${programId}/consent-types`) as Observable<IConsentTypeDto[]>;
  }

  public getConsentByTypeId(params: IInstitutionProgramParams & { typeId: string }) {
    return this.getConsents(params).pipe(
      filter(consents => consents != null),
      map(consents => consents.find(element => element.consentTypeId === params.typeId))
    );
  }

  public addLocalization(params: IInstitutionSystemManagerProgramParams & { consentVersionId: string, localization: eLocalizationType, consent: IConsentAddLocalizationDto }) {
    this.store.dispatch(ConsentsActions.ConsentsAddLocalizationAction(params));
  }

  public addLocalizationEffect({
    institutionId,
    systemManagerId,
    programId,
    consentVersionId,
    localization,
    consent
  }: IInstitutionSystemManagerProgramParams & { consentVersionId: string, localization: eLocalizationType, consent: IConsentAddLocalizationDto }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/consents/${consentVersionId}/${localization}`, consent);
  }

  public deleteConsentVersion(params: IInstitutionSystemManagerProgramParams & { consentVersionId: string }) {
    this.store.dispatch(ConsentsActions.ConsentsDeleteVersionAction(params));

    return this.dispatcher.pipe(
      ofType(ConsentsActions.ConsentsDeleteVersionSuccessAction, ConsentsActions.ConsentsDeleteVersionErrorAction),
      take(1),
      map(action => {
        if (action.type === ConsentsActions.ConsentsDeleteVersionSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteConsentVersionEffect({
    institutionId,
    systemManagerId,
    programId,
    consentVersionId
  }: IInstitutionSystemManagerProgramParams & { consentVersionId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/consent-versions/${consentVersionId}`);
  }
}
