<div class="searchForm">
  <form [formGroup]="searchForm">
    <div class="input-group">
      <input formControlName="searchText" class="form-control hierarchy-search-input" type="text"
        placeholder="Search the Hierarchy...">
    </div>
  </form>
</div>
<ul class="HierarchyList-Column-Container">
  <ng-container *ngIf="selectedInstitutionAreas$ | async as selectedInstitutionAreas">
    <li class="HierarchyList-Column">
      <div class="panel panel-default">
        <div class="panel-heading">
          <div class="panel-title">
            INSTITUTION
          </div>
        </div>
        <div class="panel-body">
          <ul class="HierarchyList">
            <ng-container *ngFor="let institutionArea of selectedInstitutionAreas">
              <ng-container
                *ngIf="selectedInstitutionArea$ | async as selectedInstitutionArea; else noSelectedInstitution">
                <li (click)="nodeChanged(institutionArea)"
                  [ngClass]="{'selected': institutionArea.hierarchyNodeId === selectedInstitutionArea.hierarchyNodeId}">
                  <div
                    [innerHTML]="institutionArea.hierarchyNodeName | highlight:(searchForm.controls['searchText'].valueChanges | async)">
                  </div>
                </li>
              </ng-container>
              <ng-template #noSelectedInstitution>
                <li (click)="nodeChanged(institutionArea)">
                  <div
                    [innerHTML]="institutionArea.hierarchyNodeName | highlight:(searchForm.controls['searchText'].valueChanges | async)">
                  </div>
                </li>
              </ng-template>
            </ng-container>
          </ul>
        </div>
      </div>
    </li>
  </ng-container>
  <ng-container *ngIf="selectedRegionAreas$ | async as selectedRegionAreas">
    <li class="HierarchyList-Column">
      <div class="panel panel-default">
        <div class="panel-heading">
          <div class="panel-title">
            {{(eAppSettingsAlias.RegionArea | programAlias$ | async)?.singular}}
          </div>
        </div>
        <div class="panel-body">
          <ul class="HierarchyList">
            <ng-container *ngFor="let regionArea of selectedRegionAreas | orderBy: 'hierarchyNodeName'">
              <ng-container
                *ngIf="regionArea.parentHierarchyNodeId == (selectedInstitutionArea$ | async)?.hierarchyNodeId || (searchForm.controls['searchText'].valueChanges | async)?.length > 0">
                <ng-container *ngIf="selectedRegionArea$ | async as selectedRegionArea; else noSelectedRegionArea">
                  <li (click)="nodeChanged(regionArea)"
                    [ngClass]="{'selected': regionArea.hierarchyNodeId === selectedRegionArea.hierarchyNodeId}">
                    <div
                      [innerHTML]="regionArea.hierarchyNodeName | highlight:(searchForm.controls['searchText'].valueChanges | async)">
                    </div>
                  </li>
                </ng-container>
                <ng-template #noSelectedRegionArea>
                  <li (click)="nodeChanged(regionArea)">
                    <div
                      [innerHTML]="regionArea.hierarchyNodeName | highlight:(searchForm.controls['searchText'].valueChanges | async)">
                    </div>
                  </li>
                </ng-template>
              </ng-container>
            </ng-container>
          </ul>
        </div>
      </div>
    </li>
  </ng-container>
  <ng-container *ngIf="selectedDistrictAreas$ | async as selectedDistrictAreas">
    <li class="HierarchyList-Column">
      <div class="panel panel-default">
        <div class="panel-heading">
          <div class="panel-title">
            {{(eAppSettingsAlias.DistrictArea | programAlias$ | async)?.singular}}
          </div>
        </div>
        <div class="panel-body">
          <ul class="HierarchyList">
            <ng-container *ngFor="let districtArea of selectedDistrictAreas | orderBy: 'hierarchyNodeName'">
              <ng-container
                *ngIf="districtArea.parentHierarchyNodeId == (selectedRegionArea$ | async)?.hierarchyNodeId || (searchForm.controls['searchText'].valueChanges | async)?.length > 0">
                <ng-container
                  *ngIf="selectedDistrictArea$ | async as selectedDistrictArea; else noDistrictAreaSelected">
                  <li (click)="nodeChanged(districtArea)"
                    [ngClass]="{'selected': districtArea.hierarchyNodeId === selectedDistrictArea.hierarchyNodeId}">
                    <div
                      [innerHTML]="districtArea.hierarchyNodeName | highlight:(searchForm.controls['searchText'].valueChanges | async)">
                    </div>
                  </li>
                </ng-container>
                <ng-template #noDistrictAreaSelected>
                  <li (click)="nodeChanged(districtArea)">
                    <div
                      [innerHTML]="districtArea.hierarchyNodeName | highlight:(searchForm.controls['searchText'].valueChanges | async)">
                    </div>
                  </li>
                </ng-template>
              </ng-container>
            </ng-container>
          </ul>
        </div>
      </div>
    </li>
  </ng-container>
  <ng-container *ngIf="selectedCountyAreas$ | async as selectedCountyAreas">
    <li class="HierarchyList-Column">
      <div class="panel panel-default">
        <div class="panel-heading">
          <div class="panel-title">
            {{(eAppSettingsAlias.CountyArea | programAlias$ | async)?.singular}}
          </div>
        </div>
        <div class="panel-body">
          <ul class="HierarchyList">
            <ng-container *ngFor="let countyArea of selectedCountyAreas | orderBy: 'hierarchyNodeName'">
              <ng-container
                *ngIf="countyArea.parentHierarchyNodeId == (selectedDistrictArea$ | async)?.hierarchyNodeId || (searchForm.controls['searchText'].valueChanges | async)?.length > 0">
                <ng-container *ngIf="selectedCountyArea$ | async as selectedCountyArea; else noSelectedCountyArea">
                  <li (click)="nodeChanged(countyArea)"
                    [ngClass]="{'selected': countyArea.hierarchyNodeId == selectedCountyArea.hierarchyNodeId}">
                    <div
                      [innerHTML]="countyArea.hierarchyNodeName | highlight:(searchForm.controls['searchText'].valueChanges | async)">
                    </div>
                  </li>
                </ng-container>
                <ng-template #noSelectedCountyArea>
                  <li (click)="nodeChanged(countyArea)">
                    <div
                      [innerHTML]="countyArea.hierarchyNodeName | highlight:(searchForm.controls['searchText'].valueChanges | async)">
                    </div>
                  </li>
                </ng-template>
              </ng-container>
            </ng-container>
          </ul>
        </div>
      </div>
    </li>
  </ng-container>
</ul>
<div class="HierarchyList-Footer">
  <ng-container *ngIf="selectedLeafNode$ | async as selectedLeafNode">
    <b>Selected: </b>
    <span *ngIf="selectedInstitutionArea$ | async as selectedInstitution">
      {{selectedInstitution.hierarchyNodeName}}</span>
    <span *ngIf="selectedRegionArea$ | async as selectedRegionArea"> / {{selectedRegionArea.hierarchyNodeName}}</span>
    <span *ngIf="selectedDistrictArea$ | async as selectedDistrictArea"> /
      {{selectedDistrictArea.hierarchyNodeName}}</span>
    <span *ngIf="selectedCountyArea$ | async as selectedCountyArea"> / {{selectedCountyArea.hierarchyNodeName}}</span>
  </ng-container>
</div>