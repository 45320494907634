export enum eAccessControlListRoleType {

  Superuser = 'Superuser',
  SystemManager = 'SystemManager',
  HierarchyAdmin = 'HierarchyAdmin', // individual permissions don’t apply to the admin, they have full permissions
  HierarchyStaff = 'HierarchyStaff', // staff are limited to the permissions they are granted
  HierarchyVolunteer = 'HierarchyVolunteer',
  EventAdmin = 'EventAdmin',
  EventStaff = 'EventStaff',
  EventVolunteer = 'EventVolunteer',
  None = 'None'
}
