<div *ngFor="let keyvalue of fields | keyvalue: retainOrder">
    <ng-container *ngIf="anyVisible(keyvalue.value)">
        <h5 class="ng4h-bg-secondary ng4h-panel-header">{{keyvalue.key}}</h5>
        <div class="ng4h-panel-body">

            <div class="ng4h-table">
                <ng-container *ngFor="let input of keyvalue.value; let inputIndex = index">
                    <ng-container *ngIf="input.inputState !== eStandardFieldStatus.Hidden">
                        <div class="col-12" *ngIf="shouldBreakBefore(keyvalue.key, inputIndex)">
                        </div>

                        <div class="row"
                            *ngIf="input.inputType != eFormControlType.TextContentBlock && (input.inputType != eFormControlType.DependentMultipleLineText || input.userResponse != null)">
                            <div class="col-4 ng4h-bg-secondary"><b>
                                    {{input.inputText}}
                                </b></div>
                            <div class="col-8">
                                <div [ngSwitch]="input.inputType">
                                    <div *ngSwitchCase="eFormControlType.Checkbox">
                                        {{input.userResponse | yesNoNullable}}
                                    </div>
                                    <div *ngSwitchCase="eFormControlType.Date">
                                        {{input.userResponse | date: 'M/d/yyyy'}}
                                    </div>
                                    <div *ngSwitchCase="eFormControlType.Currency">
                                        {{input.userResponse | currency}}
                                    </div>
                                    <div *ngSwitchCase="eFormControlType.SingleChoice">
                                        {{ input.singleChoiceOptions[input.userResponse] }}
                                    </div>

                                    <div *ngSwitchDefault>
                                        {{input.userResponse}}
                                    </div>

                                </div>
                            </div>
                        </div>


                        <div class="col-12" *ngIf="shouldBreakAfter(keyvalue.key, inputIndex)">
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </ng-container>
</div>