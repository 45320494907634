import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import {
  getAvailableColumns,
  getAvailableFilterValues,
  getCustomReport,
  getCustomReports,
  getQueuedReports,
  ManagerFeatureStore
} from 'app/core/containers/admin/manager/manager-feature.reducer';
import { CustomReportActions } from 'app/core/containers/admin/manager/reports/store';
import {
  ICustomReportCreateDto,
  ICustomReportDto,
  ICustomReportFilterDto,
  ICustomReportFilterGroupDto,
  ICustomReportGroupByDto,
  ICustomReportMailingLabelOptionsDto,
  ICustomReportOrderByDto,
  ICustomReportUpdateDto,
  IDirectDataAccessRequestDto,
  IInstitutionManagerProgramHierarchyParams,
  IInstitutionManagerProgramParams,
  IQueuedReportDto,
  IReportColumnDto,
  IReportFolderCreateDto,
  IReportFolderDto,
  IReportFolderUpdateDto,
  IReportFolderUpdateStateDto
} from 'app/core/models';
import { ICustomReportPreviewDto } from 'app/core/models/serverDTOs/ICustomReportPreviewDto';
import { downloadFile, downloadFileWithBody } from 'app/shared/utils';
import { filter, map, Observable, of, switchMap, take, tap } from 'rxjs';

import { CommonToastrService } from '../../common-toastr.service';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomReportsService {

  constructor(
    private store: Store<ManagerFeatureStore>,
    private httpClient: HttpClient,
    private dispatcher: ActionsSubject,
    private toastrService: CommonToastrService
  ) {
  }

  public getCustomReports(params: IInstitutionManagerProgramHierarchyParams): Observable<IReportFolderDto[]> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getCustomReports(params)),
      tap(reports => {
        if (reports === undefined) {
          this.store.dispatch(CustomReportActions.CustomReportsLoadReportsAction(params));
        }
      }),
      filter(reports => reports != null)
    );
  }

  public getCustomReportsEffect({
    institutionId,
    managerId,
    programId,
    hierarchyNodeId
  }: IInstitutionManagerProgramHierarchyParams): Observable<IReportFolderDto[]> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/custom-reports/${hierarchyNodeId}`) as Observable<IReportFolderDto[]>;
  }

  public getCustomReport(params: IInstitutionManagerProgramParams & { customReportId: string }) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getCustomReport(params)),
      tap(report => {
        if (report === undefined) {
          this.store.dispatch(CustomReportActions.CustomReportsLoadReportAction(params));
        }
      }),
      filter(report => report != null)
    );
  }

  public getCustomReportEffect({
    institutionId,
    managerId,
    programId,
    customReportId
  }: IInstitutionManagerProgramParams & { customReportId: string }): Observable<ICustomReportDto> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/custom-reports/${customReportId}`) as Observable<ICustomReportDto>;
  }

  public invalidateCache() {
    this.store.dispatch(CustomReportActions.CustomReportInvalidateCacheAction());
  }

  public addFilterGroupToReport(params: IInstitutionManagerProgramParams & { customReportId: string, filterGroup: ICustomReportFilterGroupDto }) {
    this.store.dispatch(CustomReportActions.CustomReportsAddFilterGroup(params));
  }

  public reorderSelectedColumns(params: IInstitutionManagerProgramParams & { customReportId: string, columns: IReportColumnDto[] }) {
    this.store.dispatch(CustomReportActions.CustomReportsReorderColumns(params));
  }

  public reorderFilterGroups(params: IInstitutionManagerProgramParams & { customReportId: string, filterGroups: ICustomReportFilterGroupDto[] }) {
    this.store.dispatch(CustomReportActions.CustomReportsReorderFilterGroup(params));
  }

  public removeFilterGroupFromReport(params: IInstitutionManagerProgramParams & { customReportId: string, filterGroupIndex: number }) {
    this.store.dispatch(CustomReportActions.CustomReportsRemoveFilterGroup(params));
  }

  public addFilterToFilterGroup(params: IInstitutionManagerProgramParams & { customReportId: string, filterGroupIndex: number, filter: ICustomReportFilterDto }) {
    this.store.dispatch(CustomReportActions.CustomReportsAddFilter(params));
  }

  public updateFilterValue(params: IInstitutionManagerProgramParams & { customReportId: string, filterGroupIndex: number, filterIndex: number, filter: ICustomReportFilterDto }) {
    this.store.dispatch(CustomReportActions.CustomReportsUpdateFilterValue(params));
  }

  public reorderFilters(params: IInstitutionManagerProgramParams & { customReportId: string, filterGroupIndex: number, filters: ICustomReportFilterDto[] }) {
    this.store.dispatch(CustomReportActions.CustomReportsReorderFilters(params));
  }

  public removeFilterFromFilterGroup(params: IInstitutionManagerProgramParams & { customReportId: string, filterGroupIndex: number, filterIndex: number }) {
    this.store.dispatch(CustomReportActions.CustomReportsRemoveFilter(params));
  }

  public getAvailableColumns(params: IInstitutionManagerProgramParams & { customReportId: string }): Observable<IReportColumnDto[]> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getAvailableColumns(params)),
      tap(report => {
        if (report === undefined) {
          this.store.dispatch(CustomReportActions.CustomReportsLoadAvailableColumnsAction(params));
        }
      }),
      filter(report => report != null)
    );

  }

  public getAvailableColumnsEffect({
    institutionId,
    managerId,
    programId,
    customReportId
  }: IInstitutionManagerProgramParams & { customReportId: string }): Observable<IReportColumnDto[]> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/custom-reports/${customReportId}/available-columns`) as Observable<IReportColumnDto[]>;
  }

  public addColumnToReport(params: IInstitutionManagerProgramParams & { customReportId: string, column: IReportColumnDto }) {
    this.store.dispatch(CustomReportActions.CustomReportsAddColumnToReport(params));
  }

  public removeColumnToReport(params: IInstitutionManagerProgramParams & { customReportId: string, column: IReportColumnDto }) {
    this.store.dispatch(CustomReportActions.CustomReportsDeleteColumnToReport(params));
  }

  public getExcelEffect({
    institutionId,
    managerId,
    programId,
    customReportId,
    hierarchyNodeId,
    programYearId
  }: IInstitutionManagerProgramParams & { customReportId: string, hierarchyNodeId: string, programYearId: string }) {
    downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/custom-reports/${customReportId}/excel?hierarchyNodeId=${hierarchyNodeId}&programYearId=${programYearId}`, this.toastrService);
  }

  public getPdfEffect({
    institutionId,
    managerId,
    programId,
    customReportId,
    hierarchyNodeId,
    programYearId,
    orientation,
    pagesize
  }: IInstitutionManagerProgramParams & { customReportId: string, hierarchyNodeId: string, programYearId: string, orientation: string, pagesize: string | number }) {
    downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/custom-reports/${customReportId}/pdf?hierarchyNodeId=${hierarchyNodeId}&programYearId=${programYearId}&orientation=${orientation}&pagesize=${pagesize}`, this.toastrService);
  }

  public createCustomReport(params: IInstitutionManagerProgramParams & { customReportFolderId: string, newReport: ICustomReportCreateDto }) {
    this.store.dispatch(CustomReportActions.CustomReportsCreateReportAction(params));

    return this.dispatcher.pipe(
      ofType(CustomReportActions.CustomReportsCreateReportSuccessAction, CustomReportActions.CustomReportsCreateReportErrorAction),
      take(1),
      map(action => {
        if (action.type === CustomReportActions.CustomReportsCreateReportSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public createCustomReportEffect({
    institutionId,
    managerId,
    programId,
    customReportFolderId,
    newReport
  }: IInstitutionManagerProgramParams & { customReportFolderId: string, newReport: ICustomReportCreateDto }) {
    return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/custom-report-folder/${customReportFolderId}`, newReport, { observe: 'response' }).pipe(
      switchMap((res: HttpResponse<any>) => this.httpClient.get(res.headers.get('location')))
    ) as Observable<ICustomReportDto>;
  }

  public updateCustomReport(params: IInstitutionManagerProgramParams & { customReportId: string, customReportUpdate: ICustomReportUpdateDto }) {
    this.store.dispatch(CustomReportActions.CustomReportsUpdateReportAction(params));

    return this.dispatcher.pipe(
      ofType(CustomReportActions.CustomReportsUpdateReportSuccessAction, CustomReportActions.CustomReportsUpdateReportErrorAction),
      take(1),
      map(action => {
        if (action.type === CustomReportActions.CustomReportsUpdateReportSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateCustomReportEffect({
    institutionId,
    managerId,
    programId,
    customReportId,
    customReportUpdate
  }: IInstitutionManagerProgramParams & { customReportId: string, customReportUpdate: ICustomReportUpdateDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/custom-reports/${customReportId}`, customReportUpdate);
  }

  public trashCustomReport(params: IInstitutionManagerProgramParams & { customReportId: string }) {
    this.store.dispatch(CustomReportActions.CustomReportsDeleteReportAction(params));

    return this.dispatcher.pipe(
      ofType(CustomReportActions.CustomReportsDeleteReportSuccessAction, CustomReportActions.CustomReportsDeleteReportErrorAction),
      take(1),
      map(action => {
        if (action.type === CustomReportActions.CustomReportsDeleteReportSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public trashCustomReportEffect({
    institutionId,
    managerId,
    programId,
    customReportId
  }: IInstitutionManagerProgramParams & { customReportId: string }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/custom-reports/${customReportId}/trash`, {});
  }

  public createCustomReportFolder(params: IInstitutionManagerProgramParams & { newFolder: IReportFolderCreateDto }) {
    this.store.dispatch(CustomReportActions.CreateReportFolderAction(params));

    return this.dispatcher.pipe(
      ofType(CustomReportActions.CreateReportFolderSuccessAction, CustomReportActions.CreateReportFolderErrorAction),
      take(1),
      map(action => {
        if (action.type === CustomReportActions.CreateReportFolderSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public createCustomReportFolderEffect({
    institutionId,
    managerId,
    programId,
    newFolder
  }: IInstitutionManagerProgramParams & { newFolder: IReportFolderCreateDto }) {
    return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/custom-report-folder`, newFolder);
  }

  public updateCustomReportFolder(params: IInstitutionManagerProgramParams & { customReportFolderId: string, folderUpdate: IReportFolderUpdateDto }) {
    this.store.dispatch(CustomReportActions.UpdateReportFolderAction(params));

    return this.dispatcher.pipe(
      ofType(CustomReportActions.UpdateReportFolderSuccessAction, CustomReportActions.UpdateReportFolderErrorAction),
      take(1),
      map(action => {
        if (action.type === CustomReportActions.UpdateReportFolderSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateCustomReportFolderEffect({
    institutionId,
    managerId,
    programId,
    customReportFolderId,
    folderUpdate
  }: IInstitutionManagerProgramParams & { customReportFolderId: string, folderUpdate: IReportFolderUpdateDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/custom-report-folder/${customReportFolderId}`, folderUpdate);
  }

  public updateCustomReportFolderState(params: IInstitutionManagerProgramHierarchyParams & { customReportFolderId: string, folderState: IReportFolderUpdateStateDto }) {
    this.store.dispatch(CustomReportActions.UpdateReportFolderStateAction(params));
  }

  public updateCustomReportFolderStateEffect({
    institutionId,
    managerId,
    programId,
    customReportFolderId,
    folderState
  }: IInstitutionManagerProgramParams & { customReportFolderId: string, folderState: IReportFolderUpdateStateDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/custom-report-folder/${customReportFolderId}/state`, folderState);
  }

  public deleteCustomReportFolder(params: IInstitutionManagerProgramParams & { customReportFolderId: string }) {
    this.store.dispatch(CustomReportActions.DeleteReportFolderAction(params));

    return this.dispatcher.pipe(
      ofType(CustomReportActions.DeleteReportFolderSuccessAction, CustomReportActions.DeleteReportFolderErrorAction),
      take(1),
      map(action => {
        if (action.type === CustomReportActions.DeleteReportFolderSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteCustomReportFolderEffect({
    institutionId,
    managerId,
    programId,
    customReportFolderId
  }: IInstitutionManagerProgramParams & { customReportFolderId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/custom-report-folder/${customReportFolderId}`);
  }

  public restoreCustomReport(params: IInstitutionManagerProgramParams & { customReportId: string }) {
    this.store.dispatch(CustomReportActions.CustomReportsRestoreReportAction(params));

    return this.dispatcher.pipe(
      ofType(CustomReportActions.CustomReportsRestoreReportSuccessAction, CustomReportActions.CustomReportsRestoreReportErrorAction),
      take(1),
      map(action => {
        if (action.type === CustomReportActions.CustomReportsRestoreReportSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public restoreCustomReportEffect({
    institutionId,
    managerId,
    programId,
    customReportId
  }: IInstitutionManagerProgramParams & { customReportId: string }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/custom-reports/${customReportId}/restore`, {});
  }

  public shareCustomReport(params: IInstitutionManagerProgramParams & { customReportId: string }) {
    this.store.dispatch(CustomReportActions.CustomReportsShareReportAction(params));

    return this.dispatcher.pipe(
      ofType(CustomReportActions.CustomReportsShareReportSuccessAction, CustomReportActions.CustomReportsShareReportErrorAction),
      take(1),
      map(action => {
        if (action.type === CustomReportActions.CustomReportsShareReportSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public shareCustomReportEffect({
    institutionId,
    managerId,
    programId,
    customReportId
  }: IInstitutionManagerProgramParams & { customReportId: string }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/custom-reports/${customReportId}/share`, {});
  }

  public unShareCustomReport(params: IInstitutionManagerProgramParams & { customReportId: string }) {
    this.store.dispatch(CustomReportActions.CustomReportsUnShareReportAction(params));

    return this.dispatcher.pipe(
      ofType(CustomReportActions.CustomReportsUnShareReportSuccessAction, CustomReportActions.CustomReportsUnShareReportErrorAction),
      take(1),
      map(action => {
        if (action.type === CustomReportActions.CustomReportsUnShareReportSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public unShareCustomReportEffect({
    institutionId,
    managerId,
    programId,
    customReportId
  }: IInstitutionManagerProgramParams & { customReportId: string }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/custom-reports/${customReportId}/un-share`, {});
  }

  public sendCustomReport(params: IInstitutionManagerProgramParams & { customReportId: string, destinationManagerId: string }) {
    this.store.dispatch(CustomReportActions.CustomReportsSendReportAction(params));

    return this.dispatcher.pipe(
      ofType(CustomReportActions.CustomReportsSendReportSuccessAction, CustomReportActions.CustomReportsSendReportErrorAction),
      take(1),
      map(action => {
        if (action.type === CustomReportActions.CustomReportsSendReportSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public sendCustomReportEffect({
    institutionId,
    managerId,
    programId,
    customReportId,
    destinationManagerId
  }: IInstitutionManagerProgramParams & { customReportId: string, destinationManagerId: string }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/custom-reports/${customReportId}/send?destinationManagerId=${destinationManagerId}`, {});
  }

  public copyCustomReport(params: IInstitutionManagerProgramParams & { customReportId: string }) {
    this.store.dispatch(CustomReportActions.CustomReportsCopyReportAction(params));

    return this.dispatcher.pipe(
      ofType(CustomReportActions.CustomReportsCopyReportSuccessAction, CustomReportActions.CustomReportsCopyReportErrorAction),
      take(1),
      map(action => {
        if (action.type === CustomReportActions.CustomReportsCopyReportSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public copyCustomReportEffect({
    institutionId,
    managerId,
    programId,
    customReportId
  }: IInstitutionManagerProgramParams & { customReportId: string }) {
    return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/custom-reports/${customReportId}/copy`, {});
  }

  public getAvailableFilterValues(params: IInstitutionManagerProgramParams & { customReportId: string, reportColumnId: string, hierarchyNodeId: string, programYearId: string }) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getAvailableFilterValues(params)),
      tap(report => {
        if (report === undefined) {
          this.store.dispatch(CustomReportActions.CustomReportsLoadAvailableFilterValuesAction(params));
        }
      }),
      filter(report => report != null)
    );
  }

  public getAvailableFilterValuesEffect({
    institutionId,
    managerId,
    programId,
    customReportId,
    reportColumnId,
    hierarchyNodeId,
    programYearId
  }: IInstitutionManagerProgramParams & { customReportId: string, reportColumnId: string, hierarchyNodeId: string, programYearId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/custom-reports/${customReportId}/available-filter-values/${reportColumnId}?hierarchyNodeId=${hierarchyNodeId}&programYearId=${programYearId}`) as Observable<{ key: string, value: string }>;
  }

  public addOrderBy(params: IInstitutionManagerProgramParams & { customReportId: string, orderBy: ICustomReportOrderByDto }) {
    this.store.dispatch(CustomReportActions.CustomReportsAddOrderByAction(params));
  }

  public updateOrderBy(params: IInstitutionManagerProgramParams & { customReportId: string, orderByIndex: number, orderBy: ICustomReportOrderByDto }) {
    this.store.dispatch(CustomReportActions.CustomReportsUpdateOrderByAction(params));
  }

  public reorderOrderBy(params: IInstitutionManagerProgramParams & { customReportId: string, orderBys: ICustomReportOrderByDto[] }) {
    this.store.dispatch(CustomReportActions.CustomReportsReorderOrderByAction(params));
  }

  public removeOrderBy(params: IInstitutionManagerProgramParams & { customReportId: string, orderByIndex: number }) {
    this.store.dispatch(CustomReportActions.CustomReportsRemoveOrderByAction(params));
  }

  public addGroupBy(params: IInstitutionManagerProgramParams & { customReportId: string, groupBy: ICustomReportGroupByDto }) {
    this.store.dispatch(CustomReportActions.CustomReportsAddGroupByAction(params));
  }

  public updateGroupBy(params: IInstitutionManagerProgramParams & { customReportId: string, groupByIndex: number, groupBy: ICustomReportGroupByDto }) {
    this.store.dispatch(CustomReportActions.CustomReportsUpdateGroupByAction(params));
  }

  public reorderGroupBy(params: IInstitutionManagerProgramParams & { customReportId: string, groupBys: ICustomReportGroupByDto[] }) {
    this.store.dispatch(CustomReportActions.CustomReportsReorderGroupByAction(params));
  }

  public removeGroupBy(params: IInstitutionManagerProgramParams & { customReportId: string, groupByIndex: number }) {
    this.store.dispatch(CustomReportActions.CustomReportsRemoveGroupByAction(params));
  }

  public getPreviewEffect({
    institutionId,
    managerId,
    programId,
    customReportId,
    hierarchyNodeId,
    programYearId,
    pageIndex,
    pageSize
  }: IInstitutionManagerProgramParams & { customReportId: string, hierarchyNodeId: string, programYearId: string, pageIndex: string, pageSize: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/custom-reports/${customReportId}/preview?hierarchyNodeId=${hierarchyNodeId}&programYearId=${programYearId}&pageIndex=${pageIndex}&pageSize=${pageSize}`) as Observable<ICustomReportPreviewDto>;
  }

  public getQueuedReports(params: IInstitutionManagerProgramParams & { hierarchyNodeId: string }) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getQueuedReports(params)),
      tap(reports => {
        if (reports === undefined) {
          this.store.dispatch(CustomReportActions.CustomReportsLoadQueuedReportsAction(params));
        }
      }),
      filter(reports => reports != null)
    );
  }

  public getQueuedReportsEffect({
    institutionId,
    managerId,
    programId,
    hierarchyNodeId
  }: IInstitutionManagerProgramParams & { hierarchyNodeId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/custom-reports/${hierarchyNodeId}/queued`) as Observable<IQueuedReportDto[]>;
  }

  // public getQueuedReport(params: IInstitutionManagerProgramParams & { queueToken: string }) {
  //   if (Object.keys(params).find(key => params[key] == null) != null) {
  //     return of(null);
  //   }
  //   return this.store.pipe(
  //     select(getQueuedReport(params)),
  //     tap(report => {
  //       if (report === undefined) {
  //         this.store.dispatch(CustomReportActions.CustomReportsLoadQueuedReportAction(params));
  //       }
  //     }),
  //     filter(report => report != null),
  //   );
  // }

  // public getQueuedReportEffect({ institutionId, managerId, programId, queueToken }: IInstitutionManagerProgramParams & { queueToken: string }) {
  //   return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/custom-reports/${queueToken}`) as Observable<IQueuedReportDto>;
  // }

  public downloadQueuedReport({
    institutionId,
    managerId,
    programId,
    queueToken
  }: IInstitutionManagerProgramParams & { queueToken: string }) {
    downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/custom-reports/${queueToken}`);
  }

  public downloadMailingLabels({
    institutionId,
    managerId,
    programId,
    customReportId,
    hierarchyNodeId,
    programYearId,
    mailingLabelOptions
  }: IInstitutionManagerProgramParams & { customReportId: string, hierarchyNodeId: string, programYearId: string, mailingLabelOptions: ICustomReportMailingLabelOptionsDto }) {
    downloadFileWithBody(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/custom-reports/${customReportId}/mailing-labels?hierarchyNodeId=${hierarchyNodeId}&programYearId=${programYearId}`, mailingLabelOptions);
  }

  public requestApiAccess(params: IInstitutionManagerProgramParams & { customReportId: string, directAccessDto: IDirectDataAccessRequestDto }) {
    this.store.dispatch(CustomReportActions.CustomReportsRequestApiAccessAction(params));

    return this.dispatcher.pipe(
      ofType(CustomReportActions.CustomReportsRequestApiAccessSuccessAction, CustomReportActions.CustomReportsRequestApiAccessErrorAction),
      take(1),
      map(action => {
        if (action.type === CustomReportActions.CustomReportsRequestApiAccessSuccessAction.type) {
          return action.location;
        } else {
          throw action.error;
        }
      })
    );
  }

  public requestApiAccessEffect({
    institutionId,
    managerId,
    programId,
    customReportId,
    directAccessDto
  }: IInstitutionManagerProgramParams & { customReportId: string, directAccessDto: IDirectDataAccessRequestDto }) {
    return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/custom-reports/${customReportId}/request-direct-access`, directAccessDto,
      { observe: 'response' }).pipe(
      map((res: HttpResponse<any>) => {
        return res.headers.get('location');
      })
    ) as Observable<string>;
  }
}
