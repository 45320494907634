import { eFormControlItemType } from './dynamic-forms/eFormControlItemType';
import { DynamicOption } from './DynamicOption';
import { IDynamicItem } from './IDynamicItem';

export class DynamicItem implements IDynamicItem {
  itemId: string;
  type: eFormControlItemType;
  label: string;
  userResponse: string;
  attributes: Partial<{
    placeholder: string,
    rows: number,
    quantityAvailable: number,
    allowWaitList: boolean;
    minDate?: string;
    maxDate?: string;
  }>;
  rate: number;
  options: DynamicOption[];
  // itemId needed for constructing formGroup
  constructor(private _itemId: string, private _type: eFormControlItemType, private _label: string = '') {
    this.itemId = _itemId;
    this.type = _type;
    this.label = _label;
    this.attributes = {};
    this.options = [];
  }
}
