import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
    IBulkDownloadCreateDto,
    IInstitutionManagerProgramHierarchyParams,
    IParticipationSearchParametersDto,
} from 'app/core/models';
import { IMemberSearchResultsDto } from 'app/core/models/serverDTOs/IMemberSearchResultsDto';


export const SearchParticipationsAction = createAction(
    '[participation-search] Search participation',
    props<IInstitutionManagerProgramHierarchyParams & { searchParameters: IParticipationSearchParametersDto }>()
);

export const SearchParticipationsSuccessAction = createAction(
    '[participation-search] Search participation success',
    props<IInstitutionManagerProgramHierarchyParams & { searchParameters: IParticipationSearchParametersDto, participationsSearchResult: IMemberSearchResultsDto }>()
);

export const SearchParticipationsErrorAction = createAction(
    '[participation-search] Search participation error',
    props<{ error: HttpErrorResponse }>()
);


export const GetParticipationSearchResultsAction = createAction(
    '[participation-search] get participation search results',
    props<IInstitutionManagerProgramHierarchyParams & { pageSize: string, pageIndex: string }>()
);

export const GetParticipationSearchResultsSuccessAction = createAction(
    '[participation-search] get participation search results success',
    props<IInstitutionManagerProgramHierarchyParams & { pageSize: string, pageIndex: string, participationsSearchResult: IMemberSearchResultsDto }>()
);

export const GetParticipationSearchResultsErrorAction = createAction(
    '[participation-search] get participation search results error',
    props<{ error: HttpErrorResponse }>()
);


export const ClearParticipationSearchAction = createAction(
    '[participation-search] clear participation search',
    props<IInstitutionManagerProgramHierarchyParams>()
);

export const ClearParticipationSearchSuccessAction = createAction(
    '[participation-search] clear participation search success',
    props<IInstitutionManagerProgramHierarchyParams>()
);

export const ClearParticipationSearchErrorAction = createAction(
    '[participation-search] clear participation search error',
    props<{ error: HttpErrorResponse }>()
);

export const DownloadBulkFilesAction = createAction(
    '[participation-search] download bulk files',
    props<IInstitutionManagerProgramHierarchyParams & { bulkDownloadCreate: IBulkDownloadCreateDto }>()
);

export const DownloadBulkFilesSuccessAction = createAction(
    '[participation-search] download bulk files success',
    props<IInstitutionManagerProgramHierarchyParams & { bulkDownloadCreate: IBulkDownloadCreateDto }>()
);

export const DownloadBulkFilesErrorAction = createAction(
    '[participation-search] download bulk files error',
    props<{ error: HttpErrorResponse }>()
);
