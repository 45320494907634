import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ng4h-modal-apply-coupon-code',
  templateUrl: './modal-apply-coupon-code.component.html',
  styleUrls: ['./modal-apply-coupon-code.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalApplyCouponCodeComponent implements OnInit {

  public form: FormGroup;
  constructor(private dialogRef: MatDialogRef<any>, private fb: FormBuilder) {
    this.form = this.fb.group({
      couponCode: [null, [Validators.required, Validators.pattern(`^[a-zA-Z0-9]{6}$`)]]
    });
  }

  ngOnInit() {
  }

  public close(result?: any): void {
    this.dialogRef.close(result);
  }

  public save(): void {
    this.close(this.form.value);
  }
}
