import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'delimitedList',
    pure: true
})


export class DelimitedListPipe implements PipeTransform {

    constructor() {
    }

    transform(list: (number | string)[], delimiter = ', ', key?: string): string {
        if (list == null || !Array.isArray(list) || list.length < 1) {
            return null;
        }
        if (key != null) {
            return list.map(item => item[key]).join(delimiter);
        }
        return list.join(delimiter);
    }
}
