import { Action, createReducer, on } from '@ngrx/store';
import { IMemberSearchResultsDto } from 'app/core/models';

import { EventPaymentSearchActions } from '.';

export interface EventPaymentSearchState {

    paymentSearchSearching: boolean;
    paymentsSearchResult: IMemberSearchResultsDto;
}

const initialState: EventPaymentSearchState = {
    paymentSearchSearching: false,
    paymentsSearchResult: undefined
};

const reducer = createReducer(
    initialState,
    on(EventPaymentSearchActions.GetEventPaymentSearchResultsAction, (state, payload) => {
        return {
            ...state,
            paymentSearchSearching: true,
        };
    }),
    on(EventPaymentSearchActions.GetEventPaymentSearchResultsSuccessAction, (state, payload) => {
        return {
            ...state,
            paymentSearchSearching: false,
            paymentsSearchResult: payload.paymentSearchResult
        };
    }),
    on(EventPaymentSearchActions.GetEventPaymentSearchResultsErrorAction, (state, payload) => {
        return {
            ...state,
            paymentSearchSearching: false,
        };
    }),

    on(EventPaymentSearchActions.EventSearchPaymentAction, (state, payload) => {
        return {
            ...state,
            paymentSearchSearching: true,
        };
    }),
    on(EventPaymentSearchActions.EventSearchPaymentSuccessAction, (state, payload) => {
        return {
            ...state,
            paymentSearchSearching: false,
            paymentsSearchResult: payload.paymentSearchResult
        };
    }),
    on(EventPaymentSearchActions.EventSearchPaymentErrorAction, (state, payload) => {
        return {
            ...state,
            paymentSearchSearching: false,
        };
    }),

    on(EventPaymentSearchActions.ClearPaymentSearchAction, (state, payload) => {
        return {
            ...state,
            paymentSearchSearching: true,
        };
    }),
    on(EventPaymentSearchActions.ClearPaymentSearchErrorAction, (state, payload) => {
        return {
            ...state,
            paymentSearchSearching: false,
        };
    }),

);

export function eventPaymentSearchReducer(state: EventPaymentSearchState | undefined, action: Action) {
    return reducer(state, action);
}

export const eventPaymentsSearchResult = (state: EventPaymentSearchState) => state.paymentsSearchResult;
export const eventPaymentSearchSearching = (state: EventPaymentSearchState) => state.paymentSearchSearching;