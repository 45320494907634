import { IEventACLDto, IHierarchyNodeDto } from '.';
import { eEventStatus } from '..';
import { IManagerDescriptorDto } from './IManagerDescriptorDto';

export interface IEventDescriptorDto {
    eventId: string;
    eventName?: string;
    allowMultipleRegistrations: boolean;

    allowRegistrationByManagerOnly: boolean;
    startDate: string;
    endDate: string;
    eventStatus: eEventStatus;
    hierarchyNode: IHierarchyNodeDto;
    programId: string;
    programYear: string;
    activeManagerPermission: IEventACLDto;
    registeredInEvent?: boolean;
    inActiveHierarchy: boolean;
    createdBy: IManagerDescriptorDto;
}
