import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import {  } from 'events';

@Component({
  selector: 'ng4h-report-sort',
  templateUrl: './report-sort.component.html',
  styleUrls: ['./report-sort.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportSortComponent implements OnInit {

  @Input() columns: Array<any>;

  @Output() delete: EventEmitter<any> = new EventEmitter<any>();

  public form: FormGroup;

  constructor(private fb: FormBuilder) { }

  public ngOnInit() {

    this.form = this.fb.group({
      column: '',
      order: 'asc'
    });
  }

  public onDelete(): void {
    this.delete.emit();
  }
}
