<div class="modal-header">
  <h5 class="modal-title">Add Consent</h5>
  <button (click)="close()" type="button" class="close" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form" class="row" [ngClass]="wasValidated ? 'was-validated' : 'needs-validation'" novalidate>
    <div class="form-group col-sm-12">
      <label class="form-control-label">Consent Name</label>
      <input formControlName="consentName" type="text" class="form-control">
    </div>
    <div class="form-group col-sm-12">
      <label class="form-control-label">Acceptance Required</label>
      <select formControlName="acceptanceRequired" class="form-control">
        <option [ngValue]="false">False</option>
        <option [ngValue]="true">True</option>
      </select>
    </div>
    <!-- <div class="form-group col-sm-12">
      <label class="form-control-label">Electronic Signature Required</label>
      <select formControlName="electronicSignatureRequired" class="form-control">
        <option [ngValue]="false">False</option>
        <option [ngValue]="true">True</option>
      </select>
    </div> -->
    <div class="form-group col-sm-12">
      <label class="form-control-label">Consent Title</label>
      <input formControlName="consentTitle" type="text" class="form-control">
    </div>
    <div class="form-group col-sm-12">
      <label class="form-control-label">Consent Text</label>
      <p-editor #editor formControlName="consentText" [style]="{'height':'320px'}" pEditorDefaultModules>
        <ng-template pTemplate="header">
          <ng4h-quill-editor-header-full [QuillEditor]="editor.quill"></ng4h-quill-editor-header-full>
        </ng-template>
      </p-editor>
    </div>
    <div class="form-group col-sm-12">
      <label class="form-control-label">Agree Text</label>
      <textarea formControlName="agreeOptionText" type="text" class="form-control"></textarea>
    </div>
    <div class="form-group col-sm-12" *ngIf="!form.controls['acceptanceRequired'].value">
      <label class="form-control-label">Disagree Text</label>
      <textarea formControlName="disagreeOptionText" type="text" class="form-control"></textarea>
    </div>
    <div class="form-group col-12">
      <label class="form-control-label">Date Effective</label>
      <div class="input-group">
        <input class="form-control" [matDatepicker]="picker" placeholder="Choose a date"
          formControlName="dateEffective">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      </div>
      <mat-datepicker #picker></mat-datepicker>
    </div>
    <div class="form-group col-sm-12">
      <label class="form-control-label">Role</label>
      <ng4h-enrollment-role-dropdown formControlName="role"></ng4h-enrollment-role-dropdown>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button (click)="add()" class="btn btn-primary my-2 my-sm-0" role="button" aria-pressed="true">Add</button>
</div>