<ng-container *ngIf="form != null">
  <div class="modal-header">
    <h5 class="modal-title">Add Custom File</h5>
    <button (click)="close()" type="button" class="close" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="form">
      <div class="form-group row">
        <label class="col-md-3 ng4h-required col-form-label"> Name </label>
        <div class="col-md-9 form-input">
          <input formControlName="fileControlName" type="text" class="form-control">
        </div>
      </div>

      <div class="form-group row">
        <label class="col-md-3 ng4h-required col-form-label"> Description </label>
        <div class="col-md-9 form-input">
          <textarea formControlName="fileControlDescription" type="text" class="form-control"></textarea>
        </div>
      </div>
      <!-- 
      <div class="form-group row">
        <label class="col-md-3 ng4h-required col-form-label"> File Type </label>
        <div class="col-md-9 form-input">
          <select class="form-control" formControlName="acceptedFileExtensions">
            <option value="hdImage"> High Quality Image </option>
            <option value="image"> Image </option>
            <option value="pdf"> PDF </option>
          </select>
        </div>
      </div> -->

      <label class="col-form-label"> <b>Registration Types</b> </label>

      <div class="form-group row" *ngFor="let item of form.get('registrationTypes')['controls']; let i = index;">
        <label class="col-md-3 col-form-label"> {{ item.controls['registrationTypeName'].value }} </label>
        <div class="col-md-9 form-input">

          <div formArrayName="registrationTypes">
            <div [formGroupName]="i" class="col-md-9 form-input">


              <mat-radio-group formControlName="selected">
                <mat-radio-button [value]="null" class="pr-2">
                  Not Visible
                </mat-radio-button>
                <mat-radio-button [value]="false" class="p-2">
                  Visible
                </mat-radio-button>
                <mat-radio-button [value]="true" class="p-2">
                  Required
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>

        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">

    <button [disabled]="form.invalid" (click)="add()" class="btn btn-primary my-2 my-sm-0" role="button"
      aria-pressed="true">Add</button>
  </div>
</ng-container>