<form [formGroup]="form">
  <input type="file" #file style="display: none;" (change)="onFileChange($event.target.files)" multiple
    [accept]="accept" />
  <button [disabled]="disabled" *ngIf="!uploading" class="btn btn-secondary" (click)="addFiles()">
    <span> <i class="fas fa-file-upload"></i> </span>
    {{hasFile ? 'Overwrite' : 'Upload'}}
  </button>
  <button *ngIf="uploading" class="btn btn-secondary" [disabled]="true" (click)="addFiles()">
    <span> <i class="fas fa-file-upload"></i> </span>
    Uploading
  </button>
</form>
