import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  IDynamicForm,
  IEnrollmentAnimalDto,
  IEventDto,
  IEventRegistrationCreateDto,
  IEventRegistrationDto,
  IInstitutionMemberEventParams,
  IMemberConsentSubmitDto,
  IPartialPaymentSubmitDto,
  IPayableInvoiceDto,
  IRegistrationTypeFileControlDto,
} from 'app/core/models';
import {
  IInstitutionMemberProgramEventRegistrationParams,
} from 'app/core/models/function-parameters/institution-member-program-event-registration';

export const EventMemberLoadAction = createAction(
    '[events-member] Get event',
    props<IInstitutionMemberEventParams>()
);
export const EventMemberLoadSuccessAction = createAction(
    '[events-member] Get success event',
    props<IInstitutionMemberEventParams & { event: IEventDto }>()
);
export const EventMemberLoadErrorAction = createAction(
    '[events-member] Get error event',
    props<{ error: HttpErrorResponse }>()
);
export const EventsMemberLoadAction = createAction(
    '[events-member] Get events',
    props<{ institutionId: string, memberId: string }>()
);
export const EventsMemberLoadSuccessAction = createAction(
    '[events-member] Get success events',
    props<{ institutionId: string, memberId: string, events: IEventDto[] }>()
);
export const EventsMemberLoadErrorAction = createAction(
    '[events-member] Get error events',
    props<{ error: HttpErrorResponse }>()
);
export const EventsMemberLoadRegistrationAction = createAction(
    '[events-member] Get registration',
    props<IInstitutionMemberProgramEventRegistrationParams>()
);
export const EventsMemberLoadRegistrationSuccessAction = createAction(
    '[events-member] Get success registration',
    props<{ institutionId: string, memberId: string, programId: string, eventRegistrationId: string, eventRegistration: IEventRegistrationDto }>()
);
export const EventsMemberLoadRegistrationErrorAction = createAction(
    '[events-member] Get error registration',
    props<{ error: HttpErrorResponse }>()
);
export const EventsMemberRegisterForEventAction = createAction(
    '[events-member] Register for event',
    props<{ institutionId: string, memberId: string, programId: string, eventRegistrationCreate: IEventRegistrationCreateDto }>()
);
export const EventsMemberRegisterForEventSuccessAction = createAction(
    '[events-member] Register for event success',
    props<{ institutionId: string, memberId: string, programId: string, eventRegistration: IEventRegistrationDto }>()
);
export const EventsMemberRegisterForEventErrorAction = createAction(
    '[events-member] Register for event error',
    props<{ error: HttpErrorResponse }>()
);
// export const EventsMemberLoadPayableInvoiceAction = createAction(
//     '[events-member] Load payable invoice',
//     props<IInstitutionMemberProgramEventRegistrationParams>()
// );
// export const EventsMemberLoadPayableInvoiceSuccessAction = createAction(
//     '[events-member] Load payable invoice success',
//     props<{ institutionId: string, memberId: string, programId: string, eventRegistrationId: string, invoice: IPayableInvoiceDto | string }>()
// );
// export const EventsMemberLoadPayableInvoiceErrorAction = createAction(
//     '[events-member] Load payable invoice error',
//     props<{ error: HttpErrorResponse }>()
// );
export const EventsMemberLoadDynamicFormAction = createAction(
    '[events-member] Load dynamic inputs',
    props<IInstitutionMemberProgramEventRegistrationParams>()
);
export const EventsMemberLoadDynamicFormSuccessAction = createAction(
    '[events-member] Load dynamic inputs success',
    props<{ institutionId: string, memberId: string, programId: string, eventRegistrationId: string, dynamicForm: IDynamicForm }>()
);
export const EventsMemberLoadDynamicFormErrorAction = createAction(
    '[events-member] Load dynamic inputs error',
    props<{ error: HttpErrorResponse }>()
);
export const EventsMemberSaveDynamicFormResponsesAction = createAction(
    '[events-member] Dynamic save responses',
    props<IInstitutionMemberProgramEventRegistrationParams & { responses: any }>()
);
export const EventsMemberSaveDynamicFormResponsesSuccessAction = createAction(
    '[events-member] Dynamic save responses success',
    props<IInstitutionMemberProgramEventRegistrationParams & { responses: any }>()
);
export const EventsMemberSaveDynamicFormResponsesErrorAction = createAction(
    '[events-member] Dynamic save responses error',
    props<{ error: HttpErrorResponse }>()
);
export const EventsMemberSaveConsentsAction = createAction(
    '[events-member] Save consent',
    props<{ institutionId: string, memberId: string, programId: string, eventRegistrationId: string, consent: IMemberConsentSubmitDto[] }>()
);
export const EventsMemberSaveConsentsSuccessAction = createAction(
    '[events-member] Save consent success',
    props<{ institutionId: string, memberId: string, programId: string, eventRegistrationId: string, consent: IMemberConsentSubmitDto[] }>()
);
export const EventsMemberSaveConsentsErrorAction = createAction(
    '[events-member] Save consent error',
    props<{ error: HttpErrorResponse }>()
);
export const EventsMemberSubmitAction = createAction(
    '[events-member] Submit',
    props<IInstitutionMemberProgramEventRegistrationParams & { invoice: IPayableInvoiceDto }>()
);
export const EventsMemberSubmitSuccessAction = createAction(
    '[events-member] Submit success',
    props<IInstitutionMemberProgramEventRegistrationParams & { invoice: IPayableInvoiceDto }>()
);
export const EventsMemberSubmitErrorAction = createAction(
    '[events-member] Submit error',
    props<{ error: HttpErrorResponse }>()
);
export const EventsMemberInvalidateEventRegistrationsAction = createAction(
    '[events-member] Invalidate event registrations'
);


export const EventsMemberApplyPaymentAction = createAction(
    '[events-member] Apply payment',
    props<IInstitutionMemberProgramEventRegistrationParams & { invoiceId: string, paymentMethodId: string, memberProgramConsentSubmitDto: IMemberConsentSubmitDto }>()
);
export const EventsMemberApplyPaymentSuccessAction = createAction(
    '[events-member] Apply payment success',
    props<IInstitutionMemberProgramEventRegistrationParams & { invoiceId: string, paymentMethodId: string, memberProgramConsentSubmitDto: IMemberConsentSubmitDto }>()
);
export const EventsMemberApplyPaymentErrorAction = createAction(
    '[events-member] Apply payment error',
    props<{ error: HttpErrorResponse }>()
);
export const EventsMemberApplyAdditionalPaymentAction = createAction(
    '[events-member] Apply additional payment',
    props<IInstitutionMemberProgramEventRegistrationParams & { invoiceId: string, paymentId: string, paymentMethodId: string, paymentConsentResponse: IMemberConsentSubmitDto }>()
);
export const EventsMemberApplyAdditionalPaymentSuccessAction = createAction(
    '[events-member] Apply additional payment success',
    props<IInstitutionMemberProgramEventRegistrationParams & { invoiceId: string, paymentId: string, paymentMethodId: string, paymentConsentResponse: IMemberConsentSubmitDto }>()
);
export const EventsMemberApplyAdditionalPaymentErrorAction = createAction(
    '[events-member] Apply additional payment error',
    props<{ error: HttpErrorResponse }>()
);
export const EventsMemberApplyPartialPaymentAction = createAction(
    '[events-member] Apply partial payment',
    props<IInstitutionMemberProgramEventRegistrationParams & { invoiceId: string, paymentMethodId: string, partialPaymentSubmit: IPartialPaymentSubmitDto }>()
);
export const EventsMemberApplyPartialPaymentSuccessAction = createAction(
    '[events-member] Apply partial payment success',
    props<IInstitutionMemberProgramEventRegistrationParams & { invoiceId: string, paymentMethodId: string, partialPaymentSubmit: IPartialPaymentSubmitDto }>()
);
export const EventsMemberApplyPartialPaymentErrorAction = createAction(
    '[events-member] Apply partial payment error',
    props<{ error: HttpErrorResponse }>()
);
export const EventsMemberApplyDepositPaymentAction = createAction(
    '[events-member] Apply deposit payment',
    props<IInstitutionMemberProgramEventRegistrationParams & { invoiceId: string, paymentMethodId: string, consentSubmit: IMemberConsentSubmitDto }>()
);
export const EventsMemberApplyDepositPaymentSuccessAction = createAction(
    '[events-member] Apply deposit payment success',
    props<IInstitutionMemberProgramEventRegistrationParams & { invoiceId: string, paymentMethodId: string, consentSubmit: IMemberConsentSubmitDto }>()
);
export const EventsMemberApplyDepositPaymentErrorAction = createAction(
    '[events-member] Apply deposit payment error',
    props<{ error: HttpErrorResponse }>()
);

export const EventsMemberLoadCustomFilesAction = createAction(
    '[events-member] LoadCustomFiles',
    props<IInstitutionMemberProgramEventRegistrationParams>()
);
export const EventsMemberLoadCustomFilesSuccessAction = createAction(
    '[events-member] LoadCustomFiles success',
    props<IInstitutionMemberProgramEventRegistrationParams & { files: IRegistrationTypeFileControlDto[] }>()
);
export const EventsMemberLoadCustomFilesErrorAction = createAction(
    '[events-member] LoadCustomFiles error',
    props<{ error: HttpErrorResponse }>()
);


export const EventsMemberApplyCouponCodeAction = createAction(
    '[events-member] Apply coupon code',
    props<IInstitutionMemberProgramEventRegistrationParams & { couponCode: string }>()
);
export const EventsMemberApplyCouponCodeSuccessAction = createAction(
    '[events-member] Apply coupon code success',
    props<IInstitutionMemberProgramEventRegistrationParams & { couponCode: string }>()
);
export const EventsMemberApplyCouponCodeErrorAction = createAction(
    '[events-member] Apply coupon code error',
    props<{ error: HttpErrorResponse }>()
);


export const EventsMemberApplyCreditAction = createAction(
    '[events-member] Apply credit',
    props<IInstitutionMemberProgramEventRegistrationParams & { consent: IMemberConsentSubmitDto }>()
);
export const EventsMemberApplyCreditSuccessAction = createAction(
    '[events-member] Apply credit success',
    props<IInstitutionMemberProgramEventRegistrationParams & { consent: IMemberConsentSubmitDto }>()
);
export const EventsMemberApplyCreditErrorAction = createAction(
    '[events-member] Apply credit error',
    props<{ error: HttpErrorResponse }>()
);

export const EventsMemberInvalidateDynamicFormsCacheAction = createAction(
    '[events-member] Invalidate dynamic forms cache'
);


export const EventsMemberUploadFileAction = createAction(
    '[events-member] Upload file',
    props<IInstitutionMemberProgramEventRegistrationParams & { fileControlId: string, formData: FormData }>()
);
export const EventsMemberUploadFileSuccessAction = createAction(
    '[events-member] Upload file success',
    props<IInstitutionMemberProgramEventRegistrationParams & { fileControlId: string, formData: FormData }>()
);
export const EventsMemberUploadFileErrorAction = createAction(
    '[events-member] Upload file error',
    props<{ error: HttpErrorResponse }>()
);

export const EventsMemberDeleteFileAction = createAction(
    '[events-member] Delete file',
    props<IInstitutionMemberProgramEventRegistrationParams & { fileControlId: string }>()
);
export const EventsMemberDeleteFileSuccessAction = createAction(
    '[events-member] Delete file success',
    props<IInstitutionMemberProgramEventRegistrationParams & { fileControlId: string }>()
);
export const EventsMemberDeleteFileErrorAction = createAction(
    '[events-member] Delete file error',
    props<{ error: HttpErrorResponse }>()
);

export const EventsMemberInvalidateCustomFilesCache = createAction(
    '[events-member] Invalidate custom files'
);

export const EventsMemberAddSessionAction = createAction(
    '[events-member] Add session',
    props<IInstitutionMemberProgramEventRegistrationParams & { eventSessionId: string }>()
);

export const EventsMemberRemoveSessionAction = createAction(
    '[events-member] Remove session',
    props<IInstitutionMemberProgramEventRegistrationParams & { eventSessionId: string }>()
);


export const EventsMemberSaveSessionsAction = createAction(
    '[events-member] Save sessions',
    props<IInstitutionMemberProgramEventRegistrationParams & { eventSessionIds: string[] }>()
);
export const EventsMemberSaveSessionsSuccessAction = createAction(
    '[events-member] Save sessions success',
    props<IInstitutionMemberProgramEventRegistrationParams & { eventSessionIds: string[] }>()
);
export const EventsMemberSaveSessionsErrorAction = createAction(
    '[events-member] Save sessions error',
    props<{ error: HttpErrorResponse }>()
);

export const EventsMemberDeleteRegistrationAction = createAction(
    '[events-member] Delete event registration',
    props<IInstitutionMemberProgramEventRegistrationParams>()
);
export const EventsMemberDeleteRegistrationSuccessAction = createAction(
    '[events-member] Delete event registration success',
    props<IInstitutionMemberProgramEventRegistrationParams>()
);
export const EventsMemberDeleteRegistrationErrorAction = createAction(
    '[events-member] Delete event registration error',
    props<{ error: HttpErrorResponse }>()
);

export const EventsMemberGetEventRegistraitonAninimalsAction = createAction(
    '[events-member] Get event registration animals',
    props<IInstitutionMemberProgramEventRegistrationParams>()
);
export const EventsMemberGetEventRegistraitonAninimalsSuccessAction = createAction(
    '[events-member] Get event registration animals success',
    props<IInstitutionMemberProgramEventRegistrationParams & { animals: IEnrollmentAnimalDto[] }>()
);
export const EventsMemberGetEventRegistraitonAninimalsErrorAction = createAction(
    '[events-member] Get event registration animals error',
    props<{ error: HttpErrorResponse }>()
);
export const EventsMemberSaveEventRegistraitonAninimalAction = createAction(
    '[events-member] Save event registration animals',
    props<IInstitutionMemberProgramEventRegistrationParams & { enrollmentAnimalId: string }>()
);
export const EventsMemberSaveEventRegistraitonAninimalSuccessAction = createAction(
    '[events-member] Save event registration animals success',
    props<IInstitutionMemberProgramEventRegistrationParams & { enrollmentAnimalId: string }>()
);
export const EventsMemberSaveEventRegistraitonAninimalErrorAction = createAction(
    '[events-member] Save event registration animals error',
    props<{ error: HttpErrorResponse }>()
);

// export const EventsMemberDeleteSessionAction = createAction(
//     '[events-member] Delete session',
//     props<IInstitutionMemberProgramEventRegistrationParams & { eventRegistrationSessionId: string }>()
// );
// export const EventsMemberDeleteSessionSuccessAction = createAction(
//     '[events-member] Delete session success',
//     props<IInstitutionMemberProgramEventRegistrationParams & { eventRegistrationSessionId: string }>()
// );
// export const EventsMemberDeleteSessionErrorAction = createAction(
//     '[events-member] Delete session error',
//     props<{ error: HttpErrorResponse }>()
// );