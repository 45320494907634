import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { AppState, getSystemManagerRollovers } from 'app/app.reducers';
import { SystemManagerActions } from 'app/shared/system-manager';
import { filter, map, Observable, of, take, tap } from 'rxjs';

import { IInstitutionProgramRolloverDescriptorDto, IInstitutionSystemManagerProgramParams } from '../models';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SystemManagerRolloverService {

  constructor(private store: Store<AppState>, private httpClient: HttpClient, private dispatcher: ActionsSubject) {
  }

  public getRollovers(params: IInstitutionSystemManagerProgramParams) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getSystemManagerRollovers(params)),
      tap(systemManager => {
        if (systemManager === undefined) {
          this.store.dispatch(SystemManagerActions.GetSystemManagerRolloversAction(params));
        }
      }),
      filter(systemManager => systemManager != null)
    );
  }

  public getRolloversEffect({ institutionId, systemManagerId, programId }: IInstitutionSystemManagerProgramParams) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/roll-overs`) as Observable<IInstitutionProgramRolloverDescriptorDto[]>;
  }

  public startRollover(params: IInstitutionSystemManagerProgramParams & { charterStatusUnderReview: boolean }) {
    this.store.dispatch(SystemManagerActions.StartSystemManagerRolloverAction(params));
    return this.dispatcher.pipe(
      ofType(SystemManagerActions.StartSystemManagerRolloverSuccessAction, SystemManagerActions.StartSystemManagerRolloverErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerActions.StartSystemManagerRolloverSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public startRolloverEffect({
    institutionId,
    systemManagerId,
    programId,
    charterStatusUnderReview
  }: IInstitutionSystemManagerProgramParams & { charterStatusUnderReview: boolean }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/roll-overs?charterStatusUnderReview=${charterStatusUnderReview}`, {});
  }
}
