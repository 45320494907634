import { IHierarchyACLDto } from '../IHierarchyACLDto';
import { eHierarchyNodeType } from './eHierarchyNodeType';

export interface IHierarchyNodeDto {
  parentHierarchyNodeId?: string;
  childrenHierarchyNodeIds?: string[];
  hierarchyNodeId: string;
  hierarchyNodeType: eHierarchyNodeType;
  hierarchyNodeName?: string;
  managers?: string[];
  contactEmail?: string;
  // I set this for managers in the hierarchy reducer
  acl?: IHierarchyACLDto;
  countyAreaId?: string;
  primary?: boolean;
  hasStripeAccount: boolean;
  stripeConfigurationError?: string;
  stripeAccountRequirements?: string;
}
