import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { userAccount } from 'app/core/containers/user/user.reducer';
import {
    AutoUnsubscribe,
    IDocumentationController,
    IHierarchyAny,
    IInstitutionProfile,
    IInstitutionProfileAuthDto,
    ILoginCredentials,
    IUserAccountDto
} from 'app/core/models';
import {
    CommonToastrService,
    DocumentationService,
    eLogLevel,
    HierarchyService,
    LoggingService,
    ProfileService,
    RouterService,
    UserService
} from 'app/core/services';
import { ProgramSettingsService } from 'app/core/services/program-settings.service';
import { filter, map, Observable, switchMap, take, takeUntil } from 'rxjs';

import { DebugService } from '../debug.service';
import frontEndVersion from '../../../../assets/version.json';
import { SystemService } from '../../../core/services/system.service';

@Component({
    selector: 'ng4h-modal-developer-tools',
    templateUrl: './modal-developer-tools.component.html',
    styleUrls: ['./modal-developer-tools.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalDeveloperToolsComponent extends AutoUnsubscribe implements OnInit {

    @ViewChild('textarea') textArea: ElementRef;

    public programSettings$: Observable<any>;
    public user: Observable<IUserAccountDto>;
    public authentication: Observable<ILoginCredentials>;
    public activeProfile: Observable<IInstitutionProfile>;
    public actingOnBehalfOf$: Observable<any>;
    public userProfile: Observable<IInstitutionProfile>;
    public institutionProfileAuth$: Observable<IInstitutionProfileAuthDto>;

    public apiVersion$: Observable<string>;
    public currentServer$: Observable<string>;

    public networkToolForm: FormGroup;
    public networkToolResult: object;
    public networkToolError: object;
    public networkToolHistory: any[] = [];
    public validJSON: boolean;

    public tabIndex = 0;

    public documentation: Observable<IDocumentationController[]>;

    public leaf$: Observable<IHierarchyAny>;
    public backendErrors$: Observable<any[]>;
    public frontendErrors$: Observable<any[]>;

    public superUserId$: Observable<string>;

    public idEncodeDecodeForm: FormGroup;
    public canDecode: boolean;
    public canEncode: boolean;

    constructor(
        private debugService: DebugService,
        private programSettings: ProgramSettingsService,
        private formBuilder: FormBuilder,
        private userService: UserService,
        private profileService: ProfileService,
        private hierarchyService: HierarchyService,
        private documentationService: DocumentationService,
        private routerService: RouterService,
        private dialogRef: MatDialogRef<ModalDeveloperToolsComponent>,
        private loggingService: LoggingService,
        private toastrService: CommonToastrService,
        private cdr: ChangeDetectorRef,
        private systemService: SystemService
    ) {
        super();

        this.programSettings$ = this.programSettings.getProgramSettings({
            institutionId: this.routerService.institutionId,
            programId: this.routerService.programId
        });

        this.authentication = this.userService.getLoginCredentials().pipe(takeUntil(this.autoUnsubscribe));
        this.institutionProfileAuth$ = this.profileService.getInstitutionProfileAuthToken(this.routerService.loggedInInstitutionProfileId);
        this.user = this.userService.userAccount$.pipe(takeUntil(this.autoUnsubscribe));
        this.activeProfile = this.profileService.loggedInAsInstitutionProfile.pipe(takeUntil(this.autoUnsubscribe));
        this.actingOnBehalfOf$ = this.profileService.actingAsInstitutionProfile;
        this.networkToolForm = this.formBuilder.group({
            type: 'GET',
            uri: '',
            body: ''
        });

        this.idEncodeDecodeForm = this.formBuilder.group({
            id: [null, Validators.required]
        });

        this.idEncodeDecodeForm.controls.id.valueChanges.pipe(
            takeUntil(this.autoUnsubscribe),
            map(id => String(id))
        ).subscribe(id => {

            if (id == null || id == '' || id.length < 1) {
                this.canEncode = false;
                this.canDecode = false;
            } else if (id.includes('_')) {
                this.canDecode = true;
                this.canEncode = false;
            } else {
                this.canDecode = false;
                this.canEncode = true;
            }
        });

        this.documentation = this.documentationService.documentation$.pipe(takeUntil(this.autoUnsubscribe));
        this.leaf$ = this.hierarchyService.selectedLeafNode$.pipe(takeUntil(this.autoUnsubscribe));

        this.superUserId$ = this.userService.userAccount$.pipe(
            map(ua => ua.superuserId),
            filter(superuserId => superuserId != null)
        );

        this.apiVersion$ = this.systemService.getApiVersion().pipe(take(1));
        this.currentServer$ = this.systemService.getServer().pipe(take(1));

        this.backendErrors$ = this.superUserId$.pipe(switchMap(superUserId => this.loggingService.getBackEndErrors(superUserId, eLogLevel.Error)));
        this.frontendErrors$ = this.superUserId$.pipe(switchMap(superUserId => this.loggingService.getFrontEndErrors(superUserId, eLogLevel.Error)));
    }

    ngOnInit() {

        this.networkToolForm.controls['body'].valueChanges.subscribe(value => {
            if (this.parseJSON(value) == null) {
                this.validJSON = false;
            } else {
                this.validJSON = true;
            }
        });
        this.networkToolForm.controls['uri'].valueChanges.subscribe((uri: string) => {
            let newUri = uri;
            if (newUri.includes('${institutionId}') && this.routerService.institutionId != null) {
                newUri = newUri.replace('${institutionId}', this.routerService.institutionId);
            }
            if (newUri.includes('${programId}') && this.routerService.programId != null) {
                newUri = newUri.replace('${programId}', this.routerService.programId);
            }
            if (newUri.includes('${managerHierarchyId}') && this.routerService.managerHierarchyId != null) {
                newUri = newUri.replace('${managerHierarchyId}', this.routerService.managerHierarchyId);
            }
            if (newUri.includes('${memberId}') && this.routerService.memberId != null) {
                newUri = newUri.replace('${memberId}', this.routerService.memberId);
            }
            if (newUri.includes('${enrollmentId}') && this.routerService.enrollmentId != null) {
                newUri = newUri.replace('${enrollmentId}', this.routerService.enrollmentId);
            }
            if (newUri.includes('${eventRegistrationId}') && this.routerService.eventRegistrationId != null) {
                newUri = newUri.replace('${eventRegistrationId}', this.routerService.eventRegistrationId);
            }
            if (newUri.includes('${managerId}') && this.routerService.managerId != null) {
                newUri = newUri.replace('${managerId}', this.routerService.managerId);
            }
            if (newUri.includes('${familyId}') && this.routerService.familyId != null) {
                newUri = newUri.replace('${familyId}', this.routerService.familyId);
            }
            if (newUri.includes('${eventId}') && this.routerService.eventId != null) {
                newUri = newUri.replace('${eventId}', this.routerService.eventId);
            }
            if (newUri.includes('${hierarchyNodeId}') && this.routerService.hierarchyNodeId != null) {
                newUri = newUri.replace('${hierarchyNodeId}', this.routerService.hierarchyNodeId);
            }
            if (newUri.includes('${superuserId}') && this.routerService.superuserId != null) {
                newUri = newUri.replace('${superuserId}', this.routerService.superuserId);
            }
            if (newUri.includes('${systemManagerId}') && this.routerService.systemManagerId != null) {
                newUri = newUri.replace('${systemManagerId}', this.routerService.systemManagerId);
            }

            if (newUri !== uri) {
                this.networkToolForm.controls['uri'].setValue(newUri);
                this.networkToolForm.controls['uri'].updateValueAndValidity();
            }
        });
    }

    public sidebarItemClicked(controllerName: string) {
        document.getElementById(controllerName).scrollIntoView();
    }

    public networkToolSend() {
        this.networkToolResult = null;
        this.networkToolError = null;
        const formValue = this.networkToolForm.value;

        this.getRequest(formValue).subscribe(res => {
                if (res == null) {
                    res = {};
                }
                this.networkToolResult = res;
                formValue.res = res;
                this.networkToolHistory.push(formValue);
            },
            error => {
                this.networkToolError = error;
                formValue.error = error;
                this.networkToolHistory.push(formValue);
            });

    }

    private getRequest(formValue): Observable<any> {
        switch (formValue.type) {
            case 'GET':
                return this.debugService.getRequest(formValue.uri);
            case 'POST':
                return this.debugService.postRequest(formValue.uri, this.parseJSON(formValue.body));
            case 'PUT':
                return this.debugService.putRequest(formValue.uri, this.parseJSON(formValue.body));
            case 'PATCH':
                return this.debugService.patchRequest(formValue.uri, this.parseJSON(formValue.body));
            case 'DELETE':
                return this.debugService.deleteRequest(formValue.uri, this.parseJSON(formValue.body));
        }
    }

    public close() {
        this.dialogRef.close();
        // this.modalService.closeModal();
    }

    public historyClicked(item) {
        this.networkToolForm.controls['type'].setValue(item.type);
        this.networkToolForm.controls['uri'].setValue(item.uri);
        this.networkToolForm.controls['body'].setValue(item.body);
        this.networkToolResult = item.res;
        this.networkToolError = item.error;
    }

    private parseJSON(str: string): object {
        try {
            return JSON.parse(str);
        } catch (e) {
            str = str.replace(/([a-z][^:]*)(?=\s*:)/g, '"$1"');
            try {
                return JSON.parse(str);
            } catch (e) {
                console.warn('Unable to parse JSON');
                return null;
            }
        }
    }

    public textareaTab(event: Event) {
        event.preventDefault();
        const val = this.networkToolForm.controls['body'].value;
        const start = this.textArea.nativeElement.selectionStart;
        const end = this.textArea.nativeElement.selectionEnd;

        let newValue = null;

        newValue = val.substring(0, start) + '\t' + val.substring(end);

        // this.textArea.nativeElement.selectionStart = this.textArea.nativeElement.selectionEnd = start + 1;
        this.networkToolForm.controls['body'].setValue(newValue);
    }

    public endpointClicked(verb: string, endpoint: string) {
        this.networkToolForm.controls['type'].setValue(verb);
        this.networkToolForm.controls['type'].updateValueAndValidity();
        this.networkToolForm.controls['uri'].setValue(endpoint);
        this.networkToolForm.controls['uri'].updateValueAndValidity();
        this.tabIndex = 1;
    }

    public selectClicked(hierarchy: IHierarchyAny) {

    }

    public saveProgramSettings() {

    }

    public encode() {
        this.debugService.encodeId(this.idEncodeDecodeForm.controls.id.value).pipe(
            take(1)
        ).subscribe(res => {
            this.idEncodeDecodeForm.controls.id.setValue(res);
            this.idEncodeDecodeForm.controls.id.updateValueAndValidity();
            this.toastrService.success('Encoded');
            this.cdr.detectChanges();
        }, error => this.toastrService.error('Error', error));
    }

    public decode() {
        this.debugService.decodeId(this.idEncodeDecodeForm.controls.id.value).pipe(
            take(1)
        ).subscribe(res => {
            this.idEncodeDecodeForm.controls.id.setValue(res);
            this.idEncodeDecodeForm.controls.id.updateValueAndValidity();
            this.toastrService.success('Decoded');
            this.cdr.detectChanges();
        }, error => this.toastrService.error('Error', error));
    }

    protected readonly frontEndVersion = frontEndVersion;
}
