import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'ng4h-sidebar-accordion-contents',
  templateUrl: './sidebar-accordion-contents.component.html',
  styleUrls: ['./sidebar-accordion-contents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarAccordionContentsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
