import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { eHierarchyNodeType, IInstitutionDiscountDto, IUnitDto } from 'app/core/models';
import { ManagerClubsService, ManagerFinancesService, ProgramYearService, RouterService } from 'app/core/services';
import { combineLatest, filter, map, Observable, startWith, switchMap } from 'rxjs';

@Component({
  selector: 'ng4h-modal-select-club',
  templateUrl: './modal-select-club.component.html',
  styleUrls: ['./modal-select-club.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalSelectClubComponent implements OnInit {

  public eHierarchyNodeType = eHierarchyNodeType;
  public clubs$: Observable<IUnitDto[]>;

  public dataSource = new MatTableDataSource();
  public displayedColumns = ['select', 'unitName'];

  public selection = new SelectionModel<IUnitDto>(true, []);

  public form: FormGroup;

  public discount$: Observable<IInstitutionDiscountDto>;

  constructor(private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private clubService: ManagerClubsService,
    private routerService: RouterService,
    private programYearService: ProgramYearService,
    private financeService: ManagerFinancesService) {

    this.form = this.fb.group({
      deliveryModeId: [],
      countyAreaId: []
    });
  }

  ngOnInit() {
    this.discount$ = this.financeService.getDiscount({
      institutionId: this.routerService.institutionId,
      managerId: this.routerService.managerId,
      programId: this.routerService.programId,
      institutionDiscountId: this.routerService.institutionDiscountId
    });

    this.clubs$ = combineLatest([
      this.programYearService.getUsersProgramYear({ institutionId: this.routerService.institutionId, programId: this.routerService.programId, institutionProfileId: this.routerService.loggedInInstitutionProfileId }),
      this.discount$,
      this.form.controls.deliveryModeId.valueChanges.pipe(startWith('')),
      this.form.controls.countyAreaId.valueChanges.pipe(startWith(''))
    ]).pipe(
      filter(([programYear, discount, deliveryModeId, countyAreaId]) => programYear != null && discount != null),
      switchMap(([programYear, discount, deliveryModeId, countyAreaId]) => {
        return this.clubService.getActiveClubs({ institutionId: this.routerService.institutionId, managerId: this.routerService.managerId, programId: this.routerService.programId, programYearId: programYear.programYearId }).pipe(
          filter(clubs => clubs != null),
          map(clubs => clubs.filter(club => {
            if (countyAreaId != null && countyAreaId.length > 0) {
              if (club.countyAreaId !== countyAreaId) {
                return false;
              }
            }
            let include = deliveryModeId == null || deliveryModeId.length < 1 || club.deliveryModeId === deliveryModeId;
            // https://regmax.atlassian.net/browse/VH4-339
            if (include) {
              include = discount.fund == null || discount.fund.hierarchyNode == null || discount.fund.hierarchyNode.hierarchyNodeType !== eHierarchyNodeType.CountyArea || club.parentHierarchyNodeId === discount.fund.hierarchyNode.hierarchyNodeId;
            }
            return include;
          }))
        );
      })
    );

    this.clubs$.subscribe(clubs => this.dataSource.data = clubs);
  }

  public isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  public masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach((row: IUnitDto) => {
        this.selection.select(row);
      });
  }

  public close(): void {
    this.dialogRef.close();
  }

  public save(): void {
    this.dialogRef.close(this.selection.selected);
  }

  public trackBy(index: number, club: IUnitDto) {
    return club.unitId;
  }
}
