import { eLocalizationType, eProfileType, IAddressDto, IFamilyMemberDto, IPaymentMethodDto } from '..';

export interface IFamilyDto {
    familyId: string;
    addressVerified: boolean;
    profileId: string;
    profileType: eProfileType.Family;
    countyId: string;
    countyHierarchyNodeId: string;
    familyMembers?: IFamilyMemberDto[];
    paymentMethods?: IPaymentMethodDto[];
    address: IAddressDto;
    familyName: string;
    name: string;
    primaryMobilePhone?: string;
    profileToken: string;
    institutionProfileId: string;
    institutionId: string;
    county?: string;
    countyAreaId: string;
    countyArea?: string;
    email?: string;
    hasUserAccount: boolean;
    sitePassword?: string;
    unsubscribed: boolean;
    localization?: eLocalizationType;
}