import { IManagerNoteEmailDto, IProfileDescriptorDto } from '.';
import { eManagerNoteDirection, eManagerNoteType } from '..';

export interface IManagerNoteDto {
    profileNoteId: string;
    dateCreated: string;
    hasFile: boolean;
    fileResourceLink?: string;
    email: IManagerNoteEmailDto;
    createdBy: IProfileDescriptorDto;
    noteType: eManagerNoteType;
    direction: eManagerNoteDirection;
    noteDate?: string;
    subject?: string;
    publicNote?: string;
    privateNote?: string;
}
