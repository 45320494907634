import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  IClubCharterUpdateDto,
  IInstitutionManagerProgramParams,
  IUnitCreateDto,
  IUnitDto,
  IUnitProgramYearUpdateDto,
  IUnitUpdateDto,
  IVolunteerACLDto,
} from 'app/core/models';

export const ManagerLoadUnitsAction = createAction(
  '[manager-unit] load units',
  props<IInstitutionManagerProgramParams>()
);

export const ManagerLoadUnitsSuccessAction = createAction(
  '[manager-unit] load units success',
  props<IInstitutionManagerProgramParams & { units: IUnitDto[] }>()
);

export const ManagerLoadUnitsErrorAction = createAction(
  '[manager-unit] load units error',
  props<{ error?: any }>()
);

export const ManagerLoadUnitsByProgramYearAction = createAction(
  '[manager-unit] load units by program year',
  props<IInstitutionManagerProgramParams & { programYearId: string, countyAreaId: string }>()
);

export const ManagerLoadUnitsByProgramYearSuccessAction = createAction(
  '[manager-unit] load units by program year success',
  props<IInstitutionManagerProgramParams & { programYearId: string, countyAreaId: string, units: IUnitDto[] }>()
);

export const ManagerLoadUnitsByProgramYearErrorAction = createAction(
  '[manager-unit] load units by program year error',
  props<{ error?: any }>()
);

export const ManagerLoadUnitsNotInProgramYearAction = createAction(
  '[manager-unit] load units not in program year',
  props<IInstitutionManagerProgramParams & { programYearId: string, countyAreaId: string }>()
);

export const ManagerLoadUnitsNotInProgramYearSuccessAction = createAction(
  '[manager-unit] load units not in program year success',
  props<IInstitutionManagerProgramParams & { programYearId: string, countyAreaId: string, units: IUnitDto[] }>()
);

export const ManagerLoadUnitsNotInProgramYearErrorAction = createAction(
  '[manager-unit] load units not in program year error',
  props<{ error?: any }>()
);

export const ManagerLoadUnitAction = createAction(
  '[manager-unit] load unit',
  props<IInstitutionManagerProgramParams & { unitId: string }>()
);

export const ManagerLoadUnitSuccessAction = createAction(
  '[manager-unit] load unit success',
  props<IInstitutionManagerProgramParams & { unitId: string, unit: IUnitDto }>()
);

export const ManagerLoadUnitErrorAction = createAction(
  '[manager-unit] load unit error',
  props<{ error?: any }>()
);

export const ManagerCreateUnitAction = createAction(
  '[manager-unit] create unit',
  props<IInstitutionManagerProgramParams & { programYearId: string, newClub: IUnitCreateDto }>()
);

export const ManagerCreateUnitSuccessAction = createAction(
  '[manager-unit] create unit success',
  props<IInstitutionManagerProgramParams & { programYearId: string, newClub: IUnitCreateDto, unit: IUnitDto }>()
);

export const ManagerCreateUnitErrorAction = createAction(
  '[manager-unit] create unit error',
  props<{ error?: any }>()
);

export const ManagerUpdateUnitAction = createAction(
  '[manager-unit] update manager-unit',
  props<IInstitutionManagerProgramParams & { unitId: string, unit: IUnitUpdateDto }>()
);

export const ManagerUpdateUnitSuccessAction = createAction(
  '[manager-unit] update manager-unit success',
  props<IInstitutionManagerProgramParams & { unitId: string, unit: IUnitUpdateDto }>()
);

export const ManagerUpdateUnitErrorAction = createAction(
  '[manager-unit] update manager-unit error',
  props<{ error?: any }>()
);

export const RemoveClubFromProgramYearAction = createAction(
  '[manager-unit] delete manager-unit',
  props<IInstitutionManagerProgramParams & { unitId: string }>()
);

export const ManagerDeleteUnitSuccessAction = createAction(
  '[manager-unit] delete manager-unit success',
  props<IInstitutionManagerProgramParams & { unitId: string }>()
);

export const ManagerDeleteUnitErrorAction = createAction(
  '[manager-unit] delete manager-unit error',
  props<{ error?: any }>()
);

export const ManagerLinkProjectAction = createAction(
  '[manager-unit] link project to unit',
  props<IInstitutionManagerProgramParams & { unitId: string, projects: Array<string> }>()
);

export const ManagerLinkProjectSuccessAction = createAction(
  '[manager-unit] link project to unit success',
  props<IInstitutionManagerProgramParams & { unitId: string, projects: Array<string> }>()
);

export const ManagerLinkProjectErrorAction = createAction(
  '[manager-unit] link project to unit error',
  props<{ error?: any }>()
);

export const ManagerUnlinkProjectAction = createAction(
  '[manager-unit] unlink project to unit',
  props<IInstitutionManagerProgramParams & { unitId: string, projectId: string }>()
);

export const ManagerUnlinkProjectSuccessAction = createAction(
  '[manager-unit] unlink project to unit success',
  props<IInstitutionManagerProgramParams & { unitId: string, projectId: string }>()
);

export const ManagerUnlinkProjectErrorAction = createAction(
  '[manager-unit] unlink project to unit error',
  props<{ error: HttpErrorResponse }>()
);

export const ManagerInvalidateCacheAction = createAction(
  '[manager-unit] invalidate units cache'
);

export const ManagerInvalidateUnitCacheAction = createAction(
  '[manager-unit] invalidate unit cache'
);

export const ManagerGetCountiesUnitsAction = createAction(
  '[manager-unit] get units from multiple counties',
  props<IInstitutionManagerProgramParams & { countyAreaIds: string[] }>()
);

export const ManagerGetCountiesUnitsSuccessAction = createAction(
  '[manager-unit] get units from multiple counties success',
  props<IInstitutionManagerProgramParams & { countyAreaIds: string[], units: IUnitDto[] }>()
);

export const ManagerGetCountiesUnitsErrorAction = createAction(
  '[manager-unit] get units from multiple counties success',
  props<{ error: HttpErrorResponse }>()
);

export const ManagerUpdateClubCharteringAction = createAction(
  '[manager-unit] update club chartering',
  props<IInstitutionManagerProgramParams & { programYearId: string, unitId: string, club: IClubCharterUpdateDto }>()
);

export const ManagerUpdateClubCharteringSuccessAction = createAction(
  '[manager-unit] update club chartering success',
  props<IInstitutionManagerProgramParams & { programYearId: string, unitId: string, club: IClubCharterUpdateDto }>()
);

export const ManagerUpdateClubCharteringErrorAction = createAction(
  '[manager-unit] update club chartering error',
  props<{ error: HttpErrorResponse }>()
);

export const ManagerGetClubCharteringFileAction = createAction(
  '[manager-unit] get club chartering',
  props<IInstitutionManagerProgramParams & { unitId: string, programYearId: string, fileId: string }>()
);

export const ManagerGetClubCharteringFileSuccessAction = createAction(
  '[manager-unit] get club chartering success',
  props<IInstitutionManagerProgramParams & { unitId: string, programYearId: string, fileId: string, chartering: any }>()
);

export const ManagerGetClubCharteringFileErrorAction = createAction(
  '[manager-unit] get club chartering error',
  props<{ error: HttpErrorResponse }>()
);

export const ManagerUpdateClubCharteringDocumentAction = createAction(
  '[manager-unit] update club chartering document',
  props<IInstitutionManagerProgramParams & { programYearId: string, fileControlId: string, unitId: string, file: any }>()
);

export const ManagerUpdateClubCharteringDocumentSuccessAction = createAction(
  '[manager-unit] update club chartering document success',
  props<IInstitutionManagerProgramParams & { programYearId: string, fileControlId: string, unitId: string, file: any }>()
);

export const ManagerUpdateClubCharteringDocumentErrorAction = createAction(
  '[manager-unit] update club chartering document error',
  props<{ error: HttpErrorResponse }>()
);

export const ManagerDeleteClubCharteringDocumentAction = createAction(
  '[manager-unit] delete club chartering document',
  props<IInstitutionManagerProgramParams & { programYearId: string, fileControlId: string, unitId: string }>()
);

export const ManagerDeleteClubCharteringDocumentSuccessAction = createAction(
  '[manager-unit] delete club chartering document success',
  props<IInstitutionManagerProgramParams & { programYearId: string, fileControlId: string, unitId: string }>()
);

export const ManagerDeleteClubCharteringDocumentErrorAction = createAction(
  '[manager-unit] delete club chartering document error',
  props<{ error: HttpErrorResponse }>()
);

export const ManagerGetActiveUnitsAction = createAction(
  '[manager-unit] get active clubs',
  props<IInstitutionManagerProgramParams & { programYearId: string }>()
);

export const ManagerGetActiveUnitsSuccessAction = createAction(
  '[manager-unit] get active clubs success',
  props<IInstitutionManagerProgramParams & { programYearId: string, units: IUnitDto[] }>()
);

export const ManagerGetActiveUnitsErrorAction = createAction(
  '[manager-unit] get active clubs error',
  props<{ error: HttpErrorResponse }>()
);


export const ManagerUpdateEnrollmentRestrictionsAction = createAction(
  '[manager-unit] update enrollment Restrictions',
  props<IInstitutionManagerProgramParams & { unitId: string, programYearId: string, update: IUnitProgramYearUpdateDto }>()
);

export const ManagerUpdateEnrollmentRestrictionsSuccessAction = createAction(
  '[manager-unit] update enrollment Restrictions success',
  props<IInstitutionManagerProgramParams & { unitId: string, programYearId: string, update: IUnitProgramYearUpdateDto }>()
);

export const ManagerUpdateEnrollmentRestrictionsErrorAction = createAction(
  '[manager-unit] update enrollment Restrictions error',
  props<{ error: HttpErrorResponse }>()
);

export const ManagerUpdateUnitLeaderPermissionsAction = createAction(
  '[manager-unit] update unit leader permissions',
  props<IInstitutionManagerProgramParams & { unitLeaderId: string, permissions: IVolunteerACLDto }>()
);

export const ManagerUpdateUnitLeaderPermissionsSuccessAction = createAction(
  '[manager-unit] update unit leader permissions success',
  props<IInstitutionManagerProgramParams & { unitLeaderId: string, permissions: IVolunteerACLDto }>()
);

export const ManagerUpdateUnitLeaderPermissionsErrorAction = createAction(
  '[manager-unit] update unit leader permissions error',
  props<{ error: HttpErrorResponse }>()
);

export const ManagerLinkUnitToProgramYearAction = createAction(
  '[manager-unit] link unit to program year',
  props<IInstitutionManagerProgramParams & { programYearId: string, unitIds: string[] }>()
);

export const ManagerLinkUnitToProgramYearSuccessAction = createAction(
  '[manager-unit] link unit to program year success',
  props<IInstitutionManagerProgramParams & { programYearId: string, unitIds: string[] }>()
);

export const ManagerLinkUnitToProgramYearErrorAction = createAction(
  '[manager-unit] link unit to program year error',
  props<{ error: HttpErrorResponse }>()
);

export const ManagerUnLinkUnitToProgramYearAction = createAction(
  '[manager-unit] unlink unit to program year',
  props<IInstitutionManagerProgramParams & { unitId: string, programYearId: string }>()
);

export const ManagerUnLinkUnitToProgramYearSuccessAction = createAction(
  '[manager-unit] unlink unit to program year success',
  props<IInstitutionManagerProgramParams & { unitId: string, programYearId: string }>()
);

export const ManagerUnLinkUnitToProgramYearErrorAction = createAction(
  '[manager-unit] unlink unit to program year error',
  props<{ error: HttpErrorResponse }>()
);

export const SetClubSearchCountyAreaIdAction = createAction(
  '[manager-unit] Set club search county area id',
  props<{ countyAreaId: string }>()
);
