import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  ICountyAreaHierarchyNodeCreateDto,
  IHierarchyCountyArea,
  IHierarchyDistrictArea,
  IHierarchyDto,
  IHierarchyInstitution,
  IHierarchyNodeCreateDto,
  IHierarchyNodeUpdateDto,
  IHierarchyRegionArea,
  IManagerProgramHierarchyForDisplayDto,
  ISuperuserInstitutionParams,
} from 'app/core/models';


export const HierarchyLoadAction = createAction(
  '[hierarchy] load',
  props<{ institutionId: string }>()
);
export const HierarchyLoadSuccessAction = createAction(
  '[hierarchy] load success',
  props<{ hierarchy: IHierarchyDto, institutionId: string }>()
);
export const HierarchyLoadErrorAction = createAction(
  '[hierarchy] load error',
  props<{ error: HttpErrorResponse }>()
);
export const HierarchySetManagerAction = createAction(
  '[hierarchy] Set Manager',
  props<{ institutionId: string, programId: string, managerId: string, hierarchyAssociations: IManagerProgramHierarchyForDisplayDto[] }>()
);

export const HierarchyInstitutionChangedAction = createAction(
  '[hierarchy] Institution changed',
);
export const HierarchySelectInstitutionAction = createAction(
  '[hierarchy] Select institution area',
  props<{ selectedInstitutionArea: IHierarchyInstitution }>()
);
export const HierarchySelectRegionAreaAction = createAction(
  '[hierarchy] Select region area',
  props<{ institutionId: string, programId?: string, managerId?: string, selectedRegionArea: IHierarchyRegionArea }>()
);
export const HierarchySelectDistrictAreaAction = createAction(
  '[hierarchy] Select district area',
  props<{ institutionId: string, programId?: string, managerId?: string, selectedDistrictArea: IHierarchyDistrictArea }>()
);
export const HierarchySelectCountyAreaAction = createAction(
  '[hierarchy] Select county area',
  props<{ institutionId: string, programId?: string, managerId?: string, selectedCountyArea: IHierarchyCountyArea }>()
);

export const UpdateHierarchyNodeAction = createAction(
  '[hierarchy] Update hierarchy node',
  props<ISuperuserInstitutionParams & { hierarchyNodeId: string, update: IHierarchyNodeUpdateDto }>()
);
export const UpdateHierarchyNodeSuccessAction = createAction(
  '[hierarchy] Update hierarchy node success',
  props<ISuperuserInstitutionParams & { hierarchyNodeId: string, update: IHierarchyNodeUpdateDto }>()
);
export const UpdateHierarchyNodeErrorAction = createAction(
  '[hierarchy] Update hierarchy node error',
  props<{ error: HttpErrorResponse }>()
);

export const ChangeHierarchyNodeParentAction = createAction(
  '[hierarchy] Change node parent',
  props<ISuperuserInstitutionParams & { hierarchyNodeId: string, moveToParentHierarchyNodeId: string }>()
);
export const ChangeHierarchyNodeParentSuccessAction = createAction(
  '[hierarchy] Change node parent success',
  props<ISuperuserInstitutionParams & { hierarchyNodeId: string, moveToParentHierarchyNodeId: string }>()
);
export const ChangeHierarchyNodeParentErrorAction = createAction(
  '[hierarchy] Change node parent error',
  props<{ error: HttpErrorResponse }>()
);

export const AddCountyAction = createAction(
  '[hierarchy] Add county',
  props<ISuperuserInstitutionParams & { districtAreaId: string, nodeCreate: ICountyAreaHierarchyNodeCreateDto }>()
);
export const AddCountySuccessAction = createAction(
  '[hierarchy] Add county success',
  props<ISuperuserInstitutionParams & { districtAreaId: string, nodeCreate: ICountyAreaHierarchyNodeCreateDto }>()
);
export const AddCountyErrorAction = createAction(
  '[hierarchy] Add county error',
  props<{ error: HttpErrorResponse }>()
);

export const AddDistrictAction = createAction(
  '[hierarchy] Add district',
  props<ISuperuserInstitutionParams & { regionAreaId: string, nodeCreate: IHierarchyNodeCreateDto }>()
);
export const AddDistrictSuccessAction = createAction(
  '[hierarchy] Add district success',
  props<ISuperuserInstitutionParams & { regionAreaId: string, nodeCreate: IHierarchyNodeCreateDto }>()
);
export const AddDistrictErrorAction = createAction(
  '[hierarchy] Add district error',
  props<{ error: HttpErrorResponse }>()
);

export const AddRegionAction = createAction(
  '[hierarchy] Add region',
  props<ISuperuserInstitutionParams & { nodeCreate: IHierarchyNodeCreateDto }>()
);
export const AddRegionSuccessAction = createAction(
  '[hierarchy] Add region success',
  props<ISuperuserInstitutionParams & { nodeCreate: IHierarchyNodeCreateDto }>()
);
export const AddRegionErrorAction = createAction(
  '[hierarchy] Add region error',
  props<{ error: HttpErrorResponse }>()
);

export const ActivateNodeAction = createAction(
  '[hierarchy] Activate node',
  props<ISuperuserInstitutionParams & { hierarchyNodeId: string }>()
);
export const ActivateNodeSuccessAction = createAction(
  '[hierarchy] Activate node success',
  props<ISuperuserInstitutionParams & { hierarchyNodeId: string }>()
);
export const ActivateNodeErrorAction = createAction(
  '[hierarchy] Activate node error',
  props<{ error: HttpErrorResponse }>()
);

export const DeleteNodeAction = createAction(
  '[hierarchy] Delete node',
  props<ISuperuserInstitutionParams & { hierarchyNodeId: string }>()
);
export const DeleteNodeSuccessAction = createAction(
  '[hierarchy] Delete node success',
  props<ISuperuserInstitutionParams & { hierarchyNodeId: string }>()
);
export const DeleteNodeErrorAction = createAction(
  '[hierarchy] Delete node error',
  props<{ error: HttpErrorResponse }>()
);

export const ArchiveNodeAction = createAction(
  '[hierarchy] Archive node',
  props<ISuperuserInstitutionParams & { hierarchyNodeId: string }>()
);
export const ArchiveNodeSuccessAction = createAction(
  '[hierarchy] Archive node success',
  props<ISuperuserInstitutionParams & { hierarchyNodeId: string }>()
);
export const ArchiveNodeErrorAction = createAction(
  '[hierarchy] Archive node error',
  props<{ error: HttpErrorResponse }>()
);

export const HierarchyInvalidateCacheAction = createAction(
  '[hierarchy] Invalidate cache'
);

