import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import {
  eEnrollmentRoleType,
  eInstitutionDiscountStatus,
  eInstitutionDiscountType,
  eInvoiceDiscountFeePercentage,
  eInvoiceDiscountType,
  IDiscountFundDescriptorDto,
  IInstitutionDiscountCreateDto,
} from 'app/core/models';
import { ManagerFinancesService, RouterService } from 'app/core/services';
import { Observable } from 'rxjs';

@Component({
  selector: 'ng4h-modal-create-coupon',
  templateUrl: './modal-create-coupon.component.html',
  styleUrls: ['./modal-create-coupon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalCreateCouponComponent implements OnInit {

  public funds$: Observable<IDiscountFundDescriptorDto[]>;

  public form: FormGroup;

  public eInstitutionDiscountType = eInstitutionDiscountType;
  public eInvoiceDiscountFeePercentage = eInvoiceDiscountFeePercentage;
  public eInvoiceDiscountType = eInvoiceDiscountType;
  public eInstitutionDiscountStatus = eInstitutionDiscountStatus;

  public eEnrollmentRoleType = eEnrollmentRoleType;

  constructor(private dialogRef: MatDialogRef<any>,
    private fb: FormBuilder,
    private financeService: ManagerFinancesService,
    private routerService: RouterService) { }

  ngOnInit() {
    this.funds$ = this.financeService.getFunds({
      institutionId: this.routerService.institutionId,
      managerId: this.routerService.managerId,
      programId: this.routerService.programId,
    });

    this.form = this.fb.group({
      fundId: [null, Validators.required],
      enrollmentRole: [null, Validators.required],
      institutionDiscountType: [null, Validators.required],
      feePercentage: [null, Validators.required],
      invoiceDiscountType: [null, Validators.required],
      status: [null, Validators.required],
      description: [null, Validators.required],
    });
  }

  public close(coupon?: IInstitutionDiscountCreateDto): void {
    this.dialogRef.close(coupon);
  }

  public create(): void {
    const { enrollmentRole, ...coupon } = this.form.value;
    this.close({ ...coupon, enrollmentRoles: [this.form.value.enrollmentRole] });
  }

  public retainOrder() {
    return 0;
  }
}
