import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
    IInstitutionSystemManagerParams,
    ISchoolCreateDto,
    ISchoolDistrictCreateDto,
    ISchoolDistrictDto,
    ISchoolDistrictUpdateDto,
    ISchoolDto,
    ISchoolUpdateDto,
} from 'app/core/models';

// export const SystemManagerGetSchoolsAction = createAction(
//     '[system manager finances] get schools',
//     props<IInstitutionSystemManagerParams>()
// );

// export const SystemManagerGetSchoolsSuccessAction = createAction(
//     '[system manager finances] get schools success',
//     props<IInstitutionSystemManagerParams & { districtsWithSchools: ISchoolDistrictDto[] }>()
// );

// export const SystemManagerGetSchoolsErrorAction = createAction(
//     '[system manager finances] get schools error',
//     props<{ error: HttpErrorResponse }>()
// );


export const SystemManagerGetSchoolAction = createAction(
    '[system manager finances] get school',
    props<IInstitutionSystemManagerParams & { schoolId: string }>()
);

export const SystemManagerGetSchoolSuccessAction = createAction(
    '[system manager finances] get school success',
    props<IInstitutionSystemManagerParams & { schoolId: string, school: ISchoolDto }>()
);

export const SystemManagerGetSchoolErrorAction = createAction(
    '[system manager finances] get school error',
    props<{ error: HttpErrorResponse }>()
);

export const SystemManagerCreateSchoolAction = createAction(
    '[system manager finances] create school',
    props<IInstitutionSystemManagerParams & { schoolCreateDo: ISchoolCreateDto }>()
);

export const SystemManagerCreateSchoolSuccessAction = createAction(
    '[system manager finances] create school success',
    props<IInstitutionSystemManagerParams & { schoolCreateDo: ISchoolCreateDto }>()
);

export const SystemManagerCreateSchoolErrorAction = createAction(
    '[system manager finances] create school error',
    props<{ error: HttpErrorResponse }>()
);

export const SystemManagerUpdateSchoolAction = createAction(
    '[system manager finances] update school',
    props<IInstitutionSystemManagerParams & { schoolId: string, schoolUpdateDto: ISchoolUpdateDto }>()
);

export const SystemManagerUpdateSchoolSuccessAction = createAction(
    '[system manager finances] update school success',
    props<IInstitutionSystemManagerParams & { schoolId: string, schoolUpdateDto: ISchoolUpdateDto }>()
);

export const SystemManagerUpdateSchoolErrorAction = createAction(
    '[system manager finances] update school error',
    props<{ error: HttpErrorResponse }>()
);

export const SystemManagerDeleteSchoolAction = createAction(
    '[system manager finances] delete school',
    props<IInstitutionSystemManagerParams & { schoolId: string }>()
);

export const SystemManagerDeleteSchoolSuccessAction = createAction(
    '[system manager finances] delete school success',
    props<IInstitutionSystemManagerParams & { schoolId: string }>()
);

export const SystemManagerDeleteSchoolErrorAction = createAction(
    '[system manager finances] delete school error',
    props<{ error: HttpErrorResponse }>()
);










export const SystemManagerGetDistrictsAction = createAction(
    '[system manager finances] get districts',
    props<IInstitutionSystemManagerParams>()
);

export const SystemManagerGetDistrictsSuccessAction = createAction(
    '[system manager finances] get districts success',
    props<IInstitutionSystemManagerParams & { districts: ISchoolDistrictDto[] }>()
);

export const SystemManagerGetDistrictsErrorAction = createAction(
    '[system manager finances] get districts error',
    props<{ error: HttpErrorResponse }>()
);


export const SystemManagerGetDistrictAction = createAction(
    '[system manager finances] get district',
    props<IInstitutionSystemManagerParams & { schoolDistrictId: string }>()
);

export const SystemManagerGetDistrictSuccessAction = createAction(
    '[system manager finances] get district success',
    props<IInstitutionSystemManagerParams & { schoolDistrictId: string, district: ISchoolDistrictDto }>()
);

export const SystemManagerGetDistrictErrorAction = createAction(
    '[system manager finances] get district error',
    props<{ error: HttpErrorResponse }>()
);

export const SystemManagerCreateDistrictAction = createAction(
    '[system manager finances] create district',
    props<IInstitutionSystemManagerParams & { schoolDistrictCreateDto: ISchoolDistrictCreateDto }>()
);

export const SystemManagerCreateDistrictSuccessAction = createAction(
    '[system manager finances] create district success',
    props<IInstitutionSystemManagerParams & { schoolDistrictCreateDto: ISchoolDistrictCreateDto }>()
);

export const SystemManagerCreateDistrictErrorAction = createAction(
    '[system manager finances] create district error',
    props<{ error: HttpErrorResponse }>()
);

export const SystemManagerUpdateDistrictAction = createAction(
    '[system manager finances] update district',
    props<IInstitutionSystemManagerParams & { schoolDistrictId: string, schoolDistrictUpdateDto: ISchoolDistrictUpdateDto }>()
);

export const SystemManagerUpdateDistrictSuccessAction = createAction(
    '[system manager finances] update district success',
    props<IInstitutionSystemManagerParams & { schoolDistrictId: string, schoolDistrictUpdateDto: ISchoolDistrictUpdateDto }>()
);

export const SystemManagerUpdateDistrictErrorAction = createAction(
    '[system manager finances] update district error',
    props<{ error: HttpErrorResponse }>()
);

export const SystemManagerDeleteDistrictAction = createAction(
    '[system manager finances] delete district',
    props<IInstitutionSystemManagerParams & { schoolDistrictId: string }>()
);

export const SystemManagerDeleteDistrictSuccessAction = createAction(
    '[system manager finances] delete district success',
    props<IInstitutionSystemManagerParams & { schoolDistrictId: string }>()
);

export const SystemManagerDeleteDistrictErrorAction = createAction(
    '[system manager finances] delete district error',
    props<{ error: HttpErrorResponse }>()
);
