import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
    IInstitutionManagerProgramHierarchyParams, IInstitutionManagerProgramParams,
    IInstitutionSystemManagerProgramParams,
    IStripeConnectDto
} from 'app/core/models';
import { IStripeRequirementsDto } from '../../../../models/serverDTOs/IStripeRequirementsDto';

export const StripeConnectManagerAction = createAction(
    '[stripe] Connect manager',
    props<IInstitutionManagerProgramHierarchyParams & { connectDto: IStripeConnectDto }>()
);
export const StripeConnectManagerSuccessAction = createAction(
    '[stripe] Connect manager success',
    props<IInstitutionManagerProgramHierarchyParams & { connectDto: IStripeConnectDto, location: string }>()
);
export const StripeConnectManagerErrorAction = createAction(
    '[stripe] Connect manager error',
    props<{ error: HttpErrorResponse }>()
);
export const StripeConnectManagerUpdateAction = createAction(
    '[stripe] Connect manager update',
    props<IInstitutionManagerProgramHierarchyParams & { connectDto: IStripeConnectDto }>()
);
export const StripeConnectManagerUpdateSuccessAction = createAction(
    '[stripe] Connect manager update success',
    props<IInstitutionManagerProgramHierarchyParams & { connectDto: IStripeConnectDto, location: string }>()
);
export const StripeConnectManagerUpdateErrorAction = createAction(
    '[stripe] Connect manager update error',
    props<{ error: HttpErrorResponse }>()
);

export const StripeConnectSystemManagerAction = createAction(
    '[stripe] Connect system manager',
    props<IInstitutionSystemManagerProgramParams & { connectDto: IStripeConnectDto }>()
);
export const StripeConnectSystemManagerSuccessAction = createAction(
    '[stripe] Connect system manager success',
    props<IInstitutionSystemManagerProgramParams & { connectDto: IStripeConnectDto, location: string }>()
);
export const StripeConnectSystemManagerErrorAction = createAction(
    '[stripe] Connect system manager error',
    props<{ error: HttpErrorResponse }>()
);
export const StripeConnectSystemManagerUpdateAction = createAction(
    '[stripe] Connect system manager update',
    props<IInstitutionSystemManagerProgramParams & { connectDto: IStripeConnectDto }>()
);
export const StripeConnectSystemManagerUpdateSuccessAction = createAction(
    '[stripe] Connect system manager update success',
    props<IInstitutionSystemManagerProgramParams & { connectDto: IStripeConnectDto, location: string }>()
);
export const StripeConnectSystemManagerUpdateErrorAction = createAction(
    '[stripe] Connect system manager update error',
    props<{ error: HttpErrorResponse }>()
);

export const ManagerStripeStatusLoadAction = createAction(
    '[stripe] account status load',
    props<IInstitutionManagerProgramHierarchyParams>()
);
export const ManagerStripeStatusLoadSuccessAction = createAction(
    '[stripe] account status load success',
    props<IInstitutionManagerProgramParams & { hierarchyNodeId: string, status: IStripeRequirementsDto[] }>()
);
export const ManagerStripeStatusLoadErrorAction = createAction(
    '[stripe] account status load error',
    props<{ error: HttpErrorResponse }>()
);
