import { AbstractControl, ValidatorFn } from '@angular/forms';

export function limitChoicesValidator(arg: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (control.value == null) {
      return null;
    }

    if (Object.keys(control.value).length != null) {
      const values = Object.keys(control.value).map(key => control.value[key]);
      const choicesLength = values.filter(v => v === true || v === 'True').length;
      return choicesLength > arg ? { [`limited to ${arg} choices`]: { value: control.value } } : null;
    }
    return null;

  };
}
