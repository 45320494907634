import { eLoginStatus, eLoginType } from '..';

export interface IUserAccountLoginDto {
    LoginAuditId: string;
    loginType: eLoginType;
    loginStatus: eLoginStatus;
    email?: string;
    ipAddress?: string;
    dateStamp: string;
}
