import { Pipe, PipeTransform } from '@angular/core';
import { IBankAccountDescriptorDto } from 'app/core/models';
import { BankAccountsService, RouterService } from 'app/core/services';
import { filter, map, Observable } from 'rxjs';

@Pipe({
    name: 'managerBankAccount$',
    pure: true
})
export class ManagerBankAccountPipe implements PipeTransform {
    constructor(private bankAccountsService: BankAccountsService, private routerService: RouterService) {
    }

    public transform(bankAccountId: string,
        institutionId: string = this.routerService.institutionId,
        managerId: string = this.routerService.managerId,
        programId: string = this.routerService.programId,
        hierarchyNodeId: string = this.routerService.managerHierarchyId
    ): Observable<IBankAccountDescriptorDto> {
        return this.bankAccountsService.getManagerBankAccounts({
            institutionId,
            managerId,
            programId,
            hierarchyNodeId
        }).pipe(
            filter(bankAccounts => bankAccounts != null && Array.isArray(bankAccounts)),
            map(bankAccounts => bankAccounts.find(bankAccount => bankAccount.bankAccountId === bankAccountId))
        );
    }
}
