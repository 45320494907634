import { Action, createReducer, on } from '@ngrx/store';
import {
  IBankAccountDto,
  IInstitutionManagerProgramHierarchyModel,
  IInstitutionManagerProgramModel,
  IInstitutionSystemManagerProgramModel,
} from 'app/core/models';
import { mergeImmutable } from 'app/shared/utils';

import { BankAccountActions } from '.';


export interface BankAccountsState {
    managerBankAccounts: IInstitutionManagerProgramHierarchyModel<IBankAccountDto[]>;
    managerBankAccount: IInstitutionManagerProgramModel<{ [bankAccountId: string]: IBankAccountDto }>;
    systemManagerBankAccounts: IInstitutionSystemManagerProgramModel<IBankAccountDto[]>;
}

const initialState: BankAccountsState = {
    managerBankAccounts: undefined,
    managerBankAccount: undefined,
    systemManagerBankAccounts: undefined
};

const reducer = createReducer(
    initialState,
    on(BankAccountActions.BankAccountsManagerLoadSuccessAction, (state, { institutionId, managerId, programId, hierarchyNodeId, bankAccounts }) => {
        const newManagerBankAccounts = mergeImmutable({ [institutionId]: { [managerId]: { [programId]: { [hierarchyNodeId]: bankAccounts } } } },
            state.managerBankAccounts
        );

        return {
            ...state,
            managerBankAccounts: newManagerBankAccounts
        };
    }),
    on(BankAccountActions.BankAccountManagerLoadSuccessAction, (state, { institutionId, managerId, programId, bankAccountId, bankAccount }) => {
        const newManagerBankAccount = mergeImmutable({ [institutionId]: { [managerId]: { [programId]: { [bankAccountId]: bankAccount } } } },
            state.managerBankAccount
        );

        return {
            ...state,
            managerBankAccount: newManagerBankAccount
        };
    }),
    on(BankAccountActions.BankAccountsSystemManagerLoadSuccessAction, (state, { institutionId, systemManagerId, programId, bankAccounts }) => {
        const newSystemManagerBankAccounts = mergeImmutable({ [institutionId]: { [systemManagerId]: { [programId]: bankAccounts } } },
            state.systemManagerBankAccounts
        );

        return {
            ...state,
            systemManagerBankAccounts: newSystemManagerBankAccounts
        };
    }),
    on(BankAccountActions.BankAccountsSystemManagerAddAccountSuccessAction,
        BankAccountActions.BankAccountsSystemManagerUpdateAccountSuccessAction,
        BankAccountActions.BankAccountsManagerAddAccountSuccessAction,
        BankAccountActions.BankAccountsManagerUpdateAccountSuccessAction,
        BankAccountActions.BankAccountsSystemManagerDeleteAccountSuccessAction,
        BankAccountActions.BankAccountsManagerDeleteAccountSuccessAction,
        (state, { }) => {
            return initialState;
        })
);

export function bankAccountsReducer(state: BankAccountsState | undefined, actions: Action) {
    return reducer(state, actions);
}

export const managerBankAccounts = (state: BankAccountsState) => state.managerBankAccounts;
export const managerBankAccount = (state: BankAccountsState) => state.managerBankAccount;
export const systemManagerBankAccounts = (state: BankAccountsState) => state.systemManagerBankAccounts;
