import { eMemberStatus, eSchoolGradeType, IMemberEventRegistrationSummaryDto } from '..';

export interface IFamilyMemberSummaryDto {
    familyMemberId: string;
    memberId: string;
    memberIdent?: number;
    primaryFamily: boolean;
    state: eMemberStatus;
    birthDate: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    schoolGrade?: eSchoolGradeType;
    memberPrograms?: object;
    has4HMemberProgram: boolean;
    eventRegistrations?: IMemberEventRegistrationSummaryDto[];
    additionalPaymentRequired: boolean;
}
