import { Action, createReducer, on } from '@ngrx/store';
import { IDeliveryModeDto, IInstitutionProgramModel } from 'app/core/models';

import { DeliveryModeActions } from '.';
import { mergeImmutable } from '../utils';

export interface DeliveryModeState {
    deliveryModes: IInstitutionProgramModel<IDeliveryModeDto[]>;
    groupEnrollmentDeliveryModes: IInstitutionProgramModel<IDeliveryModeDto[]>;
}

const initialState: DeliveryModeState = {
    deliveryModes: undefined,
    groupEnrollmentDeliveryModes: undefined
};

const reducer = createReducer(
    initialState,
    on(DeliveryModeActions.DeliveryModesLoadSuccessAction, (state, { institutionId, programId, deliveryModes }) => {
        return {
            ...state,
            deliveryModes: mergeImmutable(
                { [institutionId]: { [programId]: deliveryModes } },
                state.deliveryModes
            )
        };
    }),
    on(DeliveryModeActions.GroupEnrollmentDeliveryModesLoadSuccessAction, (state, { institutionId, programId, deliveryModes }) => {
        return {
            ...state,
            groupEnrollmentDeliveryModes: mergeImmutable(
                { [institutionId]: { [programId]: deliveryModes } },
                state.groupEnrollmentDeliveryModes
            )
        };
    }),
);

export function deliveryModeReducer(state: DeliveryModeState | undefined, action: Action) {
    return reducer(state, action);
}

export const deliveryModes = (state: DeliveryModeState) => state.deliveryModes;
export const groupEnrollmentDeliveryModes = (state: DeliveryModeState) => state.groupEnrollmentDeliveryModes;
