import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  eHealthFormType,
  IConsentVersionDto,
  IHealthFormDto,
  IHealthFormFieldStatusUpdateDto,
  IInstitutionSystemManagerProgramParams,
} from 'app/core/models';



export const SystemManagerGetHealthFormAction = createAction(
  '[system-manager-health] get health form',
  props<IInstitutionSystemManagerProgramParams & { healthFormType: eHealthFormType }>()
);

export const SystemManagerGetHealthFormSuccessAction = createAction(
  '[system-manager-health] get health form success',
  props<IInstitutionSystemManagerProgramParams & { healthFormType: eHealthFormType, healthForm: IHealthFormDto }>()
);

export const SystemManagerGetHealthFormErrorAction = createAction(
  '[system-manager-health] get health form error',
  props<{ error: HttpErrorResponse }>()
);

export const SystemManagerUpdateHealthFormAction = createAction(
  '[system-manager-health] Update health form',
  props<IInstitutionSystemManagerProgramParams & { update: IHealthFormFieldStatusUpdateDto }>()
);

export const SystemManagerUpdateHealthFormSuccessAction = createAction(
  '[system-manager-health] Update health form success',
  props<IInstitutionSystemManagerProgramParams & { update: IHealthFormFieldStatusUpdateDto }>()
);

export const SystemManagerUpdateHealthFormErrorAction = createAction(
  '[system-manager-health] Update health form error',
  props<{ error: HttpErrorResponse }>()
);

export const SystemManagerUpdateHealthFormInternationalizationAction = createAction(
  '[system-manager-health] Update health form internationalization',
  props<IInstitutionSystemManagerProgramParams & { localization: string, update: any }>()
);

export const SystemManagerUpdateHealthFormInternationalizationSuccessAction = createAction(
  '[system-manager-health] Update health form internationalization success',
  props<IInstitutionSystemManagerProgramParams & { localization: string, update: any }>()
);

export const SystemManagerUpdateHealthFormInternationalizationErrorAction = createAction(
  '[system-manager-health] Update health form internationalization error',
  props<{ error: HttpErrorResponse }>()
);



export const SystemManagerGetConsentAction = createAction(
  '[system-manager-health] get consent',
  props<IInstitutionSystemManagerProgramParams & { healthFormType: string }>()
);

export const SystemManagerGetConsentSuccessAction = createAction(
  '[system-manager-health] get consent success',
  props<IInstitutionSystemManagerProgramParams & { healthFormType: string, consent: IConsentVersionDto[] }>()
);

export const SystemManagerGetConsentErrorAction = createAction(
  '[system-manager-health] get consent error',
  props<{ error: HttpErrorResponse }>()
);

export const SystemManagerInvalidateHealthFormCacheAction = createAction(
  '[system-manager-health] Invalidate health form cache'
);

export const SystemManagerInvalidateHealthFormConsentCacheAction = createAction(
  '[system-manager-health] invalidate health form consent cache'
);
