import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ng4h-sidebar-in-app-help',
  templateUrl: './sidebar-in-app-help.component.html',
  styleUrls: ['./sidebar-in-app-help.component.scss']
})
export class SidebarInAppHelpComponent implements OnInit {

  @Output() quickHelpClicked: EventEmitter<void>;
  constructor() {
    this.quickHelpClicked = new EventEmitter();
  }

  ngOnInit() {
  }

}
