import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  AutoUnsubscribe,
  eHierarchyNodeType,
  eProfileType,
  IDynamicControl,
  IDynamicForm,
  IDynamicGroup,
} from 'app/core/models';
import { ProfileService, RouterService } from 'app/core/services';
import { rankHierarchyAssociation } from 'app/shared/utils';
import { filter, map, takeUntil } from 'rxjs';

@Component({
  selector: 'ng4h-dynamic-form-display',
  templateUrl: './dynamic-form-display.component.html',
  styleUrls: ['./dynamic-form-display.component.scss']
})
export class DynamicFormDisplayComponent extends AutoUnsubscribe implements OnInit {


  @Input() dynamicFormInputs: IDynamicForm;
  @Input() dynamicForm: FormGroup;
  @Input() readOnly: boolean;



  constructor(
    private routerService: RouterService,
    private profileService: ProfileService
  ) {
    super();


  }

  ngOnInit() {

  }


  public dynamicGroupsTrackBy(group: IDynamicGroup) {
    return group.groupId;
  }

  public dynamicControlTrackBy(control: IDynamicControl) {
    return control.controlId;
  }

  public hasPermission$(requirement: eHierarchyNodeType) {
    return this.profileService.loggedInAsInstitutionProfile.pipe(
      filter(profile => profile != null),
      map(activeProfile => {
        if (activeProfile.profileType === eProfileType.Superuser) {
          return true;
        }
        if (activeProfile.profileType === eProfileType.Manager) {
          const highestRank = activeProfile.managerPrograms[this.routerService.programId].reduce((pre, cur, index) => {
            if (rankHierarchyAssociation(cur.hierarchyNodeType) < rankHierarchyAssociation(pre.hierarchyNodeType)) {
              return cur;
            }
            return pre;
          });
          const hasPermissions = rankHierarchyAssociation(highestRank.hierarchyNodeType) <= rankHierarchyAssociation(requirement);
          return hasPermissions;
        }
      }),
      takeUntil(this.autoUnsubscribe)
    );
  }
}
