import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ONE_HOUR_IN_SECONDS } from 'app/shared/utils';
import { environment } from 'environments/environment';
import { catchError, combineLatest, filter, map, Observable, of, switchMap, take, tap, throwError } from 'rxjs';

import { CommonToastrService, LoggingService, ModalService } from '.';
import frontEndVersion from '../../../assets/version.json';
import { ILoginCredentials, IInstitutionProfileAuthDto } from '../models';
import { ProfileService } from './profile.service';
import { RouterService } from './router.service';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class Ng4hHttpInterceptor implements HttpInterceptor {
  private loginCredentials$: Observable<ILoginCredentials>;
  private loggedInInstitutionProfileId$: Observable<string>;
  private forceRefresh = false;

  constructor(
    private userService: UserService,
    private profileService: ProfileService,
    private routerService: RouterService,
    private errorService: LoggingService,
    private toastrService: CommonToastrService,
    private modalService: ModalService
  ) {
    this.loginCredentials$ = this.routerService.activeUrl$.pipe(
      take(1),
      switchMap((url) => {
        if (url != null && url !== '/') {
          return this.userService.getLoginCredentials();
        }
        return of(null);
      })
    );
    this.loggedInInstitutionProfileId$ = this.routerService.loggedInInstitutionProfileId$;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return combineLatest([this.loginCredentials$, this.loggedInInstitutionProfileId$]).pipe(
      map(([loginCredentials, loggedInInstitutionProfileId]) => {
        return this.handleLoginCredentials(loginCredentials, loggedInInstitutionProfileId);
      }),
      switchMap((credentials) => {
        return this.getCombinedLoginCredentials(req, credentials as [ILoginCredentials, string]);
      }),
      take(1),
      map(([loginCredentials, profileCredentials]) => {
        return this.setAuthRequest(req, loginCredentials, profileCredentials);
      }),
      switchMap(authReq => this.processRequest(next, authReq))
    );
  }

  private processRequest(next: HttpHandler, authReq: HttpRequest<any>): Observable<HttpEvent<any>> {
    return next.handle(authReq).pipe(
      tap((res) => this.handleResponse(res)),
      catchError((error: HttpErrorResponse): Observable<never> => this.handleError(error, authReq))
    );
  }

  private handleResponse(res: HttpEvent<any>) {
    if (res instanceof HttpResponse) {
      if (!environment.production && res?.body?.Value != null && res?.body?.hasValue != null) {
        this.handleBrokenBackEnd(res.url);
      }
      const latestVersion = res.headers.get('frontend-version');
      if (!window.location.origin.includes('localhost')) {
        this.handleFrontEndOutdated(latestVersion);
      }
    }
  }

  private handleBrokenBackEnd(url: string) {
    const body = 'Found Value and hasValue in response body. Dave needs to fix. \r\n' + url;
    const header = 'Backend broken';
    this.modalService.confirm({ header, body }).pipe(take(1)).subscribe();
  }

  private handleFrontEndOutdated(latestVersion: string) {
    const currentVersion = frontEndVersion.version;
    if (currentVersion != null && latestVersion != null && latestVersion !== '0.0.0.0') {
      if (currentVersion !== latestVersion && this.forceRefresh === false) {
        this.forceRefresh = true;
        const header = 'Client Outdated';
        const body = `The client has been updated and needs to refresh. We are sorry for the inconvenience`;
        this.modalService.confirm({ header, body }).pipe(take(1)).subscribe(() => {
          location.reload();
        });
      }
    }
  }

  private handleError(error: HttpErrorResponse, req: HttpRequest<any>): Observable<never> {
    switch (error.status) {
      case 401:
        this.handleUnauthorizedError();
        break;
      case 410:
        this.handleWebsiteOfflineError();
        break;
      case 423:
        this.handleInstitutionOfflineError(error);
        break;
      case 502:
        this.handleWebsiteUnavailableError();
        break;
      default:
        this.errorService.httpError(error, req);
    }
    return throwError(() => error);
  }

  private setAuthRequest(req: HttpRequest<any>, loginCredentials: ILoginCredentials, profileCredentials: IInstitutionProfileAuthDto): HttpRequest<any> {
    let authReq = req;
    if (loginCredentials != null) {
      const headers = profileCredentials != null
        ? this.createHeadersWithProfileCredentials(loginCredentials, profileCredentials)
        : this.createHeadersWithLoginCredentials(loginCredentials);
      authReq = req.clone({ headers });
    }
    return authReq;
  }

  private createHeadersWithProfileCredentials(loginCredentials: ILoginCredentials, profileCredentials: IInstitutionProfileAuthDto): HttpHeaders {
    return new HttpHeaders({
      useraccountauthtoken: loginCredentials.userAccountAuth,
      authorization: 'bearer ' + loginCredentials.accessToken,
      institutionprofileauthtoken: profileCredentials.institutionProfileAuthToken
    });
  }

  private createHeadersWithLoginCredentials(loginCredentials: ILoginCredentials): HttpHeaders {
    return new HttpHeaders({
      useraccountauthtoken: loginCredentials.userAccountAuth,
      authorization: 'bearer ' + loginCredentials.accessToken
    });
  }

  private getCombinedLoginCredentials(req: HttpRequest<any>, credentials: [ILoginCredentials, string]): Observable<[ILoginCredentials, IInstitutionProfileAuthDto]> {
    const apiAuthUserAccountsUrl = `${environment.apiUri}/api/auths/user-accounts`;
    const apiAuthInstitutionProfilesUrl = `${environment.apiUri}/api/auths/institution-profiles/`;
    const apiAuthDuoUrl = `${environment.apiUri}/api/auths/duo`;
    const [loginCredentials, loggedInInstitutionProfileId] = credentials;

    if (req.url === apiAuthUserAccountsUrl || req.url.includes(apiAuthDuoUrl)) {
      return combineLatest([of(null), of(null)]);
    } else if (loggedInInstitutionProfileId == null || req.url.includes(apiAuthInstitutionProfilesUrl)) {
      return combineLatest([of(loginCredentials), of(null)]);
    }

    return combineLatest([
      of(loginCredentials),
      this.profileService.getInstitutionProfileAuthToken(loggedInInstitutionProfileId).pipe(filter(id => id != null))
    ]);
  }

  private handleLoginCredentials(loginCredentials: ILoginCredentials, loggedInInstitutionProfileId: string): [ILoginCredentials, string] | never {
    if (loginCredentials == null && loggedInInstitutionProfileId != null) {
      this.routerService.Go(['user', 'sign-in']).then(r => {});
      throw new Error('Unauthenticated');
    } else if (loginCredentials != null && (((new Date()).getTime() / 1000) + ONE_HOUR_IN_SECONDS) > loginCredentials.expiresAt) {
      this.toastrService.error('Credentials Have Expired, Sign Back In To Continue.');
      this.userService.signOut();
      throw new Error('Credentials Expired');
    }
    return [loginCredentials, loggedInInstitutionProfileId] as [ILoginCredentials, string];
  }

  handleUnauthorizedError = () => {
    this.toastrService.error('Unauthorized to view or update data');
  }

  handleWebsiteOfflineError = () => {
    this.toastrService.error('Website Offline');
    this.userService.signOut();
  }

  handleInstitutionOfflineError = (error: HttpErrorResponse) => {
    this.toastrService.error('Institution Offline');
    this.modalService.confirm({
      header: 'Institution Offline',
      body: `The institution is currently offline and will not process any requests. ${error.error}`
    }).subscribe(() => {
      this.userService.signOut();
    });
  }

  handleWebsiteUnavailableError = () => {
    this.toastrService.error('Website Unavailable');
    this.userService.signOut();
  }
}
