<ng-container *ngIf="enrollment$ | async as enrollment">
  <ng-container *ngIf="(selectedProjects$ | async) as selectedProjects">
    <div *ngIf="(projectsPageMessage$ | async) == null">
      <div class="col-12">

        <table mat-table [dataSource]="selectedProjects" matSort>

          <ng-container matColumnDef="projectName">
            <th mat-header-cell *matHeaderCellDef>Project Alias</th>
            <td mat-cell *matCellDef="let element">
              {{ element.projectName }}
            </td>
          </ng-container>
          
          <ng-container matColumnDef="yearsInProject">
            <th mat-header-cell *matHeaderCellDef>Years In Project</th>
            <td mat-cell *matCellDef="let element">
              {{ element.yearsInProject }}
            </td>
          </ng-container>
          
          <ng-container matColumnDef="parentProjectName">
            <th mat-header-cell *matHeaderCellDef>State Project</th>
            <td mat-cell *matCellDef="let element">{{ element.parentProjectName }}</td>
          </ng-container>

          <ng-container matColumnDef="unitName">
            <th mat-header-cell *matHeaderCellDef>{{ (eUnitType.Club | programAlias$ | async)?.singular }}</th>
            <td mat-cell *matCellDef="let element">{{ element.unitName }}</td>
          </ng-container>

          <ng-container matColumnDef="volunteerType">
            <th mat-header-cell *matHeaderCellDef>{{
                enrollment.enrollmentRole === eEnrollmentRoleType.Volunteer ? '' :
                  'Youth'
              }} Volunteer Type</th>
            <td mat-cell *matCellDef="let element">
              {{ element.volunteerType?.volunteerTypeLabel }}</td>
          </ng-container>

          <ng-container matColumnDef="allowEdit">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;width: 1rem;">Remove</th>
            <td mat-cell *matCellDef="let element" style="text-align: center">
              <button aria-label="Remove Project" class="btn remove-project" (click)="removeProject($event, element)">
                <i class="fal fa-trash-alt"></i>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="enrollmentProjectsColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: enrollmentProjectsColumns;" (click)="projectClicked(row)"></tr>

        </table>
      </div>
      <div class="col-12" *ngIf="allowEdit">
        <ng-container *ngIf="programSettings$ | async as programSettings">
          <button
            [disabled]="programSettings?.enrollment_MaxProjects !== 0 && selectedProjects.length >= programSettings?.enrollment_MaxProjects"
            class="btn add-project ng4h-margin-right"
            [ngClass]="selectedProjects.length > 0 ? 'btn-secondary' : 'btn-primary'" (click)="addProject()">Select
            Projects</button>

        </ng-container>
      </div>
    </div>
    <ng-container *ngIf="projectsPageMessage$ | async as message">
      <ng4h-in-app-help class="selection-intro">
        <div ng4h-in-app-help-header>Projects</div>
        <div ng4h-in-app-help-body>
          <div [innerHtml]="message.message | safeHtml"></div>
        </div>
        <div ng4h-in-app-help-footer>
          <button (click)="closeHelp()" class="btn btn-primary">Select Projects</button>
        </div>
      </ng4h-in-app-help>
    </ng-container>
  </ng-container>
</ng-container>