<div class="modal-header">
    <h5 class="modal-title" id="enrollModalLabel">Add To Batch</h5>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="col-sm-12 row">
      <label class="form-control-label col-3">Existing Batches</label>
      <div class="col-9">
        <select class="form-control" [(ngModel)]="batch">
          <option *ngFor="let item of batches" class="form-control" [value]="item.name">{{ item.name }}</option>
        </select>
      </div>
    </div>
  </div>

<div class="modal-footer">
  <button (click)="update()" class="btn btn-secondary my-2 my-sm-0" role="button"
    aria-pressed="true">Update</button>
</div>
