import { eInstitutionType, eStateAbbreviation, IManagerInstitutionProfile } from '..';
import { ISystemManagerInstitutionProfile } from '../ISystemManagerInstitutionProfile';

export interface IInstitutionDescriptorDto {
    institutionId?: string;
    institutionName?: string;
    state: eStateAbbreviation;
    subdomains?: any[];
    type: eInstitutionType;
    logoResource?: string;
    manager: IManagerInstitutionProfile;
    systemManager: ISystemManagerInstitutionProfile;
    nonDiscriminationDisclosure: string;
}

