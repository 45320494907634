import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    AutoUnsubscribe,
    eInvoiceStatus,
    ePaymentGatewayType,
    eProfileType,
    IFamilyInstitutionProfile,
    IGatewayConfigurationDto,
    IPayableInvoiceDto,
    IPaymentGatewayPaymentMethodsDto,
    IPaymentMethodDto,
    ITokenizedCardDto,
} from 'app/core/models';
import {
    CommonToastrService,
    CreditCardService,
    MemberService,
    PaymentsService,
    ProfileService,
    ProgramSettingsService,
    RouterService,
} from 'app/core/services';
import { filter, map, Observable, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs';


@Component({
  selector: 'ng4h-add-payment-option',
  templateUrl: './add-payment-option.component.html',
  styleUrls: ['./add-payment-option.component.scss']
})
export class AddPaymentOptionComponent extends AutoUnsubscribe implements OnInit {

  @Input() paymentMethod: IPaymentMethodDto;
  @Input() invoice: IPayableInvoiceDto;
  @Output() paymentMethodChange: EventEmitter<IPaymentMethodDto>;

  public eInvoiceStatus = eInvoiceStatus;
  public ePaymentGatewayType = ePaymentGatewayType;
  public payments$: Observable<IPaymentGatewayPaymentMethodsDto>;
  // public selectedPaymentMethodId: string;

  public checkSelected: boolean;
  public programId: Observable<string>;
  public savingCard: boolean;
  public gatewayConfig$: Observable<IGatewayConfigurationDto>;
  public init = true;

  // public billingAddress: FormGroup;

  constructor(
    private paymentsService: PaymentsService,
    private creditCardService: CreditCardService,
    private routerService: RouterService,
    private memberService: MemberService,
    private profileService: ProfileService,
    private toastrService: CommonToastrService,
    private programSettingsService: ProgramSettingsService,
    private cdr: ChangeDetectorRef
  ) {
    super();
    this.paymentMethodChange = new EventEmitter();



  }

  ngOnInit() {

    this.gatewayConfig$ = this.programSettingsService.getPaymentGatewayConfiguration({ institutionId: this.routerService.institutionId, programId: this.routerService.programId });

    this.payments$ = this.memberService.getMember({ institutionId: this.routerService.institutionId, memberId: this.routerService.memberId }).pipe(
      map(member => member.families.find(family => family.primary).familyId),
      switchMap(familyId => {
        return this.paymentsService.getProgramPayments({ institutionId: this.routerService.institutionId, familyId, programId: this.routerService.programId });
      }),
      filter(payments => payments != null),
      takeUntil(this.autoUnsubscribe),
      shareReplay(),
    );

    this.programId = this.routerService.programId$.pipe(takeUntil(this.autoUnsubscribe));

  }


  public creditCardSuccessfullyTokenized(card: ITokenizedCardDto) {
    this.profileService.loggedInAsInstitutionProfile.pipe(
      filter(profile => profile != null),
      take(1),
      filter(profile => profile.profileType === eProfileType.Family),
      tap(() => {
        this.savingCard = true;
      }),
      switchMap((family: IFamilyInstitutionProfile) => {
        return this.creditCardService.saveFamilyCard({ institutionId: this.routerService.institutionId, familyId: family.familyId, cardSubmission: card, programId: this.routerService.programId });
      }),
      filter(res => res != null),
      take(1),
      map(res => res.paymentMethod)
    ).subscribe({
      next: (paymentMethod: IPaymentMethodDto) => {
        this.savingCard = false;
        // this.selectedPaymentMethodId = paymentMethod.paymentMethodId;
        this.paymentMethodChange.emit(paymentMethod);
        this.toastrService.success('Card added');
      }, error: (error: HttpErrorResponse) => {
        this.toastrService.error('Could not save card', error);
        this.savingCard = false;
        this.cdr.detectChanges();
      }
    });

  }
  public paymentClicked(paymentMethod: IPaymentMethodDto) {
    // if (new ExpiredPipe().transform(paymentMethod.expirationDate) || new ExpiresSoonPipe().transform(paymentMethod.expirationDate)) {
    //   return;
    // }
    // this.selectedPaymentMethodId = paymentMethod.paymentMethodId;

    this.checkSelected = false;

    this.paymentMethodChange.emit(paymentMethod);
  }

  public addNewCard() {
    // this.selectedPaymentMethodId = null;
    this.init = false;
    this.paymentMethodChange.emit(null);
  }
}
