import { Action, createReducer, on } from '@ngrx/store';
import { IFamilyCountyDiscount, IInstitutionManagerProgramModel } from 'app/core/models';
import { mergeImmutable } from 'app/shared/utils';

import { ManagerDiscountActions } from '.';

export interface ManagerDiscountsState {
  familyCountyDiscounts: IInstitutionManagerProgramModel<{ [programYearId: string]: IFamilyCountyDiscount[] }>;
}

const initialState: ManagerDiscountsState = {

  familyCountyDiscounts: undefined
};

const reducer = createReducer(
  initialState,

  on(ManagerDiscountActions.ManagerLoadFamilyCountyDiscountsSuccessAction, (state, { institutionId, managerId, programId, programYearId, discounts }) => {
    return {
      ...state,
      familyCountyDiscounts: mergeImmutable(
        { [institutionId]: { [managerId]: { [programId]: { [programYearId]: discounts } } } },
        state.familyCountyDiscounts
      )
    };
  }),
  on(ManagerDiscountActions.ManagerUpdateFamilyCountyDiscountsSuccessAction, (state, { institutionId, managerId, programId, programYearId }) => {
    return {
      ...state,
      familyCountyDiscounts: mergeImmutable(
        { [institutionId]: { [managerId]: { [programId]: { [programYearId]: undefined } } } },
        state.familyCountyDiscounts
      )
    };
  })
);

export function discountsReducer(state: ManagerDiscountsState | undefined, actions: Action) {
  return reducer(state, actions);
}


export const familyCountyDiscounts = (state: ManagerDiscountsState) => state.familyCountyDiscounts;
