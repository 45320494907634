import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IMemberConsentSubmitDto, IMemberProgramConsentDto, IPayableInvoiceDto, IPaymentMethodDto } from 'app/core/models';
import { ProfileService } from 'app/core/services';
import { Observable } from 'rxjs';

@Component({
  selector: 'ng4h-modal-add-additional-payment',
  templateUrl: './modal-add-additional-payment.component.html',
  styleUrls: ['./modal-add-additional-payment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalAddAdditionalPaymentComponent implements OnInit {

  public isManager$: Observable<boolean>;
  public paymentMethod: IPaymentMethodDto;

  public paymentConsentSigned = false;
  // public couponCodeForm: FormGroup;
  public paymentConsentResponse: IMemberConsentSubmitDto;

  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public payableInvoice: IPayableInvoiceDto,
    private profileService: ProfileService,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.isManager$ = this.profileService.activeUserInstitutionProfileIsManager$;
  }

  public close(res?: any) {
    this.dialogRef.close(res);
  }

  public paymentChanged(paymentMethod: IPaymentMethodDto) {
    this.paymentMethod = paymentMethod;
    this.cdr.detectChanges();
  }



  public apply() {
    this.close({ paymentMethod: this.paymentMethod, paymentConsentResponse: this.paymentConsentResponse });
  }


  public paymentSigned(consents: IMemberProgramConsentDto[]) {

    if (consents != null && Array.isArray(consents)) {
      const signedConsent = consents[0];
      this.paymentConsentResponse = {
        consentStatus: signedConsent.consentStatus,
        consentVersionId: signedConsent.consentVersion.consentVersionId,
        recordedMemberName: signedConsent.recordedMemberName,
        recordedParentGuardianName: signedConsent.recordedParentGuardianName,
        recordedManagerName: signedConsent.recordedManagerName
      };
      this.paymentConsentSigned = true;
    } else {
      this.paymentConsentResponse = null;
      this.paymentConsentSigned = false;
    }

  }



}
