import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import {
  getAvailableTrainings,
  getMemberLessons,
  getMemberTrainings,
  MemberFeatureStore
} from 'app/core/containers/member/member-feature.reducers';
import { MemberTrainingsActions } from 'app/core/containers/member/store/trainings';
import { IInstitutionMemberProgramParams, IProfileTrainingLessonDto } from 'app/core/models';
import { IProfileTrainingDto } from 'app/core/models/serverDTOs/IProfileTrainingDto';
import { IProfileTrainingLessonLaunchDto } from 'app/core/models/serverDTOs/IProfileTrainingLessonLaunchDto';
import { filter, map, Observable, of, take, tap } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MemberTrainingsService {

  constructor(private store: Store<MemberFeatureStore>, private httpClient: HttpClient, private dispatcher: ActionsSubject) {
  }

  public getAvailableTrainings(params: IInstitutionMemberProgramParams): Observable<IProfileTrainingDto[]> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getAvailableTrainings(params)),
      tap(trainings => {
        if (trainings === undefined) {
          this.store.dispatch(MemberTrainingsActions.MemberTrainingLoadAvailableTrainingsAction(params));
        }
      }),
      filter(trainings => trainings != null)
    );
  }

  public loadAvailableTrainingsEffect({ institutionId, memberId, programId }: IInstitutionMemberProgramParams) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/available-trainings`) as Observable<IProfileTrainingDto[]>;
  }

  public registerMemberForTraining(params: IInstitutionMemberProgramParams & { trainingId: string }) {
    this.store.dispatch(MemberTrainingsActions.MemberTrainingRegisterForTrainingAction(params));
    return this.dispatcher.pipe(
      ofType(MemberTrainingsActions.MemberTrainingRegisterForTrainingSuccessAction, MemberTrainingsActions.MemberTrainingRegisterForTrainingErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberTrainingsActions.MemberTrainingRegisterForTrainingSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public registerMemberForTrainingEffect({
    institutionId,
    memberId,
    programId,
    trainingId
  }: IInstitutionMemberProgramParams & { trainingId: string }) {
    return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/trainings/${trainingId}/register`, {});
  }

  public getTrainings(params: IInstitutionMemberProgramParams): Observable<IProfileTrainingDto[]> {

    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getMemberTrainings(params)),
      tap(trainings => {
        if (trainings === undefined) {
          this.store.dispatch(MemberTrainingsActions.MemberTrainingLoadTrainingsAction(params));
        }
      }),
      filter(trainings => trainings != null)
    );
  }

  public loadTrainingsEffect({ institutionId, memberId, programId }: IInstitutionMemberProgramParams) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/trainings`);
  }

  // public getTraining(params: IInstitutionMemberProgramParams & { profileTrainingId: string }) {
  //   if (Object.keys(params).find(key => params[key] == null) != null) {
  //     return of(null);
  //   }
  //   return this.store.pipe(
  //     select(getMemberTraining(params)),
  //     tap(training => {
  //       if (training === undefined) {
  //         this.store.dispatch(MemberTrainingsActions.MemberTrainingLoadTrainingAction(params));
  //       }
  //     }),
  //     filter(training => training != null),
  //   );
  // }

  // public loadTrainingEffect({ institutionId, memberId, programId, profileTrainingId }: IInstitutionMemberProgramParams & { profileTrainingId: string }) {
  //   return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/trainings/${profileTrainingId}`);
  // }

  // This registers the member for the training which costs money and retrieves the training
  public registerForAndGetTrainingLesson(params: IInstitutionMemberProgramParams & { profileTrainingId: string, trainingLessonId: string, dto: IProfileTrainingLessonLaunchDto }): Observable<IProfileTrainingLessonDto> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getMemberLessons(params)),
      tap(training => {
        if (training === undefined) {
          this.store.dispatch(MemberTrainingsActions.MemberTrainingLoadLessonAction(params));
        }
      }),
      filter(training => training !== undefined)
    );
  }

  // Costs $$ so be careful
  public loadTrainingLessonEffect({
    institutionId,
    memberId,
    programId,
    profileTrainingId,
    trainingLessonId,
    dto
  }: IInstitutionMemberProgramParams & { profileTrainingId: string, trainingLessonId: string, dto: IProfileTrainingLessonLaunchDto }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/trainings/${profileTrainingId}/lessons/${trainingLessonId}`, dto);
  }

  public invalidateCache() {
    this.store.dispatch(MemberTrainingsActions.MemberTrainingInvalidateCacheAction());
  }

  public deleteTraining(params: IInstitutionMemberProgramParams & { profileTrainingId: string }) {
    this.store.dispatch(MemberTrainingsActions.MemberTrainingDeleteAction(params));
    return this.dispatcher.pipe(
      ofType(MemberTrainingsActions.MemberTrainingDeleteSuccessAction, MemberTrainingsActions.MemberTrainingDeleteErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberTrainingsActions.MemberTrainingDeleteSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteTrainingEffect({
    institutionId,
    memberId,
    programId,
    profileTrainingId
  }: IInstitutionMemberProgramParams & { profileTrainingId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/trainings/${profileTrainingId}`);
  }
}
