import { Action, createReducer, on } from '@ngrx/store';
import { IProfileVolunteerScreeningDto } from 'app/core/models';
import { mergeImmutable } from 'app/shared/utils';

import { MemberScreeningActions } from '.';

export interface MemberScreeningsState {
  screenings: { [institutionId: string]: { [memberId: string]: { [profileScreeningId: string]: IProfileVolunteerScreeningDto } } };
}

const initialState: MemberScreeningsState = {
  screenings: undefined
};

const reducer = createReducer(
  initialState,
  on(MemberScreeningActions.MemberScreeningLoadSuccessAction, (state, { institutionId, memberId, profileScreeningId, screening }) => {
    const newScreenings = mergeImmutable(
      { [institutionId]: { [memberId]: { [profileScreeningId]: screening } } },
      state.screenings
    );
    return {
      ...state,
      screenings: newScreenings
    };
  }),
  on(MemberScreeningActions.MemberScreeningLoadErrorAction, (state, { institutionId, memberId, profileScreeningId }) => {
    const newScreenings = mergeImmutable(
      { [institutionId]: { [memberId]: { [profileScreeningId]: null } } },
      state.screenings
    );
    return {
      ...state,
      screenings: newScreenings
    };
  }),
  on(MemberScreeningActions.MemberScreeningInvalidateCacheAction, (state, { }) => {
    return initialState;
  })
);

export function screeningsReducer(state: MemberScreeningsState | undefined, actions: Action) {
  return reducer(state, actions);
}

export const screenings = (state: MemberScreeningsState) => state.screenings;
