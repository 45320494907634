import { Action, createReducer, on } from '@ngrx/store';
import {
  IEventEmailTemplatesDto,
  IInstitutionManagerProgramEventModel,
  IInstitutionSystemManagerProgramModel,
  IProgramEmailTemplatesDto,
} from 'app/core/models';

import { EmailTemplateActions } from '.';
import { mergeImmutable } from '../utils';

export interface EmailTemplateState {
  programEmailTemplates: IInstitutionSystemManagerProgramModel<IProgramEmailTemplatesDto>;
  eventEmailTemplates: IInstitutionManagerProgramEventModel<IEventEmailTemplatesDto>;
  wrapper: { [institutionId: string]: { [systemManagerId: string]: { [programId: string]: any } } };
}

const initialState: EmailTemplateState = {
  programEmailTemplates: undefined,
  eventEmailTemplates: undefined,
  wrapper: undefined
};


const reducer = createReducer(
  initialState,

  on(EmailTemplateActions.EmailTemplateProgramLoadSuccessAction, (state, { institutionId, systemManagerId, programId, templates }) => {
    return {
      ...state,
      programEmailTemplates: mergeImmutable(
        { [institutionId]: { [systemManagerId]: { [programId]: templates } } },
        state.programEmailTemplates
      )
    };
  }),
  on(EmailTemplateActions.EmailTemplateProgramInvalidateCache, (state, { }) => {
    return {
      ...state,
      programEmailTemplates: undefined
    };
  }),

  on(EmailTemplateActions.EmailTemplateEventLoadSuccessAction, (state, { institutionId, managerId, programId, eventId, templates }) => {
    return {
      ...state,
      eventEmailTemplates: mergeImmutable(
        { [institutionId]: { [managerId]: { [programId]: { [eventId]: templates } } } },
        state.eventEmailTemplates
      )
    };
  }),
  on(EmailTemplateActions.EmailTemplateEventInvalidateCache, (state, { }) => {
    return {
      ...state,
      eventEmailTemplates: undefined
    };
  }),

  on(EmailTemplateActions.EmailTemplateWrapperLoadSuccessAction, (state, { institutionId, systemManagerId, programId, wrapper }) => {
    return {
      ...state,
      wrapper: mergeImmutable(
        { [institutionId]: { [systemManagerId]: { [programId]: wrapper } } },
        state.wrapper
      )
    };
  }),
);

export function emailTemplateReducer(state: EmailTemplateState | undefined, actions: Action) {
  return reducer(state, actions);
}


export const programEmailTemplates = (state: EmailTemplateState) => state.programEmailTemplates;
export const eventEmailTemplates = (state: EmailTemplateState) => state.eventEmailTemplates;
export const wrapper = (state: EmailTemplateState) => state.wrapper;
