import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { IBreadCrumb } from 'app/core/models/IBreadCrumb';
import { RouterService } from 'app/core/services';
import { Observable } from 'rxjs';

@Component({
  selector: 'ng4h-router-breadcrumb',
  templateUrl: './router-breadcrumb.component.html',
  styleUrls: ['./router-breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RouterBreadcrumbComponent implements OnInit {

  public breadcrumbs$: Observable<IBreadCrumb[]>;

  constructor(private routerService: RouterService) {
    this.breadcrumbs$ = this.routerService.breadcrumbs$;
  }

  ngOnInit() {
  }

}
