<ng4h-dynamic-options-root [formDisplayTypes]="formDisplayTypes" [control]="control"
  (rootInputModified)="controlModified.emit($event)" (validatorAddOrModify)="validatorAddOrModify.emit($event)"
  (validatorRemove)="validatorRemove.emit($event)">

  <!-- <div class="form-group row">
    <label class="form-control-label col-md-3">Report Label</label>
    <div class="input-group col-md-9">
      <input class="form-control" type="text" [ngModel]="control.items[0].reportValue"
        (ngModelChange)="reportLabelChange($event, control.items[0].itemId)">
    </div>
  </div> -->

  <div class="form-group row">
    <label class="form-control-label col-md-3">Placeholder</label>
    <div class="col-md-9">
      <input [ngModel]="control.items[0].attributes.placeholder" type="text" class="form-control"
        (ngModelChange)="placeholderChange($event, control.items[0].itemId)">
    </div>
  </div>

  <ng-container *ngIf="formType == eFormType.Registration">
    <div class="form-group row">
      <div class="input-group">
        <label class="form-control-label col-md-3">Number Available</label>
        <div class="col-md-9">
          <input class="form-control" type="number" [ngModel]="control.items[0].attributes.quantityAvailable"
            (ngModelChange)="numberAvailableChanged($event, control.items[0].itemId)">
        </div>
      </div>
    </div>
  </ng-container>

  <div class="form-group row">
    <label class="form-control-label col-md-3">Min Value</label>
    <div class="col-md-9">
      <input [ngModel]="min" name="rangeFrom" type="number" class="form-control"
        (ngModelChange)="rangeMinChange($event)">
    </div>
  </div>

  <div class="form-group row">
    <label class="form-control-label col-md-3">Max Value</label>
    <div class="col-md-9">
      <input [ngModel]="max" name="rangeTo" type="number" class="form-control" (ngModelChange)="rangeMaxChange($event)">
    </div>
  </div>

</ng4h-dynamic-options-root>