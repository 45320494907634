import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState, getCountyAreaPrograms, getProgram, getPrograms } from 'app/app.reducers';
import { ProgramActions } from 'app/shared/program';
import { filter, Observable, of, tap } from 'rxjs';

import { eProgramType, IInstitutionProgramDto, IInstitutionProgramParams, IProgramSummaryDto } from '../models';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProgramService {

  constructor(private store: Store<AppState>, private httpClient: HttpClient) {
  }

  public getPrograms(params: { institutionId: string }): Observable<IProgramSummaryDto[]> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getPrograms(params)),
      tap(programs => {
        if (programs === undefined) {
          this.store.dispatch(ProgramActions.ProgramsLoadAction(params));
        }
      }),
      filter(programs => programs != null)
    );
  }

  public loadProgramsEffect({ institutionId }: { institutionId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/programs`) as Observable<IProgramSummaryDto[]>;
  }

  public getProgramsByCountyArea(params: { institutionId: string, countyAreaId: string }): Observable<IProgramSummaryDto[]> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getCountyAreaPrograms(params)),
      tap(programs => {
        if (programs === undefined) {
          this.store.dispatch(ProgramActions.ProgramsLoadCountyAreaAction(params));
        }
      }),
      filter(programs => programs != null)
    );
  }

  public getProgramsByCountyAreaEffect({
    institutionId,
    countyAreaId
  }: { institutionId: string, countyAreaId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/programs/${countyAreaId}`) as Observable<IProgramSummaryDto[]>;
  }

  public getProgram(params: IInstitutionProgramParams): Observable<IInstitutionProgramDto> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getProgram(params)),
      tap(program => {
        if (program === undefined) {
          this.store.dispatch(ProgramActions.ProgramLoadAction(params));
        }
      }),
      filter(program => program != null)
    );
  }

  public loadProgramEffect({ institutionId, programId }: IInstitutionProgramParams) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/programs/${programId}`) as Observable<IInstitutionProgramDto>;
  }

  public getProgramTypes(): Observable<{ name: string, type: eProgramType }[]> {
    return of([
      {
        name: '4-H',
        type: eProgramType._4H
      },
      {
        name: 'FFA',
        type: eProgramType.FFA
      },
      {
        name: 'Master Gardener',
        type: eProgramType.MasterGardener
      }
    ]);
  }

  public refreshPrograms() {
    this.store.dispatch(ProgramActions.ClearProgramCacheAction({}));
  }
}
