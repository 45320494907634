import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IProgramSummaryDto } from 'app/core/models';
import { RouterService } from 'app/core/services';
import { ProgramService } from 'app/core/services/program.service';
import { filter, map, Observable, take } from 'rxjs';

@Component({
  selector: 'ng4h-modal-select-program',
  templateUrl: './modal-select-program.component.html',
  styleUrls: ['./modal-select-program.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalSelectProgramComponent implements OnInit {



  public programsToDisplay$: Observable<IProgramSummaryDto[]>;
  constructor
    (private dialogRef: MatDialogRef<any>,
      @Inject(MAT_DIALOG_DATA) public data: { institutionId: string, programIds: string[] },
      private routerService: RouterService,
      private programService: ProgramService
    ) {
    if (this.data.programIds != null) {
      this.programsToDisplay$ = this.programService.getPrograms({ institutionId: this.data.institutionId }).pipe(
        filter(programs => programs != null),
        map(programs => {
          return programs.filter(program => {
            return this.data.programIds.includes(program.programId);
          });
        }));
    } else {
      this.programsToDisplay$ = this.programService.getPrograms({ institutionId: this.data.institutionId });
    }
    this.programsToDisplay$.pipe(
      take(1)
    ).subscribe(programsToDisplay => {
      if (programsToDisplay.length === 1) {
        this.programSelected(programsToDisplay[0]);
      }
    });
  }

  ngOnInit() {
  }

  public programSelected(program: IProgramSummaryDto) {
    this.close(program.programId);
  }
  public close(programId?: string): void {
    this.dialogRef.close(programId);
  }

}
