import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState, getProjects, getProjectsByUnit } from 'app/app.reducers';
import { IInstitutionMemberProgramEnrollmentParams, IProjectDto } from 'app/core/models';
import { ProjectsActions } from 'app/shared/projects';
import { filter, Observable, of, tap } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {

  constructor(private store: Store<AppState>, private httpClient: HttpClient) {

  }

  public getProjectsByClub(params: IInstitutionMemberProgramEnrollmentParams & { unitId: string }): Observable<IProjectDto[]> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getProjectsByUnit(params)),
      tap(projectsByClub => {
        if (projectsByClub === undefined) {
          this.store.dispatch(ProjectsActions.ProjectsLoadByClubAction(params));
        }
      }),
      filter(projectsByClub => projectsByClub != null)
    );
  }

  public loadProjectsByClubEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    unitId
  }: IInstitutionMemberProgramEnrollmentParams & { unitId: string }): Observable<IProjectDto[]> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/clubs/${unitId}/projects`) as Observable<IProjectDto[]>;
  }

  public getProjects(params: IInstitutionMemberProgramEnrollmentParams): Observable<IProjectDto[]> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getProjects(params)),
      tap(projects => {
        if (projects === undefined) {
          this.store.dispatch(ProjectsActions.ProjectsLoadAction(params));
        }
      }),
      filter(projects => projects != null)
    );
  }

  public loadProjectsEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId
  }: IInstitutionMemberProgramEnrollmentParams): Observable<IProjectDto[]> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/volunteer-projects`) as Observable<IProjectDto[]>;
  }
}
