import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IModalConfirm } from 'app/core/models';

@Component({
    selector: 'ng4h-modal-alert',
    templateUrl: './modal-alert.component.html',
    styleUrls: ['./modal-alert.component.scss']
})
export class ModalAlertComponent implements OnInit {

    public header: string;
    public body: string;

    constructor(private dialogRef: MatDialogRef<ModalAlertComponent>, @Inject(MAT_DIALOG_DATA) public data: IModalConfirm) {
        this.header = this.data.header;
        this.body = this.data.body;
    }

    ngOnInit() {
    }

    public close() {
        this.dialogRef.close(false);
    }

    public cancel() {
        this.dialogRef.close(false);
    }
}
