import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IPaymentMethodDto } from 'app/core/models';

@Component({
    selector: 'ng4h-modal-view-credit-card',
    templateUrl: './modal-view-credit-card.component.html',
    styleUrls: ['./modal-view-credit-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalViewCreditCardComponent implements OnInit {

    public paymentMethod: IPaymentMethodDto;

    constructor(private dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) private data) {
    }

    ngOnInit() {
        this.paymentMethod = this.data.paymentMethod;
    }

    public cancel(): void {
        this.dialogRef.close(false);
    }

    public delete(): void {
        this.dialogRef.close(true);
    }
}
