import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IDynamicControl, IDynamicItem } from 'app/core/models';

import { DynamicValidatorBaseComponent } from '../dynamic-validator-base/dynamic-validator-base.component';

@Component({
  selector: 'ng4h-dynamic-text-area-options',
  templateUrl: './dynamic-text-area-options.component.html',
  styleUrls: ['./dynamic-text-area-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicTextAreaOptionsComponent extends DynamicValidatorBaseComponent implements OnInit {

  @Input() control: IDynamicControl;
  @Output() controlModified: EventEmitter<Partial<IDynamicControl>>;
  @Output() itemModified: EventEmitter<Partial<IDynamicItem>>;
  constructor() {
    super();
    this.controlModified = new EventEmitter();
    this.itemModified = new EventEmitter();
  }

  ngOnInit() {

  }
  reportLabelChange(reportValue: string, itemId: string) {
    this.itemModified.emit({ itemId, reportValue });
  }
  public editorChanged(value: string) {
    // this.controlModified.emit({ label: value });
    this.itemModified.emit({ itemId: this.control.items[0].itemId, label: value });
  }
}
