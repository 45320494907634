import { Pipe, PipeTransform } from '@angular/core';
import { eProgramType } from 'app/core/models';

@Pipe({
    name: 'programType',
    pure: true
})
export class ProgramTypePipe implements PipeTransform {
    constructor() {
    }

    public transform(programType: eProgramType): string {
        switch (programType) {
            case eProgramType._4H: {
                return '4-H';
            }
            case eProgramType.FFA: {
                return 'FFA';
            }
            case eProgramType.MasterGardener: {
                return 'Master Gardener';
            }
            case eProgramType.Other: {
                return 'Other';
            }
            default: {
                return 'Unknown';
            }
        }
    }
}
