import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { RouterService, UserService } from 'app/core/services';
import { filter, map, Observable, take, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SuperuserGuard implements CanActivate {

  constructor(private userService: UserService, private routerService: RouterService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.userService.userAccount$.pipe(
      filter(user => user != null),
      take(1),
      tap(user => {
        if (user.superuserId == null) {
          console.error('Non superuser tried to access superuser area');
          this.routerService.Go(['user', 'sign-in']);
        }
      }),
      map(user => user.superuserId != null));
  }
}
