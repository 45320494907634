import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HierarchyService } from 'app/core/services';
import { catchError, exhaustMap, groupBy, map, mergeMap, of } from 'rxjs';

import { HierarchyActions } from '.';
import { stringKeys } from '../rxjs.utils';


@Injectable()
export class HierarchyEffects {

  loadHierarchy = createEffect(() => this.actions
    .pipe(
      ofType(HierarchyActions.HierarchyLoadAction),
      groupBy(stringKeys),
      mergeMap(groupedBy => {
        return groupedBy.pipe(
          exhaustMap(payload => {
            return this.hierarchyService.loadHierarchyEffect(payload.institutionId).pipe(
              map(hierarchy => HierarchyActions.HierarchyLoadSuccessAction({ ...payload, hierarchy })),
              catchError(error => of(HierarchyActions.HierarchyLoadErrorAction({ error })))
            );
          })
        );
      })
    ));


  updateHierarchyNode = createEffect(() => this.actions
    .pipe(
      ofType(HierarchyActions.UpdateHierarchyNodeAction),
      groupBy(stringKeys),
      mergeMap(groupedBy => {
        return groupedBy.pipe(
          exhaustMap(payload => {
            return this.hierarchyService.updateHierarchyNodeEffect(payload).pipe(
              map(() => HierarchyActions.UpdateHierarchyNodeSuccessAction(payload)),
              catchError(error => of(HierarchyActions.UpdateHierarchyNodeErrorAction({ error })))
            );
          })
        );
      })
    ));

  changeNodeParent = createEffect(() => this.actions
    .pipe(
      ofType(HierarchyActions.ChangeHierarchyNodeParentAction),
      groupBy(stringKeys),
      mergeMap(groupedBy => {
        return groupedBy.pipe(
          exhaustMap(payload => {
            return this.hierarchyService.changeHierarchyNodeParentEffect(payload).pipe(
              map(() => HierarchyActions.ChangeHierarchyNodeParentSuccessAction(payload)),
              catchError(error => of(HierarchyActions.ChangeHierarchyNodeParentErrorAction({ error })))
            );
          })
        );
      })
    ));

  addCounty = createEffect(() => this.actions
    .pipe(
      ofType(HierarchyActions.AddCountyAction),
      groupBy(stringKeys),
      mergeMap(groupedBy => {
        return groupedBy.pipe(
          exhaustMap(payload => {
            return this.hierarchyService.addCountyEffect(payload).pipe(
              map(() => HierarchyActions.AddCountySuccessAction(payload)),
              catchError(error => of(HierarchyActions.AddCountyErrorAction({ error })))
            );
          })
        );
      })
    ));

  addDistrict = createEffect(() => this.actions
    .pipe(
      ofType(HierarchyActions.AddDistrictAction),
      groupBy(stringKeys),
      mergeMap(groupedBy => {
        return groupedBy.pipe(
          exhaustMap(payload => {
            return this.hierarchyService.addDistrictEffect(payload).pipe(
              map(() => HierarchyActions.AddDistrictSuccessAction(payload)),
              catchError(error => of(HierarchyActions.AddDistrictErrorAction({ error })))
            );
          })
        );
      })
    ));

  addRegion = createEffect(() => this.actions
    .pipe(
      ofType(HierarchyActions.AddRegionAction),
      groupBy(stringKeys),
      mergeMap(groupedBy => {
        return groupedBy.pipe(
          exhaustMap(payload => {
            return this.hierarchyService.addRegionEffect(payload).pipe(
              map(() => HierarchyActions.AddRegionSuccessAction(payload)),
              catchError(error => of(HierarchyActions.AddRegionErrorAction({ error })))
            );
          })
        );
      })
    ));



  constructor(private actions: Actions, private hierarchyService: HierarchyService) {

  }
}
