import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { CommonToastrService, ModalService, ProfileService, RouterService } from 'app/core/services';
import { stringKeys } from 'app/shared/rxjs.utils';
import { SidebarActions } from 'app/shared/sidebar';
import { catchError, exhaustMap, groupBy, map, mergeMap, Observable, of, tap } from 'rxjs';

import { ProfileActions } from '.';
import { UserService } from '../../services';
import { LocalStorageService } from '../../services/local-storage.service';
import { userAccount } from './user.reducer';

@Injectable()
export class ProfileEffects {

    // public createProfile$: Observable<Action> = createEffect(() => this.actions
    //     .pipe(
    //         ofType(ProfileActions.CreateFamilyAction),
    //         groupBy(stringKeys),
    //         mergeMap(groupedBy => {
    //             return groupedBy.pipe(
    //                 exhaustMap(payload => {
    //                     return this.profileService.createProfileEffect(payload.institutionId, payload.profile).pipe(
    //                         take(1),
    //                         mergeMap(() => {
    //                             return [
    //                                 ProfileActions.CreateFamilySuccessAction(),
    //                                 UserActions.GetUserAction({ userAccountToken: payload.userAccountToken }),
    //                             ];
    //                         }),
    //                         catchError(error => {
    //                             return of(ProfileActions.CreateFamilyErrorAction({ error }));
    //                         })
    //                     );
    //                 })
    //             );
    //         })
    //     ));

    public setUserProfile$: Observable<Action> = createEffect(() => this.actions
    .pipe(
        ofType(ProfileActions.GetProfileAuthAction),
        groupBy(stringKeys),
        mergeMap(groupedBy => {
            return groupedBy.pipe(
                exhaustMap(payload => {
                    return this.profileService.authenticateProfileEffect(payload.institutionProfileId).pipe(
                        map(institutionProfileAuth => {
                            if (institutionProfileAuth == null) {
                                throw new Error('Institution Profile null');
                            }
                            return ProfileActions.GetProfileAuthSuccessAction({
                                institutionProfileId: payload.institutionProfileId,
                                institutionProfileAuth: institutionProfileAuth
                            });
                        }),
                        catchError(error => {
                            return of(ProfileActions.GetProfileAuthErrorAction());
                        })
                    );
                })
            );
        })
    ));

    public profileAuthError$: Observable<Action> = createEffect(() => this.actions
        .pipe(
            ofType(ProfileActions.GetProfileAuthErrorAction),
            tap(() => {
                this.toastrService.error('Profile Authentication Error');
                this.routerService.Go(['user', this.routerService.userAccountId, 'profile-selection']);
            })
            // map(() => UserActions.SignOutAction())
        )
    );

    public getProfileAuthSuccess$: Observable<Action> = createEffect(() => this.actions
    .pipe(
        ofType(ProfileActions.GetProfileAuthSuccessAction),
        map(() => SidebarActions.OpenSidebarIfDesktopAction())
    ));

    constructor(
        private actions: Actions,
        private profileService: ProfileService,
        private toastrService: CommonToastrService,
        private routerService: RouterService,
        private localStorageService: LocalStorageService,
        private userService: UserService
    ) {
    }
}
