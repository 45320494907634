import { IAddressDto } from '.';

export interface IDiscountFundDonorUpdateDto {
    donorName?: string;
    businessName?: string;
    phone?: string;
    email?: string;
    address: IAddressDto;
    notes?: string;
}
