import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import {
  AppState,
  getFamiliesByCounty,
  getMember,
  getMemberDashboard,
  getMemberProfileNotes,
  getMemberProgramYear,
  getMemberV1Files
} from 'app/app.reducers';
import {
  eMimeType,
  IEnrollmentAwardSubmissionDto,
  IEnrollmentGroupSubmissionDto,
  IFamilyDto,
  IFileControl,
  IInstitutionFamilyParams,
  IInstitutionManagerProgramHierarchyParams,
  IInstitutionManagerProgramParams,
  IInstitutionMemberParams,
  IInstitutionMemberProgramParams,
  IManagerNoteCreateDto,
  IManagerNoteDto,
  IManagerNoteUpdateDto,
  IMemberCreateDto,
  IMemberDashboardSummaryDto,
  IMemberDto,
  IMemberMergeManagerSubmitDto,
  IMemberProgramDismissalDto,
  IMemberProgramReinstatementDto,
  IMemberProgramVolunteerHoursUpdateDto,
  IMemberProgramYearsInProgramUpdateDto,
  IMemberUpdateDto,
  IProfileTrainingRecordCreateDto
} from 'app/core/models';
import { filter, map, Observable, of, switchMap, take, tap } from 'rxjs';

import { MemberActions } from '../containers/member';
import { IEnrollmentActivitySubmissionDto } from '../models/serverDTOs/IEnrollmentActivitySubmissionDto';
import { IMemberMergeManagerReviewDto } from '../models/serverDTOs/IMemberMergeManagerReviewDto';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MemberService {

  constructor(
    private store: Store<AppState>,
    private httpClient: HttpClient,
    private dispatcher: ActionsSubject,
    private sanitizer: DomSanitizer
  ) {

  }

  public getMember(params: IInstitutionMemberParams): Observable<IMemberDto> {

    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getMember(params)),
      tap(member => {
        if (member === undefined) {
          this.store.dispatch(MemberActions.MemberLoadAction(params));
        }
      }),
      filter(member => member != null)
    );
  }

  public loadMemberEffect({ institutionId, memberId }: IInstitutionMemberParams): Observable<IMemberDto> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}`) as Observable<IMemberDto>;
  }

  public createMember(params: { institutionId: string, familyId: string, member: IMemberCreateDto }): Observable<IMemberDto> {
    this.store.dispatch(MemberActions.MemberCreateAction(params));
    return this.dispatcher.pipe(
      ofType(MemberActions.MemberCreateSuccessAction, MemberActions.MemberCreateErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberActions.MemberCreateSuccessAction.type) {
          return action.member;
        } else {
          throw action.error;
        }
      })
    );
  }

  public createNewMemberEffect({
    institutionId,
    familyId,
    member
  }: { institutionId: string, familyId: string, member: IMemberCreateDto }): Observable<IMemberDto> {
    return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/families/${familyId}/members`,
      member,
      { observe: 'response' })
    .pipe(
      switchMap((res: HttpResponse<any>) => this.httpClient.get(res.headers.get('location')))
    ) as Observable<IMemberDto>;

  }

  public managerArchiveMember(params: IInstitutionMemberParams) {
    this.store.dispatch(MemberActions.MemberArchiveAction(params));
    return this.dispatcher.pipe(
      ofType(MemberActions.MemberArchiveSuccessAction, MemberActions.MemberArchiveErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberActions.MemberArchiveSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public managerArchiveMemberEffect({ institutionId, memberId }: IInstitutionMemberParams) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/archive`, {});
  }

  public managerUnArchiveMember(params: IInstitutionMemberParams) {
    this.store.dispatch(MemberActions.MemberUnArchiveAction(params));
    return this.dispatcher.pipe(
      ofType(MemberActions.MemberUnArchiveSuccessAction, MemberActions.MemberUnArchiveErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberActions.MemberUnArchiveSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public managerUnArchiveMemberEffect({ institutionId, memberId }: IInstitutionMemberParams) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/un-archive`, {});
  }

  public managerMarkMemberDeceased(params: IInstitutionMemberParams & { deceasedDate: string }) {
    this.store.dispatch(MemberActions.MemberMarkDeceasedAction(params));
    return this.dispatcher.pipe(
      ofType(MemberActions.MemberMarkDeceasedSuccessAction, MemberActions.MemberMarkDeceasedErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberActions.MemberMarkDeceasedSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public managerMarkMemberDeceasedEffect({
    institutionId,
    memberId,
    deceasedDate
  }: IInstitutionMemberParams & { deceasedDate: string }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/deceased?deceasedDate=${deceasedDate}`, {});
  }

  public managerDeleteMember(params: { institutionId: string, memberId: string }) {
    this.store.dispatch(MemberActions.MemberRemoveAction(params));
    return this.dispatcher.pipe(
      ofType(MemberActions.MemberRemoveSuccessAction, MemberActions.MemberRemoveErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberActions.MemberRemoveSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public managerDeleteMemberEffect({ institutionId, memberId }: { institutionId: string, memberId: string }) {
    // remove member from secondary family
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}`);
  }

  public managerRestoreMember(params: { institutionId: string, memberId: string }) {
    this.store.dispatch(MemberActions.MemberRestoreAction(params));
    return this.dispatcher.pipe(
      ofType(MemberActions.MemberRestoreSuccessAction, MemberActions.MemberRestoreErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberActions.MemberRestoreSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public managerRestoreMemberEffect({ institutionId, memberId }: { institutionId: string, memberId: string }) {
    // remove member from secondary family
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/restore`, {});
  }

  public managerDismissMember(params: IInstitutionMemberProgramParams & { dismissal: IMemberProgramDismissalDto }) {
    this.store.dispatch(MemberActions.MemberDismissAction(params));
    return this.dispatcher.pipe(
      ofType(MemberActions.MemberDismissSuccessAction, MemberActions.MemberDismissErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberActions.MemberDismissSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public managerDismissMemberEffect({
    institutionId,
    memberId,
    programId,
    dismissal
  }: IInstitutionMemberProgramParams & { dismissal: IMemberProgramDismissalDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/dismiss`, dismissal);
  }

  public managerReinstateMember(params: IInstitutionMemberProgramParams & { reinstate: IMemberProgramReinstatementDto }) {
    this.store.dispatch(MemberActions.MemberReinstateAction(params));
    return this.dispatcher.pipe(
      ofType(MemberActions.MemberReinstateSuccessAction, MemberActions.MemberReinstateErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberActions.MemberReinstateSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public managerReinstateMemberEffect({
    institutionId,
    memberId,
    programId,
    reinstate
  }: IInstitutionMemberProgramParams & { reinstate: IMemberProgramReinstatementDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/reinstate`, reinstate);
  }

  public updateMember({
    institutionId,
    memberId,
    memberUpdate
  }: IInstitutionMemberParams & { memberUpdate: IMemberDto }) {

    const member: IMemberUpdateDto = {
      email: memberUpdate.email,
      firstName: memberUpdate.firstName,
      middleName: memberUpdate.middleName,
      lastName: memberUpdate.lastName,
      preferredName: memberUpdate.preferredName,
      primaryPhone: memberUpdate.primaryPhone,
      mobilePhone: memberUpdate.mobilePhone,
      birthDate: memberUpdate.birthDate,
      jobTitle: memberUpdate.jobTitle,
      employer: memberUpdate.employer,
      workPhone: memberUpdate.workPhone,
      workExt: memberUpdate.workExt,
      emergencyContact: memberUpdate.emergencyContact,
      emergencyContactPhone: memberUpdate.emergencyContactPhone,
      emergencyContactEmail: memberUpdate.emergencyContactEmail,
      emergencyContactRelationship: memberUpdate.emergencyContactRelationship,
      guardian1FirstName: memberUpdate.guardian1FirstName,
      guardian1LastName: memberUpdate.guardian1LastName,
      guardian1Phone: memberUpdate.guardian1Phone,
      guardian1WorkPhone: memberUpdate.guardian1WorkPhone,
      guardian1WorkExt: memberUpdate.guardian1WorkExt,
      guardian2FirstName: memberUpdate.guardian2FirstName,
      guardian2LastName: memberUpdate.guardian2LastName,
      guardian2Phone: memberUpdate.guardian2Phone,
      guardian2WorkPhone: memberUpdate.guardian2WorkPhone,
      guardian2WorkExt: memberUpdate.guardian2WorkExt,

      addressLine1: memberUpdate.addressLine1,
      addressLine2: memberUpdate.addressLine2,
      city: memberUpdate.city,
      state: memberUpdate.state,
      postalCode: memberUpdate.postalCode,

      emergencyContact2: memberUpdate.emergencyContact2,
      emergencyContact2Phone: memberUpdate.emergencyContact2Phone,
      emergencyContact2Email: memberUpdate.emergencyContact2Email,
      emergencyContact2Relationship: memberUpdate.emergencyContact2Relationship,

      secondFamilyName: memberUpdate.secondFamilyName,
      secondFamilyFirstNames: memberUpdate.secondFamilyFirstNames,
      secondFamilyEmail: memberUpdate.secondFamilyEmail,
      secondFamilyPhone: memberUpdate.secondFamilyPhone,
      secondFamilyAddressLine1: memberUpdate.secondFamilyAddressLine1,
      secondFamilyAddressLine2: memberUpdate.secondFamilyAddressLine2,
      secondFamilyCity: memberUpdate.secondFamilyCity,
      secondFamilyState: memberUpdate.secondFamilyState,
      secondFamilyPostalCode: memberUpdate.secondFamilyPostalCode,
      demographics: memberUpdate.demographics,
      youthYearsInProgram: memberUpdate.youthYearsInProgram,
      adultYearsInProgram: memberUpdate.adultYearsInProgram
      // enrollmentRoleType: memberUpdate.enrollmentRoleType,
      // schoolGrade: memberUpdate.schoolGrade
    };

    this.store.dispatch(MemberActions.MemberUpdateAction({
      institutionId: institutionId,
      memberId: memberId,
      member: member
    }));
    return this.dispatcher.pipe(
      ofType(MemberActions.MemberUpdateSuccessAction, MemberActions.MemberUpdateErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberActions.MemberUpdateSuccessAction.type) {
          return action.member;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateMemberEffect({
    institutionId,
    memberId,
    member
  }: IInstitutionMemberParams & { member: IMemberUpdateDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}`, member);
  }

  public deleteMember(params: IInstitutionMemberParams) {
    this.store.dispatch(MemberActions.MemberDeleteAction(params));
    return this.dispatcher.pipe(
      ofType(MemberActions.MemberDeleteSuccessAction, MemberActions.MemberDeleteErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberActions.MemberDeleteSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteMemberEffect({ institutionId, memberId }: IInstitutionMemberParams) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}`);
  }

  public getMemberDashboard(params: IInstitutionMemberParams): Observable<IMemberDashboardSummaryDto> {

    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getMemberDashboard(params)),
      tap((dashboard) => {
        if (dashboard === undefined) {
          this.store.dispatch(MemberActions.MemberLoadDashboardAction(params));
        }
      }),
      filter(enrollment => enrollment != null)
    );
  }

  public loadMemberDashboardEffect({ institutionId, memberId }: IInstitutionMemberParams) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/dashboard`) as Observable<IMemberDashboardSummaryDto>;
  }

  public flagMember(params: IInstitutionManagerProgramHierarchyParams & { memberId: string }) {
    this.store.dispatch(MemberActions.MemberFlagAction(params));
    return this.dispatcher.pipe(
      ofType(MemberActions.MemberFlagSuccessAction, MemberActions.MemberFlagErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberActions.MemberFlagSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public flagMemberEffect({
    institutionId,
    managerId,
    programId,
    hierarchyNodeId,
    memberId
  }: IInstitutionManagerProgramHierarchyParams & { memberId: string }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/${hierarchyNodeId}/flag?memberId=${memberId}`, {});
  }

  public deleteMemberFlag(params: IInstitutionManagerProgramHierarchyParams & { memberId: string }) {
    this.store.dispatch(MemberActions.MemberFlagDeleteAction(params));
    return this.dispatcher.pipe(
      ofType(MemberActions.MemberFlagDeleteSuccessAction, MemberActions.MemberFlagDeleteErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberActions.MemberFlagDeleteSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteMemberFlagEffect({
    institutionId,
    managerId,
    programId,
    hierarchyNodeId,
    memberId
  }: IInstitutionManagerProgramHierarchyParams & { memberId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/${hierarchyNodeId}/flag?memberId=${memberId}`);
  }

  public clearAllFlags(params: IInstitutionManagerProgramHierarchyParams) {
    this.store.dispatch(MemberActions.MemberClearAllFlagsAction(params));
    return this.dispatcher.pipe(
      ofType(MemberActions.MemberClearAllFlagsSuccessAction, MemberActions.MemberClearAllFlagsErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberActions.MemberClearAllFlagsSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public clearAllFlagsEffect({
    institutionId,
    managerId,
    programId,
    hierarchyNodeId
  }: IInstitutionManagerProgramHierarchyParams) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/${hierarchyNodeId}/all-flags`);
  }

  public flagAllResults(params: IInstitutionManagerProgramHierarchyParams) {
    this.store.dispatch(MemberActions.MemberFlagAllResultsAction(params));
    return this.dispatcher.pipe(
      ofType(MemberActions.MemberFlagAllResultsSuccessAction, MemberActions.MemberFlagAllResultsErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberActions.MemberFlagAllResultsSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public flagAllResultsEffect({
    institutionId,
    managerId,
    programId,
    hierarchyNodeId
  }: IInstitutionManagerProgramHierarchyParams) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/${hierarchyNodeId}/search/flag-all`, {});
  }

  public addFlaggedToActivity(params: IInstitutionManagerProgramHierarchyParams & { activityId: string, activitySubmission: IEnrollmentActivitySubmissionDto }) {
    this.store.dispatch(MemberActions.MemberAddFlaggedToActivityAction(params));
    return this.dispatcher.pipe(
      ofType(MemberActions.MemberAddFlaggedToActivitySuccessAction, MemberActions.MemberAddFlaggedToActivityErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberActions.MemberAddFlaggedToActivitySuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public addFlaggedToActivityEffect({
    institutionId,
    managerId,
    programId,
    hierarchyNodeId,
    activityId,
    activitySubmission
  }: IInstitutionManagerProgramHierarchyParams & { activityId: string, activitySubmission: IEnrollmentActivitySubmissionDto }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/${hierarchyNodeId}/flagged/${activityId}`, activitySubmission);
  }

  public addFlaggedToAward(params: IInstitutionManagerProgramHierarchyParams & { awardId: string, awardSubmission: IEnrollmentAwardSubmissionDto }) {
    this.store.dispatch(MemberActions.MemberAddFlaggedToAwardAction(params));
    return this.dispatcher.pipe(
      ofType(MemberActions.MemberAddFlaggedToAwardSuccessAction, MemberActions.MemberAddFlaggedToAwardErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberActions.MemberAddFlaggedToAwardSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public addFlaggedToAwardEffect({
    institutionId,
    managerId,
    programId,
    hierarchyNodeId,
    awardId,
    awardSubmission
  }: IInstitutionManagerProgramHierarchyParams & { awardId: string, awardSubmission: IEnrollmentAwardSubmissionDto }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/${hierarchyNodeId}/flagged/${awardId}`, awardSubmission);
  }

  public addFlaggedToGroup(params: IInstitutionManagerProgramHierarchyParams & { groupId: string, groupSubmission: IEnrollmentGroupSubmissionDto }) {
    this.store.dispatch(MemberActions.MemberAddFlaggedToGroupAction(params));
    return this.dispatcher.pipe(
      ofType(MemberActions.MemberAddFlaggedToGroupSuccessAction, MemberActions.MemberAddFlaggedToGroupErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberActions.MemberAddFlaggedToGroupSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public addFlaggedToGroupEffect({
    institutionId,
    managerId,
    programId,
    hierarchyNodeId,
    groupId,
    groupSubmission
  }: IInstitutionManagerProgramHierarchyParams & { groupId: string, groupSubmission: IEnrollmentGroupSubmissionDto }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/${hierarchyNodeId}/flagged/${groupId}`, groupSubmission);
  }

  public addFlaggedToTraining(params: IInstitutionManagerProgramHierarchyParams & { trainingId: string, trainingSubmission: IProfileTrainingRecordCreateDto }) {
    this.store.dispatch(MemberActions.MemberAddFlaggedToTrainingAction(params));
    return this.dispatcher.pipe(
      ofType(MemberActions.MemberAddFlaggedToTrainingSuccessAction, MemberActions.MemberAddFlaggedToTrainingErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberActions.MemberAddFlaggedToTrainingSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public addFlaggedToTrainingEffect({
    institutionId,
    managerId,
    programId,
    hierarchyNodeId,
    trainingId,
    trainingSubmission
  }: IInstitutionManagerProgramHierarchyParams & { trainingId: string, trainingSubmission: IProfileTrainingRecordCreateDto }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/${hierarchyNodeId}/flagged/${trainingId}`, trainingSubmission);
  }

  public flagPageResults(params: IInstitutionManagerProgramHierarchyParams & { pagesize: string, pageindex: string }) {
    this.store.dispatch(MemberActions.MemberFlagPageResultsAction(params));
    return this.dispatcher.pipe(
      ofType(MemberActions.MemberFlagPageResultsSuccessAction, MemberActions.MemberFlagPageResultsErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberActions.MemberFlagPageResultsSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public flagPageResultsEffect({
    institutionId,
    managerId,
    programId,
    hierarchyNodeId,
    pagesize,
    pageindex
  }: IInstitutionManagerProgramHierarchyParams & { pagesize: string, pageindex: string }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/${hierarchyNodeId}/search/flag-viewable?pagesize=${pagesize}&pageindex=${pageindex}`, {});
  }

  public startMergeMemberEffect({
    institutionId,
    managerId,
    programId,
    targetMemberId,
    sourceMemberId
  }: IInstitutionManagerProgramParams & { targetMemberId: string, sourceMemberId }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/merge-members?targetMemberId=${targetMemberId}&sourceMemberId=${sourceMemberId}`) as Observable<IMemberMergeManagerReviewDto>;
  }

  public mergeMember(params: IInstitutionManagerProgramParams & { memberMerge: IMemberMergeManagerSubmitDto }) {
    this.store.dispatch(MemberActions.MergeMemberAction(params));
    return this.dispatcher.pipe(
      ofType(MemberActions.MergeMemberSuccessAction, MemberActions.MergeMemberErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberActions.MergeMemberSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public mergeMemberEffect({
    institutionId,
    managerId,
    programId,
    memberMerge
  }: IInstitutionManagerProgramParams & { memberMerge: IMemberMergeManagerSubmitDto }) {
    return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/merge-members`, memberMerge);
  }

  public getMergedMember({
    institutionId,
    managerId,
    programId,
    mergedMemberId
  }: IInstitutionManagerProgramParams & { mergedMemberId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/merge-members/${mergedMemberId}`);
  }

  public getMemberProgramYear(params: IInstitutionMemberParams): Observable<string> {

    if (params.institutionId == null || params.memberId == null) {
      return of(null);
    }
    return this.store.pipe(
      select(getMemberProgramYear(params))
    );
  }

  public setMemberProgramYear(params: IInstitutionMemberParams & { programYearId: string }) {
    this.store.dispatch(MemberActions.MemberSetProgramYearAction(params));
  }

  public togglePrimaryFamily(params: IInstitutionFamilyParams & { memberId: string, isPrimaryFamily: boolean }) {
    this.store.dispatch(MemberActions.TogglePrimaryFamilyAction(params));
    return this.dispatcher.pipe(
      ofType(MemberActions.TogglePrimaryFamilySuccessAction, MemberActions.TogglePrimaryFamilyErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberActions.TogglePrimaryFamilySuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public togglePrimaryFamilyEffect({
    institutionId,
    familyId,
    memberId,
    isPrimaryFamily
  }: IInstitutionFamilyParams & { memberId: string, isPrimaryFamily: boolean }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/families/${familyId}/members/${memberId}?isPrimaryFamily=${isPrimaryFamily}`, {});
  }

  public removeMemberFromNonPrimaryFamily(params: IInstitutionFamilyParams & { memberId: string }) {
    this.store.dispatch(MemberActions.RemoveMemberFromNonPrimaryFamilyAction(params));
    return this.dispatcher.pipe(
      ofType(MemberActions.RemoveMemberFromNonPrimaryFamilySuccessAction, MemberActions.RemoveMemberFromNonPrimaryFamilyErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberActions.RemoveMemberFromNonPrimaryFamilySuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public removeMemberFromNonPrimaryFamilyEffect({
    institutionId,
    familyId,
    memberId
  }: IInstitutionFamilyParams & { memberId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/families/${familyId}/members/${memberId}`);
  }

  public getFamiliesForCounty(params: { institutionId: string, countyAreaId: string }) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getFamiliesByCounty(params)),
      tap(families => {
        if (families === undefined) {
          this.store.dispatch(MemberActions.GetFamiliesForCountyAction(params));
        }
      }),
      filter(families => families != null)
    );
  }

  public getFamiliesForCountyEffect({ institutionId, countyAreaId }: { institutionId: string, countyAreaId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/families/${countyAreaId}`) as Observable<IFamilyDto[]>;
  }

  public updateYearsInProgram(params: IInstitutionMemberProgramParams & { yearsInProgramUpdate: IMemberProgramYearsInProgramUpdateDto }) {
    this.store.dispatch(MemberActions.UpdateYearsInProgramAction(params));
    return this.dispatcher.pipe(
      ofType(MemberActions.UpdateYearsInProgramSuccessAction, MemberActions.UpdateYearsInProgramErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberActions.UpdateYearsInProgramSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateYearsInProgramEffect({
    institutionId,
    memberId,
    programId,
    yearsInProgramUpdate
  }: IInstitutionMemberProgramParams & { yearsInProgramUpdate: IMemberProgramYearsInProgramUpdateDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/years-in-program`, yearsInProgramUpdate);
  }

  public moveToFamily(params: IInstitutionFamilyParams & { memberId: string }) {
    this.store.dispatch(MemberActions.MoveToFamilyAction(params));
    return this.dispatcher.pipe(
      ofType(MemberActions.MoveToFamilySuccessAction, MemberActions.MoveToFamilyErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberActions.MoveToFamilySuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public moveToFamilyEffect({ institutionId, familyId, memberId }: IInstitutionFamilyParams & { memberId: string }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/families/${familyId}/members/${memberId}`, {});
  }

  public getProfileNotes(params: IInstitutionMemberProgramParams) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getMemberProfileNotes(params)),
      tap(profileNotes => {
        if (profileNotes === undefined) {
          this.store.dispatch(MemberActions.GetProfileNotesAction(params));
        }
      }),
      filter(profileNotes => profileNotes != null)
    );
  }

  public getProfileNotesEffect({
    institutionId,
    memberId,
    programId
  }: IInstitutionMemberProgramParams): Observable<IManagerNoteDto[]> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/notes`) as Observable<IManagerNoteDto[]>;
  }

  public createProfileNote(params: IInstitutionMemberProgramParams & { noteCreateDto: IManagerNoteCreateDto }) {
    this.store.dispatch(MemberActions.CreateProfileNotesAction(params));
    return this.dispatcher.pipe(
      ofType(MemberActions.CreateProfileNotesSuccessAction, MemberActions.CreateProfileNotesErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberActions.CreateProfileNotesSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public createProfileNoteEffect({
    institutionId,
    memberId,
    programId,
    noteCreateDto
  }: IInstitutionMemberProgramParams & { noteCreateDto: IManagerNoteCreateDto }) {
    return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/notes`, noteCreateDto);
  }

  public updateProfileNote(params: IInstitutionMemberProgramParams & { profileNoteId: string, noteUpdateDto: IManagerNoteUpdateDto }) {
    this.store.dispatch(MemberActions.UpdateProfileNotesAction(params));
    return this.dispatcher.pipe(
      ofType(MemberActions.UpdateProfileNotesSuccessAction, MemberActions.UpdateProfileNotesErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberActions.UpdateProfileNotesSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateProfileNoteEffect({
    institutionId,
    memberId,
    programId,
    profileNoteId,
    noteUpdateDto
  }: IInstitutionMemberProgramParams & { profileNoteId: string, noteUpdateDto: IManagerNoteUpdateDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/notes/${profileNoteId}`, noteUpdateDto);
  }

  public deleteProfileNote(params: IInstitutionMemberProgramParams & { profileNoteId: string }) {
    this.store.dispatch(MemberActions.DeleteProfileNotesAction(params));
    return this.dispatcher.pipe(
      ofType(MemberActions.DeleteProfileNotesSuccessAction, MemberActions.DeleteProfileNotesErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberActions.DeleteProfileNotesSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteProfileNoteEffect({
    institutionId,
    memberId,
    programId,
    profileNoteId
  }: IInstitutionMemberProgramParams & { profileNoteId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/notes/${profileNoteId}`);
  }

  public downloadProfileNoteFile(params: IInstitutionMemberProgramParams & { profileNoteId: string }) {
    this.store.dispatch(MemberActions.DownloadProfileNotesFileAction(params));
    return this.dispatcher.pipe(
      ofType(MemberActions.DownloadProfileNotesFileSuccessAction, MemberActions.DownloadProfileNotesFileErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberActions.DownloadProfileNotesFileSuccessAction.type) {
          return action.file;
        } else {
          throw action.error;
        }
      })
    );
  }

  public downloadProfileNoteFileEffect({
    institutionId,
    memberId,
    programId,
    profileNoteId
  }: IInstitutionMemberProgramParams & { profileNoteId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/notes/${profileNoteId}/file`, { responseType: 'blob' });
  }

  public deleteProfileNoteFile(params: IInstitutionMemberProgramParams & { profileNoteId: string }) {
    this.store.dispatch(MemberActions.DeleteProfileNotesAction(params));
    return this.dispatcher.pipe(
      ofType(MemberActions.DeleteProfileNotesSuccessAction, MemberActions.DeleteProfileNotesErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberActions.DeleteProfileNotesSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteProfileNoteFileEffect({
    institutionId,
    memberId,
    programId,
    profileNoteId
  }: IInstitutionMemberProgramParams & { profileNoteId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/notes/${profileNoteId}/file`);
  }

  public addProfileNoteFile(params: IInstitutionMemberProgramParams & { profileNoteId: string, formData: FormData }) {
    this.store.dispatch(MemberActions.AddProfileNotesFileAction(params));
    return this.dispatcher.pipe(
      ofType(MemberActions.AddProfileNotesFileSuccessAction, MemberActions.AddProfileNotesFileErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberActions.AddProfileNotesFileSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public addProfileNoteFileEffect({
    institutionId,
    memberId,
    programId,
    profileNoteId,
    formData
  }: IInstitutionMemberProgramParams & { profileNoteId: string, formData: FormData }) {
    return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/notes/${profileNoteId}/file`, formData);
  }

  public getProfileNoteThumbnailEffect({
    institutionId,
    memberId,
    programId,
    profileNoteId
  }: IInstitutionMemberProgramParams & { profileNoteId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/notes/${profileNoteId}/file/thumbnail`, { responseType: 'blob' }).pipe(
      map(blob => blob.type === eMimeType.PDF ? '/assets/images/pdf.png' : this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob)))
    );
  }

  public getMemberV1Files(params: IInstitutionMemberProgramParams) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getMemberV1Files(params)),
      tap(member => {
        if (member === undefined) {
          this.store.dispatch(MemberActions.MemberLoadV1FilesAction(params));
        }
      }),
      filter(member => member != null)
    );
  }

  public loadMemberV1FilesEffect({ institutionId, memberId, programId }: IInstitutionMemberProgramParams) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/v1-file-controls`) as Observable<IFileControl[]>;
  }

  public updateMemberProgramVolunteerHours(params: IInstitutionMemberProgramParams & { memberProgramVolunteerHoursUpdate: IMemberProgramVolunteerHoursUpdateDto }) {
    this.store.dispatch(MemberActions.UpdateMemberProgramVolunteerHoursAction(params));

    return this.dispatcher.pipe(
      ofType(MemberActions.UpdateMemberProgramVolunteerHoursSuccessAction, MemberActions.UpdateMemberProgramVolunteerHoursErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberActions.UpdateMemberProgramVolunteerHoursSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateMemberProgramVolunteerHoursEffect({
    institutionId,
    memberId,
    programId,
    memberProgramVolunteerHoursUpdate
  }: IInstitutionMemberProgramParams & { memberProgramVolunteerHoursUpdate: IMemberProgramVolunteerHoursUpdateDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/volunteer-hours`, memberProgramVolunteerHoursUpdate);
  }
}


