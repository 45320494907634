import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  eEnrollmentRoleType,
  eMemberRole,
  IInvoiceDto,
  IMemberConsentSubmitDto,
  IMemberDto,
  IMemberProgramConsentDto,
  IProgramSettingsDto,
} from 'app/core/models';
import { ProfileService, ProgramSettingsService, RouterService } from 'app/core/services';
import { Observable } from 'rxjs';

@Component({
  selector: 'ng4h-modal-credit-consent',
  templateUrl: './modal-credit-consent.component.html',
  styleUrls: ['./modal-credit-consent.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalCreditConsentComponent implements OnInit {

  public eMemberRole = eMemberRole;
  public eEnrollmentRoleType = eEnrollmentRoleType;
  public programSettings$: Observable<IProgramSettingsDto>;
  public paymentConsentSigned: boolean;
  private paymentConsentResponse: IMemberConsentSubmitDto;
  public isManager$: Observable<boolean>;

  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: { invoice: IInvoiceDto, member: IMemberDto },
    private programSettingsService: ProgramSettingsService,
    private routerService: RouterService,
    private profileService: ProfileService
  ) { }

  ngOnInit(): void {
    this.programSettings$ = this.programSettingsService.getProgramSettings({
      institutionId: this.routerService.institutionId,
      programId: this.routerService.programId
    });
    this.isManager$ = this.profileService.activeUserInstitutionProfileIsManager$;
  }

  public close(res?: any) {
    this.dialogRef.close(res);
  }


  public paymentSigned(consents: IMemberProgramConsentDto[]) {

    if (consents != null && Array.isArray(consents)) {
      const signedConsent = consents[0];
      this.paymentConsentResponse = {
        consentStatus: signedConsent.consentStatus,
        consentVersionId: signedConsent.consentVersion.consentVersionId,
        recordedMemberName: signedConsent.recordedMemberName,
        recordedParentGuardianName: signedConsent.recordedParentGuardianName,
        recordedManagerName: signedConsent.recordedManagerName
      };
      this.paymentConsentSigned = true;
    } else {
      this.paymentConsentResponse = null;
      this.paymentConsentSigned = false;
    }

  }

  public confirm() {
    if (this.paymentConsentResponse != null) {
      this.close(this.paymentConsentResponse);
    } else {
      this.close({});
    }
  }

}
