import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import {
    AppState,
    getDynamicQuestionFormInputs,
    getEventRegistrationAnimals,
    getEventRegistrations,
    getEventsMemberCustomFiles,
    getMemberEvent,
    getMemberEvents
} from 'app/app.reducers';
import {
    IDynamicForm,
    IEnrollmentAnimalDto,
    IEventDto,
    IEventRegistrationCreateDto,
    IEventRegistrationDto,
    IInstitutionMemberEventParams,
    IInstitutionMemberParams,
    IInstitutionMemberProgramParams,
    IMemberConsentSubmitDto,
    IPartialPaymentSubmitDto,
    IPayableInvoiceDto,
    IRegistrationTypeFileControlDto
} from 'app/core/models';
import { EventMemberActions } from 'app/shared/events-member';
import { distinctUntilChanged, filter, map, Observable, of, shareReplay, switchMap, take, tap } from 'rxjs';

import {
    IInstitutionMemberProgramEventRegistrationParams
} from '../models/function-parameters/institution-member-program-event-registration';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class EventsMemberService {

    constructor(private store: Store<AppState>, private httpClient: HttpClient, private dispatcher: ActionsSubject) {

    }

    public getEvent(params: IInstitutionMemberEventParams): Observable<IEventDto> {

        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getMemberEvent(params)),
            tap(event => {
                if (event === undefined) {
                    this.store.dispatch(EventMemberActions.EventMemberLoadAction(params));
                }
            }),
            filter(e => e != null)
        );

    }

    public loadEventEffect({ institutionId, memberId, eventId }: IInstitutionMemberEventParams): Observable<IEventDto> {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/events/${eventId}`) as Observable<IEventDto>;
    }

    public refreshEventRegistration() {
        this.store.dispatch(EventMemberActions.EventsMemberInvalidateEventRegistrationsAction());

        return this.dispatcher.pipe(
            ofType(EventMemberActions.EventsMemberLoadRegistrationSuccessAction, EventMemberActions.EventsMemberLoadRegistrationErrorAction),
            take(1),
            map(action => {
                if (action.type === EventMemberActions.EventsMemberLoadRegistrationSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public refreshDynamicQuestions() {
        this.store.dispatch(EventMemberActions.EventsMemberInvalidateDynamicFormsCacheAction());

        return this.dispatcher.pipe(
            ofType(EventMemberActions.EventsMemberLoadDynamicFormSuccessAction, EventMemberActions.EventsMemberLoadDynamicFormErrorAction),
            take(1),
            map(action => {
                if (action.type === EventMemberActions.EventsMemberLoadDynamicFormSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    // public refreshInvoice() {
    //   this.store.dispatch(EventMemberActions.EventsMemberPayableInvoiceInvalidateCacheAction());

    //   return this.dispatcher.pipe(
    //     ofType(EventMemberActions.EventsMemberLoadPayableInvoiceSuccessAction, EventMemberActions.EventsMemberLoadPayableInvoiceErrorAction),
    //     take(1),
    //     map(action => {
    //       if (action.type === EventMemberActions.EventsMemberLoadPayableInvoiceSuccessAction.type) {
    //         return action;
    //       } else {
    //         throw action.error;
    //       }
    //     })
    //   );
    // }

    public getEvents(params: IInstitutionMemberParams): Observable<IEventDto[]> {

        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getMemberEvents(params)),
            tap(invoice => {
                this.store.dispatch(EventMemberActions.EventsMemberLoadAction(params));

            }),
            filter(e => e != null)
        );
    }

    public loadEventsEffect({ institutionId, memberId }: IInstitutionMemberParams): Observable<IEventDto[]> {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/events`) as Observable<IEventDto[]>;
    }

    public getEventRegistration(params: IInstitutionMemberProgramEventRegistrationParams): Observable<IEventRegistrationDto> {

        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getEventRegistrations(params)),
            tap(invoice => {
                if (invoice === undefined) {
                    this.store.dispatch(EventMemberActions.EventsMemberLoadRegistrationAction(params));
                }
            }),
            filter(e => e != null)
        );
    }

    public loadEventRegistrationEffect({
        institutionId,
        memberId,
        programId,
        eventRegistrationId
    }: IInstitutionMemberProgramEventRegistrationParams) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/event-registrations/${eventRegistrationId}`) as Observable<IEventRegistrationDto>;
    }

    public eventRegistrationCanSubmit(params: IInstitutionMemberProgramEventRegistrationParams) {
        return this.getEventRegistration(params).pipe(map(eventRegistration => eventRegistration != null && eventRegistration.canSubmit === true), distinctUntilChanged(), shareReplay({
            bufferSize: 1,
            refCount: true
        }));
    }

    public registerForEvent(payload: IInstitutionMemberProgramParams & { eventRegistrationCreate: IEventRegistrationCreateDto }) {
        this.store.dispatch(EventMemberActions.EventsMemberRegisterForEventAction(payload));

        return this.dispatcher.pipe(
            ofType(EventMemberActions.EventsMemberRegisterForEventSuccessAction, EventMemberActions.EventsMemberRegisterForEventErrorAction),
            take(1),
            map(action => {
                if (action.type === EventMemberActions.EventsMemberRegisterForEventSuccessAction.type) {
                    return action.eventRegistration;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public registerForEventEffect({
        institutionId,
        memberId,
        programId,
        eventRegistrationCreate
    }: IInstitutionMemberProgramParams & { eventRegistrationCreate: IEventRegistrationCreateDto }) {
        return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/event-registrations`, eventRegistrationCreate, { observe: 'response' })
            .pipe(switchMap((res: HttpResponse<any>) => this.httpClient.get(res.headers.get('location')))) as Observable<IEventRegistrationDto>;
    }

    // public getPayableInvoice(params: IInstitutionMemberProgramEventRegistrationParams): Observable<IPayableInvoiceDto> {

    //   if (Object.keys(params).find(key => params[key] == null) != null) {
    //     return of(null);
    //   }
    //   return this.store.pipe(
    //     select(getPayableInvoice(params)),
    //     tap(invoice => {
    //       if (invoice === undefined) {
    //         this.store.dispatch(EventMemberActions.EventsMemberLoadPayableInvoiceAction(params));
    //       }
    //     }),
    //   );
    // }

    // // Get all outstanding invoices for the provided event registration
    // public loadInvoiceEffect({ institutionId, memberId, programId, eventRegistrationId }: IInstitutionMemberProgramEventRegistrationParams): Observable<IPayableInvoiceDto> {
    //   return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/event-registrations/${eventRegistrationId}/invoices/payable`) as Observable<IPayableInvoiceDto>;
    // }

    public applyPayment(params: IInstitutionMemberProgramEventRegistrationParams & { invoiceId: string, paymentMethodId: string, memberProgramConsentSubmitDto: IMemberConsentSubmitDto }) {
        this.store.dispatch(EventMemberActions.EventsMemberApplyPaymentAction(params));

        return this.dispatcher.pipe(
            ofType(EventMemberActions.EventsMemberApplyPaymentSuccessAction, EventMemberActions.EventsMemberApplyPaymentErrorAction),
            take(1),
            map(action => {
                if (action.type === EventMemberActions.EventsMemberApplyPaymentSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public applyPaymentEffect({
        institutionId,
        memberId,
        programId,
        eventRegistrationId,
        invoiceId,
        paymentMethodId,
        memberProgramConsentSubmitDto
    }: IInstitutionMemberProgramEventRegistrationParams & { invoiceId: string, paymentMethodId: string, memberProgramConsentSubmitDto: IMemberConsentSubmitDto }) {
        return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/event-registrations/${eventRegistrationId}/invoices/${invoiceId}?paymentMethodId=${paymentMethodId}`, memberProgramConsentSubmitDto);
    }

    public applyAdditionalPayment(params: IInstitutionMemberProgramEventRegistrationParams & { invoiceId: string, paymentId: string, paymentMethodId: string, paymentConsentResponse: IMemberConsentSubmitDto }) {
        this.store.dispatch(EventMemberActions.EventsMemberApplyAdditionalPaymentAction(params));

        return this.dispatcher.pipe(
            ofType(EventMemberActions.EventsMemberApplyAdditionalPaymentSuccessAction, EventMemberActions.EventsMemberApplyAdditionalPaymentErrorAction),
            take(1),
            map(action => {
                if (action.type === EventMemberActions.EventsMemberApplyAdditionalPaymentSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public applyAdditionalPaymentEffect({
        institutionId,
        memberId,
        programId,
        eventRegistrationId,
        invoiceId,
        paymentId,
        paymentMethodId,
        paymentConsentResponse
    }: IInstitutionMemberProgramEventRegistrationParams & { invoiceId: string, paymentId: string, paymentMethodId: string, paymentConsentResponse: IMemberConsentSubmitDto }) {
        return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/event-registrations/${eventRegistrationId}/invoices/${invoiceId}/additional-payment?paymentId=${paymentId}&paymentMethodId=${paymentMethodId}`, paymentConsentResponse);
    }

    public applyPartialPayment(params: IInstitutionMemberProgramEventRegistrationParams & { invoiceId: string, paymentMethodId: string, partialPaymentSubmit: IPartialPaymentSubmitDto }) {
        this.store.dispatch(EventMemberActions.EventsMemberApplyPartialPaymentAction(params));

        return this.dispatcher.pipe(
            ofType(EventMemberActions.EventsMemberApplyPartialPaymentSuccessAction, EventMemberActions.EventsMemberApplyPartialPaymentErrorAction),
            take(1),
            map(action => {
                if (action.type === EventMemberActions.EventsMemberApplyPartialPaymentSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public applyPartialPaymentEffect({
        institutionId,
        memberId,
        programId,
        eventRegistrationId,
        invoiceId,
        paymentMethodId,
        partialPaymentSubmit
    }: IInstitutionMemberProgramEventRegistrationParams & { invoiceId: string, paymentMethodId: string, partialPaymentSubmit: IPartialPaymentSubmitDto }) {
        return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/event-registrations/${eventRegistrationId}/invoices/${invoiceId}/partial-payment?paymentMethodId=${paymentMethodId}`, partialPaymentSubmit);
    }

    public applyDepositPayment(params: IInstitutionMemberProgramEventRegistrationParams & { invoiceId: string, paymentMethodId: string, consentSubmit: IMemberConsentSubmitDto }) {
        this.store.dispatch(EventMemberActions.EventsMemberApplyDepositPaymentAction(params));

        return this.dispatcher.pipe(
            ofType(EventMemberActions.EventsMemberApplyDepositPaymentSuccessAction, EventMemberActions.EventsMemberApplyDepositPaymentErrorAction),
            take(1),
            map(action => {
                if (action.type === EventMemberActions.EventsMemberApplyDepositPaymentSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public applyDepositPaymentEffect({
        institutionId,
        memberId,
        programId,
        eventRegistrationId,
        invoiceId,
        paymentMethodId,
        consentSubmit
    }: IInstitutionMemberProgramEventRegistrationParams & { invoiceId: string, paymentMethodId: string, consentSubmit: IMemberConsentSubmitDto }) {
        return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/event-registrations/${eventRegistrationId}/invoices/${invoiceId}/deposit-payment?paymentMethodId=${paymentMethodId}`, consentSubmit);
    }

    public getDynamicForm(params: IInstitutionMemberProgramEventRegistrationParams): Observable<IDynamicForm> {

        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getDynamicQuestionFormInputs(params)),
            tap(invoice => {
                if (invoice === undefined) {
                    this.store.dispatch(EventMemberActions.EventsMemberLoadDynamicFormAction(params));
                }
            }),
            filter(e => e != null)
        );
    }

    public loadDynamicFormEffect({
        institutionId,
        memberId,
        programId,
        eventRegistrationId
    }: IInstitutionMemberProgramEventRegistrationParams): Observable<IDynamicForm> {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/event-registrations/${eventRegistrationId}/registration-form`) as Observable<IDynamicForm>;
    }

    public saveDynamicFormResponses(params: IInstitutionMemberProgramEventRegistrationParams & { responses: any }) {
        this.store.dispatch(EventMemberActions.EventsMemberSaveDynamicFormResponsesAction(params));

        return this.dispatcher.pipe(
            ofType(EventMemberActions.EventsMemberSaveDynamicFormResponsesSuccessAction, EventMemberActions.EventsMemberSaveDynamicFormResponsesErrorAction),
            take(1),
            map(action => {
                if (action.type === EventMemberActions.EventsMemberSaveDynamicFormResponsesSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public saveDynamicFormResponsesEffect({
        institutionId,
        memberId,
        programId,
        eventRegistrationId,
        responses
    }: IInstitutionMemberProgramEventRegistrationParams & { responses: any }) {
        return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/event-registrations/${eventRegistrationId}/registration-form/responses`, responses);
    }

    public saveEventsMemberConsents(params: { institutionId: string, memberId: string, programId: string, eventRegistrationId: string, consent: IMemberConsentSubmitDto[] }) {
        this.store.dispatch(EventMemberActions.EventsMemberSaveConsentsAction(params));

        return this.dispatcher.pipe(
            ofType(EventMemberActions.EventsMemberSaveConsentsSuccessAction, EventMemberActions.EventsMemberSaveConsentsErrorAction),
            take(1),
            map(action => {
                if (action.type === EventMemberActions.EventsMemberSaveConsentsSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public saveEventsMemberConsentsEffect({
        institutionId,
        memberId,
        programId,
        eventRegistrationId,
        consent
    }: { institutionId: string, memberId: string, programId: string, eventRegistrationId: string, consent: IMemberConsentSubmitDto[] }) {
        return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/event-registrations/${eventRegistrationId}/consents`, consent);
    }

    public submitEventRegistration(payload: IInstitutionMemberProgramEventRegistrationParams & { invoice: IPayableInvoiceDto }) {
        this.store.dispatch(EventMemberActions.EventsMemberSubmitAction(payload));
        return this.dispatcher.pipe(
            ofType(EventMemberActions.EventsMemberSubmitSuccessAction, EventMemberActions.EventsMemberSubmitErrorAction),
            take(1),
            map(action => {
                if (action.type === EventMemberActions.EventsMemberSubmitSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public submitEventRegistrationEffect({
        institutionId,
        memberId,
        programId,
        eventRegistrationId,
        invoice
    }: IInstitutionMemberProgramEventRegistrationParams & { invoice: IPayableInvoiceDto }) {
        return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/event-registrations/${eventRegistrationId}/submit`, invoice);
    }

    public getCustomFiles(params: IInstitutionMemberProgramEventRegistrationParams): Observable<IRegistrationTypeFileControlDto[]> {
        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getEventsMemberCustomFiles(params)),
            tap(files => {
                if (files === undefined) {
                    this.store.dispatch(EventMemberActions.EventsMemberLoadCustomFilesAction(params));
                }
            }),
            filter(e => e != null)
        );
    }

    public loadCustomFilesEffect({
        institutionId,
        memberId,
        programId,
        eventRegistrationId
    }: IInstitutionMemberProgramEventRegistrationParams) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/event-registrations/${eventRegistrationId}/file-controls`) as Observable<IRegistrationTypeFileControlDto[]>;
    }

    public uploadFile(params: IInstitutionMemberProgramEventRegistrationParams & { fileControlId: string, formData: FormData }) {
        this.store.dispatch(EventMemberActions.EventsMemberUploadFileAction(params));

        return this.dispatcher.pipe(
            ofType(EventMemberActions.EventsMemberUploadFileSuccessAction, EventMemberActions.EventsMemberUploadFileErrorAction),
            take(1),
            map(action => {
                if (action.type === EventMemberActions.EventsMemberUploadFileSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public uploadMemberFileEffect({
        institutionId,
        memberId,
        programId,
        eventRegistrationId,
        fileControlId,
        formData
    }: IInstitutionMemberProgramEventRegistrationParams & { fileControlId: string, formData: FormData }) {
        return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/event-registrations/${eventRegistrationId}/file-controls/${fileControlId}`, formData);
    }

    public deleteFile(params: IInstitutionMemberProgramEventRegistrationParams & { fileControlId: string }) {
        this.store.dispatch(EventMemberActions.EventsMemberDeleteFileAction(params));

        return this.dispatcher.pipe(
            ofType(EventMemberActions.EventsMemberDeleteFileSuccessAction, EventMemberActions.EventsMemberDeleteFileErrorAction),
            take(1),
            map(action => {
                if (action.type === EventMemberActions.EventsMemberDeleteFileSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public deleteMemberFileEffect({
        institutionId,
        memberId,
        programId,
        eventRegistrationId,
        fileControlId
    }: IInstitutionMemberProgramEventRegistrationParams & { fileControlId: string }) {
        return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/event-registrations/${eventRegistrationId}/file-controls/${fileControlId}/file`);
    }

    public applyCouponCode(params: IInstitutionMemberProgramEventRegistrationParams & { couponCode: string }) {
        this.store.dispatch(EventMemberActions.EventsMemberApplyCouponCodeAction(params));

        return this.dispatcher.pipe(
            ofType(EventMemberActions.EventsMemberApplyCouponCodeSuccessAction, EventMemberActions.EventsMemberApplyCouponCodeErrorAction),
            take(1),
            map(action => {
                if (action.type === EventMemberActions.EventsMemberApplyCouponCodeSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public applyCouponCodeEffect({
        institutionId,
        memberId,
        programId,
        eventRegistrationId,
        couponCode
    }: IInstitutionMemberProgramEventRegistrationParams & { couponCode: string }) {
        return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/event-registrations/${eventRegistrationId}/invoices/apply-coupon?couponCode=${couponCode}`, {});
    }

    public applyCredit(params: IInstitutionMemberProgramEventRegistrationParams & { consent: IMemberConsentSubmitDto }) {
        this.store.dispatch(EventMemberActions.EventsMemberApplyCreditAction(params));

        return this.dispatcher.pipe(
            ofType(EventMemberActions.EventsMemberApplyCreditSuccessAction, EventMemberActions.EventsMemberApplyCreditErrorAction),
            take(1),
            map(action => {
                if (action.type === EventMemberActions.EventsMemberApplyCreditSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public applyCreditEffect({
        institutionId,
        memberId,
        programId,
        eventRegistrationId,
        consent
    }: IInstitutionMemberProgramEventRegistrationParams & { consent: IMemberConsentSubmitDto }) {
        return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/event-registrations/${eventRegistrationId}/invoices/apply-credit`, consent);
    }

    public addSession(params: IInstitutionMemberProgramEventRegistrationParams & { eventSessionId: string }) {
        this.store.dispatch(EventMemberActions.EventsMemberAddSessionAction(params));
    }

    public removeSession(params: IInstitutionMemberProgramEventRegistrationParams & { eventSessionId: string }) {
        this.store.dispatch(EventMemberActions.EventsMemberRemoveSessionAction(params));
    }

    public saveSessions(params: IInstitutionMemberProgramEventRegistrationParams & { eventSessionIds: string[] }) {
        this.store.dispatch(EventMemberActions.EventsMemberSaveSessionsAction(params));

        return this.dispatcher.pipe(
            ofType(EventMemberActions.EventsMemberSaveSessionsSuccessAction, EventMemberActions.EventsMemberSaveSessionsErrorAction),
            take(1),
            map(action => {
                if (action.type === EventMemberActions.EventsMemberSaveSessionsSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public saveSessionsEffect({
        institutionId,
        memberId,
        programId,
        eventRegistrationId,
        eventSessionIds
    }: IInstitutionMemberProgramEventRegistrationParams & { eventSessionIds: string[] }) {
        return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/event-registrations/${eventRegistrationId}/sessions`, eventSessionIds);
    }

    // public deleteSession(params: IInstitutionMemberProgramEventRegistrationParams & { eventRegistrationSessionId: string }) {
    //   this.store.dispatch(EventMemberActions.EventsMemberDeleteSessionAction(params));

    //   return this.dispatcher.pipe(
    //     ofType(EventMemberActions.EventsMemberDeleteSessionSuccessAction, EventMemberActions.EventsMemberDeleteSessionErrorAction),
    //     take(1),
    //     map(action => {
    //       if (action.type === EventMemberActions.EventsMemberDeleteSessionSuccessAction.type) {
    //         return action;
    //       } else {
    //         throw action.error;
    //       }
    //     })
    //   );
    // }
    // public deleteSessionEffect({ institutionId, memberId, programId, eventRegistrationId, eventRegistrationSessionId }: IInstitutionMemberProgramEventRegistrationParams & { eventRegistrationSessionId: string }) {
    //   return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/event-registrations/${eventRegistrationId}/sessions/${eventRegistrationSessionId}`);
    // }

    public deleteEventRegistration(params: IInstitutionMemberProgramEventRegistrationParams) {
        this.store.dispatch(EventMemberActions.EventsMemberDeleteRegistrationAction(params));

        return this.dispatcher.pipe(
            ofType(EventMemberActions.EventsMemberDeleteRegistrationSuccessAction, EventMemberActions.EventsMemberDeleteRegistrationErrorAction),
            take(1),
            map(action => {
                if (action.type === EventMemberActions.EventsMemberDeleteRegistrationSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public deleteEventRegistrationEffect({
        institutionId,
        memberId,
        programId,
        eventRegistrationId
    }: IInstitutionMemberProgramEventRegistrationParams) {
        return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/event-registrations/${eventRegistrationId}`);
    }

    public getEventRegistrationAnimals(params: IInstitutionMemberProgramEventRegistrationParams) {
        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getEventRegistrationAnimals(params)),
            tap(animals => {
                if (animals === undefined) {
                    this.store.dispatch(EventMemberActions.EventsMemberGetEventRegistraitonAninimalsAction(params));
                }
            }),
            filter(e => e != null)
        );
    }

    public getEventRegistrationAnimalsEffect({
        institutionId,
        memberId,
        programId,
        eventRegistrationId
    }: IInstitutionMemberProgramEventRegistrationParams) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/event-registrations/${eventRegistrationId}/animals`) as Observable<IEnrollmentAnimalDto[]>;
    }

    public saveEventRegistrationAnimal(params: IInstitutionMemberProgramEventRegistrationParams & { enrollmentAnimalId: string }) {
        this.store.dispatch(EventMemberActions.EventsMemberSaveEventRegistraitonAninimalAction(params));

        return this.dispatcher.pipe(
            ofType(EventMemberActions.EventsMemberSaveEventRegistraitonAninimalSuccessAction, EventMemberActions.EventsMemberSaveEventRegistraitonAninimalErrorAction),
            take(1),
            map(action => {
                if (action.type === EventMemberActions.EventsMemberSaveEventRegistraitonAninimalSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public saveEventRegistrationAnimalEffect({
        institutionId,
        memberId,
        programId,
        eventRegistrationId,
        enrollmentAnimalId
    }: IInstitutionMemberProgramEventRegistrationParams & { enrollmentAnimalId: string }) {
        return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/event-registrations/${eventRegistrationId}/animals/${enrollmentAnimalId}`, {});
    }

    // public deleteEventRegistrationAnimalsEffect({ institutionId, memberId, programId, eventRegistrationId }: IInstitutionMemberProgramEventRegistrationParams) {
    //   return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/event-registrations/${eventRegistrationId}/animals`)
    // }
}
