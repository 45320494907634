import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { filter, map, Observable, of, take, tap } from 'rxjs';

import { downloadFileWithBody } from '../../../shared/utils';
import { ReportConsumerActions } from '../../containers/report-consumer';
import {
  getReportConsumer,
  getReportConsumers,
  ReportConsumerStore
} from '../../containers/report-consumer/report-consumer-feature.reducers';
import {
  IInstitutionReportConsumerParams,
  IReportConsumerCreateDto,
  IReportConsumerInstitutionProfile,
  IReportConsumerUpdateDto,
  IStandardReportFiltersDto,
  ISuperuserInstitutionParams
} from '../../models';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportConsumerService {

  constructor(
    private httpClient: HttpClient,
    private dispatcher: ActionsSubject,
    private store: Store<ReportConsumerStore>
  ) {

  }

  public createReportConsumer(params: ISuperuserInstitutionParams & { reportConsumerCreateDto: IReportConsumerCreateDto }) {
    this.store.dispatch(ReportConsumerActions.CreateReportConsumerAction(params));

    return this.dispatcher.pipe(
      ofType(ReportConsumerActions.CreateReportConsumerSuccessAction, ReportConsumerActions.CreateReportConsumerErrorAction),
      take(1),
      map(action => {
        if (action.type === ReportConsumerActions.CreateReportConsumerSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public createReportConsumerEffect({
    superuserId,
    institutionId,
    reportConsumerCreateDto
  }: ISuperuserInstitutionParams & { reportConsumerCreateDto: IReportConsumerCreateDto }) {
    return this.httpClient.post(`${environment.apiUri}/api/super-users/${superuserId}/institutions/${institutionId}/report-consumers`, reportConsumerCreateDto);
  }

  public getReportConsumer(params: IInstitutionReportConsumerParams) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getReportConsumer(params)),
      tap(reportConsumer => {
        if (reportConsumer === undefined) {
          this.store.dispatch(ReportConsumerActions.GetReportConsumerAction(params));
        }
      }),
      filter(reportConsumer => reportConsumer != null)
    );
  }

  public getReportConsumerEffect({ institutionId, reportConsumerId }: IInstitutionReportConsumerParams) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/report-consumers/${reportConsumerId}`) as Observable<IReportConsumerInstitutionProfile>;
  }

  public updateReportConsumer(params: IInstitutionReportConsumerParams & { reportConsumerUpdate: IReportConsumerUpdateDto }) {
    this.store.dispatch(ReportConsumerActions.UpdateReportConsumerAction(params));

    return this.dispatcher.pipe(
      ofType(ReportConsumerActions.UpdateReportConsumerSuccessAction, ReportConsumerActions.UpdateReportConsumerErrorAction),
      take(1),
      map(action => {
        if (action.type === ReportConsumerActions.UpdateReportConsumerSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateReportConsumerEffect({
    institutionId,
    reportConsumerId,
    reportConsumerUpdate
  }: IInstitutionReportConsumerParams & { reportConsumerUpdate: IReportConsumerUpdateDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/report-consumers/${reportConsumerId}`, reportConsumerUpdate);
  }

  public deleteReportConsumer(params: IInstitutionReportConsumerParams) {
    this.store.dispatch(ReportConsumerActions.DeleteReportConsumerAction(params));

    return this.dispatcher.pipe(
      ofType(ReportConsumerActions.DeleteReportConsumerSuccessAction, ReportConsumerActions.DeleteReportConsumerErrorAction),
      take(1),
      map(action => {
        if (action.type === ReportConsumerActions.DeleteReportConsumerSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteReportConsumerEffect({ institutionId, reportConsumerId }: IInstitutionReportConsumerParams) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/report-consumers/${reportConsumerId}`);
  }

  public reinstateReportConsumer(params: IInstitutionReportConsumerParams) {
    this.store.dispatch(ReportConsumerActions.ReinstateReportConsumerAction(params));

    return this.dispatcher.pipe(
      ofType(ReportConsumerActions.ReinstateReportConsumerSuccessAction, ReportConsumerActions.ReinstateReportConsumerErrorAction),
      take(1),
      map(action => {
        if (action.type === ReportConsumerActions.ReinstateReportConsumerSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public reinstateReportConsumerEffect({ institutionId, reportConsumerId }: IInstitutionReportConsumerParams) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/report-consumers/${reportConsumerId}/reinstate`, {});
  }

  public getReportConsumers(params: ISuperuserInstitutionParams) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getReportConsumers(params)),
      tap(reportConsumers => {
        if (reportConsumers === undefined) {
          this.store.dispatch(ReportConsumerActions.GetReportConsumersAction(params));
        }
      })
    );
  }

  public getReportConsumersEffect({ superuserId, institutionId }: ISuperuserInstitutionParams) {
    return this.httpClient.get(`${environment.apiUri}/api/super-users/${superuserId}/institutions/${institutionId}/report-consumers`) as Observable<IReportConsumerInstitutionProfile[]>;
  }

  public getStandardReportsEffect({ institutionId, reportConsumerId }: IInstitutionReportConsumerParams) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/report-consumers/${reportConsumerId}/standard-reports`);
  }

  public downloadExcelEffect({
    institutionId,
    reportConsumerId,
    standardReportId,
    standardReportFilters
  }: IInstitutionReportConsumerParams & { standardReportId: string, standardReportFilters: IStandardReportFiltersDto }) {
    downloadFileWithBody(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/report-consumers/${reportConsumerId}/standard-reports/${standardReportId}/excel`, standardReportFilters);
  }

  public downloadPdfEffect({
    institutionId,
    reportConsumerId,
    standardReportId,
    standardReportFilters
  }: IInstitutionReportConsumerParams & { standardReportId: string, standardReportFilters: IStandardReportFiltersDto }) {
    downloadFileWithBody(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/report-consumers/${reportConsumerId}/standard-reports/${standardReportId}/pdf`, standardReportFilters);
  }

}
