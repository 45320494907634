import { Action, createReducer, on } from '@ngrx/store';
import { ISchoolDistrictDto, ISchoolDto } from 'app/core/models';
import { mergeImmutable } from 'app/shared/utils';

import { SchoolActions } from '.';



export interface SchoolState {
    schoolDistricts: { [institutionId: string]: { [countyId: string]: ISchoolDistrictDto[] } };
    schoolsByDistrict: { [institutionId: string]: { [schoolDistrictId: string]: ISchoolDto[] } };
}

const initialState: SchoolState = {
    schoolDistricts: undefined,
    schoolsByDistrict: undefined
};

const reducer = createReducer(
    initialState,
    on(SchoolActions.SchoolDistrictsLoadByCountySuccessAction, (state, { institutionId, countyId, districts }) => {
        let newSchoolsByDistrict = state.schoolsByDistrict;
        districts.forEach(district => {
            newSchoolsByDistrict = mergeImmutable(
                { [institutionId]: { [district.id]: district.schools } },
                newSchoolsByDistrict
            );
        });
        return {
            ...state,
            schoolDistricts: mergeImmutable(
                { [institutionId]: { [countyId]: districts } },
                state.schoolDistricts
            ),
            schoolsByDistrict: newSchoolsByDistrict
        };
    }),
    on(SchoolActions.SchoolsLoadByDistrictSuccessAction, (state, { institutionId, schoolDistrictId, schools }) => {
        return {
            ...state,
            schoolsByDistrict: mergeImmutable(
                { [institutionId]: { [schoolDistrictId]: schools } },
                state.schoolsByDistrict
            )
        };
    })
);

export function schoolReducer(state: SchoolState | undefined, actions: Action) {
    return reducer(state, actions);
}

export const schoolDistricts = (state: SchoolState) => state.schoolDistricts;
export const schoolsByDistrict = (state: SchoolState) => state.schoolsByDistrict;
