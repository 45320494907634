<div class="modal-header">
    <h5 class="modal-title">Volunteer Type Consents</h5>
    <button (click)="close()" type="button" class="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <ng4h-consent [consents]="data.volunteerType.consents" (allSigned)="consentsSigned($event)"
        [showAsManagerConsent]="isManager$ | async" [hideGuardianSignature]="true"></ng4h-consent>
</div>
<div class="modal-footer">
    <button (click)="close()" class="btn btn-secondary my-2 my-sm-0" role="button"
        aria-pressed="true">Decline</button>
    <button [disabled]="signedConsents == null" (click)="accept()" class="btn btn-primary my-2 my-sm-0"
        role="button" aria-pressed="true">Accept</button>
</div>
