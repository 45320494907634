import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { eDeliveryModeType, IDeliveryModeDto } from 'app/core/models';
import { CommonToastrService, ModalService, RouterService, SystemManagerDeliveryModeService } from 'app/core/services';
import { take } from 'rxjs';

@Component({
  selector: 'ng4h-modal-edit-delivery-mode',
  templateUrl: './modal-edit-delivery-mode.component.html',
  styleUrls: ['./modal-edit-delivery-mode.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalEditDeliveryModeComponent implements OnInit {


  public form: FormGroup;

  public eDeliveryModeType = eDeliveryModeType;

  constructor(private dialogRef: MatDialogRef<any>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) private deliveryMode: IDeliveryModeDto,
    private deliveryModeService: SystemManagerDeliveryModeService,
    private routerService: RouterService,
    private toastrService: CommonToastrService,
    private modalService: ModalService
  ) { }

  ngOnInit() {

    this.form = this.fb.group({
      type: [this.deliveryMode.type, Validators.required],
      description: [this.deliveryMode.description],
      typeDescription: [this.deliveryMode.typeDescription],
      availableForGroupEnrollment: [this.deliveryMode.availableForGroupEnrollment]
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  public save(): void {
    this.deliveryModeService.updateDeliveryMode({
      institutionId: this.routerService.institutionId,
      programId: this.routerService.programId,
      systemManagerId: this.routerService.systemManagerId,
      deliveryModeId: this.deliveryMode.deliveryModeId,
      deliveryMode: this.form.value
    }).pipe(
      take(1),
      this.modalService.showProgressSpinnerModalUntilComplete()
    ).subscribe({
      next: () => {
        this.toastrService.success('Delivery Mode Updated');
        this.close();
      }, error: error => {
        this.toastrService.error('Could not update delivery mode', error);
      }
    });
  }
}
