import { eEventSessionType } from '..';

export interface IEventSessionUpdateDto {
    maximumRegistrations?: number;
    label?: string;
    description?: string;
    fee?: number;
    type: eEventSessionType;
    registrationTypes?: any[];
}
