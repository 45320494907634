import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { eFormType, IDynamicControl, IDynamicItem } from 'app/core/models';

import { eDynamicValidatorName } from '../../../../../models/dynamic-forms/eDynamicValidatorName';
import { DynamicValidatorBaseComponent } from '../dynamic-validator-base/dynamic-validator-base.component';

@Component({
  selector: 'ng4h-dynamic-number-input-options',
  templateUrl: './dynamic-number-input-options.component.html',
  styleUrls: ['./dynamic-number-input-options.component.scss']
})
export class DynamicNumberInputOptionsComponent extends DynamicValidatorBaseComponent implements OnInit, OnChanges {

  @Input() control: IDynamicControl;
  @Output() controlModified: EventEmitter<Partial<IDynamicControl>>;
  @Output() itemModified: EventEmitter<Partial<IDynamicItem>>;

  @Input() formType: eFormType;

  public eFormType = eFormType;

  public max;
  public min;

  public newControl: IDynamicControl;
  constructor() {
    super();
    this.controlModified = new EventEmitter();
    this.itemModified = new EventEmitter();


  }

  ngOnInit() {
    this.newControl = { ...this.control };
  }

  ngOnChanges(changes) {
    let maxValidator = null;
    let minValidator = null;
    if (Array.isArray(this.control.validators)) {
      maxValidator = this.control.validators.find(val => {
        return val.name === eDynamicValidatorName.Max;
      });
      minValidator = this.control.validators.find(val => {
        return val.name === eDynamicValidatorName.Min;
      });
    }
    if (maxValidator != null) {
      this.max = maxValidator.arg;
    } else {
      this.max = null;
    }

    if (minValidator != null) {
      this.min = minValidator.arg;
    } else {
      this.min = null;
    }
  }

  public controlChanged(event) {
    this.controlModified.emit(this.newControl);
  }

  private validatorMax(number) {
    if (number == null) {
      this.validatorRemove.emit({ name: eDynamicValidatorName.Max });
    } else {
      this.validatorAddOrModify.emit({ name: eDynamicValidatorName.Max, arg: number });
    }
  }

  public placeholderChange(value: string, itemId: string) {
    this.itemModified.emit({ itemId: itemId, attributes: { placeholder: value } });
  }

  public rangeMinChange(number: number) {
    if (number == null) {
      this.validatorRemove.emit({ name: eDynamicValidatorName.Min });
    } else {
      this.validatorAddOrModify.emit({ name: eDynamicValidatorName.Min, arg: number });
    }
    this.controlModified.emit({ attributes: { min: number } });
  }

  public rangeMaxChange(number: number) {
    this.validatorMax(number);
    this.controlModified.emit({ attributes: { max: number } });
  }

  public numberAvailableChanged(number: number, itemId: string) {
    this.itemModified.emit({ itemId: itemId, attributes: { quantityAvailable: number } });
    this.validatorMax(number);
  }

  reportLabelChange(reportValue: string, itemId: string) {
    this.itemModified.emit({ itemId, reportValue });
  }

}
