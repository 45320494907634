import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { AppState, getAuthorizeToken } from 'app/app.reducers';
import {
  IInstitutionFamilyParams,
  IInstitutionFamilyProgramParams,
  IInstitutionProgramParams,
  IPaymentMethodDto,
  ITokenizedCardDto,
  ITouchNetAddCardResponseDto,
  ITouchNetRedirectRequestDto
} from 'app/core/models';
import { CreditCardActions } from 'app/shared/credit-card';
import { filter, map, Observable, of, switchMap, take, tap } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CreditCardService {

  constructor(private store: Store<AppState>, private httpClient: HttpClient, private dispatcher: ActionsSubject) {

  }

  public saveFamilyCard(params: IInstitutionProgramParams & { familyId: string, cardSubmission: ITokenizedCardDto }) {
    this.store.dispatch(CreditCardActions.CreditCardSaveFamilyCardAction(params));

    return this.dispatcher.pipe(
      ofType(CreditCardActions.CreditCardSaveFamilyCardSuccessAction, CreditCardActions.CreditCardSaveFamilyCardErrorAction),
      take(1),
      map(action => {
        if (action.type === CreditCardActions.CreditCardSaveFamilyCardSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public saveFamilyCardEffect({
    institutionId,
    familyId,
    programId,
    cardSubmission
  }: IInstitutionProgramParams & { familyId: string, cardSubmission: ITokenizedCardDto }): Observable<IPaymentMethodDto> {
    return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/families/${familyId}/payment-methods?programId=${programId}`, cardSubmission,
      { observe: 'response' }).pipe(
      switchMap((res: HttpResponse<any>) => this.httpClient.get(res.headers.get('location')))
    ) as Observable<IPaymentMethodDto>;
  }

  public removeFamilyPaymentMethod(params: IInstitutionFamilyParams & { paymentMethodId: string }) {
    this.store.dispatch(CreditCardActions.CreditCardRemoveFamilyPaymentMethodAction(params));

    return this.dispatcher.pipe(
      ofType(CreditCardActions.CreditCardRemoveFamilyPaymentMethodSuccessAction, CreditCardActions.CreditCardRemoveFamilyPaymentMethodErrorAction),
      take(1),
      map(action => {
        if (action.type === CreditCardActions.CreditCardRemoveFamilyPaymentMethodSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public removeFamilyPaymentMethodEffect({
    institutionId,
    familyId,
    paymentMethodId
  }: IInstitutionFamilyParams & { paymentMethodId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/families/${familyId}/payment-methods/${paymentMethodId}`);
  }

  public getAuthorizeNetToken(params: IInstitutionFamilyProgramParams) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getAuthorizeToken(params)),
      tap(token => {
        if (token === undefined) {
          this.store.dispatch(CreditCardActions.GetAuthorizeNetToken(params));
        }
      }),
      filter(token => token != null)
    );
  }

  public getAuthorizeNetTokenEffect({ institutionId, familyId, programId }: IInstitutionFamilyProgramParams) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/families/${familyId}/authorize-net-token?programId=${programId}`);
  }

  public getTouchNetRedirectEffect({
    institutionId,
    familyId,
    programId,
    redirectRequest
  }: IInstitutionFamilyProgramParams & { redirectRequest: ITouchNetRedirectRequestDto }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/families/${familyId}/touch-net-redirect?programId=${programId}`, redirectRequest) as Observable<ITouchNetAddCardResponseDto>;
  }

}
