import { Pipe, PipeTransform } from '@angular/core';
import { IHierarchyCounty } from 'app/core/models';
import { CountyService, RouterService } from 'app/core/services';
import { filter, map, Observable } from 'rxjs';

@Pipe({
    name: 'county$',
    pure: true
})
export class CountyPipe implements PipeTransform {
    constructor(private countyService: CountyService, private routerService: RouterService) {
    }

    public transform(countyId: string, institutionId: string = this.routerService.institutionId, programId: string = this.routerService.programId): Observable<IHierarchyCounty> {
        return this.countyService.getCounties({ institutionId, programId }).pipe(
            filter(c => Array.isArray(c)),
            map(counties => counties.find(county => county.countyId === countyId))
        );
    }
}
