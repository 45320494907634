import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ModalChangeProjectClubComponent,
} from 'app/core/containers/member/enrollment/modal-change-project-club/modal-change-project-club.component';
import {
  eEnrollmentRoleType,
  eUnitType,
  eVolunteerType,
  IEnrollmentDto,
  IEnrollmentUnitDto,
  IEnrollmentUnitSubmissionDto,
  IInstitutionVolunteerDto,
} from 'app/core/models';
import { CommonToastrService, EnrollmentService, ModalService, ProfileService, RouterService } from 'app/core/services';
import { combineLatest, filter, map, Observable, switchMap, take } from 'rxjs';

@Component({
  selector: 'ng4h-modal-edit-enrollment-club',
  templateUrl: './modal-edit-enrollment-club.component.html',
  styleUrls: ['./modal-edit-enrollment-club.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalEditEnrollmentClubComponent implements OnInit {

  public eUnitType = eUnitType;
  public eEnrollmentRoleType = eEnrollmentRoleType;
  public enrollment$: Observable<IEnrollmentDto>;
  private volunteerCategories$: Observable<IInstitutionVolunteerDto[]>;
  public volunteerTypes$: Observable<IInstitutionVolunteerDto[]>;
  public volunteerTypeId: string;
  public isManager$: Observable<boolean>;

  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: { unit: IEnrollmentUnitDto, units: IEnrollmentUnitDto[] },
    private routerService: RouterService,
    private enrollmentService: EnrollmentService,
    private modalService: ModalService,
    private toastrService: CommonToastrService,
    private profileService: ProfileService,
  ) { }

  ngOnInit(): void {
    this.isManager$ = this.profileService.activeUserInstitutionProfileIsManager$;

    this.enrollment$ = this.enrollmentService.getEnrollment({
      institutionId: this.routerService.institutionId,
      memberId: this.routerService.memberId ? this.routerService.memberId : this.routerService.selectedMemberId,
      programId: this.routerService.programId,
      enrollmentId: this.routerService.enrollmentId
    });

    this.volunteerCategories$ = this.enrollmentService.getVolunteerTypes({
      institutionId: this.routerService.institutionId,
      memberId: this.routerService.memberId ? this.routerService.memberId : this.routerService.selectedMemberId,
      programId: this.routerService.programId,
      enrollmentId: this.routerService.enrollmentId
    });

    this.volunteerTypes$ = combineLatest([this.enrollment$, this.volunteerCategories$]).pipe(
      map(([enrollment, volunteerCategories]) => {
        if (enrollment.enrollmentRole === eEnrollmentRoleType.Volunteer) {
          return enrollment.volunteerTypes.filter(v => {
            if (enrollment.enrollmentRole === eEnrollmentRoleType.Volunteer) {
              return v.volunteerTypeDescriptor.volunteerType === eVolunteerType.AdultClubVolunteer;
            }
          });
        } else if (enrollment.enrollmentRole === eEnrollmentRoleType.ClubMember) {
          return volunteerCategories.filter(volunteerType => {
            return volunteerType.volunteerTypeDescriptor.volunteerType === eVolunteerType.YouthClubVolunteer;
          });
        }
      })
    );

    this.enrollment$.pipe(
      filter(enrollment => enrollment.enrollmentRole === eEnrollmentRoleType.Volunteer),
      switchMap(() => this.volunteerTypes$),
      filter(volunteerTypes => Array.isArray(volunteerTypes) && volunteerTypes.length > 0),
      take(1))
      .subscribe(volunteerTypes => {
        if (Array.isArray(volunteerTypes) && volunteerTypes.length === 1) {
          this.volunteerTypeChanged(volunteerTypes[0].volunteerTypeId);
        }
      });
    this.volunteerTypeChanged(this.data.unit.volunteerType?.volunteerTypeId);
  }

  public close(result?: any): void {
    this.dialogRef.close(result);
  }

  public volunteerTypeChanged(volunteerTypeId: string) {
    this.volunteerTypeId = volunteerTypeId;
  }

  public save() {

    const unitSubmission: IEnrollmentUnitSubmissionDto[] = this.data.units.map(club => {
      let newVolunteerTypeId = null;
      if (club.unitId === this.data.unit.unitId) {
        newVolunteerTypeId = this.volunteerTypeId;
      } else {
        newVolunteerTypeId = club?.volunteerType?.volunteerTypeId;
      }
      return {
        unitId: club.unitId,
        primaryClub: club.primary,
        volunteerTypeId: newVolunteerTypeId
      };
    });

    this.enrollmentService.saveClubs({
      institutionId: this.routerService.institutionId,
      memberId: this.routerService.memberId ? this.routerService.memberId : this.routerService.selectedMemberId,
      programId: this.routerService.programId,
      enrollmentId: this.routerService.enrollmentId,
      clubs: unitSubmission
    }).pipe(
      take(1),
      switchMap(() => this.enrollmentService.refreshEnrollment()),
      take(1),
      this.modalService.showProgressSpinnerModalUntilComplete(),
    ).subscribe({
      next: () => {
        this.close();
        this.toastrService.success('Saved');
      }, error: (error) => {
        this.toastrService.error('Could not save.', error);
      }
    });
  }

  public changeClubs() {
    this.modalService.openModal(ModalChangeProjectClubComponent, { data: this.data.unit });
    this.close();
  }
}
