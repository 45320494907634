import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalConfirmComponent } from 'app/core/components/modal-confirm/modal-confirm.component';
import { ModalCreditConsentComponent } from 'app/core/components/modal-credit-consent/modal-credit-consent.component';
import {
    ePaymentStatus,
    IEventRegistrationDto,
    IInvoiceDto,
    IInvoiceLineItemDto,
    IMemberConsentSubmitDto,
    IMemberDto,
    IPaymentDto
} from 'app/core/models';
import {
    CommonToastrService,
    EventsMemberService,
    ManagerFinancesService,
    EventsManagerService,
    MemberService,
    ModalService,
    RouterService
} from 'app/core/services';
import { filter, map, Observable, switchMap, take } from 'rxjs';

@Component({
    selector: 'ng4h-modal-manager-event-invoice',
    templateUrl: './modal-manager-event-invoice.component.html',
    styleUrls: ['./modal-manager-event-invoice.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalManagerEventInvoiceComponent implements OnInit {

    // public enrollment$: Observable<IEnrollmentDto>;
    // public primaryClub$: Observable<IEnrollmentClubDto>;

    public eventRegistration$: Observable<IEventRegistrationDto>;

    public invoice$: Observable<IInvoiceDto>;
    public lineItems$: Observable<IInvoiceLineItemDto[]>;
    public pendingTransactions$: Observable<boolean>;
    public lineItemColumns: Map<string, string> = new Map();
    public lineItemColumnTransforms = new Map<string, Function>();

    public payments$: Observable<IPaymentDto[]>;
    public paymentsColumns: Map<string, string> = new Map();
    public paymentsColumnTransforms = new Map<string, Function>();

    public member$: Observable<IMemberDto>;

    public couponCodeForm: FormGroup;

    constructor(
        private dialogRef: MatDialogRef<any>,
        private fb: FormBuilder,
        private eventsMemberService: EventsMemberService,
        private routerService: RouterService,
        @Inject(MAT_DIALOG_DATA) private invoiceId: string,
        private memberService: MemberService,
        private eventService: EventsManagerService,
        private toastrService: CommonToastrService,
        private managerFinanceService: ManagerFinancesService,
        private modalService: ModalService
    ) {

        this.eventRegistration$ = this.eventsMemberService.getEventRegistration({
            institutionId: this.routerService.institutionId,
            memberId: this.routerService.selectedMemberId,
            programId: this.routerService.programId,
            eventRegistrationId: this.routerService.eventRegistrationId
        });

        this.invoice$ = this.eventRegistration$.pipe(map(eventRegistration => eventRegistration.invoices.find(invoice => invoice.invoiceId === this.invoiceId)));
        this.lineItems$ = this.invoice$.pipe(map(invoice => invoice.lineItems));
        this.payments$ = this.invoice$.pipe(map(invoice => invoice.payments));
        this.pendingTransactions$ = this.invoice$.pipe(map(invoice => invoice.payments.some(p => p.status === ePaymentStatus.Pending)));
        this.member$ = this.memberService.getMember({
            institutionId: this.routerService.institutionId,
            memberId: this.routerService.selectedMemberId
        });

        this.couponCodeForm = this.fb.group({
            couponCode: [null, [Validators.required, Validators.pattern(`^[a-zA-Z0-9]{6}$`)]]
        });
    }

    ngOnInit() {
        this.lineItemColumns.set('description', 'DESCRIPTION');
        this.lineItemColumns.set('rate', 'RATE');
        this.lineItemColumns.set('quantity', 'QUANTITY');
        this.lineItemColumns.set('amount', 'AMOUNT');

        this.lineItemColumnTransforms.set('amount', this.currency);

        this.paymentsColumns.set('paymentMethod.type', 'TYPE');
        this.paymentsColumns.set('status', 'Status');
        this.paymentsColumns.set('amount', 'AMOUNT');

        this.paymentsColumnTransforms.set('amount', this.currency);

    }

    private currency(amount: string) {
        if (amount == null) {
            amount = '0';
        }
        // return new CurrencyPipe('en-US').transform(amount);
        return amount;
    }

    public close(result?: any): void {
        this.dialogRef.close(result);
    }

    public save(): void {
        this.close();
    }

    public rowClicked(lineItem: IInvoiceLineItemDto) {

    }

    public applyCoupon() {
        const couponCode = this.couponCodeForm.value.couponCode;

        this.eventsMemberService.applyCouponCode({
            institutionId: this.routerService.institutionId,
            memberId: this.routerService.selectedMemberId,
            programId: this.routerService.programId,
            eventRegistrationId: this.routerService.eventRegistrationId,
            couponCode
        }).pipe(
            take(1)
        ).subscribe({
            next: res => {
                this.toastrService.success('Coupon Applied');
                this.couponCodeForm.controls.couponCode.setValue(null);
                this.couponCodeForm.controls.couponCode.updateValueAndValidity();
                this.eventsMemberService.refreshEventRegistration();
            }, error: (error) => {
                this.toastrService.error('Could not apply coupon', error);
            }
        });
    }

    public applyCredit(invoice: IInvoiceDto, member: IMemberDto) {
        this.modalService.openModal(ModalCreditConsentComponent, { data: { invoice: invoice, member } }).pipe(
            take(1),
            filter(res => res !== false)
        ).subscribe((consent: IMemberConsentSubmitDto) => {
            return this.eventsMemberService.applyCredit({
                institutionId: this.routerService.institutionId,
                memberId: this.routerService.selectedMemberId,
                programId: this.routerService.programId,
                eventRegistrationId: this.routerService.eventRegistrationId,
                consent
            }).pipe(
                take(1),
                switchMap(() => this.eventsMemberService.refreshEventRegistration()),
                take(1),
                this.modalService.showProgressSpinnerModalUntilComplete()
            ).subscribe({
                next: res => {
                    this.toastrService.success('Credit Applied');
                }, error: (error) => {
                    this.toastrService.error('Could not apply credit', error);
                }
            });
        });
    }

    public processPayment() {
        this.eventService.processPaymentsEffect({
            institutionId: this.routerService.institutionId,
            memberId: this.routerService.selectedMemberId,
            programId: this.routerService.programId,
            eventRegistrationId: this.routerService.eventRegistrationId,
            managerId: this.routerService.managerId
        }).pipe(take(1)).subscribe(res => {

        });
    }

    public waiveFees() {
        this.modalService.openModal(ModalConfirmComponent, {
            data: {
                header: 'Waive Fees',
                body: `Are you sure you would like to waive all fees for this invoice?.`
            }
        }).pipe(
            filter(confirm => confirm),
            switchMap(() => this.lineItems$),
            take(1),
            switchMap(lineItems => {
                return this.managerFinanceService.applyEventWaiver({
                    institutionId: this.routerService.institutionId,
                    memberId: this.routerService.selectedMemberId,
                    programId: this.routerService.programId,
                    eventRegistrationId: this.routerService.eventRegistrationId,
                    invoiceId: this.invoiceId,
                    invoiceWaiver: {
                        description: '',
                        invoiceItemIds: lineItems.map(lineItem => lineItem.invoiceItemId)
                    }
                });
            })
        ).subscribe({
            next: lineItems => {
                this.toastrService.success('Fees Waived');
                this.eventsMemberService.refreshEventRegistration();
            }, error: (error) => {
                this.toastrService.error('Error waiving fees', error);
            }
        });
    }

}
