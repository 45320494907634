import { Action, createReducer, on } from '@ngrx/store';
import { ManagerGroupsActions } from 'app/core/containers/admin/manager/store/groups';
import { IGroupDto } from 'app/core/models';
import { mergeImmutable } from 'app/shared/utils';

import { MemberGroupsActions } from '.';



export interface MemberGroupsState {
  groups: { [institutionId: string]: { [programId: string]: IGroupDto[] } };
}

const initialState: MemberGroupsState = {
  groups: undefined
};

const reducer = createReducer(
  initialState,
  on(MemberGroupsActions.LoadGroupsForEnrollmentSuccessAction, (state, { institutionId, memberId, programId, enrollmentId, groups }) => {
    const newGroupsByProgram = mergeImmutable(
      { [institutionId]: { [memberId]: { [programId]: { [enrollmentId]: groups } } } },
      state.groups,
    );
    return {
      ...state,
      groups: newGroupsByProgram
    };
  }),
  on(
    ManagerGroupsActions.CreateGroupSuccessAction,
    ManagerGroupsActions.DeleteGroupSuccessAction,
    ManagerGroupsActions.UpdateGroupSuccessAction,
    MemberGroupsActions.SaveGroupsForEnrollmentSuccessAction, (state, { }) => {
      return initialState;
    })
);

export function memberGroupsReducer(state: MemberGroupsState | undefined, actions: Action) {
  return reducer(state, actions);
}

export const memberGroups = (state: MemberGroupsState) => state.groups;
