import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AutoUnsubscribe, IEnrollmentAnimalDto } from 'app/core/models';
import { CommonToastrService, MemberHealthFormService, ModalService, RouterService } from 'app/core/services';
import { AnimalsService } from 'app/core/services/member/animals/animals.service';
import { filter, Observable, take, takeUntil } from 'rxjs';

@Component({
  selector: 'ng4h-modal-edit-animal',
  templateUrl: './modal-edit-animal.component.html',
  styleUrls: ['./modal-edit-animal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalEditAnimalComponent extends AutoUnsubscribe implements OnInit {

  public animal$: Observable<IEnrollmentAnimalDto>;
  public animalForm: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<any>,
    private animalsService: AnimalsService,
    private routerService: RouterService,
    private healthFormService: MemberHealthFormService,
    private cdr: ChangeDetectorRef,
    private modalService: ModalService,
    private toastrService: CommonToastrService
  ) {
    super();
  }

  ngOnInit(): void {


    this.animal$ = this.animalsService.getEnrollmentAnimal({
      institutionId: this.routerService.institutionId,
      memberId: this.routerService.memberId != null ? this.routerService.memberId : this.routerService.selectedMemberId,
      programId: this.routerService.programId,
      enrollmentId: this.routerService.enrollmentId,
      enrollmentAnimalId: this.routerService.enrollmentAnimalId
    });

    this.animal$.pipe(
      filter(animal => animal != null),
      takeUntil(this.autoUnsubscribe)
    ).subscribe(animal => {
      this.animalForm = this.healthFormService.toFormGroup(animal.fields);
      this.cdr.markForCheck();
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  public save() {
    this.animalsService.updateEnrollmentAnimalForm({
      institutionId: this.routerService.institutionId,
      memberId: this.routerService.memberId != null ? this.routerService.memberId : this.routerService.selectedMemberId,
      programId: this.routerService.programId,
      enrollmentId: this.routerService.enrollmentId,
      enrollmentAnimalId: this.routerService.enrollmentAnimalId,
      enrollmentAnimalSubmit: {
        responses: this.animalForm.value
      }
    }).pipe(
      take(1),
      this.modalService.showProgressSpinnerModalUntilComplete()
    ).subscribe({
      next: () => {
        this.toastrService.success('Animal updated');
        this.close();
      }, error: error => {
        this.toastrService.error('Could not update animal', error);
      }
    });
  }
}
