import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { eRequiredForCourseCompletionType } from 'app/core/models';

@Component({
  selector: 'ng4h-modal-add-lesson',
  templateUrl: './modal-add-lesson.component.html',
  styleUrls: ['./modal-add-lesson.component.scss']
})
export class ModalAddLessonComponent implements OnInit {

  public form: FormGroup;
  public wasValidated = false;
  public eRequiredForCourseCompletionType = eRequiredForCourseCompletionType;
  constructor(private dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      trainingLessonTitle: [null, Validators.required],
      required: [eRequiredForCourseCompletionType.No, Validators.required]
    });
  }

  public close() {
    this.dialogRef.close();
  }

  public add() {
    if (this.form.invalid) {
      this.wasValidated = true;
    }
    this.dialogRef.close(this.form.value);
  }
}
