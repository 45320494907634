import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
    AccessControlListRoleTypePipe,
    AgePipe,
    BooleanOperatorPipe,
    CamelToTitleCasePipe,
    ConsentsSignedPipe,
    CountyPipe,
    CustomReportColumnPipe,
    DayOfWeekPipe,
    DelimitedListPipe,
    DynamicDisplayToPipe,
    EnrollmentRolesToCommaDelimitedListPipe,
    ExactFilterPipe,
    FeePercentPipe,
    FilterOperatorPipe,
    HierarchyNodePipe,
    HierarchyUnitPipe,
    IdDecodePipe,
    InstitutionPipe,
    ManagerPipe,
    ManagerRegistrationTypePipe,
    MonthPipe,
    NationalProjectCategoryPipe,
    ParticipationStatusPipe,
    PrimaryFamilyPipe,
    ProgramPipe,
    ProgramTypePipe,
    ProjectPipe,
    ReversePipe,
    SearchFilterPipe,
    YesNoNullablePipe,
    YouthDivisionPipe
} from 'app/core/pipes';
import { DecimalPrecisionDirective } from 'app/shared/directives/decimal-precision.directive';
import { HierarchyTreeOverlayDirective } from 'app/shared/directives/hierarchy-tree-overlay.directive';
import { ManagerEventPermissionsDirective } from 'app/shared/directives/manager-event-permissions.directive';
import { ManagerHierarchyNotTypeDirective } from 'app/shared/directives/manager-hierarchy-not-type.directive';
import { ManagerHierarchyTypeDirective } from 'app/shared/directives/manager-hierarchy-type.directive';
import { ManagerPermissionsDirective } from 'app/shared/directives/manager-permissions.directive';
import { OnlyNumber } from 'app/shared/directives/only-number.directive';
import { PhoneMaskDirective } from 'app/shared/directives/phone-mask.directive';
import { PlaceholderDirective } from 'app/shared/directives/placeholder.directive';
import { ProfileTypesDirective } from 'app/shared/directives/profile-types.directive';
import { ProgramSettingsDirective } from 'app/shared/directives/program-settings.directive';
import { ProgramSettingsMultiDirective } from 'app/shared/directives/program-settings-multi.directive';
import { VarDirective } from 'app/shared/directives/var.directive';
import { StripeModule } from 'stripe-angular';

import {
    ConsentComponent,
    DynamicFormInputComponent,
    HeaderComponent,
    InputErrorComponent,
    MarkupEditorComponent,
    PermissionInputComponent,
    SidebarCartItemComponent,
    SidebarComponent,
    StandardFormInputComponent,
    TrainingComponent
} from '../../core/components';
import { MaterialModule } from '../../material.module';
import { PrimeNGModule } from '../../primeng.module';
import {
    ModalDeveloperToolsComponent
} from '../../shared/developer-tools/modal-developer-tools/modal-developer-tools.component';
import { EditorDefaultModulesDirective } from '../../shared/directives/p-editor-default-modules.directive';
import { ModalHttpErrorComponent } from '../../shared/error/modal-http-error/modal-http-error.component';
import {
    ModalManagerEnrollmentInvoiceComponent
} from '../containers/admin/manager/enrollments/manager-enrollments/modal-manager-enrollment-invoice/modal-manager-enrollment-invoice.component';
import {
    ModalApplyCouponCodeComponent
} from '../containers/admin/manager/enrollments/modal-apply-coupon-code/modal-apply-coupon-code.component';
import {
    ModalEditDynamicFormQuestionsComponent
} from '../containers/admin/manager/enrollments/modal-edit-dynamic-form-questions/modal-edit-dynamic-form-questions.component';
import {
    ModalEditEnrollmentClubComponent
} from '../containers/admin/manager/enrollments/modal-edit-enrollment-club/modal-edit-enrollment-club.component';
import {
    ModalEditEnrollmentClubsComponent
} from '../containers/admin/manager/enrollments/modal-edit-enrollment-clubs/modal-edit-enrollment-clubs.component';
import {
    ModalManagerEditEnrollmentProjectComponent
} from '../containers/admin/manager/enrollments/modal-manager-edit-enrollment-project/modal-manager-edit-enrollment-project.component';
import {
    ModalManagerEditEnrollmentProjectsComponent
} from '../containers/admin/manager/enrollments/modal-manager-edit-enrollment-projects/modal-manager-edit-enrollment-projects.component';
import {
    ModalAddEventEmailTemplateComponent
} from '../containers/admin/manager/events/modal-add-event-email-template/modal-add-event-email-template.component';
import { ModalAddEventComponent } from '../containers/admin/manager/events/modal-add-event/modal-add-event.component';
import {
    ModalAddRegistrationTypeComponent
} from '../containers/admin/manager/events/modal-add-registration-type/modal-add-registration-type.component';
import {
    ModalChangeEventStatusComponent
} from '../containers/admin/manager/events/modal-change-event-status/modal-change-event-status.component';
import {
    ModalEditEventInstructionsComponent
} from '../containers/admin/manager/events/modal-edit-event-instructions/modal-edit-event-instructions.component';
import {
    ModalEditEventRegistrationComponent
} from '../containers/admin/manager/events/modal-edit-event-registration/modal-edit-event-registration.component';
import { ModalEditEventComponent } from '../containers/admin/manager/events/modal-edit-event/modal-edit-event.component';
import {
    ModalEventAddManagerComponent
} from '../containers/admin/manager/events/modal-event-add-manager/modal-event-add-manager.component';
import {
    ModalEventPermissionsComponent
} from '../containers/admin/manager/events/modal-event-permissions/modal-event-permissions.component';
import {
    ModalManagerEventInvoiceComponent
} from '../containers/admin/manager/events/modal-manager-event-invoice/modal-manager-event-invoice.component';
import {
    ManagerAnimalCardComponent
} from '../containers/admin/manager/manager/manager-animal-card/manager-animal-card.component';
import { ModalAddClubComponent } from '../containers/admin/manager/modals/modal-add-club/modal-add-club.component';
import {
    ModalAddEventCustomFileComponent
} from '../containers/admin/manager/modals/modal-add-event-custom-file/modal-add-event-custom-file.component';
import {
    ModalManagerAddProjectsComponent
} from '../containers/admin/manager/modals/modal-add-projects/modal-add-projects.component';
import {
    ModalChangeProfileComponent
} from '../containers/admin/manager/modals/modal-change-profile/modal-change-profile.component';
import {
    ModalChangeProgramYearComponent
} from '../containers/admin/manager/modals/modal-change-program-year/modal-change-program-year.component';
import {
    ModalCreateBatchComponent
} from '../containers/admin/manager/modals/modal-create-batch/modal-create-batch.component';
import {
    ModalCreateCouponComponent
} from '../containers/admin/manager/modals/modal-create-coupon/modal-create-coupon.component';
import {
    ModalEditAddressComponent
} from '../containers/admin/manager/modals/modal-edit-address/modal-edit-address.component';
import {
    ModalEditClubCharterComponent
} from '../containers/admin/manager/modals/modal-edit-club-charter/modal-edit-club-charter.component';
import {
    ModalEditClubEnrollmentRestrictionsComponent
} from '../containers/admin/manager/modals/modal-edit-club-enrollment-restrictions/modal-edit-club-enrollment-restrictions.component';
import { ModalEditClubComponent } from '../containers/admin/manager/modals/modal-edit-club/modal-edit-club.component';
import { ModalEditCouponComponent } from '../containers/admin/manager/modals/modal-edit-coupon/modal-edit-coupon.component';
import {
    ModalEditCustomReportComponent
} from '../containers/admin/manager/modals/modal-edit-custom-report/modal-edit-custom-report.component';
import {
    ModalEditDiscountsComponent
} from '../containers/admin/manager/modals/modal-edit-discounts/modal-edit-discounts.component';
import {
    ModalEditEnrollmentFeeComponent
} from '../containers/admin/manager/modals/modal-edit-enrollment-fee/modal-edit-enrollment-fee.component';
import {
    ModalEditProjectRestrictionComponent
} from '../containers/admin/manager/modals/modal-edit-project-restriction/modal-edit-project-restriction.component';
import { ModalEmailTitleComponent } from '../containers/admin/manager/modals/modal-email-title/modal-email-title.component';
import {
    ModalLinkEventConsentComponent
} from '../containers/admin/manager/modals/modal-link-event-consent/modal-link-event-consent.component';
import { ModalSelectClubComponent } from '../containers/admin/manager/modals/modal-select-club/modal-select-club.component';
import {
    ModalUnlinkProjectComponent
} from '../containers/admin/manager/modals/modal-unlink-project/modal-unlink-project.component';
import {
    ModalUpdateCouponComponent
} from '../containers/admin/manager/modals/modal-update-coupon/modal-update-coupon.component';
import {
    ModalUpdateScreeningComponent
} from '../containers/admin/manager/modals/modal-update-screening/modal-update-screening.component';
import { ModalSelectProgramComponent } from '../containers/admin/modal-select-program/modal-select-program.component';
import {
    DynamicCheckboxOptionsComponent
} from '../containers/admin/shared/dynamic-forms/dynamic-checkbox-options/dynamic-checkbox-options.component';
import {
    DynamicDateInputOptionsComponent
} from '../containers/admin/shared/dynamic-forms/dynamic-date-input-options/dynamic-date-input-options.component';
import {
    DynamicDropdownInputOptionsComponent
} from '../containers/admin/shared/dynamic-forms/dynamic-dropdown-input-options/dynamic-dropdown-input-options.component';
import {
    DynamicMultipleChoiceQuestionOptionsComponent
} from '../containers/admin/shared/dynamic-forms/dynamic-multiple-choice-question-options/dynamic-multiple-choice-question-options.component';
import {
    DynamicNumberInputOptionsComponent
} from '../containers/admin/shared/dynamic-forms/dynamic-number-input-options/dynamic-number-input-options.component';
import {
    DynamicOptionsRootComponent
} from '../containers/admin/shared/dynamic-forms/dynamic-options-root/dynamic-options-root.component';
import {
    DynamicQuantityControlOptionsComponent
} from '../containers/admin/shared/dynamic-forms/dynamic-quantity-control-options/dynamic-quantity-control-options.component';
import {
    DynamicTextAreaInputOptionsComponent
} from '../containers/admin/shared/dynamic-forms/dynamic-text-area-input-options/dynamic-text-area-input-options.component';
import {
    DynamicTextAreaOptionsComponent
} from '../containers/admin/shared/dynamic-forms/dynamic-text-area-options/dynamic-text-area-options.component';
import {
    DynamicTextInputOptionsComponent
} from '../containers/admin/shared/dynamic-forms/dynamic-text-input-options/dynamic-text-input-options.component';
import {
    ModalDynamicAddControlComponent
} from '../containers/admin/shared/dynamic-forms/modal-dynamic-add-control/modal-dynamic-add-control.component';
import {
    ModalDynamicEditControlComponent
} from '../containers/admin/shared/dynamic-forms/modal-dynamic-edit-control/modal-dynamic-edit-control.component';
import {
    ModalAddDeliveryModeComponent
} from '../containers/admin/system-manager/enrollment/modal-add-delivery-mode/modal-add-delivery-mode.component';
import {
    ModalAddEnrollmentTrainingComponent
} from '../containers/admin/system-manager/enrollment/modal-add-enrollment-training/modal-add-enrollment-training.component';
import {
    ModalAddVolunteerTypeComponent
} from '../containers/admin/system-manager/enrollment/modal-add-volunteer-type/modal-add-volunteer-type.component';
import {
    ModalEditDeliveryModeComponent
} from '../containers/admin/system-manager/enrollment/modal-edit-delivery-mode/modal-edit-delivery-mode.component';
import {
    ModalEditVolunteerTypeComponent
} from '../containers/admin/system-manager/enrollment/modal-edit-volunteer-type/modal-edit-volunteer-type.component';
import {
    ModalLinkProjectConsentComponent
} from '../containers/admin/system-manager/enrollment/modal-link-project-consent/modal-link-project-consent.component';
import {
    ModalLinkVolunteerTypeConsentComponent
} from '../containers/admin/system-manager/enrollment/modal-link-volunteer-type-consent/modal-link-volunteer-type-consent.component';
import {
    ModalReorderCustomFilesComponent
} from '../containers/admin/system-manager/enrollment/modal-reorder-custom-files/modal-reorder-custom-files.component';
import {
    ModalAddCustomFileComponent
} from '../containers/admin/system-manager/modals/modal-add-custom-file/modal-add-custom-file.component';
import { ModalAddFundComponent } from '../containers/admin/system-manager/modals/modal-add-fund/modal-add-fund.component';
import {
    ModalAddLessonComponent
} from '../containers/admin/system-manager/modals/modal-add-lesson/modal-add-lesson.component';
import {
    ModalAddManagerComponent
} from '../containers/admin/system-manager/modals/modal-add-manager/modal-add-manager.component';
import {
    ModalAddProjectComponent
} from '../containers/admin/system-manager/modals/modal-add-project/modal-add-project.component';
import {
    ModalAddStaffPermissionsComponent
} from '../containers/admin/system-manager/modals/modal-add-staff-permissions/modal-add-staff-permissions.component';
import {
    ModalAddTrainingComponent
} from '../containers/admin/system-manager/modals/modal-add-training/modal-add-training.component';
import {
    ModalCreateConsentComponent
} from '../containers/admin/system-manager/modals/modal-create-consent/modal-create-consent.component';
import {
    ModalEditBankAccountComponent
} from '../containers/admin/system-manager/modals/modal-edit-bank-account/modal-edit-bank-account.component';
import {
    ModalEditCustomFileComponent
} from '../containers/admin/system-manager/modals/modal-edit-custom-file/modal-edit-custom-file.component';
import { ModalEditFundComponent } from '../containers/admin/system-manager/modals/modal-edit-fund/modal-edit-fund.component';
import {
    ModalEditLessonComponent
} from '../containers/admin/system-manager/modals/modal-edit-lesson/modal-edit-lesson.component';
import {
    ModalEditProjectComponent
} from '../containers/admin/system-manager/modals/modal-edit-project/modal-edit-project.component';
import {
    ModalEditTrainingComponent
} from '../containers/admin/system-manager/modals/modal-edit-training/modal-edit-training.component';
import {
    ModalLinkConsentComponent
} from '../containers/admin/system-manager/modals/modal-link-consent/modal-link-consent.component';
import {
    ModalManagerPermissionsComponent
} from '../containers/admin/system-manager/modals/modal-manager-permissions/modal-manager-permissions.component';
import {
    ModalPreviewConsentComponent
} from '../containers/admin/system-manager/modals/modal-preview-consent/modal-preview-consent.component';
import {
    ModalReorderLessonsComponent
} from '../containers/admin/system-manager/modals/modal-reorder-lessons/modal-reorder-lessons.component';
import {
    ModalSetupStandardFormComponent
} from '../containers/admin/system-manager/modals/modal-setup-standard-form/modal-setup-standard-form.component';
import {
    ModalVerifyEmailComponent
} from '../containers/admin/system-manager/modals/modal-verify-email/modal-verify-email.component';
import {
    ModalEditFamilyAddressComponent
} from '../containers/family/modal-edit-family-address/modal-edit-family-address.component';
import { ModalEditFamilyComponent } from '../containers/family/modal-edit-family/modal-edit-family.component';
import {
    ModalRegisterForPromotedEventComponent
} from '../containers/family/modal-register-for-promoted-event/modal-register-for-promoted-event.component';
import { ModalViewCreditCardComponent } from '../containers/family/modal-view-credit-card/modal-view-credit-card.component';
import { ModalEditHierarchyComponent } from '../containers/institution/modal-edit-hierarchy/modal-edit-hierarchy.component';
import { ModalEnterEmailComponent } from '../containers/institution/modal-enter-email/modal-enter-email.component';
import {
    ModalSelectSystemManagerInstitutionProfileComponent
} from '../containers/institution/modal-select-system-manager-institution-profile/modal-select-system-manager-institution-profile.component';
import {
    ModalSuperuserPermissionsComponent
} from '../containers/institution/modal-superuser-permissions/modal-superuser-permissions.component';
import {
    ModalUpdatePermissionsComponent
} from '../containers/institution/modal-update-permissions/modal-update-permissions.component';
import {
    AddVolunteerTypesComponent
} from '../containers/member/enrollment/adult-enrollment/add-volunteer-types/add-volunteer-types.component';
import {
    ModalAddEnrollmentVolunteerTypesComponent
} from '../containers/member/enrollment/adult-enrollment/modal-add-enrollment-volunteer-types/modal-add-enrollment-volunteer-types.component';
import {
    ModalEnrollmentVolunteerTypesConsentComponent
} from '../containers/member/enrollment/adult-enrollment/modal-enrollment-volunteer-types-consent/modal-enrollment-volunteer-types-consent.component';
import {
    ModalAddAdditionalPaymentComponent
} from '../containers/member/enrollment/modal-add-additional-payment/modal-add-additional-payment.component';
import { ModalAddClubsComponent } from '../containers/member/enrollment/modal-add-clubs/modal-add-clubs.component';
import {
    ModalAddProjectsVolunteerComponent
} from '../containers/member/enrollment/modal-add-projects-volunteer/modal-add-projects-volunteer.component';
import { ModalAddProjectsComponent } from '../containers/member/enrollment/modal-add-projects/modal-add-projects.component';
import {
    ModalChangeProjectClubComponent
} from '../containers/member/enrollment/modal-change-project-club/modal-change-project-club.component';
import {
    ModalEditEnrollmentVolunteerTypesComponent
} from '../containers/member/enrollment/view/modal-edit-enrollment-volunteer-types/modal-edit-enrollment-volunteer-types.component';
import {
    AdultDemographicsFormComponent
} from '../containers/member/enrollment/wizard-containers/adult-demographics-form/adult-demographics-form.component';
import {
    DemographicsFormComponent
} from '../containers/member/enrollment/wizard-containers/demographics-form/demographics-form.component';
import { AddClubsComponent } from '../containers/member/enrollment/youth-enrollment/add-clubs/add-clubs.component';
import { AddProjectsComponent } from '../containers/member/enrollment/youth-enrollment/add-projects/add-projects.component';
import {
    MemberProgramSelectorComponent
} from '../containers/member/member-program-selector/member-program-selector.component';
import { ModalAddMedicationComponent } from '../containers/member/modal-add-medication/modal-add-medication.component';
import { ModalEditMedicationComponent } from '../containers/member/modal-edit-medication/modal-edit-medication.component';
import {
    ModalEditMemberEmergencyContactComponent
} from '../containers/member/modal-edit-member-emergency-contact/modal-edit-member-emergency-contact.component';
import { ModalEditMemberComponent } from '../containers/member/modal-edit-member/modal-edit-member.component';
import {
    ModalEditSecondFamilyComponent
} from '../containers/member/modal-edit-second-family/modal-edit-second-family.component';
import { ModalEnrollComponent } from '../containers/member/modal-enroll/modal-enroll.component';
import { ModalProjectsConsentComponent } from '../containers/member/modal-projects-consent/modal-projects-consent.component';
import { AddPaymentOptionComponent } from '../containers/member/shared-wizard-containers';
import {
    ModalChangeInstitutionComponent
} from '../containers/superuser/modal-change-institution/modal-change-institution.component';
import {
    ModalOfflineScheduleComponent
} from '../containers/superuser/modal-offline-schedule/modal-offline-schedule.component';
import { ModalTakeOfflineComponent } from '../containers/superuser/modal-take-offline/modal-take-offline.component';
import { AvailableFilterValuePipe } from '../pipes/available-filter-values.pipe';
import { DeliveryModePipe } from '../pipes/delivery-mode.pipe';
import { DocumentationQueryStringPipe } from '../pipes/documentation-query-string.pipe';
import { EnrollmentFeePipe } from '../pipes/enrollment-fee.pipe';
import { EnrollmentStatusPipe } from '../pipes';
import { ExcludeByPropertyValuePipe } from '../pipes';
import { FilterOperatorColumnFilterPipe } from '../pipes/filter-operator-column-filter';
import { GenderAliasPipe } from '../pipes/gender-alias.pipe';
import { HierarchyNodeTypePipe } from '../pipes/hierarchy-node-type.pipe';
import { HighlightPipe } from '../pipes/highlight.pipe';
import { ImagePipe } from '../pipes/image.pipe';
import { IncludeByPropertyValuePipe } from '../pipes/include-by-property-value.pipe';
import { ManagerBankAccountPipe } from '../pipes/manager-bank-account.pipe';
import { MemberPipe } from '../pipes/member.pipe';
import { OrderByPipe } from '../pipes/order-by.pipe';
import { ProgramAliasPipe } from '../pipes/program-alias.pipe';
import { ProgramLogoSrcPipe } from '../pipes/program-logo-src.pipe';
import { ProgramYearPipe } from '../pipes/program-year.pipe';
import { ReplacePipe } from '../pipes/replace.pipe';
import { SafeHtmlPipe } from '../pipes/safe-html.pipe';
import { SafePipe } from '../pipes/safe.pipe';
import { SchoolDistrictPipe } from '../pipes/school-district.pipe';
import { SchoolGradePipe } from '../pipes/school-grade.pipe';
import { SchoolPipe } from '../pipes/school.pipe';
import { TableApprovalStatePipe } from '../pipes/table-approval-state.pipe';
import { YesNoPipe } from '../pipes/yes-no.pipe';
import { ManagerProgramHierarchyPipe } from '../pipes/manager-program-hierarchy.pipe';
import { AddressDisplayComponent } from './address-display/address-display.component';
import { AddressInputComponent } from './address-input/address-input.component';
import { AdminConsentComponent } from './admin-consent/admin-consent.component';
import { MemberFooterComponent } from './anti-discrimination/member-footer.component';
import {
    AuthorizeNetCreditCardInputComponent
} from './authorize-net-credit-card-input/authorize-net-credit-card-input.component';
import { CardModule } from './card/card.module';
import {
    ClubsMultiselectAutocompleteComponent
} from './clubs-multiselect-autocomplete/clubs-multiselect-autocomplete.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import {
    CountyAreaMultiselectAutocompleteComponent
} from './county-area-multiselect-autocomplete/county-area-multiselect-autocomplete.component';
import { CountyAreaSelectComponent } from './county-area-select/county-area-select.component';
import {
    CountyMultiselectAutocompleteComponent
} from './county-multiselect-autocomplete/county-multiselect-autocomplete.component';
import { CountySelectComponent } from './county-select/county-select.component';
import { DeliveryModeDropdownComponent } from './delivery-mode-dropdown/delivery-mode-dropdown.component';
import { DisplayStandardFormComponent } from './display-standard-form/display-standard-form.component';
import { DynamicFormDisplayComponent } from './dynamic-form-display/dynamic-form-display.component';
import { EnrollmentRoleDropdownComponent } from './enrollment-role-dropdown/enrollment-role-dropdown.component';
import { FileControlComponent } from './file-control/file-control.component';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { FilterGroupComponent } from './filter-group/filter-group.component';
import { HierarchyBreadcrumbComponent } from './hierarchy-breadcrumb/hierarchy-breadcrumb.component';
import { HierarchyPathComponent } from './hierarchy-path/hierarchy-path.component';
import { HierarchyPickerComponent } from './hierarchy-picker/hierarchy-picker.component';
import { HierarchyTreeOverlayComponent } from './hierarchy-tree-overlay/hierarchy-tree-overlay.component';
import { HierarchyTreeComponent } from './hierarchy-tree/hierarchy-tree.component';
import { InAppHelpComponent } from './in-app-help/in-app-help.component';
import { InstitutionDropdownComponent } from './institution-dropdown/institution-dropdown.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { LoadingPlaceholderComponent } from './loading-placeholder/loading-placeholder.component';
import { LogoComponent } from './logo/logo.component';
import { ManagerSearchAutocompleteComponent } from './manager-search-autocomplete/manager-search-autocomplete.component';
import { ManagerSidebarAdComponent } from './manager-sidebar-ad/manager-sidebar-ad.component';
import { ManagerSidebarComponent } from './manager-sidebar/manager-sidebar.component';
import { ModalAntiDiscriminationComponent } from './modal-anti-discrimination/modal-anti-discrimination.component';
import { ModalChangeHierarchyComponent } from './modal-change-hierarchy/modal-change-hierarchy.component';
import { ModalConfirmComponent } from './modal-confirm/modal-confirm.component';
import { ModalCreditConsentComponent } from './modal-credit-consent/modal-credit-consent.component';
import { ModalEditAnimalComponent } from './modal-edit-animal/modal-edit-animal.component';
import { ModalEditRawTextComponent } from './modal-edit-raw-text/modal-edit-raw-text.component';
import { ModalFullscreenComponent } from './modal-fullscreen/modal-fullscreen.component';
import { ModalGlobalSearchComponent } from './modal-global-search/modal-global-search.component';
import { ModalManagerSearchComponent } from './modal-manager-search/modal-manager-search.component';
import { MonthPickerComponent } from './month-picker/month-picker.component';
import {
    ModalMultiselectAutocompleteComponent
} from './multiselect-autocomplete/modal-multiselect-autocomplete/modal-multiselect-autocomplete.component';
import { MultiselectAutocompleteComponent } from './multiselect-autocomplete/multiselect-autocomplete.component';
import { NumberSelectComponent } from './number-select/number-select.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { ProgramDropdownComponent } from './program-dropdown/program-dropdown.component';
import { ProgramYearDropdownComponent } from './program-year-dropdown/program-year-dropdown.component';
import { ProgressSpinnerDialogComponent } from './progress-spinner-dialog/progress-spinner-dialog.component';
import { ProjectDropdownComponent } from './project-dropdown/project-dropdown.component';
import { QuillEditorHeaderFullComponent } from './quill-editor-header-full/quill-editor-header-full.component';
import { ReadonlyStandardFormComponent } from './readonly-standard-form/readonly-standard-form.component';
import { RegistrationRoleDropdownComponent } from './registration-role-dropdown/registration-role-dropdown.component';
import { RenderedComponent } from './rendered/rendered.component';
import { ReorderListComponent } from './reorder-list/reorder-list.component';
import { ReportSortComponent } from './report-sort/report-sort.component';
import { RootRoutingComponent } from './root-routing/root-routing.component';
import { RouterBreadcrumbComponent } from './router-breadcrumb/router-breadcrumb.component';
import { SidebarAccordionContentsComponent } from './sidebar-accordion-contents/sidebar-accordion-contents.component';
import { SidebarAccordionHeaderComponent } from './sidebar-accordion-header/sidebar-accordion-header.component';
import { SidebarAccordionComponent } from './sidebar-accordion/sidebar-accordion.component';
import { SidebarCartComponent } from './sidebar-cart/sidebar-cart.component';
import { SidebarInAppHelpComponent } from './sidebar-in-app-help/sidebar-in-app-help.component';
import { StandardFormFieldConfigComponent } from './standard-form-field-config/standard-form-field-config.component';
import { StateDropdownComponent } from './state-dropdown/state-dropdown.component';
import { StripeCreditCardInputComponent } from './stripe-credit-card-input/stripe-credit-card-input.component';
import { TableColumnManagerComponent } from './table-column-manager/table-column-manager.component';
import { TableFullscreenComponent } from './table-fullscreen/table-fullscreen.component';
import { TableComponent } from './table/table.component';
import {
    TouchnetCreditCardInputComponentComponent
} from './touchnet-credit-card-input-component/touchnet-credit-card-input-component.component';
import { VantivCreditCardInputComponent } from './vantiv-credit-card-input/vantiv-credit-card-input.component';
import { MaybeYesNoPipe } from '../pipes/maybe-yes-no.pipe';
import { ModalInteractionComponent } from './modal-interaction/modal-interaction.component';
import { ModalAlertComponent } from './modal-alert/modal-alert.component';

const pipes = [
    HierarchyNodePipe,
    AgePipe,
    ReversePipe,
    CamelToTitleCasePipe,
    DynamicDisplayToPipe,
    SearchFilterPipe,
    ExactFilterPipe,
    HighlightPipe,
    DocumentationQueryStringPipe,
    ReplacePipe,
    IncludeByPropertyValuePipe,
    ExcludeByPropertyValuePipe,
    ProgramPipe,
    CountyPipe,
    SchoolDistrictPipe,
    SchoolPipe,
    SchoolGradePipe,
    ProgramYearPipe,
    OrderByPipe,
    YesNoPipe,
    EnrollmentFeePipe,
    SafePipe,
    PrimaryFamilyPipe,
    MemberPipe,
    ImagePipe,
    HierarchyNodeTypePipe,
    AccessControlListRoleTypePipe,
    ProgramAliasPipe,
    GenderAliasPipe,
    EnrollmentStatusPipe,
    EnrollmentRolesToCommaDelimitedListPipe,
    MonthPipe,
    DeliveryModePipe,
    FeePercentPipe,
    ManagerBankAccountPipe,
    BooleanOperatorPipe,
    FilterOperatorColumnFilterPipe,
    AvailableFilterValuePipe,
    ManagerPipe,
    InstitutionPipe,
    DayOfWeekPipe,
    ProgramLogoSrcPipe,
    CustomReportColumnPipe,
    DelimitedListPipe,
    FilterOperatorPipe,
    TableApprovalStatePipe,
    SafeHtmlPipe,
    ProgramTypePipe,
    ParticipationStatusPipe,
    ManagerRegistrationTypePipe,
    YouthDivisionPipe,
    ProjectPipe,
    HierarchyUnitPipe,
    ConsentsSignedPipe,
    YesNoNullablePipe,
    NationalProjectCategoryPipe,
    IdDecodePipe,
    ManagerProgramHierarchyPipe,
    MaybeYesNoPipe
];

// Modal components have to go here because they need to be used in the entry Components
const modals = [
    ModalChangeHierarchyComponent,
    ModalInteractionComponent,
    ModalHttpErrorComponent,
    ModalDeveloperToolsComponent,
    ModalChangeProfileComponent,
    ModalAddClubComponent,
    ModalManagerAddProjectsComponent,
    ModalEnrollComponent,
    ModalAddClubsComponent,
    ModalAddProjectsComponent,
    ModalProjectsConsentComponent,
    CountyAreaMultiselectAutocompleteComponent,
    ClubsMultiselectAutocompleteComponent,
    ModalAddProjectComponent,
    ModalAddTrainingComponent,
    ModalAddLessonComponent,
    ModalEditLessonComponent,
    ModalCreateConsentComponent,
    ModalAddEventComponent,
    ModalAddRegistrationTypeComponent,
    ModalDynamicAddControlComponent,
    ModalManagerPermissionsComponent,
    ModalAddStaffPermissionsComponent,
    ModalSetupStandardFormComponent,
    ModalEditTrainingComponent,
    ModalReorderLessonsComponent,
    ModalEditProjectComponent,
    ModalDynamicEditControlComponent,
    ModalUpdatePermissionsComponent,
    ModalEditHierarchyComponent,
    ModalEditEventComponent,
    ModalEditEventInstructionsComponent,
    ModalEditEventRegistrationComponent,
    ModalEditClubComponent,
    ModalEditMemberComponent,
    ModalEditSecondFamilyComponent,
    ModalEditMemberEmergencyContactComponent,
    ModalConfirmComponent,
    ModalAlertComponent,
    ModalEditFamilyComponent,
    ModalAddCustomFileComponent,
    ModalEditCustomFileComponent,

    ModalAddManagerComponent,
    ModalVerifyEmailComponent,
    ModalEditAddressComponent,
    ModalPreviewConsentComponent,
    ModalLinkConsentComponent,
    ModalLinkProjectConsentComponent,

    ModalAddEnrollmentTrainingComponent,
    ModalCreateBatchComponent,
    ModalUpdateScreeningComponent,
    ModalEditCustomReportComponent,
    ModalAddEventCustomFileComponent,
    ModalGlobalSearchComponent,
    ModalEditFamilyAddressComponent,
    ModalChangeEventStatusComponent,
    ModalLinkEventConsentComponent,
    ModalEditEnrollmentFeeComponent,
    ModalEditDiscountsComponent,
    ModalChangeProgramYearComponent,
    ModalAddMedicationComponent,
    ModalEditMedicationComponent,
    ModalUnlinkProjectComponent,
    ModalEnterEmailComponent,
    ModalChangeInstitutionComponent,
    ModalSuperuserPermissionsComponent,
    ModalSelectProgramComponent,
    ModalEmailTitleComponent,
    ModalEditProjectRestrictionComponent,
    ModalEventPermissionsComponent,
    ModalEventAddManagerComponent,
    ModalAddFundComponent,
    ModalEditFundComponent,

    ModalReorderCustomFilesComponent,

    ModalViewCreditCardComponent,
    ModalAddEventEmailTemplateComponent,
    ModalEditDynamicFormQuestionsComponent,
    ModalEditClubCharterComponent,
    ModalAddVolunteerTypeComponent,
    ModalLinkVolunteerTypeConsentComponent,
    ModalEditVolunteerTypeComponent,
    ModalAddEnrollmentVolunteerTypesComponent,
    ModalEnrollmentVolunteerTypesConsentComponent,
    ModalAddDeliveryModeComponent,
    ModalEditDeliveryModeComponent,
    ModalCreateCouponComponent,
    ModalEditCouponComponent,
    ModalUpdateCouponComponent,
    ModalSelectClubComponent,
    ModalAddProjectsVolunteerComponent,
    ModalApplyCouponCodeComponent,
    ModalManagerEnrollmentInvoiceComponent,
    ModalManagerEventInvoiceComponent,
    ModalEditBankAccountComponent,
    ProgressSpinnerDialogComponent,
    ModalEditClubEnrollmentRestrictionsComponent,
    ModalAddAdditionalPaymentComponent,
    ModalRegisterForPromotedEventComponent,
    ModalSelectSystemManagerInstitutionProfileComponent,
    ModalManagerSearchComponent,
    ModalTakeOfflineComponent,
    AuthorizeNetCreditCardInputComponent,
    ModalEditEnrollmentVolunteerTypesComponent,
    ModalEditEnrollmentClubsComponent,
    ModalEditEnrollmentClubComponent,
    ModalManagerEditEnrollmentProjectsComponent,
    ModalManagerEditEnrollmentProjectComponent,
    ModalMultiselectAutocompleteComponent,
    ModalChangeProjectClubComponent,
    ModalEditAnimalComponent,
    ModalFullscreenComponent,
    ModalCreditConsentComponent,
    ModalOfflineScheduleComponent,
    ModalEditRawTextComponent,
    ModalAntiDiscriminationComponent
];

const components = [
    DynamicFormInputComponent,
    DynamicFormDisplayComponent,
    StandardFormInputComponent,
    DisplayStandardFormComponent,
    ConsentComponent,
    SidebarComponent,
    ManagerSidebarComponent,
    HeaderComponent,
    InAppHelpComponent,
    ProgramDropdownComponent,
    InstitutionDropdownComponent,
    HierarchyPickerComponent,
    HierarchyPathComponent,
    HierarchyTreeComponent,
    HierarchyTreeOverlayComponent,
    SidebarCartComponent,
    SidebarCartItemComponent,
    SidebarInAppHelpComponent,
    VantivCreditCardInputComponent,
    AddressInputComponent,
    MultiselectAutocompleteComponent,
    CountyAreaSelectComponent,
    CountySelectComponent,
    ReorderListComponent,
    InputErrorComponent,
    PermissionInputComponent,
    ProgramYearDropdownComponent,
    HierarchyBreadcrumbComponent,
    MarkupEditorComponent,
    TrainingComponent,
    LoadingPlaceholderComponent,
    FileUploaderComponent,
    StandardFormFieldConfigComponent,
    FileControlComponent,
    AdminConsentComponent,
    AddressDisplayComponent,
    StateDropdownComponent,
    TableComponent,
    FilterGroupComponent,
    ReportSortComponent,
    InvoicesComponent,
    NumberSelectComponent,
    MonthPickerComponent,
    EnrollmentRoleDropdownComponent,
    RouterBreadcrumbComponent,
    PaginatorComponent,
    DeliveryModeDropdownComponent,
    AddPaymentOptionComponent,
    SidebarAccordionComponent,
    SidebarAccordionHeaderComponent,
    SidebarAccordionContentsComponent,
    ComingSoonComponent,
    AddVolunteerTypesComponent,
    AddClubsComponent,
    DemographicsFormComponent,
    AdultDemographicsFormComponent,

    AddProjectsComponent,
    ProjectDropdownComponent,
    ManagerSearchAutocompleteComponent,
    RegistrationRoleDropdownComponent,
    TouchnetCreditCardInputComponentComponent,
    RenderedComponent,
    ReadonlyStandardFormComponent,
    ManagerAnimalCardComponent,
    TableColumnManagerComponent,
    TableFullscreenComponent,
    StripeCreditCardInputComponent,
    CountyMultiselectAutocompleteComponent,
    LogoComponent,
    ManagerSidebarAdComponent,
    QuillEditorHeaderFullComponent,
    MemberProgramSelectorComponent,
    RootRoutingComponent,
    MemberFooterComponent
];
const directives = [
    PlaceholderDirective,
    HierarchyTreeOverlayDirective,
    ManagerPermissionsDirective,
    ManagerHierarchyTypeDirective,
    ProfileTypesDirective,
    DecimalPrecisionDirective,
    ManagerHierarchyNotTypeDirective,
    ManagerEventPermissionsDirective,
    VarDirective,
    OnlyNumber,
    PhoneMaskDirective,
    ProgramSettingsDirective,
    ProgramSettingsMultiDirective,
    EditorDefaultModulesDirective
];

// These have to go here because they're used in a modal
const dynamicOptionsComponentsForModal = [
    DynamicDateInputOptionsComponent,
    DynamicDropdownInputOptionsComponent,
    DynamicTextAreaInputOptionsComponent,
    DynamicTextAreaOptionsComponent,
    DynamicTextInputOptionsComponent,
    DynamicMultipleChoiceQuestionOptionsComponent,
    DynamicNumberInputOptionsComponent,
    DynamicCheckboxOptionsComponent,
    DynamicOptionsRootComponent,
    DynamicQuantityControlOptionsComponent
];

const modules = [
    CardModule,
    PrimeNGModule
];

const declarationsAndExports = [
    ...pipes,
    ...components,
    ...directives,
    ...modals,
    ...dynamicOptionsComponentsForModal
];

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        StripeModule,
        ...modules
    ],
    declarations: declarationsAndExports,
    //   entryComponents: [
    //     ...modals,
    //     LoadingPlaceholderComponent,
    //     LoaderComponent,
    //     HierarchyTreeComponent
    //   ],
    exports: [declarationsAndExports, ...modules]
})
export class SharedComponentModule {}
