import { Action, createReducer, on } from '@ngrx/store';
import { IPaymentSearchResultDto } from 'app/core/models';

import { PaymentsSearchActions } from '.';

export interface PaymentSearchState {

    paymentSearchSearching: boolean;
    paymentsSearchResult: IPaymentSearchResultDto;
}

const initialState: PaymentSearchState = {
    paymentSearchSearching: false,
    paymentsSearchResult: undefined
};

const reducer = createReducer(
    initialState,
    on(PaymentsSearchActions.GetPaymentSearchResultsAction, (state, payload) => {
        return {
            ...state,
            paymentSearchSearching: true,
        };
    }),
    on(PaymentsSearchActions.GetPaymentSearchResultsSuccessAction, (state, payload) => {
        return {
            ...state,
            paymentSearchSearching: false,
            paymentsSearchResult: payload.paymentsSearchResult
        };
    }),
    on(PaymentsSearchActions.GetPaymentSearchResultsErrorAction, (state, payload) => {
        return {
            ...state,
            paymentSearchSearching: false,
        };
    }),

    on(PaymentsSearchActions.SearchPaymentsAction, (state, payload) => {
        return {
            ...state,
            paymentSearchSearching: true,
        };
    }),
    on(PaymentsSearchActions.SearchPaymentsSuccessAction, (state, payload) => {
        return {
            ...state,
            paymentSearchSearching: false,
            paymentsSearchResult: payload.paymentsSearchResult
        };
    }),
    on(PaymentsSearchActions.SearchPaymentsErrorAction, (state, payload) => {
        return {
            ...state,
            paymentSearchSearching: false,
        };
    }),

    on(PaymentsSearchActions.ClearPaymentSearchAction, (state, payload) => {
        return {
            ...state,
            paymentSearchSearching: true,
        };
    }),
    on(PaymentsSearchActions.ClearPaymentSearchErrorAction, (state, payload) => {
        return {
            ...state,
            paymentSearchSearching: false,
        };
    }),

);

export function paymentSearchReducer(state: PaymentSearchState | undefined, action: Action) {
    return reducer(state, action);
}

export const paymentsSearchResult = (state: PaymentSearchState) => state.paymentsSearchResult;
export const paymentSearchSearching = (state: PaymentSearchState) => state.paymentSearchSearching;