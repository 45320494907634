<div class="modal-section">
  <div class="modal-header">
    <h5 class="modal-title">Add Medication</h5>
    <button (click)="close()" type="button" class="close" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <ng4h-display-standard-form [form]="form" [fields]="medicationFields" [showHeader]="false">
    </ng4h-display-standard-form>

  </div>

  <div class="modal-footer">
    <button [disabled]="form.invalid" class="btn btn-primary" (click)="add()">Add</button>
    <button class="btn btn-secondary" (click)="close()">Cancel</button>
  </div>
</div>