import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, Store } from '@ngrx/store';
import { AppState } from 'app/app.reducers';
import { map, Observable, take } from 'rxjs';

import { AddressVerificationActions } from '../containers/user/address-verification';
import { IAddressDto, IInstitutionFamilyParams } from '../models';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AddressVerificationService {

  constructor(private store: Store<AppState>, private httpClient: HttpClient, private dispatcher: ActionsSubject) {
  }

  public verify(params: IInstitutionFamilyParams & { address: IAddressDto }) {
    this.store.dispatch(AddressVerificationActions.VerifyAddressAction(params));
    return this.dispatcher.pipe(
      ofType(AddressVerificationActions.VerifyAddressSuccessAction, AddressVerificationActions.VerifyAddressErrorAction),
      take(1),
      map(action => {
        if (action.type === AddressVerificationActions.VerifyAddressSuccessAction.type) {
          return action.res;
        } else {
          throw action;
        }
      })
    );
  }

  public verifyEffect({ institutionId, familyId, address }: IInstitutionFamilyParams & {
    address: IAddressDto
  }): Observable<any> {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/families/${familyId}/address`, address);
  }

  public recoverAccountEffect({ institutionId, familyId, mobilePhone }: IInstitutionFamilyParams & {
    mobilePhone: string
  }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/families/recover-account?targetFamilyId=${familyId}&mobilePhone=${mobilePhone}`);
  }
}
