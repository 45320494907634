import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ng4h-reorder-list',
  templateUrl: './reorder-list.component.html',
  styleUrls: ['./reorder-list.component.scss']
})
export class ReorderListComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
