import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import {
  IInstitutionManagerProgramEventParams,
  IInstitutionManagerProgramHierarchyParams,
  IInstitutionManagerProgramParams,
  IInstitutionProgramParams,
} from 'app/core/models';

import * as managerUnits from './enrollments/club/store/manager-units.reducer';
import * as managerDiscounts from './enrollments/discounts/store/discounts.reducer';
import * as managerInvoices from './enrollments/enrollment-fees/store/invoice.reducer';
import * as managerEnrollment from './enrollments/manager-enrollments/store/manager-enrollments.reducer';
import * as eventCustomReports from './events/reports/store/event-custom-reports.reducer';
import * as customReports from './reports/store/custom-reports.reducer';
import * as standardReports from './standard-reports/store/standard-reports.reducer';
import * as activities from './store/activities/manager-activities.reducer';
import * as animals from './store/animals/manager-animals.reducer';
import * as awards from './store/awards/manager-awards.reducer';
import * as managerBroadcast from './store/broadcast/manager-broadcast.reducer';
import * as fairEntry from './store/fair-entry/manager-fair-entry.reducer';
import * as managerFinances from './store/finances/manager-finances.reducer';
import * as funds from './store/funding/manager-funding.reducer';
import * as groups from './store/groups/manager-groups.reducer';
import * as managerProjects from './store/projects/manager-projects.reducer';
import * as managerScreenings from './store/screenings/manager-screening.reducer';
import * as animalsSearch from './store/searches/animals/animals-search.reducer';
import * as enrollmentSearch from './store/searches/enrollment/enrollment-search.reducer';
import * as eventPaymentSearch from './store/searches/event-payment/event-payment-search.reducer';
import * as memberSearch from './store/searches/member/member-search.reducer';
import * as participationSearch from './store/searches/participation/participation-search.reducer';
import * as paymentsSearch from './store/searches/payments/payments-search.reducer';
import * as trainingsSearch from './store/searches/trainings/trainings-search.reducer';
import * as trainings from './store/trainings/manager-trainings.reducer';

export const MANAGER_FEATURE = 'MANAGER_FEATURE';

export interface ManagerFeatureStore {
  managerEnrollment: managerEnrollment.ManagerEnrollmentState;
  managerUnits: managerUnits.ManagerUnitsState;
  managerInvoices: managerInvoices.ManagerInvoicesState;
  managerDiscounts: managerDiscounts.ManagerDiscountsState;
  managerProjects: managerProjects.ManagerProjectsState;
  managerScreenings: managerScreenings.ManagerScreeningsState;
  managerBroadcast: managerBroadcast.ManagerBroadcastState;
  managerFinances: managerFinances.ManagerFinancesState;
  customReports: customReports.CustomReportState;
  eventCustomReports: eventCustomReports.EventCustomReportState;

  enrollmentSearch: enrollmentSearch.EnrollmentSearchState;
  memberSearch: memberSearch.MemberSearchState;
  participationSearch: participationSearch.ParticipationSearchState;
  paymentsSearch: paymentsSearch.PaymentSearchState;
  eventPaymentSearch: eventPaymentSearch.EventPaymentSearchState;
  trainingsSearch: trainingsSearch.TrainingSearchState;
  animalsSearch: animalsSearch.AnimalSearchState;
  trainings: trainings.ManagerTrainingsState;
  fairEntry: fairEntry.ManagerFairEntryState;
  activities: activities.ManagerActivitiesState;
  awards: awards.ManagerAwardsState;
  groups: groups.ManagerGroupsState;

  animals: animals.ManagerAnimalState;
  standardReports: standardReports.ManagerStandardReportsState;
  funds: funds.ManagerFundingState;
}

export const managerReducers: ActionReducerMap<ManagerFeatureStore> = {
  managerEnrollment: managerEnrollment.enrollmentManagerReducer,
  managerUnits: managerUnits.managerUnitReducer,
  managerInvoices: managerInvoices.enrollmentInvoiceReducer,
  managerDiscounts: managerDiscounts.discountsReducer,
  managerProjects: managerProjects.managerProjectsReducer,
  managerScreenings: managerScreenings.managerScreeningsReducer,
  managerBroadcast: managerBroadcast.broadcastReducer,
  managerFinances: managerFinances.managerFinancesReducer,
  customReports: customReports.customReportsReducer,
  eventCustomReports: eventCustomReports.eventCustomReportsReducer,

  enrollmentSearch: enrollmentSearch.enrollmentSearchReducer,
  memberSearch: memberSearch.memberSearchReducer,
  participationSearch: participationSearch.participationSearchReducer,
  paymentsSearch: paymentsSearch.paymentSearchReducer,
  eventPaymentSearch: eventPaymentSearch.eventPaymentSearchReducer,
  trainingsSearch: trainingsSearch.trainingSearchReducer,
  animalsSearch: animalsSearch.animalSearchReducer,
  trainings: trainings.managerTrainingsReducer,
  fairEntry: fairEntry.managerFairEntryReducer,
  activities: activities.managerActivitiesReducer,
  awards: awards.managerAwardsReducer,
  groups: groups.managerGroupsReducer,
  animals: animals.animalReducer,
  standardReports: standardReports.standardReportsReducer,
  funds: funds.managerFundingReducer,
};

export const getManagerFeatureStore = createFeatureSelector<ManagerFeatureStore>(MANAGER_FEATURE);

const getCustomReportsState = createSelector(getManagerFeatureStore, (state: ManagerFeatureStore) => state.customReports);

export const getCustomReports = ({ institutionId, managerId, programId, hierarchyNodeId }: IInstitutionManagerProgramHierarchyParams) => createSelector(
  createSelector(getCustomReportsState, customReports.customReports),
  (crs) => crs?.[institutionId]?.[managerId]?.[programId]?.[hierarchyNodeId]
);

export const getCustomReport = ({ institutionId, managerId, programId, customReportId }: IInstitutionManagerProgramParams & { customReportId: string }) => createSelector(
  createSelector(getCustomReportsState, customReports.customReport),
  (customReport) => customReport?.[institutionId]?.[managerId]?.[programId]?.[customReportId]
);
export const getAvailableColumns = ({ institutionId, managerId, programId, customReportId }: IInstitutionManagerProgramParams & { customReportId: string }) => createSelector(
  createSelector(getCustomReportsState, customReports.availableColumns),
  (availableColumns) => availableColumns?.[institutionId]?.[managerId]?.[programId]?.[customReportId]
);

export const getAvailableFilterValues = ({ institutionId, managerId, programId, customReportId, reportColumnId, hierarchyNodeId, programYearId }: IInstitutionManagerProgramParams & { customReportId: string, reportColumnId: string, hierarchyNodeId: string, programYearId: string }) => createSelector(
  createSelector(getCustomReportsState, customReports.availableFilterValues),
  (availableFilterValues) => availableFilterValues?.[institutionId]?.[managerId]?.[programId]?.[customReportId]?.[reportColumnId]?.[hierarchyNodeId]?.[programYearId]
);

export const getQueuedReports = ({ institutionId, managerId, programId, hierarchyNodeId }: IInstitutionManagerProgramParams & { hierarchyNodeId: string }) => createSelector(
  createSelector(getCustomReportsState, customReports.queuedReports),
  (queuedReports) => queuedReports?.[institutionId]?.[managerId]?.[programId]?.[hierarchyNodeId]
);
// export const getQueuedReport = ({ institutionId, managerId, programId, queueToken }: IInstitutionManagerProgramParams & { queueToken: string }) => createSelector(
//   createSelector(getCustomReportsState, customReports.queuedReport),
//   (queuedReport) => queuedReport?.[institutionId]?.[managerId]?.[programId]?.[queueToken]
// );

const getEventCustomReportsState = createSelector(getManagerFeatureStore, (state: ManagerFeatureStore) => state.eventCustomReports);

export const getEventCustomReports = ({ institutionId, managerId, programId, eventId }: IInstitutionManagerProgramEventParams) => createSelector(
  createSelector(getEventCustomReportsState, eventCustomReports.eventCustomReports),
  (crs) => crs?.[institutionId]?.[managerId]?.[programId]?.[eventId]
);

export const getEventCustomReport = ({ institutionId, managerId, programId, eventId, customReportId }: IInstitutionManagerProgramEventParams & { customReportId: string }) => createSelector(
  createSelector(getEventCustomReportsState, eventCustomReports.eventCustomReport),
  (customReport) => customReport?.[institutionId]?.[managerId]?.[programId]?.[eventId]?.[customReportId]
);
export const getEventAvailableColumns = ({ institutionId, managerId, programId, eventId, customReportId }: IInstitutionManagerProgramEventParams & { customReportId: string }) => createSelector(
  createSelector(getEventCustomReportsState, eventCustomReports.eventAvailableColumns),
  (availableColumns) => availableColumns?.[institutionId]?.[managerId]?.[programId]?.[eventId]?.[customReportId]
);

export const getEventAvailableFilterValues = ({ institutionId, managerId, programId, eventId, customReportId, reportColumnId, hierarchyNodeId }: IInstitutionManagerProgramEventParams & { customReportId: string, reportColumnId: string, hierarchyNodeId: string }) => createSelector(
  createSelector(getEventCustomReportsState, eventCustomReports.eventAvailableFilterValues),
  (availableFilterValues) => availableFilterValues?.[institutionId]?.[managerId]?.[programId]?.[eventId]?.[customReportId]?.[reportColumnId]?.[hierarchyNodeId]
);

export const getEventQueuedReports = ({ institutionId, managerId, programId, eventId }: IInstitutionManagerProgramEventParams) => createSelector(
  createSelector(getEventCustomReportsState, eventCustomReports.eventQueuedReports),
  eventQueuedReports => eventQueuedReports?.[institutionId]?.[managerId]?.[programId]?.[eventId]
);
// export const getEventQueuedReport = ({ institutionId, managerId, programId, eventId, queueToken }: IInstitutionManagerProgramEventParams & { queueToken: string }) => createSelector(
//   createSelector(getEventCustomReportsState, eventCustomReports.eventQueuedReport),
//   eventQueuedReport => eventQueuedReport?.[institutionId]?.[managerId]?.[programId]?.[eventId]?.[queueToken]
// )


const getManagerEnrollmentState = createSelector(getManagerFeatureStore, (state: ManagerFeatureStore) => state.managerEnrollment);
export const getManagerSubmittedEnrollments = ({ institutionId, managerId, programId, countyAreaId }: IInstitutionManagerProgramParams & { countyAreaId: string }) => createSelector(
  createSelector(getManagerEnrollmentState, managerEnrollment.submittedEnrollments),
  (enrollments) => enrollments?.[institutionId]?.[managerId]?.[programId]?.[countyAreaId]
);
export const getOutstandingCreditEnrollments = ({ institutionId, managerId, programId, countyAreaId, programYearId }: IInstitutionManagerProgramParams & { countyAreaId: string, programYearId: string }) => createSelector(
  createSelector(getManagerEnrollmentState, managerEnrollment.outstandingCredits),
  (enrollments) => enrollments?.[institutionId]?.[managerId]?.[programId]?.[countyAreaId]?.[programYearId]
);
export const getCollectedCreditEnrollments = ({ institutionId, managerId, programId, countyAreaId, programYearId }: IInstitutionManagerProgramParams & { countyAreaId: string, programYearId: string }) => createSelector(
  createSelector(getManagerEnrollmentState, managerEnrollment.collectedCredits),
  (enrollments) => enrollments?.[institutionId]?.[managerId]?.[programId]?.[countyAreaId]?.[programYearId]
);
export const getMangerGroupEnrollments = ({ institutionId, managerId, programId, countyAreaId, programYearId }: IInstitutionManagerProgramParams & { countyAreaId: string, programYearId: string }) => createSelector(
  createSelector(getManagerEnrollmentState, managerEnrollment.groupEnrollments),
  (enrollments) => enrollments?.[institutionId]?.[managerId]?.[programId]?.[countyAreaId]?.[programYearId]
);

export const getAllCountyCreditBills = ({ institutionId, managerId, programId }: IInstitutionManagerProgramParams) => createSelector(
  createSelector(getManagerEnrollmentState, managerEnrollment.allCountyCreditBills),
  (bills) => bills?.[institutionId]?.[managerId]?.[programId]
);
export const getCountyCreditBillsByProgramYear = ({ institutionId, managerId, programId, programYearId }: IInstitutionManagerProgramParams & { programYearId: string }) => createSelector(
  createSelector(getManagerEnrollmentState, managerEnrollment.countyCreditBillsByProgramYear),
  (bills) => bills?.[institutionId]?.[managerId]?.[programId]?.[programYearId]
);
export const getCountyCreditBills = ({ institutionId, managerId, programId, countyAreaId }: IInstitutionManagerProgramParams & { countyAreaId: string }) => createSelector(
  createSelector(getManagerEnrollmentState, managerEnrollment.countyCreditBills),
  (bills) => bills?.[institutionId]?.[managerId]?.[programId]?.[countyAreaId]
);
export const getCountyCreditBill = ({ institutionId, managerId, programId, countyCreditBillId }: IInstitutionManagerProgramParams & { countyCreditBillId: string }) => createSelector(
  createSelector(getManagerEnrollmentState, managerEnrollment.countyCreditBill),
  (bills) => bills?.[institutionId]?.[managerId]?.[programId]?.[countyCreditBillId]
);
export const getEnrollmentBillPreview = ({ institutionId, managerId, programId, countyAreaId }: IInstitutionManagerProgramParams & { countyAreaId: string }) => createSelector(
  createSelector(getManagerEnrollmentState, managerEnrollment.enrollmentBillPreview),
  (bills) => bills?.[institutionId]?.[managerId]?.[programId]?.[countyAreaId]
);
export const getRegistrationBillPreview = ({ institutionId, managerId, programId, countyAreaId }: IInstitutionManagerProgramParams & { countyAreaId: string }) => createSelector(
  createSelector(getManagerEnrollmentState, managerEnrollment.registrationBillPreview),
  (bills) => bills?.[institutionId]?.[managerId]?.[programId]?.[countyAreaId]
);
export const getMemberCredits = ({ institutionId, managerId, programId, countyCreditBillId }: IInstitutionManagerProgramParams & { countyCreditBillId: string }) => createSelector(
  createSelector(getManagerEnrollmentState, managerEnrollment.memberCredits),
  (memberCredits) => memberCredits?.[institutionId]?.[managerId]?.[programId]?.[countyCreditBillId]
);
export const getEnrollmentFeeWaiverRequests = ({ institutionId, managerId, programId, programYearId }: IInstitutionManagerProgramParams & { programYearId: string }) => createSelector(
  createSelector(getManagerEnrollmentState, managerEnrollment.enrollmentFeeWaiverRequests),
  waiverRequests => waiverRequests?.[institutionId]?.[managerId]?.[programId]?.[programYearId]
);
export const getCountyAreaEnrollmentSettings = ({ institutionId, managerId, programId, countyAreaId }: IInstitutionManagerProgramParams & { countyAreaId: string }) => createSelector(
  createSelector(getManagerEnrollmentState, managerEnrollment.countyAreaEnrollmentSettings),
  waiverRequests => waiverRequests?.[institutionId]?.[managerId]?.[programId]?.[countyAreaId]
);
export const getManagerUnitRequests = ({ institutionId, managerId, programId, countyAreaId }: IInstitutionManagerProgramParams & { countyAreaId: string }) => createSelector(
  createSelector(getManagerEnrollmentState, managerEnrollment.managerUnitRequests),
  managerUnitRequests => managerUnitRequests?.[institutionId]?.[managerId]?.[programId]?.[countyAreaId]
);
// export const getOutstandingPaymentSearchResult = createSelector(getManagerEnrollmentState, managerEnrollment.outstandingCreditSearch);
// export const getOutstandingPaymentSearchSearching = createSelector(getManagerEnrollmentState, managerEnrollment.outstandingCreditSearchSearching);

const getUnitsState = createSelector(getManagerFeatureStore, (state: ManagerFeatureStore) => state.managerUnits);
export const getClubSearchSelectedCountyAreaId = createSelector(getUnitsState, managerUnits.clubSearchSelectedCountyAreaId);
export const getManagerClubsByProgramYear = ({ institutionId, managerId, programId, programYearId, countyAreaId }: IInstitutionManagerProgramParams & { programYearId: string, countyAreaId: string }) => createSelector(
  createSelector(getUnitsState, managerUnits.unitsByProgramYear),
  clubs => clubs?.[institutionId]?.[managerId]?.[programId]?.[programYearId]?.[countyAreaId]
);
export const getManagerClubsNotInProgramYear = ({ institutionId, managerId, programId, programYearId, countyAreaId }: IInstitutionManagerProgramParams & { programYearId: string, countyAreaId: string }) => createSelector(
  createSelector(getUnitsState, managerUnits.unitsNotInProgramYear),
  clubs => clubs?.[institutionId]?.[managerId]?.[programId]?.[programYearId]?.[countyAreaId]
);
export const getManagerClubs = ({ institutionId, managerId, programId }: IInstitutionManagerProgramParams) => createSelector(
  createSelector(getUnitsState, managerUnits.units),
  club => club?.[institutionId]?.[managerId]?.[programId]
);
export const getManagerClub = ({ institutionId, managerId, programId, unitId }: IInstitutionManagerProgramParams & { unitId: string }) => createSelector(
  createSelector(getUnitsState, managerUnits.unit),
  club => club?.[institutionId]?.[managerId]?.[programId]?.[unitId]
);

export const getManagerActiveClubs = ({ institutionId, managerId, programId, programYearId }: IInstitutionManagerProgramParams & { programYearId: string }) => createSelector(
  createSelector(getUnitsState, managerUnits.activeClubs),
  (activeClubs) => activeClubs?.[institutionId]?.[managerId]?.[programId]?.[programYearId]
);

const getManagerInvoiceState = createSelector(getManagerFeatureStore, (state: ManagerFeatureStore) => state.managerInvoices);
export const getManagerInvoices = ({ institutionId, managerId, programId, programYearId }: IInstitutionManagerProgramParams & { programYearId: string }) => createSelector(
  createSelector(getManagerInvoiceState, managerInvoices.enrollmentFees),
  invoices => invoices?.[institutionId]?.[managerId]?.[programId]?.[programYearId]
);

export const getManagerClubInvoices = ({ institutionId, managerId, programId, countyAreaId, programYearId }: IInstitutionManagerProgramParams & { countyAreaId: string, programYearId: string }) => createSelector(
  createSelector(getManagerInvoiceState, managerInvoices.clubInvoices),
  clubInvoices => clubInvoices?.[institutionId]?.[managerId]?.[programId]?.[countyAreaId]?.[programYearId]
);
export const getManagerInvoice = ({ institutionId, managerId, programId, invoiceId }: IInstitutionManagerProgramParams & { invoiceId: string }) => createSelector(
  createSelector(getManagerInvoiceState, managerInvoices.invoice),
  invoice => invoice?.[institutionId]?.[managerId]?.[programId]?.[invoiceId]
);

const getManagerDiscountsState = createSelector(getManagerFeatureStore, (state: ManagerFeatureStore) => state.managerDiscounts);

export const getManagerFamilyCountyDiscounts = ({ institutionId, managerId, programId, programYearId }: IInstitutionManagerProgramParams & { programYearId: string }) => createSelector(
  createSelector(getManagerDiscountsState, managerDiscounts.familyCountyDiscounts),
  discounts => discounts?.[institutionId]?.[managerId]?.[programId]?.[programYearId]
);

const getManagerProjectsState = createSelector(getManagerFeatureStore, (state: ManagerFeatureStore) => state.managerProjects);
export const getManagerProjects = ({ institutionId, managerId, programId }: IInstitutionManagerProgramParams) => createSelector(
  createSelector(getManagerProjectsState, managerProjects.projects),
  (projects) => projects?.[institutionId]?.[managerId]?.[programId]
);

export const getManagerProjectsById = ({ institutionId, managerId, programId, projectId, programYearId }: IInstitutionManagerProgramParams & { projectId: string, programYearId: string }) => createSelector(
  createSelector(getManagerProjectsState, managerProjects.project),
  (project) => project?.[institutionId]?.[managerId]?.[programId]?.[projectId]?.[programYearId]
);
export const getManagerNationalProjectCategories = ({ institutionId, programId, programYearId }: IInstitutionProgramParams & { programYearId: string }) => createSelector(
  createSelector(getManagerProjectsState, managerProjects.nationalProjectCategories),
  (nationalProjectCategories) => nationalProjectCategories?.[institutionId]?.[programId]?.[programYearId]
);
export const getManagerExcludedProjects = ({ institutionId, managerId, programId, countyAreaId }: IInstitutionManagerProgramParams & { countyAreaId: string }) => createSelector(
  createSelector(getManagerProjectsState, managerProjects.excludedProjects),
  (excludedProjects) => excludedProjects?.[institutionId]?.[managerId]?.[programId]?.[countyAreaId]
);
export const getCountyManagerExcludedProjects = ({ institutionId, managerId, programId, unitId }: IInstitutionManagerProgramParams & { unitId: string }) => createSelector(
  createSelector(getManagerProjectsState, managerProjects.countyExcludedProjects),
  (countyExcludedProjects) => countyExcludedProjects?.[institutionId]?.[managerId]?.[programId]?.[unitId]
);

export const getManagerProgramYearHierarchyProjects = ({ institutionId, managerId, programId, programYearId, hierarchyNodeId }: IInstitutionManagerProgramParams & { programYearId: string, hierarchyNodeId: string }) => createSelector(
  createSelector(getManagerProjectsState, managerProjects.programYearHierarchyProjects),
  (projects) => projects?.[institutionId]?.[managerId]?.[programId]?.[programYearId]?.[hierarchyNodeId]
);
export const getManagerProgramYearProjects = ({ institutionId, managerId, programId, programYearId }: IInstitutionManagerProgramParams & { programYearId: string }) => createSelector(
  createSelector(getManagerProjectsState, managerProjects.programYearProjects),
  (projects) => projects?.[institutionId]?.[managerId]?.[programId]?.[programYearId]
);

export const getProjectCountyAliases = ({ institutionId, managerId, programId, countyAreaId, programYearId }: IInstitutionManagerProgramParams & { countyAreaId: string, programYearId: string }) => createSelector(
  createSelector(getManagerProjectsState, managerProjects.countyAliasProjects),
  (projects) => projects?.[institutionId]?.[managerId]?.[programId]?.[countyAreaId]?.[programYearId]
);

const getManagerScreeningsState = createSelector(getManagerFeatureStore, (state: ManagerFeatureStore) => state.managerScreenings);

export const getScreeningBatch = ({ institutionId, managerId, programId }: IInstitutionManagerProgramParams) => createSelector(
  createSelector(getManagerScreeningsState, managerScreenings.managerBatches),
  (managerBatches) => managerBatches?.[institutionId]?.[managerId]?.[programId]
);

export const getScreeningsSearchResult = createSelector(getManagerScreeningsState, managerScreenings.screeningsSearchResult);
export const getScreeningSearchSearching = createSelector(getManagerScreeningsState, managerScreenings.screeningSearchSearching);



export const getScreeningsAvailableForBatch = ({ institutionId, managerId, programId }: IInstitutionManagerProgramParams) => createSelector(
  createSelector(getManagerScreeningsState, managerScreenings.screeningsAvailableForBatch),
  (screeningsAvailableForBatch) => screeningsAvailableForBatch?.[institutionId]?.[managerId]?.[programId]
);
export const getBatchScreenings = ({ institutionId, managerId, programId, profileScreeningBatchId }: IInstitutionManagerProgramParams & { profileScreeningBatchId: string }) => createSelector(
  createSelector(getManagerScreeningsState, managerScreenings.managerBatchScreenings),
  (batchScreenings) => batchScreenings?.[institutionId]?.[managerId]?.[programId]?.[profileScreeningBatchId]
);

export const getSubmittedScreenings = ({ institutionId, managerId, programId, hierarchyNodeId }: IInstitutionManagerProgramHierarchyParams) => createSelector(
  createSelector(getManagerScreeningsState, managerScreenings.submittedScreenings),
  (submittedScreenings) => submittedScreenings?.[institutionId]?.[managerId]?.[programId]?.[hierarchyNodeId]
);

const getManagerBroadcastState = createSelector(getManagerFeatureStore, (state: ManagerFeatureStore) => state.managerBroadcast);

export const getManagerBroadcastNavigation = createSelector(getManagerBroadcastState, managerBroadcast.getNavigation);
export const getManagerBroadcastEmails = ({ institutionId, managerId, programId }: IInstitutionManagerProgramParams) => createSelector(
  createSelector(getManagerBroadcastState, managerBroadcast.broadCastEmails),
  (emails) => emails ? emails?.[institutionId]?.[managerId]?.[programId] : undefined
);
export const getManagerBroadcastEmailById = ({ institutionId, managerId, programId, broadcastEmailId }: IInstitutionManagerProgramParams & { broadcastEmailId: string }) => createSelector(
  createSelector(getManagerBroadcastState, managerBroadcast.broadcastEmail),
  (email) => email ? email[institutionId]?.[managerId]?.[programId]?.[broadcastEmailId] : undefined
);

const getManagerFinanceState = createSelector(getManagerFeatureStore, (state: ManagerFeatureStore) => state.managerFinances);

export const getManagerFinanceFunds = ({ institutionId, managerId, programId }: IInstitutionManagerProgramParams) => createSelector(
  createSelector(getManagerFinanceState, managerFinances.funds),
  (funds) => funds?.[institutionId]?.[managerId]?.[programId]
);
export const getManagerHierarchyFinanceFunds = ({ institutionId, managerId, programId, hierarchyNodeId }: IInstitutionManagerProgramHierarchyParams) => createSelector(
  createSelector(getManagerFinanceState, managerFinances.hierarchyFunds),
  (hierarchyFunds) => hierarchyFunds?.[institutionId]?.[managerId]?.[programId]?.[hierarchyNodeId]
);

export const getManagerFinanceFundById = ({ institutionId, managerId, programId, discountFundId }: IInstitutionManagerProgramParams & { discountFundId: string }) => createSelector(
  createSelector(getManagerFinanceState, managerFinances.fund),
  (fund) => fund?.[institutionId]?.[managerId]?.[programId]?.[discountFundId]
);

export const getManagerFinanceDonors = ({ institutionId, managerId, programId }: IInstitutionManagerProgramParams) => createSelector(
  createSelector(getManagerFinanceState, managerFinances.donors),
  (donors) => donors?.[institutionId]?.[managerId]?.[programId]
);

export const getManagerFinanceDonorById = ({ institutionId, managerId, programId, discountFundDonorId }: IInstitutionManagerProgramParams & { discountFundDonorId: string }) => createSelector(
  createSelector(getManagerFinanceState, managerFinances.donor),
  (donor) => donor?.[institutionId]?.[managerId]?.[programId]?.[discountFundDonorId]
);

export const getManagerFinanceFundDonations = ({ institutionId, managerId, programId, discountFundId }: IInstitutionManagerProgramParams & { discountFundId: string }) => createSelector(
  createSelector(getManagerFinanceState, managerFinances.donations),
  (donations) => donations?.[institutionId]?.[managerId]?.[programId]?.[discountFundId]
);

export const getManagerFinanceFundDonationById = ({ institutionId, managerId, programId, discountFundId, discountFundDonationId }: IInstitutionManagerProgramParams & { discountFundId: string, discountFundDonationId: string }) => createSelector(
  createSelector(getManagerFinanceState, managerFinances.donation),
  (donation) => donation?.[institutionId]?.[managerId]?.[programId]?.[discountFundId]?.[discountFundDonationId]
);

export const getManagerFinanceCountyFundDonations = ({ institutionId, managerId, programId, discountFundId, countyAreaId }: IInstitutionManagerProgramParams & { discountFundId: string, countyAreaId: string }) => createSelector(
  createSelector(getManagerFinanceState, managerFinances.countyDonations),
  (donations) => donations?.[institutionId]?.[managerId]?.[programId]?.[discountFundId]?.[countyAreaId]
);

export const getManagerFinanceCountyDonors = ({ institutionId, managerId, programId, countyAreaId }: IInstitutionManagerProgramParams & { countyAreaId: string }) => createSelector(
  createSelector(getManagerFinanceState, managerFinances.countyDonors),
  (donors) => donors?.[institutionId]?.[managerId]?.[programId]?.[countyAreaId]
);

export const getManagerFinanceCountyDonorById = ({ institutionId, managerId, programId, discountFundDonorId, countyAreaId }: IInstitutionManagerProgramParams & { discountFundDonorId: string, countyAreaId: string }) => createSelector(
  createSelector(getManagerFinanceState, managerFinances.countyDonor),
  (donor) => donor?.[institutionId]?.[managerId]?.[programId]?.[discountFundDonorId]?.[countyAreaId]
);

export const getManagerFinanceCountyFunds = ({ institutionId, managerId, programId, countyAreaId }: IInstitutionManagerProgramParams & { countyAreaId: string }) => createSelector(
  createSelector(getManagerFinanceState, managerFinances.countyFunds),
  (funds) => funds?.[institutionId]?.[managerId]?.[programId]?.[countyAreaId]
);

export const getManagerFinanceCountyFundById = ({ institutionId, managerId, programId, discountFundId, countyAreaId }: IInstitutionManagerProgramParams & { discountFundId: string, countyAreaId: string }) => createSelector(
  createSelector(getManagerFinanceState, managerFinances.countyFund),
  (fund) => fund?.[institutionId]?.[managerId]?.[programId]?.[discountFundId]?.[countyAreaId]
);

export const getManagerFinanceDiscounts = ({ institutionId, managerId, programId, programYearId }: IInstitutionManagerProgramParams & { programYearId: string }) => createSelector(
  createSelector(getManagerFinanceState, managerFinances.discounts),
  (discounts) => discounts?.[institutionId]?.[managerId]?.[programId]?.[programYearId]
);

export const getManagerFinanceDiscount = ({ institutionId, managerId, programId, institutionDiscountId }: IInstitutionManagerProgramParams & { institutionDiscountId: string }) => createSelector(
  createSelector(getManagerFinanceState, managerFinances.discount),
  (discount) => discount?.[institutionId]?.[managerId]?.[programId]?.[institutionDiscountId]
);

const getEnrollmentSearchState = createSelector(getManagerFeatureStore, (state: ManagerFeatureStore) => state.enrollmentSearch);

export const getEnrollmentSearchSearching = createSelector(getEnrollmentSearchState, enrollmentSearch.enrollmentSearchSearching);
export const getEnrollmentsSearchResult = createSelector(getEnrollmentSearchState, enrollmentSearch.enrollmentsSearchResult);

const getMemberSearchState = createSelector(getManagerFeatureStore, (state: ManagerFeatureStore) => state.memberSearch);

export const getMemberSearchSearching = createSelector(getMemberSearchState, memberSearch.memberSearchSearching);
export const getMembersSearchResult = createSelector(getMemberSearchState, memberSearch.membersSearchResult);

const getParticipationSearchState = createSelector(getManagerFeatureStore, (state: ManagerFeatureStore) => state.participationSearch);

export const getParticipationSearchSearching = createSelector(getParticipationSearchState, participationSearch.participationSearchSearching);
export const getParticipationsSearchResult = createSelector(getParticipationSearchState, participationSearch.participationsSearchResult);

const getPaymentsSearchState = createSelector(getManagerFeatureStore, (state: ManagerFeatureStore) => state.paymentsSearch);

export const getPaymentsSearchSearching = createSelector(getPaymentsSearchState, paymentsSearch.paymentSearchSearching);
export const getPaymentsSearchResult = createSelector(getPaymentsSearchState, paymentsSearch.paymentsSearchResult);


const getEventPaymentSearchState = createSelector(getManagerFeatureStore, (state: ManagerFeatureStore) => state.eventPaymentSearch);

export const getEventPaymentSearchSearching = createSelector(getEventPaymentSearchState, eventPaymentSearch.eventPaymentSearchSearching);
export const getEventPaymentSearchResult = createSelector(getEventPaymentSearchState, eventPaymentSearch.eventPaymentsSearchResult);

const getTrainingsSearchState = createSelector(getManagerFeatureStore, (state: ManagerFeatureStore) => state.trainingsSearch);

export const getTrainingsSearchSearching = createSelector(getTrainingsSearchState, trainingsSearch.trainingSearchSearching);
export const getTrainingsSearchResult = createSelector(getTrainingsSearchState, trainingsSearch.trainingsSearchResult);

const getTrainingsState = createSelector(getManagerFeatureStore, (state: ManagerFeatureStore) => state.trainings);

export const getTrainings = ({ institutionId, managerId, programId, hierarchyNodeId }: IInstitutionManagerProgramHierarchyParams) => createSelector(
  createSelector(getTrainingsState, trainings.trainings),
  (t) => t?.[institutionId]?.[managerId]?.[programId]?.[hierarchyNodeId]
);

const getAnimalsSearchState = createSelector(getManagerFeatureStore, (state: ManagerFeatureStore) => state.animalsSearch);

export const getAnimalsSearchSearching = createSelector(getAnimalsSearchState, animalsSearch.animalSearchSearching);
export const getAnimalsSearchResult = createSelector(getAnimalsSearchState, animalsSearch.animalsSearchResult);


const getActivitiesState = createSelector(getManagerFeatureStore, (state: ManagerFeatureStore) => state.activities);

export const getActivities = ({ institutionId, managerId, programId, hierarchyNodeId }: IInstitutionManagerProgramHierarchyParams) => createSelector(
  createSelector(getActivitiesState, activities.activities),
  (a) => a?.[institutionId]?.[managerId]?.[programId]?.[hierarchyNodeId]
);
export const getActivity = ({ institutionId, managerId, programId, activityId }: IInstitutionManagerProgramParams & { activityId: string }) => createSelector(
  createSelector(getActivitiesState, activities.activity),
  (a) => a?.[institutionId]?.[managerId]?.[programId]?.[activityId]
);

const getAwardsState = createSelector(getManagerFeatureStore, (state: ManagerFeatureStore) => state.awards);

export const getAwards = ({ institutionId, managerId, programId, hierarchyNodeId }: IInstitutionManagerProgramHierarchyParams) => createSelector(
  createSelector(getAwardsState, awards.awards),
  (a) => a?.[institutionId]?.[managerId]?.[programId]?.[hierarchyNodeId]
);

export const getAward = ({ institutionId, managerId, programId, awardId }: IInstitutionManagerProgramParams & { awardId: string }) => createSelector(
  createSelector(getAwardsState, awards.award),
  (a) => a?.[institutionId]?.[managerId]?.[programId]?.[awardId]
);


const getGroupsState = createSelector(getManagerFeatureStore, (state: ManagerFeatureStore) => state.groups);

export const getGroups = ({ institutionId, managerId, programId, hierarchyNodeId }: IInstitutionManagerProgramHierarchyParams) => createSelector(
  createSelector(getGroupsState, groups.groups),
  (a) => a?.[institutionId]?.[managerId]?.[programId]?.[hierarchyNodeId]
);

export const getGroup = ({ institutionId, managerId, programId, groupId }: IInstitutionManagerProgramParams & { groupId: string }) => createSelector(
  createSelector(getGroupsState, groups.group),
  (a) => a?.[institutionId]?.[managerId]?.[programId]?.[groupId]
);

const getAnimalsState = createSelector(getManagerFeatureStore, (state: ManagerFeatureStore) => state.animals);

export const getAnimalTypes = ({ institutionId, programId }: IInstitutionProgramParams) => createSelector(
  createSelector(getAnimalsState, animals.animalTypes),
  animalTypes => animalTypes?.[institutionId]?.[programId]
);
export const getSubmittedEnrollmentAnimals = ({ institutionId, managerId, programId, countyAreaId }: IInstitutionManagerProgramParams & { countyAreaId: string }) => createSelector(
  createSelector(getAnimalsState, animals.submittedAnimals),
  animalTypes => animalTypes?.[institutionId]?.[managerId]?.[programId]?.[countyAreaId]
);
const getStandardReportsState = createSelector(getManagerFeatureStore, (state: ManagerFeatureStore) => state.standardReports);

export const getStandardReports = ({ institutionId, managerId, programId }: IInstitutionManagerProgramParams) => createSelector(
  createSelector(getStandardReportsState, standardReports.standardReports),
  standardReports => standardReports?.[institutionId]?.[managerId]?.[programId]
);

const getFundsState = createSelector(getManagerFeatureStore, (state: ManagerFeatureStore) => state.funds);

export const getFunds = ({ institutionId, managerId, programId, hierarchyNodeId }: IInstitutionManagerProgramHierarchyParams) => createSelector(
  createSelector(getFundsState, funds.funds),
  funds => funds?.[institutionId]?.[managerId]?.[programId]?.[hierarchyNodeId]
);

const getFundCreditsState = createSelector(getManagerFeatureStore, (state: ManagerFeatureStore) => state.funds);

export const getFundCredits = ({ institutionId, managerId, programId, hierarchyNodeId }: IInstitutionManagerProgramHierarchyParams) => createSelector(
    createSelector(getFundCreditsState, funds.credits),
    credits => credits?.[institutionId]?.[managerId]?.[programId]?.[hierarchyNodeId]
);

export const getFund = ({ institutionId, managerId, programId, fundsTransferId }: IInstitutionManagerProgramParams & { fundsTransferId: string }) => createSelector(
  createSelector(getFundsState, funds.fund),
  fund => fund?.[institutionId]?.[managerId]?.[programId]?.[fundsTransferId]
);


const getFairEntryState = createSelector(getManagerFeatureStore, (state: ManagerFeatureStore) => state.fairEntry);

export const getFairEntryIntegrations = ({ institutionId, managerId, programId, hierarchyNodeId }: IInstitutionManagerProgramHierarchyParams) => createSelector(
  createSelector(getFairEntryState, fairEntry.fairIntegrations),
  fairIntegrations => fairIntegrations?.[institutionId]?.[managerId]?.[programId]?.[hierarchyNodeId]
);
export const getFairEntryPendingIntegrations = ({ institutionId, managerId, programId, hierarchyNodeId }: IInstitutionManagerProgramHierarchyParams) => createSelector(
  createSelector(getFairEntryState, fairEntry.pendingFairIntegrations),
  pendingFairIntegrations => pendingFairIntegrations?.[institutionId]?.[managerId]?.[programId]?.[hierarchyNodeId]
);

