<mat-chip-list class="fake-input form-control" matAutocompleteOrigin #origin="matAutocompleteOrigin"
  (click)="fakeClicked($event)" [ngClass]="{'focus': realInputFocused, 'disabled': disabled}">
  <mat-chip *ngFor="let option of selectedOptions">
    <ng-container *ngIf="displayCamelCaseToTitleCase === true; else default">
      {{option[displaySelector] | camelToTitleCase}}
    </ng-container>
    <ng-template #default>
      {{option[displaySelector]}}
    </ng-template>

    <div class="remove-option" (click)="removeOption(option)"><i class="fas fa-times"></i></div>
  </mat-chip>
  <input #autocompleteInput type="text" aria-label="Number" [matAutocomplete]="auto"
    [matAutocompleteConnectedTo]="origin" [formControl]="formControl"
    [style.width.px]="(formControl.value.length * 10) + 4" (blur)="realInputFocused = false"
    (focus)="realInputFocused = true">
  <span class="placeholder"
    *ngIf="selectedOptions.length == 0 && formControl.value.length == 0 && !realInputFocused">{{placeholder}}</span>
  <span class="icon-container" (click)="openExternal($event)">
    <i class="fas fa-external-link-square-alt"></i>
  </span>

</mat-chip-list>

<mat-autocomplete #auto="matAutocomplete">
  <mat-option (click)="blur()" *ngFor="let option of filteredOptions$ | async" [value]="option">
    <ng-container *ngIf="displayCamelCaseToTitleCase === true; else default">
      {{option[displaySelector] | camelToTitleCase}}
    </ng-container>
    <ng-template #default>
      {{option[displaySelector]}}
    </ng-template>
  </mat-option>
</mat-autocomplete>

<!-- <pre *ngIf="debug">
  {{selectedOptions | json}}
  --
  {{filteredOptions$ | async | json}}
</pre> -->