import { ePaymentGatewayType } from '.';

export interface IGatewayConfigurationDto {
  amexEnabled: boolean;
  discEnabled: boolean;
  mcEnabled: boolean;
  visaEnabled: boolean;
  subMerchantPayPageId: string;
  generatedVaultIdsForCreditCardTokenizer: {
    orderId: string;
    requestId: string;
  };
  tokenizationJavaScriptLibraryUrl: string;
  tokenizationRequestSubmissionUrl: string;
  gateway: ePaymentGatewayType;
  submerchantId: string;

  // touchnet
  uPaySiteId: number;
  // touchnet
  uPayUrl: string;

  // stripe
  publishableKey: string;
  tokenizeUsingSubmerchant: boolean;

  configurationError?: string;
  accountRequirements?: string;
}
