import {
    ChangeDetectorRef,
    Directive,
    ElementRef,
    EmbeddedViewRef,
    Input,
    Renderer2,
    TemplateRef,
    ViewContainerRef
} from '@angular/core';
import { AutoUnsubscribe, IProgramSettingsDto } from 'app/core/models';
import { ProgramSettingsService, RouterService } from 'app/core/services';
import { combineLatest, filter, map, of, switchMap, takeUntil } from 'rxjs';

import { DebugService } from '../developer-tools/debug.service';

export class PermissionsDirectiveContext {
    public $implicit: Partial<IProgramSettingsDto> = null;
    public ng4hProgramSettings: Partial<IProgramSettingsDto> & { operator: 'and' | 'or' } = null;
    public operator: string;
}

@Directive({
    selector: '[ng4hProgramSettings]'
})
export class ProgramSettingsDirective extends AutoUnsubscribe {

    private context: PermissionsDirectiveContext = new PermissionsDirectiveContext();
    private thenViewRef: EmbeddedViewRef<any> | null;

    @Input('ng4hProgramSettings')
    set ng4hProgramSettings(programSettings: Partial<IProgramSettingsDto>) {
        this.context.$implicit = this.context.ng4hProgramSettings = {
            ...programSettings,
            operator: 'or'
        };
        this.thenViewRef = null;  // clear previous view if any.
        this.updateView();
    }

    constructor(
        private elRef: ElementRef,
        private thenTemplateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private routerService: RouterService,
        private programSettingsService: ProgramSettingsService,
        private cdr: ChangeDetectorRef,
        private debugService: DebugService,
        private renderer: Renderer2
    ) {
        super();
    }

    private updateView() {

        combineLatest([this.routerService.institutionId$, this.routerService.programId$]).pipe(
            filter(combined => combined.every(c => c != null)),
            switchMap(([institutionId, programId]) => {
                return this.programSettingsService.getProgramSettings({
                    institutionId,
                    programId
                });
            }),
            filter(programSettings => programSettings != null),
            map(programSettings => this.hasPermissions(this.context.ng4hProgramSettings, programSettings)),
            switchMap(hasPermissions => combineLatest([of(hasPermissions), this.debugService.debugMode])),
            takeUntil(this.autoUnsubscribe)
        ).subscribe(([hasPermissions, debugMode]) => {
            if (hasPermissions || debugMode) {
                this.thenViewRef = null;
                this.viewContainer.clear();
                if (this.thenTemplateRef) {
                    this.thenViewRef =
                        this.viewContainer.createEmbeddedView(this.thenTemplateRef, this.context);
                }

            } else {
                this.viewContainer.clear();
            }
            if (debugMode && this.elRef.nativeElement?.previousElementSibling?.style != null) {
                const div = this.renderer.createElement('div');
                div.innerHTML = JSON.stringify(this.context.ng4hProgramSettings);
                div.style.fontSize = '8pt';
                this.renderer.appendChild(this.elRef.nativeElement.previousElementSibling, div);
                this.elRef.nativeElement.previousElementSibling.style.background = 'red';
                this.elRef.nativeElement.previousElementSibling.style.border = '2px solid darkred';
                this.elRef.nativeElement.previousElementSibling.style.padding = '5px';
                this.elRef.nativeElement.previousElementSibling.style.margin = '2px';
            }
            this.cdr.markForCheck();
        });
    }

    private hasPermissions(requiredPermissions: Partial<IProgramSettingsDto> & { operator?: 'and' | 'or' }, programSettings: IProgramSettingsDto): boolean {

        const permissionKeys = Object.keys(requiredPermissions);
        if (permissionKeys == null || permissionKeys.length < 1) {
            return false;
        }
        if (requiredPermissions.operator == null) {
            return permissionKeys.every(permissionKey => programSettings[permissionKey] === requiredPermissions[permissionKey]);
        } else if (requiredPermissions.operator === 'or') {
            return permissionKeys.some(permissionKey => programSettings[permissionKey] === requiredPermissions[permissionKey]);
        }
    }
}
