import { IProgramYearDto } from '.';
import { eEnrollmentRoleType } from '..';

export interface ICountyCreditBillMemberDto {
    memberId: string;
    enrollmentId: string;
    invoiceDiscountId: string;
    programYear: IProgramYearDto;
    primaryClub?: string;
    enrollmentRole: eEnrollmentRoleType;
    registrationId: string;
    eventId: string;
    eventName?: string;
    firstName?: string;
    lastName?: string;
    amount: number;
    dateCollected: string;
    notes?: string;
}
