<div *ngFor="let keyvalue of fields | keyvalue: retainOrder">
  <ng-container *ngIf="anyVisible(keyvalue.value)">
    <h5 class="ng4h-bg-secondary ng4h-panel-header" *ngIf="showHeader">{{keyvalue.key}}</h5>
    <div class="ng4h-panel-body">
      <div class="row">
        <ng-container *ngFor="let input of keyvalue.value; let inputIndex = index">
          <ng-container *ngIf="input.inputState !== eStandardFieldStatus.Hidden">

            <div class="col-12" *ngIf="shouldBreakBefore(keyvalue.key, inputIndex)">
            </div>
            <ng4h-standard-form-input class="col-12" [ngClass]="{'highlight': input.highlight}" [input]="input"
              [form]="form" [sectionName]="keyvalue.key">
            </ng4h-standard-form-input>
            <div class="col-12" *ngIf="shouldBreakAfter(keyvalue.key, inputIndex)">
            </div>

          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>