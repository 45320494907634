<div class="modal-header">
    <h5 class="modal-title">Add Manager to Event</h5>
    <button (click)="close()" type="button" class="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="addManager" novalidate>
        <div class="form-group col-sm-12">
            <label class="form-control-label">Email or Name</label>
            <input class="form-control" [matAutocomplete]="auto" formControlName="email" type="email">
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let user of filteredUsers | async; let i = index" [value]="user.email"
                    style="height: 65px; line-height: 25px" (click)="managerSelected(user)">
                    <div>{{user.email}}</div>
                    <small>{{user.lastName}}, {{user.firstName}}</small>
                </mat-option>
            </mat-autocomplete>
        </div>
    </form>
    <form [formGroup]="permissionRoleForm">
        <div class="form-group col-12" *ngIf="managerPermissionRole$ | async as managerPermissionRole">
            <label class="form-control-label">Role</label>
            <select formControlName="permissionRole" class="form-control">
                <option *ngIf="managerPermissionRole === eAccessControlListRoleType.EventAdmin"
                    [value]="eAccessControlListRoleType.EventAdmin">Admin</option>
                <option
                    *ngIf="managerPermissionRole === eAccessControlListRoleType.EventAdmin || managerPermissionRole === eAccessControlListRoleType.EventStaff"
                    [value]="eAccessControlListRoleType.EventStaff">Staff</option>
                <option [value]="eAccessControlListRoleType.EventVolunteer">Volunteer</option>
            </select>
        </div>
    </form>
    <form [formGroup]="managerPermissions">
        <ng4h-permission-input formControlName="EventSetup" label="Event Setup"></ng4h-permission-input>
        <ng4h-permission-input formControlName="RegistrationManagement" label="Registration Management">
        </ng4h-permission-input>
        <ng4h-permission-input formControlName="EventManagers" label="Edit Event Managers"></ng4h-permission-input>
    </form>
</div>
<div class="modal-footer">
    <button [disabled]="selectedManager == null || managerPermissions.invalid || permissionRoleForm.invalid"
        (click)="save()" class="btn btn-primary my-2 my-sm-0" role="button" aria-pressed="true">Save</button>
</div>
