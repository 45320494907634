<b>Redirecting to Touchnet...</b>

<div class="container ">
    <div [hidden]="touchnetResponse == null || loading == true">
        <form #touchnetForm method="post" [action]="touchnetResponse.uPayLink" target="_self">
            <input type="hidden" name="UPAY_SITE_ID" [value]="touchnetResponse.uPaySiteId" />
            <input type="hidden" name="TICKET" [value]="touchnetResponse.ticket" />
            <input type="hidden" name="TICKET_NAME" [value]="touchnetResponse.ticketName" />
        </form>
    </div>
</div>