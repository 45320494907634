<div>
  <div class="modal-header">
    <h5 class="modal-title" id="enrollModalLabel">Enrollment</h5>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div *ngIf="loading === false; else spinner">
    <mat-vertical-stepper [linear]="true">
      <mat-step *ngIf="showRoleSelect === true" [stepControl]="selectARoleFormGroup">
        <form [formGroup]="selectARoleFormGroup">
          <ng-template matStepLabel>Select a Role</ng-template>
          <h4 class="section-title text-muted" style="margin-top: 0;">Are you registering as an adult or youth?</h4>
          <ul class="section-list list-unstyled" *ng4hVar="programSettings$ | async as programSettings">
            <input type="hidden" formControlName="role">
            <li (click)="roleSelected(eEnrollmentRoleType.ClubMember)" class="section-item col-12"
              [ngClass]="{'selected': selectARoleFormGroup.controls.role.value === eEnrollmentRoleType.ClubMember}">
              <strong>{{(eEnrollmentRoleType.ClubMember |
                programAlias$:institutionId:joinProgramFormGroup.controls['program'].value | async)?.singular}}</strong>
              <div>{{programSettings?.youthSelectionMessage}}</div>
            </li>
            <li (click)="roleSelected(eEnrollmentRoleType.Volunteer)" class="section-item col-12"
              [ngClass]="{'selected': selectARoleFormGroup.controls.role.value === eEnrollmentRoleType.Volunteer}">
              <strong>{{(eEnrollmentRoleType.Volunteer |
                programAlias$:institutionId:joinProgramFormGroup.controls['program'].value | async)?.singular}}</strong>
              <div>{{programSettings?.volunteerSelectionMessage}}</div>
            </li>
          </ul>
          <!-- <button
            *ngIf="selectARoleFormGroup.controls['role'].value === eEnrollmentRoleType.ClubMember || selectARoleFormGroup.controls['role'].value === eEnrollmentRoleType.CloverBud"
            class="btn btn-primary float-right" (click)="enroll()">Enroll</button> -->
          <button [disabled]="selectARoleFormGroup.controls['role'].value == null" class="btn btn-primary float-right"
            matStepperNext>Next</button>
        </form>
      </mat-step>
      <mat-step [stepControl]="youthGradeFormGroup"
        *ngIf="selectARoleFormGroup.controls['role'].value === eEnrollmentRoleType.ClubMember">
        <form [formGroup]="youthGradeFormGroup">
          <ng-template matStepLabel>School Grade</ng-template>
          <h4 class="section-title text-muted" style="margin-top: 0;">What school grade is this member in?</h4>
          <ul class="section-list list-unstyled">
            <li class="form-group full-width">
              <label class="form-control-label">Grade</label>
              <select class="form-control" formControlName="grade">
                <option [value]="option.key" *ngFor="let option of eSchoolGradeType | keyvalue: retainOrder">
                  {{option.value |
                  camelToTitleCase | replace:'_':''}}</option>
              </select>
            </li>
          </ul>
          <button class="btn btn-primary float-right" matStepperNext
            [disabled]="youthGradeFormGroup.invalid">Next</button>
          <button class="btn btn-secondary float-right" matStepperPrevious>Back</button>
        </form>
      </mat-step>
      <mat-step [stepControl]="selectARoleFormGroup">

        <ng-template matStepLabel>Confirm Enrollment</ng-template>

        <div *ngIf="error; else confirm">
          <h4 class="ng4h-danger">{{error}}</h4>
        </div>
        <ng-template #confirm>
          <h4>I'm confirming I want to enroll {{(data.memberId | member$ | async)?.preferredName}} in
            {{joinProgramFormGroup.controls['program'].value | program$ | async}} as a
            {{(selectARoleFormGroup.controls['role'].value |
            programAlias$:institutionId:joinProgramFormGroup.controls['program'].value | async)?.singular}}
          </h4>
        </ng-template>
        <button class="btn btn-primary float-right ng4h-margin-bottom ng4h-margin-left" [disabled]="error != null"
          (click)="enroll()" [disabled]="selectARoleFormGroup.invalid">Enroll</button>
        <button *ngIf="showRoleSelect === true" class="btn btn-secondary float-right" matStepperPrevious>Back</button>

      </mat-step>
    </mat-vertical-stepper>

  </div>
  <ng-template #spinner>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </ng-template>

</div>