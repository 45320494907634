import { Pipe, PipeTransform } from '@angular/core';
import { CustomReportsService, EventCustomReportsService, RouterService } from 'app/core/services';
import { map, Observable, of } from 'rxjs';

import { IReportColumnDto } from '../models';

@Pipe({
    name: 'customReportColumn$',
    pure: true
})
export class CustomReportColumnPipe implements PipeTransform {
    constructor(
        private customReportsService: CustomReportsService,
        private eventCustomReportService: EventCustomReportsService,
        private routerService: RouterService
    ) {
    }

    public transform(
        reportColumnId: string,
        institutionId: string = this.routerService.institutionId,
        managerId: string = this.routerService.managerId,
        programId: string = this.routerService.programId,
        eventId: string = this.routerService.eventId,
        customReportId: string = this.routerService.customReportId,
    ): Observable<IReportColumnDto> {
        if (reportColumnId == null) {
            return of(null);
        }
        if (eventId == null) {

            return this.customReportsService.getAvailableColumns({
                institutionId,
                managerId,
                programId,
                customReportId,
            }).pipe(map(columns => columns.find(column => column.columnId === reportColumnId)));
        } else {
            return this.eventCustomReportService.getAvailableColumns({
                institutionId,
                managerId,
                programId,
                eventId,
                customReportId,
            }).pipe(map(columns => columns.find(column => column.columnId === reportColumnId)));

        }
    }
}
