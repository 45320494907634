import { Action, createReducer, on } from '@ngrx/store';
import { IProgramYearDto } from 'app/core/models';

import { ProgramYearActions } from '.';
import { mergeImmutable } from '../utils';


export interface ProgramYearState {
  usersProgramYear: { [institutionId: string]: { [programId: string]: { [institutionProfileId: string]: IProgramYearDto } } };
  currentProgramYear: { [institutionId: string]: { [programId: string]: IProgramYearDto } };
  availableProgramYears: { [institutionId: string]: { [programId: string]: IProgramYearDto[] } };
}

const initialState: ProgramYearState = {
  usersProgramYear: undefined,
  currentProgramYear: undefined,
  availableProgramYears: undefined
};

const reducer = createReducer(
  initialState,
  on(ProgramYearActions.ProgramYearLoadAvailableSuccessAction, (state, { institutionId, programId, programYears }) => {
    const newAvailableProgramYearsByProgram = mergeImmutable(
      { [institutionId]: { [programId]: programYears } },
      state.availableProgramYears
    );
    return {
      ...state,
      availableProgramYears: newAvailableProgramYearsByProgram
    };
  }),
  on(ProgramYearActions.ProgramYearCurrentLoadSuccessAction, (state, { institutionId, programId, programYear }) => {
    const newProgramYearByProgram = mergeImmutable(
      { [institutionId]: { [programId]: programYear } },
      state.currentProgramYear
    );
    return {
      ...state,
      currentProgramYear: newProgramYearByProgram
    };
  }),
  on(ProgramYearActions.ProgramYearUsersSetAction, (state, { institutionId, programId, institutionProfileId, programYear }) => {
    const newUserProgramYears = mergeImmutable(
      { [institutionId]: { [programId]: { [institutionProfileId]: programYear } } },
      state.usersProgramYear
    );
    return {
      ...state,
      usersProgramYear: newUserProgramYears
    };
  })
);

export function programYearReducer(state: ProgramYearState | undefined, actions: Action) {
  return reducer(state, actions);
}

export const usersProgramYear = (state: ProgramYearState) => state.usersProgramYear;
export const availableProgramYears = (state: ProgramYearState) => state.availableProgramYears;
export const currentProgramYear = (state: ProgramYearState) => state.currentProgramYear;
