<div class="modal-header">
  <h5 class="modal-title">System Manager Permissions</h5>
  <button class="close" (click)="close()" type="button" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="row">
    <div class="col-12">
      <h4 class="general-header">{{ user.firstName }} {{ user.lastName }} - <span style="font-size: 90%">{{ user.email }}</span></h4>
    </div>
  </div>

  <form [formGroup]="form" class="col-12 row">
    <ng4h-permission-input *ngFor="let permissionType of permissionTypes" class="col-6" [formControlName]="permissionType.permission" [label]="permissionType.caption"></ng4h-permission-input>
  </form>
</div>
<div class="modal-footer">
  <button [disabled]="form.invalid" class="btn btn-primary my-2 my-sm-0" role="button" aria-pressed="true"
          (click)="save()">Save
  </button>
  <button class="btn btn-secondary" (click)="close()">Close</button>
</div>
