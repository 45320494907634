import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import {
  AppState,
  getEnrollment,
  getEnrollmentCustomFiles,
  getEnrollmentDynamicFormInputs,
  getEnrollmentVolunteerTypes
} from 'app/app.reducers';
import {
  IDynamicForm,
  IEnrollmentCreateDto,
  IEnrollmentDto,
  IEnrollmentFeeWaiverRequestSubmitDto,
  IEnrollmentFileControlDto,
  IEnrollmentProjectSubmissionDto,
  IEnrollmentSubmitDto,
  IEnrollmentUnitAdditionRequestCreateDto,
  IEnrollmentUnitChangeDto,
  IEnrollmentUnitDto,
  IEnrollmentUnitSubmissionDto,
  IEnrollmentVolunteerTypeSubmissionDto,
  IInstitutionVolunteerDto,
  IMemberConsentSubmitDto
} from 'app/core/models';
import { filter, map, Observable, of, switchMap, take, tap } from 'rxjs';

import { EnrollmentActions } from '../containers/member/enrollment';
import {
  IInstitutionMemberProgramEnrollmentParams,
  IInstitutionMemberProgramParams
} from '../models/function-parameters';
import { IEnrollmentUpdateDto } from '../models/serverDTOs/IEnrollmentUpdateDto';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnrollmentService {

  constructor(
    private store: Store<AppState>,
    private httpClient: HttpClient,
    private dispatcher: ActionsSubject
  ) {

  }

  public refreshEnrollment() {
    this.store.dispatch(EnrollmentActions.EnrollmentInvalidateCacheAction());

    return this.dispatcher.pipe(
      ofType(EnrollmentActions.EnrollmentLoadSuccessAction, EnrollmentActions.EnrollmentLoadErrorAction),
      take(1),
      map(action => {
        if (action.type === EnrollmentActions.EnrollmentLoadSuccessAction.type) {
          return action.enrollment;
        } else {
          throw action.error;
        }
      })
    );

  }

  public refreshDynamicQuestions() {
    this.store.dispatch(EnrollmentActions.EnrollmentInvalidateDynamicFormsCacheAction());

    return this.dispatcher.pipe(
      ofType(EnrollmentActions.EnrollmentLoadDynamicFormSuccessAction, EnrollmentActions.EnrollmentLoadDynamicFormErrorAction),
      take(1),
      map(action => {
        if (action.type === EnrollmentActions.EnrollmentLoadDynamicFormSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  // public refreshInvoice() {
  //   this.store.dispatch(EnrollmentActions.EnrollmentPayableInvoiceInvalidateCacheAction());

  //   return this.dispatcher.pipe(
  //     ofType(EnrollmentActions.EnrollmentLoadPayableInvoiceSuccessAction, EnrollmentActions.EnrollmentLoadPayableInvoiceErrorAction),
  //     take(1),
  //     map(action => {
  //       if (action.type === EnrollmentActions.EnrollmentLoadPayableInvoiceSuccessAction.type) {
  //         return action;
  //       } else {
  //         throw action.error;
  //       }
  //     })
  //   );
  // }

  public getEnrollment(params: IInstitutionMemberProgramEnrollmentParams): Observable<IEnrollmentDto> {

    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getEnrollment(params)),
      tap(enrollment => {
        if (enrollment === undefined) {
          this.store.dispatch(EnrollmentActions.EnrollmentLoadAction(params));
        }
      }),
      filter(enrollment => enrollment != null)
    );
  }

  public loadEnrollmentEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId
  }: IInstitutionMemberProgramEnrollmentParams): Observable<IEnrollmentDto> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}`) as Observable<IEnrollmentDto>;
  }

  public createEnrollment(params: IInstitutionMemberProgramParams & IEnrollmentCreateDto): Observable<IEnrollmentDto> {

    this.store.dispatch(EnrollmentActions.EnrollmentCreateAction(params));
    return this.dispatcher.pipe(
      ofType(EnrollmentActions.EnrollmentCreateSuccessAction, EnrollmentActions.EnrollmentCreateErrorAction),
      take(1),
      map(action => {
        if (action.type === EnrollmentActions.EnrollmentCreateSuccessAction.type) {
          return action.enrollment;
        } else {
          throw action.error;
        }
      })
    );
  }

  public createEnrollmentEffect({
    institutionId,
    memberId,
    programId,
    enrollmentRoleType,
    schoolGrade
  }: IInstitutionMemberProgramParams & IEnrollmentCreateDto): Observable<any> {
    return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments`,
      {
        enrollmentRoleType,
        schoolGrade
      },
      { observe: 'response' })
    .pipe(
      switchMap((res: HttpResponse<any>) => this.httpClient.get(res.headers.get('location')))
    ) as Observable<IEnrollmentDto>;
  }

  public getCustomFiles(params: IInstitutionMemberProgramEnrollmentParams): Observable<IEnrollmentFileControlDto[]> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getEnrollmentCustomFiles(params)),
      tap(invoice => {
        if (invoice === undefined) {
          this.store.dispatch(EnrollmentActions.EnrollmentLoadCustomFileAction(params));
        }
      }),
      map(customFiles => Array.isArray(customFiles) ? customFiles : [])
    );
  }

  public loadCustomFilesEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId
  }: IInstitutionMemberProgramEnrollmentParams) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/enrollment-file-controls`) as Observable<any[]>;
  }

  public uploadFile(params: IInstitutionMemberProgramEnrollmentParams & { fileControlId: string, formData: FormData }) {
    this.store.dispatch(EnrollmentActions.EnrollmentUploadFileAction(params));

    return this.dispatcher.pipe(
      ofType(EnrollmentActions.EnrollmentUploadFileSuccessAction, EnrollmentActions.EnrollmentUploadFileErrorAction),
      take(1),
      map(action => {
        if (action.type === EnrollmentActions.EnrollmentUploadFileSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public uploadMemberFileEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    fileControlId,
    formData
  }: IInstitutionMemberProgramEnrollmentParams & { fileControlId: string, formData: FormData }) {
    return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/enrollment-file-controls/${fileControlId}`, formData);
  }

  public deleteFile(params: IInstitutionMemberProgramEnrollmentParams & { fileControlId: string }) {
    this.store.dispatch(EnrollmentActions.EnrollmentDeleteFileAction(params));

    return this.dispatcher.pipe(
      ofType(EnrollmentActions.EnrollmentDeleteFileSuccessAction, EnrollmentActions.EnrollmentDeleteFileErrorAction),
      take(1),
      map(action => {
        if (action.type === EnrollmentActions.EnrollmentDeleteFileSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteMemberFileEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    fileControlId
  }: IInstitutionMemberProgramEnrollmentParams & { fileControlId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/enrollment-file-controls/${fileControlId}/file`);
  }

  public getVolunteerTypes(params: IInstitutionMemberProgramEnrollmentParams): Observable<IInstitutionVolunteerDto[]> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getEnrollmentVolunteerTypes(params)),
      tap(volunteerTypes => {
        if (volunteerTypes === undefined) {
          this.store.dispatch(EnrollmentActions.EnrollmentLoadAvailableVolunteerTypesAction(params));
        }
      }),
      filter(volunteerTypes => volunteerTypes != null)
    );
  }

  public getVolunteerTypesEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId
  }: IInstitutionMemberProgramEnrollmentParams) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/available-volunteer-types`) as Observable<IInstitutionVolunteerDto[]>;
  }

  public addVolunteerType(params: IInstitutionMemberProgramEnrollmentParams & { volunteerType: IInstitutionVolunteerDto }) {
    this.store.dispatch(EnrollmentActions.EnrollmentAddVolunteerTypesAction(params));
  }

  public removeVolunteerType(params: IInstitutionMemberProgramEnrollmentParams & { volunteerType: IInstitutionVolunteerDto }) {
    this.store.dispatch(EnrollmentActions.EnrollmentRemoveVolunteerTypesAction(params));
  }

  public saveVolunteerTypes(params: IInstitutionMemberProgramEnrollmentParams & { volunteerTypeSubmission: IEnrollmentVolunteerTypeSubmissionDto[] }) {
    this.store.dispatch(EnrollmentActions.EnrollmentSaveVolunteerTypesAction(params));

    return this.dispatcher.pipe(
      ofType(EnrollmentActions.EnrollmentSaveVolunteerTypesSuccessAction, EnrollmentActions.EnrollmentSaveVolunteerTypesErrorAction),
      take(1),
      map(action => {
        if (action.type === EnrollmentActions.EnrollmentSaveVolunteerTypesSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public saveVolunteerTypesEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    volunteerTypeSubmission
  }: IInstitutionMemberProgramEnrollmentParams & { volunteerTypeSubmission: IEnrollmentVolunteerTypeSubmissionDto[] }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/volunteer-types`, volunteerTypeSubmission);
  }

  public addClub(params: IInstitutionMemberProgramEnrollmentParams & { club: IEnrollmentUnitDto }) {
    this.store.dispatch(EnrollmentActions.EnrollmentAddClubAction(params));
  }

  public removeClub(params: IInstitutionMemberProgramEnrollmentParams & { unitId: string }) {
    this.store.dispatch(EnrollmentActions.EnrollmentRemoveClubAction(params));
    this.store.dispatch(EnrollmentActions.EnrollmentResetProjectsAction());
  }

  public primaryClubChange(params: IInstitutionMemberProgramEnrollmentParams & { club: IEnrollmentUnitDto }) {
    if (params.club.primary) {
      return;
    }
    this.store.dispatch(EnrollmentActions.EnrollmentChangePrimaryClubAction(params));
  }

  public saveClubs({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    clubs
  }: IInstitutionMemberProgramEnrollmentParams & { clubs: IEnrollmentUnitSubmissionDto[] }) {

    this.store.dispatch(EnrollmentActions.EnrollmentSaveClubsAction({
      institutionId,
      memberId,
      programId,
      enrollmentId,
      clubSubmission: clubs
    }));

    return this.dispatcher.pipe(
      ofType(EnrollmentActions.EnrollmentSaveClubsSuccessAction, EnrollmentActions.EnrollmentSaveClubsErrorAction),
      take(1),
      map(action => {
        if (action.type === EnrollmentActions.EnrollmentSaveClubsSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public saveClubsEffect(clubSubmission: IEnrollmentUnitSubmissionDto[], institutionId: string, memberId: string, programId: string, enrollmentId: string) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/units`, clubSubmission);
  }

  public saveProject(params: IInstitutionMemberProgramEnrollmentParams & { projectSubmission: IEnrollmentProjectSubmissionDto }) {
    this.store.dispatch(EnrollmentActions.EnrollmentSaveProjectAction(params));

    return this.dispatcher.pipe(
      ofType(EnrollmentActions.EnrollmentSaveProjectSuccessAction, EnrollmentActions.EnrollmentSaveProjectErrorAction),
      take(1),
      map(action => {
        if (action.type === EnrollmentActions.EnrollmentSaveProjectSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public saveProjectEffect(projectsSubmission: IEnrollmentProjectSubmissionDto, institutionId: string, memberId: string, programId: string, enrollmentId: string) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/projects`, projectsSubmission);
  }

  public deleteProject(params: IInstitutionMemberProgramEnrollmentParams & { projectId: string }) {
    this.store.dispatch(EnrollmentActions.EnrollmentDeleteProjectAction(params));

    return this.dispatcher.pipe(
      ofType(EnrollmentActions.EnrollmentDeleteProjectSuccessAction, EnrollmentActions.EnrollmentDeleteProjectErrorAction),
      take(1),
      map(action => {
        if (action.type === EnrollmentActions.EnrollmentDeleteProjectSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteProjectEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    projectId
  }: IInstitutionMemberProgramEnrollmentParams & { projectId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/projects/${projectId}`);
  }

  public getDynamicForm(params: IInstitutionMemberProgramEnrollmentParams): Observable<IDynamicForm> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getEnrollmentDynamicFormInputs(params)),
      tap(invoice => {
        if (invoice === undefined) {
          this.store.dispatch(EnrollmentActions.EnrollmentLoadDynamicFormAction(params));
        }
      }),
      filter(enrollment => enrollment != null)
    );
  }

  public loadDynamicFormEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId
  }: IInstitutionMemberProgramEnrollmentParams): Observable<IDynamicForm> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/enrollment-form`) as Observable<IDynamicForm>;
  }

  public saveDynamicFormResponses(params: IInstitutionMemberProgramEnrollmentParams & { responses: any }) {
    this.store.dispatch(EnrollmentActions.EnrollmentSaveDynamicFormResponsesAction(params));

    return this.dispatcher.pipe(
      ofType(EnrollmentActions.EnrollmentSaveDynamicFormResponsesSuccessAction, EnrollmentActions.EnrollmentSaveDynamicFormResponsesErrorAction),
      take(1),
      map(action => {
        if (action.type === EnrollmentActions.EnrollmentSaveDynamicFormResponsesSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public saveDynamicFormResponsesEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    responses
  }: IInstitutionMemberProgramEnrollmentParams & { responses: any }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/enrollment-form/responses`, responses);
  }

  public saveDemographicsResponses(params: IInstitutionMemberProgramEnrollmentParams & { responses: any }) {
    this.store.dispatch(EnrollmentActions.EnrollmentSaveDemographicsFormResponsesAction(params));

    return this.dispatcher.pipe(
      ofType(EnrollmentActions.EnrollmentSaveDemographicsFormResponsesSuccessAction, EnrollmentActions.EnrollmentSaveDemographicsFormResponsesErrorAction),
      take(1),
      map(action => {
        if (action.type === EnrollmentActions.EnrollmentSaveDemographicsFormResponsesSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public saveDemographicsResponsesEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    responses
  }: IInstitutionMemberProgramEnrollmentParams & { responses: any }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/demographics`, responses);
  }

  public saveDemographics({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    demographics,
    update
  }: IInstitutionMemberProgramEnrollmentParams & { demographics: any, update?: Partial<IEnrollmentUpdateDto> }) {

    const enrollmentUpdate: IEnrollmentUpdateDto = {
      militaryService: demographics.militaryService,
      militaryBranch: demographics.militaryBranch,
      militaryComponent: demographics.militaryComponent,
      schoolId: demographics.schoolId == null ? null : demographics.schoolId,

      schoolName: demographics.schoolName == null ? null : demographics.schoolName,
      schoolType: demographics.schoolType == null ? null : demographics.schoolType,

      schoolGrade: demographics.schoolGrade,
      demographics: {
        birthGender: demographics.birthGender,
        identifyingGender: demographics.identifyingGender,
        ethnicity: demographics.ethnicity,
        residence: demographics.residence,
        racialBreakdownWhite: demographics.racialBreakdownWhite,
        racialBreakdownBlack: demographics.racialBreakdownBlack,
        racialBreakdownAmericanIndianOrAlaskanNative: demographics.racialBreakdownAmericanIndianOrAlaskanNative,
        racialBreakdownNativeHawaiianOrPacificIslander: demographics.racialBreakdownNativeHawaiianOrPacificIslander,
        racialBreakdownAsian: demographics.racialBreakdownAsian,
        racialBreakdownBalanceOfOtherCombinations: demographics.racialBreakdownBalanceOfOtherCombinations,
        racialBreakdownUndetermined: demographics.racialBreakdownUndetermined
      },
      ...demographics.guardian,
      ...demographics.emergencyContact,
      ...demographics.address,
      ...demographics.secondFamily,
      ...update
    };

    this.store.dispatch(EnrollmentActions.EnrollmentSaveDemographicsAction({
      institutionId,
      memberId,
      programId,
      enrollmentId,
      enrollmentUpdate
    }));

    return this.dispatcher.pipe(
      ofType(EnrollmentActions.EnrollmentSaveDemographicsSuccessAction, EnrollmentActions.EnrollmentSaveDemographicsErrorAction),
      take(1),
      map(action => {
        if (action.type === EnrollmentActions.EnrollmentSaveDemographicsSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public saveDemographicsEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    enrollmentUpdate
  }: IInstitutionMemberProgramEnrollmentParams & { enrollmentUpdate: IEnrollmentUpdateDto }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}`, enrollmentUpdate) as Observable<any>;
  }

  public saveEnrollmentConsents(params: IInstitutionMemberProgramEnrollmentParams & { consent: IMemberConsentSubmitDto[] }) {
    this.store.dispatch(EnrollmentActions.EnrollmentSaveConsentsAction(params));

    return this.dispatcher.pipe(
      ofType(EnrollmentActions.EnrollmentSaveConsentsSuccessAction, EnrollmentActions.EnrollmentSaveConsentsErrorAction),
      take(1),
      map(action => {
        if (action.type === EnrollmentActions.EnrollmentSaveConsentsSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public saveEnrollmentConsentsEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    consent
  }: IInstitutionMemberProgramEnrollmentParams & { consent: IMemberConsentSubmitDto[] }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/consents`, consent);
  }

  // public getPayableInvoice(params: IInstitutionMemberProgramEnrollmentParams): Observable<IPayableInvoiceDto> {
  //   if (Object.keys(params).find(key => params[key] == null) != null) {
  //     return of(null);
  //   }
  //   return this.store.pipe(
  //     select(getEnrollmentPayableInvoice(params)),
  //     tap(invoice => {
  //       if (invoice === undefined) {
  //         this.store.dispatch(EnrollmentActions.EnrollmentLoadPayableInvoiceAction(params));
  //       }
  //     })
  //   );
  // }

  // // Get all outstanding invoices for the provided enrollment
  // public loadInvoiceEffect({ institutionId, memberId, programId, enrollmentId }: IInstitutionMemberProgramEnrollmentParams): Observable<IPayableInvoiceDto> {
  //   return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/invoices/payable`).pipe(
  //     // map(invoice => invoice === null ? {} : invoice)
  //   ) as Observable<IPayableInvoiceDto>;
  // }

  public applyPayment(params: IInstitutionMemberProgramEnrollmentParams & { invoiceId: string, paymentMethodId: string, memberProgramConsentSubmitDto: IMemberConsentSubmitDto }) {
    this.store.dispatch(EnrollmentActions.EnrollmentApplyPaymentAction(params));

    return this.dispatcher.pipe(
      ofType(EnrollmentActions.EnrollmentApplyPaymentSuccessAction, EnrollmentActions.EnrollmentApplyPaymentErrorAction),
      take(1),
      map(action => {
        if (action.type === EnrollmentActions.EnrollmentApplyPaymentSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public applyPaymentEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    invoiceId,
    paymentMethodId,
    memberProgramConsentSubmitDto
  }: IInstitutionMemberProgramEnrollmentParams & { invoiceId: string, paymentMethodId: string, memberProgramConsentSubmitDto: IMemberConsentSubmitDto }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/invoices/${invoiceId}?paymentMethodId=${paymentMethodId}`, memberProgramConsentSubmitDto);
  }

  public applyAdditionalPayment(params: IInstitutionMemberProgramEnrollmentParams & { invoiceId: string, paymentMethodId: string, paymentConsentResponse: IMemberConsentSubmitDto }) {
    this.store.dispatch(EnrollmentActions.EnrollmentApplyAdditionalPaymentAction(params));

    return this.dispatcher.pipe(
      ofType(EnrollmentActions.EnrollmentApplyAdditionalPaymentSuccessAction, EnrollmentActions.EnrollmentApplyAdditionalPaymentErrorAction),
      take(1),
      map(action => {
        if (action.type === EnrollmentActions.EnrollmentApplyAdditionalPaymentSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public applyAdditionalPaymentEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    invoiceId,
    paymentMethodId,
    paymentConsentResponse
  }: IInstitutionMemberProgramEnrollmentParams & { invoiceId: string, paymentMethodId: string, paymentConsentResponse: IMemberConsentSubmitDto }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/invoices/${invoiceId}/additional-payment?paymentMethodId=${paymentMethodId}`, paymentConsentResponse);
  }

  public applyCouponCode(params: IInstitutionMemberProgramEnrollmentParams & { couponCode: string }) {
    this.store.dispatch(EnrollmentActions.EnrollmentApplyCouponCodeAction(params));

    return this.dispatcher.pipe(
      ofType(EnrollmentActions.EnrollmentApplyCouponCodeSuccessAction, EnrollmentActions.EnrollmentApplyCouponCodeErrorAction),
      take(1),
      map(action => {
        if (action.type === EnrollmentActions.EnrollmentApplyCouponCodeSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public applyCouponCodeEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    couponCode
  }: IInstitutionMemberProgramEnrollmentParams & { couponCode: string }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/invoices/apply-coupon?couponCode=${couponCode}`, {});
  }

  public requestFeeWaiver(params: IInstitutionMemberProgramEnrollmentParams & { submitDto: IEnrollmentFeeWaiverRequestSubmitDto }) {
    this.store.dispatch(EnrollmentActions.EnrollmentRequestFeeWaiverAction(params));

    return this.dispatcher.pipe(
      ofType(EnrollmentActions.EnrollmentRequestFeeWaiverSuccessAction, EnrollmentActions.EnrollmentRequestFeeWaiverErrorAction),
      take(1),
      map(action => {
        if (action.type === EnrollmentActions.EnrollmentRequestFeeWaiverSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public requestFeeWaiverEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    submitDto
  }: IInstitutionMemberProgramEnrollmentParams & { submitDto: IEnrollmentFeeWaiverRequestSubmitDto }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/request-fee-waiver`, submitDto);
  }

  public applyCredit(params: IInstitutionMemberProgramEnrollmentParams & { consent: IMemberConsentSubmitDto }) {
    this.store.dispatch(EnrollmentActions.EnrollmentApplyCreditAction(params));

    return this.dispatcher.pipe(
      ofType(EnrollmentActions.EnrollmentApplyCreditSuccessAction, EnrollmentActions.EnrollmentApplyCreditErrorAction),
      take(1),
      map(action => {
        if (action.type === EnrollmentActions.EnrollmentApplyCreditSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public applyCreditEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    consent
  }: IInstitutionMemberProgramEnrollmentParams & { consent: IMemberConsentSubmitDto }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/invoices/apply-credit`, consent);
  }

  public submitEnrollment(payload: IInstitutionMemberProgramEnrollmentParams & { submitDto: IEnrollmentSubmitDto }) {
    this.store.dispatch(EnrollmentActions.EnrollmentSubmitAction(payload));
    return this.dispatcher.pipe(
      ofType(EnrollmentActions.EnrollmentSubmitSuccessAction, EnrollmentActions.EnrollmentSubmitErrorAction),
      take(1),
      map(action => {
        if (action.type === EnrollmentActions.EnrollmentSubmitSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public submitEnrollmentEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    submitDto
  }: IInstitutionMemberProgramEnrollmentParams & { submitDto: IEnrollmentSubmitDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/submit`, submitDto);
  }

  public deleteEnrollment(params: IInstitutionMemberProgramEnrollmentParams) {
    this.store.dispatch(EnrollmentActions.DeleteEnrollmentAction(params));
    return this.dispatcher.pipe(
      ofType(EnrollmentActions.DeleteEnrollmentSuccessAction, EnrollmentActions.DeleteEnrollmentErrorAction),
      take(1),
      map(action => {
        if (action.type === EnrollmentActions.DeleteEnrollmentSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteEnrollmentEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId
  }: IInstitutionMemberProgramEnrollmentParams) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}`);
  }

  public swapUnits(params: IInstitutionMemberProgramEnrollmentParams & { unitChange: IEnrollmentUnitChangeDto }) {
    this.store.dispatch(EnrollmentActions.SwapUnitsAction(params));

    return this.dispatcher.pipe(
      ofType(EnrollmentActions.SwapUnitsSuccessAction, EnrollmentActions.SwapUnitsErrorAction),
      take(1),
      map(action => {
        if (action.type === EnrollmentActions.SwapUnitsSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public swapUnitsEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    unitChange
  }: IInstitutionMemberProgramEnrollmentParams & { unitChange: IEnrollmentUnitChangeDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/swap-units`, unitChange);
  }

  public requestAddUnit(params: IInstitutionMemberProgramEnrollmentParams & { unitRequest: IEnrollmentUnitAdditionRequestCreateDto }) {
    this.store.dispatch(EnrollmentActions.RequestAddUnitAction(params));

    return this.dispatcher.pipe(
      ofType(EnrollmentActions.RequestAddUnitSuccessAction, EnrollmentActions.RequestAddUnitErrorAction),
      take(1),
      map(action => {
        if (action.type === EnrollmentActions.RequestAddUnitSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public requestAddUnitEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    unitRequest
  }: IInstitutionMemberProgramEnrollmentParams & { unitRequest: IEnrollmentUnitAdditionRequestCreateDto }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/unit-add-requests`, unitRequest);
  }
}
