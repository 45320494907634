import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AutoUnsubscribe, IFreeSearchResultDto } from 'app/core/models';
import { eFreeSearchResultPayloadType } from 'app/core/models/enums/eFreeSearchResultPayloadType';
import { HierarchyService, MemberService, ProfileService, RouterService } from 'app/core/services';
import { GlobalSearchService } from 'app/core/services/global-search.service';
import { debounceTime, Observable, of, switchMap, take, tap } from 'rxjs';

@Component({
  selector: 'ng4h-modal-global-search',
  templateUrl: './modal-global-search.component.html',
  styleUrls: ['./modal-global-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalGlobalSearchComponent extends AutoUnsubscribe implements OnInit {

  public form: FormGroup;
  public searchResults$: Observable<IFreeSearchResultDto[]>;
  public eFreeSearchResultPayloadType = eFreeSearchResultPayloadType;
  public selectedIndex = 0;
  public loading = false;
  constructor(private routerService: RouterService, private fb: FormBuilder, private globalSearchService: GlobalSearchService, private profileService: ProfileService, private memberService: MemberService,
    private dialogRef: MatDialogRef<ModalGlobalSearchComponent>, private hierarchyService: HierarchyService) {
    super();
    this.form = this.fb.group({
      searchText: ['']
    });

    this.searchResults$ = this.form.controls['searchText'].valueChanges.pipe(
      debounceTime(500),
      switchMap(searchText => {
        if (searchText == null || searchText.length < 1) {
          return of([]);
        }
        this.loading = true;
        return this.hierarchyService.selectedLeafNode$.pipe(take(1), switchMap(leaf => {
          return this.globalSearchService.globalSearch({ institutionId: this.routerService.institutionId, managerId: this.routerService.managerId, programId: this.routerService.programId, hierarchyNodeId: leaf.hierarchyNodeId, searchTerm: searchText });
        }));
      }),
      tap(() => this.loading = false)
    );
  }

  public view(selectedResult: IFreeSearchResultDto) {
    switch (selectedResult.type) {
      case eFreeSearchResultPayloadType.Family: {
        const familyId = selectedResult.data.familyId;
        this.routerService.Go([this.routerService.userAccountId, this.routerService.institutionId, this.routerService.loggedInInstitutionProfileId, 'family', familyId]);
        break;
      }
      case eFreeSearchResultPayloadType.Member: {
        const memberId = selectedResult.data.memberId;

        this.routerService.Go([this.routerService.userAccountId, this.routerService.institutionId, this.routerService.loggedInInstitutionProfileId, 'member', memberId, this.routerService.programId, 'dashboard']);
        break;
      }
    }
    this.close();
  }

  ngOnInit() {
  }

  public close() {
    this.dialogRef.close();
  }

}
