import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  AutoUnsubscribe,
  eHierarchyNodeType,
  IHierarchyAny,
  IHierarchyCountyArea,
  IHierarchyDistrictArea,
  IHierarchyInstitution,
  IHierarchyRegionArea,
} from 'app/core/models';
import { HierarchyService, ModalService, RouterService } from 'app/core/services';
import { filterArrayOfNulls } from 'app/shared/rxjs.utils';
import { combineLatest, filter, map, Observable, switchMap, takeUntil } from 'rxjs';

import { ModalChangeHierarchyComponent } from '../modal-change-hierarchy/modal-change-hierarchy.component';

@Component({
  selector: 'ng4h-hierarchy-path',
  templateUrl: './hierarchy-path.component.html',
  styleUrls: ['./hierarchy-path.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HierarchyPathComponent extends AutoUnsubscribe implements OnInit {


  public eHierarchyNodeType = eHierarchyNodeType;


  public selectedInstitution$: Observable<IHierarchyInstitution>;
  public selectedRegionArea$: Observable<IHierarchyRegionArea>;
  public selectedDistrictArea$: Observable<IHierarchyDistrictArea>;
  public selectedCountyArea$: Observable<IHierarchyCountyArea>;


  public leaf$: Observable<IHierarchyAny>;

  constructor(private hierarchyService: HierarchyService, private modalService: ModalService, private routerService: RouterService) {
    super();

    this.leaf$ = this.hierarchyService.selectedLeafNode$.pipe(takeUntil(this.autoUnsubscribe));
    const hierarchyNodeAndParents$ = combineLatest([this.routerService.institutionId$, this.routerService.managerHierarchyId$]).pipe(
      filterArrayOfNulls(),
      switchMap(combined => this.hierarchyService.getHierarchyNodeAndParents({ institutionId: combined[0], hierarchyNodeId: combined[1] })),
      filter(nodes => nodes != null),
      takeUntil(this.autoUnsubscribe)
    );

    this.selectedInstitution$ = hierarchyNodeAndParents$.pipe(
      map(nodes => {
        return nodes[eHierarchyNodeType.Institution] as IHierarchyInstitution;
      })
    );
    this.selectedRegionArea$ = hierarchyNodeAndParents$.pipe(
      map(nodes => {
        return nodes[eHierarchyNodeType.RegionArea] as IHierarchyRegionArea;
      })
    );
    this.selectedDistrictArea$ = hierarchyNodeAndParents$.pipe(
      map(nodes => {
        return nodes[eHierarchyNodeType.DistrictArea] as IHierarchyDistrictArea;
      })
    );
    this.selectedCountyArea$ = hierarchyNodeAndParents$.pipe(
      map(nodes => {
        return nodes[eHierarchyNodeType.CountyArea] as IHierarchyCountyArea;
      })
    );

  }

  public changeHierarchy() {
    this.modalService.openModal(ModalChangeHierarchyComponent);
  }

  ngOnInit() {
  }

}
