<div class="modal-header">
    <h5 class="modal-title">Reorder Files</h5>
    <button (click)="close()" type="button" class="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div cdkDropList (cdkDropListDropped)="drop($event)">
        <ng4h-reorder-list *ngFor="let file of customFiles" cdkDrag>
            {{file.fileControlName}}
        </ng4h-reorder-list>
    </div>
</div>
<div class="modal-footer">
    <button class="btn btn-secondary float-right" (click)="close()">Cancel</button>
    <button class="btn btn-primary float-right" (click)="save()">Save</button>
</div>
