import { eHierarchyNodeType, eProfileType } from '.';
import { IInstitutionProfile, IManagerProgramHierarchyForDisplayDto } from './serverDTOs';
import { IAddressDto } from './serverDTOs/IAddressDto';

export interface IManagerInstitutionProfile extends IInstitutionProfile {
    managerId: string;
    profileType: eProfileType.Manager;
    // multiple because permissions are on hierarchy node (institution, county), not 1:1 per program
    managerPrograms: { [programId: string]: IManagerProgramHierarchyForDisplayDto[] };
    managerProgramsPrimaryHierarchy: { [programId: string]: eHierarchyNodeType };
    address?: IAddressDto;
    firstName?: string;
    lastName?: string;
    sitePassword?: string;
    apiKey: string;
}
