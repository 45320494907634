import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { eCountryCode, eUnitType, IUnitCreateDto, IUnitDto } from 'app/core/models';
import {
  CommonToastrService,
  ManagerClubsService,
  ProfileService,
  ProgramYearService,
  RouterService,
} from 'app/core/services';
import { filter, map, Observable, switchMap, take } from 'rxjs';

@Component({
  selector: 'ng4h-modal-add-club',
  templateUrl: './modal-add-club.component.html',
  styleUrls: ['./modal-add-club.component.scss']
})

export class ModalAddClubComponent implements OnInit {

  public eUnitType = eUnitType;
  public form: FormGroup;

  public contactAddress: FormGroup;
  public meetingAddress: FormGroup;

  public managerHierarchyId$: Observable<string>;

  constructor(private dialogRef: MatDialogRef<any>,
    private fb: FormBuilder, private routerService: RouterService,
    private clubsService: ManagerClubsService, private profileService: ProfileService,
    private programYearService: ProgramYearService, private toastrService: CommonToastrService) {
    this.managerHierarchyId$ = this.routerService.managerHierarchyId$;
  }

  ngOnInit() {
    this.contactAddress = this.fb.group({
      addressLine1: [null],
      addressLine2: [null],
      city: [null],
      state: [null],
      postalCode: [null],
      country: [eCountryCode['United States of America']]
    });
    this.meetingAddress = this.fb.group({
      addressLine1: [null],
      addressLine2: [null],
      city: [null],
      state: [null],
      postalCode: [null],
      country: [eCountryCode['United States of America']]
    });

    this.form = this.fb.group({
      // isClub: [true],
      // chargeFee: [false],
      unitName: [null, Validators.required],
      ein: [null],
      countyAreaId: [null, Validators.required],
      deliveryModeId: [null],
      meetingLocation: [null]
    });
  }

  public add(): void {
    const newClub: IUnitCreateDto = {
      ...this.form.value,
      contactAddress: this.contactAddress.value,
      meetingAddress: this.meetingAddress.value
    };

    this.profileService.loggedInAsInstitutionProfile.pipe(
      map(ap => ap.institutionProfileId),

      switchMap(institutionProfileId => {
        return this.programYearService.getUsersProgramYear({
          institutionId: this.routerService.institutionId,
          programId: this.routerService.programId,
          institutionProfileId
        }).pipe(filter(py => py != null));
      }),
      take(1),
      switchMap(programYear => {
        return this.clubsService.createClub({
          institutionId: this.routerService.institutionId,
          managerId: this.routerService.managerId,
          programId: this.routerService.programId,
          programYearId: programYear.programYearId,
          newClub
        });
      }),
      take(1)
    ).subscribe({
      next: (club: IUnitDto) => {
        this.toastrService.success('create club success');
        this.close(club);
      }, error: (error) => {
        this.toastrService.error('create club failed');
      }
    });
  }

  public close(club?: IUnitDto): void {
    this.dialogRef.close();
  }
}
