import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import {
  eAdultVolunteerType,
  eEnrollmentRoleType,
  eYouthVolunteerType,
  IEnrollmentDto,
  IInstitutionVolunteerDto,
} from 'app/core/models';
import { EnrollmentService, ModalService, RouterService } from 'app/core/services';
import { combineLatest, map, Observable, startWith } from 'rxjs';

import {
  ModalEnrollmentVolunteerTypesConsentComponent,
} from '../modal-enrollment-volunteer-types-consent/modal-enrollment-volunteer-types-consent.component';

@Component({
  selector: 'ng4h-modal-add-enrollment-volunteer-types',
  templateUrl: './modal-add-enrollment-volunteer-types.component.html',
  styleUrls: ['./modal-add-enrollment-volunteer-types.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalAddEnrollmentVolunteerTypesComponent implements OnInit {

  public eEnrollmentRoleType = eEnrollmentRoleType;
  public eAdultVolunteerType = eAdultVolunteerType;
  public eYouthVolunteerType = eYouthVolunteerType;
  public selectedVolunteerTypes$: Observable<IInstitutionVolunteerDto[]>;
  public volunteerCategories$: Observable<IInstitutionVolunteerDto[]>;

  public remainingVolunteerTypes$: Observable<IInstitutionVolunteerDto[]>;
  public form: FormGroup;

  public enrollment$: Observable<IEnrollmentDto>;

  public availableVolunteerTypes$: Observable<eAdultVolunteerType[]>;

  constructor(private dialogRef: MatDialogRef<any>, private routerService: RouterService, private modalService: ModalService, private fb: FormBuilder, private enrollmentService: EnrollmentService) {

    this.enrollment$ = this.enrollmentService.getEnrollment({
      institutionId: this.routerService.institutionId,
      programId: this.routerService.programId,
      memberId: this.routerService.memberId ? this.routerService.memberId : this.routerService.selectedMemberId,
      enrollmentId: this.routerService.enrollmentId
    });
    this.form = this.fb.group({
      volunteerType: [null, Validators.required]
    });

    this.volunteerCategories$ = this.enrollmentService.getVolunteerTypes({
      institutionId: this.routerService.institutionId,
      programId: this.routerService.programId,
      memberId: this.routerService.memberId ? this.routerService.memberId : this.routerService.selectedMemberId,
      enrollmentId: this.routerService.enrollmentId
    });


    this.availableVolunteerTypes$ = this.volunteerCategories$.pipe(
      map(volunteerCategories => {
        const vts = [];
        volunteerCategories.map(vc => vc.volunteerTypeDescriptor.volunteerType).forEach(vt => {
          if (!vts.includes(vt)) {
            vts.push(vt);
          }
        });
        return vts;
      })
    );

    this.selectedVolunteerTypes$ = this.enrollment$.pipe(
      map(enrollment => enrollment.volunteerTypes)
    );

    this.remainingVolunteerTypes$ = combineLatest([this.volunteerCategories$, this.selectedVolunteerTypes$, this.form.controls.volunteerType.valueChanges.pipe(startWith(null))]).pipe(
      map(([volunteerCategories, selectedVolunteerTypes, volunteerType]) => {
        return volunteerCategories.filter(volunteerCategory => {
          return selectedVolunteerTypes.find(selectedVolunteerType => selectedVolunteerType.volunteerTypeId === volunteerCategory.volunteerTypeId) == null && volunteerCategory.volunteerTypeDescriptor.volunteerType === volunteerType;
        });
      })
    );

  }

  ngOnInit() {
  }

  public close() {
    this.dialogRef.close();
  }

  public addVolunteerType(volunteerType: IInstitutionVolunteerDto) {
    if (volunteerType.consents && volunteerType.consents.length > 0) {

      this.close();
      this.modalService.openModal(ModalEnrollmentVolunteerTypesConsentComponent, { data: { volunteerType } });
    } else {
      this.enrollmentService.addVolunteerType({
        institutionId: this.routerService.institutionId,
        programId: this.routerService.programId,
        memberId: this.routerService.memberId ? this.routerService.memberId : this.routerService.selectedMemberId,
        enrollmentId: this.routerService.enrollmentId,
        volunteerType
      });
      this.close();
    }


  }

}
