import { Component, EventEmitter, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AutoUnsubscribe, IHierarchyCounty } from 'app/core/models';
import { CountyService, RouterService } from 'app/core/services';
import { Observable, takeUntil } from 'rxjs';

@Component({
  selector: 'ng4h-county-select',
  templateUrl: './county-select.component.html',
  styleUrls: ['./county-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CountySelectComponent),
    multi: true
  }]
})
export class CountySelectComponent extends AutoUnsubscribe implements OnInit, ControlValueAccessor {


  @Input() programId: string;
  @Input() reset: EventEmitter<void>;
  @Input() disabled: boolean;
  public countyId: string;

  public counties$: Observable<IHierarchyCounty[]>;

  // START ControlValueAccessor
  onChange: any = () => { };
  onTouched: any = () => { };

  writeValue(countyId: string): void {
    if (countyId != null) {
      this.countyId = countyId;
      this.onChange(countyId);
      this.onTouched();
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
  // END ControlValueAccessor
  constructor(private countyService: CountyService, private routerService: RouterService) {
    super();
    if (this.reset != null) {
      this.reset.pipe(takeUntil(this.autoUnsubscribe)).subscribe(() => this.countyId = '');
    }
  }

  ngOnInit() {
    this.counties$ = this.countyService.getCounties({ institutionId: this.routerService.institutionId, programId: this.routerService.programId });
  }
}
