export enum eCharterFileType {
  BankStatement = 'BankStatement',
  Budget = 'Budget',
  Bylaws = 'Bylaws',
  CharterApplication = 'CharterApplication',
  CharterLetter = 'CharterLetter',
  CharterPacket = 'CharterPacket',
  EINVerificationLetter = 'EINVerificationLetter',
  IRS990Acceptance = 'IRS990Acceptance'
}
