import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FamilyActions } from 'app/core/containers/family';
import { MemberActions } from 'app/core/containers/member';
import { CommonToastrService, EventsMemberService } from 'app/core/services';
import { catchError, exhaustMap, groupBy, map, mergeMap, of, tap } from 'rxjs';

import { EventMemberActions } from '.';
import { EventsManagerActions } from '../events-manager';
import { stringKeys } from '../rxjs.utils';

@Injectable()
export class EventsMemberEffects {

    public getEvent$ = createEffect(() => this.actions.pipe(
        ofType(EventMemberActions.EventMemberLoadAction),
        groupBy(stringKeys),
        mergeMap(groupedBy => {
            return groupedBy.pipe(
                exhaustMap(payload => {
                    return this.eventsService.loadEventEffect(payload).pipe(
                        map(event => EventMemberActions.EventMemberLoadSuccessAction({ ...payload, event })),
                        catchError(error => of(EventMemberActions.EventMemberLoadErrorAction({ error })))
                    );
                })
            );
        })
    ));


    public getEvents$t$ = createEffect(() => this.actions.pipe(
        ofType(EventMemberActions.EventsMemberLoadAction),
        groupBy(stringKeys),
        mergeMap(groupedBy => {
            return groupedBy.pipe(
                exhaustMap(payload => {
                    return this.eventsService.loadEventsEffect(payload).pipe(
                        map(events => {
                            return events == null ? [] : events;
                        }),
                        map(events => EventMemberActions.EventsMemberLoadSuccessAction({ ...payload, events })),
                        catchError(error => of(EventMemberActions.EventsMemberLoadErrorAction({ error })))
                    );
                })
            );
        })
    ));

    public getEventRegistration$t$ = createEffect(() => this.actions.pipe(
        ofType(EventMemberActions.EventsMemberLoadRegistrationAction),
        groupBy(stringKeys),
        mergeMap(groupedBy => {
            return groupedBy.pipe(
                exhaustMap(payload => {
                    return this.eventsService.loadEventRegistrationEffect(payload).pipe(
                        map(eventRegistration => EventMemberActions.EventsMemberLoadRegistrationSuccessAction({ ...payload, eventRegistration: eventRegistration })),
                        catchError(error => of(EventMemberActions.EventsMemberLoadRegistrationErrorAction({ error })))
                    );
                })
            );
        }),
    ));

    public registrationLoadError$ = createEffect(() => this.actions.pipe(
        ofType(EventMemberActions.EventsMemberLoadRegistrationErrorAction),
        tap(error => {
            this.toastrService.error('Error Loading Event.', error.error);
        })
    ), { dispatch: false });

    public registerForEvent$t$ = createEffect(() => this.actions.pipe(
        ofType(EventMemberActions.EventsMemberRegisterForEventAction),
        groupBy(stringKeys),
        mergeMap(groupedBy => {
            return groupedBy.pipe(
                exhaustMap(payload => {
                    return this.eventsService.registerForEventEffect(payload).pipe(
                        map((eventRegistration) => EventMemberActions.EventsMemberRegisterForEventSuccessAction({ institutionId: payload.institutionId, memberId: payload.memberId, programId: payload.programId, eventRegistration: eventRegistration })),
                        catchError(error => of(EventMemberActions.EventsMemberRegisterForEventErrorAction({ error })))
                    );
                })
            );
        })
    ));


    // public loadInvoice$t$ = createEffect(() => this.actions.pipe(
    //     ofType(EventMemberActions.EventsMemberLoadPayableInvoiceAction),
    //     groupBy(stringKeys),
    //     mergeMap(groupedBy => {
    //         return groupedBy.pipe(
    //             exhaustMap(payload => {
    //                 return this.eventsService.loadInvoiceEffect(payload).pipe(
    //                     // ensure it's null and not undefined
    //                     map(invoice => invoice == null ? null : invoice),
    //                     map((invoice) => EventMemberActions.EventsMemberLoadPayableInvoiceSuccessAction({ ...payload, invoice: invoice })),
    //                     catchError(error => of(EventMemberActions.EventsMemberLoadPayableInvoiceErrorAction({ error })))
    //                 );
    //             })
    //         );
    //     })
    // ));

    public loadDynamicForm$t$ = createEffect(() => this.actions.pipe(
        ofType(EventMemberActions.EventsMemberLoadDynamicFormAction),
        groupBy(stringKeys),
        mergeMap(groupedBy => {
            return groupedBy.pipe(
                exhaustMap(payload => {
                    return this.eventsService.loadDynamicFormEffect(payload).pipe(
                        map(dynamicForm => EventMemberActions.EventsMemberLoadDynamicFormSuccessAction({ ...payload, dynamicForm: dynamicForm })),
                        catchError(error => of(EventMemberActions.EventsMemberLoadDynamicFormErrorAction({ error })))
                    );
                })
            );
        })
    ));

    public saveDynamicFormResponses$t$ = createEffect(() => this.actions.pipe(
        ofType(EventMemberActions.EventsMemberSaveDynamicFormResponsesAction),
        groupBy(stringKeys),
        mergeMap(groupedBy => {
            return groupedBy.pipe(
                exhaustMap(payload => {
                    return this.eventsService.saveDynamicFormResponsesEffect(payload).pipe(
                        map(dynamicFormResponses => EventMemberActions.EventsMemberSaveDynamicFormResponsesSuccessAction(payload)),
                        catchError(error => of(EventMemberActions.EventsMemberSaveDynamicFormResponsesErrorAction({ error })))
                    );
                })
            );
        })
    ));

    submitEventRegistration$ = createEffect(() => this.actions
        .pipe(
            ofType(EventMemberActions.EventsMemberSubmitAction),
            groupBy(stringKeys),
            mergeMap(groupedBy => {
                return groupedBy.pipe(
                    exhaustMap(payload => {
                        return this.eventsService.submitEventRegistrationEffect(payload).pipe(
                            map(() => EventMemberActions.EventsMemberSubmitSuccessAction(payload)),
                            catchError(error => of(EventMemberActions.EventsMemberSubmitErrorAction({ error })))
                        );
                    })
                );
            })
        ));

    submitEventRegistrationSuccess$ = createEffect(() => this.actions
        .pipe(
            ofType(EventMemberActions.EventsMemberRegisterForEventSuccessAction,
                EventMemberActions.EventsMemberSubmitSuccessAction,
                EventMemberActions.EventsMemberDeleteRegistrationSuccessAction),
            mergeMap(payload => {
                return [
                    EventsManagerActions.EventsManagerInvalidateEventRegistrationsCacheAction(),
                    FamilyActions.FamilyInvalidateCacheAction(),
                    MemberActions.MemberInvalidateMemberCache()
                ];
            })
        ));

    applyPayment$ = createEffect(() => this.actions.pipe(
        ofType(EventMemberActions.EventsMemberApplyPaymentAction),
        groupBy(stringKeys),
        mergeMap(groupedBy => {
            return groupedBy.pipe(
                exhaustMap(payload => {
                    return this.eventsService.applyPaymentEffect(payload).pipe(
                        map(() => EventMemberActions.EventsMemberApplyPaymentSuccessAction(payload)),
                        catchError(error => of(EventMemberActions.EventsMemberApplyPaymentErrorAction({ error })))
                    );
                })
            );
        })
    ));

    applyAdditionalPayment$ = createEffect(() => this.actions.pipe(
        ofType(EventMemberActions.EventsMemberApplyAdditionalPaymentAction),
        groupBy(stringKeys),
        mergeMap(groupedBy => {
            return groupedBy.pipe(
                exhaustMap(payload => {
                    return this.eventsService.applyAdditionalPaymentEffect(payload).pipe(
                        map(() => EventMemberActions.EventsMemberApplyAdditionalPaymentSuccessAction(payload)),
                        catchError(error => of(EventMemberActions.EventsMemberApplyAdditionalPaymentErrorAction({ error })))
                    );
                })
            );
        })
    ));
    applyPartialPayment$ = createEffect(() => this.actions.pipe(
        ofType(EventMemberActions.EventsMemberApplyPartialPaymentAction),
        groupBy(stringKeys),
        mergeMap(groupedBy => {
            return groupedBy.pipe(
                exhaustMap(payload => {
                    return this.eventsService.applyPartialPaymentEffect(payload).pipe(
                        map(() => EventMemberActions.EventsMemberApplyPartialPaymentSuccessAction(payload)),
                        catchError(error => of(EventMemberActions.EventsMemberApplyPartialPaymentErrorAction({ error })))
                    );
                })
            );
        })
    ));
    applyDepositPayment$ = createEffect(() => this.actions.pipe(
        ofType(EventMemberActions.EventsMemberApplyDepositPaymentAction),
        groupBy(stringKeys),
        mergeMap(groupedBy => {
            return groupedBy.pipe(
                exhaustMap(payload => {
                    return this.eventsService.applyDepositPaymentEffect(payload).pipe(
                        map(() => EventMemberActions.EventsMemberApplyDepositPaymentSuccessAction(payload)),
                        catchError(error => of(EventMemberActions.EventsMemberApplyDepositPaymentErrorAction({ error })))
                    );
                })
            );
        })
    ));

    loadCustomFiles$ = createEffect(() => this.actions.pipe(
        ofType(EventMemberActions.EventsMemberLoadCustomFilesAction),
        groupBy(stringKeys),
        mergeMap(groupedBy => {
            return groupedBy.pipe(
                exhaustMap(payload => {
                    return this.eventsService.loadCustomFilesEffect(payload).pipe(
                        map(customFiles => customFiles == null ? [] : customFiles),
                        map((files) => EventMemberActions.EventsMemberLoadCustomFilesSuccessAction({ ...payload, files })),
                        catchError(error => of(EventMemberActions.EventsMemberLoadCustomFilesErrorAction({ error })))
                    );
                })
            );
        })
    ));


    refreshEnrollment$ = createEffect(() => this.actions.pipe(
        ofType(
            EventMemberActions.EventsMemberSaveConsentsSuccessAction,
            // EventMemberActions.EventsMemberApplyPaymentSuccessAction,
            // using the refresh function on the service for a spinny blocking element
            // EventMemberActions.EventsMemberApplyAdditionalPaymentSuccessAction,
            // EventMemberActions.EventsMemberApplyPartialPaymentSuccessAction,
            // EventMemberActions.EventsMemberApplyDepositPaymentSuccessAction
        ),
        mergeMap(payload => {
            return [
                EventMemberActions.EventsMemberInvalidateEventRegistrationsAction(),
            ];
        })
    ));


    saveConsents$ = createEffect(() => this.actions.pipe(
        ofType(EventMemberActions.EventsMemberSaveConsentsAction),
        groupBy(stringKeys),
        mergeMap(groupedBy => {
            return groupedBy.pipe(
                exhaustMap(payload => {
                    return this.eventsService.saveEventsMemberConsentsEffect(payload).pipe(
                        map(() => EventMemberActions.EventsMemberSaveConsentsSuccessAction(payload)),
                        catchError(error => of(EventMemberActions.EventsMemberSaveConsentsErrorAction({ error })))
                    );
                })
            );
        })
    ));

    applyCoupons$ = createEffect(() => this.actions.pipe(
        ofType(EventMemberActions.EventsMemberApplyCouponCodeAction),
        groupBy(stringKeys),
        mergeMap(groupedBy => {
            return groupedBy.pipe(
                exhaustMap(payload => {
                    return this.eventsService.applyCouponCodeEffect(payload).pipe(
                        map(() => EventMemberActions.EventsMemberApplyCouponCodeSuccessAction(payload)),
                        catchError(error => of(EventMemberActions.EventsMemberApplyCouponCodeErrorAction({ error })))
                    );
                })
            );
        })
    ));


    applyCredit$ = createEffect(() => this.actions.pipe(
        ofType(EventMemberActions.EventsMemberApplyCreditAction),
        groupBy(stringKeys),
        mergeMap(groupedBy => {
            return groupedBy.pipe(
                exhaustMap(payload => {
                    return this.eventsService.applyCreditEffect(payload).pipe(
                        map(() => EventMemberActions.EventsMemberApplyCreditSuccessAction(payload)),
                        catchError(error => of(EventMemberActions.EventsMemberApplyCreditErrorAction({ error })))
                    );
                })
            );
        })
    ));
    public uploadEventMemberFile$ = createEffect(() => this.actions.pipe(
        ofType(EventMemberActions.EventsMemberUploadFileAction),
        groupBy(stringKeys),
        mergeMap(groupedBy => {
            return groupedBy.pipe(
                exhaustMap((payload) => {
                    return this.eventsService.uploadMemberFileEffect(payload).pipe(
                        map((res => EventMemberActions.EventsMemberUploadFileSuccessAction(payload))),
                        catchError(error => of(EventMemberActions.EventsMemberUploadFileErrorAction({ error })))
                    );
                })
            );
        })
    ));

    public deleteEventMemberFile$ = createEffect(() => this.actions.pipe(
        ofType(EventMemberActions.EventsMemberDeleteFileAction),
        groupBy(stringKeys),
        mergeMap(groupedBy => {
            return groupedBy.pipe(
                exhaustMap((payload) => {
                    return this.eventsService.deleteMemberFileEffect(payload).pipe(
                        map((res => EventMemberActions.EventsMemberDeleteFileSuccessAction(payload))),
                        catchError(error => of(EventMemberActions.EventsMemberDeleteFileErrorAction({ error })))
                    );
                })
            );
        })
    ));
    public deleteEventRegistration$ = createEffect(() => this.actions.pipe(
        ofType(EventMemberActions.EventsMemberDeleteRegistrationAction),
        groupBy(stringKeys),
        mergeMap(groupedBy => {
            return groupedBy.pipe(
                exhaustMap((payload) => {
                    return this.eventsService.deleteEventRegistrationEffect(payload).pipe(
                        map((res => EventMemberActions.EventsMemberDeleteRegistrationSuccessAction(payload))),
                        catchError(error => of(EventMemberActions.EventsMemberDeleteRegistrationErrorAction({ error })))
                    );
                })
            );
        })
    ));

    public invalidateCustomFilesCache = createEffect(() => this.actions.pipe(
        ofType(EventMemberActions.EventsMemberUploadFileSuccessAction,
            EventMemberActions.EventsMemberDeleteFileSuccessAction),
        map(payload => {
            return EventMemberActions.EventsMemberInvalidateCustomFilesCache();
        })
    ));

    public addSession$ = createEffect(() => this.actions.pipe(
        ofType(EventMemberActions.EventsMemberSaveSessionsAction),
        groupBy(stringKeys),
        mergeMap(groupedBy => {
            return groupedBy.pipe(
                exhaustMap((payload) => {
                    return this.eventsService.saveSessionsEffect(payload).pipe(
                        map((res => EventMemberActions.EventsMemberSaveSessionsSuccessAction(payload))),
                        catchError(error => of(EventMemberActions.EventsMemberSaveSessionsErrorAction({ error })))
                    );
                })
            );
        })
    ));

    public getRegistrationAnimals$ = createEffect(() => this.actions.pipe(
        ofType(EventMemberActions.EventsMemberGetEventRegistraitonAninimalsAction),
        groupBy(stringKeys),
        mergeMap(groupedBy => {
            return groupedBy.pipe(
                exhaustMap((payload) => {
                    return this.eventsService.getEventRegistrationAnimalsEffect(payload).pipe(
                        map((animals => EventMemberActions.EventsMemberGetEventRegistraitonAninimalsSuccessAction({ ...payload, animals }))),
                        catchError(error => of(EventMemberActions.EventsMemberGetEventRegistraitonAninimalsErrorAction({ error })))
                    );
                })
            );
        })
    ));

    public saveRegistrationAnimals$ = createEffect(() => this.actions.pipe(
        ofType(EventMemberActions.EventsMemberSaveEventRegistraitonAninimalAction),
        groupBy(stringKeys),
        mergeMap(groupedBy => {
            return groupedBy.pipe(
                exhaustMap((payload) => {
                    return this.eventsService.saveEventRegistrationAnimalEffect(payload).pipe(
                        map((res => EventMemberActions.EventsMemberSaveEventRegistraitonAninimalSuccessAction(payload))),
                        catchError(error => of(EventMemberActions.EventsMemberSaveEventRegistraitonAninimalErrorAction({ error })))
                    );
                })
            );
        })
    ));


    constructor(private actions: Actions, private eventsService: EventsMemberService, private toastrService: CommonToastrService) { }
}
