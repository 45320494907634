import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { getStandardReports, ManagerFeatureStore } from 'app/core/containers/admin/manager/manager-feature.reducer';
import { StandardReportActions } from 'app/core/containers/admin/manager/standard-reports/store';
import {
  eStandardReportFilter,
  IInstitutionManagerProgramParams,
  IMemorizedReportCreateDto,
  IStandardReportDescriptorDto,
  IStandardReportFiltersDto
} from 'app/core/models';
import { downloadFileWithBody } from 'app/shared/utils';
import { filter, Observable, of, tap } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StandardReportsService {

  public eStandardReportFilter = eStandardReportFilter;

  // Possible Filters:
  // eStandardReportFilter.IncludeAllYears: true
  // eStandardReportFilter.MemberFlagged: true
  // eStandardReportFilter.Gender: true
  // eStandardReportFilter.MemberParticipationStatus: true
  // eStandardReportFilter.Role: true
  // eStandardReportFilter.SchoolGrade: true

  public standardReportsConfig = {
    'ES237_Activity': {},
    'ES237_Ethnicity': {},
    'ES237_Participation': {},
    'ES237_Volunteer': {},
    'ES237_Youth': {},
    'Financial_CreditCardTransactions': {
      'DateRange': true
    },
    'Units_DirectoryPrimaryClub': {},
    'Financial_CheckPayments': {
      'DateRange': true
    }
  };

  constructor(
    private store: Store<ManagerFeatureStore>,
    private httpClient: HttpClient
  ) {
  }

  public getStandardReports(params: IInstitutionManagerProgramParams) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getStandardReports(params)),
      tap(enrollment => {
        if (enrollment === undefined) {
          this.store.dispatch(StandardReportActions.GetStandardReportsAction(params));
        }
      }),
      filter(enrollment => enrollment != null)
    );
  }

  public getStandardReportsEffect({ institutionId, managerId, programId }: IInstitutionManagerProgramParams) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/standard-reports`) as Observable<IStandardReportDescriptorDto[]>;
  }

  public downloadExcelEffect({
    institutionId,
    managerId,
    programId,
    standardReportId,
    programYearId,
    hierarchyNodeId,
    reportFilters
  }: IInstitutionManagerProgramParams & { standardReportId: string, programYearId: string, hierarchyNodeId: string, reportFilters: IStandardReportFiltersDto }) {
    //     return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/standard-reports/${standardReportId}/excel?programYearId=${programYearId}&hierarchyNodeId=${hierarchyNodeId}`, reportFilters);
    downloadFileWithBody(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/standard-reports/${standardReportId}/excel?programYearId=${programYearId}&hierarchyNodeId=${hierarchyNodeId}`, reportFilters);
  }

  public downloadPdfEffect({
    institutionId,
    managerId,
    programId,
    standardReportId,
    programYearId,
    hierarchyNodeId,
    reportFilters
  }: IInstitutionManagerProgramParams & { standardReportId: string, programYearId: string, hierarchyNodeId: string, reportFilters: IStandardReportFiltersDto }) {
    // return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/standard-reports/${standardReportId}/pdf?programYearId=${programYearId}&hierarchyNodeId=${hierarchyNodeId}`, reportFilters);
    downloadFileWithBody(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/standard-reports/${standardReportId}/pdf?programYearId=${programYearId}&hierarchyNodeId=${hierarchyNodeId}`, reportFilters);
  }

  public memorize({
    institutionId,
    managerId,
    programId,
    standardReportId,
    memorizedReportCreate
  }: IInstitutionManagerProgramParams & { standardReportId: string, memorizedReportCreate: IMemorizedReportCreateDto }) {
    return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/standard-reports/${standardReportId}/memorize`, memorizedReportCreate);
  }
}
