import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IConsentTypeDto } from 'app/core/models';
import { IEventRegistrationTypeDto } from 'app/core/models/serverDTOs/IEventRegistrationTypeDto';

@Component({
  selector: 'ng4h-modal-link-event-consent',
  templateUrl: './modal-link-event-consent.component.html',
  styleUrls: ['./modal-link-event-consent.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalLinkEventConsentComponent implements OnInit {

  public form: FormGroup;

  public consents: IConsentTypeDto[];
  public registrationTypes: IEventRegistrationTypeDto[];

  constructor(private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) private data,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.consents = this.data.consents.filter(c => !c.system);
    this.registrationTypes = this.data.registrationTypes;

    this.form = this.fb.group({
      consentTypeId: [''],
      eventRegistrationTypeId: ['']
    });
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public save(): void {
    this.dialogRef.close(this.form.value);
  }
}
