import { Pipe, PipeTransform } from '@angular/core';
import { IMemberDto } from 'app/core/models';

import { IMemberFamilyDescriptorDto } from '../models/serverDTOs/IMemberFamilyDescriptorDto';

@Pipe({
    name: 'primaryFamily',
    pure: true
})
export class PrimaryFamilyPipe implements PipeTransform {
    constructor() {
    }

    public transform(member: IMemberDto): IMemberFamilyDescriptorDto {
        return member.families.find(f => f.primary);
    }
}
