import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState, getPayments, getPaymentsByProgram } from 'app/app.reducers';
import {
  IInstitutionFamilyParams,
  IInstitutionFamilyProgramParams,
  IPaymentGatewayPaymentMethodsDto
} from 'app/core/models';
import { PaymentsActions } from 'app/shared/payments';
import { filter, Observable, of, tap } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  constructor(private store: Store<AppState>, private httpClient: HttpClient) {

  }

  public getPayments(params: IInstitutionFamilyParams): Observable<IPaymentGatewayPaymentMethodsDto[]> {

    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getPayments(params)),
      tap(payments => {
        if (payments === undefined) {
          this.store.dispatch(PaymentsActions.PaymentsLoadAction(params));
        }
      }),
      filter(payments => payments != null)
    );
  }

  public loadPaymentsEffect({
    institutionId,
    familyId
  }: IInstitutionFamilyParams): Observable<IPaymentGatewayPaymentMethodsDto[]> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/families/${familyId}/payment-methods`) as Observable<IPaymentGatewayPaymentMethodsDto[]>;
  }

  public getProgramPayments(params: IInstitutionFamilyProgramParams): Observable<IPaymentGatewayPaymentMethodsDto> {

    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getPaymentsByProgram(params)),
      tap(payments => {
        if (payments === undefined) {
          this.store.dispatch(PaymentsActions.PaymentsByProgramLoadAction(params));
        }
      }),
      filter(payments => payments != null)
    );
  }

  public loadProgramPaymentsEffect({
    institutionId,
    familyId,
    programId
  }: IInstitutionFamilyProgramParams): Observable<IPaymentGatewayPaymentMethodsDto> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/families/${familyId}/payment-methods?programId=${programId}`) as Observable<IPaymentGatewayPaymentMethodsDto>;
  }
}
