<ng4h-dynamic-options-root [formDisplayTypes]="formDisplayTypes" [showRequired]="false" [control]="control"
  (rootInputModified)="controlModified.emit($event)" (validatorAddOrModify)="validatorAddOrModify.emit($event)"
  (validatorRemove)="validatorRemove.emit($event)">

  <div class="form-group">

    <p-editor #editor [ngModel]="control.items[0].label" (ngModelChange)="editorChanged($event)"
      [style]="{'height':'320px'}" pEditorDefaultModules>
      <ng-template pTemplate="header">
        <ng4h-quill-editor-header-full [QuillEditor]="editor.quill"></ng4h-quill-editor-header-full>
      </ng-template>
    </p-editor>
  </div>
</ng4h-dynamic-options-root>