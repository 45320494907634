import { createAction, props } from '@ngrx/store';
import { IDocumentationController } from 'app/core/models';

export enum DocumentationActionTypes {
    LOAD = '[documentation] Load',
    LOAD_SUCCESS = '[documentation] Load Success',
    LOAD_ERROR = '[documentation] Load Error',
}

export const DocumentationLoadAction = createAction(
    '[documentation] Load'
);
export const DocumentationLoadSuccessAction = createAction(
    '[documentation] Load Success',
    props<{ documentation: IDocumentationController[] }>()
);
export const DocumentationLoadErrorAction = createAction(
    '[documentation] Load Error'
);
