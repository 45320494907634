import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ModalService } from 'app/core/services';
import { environment } from 'environments/environment';
import { filter, tap } from 'rxjs';

import { ErrorActions } from '.';
import { ModalHttpErrorComponent } from './modal-http-error/modal-http-error.component';

@Injectable()
export class ErrorEffects {

  public httpError = createEffect(() => this.actions
  .pipe(
    ofType(ErrorActions.ErrorHttpAction),
    filter((payload: { error: HttpErrorResponse, req: HttpRequest<any> }) => payload.error.status > 299),
    tap((payload: { error: HttpErrorResponse, req: HttpRequest<any> }) => {
      if (!environment.production) {
        this.modalService.openModal(ModalHttpErrorComponent, { data: payload });
      }
    })
  ), { dispatch: false });

  constructor(
    private actions: Actions, private modalService: ModalService
  ) {
  }
}
