import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { AppState } from 'app/app.reducers';
import { HealthFormActions } from 'app/core/containers/admin/system-manager/health/store';
import {
  getSystemManagerConsent,
  getSystemManagerHealth
} from 'app/core/containers/admin/system-manager/system-manager-feature.reducers';
import {
  eHealthFormType,
  IConsentVersionDto,
  IHealthFormDto,
  IHealthFormFieldStatusUpdateDto,
  IInstitutionSystemManagerProgramParams
} from 'app/core/models';
import { map, Observable, of, take, tap } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SystemManagerHealthService {

  constructor(
    private store: Store<AppState>,
    private httpClient: HttpClient,
    private dispatcher: ActionsSubject
  ) {

  }

  public getHealthForm(params: IInstitutionSystemManagerProgramParams & { healthFormType: eHealthFormType }): Observable<IHealthFormDto> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getSystemManagerHealth(params)),
      tap(healthForm => {
        if (healthForm === undefined) {
          this.store.dispatch(HealthFormActions.SystemManagerGetHealthFormAction(params));
        }
      })
    );

  }

  public loadHealthFormEffect({
    institutionId,
    systemManagerId,
    programId,
    healthFormType
  }: IInstitutionSystemManagerProgramParams & { healthFormType: eHealthFormType }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/health-forms?type=${healthFormType}`) as Observable<IHealthFormDto>;
  }

  public updateHealthForm(params: IInstitutionSystemManagerProgramParams & { update: IHealthFormFieldStatusUpdateDto }) {
    this.store.dispatch(HealthFormActions.SystemManagerUpdateHealthFormAction(params));
    return this.dispatcher.pipe(
      ofType(HealthFormActions.SystemManagerUpdateHealthFormSuccessAction, HealthFormActions.SystemManagerUpdateHealthFormErrorAction),
      take(1),
      map(action => {
        if (action.type === HealthFormActions.SystemManagerUpdateHealthFormSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateHealthFormEffect({
    institutionId,
    systemManagerId,
    programId,
    update
  }: IInstitutionSystemManagerProgramParams & { update: IHealthFormFieldStatusUpdateDto }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/health-forms`, update);
  }

  public updateHealthFormInternationalization(params: IInstitutionSystemManagerProgramParams & { localization: string, update: any }) {
    this.store.dispatch(HealthFormActions.SystemManagerUpdateHealthFormInternationalizationAction(params));
    return this.dispatcher.pipe(
      ofType(HealthFormActions.SystemManagerUpdateHealthFormInternationalizationSuccessAction, HealthFormActions.SystemManagerUpdateHealthFormInternationalizationErrorAction),
      take(1),
      map(action => {
        if (action.type === HealthFormActions.SystemManagerUpdateHealthFormInternationalizationSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateHealthFormInternationalizationEffect({
    institutionId,
    systemManagerId,
    programId,
    localization,
    update
  }: IInstitutionSystemManagerProgramParams & { localization: string, update: any }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/health-forms/${localization}`, update);
  }

  public getConsentVersions(params: IInstitutionSystemManagerProgramParams & { healthFormType: string }): Observable<IConsentVersionDto[]> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getSystemManagerConsent(params)),
      tap(consentVersions => {
        if (consentVersions === undefined) {
          this.store.dispatch(HealthFormActions.SystemManagerGetConsentAction(params));
        }
      })
    );
  }

  public loadConsentEffect({
    institutionId,
    systemManagerId,
    programId,
    type
  }: IInstitutionSystemManagerProgramParams & { type: string }): Observable<IConsentVersionDto[]> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/health-form-consents?type=${type}`) as Observable<IConsentVersionDto[]>;
  }
}
