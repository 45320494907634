import { Pipe, PipeTransform } from '@angular/core';

import { eInvoiceDiscountFeePercentage } from '../models';

@Pipe({
    name: 'feePercent',
    pure: true
})
export class FeePercentPipe implements PipeTransform {
    public transform(value: eInvoiceDiscountFeePercentage): string {
        switch (value) {
            case eInvoiceDiscountFeePercentage._10:
                return '10%';
            case eInvoiceDiscountFeePercentage._25:
                return '25%';
            case eInvoiceDiscountFeePercentage._50:
                return '50%';
            case eInvoiceDiscountFeePercentage._100:
                return '100%';
        }
    }
}
