import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { eDocumentationPropertyType, IDocumentationController, IDocumentationTypeDefinition } from 'app/core/models';
import { DocumentationActions } from 'app/shared/documentation';
import { filter, map, Observable, tap } from 'rxjs';

import { AppState, getDocumentation } from '../../app.reducers';
import { environment } from '../../../environments/environment';

const TAB = '    ';

@Injectable({
  providedIn: 'root'
})
export class DocumentationService {

  public documentation$: Observable<IDocumentationController[]>;

  constructor(private store: Store<AppState>, private httpClient: HttpClient) {
    this.documentation$ = this.store.pipe(
      select(getDocumentation),
      tap(doc => {
        if (!Array.isArray(doc)) {
          this.store.dispatch(DocumentationActions.DocumentationLoadAction());
        }
      }),
      filter(doc => Array.isArray(doc)),
      map(documentation => {
        return documentation.map(controller => {
          return {
            ...controller,
            actions: controller.actions.map(action => {
              return {
                ...action,

                responseTypes: action.responseTypes.map(responseType => {
                  return {
                    ...responseType,

                    typeDefinition: this.transform(responseType.typeDefinition, responseType.type)
                  };
                }),
                parameters: action.parameters.map(parameter => {
                  return {
                    ...parameter,

                    typeDefinition: this.transform(parameter.typeDefinition, parameter.type)
                  };
                })
              };
            })
          };
        });
      })
    );
  }

  public loadDocumentationEffect() {
    return this.httpClient.get(`${environment.apiUri}/api/documentation`) as Observable<IDocumentationController[]>;
  }

  private transform(def: IDocumentationTypeDefinition, type: string): any {
    if (def != null) {
      let line = '';
      if (def.definitions != null) {
        Object.keys(def.definitions).filter(definitionKey => {
          return def.definitions[definitionKey].properties != null;
        }).forEach(definitionKey => {
          line += this.defineInterface(def, def.definitions[definitionKey].properties, definitionKey);
        });
      }
      if (def.properties != null) {
        line += this.defineInterface(def, def.properties, type);
      } else if (def.additionalProperties != null) {
        line += this.defineInterface(def, def.additionalProperties.properties, type);
      }
      return line;
    } else {
      return 'null';
    }
  }

  private defineInterface(def: IDocumentationTypeDefinition, properties: { [propertyName: string]: any }, interfaceName: string) {
    // interfaceName = interfaceName.charAt(0).toUpperCase() + interfaceName.slice(1);
    let line = 'export interface I' + interfaceName + ' {';
    Object.keys(properties).forEach(propertyName => {
      const reference = properties[propertyName] as any;
      if (reference.$ref != null) {
        const referenceName = reference.$ref.split('#/definitions/')[1];
        const definition = def.definitions[referenceName];
        if (def.definitions[referenceName].properties) {
          line += '\r\n' + TAB + propertyName + ': I' + referenceName + ';';
        } else {
          line += this.defineInterfaceProperty(definition, propertyName);
        }
      } else {
        line += this.defineInterfaceProperty(properties[propertyName] as IDocumentationTypeDefinition, propertyName);
      }
    });
    return line + '\r\n}\r\n';
  }

  private defineInterfaceProperty(def: IDocumentationTypeDefinition, propertyName: string) {
    let type: eDocumentationPropertyType = null;
    let nullable = '';
    if (Array.isArray(def.type)) {
      type = def.type[0];
      nullable = '?';
    } else {
      type = def.type;
    }
    let line = '';
    switch (type) {
      case eDocumentationPropertyType.string: {
        if (def.enum) {
          line += `\r\n${TAB}${propertyName}${nullable}: e${def.description};`;
        } else {
          line += `\r\n${TAB}${propertyName}${nullable}: string;`;
        }
        break;
      }
      case eDocumentationPropertyType.object:
      case eDocumentationPropertyType.boolean:
      case eDocumentationPropertyType.number: {
        line += `\r\n${TAB}${propertyName}${nullable}: ${type};`;
        break;
      }
      case eDocumentationPropertyType.integer: {
        line += `\r\n${TAB}${propertyName}${nullable}: number;`;
        break;
      }
      // case eDocumentationPropertyType.object: {
      //     line += '\r\n' + TAB + propertyName + nullable + ': object;';
      //     break;
      //     if (def.additionalProperties?.items?.$ref != null) {
      //         line += '\r\n' + TAB + propertyName + nullable + ': { [key: string]: I' + def.additionalProperties?.items?.$ref.split('#/definitions/')[1] + '[];  };';
      //     } else if (def.additionalProperties?.$ref != null) {
      //         const referenceName = def.additionalProperties.$ref.split('#/definitions/')[1];
      //         line += '\r\n' + TAB + propertyName + nullable + ': { [key: string]: I' + referenceName + '  };';
      //     } else if (def.additionalProperties?.description) {
      //         line += '\r\n' + TAB + propertyName + nullable + ': { [key: string]:' + this.defineInterfaceProperty(def.additionalProperties, def.additionalProperties.description) + '\r\n}';
      //     } else {
      //         line += '\r\n' + TAB + propertyName + nullable + ': { [key: string]: any };';
      //     }
      //     break;
      // }
      case eDocumentationPropertyType.array: {
        if (def.items.$ref != null) {
          line += `\r\n${TAB}${propertyName}${nullable}: I${def.items.$ref.split('#/definitions/')[1]}[];`;
        } else {
          line += `\r\n${TAB}${propertyName}${nullable}: any[];`;
        }
        break;
      }
      case undefined: {
        line += `\r\n${TAB}${propertyName}${nullable}: any;`;
        break;
      }
      default:
        console.warn(def, propertyName);
    }
    return line;
  }
}
