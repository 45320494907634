import { Action, createReducer, on } from '@ngrx/store';
import { IInstitutionProgramModel, IVolunteerScreeningDto } from 'app/core/models';
import { mergeImmutable } from 'app/shared/utils';

import { AdminScreeningActions } from '.';

export interface AdminScreeningsState {
    screenings: IInstitutionProgramModel<IVolunteerScreeningDto[]>;
}

const initialState: AdminScreeningsState = {
    screenings: undefined
};

const reducer = createReducer(
    initialState,
    on(AdminScreeningActions.AdminScreeningsLoadSuccessAction, (state, { institutionId, programId, screenings }) => {
        const newScreenings = mergeImmutable(
            { [institutionId]: { [programId]: screenings } },
            state.screenings
        );
        return {
            ...state,
            screenings: newScreenings
        };
    })
);

export function adminScreeningsReducer(state: AdminScreeningsState | undefined, actions: Action) {
    return reducer(state, actions);
}

export const screenings = (state: AdminScreeningsState) => state.screenings;
