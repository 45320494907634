import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  eLocalizationType,
  IConsentAddLocalizationDto,
  IConsentCreateDto,
  IConsentTypeDto,
  IConsentTypeUpdateDto,
  IConsentVersionUpdateDto,
  IInstitutionSystemManagerProgramParams
} from 'app/core/models';

export const ConsentsCreateAction = createAction(
  '[consents] Create consent',
  props<{ institutionId: string, systemManagerId: string, programId: string, consent: IConsentCreateDto }>()
);
export const ConsentsCreateSuccessAction = createAction(
  '[consents] Create consent success',
  props<{ institutionId: string, systemManagerId: string, programId: string, consent: IConsentCreateDto }>()
);
export const ConsentsCreateErrorAction = createAction(
  '[consents] Create consent error',
  props<{ error?: any }>()
);

export const UpdateConsentTypeAction = createAction(
  '[consents] Update consent type',
  props<{ institutionId: string, systemManagerId: string, programId: string, consentTypeId: string, consent: IConsentTypeUpdateDto }>()
);
export const UpdateConsentTypeSuccessAction = createAction(
  '[consents] Update consent type success',
  props<{ institutionId: string, systemManagerId: string, programId: string, consentTypeId: string, consent: IConsentTypeUpdateDto }>()
);
export const UpdateConsentTypeErrorAction = createAction(
  '[consents] Update consent type error',
  props<{ error?: any }>()
);
export const DeleteConsentTypeAction = createAction(
  '[consents] Delete consent type',
  props<{ institutionId: string, systemManagerId: string, programId: string, consentTypeId: string }>()
);
export const DeleteConsentTypeSuccessAction = createAction(
  '[consents] Delete consent type success',
  props<{ institutionId: string, systemManagerId: string, programId: string, consentTypeId: string }>()
);
export const DeleteConsentTypeErrorAction = createAction(
  '[consents] Delete consent type error',
  props<{ error?: any }>()
);

export const CreateConsentVersionAction = createAction(
  '[consents] Create consent version',
  props<IInstitutionSystemManagerProgramParams & { consentTypeId: string, consent: IConsentVersionUpdateDto }>()
);
export const CreateConsentVersionSuccessAction = createAction(
  '[consents] Create consent version success',
  props<IInstitutionSystemManagerProgramParams & { consentTypeId: string, consent: IConsentVersionUpdateDto }>()
);
export const CreateConsentVersionErrorAction = createAction(
  '[consents] Create consent version error',
  props<{ error?: any }>()
);

export const UpdateConsentVersionAction = createAction(
  '[consents] Update consent version',
  props<{ institutionId: string, systemManagerId: string, programId: string, consentVersionId: string, consent: IConsentVersionUpdateDto }>()
);
export const UpdateConsentVersionSuccessAction = createAction(
  '[consents] Update consent version success',
  props<{ institutionId: string, systemManagerId: string, programId: string, consentVersionId: string, consent: IConsentVersionUpdateDto }>()
);
export const UpdateConsentVersionErrorAction = createAction(
  '[consents] Update consent version error',
  props<{ error?: any }>()
);

// export const ConsentsDeleteEnrollmentAction = createAction(
//     '[consents] Delete Enrollment',
//     props<{ institutionId: string, systemManagerId: string, programId: string, consentTypeId: string }>()
// );
// export const ConsentsDeleteEnrollmentSuccessAction = createAction(
//     '[consents] Delete Enrollment success',
//     props<{ institutionId: string, systemManagerId: string, programId: string, consentTypeId: string }>()
// );
// export const ConsentsDeleteEnrollmentErrorAction = createAction(
//     '[consents] Delete Enrollment error',
//     props<{ error?: any }>()
// );

export const ConsentsGetConsentAction = createAction(
  '[consents] get consents',
  props<{ institutionId: string, programId: string }>()
);

export const ConsentsGetConsentSuccessAction = createAction(
  '[consents] get consents success',
  props<{ institutionId: string, programId: string, consents: IConsentTypeDto[] }>()
);

export const ConsentsGetConsentErrorAction = createAction(
  '[consents] get consents error',
  props<{ error: HttpErrorResponse }>()
);

export const ConsentsAddLocalizationAction = createAction(
  '[consents] add consent localization',
  props<{ institutionId: string, systemManagerId: string, programId: string, consentVersionId: string, localization: eLocalizationType, consent: IConsentAddLocalizationDto }>()
);

export const ConsentsAddLocalizationSuccessAction = createAction(
  '[consents add consent localization success',
  props<{ institutionId: string, systemManagerId: string, programId: string, consentVersionId: string, localization: eLocalizationType, consent: IConsentAddLocalizationDto }>()
);

export const ConsentsAddLocalizationErrorAction = createAction(
  '[consents] add consent localization error',
  props<{ error?: any }>()
);

export const ConsentsInvalidateCacheAction = createAction(
  '[consents] invalidate consents cache'
);

export const ConsentsDeleteVersionAction = createAction(
  '[consents] delete consent',
  props<IInstitutionSystemManagerProgramParams & { consentVersionId: string }>()
);

export const ConsentsDeleteVersionSuccessAction = createAction(
  '[consents] delete consent success',
  props<IInstitutionSystemManagerProgramParams & { consentVersionId: string }>()
);

export const ConsentsDeleteVersionErrorAction = createAction(
  '[consents] delete consent error',
  props<{ error: HttpErrorResponse }>()
);
