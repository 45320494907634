import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AutoUnsubscribe, IHierarchyAny } from 'app/core/models';
import { RouterService } from 'app/core/services';
import { Observable, take, takeUntil } from 'rxjs';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'ng4h-modal-interaction',
    templateUrl: './modal-interaction.component.html',
    styleUrls: ['./modal-interaction.component.scss']
})
export class ModalInteractionComponent extends AutoUnsubscribe implements OnInit {

    public form: FormGroup;
    public wasValidated = false;

    constructor(private dialogRef: MatDialogRef<any>, private routerService: RouterService, private route: ActivatedRoute) {
        super();

    }

    ngOnInit() {
    }

    public close(): void {
        this.dialogRef.close();
    }

    public save() {
        if (this.form.invalid) {
            this.wasValidated = true;
        }
        this.dialogRef.close(this.form.value);
    }

}
