import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  IEnrollmentHierarchyInvoiceItemsDto,
  IEnrollmentInvoiceItemSubmitDto,
  IInstitutionSystemManagerProgramParams,
} from 'app/core/models';
import { IEnrollmentConsentTypeDto } from 'app/core/models/serverDTOs/IEnrollmentConsentTypeDto';


export const SystemManagerLoadEnrollmentAction = createAction(
  '[system manager enrollments] load enrollments consents',
  props<IInstitutionSystemManagerProgramParams>()
);

export const SystemManagerLoadEnrollmentSuccessAction = createAction(
  '[system manager enrollments] load enrollments consents success',
  props<IInstitutionSystemManagerProgramParams & { consents: IEnrollmentConsentTypeDto[] }>()
);

export const SystemManagerLoadEnrollmentErrorAction = createAction(
  '[system manager enrollments] load enrollments consents error',
  props<{ error: HttpErrorResponse }>()
);

export const SystemManagerAddEnrollmentAction = createAction(
  '[system manager enrollments] add enrollment consent',
  props<IInstitutionSystemManagerProgramParams & { enrollmentRole: string, consentTypeId: string }>()
);

export const SystemManagerAddEnrollmentSuccessAction = createAction(
  '[system manager enrollments] add enrollment consent success',
  props<IInstitutionSystemManagerProgramParams & { enrollmentRole: string, consentTypeId: string }>()
);

export const SystemManagerAddEnrollmentErrorAction = createAction(
  '[system manager enrollments] add enrollment consent error',
  props<{ error: HttpErrorResponse }>()
);

export const SystemManagerDeleteEnrollmentAction = createAction(
  '[system manager enrollments] delete enrollment consent',
  props<IInstitutionSystemManagerProgramParams & { enrollmentRole: string, consentTypeId: string }>()
);

export const SystemManagerDeleteEnrollmentSuccessAction = createAction(
  '[system manager enrollments] delete enrollment consent success',
  props<IInstitutionSystemManagerProgramParams & { enrollmentRole: string, consentTypeId: string }>()
);

export const SystemManagerDeleteEnrollmentErrorAction = createAction(
  '[system manager enrollments] delete enrollment consent error',
  props<{ error: HttpErrorResponse }>()
);

export const SystemManagerInvalidateEnrollmentConsentsCacheAction = createAction(
  '[system manager enrollments] invalidate enrollments consents cache',
  props<IInstitutionSystemManagerProgramParams>()
);

export const SystemManagerLoadInvoicesAction = createAction(
  '[system manager enrollments] load invoices',
  props<IInstitutionSystemManagerProgramParams & { programYearId: string }>()
);

export const SystemManagerLoadInvoicesSuccessAction = createAction(
  '[system manager enrollments] load invoices success',
  props<IInstitutionSystemManagerProgramParams & { programYearId: string, invoices: IEnrollmentHierarchyInvoiceItemsDto[] }>()
);

export const SystemManagerLoadInvoicesErrorAction = createAction(
  '[system manager enrollments] load invoices error',
  props<{ error: HttpErrorResponse }>()
);

export const SystemManagerUpdateInvoiceAction = createAction(
  '[system manager enrollments] update invoices',
  props<IInstitutionSystemManagerProgramParams & { programYearId: string, hierarchyNodeId: string, invoice: IEnrollmentInvoiceItemSubmitDto }>()
);

export const SystemManagerUpdateInvoiceSuccessAction = createAction(
  '[system manager enrollments] update invoices success',
  props<IInstitutionSystemManagerProgramParams & { programYearId: string, hierarchyNodeId: string, invoice: IEnrollmentInvoiceItemSubmitDto }>()
);

export const SystemManagerUpdateInvoiceErrorAction = createAction(
  '[system manager enrollments] update invoices error',
  props<{ error: HttpErrorResponse }>()
);

export const SystemManagerLoadClubInvoicesAction = createAction(
  '[system manager enrollments] load club invoices',
  props<IInstitutionSystemManagerProgramParams & { countyAreaId: string, programYearId: string }>()
);

export const SystemManagerLoadClubInvoicesSuccessAction = createAction(
  '[system manager enrollments] load club invoices success',
  props<IInstitutionSystemManagerProgramParams & { countyAreaId: string, programYearId: string, invoices: IEnrollmentHierarchyInvoiceItemsDto[] }>()
);

export const SystemManagerLoadClubInvoicesErrorAction = createAction(
  '[system manager enrollments] load club invoices error',
  props<{ error: HttpErrorResponse }>()
);

export const SystemManagerUpdateClubInvoiceAction = createAction(
  '[system manager enrollments] update club invoices',
  props<IInstitutionSystemManagerProgramParams & { programYearId: string, countyAreaId: string, unitId: string, invoice: IEnrollmentInvoiceItemSubmitDto }>()
);

export const SystemManagerUpdateClubInvoiceSuccessAction = createAction(
  '[system manager enrollments] update club invoices success',
  props<IInstitutionSystemManagerProgramParams & { programYearId: string, countyAreaId: string, unitId: string, invoice: IEnrollmentInvoiceItemSubmitDto }>()
);

export const SystemManagerUpdateClubInvoiceErrorAction = createAction(
  '[system manager enrollments] update club invoices error',
  props<{ error: HttpErrorResponse }>()
);
