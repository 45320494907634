import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import { IInstitutionFamilyParams } from '../../models';
import * as publicEvents from './public.reducer';

export const PUBLIC_FEATURE = 'PUBLIC_FEATURE';

export interface PublicFeatureStore {
    publicEvents: publicEvents.PublicState;
}

export const publicReducers: ActionReducerMap<PublicFeatureStore> = {
    publicEvents: publicEvents.publicReducer
}

export const getPublicFeatureStore = createFeatureSelector<PublicFeatureStore>(PUBLIC_FEATURE);

export const getPublicState = createSelector(getPublicFeatureStore, (state: PublicFeatureStore) => state?.publicEvents);

export const getPublicEvent = ({ institutionId, eventId }: { institutionId: string, eventId: string }) => createSelector(
    createSelector(getPublicState, publicEvents.publicEvent),
    events => events?.[institutionId]?.[eventId]
);
export const getFamilyEventEligibility = ({ institutionId, familyId, eventId }: IInstitutionFamilyParams & { eventId: string }) => createSelector(
    createSelector(getPublicState, publicEvents.familyEventEligibility),
    events => events?.[institutionId]?.[familyId]?.[eventId]
);