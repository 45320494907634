import { eHierarchyNodeType, IEnrollmentFamilyDiscountDto } from '.';
import { ICountyDescriptorDto } from './serverDTOs';

export interface IFamilyCountyDiscount {
    familyDiscounts: IEnrollmentFamilyDiscountDto[];
    countyAreaId: string;
    counties: ICountyDescriptorDto[];
    hierarchyNodeId: string;
    hierarchyNodeType: eHierarchyNodeType;
    hierarchyNodeName: string;
}