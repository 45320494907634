export enum eDeliveryModeType {
    Undetermined = 'Undetermined',
    Organized4HClub = 'OrganizedClub',
    Organized4HCommunityClub = 'OrganizedCommunityClub',
    Organized4HInSchoolClub = 'OrganizedInSchoolClub',
    Organized4HAfterSchoolClub = 'OrganizedAfterSchoolClub',
    OrganizedMilitary4HClub = 'OrganizedMilitaryClub',
    _4HSpecialInterest_ShortTermProgram = '_SpecialInterest_ShortTermProgram',
    _4HCampingProgram = '_CampingProgram',
    _4HOvernightCampingProgram = '_OvernightCampingProgram',
    _4HDayCampingProgram = '_DayCampingProgram',
    _4HSchoolEnrichmentProgram = '_SchoolEnrichmentProgram',
    _4HIndividualStudy_Mentoring_FamilyLearningProgram = '_IndividualStudy_Mentoring_FamilyLearningProgram',
    AfterSchoolProgramsUsing4HCurricula_StaffTraining = 'AfterSchoolProgramsUsingCurricula_StaffTraining',
    InstructionalTV_VideoProgram = 'InstructionalTV_VideoProgram',
    NotConnectedWithYouthGroup = 'NotConnectedWithYouthGroup',
    Administrative_OrganizationUnit = 'Administrative_OrganizationUnit'
}
