import { Pipe, PipeTransform } from '@angular/core';

import { eFilterOperator } from '../models';

@Pipe({
    name: 'filterOperator',
    pure: true
})
export class FilterOperatorPipe implements PipeTransform {
    public transform(value: eFilterOperator): string {
        switch (value) {
            case eFilterOperator.Between: {
                return 'between';
            }
            case eFilterOperator.Equal: {
                return 'equal to';
            }
            case eFilterOperator.EqualAvailableOptions: {
                return 'equal to';
            }
            case eFilterOperator.GreaterThan: {
                return 'greater than';
            }
            case eFilterOperator.GreaterThanOrEqual: {
                return 'greater than or equal to';
            }
            case eFilterOperator.IsNull: {
                return 'null';
            }
            case eFilterOperator.IsTrue: {
                return 'true';
            }
            case eFilterOperator.LessThan: {
                return 'less than';
            }
            case eFilterOperator.LessThanOrEqual: {
                return 'less than or equal to';
            }
            case eFilterOperator.Like: {
                return 'like';
            }
            case eFilterOperator.NotEqual: {
                return 'not equal to';
            }
            case eFilterOperator.NotEqualAvailableOptions: {
                return 'not equal to';
            }
            case eFilterOperator.NotNull: {
                return 'not null';
            }
            case eFilterOperator.NotTrue: {
                return 'not true';
            }
        }
    }
}
