import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { AppState, getSystemManager, getSystemManagerPermissionTypes, getSystemManagers } from 'app/app.reducers';
import {
  IInstitutionSystemManagerProgramParams,
  ISuperuserInstitutionParams,
  ISuperuserInstitutionSystemManagerParams,
  ISuperuserInstitutionSystemManagerProgramParams,
  ISystemManagerACLDto,
  ISystemManagerCreateDto,
  ISystemManagerInstitutionProfile,
  ISystemManagerUpdateDto
} from 'app/core/models';
import { SystemManagerActions } from 'app/shared/system-manager';
import { filter, map, Observable, of, take, tap } from 'rxjs';

import { downloadFile } from '../../shared/utils';
import { IPermissionType } from '../models';
import { CommonToastrService } from './common-toastr.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SystemManagerService {

  constructor(
    private store: Store<AppState>,
    private httpClient: HttpClient,
    private dispatcher: ActionsSubject,
    private toastrService: CommonToastrService
  ) {

  }

  public getSystemManager(params: { superuserId: string, institutionId: string, systemManagerId: string }) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getSystemManager(params)),
      tap(systemManager => {
        if (systemManager === undefined) {
          this.store.dispatch(SystemManagerActions.GetSystemManagerAction(params));
        }
      }),
      filter(systemManager => systemManager != null)
    );
  }

  public loadSystemManagerEffect({
    superuserId,
    institutionId,
    systemManagerId
  }: ISuperuserInstitutionSystemManagerParams): Observable<ISystemManagerInstitutionProfile> {
    return this.httpClient.get(`${environment.apiUri}/api/super-users/${superuserId}/institutions/${institutionId}/system-managers/${systemManagerId}`) as Observable<ISystemManagerInstitutionProfile>;
  }

  public updateSystemManager(params: { institutionId: string, systemManagerId: string, systemManagerUpdate: ISystemManagerUpdateDto }) {
    this.store.dispatch(SystemManagerActions.UpdateSystemManagerAction(params));
    return this.dispatcher.pipe(
      ofType(SystemManagerActions.UpdateSystemManagerSuccessAction, SystemManagerActions.UpdateSystemManagerErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerActions.UpdateSystemManagerSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateSystemManagerEffect({
    institutionId,
    systemManagerId,
    systemManagerUpdate
  }: { institutionId: string, systemManagerId: string, systemManagerUpdate: ISystemManagerUpdateDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}`, systemManagerUpdate);
  }

  public getSystemManagers(params: { institutionId: string }): Observable<ISystemManagerInstitutionProfile[]> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getSystemManagers(params)),
      tap(systemManagers => {
        if (systemManagers === undefined) {
          this.store.dispatch(SystemManagerActions.GetSystemManagersAction(params));
        }
      }),
      filter(systemManagers => systemManagers != null)
    );
  }

  public loadSystemManagersEffect({ institutionId }): Observable<ISystemManagerInstitutionProfile[]> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/system-managers`) as Observable<ISystemManagerInstitutionProfile[]>;
  }

  public getPermissionTypes(): Observable<IPermissionType[]> {

    return this.store.pipe(
      select(getSystemManagerPermissionTypes({})),
      tap(permissionTypes => {
        if (permissionTypes === undefined) {
          this.store.dispatch(SystemManagerActions.GetSystemManagerPermissionTypesAction());
        }
      }),
      filter(permissionTypes => permissionTypes != null)
    );
  }

  public loadSystemManagerPermissionTypesEffect(): Observable<IPermissionType[]> {
    return this.httpClient.get(`${environment.apiUri}/api/system-managers/permission-types`) as Observable<IPermissionType[]>;
  }

  public createSystemManager(params: ISuperuserInstitutionParams & { managerCreate: ISystemManagerCreateDto }) {
    this.store.dispatch(SystemManagerActions.CreateSystemManagerAction(params));
  }

  public createSystemManagerEffect({
    superuserId,
    institutionId,
    managerCreate
  }: ISuperuserInstitutionParams & { managerCreate: ISystemManagerCreateDto }): Observable<Object> {
    return this.httpClient.post(`${environment.apiUri}/api/super-users/${superuserId}/institutions/${institutionId}/system-managers`, managerCreate);
  }

  public updateSystemManagerPermissions(params: ISuperuserInstitutionSystemManagerProgramParams & { permissions: ISystemManagerACLDto }): void {
    this.store.dispatch(SystemManagerActions.UpdateSystemManagerPermissionsAction(params));
  }

  public updateSystemManagerPermissionsEffect({
    superuserId,
    institutionId,
    systemManagerId,
    programId,
    permissions
  }: ISuperuserInstitutionSystemManagerProgramParams & { permissions: ISystemManagerACLDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/super-users/${superuserId}/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/permissions`, permissions);
  }

  public removeSystemManagerFromProgram(params: ISuperuserInstitutionSystemManagerProgramParams) {
    this.store.dispatch(SystemManagerActions.RemoveFromProgramAction(params));
    return this.dispatcher.pipe(
      ofType(SystemManagerActions.RemoveFromProgramSuccessAction, SystemManagerActions.RemoveFromProgramErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerActions.RemoveFromProgramSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public removeSystemManagerFromProgramEffect({
    superuserId,
    institutionId,
    systemManagerId,
    programId
  }: ISuperuserInstitutionSystemManagerProgramParams) {
    return this.httpClient.delete(`${environment.apiUri}/api/super-users/${superuserId}/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}`);
  }

  public exportManagerPermissions({
    institutionId,
    systemManagerId,
    programId
  }: IInstitutionSystemManagerProgramParams) {
    downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/managers/permissions-export`, this.toastrService);
  }
}
