import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { eInvoiceStatus, IInvoiceDto, IMemberDto } from 'app/core/models';

@Component({
  selector: 'ng4h-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoicesComponent implements OnInit {

  public eInvoiceStatus = eInvoiceStatus;
  @Input() member: IMemberDto;
  @Input() invoices: IInvoiceDto[];
  constructor() { }

  ngOnInit() {
  }

}
