import { Pipe, PipeTransform } from '@angular/core';
import { INationalProjectCategoryDto } from 'app/core/models';
import { ManagerProjectsService, ProgramYearService, RouterService } from 'app/core/services';
import { filter, map, Observable, switchMap } from 'rxjs';

@Pipe({
    name: 'nationalProjectCategory$',
    pure: true
})
export class NationalProjectCategoryPipe implements PipeTransform {
    constructor(private managerProjectsService: ManagerProjectsService, private programYearService: ProgramYearService, private routerService: RouterService) {
    }

    public transform(nationalProjectCategoryId: string, institutionId: string = this.routerService.institutionId, programId: string = this.routerService.programId, programYearId: string): Observable<INationalProjectCategoryDto> {

        return this.programYearService.getUsersProgramYear({
            institutionId,
            programId,
            institutionProfileId: this.routerService.loggedInInstitutionProfileId
        }).pipe(
            switchMap(programYear => {
                return this.managerProjectsService.getNationalProjectCategories({
                    institutionId,
                    programId,
                    programYearId: programYearId ? programYearId : programYear.programYearId
                });
            }),
            filter(categories => categories != null),
            map(categories => {
                return categories.find(category => category.nationalProjectCategoryId === nationalProjectCategoryId);
            })
        );

    }
}
