import { IDynamicOption } from './IDynamicOption';

export class DynamicOption implements IDynamicOption {
  optionId: string;
  label: string;
  attributes: {
    selected: boolean,
    quantityAvailable: number,
    allowWaitList: boolean
  };
  // readonly hasFee: boolean;
  rate: number;
  constructor(private _optionId: string, private _label: string) {
    this.optionId = _optionId;
    this.label = _label;
  }
}
