import { ePaymentMethodType } from '..';
import { IAddressDto } from './IAddressDto';

export interface IPaymentMethodDto {
    paymentMethodId: string;
    maskedAccountNumber: string;
    type: ePaymentMethodType;
    dateLastUsed: Date;
    dateCreated: Date;
    address: IAddressDto;
}
