import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, Store } from '@ngrx/store';
import { AppState } from 'app/app.reducers';
import { map, Observable, take } from 'rxjs';

import { StripeActions } from '../containers/admin/shared/stripe';
import {
    IInstitutionManagerProgramHierarchyParams, IInstitutionManagerProgramParams,
    IInstitutionSystemManagerProgramParams,
    IStripeConnectDto
} from '../models';
import { BankAccountActions } from '../containers/admin/shared/bank-accounts';
import { IStripeRequirementsDto } from '../models/serverDTOs/IStripeRequirementsDto';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class StripeService {

    constructor(private store: Store<AppState>,
        private httpClient: HttpClient,
        private dispatcher: ActionsSubject
    ) {
    }

    public connectStripeManager(params: IInstitutionManagerProgramHierarchyParams & { connectDto: IStripeConnectDto }) {
        this.store.dispatch(StripeActions.StripeConnectManagerAction(params));

        return this.dispatcher.pipe(
            ofType(StripeActions.StripeConnectManagerSuccessAction, StripeActions.StripeConnectManagerErrorAction),
            take(1),
            map(action => {
                if (action.type === StripeActions.StripeConnectManagerSuccessAction.type) {
                    return action.location;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public connectStripeManagerEffect({
        institutionId,
        managerId,
        programId,
        hierarchyNodeId,
        connectDto
    }: IInstitutionManagerProgramHierarchyParams & { connectDto: IStripeConnectDto }) {
        return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/stripe-connect/${hierarchyNodeId}`, connectDto,
            { observe: 'response' }).pipe(
            map((res: HttpResponse<any>) => res.headers.get('location'))
        ) as Observable<string>;
    }

    public updateStripeManager(params: IInstitutionManagerProgramHierarchyParams & { connectDto: IStripeConnectDto }) {
        this.store.dispatch(StripeActions.StripeConnectManagerUpdateAction(params));

        return this.dispatcher.pipe(
            ofType(StripeActions.StripeConnectManagerUpdateSuccessAction, StripeActions.StripeConnectManagerUpdateErrorAction),
            take(1),
            map(action => {
                if (action.type === StripeActions.StripeConnectManagerUpdateSuccessAction.type) {
                    return action.location;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public updateStripeManagerEffect({
        institutionId,
        managerId,
        programId,
        hierarchyNodeId,
        connectDto
    }: IInstitutionManagerProgramHierarchyParams & { connectDto: IStripeConnectDto }) {
        return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/stripe-connect/${hierarchyNodeId}`, connectDto,
            { observe: 'response' }).pipe(
            map((res: HttpResponse<any>) => res.headers.get('location'))
        ) as Observable<string>;
    }

    public connectStripeSystemManager(params: IInstitutionSystemManagerProgramParams & { connectDto: IStripeConnectDto }) {
        this.store.dispatch(StripeActions.StripeConnectSystemManagerAction(params));

        return this.dispatcher.pipe(
            ofType(StripeActions.StripeConnectSystemManagerSuccessAction, StripeActions.StripeConnectSystemManagerErrorAction),
            take(1),
            map(action => {
                if (action.type === StripeActions.StripeConnectSystemManagerSuccessAction.type) {
                    return action.location;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public connectStripeSystemManagerEffect({
        institutionId,
        systemManagerId,
        programId,
        connectDto
    }: IInstitutionSystemManagerProgramParams & { connectDto: IStripeConnectDto }) {
        return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/stripe-connect`, connectDto,
            { observe: 'response' }).pipe(
            map((res: HttpResponse<any>) => res.headers.get('location'))
        ) as Observable<string>;
    }

    public updateStripeSystemManager(params: IInstitutionSystemManagerProgramParams & { connectDto: IStripeConnectDto }) {
        this.store.dispatch(StripeActions.StripeConnectSystemManagerUpdateAction(params));

        return this.dispatcher.pipe(
            ofType(StripeActions.StripeConnectSystemManagerUpdateSuccessAction, StripeActions.StripeConnectSystemManagerUpdateErrorAction),
            take(1),
            map(action => {
                if (action.type === StripeActions.StripeConnectSystemManagerUpdateSuccessAction.type) {
                    return action.location;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public updateStripeSystemManagerEffect({
        institutionId,
        systemManagerId,
        programId,
        connectDto
    }: IInstitutionSystemManagerProgramParams & { connectDto: IStripeConnectDto }) {
        return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/stripe-connect`, connectDto,
            { observe: 'response' }).pipe(
            map((res: HttpResponse<any>) => res.headers.get('location'))
        ) as Observable<string>;
    }

    public stripeStatusManager(params: IInstitutionManagerProgramHierarchyParams) {
        this.store.dispatch(StripeActions.ManagerStripeStatusLoadAction(params));
        return this.dispatcher.pipe(
            ofType(StripeActions.ManagerStripeStatusLoadSuccessAction, StripeActions.ManagerStripeStatusLoadErrorAction),
            take(1),
            map(action => {
                if (action.type === StripeActions.ManagerStripeStatusLoadSuccessAction.type) {
                    return action.status;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public getStripeAccountStatusEffect({
        institutionId,
        managerId,
        programId,
        hierarchyNodeId
    }: IInstitutionManagerProgramParams & { hierarchyNodeId: string }) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/stripe-status/${hierarchyNodeId}`) as Observable<IStripeRequirementsDto[]>;
    }

    public termsOfServiceEffect({
        institutionId,
        managerId,
        programId,
        hierarchyNodeId
    }: IInstitutionManagerProgramHierarchyParams) {
        return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/stripe-terms-of-service/${hierarchyNodeId}`, {});
    }

    public termsOfServiceSystemManagerEffect({
        institutionId,
        systemManagerId,
        programId
    }: IInstitutionSystemManagerProgramParams) {
        return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/stripe-terms-of-service`, {});
    }
}
