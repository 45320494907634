import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { AdminFeatureStore, getAdminScreenings } from 'app/core/containers/admin/admin-feature.reducers';
import { AdminScreeningActions } from 'app/core/containers/admin/shared/screenings';
import { IInstitutionProgramParams, IVolunteerScreeningDto } from 'app/core/models';
import { filter, Observable, of, tap } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminScreeningsService {
  constructor(
    private store: Store<AdminFeatureStore>,
    private httpClient: HttpClient,
    private dispatcher: ActionsSubject) {
  }

  public getScreenings(params: IInstitutionProgramParams): Observable<IVolunteerScreeningDto[]> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getAdminScreenings(params)),
      tap(screenings => {
        if (screenings === undefined) {
          this.store.dispatch(AdminScreeningActions.AdminScreeningsLoadAction(params));
        }
      }),
      filter(screenings => screenings != null)
    );
  }

  public loadScreeningsEffect({
    institutionId,
    programId
  }: IInstitutionProgramParams): Observable<IVolunteerScreeningDto[]> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/programs/${programId}/screenings`) as Observable<IVolunteerScreeningDto[]>;
  }

}
