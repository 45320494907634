import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { getAward, getAwards, ManagerFeatureStore } from 'app/core/containers/admin/manager/manager-feature.reducer';
import { ManagerAwardsActions } from 'app/core/containers/admin/manager/store/awards';
import {
  IAwardCreateDto,
  IAwardDto,
  IAwardUpdateDto,
  IInstitutionManagerProgramHierarchyParams,
  IInstitutionManagerProgramParams
} from 'app/core/models';
import { filter, map, Observable, of, take, tap } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ManagerAwardsService {
  constructor(private httpClient: HttpClient, private store: Store<ManagerFeatureStore>, private dispatcher: ActionsSubject) {
  }

  public getAllAwards(params: IInstitutionManagerProgramHierarchyParams): Observable<IAwardDto[]> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getAwards(params)),
      tap(awards => {
        if (awards === undefined) {
          this.store.dispatch(ManagerAwardsActions.LoadAwardsAction(params));
        }
      }),
      filter(awards => awards != null)
    );
  }

  public getAllAwardsEffect({
    institutionId,
    managerId,
    programId,
    hierarchyNodeId
  }: IInstitutionManagerProgramHierarchyParams) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/awards?hierarchyNodeId=${hierarchyNodeId}`) as Observable<IAwardDto[]>;
  }

  public getAward(params: IInstitutionManagerProgramParams & { awardId: string }) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getAward(params)),
      tap(award => {
        if (award === undefined) {
          this.store.dispatch(ManagerAwardsActions.LoadAwardAction(params));
        }
      }),
      filter(award => award != null)
    );
  }

  public getAwardEffect({
    institutionId,
    managerId,
    programId,
    awardId
  }: IInstitutionManagerProgramParams & { awardId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/awards/${awardId}`) as Observable<IAwardDto>;
  }

  public createAward(params: IInstitutionManagerProgramParams & { awardCreate: IAwardCreateDto }) {
    this.store.dispatch(ManagerAwardsActions.CreateAwardAction(params));
    return this.dispatcher.pipe(
      ofType(ManagerAwardsActions.CreateAwardSuccessAction, ManagerAwardsActions.CreateAwardErrorAction),
      take(1),
      map(action => {
        if (action.type === ManagerAwardsActions.CreateAwardSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public createAwardEffect({
    institutionId,
    managerId,
    programId,
    awardCreate
  }: IInstitutionManagerProgramParams & { awardCreate: IAwardCreateDto }) {
    return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/awards`, awardCreate);
  }

  public updateAward(params: IInstitutionManagerProgramParams & { awardId: string, awardUpdate: IAwardUpdateDto }) {
    this.store.dispatch(ManagerAwardsActions.UpdateAwardAction(params));
    return this.dispatcher.pipe(
      ofType(ManagerAwardsActions.UpdateAwardSuccessAction, ManagerAwardsActions.UpdateAwardErrorAction),
      take(1),
      map(action => {
        if (action.type === ManagerAwardsActions.UpdateAwardSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateAwardEffect({
    institutionId,
    managerId,
    programId,
    awardId,
    awardUpdate
  }: IInstitutionManagerProgramParams & { awardId: string, awardUpdate: IAwardUpdateDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/awards/${awardId}`, awardUpdate);
  }

  public deleteAward(params: IInstitutionManagerProgramParams & { awardId: string }) {
    this.store.dispatch(ManagerAwardsActions.DeleteAwardAction(params));
    return this.dispatcher.pipe(
      ofType(ManagerAwardsActions.DeleteAwardSuccessAction, ManagerAwardsActions.DeleteAwardErrorAction),
      take(1),
      map(action => {
        if (action.type === ManagerAwardsActions.DeleteAwardSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteAwardEffect({
    institutionId,
    managerId,
    programId,
    awardId
  }: IInstitutionManagerProgramParams & { awardId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/awards/${awardId}`);
  }
}
