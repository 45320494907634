<ng-container *ngIf="programSettings$ | async as programSettings">
    <div class="modal-header">
        <h5 class="modal-title">Pay By Check</h5>
        <button (click)="close(false)" type="button" class="close" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>
            <ng-container *ngIf="data.invoice.creditsPayableTo?.hierarchyNodeName != null">Payment for this invoice will
                be collected by {{data.invoice.creditsPayableTo?.hierarchyNodeName}}
                {{data.invoice.creditsPayableTo.hierarchyNodeType}}.</ng-container>
            By applying this option you are responsible for providing a check as payment.
            This may cause a delay in processing versus other methods of payment. Are you sure you want to continue?
        </p>

        <ng-container *ngIf="programSettings.memberCreditsRequireTermsOfPayment === true">
            <ng4h-consent class="col-md-12 ng4h-margin-top" [hideGuardianSignature]="data.member.enrollmentRoleType == null && 
    data.member.memberRole === eMemberRole.Adult ||
     data.member.enrollmentRoleType === eEnrollmentRoleType.Volunteer ||
      data.member.enrollmentRoleType == eEnrollmentRoleType.Participant &&
       data.member.memberRole === eMemberRole.Adult" [consents]="[data.invoice.termsOfPaymentConsent]"
                [showAsManagerConsent]="(isManager$ | async)" (allSigned)="paymentSigned($event)">
            </ng4h-consent>
        </ng-container>
    </div>
    <div class="modal-footer">
        <button [disabled]="programSettings.memberCreditsRequireTermsOfPayment === true && paymentConsentSigned != true"
            (click)="confirm()" class="btn btn-primary">Confirm</button>
        <button (click)="close(false)" class="btn btn-secondary">Cancel</button>
    </div>
</ng-container>