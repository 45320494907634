<div class="modal-header">
    <h5 class="modal-title">Select a Manager</h5>
    <button (click)="close()" type="button" class="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <ng4h-manager-search-autocomplete (managerSelected)="managerSelected($event)"></ng4h-manager-search-autocomplete>
</div>
<div class="modal-footer">
    <button [disabled]="selectedManager == null" (click)="select()" class="btn btn-primary">Select</button>
    <button (click)="close()" class="btn btn-secondary">Cancel</button>
</div>