import { ICustomReportDescriptorDto, IReportFolderState } from '.';

export interface IReportFolderDto {
    reportFolderId: string;
    reportFolderState: IReportFolderState;
    reports?: ICustomReportDescriptorDto[];
    reportFolderLabel?: string;
    trash: boolean;
    shared: boolean;
}
