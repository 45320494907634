import { Pipe, PipeTransform } from '@angular/core';

import { eFilterGroupOperator } from '../models';

@Pipe({
    name: 'booleanOperator',
    pure: true
})
export class BooleanOperatorPipe implements PipeTransform {
    public transform(value: eFilterGroupOperator): string {
        switch (value) {
            case eFilterGroupOperator.AND:
                return 'AND';
            case eFilterGroupOperator.OR:
                return 'OR';
            default:
                return value;
        }
    }
}
