import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import {
    getFairEntryIntegrations,
    getFairEntryPendingIntegrations,
    ManagerFeatureStore
} from 'app/core/containers/admin/manager/manager-feature.reducer';
import { ManagerFairEntryActions } from 'app/core/containers/admin/manager/store/fair-entry';
import {
    IFairEntryIntegrationDto,
    IInstitutionManagerProgramHierarchyParams,
    IInstitutionManagerProgramParams,
    IManagerCommentDto
} from 'app/core/models';
import { filter, map, Observable, of, take, tap } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ManagerFairEntryService {

    constructor(private store: Store<ManagerFeatureStore>, private httpClient: HttpClient, private dispatcher: ActionsSubject) {
    }

    public getIntegrations(params: IInstitutionManagerProgramHierarchyParams) {
        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getFairEntryIntegrations(params)),
            tap(integrations => {
                if (integrations === undefined) {
                    this.store.dispatch(ManagerFairEntryActions.ManagerFairEntryGetIntegrationsAction(params));
                }
            }),
            filter(integrations => integrations != null)
        );
    }

    public getIntegrationsEffect({
        institutionId,
        managerId,
        programId,
        hierarchyNodeId
    }: IInstitutionManagerProgramHierarchyParams) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/fair-entry-integrations/${hierarchyNodeId}`) as Observable<IFairEntryIntegrationDto[]>;
    }

    public getPendingIntegrations(params: IInstitutionManagerProgramHierarchyParams) {
        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getFairEntryPendingIntegrations(params)),
            tap(integrations => {
                if (integrations === undefined) {
                    this.store.dispatch(ManagerFairEntryActions.ManagerFairEntryGetPendingIntegrationsAction(params));
                }
            }),
            filter(integrations => integrations != null)
        );
    }

    public getPendingIntegrationsEffect({
        institutionId,
        managerId,
        programId,
        hierarchyNodeId
    }: IInstitutionManagerProgramHierarchyParams) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/fair-entry-integrations/${hierarchyNodeId}/pending`) as Observable<IFairEntryIntegrationDto[]>;
    }

    public acceptIntegration(params: IInstitutionManagerProgramParams & { fairEntryIntegrationId: string, managerComment: IManagerCommentDto }) {
        this.store.dispatch(ManagerFairEntryActions.ManagerFairEntryAcceptIntegrationAction(params));
        return this.dispatcher.pipe(
            ofType(ManagerFairEntryActions.ManagerFairEntryAcceptIntegrationSuccessAction, ManagerFairEntryActions.ManagerFairEntryAcceptIntegrationErrorAction),
            take(1),
            map(action => {
                if (action.type === ManagerFairEntryActions.ManagerFairEntryAcceptIntegrationSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public acceptIntegrationEffect({
        institutionId,
        managerId,
        programId,
        fairEntryIntegrationId,
        managerComment
    }: IInstitutionManagerProgramParams & { fairEntryIntegrationId: string, managerComment: IManagerCommentDto }) {
        return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/fair-entry-integrations/${fairEntryIntegrationId}/accept`, managerComment);
    }

    public rejectIntegration(params: IInstitutionManagerProgramParams & { fairEntryIntegrationId: string, managerComment: IManagerCommentDto }) {
        this.store.dispatch(ManagerFairEntryActions.ManagerFairEntryRejectIntegrationAction(params));
        return this.dispatcher.pipe(
            ofType(ManagerFairEntryActions.ManagerFairEntryRejectIntegrationSuccessAction, ManagerFairEntryActions.ManagerFairEntryRejectIntegrationErrorAction),
            take(1),
            map(action => {
                if (action.type === ManagerFairEntryActions.ManagerFairEntryRejectIntegrationSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public rejectIntegrationEffect({
        institutionId,
        managerId,
        programId,
        fairEntryIntegrationId,
        managerComment
    }: IInstitutionManagerProgramParams & { fairEntryIntegrationId: string, managerComment: IManagerCommentDto }) {
        return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/fair-entry-integrations/${fairEntryIntegrationId}/reject`, managerComment);
    }

    public cancelIntegration(params: IInstitutionManagerProgramParams & { fairEntryIntegrationId: string, managerComment: IManagerCommentDto }) {
        this.store.dispatch(ManagerFairEntryActions.ManagerFairEntryCancelIntegrationAction(params));
        return this.dispatcher.pipe(
            ofType(ManagerFairEntryActions.ManagerFairEntryCancelIntegrationSuccessAction, ManagerFairEntryActions.ManagerFairEntryCancelIntegrationErrorAction),
            take(1),
            map(action => {
                if (action.type === ManagerFairEntryActions.ManagerFairEntryCancelIntegrationSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public cancelIntegrationEffect({
        institutionId,
        managerId,
        programId,
        fairEntryIntegrationId,
        managerComment
    }: IInstitutionManagerProgramParams & { fairEntryIntegrationId: string, managerComment: IManagerCommentDto }) {
        return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/fair-entry-integrations/${fairEntryIntegrationId}/cancel`, managerComment);
    }
}
