<div class="d-flex flex-column page-container">
  <div class="d-flex flex-column">
    <ng-content select="[admin-page-banner]"></ng-content>
  </div>
  <div class="d-flex flex-column">
    <ng-content select="ng4h-admin-page-header"></ng-content>
  </div>

  <div class="d-flex flex-column page-content">
    <ng-content select="ng4h-admin-page-content"></ng-content>
  </div>
</div>