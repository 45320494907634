import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { EmailTemplateService } from 'app/core/services';
import { catchError, exhaustMap, groupBy, map, mergeMap, Observable, of } from 'rxjs';

import { EmailTemplateActions } from '.';
import { stringKeys } from '../rxjs.utils';

@Injectable()
export class EmailTemplateEffect {

  public loadProgramEmailTemplates = createEffect(() => this.actions
  .pipe(
    ofType(EmailTemplateActions.EmailTemplateProgramLoadAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.emailTemplateService.loadProgramEmailTemplatesEffect(payload).pipe(
            map(emailTemplates => emailTemplates == null ? [] : emailTemplates),
            map(emailTemplates => EmailTemplateActions.EmailTemplateProgramLoadSuccessAction({
              ...payload,
              templates: emailTemplates
            })),
            catchError(error => of(EmailTemplateActions.EmailTemplateProgramLoadErrorAction({ error })))
          );
        })
      );
    })
  ));

  public updateProgramEmailTemplate = createEffect(() => this.actions
  .pipe(
    ofType(EmailTemplateActions.EmailTemplateProgramUpdateAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.emailTemplateService.updateProgramEmailTemplateEffect(payload).pipe(
            map(() => EmailTemplateActions.EmailTemplateProgramUpdateSuccessAction(payload)),
            catchError(error => of(EmailTemplateActions.EmailTemplateProgramUpdateErrorAction({ error })))
          );
        })
      );
    })
  ));

  public programTemplateUpdateSuccess$: Observable<Action> = createEffect(() => this.actions
  .pipe(
    ofType(EmailTemplateActions.EmailTemplateProgramUpdateSuccessAction),
    map(payload => EmailTemplateActions.EmailTemplateProgramInvalidateCache())
  ));

  public loadEventEmailTemplates = createEffect(() => this.actions
  .pipe(
    ofType(EmailTemplateActions.EmailTemplateEventLoadAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.emailTemplateService.loadEventEmailTemplatesEffect(payload).pipe(
            map(emailTemplates => emailTemplates == null ? [] : emailTemplates),
            map(emailTemplates => EmailTemplateActions.EmailTemplateEventLoadSuccessAction({
              ...payload,
              templates: emailTemplates
            })),
            catchError(error => of(EmailTemplateActions.EmailTemplateEventLoadErrorAction({ error })))
          );
        })
      );
    })
  ));

  public updateEventEmailTemplate = createEffect(() => this.actions
  .pipe(
    ofType(EmailTemplateActions.EmailTemplateEventUpdateAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.emailTemplateService.updateEventEmailTemplateEffect(payload).pipe(
            map(() => EmailTemplateActions.EmailTemplateEventUpdateSuccessAction(payload)),
            catchError(error => of(EmailTemplateActions.EmailTemplateEventUpdateErrorAction({ error })))
          );
        })
      );
    })
  ));

  public deleteEventEmailTemplate = createEffect(() => this.actions
  .pipe(
    ofType(EmailTemplateActions.EmailTemplateEventDeleteAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.emailTemplateService.deleteEventEmailTemplateEffect(payload).pipe(
            map(() => EmailTemplateActions.EmailTemplateEventDeleteSuccessAction(payload)),
            catchError(error => of(EmailTemplateActions.EmailTemplateEventDeleteErrorAction({ error })))
          );
        })
      );
    })
  ));

  public eventTemplateUpdateSuccess$: Observable<Action> = createEffect(() => this.actions
  .pipe(
    ofType(EmailTemplateActions.EmailTemplateEventUpdateSuccessAction, EmailTemplateActions.EmailTemplateEventDeleteSuccessAction),
    map(payload => EmailTemplateActions.EmailTemplateEventInvalidateCache())
  ));

  // public loadEmailWrapper = createEffect(() => this.actions
  //   .pipe(
  //     ofType(EmailTemplateActions.EmailTemplateWrapperLoadAction),
  //     switchMap(payload => {
  //       return this.emailTemplateService.loadWrapperEffect(payload.institutionId, payload.systemManagerId, payload.programId).pipe(
  //         map(wrapper => wrapper === null ? [] : wrapper),
  //         map(wrapper => EmailTemplateActions.EmailTemplateWrapperLoadSuccessAction({ ...payload, wrapper: wrapper })),
  //         catchError(error => of(EmailTemplateActions.EmailTemplateWrapperLoadErrorAction({ error })))
  //       );
  //     })
  //   ));

  constructor(private actions: Actions, private emailTemplateService: EmailTemplateService) {
  }
}
