import { Action, createReducer, on } from '@ngrx/store';
import { IHierarchyCountyArea } from 'app/core/models';

import { CountyAreasActions } from '.';
import { mergeImmutable } from '../utils';

export interface CountyAreasState {
  countyAreas: { [institutionId: string]: { [programId: string]: IHierarchyCountyArea[] } };
}

const initialState: CountyAreasState = {
  countyAreas: undefined
};

const reducer = createReducer(
  initialState,
  on(CountyAreasActions.CountyAreasLoadSuccessAction, (state, { institutionId, programId, countyAreas }) => {
    const newCountyAreasByProgram = mergeImmutable(
      { [institutionId]: { [programId]: countyAreas } },
      state.countyAreas
    );
    return {
      ...state,
      countyAreas: newCountyAreasByProgram
    };
  })
);

export function countyAreasReducer(state: CountyAreasState | undefined, actions: Action) {
  return reducer(state, actions);
}


export const countyAreas = (state: CountyAreasState) => state.countyAreas;
