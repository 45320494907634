import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, Store } from '@ngrx/store';
import { AppState } from 'app/app.reducers';
import { IRecoverAccount } from 'app/core/models';
import { map, Observable, take } from 'rxjs';

import { UserActions } from '../containers/user';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RecoverAccountService {

  constructor(private store: Store<AppState>, private httpClient: HttpClient, private dispatcher: ActionsSubject) {

  }

  public resetPassword(email: string) {
    this.store.dispatch(UserActions.ResetPasswordAction({ email: email }));

    return this.dispatcher.pipe(
      ofType(UserActions.ResetPasswordSuccessAction, UserActions.ResetPasswordErrorAction),
      take(1),
      map(action => {
        if (action.type === UserActions.ResetPasswordSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public resetPasswordEffect(email: string): Observable<object> {
    return this.httpClient.get(`${environment.apiUri}/api/user-accounts/recover-account?email=${email}`) as Observable<object>;
  }

  public recoverAccount(code: string, securityStamp: string) {
    this.store.dispatch(UserActions.RecoverAccountAction({ code: code, securityStamp: securityStamp }));
    return this.dispatcher.pipe(
      ofType(UserActions.RecoverAccountSuccessAction, UserActions.RecoverAccountErrorAction),
      take(1),
      map(action => {
        if (action.type === UserActions.RecoverAccountSuccessAction.type) {
          return action.recoverAccount;
        } else {
          throw action;
        }
      })
    );
  }

  public recoverAccountEffect(code: string, securityStamp: string): Observable<IRecoverAccount> {
    return this.httpClient.get(`${environment.apiUri}/api/user-accounts/recover-account/${securityStamp}?code=${code}`) as Observable<IRecoverAccount>;
  }
}
