import { Injectable } from '@angular/core';

import { LocalStorageService } from './local-storage.service';
import { RouterService } from './router.service';

@Injectable({
  providedIn: 'root'
})
export class AdService {

  // private SEVEN_DAYS_IN_MS = 604800000;

  constructor(private localStorageService: LocalStorageService, private routerService: RouterService) {
  }

  public shouldShowAd() {
    return this.localStorageService.memberAdStatus(this.routerService.userAccountId);
  }

  public hideAd() {
    this.localStorageService.hideMemberAd(this.routerService.userAccountId);
  }
}
