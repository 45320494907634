import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { SystemManagerService } from 'app/core/services';
import { SystemManagerRolloverService } from 'app/core/services/system-manager-rollover.service';
import { catchError, exhaustMap, groupBy, map, mergeMap, Observable, of } from 'rxjs';

import { SystemManagerActions } from '.';
import { stringKeys } from '../rxjs.utils';



@Injectable()
export class SystemManagerEffects {
  public getSystemManagers$: Observable<Action> = createEffect(() => this.actions
    .pipe(
      ofType(SystemManagerActions.GetSystemManagersAction),
      groupBy(stringKeys),
      mergeMap(groupedBy => {
        return groupedBy.pipe(
          exhaustMap(payload => {
            return this.systemManagerService.loadSystemManagersEffect(payload).pipe(
              map((systemManagers) => SystemManagerActions.GetSystemManagersSuccessAction({ ...payload, systemManagers })),
              catchError(error => of(SystemManagerActions.GetSystemManagersErrorAction({ error })))
            );
          })
        );
      })
    ));

  public getSystemManager$: Observable<Action> = createEffect(() => this.actions
    .pipe(
      ofType(SystemManagerActions.GetSystemManagerAction),
      groupBy(stringKeys),
      mergeMap(groupedBy => {
        return groupedBy.pipe(
          exhaustMap(payload => {
            return this.systemManagerService.loadSystemManagerEffect(payload).pipe(
              map((systemManager) => SystemManagerActions.GetSystemManagerSuccessAction({ ...payload, systemManager })),
              catchError(error => of(SystemManagerActions.GetSystemManagerErrorAction({ error })))
            );
          })
        );
      })
    ));

  public updateSystemManager$: Observable<Action> = createEffect(() => this.actions
    .pipe(
      ofType(SystemManagerActions.UpdateSystemManagerAction),
      groupBy(stringKeys),
      mergeMap(groupedBy => {
        return groupedBy.pipe(
          exhaustMap(payload => {
            return this.systemManagerService.updateSystemManagerEffect(payload).pipe(
              map((systemManager) => SystemManagerActions.UpdateSystemManagerSuccessAction(payload)),
              catchError(error => of(SystemManagerActions.UpdateSystemManagerErrorAction({ error })))
            );
          })
        );
      })
    ));

  public getSystemPermissionTypes$: Observable<Action> = createEffect(() => this.actions
    .pipe(
      ofType(SystemManagerActions.GetSystemManagerPermissionTypesAction),
      groupBy(stringKeys),
      mergeMap(groupedBy => {
        return groupedBy.pipe(
          exhaustMap(payload => {
            return this.systemManagerService.loadSystemManagerPermissionTypesEffect().pipe(
              map((types) => SystemManagerActions.GetSystemManagerPermissionTypesSuccessAction({ types })),
              catchError(error => of(SystemManagerActions.GetSystemManagerPermissionTypesErrorAction({ error })))
            );
          })
        );
      })
    ));


  public createNewSystemManager$: Observable<Action> = createEffect(() => this.actions
    .pipe(
      ofType(SystemManagerActions.CreateSystemManagerAction),
      groupBy(stringKeys),
      mergeMap(groupedBy => {
        return groupedBy.pipe(
          exhaustMap(payload => {
            return this.systemManagerService.createSystemManagerEffect(payload).pipe(
              map(() => SystemManagerActions.CreateSystemManagerSuccessAction(payload)),
              catchError(error => of(SystemManagerActions.CreateSystemManagerErrorAction({ error })))
            );
          })
        );
      })
    ));


  public updateSystemManagerPermissions$: Observable<Action> = createEffect(() => this.actions
    .pipe(
      ofType(SystemManagerActions.UpdateSystemManagerPermissionsAction),
      groupBy(stringKeys),
      mergeMap(groupedBy => {
        return groupedBy.pipe(
          exhaustMap(payload => {
            return this.systemManagerService.updateSystemManagerPermissionsEffect(payload).pipe(
              map(() => SystemManagerActions.UpdateSystemManagerPermissionsSuccessAction(payload)),
              catchError(error => of(SystemManagerActions.UpdateSystemManagerPermissionsErrorAction({ error })))
            );
          })
        );
      })
    ));


  public getSystemMangerRollovers$: Observable<Action> = createEffect(() => this.actions
    .pipe(
      ofType(SystemManagerActions.GetSystemManagerRolloversAction),
      groupBy(stringKeys),
      mergeMap(groupedBy => {
        return groupedBy.pipe(
          exhaustMap(payload => {
            return this.systemMangerRolloverService.getRolloversEffect(payload).pipe(
              map((rollovers) => SystemManagerActions.GetSystemManagerRolloversSuccessAction({ rollovers, ...payload })),
              catchError(error => of(SystemManagerActions.GetSystemManagerRolloversErrorAction({ error })))
            );
          })
        );
      })
    ));
  public startSystemMangerRollover$: Observable<Action> = createEffect(() => this.actions
    .pipe(
      ofType(SystemManagerActions.StartSystemManagerRolloverAction),
      groupBy(stringKeys),
      mergeMap(groupedBy => {
        return groupedBy.pipe(
          exhaustMap(payload => {
            return this.systemMangerRolloverService.startRolloverEffect(payload).pipe(
              map(() => SystemManagerActions.StartSystemManagerRolloverSuccessAction(payload)),
              catchError(error => of(SystemManagerActions.StartSystemManagerRolloverErrorAction({ error })))
            );
          })
        );
      })
    ));

  public removeFromProgram$: Observable<Action> = createEffect(() => this.actions
    .pipe(
      ofType(SystemManagerActions.RemoveFromProgramAction),
      groupBy(stringKeys),
      mergeMap(groupedBy => {
        return groupedBy.pipe(
          exhaustMap(payload => {
            return this.systemManagerService.removeSystemManagerFromProgramEffect(payload).pipe(
              map(() => SystemManagerActions.RemoveFromProgramSuccessAction(payload)),
              catchError(error => of(SystemManagerActions.RemoveFromProgramErrorAction({ error })))
            );
          })
        );
      })
    ));



  constructor(private actions: Actions, private systemManagerService: SystemManagerService, private systemMangerRolloverService: SystemManagerRolloverService) { }
}
