export enum eStandardReportFilter {
    IncludeAllYears = 'IncludeAllYears',
    MemberFlagged = 'MemberFlagged',
    Gender = 'Gender',
    MemberParticipationStatus = 'MemberParticipationStatus',
    Role = 'Role',
    SchoolGrade = 'SchoolGrade',
    EnrollmentRoles = 'EnrollmentRoles',
    DateRange = 'DateRange',
    GroupByCounty = 'GroupByCounty'
}
