import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ePaymentGatewayType, IGatewayConfigurationDto, ITokenizedCardDto } from 'app/core/models';
import { CommonToastrService } from 'app/core/services';
import { StripeScriptTag } from 'stripe-angular';

/// <reference types="stripe-v3" />
@Component({
  selector: 'ng4h-stripe-credit-card-input',
  templateUrl: './stripe-credit-card-input.component.html',
  styleUrls: ['./stripe-credit-card-input.component.scss']
})
export class StripeCreditCardInputComponent implements OnInit {

  @Input() gatewayConfig: IGatewayConfigurationDto;
  @Output() tokenizationSuccess: EventEmitter<ITokenizedCardDto>;
  public cardCaptureReady = false;
  public invalidError: string;
  public cardDetailsFilledOut = false;
  public form: FormGroup;

  public options: stripe.elements.ElementsOptions = {
    style: {

      base: {
        iconColor: '#555459',
        color: '#555459',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
      },

    }
  };

  constructor(
    private stripeScriptTag: StripeScriptTag,
    private toastrService: CommonToastrService,
    private fb: FormBuilder
  ) {
    this.tokenizationSuccess = new EventEmitter();
  }

  ngOnInit() {
    this.form = this.fb.group({
      name: [null, Validators.required]
    });

    if (!this.stripeScriptTag.StripeInstance) {
      if (this.gatewayConfig.publishableKey == null || (this.gatewayConfig.tokenizeUsingSubmerchant === true && this.gatewayConfig.submerchantId == null)) {
        this.toastrService.error('Stripe gateway improperly configured.');
        return;
      }
      if (this.gatewayConfig.tokenizeUsingSubmerchant === true) {
        this.stripeScriptTag.setPublishableKey(this.gatewayConfig.publishableKey, { stripeAccount: this.gatewayConfig.submerchantId });
      } else {
        this.stripeScriptTag.setPublishableKey(this.gatewayConfig.publishableKey);
      }
    }
  }

  changed() {

  }
  onStripeInvalid(error: Error) {
    this.toastrService.error('Validation Error', error);
  }

  onStripeError(error: stripe.Error) {
    this.toastrService.error('Stripe error ' + error.code + ': ' + error.message);
  }

  // setPaymentMethod(token: stripe.paymentMethod.PaymentMethod) {
  //   this.tokenizationSuccess.emit({
  //     gateway: ePaymentGatewayType.Stripe,
  //     cardBrand: token.card.brand,
  //     vaultTokenRegistrationId: token.id,
  //     cardholderName: token.billing_details.name,
  //     expirationMonth: token.card.exp_month,
  //     expirationYear: token.card.exp_year,
  //     address: {
  //       addressLine1: token.billing_details.address.line1,
  //       addressLine2: token.billing_details.address.line2,
  //       city: token.billing_details.address.city,
  //       state: token.billing_details.address.state as any,
  //       postalCode: token.billing_details.address.postal_code,
  //       country: token.billing_details.address.country as any
  //     }
  //   });
  // }

  setStripeToken(token: stripe.Token) {
    this.tokenizationSuccess.emit({
      gateway: ePaymentGatewayType.Stripe,
      cardBrand: token.card.brand,
      vaultTokenRegistrationId: token.id,
      expirationMonth: token.card.exp_month,
      expirationYear: token.card.exp_year,
      address: null
    });
  }


  cardMounted() {

  }

  public add() {

  }

}
