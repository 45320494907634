export enum eColumnValueType {
    Currency = 'Currency',
    Date = 'Date',
    Decimal = 'Decimal',
    Email = 'Email',
    Integer = 'Integer',
    Phone = 'Phone',
    Text = 'Text',
    TrueFalse = 'TrueFalse',
    FormControlItemOption = 'FormControlItemOption',
    Enum = 'Enum',
}
