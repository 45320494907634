import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { AppState } from 'app/app.reducers';
import { ManagerDiscountActions } from 'app/core/containers/admin/manager/enrollments/discounts/store';
import { getManagerFamilyCountyDiscounts } from 'app/core/containers/admin/manager/manager-feature.reducer';
import { discounts } from 'app/core/containers/admin/manager/store/finances/manager-finances.reducer';
import { IEnrollmentFamilyDiscountDto, IFamilyCountyDiscount, IInstitutionManagerProgramParams } from 'app/core/models';
import { filter, map, Observable, of, take, tap } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ManagerDiscountsService {

  constructor(private httpClient: HttpClient,
    private dispatcher: ActionsSubject,
    private store: Store<AppState>
  ) {

  }

  // public getFamilyDiscounts(params: IInstitutionManagerProgramParams & { programYearId: string }): Observable<IEnrollmentFamilyDiscountDto[]> {

  //   if (Object.keys(params).find(key => params[key] == null) != null) {
  //     return of(null);
  //   }
  //   return this.store.pipe(
  //     select(getManagerFamilyDiscounts(params)),
  //     tap(discounts => {
  //       if (discounts === undefined) {
  //         this.store.dispatch(ManagerDiscountActions.ManagerLoadFamilyDiscountsAction(params));
  //       }
  //     }),
  //     filter(discounts => discounts != null),
  //   );
  // }

  // public loadFamilyDiscountsEffect({ institutionId, managerId, programId, programYearId }: IInstitutionManagerProgramParams & { programYearId: string }): Observable<IEnrollmentFamilyDiscountDto[]> {
  //   return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/family-discounts/${programYearId}`)
  //     .pipe(filter(data => data !== null)) as Observable<IEnrollmentFamilyDiscountDto[]>;
  // }

  // public updateFamilyDiscounts(params: IInstitutionManagerProgramParams & { programYearId: string, discounts: IEnrollmentFamilyDiscountDto[] }) {
  //   this.store.dispatch(ManagerDiscountActions.ManagerUpdateFamilyDiscountsAction(params));

  //   return this.dispatcher.pipe(
  //     ofType(ManagerDiscountActions.ManagerUpdateFamilyDiscountsSuccessAction, ManagerDiscountActions.ManagerUpdateFamilyDiscountsErrorAction),
  //     take(1),
  //     map(action => {
  //       if (action.type === ManagerDiscountActions.ManagerUpdateFamilyDiscountsSuccessAction.type) {
  //         return action.discounts;
  //       } else {
  //         throw action.error;
  //       }
  //     })
  //   );
  // }

  // public updateFamilyDiscountEffect({ institutionId, managerId, programId, programYearId, discounts }: IInstitutionManagerProgramParams & { programYearId: string, discounts: IEnrollmentFamilyDiscountDto[] }): Observable<IEnrollmentFamilyDiscountDto[]> {
  //   return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/family-discounts/${programYearId}`, discounts) as Observable<IEnrollmentFamilyDiscountDto[]>;
  // }

  public getFamilyDiscounts(params: IInstitutionManagerProgramParams & { programYearId: string }): Observable<IFamilyCountyDiscount[]> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getManagerFamilyCountyDiscounts(params)),
      tap(dx => {
        if (dx === undefined) {
          this.store.dispatch(ManagerDiscountActions.ManagerLoadFamilyCountyDiscountsAction(params));
        }
      }),
      filter(dx => dx != null)
    );
  }

  public getFamilyCountyDiscountsEffect({
    institutionId,
    managerId,
    programId,
    programYearId
  }: IInstitutionManagerProgramParams & { programYearId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/family-discounts/${programYearId}`) as Observable<IFamilyCountyDiscount[]>;
  }

  public updateFamilyDiscounts(params: IInstitutionManagerProgramParams & { programYearId: string, hierarchyNodeId: string, discounts: IEnrollmentFamilyDiscountDto[] }) {
    this.store.dispatch(ManagerDiscountActions.ManagerUpdateFamilyCountyDiscountsAction(params));

    return this.dispatcher.pipe(
      ofType(ManagerDiscountActions.ManagerUpdateFamilyCountyDiscountsSuccessAction, ManagerDiscountActions.ManagerUpdateFamilyCountyDiscountsErrorAction),
      take(1),
      map(action => {
        if (action.type === ManagerDiscountActions.ManagerUpdateFamilyCountyDiscountsSuccessAction.type) {
          return action.discounts;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateFamilyCountyDiscountEffect({
    institutionId,
    managerId,
    programId,
    programYearId,
    hierarchyNodeId,
    discounts
  }: IInstitutionManagerProgramParams & { programYearId: string, hierarchyNodeId: string, discounts: IEnrollmentFamilyDiscountDto[] }): Observable<IEnrollmentFamilyDiscountDto[]> {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/family-discounts/${programYearId}?hierarchyNodeId=${hierarchyNodeId}`, discounts) as Observable<IEnrollmentFamilyDiscountDto[]>;
  }

}
