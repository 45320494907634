export enum eEmailType {
    Unknown = 'Unknown',
    NewSystemManager = 'NewSystemManager',
    NewManager = 'NewManager',
    NewFamily = 'NewFamily',
    NewMember = 'NewMember',
    EnrollmentSubmitted = 'EnrollmentSubmitted',
    EnrollmentApproved = 'EnrollmentApproved',
    EnrollmentRejected = 'EnrollmentRejected',
    EnrollmentSentBack = 'EnrollmentSentBack',
    PaymentProcessedSuccess = 'PaymentProcessedSuccess',
    PaymentProcessedFailure = 'PaymentProcessedFailure',
    PaymentRefundedSuccess = 'PaymentRefundedSuccess',
    EventRegistrationSubmitted = 'EventRegistrationSubmitted',
    EventRegistrationApproved = 'EventRegistrationApproved',
    EventRegistrationSentBack = 'EventRegistrationSentBack',
    EventRegistrationDeclined = 'EventRegistrationDeclined',
    ManagerHierarchyAssociationAdded = 'ManagerHierarchyAssociationAdded',
    ContactCountyAgent = 'ContactCountyAgent',
    Broadcast = 'Broadcast',
    ManagerPasswordReset = 'ManagerPasswordReset',
    ScreeningApproved = 'ScreeningApproved',
    ScreeningRejected = 'ScreeningRejected',
    ScreeningRestricted = 'ScreeningRestricted',
    ScreeningSentBack = 'ScreeningSentBack',
    EnrollmentCreditsDue = 'EnrollmentCreditsDue',
    EventRegistrationCreditsDue = 'EventRegistrationCreditsDue',
    MemberProgramYearActive = 'MemberProgramYearActive',
    EventRegistrationActive = 'EventRegistrationActive',
    ManagerQueuedReportReady = 'ManagerQueuedReportReady',
    ManagerQueuedFileDownloadReady = 'ManagerQueuedFileDownloadReady',
    BroadcastSms = 'BroadcastSms',
    AnimalApproved = 'AnimalApproved',
    AnimalSentBack = 'AnimalSentBack',
    FairIntegration = 'FairIntegration',
}
