import { Pipe, PipeTransform } from '@angular/core';
import { map, Observable } from 'rxjs';

import { eEnrollmentStatus } from '../models';
import { RouterService } from '../services';
import { ProgramSettingsService } from '../services/program-settings.service';


@Pipe({
    name: 'enrollmentStatus$',
    pure: true
})


export class EnrollmentStatusPipe implements PipeTransform {

    constructor(private programSettingsService: ProgramSettingsService, private routerService: RouterService) {
    }

    transform(enrollmentStatus: eEnrollmentStatus, institutionId: string = this.routerService.institutionId, programId: string = this.routerService.programId): Observable<any> {
        return this.programSettingsService.getProgramSettings({ institutionId, programId }).pipe(
            map(programSettings => programSettings.enrollmentStatuses[enrollmentStatus])
        );
    }
}
