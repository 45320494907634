import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import {
  getProgramAssociations,
  getSystemManagerTrainingCourse,
  getSystemManagerTrainingLesson,
  getSystemManagerTrainings,
  SystemManagerFeatureStore
} from 'app/core/containers/admin/system-manager/system-manager-feature.reducers';
import { SystemManagerTrainingActions } from 'app/core/containers/admin/system-manager/training/store';
import {
  eEnrollmentRoleType,
  IInstitutionProgramTrainingCreateDto,
  IInstitutionProgramTrainingDto,
  IInstitutionSystemManagerProgramParams,
  ITrainingCourseDto,
  ITrainingDto,
  ITrainingLessonDto,
  ITrainingLessonUpdateDto,
  ITrainingUpdateDto
} from 'app/core/models';
import { update } from 'immutable';
import { filter, map, Observable, of, take, tap } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class SystemManagerTrainingsService {

  constructor(private httpClient: HttpClient, private store: Store<SystemManagerFeatureStore>, private dispatcher: ActionsSubject) {
  }

  public getSystemManagerTrainings(params: IInstitutionSystemManagerProgramParams): Observable<ITrainingDto[]> {

    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getSystemManagerTrainings(params)),
      tap(trainings => {
        if (trainings === undefined) {
          this.store.dispatch(SystemManagerTrainingActions.SystemManagerLoadTrainingsAction(params));
        }
      })
    );

  }

  public loadSystemManagerTrainingsEffect({
    institutionId,
    systemManagerId,
    programId
  }: IInstitutionSystemManagerProgramParams): Observable<ITrainingDto[]> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/trainings`) as Observable<ITrainingDto[]>;
  }

  public getSystemManagerTrainingById({
    institutionId,
    systemManagerId,
    programId,
    trainingId
  }: IInstitutionSystemManagerProgramParams & { trainingId: string }): Observable<ITrainingDto> {
    return this.getSystemManagerTrainings({ institutionId, systemManagerId, programId }).pipe(
      filter(trainings => trainings != null),
      map(trainings => trainings.find(element => element.trainingId === trainingId))
    );
  }

  public updateSystemManagerTrainingLesson(params: IInstitutionSystemManagerProgramParams & { trainingId: string, trainingCourseId: string, trainingLessonId: string, trainingLesson: ITrainingLessonUpdateDto }) {
    this.store.dispatch(SystemManagerTrainingActions.SystemManagerUpdateTrainingLessonAction(params));

    return this.dispatcher.pipe(
      ofType(SystemManagerTrainingActions.SystemManagerUpdateTrainingLessonSuccessAction, SystemManagerTrainingActions.SystemManagerUpdateTrainingLessonErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerTrainingActions.SystemManagerUpdateTrainingLessonSuccessAction.type) {
          return action.trainingLesson;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateSystemManagerTrainingLessonEffect({
    institutionId,
    systemManagerId,
    programId,
    trainingId,
    trainingCourseId,
    trainingLessonId,
    trainingLesson
  }: IInstitutionSystemManagerProgramParams & { trainingId: string, trainingCourseId: string, trainingLessonId: string, trainingLesson: ITrainingLessonUpdateDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/trainings/${trainingId}/courses/${trainingCourseId}/lessons/${trainingLessonId}`, trainingLesson);
  }

  public createSystemManagerTraining(params: IInstitutionSystemManagerProgramParams & { training: ITrainingUpdateDto }) {
    this.store.dispatch(SystemManagerTrainingActions.SystemManagerCreateTrainingAction(params));

    return this.dispatcher.pipe(
      ofType(SystemManagerTrainingActions.SystemManagerCreateTrainingSuccessAction, SystemManagerTrainingActions.SystemManagerCreateTrainingErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerTrainingActions.SystemManagerCreateTrainingSuccessAction.type) {
          return action.training;
        } else {
          throw action.error;
        }
      })
    );
  }

  public createSystemManagerTrainingEffect({
    institutionId,
    systemManagerId,
    programId,
    training
  }: IInstitutionSystemManagerProgramParams & { training: ITrainingUpdateDto }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/trainings`, training);
  }

  public updateSystemManagerTraining(params: IInstitutionSystemManagerProgramParams & { trainingId: string, update: ITrainingUpdateDto }) {

    this.store.dispatch(SystemManagerTrainingActions.SystemManagerUpdateTrainingAction(params));

    return this.dispatcher.pipe(
      ofType(SystemManagerTrainingActions.SystemManagerUpdateTrainingSuccessAction, SystemManagerTrainingActions.SystemManagerUpdateTrainingErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerTrainingActions.SystemManagerUpdateTrainingSuccessAction.type) {
          return action.update;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateSystemManagerTrainingEffect({
    institutionId,
    systemManagerId,
    programId,
    trainingId,
    update
  }: IInstitutionSystemManagerProgramParams & { trainingId: string, update: ITrainingUpdateDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/trainings/${trainingId}`, update);
  }

  public deleteSystemManagerTraining(params: IInstitutionSystemManagerProgramParams & { trainingId: string }) {
    this.store.dispatch(SystemManagerTrainingActions.SystemManagerDeleteTrainingAction(params));

    return this.dispatcher.pipe(
      ofType(SystemManagerTrainingActions.SystemManagerDeleteTrainingSuccessAction, SystemManagerTrainingActions.SystemManagerDeleteTrainingErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerTrainingActions.SystemManagerDeleteTrainingSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteSystemManagerTrainingEffect({
    institutionId,
    systemManagerId,
    programId,
    trainingId
  }: IInstitutionSystemManagerProgramParams & { trainingId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/trainings/${trainingId}`) as Observable<any>;
  }

  public updateTrainingLessonsOrder(params: IInstitutionSystemManagerProgramParams & { trainingId: string, trainingCourseId: string, lessonIds: string[] }) {
    this.store.dispatch(SystemManagerTrainingActions.SystemManagerUpdateTrainingLessonsOrderAction(params));

    return this.dispatcher.pipe(
      ofType(SystemManagerTrainingActions.SystemManagerUpdateTrainingLessonsOrderSuccessAction, SystemManagerTrainingActions.SystemManagerUpdateTrainingLessonsOrderErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerTrainingActions.SystemManagerUpdateTrainingLessonsOrderSuccessAction.type) {
          return action.lessonIds;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateTrainingLessonsOrderEffect({
    institutionId,
    systemManagerId,
    programId,
    trainingId,
    trainingCourseId,
    lessonIds
  }: IInstitutionSystemManagerProgramParams & { trainingId: string, trainingCourseId: string, lessonIds: string[] }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/trainings/${trainingId}/courses/${trainingCourseId}/lesson-order`, lessonIds);
  }

  public createSystemManagerTrainingLesson(params: IInstitutionSystemManagerProgramParams & { trainingId: string, trainingCourseId: string, lesson: ITrainingLessonDto }) {
    this.store.dispatch(SystemManagerTrainingActions.SystemManagerCreateTrainingLessonAction(params));

    return this.dispatcher.pipe(
      ofType(SystemManagerTrainingActions.SystemManagerCreateTrainingLessonSuccessAction, SystemManagerTrainingActions.SystemManagerCreateTrainingLessonErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerTrainingActions.SystemManagerCreateTrainingLessonSuccessAction.type) {
          return action.lesson;
        } else {
          throw action.error;
        }
      })
    );
  }

  public createSystemManagerTrainingLessonEffect({
    institutionId,
    systemManagerId,
    programId,
    trainingId,
    trainingCourseId,
    lesson
  }: IInstitutionSystemManagerProgramParams & { trainingId: string, trainingCourseId: string, lesson: ITrainingLessonDto }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/trainings/${trainingId}/courses/${trainingCourseId}/lessons`, lesson);
  }

  public getTrainingCourse(params: IInstitutionSystemManagerProgramParams & { trainingId: string, trainingCourseId: string }) {

    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getSystemManagerTrainingCourse(params)),
      tap(course => {
        if (course === undefined) {
          this.store.dispatch(SystemManagerTrainingActions.SystemManagerTrainingCourseLoadAction(params));
        }
      })
    );

  }

  public loadTrainingCourseEffect({
    institutionId,
    systemManagerId,
    programId,
    trainingId,
    trainingCourseId
  }: IInstitutionSystemManagerProgramParams & { trainingId: string, trainingCourseId: string }): Observable<ITrainingCourseDto> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/trainings/${trainingId}/courses/${trainingCourseId}`) as Observable<ITrainingCourseDto>;
  }

  public changeTrainingCourseLessonOrder(params: IInstitutionSystemManagerProgramParams & { trainingId: string, trainingCourseId: string, lessonIds: string[] }) {
    this.store.dispatch(SystemManagerTrainingActions.SystemManagerTrainingCourseChangeLessonOrderAction(params));
  }

  public changeTrainingCourseLessonOrderEffect({
    institutionId,
    systemManagerId,
    programId,
    trainingId,
    trainingCourseId,
    lessonIds
  }: IInstitutionSystemManagerProgramParams & { trainingId: string, trainingCourseId: string, lessonIds: string[] }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/trainings/${trainingId}/courses/${trainingCourseId}/lesson-order`, lessonIds);
  }

  public createTrainingCourseEffect({
    institutionId,
    systemManagerId,
    programId,
    trainingId,
    course
  }: IInstitutionSystemManagerProgramParams & { trainingId: string, course: ITrainingCourseDto }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/trainings/${trainingId}/courses`, course);
  }

  public getTrainingLesson(params: IInstitutionSystemManagerProgramParams & { trainingId: string, trainingCourseId: string, trainingLessonId: string }): Observable<ITrainingLessonDto> {

    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getSystemManagerTrainingLesson(params)),
      tap(lesson => {
        if (lesson === undefined) {
          this.store.dispatch(SystemManagerTrainingActions.SystemManagerTrainingLessonLoadAction(params));
        }
      })
    );
  }

  public loadTrainingLessonEffect({
    institutionId,
    systemManagerId,
    programId,
    trainingId,
    trainingCourseId,
    trainingLessonId
  }: IInstitutionSystemManagerProgramParams & { trainingId: string, trainingCourseId: string, trainingLessonId: string }): Observable<ITrainingLessonDto> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/trainings/${trainingId}/courses/${trainingCourseId}/lessons/${trainingLessonId}`) as Observable<ITrainingLessonDto>;
  }

  public createTrainingLesson(params: IInstitutionSystemManagerProgramParams & { trainingId: string, trainingCourseId: string, trainingLessonId: string, lesson: ITrainingLessonDto }) {
    this.store.dispatch(SystemManagerTrainingActions.SystemManagerCreateTrainingLessonAction(params));
  }

  public createTrainingLessonEffect({
    institutionId,
    systemManagerId,
    programId,
    trainingId,
    trainingCourseId,
    lesson
  }: IInstitutionSystemManagerProgramParams & { trainingId: string, trainingCourseId: string, trainingLessonId: string, lesson: ITrainingLessonDto }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/trainings/${trainingId}/courses/${trainingCourseId}/lessons`, lesson);
  }

  public fileUploadTrainingLesson(params: IInstitutionSystemManagerProgramParams & { trainingId: string, trainingCourseId: string, trainingLessonId: string, file: File }) {
    this.store.dispatch(SystemManagerTrainingActions.SystemManagerTrainingLessonFileUploadAction(params));

    return this.dispatcher.pipe(
      ofType(SystemManagerTrainingActions.SystemManagerTrainingLessonFileUploadSuccessAction, SystemManagerTrainingActions.SystemManagerTrainingLessonFileUploadErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerTrainingActions.SystemManagerTrainingLessonFileUploadSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );

  }

  public fileUploadTrainingLessonEffect({
    institutionId,
    systemManagerId,
    programId,
    trainingId,
    trainingCourseId,
    trainingLessonId,
    file
  }: IInstitutionSystemManagerProgramParams & { trainingId: string, trainingCourseId: string, trainingLessonId: string, file: File }) {
    const formData: FormData = new FormData();
    formData.append('uploadFile', file, file.name);
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/trainings/${trainingId}/courses/${trainingCourseId}/lessons/${trainingLessonId}/package`, formData);
  }

  public getRequiredEnrollmentTraining(params: IInstitutionSystemManagerProgramParams): Observable<IInstitutionProgramTrainingDto[]> {

    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getProgramAssociations(params)),
      tap(programAssociations => {
        if (programAssociations === undefined) {
          this.store.dispatch(SystemManagerTrainingActions.SystemManagerTrainingProgramLoadAction(params));
        }
      }),
      filter(trainings => trainings != null)
    );

  }

  public loadRequiredEnrollmentTrainingEffect({
    institutionId,
    systemManagerId,
    programId
  }: IInstitutionSystemManagerProgramParams) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/enrollment-trainings`) as Observable<IInstitutionProgramTrainingDto[]>;
  }

  public getRequiredEnrollmentTrainingId({
    institutionId,
    systemManagerId,
    programId,
    trainingId
  }: IInstitutionSystemManagerProgramParams & { trainingId: string }): Observable<IInstitutionProgramTrainingDto> {
    return this.getRequiredEnrollmentTraining({ institutionId, systemManagerId, programId }).pipe(
      filter(trainings => trainings !== null),
      map(trainings => trainings.find(element => element.trainingId === trainingId))
    );
  }

  public saveRequiredEnrollmentTraining(params: IInstitutionSystemManagerProgramParams & { enrollmentRole: eEnrollmentRoleType, trainingId: string, programTraining: IInstitutionProgramTrainingCreateDto }) {
    this.store.dispatch(SystemManagerTrainingActions.SystemManagerTrainingProgramAddAction(params));

    return this.dispatcher.pipe(
      ofType(SystemManagerTrainingActions.SystemManagerTrainingProgramAddSuccessAction, SystemManagerTrainingActions.SystemManagerTrainingProgramAddErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerTrainingActions.SystemManagerTrainingProgramAddSuccessAction.type) {
          return action.programTraining;
        } else {
          throw action.error;
        }
      })
    );
  }

  public saveRequiredEnrollmentTrainingEffect({
    institutionId,
    systemManagerId,
    programId,
    enrollmentRole,
    trainingId,
    programTraining
  }: IInstitutionSystemManagerProgramParams & { enrollmentRole: eEnrollmentRoleType, trainingId: string, programTraining: IInstitutionProgramTrainingCreateDto }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/enrollment-trainings/${enrollmentRole}/${trainingId}`, programTraining);
  }

  public deleteRequiredEnrollmentTraining(params: IInstitutionSystemManagerProgramParams & { enrollmentRole: eEnrollmentRoleType, trainingId: string }) {
    this.store.dispatch(SystemManagerTrainingActions.SystemManagerTrainingProgramDeleteAction(params));

    return this.dispatcher.pipe(
      ofType(SystemManagerTrainingActions.SystemManagerTrainingProgramDeleteSuccessAction, SystemManagerTrainingActions.SystemManagerTrainingProgramDeleteErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerTrainingActions.SystemManagerTrainingProgramDeleteSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteRequiredEnrollmentTrainingEffect({
    institutionId,
    systemManagerId,
    programId,
    enrollmentRole,
    trainingId
  }: IInstitutionSystemManagerProgramParams & { enrollmentRole: eEnrollmentRoleType, trainingId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/enrollment-trainings/${enrollmentRole}/${trainingId}`);
  }

  public saveTrainingToProject(params: IInstitutionSystemManagerProgramParams & { projectId: string, enrollmentRole: eEnrollmentRoleType, trainingId: string, programTraining: IInstitutionProgramTrainingCreateDto }) {
    this.store.dispatch(SystemManagerTrainingActions.SystemManagerTrainingProjectAddAction(params));

    return this.dispatcher.pipe(
      ofType(SystemManagerTrainingActions.SystemManagerTrainingProjectAddSuccessAction, SystemManagerTrainingActions.SystemManagerTrainingProjectAddErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerTrainingActions.SystemManagerTrainingProjectAddSuccessAction.type) {
          return action.programTraining;
        } else {
          throw action.error;
        }
      })
    );
  }

  public saveTrainingToProjectEffect({
    institutionId,
    systemManagerId,
    programId,
    projectId,
    enrollmentRole,
    trainingId,
    programTraining
  }: IInstitutionSystemManagerProgramParams & { projectId: string, enrollmentRole: eEnrollmentRoleType, trainingId: string, programTraining: IInstitutionProgramTrainingCreateDto }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/projects/${projectId}/trainings/${enrollmentRole}/${trainingId}`, programTraining);
  }

  public deleteTrainingFromProject(params: IInstitutionSystemManagerProgramParams & { projectId: string, enrollmentRole: eEnrollmentRoleType, trainingId: string }) {
    this.store.dispatch(SystemManagerTrainingActions.SystemManagerTrainingProjectDeleteAction(params));

    return this.dispatcher.pipe(
      ofType(SystemManagerTrainingActions.SystemManagerTrainingProjectDeleteSuccessAction, SystemManagerTrainingActions.SystemManagerTrainingProjectDeleteErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerTrainingActions.SystemManagerTrainingProjectDeleteSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteTrainingFromProjectEffect({
    institutionId,
    systemManagerId,
    programId,
    projectId,
    enrollmentRole,
    trainingId
  }: IInstitutionSystemManagerProgramParams & { projectId: string, enrollmentRole: eEnrollmentRoleType, trainingId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/projects/${projectId}/trainings/${enrollmentRole}/${trainingId}`);
  }

  public linkTrainingForVolunteerRole(params: IInstitutionSystemManagerProgramParams & { institutionVolunteerId: string, trainingId: string, createDto: IInstitutionProgramTrainingCreateDto }) {
    this.store.dispatch(SystemManagerTrainingActions.SystemManagerLinkTrainingForVolunteerRoleAction(params));

    return this.dispatcher.pipe(
      ofType(SystemManagerTrainingActions.SystemManagerLinkTrainingForVolunteerRoleSuccessAction, SystemManagerTrainingActions.SystemManagerLinkTrainingForVolunteerRoleErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerTrainingActions.SystemManagerLinkTrainingForVolunteerRoleSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public linkTrainingForVolunteerRoleEffect({
    institutionId,
    systemManagerId,
    programId,
    institutionVolunteerId,
    trainingId,
    createDto
  }: IInstitutionSystemManagerProgramParams & { institutionVolunteerId: string, trainingId: string, createDto: IInstitutionProgramTrainingCreateDto }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/volunteer-types/${institutionVolunteerId}/trainings/${trainingId}`, createDto);
  }

  public deleteTrainingForVolunteerRole(params: IInstitutionSystemManagerProgramParams & { institutionVolunteerId: string, trainingId: string }) {
    this.store.dispatch(SystemManagerTrainingActions.SystemManagerDeleteTrainingForVolunteerRoleAction(params));

    return this.dispatcher.pipe(
      ofType(SystemManagerTrainingActions.SystemManagerDeleteTrainingForVolunteerRoleSuccessAction, SystemManagerTrainingActions.SystemManagerDeleteTrainingForVolunteerRoleErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerTrainingActions.SystemManagerDeleteTrainingForVolunteerRoleSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteTrainingForVolunteerRoleEffect({
    institutionId,
    systemManagerId,
    programId,
    institutionVolunteerId,
    trainingId
  }: IInstitutionSystemManagerProgramParams & { institutionVolunteerId: string, trainingId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/volunteer-types/${institutionVolunteerId}/trainings/${trainingId}`);
  }
}
