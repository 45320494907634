<div class="selection-intro">
  <h3>
    <ng-content select="[ng4h-in-app-help-header]"></ng-content>
  </h3>
  <p class="in-app-help-body-wrapper">
    <ng-content select="[ng4h-in-app-help-body]"></ng-content>
  </p>
  <div class="col-12 center">
    <ng-content select="[ng4h-in-app-help-footer]"></ng-content>
  </div>
</div>