import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import {
  getManagerFinanceCountyDonorById,
  getManagerFinanceCountyDonors,
  getManagerFinanceCountyFundById,
  getManagerFinanceCountyFundDonations,
  getManagerFinanceCountyFunds,
  getManagerFinanceDiscount,
  getManagerFinanceDiscounts,
  getManagerFinanceDonorById,
  getManagerFinanceDonors,
  getManagerFinanceFundById,
  getManagerFinanceFundDonationById,
  getManagerFinanceFundDonations,
  getManagerFinanceFunds,
  getManagerHierarchyFinanceFunds,
  ManagerFeatureStore
} from 'app/core/containers/admin/manager/manager-feature.reducer';
import { ManagerFinanceActions } from 'app/core/containers/admin/manager/store/finances';
import {
  IDiscountFundDescriptorDto,
  IDiscountFundDonationCreateDto,
  IDiscountFundDonationDescriptorDto,
  IDiscountFundDonationUpdateDto,
  IDiscountFundDonorCreateDto,
  IDiscountFundDonorDto,
  IDiscountFundDonorUpdateDto,
  IInstitutionDiscountCreateDto,
  IInstitutionDiscountUpdateDto,
  IInstitutionManagerProgramHierarchyParams,
  IInstitutionManagerProgramParams,
  IInstitutionMemberProgramEnrollmentParams,
  IInvoiceWaiverDto
} from 'app/core/models';
import {
  IInstitutionMemberProgramEventRegistrationParams
} from 'app/core/models/function-parameters/institution-member-program-event-registration';
import { IInstitutionDiscountDto } from 'app/core/models/serverDTOs/IInstitutionDiscountDto';
import { downloadFile } from 'app/shared/utils';
import { filter, map, Observable, of, switchMap, take, tap } from 'rxjs';

import { CommonToastrService } from '../../common-toastr.service';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ManagerFinancesService {

  constructor(
    private httpClient: HttpClient,
    private store: Store<ManagerFeatureStore>,
    private dispatcher: ActionsSubject,
    private toastrService: CommonToastrService
  ) {
  }

  public getDonors(params: IInstitutionManagerProgramParams) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }

    return this.store.pipe(
      select(getManagerFinanceDonors(params)),
      tap(donors => {
        if (donors === undefined) {
          this.store.dispatch(ManagerFinanceActions.ManagerGetDonorsAction(params));
        }
      }),
      filter(donors => donors != null)
    );
  }

  public getDonorsEffect({ institutionId, managerId, programId }: IInstitutionManagerProgramParams) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/discount-fund-donors`) as Observable<IDiscountFundDonorDto[]>;
  }

  public getDonorById(params: IInstitutionManagerProgramParams & { discountFundDonorId: string }) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }

    return this.store.pipe(
      select(getManagerFinanceDonorById(params)),
      tap(donor => {
        if (donor === undefined) {
          this.store.dispatch(ManagerFinanceActions.ManagerGetDonorByIdAction(params));
        }
      }),
      filter(donor => donor != null)
    );
  }

  public getDonorByIdEffect({
    institutionId,
    managerId,
    programId,
    discountFundDonorId
  }: IInstitutionManagerProgramParams & { discountFundDonorId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/discount-fund-donors/${discountFundDonorId}`) as Observable<IDiscountFundDonorDto>;
  }

  public updateFundDonor(params: IInstitutionManagerProgramParams & { discountFundDonorId: string, update: IDiscountFundDonorUpdateDto }) {
    this.store.dispatch(ManagerFinanceActions.UpdateFundDonorAction(params));

    return this.dispatcher.pipe(
      ofType(ManagerFinanceActions.UpdateFundDonorSuccessAction, ManagerFinanceActions.UpdateFundDonorErrorAction),
      take(1),
      map(action => {
        if (action.type === ManagerFinanceActions.UpdateFundDonorSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateFundDonorEffect({
    institutionId,
    managerId,
    programId,
    discountFundDonorId,
    update
  }: IInstitutionManagerProgramParams & { discountFundDonorId: string, update: IDiscountFundDonorUpdateDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/discount-fund-donors/${discountFundDonorId}`, update) as Observable<IDiscountFundDonorDto>;
  }

  public addDonor(params: IInstitutionManagerProgramParams & { donor: IDiscountFundDonorCreateDto }) {
    this.store.dispatch(ManagerFinanceActions.ManagerAddDonorAction(params));
  }

  public addDonorEffect({
    institutionId,
    managerId,
    programId,
    donor
  }: IInstitutionManagerProgramParams & { donor: IDiscountFundDonorCreateDto }) {
    return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/discount-fund-donors`, donor) as Observable<IDiscountFundDonorCreateDto>;
  }

  public getFunds(params: IInstitutionManagerProgramParams): Observable<IDiscountFundDescriptorDto[]> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }

    return this.store.pipe(
      select(getManagerFinanceFunds(params)),
      tap(funds => {
        if (funds === undefined) {
          this.store.dispatch(ManagerFinanceActions.ManagerGetFundsAction(params));
        }
      }),
      filter(funds => funds != null)
    );
  }

  public getFundsEffect({ institutionId, managerId, programId }: IInstitutionManagerProgramParams) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/discount-funds`) as Observable<IDiscountFundDescriptorDto[]>;
  }

  public getHierarchyFunds(params: IInstitutionManagerProgramHierarchyParams): Observable<IDiscountFundDescriptorDto[]> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }

    return this.store.pipe(
      select(getManagerHierarchyFinanceFunds(params)),
      tap(hierarchyFunds => {
        if (hierarchyFunds === undefined) {
          this.store.dispatch(ManagerFinanceActions.ManagerHierarchyGetFundsAction(params));
        }
      }),
      filter(hierarchyFunds => hierarchyFunds != null)
    );
  }

  public getHierarchyFundsEffect({
    institutionId,
    managerId,
    programId,
    hierarchyNodeId
  }: IInstitutionManagerProgramHierarchyParams) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/discount-funds/${hierarchyNodeId}`) as Observable<IDiscountFundDescriptorDto[]>;
  }

  public getFundById(params: IInstitutionManagerProgramParams & { discountFundId: string }): Observable<IDiscountFundDescriptorDto> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }

    return this.store.pipe(
      select(getManagerFinanceFundById(params)),
      tap(fund => {
        if (fund === undefined) {
          this.store.dispatch(ManagerFinanceActions.ManagerGetFundByIdAction(params));
        }
      }),
      filter(fund => fund != null)
    );
  }

  public getFundByIdEffect({
    institutionId,
    managerId,
    programId,
    discountFundId
  }: IInstitutionManagerProgramParams & { discountFundId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/discount-funds/${discountFundId}`) as Observable<IDiscountFundDescriptorDto>;
  }

  public getFundDonations(params: IInstitutionManagerProgramParams & { discountFundId: string }) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }

    return this.store.pipe(
      select(getManagerFinanceFundDonations(params)),
      tap(donations => {
        if (donations === undefined) {
          this.store.dispatch(ManagerFinanceActions.ManagerGetFundDonationsAction(params));
        }
      }),
      filter(donations => donations != null)
    );
  }

  public getFundDonationsEffect({
    institutionId,
    managerId,
    programId,
    discountFundId
  }: IInstitutionManagerProgramParams & { discountFundId: string }): Observable<IDiscountFundDonationDescriptorDto[]> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/discount-funds/${discountFundId}/donations`) as Observable<IDiscountFundDonationDescriptorDto[]>;
  }

  public addFundDonation(params: IInstitutionManagerProgramParams & { discountFundId: string, discountFundDonorId: string, newDonation: IDiscountFundDonationCreateDto }) {
    this.store.dispatch(ManagerFinanceActions.ManagerAddFundDonationAction(params));
  }

  public addFundDonationEffect({
    institutionId,
    managerId,
    programId,
    discountFundId,
    discountFundDonorId,
    newDonation
  }: IInstitutionManagerProgramParams & { discountFundId: string, discountFundDonorId: string, newDonation: IDiscountFundDonationCreateDto }) {
    return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/discount-funds/${discountFundId}/donations?discountFundDonorId=${discountFundDonorId}`, newDonation);
  }

  public updateFundDonation(params: IInstitutionManagerProgramParams & { discountFundId: string, discountFundDonationId: string, update: IDiscountFundDonationUpdateDto }) {
    this.store.dispatch(ManagerFinanceActions.ManagerEditFundDonationAction(params));

    return this.dispatcher.pipe(
      ofType(ManagerFinanceActions.ManagerEditFundDonationSuccessAction, ManagerFinanceActions.ManagerEditFundDonationErrorAction),
      take(1),
      map(action => {
        if (action.type === ManagerFinanceActions.ManagerEditFundDonationSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateFundDonationEffect({
    institutionId,
    managerId,
    programId,
    discountFundId,
    discountFundDonationId,
    update
  }: IInstitutionManagerProgramParams & { discountFundId: string, discountFundDonationId: string, update: IDiscountFundDonationUpdateDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/discount-funds/${discountFundId}/donations/${discountFundDonationId}`, update);
  }

  public deleteFundDonation(params: IInstitutionManagerProgramParams & { discountFundId: string, discountFundDonationId: string }) {
    this.store.dispatch(ManagerFinanceActions.ManagerDeleteFundDonationAction(params));

    return this.dispatcher.pipe(
      ofType(ManagerFinanceActions.ManagerDeleteFundDonationSuccessAction, ManagerFinanceActions.ManagerDeleteFundDonationErrorAction),
      take(1),
      map(action => {
        if (action.type === ManagerFinanceActions.ManagerDeleteFundDonationSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteFundDonationEffect({
    institutionId,
    managerId,
    programId,
    discountFundId,
    discountFundDonationId
  }: IInstitutionManagerProgramParams & { discountFundId: string, discountFundDonationId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/discount-funds/${discountFundId}/donations/${discountFundDonationId}`);
  }

  public getFundDonationById(params: IInstitutionManagerProgramParams & { discountFundId: string, discountFundDonationId: string }) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }

    return this.store.pipe(
      select(getManagerFinanceFundDonationById(params)),
      tap(donation => {
        if (donation === undefined) {
          this.store.dispatch(ManagerFinanceActions.ManagerGetDonationByIdAction(params));
        }
      }),
      filter(donation => donation != null)
    );
  }

  public getFundDonationByIdEffect({
    institutionId,
    managerId,
    programId,
    discountFundId,
    discountFundDonationId
  }: IInstitutionManagerProgramParams & { discountFundId: string, discountFundDonationId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/discount-funds/${discountFundId}/donations/${discountFundDonationId}`) as Observable<IDiscountFundDonationDescriptorDto>;
  }

  // County Area
  public getCountyFundDonations(params: IInstitutionManagerProgramParams & { discountFundId: string, countyAreaId: string }) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }

    return this.store.pipe(
      select(getManagerFinanceCountyFundDonations(params)),
      tap(donations => {
        if (donations === undefined) {
          this.store.dispatch(ManagerFinanceActions.ManagerGetCountyFundDonationsAction(params));
        }
      }),
      filter(donations => donations != null)
    );
  }

  public getCountyFundDonationsEffect({
    institutionId,
    managerId,
    programId,
    discountFundId,
    countyAreaId
  }: IInstitutionManagerProgramParams & { discountFundId: string, countyAreaId: string }): Observable<IDiscountFundDonationDescriptorDto[]> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/discount-funds/${discountFundId}/donations?countyAreaId=${countyAreaId}`) as Observable<IDiscountFundDonationDescriptorDto[]>;
  }

  public getCountyDonors(params: IInstitutionManagerProgramParams & { countyAreaId: string }) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }

    return this.store.pipe(
      select(getManagerFinanceCountyDonors(params)),
      tap(donors => {
        if (donors === undefined) {
          this.store.dispatch(ManagerFinanceActions.ManagerGetCountyDonorsAction(params));
        }
      }),
      filter(donors => donors != null)
    );
  }

  public getCountyDonorsEffect({
    institutionId,
    managerId,
    programId,
    countyAreaId
  }: IInstitutionManagerProgramParams & { countyAreaId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/discount-fund-donors?countyAreaId=${countyAreaId}`) as Observable<IDiscountFundDonorDto[]>;
  }

  public getCountyDonorById(params: IInstitutionManagerProgramParams & { discountFundDonorId: string, countyAreaId: string }) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }

    return this.store.pipe(
      select(getManagerFinanceCountyDonorById(params)),
      tap(donor => {
        if (donor === undefined) {
          this.store.dispatch(ManagerFinanceActions.ManagerGetCountyDonorByIdAction(params));
        }
      }),
      filter(donor => donor != null)
    );
  }

  public getCountyDonorByIdEffect({
    institutionId,
    managerId,
    programId,
    discountFundDonorId,
    countyAreaId
  }: IInstitutionManagerProgramParams & { discountFundDonorId: string, countyAreaId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/discount-fund-donors/${discountFundDonorId}?countyAreaId=${countyAreaId}`) as Observable<IDiscountFundDonorDto>;
  }

  public addCountyDonor(params: IInstitutionManagerProgramParams & { countyAreaId: string, donor: IDiscountFundDonorCreateDto }) {
    this.store.dispatch(ManagerFinanceActions.ManagerAddCountyDonorAction(params));
  }

  public addCountyDonorEffect({
    institutionId,
    managerId,
    programId,
    countyAreaId,
    donor
  }: IInstitutionManagerProgramParams & { countyAreaId: string, donor: IDiscountFundDonorCreateDto }) {
    return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/discount-fund-donors?countyAreaId=${countyAreaId}`, donor) as Observable<IDiscountFundDonorCreateDto>;
  }

  public getCountyFunds(params: IInstitutionManagerProgramParams & { countyAreaId: string }): Observable<IDiscountFundDescriptorDto[]> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }

    return this.store.pipe(
      select(getManagerFinanceCountyFunds(params)),
      tap(funds => {
        if (funds === undefined) {
          this.store.dispatch(ManagerFinanceActions.ManagerGetCountyFundsAction(params));
        }
      }),
      filter(funds => funds != null)
    );
  }

  public getCountyFundsEffect({
    institutionId,
    managerId,
    programId,
    countyAreaId
  }: IInstitutionManagerProgramParams & { countyAreaId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/discount-funds?countyAreaId=${countyAreaId}`) as Observable<IDiscountFundDescriptorDto[]>;
  }

  public getCountyFundById(params: IInstitutionManagerProgramParams & { discountFundId: string, countyAreaId: string }): Observable<IDiscountFundDescriptorDto> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }

    return this.store.pipe(
      select(getManagerFinanceCountyFundById(params)),
      tap(fund => {
        if (fund === undefined) {
          this.store.dispatch(ManagerFinanceActions.ManagerGetCountyFundByIdAction(params));
        }
      }),
      filter(fund => fund != null)
    );
  }

  public getCountyFundByIdEffect({
    institutionId,
    managerId,
    programId,
    discountFundId
  }: IInstitutionManagerProgramParams & { discountFundId: string, countyAreaId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/discount-funds/${discountFundId}`) as Observable<IDiscountFundDescriptorDto>;
  }

  public getDiscounts(params: IInstitutionManagerProgramParams & { programYearId: string }): Observable<IInstitutionDiscountDto[]> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }

    return this.store.pipe(
      select(getManagerFinanceDiscounts(params)),
      tap(fund => {
        if (fund === undefined) {
          this.store.dispatch(ManagerFinanceActions.ManagerGetDiscountsAction(params));
        }
      }),
      filter(fund => fund != null)
    );
  }

  public getDiscountsEffect({
    institutionId,
    managerId,
    programId,
    programYearId
  }: IInstitutionManagerProgramParams & { programYearId: string }): Observable<IInstitutionDiscountDto[]> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/discounts?programYearId=${programYearId}`) as Observable<IInstitutionDiscountDto[]>;
  }

  public addDiscount(params: IInstitutionManagerProgramParams & { programYearId: string, discount: IInstitutionDiscountCreateDto }) {
    this.store.dispatch(ManagerFinanceActions.ManagerAddDiscountAction(params));

    return this.dispatcher.pipe(
      ofType(ManagerFinanceActions.ManagerAddDiscountSuccessAction, ManagerFinanceActions.ManagerAddDiscountErrorAction),
      take(1),
      map(action => {
        if (action.type === ManagerFinanceActions.ManagerAddDiscountSuccessAction.type) {
          return action.newDiscount;
        } else {
          throw action.error;
        }
      })
    );
  }

  public addDiscountEffect({
    institutionId,
    managerId,
    programId,
    programYearId,
    discount
  }: IInstitutionManagerProgramParams & { programYearId: string, discount: IInstitutionDiscountCreateDto }) {
    return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/discounts?programYearId=${programYearId}`, discount, {
      observe: 'response'
    }).pipe(
      switchMap((res: HttpResponse<any>) => this.httpClient.get(res.headers.get('location')))
    ) as Observable<IInstitutionDiscountDto>;
  }

  public updateDiscount(params: IInstitutionManagerProgramParams & { institutionDiscountId: string, update: IInstitutionDiscountUpdateDto }) {
    this.store.dispatch(ManagerFinanceActions.ManagerUpdateDiscountAction(params));
  }

  public updateDiscountEffect({
    institutionId,
    managerId,
    programId,
    institutionDiscountId,
    update
  }: IInstitutionManagerProgramParams & { institutionDiscountId: string, update: IInstitutionDiscountUpdateDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/discounts/${institutionDiscountId}`, update);
  }

  public deleteDiscount(params: IInstitutionManagerProgramParams & { institutionDiscountId: string }) {
    this.store.dispatch(ManagerFinanceActions.ManagerDeleteDiscountAction(params));

    return this.dispatcher.pipe(
      ofType(ManagerFinanceActions.ManagerDeleteDiscountSuccessAction, ManagerFinanceActions.ManagerDeleteDiscountErrorAction),
      take(1),
      map(action => {
        if (action.type === ManagerFinanceActions.ManagerDeleteDiscountSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteDiscountEffect({
    institutionId,
    managerId,
    programId,
    institutionDiscountId
  }: IInstitutionManagerProgramParams & { institutionDiscountId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/discounts/${institutionDiscountId}`);
  }

  public getDiscount(params: IInstitutionManagerProgramParams & { institutionDiscountId: string }): Observable<IInstitutionDiscountDto> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }

    return this.store.pipe(
      select(getManagerFinanceDiscount(params)),
      tap(discount => {
        if (discount === undefined) {
          this.store.dispatch(ManagerFinanceActions.ManagerGetDiscountAction(params));
        }
      }),
      filter(discount => discount != null)
    );
  }

  public getDiscountEffect({
    institutionId,
    managerId,
    programId,
    institutionDiscountId
  }: IInstitutionManagerProgramParams & { institutionDiscountId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/discounts/${institutionDiscountId}`) as Observable<IInstitutionDiscountDto>;
  }

  public addClubs(params: IInstitutionManagerProgramParams & { institutionDiscountId: string, clubs: string[] }) {
    this.store.dispatch(ManagerFinanceActions.ManagerAddClubsAction(params));
  }

  public addClubsEffect({
    institutionId,
    managerId,
    programId,
    institutionDiscountId,
    clubs
  }: IInstitutionManagerProgramParams & { institutionDiscountId: string, clubs: string[] }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/discounts/${institutionDiscountId}/clubs`, clubs);
  }

  public applyEnrollmentWaiver(params: IInstitutionMemberProgramEnrollmentParams & { invoiceId: string, invoiceWaiver: IInvoiceWaiverDto }) {
    this.store.dispatch(ManagerFinanceActions.ManagerApplyEnrollmentWaiverAction(params));

    return this.dispatcher.pipe(
      ofType(ManagerFinanceActions.ManagerApplyEnrollmentWaiverSuccessAction, ManagerFinanceActions.ManagerApplyEnrollmentWaiverErrorAction),
      take(1),
      map(action => {
        if (action.type === ManagerFinanceActions.ManagerApplyEnrollmentWaiverSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public applyEnrollmentWaiverEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    invoiceId,
    invoiceWaiver
  }: IInstitutionMemberProgramEnrollmentParams & { invoiceId: string, invoiceWaiver: IInvoiceWaiverDto }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/invoices/${invoiceId}/waive-invoice-items`, invoiceWaiver);
  }

  public applyEventWaiver(params: IInstitutionMemberProgramEventRegistrationParams & { invoiceId: string, invoiceWaiver: IInvoiceWaiverDto }) {
    this.store.dispatch(ManagerFinanceActions.ManagerApplyEventWaiverAction(params));

    return this.dispatcher.pipe(
      ofType(ManagerFinanceActions.ManagerApplyEventWaiverSuccessAction, ManagerFinanceActions.ManagerApplyEventWaiverErrorAction),
      take(1),
      map(action => {
        if (action.type === ManagerFinanceActions.ManagerApplyEventWaiverSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public applyEventWaiverEffect({
    institutionId,
    memberId,
    programId,
    eventRegistrationId,
    invoiceId,
    invoiceWaiver
  }: IInstitutionMemberProgramEventRegistrationParams & { invoiceId: string, invoiceWaiver: IInvoiceWaiverDto }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/event-registrations/${eventRegistrationId}/invoices/${invoiceId}/waive-invoice-items`, invoiceWaiver);
  }

  public downloadEnrollmentCouponsExcel({
    institutionId,
    managerId,
    programId,
    hierarchyNodeId,
    institutionDiscountId
  }: IInstitutionManagerProgramParams & { hierarchyNodeId: string, institutionDiscountId: string }) {
    downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/quick-exports/${hierarchyNodeId}/enrollment-coupons/${institutionDiscountId}`, this.toastrService);
  }

  public downloadEnrollmentInvoicePdf({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    invoiceId
  }: IInstitutionMemberProgramEnrollmentParams & { invoiceId: string }) {
    downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/invoices/${invoiceId}/pdf`, this.toastrService);
  }

  public downloadEventInvoicePdf({
    institutionId,
    memberId,
    programId,
    eventRegistrationId,
    invoiceId
  }: IInstitutionMemberProgramEventRegistrationParams & { invoiceId: string }) {
    downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/event-registrations/${eventRegistrationId}/invoices/${invoiceId}/pdf`, this.toastrService);
  }

}
