import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  IInstitutionManagerProgramEventParams,
  IRegistrationTypeFileControlCreateDto,
  IRegistrationTypeFileControlDto,
  IRegistrationTypeFileControlUpdateDto,
} from 'app/core/models';

export const EventGetCustomFilesAction = createAction(
  '[event-custom-files] get event custom files',
  props<{ institutionId: string, managerId: string, programId: string, eventId: string }>()
);

// TODO: update custom files type when known
export const EventGetCustomFilesSuccessAction = createAction(
  '[event-custom-files] get event custom files success',
  props<{ institutionId: string, managerId: string, programId: string, eventId: string, customFiles: IRegistrationTypeFileControlDto[] }>()
);

export const EventGetCustomFilesErrorAction = createAction(
  '[event-custom-files] get event custom files error',
  props<{ error: HttpErrorResponse }>()
);

export const EventAddEventCustomFileAction = createAction(
  '[event-custom-files] add event custom file',
  props<IInstitutionManagerProgramEventParams & { customFile: IRegistrationTypeFileControlCreateDto }>()
);

export const EventAddEventCustomFileSuccessAction = createAction(
  '[event-custom-files] add event custom file success',
  props<IInstitutionManagerProgramEventParams & { customFile: IRegistrationTypeFileControlCreateDto }>()
);

export const EventAddEventCustomFileErrorAction = createAction(
  '[event-custom-files] add event custom file error',
  props<{ error: HttpErrorResponse }>()
);

export const EventUpdateCustomFileAction = createAction(
  '[event-custom-files] Update event custom file',
  props<IInstitutionManagerProgramEventParams & { fileControlId: string, fileControlUpdate: IRegistrationTypeFileControlUpdateDto }>()
);

export const EventUpdateCustomFileSuccessAction = createAction(
  '[event-custom-files] Update event custom file success',
  props<IInstitutionManagerProgramEventParams & { fileControlId: string, fileControlUpdate: IRegistrationTypeFileControlUpdateDto }>()
);

export const EventUpdateCustomFileErrorAction = createAction(
  '[event-custom-files] Update event custom file error',
  props<{ error: HttpErrorResponse }>()
);

export const EventInvalidateEventCustomFileCache = createAction(
  '[event-custom-files] invalidate custom file cache'
);

export const EventDeleteCustomFileAction = createAction(
  '[event-custom-files] delete event custom files',
  props<IInstitutionManagerProgramEventParams & { fileControlId: string }>()
);

export const EventDeleteCustomFileSuccessAction = createAction(
  '[event-custom-files] delete event custom files success',
  props<IInstitutionManagerProgramEventParams & { fileControlId: string }>()
);

export const EventDeleteCustomFileErrorAction = createAction(
  '[event-custom-files] delete event custom files error',
  props<{ error: HttpErrorResponse }>()
);


export const EventManagerUploadFileTemplateAction = createAction(
  '[event-custom-files] upload file template',
  props<IInstitutionManagerProgramEventParams & { fileControlId: string, formData: FormData }>()
);
export const EventManagerUploadFileTemplateSuccessAction = createAction(
  '[event-custom-files] upload file template success',
  props<IInstitutionManagerProgramEventParams & { fileControlId: string, formData: FormData }>()
);
export const EventManagerUploadFileTemplateErrorAction = createAction(
  '[event-custom-files] upload file error template',
  props<{ error: HttpErrorResponse }>()
);

export const EventManagerDeleteFileTemplateAction = createAction(
  '[event-custom-files] delete file template',
  props<IInstitutionManagerProgramEventParams & { fileControlId: string }>()
);
export const EventManagerDeleteFileTemplateSuccessAction = createAction(
  '[event-custom-files] delete file template success',
  props<IInstitutionManagerProgramEventParams & { fileControlId: string }>()
);
export const EventManagerDeleteFileTemplateErrorAction = createAction(
  '[event-custom-files] delete file error template',
  props<{ error: HttpErrorResponse }>()
);
