<div class="modal-header">
  <h5 class="modal-title">Add Training</h5>
  <button (click)="close()" type="button" class="close" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form" [ngClass]="wasValidated ? 'was-validated' : 'needs-validation'" novalidate>
    <div class="form-group row">
      <label class="form-control-label col-md-4">Training Name</label>
      <div class="col-md-8">
        <input formControlName="trainingName" type="text" class="form-control">
      </div>
    </div>
    <div class="form-group row">
      <label class="form-control-label col-md-4">Training Mode</label>
      <div class="col-md-8">
        <select formControlName="trainingMode" class="form-control">
          <option *ngFor="let mode of eTradingMode | keyvalue" [value]="mode.key">{{mode.value}}</option>
        </select>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button (click)="add()" class="btn btn-primary my-2 my-0" role="button" aria-pressed="true">Add</button>
</div>