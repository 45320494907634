import { FormGroup, ValidationErrors } from '@angular/forms';

export class EnrollmentFeesValidators {
  static CloverBud(formGroup: FormGroup): ValidationErrors {
    if (parseFloat(formGroup.controls.cloverLate.value) < parseFloat(formGroup.controls.cloverNormal.value)) {
      return { cloverBudLateFeeHigherThanNormal: true };
    }
    return null;
  }

  static Youth(formGroup: FormGroup): ValidationErrors {
    if (parseFloat(formGroup.controls.youthLate.value) < parseFloat(formGroup.controls.youthNormal.value)) {
      return { YouthLateFeeHigherThanNormal: true };
    }
    return null;
  }

  static Volunteer(formGroup: FormGroup): ValidationErrors {
    if (parseFloat(formGroup.controls.adultLate.value) < parseFloat(formGroup.controls.adultNormal.value)) {
      return { VolunteerLateFeeHigherThanNormal: true };
    }
    return null;
  }
}
