import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState, getCountyAreas } from 'app/app.reducers';
import { IHierarchyCounty, IHierarchyCountyArea, IInstitutionProgramParams } from 'app/core/models';
import { CountyAreasActions } from 'app/shared/county-areas';
import { filter, map, Observable, of, tap } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CountyService {

  constructor(private store: Store<AppState>, private httpClient: HttpClient) {

  }

  public getCountyAreas(params: { institutionId: string, programId: string }): Observable<IHierarchyCountyArea[]> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getCountyAreas(params)),
      tap(enrollment => {
        if (enrollment === undefined) {
          this.store.dispatch(CountyAreasActions.CountyAreasLoadAction(params));
        }
      })
    );
  }

  public loadCountyAreasEffect({
    institutionId,
    programId
  }: { institutionId: string, programId: string }): Observable<IHierarchyCountyArea[]> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/programs/${programId}/county-areas`) as Observable<IHierarchyCountyArea[]>;
  }

  public getCountyArea({
    institutionId,
    programId,
    hierarchyNodeId
  }: IInstitutionProgramParams & { hierarchyNodeId: string }): Observable<IHierarchyCountyArea> {
    return this.getCountyAreas({ institutionId, programId }).pipe(
      filter(countyAreas => countyAreas != null),
      map(countyAreas => {
        return countyAreas.find(countyArea => countyArea.hierarchyNodeId === hierarchyNodeId);
      })
    );
  }

  public getCountyAreaById({
    institutionId,
    programId,
    countyAreaId
  }: IInstitutionProgramParams & { countyAreaId: string }): Observable<IHierarchyCountyArea> {
    return this.getCountyAreas({ institutionId, programId }).pipe(
      filter(countyAreas => countyAreas != null),
      map(countyAreas => {
        return countyAreas.find(countyArea => countyArea.countyAreaId === countyAreaId);
      })
    );
  }

  public getCounties({ institutionId, programId }: IInstitutionProgramParams): Observable<IHierarchyCounty[]> {
    if (programId == null) {
      console.warn('passed in undefined parameters');
      return of(null);
    }
    return this.getCountyAreas({ institutionId, programId }).pipe(
      filter(countyAreas => countyAreas != null),
      map(countyAreas =>
        countyAreas.reduce((acc: IHierarchyCounty[], cur: IHierarchyCountyArea) => {
          return [...acc, ...cur.counties];
        }, [])));
  }
}
