import { eGroupStatus } from '..';

export interface IGroupDto {
    groupId: string;
    status: eGroupStatus;
    label?: string;
    description?: string;
    hierarchyNodeId: string;
    deliveryModeId: string;
    allowMembersToRegister: boolean;
}
