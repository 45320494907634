import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ModalService } from 'app/core/services';

import { ModalFullscreenComponent } from '../modal-fullscreen/modal-fullscreen.component';

@Component({
  selector: 'ng4h-table-fullscreen',
  templateUrl: './table-fullscreen.component.html',
  styleUrls: ['./table-fullscreen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableFullscreenComponent implements OnInit {

  @Input('ref') ref: HTMLElement;

  constructor(
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
  }

  public fullscreen() {
    this.modalService.openModal(ModalFullscreenComponent, { data: this.ref, maxHeight: '100%', maxWidth: '100%', panelClass: 'col-12' });
  }

}
