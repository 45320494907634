<div class="modal-header">
  <h5 class="modal-title">Edit Emergency Contact</h5>
  <button (click)="close()" type="button" class="close" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form *ngIf="form != null" [formGroup]="form">
    <!-- <div class="form-group row">
        <label class="form-control-label col-md-4">First Name</label>
        <div class="col-md-8">
          <input formControlName="firstName" class="form-control" type="text">
        </div>
      </div>
      <div class="form-group row">
        <label class="form-control-label col-md-4">Middle Name</label>
        <div class="col-md-8">
          <input formControlName="middleName" class="form-control" type="text">
        </div>
      </div>
      <div class="form-group row">
        <label class="form-control-label col-md-4">Last Name</label>
        <div class="col-md-8">
          <input formControlName="lastName" class="form-control" type="text">
        </div>
      </div>
      <div class="form-group row">
        <label class="form-control-label col-md-4">Preferred Name</label>
        <div class="col-md-8">
          <input formControlName="preferredName" class="form-control" type="text">
        </div>
      </div>
      <div class="form-group row">
        <label class="form-control-label col-md-4">Email</label>
        <div class="col-md-8">
          <input formControlName="email" class="form-control" type="email">
        </div>
      </div>
      <div class="form-group row">
        <label class="form-control-label col-md-4">Mobile Phone</label>
        <div class="col-md-8">
          <input formControlName="mobilePhone" class="form-control" type="text">
        </div>
      </div>
      <div class="form-group row">
        <label class="form-control-label col-md-4">Birthdate</label>
        <div class="input-group col-md-8">
          <input class="form-control" formControlName="birthDate" [matDatepicker]="birthDate">
          <mat-datepicker-toggle matSuffix [for]="birthDate"></mat-datepicker-toggle>
        </div>
        <mat-datepicker #birthDate></mat-datepicker>
      </div>
      <div class="form-group row">
        <label class="form-control-label col-md-4">Job Title</label>
        <div class="col-md-8">
          <input formControlName="jobTitle" class="form-control" type="text">
        </div>
      </div>
      <div class="form-group row">
        <label class="form-control-label col-md-4">Employer</label>
        <div class="col-md-8">
          <input formControlName="employer" class="form-control" type="text">
        </div>
      </div>
      <div class="form-group row">
        <label class="form-control-label col-md-4">Work Phone</label>
        <div class="col-md-8">
          <input formControlName="workPhone" class="form-control" type="text">
        </div>
      </div>
      <div class="form-group row">
        <label class="form-control-label col-md-4">Work Extension</label>
        <div class="col-md-8">
          <input formControlName="workExt" class="form-control" type="text">
        </div>
      </div> -->
    <div class="form-group row">
      <label class="ng4h-required form-control-label col-md-4">Emergency Contact</label>
      <div class="col-md-8">
        <input formControlName="emergencyContact" class="form-control" type="text">
      </div>
    </div>
    <div class="form-group row">
      <label class="ng4h-required form-control-label col-md-4">Emergency Contact Phone</label>
      <div class="col-md-8">
        <input formControlName="emergencyContactPhone" class="form-control" type="text" [maxLength]="12" type="text"
          ng4hPhoneMask>
      </div>
    </div>

    <div class="form-group row">
      <label class="form-control-label col-md-4">Emergency Contact Email</label>
      <div class="col-md-8">
        <input formControlName="emergencyContactEmail" class="form-control" type="email"></div>
    </div>

    <div class="form-group row">
      <label class="ng4h-required form-control-label col-md-4">Emergency Contact Relationship</label>
      <div class="col-md-8">
        <input formControlName="emergencyContactRelationship" class="form-control" type="text"></div>
    </div>

    <div class="form-group row">
      <label class="ng4h-required form-control-label col-md-4">Emergency Contact 2</label>
      <div class="col-md-8">
        <input formControlName="emergencyContact2" class="form-control" type="text">
      </div>
    </div>
    <div class="form-group row">
      <label class="ng4h-required form-control-label col-md-4">Emergency Contact 2 Phone</label>
      <div class="col-md-8">
        <input formControlName="emergencyContact2Phone" class="form-control" type="text" [maxLength]="12" type="text"
          ng4hPhoneMask>
      </div>
    </div>

    <div class="form-group row">
      <label class="ng4h-required form-control-label col-md-4">Emergency Contact 2 Email</label>
      <div class="col-md-8">
        <input formControlName="emergencyContact2Email" class="form-control" type="email"></div>
    </div>

    <div class="form-group row">
      <label class="ng4h-required form-control-label col-md-4">Emergency Contact 2 Relationship</label>
      <div class="col-md-8">
        <input formControlName="emergencyContact2Relationship" class="form-control" type="text"></div>
    </div>


    <ng-container *ngIf="member.memberRole !== eMemberRole.Adult">
      <div class="form-group row">
        <label class="form-control-label col-md-4">Parent / Guardian 1 First Name</label>
        <div class="col-md-8">
          <input formControlName="guardian1FirstName" class="form-control" type="text">
        </div>
      </div>
      <div class="form-group row">
        <label class="form-control-label col-md-4">Parent / Guardian 1 Last Name</label>
        <div class="col-md-8">
          <input formControlName="guardian1LastName" class="form-control" type="text">
        </div>
      </div>
      <div class="form-group row">
        <label class="form-control-label col-md-4">Parent / Guardian 1 Phone</label>
        <div class="col-md-8">
          <input [maxLength]="12" ng4hPhoneMask formControlName="guardian1Phone" class="form-control" type="text">
        </div>
      </div>
      <div class="form-group row">
        <label class="form-control-label col-md-4">Parent / Guardian 1 Work Phone</label>
        <div class="col-md-8">
          <input [maxLength]="12" ng4hPhoneMask formControlName="guardian1WorkPhone" class="form-control" type="text">
        </div>
      </div>
      <div class="form-group row">
        <label class="form-control-label col-md-4">Parent / Guardian 1 Work Extension</label>
        <div class="col-md-8">
          <input formControlName="guardian1WorkExt" class="form-control" type="text">
        </div>
      </div>
      <div class="form-group row">
        <label class="form-control-label col-md-4">Parent / Guardian 2 First Name</label>
        <div class="col-md-8">
          <input formControlName="guardian2FirstName" class="form-control" type="text">
        </div>
      </div>
      <div class="form-group row">
        <label class="form-control-label col-md-4">Parent / Guardian 2 Last Name</label>
        <div class="col-md-8">
          <input formControlName="guardian2LastName" class="form-control" type="text">
        </div>
      </div>
      <div class="form-group row">
        <label class="form-control-label col-md-4">Parent / Guardian 2 Phone</label>
        <div class="col-md-8">
          <input [maxLength]="12" ng4hPhoneMask formControlName="guardian2Phone" class="form-control" type="text">
        </div>
      </div>
      <div class="form-group row">
        <label class="form-control-label col-md-4">Parent / Guardian 2 Work Phone</label>
        <div class="col-md-8">
          <input [maxLength]="12" ng4hPhoneMask formControlName="guardian2WorkPhone" class="form-control" type="text">
        </div>
      </div>
      <div class="form-group row">
        <label class="form-control-label col-md-4">Parent / Guardian 2 Work Extension</label>
        <div class="col-md-8">
          <input formControlName="guardian2WorkExt" class="form-control" type="text">
        </div>
      </div>
    </ng-container>
  </form>

</div>
<div class="modal-footer">
  <button class="btn btn-primary" (click)="save()" [disabled]="form.invalid">Save</button>
  <button class="btn btn-secondary" (click)="close()">Cancel</button>
</div>