<ng-container *ng4hVar="currentProgramYear$ | async as currentProgramYear">
  <label for="programYearSelector" class="sr-only">Program Year Selector</label>
  <select [disabled]="disabled" id="programYearSelector"
          [ngClass]="{'noncurrent': value !== currentProgramYear?.programYearId}" class="form-control"
          style="height: 44px;"
          (change)="changed($event.target.value)">
    <option [ngValue]="null"></option>
    <option
      [ngClass]="{'noncurrent': programYear.programYearId !== currentProgramYear?.programYearId, 'current': programYear.programYearId === currentProgramYear?.programYearId}"
      [value]="programYear.programYearId" *ngFor="let programYear of programYears$ | async"
      [selected]="programYear.programYearId == value">{{ programYear.programYearForDisplay }}
    </option>
  </select>
</ng-container>