import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IHierarchyDistrictArea } from 'app/core/models';

export const DistrictLoadDistrictsAction = createAction(
  '[districts] load districts',
  props<{ institutionId: string }>()
);

export const DistrictLoadDistrictsSuccessAction = createAction(
  '[districts] load districts success',
  props<{ institutionId: string, districts: IHierarchyDistrictArea[] }>()
);

export const DistrictLoadDistrictsErrorAction = createAction(
  '[districts] load districts error',
  props<{ error: HttpErrorResponse }>()
);
