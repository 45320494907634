import { Action, createReducer, on } from '@ngrx/store';
import { IHierarchySummaryDto, IManagerDescriptorDto, IManagerInstitutionProfile, IPagination } from 'app/core/models';
import { IPermissionType } from 'app/core/models/IPermissionType';
import { mergeImmutable } from 'app/shared/utils';

import { AdminActions } from '.';

export interface AdminState {
  managers: { [institutionId: string]: IManagerInstitutionProfile[] };
  manager: { [institutionId: string]: { [managerId: string]: IManagerInstitutionProfile } };

  managerPermissionTypes: IPermissionType[];
  managersSearchSearching: boolean;
  managersSearchResult: IPagination<IManagerInstitutionProfile>;

  hierarchySummary: { [institutionId: string]: { [hierarchyNodeId: string]: { [programYearId: string]: IHierarchySummaryDto } } };

  deletedManagers: { [institutionId: string]: IManagerDescriptorDto[] };

}

const initialState: AdminState = {
  managers: undefined,
  manager: undefined,

  managerPermissionTypes: undefined,


  managersSearchSearching: false,
  managersSearchResult: undefined,

  hierarchySummary: undefined,
  deletedManagers: undefined
};

const reducer = createReducer(
  initialState,
  on(AdminActions.AdminGetManagersSuccessAction, (state, payload) => {
    const newManagers = mergeImmutable(
      { [payload.institutionId]: payload.managers },
      state.managers
    );
    return {
      ...state,
      managers: newManagers
    };
  }),
  on(AdminActions.AdminGetManagerSuccessAction, (state, payload) => {
    const newManager = mergeImmutable(
      { [payload.institutionId]: { [payload.managerId]: payload.manager } },
      state.manager
    );
    return {
      ...state,
      manager: newManager
    };
  }),
  on(AdminActions.AdminGetManagerPermissionTypesSuccessAction, (state, payload) => {
    return {
      ...state,
      managerPermissionTypes: payload.types
    };
  }),
  on(AdminActions.AdminSearchManagersAction, (state, payload) => {
    return {
      ...state,
      managersSearchSearching: true
    };
  }),
  on(AdminActions.AdminSearchManagersSuccessAction, (state, payload) => {
    return {
      ...state,
      managersSearchResult: payload.managersSearchResult,
      managersSearchSearching: false
    };
  }),
  on(AdminActions.AdminSearchManagersErrorAction, (state, payload) => {
    return {
      ...state,
      managersSearchSearching: false
    };
  }),

  on(AdminActions.AdminClearManagersSearchAction, (state, payload) => {
    return {
      ...state,
      managersSearchResult: initialState.managersSearchResult
    };
  }),
  on(AdminActions.GetHierarchySummarySuccessAction, (state, { institutionId, hierarchyNodeId, programYearId, summary }) => {
    const newHierarchySummaries = mergeImmutable(
      { [institutionId]: { [hierarchyNodeId]: { [programYearId]: summary } } },
      state.hierarchySummary
    );
    return {
      ...state,
      hierarchySummary: newHierarchySummaries
    };
  }),

  on(AdminActions.AdminInvalidateManagersCacheAction, (state, payload) => {
    return {
      ...state,
      managers: undefined
    };
  }),
  on(AdminActions.GetDeletedManagersSuccessAction, (state, { institutionId, managers }) => {
    const newDeletedManagers = mergeImmutable(
      { [institutionId]: managers },
      state.deletedManagers
    );
    return {
      ...state,
      deletedManagers: newDeletedManagers
    };
  }),
  on(AdminActions.ReinstateManagerSuccessAction, (state, payload) => {
    return initialState;
  })


);

export function adminReducers(state: AdminState | undefined, action: Action) {
  return reducer(state, action);
}

export const managerPermissionTypes = (state: AdminState) => state.managerPermissionTypes;


export const managersSearchResult = (state: AdminState) => state.managersSearchResult;





export const managers = (state: AdminState) => state?.managers;
export const manager = (state: AdminState) => state?.manager;
export const hierarchySummary = (state: AdminState) => state?.hierarchySummary;
export const deletedManagers = (state: AdminState) => state?.deletedManagers;
