import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ng4h-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {

  public source: string;
  public altText: string;

  constructor() { }

  ngOnInit(): void {
    this.source = this.getLogoPath();

    this.altText = this.getAltText();
  }


  public getAltText() {
    switch (location.hostname) {
      case 'extension.online':
        return 'Extension Online';
      default:
        return 'regmax';
    }
  }
  public getLogoPath() {
    switch (location.hostname) {
      case 'extension.online':
        return '/assets/images/Ex-Online.svg';
      default:
        return '/assets/images/4-H_Online_LOGO.svg';
    }
  }

}
