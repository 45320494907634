import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IDynamicControl, eFormType } from 'app/core/models';
import { IDynamicValidator } from 'app/core/models/dynamic-forms/IDynamicValidator';
import { Observable } from 'rxjs';

@Component({ template: '' })
export class DynamicValidatorBaseComponent implements OnInit {

  @Input() control: IDynamicControl;
  @Input() formType: eFormType;
  @Input() formDisplayTypes: Observable<{ id: string, name: string }[]>;

  @Output() validatorAddOrModify: EventEmitter<IDynamicValidator>;
  @Output() validatorRemove: EventEmitter<IDynamicValidator>;

  constructor() {
    this.validatorAddOrModify = new EventEmitter();
    this.validatorRemove = new EventEmitter();
  }

  ngOnInit() {
  }

}
