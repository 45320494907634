export enum eSchoolType {

    PublicSchool = 'PublicSchool',
    PrivateSchool = 'PrivateSchool',
    SpecialEducation = 'SpecialEducation',
    VocationalEducation = 'VocationalEducation',
    HomeSchool = 'HomeSchool',
    MagnetSpecializedSchool = 'MagnetSpecializedSchool',
    CharterSchool = 'CharterSchool',
    'CollegeUniversity' = 'College/University'
}
