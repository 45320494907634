import { IBroadcastEmailToken } from '.';
import { eBroadcastEmailDeliveryStatus, eBroadcastEmailQueueStatus } from '..';

export interface IBroadcastEmailRecipientDto {
    broadcastEmailQueueId: string;
    recipientProfile?: object;
    emailId: string;
    emailToken: IBroadcastEmailToken;
    emailAddress?: string;
    status: eBroadcastEmailQueueStatus;
    statusMessage?: string;
    processedDate: string;
    finalStatusDate?: string;
    deliveryStatus?: eBroadcastEmailDeliveryStatus;
    deliveryEventDate?: string;
    engagementDate?: string;
    processor_StatusMessage?: string;
    processor_StatusReason?: string;
}
