import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { eAccessControlListRoleType, ePermissionState, IEventDto } from 'app/core/models';
import { CommonToastrService, EventsManagerService, ModalService, RouterService } from 'app/core/services';
import { filter, Observable, take } from 'rxjs';

@Component({
  selector: 'ng4h-modal-event-permissions',
  templateUrl: './modal-event-permissions.component.html',
  styleUrls: ['./modal-event-permissions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalEventPermissionsComponent implements OnInit {
  public permissionRoleForm: FormGroup;
  public permissionForm: FormGroup;
  public eAccessControlListRoleType = eAccessControlListRoleType;
  public event$: Observable<IEventDto>;

  constructor(
    @Inject(MAT_DIALOG_DATA) private managerId: string,
    private dialogRef: MatDialogRef<ModalEventPermissionsComponent>,
    private eventsManagerService: EventsManagerService,
    private routerService: RouterService,
    private fb: FormBuilder,
    private modalService: ModalService,
    private toastrService: CommonToastrService
  ) {
  }


  ngOnInit() {
    this.event$ = this.eventsManagerService.getEvent({
      institutionId: this.routerService.institutionId,
      managerId: this.routerService.managerId,
      programId: this.routerService.programId,
      eventId: this.routerService.eventId
    });

    this.event$.pipe(
      filter(event => event != null),
      take(1)
    ).subscribe(event => {
      const acl = event.eventManagers[this.managerId];

      this.permissionForm = this.fb.group({
        EventSetup: [acl.grantedPermissions.EventSetup == null ? ePermissionState.None : acl.grantedPermissions.EventSetup, [Validators.required]],
        RegistrationManagement: [acl.grantedPermissions.RegistrationManagement == null ? ePermissionState.None : acl.grantedPermissions.RegistrationManagement, [Validators.required]],
        EventManagers: [acl.grantedPermissions.EventManagers == null ? ePermissionState.None : acl.grantedPermissions.EventManagers, [Validators.required]],
      });
      this.permissionRoleForm = this.fb.group({
        permissionRole: [acl.permissionRole, [Validators.required]]
      });
    });
  }

  public close(result?: any) {
    this.dialogRef.close(result);
  }

  public save() {
    this.eventsManagerService.updateEventPermissions({
      institutionId: this.routerService.institutionId,
      targetManagerId: this.managerId,
      programId: this.routerService.programId,
      eventId: this.routerService.eventId,
      managerId: this.routerService.managerId,
      permissions: {
        permissionRole: this.permissionRoleForm.value.permissionRole,
        grantedPermissions: this.permissionForm.value
      }
    }).pipe(
      take(1),
      this.modalService.showProgressSpinnerModalUntilComplete()
    ).subscribe({
      next: res => {
        this.toastrService.success('Permissions Updated');
        this.close();
      }, error: error => {
        this.toastrService.error('Could not update permissions', error);
      }
    });
  }

  public removeManager() {
    this.modalService.confirm({ header: 'Remove Manager?', body: 'Are you sure you want to remove this manager from this event?' }).pipe(
      take(1),
      filter(res => res === true)
    ).subscribe(() => {

      this.eventsManagerService.removeManagerFromEvent({
        institutionId: this.routerService.institutionId,
        managerId: this.routerService.managerId,
        programId: this.routerService.programId,
        eventId: this.routerService.eventId,
        adminManagerId: this.managerId,
      }).pipe(
        take(1),
        this.modalService.showProgressSpinnerModalUntilComplete()
      ).subscribe({
        next: res => {
          this.toastrService.success('Manager Removed');
          this.close();
        }, error: error => {
          this.toastrService.error('Could not remove manager', error);
        }
      });

    });

  }
}
