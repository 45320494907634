import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { eScreeningMode, eScreeningType } from 'app/core/models';
import { AdminScreeningsService, CommonToastrService, MemberScreeningsService, RouterService } from 'app/core/services';
import { ManagerScreeningsService } from 'app/core/services/manager/screenings/manager-screenings.service';
import { filter, map, Observable, take } from 'rxjs';

@Component({
  selector: 'ng4h-modal-change-screening-level',
  templateUrl: './modal-change-screening-level.component.html',
  styleUrls: ['./modal-change-screening-level.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalChangeScreeningLevelComponent implements OnInit {
  public availableScreeningTypes$: Observable<eScreeningType[]>;

  public form: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: { memberId: string, profileScreeningId: string },
    private fb: FormBuilder,
    private memberScreeningService: MemberScreeningsService,
    private managerScreeningService: ManagerScreeningsService,
    private routerService: RouterService,
    private toastrService: CommonToastrService,
    private adminScreeningsService: AdminScreeningsService
  ) { }

  ngOnInit(): void {


    this.availableScreeningTypes$ = this.adminScreeningsService.getScreenings({
      institutionId: this.routerService.institutionId,
      programId: this.routerService.programId,
    }).pipe(
      map(screenings => {
        return screenings.filter(s => s.active === eScreeningMode.Live).map(s => s.screeningType);
      })
    );

    this.memberScreeningService.getVolunteerScreening({
      institutionId: this.routerService.institutionId,
      memberId: this.data.memberId,
      profileScreeningId: this.data.profileScreeningId
    }).pipe(
      filter(screening => screening != null), take(1)).subscribe(screening => {
        this.form = this.fb.group({
          screeningType: [screening.type, [Validators.required]]
        });
      });
  }

  public close(): void {
    this.dialogRef.close();
  }

  public save() {
    this.managerScreeningService.changeScreeningLevel({
      institutionId: this.routerService.institutionId,
      managerId: this.routerService.managerId,
      programId: this.routerService.programId,
      profileScreeningId: this.data.profileScreeningId,
      update: {
        screeningType: this.form.value.screeningType
      }
    }).pipe(
      take(1)
    ).subscribe({
      next: res => {
        this.toastrService.success('Screening level updated');
        this.close();
      }, error: error => {
        this.toastrService.error('Could not update screening level', error);
      }
    });

  }
}
