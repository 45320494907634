import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { eFormControlType, eStandardFieldStatus, IStandardFormColumnDto } from 'app/core/models';

@Component({
  selector: 'ng4h-readonly-standard-form',
  templateUrl: './readonly-standard-form.component.html',
  styleUrls: ['./readonly-standard-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReadonlyStandardFormComponent implements OnInit {

  public eFormControlType = eFormControlType;
  public eStandardFieldStatus = eStandardFieldStatus;

  @Input() fields: { [filedId: string]: IStandardFormColumnDto[] };


  constructor() { }

  ngOnInit(): void {
  }

  // Here we go backwards until we hit a visible input
  public shouldBreakBefore(section: string, inputIndex: number) {
    if (this.fields == null) {
      return false;
    }
    const inputs = this.fields[section];
    for (let i = inputIndex - 1; i >= 0; i--) {
      if (inputs[i].inputState === eStandardFieldStatus.Visible) {
        return inputs[i].inputType !== eFormControlType.Checkbox;
      }
    }
    return false;
  }
  // Here we go forwards until we hit a visible input
  public shouldBreakAfter(section: string, inputIndex: number) {
    if (this.fields == null) {
      return false;
    }
    const inputs = this.fields[section];
    for (let i = inputIndex + 1; i < inputs.length; i++) {
      if (inputs[i].inputState === eStandardFieldStatus.Visible) {
        return inputs[i].inputType !== eFormControlType.Checkbox;
      }
    }
    return false;
  }
  public anyVisible(array: IStandardFormColumnDto[]) {
    return array.some(item => item.inputState !== eStandardFieldStatus.Hidden);
  }

  public retainOrder() {
    return 0;
  }
}
