import { Pipe, PipeTransform } from '@angular/core';
import { IMemberDto } from 'app/core/models';
import { MemberService, RouterService } from 'app/core/services';
import { Observable } from 'rxjs';

@Pipe({
    name: 'member$',
    pure: true
})
export class MemberPipe implements PipeTransform {
    constructor(private memberService: MemberService, private routerService: RouterService) {
    }

    public transform(memberId: string, institutionId: string = this.routerService.institutionId): Observable<IMemberDto> {
        return this.memberService.getMember({ institutionId, memberId });
    }
}
