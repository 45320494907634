<div class="form-group col-12">
  <div class="row">
    <label class="col col-4 form-control-label">{{label}}</label>
    <div class="col col-8">
      <select [ngModel]="value" (ngModelChange)="writeValue($event)" class="form-control">
        <option *ngFor="let permission of ePermissionState | keyvalue" [ngValue]="permission.key">
          {{permission.value}}
        </option>
      </select>
    </div>
  </div>
</div>