import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ISchoolDistrictDto, ISchoolDto } from 'app/core/models';


export const SchoolDistrictsLoadByCountyAction = createAction(
    '[school] Load districts by county',
    props<{ institutionId: string, countyId: string }>()
);
export const SchoolDistrictsLoadByCountySuccessAction = createAction(
    '[school] Load districts by county success',
    props<{ institutionId: string, countyId: string, districts: ISchoolDistrictDto[] }>()
);
export const SchoolDistrictsLoadByCountyErrorAction = createAction(
    '[school] Load districts by county error',
    props<{ error: HttpErrorResponse }>()
);

export const SchoolsLoadByDistrictAction = createAction(
    '[school] Load schools by district',
    props<{ institutionId: string, schoolDistrictId: string }>()
);
export const SchoolsLoadByDistrictSuccessAction = createAction(
    '[school] Load schools by district success',
    props<{ institutionId: string, schoolDistrictId: string, schools: ISchoolDto[] }>()
);
export const SchoolsLoadByDistrictErrorAction = createAction(
    '[school] Load schools by district error',
    props<{ error: HttpErrorResponse }>()
);
