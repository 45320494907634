import { eCharterStatusType } from '../enums';

export interface IClubCharterUpdateDto {
  status: eCharterStatusType;
  charterNumber?: string;
  charteredDate?: string;
  provisionalDate?: string;
  probationalDate?: string;
  revokedDate?: string;
  renewalDate?: string;
  inactiveDate?: string;
  closedDate?: string;
  charteringComment?: string;
}
