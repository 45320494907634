import { IHierarchyCountyArea } from './IHierarchyCountyArea';
import { IHierarchyDistrictArea } from './IHierarchyDistrictArea';
import { IHierarchyInstitution } from './IHierarchyInstitution';
import { IHierarchyRegionArea } from './IHierarchyRegionArea';

export type IHierarchyAny =
    IHierarchyInstitution
    | IHierarchyRegionArea
    | IHierarchyDistrictArea
    | IHierarchyCountyArea;
