import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'searchfilter',
    pure: true
})
export class SearchFilterPipe implements PipeTransform {
    transform(items: any[], field: string, value: string): any[] {
        if (!items) { return []; }
        if (value == null || value === '') { return items; }
        const filteredItems = items.filter(it => {
            return it[field].toUpperCase().indexOf(value.toUpperCase()) > -1;
        });
        return filteredItems;
    }
}
