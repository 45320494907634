import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IInstitutionProgramParams, IProgramYearDto } from 'app/core/models';

export const ProgramYearLoadAvailableAction = createAction(
  '[program-year] Load available years',
  props<IInstitutionProgramParams>()
);
export const ProgramYearLoadAvailableSuccessAction = createAction(
  '[program-year] Load available years success',
  props<IInstitutionProgramParams & { programYears: IProgramYearDto[] }>()
);
export const ProgramYearLoadAvailableErrorAction = createAction(
  '[program-year] Load available years error',
  props<{ error: HttpErrorResponse }>()
);

export const ProgramYearCurrentLoadAction = createAction(
  '[program-year] Load current',
  props<IInstitutionProgramParams>()
);
export const ProgramYearCurrentLoadSuccessAction = createAction(
  '[program-year] Load current success',
  props<IInstitutionProgramParams & { programYear: IProgramYearDto }>()
);
export const ProgramYearCurrentLoadErrorAction = createAction(
  '[program-year] Load current error',
  props<{ error: HttpErrorResponse }>()
);

export const ProgramYearUsersLoadAction = createAction(
  '[program-year] Load user',
  props<IInstitutionProgramParams & { institutionProfileId: string }>()
);
export const ProgramYearUsersSetAction = createAction(
  '[program-year] Set user',
  props<IInstitutionProgramParams & { institutionProfileId: string, programYear: IProgramYearDto }>()
);
export const ProgramYearUsersSetErrorAction = createAction(
  '[program-year] Set user error',
  props<IInstitutionProgramParams & { institutionProfileId: string }>()
);
