<div class="modal-header">
  <h5 class="modal-title">Add {{(eUnitType.Club | programAlias$ | async)?.plural}}</h5>
  <button (click)="close()" type="button" class="close" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<ng-container *ngIf="programSettings$ | async as programSettings">
  <div class="modal-body" *ngIf="enrollmentRole$ | async as enrollmentRole">
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label class="form-control-label ng4h-required">County</label>
          <ng4h-county-area-select
            [disabled]="programSettings.member_RestrictClubSelectionToFamilyCounty == true && (isManager$ | async) == false"
            [ngModel]="selectedCountyAreaId" (ngModelChange)="countyAreaIdChanged($event)">
          </ng4h-county-area-select>
        </div>
        <ng-container *ngIf="enrollmentRole !== eEnrollmentRoleType.CloverBud">
          <ng-container *ngIf="volunteerTypes$ | async as vts">
            <ng-container *ngIf="vts.length > 0">
              <div class="form-group">
                <label class="form-control-label"
                  [ngClass]="{'ng4h-required': enrollmentRole === eEnrollmentRoleType.Volunteer}">{{enrollmentRole ===
                  eEnrollmentRoleType.Volunteer ? '' : 'Youth'}} Volunteer Role</label>
                <select class="form-control" [ngModel]="volunteerType" (ngModelChange)="volunteerTypeChanged($event)">
                  <option *ngIf="enrollmentRole !== eEnrollmentRoleType.Volunteer" [ngValue]="null"></option>
                  <option *ngFor="let vt of vts" [ngValue]="vt">
                    {{ vt.volunteerTypeLabel }}
                  </option>
                </select>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>

      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <ng-container
          *ngIf="volunteerType != null || enrollmentRole !== eEnrollmentRoleType.Volunteer || (alwaysShowUnitAndProjects$ | async)">
          <div class="card club-card" *ngIf="(remainingClubs$ | async) as remainingClubs">
            <ul class="club-list list-group list-group-flush">
              <li class="list-group-item" *ngFor="let club of remainingClubs">
                <div>
                  {{club.unitName}}
                  <button type="button" class="btn btn-primary float-right add-club"
                    (click)="addClub(club)">Add</button>
                </div>
              </li>
              <li class="list-group-item" *ngIf="remainingClubs.length == 0">
                No clubs available
              </li>
            </ul>
          </div>
        </ng-container>
        <div *ngIf="selectedCountyAreaId == null">
          Please select a county
        </div>
        <div
          *ngIf="volunteerType == null && enrollmentRole === eEnrollmentRoleType.Volunteer && (alwaysShowUnitAndProjects$ | async) === false">
          Please select a Volunteer Type
        </div>
      </div>
    </div>
  </div>
</ng-container>
<div class="modal-footer">
  <button (click)="close()" class="btn btn-secondary my-2 my-sm-0" role="button" aria-pressed="true">Cancel</button>
</div>