import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IProgramPageMessageDto, IProgramPageMessageUpdateDto } from 'app/core/models';

export const PageMessagesLoadAction = createAction(
  '[page message] load page messages',
  props<{ institutionId: string, systemManagerId: string, programId: string }>()
);

export const PageMessagesLoadSuccessAction = createAction(
  '[page message] load page messages success',
  props<{ institutionId: string, systemManagerId: string, programId: string, messages: IProgramPageMessageDto[] }>()
);

export const PageMessagesLoadErrorAction = createAction(
  '[page message] load page messages error',
  props<{ error: HttpErrorResponse }>()
);


export const PageMessageUpdateAction = createAction(
  '[page message] update page message',
  props<{ institutionId: string, systemManagerId: string, programId: string, typeId: string, message: IProgramPageMessageUpdateDto }>()
);

export const PageMessageUpdateSuccessAction = createAction(
  '[page message] update page message success',
  props<{ institutionId: string, systemManagerId: string, programId: string, typeId: string, message: IProgramPageMessageUpdateDto }>()
);

export const PageMessageUpdateErrorAction = createAction(
  '[page message] update page message error',
  props<{ error: HttpErrorResponse }>()
);

export const PageMessageInvalidateCacheAction = createAction(
  '[page message] invalidate page message cache'
);
