import { Component, EventEmitter, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  AutoUnsubscribe,
  eEnrollmentRoleType,
  eUnitType,
  eVolunteerType,
  IEnrollmentDto,
  IEnrollmentProjectDto,
  IEnrollmentProjectSubmissionDto,
  IEnrollmentUnitDto,
  IHierarchyCountyArea,
  IInstitutionMemberProgramEnrollmentParams,
  IInstitutionVolunteerDto,
  IProgramSettingsDto,
  IProjectDto,
} from 'app/core/models';
import {
  CommonToastrService,
  EnrollmentService,
  HierarchyService,
  MemberService,
  ModalService,
  ProjectsService,
  RouterService,
} from 'app/core/services';
import { ProgramSettingsService } from 'app/core/services/program-settings.service';
import { combineLatest, delay, filter, map, Observable, switchMap, take, takeUntil } from 'rxjs';

import { ModalProjectsConsentComponent } from '../../modal-projects-consent/modal-projects-consent.component';


@Component({
  selector: 'ng4h-modal-add-projects-volunteer',
  templateUrl: './modal-add-projects-volunteer.component.html',
  styleUrls: ['./modal-add-projects-volunteer.component.scss']
})
export class ModalAddProjectsVolunteerComponent extends AutoUnsubscribe implements OnInit {

  public eUnitType = eUnitType;
  public selectedProject: IProjectDto;
  public yearsInProject: number;
  public eEnrollmentRoleType = eEnrollmentRoleType;

  public projectFilterString: string;
  public remainingProjects$: Observable<IProjectDto[]>;
  public selectedUnits$: Observable<IEnrollmentUnitDto[]>;
  public selectedUnitId: string;
  private selectedProjects$: Observable<IEnrollmentProjectDto[]>;

  public volunteerType: IInstitutionVolunteerDto;
  private enrollment$: Observable<IEnrollmentDto>;
  public volunteerTypes$: Observable<IInstitutionVolunteerDto[]>;

  public clubSelectionChanged$: EventEmitter<string> = new EventEmitter();

  public selectedCountyAreaId: string;
  public programSettings$: Observable<IProgramSettingsDto>;

  public showClubSelection = false;

  public showClubsStep$: Observable<boolean>;
  public alwaysShowUnitAndProjects$: Observable<boolean>;


  private institutionMemberProgramEnrollmentParams: IInstitutionMemberProgramEnrollmentParams;


  constructor(
    private dialogRef: MatDialogRef<any>,
    private modalService: ModalService,
    private projectsService: ProjectsService,
    private enrollmentService: EnrollmentService,
    private routerService: RouterService,
    private toastrService: CommonToastrService,
    private programSettingsService: ProgramSettingsService,
    private memberService: MemberService,
    private hierarchyService: HierarchyService
  ) {
    super();
    this.institutionMemberProgramEnrollmentParams = {
      institutionId: this.routerService.institutionId,
      memberId: this.routerService.memberId ? this.routerService.memberId : this.routerService.selectedMemberId,
      programId: this.routerService.programId,
      enrollmentId: this.routerService.enrollmentId
    };

    this.programSettings$ = this.programSettingsService.getProgramSettings({
      institutionId: this.institutionMemberProgramEnrollmentParams.institutionId,
      programId: this.institutionMemberProgramEnrollmentParams.programId
    });

    this.selectedUnits$ = this.enrollmentService.getEnrollment(this.institutionMemberProgramEnrollmentParams).pipe(
      map(enrollment => enrollment.units)
    );

    // this.selectedClubs$.pipe(take(1)).subscribe(clubs => {
    //   if (!Array.isArray(clubs) || clubs.length < 1) {
    //     this.showClubSelection = false;
    //   }
    // });




    this.selectedProjects$ = this.enrollmentService.getEnrollment(this.institutionMemberProgramEnrollmentParams).pipe(
      map(enrollment => enrollment.projects)
    );

    this.enrollment$ = this.enrollmentService.getEnrollment(this.institutionMemberProgramEnrollmentParams);


    this.volunteerTypes$ = this.enrollment$.pipe(
      map((enrollment) => {
        return enrollment.volunteerTypes.filter(v => {
          return v.volunteerTypeDescriptor.volunteerType === eVolunteerType.AdultProjectVolunteer;
        });
      })
    );

    this.volunteerTypes$.pipe(takeUntil(this.autoUnsubscribe)).subscribe(volunteerTypes => {
      if (Array.isArray(volunteerTypes) && volunteerTypes.length > 0) {
        this.volunteerTypeChanged(volunteerTypes[0]);
      }
    });


    this.remainingProjects$ =
      this.clubSelectionChanged$.pipe(
        switchMap(unitId => {
          return combineLatest(
            [
              unitId == null ?
                this.projectsService.getProjects({
                  institutionId: this.institutionMemberProgramEnrollmentParams.institutionId,
                  memberId: this.institutionMemberProgramEnrollmentParams.memberId,
                  programId: this.institutionMemberProgramEnrollmentParams.programId,
                  enrollmentId: this.institutionMemberProgramEnrollmentParams.enrollmentId
                })
                :
                this.projectsService.getProjectsByClub({
                  institutionId: this.institutionMemberProgramEnrollmentParams.institutionId,
                  memberId: this.institutionMemberProgramEnrollmentParams.memberId,
                  programId: this.institutionMemberProgramEnrollmentParams.programId,
                  enrollmentId: this.institutionMemberProgramEnrollmentParams.enrollmentId,
                  unitId: unitId
                }),
              this.selectedProjects$
            ]);
        }),
        filter(combined => combined.every(c => c != null)),
        map(([projects, selectedProjects]) => {
          return projects.filter(project => {
            const selectedClubIndex = selectedProjects.findIndex(selectedProject => {
              return selectedProject.projectId === project.id;
            });
            return selectedClubIndex === -1;
          });
        }),
      );

    this.alwaysShowUnitAndProjects$ = this.programSettings$.pipe(
      map(programSettings => programSettings.enrollment_Always_Show_Unit_And_Project_Selection === true)
    );

    this.showClubsStep$ = combineLatest([this.enrollment$, this.alwaysShowUnitAndProjects$]).pipe(
      filter(combined => combined.every(c => c != null)),
      map(([enrollment, alwaysShowUnitAndProjects]) => {
        return alwaysShowUnitAndProjects || enrollment.volunteerTypes.find(vt => vt.volunteerTypeDescriptor.volunteerType === eVolunteerType.AdultClubVolunteer) != null;
      })
    );



    this.memberService.getMember({ institutionId: this.institutionMemberProgramEnrollmentParams.institutionId, memberId: this.institutionMemberProgramEnrollmentParams.memberId }).pipe(
      filter(member => member != null),
      take(1),
      map(member => member.families.find(family => family.primary).countyAreaHierarchyNodeId),
      switchMap(countyAreaHierarchyNodeId => this.hierarchyService.getHierarchyNode({ institutionId: this.institutionMemberProgramEnrollmentParams.institutionId, hierarchyNodeId: countyAreaHierarchyNodeId })),
      filter(node => node != null),
      take(1),
      map((node: IHierarchyCountyArea) => node.countyAreaId)
    ).subscribe(countyAreaId => {
      this.countyAreaIdChanged(countyAreaId);
    });

    combineLatest([this.showClubsStep$, this.selectedUnits$, this.alwaysShowUnitAndProjects$]).pipe(
      filter(combined => combined.every(e => e != null)),
      take(1),
      delay(100)
    ).subscribe(([showClubsStep, clubs, alwaysShowUnitAndProjects]) => {
      if (alwaysShowUnitAndProjects === true && clubs?.length > 0) {
        this.selectedUnitId = null;
        this.showClubSelection = true;
      } else if (!showClubsStep || !Array.isArray(clubs) || clubs.length < 1) {
        this.selectedUnitId = null;
        this.showClubSelection = false;
      } else {
        this.selectedUnitId = clubs.find(c => c.primary === true).unitId;
        this.showClubSelection = true;
      }
      this.clubSelectionChanged$.emit(this.selectedUnitId);
    });

  }

  ngOnInit() {

  }

  public selectProject(project: IProjectDto) {
    this.selectedProject = project;
    this.yearsInProject = this.selectedProject.yearsInProjectForEnrollment;
  }

  public addProject(project: IProjectDto) {
    const projectSubmission: IEnrollmentProjectSubmissionDto = {
      projectId: project.id,
      unitId: this.showClubSelection ? this.selectedUnitId : null,
      consents: [],
      volunteerTypeId: this.volunteerType?.volunteerTypeId,
      countyAreaId: this.showClubSelection ? null : this.selectedCountyAreaId,
      yearsInProject: this.yearsInProject
    };
    if (project.consents.length === 0) {
      this.enrollmentService.saveProject({
        ...this.institutionMemberProgramEnrollmentParams,
        projectSubmission
      }).pipe(
        take(1),
        switchMap(() => this.enrollmentService.refreshEnrollment()),
        take(1),
        this.modalService.showProgressSpinnerModalUntilComplete()
      ).subscribe({
        next: () => {
          this.toastrService.success('Project Saved');
          this.close();
        }, error: (error) => {
          this.toastrService.error('Could not save project', error);
        }
      }
      );

    } else {
      this.close();
      this.modalService.openModal(ModalProjectsConsentComponent, {
        data: { project: project, submission: projectSubmission }
      });
    }
  }



  public close() {
    this.dialogRef.close();
  }

  public volunteerTypeChanged(volunteerType: IInstitutionVolunteerDto) {
    this.volunteerType = volunteerType;
  }

  public countyAreaIdChanged(selectedCountyAreaId) {
    if (selectedCountyAreaId !== '') {
      this.selectedCountyAreaId = selectedCountyAreaId;
    }
  }

  public showClubSelectionChange(showClubSelection: boolean) {
    if (showClubSelection === false) {
      this.selectedUnitId = null;
      this.clubSelectionChanged$.emit(this.selectedUnitId);
    } else {
      this.selectedUnits$.pipe(
        filter(clubs => Array.isArray(clubs)),
        take(1)
      ).subscribe(clubs => {
        this.selectedUnitId = clubs[0].unitId;
        this.clubSelectionChanged$.emit(this.selectedUnitId);
      });
    }
  }
}
