import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IInstitutionManagerProgramHierarchyParams, ITrainingSearchParametersDto } from 'app/core/models';
import { IMemberSearchResultsDto } from 'app/core/models/serverDTOs/IMemberSearchResultsDto';


export const SearchTrainingsAction = createAction(
    '[trainings-search] Search trainings',
    props<IInstitutionManagerProgramHierarchyParams & { searchParameters: ITrainingSearchParametersDto }>()
);

export const SearchTrainingsSuccessAction = createAction(
    '[trainings-search] Search trainings success',
    props<IInstitutionManagerProgramHierarchyParams & { searchParameters: ITrainingSearchParametersDto, trainingsSearchResult: IMemberSearchResultsDto }>()
);

export const SearchTrainingsErrorAction = createAction(
    '[trainings-search] Search trainings error',
    props<{ error: HttpErrorResponse }>()
);


export const GetTrainingSearchResultsAction = createAction(
    '[trainings-search] get trainings search results',
    props<IInstitutionManagerProgramHierarchyParams & { pageSize: string, pageIndex: string }>()
);

export const GetTrainingSearchResultsSuccessAction = createAction(
    '[trainings-search] get trainings search results success',
    props<IInstitutionManagerProgramHierarchyParams & { pageSize: string, pageIndex: string, trainingsSearchResult: IMemberSearchResultsDto }>()
);

export const GetTrainingSearchResultsErrorAction = createAction(
    '[trainings-search] get trainings search results error',
    props<{ error: HttpErrorResponse }>()
);


export const ClearTrainingSearchAction = createAction(
    '[trainings-search] clear trainings search',
    props<IInstitutionManagerProgramHierarchyParams>()
);

export const ClearTrainingSearchSuccessAction = createAction(
    '[trainings-search] clear trainings search success',
    props<IInstitutionManagerProgramHierarchyParams>()
);

export const ClearTrainingSearchErrorAction = createAction(
    '[trainings-search] clear trainings search error',
    props<{ error: HttpErrorResponse }>()
);
