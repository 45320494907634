import { Action, createReducer, on } from '@ngrx/store';
import { IActivityDto, IInstitutionMemberProgramEnrollmentModel } from 'app/core/models';
import { mergeImmutable } from 'app/shared/utils';

import { MemberActivitiesActions } from '.';
import { MemberActions } from '../..';

export interface MemberActivitiesState {
    activities: IInstitutionMemberProgramEnrollmentModel<IActivityDto[]>;
}

const initialState: MemberActivitiesState = {
    activities: undefined
};

const reducer = createReducer(
    initialState,
    on(MemberActivitiesActions.LoadActivitiesForEnrollmentSuccessAction, (state, { institutionId, memberId, programId, enrollmentId, activities }) => {
        const newActivities = mergeImmutable(
            { [institutionId]: { [memberId]: { [programId]: { [enrollmentId]: activities } } } },
            state.activities
        );
        return {
            ...state,
            activities: newActivities
        };
    }),
    on(MemberActions.MemberInvalidateMemberCache,
        MemberActivitiesActions.DeleteActivityForEnrollmentSuccessAction, (state, { }) => {
            return initialState;
        })
);

export function activitiesReducer(state: MemberActivitiesState | undefined, actions: Action) {
    return reducer(state, actions);
}

export const activities = (state: MemberActivitiesState) => state.activities;
