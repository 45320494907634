import { eProfileTrainingStatus, IProgramYearDto } from '..';

export interface IProfileTrainingDescriptorDto {
    profileTrainingId: string;
    trainingId: string;
    trainingName?: string;
    status: eProfileTrainingStatus;
    required: boolean;
    completionDate: string;
    expirationDate: IProgramYearDto;

    programYear: IProgramYearDto;
    trainingSource?: string;
    certificateNumber?: number;
    canDelete: boolean;
}
