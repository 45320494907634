import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ng4h-manager-sidebar-ad',
  templateUrl: './manager-sidebar-ad.component.html',
  styleUrls: ['./manager-sidebar-ad.component.scss']
})
export class ManagerSidebarAdComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
