import { IHierarchyNodeDto } from './Hierarchy/IHierarchyNodeDto';
import { IEnrollmentRoleInvoiceItemDto } from './IEnrollmentRoleInvoiceItemDto';

export interface IEnrollmentHierarchyInvoiceItemsDto {
  programYearId: string;
  hierarchyNode: IHierarchyNodeDto;
  cloverBud: IEnrollmentRoleInvoiceItemDto;
  youth: IEnrollmentRoleInvoiceItemDto;
  adult: IEnrollmentRoleInvoiceItemDto;

  // client value to determine whether a row is editable or not -> fees.component
  edit?: boolean;
}
