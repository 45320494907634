export enum eFundsTransferStatus {
    Pending = 'Pending',
    Finalized = 'Finalized',
    Declined = 'Declined',
    Error = 'Error',
    Canceled = 'Canceled',
    Void = 'Void',
    Initiated = 'Initiated',
    Accepted = 'Accepted',
}
