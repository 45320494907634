import { Action, createReducer, on } from '@ngrx/store';
import { IHierarchyDistrictArea } from 'app/core/models';

import { DistrictActions } from '.';
import { mergeImmutable } from '../utils';



export interface DistrictState {
  districts: { [institutionId: string]: IHierarchyDistrictArea[] };
}

const initialState: DistrictState = {
  districts: undefined
};


const reducer = createReducer(
  initialState,
  on(DistrictActions.DistrictLoadDistrictsSuccessAction, (state, { institutionId, districts }) => {
    return {
      ...state,
      districts: mergeImmutable(
        { [institutionId]: districts },
        state.districts
      )
    };
  })
);

export function districtsReducer(state: DistrictState | undefined, actions: Action) {
  return reducer(state, actions);
}


export const districts = (state: DistrictState) => state.districts;
