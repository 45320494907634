import { IMemberDescriptorDto, IVolunteerACLDto } from '.';

export interface IProjectLeaderDto {
    projectLeaderId: string;
    member: IMemberDescriptorDto;
    projectId: string;
    projectName?: string;
    projectYearStatuses?: object;
    permissions: IVolunteerACLDto;
}
