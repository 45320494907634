import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  IDiscountFundDescriptorDto,
  IDiscountFundDonationCreateDto,
  IDiscountFundDonationDescriptorDto,
  IDiscountFundDonationUpdateDto,
  IDiscountFundDonorCreateDto,
  IDiscountFundDonorDto,
  IDiscountFundDonorUpdateDto,
  IInstitutionDiscountCreateDto,
  IInvoiceWaiverDto,
} from 'app/core/models';
import {
  IInstitutionManagerProgramHierarchyParams,
  IInstitutionManagerProgramParams,
  IInstitutionMemberProgramEnrollmentParams,
} from 'app/core/models/function-parameters';
import {
  IInstitutionMemberProgramEventRegistrationParams,
} from 'app/core/models/function-parameters/institution-member-program-event-registration';
import { IInstitutionDiscountDto } from 'app/core/models/serverDTOs/IInstitutionDiscountDto';

export const ManagerGetFundsAction = createAction(
  '[manager finances] get manager funds',
  props<IInstitutionManagerProgramParams>()
);

export const ManagerGetFundsSuccessAction = createAction(
  '[manager finances] get manager funds success',
  props<IInstitutionManagerProgramParams & { funds: IDiscountFundDescriptorDto[] }>()
);

export const ManagerGetFundsErrorAction = createAction(
  '[manager finances] get manager funds error',
  props<{ error: HttpErrorResponse }>()
);
export const ManagerHierarchyGetFundsAction = createAction(
  '[manager finances] get manager hierarchy funds',
  props<IInstitutionManagerProgramHierarchyParams>()
);

export const ManagerHierarchyGetFundsSuccessAction = createAction(
  '[manager finances] get manager hierarchy funds success',
  props<IInstitutionManagerProgramHierarchyParams & { hierarchyFunds: IDiscountFundDescriptorDto[] }>()
);

export const ManagerHierarchyGetFundsErrorAction = createAction(
  '[manager finances] get manager hierarchy funds error',
  props<{ error: HttpErrorResponse }>()
);

export const ManagerGetFundByIdAction = createAction(
  '[manager finances] get manager fund by id',
  props<IInstitutionManagerProgramParams & { discountFundId: string }>()
);

export const ManagerGetFundByIdSuccessAction = createAction(
  '[manager finances] get manager fund by id success',
  props<IInstitutionManagerProgramParams & { discountFundId: string, fund: IDiscountFundDescriptorDto }>()
);

export const ManagerGetFundByIdErrorAction = createAction(
  '[manager finances] get manager fund by id error',
  props<{ error: HttpErrorResponse }>()
);

export const ManagerGetDonorsAction = createAction(
  '[manager finances] get manager donors',
  props<IInstitutionManagerProgramParams>()
);

export const ManagerGetDonorsSuccessAction = createAction(
  '[manager finances] get manager donors success',
  props<IInstitutionManagerProgramParams & { donors: IDiscountFundDonorDto[] }>()
);

export const ManagerGetDonorsErrorAction = createAction(
  '[manager finances] get manager donors error',
  props<{ error: HttpErrorResponse }>()
);

export const ManagerGetDonorByIdAction = createAction(
  '[manager finances] get manager donor by id',
  props<IInstitutionManagerProgramParams & { discountFundDonorId: string }>()
);

export const ManagerGetDonorByIdSuccessAction = createAction(
  '[manager finances] get manager donor by id success',
  props<IInstitutionManagerProgramParams & { discountFundDonorId: string, donor: IDiscountFundDonorDto }>()
);

export const ManagerGetDonorByIdErrorAction = createAction(
  '[manager finances] get manager donor by id error',
  props<{ error: HttpErrorResponse }>()
);

export const UpdateFundDonorAction = createAction(
  '[manager finances] Update donor',
  props<IInstitutionManagerProgramParams & { discountFundDonorId: string, update: IDiscountFundDonorUpdateDto }>()
);

export const UpdateFundDonorSuccessAction = createAction(
  '[manager finances] Update donor success',
  props<IInstitutionManagerProgramParams & { discountFundDonorId: string, update: IDiscountFundDonorUpdateDto }>()
);

export const UpdateFundDonorErrorAction = createAction(
  '[manager finances] Update donor error',
  props<{ error: HttpErrorResponse }>()
);

export const ManagerAddDonorAction = createAction(
  '[manager finances] add manager donor',
  props<IInstitutionManagerProgramParams & { donor: IDiscountFundDonorCreateDto }>()
);

export const ManagerAddDonorSuccessAction = createAction(
  '[manager finances] add manager donor success',
  props<IInstitutionManagerProgramParams & { donor: IDiscountFundDonorCreateDto }>()
);

export const ManagerAddDonorErrorAction = createAction(
  '[manager finances] add manager donor error',
  props<{ error: HttpErrorResponse }>()
);

export const ManagerGetFundDonationsAction = createAction(
  '[manager finances] get manager fund donations',
  props<IInstitutionManagerProgramParams & { discountFundId: string }>()
);

export const ManagerGetFundDonationsSuccessAction = createAction(
  '[manager finances] get manager fund donations success',
  props<IInstitutionManagerProgramParams & { discountFundId: string, donations: IDiscountFundDonationDescriptorDto[] }>()
);

export const ManagerGetFundDonationsErrorAction = createAction(
  '[manager finances] get manager fund donations error',
  props<{ error: HttpErrorResponse }>()
);

export const ManagerGetCountyFundDonationsAction = createAction(
  '[manager finances] get county manager fund donations',
  props<IInstitutionManagerProgramParams & { discountFundId: string, countyAreaId: string }>()
);

export const ManagerGetCountyFundDonationsSuccessAction = createAction(
  '[manager finances] get county manager fund donations success',
  props<IInstitutionManagerProgramParams & { discountFundId: string, countyAreaId: string, donations: IDiscountFundDonationDescriptorDto[] }>()
);

export const ManagerGetCountyFundDonationsErrorAction = createAction(
  '[manager finances] get county manager fund donations error',
  props<{ error: HttpErrorResponse }>()
);

export const ManagerAddFundDonationAction = createAction(
  '[manager finances] add fund donation',
  props<IInstitutionManagerProgramParams & { discountFundId: string, discountFundDonorId: string, newDonation: IDiscountFundDonationCreateDto }>()
);

export const ManagerAddFundDonationSuccessAction = createAction(
  '[manager finances] add fund donation success',
  props<IInstitutionManagerProgramParams & { discountFundId: string, discountFundDonorId: string, newDonation: IDiscountFundDonationCreateDto }>()
);

export const ManagerAddFundDonationErrorAction = createAction(
  '[manager finances] add fund donation error',
  props<{ error: HttpErrorResponse }>()
);

export const ManagerEditFundDonationAction = createAction(
  '[manager finances] edit fund donation',
  props<IInstitutionManagerProgramParams & { discountFundId: string, discountFundDonationId: string, update: IDiscountFundDonationUpdateDto }>()
);

export const ManagerEditFundDonationSuccessAction = createAction(
  '[manager finances] edit fund donation success',
  props<IInstitutionManagerProgramParams & { discountFundId: string, discountFundDonationId: string, update: IDiscountFundDonationUpdateDto }>()
);

export const ManagerEditFundDonationErrorAction = createAction(
  '[manager finances] edit fund donation error',
  props<{ error: HttpErrorResponse }>()
);
export const ManagerDeleteFundDonationAction = createAction(
  '[manager finances] Delete fund donation',
  props<IInstitutionManagerProgramParams & { discountFundId: string, discountFundDonationId: string }>()
);

export const ManagerDeleteFundDonationSuccessAction = createAction(
  '[manager finances] Delete fund donation success',
  props<IInstitutionManagerProgramParams & { discountFundId: string, discountFundDonationId: string }>()
);

export const ManagerDeleteFundDonationErrorAction = createAction(
  '[manager finances] Delete fund donation error',
  props<{ error: HttpErrorResponse }>()
);

export const ManagerGetDonationByIdAction = createAction(
  '[manager finances] get manager donation by id',
  props<IInstitutionManagerProgramParams & { discountFundId: string, discountFundDonationId: string }>()
);

export const ManagerGetDonationByIdSuccessAction = createAction(
  '[manager finances] get manager donation by id success',
  props<IInstitutionManagerProgramParams & { discountFundId: string, discountFundDonationId: string, donation: IDiscountFundDonationDescriptorDto }>()
);

export const ManagerGetDonationByIdErrorAction = createAction(
  '[manager finances] get manager donation by id error',
  props<{ error: HttpErrorResponse }>()
);

export const ManagerGetCountyDonorsAction = createAction(
  '[manager finances] get manager county donors',
  props<IInstitutionManagerProgramParams & { countyAreaId: string }>()
);

export const ManagerGetCountyDonorsSuccessAction = createAction(
  '[manager finances] get manager county donors success',
  props<IInstitutionManagerProgramParams & { countyAreaId: string, donors: IDiscountFundDonorDto[] }>()
);

export const ManagerGetCountyDonorsErrorAction = createAction(
  '[manager finances] get manager county donors error',
  props<{ error: HttpErrorResponse }>()
);


export const ManagerGetCountyDonorByIdAction = createAction(
  '[manager finances] get county manager donor by id',
  props<IInstitutionManagerProgramParams & { discountFundDonorId: string, countyAreaId: string }>()
);

export const ManagerGetCountyDonorByIdSuccessAction = createAction(
  '[manager finances] get county manager donor by id success',
  props<IInstitutionManagerProgramParams & { discountFundDonorId: string, countyAreaId: string, donor: IDiscountFundDonorDto }>()
);

export const ManagerGetCountyDonorByIdErrorAction = createAction(
  '[manager finances] get county manager donor by id error',
  props<{ error: HttpErrorResponse }>()
);

export const ManagerAddCountyDonorAction = createAction(
  '[manager finances] add manager county donor',
  props<IInstitutionManagerProgramParams & { countyAreaId: string, donor: IDiscountFundDonorCreateDto }>()
);

export const ManagerAddCountyDonorSuccessAction = createAction(
  '[manager finances] add manager county donor success',
  props<IInstitutionManagerProgramParams & { countyAreaId: string, donor: IDiscountFundDonorCreateDto }>()
);

export const ManagerAddCountyDonorErrorAction = createAction(
  '[manager finances] add manager county donor error',
  props<{ error: HttpErrorResponse }>()
);

export const ManagerGetCountyFundsAction = createAction(
  '[manager finances] get manager county funds',
  props<IInstitutionManagerProgramParams & { countyAreaId: string }>()
);

export const ManagerGetCountyFundsSuccessAction = createAction(
  '[manager finances] get manager county funds success',
  props<IInstitutionManagerProgramParams & { countyAreaId: string, funds: IDiscountFundDescriptorDto[] }>()
);

export const ManagerGetCountyFundsErrorAction = createAction(
  '[manager finances] get manager county funds error',
  props<{ error: HttpErrorResponse }>()
);

export const ManagerGetCountyFundByIdAction = createAction(
  '[manager finances] get manager county fund by id',
  props<IInstitutionManagerProgramParams & { discountFundId: string, countyAreaId: string }>()
);

export const ManagerGetCountyFundByIdSuccessAction = createAction(
  '[manager finances] get manager county fund by id success',
  props<IInstitutionManagerProgramParams & { discountFundId: string, countyAreaId: string, fund: IDiscountFundDescriptorDto }>()
);

export const ManagerGetCountyFundByIdErrorAction = createAction(
  '[manager finances] get manager county fund by id error',
  props<{ error: HttpErrorResponse }>()
);

export const ManagerGetDiscountsAction = createAction(
  '[manager finances] get manager discounts',
  props<IInstitutionManagerProgramParams & { programYearId: string, }>()
);

export const ManagerGetDiscountsSuccessAction = createAction(
  '[manager finances] get manager discounts success',
  props<IInstitutionManagerProgramParams & { programYearId: string, discounts: IInstitutionDiscountDto[] }>()
);

export const ManagerGetDiscountsErrorAction = createAction(
  '[manager finances] get manager discounts error',
  props<{ error: HttpErrorResponse }>()
);

export const ManagerAddDiscountAction = createAction(
  '[manager finances] add manager discounts',
  props<IInstitutionManagerProgramParams & { programYearId: string, discount: IInstitutionDiscountCreateDto }>()
);

export const ManagerAddDiscountSuccessAction = createAction(
  '[manager finances] add manager discounts success',
  props<IInstitutionManagerProgramParams & { programYearId: string, discount: IInstitutionDiscountCreateDto, newDiscount: IInstitutionDiscountDto }>()
);

export const ManagerAddDiscountErrorAction = createAction(
  '[manager finances] add manager discounts error',
  props<{ error: HttpErrorResponse }>()
);

export const ManagerUpdateDiscountAction = createAction(
  '[manager finances] update manager discount',
  props<IInstitutionManagerProgramParams & { institutionDiscountId: string, update: any }>()
);

export const ManagerUpdateDiscountSuccessAction = createAction(
  '[manager finances] update manager discount success',
  props<IInstitutionManagerProgramParams & { institutionDiscountId: string, update: any }>()
);

export const ManagerUpdateDiscountErrorAction = createAction(
  '[manager finances] update manager discount error',
  props<{ error: HttpErrorResponse }>()
);

export const ManagerDeleteDiscountAction = createAction(
  '[manager finances] delete manager discount',
  props<IInstitutionManagerProgramParams & { institutionDiscountId: string }>()
);

export const ManagerDeleteDiscountSuccessAction = createAction(
  '[manager finances] delete manager discount success',
  props<IInstitutionManagerProgramParams & { institutionDiscountId: string }>()
);

export const ManagerDeleteDiscountErrorAction = createAction(
  '[manager finances] delete manager discount error',
  props<{ error: HttpErrorResponse }>()
);

export const ManagerGetDiscountAction = createAction(
  '[manager finances] get discount by id',
  props<IInstitutionManagerProgramParams & { institutionDiscountId: string }>()
);

export const ManagerGetDiscountSuccessAction = createAction(
  '[manager finances] get discount by id success',
  props<IInstitutionManagerProgramParams & { institutionDiscountId: string, discount: IInstitutionDiscountDto }>()
);

export const ManagerGetDiscountErrorAction = createAction(
  '[manager finances] get discount by id error',
  props<{ error: HttpErrorResponse }>()
);

export const ManagerAddClubsAction = createAction(
  '[manager finances] add clubs',
  props<IInstitutionManagerProgramParams & { institutionDiscountId: string, clubs: string[] }>()
);

export const ManagerAddClubsSuccessAction = createAction(
  '[manager finances] add clubs success',
  props<IInstitutionManagerProgramParams & { institutionDiscountId: string, clubs: string[] }>()
);

export const ManagerAddClubsErrorAction = createAction(
  '[manager finances] add clubs error',
  props<{ error: HttpErrorResponse }>()
);

export const ManagerApplyEnrollmentWaiverAction = createAction(
  '[manager finances] apply enrollment waiver',
  props<IInstitutionMemberProgramEnrollmentParams & { invoiceId: string, invoiceWaiver: IInvoiceWaiverDto }>()
);
export const ManagerApplyEnrollmentWaiverSuccessAction = createAction(
  '[manager finances] apply enrollment waiver success',
  props<IInstitutionMemberProgramEnrollmentParams & { invoiceId: string, invoiceWaiver: IInvoiceWaiverDto }>()
);
export const ManagerApplyEnrollmentWaiverErrorAction = createAction(
  '[manager finances] apply enrollment waiver error',
  props<{ error: HttpErrorResponse }>()
);


export const ManagerApplyEventWaiverAction = createAction(
  '[manager finances] apply event waiver',
  props<IInstitutionMemberProgramEventRegistrationParams & { invoiceId: string, invoiceWaiver: IInvoiceWaiverDto }>()
);
export const ManagerApplyEventWaiverSuccessAction = createAction(
  '[manager finances] apply event waiver success',
  props<IInstitutionMemberProgramEventRegistrationParams & { invoiceId: string, invoiceWaiver: IInvoiceWaiverDto }>()
);
export const ManagerApplyEventWaiverErrorAction = createAction(
  '[manager finances] apply event waiver error',
  props<{ error: HttpErrorResponse }>()
);
