import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState, getDeliveryModes, getGroupEnrollmentDeliveryModes } from 'app/app.reducers';
import { DeliveryModeActions } from 'app/shared/delivery-mode';
import { filter, map, Observable, of, tap } from 'rxjs';

import { IDeliveryModeDto, IInstitutionProgramParams } from '../models';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeliveryModeService {

  constructor(private store: Store<AppState>, private httpClient: HttpClient) {
  }

  public getDeliveryModes(params: IInstitutionProgramParams): Observable<IDeliveryModeDto[]> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getDeliveryModes(params)),
      tap(deliveryModes => {
        if (deliveryModes === undefined) {
          this.store.dispatch(DeliveryModeActions.DeliveryModesLoadAction(params));
        }
      }),
      filter(deliveryModes => deliveryModes != null)
    );
  }

  public getDeliveryModesEffect({ institutionId, programId }: IInstitutionProgramParams) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/programs/${programId}/delivery-modes`) as Observable<IDeliveryModeDto[]>;
  }

  public getDeliveryMode({
    institutionId,
    programId,
    deliveryModeId
  }: IInstitutionProgramParams & { deliveryModeId: string }): Observable<IDeliveryModeDto> {
    return this.getDeliveryModes({ institutionId, programId }).pipe(
      filter(dms => Array.isArray(dms)),
      map(dms => dms.find(dm => dm.deliveryModeId === deliveryModeId))
    );
  }

  public getGroupEnrollmentDeliveryModes(params: IInstitutionProgramParams) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getGroupEnrollmentDeliveryModes(params)),
      tap(deliveryModes => {
        if (deliveryModes === undefined) {
          this.store.dispatch(DeliveryModeActions.GroupEnrollmentDeliveryModesLoadAction(params));
        }
      }),
      filter(deliveryModes => deliveryModes != null)
    );
  }

  public getGroupEnrollmentDeliveryModesEffect({ institutionId, programId }: IInstitutionProgramParams) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/programs/${programId}/delivery-modes-group-enrollments`) as Observable<IDeliveryModeDto[]>;
  }

}
