import { IBroadcastEmailStatsDto } from '.';
import { eBroadcastEmailRecipientTargetType, eBroadcastEmailStatus } from '../enums';
import { IBroadcastEmailAttachmentDto } from './IBroadcastEmailAttachmentDto';
import { IBroadcastEmailOptionsDto } from './IBroadcastEmailOptionsDto';
import { IEmailMergeFieldDto } from './IEmailMergeFieldDto';

export interface IBroadcastEmailDto {
  broadcastEmailId: string;
  createdByProfileId: string;
  dateCreated: string;
  dateSent?: string;
  dateCompleted?: string;
  recipientType: eBroadcastEmailRecipientTargetType;
  // recipientTypeSourceId: IMeaningfulId;
  recipientName?: string;
  status: eBroadcastEmailStatus;
  statusMessage?: string;
  attachments?: IBroadcastEmailAttachmentDto[];
  statistics: IBroadcastEmailStatsDto;
  mergeFields?: IEmailMergeFieldDto[];
  title?: string;
  subject?: string;
  replyTo?: string;
  htmlBody?: string;
  textBody?: string;
  options: IBroadcastEmailOptionsDto;
}
