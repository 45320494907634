import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  ICustomReportCreateDto,
  ICustomReportDto,
  ICustomReportFilterDto,
  ICustomReportFilterGroupDto,
  ICustomReportGroupByDto,
  ICustomReportOrderByDto,
  ICustomReportUpdateDto,
  IDirectDataAccessRequestDto,
  IInstitutionManagerProgramEventParams,
  IQueuedReportDto,
  IReportColumnDto,
  IReportFolderCreateDto,
  IReportFolderDto,
  IReportFolderUpdateDto,
  IReportFolderUpdateStateDto,
} from 'app/core/models';

export const CustomReportsLoadReportsAction = createAction(
    '[event-custom-reports] Load reports',
    props<IInstitutionManagerProgramEventParams>()
);

export const CustomReportsLoadReportsSuccessAction = createAction(
    '[event-custom-reports] Load reports success',
    props<IInstitutionManagerProgramEventParams & { reports: IReportFolderDto[] }>()
);

export const CustomReportsLoadReportsErrorAction = createAction(
    '[event-custom-reports] Load reports error',
    props<{ error: HttpErrorResponse }>()
);


export const CustomReportsLoadReportAction = createAction(
    '[event-custom-reports] Load report',
    props<IInstitutionManagerProgramEventParams & { customReportId: string }>()
);

export const CustomReportsLoadReportSuccessAction = createAction(
    '[event-custom-reports] Load report success',
    props<IInstitutionManagerProgramEventParams & { customReportId: string, report: ICustomReportDto }>()
);

export const CustomReportsLoadReportErrorAction = createAction(
    '[event-custom-reports] Load report error',
    props<{ error: HttpErrorResponse }>()
);

export const CreateReportFolderAction = createAction(
    '[event-custom-reports] Create report folder',
    props<IInstitutionManagerProgramEventParams & { newFolder: IReportFolderCreateDto }>()
);
export const CreateReportFolderSuccessAction = createAction(
    '[event-custom-reports] Create report folder success',
    props<IInstitutionManagerProgramEventParams & { newFolder: IReportFolderCreateDto }>()
);
export const CreateReportFolderErrorAction = createAction(
    '[event-custom-reports] Create report folder error',
    props<{ error: HttpErrorResponse }>()
);

export const UpdateReportFolderAction = createAction(
    '[event-custom-reports] Update report folder',
    props<IInstitutionManagerProgramEventParams & { customReportFolderId: string, folderUpdate: IReportFolderUpdateDto }>()
);
export const UpdateReportFolderSuccessAction = createAction(
    '[event-custom-reports] Update report folder success',
    props<IInstitutionManagerProgramEventParams & { customReportFolderId: string, folderUpdate: IReportFolderUpdateDto }>()
);
export const UpdateReportFolderErrorAction = createAction(
    '[event-custom-reports] Update report folder error',
    props<{ error: HttpErrorResponse }>()
);

export const UpdateReportFolderStateAction = createAction(
    '[event-custom-reports] Update report folder state',
    props<IInstitutionManagerProgramEventParams & { customReportFolderId: string, folderState: IReportFolderUpdateStateDto }>()
);
export const UpdateReportFolderStateSuccessAction = createAction(
    '[event-custom-reports] Update report folder state success',
    props<IInstitutionManagerProgramEventParams & { customReportFolderId: string, folderState: IReportFolderUpdateStateDto }>()
);
export const UpdateReportFolderStateErrorAction = createAction(
    '[event-custom-reports] Update report folder state error',
    props<{ error: HttpErrorResponse }>()
);

export const DeleteReportFolderAction = createAction(
    '[event-custom-reports] Delete report folder',
    props<IInstitutionManagerProgramEventParams & { customReportFolderId: string }>()
);
export const DeleteReportFolderSuccessAction = createAction(
    '[event-custom-reports] Delete report folder success',
    props<IInstitutionManagerProgramEventParams & { customReportFolderId: string }>()
);
export const DeleteReportFolderErrorAction = createAction(
    '[event-custom-reports] Delete report folder error',
    props<{ error: HttpErrorResponse }>()
);

export const CustomReportsCreateReportAction = createAction(
    '[event-custom-reports] Create report',
    props<IInstitutionManagerProgramEventParams & { customReportFolderId: string, newReport: ICustomReportCreateDto }>()
);

export const CustomReportsCreateReportSuccessAction = createAction(
    '[event-custom-reports] Create report success',
    props<IInstitutionManagerProgramEventParams & { customReportFolderId: string, newReport: ICustomReportCreateDto, report: ICustomReportDto }>()
);

export const CustomReportsCreateReportErrorAction = createAction(
    '[event-custom-reports] Create report error',
    props<{ error: HttpErrorResponse }>()
);

export const CustomReportsAddFilterGroup = createAction(
    '[event-custom-reports] Add filter group',
    props<IInstitutionManagerProgramEventParams & { customReportId: string, filterGroup: ICustomReportFilterGroupDto }>()
);
export const CustomReportsReorderFilterGroup = createAction(
    '[event-custom-reports] Reorder filter group',
    props<IInstitutionManagerProgramEventParams & { customReportId: string, filterGroups: ICustomReportFilterGroupDto[] }>()
);

export const CustomReportsRemoveFilterGroup = createAction(
    '[event-custom-reports] Remove filter group',
    props<IInstitutionManagerProgramEventParams & { customReportId: string, filterGroupIndex: number }>()
);

export const CustomReportsAddFilter = createAction(
    '[event-custom-reports] Add filter to group',
    props<IInstitutionManagerProgramEventParams & { customReportId: string, filterGroupIndex: number, filter: ICustomReportFilterDto }>()
);
export const CustomReportsUpdateFilterValue = createAction(
    '[event-custom-reports] Update Filter value',
    props<IInstitutionManagerProgramEventParams & { customReportId: string, filterGroupIndex: number, filterIndex: number, filter: ICustomReportFilterDto }>()
);
export const CustomReportsReorderFilters = createAction(
    '[event-custom-reports] Reorder filters',
    props<IInstitutionManagerProgramEventParams & { customReportId: string, filterGroupIndex: number, filters: ICustomReportFilterDto[] }>()
);
export const CustomReportsRemoveFilter = createAction(
    '[event-custom-reports] Remove filter from group',
    props<IInstitutionManagerProgramEventParams & { customReportId: string, filterGroupIndex: number, filterIndex: number }>()
);



export const CustomReportsLoadAvailableColumnsAction = createAction(
    '[event-custom-reports] Load available columns',
    props<IInstitutionManagerProgramEventParams & { customReportId: string }>()
);

export const CustomReportsLoadAvailableColumnsSuccessAction = createAction(
    '[event-custom-reports] Load available columns success',
    props<IInstitutionManagerProgramEventParams & { customReportId: string, availableColumns: IReportColumnDto[] }>()
);

export const CustomReportsLoadAvailableColumnsErrorAction = createAction(
    '[event-custom-reports] Load available columns error',
    props<{ error: HttpErrorResponse }>()
);

export const CustomReportsReorderColumns = createAction(
    '[event-custom-reports] Reorder column',
    props<IInstitutionManagerProgramEventParams & { customReportId: string, columns: IReportColumnDto[] }>()
);
export const CustomReportsAddColumnToReport = createAction(
    '[event-custom-reports] Add column',
    props<IInstitutionManagerProgramEventParams & { customReportId: string, column: IReportColumnDto }>()
);

export const CustomReportsDeleteColumnToReport = createAction(
    '[event-custom-reports] Delete column',
    props<IInstitutionManagerProgramEventParams & { customReportId: string, column: IReportColumnDto }>()
);

export const CustomReportsUpdateReportAction = createAction(
    '[event-custom-reports] Update report',
    props<IInstitutionManagerProgramEventParams & { customReportId: string, customReportUpdate: ICustomReportUpdateDto }>()
);

export const CustomReportsUpdateReportSuccessAction = createAction(
    '[event-custom-reports] Update report success',
    props<IInstitutionManagerProgramEventParams & { customReportId: string, customReportUpdate: ICustomReportUpdateDto }>()
);

export const CustomReportsUpdateReportErrorAction = createAction(
    '[event-custom-reports] Update report error',
    props<{ error: HttpErrorResponse }>()
);

export const CustomReportsDeleteReportAction = createAction(
    '[event-custom-reports] Delete report',
    props<IInstitutionManagerProgramEventParams & { customReportId: string }>()
);

export const CustomReportsDeleteReportSuccessAction = createAction(
    '[event-custom-reports] Delete report success',
    props<IInstitutionManagerProgramEventParams & { customReportId: string }>()
);

export const CustomReportsDeleteReportErrorAction = createAction(
    '[event-custom-reports] Delete report error',
    props<{ error: HttpErrorResponse }>()
);

export const CustomReportsRestoreReportAction = createAction(
    '[event-custom-reports] Restore report',
    props<IInstitutionManagerProgramEventParams & { customReportId: string }>()
);

export const CustomReportsRestoreReportSuccessAction = createAction(
    '[event-custom-reports] Restore report success',
    props<IInstitutionManagerProgramEventParams & { customReportId: string }>()
);

export const CustomReportsRestoreReportErrorAction = createAction(
    '[event-custom-reports] Restore report error',
    props<{ error: HttpErrorResponse }>()
);

export const CustomReportsCopyReportAction = createAction(
    '[event-custom-reports] Copy report',
    props<IInstitutionManagerProgramEventParams & { customReportId: string }>()
);

export const CustomReportsCopyReportSuccessAction = createAction(
    '[event-custom-reports] Copy report success',
    props<IInstitutionManagerProgramEventParams & { customReportId: string }>()
);

export const CustomReportsCopyReportErrorAction = createAction(
    '[event-custom-reports] Copy report error',
    props<{ error: HttpErrorResponse }>()
);

export const CustomReportsShareReportAction = createAction(
    '[event-custom-reports] Share report',
    props<IInstitutionManagerProgramEventParams & { customReportId: string }>()
);

export const CustomReportsShareReportSuccessAction = createAction(
    '[event-custom-reports] Share report success',
    props<IInstitutionManagerProgramEventParams & { customReportId: string }>()
);

export const CustomReportsShareReportErrorAction = createAction(
    '[event-custom-reports] Share report error',
    props<{ error: HttpErrorResponse }>()
);

export const CustomReportsUnShareReportAction = createAction(
    '[event-custom-reports] Un-Share report',
    props<IInstitutionManagerProgramEventParams & { customReportId: string }>()
);

export const CustomReportsUnShareReportSuccessAction = createAction(
    '[event-custom-reports] Un-Share report success',
    props<IInstitutionManagerProgramEventParams & { customReportId: string }>()
);

export const CustomReportsUnShareReportErrorAction = createAction(
    '[event-custom-reports] Un-Share report error',
    props<{ error: HttpErrorResponse }>()
);

export const CustomReportsSendReportAction = createAction(
    '[event-custom-reports] Send report',
    props<IInstitutionManagerProgramEventParams & { customReportId: string, destinationManagerId: string }>()
);

export const CustomReportsSendReportSuccessAction = createAction(
    '[event-custom-reports] Send report success',
    props<IInstitutionManagerProgramEventParams & { customReportId: string, destinationManagerId: string }>()
);

export const CustomReportsSendReportErrorAction = createAction(
    '[event-custom-reports] Send report error',
    props<{ error: HttpErrorResponse }>()
);



export const CustomReportsLoadAvailableFilterValuesAction = createAction(
    '[event-custom-reports] Load available filter values',
    props<IInstitutionManagerProgramEventParams & { customReportId: string, reportColumnId: string, hierarchyNodeId: string, }>()
);
export const CustomReportsLoadAvailableFilterValuesSuccessAction = createAction(
    '[event-custom-reports] Load available filter values success',
    props<IInstitutionManagerProgramEventParams & { customReportId: string, reportColumnId: string, hierarchyNodeId: string, eventAvailableFilterValues: { [key: string]: string } }>()
);
export const CustomReportsLoadAvailableFilterValuesErrorAction = createAction(
    '[event-custom-reports] Load available filter values error',
    props<{ error: HttpErrorResponse }>()
);

export const CustomReportsAddOrderByAction = createAction(
    '[event-custom-reports] Add order by',
    props<IInstitutionManagerProgramEventParams & { customReportId: string, orderBy: ICustomReportOrderByDto }>()
);

export const CustomReportsUpdateOrderByAction = createAction(
    '[event-custom-reports] Update order by',
    props<IInstitutionManagerProgramEventParams & { customReportId: string, orderByIndex: number, orderBy: ICustomReportOrderByDto }>()
);

export const CustomReportsRemoveOrderByAction = createAction(
    '[event-custom-reports] Remove order by',
    props<IInstitutionManagerProgramEventParams & { customReportId: string, orderByIndex: number }>()
);

export const CustomReportsReorderOrderByAction = createAction(
    '[event-custom-reports] Reorder order by',
    props<IInstitutionManagerProgramEventParams & { customReportId: string, orderBys: ICustomReportOrderByDto[] }>()
);


export const CustomReportsAddGroupByAction = createAction(
    '[event-custom-reports] Add group by',
    props<IInstitutionManagerProgramEventParams & { customReportId: string, groupBy: ICustomReportGroupByDto }>()
);

export const CustomReportsUpdateGroupByAction = createAction(
    '[event-custom-reports] Update group by',
    props<IInstitutionManagerProgramEventParams & { customReportId: string, groupByIndex: number, groupBy: ICustomReportGroupByDto }>()
);

export const CustomReportsRemoveGroupByAction = createAction(
    '[event-custom-reports] Remove group by',
    props<IInstitutionManagerProgramEventParams & { customReportId: string, groupByIndex: number }>()
);

export const CustomReportsReorderGroupByAction = createAction(
    '[event-custom-reports] Reorder group by',
    props<IInstitutionManagerProgramEventParams & { customReportId: string, groupBys: ICustomReportGroupByDto[] }>()
);



export const CustomReportsLoadQueuedReportsAction = createAction(
    '[event-custom-reports] Load queued reports',
    props<IInstitutionManagerProgramEventParams>()
);
export const CustomReportsLoadQueuedReportsSuccessAction = createAction(
    '[event-custom-reports] Load queued reports success',
    props<IInstitutionManagerProgramEventParams & { eventQueuedReports: IQueuedReportDto[] }>()
);
export const CustomReportsLoadQueuedReportsErrorAction = createAction(
    '[event-custom-reports] Load queued reports error',
    props<{ error: HttpErrorResponse }>()
);

export const CustomReportsRequestApiAccessAction = createAction(
    '[event-custom-reports] Request events api access',
    props<IInstitutionManagerProgramEventParams & { customReportId: string, directAccessDto: IDirectDataAccessRequestDto }>()
);
export const CustomReportsRequestApiAccessSuccessAction = createAction(
    '[event-custom-reports] Request events api access success',
    props<IInstitutionManagerProgramEventParams & { customReportId: string, directAccessDto: IDirectDataAccessRequestDto, location: string }>()
);
export const CustomReportsRequestApiAccessErrorAction = createAction(
    '[event-custom-reports] Request events api access error',
    props<{ error: HttpErrorResponse }>()
);

// export const CustomReportsLoadQueuedReportAction = createAction(
//     '[event-custom-reports] Load queued report',
//     props<IInstitutionManagerProgramEventParams & { queueToken: string }>()
// );
// export const CustomReportsLoadQueuedReportSuccessAction = createAction(
//     '[event-custom-reports] Load queued report success',
//     props<IInstitutionManagerProgramEventParams & { queueToken: string, eventQueuedReport: IQueuedReportDto }>()
// );
// export const CustomReportsLoadQueuedReportErrorAction = createAction(
//     '[event-custom-reports] Load queued report error',
//     props<{ error: HttpErrorResponse }>()
// );

export const CustomReportInvalidateCacheAction = createAction(
    '[event-custom-reports] Invalidate Cache'
);
