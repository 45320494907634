import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { filter, map, Observable, of, switchMap, take, tap } from 'rxjs';

import { PublicActions } from '../containers/public';
import {
  getFamilyEventEligibility,
  getPublicEvent,
  PublicFeatureStore
} from '../containers/public/public-feature.reducers';
import {
  IEventDto,
  IFamilyEventDto,
  IInstitutionFamilyParams,
  IMemberCreateDto,
  IMemberEventDescriptorDto,
  IUserAccountEmailLookupDto
} from '../models';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PublicEventsService {

  constructor(
    private httpClient: HttpClient,
    private store: Store<PublicFeatureStore>,
    private dispatcher: ActionsSubject
  ) {
  }

  public getEventFromVanityDomain() {

    const host = window.location.host === 'localhost:4200' ? 'testing.v2-dev.4honline.com' : window.location.host;

    return this.httpClient.post(`${environment.apiUri}/api/events/vanity-domain-lookup`, { vanityDomain: host },
      { observe: 'response' }).pipe(
      switchMap((res: HttpResponse<any>) => {
        const location = res.headers.get('location');
        if (location == null) {
          return of(null);
        }
        return this.httpClient.get(location);
      })
    ) as Observable<IEventDto | null>;
  }

  public getPublicEvent(params: { institutionId: string, eventId: string }) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getPublicEvent(params)),
      tap(event => {
        if (event === undefined) {
          this.store.dispatch(PublicActions.PublicLoadEventAction(params));
        }
      }),
      filter(event => event != null)
    );
  }

  public getPublicEventEffect({ institutionId, eventId }: { institutionId: string, eventId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/events/${eventId}`) as Observable<IEventDto>;
  }

  public getEventEligibility(params: IInstitutionFamilyParams & { eventId: string }) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getFamilyEventEligibility(params)),
      tap(events => {
        if (events === undefined) {
          this.store.dispatch(PublicActions.PublicLoadEventEligibilityAction(params));
        }
      }),
      filter(events => events != null)
    );
  }

  public getEventEligibilityEffect({
    institutionId,
    familyId,
    eventId
  }: IInstitutionFamilyParams & { eventId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/families/${familyId}/events/${eventId}/members-available-registration-types`) as Observable<IFamilyEventDto>;
  }

  // public registrationLookup(params: {institutionId: string, eventId: string, dto: IUserAccountEmailLookupDto }) {

  // }
  public registrationLookupEffect({
    institutionId,
    eventId,
    dto
  }: { institutionId: string, eventId: string, dto: IUserAccountEmailLookupDto }) {
    return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/events/${eventId}/registration-lookup`, dto);
  }

  public createMember(params: { institutionId: string, familyId: string, eventId: string, member: IMemberCreateDto }): Observable<IMemberEventDescriptorDto> {
    this.store.dispatch(PublicActions.MemberCreateAction(params));
    return this.dispatcher.pipe(
      ofType(PublicActions.MemberCreateSuccessAction, PublicActions.MemberCreateErrorAction),
      take(1),
      map(action => {
        if (action.type === PublicActions.MemberCreateSuccessAction.type) {
          return action.member;
        } else {
          throw action.error;
        }
      })
    );
  }

  public createNewMemberEffect({
    institutionId,
    familyId,
    eventId,
    member
  }: { institutionId: string, familyId: string, eventId: string, member: IMemberCreateDto }): Observable<IMemberEventDescriptorDto> {
    return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/families/${familyId}/events/${eventId}/members`,
      member,
      { observe: 'response' })
    .pipe(
      switchMap((res: HttpResponse<any>) => this.httpClient.get(res.headers.get('location')))
    ) as Observable<IMemberEventDescriptorDto>;

  }

  public getEventDashboardEffect({ institutionId, familyId, eventId }: IInstitutionFamilyParams & { eventId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/families/${familyId}/events/${eventId}`);
  }

  public getEventsEffect({ institutionId, familyId }: IInstitutionFamilyParams): Observable<IEventDto> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/families/${familyId}/events`) as Observable<IEventDto>;
  }
}
