import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { AppState } from 'app/app.reducers';
import {
  DeliveryModesActions
} from 'app/core/containers/admin/system-manager/enrollment/enrollment-delivery-modes/store';
import {
  getSystemManagerDeliveryMode,
  getSystemManagerDeliveryModes,
  getSystemManagerSystemDeliveryModes
} from 'app/core/containers/admin/system-manager/system-manager-feature.reducers';
import {
  IDeliveryModeCreateDto,
  IDeliveryModeDto,
  IDeliveryModeUpdateDto,
  IInstitutionSystemManagerProgramParams
} from 'app/core/models';
import { filter, map, Observable, of, take, tap } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SystemManagerDeliveryModeService {

  constructor(private httpClient: HttpClient,
    private store: Store<AppState>,
    private dispatcher: ActionsSubject) {
  }

  public getSystemDeliveryModes(params: IInstitutionSystemManagerProgramParams) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }

    return this.store.pipe(
      select(getSystemManagerSystemDeliveryModes(params)),
      tap(systemDeliveryModes => {
        if (systemDeliveryModes === undefined) {
          this.store.dispatch(DeliveryModesActions.SystemDeliveryModeLoadAction(params));
        }
      }),
      filter(systemDeliveryModes => systemDeliveryModes != null)
    );
  }

  public getSystemDeliveryModesEffect({
    institutionId,
    systemManagerId,
    programId
  }: IInstitutionSystemManagerProgramParams) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/system-delivery-modes`) as Observable<IDeliveryModeDto[]>;
  }

  public getDeliveryModes(params: IInstitutionSystemManagerProgramParams) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }

    return this.store.pipe(
      select(getSystemManagerDeliveryModes(params)),
      tap(deliveryModes => {
        if (deliveryModes === undefined) {
          this.store.dispatch(DeliveryModesActions.DeliveryModeLoadAction(params));
        }
      }),
      filter(deliveryModes => deliveryModes != null)
    );
  }

  public getDeliveryModesEffect({ institutionId, systemManagerId, programId }: IInstitutionSystemManagerProgramParams) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/delivery-modes`) as Observable<IDeliveryModeDto[]>;
  }

  public getDeliveryMode(params: IInstitutionSystemManagerProgramParams & { deliveryModeId: string }): Observable<IDeliveryModeDto> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }

    return this.store.pipe(
      select(getSystemManagerDeliveryMode(params)),
      tap(deliveryMode => {
        if (deliveryMode === undefined) {
          this.store.dispatch(DeliveryModesActions.DeliveryModeLoadByIdAction(params));
        }
      }),
      filter(deliveryMode => deliveryMode != null)
    );
  }

  public getDeliveryModeEffect({
    institutionId,
    systemManagerId,
    programId,
    deliveryModeId
  }: IInstitutionSystemManagerProgramParams & { deliveryModeId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/delivery-modes/${deliveryModeId}`) as Observable<IDeliveryModeDto>;
  }

  public addDeliveryMode(params: IInstitutionSystemManagerProgramParams & { deliveryMode: IDeliveryModeCreateDto }) {
    this.store.dispatch(DeliveryModesActions.DeliveryModeAddAction(params));

    return this.dispatcher.pipe(
      ofType(DeliveryModesActions.DeliveryModeAddSuccessAction, DeliveryModesActions.DeliveryModeAddErrorAction),
      take(1),
      map(action => {
        if (action.type === DeliveryModesActions.DeliveryModeAddSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public addDeliveryModeEffect({
    institutionId,
    systemManagerId,
    programId,
    deliveryMode
  }: IInstitutionSystemManagerProgramParams & { deliveryMode: IDeliveryModeCreateDto }) {
    return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/delivery-modes`, deliveryMode);
  }

  public updateDeliveryMode(params: IInstitutionSystemManagerProgramParams & { deliveryModeId: string, deliveryMode: IDeliveryModeUpdateDto }) {
    this.store.dispatch(DeliveryModesActions.DeliveryModeUpdateAction(params));

    return this.dispatcher.pipe(
      ofType(DeliveryModesActions.DeliveryModeUpdateSuccessAction, DeliveryModesActions.DeliveryModeUpdateErrorAction),
      take(1),
      map(action => {
        if (action.type === DeliveryModesActions.DeliveryModeUpdateSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateDeliveryModeEffect({
    institutionId,
    systemManagerId,
    programId,
    deliveryModeId,
    deliveryMode
  }: IInstitutionSystemManagerProgramParams & { deliveryModeId: string, deliveryMode: IDeliveryModeUpdateDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/delivery-modes/${deliveryModeId}`, deliveryMode);
  }

  public deleteDeliveryMode(params: IInstitutionSystemManagerProgramParams & { deliveryModeId: string }) {
    this.store.dispatch(DeliveryModesActions.DeliveryModeDeleteAction(params));

    return this.dispatcher.pipe(
      ofType(DeliveryModesActions.DeliveryModeDeleteSuccessAction, DeliveryModesActions.DeliveryModeDeleteErrorAction),
      take(1),
      map(action => {
        if (action.type === DeliveryModesActions.DeliveryModeDeleteSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteDeliveryModeEffect({
    institutionId,
    systemManagerId,
    programId,
    deliveryModeId
  }: IInstitutionSystemManagerProgramParams & { deliveryModeId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/delivery-modes/${deliveryModeId}`);
  }
}
