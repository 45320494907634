import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  IBroadcastEmailCreateDto,
  IBroadcastEmailRecipientDto,
  IBroadcastEmailUpdateDto,
  IInstitutionManagerProgramParams,
  ISearchResult,
} from 'app/core/models';
import { IBroadcastEmailDto } from 'app/core/models/serverDTOs/IBroadcastEmailDto';

export const ManagerNavigateBroadcastAction = createAction(
  '[manager broadcast] navigate to broadcast',
  props<{ page: string, id: string, name: string }>()
);


export const ManagerGetBroadcastAction = createAction(
  '[manager broadcast] get broadcast emails',
  props<{ institutionId: string, managerId: string, programId: string }>()
);

export const ManagerGetBroadcastSuccessAction = createAction(
  '[manager broadcast] get broadcast emails success',
  props<{ institutionId: string, managerId: string, programId: string, emails: IBroadcastEmailDto[] }>()
);

export const ManagerGetBroadcastErrorAction = createAction(
  '[manager broadcast] get broadcast emails error',
  props<{ error: HttpErrorResponse }>()
);

export const ManagerGetBroadcastByIdAction = createAction(
  '[manager broadcast] get broadcast email by id',
  props<{ institutionId: string, managerId: string, programId: string, broadcastEmailId: string }>()
);

export const ManagerGetBroadcastByIdSuccessAction = createAction(
  '[manager broadcast] get broadcast email by id success',
  props<{ institutionId: string, managerId: string, programId: string, broadcastEmailId: string, broadcastEmail: IBroadcastEmailDto }>()
);

export const ManagerGetBroadcastByIdErrorAction = createAction(
  '[manager broadcast] get broadcast email by id error',
  props<{ error: HttpErrorResponse }>()
);

export const ManagerUpdateBroadcastEmailAction = createAction(
  '[manager broadcast] update broadcast email',
  props<({ institutionId: string, managerId: string, programId: string, broadcastEmailId: string, broadcastEmail: IBroadcastEmailUpdateDto })>()
);

export const ManagerUpdateBroadcastEmailSuccessAction = createAction(
  '[manager broadcast] update broadcast email success',
  props<({ institutionId: string, managerId: string, programId: string, broadcastEmailId: string, broadcastEmail: IBroadcastEmailUpdateDto })>()
);

export const ManagerUpdateBroadcastEmailErrorAction = createAction(
  '[manager broadcast] update broadcast email error',
  props<({ error: HttpErrorResponse })>()
);

export const ManagerCreateBroadcastEmailClubAction = createAction(
  '[manager broadcast] create broadcast email club',
  props<({ institutionId: string, managerId: string, programId: string, unitId: string, broadcastEmail: IBroadcastEmailCreateDto })>()
);

export const ManagerCreateBroadcastEmailClubSuccessAction = createAction(
  '[manager broadcast] create broadcast email club success',
  props<({ institutionId: string, managerId: string, programId: string, broadcastEmail: IBroadcastEmailCreateDto, newEmail: IBroadcastEmailDto })>()
);

export const ManagerCreateBroadcastEmailClubErrorAction = createAction(
  '[manager broadcast] create broadcast email club error',
  props<({ error: HttpErrorResponse })>()
);

export const ManagerSendBroadcastEmailAction = createAction(
  '[manager broadcast] send broadcast email',
  props<IInstitutionManagerProgramParams & { broadcastEmailId: string, hierarchyNodeId: string }>()
);

export const ManagerSendBroadcastEmailSuccessAction = createAction(
  '[manager broadcast] send broadcast email success',
  props<IInstitutionManagerProgramParams & { broadcastEmailId: string, hierarchyNodeId: string }>()
);

export const ManagerSendBroadcastEmailErrorAction = createAction(
  '[manager broadcast] send broadcast email error',
  props<{ error: HttpErrorResponse }>()
);

export const ManagerCreateBroadcastEmailCountyAreaAction = createAction(
  '[manager broadcast] create broadcast email county area',
  props<IInstitutionManagerProgramParams & { countyAreaId: string, email: IBroadcastEmailCreateDto }>()
);

export const ManagerCreateBroadcastEmailCountyAreaSuccessAction = createAction(
  '[manager broadcast] create broadcast email county area success',
  props<IInstitutionManagerProgramParams & { countyAreaId: string, email: IBroadcastEmailCreateDto, newEmail: IBroadcastEmailDto }>()
);

export const ManagerCreateBroadcastEmailCountyAreaErrorAction = createAction(
  '[manager broadcast] create broadcast email county area error',
  props<{ error: HttpErrorResponse }>()
);

export const ManagerCreateBroadcastEmailEventAction = createAction(
  '[manager broadcast] create broadcast email event',
  props<IInstitutionManagerProgramParams & { eventId: string, email: IBroadcastEmailCreateDto }>()
);

export const ManagerCreateBroadcastEmailEventSuccessAction = createAction(
  '[manager broadcast] create broadcast email event success',
  props<IInstitutionManagerProgramParams & { eventId: string, email: IBroadcastEmailCreateDto, newEmail: IBroadcastEmailDto }>()
);

export const ManagerCreateBroadcastEmailEventErrorAction = createAction(
  '[manager broadcast] create broadcast email event error',
  props<{ error: HttpErrorResponse }>()
);

export const ManagerCreateBroadcastEmailEventRegistrationAction = createAction(
  '[manager broadcast] create broadcast email event registration',
  props<IInstitutionManagerProgramParams & { eventRegistrationTypeId: string, email: IBroadcastEmailCreateDto }>()
);

export const ManagerCreateBroadcastEmailEventRegistrationSuccessAction = createAction(
  '[manager broadcast] create broadcast email event registration success',
  props<IInstitutionManagerProgramParams & { eventRegistrationTypeId: string, email: IBroadcastEmailCreateDto, newEmail: IBroadcastEmailDto }>()
);

export const ManagerCreateBroadcastEmailEventRegistrationErrorAction = createAction(
  '[manager broadcast] create broadcast email event registration error',
  props<{ error: HttpErrorResponse }>()
);

export const ManagerCreateBroadcastEmailSearchAction = createAction(
  '[manager broadcast] create broadcast email search',
  props<IInstitutionManagerProgramParams & { searchId: string, email: IBroadcastEmailCreateDto }>()
);

export const ManagerCreateBroadcastEmailSearchSuccessAction = createAction(
  '[manager broadcast] create broadcast email search success',
  props<IInstitutionManagerProgramParams & { searchId: string, email: IBroadcastEmailCreateDto, newEmail: IBroadcastEmailDto }>()
);

export const ManagerCreateBroadcastEmailSearchErrorAction = createAction(
  '[manager broadcast] create broadcast email search error',
  props<{ error: HttpErrorResponse }>()
);

export const ManagerCreateBroadcastEmailCustomReportsAction = createAction(
  '[manager broadcast] create broadcast email custom reports',
  props<IInstitutionManagerProgramParams & { hierarchyNodeId: string, programYearId: string, customReportId: string, email: IBroadcastEmailCreateDto }>()
);

export const ManagerCreateBroadcastEmailCustomReportsSuccessAction = createAction(
  '[manager broadcast] create broadcast email custom reports success',
  props<IInstitutionManagerProgramParams & { hierarchyNodeId: string, programYearId: string, customReportId: string, email: IBroadcastEmailCreateDto, newEmail: IBroadcastEmailDto }>()
);

export const ManagerCreateBroadcastEmailCustomReportsErrorAction = createAction(
  '[manager broadcast] create broadcast email custom reports error',
  props<{ error: HttpErrorResponse }>()
);


export const GetQueuedBroadcastRecipientsAction = createAction(
  '[manager broadcast] Get queued broadcast recipients',
  props<IInstitutionManagerProgramParams & { broadcastEmailId: string, pagesize: string, pageindex: string }>()
);
export const GetQueuedBroadcastRecipientsSuccessAction = createAction(
  '[manager broadcast] Get queued broadcast recipients success',
  props<IInstitutionManagerProgramParams & { broadcastEmailId: string, pagesize: string, pageindex: string, recipients: ISearchResult<IBroadcastEmailRecipientDto> }>()
);
export const GetQueuedBroadcastRecipientsErrorAction = createAction(
  '[manager broadcast] Get queued broadcast recipients error',
  props<{ error: HttpErrorResponse }>()
);


export const GetSentBroadcastRecipientsAction = createAction(
  '[manager broadcast] Get sent broadcast recipients',
  props<IInstitutionManagerProgramParams & { broadcastEmailId: string, pagesize: string, pageindex: string }>()
);
export const GetSentBroadcastRecipientsSuccessAction = createAction(
  '[manager broadcast] Get sent broadcast recipients success',
  props<IInstitutionManagerProgramParams & { broadcastEmailId: string, pagesize: string, pageindex: string, recipients: ISearchResult<IBroadcastEmailRecipientDto> }>()
);
export const GetSentBroadcastRecipientsErrorAction = createAction(
  '[manager broadcast] Get sent broadcast recipients error',
  props<{ error: HttpErrorResponse }>()
);


export const GetFailedBroadcastRecipientsAction = createAction(
  '[manager broadcast] Get failed broadcast recipients',
  props<IInstitutionManagerProgramParams & { broadcastEmailId: string, pagesize: string, pageindex: string }>()
);
export const GetFailedBroadcastRecipientsSuccessAction = createAction(
  '[manager broadcast] Get failed broadcast recipients success',
  props<IInstitutionManagerProgramParams & { broadcastEmailId: string, pagesize: string, pageindex: string, recipients: ISearchResult<IBroadcastEmailRecipientDto> }>()
);
export const GetFailedBroadcastRecipientsErrorAction = createAction(
  '[manager broadcast] Get failed broadcast recipients error',
  props<{ error: HttpErrorResponse }>()
);




export const GetDeliveredBroadcastRecipientsAction = createAction(
  '[manager broadcast] Get delivered broadcast recipients',
  props<IInstitutionManagerProgramParams & { broadcastEmailId: string, pagesize: string, pageindex: string }>()
);
export const GetDeliveredBroadcastRecipientsSuccessAction = createAction(
  '[manager broadcast] Get delivered broadcast recipients success',
  props<IInstitutionManagerProgramParams & { broadcastEmailId: string, pagesize: string, pageindex: string, recipients: IBroadcastEmailRecipientDto[] }>()
);
export const GetDeliveredBroadcastRecipientsErrorAction = createAction(
  '[manager broadcast] Get delivered broadcast recipients error',
  props<{ error: HttpErrorResponse }>()
);



// export const GetEmailAttachmentAction = createAction(
//   '[manager broadcast] Get email attachment',
//   props<IInstitutionManagerProgramParams & { broadcastEmailId: string, fileId: string }>()
// );
// export const GetEmailAttachmentSuccessAction = createAction(
//   '[manager broadcast] Get email attachment success',
//   props<IInstitutionManagerProgramParams & { broadcastEmailId: string, fileId: string, attachment: { fileName: string, file: File } }>()
// );
// export const GetEmailAttachmentErrorAction = createAction(
//   '[manager broadcast] Get email attachment error',
//   props<{ error: HttpErrorResponse }>()
// );

export const UploadEmailAttachmentAction = createAction(
  '[manager broadcast] Upload email attachment',
  props<IInstitutionManagerProgramParams & { broadcastEmailId: string, attachment: FormData }>()
);
export const UploadEmailAttachmentSuccessAction = createAction(
  '[manager broadcast] Upload email attachment success',
  props<IInstitutionManagerProgramParams & { broadcastEmailId: string, attachment: FormData }>()
);
export const UploadEmailAttachmentErrorAction = createAction(
  '[manager broadcast] Upload email attachment error',
  props<{ error: HttpErrorResponse }>()
);

export const DeleteEmailAttachmentAction = createAction(
  '[manager broadcast] Delete email attachment',
  props<IInstitutionManagerProgramParams & { broadcastEmailId: string, fileId: string }>()
);
export const DeleteEmailAttachmentSuccessAction = createAction(
  '[manager broadcast] Delete email attachment success',
  props<IInstitutionManagerProgramParams & { broadcastEmailId: string, fileId: string }>()
);
export const DeleteEmailAttachmentErrorAction = createAction(
  '[manager broadcast] Delete email attachment error',
  props<{ error: HttpErrorResponse }>()
);

export const DeleteEmailAction = createAction(
  '[manager broadcast] Delete email',
  props<IInstitutionManagerProgramParams & { broadcastEmailId: string }>()
);
export const DeleteEmailSuccessAction = createAction(
  '[manager broadcast] Delete email success',
  props<IInstitutionManagerProgramParams & { broadcastEmailId: string }>()
);
export const DeleteEmailErrorAction = createAction(
  '[manager broadcast] Delete email error',
  props<{ error: HttpErrorResponse }>()
);

export const BroadcastToManagersAction = createAction(
  '[manager broadcast] Broadcast to managers',
  props<IInstitutionManagerProgramParams & { hierarchyNodeId: string, broadcastEmail: IBroadcastEmailCreateDto }>()
);
export const BroadcastToManagersSuccessAction = createAction(
  '[manager broadcast] Broadcast to managers success',
  props<IInstitutionManagerProgramParams & { hierarchyNodeId: string, broadcastEmail: IBroadcastEmailCreateDto, newEmail: IBroadcastEmailDto }>()
);
export const BroadcastToManagersErrorAction = createAction(
  '[manager broadcast] Broadcast to managers error',
  props<{ error: HttpErrorResponse }>()
);