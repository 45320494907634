import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ITrainingLessonDto } from 'app/core/models';

@Component({
  selector: 'ng4h-modal-reorder-lessons',
  templateUrl: './modal-reorder-lessons.component.html',
  styleUrls: ['./modal-reorder-lessons.component.scss'],
})

export class ModalReorderLessonsComponent implements OnInit {
  public lessons: ITrainingLessonDto[];

  constructor(private dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder) { }

  public ngOnInit(): void {
    this.lessons = this.data.lessons.slice();
  }

  public drop(event: CdkDragDrop<ITrainingLessonDto[]>) {
    moveItemInArray(this.lessons, event.previousIndex, event.currentIndex);
  }

  public save(): void {
    this.dialogRef.close(this.lessons);
  }
  public close(): void {
    this.dialogRef.close();
  }
}
