import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IOfflineDto } from '../models';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SystemService {

  constructor(private httpClient: HttpClient) {

  }

  public getApiVersion() {
    return this.httpClient.get(`${environment.apiUri}/api/system/version`, { responseType: 'text' });
  }

  public getServer() {
    return this.httpClient.get(`${environment.apiUri}/api/system/current-server`, { responseType: 'text' });
  }

  public getWebsiteStatus(): Observable<IOfflineDto> {
    return this.httpClient.get(`${environment.apiUri}/api/system/site-offline`) as Observable<IOfflineDto>;
  }

  public getInstitutionStatus({ institutionId }: { institutionId: string }): Observable<IOfflineDto> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/offline`) as Observable<IOfflineDto>;
  }
}
