import { Pipe, PipeTransform } from '@angular/core';

import { eEnrollmentStatus, eMemberParticipationStatus, IMemberProgramYearApprovalStateDto } from '../models';

@Pipe({
    name: 'approvalState',
    pure: true
})

export class TableApprovalStatePipe implements PipeTransform {
    transform(state: IMemberProgramYearApprovalStateDto): string {
        if (state == null) {
            return '';
        }
        if (state.participationStatus === eMemberParticipationStatus.Incomplete) {
            return `<div class='approval-state'>
                        <i class='fal fa-user fa-lg fa-fw incomplete-enrollment' title='Incomplete enrollment'></i>
                    </div>`;
        }
        if (state.canApprove && state.participationStatus === eMemberParticipationStatus.AwaitingReview) {
            return `<div class='approval-state'>
                        <i class='fal fa-alarm-exclamation fa-lg fa-fw awaiting-approval' title='Awaiting review'></i>
                    </div>`;
        }
        if (state.canApprove && state.participationStatus === eMemberParticipationStatus.Approved) {
            return '';
        }
        let errorState = `<div class='approval-state'>`;
        if (state.enrollmentStatus === eEnrollmentStatus.Approved && state.invoicesPaid === false) {
            errorState += `<i class='fal fa-usd-circle fa-lg fa-fw outstanding-invoice' title='Payment required'></i>`;
        }
        if (!state.screeningApproved) {
            errorState += `<i class='fal fa-hands-heart fa-lg fa-fw screening-not-approved' title='Screening not approved'></i>`;
        }
        if (!state.trainingsCompleted) {
            errorState += `<i class='fal fa-user-graduate fa-lg fa-fw trainings-not-complete' title='Trainings not completed'></i>`;
        }
        return errorState + '</div>';
    }
}
