import { eFormControlType } from '.';
import { DynamicItem } from './DynamicItem';
import { IDynamicControl } from './IDynamicControl';
import { eHierarchyNodeType } from './serverDTOs/Hierarchy/eHierarchyNodeType';



export class DynamicControl implements IDynamicControl {
  controlId: string;
  type: eFormControlType;
  label: string;
  displayTo: number[];
  attributes: Partial<{
    required: boolean,
    class: string,
    min: number,
    max: number,
    multipleChoiceMinAllowedSelection: number,
    multipleChoiceMaxAllowedSelection: number
  }>;
  validators: any[];
  items: DynamicItem[];
  managerField: boolean;
  managerField_MinimumViewHierarchyType?: eHierarchyNodeType;
  managerField_MinimumEditHierarchyType?: eHierarchyNodeType;

  // controlId needed for constructing formGroup
  constructor(private _controlId: string, private _type: eFormControlType) {
    this.controlId = _controlId;
    this.type = _type;
    this.displayTo = [];
    this.attributes = {
      class: 'col-12'
    };
    this.validators = [];
    this.items = [];
    this.managerField = false;
  }

  archived: boolean;
}
