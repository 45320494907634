import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
    IBankAccountCreateDto,
    IBankAccountDto,
    IBankAccountUpdateDto,
    IInstitutionManagerProgramHierarchyParams,
    IInstitutionManagerProgramParams,
    IInstitutionSystemManagerProgramParams
} from 'app/core/models';

export const BankAccountsManagerLoadAction = createAction(
    '[bank-accounts] manager load accounts',
    props<IInstitutionManagerProgramHierarchyParams>()
);

export const BankAccountsManagerLoadSuccessAction = createAction(
    '[bank-accounts] manager load accounts success',
    props<IInstitutionManagerProgramHierarchyParams & { bankAccounts: IBankAccountDto[] }>()
);
export const BankAccountsManagerLoadErrorAction = createAction(
    '[bank-accounts] manager load accounts error',
    props<{ error: HttpErrorResponse }>()
);

export const BankAccountManagerLoadAction = createAction(
    '[bank-account] manager load account',
    props<IInstitutionManagerProgramParams & { bankAccountId: string }>()
);

export const BankAccountManagerLoadSuccessAction = createAction(
    '[bank-account] manager load account success',
    props<IInstitutionManagerProgramParams & { bankAccountId: string, bankAccount: IBankAccountDto }>()
);
export const BankAccountManagerLoadErrorAction = createAction(
    '[bank-account] manager load account error',
    props<{ error: HttpErrorResponse }>()
);

export const BankAccountsManagerAddAccountAction = createAction(
    '[bank-accounts] manager add accounts',
    props<IInstitutionManagerProgramHierarchyParams & { bankAccountCreate: IBankAccountCreateDto }>()
);

export const BankAccountsManagerAddAccountSuccessAction = createAction(
    '[bank-accounts] manager add accounts success',
    props<IInstitutionManagerProgramHierarchyParams & { bankAccountCreate: IBankAccountCreateDto }>()
);
export const BankAccountsManagerAddAccountErrorAction = createAction(
    '[bank-accounts] manager add accounts error',
    props<{ error: HttpErrorResponse }>()
);

export const BankAccountsManagerDeleteAccountAction = createAction(
    '[bank-accounts] manager Delete accounts',
    props<IInstitutionManagerProgramHierarchyParams & { bankAccountId: string }>()
);

export const BankAccountsManagerDeleteAccountSuccessAction = createAction(
    '[bank-accounts] manager Delete accounts success',
    props<IInstitutionManagerProgramHierarchyParams & { bankAccountId: string }>()
);
export const BankAccountsManagerDeleteAccountErrorAction = createAction(
    '[bank-accounts] manager Delete accounts error',
    props<{ error: HttpErrorResponse }>()
);

export const BankAccountsManagerUpdateAccountAction = createAction(
    '[bank-accounts] manager update accounts',
    props<IInstitutionManagerProgramParams & { bankAccountId: string, bankAccountUpdate: IBankAccountUpdateDto }>()
);

export const BankAccountsManagerUpdateAccountSuccessAction = createAction(
    '[bank-accounts] manager update accounts success',
    props<IInstitutionManagerProgramParams & { bankAccountId: string, bankAccountUpdate: IBankAccountUpdateDto }>()
);
export const BankAccountsManagerUpdateAccountErrorAction = createAction(
    '[bank-accounts] manager update accounts error',
    props<{ error: HttpErrorResponse }>()
);

export const BankAccountsSystemManagerLoadAction = createAction(
    '[bank-accounts] system-manager load',
    props<IInstitutionSystemManagerProgramParams>()
);

export const BankAccountsSystemManagerLoadSuccessAction = createAction(
    '[bank-accounts] system-manager load success',
    props<IInstitutionSystemManagerProgramParams & { bankAccounts: IBankAccountDto[] }>()
);
export const BankAccountsSystemManagerLoadErrorAction = createAction(
    '[bank-accounts] system-manager load error',
    props<{ error: HttpErrorResponse }>()
);

// export const BankAccountsSystemManagerLoadAccountAction = createAction(
//     '[bank-accounts] system-manager load account',
//     props<IInstitutionSystemManagerProgramParams & { bankAccountId: string }>()
// );

// export const BankAccountsSystemManagerLoadAccountSuccessAction = createAction(
//     '[bank-accounts] system-manager load account success',
//     props<IInstitutionSystemManagerProgramParams & { bankAccountId: string, bankAccount: IBankAccountDto }>()
// );
// export const BankAccountsSystemManagerLoadAccountErrorAction = createAction(
//     '[bank-accounts] system-manager load account error',
//     props<{ error: HttpErrorResponse }>()
// );

export const BankAccountsSystemManagerAddAccountAction = createAction(
    '[bank-accounts] system-manager add account',
    props<IInstitutionSystemManagerProgramParams & { bankAccountCreate: IBankAccountCreateDto }>()
);

export const BankAccountsSystemManagerAddAccountSuccessAction = createAction(
    '[bank-accounts] system-manager add account success',
    props<IInstitutionSystemManagerProgramParams & { bankAccountCreate: IBankAccountCreateDto }>()
);
export const BankAccountsSystemManagerAddAccountErrorAction = createAction(
    '[bank-accounts] system-manager add account error',
    props<{ error: HttpErrorResponse }>()
);

export const BankAccountsSystemManagerDeleteAccountAction = createAction(
    '[bank-accounts] system-manager Delete account',
    props<IInstitutionSystemManagerProgramParams & { bankAccountId: string }>()
);

export const BankAccountsSystemManagerDeleteAccountSuccessAction = createAction(
    '[bank-accounts] system-manager Delete account success',
    props<IInstitutionSystemManagerProgramParams & { bankAccountId: string }>()
);
export const BankAccountsSystemManagerDeleteAccountErrorAction = createAction(
    '[bank-accounts] system-manager Delete account error',
    props<{ error: HttpErrorResponse }>()
);

export const BankAccountsSystemManagerUpdateAccountAction = createAction(
    '[bank-accounts] system-manager update account',
    props<IInstitutionSystemManagerProgramParams & { bankAccountId: string, bankAccount: IBankAccountUpdateDto }>()
);

export const BankAccountsSystemManagerUpdateAccountSuccessAction = createAction(
    '[bank-accounts] system-manager update account success',
    props<IInstitutionSystemManagerProgramParams & { bankAccountId: string, bankAccount: IBankAccountUpdateDto }>()
);
export const BankAccountsSystemManagerUpdateAccountErrorAction = createAction(
    '[bank-accounts] system-manager update account error',
    props<{ error: HttpErrorResponse }>()
);
