import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'ng4h-modal-create-batch',
  templateUrl: './modal-create-batch.component.html',
  styleUrls: ['./modal-create-batch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalCreateBatchComponent implements OnInit {

  public batch;

  public batches = [
    { name: 'test 1' },
    { name: 'test 2' },
    { name: 'test 3' },
    { name: 'test 4' },
  ];

  constructor(private dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

  public close(): void {
    this.dialogRef.close();
  }

  public update(): void {
    this.dialogRef.close( this.batch );
  }
}
