import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'age',
    pure: true
})
// https://stackoverflow.com/questions/4060004/calculate-age-given-the-birth-date-in-the-format-yyyymmdd
// not perfect but I think good enough to remove momentjs dependency
export class AgePipe implements PipeTransform {
    public transform(input: string): number {
        const ageDifMs = Date.now() - new Date(input).getTime();
        const ageDate = new Date(ageDifMs); // miliseconds from epoch
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }
}
