import { IHierarchyNodeDto } from '.';
import { eUnitType, IMemberVolunteerDto } from '..';

export interface IEnrollmentGroupUnitDto {
    groupId: string;
    groupName?: string;
    unitType: eUnitType;
    countyArea: IHierarchyNodeDto;
    volunteerType: IMemberVolunteerDto;
}
