import { Action, createReducer, on } from '@ngrx/store';
import {
  IBroadcastEmailDto,
  IBroadcastEmailRecipientDto,
  IInstitutionManagerProgramModel,
  ISearchResult,
} from 'app/core/models';
import { mergeImmutable } from 'app/shared/utils';

import { ManagerBroadcastActions } from '.';



export interface ManagerBroadcastState {
  navigate: { id: string, page: string, name: string };
  broadcastEmails: IInstitutionManagerProgramModel<IBroadcastEmailDto[]>;
  broadcastEmail: IInstitutionManagerProgramModel<{ [broadcastEmailId: string]: IBroadcastEmailDto }>;

  queuedRecipients: IInstitutionManagerProgramModel<{ [broadcastEmailId: string]: ISearchResult<IBroadcastEmailRecipientDto> }>;
}

const initialState: ManagerBroadcastState = {
  navigate: undefined,
  broadcastEmails: undefined,
  broadcastEmail: undefined,
  queuedRecipients: undefined

};

const reducer = createReducer(
  initialState,
  on(ManagerBroadcastActions.ManagerNavigateBroadcastAction, (state, { page, id, name }) => {
    return {
      ...state,
      navigate: { page: page, id: id, name: name }
    };
  }),
  on(ManagerBroadcastActions.ManagerGetBroadcastSuccessAction, (state, { institutionId, managerId, programId, emails }) => {
    return {
      ...state,
      broadcastEmails: mergeImmutable(
        { [institutionId]: { [managerId]: { [programId]: emails } } },
        state.broadcastEmails
      )
    };
  }),
  on(ManagerBroadcastActions.ManagerGetBroadcastByIdSuccessAction, (state, { institutionId, managerId, programId, broadcastEmailId, broadcastEmail }) => {
    return {
      ...state,
      broadcastEmail: mergeImmutable(
        { [institutionId]: { [managerId]: { [programId]: { [broadcastEmailId]: broadcastEmail } } } },
        state.broadcastEmail
      )
    };
  }),
  on(ManagerBroadcastActions.ManagerUpdateBroadcastEmailSuccessAction, (state, { institutionId, managerId, programId, broadcastEmailId }) => {
    return {
      ...state,
      broadcastEmail: mergeImmutable(
        { [institutionId]: { [managerId]: { [programId]: { [broadcastEmailId]: undefined } } } },
        state.broadcastEmail
      )
    };
  }),
  on(ManagerBroadcastActions.ManagerSendBroadcastEmailSuccessAction, ManagerBroadcastActions.ManagerCreateBroadcastEmailSearchSuccessAction, (state, payload) => {
    return {
      ...state,
      broadcastEmail: undefined,
      broadcastEmails: undefined
    };
  }),
  on(ManagerBroadcastActions.GetQueuedBroadcastRecipientsSuccessAction, (state, { institutionId, managerId, programId, broadcastEmailId, pagesize, pageindex, recipients }) => {
    return {
      ...state,
      queuedRecipients: mergeImmutable(
        { [institutionId]: { [managerId]: { [programId]: { [broadcastEmailId]: recipients } } } },
        state.queuedRecipients
      )
    };
  }),
  on(ManagerBroadcastActions.UploadEmailAttachmentSuccessAction,
    ManagerBroadcastActions.DeleteEmailAttachmentSuccessAction,
    (state, { institutionId, managerId, programId, broadcastEmailId }) => {
      return {
        ...state,
        broadcastEmail: mergeImmutable(
          { [institutionId]: { [managerId]: { [programId]: { [broadcastEmailId]: undefined } } } },
          state.broadcastEmail
        )
      };
    }),
  on(ManagerBroadcastActions.DeleteEmailSuccessAction, (state, { }) => {
    return {
      ...state,
      broadcastEmail: undefined,
      broadcastEmails: undefined
    }
  })

  // on(ManagerBroadcastActions.GetSentBroadcastRecipientsSuccessAction, (state, {institutionId, managerId, programId, broadcastEmailId, pagesize, pageindex, recipients}) => {
  //   return {
  //     ...state,
  //     queuedRecipients: mergeImmutable(
  //       { [institutionId]: { [managerId]: { [programId]: { [broadcastEmailId]: recipients } } } },
  //     state.queuedRecipients
  //     )
  //   };
  // }),
  // on(ManagerBroadcastActions.GetFailedBroadcastRecipientsSuccessAction, (state, {institutionId, managerId, programId, broadcastEmailId, pagesize, pageindex, recipients}) => {
  //   return {
  //     ...state,
  //     queuedRecipients: mergeImmutable(
  //       { [institutionId]: { [managerId]: { [programId]: { [broadcastEmailId]: recipients } } } },
  //     state.queuedRecipients
  //     )
  //   };
  // }),
  // on(ManagerBroadcastActions.GetDeliveredBroadcastRecipientsSuccessAction, (state, {institutionId, managerId, programId, broadcastEmailId, pagesize, pageindex, recipients}) => {
  //   return {
  //     ...state,
  //     queuedRecipients: mergeImmutable(
  //       { [institutionId]: { [managerId]: { [programId]: { [broadcastEmailId]: recipients } } } },
  //     state.queuedRecipients
  //     )
  //   };
  // }),
);

export function broadcastReducer(state: ManagerBroadcastState | undefined, action: Action) {
  return reducer(state, action);
}

export const getNavigation = (state: ManagerBroadcastState) => state.navigate;
export const broadCastEmails = (state: ManagerBroadcastState) => state.broadcastEmails;
export const broadcastEmail = (state: ManagerBroadcastState) => state.broadcastEmail;
