import { Action, createReducer, on } from '@ngrx/store';
import { ILoginCredentials, IUserAccountDto } from 'app/core/models';

import { UserActions } from '.';

export interface UserState {
  userAccount: IUserAccountDto;
  loginCredentials: ILoginCredentials;
  twoFactorTempCredentials: ILoginCredentials;
}

const initialState: UserState = {
  userAccount: undefined,
  loginCredentials: undefined,
  twoFactorTempCredentials: undefined
};

const reducer = createReducer(
  initialState,
  on(UserActions.AuthenticationDoTwoFactorAction, (state, { credentials }) => {
    return {
      ...state,
      twoFactorTempCredentials: credentials
    };
  }),
  on(UserActions.AuthenticationDoTwoFactorSuccessAction, (state, { }) => {
    return {
      ...state,
      twoFactorTempCredentials: undefined
    };
  }),
  on(UserActions.GetUserSuccessAction, (state, { user }) => {
    return {
      ...state,
      userAccount: user
    };
  }),
  on(UserActions.AuthenticationSuccessAction, (state, { credentials }) => {
    if (credentials == null) {
      return state;
    }
    return {
      ...state,
      loginCredentials: credentials
    };
  }),
  on(UserActions.SignUpSuccessAction, (state, { user }) => {
    if (user == null) {
      return state;
    }
    return {
      ...state,
      userAccount: user.userAccount
    };
  }),
  on(UserActions.SignOutErrorAction, (state, { }) => {
    return {
      ...state,
      userAccount: undefined
    };
  }),
  on(UserActions.SignOutSuccessAction, (state, { }) => {
    return initialState;
  }),
);

export function userReducer(state: UserState | undefined, actions: Action) {
  return reducer(state, actions);
}

export const userAccount = (state: UserState) => state.userAccount;
export const loginCredentials = (state: UserState) => state.loginCredentials;
export const twoFactorTempCredentials = (state: UserState) => state.twoFactorTempCredentials;
