import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  IInstitutionDescriptorDto,
  IInstitutionProgramCreateDto,
  INonDiscriminationDisclosureDto,
  IOfflineDto,
  IOfflineScheduleDto,
  ISuperuserInstitutionParams,
  ISuperuserInstitutionProgramParams,
  ISuperuserParams
} from '../models';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SuperuserService {

  constructor(private httpClient: HttpClient) {
  }

  public purgeInstitutionCache({ superuserId, institutionId }: ISuperuserInstitutionParams) {
    return this.httpClient.patch(`${environment.apiUri}/api/super-users/${superuserId}/institutions/${institutionId}/purge-cache`, {});
  }

  // Not cached
  public getSuperuserInstitutionLogoSrc({ superuserId, institutionId }: ISuperuserInstitutionParams) {
    return `${environment.apiUri}/api/super-users/${superuserId}/institutions/${institutionId}/logo`;
  }

  public setSuperuserInstitutionLogo({
    superuserId,
    institutionId,
    formData
  }: ISuperuserInstitutionParams & { formData: FormData }) {
    return this.httpClient.put(`${environment.apiUri}/api/super-users/${superuserId}/institutions/${institutionId}/logo`, formData);
  }

  // Not cached
  public getSuperuserProgramLogoSrc({ superuserId, institutionId, programId }: ISuperuserInstitutionProgramParams) {
    return `${environment.apiUri}/api/super-users/${superuserId}/institutions/${institutionId}/programs/${programId}/logo`;
  }

  public setSuperuserProgramLogo({
    superuserId,
    institutionId,
    programId,
    formData
  }: ISuperuserInstitutionProgramParams & { formData: FormData }) {
    return this.httpClient.put(`${environment.apiUri}/api/super-users/${superuserId}/institutions/${institutionId}/programs/${programId}/logo`, formData);
  }

  public getInstitutionsEffect({ superuserId }: { superuserId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/super-users/${superuserId}/institutions`) as Observable<IInstitutionDescriptorDto[]>;
  }

  // public changeWebsiteInstitutionStatusEffect({ superuserId, institutionId, offline }: ISuperuserInstitutionParams & { offline: IOfflineDto }) {
  //   return this.httpClient.patch(`${environment.apiUri}/api/super-users/${superuserId}/institutions/${institutionId}/offline`, offline);
  // }

  public changeWebsiteStatusEffect({ superuserId, offlineDto }: ISuperuserParams & { offlineDto: IOfflineDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/super-users/${superuserId}/site-offline`, offlineDto);
  }

  public vantivSettlementSync({ superuserId, institutionId, programId }: ISuperuserInstitutionProgramParams) {
    return this.httpClient.patch(`${environment.apiUri}/api/super-users/${superuserId}/institutions/${institutionId}/programs/${programId}/vantiv-settlement-sync`, {});
  }

  public pendingTransactionSync({ superuserId, institutionId, programId }: ISuperuserInstitutionProgramParams) {
    return this.httpClient.patch(`${environment.apiUri}/api/super-users/${superuserId}/institutions/${institutionId}/programs/${programId}/pending-transaction-sync`, {});
  }

  public setInstitutionStatus({
    superuserId,
    institutionId,
    offlineDto
  }: ISuperuserInstitutionParams & { offlineDto: IOfflineDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/super-users/${superuserId}/institutions/${institutionId}/offline`, offlineDto);
  }

  public setInstitutionOfflineSchedule({
    superuserId,
    institutionId,
    offlineSchedule
  }: ISuperuserInstitutionParams & { offlineSchedule: IOfflineScheduleDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/super-users/${superuserId}/institutions/${institutionId}/offline-schedule`, offlineSchedule);
  }

  public deleteInstitutionOfflineSchedule({ superuserId, institutionId }: ISuperuserInstitutionParams) {
    return this.httpClient.delete(`${environment.apiUri}/api/super-users/${superuserId}/institutions/${institutionId}/offline-schedule`);
  }

  public createProgram({
    superuserId,
    institutionId,
    programCreate
  }: ISuperuserInstitutionParams & { programCreate: IInstitutionProgramCreateDto }) {
    return this.httpClient.post(`${environment.apiUri}/api/super-users/${superuserId}/institutions/${institutionId}/programs`, programCreate);
  }

  public deleteUserAccount(email: string, force: boolean = false) {
    return this.httpClient.delete(`${environment.apiUri}/api/user-accounts?email=${email}&force=${force}`);
  }

  public setNonDiscriminationDisclosure({
    superuserId,
    institutionId,
    disclosure
  }: ISuperuserInstitutionParams & { disclosure: INonDiscriminationDisclosureDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/super-users/${superuserId}/institutions/${institutionId}/non-discrimination-disclosure`, disclosure);
  }
}
