import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IInstitutionManagerProgramHierarchyParams, IMemberSearchParametersDto } from 'app/core/models';
import { IMemberSearchResultsDto } from 'app/core/models/serverDTOs/IMemberSearchResultsDto';


export const SearchMembersAction = createAction(
    '[member-search] Search members',
    props<IInstitutionManagerProgramHierarchyParams & { searchParameters: IMemberSearchParametersDto }>()
);

export const SearchMembersSuccessAction = createAction(
    '[member-search] Search members success',
    props<IInstitutionManagerProgramHierarchyParams & { searchParameters: IMemberSearchParametersDto, membersSearchResult: IMemberSearchResultsDto }>()
);

export const SearchMembersErrorAction = createAction(
    '[member-search] Search members error',
    props<{ error: HttpErrorResponse }>()
);


export const GetMemberSearchResultsAction = createAction(
    '[member-search] get member search results',
    props<IInstitutionManagerProgramHierarchyParams & { pageSize: string, pageIndex: string }>()
);

export const GetMemberSearchResultsSuccessAction = createAction(
    '[member-search] get member search results success',
    props<IInstitutionManagerProgramHierarchyParams & { pageSize: string, pageIndex: string, membersSearchResult: IMemberSearchResultsDto }>()
);

export const GetMemberSearchResultsErrorAction = createAction(
    '[member-search] get member search results error',
    props<{ error: HttpErrorResponse }>()
);


export const ClearMemberSearchAction = createAction(
    '[member-search] clear member search',
    props<IInstitutionManagerProgramHierarchyParams>()
);

export const ClearMemberSearchSuccessAction = createAction(
    '[member-search] clear member search success',
    props<IInstitutionManagerProgramHierarchyParams>()
);

export const ClearMemberSearchErrorAction = createAction(
    '[member-search] clear member search error',
    props<{ error: HttpErrorResponse }>()
);
