import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { filter, map, Observable, of, take, tap } from 'rxjs';

import {
    AdminFeatureStore,
    getManagerBankAccount,
    getManagerBankAccounts,
    getSystemManagerBankAccounts
} from '../containers/admin/admin-feature.reducers';
import { BankAccountActions } from '../containers/admin/shared/bank-accounts';
import {
    IBankAccountCreateDto,
    IBankAccountDto,
    IBankAccountUpdateDto,
    IInstitutionManagerProgramHierarchyParams,
    IInstitutionManagerProgramParams,
    IInstitutionSystemManagerProgramParams
} from '../models';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class BankAccountsService {

    constructor(private store: Store<AdminFeatureStore>, private httpClient: HttpClient, private dispatcher: ActionsSubject) {
    }

    public getManagerBankAccounts(params: IInstitutionManagerProgramHierarchyParams): Observable<IBankAccountDto[]> {
        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getManagerBankAccounts(params)),
            tap(accounts => {
                if (accounts === undefined) {
                    this.store.dispatch(BankAccountActions.BankAccountsManagerLoadAction(params));
                }
            }),
            filter(accounts => accounts != null)
        );
    }

    public getManagerBankAccountsEffect({
        institutionId,
        managerId,
        programId,
        hierarchyNodeId
    }: IInstitutionManagerProgramHierarchyParams) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/bank-accounts/${hierarchyNodeId}`) as Observable<IBankAccountDto[]>;
    }

    public getManagerBankAccount(params: IInstitutionManagerProgramParams & {
        bankAccountId: string
    }): Observable<IBankAccountDto> {
        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getManagerBankAccount(params)),
            tap(account => {
                if (account === undefined) {
                    this.store.dispatch(BankAccountActions.BankAccountManagerLoadAction(params));
                }
            }),
            filter(account => account != null)
        );
    }

    public getManagerBankAccountEffect({
        institutionId,
        managerId,
        programId,
        bankAccountId
    }: IInstitutionManagerProgramParams & { bankAccountId: string }) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/bank-accounts/${bankAccountId}`) as Observable<IBankAccountDto>;
    }

    public addManagerBankAccount(params: IInstitutionManagerProgramHierarchyParams & {
        bankAccountCreate: IBankAccountCreateDto
    }) {
        this.store.dispatch(BankAccountActions.BankAccountsManagerAddAccountAction(params));

        return this.dispatcher.pipe(
            ofType(BankAccountActions.BankAccountsManagerAddAccountSuccessAction, BankAccountActions.BankAccountsManagerAddAccountErrorAction),
            take(1),
            map(action => {
                if (action.type === BankAccountActions.BankAccountsManagerAddAccountSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public addManagerBankAccountEffect({
        institutionId,
        managerId,
        programId,
        hierarchyNodeId,
        bankAccountCreate
    }: IInstitutionManagerProgramHierarchyParams & {
        bankAccountCreate: IBankAccountCreateDto
    }) {
        return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/bank-accounts/${hierarchyNodeId}`, bankAccountCreate);
    }

    public deleteManagerBankAccount(params: IInstitutionManagerProgramHierarchyParams & { bankAccountId: string }) {
        this.store.dispatch(BankAccountActions.BankAccountsManagerDeleteAccountAction(params));

        return this.dispatcher.pipe(
            ofType(BankAccountActions.BankAccountsManagerDeleteAccountSuccessAction, BankAccountActions.BankAccountsManagerDeleteAccountErrorAction),
            take(1),
            map(action => {
                if (action.type === BankAccountActions.BankAccountsManagerDeleteAccountSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public deleteManagerBankAccountEffect({
        institutionId,
        managerId,
        programId,
        hierarchyNodeId,
        bankAccountId
    }: IInstitutionManagerProgramHierarchyParams & { bankAccountId: string }) {
        return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/bank-accounts/${bankAccountId}`);
    }

    public updateManagerBankAccount(params: IInstitutionManagerProgramParams & {
        bankAccountId: string,
        bankAccountUpdate: IBankAccountUpdateDto
    }) {
        this.store.dispatch(BankAccountActions.BankAccountsManagerUpdateAccountAction(params));

        return this.dispatcher.pipe(
            ofType(BankAccountActions.BankAccountsManagerUpdateAccountSuccessAction, BankAccountActions.BankAccountsManagerUpdateAccountErrorAction),
            take(1),
            map(action => {
                if (action.type === BankAccountActions.BankAccountsManagerUpdateAccountSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public updateManagerBankAccountEffect({
        institutionId,
        managerId,
        programId,
        bankAccountId,
        bankAccountUpdate
    }: IInstitutionManagerProgramParams & {
        bankAccountId: string,
        bankAccountUpdate: IBankAccountUpdateDto
    }) {
        return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/bank-accounts/${bankAccountId}`, bankAccountUpdate);
    }

    public getSystemManagerBankAccounts(params: IInstitutionSystemManagerProgramParams): Observable<IBankAccountDto[]> {
        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getSystemManagerBankAccounts(params)),
            tap(accounts => {
                if (accounts === undefined) {
                    this.store.dispatch(BankAccountActions.BankAccountsSystemManagerLoadAction(params));
                }
            }),
            filter(accounts => accounts != null)
        );
    }

    public getSystemManagerBankAccountsEffect({
        institutionId,
        systemManagerId,
        programId
    }: IInstitutionSystemManagerProgramParams) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/bank-accounts`) as Observable<IBankAccountDto[]>;
    }

    public addSystemManagerBankAccount(params: IInstitutionSystemManagerProgramParams & {
        bankAccountCreate: IBankAccountCreateDto
    }) {
        this.store.dispatch(BankAccountActions.BankAccountsSystemManagerAddAccountAction(params));

        return this.dispatcher.pipe(
            ofType(BankAccountActions.BankAccountsSystemManagerAddAccountSuccessAction, BankAccountActions.BankAccountsSystemManagerAddAccountErrorAction),
            take(1),
            map(action => {
                if (action.type === BankAccountActions.BankAccountsSystemManagerAddAccountSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public addSystemManagerBankAccountEffect({
        institutionId,
        systemManagerId,
        programId,
        bankAccountCreate
    }: IInstitutionSystemManagerProgramParams & {
        bankAccountCreate: IBankAccountCreateDto
    }) {
        return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/bank-accounts`, bankAccountCreate) as Observable<IBankAccountCreateDto>;
    }

    public updateSystemManagerBankAccount(params: IInstitutionSystemManagerProgramParams & {
        bankAccountId: string,
        bankAccount: IBankAccountUpdateDto
    }) {
        this.store.dispatch(BankAccountActions.BankAccountsSystemManagerUpdateAccountAction(params));

        return this.dispatcher.pipe(
            ofType(BankAccountActions.BankAccountsSystemManagerUpdateAccountSuccessAction, BankAccountActions.BankAccountsSystemManagerUpdateAccountErrorAction),
            take(1),
            map(action => {
                if (action.type === BankAccountActions.BankAccountsSystemManagerUpdateAccountSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public updateSystemManagerBankAccountEffect({
        institutionId,
        systemManagerId,
        programId,
        bankAccountId,
        bankAccount
    }: IInstitutionSystemManagerProgramParams & {
        bankAccountId: string,
        bankAccount: IBankAccountUpdateDto
    }) {
        return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/bank-accounts/${bankAccountId}`, bankAccount) as Observable<IBankAccountUpdateDto>;
    }

    public deleteSystemManagerBankAccount(params: IInstitutionSystemManagerProgramParams & { bankAccountId: string }) {
        this.store.dispatch(BankAccountActions.BankAccountsSystemManagerDeleteAccountAction(params));

        return this.dispatcher.pipe(
            ofType(BankAccountActions.BankAccountsSystemManagerDeleteAccountSuccessAction, BankAccountActions.BankAccountsSystemManagerDeleteAccountErrorAction),
            take(1),
            map(action => {
                if (action.type === BankAccountActions.BankAccountsSystemManagerDeleteAccountSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public deleteSystemManagerBankAccountEffect({
        institutionId,
        systemManagerId,
        programId,
        bankAccountId
    }: IInstitutionSystemManagerProgramParams & { bankAccountId: string }) {
        return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/bank-accounts/${bankAccountId}`) as Observable<any>;
    }
}
