import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DeliveryModeService } from 'app/core/services/delivery-mode.service';
import { catchError, exhaustMap, groupBy, map, mergeMap, of } from 'rxjs';

import { DeliveryModeActions } from '.';
import { stringKeys } from '../rxjs.utils';

@Injectable()
export class DeliveryModeEffects {

  public getDeliveryModes$ = createEffect(() => this.actions.pipe(
    ofType(DeliveryModeActions.DeliveryModesLoadAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.deliveryModeService.getDeliveryModesEffect(payload).pipe(
            map(deliveryModes => DeliveryModeActions.DeliveryModesLoadSuccessAction({ ...payload, deliveryModes })),
            catchError(error => of(DeliveryModeActions.DeliveryModesLoadErrorAction({ error })))
          );
        })
      );
    })
  ));
  public getGroupEnrollmentDeliveryModesDeliveryModes$ = createEffect(() => this.actions.pipe(
    ofType(DeliveryModeActions.GroupEnrollmentDeliveryModesLoadAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.deliveryModeService.getGroupEnrollmentDeliveryModesEffect(payload).pipe(
            map(deliveryModes => DeliveryModeActions.GroupEnrollmentDeliveryModesLoadSuccessAction({
              ...payload,
              deliveryModes
            })),
            catchError(error => of(DeliveryModeActions.GroupEnrollmentDeliveryModesLoadErrorAction({ error })))
          );
        })
      );
    })
  ));

  constructor(private actions: Actions, private deliveryModeService: DeliveryModeService) {
  }
}
