import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import { IInstitutionFamilyParams, IInstitutionFamilyProgramParams } from 'app/core/models';

import * as family from './family.reducer';

// import * as enrollment from '../member/enrollment/enrollment.reducer';
export const FAMILY_FEATURE = 'FAMILY_FEATURE';
export interface FamilyFeatureStore {
    family: family.FamilyState;
}
export const familyReducers: ActionReducerMap<FamilyFeatureStore> = {
    family: family.familyReducer,

};

export const getFamilyFeatureStore = createFeatureSelector<FamilyFeatureStore>(FAMILY_FEATURE);

export const getFamilyState = createSelector(getFamilyFeatureStore, (state: FamilyFeatureStore) => state && state.family);

export const getFamilies = ({ institutionId, familyId }: IInstitutionFamilyParams) => createSelector(
    createSelector(getFamilyState, family.families),
    families => families?.[institutionId]?.[familyId]
);

export const getFamiliesDashboard = ({ institutionId, familyId }: IInstitutionFamilyParams) => createSelector(
    createSelector(getFamilyState, family.familiesDashboard),
    dashboard => dashboard?.[institutionId]?.[familyId]
);
export const getFamiliesDescriptor = ({ institutionId, familyId }: IInstitutionFamilyParams) => createSelector(
    createSelector(getFamilyState, family.familiesDescriptor),
    descriptor => descriptor?.[institutionId]?.[familyId]
);

export const getFamilyEventCatalog = ({ institutionId, familyId }: IInstitutionFamilyParams) => createSelector(
    createSelector(getFamilyState, family.familyEventCatalog),
    events => events?.[institutionId]?.[familyId]
);

export const getFamilyLogins = ({ institutionId, familyId }: IInstitutionFamilyParams) => createSelector(
    createSelector(getFamilyState, family.familyLogins),
    logins => logins?.[institutionId]?.[familyId]
);
export const getFamilyEmails = ({ institutionId, familyId }: IInstitutionFamilyParams) => createSelector(
    createSelector(getFamilyState, family.familyEmails),
    emails => emails?.[institutionId]?.[familyId]
);
export const getFamilyProfileNotes = ({ institutionId, familyId, programId }: IInstitutionFamilyProgramParams) => createSelector(
    createSelector(getFamilyState, family.familyNotes),
    emails => emails?.[institutionId]?.[familyId]?.[programId]
);

