import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { EnrollmentService } from 'app/core/services';
import { stringKeys } from 'app/shared/rxjs.utils';
import { catchError, exhaustMap, groupBy, map, mergeMap, Observable, of } from 'rxjs';

import { EnrollmentActions } from '.';
import { MemberActions } from '..';
import { ManagerEnrollmentActions } from '../../admin/manager/enrollments/manager-enrollments/store';
import { FamilyActions } from '../../family';



@Injectable()
export class EnrollmentEffects {


  public createEnrollment$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EnrollmentActions.EnrollmentCreateAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.enrollmentService.createEnrollmentEffect(payload).pipe(
            map(enrollment => EnrollmentActions.EnrollmentCreateSuccessAction({ enrollment: enrollment, ...payload })),
            catchError(error => of(EnrollmentActions.EnrollmentCreateErrorAction({ error })))
          );
        })
      );
    })
  ));

  public createEnrollmentSuccess$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EnrollmentActions.EnrollmentCreateSuccessAction),
    mergeMap(payload => {
      return [
        FamilyActions.FamilyInvalidateCacheAction(),
        MemberActions.MemberInvalidateMemberCache()
      ];
    })
  ));


  public loadEnrollment$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EnrollmentActions.EnrollmentLoadAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap((payload) => {
          return this.enrollmentService.loadEnrollmentEffect(payload).pipe(
            map(enrollment => EnrollmentActions.EnrollmentLoadSuccessAction({ ...payload, enrollment })),
            catchError(error => of(EnrollmentActions.EnrollmentLoadErrorAction({ error })))
          );
        })
      );
    })
  ));


  public loadVolunteerTypes$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EnrollmentActions.EnrollmentLoadAvailableVolunteerTypesAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap((payload) => {
          return this.enrollmentService.getVolunteerTypesEffect(payload).pipe(
            map(volunteerTypes => EnrollmentActions.EnrollmentLoadAvailableVolunteerTypesSuccessAction({ ...payload, volunteerTypes })),
            catchError(error => of(EnrollmentActions.EnrollmentLoadAvailableVolunteerTypesErrorAction({ error })))
          );
        })
      );
    })
  ));

  public saveVolunteerTypes$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EnrollmentActions.EnrollmentSaveVolunteerTypesAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap((payload) => {
          return this.enrollmentService.saveVolunteerTypesEffect(payload).pipe(
            map(enrollment => EnrollmentActions.EnrollmentSaveVolunteerTypesSuccessAction(payload)),
            catchError(error => of(EnrollmentActions.EnrollmentSaveVolunteerTypesErrorAction({ error })))
          );
        })
      );
    })
  ));


  public saveClubs$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EnrollmentActions.EnrollmentSaveClubsAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap((payload) => {
          return this.enrollmentService.saveClubsEffect(payload.clubSubmission, payload.institutionId, payload.memberId, payload.programId, payload.enrollmentId).pipe(
            map(enrollment => EnrollmentActions.EnrollmentSaveClubsSuccessAction(payload)),
            catchError(error => of(EnrollmentActions.EnrollmentSaveClubsErrorAction({ error })))
          );
        })
      );
    })
  ));

  public saveProject$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EnrollmentActions.EnrollmentSaveProjectAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap((payload) => {
          return this.enrollmentService.saveProjectEffect(payload.projectSubmission, payload.institutionId, payload.memberId, payload.programId, payload.enrollmentId).pipe(
            map(enrollment => EnrollmentActions.EnrollmentSaveProjectSuccessAction()),
            catchError(error => of(EnrollmentActions.EnrollmentSaveProjectErrorAction({ error })))
          );
        })
      );
    })
  ));


  public deleteProject$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EnrollmentActions.EnrollmentDeleteProjectAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap((payload) => {
          return this.enrollmentService.deleteProjectEffect(payload).pipe(
            map(() => EnrollmentActions.EnrollmentDeleteProjectSuccessAction(payload)),
            catchError(error => of(EnrollmentActions.EnrollmentDeleteProjectErrorAction({ error })))
          );
        })
      );
    })
  ));

  public loadDynamicForm$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EnrollmentActions.EnrollmentLoadDynamicFormAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap((payload) => {
          return this.enrollmentService.loadDynamicFormEffect(payload).pipe(
            map(dynamicForm => EnrollmentActions.EnrollmentLoadDynamicFormSuccessAction({ ...payload, dynamicForm: dynamicForm })),
            catchError(error => of(EnrollmentActions.EnrollmentLoadDynamicFormErrorAction({ error })))
          );
        })
      );
    })
  ));


  public saveDynamicFormResponses$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EnrollmentActions.EnrollmentSaveDynamicFormResponsesAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap((payload) => {
          return this.enrollmentService.saveDynamicFormResponsesEffect(payload).pipe(
            map(dynamicFormResponses => EnrollmentActions.EnrollmentSaveDynamicFormResponsesSuccessAction(payload)),
            catchError(error => of(EnrollmentActions.EnrollmentSaveDynamicFormResponsesErrorAction({ error })))
          );
        })
      );
    })
  ));



  public saveDemographics$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EnrollmentActions.EnrollmentSaveDemographicsAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap((payload) => {
          return this.enrollmentService.saveDemographicsEffect(payload).pipe(
            map(() => EnrollmentActions.EnrollmentSaveDemographicsSuccessAction(payload)),
            catchError(error => of(EnrollmentActions.EnrollmentSaveDemographicsErrorAction({ error })))
          );
        })
      );
    })
  ));

  public saveConsent$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EnrollmentActions.EnrollmentSaveConsentsAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap((payload) => {
          return this.enrollmentService.saveEnrollmentConsentsEffect(payload).pipe(
            map(() => EnrollmentActions.EnrollmentSaveConsentsSuccessAction(payload)),
            catchError(error => of(EnrollmentActions.EnrollmentSaveConsentsErrorAction({ error })))
          );
        })
      );
    })
  ));

  // public loadInvoice$: Observable<Action> = createEffect(() => this.actions.pipe(
  //   ofType(EnrollmentActions.EnrollmentLoadPayableInvoiceAction),
  //   groupBy(stringKeys),
  //   mergeMap(groupedBy => {
  //     return groupedBy.pipe(
  //       exhaustMap((payload) => {
  //         return this.enrollmentService.loadInvoiceEffect(payload).pipe(
  //           //ensure it's null and not undefined
  //           map((invoice) => invoice == null ? null : invoice),
  //           map((invoice) => EnrollmentActions.EnrollmentLoadPayableInvoiceSuccessAction({ ...payload, invoice })),
  //           catchError(error => of(EnrollmentActions.EnrollmentLoadPayableInvoiceErrorAction({ error })))
  //         );
  //       })
  //     );
  //   })
  // ));

  public applyPayment$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EnrollmentActions.EnrollmentApplyPaymentAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap((payload) => {
          return this.enrollmentService.applyPaymentEffect(payload).pipe(
            map(() => EnrollmentActions.EnrollmentApplyPaymentSuccessAction(payload)),
            catchError(error => of(EnrollmentActions.EnrollmentApplyPaymentErrorAction({ error })))
          );
        })
      );
    })
  ));

  public applyAdditionalPayment$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EnrollmentActions.EnrollmentApplyAdditionalPaymentAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap((payload) => {
          return this.enrollmentService.applyAdditionalPaymentEffect(payload).pipe(
            map(() => EnrollmentActions.EnrollmentApplyAdditionalPaymentSuccessAction(payload)),
            catchError(error => of(EnrollmentActions.EnrollmentApplyAdditionalPaymentErrorAction({ error })))
          );
        })
      );
    })
  ));

  public applyCouponCode$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EnrollmentActions.EnrollmentApplyCouponCodeAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap((payload) => {
          return this.enrollmentService.applyCouponCodeEffect(payload).pipe(
            map(() => EnrollmentActions.EnrollmentApplyCouponCodeSuccessAction(payload)),
            catchError(error => of(EnrollmentActions.EnrollmentApplyCouponCodeErrorAction({ error })))
          );
        })
      );
    })
  ));

  public requestFeeWaiver$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EnrollmentActions.EnrollmentRequestFeeWaiverAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap((payload) => {
          return this.enrollmentService.requestFeeWaiverEffect(payload).pipe(
            map(() => EnrollmentActions.EnrollmentRequestFeeWaiverSuccessAction(payload)),
            catchError(error => of(EnrollmentActions.EnrollmentRequestFeeWaiverErrorAction({ error })))
          );
        })
      );
    })
  ));

  applyCredit$ = createEffect(() => this.actions.pipe(
    ofType(EnrollmentActions.EnrollmentApplyCreditAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.enrollmentService.applyCreditEffect(payload).pipe(
            map(() => EnrollmentActions.EnrollmentApplyCreditSuccessAction(payload)),
            catchError(error => of(EnrollmentActions.EnrollmentApplyCreditErrorAction({ error })))
          );
        })
      );
    })
  ));
  public submitEnrollment$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EnrollmentActions.EnrollmentSubmitAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap((payload) => {
          return this.enrollmentService.submitEnrollmentEffect(payload).pipe(
            map(() => EnrollmentActions.EnrollmentSubmitSuccessAction(payload)),
            catchError(error => of(EnrollmentActions.EnrollmentSubmitErrorAction({ error })))
          );
        })
      );
    })
  ));

  public afterSuccessfulEnrollmentSubmission$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(
      EnrollmentActions.DeleteEnrollmentSuccessAction,
      EnrollmentActions.EnrollmentSubmitSuccessAction,
      EnrollmentActions.EnrollmentApplyAdditionalPaymentSuccessAction,
      EnrollmentActions.DeleteEnrollmentSuccessAction
    ),
    mergeMap(payload => {
      return [
        EnrollmentActions.EnrollmentInvalidateCacheAction(),
        ManagerEnrollmentActions.InvalidateManagerEnrollmentsCache(),
        FamilyActions.FamilyInvalidateCacheAction(),
        MemberActions.MemberInvalidateMemberCache()
      ];
    })
  ));





  public loadCustomFiles$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EnrollmentActions.EnrollmentLoadCustomFileAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap((payload) => {
          return this.enrollmentService.loadCustomFilesEffect(payload).pipe(
            map(customFiles => customFiles == null ? [] : customFiles),
            map((customFiles => EnrollmentActions.EnrollmentLoadCustomFileSuccessAction({ ...payload, customFiles }))),
            catchError(error => of(EnrollmentActions.EnrollmentLoadCustomFileErrorAction({ error })))
          );
        })
      );
    })
  ));

  public uploadEnrollmentFile$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EnrollmentActions.EnrollmentUploadFileAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap((payload) => {
          return this.enrollmentService.uploadMemberFileEffect(payload).pipe(
            map((res => EnrollmentActions.EnrollmentUploadFileSuccessAction(payload))),
            catchError(error => of(EnrollmentActions.EnrollmentUploadFileErrorAction({ error })))
          );
        })
      );
    })
  ));

  public deleteEnrollmentFile$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EnrollmentActions.EnrollmentDeleteFileAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap((payload) => {
          return this.enrollmentService.deleteMemberFileEffect(payload).pipe(
            map((res => EnrollmentActions.EnrollmentDeleteFileSuccessAction(payload))),
            catchError(error => of(EnrollmentActions.EnrollmentDeleteFileErrorAction({ error })))
          );
        })
      );
    })
  ));




  public invalidateEnrollmentOnClubSave: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EnrollmentActions.EnrollmentSaveClubsSuccessAction),
    map(payload => {
      return EnrollmentActions.EnrollmentInvalidateCacheAction();
    })
  ));

  public invalidateCustomFilesCache: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EnrollmentActions.EnrollmentUploadFileSuccessAction, EnrollmentActions.EnrollmentDeleteFileSuccessAction),
    map(payload => {
      return EnrollmentActions.EnrollmentInvalidateCustomFilesCache();
    })
  ));

  public deleteEnrollment: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EnrollmentActions.DeleteEnrollmentAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap((payload) => {
          return this.enrollmentService.deleteEnrollmentEffect(payload).pipe(
            map((res => EnrollmentActions.DeleteEnrollmentSuccessAction(payload))),
            catchError(error => of(EnrollmentActions.DeleteEnrollmentErrorAction({ error })))
          );
        })
      );
    })
  ));

  public swapUnits: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EnrollmentActions.SwapUnitsAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap((payload) => {
          return this.enrollmentService.swapUnitsEffect(payload).pipe(
            map((res => EnrollmentActions.SwapUnitsSuccessAction(payload))),
            catchError(error => of(EnrollmentActions.SwapUnitsErrorAction({ error })))
          );
        })
      );
    })
  ));

  public requestAddUnit: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EnrollmentActions.RequestAddUnitAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap((payload) => {
          return this.enrollmentService.requestAddUnitEffect(payload).pipe(
            map((res => EnrollmentActions.RequestAddUnitSuccessAction(payload))),
            catchError(error => of(EnrollmentActions.RequestAddUnitErrorAction({ error })))
          );
        })
      );
    })
  ));

  constructor(private actions: Actions, private enrollmentService: EnrollmentService) { }
}
