import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { eConsentStatus, IConsentVersionDto } from 'app/core/models';
import { IConsentTextDto } from 'app/core/models/serverDTOs/IConsentTextDto';

@Component({
  selector: 'ng4h-admin-consent',
  templateUrl: './admin-consent.component.html',
  styleUrls: ['./admin-consent.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminConsentComponent implements OnInit {

  @Input() public consent: IConsentVersionDto;
  @Input() public language: string;

  public eConsentStatus = eConsentStatus;
  public consentText: IConsentTextDto;

  constructor(private fb: FormBuilder) { }

  public ngOnInit(): void {
    this.consentText = this.consent.consentTexts[this.language];
  }
}
