import { Pipe, PipeTransform } from '@angular/core';
import { IAliasDto } from 'app/core/models';
import { RouterService } from 'app/core/services';
import { map, Observable } from 'rxjs';

import { ProgramSettingsService } from '../services/program-settings.service';

@Pipe({
    name: 'programAlias$',
    pure: true
})
export class ProgramAliasPipe implements PipeTransform {
    constructor(private programSettingsService: ProgramSettingsService, private routerService: RouterService) {
    }

    public transform(alias: string, institutionId: string = this.routerService.institutionId, programId: string = this.routerService.programId): Observable<IAliasDto> {
        return this.programSettingsService.getProgramNullableSettings({ institutionId, programId }).pipe(
            map(programSettings => {
                if (programSettings?.aliases?.[alias] == null) {
                    return {
                        singular: alias,
                        plural: alias + 's'
                    };
                }
                return programSettings.aliases[alias];
            })
        );
    }
}
