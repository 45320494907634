import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { eHierarchyNodeType, IHierarchyCountyArea } from 'app/core/models';
import { BehaviorSubject, combineLatest, filter, map, Observable, of, switchMap, take } from 'rxjs';

import { HierarchyService, RouterService } from '../../services';

@Component({
    selector: 'ng4h-county-area-multiselect-autocomplete',
    templateUrl: './county-area-multiselect-autocomplete.component.html',
    styleUrls: ['./county-area-multiselect-autocomplete.component.scss']
})
export class CountyAreaMultiselectAutocompleteComponent implements OnInit, OnChanges {

    @Input() hierarchyNodeId: string;
    @Input() selectedCounties: string[]; // hierarchy node ids
    @Input() showAllCountiesForInstitution: boolean;
    @Input() showAllCounties: boolean;
    @Input() disabled: boolean;
    @Input() reset: EventEmitter<void>;
    @Output() selectedChanged: EventEmitter<IHierarchyCountyArea[]> = new EventEmitter();

    public counties$: Observable<IHierarchyCountyArea[]>;
    public selectCounties$: Observable<string[]>;

    private selectedCounties$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

    constructor(private hierarchyService: HierarchyService, private routerService: RouterService) {
    }

    ngOnInit() {
        if (this.showAllCounties === true) {
            this.counties$ = this.hierarchyService.getHierarchy({ institutionId: this.routerService.institutionId }).pipe(
                filter(hierarchy => hierarchy != null),
                take(1),
                map(hierarchy => {
                    return Object.keys(hierarchy).map(key => hierarchy[key]).filter(hierarchyNode => hierarchyNode.hierarchyNodeType === eHierarchyNodeType.CountyArea) as IHierarchyCountyArea[];
                })
            );
        } else {
            this.counties$ = this.hierarchyService.getHierarchyNode({
                    institutionId: this.routerService.institutionId,
                    hierarchyNodeId: this.hierarchyNodeId
                })
                .pipe(
                    filter(hierarchyNode => hierarchyNode != null),
                    take(1),
                    switchMap(hierarchyNode => {
                        if (this.showAllCounties
                            || hierarchyNode.hierarchyNodeType === eHierarchyNodeType.RegionArea
                            || hierarchyNode.hierarchyNodeType === eHierarchyNodeType.DistrictArea
                            || (hierarchyNode.hierarchyNodeType === eHierarchyNodeType.Institution && this.showAllCountiesForInstitution)) {
                            return this.hierarchyService.getCountyAreas({ institutionId: this.routerService.institutionId, hierarchyNode }).pipe(
                                filter(counties => Array.isArray(counties)),
                                map(counties => {
                                    return counties.sort((a, b) => a.hierarchyNodeName.localeCompare(b.hierarchyNodeName));
                                })
                            );
                        } else {
                            return of(null);
                        }
                    })
                );
        }

        this.selectCounties$ = combineLatest([this.counties$, this.selectedCounties$]).pipe(
            filter(([counties, selectedCounties]) => Array.isArray(counties) && Array.isArray(selectedCounties)),
            map(([counties, selectedCounties]) => {
                const selected = counties.filter(x => selectedCounties.includes(x.hierarchyNodeId));
                this.selectedChanged.emit(selected);
                return selected.map(county => county.hierarchyNodeName);
            })
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.selectedCounties) {
            if (changes.selectedCounties?.currentValue?.length != null && changes.selectedCounties?.currentValue?.length === changes.selectedCounties?.previousValue?.length && changes.selectedCounties.currentValue.every((id, index) => id === changes.selectedCounties.previousValue[index])) {
                return;
            }
            this.selectedCounties$.next(changes.selectedCounties.currentValue);
        }
    }
}
