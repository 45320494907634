import { Pipe, PipeTransform } from '@angular/core';
import { RouterService } from 'app/core/services';
import { map, Observable } from 'rxjs';

import { ProgramSettingsService } from '../services/program-settings.service';
import { CamelToTitleCasePipe } from './camel-to-title-case.pipe';

@Pipe({
    name: 'genderAlias$',
    pure: true
})
export class GenderAliasPipe implements PipeTransform {
    constructor(private programSettingsService: ProgramSettingsService, private routerService: RouterService) {
    }

    public transform(alias: string, institutionId: string = this.routerService.institutionId, programId: string = this.routerService.programId): Observable<string> {
        return this.programSettingsService.getProgramNullableSettings({ institutionId, programId }).pipe(
            map(programSettings => {
                if (programSettings?.genderAliases?.[alias] == null) {
                    return new CamelToTitleCasePipe().transform(alias);
                }
                return programSettings.genderAliases[alias];
            })
        );
    }
}
