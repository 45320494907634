import { Pipe, PipeTransform } from '@angular/core';

import { eAccessControlListRoleType } from '../models';


@Pipe({
    name: 'accessControlListRoleType',
    pure: true
})

export class AccessControlListRoleTypePipe implements PipeTransform {

    transform(value: eAccessControlListRoleType): any {
        switch (value) {
            case eAccessControlListRoleType.EventAdmin: {
                return 'Event Admin';
            }
            case eAccessControlListRoleType.EventStaff: {
                return 'Event Staff';
            }
            case eAccessControlListRoleType.EventVolunteer: {
                return 'Event Volunteer';
            }
            case eAccessControlListRoleType.HierarchyAdmin: {
                return 'Hierarchy Admin';
            }
            case eAccessControlListRoleType.HierarchyStaff: {
                return 'Hierarchy Staff';
            }
            case eAccessControlListRoleType.HierarchyVolunteer: {
                return 'Hierarchy Volunteer';
            }
            case eAccessControlListRoleType.Superuser: {
                return 'Superuser';
            }
            case eAccessControlListRoleType.SystemManager: {
                return 'SystemManager';
            }
            default: {
                return value;
            }
        }
    }
}
