import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { eLocalizationType } from 'app/core/models';
import { ConsentsService, RouterService } from 'app/core/services';
import { CommonToastrService } from 'app/core/services/common-toastr.service';

@Component({
  selector: 'ng4h-modal-create-consent',
  templateUrl: './modal-create-consent.component.html',
  styleUrls: ['./modal-create-consent.component.scss']
})
export class ModalCreateConsentComponent implements OnInit {

  public eLocalizationType = eLocalizationType;
  public form: FormGroup;
  public config: any;
  public wasValidated = false;

  constructor(private dialogRef: MatDialogRef<any>, private toastrService: CommonToastrService, private fb: FormBuilder, private consentsService: ConsentsService, private routerService: RouterService) {
  }

  ngOnInit() {

    this.form = this.fb.group({
      acceptanceRequired: [false, Validators.required],
      electronicSignatureRequired: [true, Validators.required],
      consentName: ['', Validators.required],
      consentText: ['', Validators.required],
      consentTitle: ['', Validators.required],
      agreeOptionText: ['', Validators.required],
      disagreeOptionText: ['', Validators.required],
      dateEffective: [null, Validators.required],
      localization: [eLocalizationType.US_English, Validators.required],
      role: [null, Validators.required]
    });

    this.form.controls.acceptanceRequired.valueChanges.subscribe(newAcceptanceRequired => {
      if (newAcceptanceRequired) {
        this.form.controls.disagreeOptionText.clearValidators();
      } else {
        this.form.controls.disagreeOptionText.setValidators(Validators.required);
      }
      this.form.controls.disagreeOptionText.updateValueAndValidity();
    });
  }

  public add() {
    if (this.form.invalid) {
      this.wasValidated = true;
      return;
    }
    const { role, ...consent } = this.form.value;
    this.consentsService.createConsent({ institutionId: this.routerService.institutionId, systemManagerId: this.routerService.systemManagerId, programId: this.routerService.programId, consent })
      .subscribe({
        next: () => {
          this.toastrService.success('consent created');
        }, error: (error) => {
          this.toastrService.error('consent creation failed');
        }
      });
    this.close();
  }

  public close() {
    this.dialogRef.close();
  }
}
