import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IFreeSearchResultDto, IInstitutionManagerProgramHierarchyParams } from '../models';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GlobalSearchService {

  constructor(private httpClient: HttpClient) {
  }

  public globalSearch({
    institutionId,
    managerId,
    programId,
    hierarchyNodeId,
    searchTerm
  }: IInstitutionManagerProgramHierarchyParams & { searchTerm: string }): Observable<IFreeSearchResultDto[]> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/search/${hierarchyNodeId}?searchTerm=${searchTerm}`) as Observable<IFreeSearchResultDto[]>;
  }
}
