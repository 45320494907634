import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { eRequiredForCourseCompletionType, ITrainingLessonDto } from 'app/core/models';
import { RouterService, SystemManagerTrainingsService } from 'app/core/services';
import { CommonToastrService } from 'app/core/services/common-toastr.service';

@Component({
  selector: 'ng4h-modal-edit-lesson',
  templateUrl: './modal-edit-lesson.component.html',
  styleUrls: ['./modal-edit-lesson.component.scss']
})
export class ModalEditLessonComponent implements OnInit {

  public eRequiredForCourseCompletionType = eRequiredForCourseCompletionType;



  public form: FormGroup;

  public modalData: {
    programId: string,
    trainingId: string,
    trainingCourseId: string,
    trainingLesson: ITrainingLessonDto,
  };
  public wasValidated = false;
  constructor(private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private trainingsService: SystemManagerTrainingsService,
    private routerService: RouterService,
    private toastrService: CommonToastrService) {

    this.modalData = data;
    this.form = this.formBuilder.group({
      trainingLessonTitle: [this.modalData.trainingLesson.trainingLessonTitle, Validators.required],
      required: [this.modalData.trainingLesson.required]
    });

  }

  ngOnInit() {
  }

  public save() {
    if (this.form.invalid) {
      this.wasValidated = true;
      return;
    }
    this.trainingsService.updateSystemManagerTrainingLesson({ institutionId: this.routerService.institutionId, systemManagerId: this.routerService.systemManagerId, programId: this.routerService.programId, trainingId: this.modalData.trainingId, trainingCourseId: this.modalData.trainingCourseId, trainingLessonId: this.modalData.trainingLesson.trainingLessonId, trainingLesson: this.form.value })
      .subscribe({
        next: () => {
          this.toastrService.success('training lesson updated');
        }, error: (error) => {
          this.toastrService.error('training lesson update failed');
        }
      });

    this.close();
  }


  public close() {
    this.dialogRef.close();
  }
}
