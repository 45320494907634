import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ng4h-modal-enable-registrationmax-ticketing',
  templateUrl: './modal-enable-registrationmax-ticketing.component.html',
  styleUrls: ['./modal-enable-registrationmax-ticketing.component.scss']
})
export class ModalEnableRegistrationmaxTicketingComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<any>,
  ) { }

  ngOnInit(): void {
  }

  public close(): void {
    this.dialogRef.close(false);
  }

  public enable(): void {
    this.dialogRef.close(true);
  }
}
