import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'excludeByPropertyValue',
    pure: true
})

export class ExcludeByPropertyValuePipe implements PipeTransform {
    transform(arr: any[], key: string, value: string) {
        return arr.filter(item => item[key] !== value);
    }
}
