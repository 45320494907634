<div class="modal-header">
  <h5 class="modal-title">Manager Permissions</h5>
  <button (click)="close()" type="button" class="close" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-6 my-auto">
      <h4>{{data.manager.firstName}} {{data.manager.lastName}} <span
          *ngIf="role === eAccessControlListRoleType.HierarchyAdmin">Hierarchy
          Admin</span></h4>
      {{data.manager.email}}
    </div>
    <div class="col-6">
      <div class="form-group">
        <label class="form-control-label">Select All</label>
        <select class="form-control" (change)="selectAll($event.target.value)">
          <option [ngValue]="null">
          </option>
          <option *ngFor="let permission of ePermissionState | keyvalue" [value]="permission.key">
            {{permission.value}}
          </option>
        </select>
      </div>
      <!-- <ng4h-permission-input label="Select All" (change)="selectAll($event.target.value)"></ng4h-permission-input> -->
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label class="form-control-label">Role</label>
        <select class="form-control" [(ngModel)]="role">
          <option [ngValue]="eAccessControlListRoleType.HierarchyAdmin">Admin</option>
          <option [ngValue]="eAccessControlListRoleType.HierarchyStaff">Staff</option>
        </select>
      </div>
    </div>

    <!-- <div class="col-6" *ngIf="role === eAccessControlListRoleType.HierarchyAdmin"> -->
    <div class="col-6">
      <div class="form-group">
        <label class="form-control-label">Primary</label>
        <select [disabled]="lockPrimary" class="form-control" [(ngModel)]="primary">
          <option [ngValue]="true">True</option>
          <option [ngValue]="false">False</option>
        </select>
        <label *ngIf="lockPrimary" class="help-text">There must be at least one primary admin per hierarchy
          area.</label>
        <label *ngIf="!lockPrimary && primary" class="help-text">This will change the primary admin to this profile and
          remove it from the current primary for this hierarchy area.</label>
      </div>
    </div>
  </div>
  <div class="row">
    <form [formGroup]="permissionForm">
      <div class="row">
        <ng4h-permission-input *ngFor="let permissionType of permissionTypes$ | async" class="col-6"
          [formControlName]="permissionType.permission" [label]="permissionType.caption">
        </ng4h-permission-input>
      </div>
    </form>
  </div>

</div>
<div class="modal-footer">
  <button [disabled]="permissionForm.invalid" (click)="save()" class="btn btn-primary my-2 my-sm-0" role="button"
    aria-pressed="true">Save</button>
  <button class="btn btn-secondary" (click)="close()">Close</button>
</div>