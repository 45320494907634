import { ICreditDescriptorDto, IHierarchyNodeDto, IUnitDto } from '..';

export interface ICreditDto {
    memberId: string;
    enrollmentId: string;
    registrationId: string;
    eventId: string;
    firstName?: string;
    lastName?: string;
    credits?: ICreditDescriptorDto[];
    eventName?: string;
    countyArea: IHierarchyNodeDto;
    primaryUnit?: IUnitDto;
    totalDue: number;
}
