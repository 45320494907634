import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { SystemManagerEnrollmentActions } from 'app/core/containers/admin/system-manager/enrollment/store';
import {
  getSystemManagerClubInvoices,
  getSystemManagerEnrollmentConsents,
  getSystemManagerInvoices,
  SystemManagerFeatureStore
} from 'app/core/containers/admin/system-manager/system-manager-feature.reducers';
import {
  IEnrollmentHierarchyInvoiceItemsDto,
  IEnrollmentInvoiceItemSubmitDto,
  IInstitutionSystemManagerProgramParams
} from 'app/core/models';
import { IEnrollmentConsentTypeDto } from 'app/core/models/serverDTOs/IEnrollmentConsentTypeDto';
import { filter, map, Observable, of, take, tap } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SystemManagerEnrollmentsService {
  constructor(
    private httpClient: HttpClient,
    private store: Store<SystemManagerFeatureStore>,
    private dispatcher: ActionsSubject
  ) {

  }

  public getEnrollmentConsents(params: IInstitutionSystemManagerProgramParams): Observable<IEnrollmentConsentTypeDto[]> {

    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getSystemManagerEnrollmentConsents(params)),
      tap(enrollment => {
        if (enrollment === undefined) {
          this.store.dispatch(SystemManagerEnrollmentActions.SystemManagerLoadEnrollmentAction(params));
        }
      }),
      filter(enrollment => enrollment != null)
    );
  }

  public loadEnrollmentConsentsEffect({
    institutionId,
    systemManagerId,
    programId
  }: IInstitutionSystemManagerProgramParams) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/enrollment-consents`) as Observable<IEnrollmentConsentTypeDto[]>;
  }

  public addEnrollmentConsent(params: IInstitutionSystemManagerProgramParams & { enrollmentRole: string, consentTypeId: string }) {
    this.store.dispatch(SystemManagerEnrollmentActions.SystemManagerAddEnrollmentAction(params));

    return this.dispatcher.pipe(
      ofType(SystemManagerEnrollmentActions.SystemManagerAddEnrollmentSuccessAction, SystemManagerEnrollmentActions.SystemManagerAddEnrollmentErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerEnrollmentActions.SystemManagerAddEnrollmentSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public addEnrollmentConsentEffect({
    institutionId,
    systemManagerId,
    programId,
    enrollmentRole,
    consentTypeId
  }: IInstitutionSystemManagerProgramParams & { enrollmentRole: string, consentTypeId: string }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/enrollment-consents/${enrollmentRole}?consentTypeId=${consentTypeId}`, {});
  }

  public deleteEnrollmentConsent({
    institutionId,
    systemManagerId,
    programId,
    enrollmentRole,
    consentTypeId
  }: IInstitutionSystemManagerProgramParams & { enrollmentRole: string, consentTypeId: string }) {
    this.store.dispatch(SystemManagerEnrollmentActions.SystemManagerDeleteEnrollmentAction({
      institutionId,
      systemManagerId,
      programId,
      enrollmentRole: enrollmentRole,
      consentTypeId
    }));

    return this.dispatcher.pipe(
      ofType(SystemManagerEnrollmentActions.SystemManagerDeleteEnrollmentSuccessAction, SystemManagerEnrollmentActions.SystemManagerDeleteEnrollmentErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerEnrollmentActions.SystemManagerDeleteEnrollmentSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteEnrollmentConsentEffect({
    institutionId,
    systemManagerId,
    programId,
    enrollmentRole,
    consentTypeId
  }: IInstitutionSystemManagerProgramParams & { enrollmentRole: string, consentTypeId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/enrollment-consents/${enrollmentRole}?consentTypeId=${consentTypeId}`);
  }

  public getSystemManagerInvoices(params: IInstitutionSystemManagerProgramParams & { programYearId: string }): Observable<IEnrollmentHierarchyInvoiceItemsDto[]> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getSystemManagerInvoices(params)),
      tap(invoices => {
        if (invoices === undefined) {
          this.store.dispatch(SystemManagerEnrollmentActions.SystemManagerLoadInvoicesAction(params));
        }
      }),
      filter(invoices => invoices != null)
    );
  }

  public loadSystemManagerInvoicesEffect({
    institutionId,
    systemManagerId,
    programId,
    programYearId
  }: IInstitutionSystemManagerProgramParams & { programYearId: string }): Observable<IEnrollmentHierarchyInvoiceItemsDto[]> {

    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/invoice-items/${programYearId}`)
    .pipe(filter(data => data !== null)) as Observable<IEnrollmentHierarchyInvoiceItemsDto[]>;
  }

  public updateInvoice(params: IInstitutionSystemManagerProgramParams & { programYearId: string, hierarchyNodeId: string, invoice: IEnrollmentInvoiceItemSubmitDto }) {
    this.store.dispatch(SystemManagerEnrollmentActions.SystemManagerUpdateInvoiceAction(params));

    return this.dispatcher.pipe(
      ofType(SystemManagerEnrollmentActions.SystemManagerUpdateInvoiceSuccessAction, SystemManagerEnrollmentActions.SystemManagerUpdateInvoiceErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerEnrollmentActions.SystemManagerUpdateInvoiceSuccessAction.type) {
          return action.invoice;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateInvoiceEffect({
    institutionId,
    systemManagerId,
    programId,
    programYearId,
    hierarchyNodeId,
    invoice
  }: IInstitutionSystemManagerProgramParams & { programYearId: string, hierarchyNodeId: string, invoice: IEnrollmentInvoiceItemSubmitDto }): Observable<IEnrollmentHierarchyInvoiceItemsDto> {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/invoice-items/${programYearId}?hierarchyNodeId=${hierarchyNodeId}`, invoice) as Observable<IEnrollmentHierarchyInvoiceItemsDto>;
  }

  public getSystemManagerClubInvoices(params: IInstitutionSystemManagerProgramParams & { countyAreaId: string, programYearId: string }): Observable<IEnrollmentHierarchyInvoiceItemsDto[]> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getSystemManagerClubInvoices(params)),
      tap(invoices => {
        if (invoices === undefined) {
          this.store.dispatch(SystemManagerEnrollmentActions.SystemManagerLoadClubInvoicesAction(params));
        }
      }),
      filter(invoices => invoices != null)
    );
  }

  public loadSystemManagerClubInvoicesEffect({
    institutionId,
    systemManagerId,
    programId,
    countyAreaId,
    programYearId
  }: IInstitutionSystemManagerProgramParams & { countyAreaId: string, programYearId: string }): Observable<IEnrollmentHierarchyInvoiceItemsDto[]> {

    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/county-areas/${countyAreaId}/club-invoice-items/${programYearId}`)
    .pipe(filter(data => data !== null)) as Observable<IEnrollmentHierarchyInvoiceItemsDto[]>;
  }

  public updateClubInvoice(params: IInstitutionSystemManagerProgramParams & { programYearId: string, countyAreaId: string, unitId: string, invoice: IEnrollmentInvoiceItemSubmitDto }) {
    this.store.dispatch(SystemManagerEnrollmentActions.SystemManagerUpdateClubInvoiceAction(params));

    return this.dispatcher.pipe(
      ofType(SystemManagerEnrollmentActions.SystemManagerUpdateClubInvoiceSuccessAction, SystemManagerEnrollmentActions.SystemManagerUpdateClubInvoiceErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerEnrollmentActions.SystemManagerUpdateClubInvoiceSuccessAction.type) {
          return action.invoice;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateClubInvoiceEffect({
    institutionId,
    systemManagerId,
    programId,
    programYearId,
    countyAreaId,
    unitId,
    invoice
  }: IInstitutionSystemManagerProgramParams & { programYearId: string, countyAreaId: string, unitId: string, invoice: IEnrollmentInvoiceItemSubmitDto }): Observable<IEnrollmentHierarchyInvoiceItemsDto> {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/county-areas/${countyAreaId}/club-invoice-items/${programYearId}?unitId=${unitId}`, invoice) as Observable<IEnrollmentHierarchyInvoiceItemsDto>;
  }
}
