import { eDeliveryModeType } from '../enums';
import { IDeliveryModeCreateDto } from './IDeliveryModeCreateDto';


export interface IDeliveryModeDto extends IDeliveryModeCreateDto {
  deliveryModeId: string;
  parentType?: eDeliveryModeType;
  sequence?: string;
  type: eDeliveryModeType;
  description?: string;
  parentTypeDescription?: string;
  displayLabel?: string;
  system: boolean;
  deleted: boolean;
  typeDescription?: string;
  availableForGroupEnrollment?: boolean;
}
