import { ValidatorFn, AbstractControl } from '@angular/forms';

export function decimalPrecisionValidator(precision: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (control.value === null) {
      return null;
    }
    return !isNaN(control.value - parseFloat(control.value)) ? { 'decimalPrecision': { value: control.value } } : null;
  };
}
