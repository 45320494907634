import {
  eProfileVolunteerScreeningStatus,
  eScreeningSource,
  eScreeningType,
  IMemberProgramConsentDto,
  IProgramYearDto,
  IStandardFormColumnDto,
} from '..';


export interface IProfileVolunteerScreeningDto {
  memberId: string;
  profileScreeningBatchId: string;
  firstName?: string;
  lastName?: string;
  county?: string;
  dateSubmitted?: string;
  consent: IMemberProgramConsentDto;
  restrictions?: string;
  managerComment?: string;
  dateApproved?: string;
  programYearApproved: string;
  source: eScreeningSource;
  fields?: {
    [category: string]: IStandardFormColumnDto[]
  };
  profileScreeningId: string;
  screeningId: string;
  status: eProfileVolunteerScreeningStatus;
  type: eScreeningType;
  canDelete: boolean;

  batchNumber?: number;

  comment?: string;
  programId: string;

  nextScreeningYear: string;
  nextScreeningYearRange: IProgramYearDto[];

}
