import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  Input,
  Renderer2,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { AutoUnsubscribe, eHierarchyNodeType } from 'app/core/models';
import { HierarchyService } from 'app/core/services';
import { combineLatest, filter, takeUntil } from 'rxjs';

import { DebugService } from '../developer-tools/debug.service';

export class ManagerHierarchyTypeDirectiveContext {
  public $implicit: eHierarchyNodeType | eHierarchyNodeType[] = null;
  public ng4hManagerHierarchyNodeType: eHierarchyNodeType | eHierarchyNodeType[] = null;
}

@Directive({
  selector: '[ng4hManagerHierarchyType]'
})
export class ManagerHierarchyTypeDirective extends AutoUnsubscribe {

  private context: ManagerHierarchyTypeDirectiveContext = new ManagerHierarchyTypeDirectiveContext();
  private thenViewRef: EmbeddedViewRef<any> | null;

  @Input('ng4hManagerHierarchyType')
  set ng4hManagerPermissions(managerPermissions: eHierarchyNodeType | eHierarchyNodeType[]) {
    this.context.$implicit = this.context.ng4hManagerHierarchyNodeType = managerPermissions;
    this.thenViewRef = null;  // clear previous view if any.
    this.updateView();
  }

  constructor(
    private elRef: ElementRef,
    private thenTemplateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private hierarchyService: HierarchyService,
    private cdr: ChangeDetectorRef,
    private debugService: DebugService,
    private renderer: Renderer2
  ) {
    super();
  }

  private updateView() {

    combineLatest([this.hierarchyService.managerHierarchyNode$, this.debugService.debugMode]).pipe(
      filter(([managerHierarchyNode, debugMode]) => managerHierarchyNode != null),
      takeUntil(this.autoUnsubscribe)
    ).subscribe(([managerHierarchyNode, debugMode]) => {
      this.thenViewRef = null;  // clear previous view if any.
      if (debugMode ||
        this.context.ng4hManagerHierarchyNodeType === managerHierarchyNode.hierarchyNodeType
        || (Array.isArray(this.context.ng4hManagerHierarchyNodeType) && this.context.ng4hManagerHierarchyNodeType.includes(managerHierarchyNode.hierarchyNodeType))) {
        // if (this.thenViewRef == null) {
        this.viewContainer.clear();
        if (this.thenTemplateRef) {
          this.thenViewRef =
            this.viewContainer.createEmbeddedView(this.thenTemplateRef, this.context);
        }

      } else {
        this.viewContainer.clear();
      }
      if (debugMode && this.elRef.nativeElement?.previousElementSibling?.style != null) {
        const div = this.renderer.createElement('div');
        div.innerHTML = JSON.stringify(this.context.ng4hManagerHierarchyNodeType);
        div.style.fontSize = '8pt';
        this.renderer.appendChild(this.elRef.nativeElement.previousElementSibling, div);
        this.elRef.nativeElement.previousElementSibling.style.background = 'green';
        this.elRef.nativeElement.previousElementSibling.style.border = '2px solid darkgreen';
        this.elRef.nativeElement.previousElementSibling.style.padding = '5px';
        this.elRef.nativeElement.previousElementSibling.style.margin = '2px';
      }
      this.cdr.markForCheck();
    });
  }
}
