import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { EventsManagerService } from 'app/core/services';
import { catchError, exhaustMap, groupBy, map, mergeMap, Observable, of } from 'rxjs';

import { EventsManagerActions } from '.';
import { stringKeys } from '../rxjs.utils';

@Injectable()
export class EventsManagerEffects {

  public getEvent$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.EventsManagerLoadAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.eventsService.loadEventEffect(payload).pipe(
            map(event => EventsManagerActions.EventsManagerLoadSuccessAction({ ...payload, event })),
            catchError(error => of(EventsManagerActions.EventsManagerLoadErrorAction({ error })))
          ))
      ))
  ));

  public getEvents$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.EventsManagerLoadManyAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.eventsService.loadEventsEffect(payload).pipe(
            map(events => events == null ? [] : events),
            map(events => EventsManagerActions.EventsManagerLoadManySuccessAction({ ...payload, events: events })),
            catchError(error => of(EventsManagerActions.EventsManagerLoadManyErrorAction({ error })))
          ))
      ))
  ));

  public getEventConsents$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.EventsManagerLoadConsentsAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.eventsService.loadEventConsentsEffect(payload).pipe(
            map(consents => EventsManagerActions.EventsManagerLoadConsentsSuccessAction({
              ...payload,
              consents: consents
            }))
          ))
      ))
  ));

  public createEvent$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.EventsManagerCreateAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.eventsService.createEventEffect(payload).pipe(
            map(event => EventsManagerActions.EventsManagerCreateSuccessAction({ ...payload, event })),
            catchError(error => of(EventsManagerActions.EventsManagerCreateErrorAction({ error })))
          ))
      ))
  ));

  public copyEvent$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.EventsManagerCopyAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.eventsService.copyEventEffect(payload).pipe(
            map(event => EventsManagerActions.EventsManagerCopySuccessAction({ ...payload, event })),
            catchError(error => of(EventsManagerActions.EventsManagerCopyErrorAction({ error })))
          ))
      ))
  ));

  public createEventSuccess$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.EventsManagerCreateSuccessAction,
      EventsManagerActions.EventsManagerSendBackSuccessAction,
      EventsManagerActions.EventsManagerBlockSuccessAction,
      EventsManagerActions.EventsManagerApproveSuccessAction,
      EventsManagerActions.CancelRegistrationSuccessAction,
      EventsManagerActions.EventsManagerCopySuccessAction
    ),

    map(() => EventsManagerActions.EventsManagerInvalidateEventsCacheAction())
  ));

  public invalidateCache$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(
      EventsManagerActions.EventsManagerCreateRegistrationTypeSuccessAction,
      EventsManagerActions.EventsManagerUpdateRegistrationTypeSuccessAction,
      EventsManagerActions.EventsManagerUpdateSuccessAction,
      EventsManagerActions.EventsManagerAddConsentSuccessAction,
      EventsManagerActions.EventsManagerDeleteConsentAction,
      EventsManagerActions.EventsManagerUpdateEventSuccessPermissions,
      EventsManagerActions.RemoveManagerFromEventSuccessPermissions,
      EventsManagerActions.UpdateEventEmailConfigurationSuccessAction,
      EventsManagerActions.EventsManagerAddVanityDomainSuccessAction,
      EventsManagerActions.EventsManagerUpdateVanityDomainSuccessAction,
      EventsManagerActions.EventsManagerDeleteVanityDomainSuccessAction,
      EventsManagerActions.EventsManagerUpdateStatusSuccessAction,
      EventsManagerActions.EventsManagerUpdateInstructionsSuccessAction
    ),
    map(() => EventsManagerActions.EventsManagerInvalidateEventCacheAction())
  ));

  public updateEvent$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.EventsManagerUpdateAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.eventsService.updateEventEffect(payload).pipe(
            map(event => EventsManagerActions.EventsManagerUpdateSuccessAction({ ...payload, event })),
            catchError(error => of(EventsManagerActions.EventsManagerUpdateErrorAction({ error })))
          ))
      ))
  ));
  public updateEventInstructions$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.EventsManagerUpdateInstructionsAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.eventsService.updateEventInstructionsEffect(payload).pipe(
            map(event => EventsManagerActions.EventsManagerUpdateInstructionsSuccessAction({ ...payload, event })),
            catchError(error => of(EventsManagerActions.EventsManagerUpdateErrorAction({ error })))
          ))
      ))
  ));
  public updateEventStatus$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.EventsManagerUpdateStatusAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.eventsService.updateEventStatusEffect(payload).pipe(
            map(event => EventsManagerActions.EventsManagerUpdateStatusSuccessAction({ ...payload, event })),
            catchError(error => of(EventsManagerActions.EventsManagerUpdateErrorAction({ error })))
          ))
      ))
  ));
  public createRegistrationType$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.EventsManagerCreateRegistrationTypeAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.eventsService.createRegistrationTypeEffect(payload).pipe(
            map(event => EventsManagerActions.EventsManagerCreateRegistrationTypeSuccessAction(payload)),
            catchError(error => of(EventsManagerActions.EventsManagerCreateRegistrationTypeErrorAction({ error })))
          ))
      ))
  ));

  public updateRegistrationType$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.EventsManagerUpdateRegistrationTypeAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.eventsService.updateRegistrationTypeEffect(payload).pipe(
            map(event => EventsManagerActions.EventsManagerUpdateRegistrationTypeSuccessAction(payload)),
            catchError(error => of(EventsManagerActions.EventsManagerUpdateRegistrationTypeErrorAction({ error })))
          ))
      ))
  ));

  // public getSubmittedEventRegistrations$: Observable<Action> = createEffect(() => this.actions.pipe(
  //     ofType(EventsManagerActions.EventsManagerLoadSubmittedRegistrationsAction),
  //     groupBy(stringKeys),
  //     mergeMap(groupedBy => {
  //         return groupedBy.pipe(
  //             exhaustMap(payload => {
  //                 return this.eventsService.loadSubmittedEventRegistrationsEffect(payload).pipe(
  //                     map(submittedEventRegistrations => submittedEventRegistrations == null ? [] : submittedEventRegistrations),
  //                     map(submittedEventRegistrations => EventsManagerActions.EventsManagerLoadSubmittedRegistrationsSuccessAction({ ...payload, submittedEventRegistrations })),
  //                     catchError(error => of(EventsManagerActions.EventsManagerLoadSubmittedRegistrationsErrorAction({ error })))
  //                 );
  //             })
  //         );
  //     })
  // ));

  public getEventRegistrations$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.EventsManagerLoadEventRegistrations),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.eventsService.loadEventRegistrationsEffect(payload).pipe(
            map(registrations => EventsManagerActions.EventsManagerLoadEventRegistrationsSuccess({
              ...payload,
              eventRegistrations: registrations
            })),
            catchError(error => of(EventsManagerActions.EventsManagerLoadEventRegistrationsError({ error })))
          ))
      ))
  ));

  public getCrossEventRegistrations$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.EventsManagerLoadCrossEventRegistrations),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.eventsService.crossEventSearchResultsEffect(payload).pipe(
            map(registrations => EventsManagerActions.EventsManagerLoadCrossEventRegistrationsSuccess({
              ...payload,
              eventRegistrations: registrations
            })),
            catchError(error => of(EventsManagerActions.EventsManagerLoadCrossEventRegistrationsError({ error })))
          ))
      ))
  ));

  public approveEventsManager$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.EventsManagerApproveAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.eventsService.approveEffect(payload).pipe(
            map(() => EventsManagerActions.EventsManagerApproveSuccessAction(payload)),
            catchError(error => of(EventsManagerActions.EventsManagerApproveErrorAction({ error })))
          ))
      ))
  ));

  public sendBackEventsManager$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.EventsManagerSendBackAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.eventsService.sendBackEffect(payload).pipe(
            map(() => EventsManagerActions.EventsManagerSendBackSuccessAction(payload)),
            catchError(error => of(EventsManagerActions.EventsManagerSendBackErrorAction({ error })))
          ))
      ))
  ));

  public blockEventsManager$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.EventsManagerBlockAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.eventsService.blockEffect(payload).pipe(
            map(() => EventsManagerActions.EventsManagerBlockSuccessAction(payload)),
            catchError(error => of(EventsManagerActions.EventsManagerBlockErrorAction({ error })))
          ))
      ))
  ));

  public addEventConsents$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.EventsManagerAddConsentAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.eventsService.addEventConsentEffect(payload).pipe(
            map(() => EventsManagerActions.EventsManagerAddConsentSuccessAction(payload)),
            catchError(error => of(EventsManagerActions.EventsManagerAddConsentErrorAction({ error })))
          ))
      ))
  ));

  public deleteEventConsent$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.EventsManagerDeleteConsentAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.eventsService.deleteEventConsentEffect(payload).pipe(
            map(() => EventsManagerActions.EventsManagerDeleteConsentSuccessAction(payload)),
            catchError(error => of(EventsManagerActions.EventsManagerDeleteConsentErrorAction({ error })))
          ))
      ))
  ));

  public invalidateSubmittedEventRegistrationsAfterManagerReview$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.EventsManagerApproveAction, EventsManagerActions.EventsManagerSendBackAction, EventsManagerActions.EventsManagerBlockAction),
    map(payload => EventsManagerActions.EventsManagerInvalidateEventRegistrationsCacheAction())
  ));

  public updateEventPermissions$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.EventsManagerUpdateEventPermissions),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.eventsService.updateEventPermissionsEffect(payload).pipe(
            map(() => EventsManagerActions.EventsManagerUpdateEventSuccessPermissions(payload)),
            catchError(error => of(EventsManagerActions.EventsManagerUpdateEventErrorPermissions({ error })))
          ))
      ))
  ));
  public removeManager$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.RemoveManagerFromEventPermissions),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.eventsService.removeManagerFromEventEffect(payload).pipe(
            map(() => EventsManagerActions.RemoveManagerFromEventSuccessPermissions(payload)),
            catchError(error => of(EventsManagerActions.RemoveManagerFromEventErrorPermissions({ error })))
          ))
      ))
  ));

  public registrationSearch$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.EventRegistrationSearchAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.eventsService.eventRegistrationSearchEffect(payload).pipe(
            map(registrations => EventsManagerActions.EventRegistrationSearchSuccessAction({
              ...payload,
              eventRegistrations: registrations
            })),
            catchError(error => of(EventsManagerActions.EventRegistrationSearchErrorAction({ error })))
          ))
      ))
  ));

  public crossRegistrationSearch$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.CrossEventRegistrationSearchAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.eventsService.crossEventSearchEffect(payload).pipe(
            map(registrations => EventsManagerActions.CrossEventRegistrationSearchSuccessAction({
              ...payload,
              eventRegistrations: registrations
            })),
            catchError(error => of(EventsManagerActions.CrossEventRegistrationSearchErrorAction({ error })))
          ))
      ))
  ));

  public registrationClearSearch$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.EventRegistrationClearSearchAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.eventsService.eventRegistrationClearSearchEffect(payload).pipe(
            map(() => EventsManagerActions.EventRegistrationClearSearchSuccessAction(payload)),
            catchError(error => of(EventsManagerActions.EventRegistrationClearSearchErrorAction({ error })))
          ))
      ))
  ));

  public crossRegistrationClearSearch$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.CrossEventRegistrationClearSearchAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.eventsService.crossEventClearSearchEffect(payload).pipe(
            map(() => EventsManagerActions.CrossEventRegistrationClearSearchSuccessAction(payload)),
            catchError(error => of(EventsManagerActions.CrossEventRegistrationClearSearchErrorAction({ error })))
          ))
      ))
  ));

  public clearSearchSuccess$ = createEffect(() => this.actions.pipe(
    ofType(
      EventsManagerActions.CrossEventRegistrationClearSearchSuccessAction),
    map(payload =>
      EventsManagerActions.EventsManagerLoadCrossEventRegistrations({
        ...payload,
        pagesize: '50',
        pageindex: '1'
      }))
  ));

  public deleteEvent$ = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.EventsManagerDeleteEventAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.eventsService.deleteEventEffect(payload).pipe(
            map(() => EventsManagerActions.EventsManagerDeleteEventSuccessAction(payload)),
            catchError(error => of(EventsManagerActions.EventsManagerDeleteEventErrorAction({ error })))
          ))
      ))
  ));

  public deleteRegistrationType$ = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.EventsManagerDeleteRegistrationTypeAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.eventsService.deleteEventRegistrationTypeEffect(payload).pipe(
            map(() => EventsManagerActions.EventsManagerDeleteRegistrationTypeSuccessAction(payload)),
            catchError(error => of(EventsManagerActions.EventsManagerDeleteRegistrationTypeErrorAction({ error })))
          ))
      ))
  ));

  public deleteRegistrationTypeSuccess$ = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.EventsManagerDeleteRegistrationTypeSuccessAction),
    map(payload => EventsManagerActions.EventsManagerInvalidateEventRegistrationsCacheAction())
  ));

  public createEventSession$ = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.EventsManagerCreateSessionAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.eventsService.createEventSessionEffect(payload).pipe(
            map(() => EventsManagerActions.EventsManagerCreateSessionSuccessAction(payload)),
            catchError(error => of(EventsManagerActions.EventsManagerCreateSessionErrorAction({ error })))
          ))
      ))
  ));

  public updateEventSession$ = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.EventsManagerUpdateSessionAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.eventsService.updateEventSessionEffect(payload).pipe(
            map(() => EventsManagerActions.EventsManagerUpdateSessionSuccessAction(payload)),
            catchError(error => of(EventsManagerActions.EventsManagerUpdateSessionErrorAction({ error })))
          ))
      ))
  ));

  public deleteEventSession$ = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.EventsManagerDeleteSessionAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.eventsService.deleteEventSessionEffect(payload).pipe(
            map(() => EventsManagerActions.EventsManagerDeleteSessionSuccessAction(payload)),
            catchError(error => of(EventsManagerActions.EventsManagerDeleteSessionErrorAction({ error })))
          ))
      ))
  ));

  public getOutstandingCredits$ = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.GetOutstandingCreditAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.eventsService.getOutstandingCreditsEffect(payload).pipe(
            map(outstandingCredits => outstandingCredits == null ? [] : outstandingCredits),
            map(outstandingCredits => EventsManagerActions.GetOutstandingCreditSuccessAction({
              ...payload,
              outstandingCredits
            })),
            catchError(error => of(EventsManagerActions.GetOutstandingCreditErrorAction({ error })))
          ))
      ))
  ));

  public getOutstandingCountyCredits$ = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.GetOutstandingCountyCreditsAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.eventsService.getCountyOutstandingCreditsEffect(payload).pipe(
            map(outstandingCredits => outstandingCredits == null ? [] : outstandingCredits),
            map(outstandingCredits => EventsManagerActions.GetOutstandingCountyCreditsSuccessAction({
              ...payload,
              outstandingCredits
            })),
            catchError(error => of(EventsManagerActions.GetOutstandingCountyCreditsErrorAction({ error })))
          ))
      ))
  ));

  public confirmCreditCollected$ = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.ConfirmCreditCollectedAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.eventsService.confirmCreditCollectedEffect(payload).pipe(
            map(() => EventsManagerActions.ConfirmCreditCollectedSuccessAction(payload)),
            catchError(error => of(EventsManagerActions.ConfirmCreditCollectedErrorAction({ error })))
          ))
      ))
  ));

  public createCountyBill$ = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.CreateCountyBillForEventAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.eventsService.createCountyBillForEventEffect(payload).pipe(
            map(() => EventsManagerActions.CreateCountyBillForEventSuccessAction(payload)),
            catchError(error => of(EventsManagerActions.CreateCountyBillForEventErrorAction({ error })))
          ))
      ))
  ));

  public getEventSearchFileDownload$ = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.GetEventSearchFileDownloadAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.eventsService.getEventSearchFileDownloadEffect(payload).pipe(
            map(() => EventsManagerActions.GetEventSearchFileDownloadSuccessAction(payload)),
            catchError(error => of(EventsManagerActions.GetEventSearchFileDownloadErrorAction({ error })))
          ))
      ))
  ));
  public updateEventHierarchyRestrictions$ = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.UpdateEventHierarchyRestrictionsAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.eventsService.updateEventHierarchyRestrictionsEffect(payload).pipe(
            map(() => EventsManagerActions.UpdateEventHierarchyRestrictionsSuccessAction(payload)),
            catchError(error => of(EventsManagerActions.UpdateEventHierarchyRestrictionsErrorAction({ error })))
          ))
      ))
  ));
  public createCountyBillFromCredits$ = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.CreateCountyBillFromCreditsAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.eventsService.createCountyBillFromCreditsEffect(payload).pipe(
            map(() => EventsManagerActions.CreateCountyBillFromCreditsSuccessAction(payload)),
            catchError(error => of(EventsManagerActions.CreateCountyBillFromCreditsErrorAction({ error })))
          ))
      ))
  ));
  public refundPayment$ = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.RefundPaymentAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.eventsService.refundPaymentEffect(payload).pipe(
            map(() => EventsManagerActions.RefundPaymentSuccessAction(payload)),
            catchError(error => of(EventsManagerActions.RefundPaymentErrorAction({ error })))
          ))
      ))
  ));

  public cancelRegistration$ = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.CancelRegistrationAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.eventsService.cancelRegistrationEffect(payload).pipe(
            map(() => EventsManagerActions.CancelRegistrationSuccessAction(payload)),
            catchError(error => of(EventsManagerActions.CancelRegistrationErrorAction({ error })))
          ))
      ))
  ));
  public getEventCoupons$ = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.GetEventCouponsAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.eventsService.getEventCouponsEffect(payload).pipe(
            map(coupons => EventsManagerActions.GetEventCouponsSuccessAction({ ...payload, coupons })),
            catchError(error => of(EventsManagerActions.GetEventCouponsErrorAction({ error })))
          ))
      ))
  ));
  public getEventCoupon$ = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.GetEventCouponAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.eventsService.getEventCouponEffect(payload).pipe(
            map(coupon => EventsManagerActions.GetEventCouponSuccessAction({ ...payload, coupon })),
            catchError(error => of(EventsManagerActions.GetEventCouponErrorAction({ error })))
          ))
      ))
  ));
  public deleteEventCoupon$ = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.DeleteEventCouponAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.eventsService.deleteEventCouponEffect(payload).pipe(
            map(() => EventsManagerActions.DeleteEventCouponSuccessAction(payload)),
            catchError(error => of(EventsManagerActions.DeleteEventCouponErrorAction({ error })))
          ))
      ))
  ));
  public updateEventCoupon$ = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.UpdateEventCouponAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.eventsService.updateEventCouponEffect(payload).pipe(
            map(() => EventsManagerActions.UpdateEventCouponSuccessAction(payload)),
            catchError(error => of(EventsManagerActions.UpdateEventCouponErrorAction({ error })))
          ))
      ))
  ));
  public createEventCoupon$ = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.CreateEventCouponAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.eventsService.createEventCouponEffect(payload).pipe(
            map(coupon => EventsManagerActions.CreateEventCouponSuccessAction({ ...payload, coupon })),
            catchError(error => of(EventsManagerActions.CreateEventCouponErrorAction({ error })))
          ))
      ))
  ));
  public createGroupEnrollment$ = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.EventsManagerCreateGroupEnrollmentAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.eventsService.createGroupEnrollmentEffect(payload).pipe(
            map(() => EventsManagerActions.EventsManagerCreateGroupEnrollmentSuccessAction(payload)),
            catchError(error => of(EventsManagerActions.EventsManagerCreateGroupEnrollmentErrorAction({ error })))
          ))
      ))
  ));
  public getGroupEnrollment$ = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.EventsManagerGetGroupEnrollmentAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.eventsService.getEventGroupEnrollmentsEffect(payload).pipe(
            map(groupEnrollments => EventsManagerActions.EventsManagerGetGroupEnrollmentSuccessAction({
              ...payload,
              groupEnrollments
            })),
            catchError(error => of(EventsManagerActions.EventsManagerGetGroupEnrollmentErrorAction({ error })))
          ))
      ))
  ));

  public updateEmailConfig$ = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.UpdateEventEmailConfigurationAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.eventsService.updateEventEmailConfigurationEffect(payload).pipe(
            map(() => EventsManagerActions.UpdateEventEmailConfigurationSuccessAction(payload)),
            catchError(error => of(EventsManagerActions.UpdateEventEmailConfigurationErrorAction({ error })))
          ))
      ))
  ));

  public addVanityDomain$ = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.EventsManagerAddVanityDomainAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.eventsService.addVanityDomainEffect(payload).pipe(
            map(() => EventsManagerActions.EventsManagerAddVanityDomainSuccessAction(payload)),
            catchError(error => of(EventsManagerActions.EventsManagerAddVanityDomainErrorAction({ error })))
          ))
      ))
  ));

  public updateVanityDomain$ = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.EventsManagerUpdateVanityDomainAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.eventsService.updateVanityDomainEffect(payload).pipe(
            map(() => EventsManagerActions.EventsManagerUpdateVanityDomainSuccessAction(payload)),
            catchError(error => of(EventsManagerActions.EventsManagerUpdateVanityDomainErrorAction({ error })))
          ))
      ))
  ));

  public deleteVanityDomain$ = createEffect(() => this.actions.pipe(
    ofType(EventsManagerActions.EventsManagerDeleteVanityDomainAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.eventsService.deleteVanityDomainEffect(payload).pipe(
            map(() => EventsManagerActions.EventsManagerDeleteVanityDomainSuccessAction(payload)),
            catchError(error => of(EventsManagerActions.EventsManagerDeleteVanityDomainErrorAction({ error })))
          ))
      ))
  ));

  constructor(private actions: Actions, private eventsService: EventsManagerService) {
  }
}
