import { ChangeDetectorRef, Directive, EmbeddedViewRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { eProfileType } from 'app/core/models';
import { ProfileService } from 'app/core/services';
import { filter, take } from 'rxjs';

export class ProfileTypeDirectiveContext {
  public $implicit: eProfileType | eProfileType[] = null;
  public ng4hProfileType: eProfileType | eProfileType[] = null;
}

@Directive({
  selector: '[ng4hProfileType]'
})
export class ProfileTypesDirective {

  private context: ProfileTypeDirectiveContext = new ProfileTypeDirectiveContext();
  private thenViewRef: EmbeddedViewRef<any> | null;

  @Input('ng4hProfileType')
  set ng4hProfileType(managerPermissions: eProfileType | eProfileType[]) {
    this.context.$implicit = this.context.ng4hProfileType = managerPermissions;
    this.thenViewRef = null;  // clear previous view if any.
    this.updateView();
  }

  constructor(
    private thenTemplateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private profileService: ProfileService,
    private cdr: ChangeDetectorRef
  ) {

  }

  private updateView() {
    this.profileService.loggedInAsInstitutionProfile.pipe(
      filter(profile => profile != null), take(1)).subscribe(profile => {
      if (profile.profileType != null &&
        (profile.profileType === this.context.ng4hProfileType ||
          (Array.isArray(this.context.ng4hProfileType) && this.context.ng4hProfileType.includes(profile.profileType)))
      ) {
        if (this.thenViewRef == null) {
          this.viewContainer.clear();
          if (this.thenTemplateRef) {
            this.thenViewRef =
              this.viewContainer.createEmbeddedView(this.thenTemplateRef, this.context);
          }
        }
      } else {
        this.viewContainer.clear();
      }
      this.cdr.markForCheck();
    });
  }
}
