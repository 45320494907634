import { eProfileType } from './enums/eProfileType';
import { IInstitutionProfile } from './serverDTOs';

export interface IFamilyInstitutionProfile extends IInstitutionProfile {

    familyId: string;
    familyName: string;
    addressVerified: boolean;
    countyArea: {
        countyAreaId: string;
        counties: [
            {
                countyId: string;
                countyName: string;
                state: string
            }
        ];
        hierarchyNodeId: string;
        hierarchyNodeType: string;
        hierarchyNodeName: string;
    };
    county: {
        countyId: string;
        countyName: string;
        state: string;
    };
    familyIdent: number;
    profileId: string;
    profileType: eProfileType.Family;
    email: string;
    institutionId: string;
    institutionProfileId: string;
    profileToken: string;
    institutionProfileIdent: number;
    institutionName: string;
    countyHierarchyNodeId?: string;
}
