import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';
import {
  getEnrollmentSearchSearching,
  getEnrollmentsSearchResult,
  ManagerFeatureStore
} from 'app/core/containers/admin/manager/manager-feature.reducer';
import { EnrollmentSearchActions } from 'app/core/containers/admin/manager/store/searches/enrollment';
import { IInstitutionManagerProgramHierarchyParams, IMemberSearchParametersDto } from 'app/core/models';
import { IMemberSearchResultsDto } from 'app/core/models/serverDTOs/IMemberSearchResultsDto';
import { anyNull } from 'app/shared/utils';
import { Observable, switchMap } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnrollmentSearchService {

  public enrollmentsSearchResult$: Observable<IMemberSearchResultsDto>;
  public enrollmentSearchSearching$: Observable<boolean>;

  constructor(private store: Store<ManagerFeatureStore>, private httpClient: HttpClient, private dispatcher: ActionsSubject) {
    this.enrollmentSearchSearching$ = this.store.pipe(select(getEnrollmentSearchSearching));
    this.enrollmentsSearchResult$ = this.store.pipe(select(getEnrollmentsSearchResult));
  }

  public searchEnrollments(params: IInstitutionManagerProgramHierarchyParams & { searchParameters: IMemberSearchParametersDto }) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return;
    }
    this.store.dispatch(EnrollmentSearchActions.SearchEnrollmentsAction(params));
  }

  public searchEnrollmentsEffect({
    institutionId,
    managerId,
    programId,
    hierarchyNodeId,
    searchParameters
  }: IInstitutionManagerProgramHierarchyParams & { searchParameters: IMemberSearchParametersDto }): Observable<IMemberSearchResultsDto> {
    return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/${hierarchyNodeId}/search-enrollments/approved`, searchParameters, { observe: 'response' }).pipe(
      switchMap((res: HttpResponse<any>) => this.httpClient.get(res.headers.get('location')))
    ) as Observable<IMemberSearchResultsDto>;
  }

  public clearEnrollmentSearch(params: IInstitutionManagerProgramHierarchyParams) {
    this.store.dispatch(EnrollmentSearchActions.ClearEnrollmentSearchAction(params));
  }

  public clearEnrollmentSearchEffect({
    institutionId,
    managerId,
    programId,
    hierarchyNodeId
  }: IInstitutionManagerProgramHierarchyParams) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/${hierarchyNodeId}/search-enrollments/approved`);
  }

  public getEnrollmentSearchResults({
    institutionId,
    managerId,
    programId,
    hierarchyNodeId,
    pageSize,
    pageIndex
  }: IInstitutionManagerProgramHierarchyParams & { pageSize: string, pageIndex: string }) {
    if (anyNull(institutionId, managerId, programId, hierarchyNodeId)) {
      return;
    }
    this.store.dispatch(EnrollmentSearchActions.GetEnrollmentSearchResultsAction({
      institutionId,
      managerId,
      programId,
      hierarchyNodeId,
      pageSize,
      pageIndex
    }));
  }

  public getEnrollmentSearchResultsEffect({
    institutionId,
    managerId,
    programId,
    hierarchyNodeId,
    pageSize,
    pageIndex
  }: IInstitutionManagerProgramHierarchyParams & { pageSize: string, pageIndex: string }): Observable<IMemberSearchResultsDto> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/${hierarchyNodeId}/search-enrollments/approved?pagesize=${pageSize}&pageindex=${pageIndex}`) as Observable<IMemberSearchResultsDto>;
  }
}
