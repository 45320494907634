import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DocumentationService } from 'app/core/services';
import { catchError, exhaustMap, map, of } from 'rxjs';

import { DocumentationActions } from '.';

@Injectable()
export class DocumentationEffects {

  loadDocumentation = createEffect(() => this.actions.pipe(
    ofType(DocumentationActions.DocumentationLoadAction),
    exhaustMap(payload => {
      return this.documentationService.loadDocumentationEffect().pipe(
        map(documentation => DocumentationActions.DocumentationLoadSuccessAction({ documentation })),
        catchError(error => of(DocumentationActions.DocumentationLoadErrorAction()))
      );
    })
  ));

  constructor(private actions: Actions, private documentationService: DocumentationService) {
  }
}
