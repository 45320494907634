import { Component, EventEmitter, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  AutoUnsubscribe,
  eEnrollmentRoleType,
  eUnitType,
  eVolunteerType,
  IEnrollmentDto,
  IEnrollmentProjectDto,
  IEnrollmentProjectSubmissionDto,
  IEnrollmentUnitDto,
  IInstitutionMemberProgramEnrollmentParams,
  IInstitutionVolunteerDto,
  IProjectDto,
} from 'app/core/models';
import { CommonToastrService, EnrollmentService, ModalService, ProjectsService, RouterService } from 'app/core/services';
import { combineLatest, delay, filter, map, Observable, switchMap, take, takeUntil } from 'rxjs';

import { ModalProjectsConsentComponent } from '../../modal-projects-consent/modal-projects-consent.component';

// import { EnrollmentAddProjectAction } from '../enrollment/enrollment.actions';

@Component({
  selector: 'ng4h-modal-add-projects',
  templateUrl: './modal-add-projects.component.html',
  styleUrls: ['./modal-add-projects.component.scss']
})
export class ModalAddProjectsComponent extends AutoUnsubscribe implements OnInit {

  public eUnitType = eUnitType;
  public selectedProject: IProjectDto;
  public eEnrollmentRoleType = eEnrollmentRoleType;

  public projectFilterString: string;
  public remainingProjects$: Observable<IProjectDto[]>;
  public selectedUnits$: Observable<IEnrollmentUnitDto[]>;
  public selectedUnitId: string;
  private selectedProjects$: Observable<IEnrollmentProjectDto[]>;

  public clubSelectionChanged$: EventEmitter<string> = new EventEmitter();

  private institutionMemberProgramEnrollmentParams: IInstitutionMemberProgramEnrollmentParams;

  public volunteerTypes$: Observable<IInstitutionVolunteerDto[]>;
  private volunteerCategories$: Observable<IInstitutionVolunteerDto[]>;
  private enrollment$: Observable<IEnrollmentDto>;
  public enrollmentRole$: Observable<eEnrollmentRoleType>;
  public volunteerType: IInstitutionVolunteerDto;
  public yearsInProject: number;

  constructor(
    private dialogRef: MatDialogRef<any>,
    private modalService: ModalService,
    private projectsService: ProjectsService,
    private enrollmentService: EnrollmentService,
    private routerService: RouterService,
    private toastrService: CommonToastrService
  ) {
    super();
    this.institutionMemberProgramEnrollmentParams = {
      institutionId: this.routerService.institutionId,
      memberId: this.routerService.memberId ? this.routerService.memberId : this.routerService.selectedMemberId,
      programId: this.routerService.programId,
      enrollmentId: this.routerService.enrollmentId
    };

    this.selectedUnits$ = this.enrollmentService.getEnrollment(this.institutionMemberProgramEnrollmentParams).pipe(
      map(enrollment => enrollment.units),
      takeUntil(this.autoUnsubscribe));
    this.selectedProjects$ = this.enrollmentService.getEnrollment(this.institutionMemberProgramEnrollmentParams).pipe(
      map(enrollment => enrollment.projects),
      takeUntil(this.autoUnsubscribe));

    this.remainingProjects$ =
      this.clubSelectionChanged$.pipe(
        switchMap(unitId => {
          return combineLatest(
            [
              this.projectsService.getProjectsByClub({
                institutionId: this.institutionMemberProgramEnrollmentParams.institutionId,
                memberId: this.institutionMemberProgramEnrollmentParams.memberId,
                programId: this.institutionMemberProgramEnrollmentParams.programId,
                enrollmentId: this.institutionMemberProgramEnrollmentParams.enrollmentId,
                unitId: unitId
              }),
              this.selectedProjects$
            ]);
        }),
        filter(combined => combined.every(c => c != null)),
        map(([projects, selectedProjects]) => {
          return projects.filter(project => {
            const selectedClubIndex = selectedProjects.findIndex(selectedProject => {
              return selectedProject.projectId === project.id;
            });
            return selectedClubIndex === -1;
          });
        }),
      );

    this.selectedUnits$.pipe(
      filter(clubs => Array.isArray(clubs) && clubs.length > 0),
      take(1),
      delay(100)
    ).subscribe(clubs => {
      this.selectedUnitId = clubs[0].unitId;
      this.clubSelectionChanged$.emit(this.selectedUnitId);
    });
  }

  ngOnInit() {

    this.enrollment$ = this.enrollmentService.getEnrollment(this.institutionMemberProgramEnrollmentParams).pipe(
      takeUntil(this.autoUnsubscribe)
    );
    this.volunteerCategories$ = this.enrollmentService.getVolunteerTypes(this.institutionMemberProgramEnrollmentParams);

    this.volunteerTypes$ = combineLatest([this.enrollment$, this.volunteerCategories$]).pipe(
      map(([enrollment, volunteerCategories]) => {
        if (enrollment.enrollmentRole === eEnrollmentRoleType.ClubMember) {
          return volunteerCategories.filter(volunteerType => {
            return volunteerType.volunteerTypeDescriptor.volunteerType === eVolunteerType.YouthProjectVolunteer;
          });
        }
      })
    );

    this.enrollmentRole$ = this.enrollment$.pipe(map(enrollment => enrollment.enrollmentRole));

    this.enrollmentRole$.pipe(
      filter(enrollmentRole => enrollmentRole === eEnrollmentRoleType.Volunteer),
      switchMap(() => this.volunteerTypes$),
      filter(volunteerTypes => Array.isArray(volunteerTypes) && volunteerTypes.length > 0),
      take(1))
      .subscribe(volunteerTypes => {
        if (Array.isArray(volunteerTypes) && volunteerTypes.length === 1) {
          this.volunteerTypeChanged(volunteerTypes[0]);
        }
      });


  }

  public volunteerTypeChanged(volunteerType: IInstitutionVolunteerDto) {
    this.volunteerType = volunteerType;
  }

  public selectProject(project: IProjectDto) {
    this.selectedProject = project;
    this.yearsInProject = this.selectedProject.yearsInProjectForEnrollment;
  }

  public addProject(project: IProjectDto) {

    const projectSubmission: IEnrollmentProjectSubmissionDto = {
      projectId: project.id,
      unitId: this.selectedUnitId,
      consents: [],
      volunteerTypeId: this.volunteerType?.volunteerTypeId,
      countyAreaId: null,
      yearsInProject: this.yearsInProject,
    };

    if (project.consents.length === 0) {


      this.enrollmentService.saveProject({
        ...this.institutionMemberProgramEnrollmentParams,
        projectSubmission
      }).pipe(
        take(1),
        switchMap(() => this.enrollmentService.refreshEnrollment()),
        take(1),
        this.modalService.showProgressSpinnerModalUntilComplete()
      ).subscribe({
        next: () => {
          this.toastrService.success('Project Saved');
          this.close();
        }, error: (error) => {
          this.toastrService.error('Could not save project', error);
        }
      });
      this.close();
    } else {
      this.close();
      this.modalService.openModal(ModalProjectsConsentComponent, { data: { project: project, submission: projectSubmission } });
    }

  }


  public close() {
    this.dialogRef.close();
  }


}
