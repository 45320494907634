import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { eStateAbbreviation } from 'app/core/models';


@Component({
  selector: 'ng4h-address-input',
  templateUrl: './address-input.component.html',
  styleUrls: ['./address-input.component.scss']
})
export class AddressInputComponent implements OnInit {

  @Input() form: FormGroup;

  public eStateAbbreviation = eStateAbbreviation;

  constructor() {
  }

  ngOnInit() {
  }

}
