import {
  IEnrollmentSummaryDto,
  IMemberProgramConsentSummaryDto,
  IMemberProgramScreeningSummaryDto,
  IMemberProgramTrainingSummaryDto,
  IMemberProgramYearApprovalStateDto,
  IProgramYearDto,
} from '.';
import { eMemberProgramYearStatus } from '..';

export interface IMemberProgramYearSummaryDto {
  memberProgramYearId: string;
  active: boolean;
  status: eMemberProgramYearStatus;
  approvalState: IMemberProgramYearApprovalStateDto;
  additionalPaymentRequired: boolean;
  programYear: IProgramYearDto;
  enrollment: IEnrollmentSummaryDto;
  consents?: IMemberProgramConsentSummaryDto[];
  animals?: any[];
  experiences?: any[];
  trainings?: IMemberProgramTrainingSummaryDto[];
  screening: IMemberProgramScreeningSummaryDto;
  trainingsComplete: boolean;
}
