<table mat-table [dataSource]="matTableDataSource" matSort>

  <ng-container *ngFor="let column of columns | keyvalue; let isLast = last; let i = index;">

    <ng-container *ngIf="column.key === 'select'; else regular">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>
    </ng-container>

    <ng-template #regular>
      <ng-container matColumnDef="{{column.key}}">
        <!-- <ng-container *> -->
        <th mat-header-cell *matHeaderCellDef mat-sort-header [innerHTML]="column.value">
          <!-- <a *ngIf="enableExport" href="Download" (click)="excelDownload($event)">
            <div class="excel-download-container">
              <i class="far fa-file-excel"></i>
            </div>
          </a> -->
        </th>

        <ng-container>
          <td mat-cell *matCellDef="let element" [innerHTML]="resolveObjectPath(column.key, element)"></td>
        </ng-container>

      </ng-container>
    </ng-template>

  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

  <tr [ngClass]="{'selected': highlightSelected && (rowClicked | async) == row}" mat-row
    *matRowDef="let row; columns: displayedColumns;" (click)="rowClick(row)"></tr>

</table>


<mat-paginator *ngIf="!hidePaging" [pageSize]="pageSize"></mat-paginator>