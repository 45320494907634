import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
    IInstitutionManagerProgramEventParams,
    IMemberSearchResultsDto,
    IRegistrationPaymentSearchParametersDto,
} from 'app/core/models';


export const EventSearchPaymentAction = createAction(
    '[payment-search] Search event payment',
    props<IInstitutionManagerProgramEventParams & { searchParameters: IRegistrationPaymentSearchParametersDto }>()
);

export const EventSearchPaymentSuccessAction = createAction(
    '[payment-search] Search event payment success',
    props<IInstitutionManagerProgramEventParams & { searchParameters: IRegistrationPaymentSearchParametersDto, paymentSearchResult: IMemberSearchResultsDto }>()
);

export const EventSearchPaymentErrorAction = createAction(
    '[payment-search] Search event payment error',
    props<{ error: HttpErrorResponse }>()
);


export const GetEventPaymentSearchResultsAction = createAction(
    '[payment-search] get event payment search results',
    props<IInstitutionManagerProgramEventParams & { pageSize: string, pageIndex: string }>()
);

export const GetEventPaymentSearchResultsSuccessAction = createAction(
    '[payment-search] get event payment search results success',
    props<IInstitutionManagerProgramEventParams & { pageSize: string, pageIndex: string, paymentSearchResult: IMemberSearchResultsDto }>()
);

export const GetEventPaymentSearchResultsErrorAction = createAction(
    '[payment-search] get event payment search results error',
    props<{ error: HttpErrorResponse }>()
);


export const ClearPaymentSearchAction = createAction(
    '[payment-search] clear event payment search',
    props<IInstitutionManagerProgramEventParams>()
);

export const ClearPaymentSearchSuccessAction = createAction(
    '[payment-search] clear event payment search success',
    props<IInstitutionManagerProgramEventParams>()
);

export const ClearPaymentSearchErrorAction = createAction(
    '[payment-search] clear event payment search error',
    props<{ error: HttpErrorResponse }>()
);
