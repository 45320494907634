<ng-container *ngFor="let control of fileControls; let i = index;">

  <div class="d-flex flex-column justify-content-center file-container"
       [ngClass]="{'required': !control.hasFile && (
    (enrollmentRole && control.enrollmentRoles && control.enrollmentRoles[enrollmentRole] === true) || 
  (registrationTypeId && control.registrationTypes && control.registrationTypes[registrationTypeId] === true) ||
  control.required
  )}">
    <div class="d-flex flex-row justify-content-center">
      <div class="d-flex flex-column">
        <div class="d-flex flex-row justify-content-center">
          <h4 class="file-control-name" [ngClass]="{'ng4h-required': !control.hasFile && (
          (enrollmentRole && control.enrollmentRoles && control.enrollmentRoles[enrollmentRole] === true) || 
        (registrationTypeId && control.registrationTypes && control.registrationTypes[registrationTypeId] === true) ||
        control.required
        )}">
            {{ control.fileControlName }}
          </h4>
        </div>
        <div class="d-flex flex-row justify-content-around p-2">
          <button *ngIf="control.hasTemplateFile" class="btn btn-secondary" (click)="downloadTemplate(control)">Download
            Template
          </button>
        </div>

        <ng-container
          *ngIf="control.acceptedFileExtensions[0].mimeType === eMimeType.PDF || control.uploadedFile?.mimeType === 'application/pdf'; else image">
          <ng-container *ngIf="control.hasFile; else noPdf">
            <div class="thumbnail">
              <img src="/assets/images/pdf.png" alt="PDF Thumbnail">
            </div>
          </ng-container>
          <ng-template #noPdf>
            <div class="thumbnail">
              <img src="/assets/images/pdf_empty.png" alt="PDF Empty Thumbnail">
            </div>
          </ng-template>
        </ng-container>

        <ng-template #image>
          <ng-container *ngIf="control.hasFile; else noThumbnail">
            <div class="thumbnail">
              <img
                *ng4hPlaceholder="control.uploadedFile.thumbnailResourceUrl | image$ | async as thumbnail; size: [100,200]"
                [src]="thumbnail" alt="Thumbnail">
            </div>
          </ng-container>
          <ng-template #noThumbnail>
            <div class="thumbnail">
              <img src="/assets/images/no-image-selected.png" alt="No Image Selected">
            </div>
          </ng-template>

        </ng-template>


        <!-- <div class="d-flex flex-row justify-content-center">
          <div *ngFor="let type of control.fileTypes; let isLast = last">
            <span>{{ type.fileExtension }}{{ isLast ? '' : ', '}} </span>
          </div>
        </div> -->


        <div class="d-flex flex-row justify-content-around p-2">
          <ng4h-file-uploader *ngIf="!readonly" [hasFile]="control.hasFile" accept="image/*,.pdf"
                              (uploadFile)="onUpload($event, control)">
          </ng4h-file-uploader>
          <button *ngIf="control.hasFile" class="btn btn-success ng4h-margin-left"
                  (click)="download(control)">Download
          </button>
          <button *ngIf="control.hasFile && !readonly" class="btn btn-danger ng4h-margin-left"
                  (click)="clear(control)">Delete
          </button>

        </div>
      </div>
    </div>
  </div>
</ng-container>