import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'ng4h-admin-page-icon',
  templateUrl: './admin-page-icon.component.html',
  styleUrls: ['./admin-page-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminPageIconComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
