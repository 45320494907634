export enum eAnimalFileType {
    PhotoLeft = 'PhotoLeft',
    LiabilityWaiver = 'LiabilityWaiver',
    ImmunizationForm = 'ImmunizationForm',
    Photo = 'Photo',
    PhotoFront = 'PhotoFront',
    PhotoBack = 'PhotoBack',
    LeaseForm = 'LeaseForm',
    RegistrationPapers = 'RegistrationPapers',
    PhotoTag = 'PhotoTag',
    PhotoBrand = 'PhotoBrand',
    BillOfSale = 'BillOfSale',
    CountyUseForm = 'CountyUseForm',
    QualityAssurance = 'QualityAssurance',
    HealthRecord = 'HealthRecord',
    CogginsTest = 'CogginsTest',
    EarNotch = 'EarNotch',
    NominationForm = 'NominationForm',
    PhotoIdentifying = 'PhotoIdentifying',
    PullorumTestPaperwork = 'PullorumTestPaperwork',
    DogAgilityRelease = 'DogAgilityRelease',
    HelmetRelease = 'HelmetRelease',
    EasternEncephalitisVaccination = 'EasternEncephalitisVaccination',
    TetanusVaccination = 'TetanusVaccination',
    RabiesVaccination = 'RabiesVaccination',
    WestNileVirusVaccination = 'WestNileVirusVaccination',
    CertificateOfVeterinaryInspection = 'CertificateOfVeterinaryInspection',
    Application = 'Application',
    RabiesTestPaperwork = 'RabiesTestPaperwork',
    PermissionToParticipate = 'PermissionToParticipate',
    RactopamineAffidavit = 'RactopamineAffidavit'
}
