import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { eProfileType, IAnyInstitutionProfile, IMemberDto } from 'app/core/models';
import { CommonToastrService, MemberService, ProfileService, RouterService } from 'app/core/services';
import { combineLatest, filter, map, Observable, switchMap, take } from 'rxjs';

import { IMemberProgramSummaryDto } from '../../../models/serverDTOs/IMemberProgramSummaryDto';

@Component({
    selector: 'ng4h-member-program-selector',
    templateUrl: './member-program-selector.component.html',
    styleUrls: ['./member-program-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MemberProgramSelectorComponent implements OnInit {

    public programId$: Observable<string>;
    public memberProgramIds$: Observable<string[]>;
    private activeUserInstitutionProfile$: Observable<IAnyInstitutionProfile>;

    public member$: Observable<IMemberDto>;

    public memberProgramDashboard$: Observable<IMemberProgramSummaryDto>;
    constructor(
        private routerService: RouterService,
        private profileService: ProfileService,
        private memberService: MemberService,
        private toastrService: CommonToastrService
    ) {
    }
    ngOnInit(): void {

        this.member$ = this.routerService.memberId$.pipe(
            filter(rootMemberId => rootMemberId != null),
            switchMap(rootMemberId => this.memberService.getMember({ institutionId: this.routerService.institutionId, memberId: rootMemberId })),
            filter(member => member != null)
        );
        this.activeUserInstitutionProfile$ = this.profileService.loggedInAsInstitutionProfile;
        this.programId$ = this.routerService.programId$;

        this.memberProgramIds$ = combineLatest([this.activeUserInstitutionProfile$, this.member$]).pipe(
            map(([profile, member]) => {
                if (profile == null || member == null) {
                    return [];
                }
                const memberProgramIds = Object.keys(member.memberPrograms);
                if (profile.profileType === eProfileType.Manager) {
                    const managerProgramIds = Object.keys(profile.managerPrograms);
                    return memberProgramIds.filter(memberProgramId => managerProgramIds.includes(memberProgramId));
                }
                return memberProgramIds;
            })
        );

        if (this.routerService.programId == null) {
            this.memberProgramIds$.pipe(take(1)).subscribe(memberProgramIds => {
                this.routerService.Go(['../../', memberProgramIds[0], 'profile']);
            });
        }

        const memberDashboard$ = this.memberService.getMemberDashboard({
            institutionId: this.routerService.institutionId,
            memberId: this.routerService.memberId
        });

        this.memberProgramDashboard$ = this.routerService.programId$.pipe(
            switchMap(programId => {
                return memberDashboard$.pipe(
                    map(memberDashboard => {
                        return memberDashboard.memberPrograms[programId];
                    })
                );
            })
        );
    }

    public selectProgram(programId: string) {
        this.memberService.setMemberProgramYear({
            institutionId: this.routerService.institutionId,
            memberId: this.routerService.memberId,
            programYearId: null
        });
        this.routerService.Go([this.routerService.userAccountId, this.routerService.institutionId, this.routerService.loggedInInstitutionProfileId, 'member', this.routerService.memberId, programId, 'dashboard']);
        // this.toastrService.success('Program Changed');
    }
}
