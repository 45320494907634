import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  IBatchProfileScreeningDto,
  IInstitutionManagerProgramHierarchyParams,
  IInstitutionManagerProgramParams,
  IMemberSearchResultsDto,
  IPendingScreeningDto,
  IProfileScreeningUpdateDto,
  IProfileScreeningUpdateTypeDto,
  IProfileVolunteerScreeningDto,
  IScreeningBatchDto,
} from 'app/core/models';
import { IScreeningSearchParametersDto } from 'app/core/models/serverDTOs/IScreeningSearchParametersDto';

export const ManagerGetBatchScreeningsAction = createAction(
  '[manager screenings] get batch screenings',
  props<{ institutionId: string, managerId: string, programId: string, profileScreeningBatchId: string }>()
);

export const ManagerGetBatchScreeningsSuccessAction = createAction(
  '[manager screenings] get batch screenings success',
  props<{ institutionId: string, managerId: string, programId: string, profileScreeningBatchId: string, screenings: IProfileVolunteerScreeningDto[] }>()
);

export const ManagerGetBatchScreeningsErrorAction = createAction(
  '[manager screenings] get batch screenings error',
  props<{ error: HttpErrorResponse }>()
);

export const ManagerCreateScreeningsBatchAction = createAction(
  '[manager screenings] create screenings batch',
  props<{ institutionId: string, managerId: string, programId: string, screenings: string[] }>()
);

export const ManagerCreateScreeningsBatchSuccessAction = createAction(
  '[manager screenings] create screenings batch success',
  props<{ institutionId: string, managerId: string, programId: string, screenings: string[] }>()
);

export const ManagerCreateScreeningsBatchErrorAction = createAction(
  '[manager screenings] create screenings batch error',
  props<{ error: HttpErrorResponse }>()
);

export const ManageGetScreeningsAvailableForBatchAction = createAction(
  '[manager screenings] get manager screenings available for batch',
  props<{ institutionId: string, managerId: string, programId: string }>()
);

export const ManagerGetScreeningsAvailableForBatchSuccessAction = createAction(
  '[manager screenings] get manager screenings available for batch success',
  props<{ institutionId: string, managerId: string, programId: string, screenings: IPendingScreeningDto[] }>()
);

export const ManagerGetScreeningsAvailableForBatchErrorAction = createAction(
  '[manager screenings] get manager screenings available for batch error',
  props<{ error: HttpErrorResponse }>()
);
export const ManageGetSubmittedScreeningsAction = createAction(
  '[manager screenings] get submitted screenings',
  props<{ institutionId: string, managerId: string, programId: string, hierarchyNodeId: string }>()
);

export const ManageGetSubmittedScreeningsSuccessAction = createAction(
  '[manager screenings] get submitted screenings success',
  props<{ institutionId: string, managerId: string, programId: string, hierarchyNodeId: string, submittedScreenings: IProfileVolunteerScreeningDto[] }>()
);

export const ManageGetSubmittedScreeningsErrorAction = createAction(
  '[manager screenings] get submitted screenings error',
  props<{ error: HttpErrorResponse }>()
);

export const ManagerGetBatchesAction = createAction(
  '[manager screenings] get manager screenings batches',
  props<{ institutionId: string, managerId: string, programId: string }>()
);

export const ManagerGetBatchesSuccessAction = createAction(
  '[manager screenings] get manager screenings batches success',
  props<{ institutionId: string, managerId: string, programId: string, batches: IScreeningBatchDto[] }>()
);

export const ManagerGetBatchesErrorAction = createAction(
  '[manager screenings] get manager screenings batches error',
  props<{ error: HttpErrorResponse }>()
);

// TODO: remove any when post type is known
export const ManagerScreeningsUpdateBatchAction = createAction(
  '[manager screenings] update manager screenings batches',
  props<{ institutionId: string, managerId: string, programId: string, batch: any }>()
);

export const ManagerScreeningsUpdateBatchSuccessAction = createAction(
  '[manager screenings] update manager screenings batches success',
  props<{ institutionId: string, managerId: string, programId: string, batch: any }>()
);

export const ManagerScreeningsUpdateBatchErrorAction = createAction(
  '[manager screenings] update manager screenings batches error',
  props<{ error: HttpErrorResponse }>()
);

export const ManagerScreeningsUpdateScreeningsStatusAction = createAction(
  '[manager screenings] update multiple screenings status',
  props<{ institutionId: string, managerId: string, programId: string, screenings: IBatchProfileScreeningDto[] }>()
);

export const ManagerScreeningsUpdateScreeningsStatusSuccessAction = createAction(
  '[manager screenings] update multiple screenings status success',
  props<{ institutionId: string, managerId: string, programId: string, screenings: IBatchProfileScreeningDto[] }>()
);

export const ManagerScreeningsUpdateScreeningsStatusErrorAction = createAction(
  '[manager screenings] update multiple screenings status error',
  props<{ error: HttpErrorResponse }>()
);

export const ManagerScreeningsUpdateScreeningStatusAction = createAction(
  '[manager screenings] update screening status',
  props<{ institutionId: string, managerId: string, programId: string, profileScreeningId: string, update: IProfileScreeningUpdateDto }>()
);

export const ManagerScreeningsUpdateScreeningStatusSuccessAction = createAction(
  '[manager screenings] update screening status success',
  props<{ institutionId: string, managerId: string, programId: string, profileScreeningId: string, update: IProfileScreeningUpdateDto }>()
);

export const ManagerScreeningsUpdateScreeningStatusErrorAction = createAction(
  '[manager screenings] update screening status error',
  props<{ error: HttpErrorResponse }>()
);

export const ManagerScreeningsChangeScreeningLevelAction = createAction(
  '[manager screenings] change screening level',
  props<{ institutionId: string, managerId: string, programId: string, profileScreeningId: string, update: IProfileScreeningUpdateTypeDto }>()
);

export const ManagerScreeningsChangeScreeningLevelSuccessAction = createAction(
  '[manager screenings] change screening level success',
  props<{ institutionId: string, managerId: string, programId: string, profileScreeningId: string, update: IProfileScreeningUpdateTypeDto }>()
);

export const ManagerScreeningsChangeScreeningLevelErrorAction = createAction(
  '[manager screenings] change screening level error',
  props<{ error: HttpErrorResponse }>()
);


export const SearchScreeningsAction = createAction(
  '[manager-screenings] Search screenings',
  props<IInstitutionManagerProgramHierarchyParams & { searchParameters: IScreeningSearchParametersDto }>()
);
export const SearchScreeningsSuccessAction = createAction(
  '[manager-screenings] Search screenings success',
  props<IInstitutionManagerProgramHierarchyParams & { searchParameters: IScreeningSearchParametersDto, screeningsSearchResult: IMemberSearchResultsDto }>()
);
export const SearchScreeningsErrorAction = createAction(
  '[manager-screenings] Search screenings error',
  props<{ error: HttpErrorResponse }>()
);

export const GetScreeningSearchResultsAction = createAction(
  '[manager-screenings] Get screening search results',
  props<IInstitutionManagerProgramHierarchyParams & { pageSize: string, pageIndex: string }>()
);
export const GetScreeningSearchResultsSuccessAction = createAction(
  '[manager-screenings] Get screening search results success',
  props<IInstitutionManagerProgramHierarchyParams & { pageSize: string, pageIndex: string, screeningsSearchResult: IMemberSearchResultsDto }>()
);
export const GetScreeningSearchResultsErrorAction = createAction(
  '[manager-screenings] Get screening search results error',
  props<{ error: HttpErrorResponse }>()
);



export const ClearScreeningSearchAction = createAction(
  '[manager-screenings] Clear screening search',
  props<IInstitutionManagerProgramHierarchyParams>()
);

export const ClearScreeningSearchSuccessAction = createAction(
  '[manager-screenings] Clear screening search success',
  props<IInstitutionManagerProgramHierarchyParams>()
);

export const ClearScreeningSearchErrorAction = createAction(
  '[manager-screenings] Clear screening search error',
  props<{ error: HttpErrorResponse }>()
);

export const SendBackScreeningAction = createAction(
  '[manager-screenings] Send back screening',
  props<IInstitutionManagerProgramParams & { profileScreeningId: string, update: IProfileScreeningUpdateDto }>()
);

export const SendBackScreeningSuccessAction = createAction(
  '[manager-screenings] Send back screening success',
  props<IInstitutionManagerProgramParams & { profileScreeningId: string, update: IProfileScreeningUpdateDto }>()
);

export const SendBackScreeningErrorAction = createAction(
  '[manager-screenings] Send back screening error',
  props<{ error: HttpErrorResponse }>()
);

export const DeleteScreeningAction = createAction(
  '[manager-screenings] Delete screening',
  props<IInstitutionManagerProgramParams & { profileScreeningId: string }>()
);

export const DeleteScreeningSuccessAction = createAction(
  '[manager-screenings] Delete screening success',
  props<IInstitutionManagerProgramParams & { profileScreeningId: string }>()
);

export const DeleteScreeningErrorAction = createAction(
  '[manager-screenings] Delete screening error',
  props<{ error: HttpErrorResponse }>()
);
