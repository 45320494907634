import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IOfflineDto } from 'app/core/models';

@Component({
  selector: 'ng4h-modal-take-offline',
  templateUrl: './modal-take-offline.component.html',
  styleUrls: ['./modal-take-offline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalTakeOfflineComponent implements OnInit {

  public form: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<any>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) private data: IOfflineDto
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      down: [this.data.down],
      message: [this.data.message],
      allowSystemManagers: [this.data.allowSystemManagers],
      allowManagers: [this.data.allowManagers]
    });
  }

  public save() {
    this.close(this.form.value);
  }
  public close(res?: IOfflineDto): void {
    this.dialogRef.close(res);
  }
}
