<div class="modal-section">
  <div class="modal-header">
    <h5 class="modal-title">Edit Custom Report</h5>
    <button (click)="cancel()" type="button" class="close" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="form">
      <div class="form-group col-sm-12 row">
        <label class="form-control-label col-6">Report Title</label>
        <input formControlName="reportName" class="form-control col-sm-6">
      </div>

      <div class="form-group col-sm-12 row">
        <label class="form-control-label col-6">Description</label>
        <input formControlName="description" class="form-control col-sm-6">
      </div>

      <div class="form-group col-sm-12 row">
        <label class="form-control-label col-6">Excel Worksheet Title</label>
        <input formControlName="excelWorksheetTitle" class="form-control col-sm-6">
      </div>
    </form>
  </div>

  <div class="modal-footer" *ngIf="form">
    <button id (click)="cancel()" class="btn btn-secondary my-2 my-sm-0" role="button"
      aria-pressed="true">Cancel</button>
    <button [disabled]="form.invalid" (click)="update()" class="btn btn-primary my-2 my-sm-0" role="button"
      aria-pressed="true">Update</button>
  </div>
</div>