import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { AppState } from 'app/app.reducers';
import {
  VolunteerTypesActions
} from 'app/core/containers/admin/system-manager/enrollment/enrollment-volunteer-types/store';
import { getVolunteerTypes } from 'app/core/containers/admin/system-manager/system-manager-feature.reducers';
import {
  IInstitutionSystemManagerProgramParams,
  IInstitutionVolunteerCreateDto,
  IInstitutionVolunteerDto,
  IInstitutionVolunteerUpdateDto
} from 'app/core/models';
import { filter, map, Observable, of, take, tap } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SystemManagerVolunteerTypesService {

  constructor(private store: Store<AppState>, private httpClient: HttpClient, private dispatcher: ActionsSubject) {
  }

  public getVolunteerTypes(params: IInstitutionSystemManagerProgramParams): Observable<IInstitutionVolunteerDto[]> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getVolunteerTypes(params)),
      tap(volunteerTypes => {
        if (volunteerTypes === undefined) {
          this.store.dispatch(VolunteerTypesActions.VolunteerTypesLoadAction(params));
        }
      }),
      filter(volunteerTypes => volunteerTypes != null)
    );
  }

  public loadVolunteerTypesEffect({
    institutionId,
    systemManagerId,
    programId
  }: IInstitutionSystemManagerProgramParams) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/volunteer-types`) as Observable<IInstitutionVolunteerDto[]>;
  }

  public addVolunteerType(params: IInstitutionSystemManagerProgramParams & { volunteerType: IInstitutionVolunteerCreateDto }) {
    this.store.dispatch(VolunteerTypesActions.VolunteerTypesCreateAction(params));

    return this.dispatcher.pipe(
      ofType(VolunteerTypesActions.VolunteerTypesCreateSuccessAction, VolunteerTypesActions.VolunteerTypesCreateErrorAction),
      take(1),
      map(action => {
        if (action.type === VolunteerTypesActions.VolunteerTypesCreateSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public createVolunteerTypeEffect({
    institutionId,
    systemManagerId,
    programId,
    volunteerType
  }: IInstitutionSystemManagerProgramParams & { volunteerType: IInstitutionVolunteerCreateDto }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/volunteer-types`, volunteerType);
  }

  public updateVolunteerTypes(params: IInstitutionSystemManagerProgramParams & { institutionVolunteerId: string, volunteerType: IInstitutionVolunteerUpdateDto }) {
    this.store.dispatch(VolunteerTypesActions.VolunteerTypesUpdateAction(params));

    return this.dispatcher.pipe(
      ofType(VolunteerTypesActions.VolunteerTypesUpdateSuccessAction, VolunteerTypesActions.VolunteerTypesUpdateErrorAction),
      take(1),
      map(action => {
        if (action.type === VolunteerTypesActions.VolunteerTypesUpdateSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateVolunteerTypesEffect({
    institutionId,
    systemManagerId,
    programId,
    institutionVolunteerId,
    volunteerType
  }: IInstitutionSystemManagerProgramParams & { institutionVolunteerId: string, volunteerType: IInstitutionVolunteerUpdateDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/volunteer-types/${institutionVolunteerId}`, volunteerType);
  }

  public deleteVolunteerTypes(params: IInstitutionSystemManagerProgramParams & { institutionVolunteerId: string }) {
    this.store.dispatch(VolunteerTypesActions.VolunteerTypesDeleteAction(params));

    return this.dispatcher.pipe(
      ofType(VolunteerTypesActions.VolunteerTypesDeleteSuccessAction, VolunteerTypesActions.VolunteerTypesDeleteErrorAction),
      take(1),
      map(action => {
        if (action.type === VolunteerTypesActions.VolunteerTypesDeleteSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteVolunteerTypesEffect({
    institutionId,
    systemManagerId,
    programId,
    institutionVolunteerId
  }: IInstitutionSystemManagerProgramParams & { institutionVolunteerId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/volunteer-types/${institutionVolunteerId}`);
  }

  public associateConsent(params: IInstitutionSystemManagerProgramParams & { institutionVolunteerId: string, consentTypeId: string }) {
    this.store.dispatch(VolunteerTypesActions.VolunteerTypesAssociateConsentAction(params));

    return this.dispatcher.pipe(
      ofType(VolunteerTypesActions.VolunteerTypesAssociateConsentSuccessAction, VolunteerTypesActions.VolunteerTypesAssociateConsentErrorAction),
      take(1),
      map(action => {
        if (action.type === VolunteerTypesActions.VolunteerTypesAssociateConsentSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public associateConsentEffect({
    institutionId,
    systemManagerId,
    programId,
    institutionVolunteerId,
    consentTypeId
  }: IInstitutionSystemManagerProgramParams & { institutionVolunteerId: string, consentTypeId: string }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/volunteer-types/${institutionVolunteerId}?consentTypeId=${consentTypeId}`, {});
  }

  public removeAssociationConsent(params: IInstitutionSystemManagerProgramParams & { institutionVolunteerId: string, consentTypeId: string }) {
    this.store.dispatch(VolunteerTypesActions.VolunteerTypesRemoveConsentAssociationAction(params));

    return this.dispatcher.pipe(
      ofType(VolunteerTypesActions.VolunteerTypesRemoveConsentAssociationSuccessAction, VolunteerTypesActions.VolunteerTypesRemoveConsentAssociationErrorAction),
      take(1),
      map(action => {
        if (action.type === VolunteerTypesActions.VolunteerTypesRemoveConsentAssociationSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public removeAssociationConsentEffect({
    institutionId,
    systemManagerId,
    programId,
    institutionVolunteerId,
    consentTypeId
  }: IInstitutionSystemManagerProgramParams & { institutionVolunteerId: string, consentTypeId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/volunteer-types/${institutionVolunteerId}?consentTypeId=${consentTypeId}`);
  }
}
