import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EMAIL_REGEX, eProfileType, IReportConsumerCreateDto, ISystemManagerCreateDto } from 'app/core/models';
import { UserAccountService } from 'app/core/services/user-account.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'ng4h-modal-enter-email',
  templateUrl: './modal-enter-email.component.html',
  styleUrls: ['./modal-enter-email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalEnterEmailComponent implements OnInit {

  public form: FormGroup;

  public emailValid = false;
  public errorMessage$: Subject<string> = new Subject<string>();
  public wasValidated = false;

  public requireAdditionalInformation = false;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<any>,
    private userAccountService: UserAccountService,
    @Inject(MAT_DIALOG_DATA) public invalidProfileTypes: eProfileType[]
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email, Validators.pattern(EMAIL_REGEX)]],
      firstName: [],
      lastName: []
    });
  }

  public close(res?: ISystemManagerCreateDto | IReportConsumerCreateDto): void {
    this.dialogRef.close(res);
  }

  public verify(): void {

    this.userAccountService.emailLookup(this.form.controls['email'].value).subscribe(user => {
      if (user != null) {
        this.requireAdditionalInformation = false;

        if (this.invalidProfileTypes.find(pt => user.institutionProfiles.find(up => up.profileType === pt) != null)) {
          this.emailValid = false;
          this.errorMessage$.next(`This email is already associated with a restricted profile type.(${this.invalidProfileTypes.join(',')})`);
        } else {
          const existingUser = {
            email: this.form.value.email,
            useraccounttoken: user.token,
            firstName: user.firstName,
            lastName: user.lastName,
          };

          this.close(existingUser);
        }
      } else {
        this.emailValid = true;
        this.errorMessage$.next(null);

        this.requireAdditionalInformation = true;

        const formValue = this.form.value;

        if (formValue.firstName == null || formValue.firstName?.length < 1 || formValue.lastName == null || formValue.lastName?.length < 1) {
          this.emailValid = false;
          this.errorMessage$.next(`Missing additional information for new user.`);
          return;
        }

        const newUser = {
          ...formValue
        };

        this.close(newUser);
      }
    });
  }
}
