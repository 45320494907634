import { Action, createReducer, on } from '@ngrx/store';
import {
  IEnrollmentUnitDto,
  IInstitutionMemberProgramEnrollmentModel,
  IInstitutionMemberProgramModel,
  IMemberSearchResultDto,
} from 'app/core/models';
import { mergeImmutable } from 'app/shared/utils';

import { MemberUnitActions } from '.';


export interface UnitsState {
  units: IInstitutionMemberProgramEnrollmentModel<{ [countyAreaId: string]: IEnrollmentUnitDto[] }>;
  leaderUnitMembers: IInstitutionMemberProgramModel<{ [unitId: string]: IMemberSearchResultDto[] }>;
}

const initialState: UnitsState = {
  units: undefined,
  leaderUnitMembers: undefined
};


const reducer = createReducer(
  initialState,
  on(MemberUnitActions.UnitsLoadSuccessAction, (state, { institutionId, memberId, programId, enrollmentId, countyAreaId, units }) => {
    const newUnits = mergeImmutable(
      { [institutionId]: { [memberId]: { [programId]: { [enrollmentId]: { [countyAreaId]: units } } } } },
      state.units
    );

    return {
      ...state,
      units: newUnits
    };
  }),
  on(MemberUnitActions.UnitLeaderLoadMembersSuccessAction, (state, { institutionId, memberId, programId, unitId, members }) => {
    const newMembers = mergeImmutable(
      { [institutionId]: { [memberId]: { [programId]: { [unitId]: members } } } },
      state.leaderUnitMembers
    );
    return {
      ...state,
      leaderUnitMembers: newMembers
    };
  })
);

export function memberUnitsReducer(state: UnitsState | undefined, actions: Action) {
  return reducer(state, actions);
}

export const clubs = (state: UnitsState) => state.units;
export const leaderClubMembers = (state: UnitsState) => state.leaderUnitMembers;