import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  ICountyProjectCreateDto,
  ICountyProjectUpdateDto,
  INationalProjectCategoryDto,
  IProjectDto,
  IProjectProgramYearUpdateDto,
} from 'app/core/models';
import { IInstitutionManagerProgramParams, IInstitutionProgramParams } from 'app/core/models/function-parameters';

export const ManagerGetProjectsAction = createAction(
  '[manager-projects] get manager projects',
  props<IInstitutionManagerProgramParams>()
);

export const ManagerGetProjectsSuccessAction = createAction(
  '[manager-projects] get manager projects success',
  props<IInstitutionManagerProgramParams & { projects: IProjectDto[] }>()
);

export const ManagerGetProjectsErrorAction = createAction(
  '[manager-projects] get manager projects error',
  props<{ error: HttpErrorResponse }>()
);

export const ManagerGetProgramYearHierarchyProjectsAction = createAction(
  '[manager-projects] get program year hierarchy projects',
  props<IInstitutionManagerProgramParams & { programYearId: string, hierarchyNodeId: string }>()
);

export const ManagerGetProgramYearHierarchyProjectsSuccessAction = createAction(
  '[manager-projects] get program year hierarchy projects success',
  props<IInstitutionManagerProgramParams & { programYearId: string, hierarchyNodeId: string, projects: IProjectDto[] }>()
);

export const ManagerGetProgramYearHierarchyProjectsErrorAction = createAction(
  '[manager-projects] get program year hierarchy projects error',
  props<{ error: HttpErrorResponse }>()
);

export const ManagerGetProgramYearProjectsAction = createAction(
  '[manager-projects] get program year projects',
  props<IInstitutionManagerProgramParams & { programYearId: string }>()
);

export const ManagerGetProgramYearProjectsSuccessAction = createAction(
  '[manager-projects] get program year projects success',
  props<IInstitutionManagerProgramParams & { programYearId: string, projects: IProjectDto[] }>()
);

export const ManagerGetProgramYearProjectsErrorAction = createAction(
  '[manager-projects] get program year projects error',
  props<{ error: HttpErrorResponse }>()
);


export const ManagerLinkProjectToProgramYearAction = createAction(
  '[manager-projects] link project to program year',
  props<IInstitutionManagerProgramParams & { programYearId: string, projectIds: string[] }>()
);

export const ManagerLinkProjectToProgramYearSuccessAction = createAction(
  '[manager-projects] link project to program year success',
  props<IInstitutionManagerProgramParams & { programYearId: string }>()
);

export const ManagerLinkProjectToProgramYearErrorAction = createAction(
  '[manager-projects] link project to program year error',
  props<{ error: HttpErrorResponse }>()
);

export const ManagerRemoveProjectLinkProgramYearAction = createAction(
  '[manager-projects] remove project from program year',
  props<IInstitutionManagerProgramParams & { programYearId: string, projectId: string }>()
);

export const ManagerRemoveProjectLinkProgramYearSuccessAction = createAction(
  '[manager-projects] remove project from program year success',
  props<IInstitutionManagerProgramParams & { programYearId: string, projectId: string }>()
);

export const ManagerRemoveProjectLinkProgramYearErrorAction = createAction(
  '[manager-projects] remove project from program year error',
  props<{ error: HttpErrorResponse }>()
);

export const ManagerInvalidateProgramYearProjectsAction = createAction(
  '[manager-projects] invalidate program year projects cache',
  props<IInstitutionManagerProgramParams & { programYearId: string }>()
);

export const ManagerGetProjectByIdAction = createAction(
  '[manager-projects] get project by id',
  props<IInstitutionManagerProgramParams & { projectId: string, programYearId: string }>()
);

export const ManagerGetProjectByIdSuccessAction = createAction(
  '[manager-projects] get project by id success',
  props<IInstitutionManagerProgramParams & { projectId: string, programYearId: string, project: IProjectDto }>()
);

export const ManagerGetProjectByIdErrorAction = createAction(
  '[manager-projects] get project by id error',
  props<{ error: HttpErrorResponse }>()
);

export const ManagerRemoveCountyProjectAction = createAction(
  '[manager-projects] remove county project',
  props<IInstitutionManagerProgramParams & { programYearId: string, projectId: string, countyAreaId: string }>()
);

export const ManagerRemoveCountyProjectSuccessAction = createAction(
  '[manager-projects] remove county project success',
  props<IInstitutionManagerProgramParams & { programYearId: string, projectId: string, countyAreaId: string }>()
);

export const ManagerRemoveCountyProjectErrorAction = createAction(
  '[manager-projects] remove county project error',
  props<{ error: HttpErrorResponse }>()
);

export const ManagerUpdateProjectEnrollmentRestrictionAction = createAction(
  '[manager-projects] Update project Enrollment Restriction',
  props<IInstitutionManagerProgramParams & { programYearId: string, projectId: string, projectProgramYearUpdateDto: IProjectProgramYearUpdateDto }>()
);
export const ManagerUpdateProjectEnrollmentRestrictionSuccessAction = createAction(
  '[manager-projects] Update project Enrollment Restriction success',
  props<IInstitutionManagerProgramParams & { programYearId: string, projectId: string, projectProgramYearUpdateDto: IProjectProgramYearUpdateDto }>()
);
export const ManagerUpdateProjectEnrollmentRestrictionErrorAction = createAction(
  '[manager-projects] Update project Enrollment Restriction error',
  props<{ error: HttpErrorResponse }>()
);


export const GetProjectCountyAliasesAction = createAction(
  '[manager-projects] Get county project alias',
  props<IInstitutionManagerProgramParams & { countyAreaId: string, programYearId: string }>()
);
export const GetProjectCountyAliasesSuccessAction = createAction(
  '[manager-projects] Get county project alias success',
  props<IInstitutionManagerProgramParams & { countyAreaId: string, programYearId: string, projects: IProjectDto[] }>()
);
export const GetProjectCountyAliasesErrorAction = createAction(
  '[manager-projects] Get county project alias error',
  props<{ error: HttpErrorResponse }>()
);

export const CreateProjectCountyAliasesAction = createAction(
  '[manager-projects] Create county project alias',
  props<IInstitutionManagerProgramParams & { countyAreaId: string, projectCreate: ICountyProjectCreateDto }>()
);
export const CreateProjectCountyAliasesSuccessAction = createAction(
  '[manager-projects] Create county project alias success',
  props<IInstitutionManagerProgramParams & { countyAreaId: string, projectCreate: ICountyProjectCreateDto }>()
);
export const CreateProjectCountyAliasesErrorAction = createAction(
  '[manager-projects] Create county project alias error',
  props<{ error: HttpErrorResponse }>()
);

export const UpdateProjectCountyAliasesAction = createAction(
  '[manager-projects] Update county project alias',
  props<IInstitutionManagerProgramParams & { projectId: string, countyAreaId: string, projectUpdate: ICountyProjectUpdateDto }>()
);
export const UpdateProjectCountyAliasesSuccessAction = createAction(
  '[manager-projects] Update county project alias success',
  props<IInstitutionManagerProgramParams & { projectId: string, countyAreaId: string, projectUpdate: ICountyProjectUpdateDto }>()
);
export const UpdateProjectCountyAliasesErrorAction = createAction(
  '[manager-projects] Update county project alias error',
  props<{ error: HttpErrorResponse }>()
);

export const DeleteProjectCountyAliasesAction = createAction(
  '[manager-projects] Delete county project alias',
  props<IInstitutionManagerProgramParams & { projectId: string, countyAreaId: string }>()
);
export const DeleteProjectCountyAliasesSuccessAction = createAction(
  '[manager-projects] Delete county project alias success',
  props<IInstitutionManagerProgramParams & { projectId: string, countyAreaId: string }>()
);
export const DeleteProjectCountyAliasesErrorAction = createAction(
  '[manager-projects] Delete county project alias error',
  props<{ error: HttpErrorResponse }>()
);


export const GetNationalProjectCategoriesAction = createAction(
  '[manager-projects] Get national project categories',
  props<IInstitutionProgramParams & { programYearId: string }>()
);
export const GetNationalProjectCategoriesSuccessAction = createAction(
  '[manager-projects] Get national project categories success',
  props<IInstitutionProgramParams & { programYearId: string, nationalCategories: INationalProjectCategoryDto[] }>()
);
export const GetNationalProjectCategoriesErrorAction = createAction(
  '[manager-projects] Get national project categories error',
  props<{ error: HttpErrorResponse }>()
);



export const GetExcludedCountyProjectsAction = createAction(
  '[manager-projects] Get excluded county projects',
  props<IInstitutionManagerProgramParams & { unitId: string }>()
);
export const GetExcludedCountyProjectsSuccessAction = createAction(
  '[manager-projects] Get excluded county projects success',
  props<IInstitutionManagerProgramParams & { unitId: string, projects: IProjectDto[] }>()
);
export const GetExcludedCountyProjectsErrorAction = createAction(
  '[manager-projects] Get excluded county projects error',
  props<{ error: HttpErrorResponse }>()
);


export const UpdateExcludedCountyProjectAction = createAction(
  '[manager-projects] Update excluded county projects',
  props<IInstitutionManagerProgramParams & { unitId: string, projects: string[] }>()
);
export const UpdateExcludedCountyProjectSuccessAction = createAction(
  '[manager-projects] Update excluded county projects success',
  props<IInstitutionManagerProgramParams & { unitId: string, projects: string[] }>()
);
export const UpdateExcludedCountyProjectErrorAction = createAction(
  '[manager-projects] Update excluded county projects error',
  props<{ error: HttpErrorResponse }>()
);


export const DeleteExcludedCountyProjectAction = createAction(
  '[manager-projects] Delete excluded county projects',
  props<IInstitutionManagerProgramParams & { unitId: string, projectId: string }>()
);
export const DeleteExcludedCountyProjectSuccessAction = createAction(
  '[manager-projects] Delete excluded county projects success',
  props<IInstitutionManagerProgramParams & { unitId: string, projectId: string }>()
);
export const DeleteExcludedCountyProjectErrorAction = createAction(
  '[manager-projects] Delete excluded county projects error',
  props<{ error: HttpErrorResponse }>()
);




export const GetExcludedProjectsAction = createAction(
  '[manager-projects] Get excluded projects',
  props<IInstitutionManagerProgramParams & { countyAreaId: string }>()
);
export const GetExcludedProjectsSuccessAction = createAction(
  '[manager-projects] Get excluded projects success',
  props<IInstitutionManagerProgramParams & { countyAreaId: string, projects: IProjectDto[] }>()
);
export const GetExcludedProjectsErrorAction = createAction(
  '[manager-projects] Get excluded projects error',
  props<{ error: HttpErrorResponse }>()
);

export const UpdateExcludedProjectAction = createAction(
  '[manager-projects] Update excluded projects',
  props<IInstitutionManagerProgramParams & { countyAreaId: string, projects: string[] }>()
);
export const UpdateExcludedProjectSuccessAction = createAction(
  '[manager-projects] Update excluded projects success',
  props<IInstitutionManagerProgramParams & { countyAreaId: string, projects: string[] }>()
);
export const UpdateExcludedProjectErrorAction = createAction(
  '[manager-projects] Update excluded projects error',
  props<{ error: HttpErrorResponse }>()
);


export const DeleteExcludedProjectAction = createAction(
  '[manager-projects] Delete excluded projects',
  props<IInstitutionManagerProgramParams & { countyAreaId: string, projectId: string }>()
);
export const DeleteExcludedProjectSuccessAction = createAction(
  '[manager-projects] Delete excluded projects success',
  props<IInstitutionManagerProgramParams & { countyAreaId: string, projectId: string }>()
);
export const DeleteExcludedProjectErrorAction = createAction(
  '[manager-projects] Delete excluded projects error',
  props<{ error: HttpErrorResponse }>()
);

