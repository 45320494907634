import { Action, createReducer, on } from '@ngrx/store';

import { SidebarActions } from '.';


const MOBILE_WIDTH = 1200;

export interface SidebarState {
  sidebarOpen: boolean;
  managerSidebarOpen: boolean;
  sidebarMode: string;
}

const initialState: SidebarState = {
  sidebarOpen: false,
  managerSidebarOpen: false,
  sidebarMode: undefined
};

const reducer = createReducer(
  initialState,
  on(SidebarActions.CloseSidebarAction, (state, { }) => {
    return {
      ...state,
      sidebarOpen: false,
    };
  }),
  on(SidebarActions.CloseManagerSidebarAction, (state, { }) => {
    return {
      ...state,
      managerSidebarOpen: false,
    };
  }),
  on(SidebarActions.ToggleSideBarAction, (state, { }) => {
    if (state.sidebarOpen) {
      return {
        ...state,
        sidebarOpen: false,
      };
    } else {
      return {
        ...state,
        sidebarOpen: true,
      };
    }
  }),
  on(SidebarActions.ToggleManagerSideBarAction, (state, { }) => {
    if (state.managerSidebarOpen) {
      return {
        ...state,
        managerSidebarOpen: false,
      };
    } else {
      return {
        ...state,
        managerSidebarOpen: true,
      };
    }
  }),
  on(SidebarActions.OpenSidebarIfDesktopAction, (state, { }) => {
    if (state.sidebarMode === 'side' && !window.location.href.includes('public')) {
      return {
        ...state,
        sidebarOpen: true,
        managerSidebarOpen: true
      };
    }
    return state;
  }),
  on(SidebarActions.CloseSidebarIfMobileSidebarAction, (state, { }) => {
    if (state.sidebarMode === 'over') {
      return {
        ...state,
        sidebarOpen: false,
        managerSidebarOpen: false,
      };
    }
    return state;
  }),
  on(SidebarActions.WindowWidthInitAction, (state, { width }) => {
    if (width > MOBILE_WIDTH) {
      return {
        ...state,
        sidebarMode: 'side'
      };
    }
    return {
      ...state,
      sidebarMode: 'over'
    };
  }),
  on(SidebarActions.WindowWidthChangedAction, (state, { width }) => {
    if (width > MOBILE_WIDTH) {
      return {
        ...state,
        sidebarOpen: true,
        managerSidebarOpen: true,
        sidebarMode: 'side'
      };

    } else {
      return {
        ...state,
        sidebarOpen: false,
        managerSidebarOpen: false,
        sidebarMode: 'over'
      };
    }
  })
);


export function sidebarReducer(state: SidebarState | undefined, actions: Action) {
  return reducer(state, actions);
}

export const sidebarOpen = (state: SidebarState) => state.sidebarOpen;
export const managerSidebarOpen = (state: SidebarState) => state.managerSidebarOpen;
export const sidebarMode = (state: SidebarState) => state.sidebarMode;
