import { IConsentVersionDto } from '.';
import { eConsentStatus } from '..';

export interface IEventRegistrationConsentDto {
    eventRegistrationConsentId: string;
    consentVersion: IConsentVersionDto;
    dateOfConsent: string;
    consentVersionId: string;
    recordedMemberName?: string;
    recordedParentGuardianName?: string;
    recordedManagerName: string;
    consentStatus: eConsentStatus;
}