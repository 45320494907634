<div class="modal-header">
  <h5 class="modal-title">Developer Tools</h5>
  <button (click)="close()" type="button" class="close" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" style="padding: 0">

  <mat-tab-group [(selectedIndex)]="tabIndex">

    <mat-tab label="ID Encode / Decode">
      <form [formGroup]="idEncodeDecodeForm" *ngIf="tabIndex === 0">
        <div class="col-4">
          <input formControlName="id" class=" form-control ng4h-margin-top" type="text">
          <button class="btn btn-secondary ng4h-margin" (click)="encode()" [disabled]="idEncodeDecodeForm.invalid || canEncode == false">Encode
          </button>
          <button class="btn btn-secondary ng4h-margin" (click)="decode()" [disabled]="idEncodeDecodeForm.invalid || canDecode == false">Decode
          </button>
        </div>
      </form>
    </mat-tab>

    <mat-tab label="User Info">

      <div *ngIf="tabIndex === 1" class="col-12">
        <div class="row" style="margin-top:10px;">
          <div class="col-4">
            <h5>User Info:</h5>
            <pre>{{ user | async | json }}</pre>
          </div>
          <!-- <div class="col-3">
            <h5>User Profile:</h5>
            <pre>{{userProfile | async | json}}</pre>
          </div> -->
          <div class="col-4">
            <h5>Active User Institution Profile:</h5>
            <pre>{{ activeProfile | async | json }}</pre>
            <br>
            <h5>Acting On Behalf of:</h5>
            <pre>{{ actingOnBehalfOf$ | async | json }}</pre>
          </div>
          <div class="col-4">
            <h5>Auth Headers:</h5>
            <div *ngIf="(authentication | async) == null" style="color:red">No Auth Headers</div>
            <div class="col-12" *ngIf="(authentication | async) != null">
              <b>Access Token:</b>
              <div style="word-wrap: break-word">
                {{ 'authorization: bearer ' + (authentication | async).accessToken }}
              </div>
              <b>User Account Auth Token:</b>
              <div>
                {{ (authentication | async).userAccountAuth }}
              </div>
              <b>Institution Profile Auth Token:</b>
              <div>
                <pre>{{ (institutionProfileAuth$ | async | json) }}</pre>
              </div>
              <div *ngIf="(institutionProfileAuth$ | async) == null" style="color:red">
                No Profile Auth Token
              </div>
              <br>
            </div>
          </div>
        </div>

      </div>

    </mat-tab>

    <mat-tab label="Api Docs">

      <ng-container *ngIf="tabIndex === 2">
        <div class="sidebar">
          <div class="sidebar-item" *ngFor="let documentationController of documentation | async"
               (click)="sidebarItemClicked(documentationController.controllerName)">
            {{
              documentationController.controllerName
                | camelToTitleCase
            }}
          </div>
        </div>
        <div class="sidebar-content">
          <div class="row">
            <div class="col-12">
              <div class="row" *ngFor="let documentationController of documentation | async">
                <div class="col-12">
                  <h4 class="controller-name" [attr.id]="documentationController.controllerName">
                    {{
                      documentationController.controllerName
                        | camelToTitleCase
                    }}</h4>
                  <div class="row action" *ngFor="let documentationAction of documentationController.actions">
                    <div class="col-12">
                      <div class="row endpoint-wrapper">
                        <div class="col-12 summary" *ngIf="documentationAction.summary">
                          // {{ documentationAction.summary }}
                        </div>
                        <div class="col-12 permissions">
                          <div class="row">
                            <div class="col-12" *ngFor="let permission of documentationAction.permissions">
                              <div>Required Role: {{ permission.role }}</div>
                              <div>Required Permission: {{ permission.permission }}:{{ permission.state }}</div>
                              <div>Type: {{ permission.type }}</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <span class="verb">{{ documentationAction.verb }}</span>
                          <span *ngIf="documentationAction.endpoint| replace:'{':'${' |
                        documentationQueryString:documentationAction.parameters as endpoint" class="endpoint"
                                (click)="endpointClicked(documentationAction.verb, endpoint)"> {{ endpoint }}</span>
                          <!-- <span  class="endpoint">{{documentationAction.endpoint}}</span> -->
                        </div>

                      </div>
                      <div class="row" *ngIf="documentationAction.example">
                        <div class="col-12 example">
                          Example:
                          <pre>{{ documentationAction.example }}</pre>
                        </div>
                      </div>
                      <div class="input-parameter"
                           *ngFor="let documentationParameters of documentationAction.parameters | includeByPropertyValue:'source':'Body'">
                        <!-- <pre>export interface I{{documentationParameters.type}} {{ documentationParameters.typeDefinition | documentationTypeDefinitionToInterface}}</pre> -->
                        <pre><span [innerHTML]="documentationParameters.typeDefinition"></span></pre>
                      </div>
                      <div class="row response-type" *ngFor="let responseType of documentationAction.responseTypes">
                        <div class="col-12">
                          <!-- <div class="row">
                                <div class="col-12 description">
                                  {{responseType.description}}
                                </div>
                              </div> -->
                          <div class="response-status-code">
                            <div class="col-12 description" *ngIf="responseType.description">//
                              {{ responseType.description }}
                            </div>
                            <div class="col-12 " [ngClass]="responseType.httpStatusCode.toString()">
                              {{ responseType.httpStatusCode }}
                            </div>
                          </div>
                          <div>
                            <pre><span [innerHTML]="responseType.typeDefinition"></span></pre>
                            <!-- <pre>export interface I{{responseType.type}} {{ responseType.typeDefinition | documentationTypeDefinitionToInterface}}</pre> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="col-6">
            <div class="api-network-tool">
              <h5>Network Tool</h5>
              <form [formGroup]="networkToolForm" (ngSubmit)="networkToolSend()">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <select formControlName="type" class="form-control">
                      <option value="GET">GET</option>
                      <option value="POST">POST</option>
                      <option value="PUT">PUT</option>
                      <option value="PATCH">PATCH</option>
                      <option value="DELETE">DELETE</option>
                    </select>
                  </div>
                  <input formControlName="uri" type="text" class="form-control" placeholder="/api/...">
                  <div class="input-group-append">
                    <button class="btn btn-primary" type="submit">Send</button>
                  </div>
                </div>
                <textarea #textarea [ngClass]="validJSON ? 'valid-json' : 'invalid-json'" *ngIf="networkToolForm.controls['type'].value != 'GET'"
                  formControlName="body" class="col-12 form-control" rows="10" placeholder="Body" (keydown.Tab)="textareaTab($event);"></textarea>
              </form>
              <span *ngIf="networkToolResult != null">
                <b>Result: </b>
                <pre>{{networkToolResult | json}}</pre>
              </span>
              <span *ngIf="networkToolError != null">
                <b>Error: </b>
                <pre style="color:red">{{networkToolError | json}}</pre>
              </span>
            </div>
          </div> -->
          </div>
        </div>
      </ng-container>
    </mat-tab>

    <mat-tab label="Network Tool">
      <div *ngIf="tabIndex === 3" class="col-12 network-tool">
        <div class="row" style="margin-top: 20px;">
          <div class="col-8">
            <form [formGroup]="networkToolForm" (ngSubmit)="networkToolSend()" class="col-12">
              <div class="row">
                <div class="col-2">
                  <select formControlName="type" class="form-control">
                    <option value="GET">GET</option>
                    <option value="POST">POST</option>
                    <option value="PUT">PUT</option>
                    <option value="PATCH">PATCH</option>
                    <option value="DELETE">DELETE</option>
                  </select>
                </div>
                <div class="col-8">
                  <input formControlName="uri" class="form-control" type="text" placeholder="/api/...">
                </div>
                <div class="col-2">
                  <button class="col-2 btn btn-primary" style="height:38px;" type="submit">Send</button>
                </div>
              </div>
              <div class="row">
                <textarea #textarea [ngClass]="validJSON ? 'valid-json' : 'invalid-json'"
                          *ngIf="networkToolForm.controls['type'].value != 'GET'" formControlName="body"
                          class="col-12 form-control" rows="10" placeholder="Body"
                          (keydown.Tab)="textareaTab($event);"></textarea>
              </div>
            </form>
            <span *ngIf="networkToolResult != null">
              <pre>{{ networkToolResult | json }}</pre>
            </span>
            <span *ngIf="networkToolError != null">
              <pre style="color:red">{{ networkToolError | json }}</pre>
            </span>
          </div>
          <div class="col-4">
            <div [ngClass]="item.res ? 'history-success': 'history-error'" (click)="historyClicked(item)"
                 class="history-item" *ngFor="let item of networkToolHistory | reverse">
              <b>{{ item.type + ' ' }}</b>{{ item.uri }}
            </div>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Hierarchy">
      <div *ngIf="tabIndex === 4">
        <ng4h-hierarchy-picker (selected)="selectClicked($event)">
        </ng4h-hierarchy-picker>
        <h5>Node:</h5>
        <pre>{{ leaf$ | async | json }}</pre>

        <hr>
        <button ng4hHierarchyTreeOverlay class="btn btn-primary">Test</button>
      </div>
      <!-- <ng4h-hierarchy-tree></ng4h-hierarchy-tree> -->
    </mat-tab>

    <mat-tab label="Program Settings">
      <pre *ngIf="tabIndex === 5">{{ programSettings$ | async | json }}</pre>
    </mat-tab>

    <mat-tab label="Backend Errors">
      <mat-accordion *ngIf="tabIndex === 6">
        <mat-expansion-panel *ngFor="let error of backendErrors$ | async">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ error.datestamp | date: 'short' }}: {{ error.referringPage }}
            </mat-panel-title>
            <mat-panel-description>
              {{ error.outerException | slice:0:50 }}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <pre>{{ error | json }}</pre>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-tab>

    <mat-tab label="Frontend Errors">
      <mat-accordion *ngIf="tabIndex === 7">
        <mat-expansion-panel *ngFor="let error of frontendErrors$ | async">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ error.dateStamp | date: 'short' }}: {{ error.data?.currentRoute }}
            </mat-panel-title>
            <mat-panel-description>
              {{ error.data?.userAccount?.email }}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <pre>{{ error | json }}</pre>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-tab>

  </mat-tab-group>

  <div style="text-align: center;font-size: .8rem; font-weight: bold;color: #444; margin-top: 20px;">
    <div *ngIf="apiVersion$ | async as apiVersion">
      <code>v.{{ frontEndVersion.version }} api.v.{{ apiVersion }}</code>
    </div>
    <div *ngIf="currentServer$ | async as currentServer">
      {{ currentServer }}
    </div>

  </div>

</div>
<div class="modal-footer">
  <button (click)="close()" class="btn btn-secondary my-2 my-sm-0" role="button" aria-pressed="true">Close</button>
</div>