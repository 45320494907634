export class IDynamicOption {
  optionId: string;
  label: string;
  attributes: {
    selected: boolean,
    quantityAvailable: number,
    allowWaitList: boolean
  };
  rate: number;
  reportValue?: string;
}
