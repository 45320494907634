import { eRequiredForCourseCompletionType } from '..';

export interface ITrainingLessonDto {
    trainingLessonId?: string;
    scormEngineCourseId?: string;
    sequenceIndex: number;
    required: eRequiredForCourseCompletionType;
    trainingLessonTitle?: string;
    hasPackage: boolean;
}
