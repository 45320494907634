import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { eDeliveryModeType } from 'app/core/models';
import { CommonToastrService, ModalService, RouterService, SystemManagerDeliveryModeService } from 'app/core/services';
import { take } from 'rxjs';

@Component({
  selector: 'ng4h-modal-add-delivery-mode',
  templateUrl: './modal-add-delivery-mode.component.html',
  styleUrls: ['./modal-add-delivery-mode.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalAddDeliveryModeComponent implements OnInit {

  public form: FormGroup;

  public eDeliveryModeType = eDeliveryModeType;
  constructor(
    private dialogRef: MatDialogRef<any>,
    private fb: FormBuilder,
    private deliveryModeService: SystemManagerDeliveryModeService,
    private routerService: RouterService,
    private toastrService: CommonToastrService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      type: [null, Validators.required],
      description: [],
      typeDescription: [],
      availableForGroupEnrollment: [true]
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  public save(): void {
    this.deliveryModeService.addDeliveryMode({
      institutionId: this.routerService.institutionId,
      programId: this.routerService.programId,
      systemManagerId: this.routerService.systemManagerId,
      deliveryMode: this.form.value
    }).pipe(
      take(1),
      this.modalService.showProgressSpinnerModalUntilComplete()
    ).subscribe({
      next: () => {
        this.toastrService.success('Delivery Mode Added');
        this.close();
      }, error: error => {
        this.toastrService.error('Could not add delivery mode', error);
      }
    });
  }
}
