import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EventCustomFilesService } from 'app/core/services';
import { catchError, exhaustMap, groupBy, map, mergeMap, of } from 'rxjs';

import { EventCustomFilesActions } from '.';
import { stringKeys } from '../rxjs.utils';

@Injectable()
export class EventCustomFilesEffects {

  getManagerCustomFiles = createEffect(() =>
    this.actions.pipe(
      ofType(EventCustomFilesActions.EventGetCustomFilesAction),
      groupBy(stringKeys),
      mergeMap(groupedBy => {
        return groupedBy.pipe(
          exhaustMap(payload => {
            return this.eventCustomFileService.loadCustomFilesEffect(payload)
            .pipe(
              map(customFiles => EventCustomFilesActions.EventGetCustomFilesSuccessAction({ ...payload, customFiles })),
              catchError(error => of(EventCustomFilesActions.EventGetCustomFilesErrorAction({ ...payload, error })))
            );
          })
        );
      })
    ));

  addManagerEventCustomFile = createEffect(() =>
    this.actions.pipe(
      ofType(EventCustomFilesActions.EventAddEventCustomFileAction),
      groupBy(stringKeys),
      mergeMap(groupedBy => {
        return groupedBy.pipe(
          exhaustMap(payload => {
            return this.eventCustomFileService.addCustomFileEffect(payload)
            .pipe(
              map(file => EventCustomFilesActions.EventAddEventCustomFileSuccessAction({
                ...payload,
                customFile: file
              })),
              catchError(error => of(EventCustomFilesActions.EventAddEventCustomFileErrorAction({ error })))
            );
          })
        );
      })
    ));

  clearEventCustomFile = createEffect(() =>
    this.actions.pipe(
      ofType(EventCustomFilesActions.EventDeleteCustomFileAction),
      groupBy(stringKeys),
      mergeMap(groupedBy => {
        return groupedBy.pipe(
          exhaustMap(payload => {
            return this.eventCustomFileService.deleteEventFileControlEffect(payload)
            .pipe(
              map(() => EventCustomFilesActions.EventDeleteCustomFileSuccessAction(payload)),
              catchError(error => of(EventCustomFilesActions.EventDeleteCustomFileErrorAction({ error })))
            );
          })
        );
      })
    ));

  invalidateCustomFileCache = createEffect(() =>
    this.actions.pipe(
      ofType(
        EventCustomFilesActions.EventAddEventCustomFileSuccessAction,
        EventCustomFilesActions.EventDeleteCustomFileSuccessAction,
        EventCustomFilesActions.EventUpdateCustomFileSuccessAction,
        EventCustomFilesActions.EventManagerUploadFileTemplateSuccessAction,
        EventCustomFilesActions.EventManagerDeleteFileTemplateSuccessAction
      ),
      map(payload => EventCustomFilesActions.EventInvalidateEventCustomFileCache())
    )
  );

  systemManagerUploadTemplate = createEffect(() =>
    this.actions.pipe(
      ofType(EventCustomFilesActions.EventManagerUploadFileTemplateAction),
      groupBy(stringKeys),
      mergeMap(groupedBy => {
        return groupedBy.pipe(
          exhaustMap(payload => {
            return this.eventCustomFileService.managerUploadFileTemplateEffect(payload)
            .pipe(
              map(() => EventCustomFilesActions.EventManagerUploadFileTemplateSuccessAction(payload)),
              catchError(error => of(EventCustomFilesActions.EventManagerUploadFileTemplateErrorAction({ error })))
            );
          })
        );
      })
    ));

  systemManagerDeleteTemplate = createEffect(() =>
    this.actions.pipe(
      ofType(EventCustomFilesActions.EventManagerDeleteFileTemplateAction),
      groupBy(stringKeys),
      mergeMap(groupedBy => {
        return groupedBy.pipe(
          exhaustMap(payload => {
            return this.eventCustomFileService.managerDeleteFileTemplateEffect(payload)
            .pipe(
              map(() => EventCustomFilesActions.EventManagerDeleteFileTemplateSuccessAction(payload)),
              catchError(error => of(EventCustomFilesActions.EventManagerDeleteFileTemplateErrorAction({ error })))
            );
          })
        );
      })
    ));

  eventCustomFileUpdate = createEffect(() =>
    this.actions.pipe(
      ofType(EventCustomFilesActions.EventUpdateCustomFileAction),
      groupBy(stringKeys),
      mergeMap(groupedBy => {
        return groupedBy.pipe(
          exhaustMap(payload => {
            return this.eventCustomFileService.updateEventFileControlEffect(payload)
            .pipe(
              map(() => EventCustomFilesActions.EventUpdateCustomFileSuccessAction(payload)),
              catchError(error => of(EventCustomFilesActions.EventUpdateCustomFileErrorAction({ error })))
            );
          })
        );
      })
    ));

  constructor(private actions: Actions, private eventCustomFileService: EventCustomFilesService) {
  }
}
