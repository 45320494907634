import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ePermissionState, IPermissionType } from 'app/core/models';

@Component({
  selector: 'ng4h-modal-update-permissions',
  templateUrl: './modal-update-permissions.component.html',
  styleUrls: ['./modal-update-permissions.component.scss']
})

export class ModalUpdatePermissionsComponent implements OnInit {

  public permissionTypes: IPermissionType[];
  public user: any;
  public grantedPermissions: any;

  public form: FormGroup;

  constructor(private dialogRef: MatDialogRef<any>, private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { permissionTypes: IPermissionType[], grantedPermissions: any, user: any }) { }

  public ngOnInit(): void {
    this.form = this.fb.group({});

    this.permissionTypes = this.data.permissionTypes.slice();
    this.user = this.data.user;
    this.grantedPermissions = this.data.grantedPermissions;

    this.permissionTypes.forEach(permissionType => {
      this.form.addControl(permissionType.permission, this.fb.control(this.grantedPermissions == null || this.grantedPermissions[permissionType.permission] === null ? ePermissionState.None : this.grantedPermissions[permissionType.permission]));
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  public save(): void {

    this.dialogRef.close(this.form.value);
  }
}
