import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import {
  AutoUnsubscribe,
  eEventSessionType,
  eEventStatus,
  eFormControlType,
  IBankAccountDescriptorDto,
  IEventDto
} from 'app/core/models';
import {
  BankAccountsService,
  CommonToastrService,
  EventsManagerService,
  ModalService,
  RegistrationmaxTicketingService,
  RouterService
} from 'app/core/services';
import { DateValidators } from 'app/core/validators';
import { filter, Observable, take, takeUntil } from 'rxjs';

import {
  ModalEnableRegistrationmaxTicketingComponent
} from '../../modals/modal-enable-registrationmax-ticketing/modal-enable-registrationmax-ticketing.component';

@Component({
  selector: 'ng4h-modal-edit-event',
  templateUrl: './modal-edit-event.component.html',
  styleUrls: ['./modal-edit-event.component.scss']
})
export class ModalEditEventComponent extends AutoUnsubscribe implements OnInit {

  public eEventSessionType = eEventSessionType;
  public eFormControlType = eFormControlType;
  public eEventStatus = eEventStatus;
  public eventForm: FormGroup;
  public event$: Observable<IEventDto>;

  public bankAccounts$: Observable<IBankAccountDescriptorDto[]>;

  constructor(
    private dialogRef: MatDialogRef<ModalEditEventComponent>,
    private routerService: RouterService,
    private eventsManagerService: EventsManagerService,
    private fb: FormBuilder,
    private toastrService: CommonToastrService,
    private bankAccountsService: BankAccountsService,
    private modalService: ModalService,
    private registrationmaxTicketingService: RegistrationmaxTicketingService
  ) {
    super();
    this.event$ = this.eventsManagerService.getEvent({
      institutionId: this.routerService.institutionId,
      managerId: this.routerService.managerId,
      programId: this.routerService.programId,
      eventId: this.routerService.eventId
    }).pipe(
      filter(event => event != null),
      take(1)
    );

    this.bankAccounts$ = this.bankAccountsService.getManagerBankAccounts({
      institutionId: this.routerService.institutionId,
      managerId: this.routerService.managerId,
      programId: this.routerService.programId,
      hierarchyNodeId: this.routerService.managerHierarchyId
    });
  }

  ngOnInit() {
    this.event$.subscribe(event => {
      this.eventForm = this.fb.group({
        eventName: [event.eventName],
        eventDescription: [event.eventDescription],
        status: [event.eventStatus],
        enableSessions: [event.enableSessions === true],
        enableTicketing: [event.enableTicketing === true],
        allowMultipleRegistrations: [event.allowMultipleRegistrations === true],
        allowRegistrationByManagerOnly: [event.allowRegistrationByManagerOnly],
        paymentMethod_Credits_On: [event.paymentMethod_Credits_On === true],
        paymentMethod_Electronic_On: [event.paymentMethod_Electronic_On === true],
        sessionType: [{ value: event.sessionType, disabled: event.enableSessions !== true }],
        //  bankAccountId: [event.bankAccountId],
        requireCountyAreaApproval: [event.requireCountyAreaApproval === true],
        requireDistrictAreaApproval: [event.requireDistrictAreaApproval === true],
        requireRegionAreaApproval: [event.requireRegionAreaApproval === true],
        requireProgramLevelApproval: [event.requireProgramLevelApproval === true],
        programYear: [event.programYear],
        startDate: [event.startDate, [DateValidators.AppMin, DateValidators.AppMax]],
        endDate: [event.endDate, [DateValidators.AppMin, DateValidators.AppMax]],
        disableAutomatedEmails: [event.disableAutomatedEmails],
        creditCardFeeRecovery: [event.creditCardFeeRecovery === true]
      });

      this.eventForm.controls.enableSessions.valueChanges.pipe(
        takeUntil(this.autoUnsubscribe)
      ).subscribe(enableSessions => {
        if (enableSessions) {
          this.eventForm.controls.sessionType.setValue(eEventSessionType.Session);
          this.eventForm.controls.sessionType.enable();
        } else {
          this.eventForm.controls.sessionType.setValue(null);
          this.eventForm.controls.sessionType.disable();
        }
      });
    });
  }

  public save() {
    this.eventsManagerService.updateEvent({
      institutionId: this.routerService.institutionId,
      managerId: this.routerService.managerId,
      programId: this.routerService.programId,
      eventId: this.routerService.eventId,
      eventUpdateDto: this.eventForm.value
    }).subscribe({
      next: () => {
        this.toastrService.success('update event success');
        this.close();
      }, error: (error) => {
        this.toastrService.error('event update failed');
      }
    });
  }

  public close() {
    this.dialogRef.close();
  }

  public openTicketingModal() {
    this.modalService.openModal(ModalEnableRegistrationmaxTicketingComponent).pipe(
      take(1),
      filter(res => res === true)
    ).subscribe(() => {
      this.registrationmaxTicketingService.enableTicketing({
        institutionId: this.routerService.institutionId,
        managerId: this.routerService.managerId,
        programId: this.routerService.programId,
        eventId: this.routerService.eventId
      }).pipe(
        take(1),
        this.modalService.showProgressSpinnerModalUntilComplete()
      ).subscribe({
        next: () => {
          this.toastrService.success('Ticketing Enabled');
          this.close();
        },
        error: (err) => {
          this.toastrService.success('Could not enable ticketing');
        }
      });
    });
  }

  public disableTicketing() {
    this.modalService.confirm({ header: 'Are You Sure?', body: 'Are you sure you want to disable ticketing?' }).pipe(
      take(1),
      filter(res => res === true)
    ).subscribe(() => {

      this.registrationmaxTicketingService.disableTicketing({
        institutionId: this.routerService.institutionId,
        managerId: this.routerService.managerId,
        programId: this.routerService.programId,
        eventId: this.routerService.eventId
      }).pipe(
        take(1),
        this.modalService.showProgressSpinnerModalUntilComplete()
      ).subscribe({
        next: () => {
          this.toastrService.success('Ticketing Disabled');
          this.close();
        },
        error: (err) => {
          this.toastrService.success('Could not disable ticketing');
        }
      });

    });
  }

}
