import { IEnrollmentFeeWaiverRequestSearchResultDto } from '.';

export interface IEnrollmentFeeWaiverRequestSearchResultsDto {
    results?: IEnrollmentFeeWaiverRequestSearchResultDto[];
    resultsCount: number;
    pageSize?: number;
    pageIndex?: number;
    pageCount: number;
    startingResultRangeRecordIndex?: number;
    endingResultRangeRecordIndex?: number;
}
