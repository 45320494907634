import { Pipe, PipeTransform } from '@angular/core';
import { IProjectDto } from 'app/core/models';
import { ManagerProjectsService, RouterService } from 'app/core/services';
import { filter, map, Observable } from 'rxjs';

@Pipe({
    name: 'project$',
    pure: true
})
export class ProjectPipe implements PipeTransform {
    constructor(private managerProjectsService: ManagerProjectsService, private routerService: RouterService) {
    }

    public transform(projectId: string, institutionId: string = this.routerService.institutionId, managerId: string = this.routerService.managerId, programId: string = this.routerService.programId): Observable<IProjectDto> {
        return this.managerProjectsService.getProjects({
            institutionId: institutionId,
            managerId,
            programId
        }).pipe(
            filter(c => Array.isArray(c)),
            map(projects => projects.find(project => project.id === projectId))
        );
    }
}
