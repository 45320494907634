import { eBroadcastEmailRecipientType } from '..';

export interface IBroadcastEmailOptionsDto {
  recipientType: eBroadcastEmailRecipientType;
  userManagerEmailForReplyTo: boolean;
  sendTextMessageNotification: boolean;
  includeAllFamilies: boolean;
  includeEmergencyContact: boolean;
  includeGuardian: boolean;
  includeUnsubscribed: boolean;
  managersIncludeRegions: boolean;
  managersIncludeDistricts: boolean;
  managersIncludeCounties: boolean;
  managersIncludeClubs: boolean;
}
