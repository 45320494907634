import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
    IGroupCreateDto,
    IGroupDto,
    IGroupUpdateDto,
    IInstitutionManagerProgramHierarchyParams,
    IInstitutionManagerProgramParams,
} from 'app/core/models';

export const LoadGroupsAction = createAction(
    '[manager groups] Load groups',
    props<IInstitutionManagerProgramHierarchyParams>()
);
export const LoadGroupsSuccessAction = createAction(
    '[manager groups] Load groups success',
    props<IInstitutionManagerProgramHierarchyParams & { groups: IGroupDto[] }>()
);
export const LoadGroupsErrorAction = createAction(
    '[manager groups] Load groups error',
    props<{ error: HttpErrorResponse }>()
);

export const LoadGroupAction = createAction(
    '[manager activities] Load group',
    props<IInstitutionManagerProgramParams & { groupId: string }>()
);
export const LoadGroupSuccessAction = createAction(
    '[manager activities] Load group success',
    props<IInstitutionManagerProgramParams & { groupId: string, group: IGroupDto }>()
);
export const LoadGroupErrorAction = createAction(
    '[manager activities] Load group error',
    props<{ error: HttpErrorResponse }>()
);


export const CreateGroupAction = createAction(
    '[manager activities] Create group',
    props<IInstitutionManagerProgramParams & { groupCreate: IGroupCreateDto }>()
);
export const CreateGroupSuccessAction = createAction(
    '[manager activities] Create group success',
    props<IInstitutionManagerProgramParams & { groupCreate: IGroupCreateDto }>()
);
export const CreateGroupErrorAction = createAction(
    '[manager activities] Create group error',
    props<{ error: HttpErrorResponse }>()
);

export const UpdateGroupAction = createAction(
    '[manager activities] Update group',
    props<IInstitutionManagerProgramParams & { groupId: string, groupUpdate: IGroupUpdateDto }>()
);
export const UpdateGroupSuccessAction = createAction(
    '[manager activities] Update group success',
    props<IInstitutionManagerProgramParams & { groupId: string, groupUpdate: IGroupUpdateDto }>()
);
export const UpdateGroupErrorAction = createAction(
    '[manager activities] Update group error',
    props<{ error: HttpErrorResponse }>()
);

export const DeleteGroupAction = createAction(
    '[manager activities] Delete group',
    props<IInstitutionManagerProgramParams & { groupId: string }>()
);
export const DeleteGroupSuccessAction = createAction(
    '[manager activities] Delete group success',
    props<IInstitutionManagerProgramParams & { groupId: string }>()
);
export const DeleteGroupErrorAction = createAction(
    '[manager activities] Delete group error',
    props<{ error: HttpErrorResponse }>()
);
