import { Pipe, PipeTransform } from '@angular/core';
import { ISchoolDistrictDto } from 'app/core/models';
import { RouterService, SchoolService } from 'app/core/services';
import { filter, map, Observable } from 'rxjs';

@Pipe({
    name: 'schoolDistrict$',
    pure: true
})
export class SchoolDistrictPipe implements PipeTransform {
    constructor(private schoolService: SchoolService, private routerService: RouterService) {
    }

    public transform(districtId: string, countyId: string, institutionId: string = this.routerService.institutionId): Observable<ISchoolDistrictDto> {
        return this.schoolService.getSchoolDistricts({ institutionId, countyId }).pipe(
            filter(d => Array.isArray(d)),
            map(districts => districts.find(district => district.id === districtId))
        );
    }
}

