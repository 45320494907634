import {
  eEventSessionType,
  eEventStatus,
  ICountyDescriptorDto,
  IEventACLDto,
  IEventSessionDto,
  IEventVanityDomainDto,
  IHierarchyNodeDto,
  IManagerDescriptorDto
} from '..';
import { IEventRegistrationTypeDto } from './IEventRegistrationTypeDto';

export interface IEventDto {
  eventDescription?: string;
  logoUrl?: string;
  splashUrl?: string;
  bankAccountId: string;
  enableSessions: boolean;
  enableTicketing: boolean;
  ticketingSystemId: string;
  sessionType?: eEventSessionType;
  paymentMethod_Credits_On: boolean;
  paymentMethod_Electronic_On: boolean;
  requireCountyAreaApproval: boolean;
  requireDistrictAreaApproval: boolean;
  requireRegionAreaApproval: boolean;
  requireProgramLevelApproval: boolean;
  maximumRegistrations?: number;
  unlimited: boolean;
  registrationTypes?: IEventRegistrationTypeDto[];
  eventSessions?: IEventSessionDto[];
  hierarchyNodes?: IHierarchyNodeDto[];
  eventManagers?: object;
  vanityDomains?: IEventVanityDomainDto[];
  eventId: string;
  eventName?: string;
  allowMultipleRegistrations: boolean;
  allowRegistrationByManagerOnly: boolean;
  startDate: string;
  endDate: string;
  eventStatus: eEventStatus;
  hierarchyNode: IHierarchyNodeDto;
  programId: string;
  institutionId: string;
  programYear: string;
  activeManagerPermission: IEventACLDto;
  registeredInEvent?: boolean;
  inActiveHierarchy: boolean;
  disableAutomatedEmails?: boolean;

  emailConfiguration: {
    sendRegistrationSubmitted?: boolean;
    sendRegistrationMovedToNextLevel?: boolean;
    sendRegistrationApproved?: boolean;
    sendRegistrationDeclined?: boolean;
    sendRegistrationSentBack?: boolean;
    sendCheckPaymentDue?: boolean;
  };

  creditCardFeeRecovery: boolean;
  hasParticipationTypes: boolean;

  requireCountyArea: boolean;
  availableCounties?: ICountyDescriptorDto[];

  isCurrentManagersEvent: boolean;

  createdBy: IManagerDescriptorDto;
  dateCreated?: string;
  eventInstructions: string;

}
