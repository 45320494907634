import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit } from '@angular/core';
import {
  AutoUnsubscribe,
  ePageMessage,
  eUnitType,
  IEnrollmentUnitDto,
  IProgramPageMessageDto,
  IProgramSettingsDto,
} from 'app/core/models';
import { EnrollmentService, ModalService, ProfileService, RouterService } from 'app/core/services';
import { ProgramSettingsService } from 'app/core/services/program-settings.service';
import { combineLatest, map, Observable, startWith, takeUntil } from 'rxjs';

import { ModalAddClubsComponent } from '../../modal-add-clubs/modal-add-clubs.component';

@Component({
  selector: 'ng4h-add-clubs',
  templateUrl: './add-clubs.component.html',
  styleUrls: ['./add-clubs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddClubsComponent extends AutoUnsubscribe implements OnInit {

  public eUnitType = eUnitType;
  @Input() allowEdit = true;
  public isManager$: Observable<boolean>;
  public selectedUnits$: Observable<IEnrollmentUnitDto[]>;

  public closeClubsPageMessage: EventEmitter<boolean> = new EventEmitter<boolean>();
  public clubsPageMessage$: Observable<IProgramPageMessageDto>;

  public programSettings$: Observable<IProgramSettingsDto>;

  private args: any;

  constructor(
    private enrollmentService: EnrollmentService,
    private modalService: ModalService,
    private routerService: RouterService,
    private profileService: ProfileService,
    private programSettingsService: ProgramSettingsService
  ) {
    super();
    this.args = {
      institutionId: this.routerService.institutionId,
      memberId: this.routerService.memberId ? this.routerService.memberId : this.routerService.selectedMemberId,
      programId: this.routerService.programId,
      enrollmentId: this.routerService.enrollmentId
    };
    this.programSettings$ = this.programSettingsService.getProgramSettings({ institutionId: this.routerService.institutionId, programId: this.routerService.programId });
    this.isManager$ = this.profileService.activeUserInstitutionProfileIsManager$;

    this.selectedUnits$ = this.enrollmentService.getEnrollment({ ...this.args }).pipe(
      map(enrollment => enrollment.units),
      takeUntil(this.autoUnsubscribe));

    this.clubsPageMessage$ = combineLatest([
      this.isManager$,
      this.programSettingsService.getPageMessageByType({ institutionId: this.routerService.institutionId, programId: this.routerService.programId, typeId: ePageMessage.EnrollmentClubs }),
      this.closeClubsPageMessage.pipe(startWith(false)),
      this.selectedUnits$
    ]).pipe(
      map(([isManager, clubsPageMessage, closeClubsPageMessage, selectedClubs]) => {
        if (isManager || clubsPageMessage == null || closeClubsPageMessage || (Array.isArray(selectedClubs) && selectedClubs.length > 0)) {
          return null;
        }
        return clubsPageMessage;
      })
    );
  }

  ngOnInit() {

  }

  openAddClubModal() {
    this.modalService.openModal(ModalAddClubsComponent);
  }

  removeClub(club: IEnrollmentUnitDto) {
    this.enrollmentService.removeClub({ ...this.args, unitId: club.unitId });
  }

  public closeHelp() {
    this.closeClubsPageMessage.emit(true);
    this.openAddClubModal();
  }

  primaryClubChange(club: IEnrollmentUnitDto) {
    this.modalService.confirm({ header: 'Confirm Primary Club Change', body: 'Are you sure you want to change your primary club to ' + club.unitName + '?' }).subscribe(confirmed => {
      if (confirmed) {
        this.enrollmentService.primaryClubChange({ ...this.args, club });
      }
    });
  }

}
