import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IProjectDto } from 'app/core/models';

@Component({
  selector: 'ng4h-modal-edit-project',
  templateUrl: 'modal-edit-project.component.html',
  styleUrls: ['./modal-edit-project.component.scss']
})

export class ModalEditProjectComponent implements OnInit {

  public form: FormGroup;
  public project: IProjectDto;
  public wasValidated = false;
  constructor(private dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder) { }

  public ngOnInit(): void {
    this.project = this.data.project;

    this.form = this.fb.group({
      id: [this.project.id],
      projectName: [this.project.projectName],
      projectDescription: [this.project.projectDescription],
      url: [this.project.url]
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  public save(): void {
    if (this.form.invalid) {
      this.wasValidated = true;
      return;
    }
    this.dialogRef.close(this.form.value);
  }
}
