export enum ePageMessage {
    InstitutionLogin = 'Institution_Login',
    FamilyDashboard = 'Family_Dashboard',
    FamilyProfile = 'Family_Profile',
    MemberDashboard = 'Member_Dashboard',
    MemberProfile = 'Member_Profile',
    ManagerDashboard = 'Manager_Dashboard',
    ManagerProfile = 'Manager_Profile',
    EnrollmentAdditional = 'Enrollment_Additional',
    EnrollmentClubs = 'Enrollment_Clubs',
    EnrollmentGroups = 'Enrollment_Groups',
    EnrollmentProjects = 'Enrollment_Projects',
    EnrollmentPayment = 'Enrollment_Payment',
    MemberProgramVolunteerScreening = 'MemberProgram_VolunteerScreening',
    Enrollment_VolunteerType = 'Enrollment_VolunteerType',
    MemberProgramHealthForm = 'MemberProgram_HealthForm',
    EnrollmentConsents = 'Enrollment_Consents',
    EnrollmentCamps = 'Enrollment_Camps',
    EnrollmentSchoolGroups = 'Enrollment_SchoolGroups',
    // I added these, they don't exist as of now
    EventAdditional = 'Event_Additional',
    EventConsents = 'Event_Consents',
    EventPayments = 'Event_Payment',

}
