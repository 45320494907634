export enum eLoginStatus {
    Error = 'Error',
    Success = 'Success',
    InvalidPassword = 'InvalidPassword',
    InvalidUserName = 'InvalidUserName',
    AccountLocked = 'AccountLocked',
    AccountInactive = 'AccountInactive',
    PasswordExpired = 'PasswordExpired',
    PasswordChangeRequired = 'PasswordChangeRequired',
}
