import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ProgramSettingsService } from 'app/core/services/program-settings.service';
import { catchError, exhaustMap, groupBy, map, mergeMap, of } from 'rxjs';

import { ProgramSettingsActions } from '.';
import { stringKeys } from '../rxjs.utils';

@Injectable()
export class ProgramSettingsEffects {

    loadProgramSettings = createEffect(() => this.actions.pipe(
        ofType(ProgramSettingsActions.ProgramSettingsLoadAction),
        groupBy(stringKeys),
        mergeMap(groupedBy => {
            return groupedBy.pipe(
                exhaustMap(payload => {
                    return this.programSettingsService.loadProgramSettingsEffect(payload).pipe(
                        map(settings => ProgramSettingsActions.ProgramSettingsLoadSuccessAction({ ...payload, settings: settings })),
                        catchError(error => of(ProgramSettingsActions.ProgramSettingsLoadErrorAction({ error })))
                    );
                })
            );
        })
    ));

    updateProgramSettings = createEffect(() => this.actions.pipe(
        ofType(ProgramSettingsActions.ProgramSettingsSystemManagerUpdateAction),
        groupBy(stringKeys),
        mergeMap(groupedBy => {
            return groupedBy.pipe(
                exhaustMap(payload => {
                    return this.programSettingsService.saveSystemManagerProgramSettingsEffect(payload).pipe(
                        map(() => ProgramSettingsActions.ProgramSettingsSystemManagerUpdateSuccessAction({ ...payload })),
                        catchError(error => of(ProgramSettingsActions.ProgramSettingsSystemManagerUpdateErrorAction({ error })))
                    );
                })
            );
        })
    ));

    updateSuperuserProgramSettings = createEffect(() => this.actions.pipe(
        ofType(ProgramSettingsActions.ProgramSettingsSuperuserUpdateAction),
        groupBy(stringKeys),
        mergeMap(groupedBy => {
            return groupedBy.pipe(
                exhaustMap(payload => {
                    return this.programSettingsService.saveSuperuserProgramSettingsEffect(payload).pipe(
                        map(() => ProgramSettingsActions.ProgramSettingsSuperuserUpdateSuccessAction({ ...payload })),
                        catchError(error => of(ProgramSettingsActions.ProgramSettingsSuperuserUpdateErrorAction({ error })))
                    );
                })
            );
        })
    ));



    updateProgramSettingsSuccess = createEffect(() => this.actions.pipe(
        ofType(ProgramSettingsActions.ProgramSettingsSystemManagerUpdateSuccessAction, ProgramSettingsActions.ProgramSettingsSuperuserUpdateSuccessAction),
        map(payload => ProgramSettingsActions.ProgramSettingsInvalidateCacheAction({ institutionId: payload.institutionId, programId: payload.programId }))
    ));
    constructor(private actions: Actions, private programSettingsService: ProgramSettingsService) { }
}
