import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'includeByPropertyValue',
    pure: true
})

export class IncludeByPropertyValuePipe implements PipeTransform {
    transform(arr: any[], key: string, value: string) {
        return arr.filter(item => item[key] === value);
    }
}
