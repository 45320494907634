import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IHierarchyCountyArea } from 'app/core/models';

export const CountyAreasLoadAction = createAction(
  '[county-areas] Load',
  props<{ institutionId: string, programId: string }>()
);
export const CountyAreasLoadSuccessAction = createAction(
  '[county-areas] Load success',
  props<{ institutionId: string, programId: string, countyAreas: IHierarchyCountyArea[] }>()
);
export const CountyAreasLoadErrorAction = createAction(
  '[county-areas] Load error',
  props<{ error: HttpErrorResponse }>()
);

