import {
    IFamilyDto,
    IFamilyInstitutionProfile,
    IManagerInstitutionProfile,
    IMemberDto,
    IMemberInstitutionProfile,
    IReportConsumerInstitutionProfile,
    ISuperUserInstitutionProfile,
    ISystemManagerInstitutionProfile
} from '.';

export type IAnyInstitutionProfile =
    IMemberInstitutionProfile
    | IFamilyInstitutionProfile
    | IManagerInstitutionProfile
    | ISystemManagerInstitutionProfile
    | ISuperUserInstitutionProfile
    | IFamilyDto
    | IMemberDto
    | IReportConsumerInstitutionProfile;

