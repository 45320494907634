import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectionStrategy, Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AutoUnsubscribe, eEnrollmentRoleType, eEnrollmentStatus, eMemberProgramYearStatus } from 'app/core/models';
import { eMemberParticipationStatus } from 'app/core/models/enums/eMemberParticipationStatus';
import { RouterService } from 'app/core/services';
import { ProgramSettingsService } from 'app/core/services/program-settings.service';
import { formatDate, isDate, isDefaultDate, resolveObjectPath } from 'app/shared/utils';
import { filter, map, Observable, Subject, takeUntil } from 'rxjs';

import { IProgramSettingsDto } from '../../models';

@Component({
    selector: 'ng4h-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableComponent extends AutoUnsubscribe implements OnInit {

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    @Input() columns: Map<string, string>;
    @Input() columnTransforms: Map<string, Function>;
    @Input() transformData = true;
    @Input() dataSource: Observable<any>;
    @Input() highlightSelected = false;
    @Input() sortable = true;
    @Input() sortProperty: string;

    @Input() headersToTitleCase = true;

    @Output() rowClicked: Subject<any> = new Subject<any>();
    @Output() selectionChange: Subject<any> = new Subject<any>();

    public matTableDataSource = new MatTableDataSource();
    public displayedColumns: Array<string>;
    public selection = new SelectionModel<any>(true, []);
    public columnCount: number;
    public hidePaging = true;
    public pageSize = 100;
    private settings: IProgramSettingsDto;
    private enrollmentRoles = Object.keys(eEnrollmentRoleType);

    public resolveObjectPath = resolveObjectPath;

    constructor(
        private programSettingsService: ProgramSettingsService,
        private routerService: RouterService) {
        super();
        this.matTableDataSource.data = [];
    }

    public ngOnInit(): void {
        this.matTableDataSource.paginator = this.paginator;
        this.matTableDataSource.sort = this.sort;
        const titleCaseColumns = new Map<string, string>();
        for (const c of Array.from(this.columns.keys())) {
            titleCaseColumns.set(c, this.toTitleCase(this.columns.get(c)));
        }
        this.columns = titleCaseColumns;

        this.displayedColumns = Array.from(this.columns.keys());

        this.dataSource.pipe(
            filter(data => data !== null && data !== undefined),
            map(data => this.transformRowData(data)),
            takeUntil(this.autoUnsubscribe)
        ).subscribe((data) => {
            this.matTableDataSource.data = data;
            this.hidePaging = !(data.length > this.pageSize);
        });

        this.selection.changed.subscribe(selection => {
            this.selectionChange.next(selection.source.selected);
        });
    }

    public rowClick(row): void {
        this.rowClicked.next(row);
    }

    public isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.matTableDataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    public masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.matTableDataSource.data.forEach(row => this.selection.select(row));
    }

    /** The label for the checkbox on the passed row */
    public checkboxLabel(row?: any): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
    }

    private transformRowData(data: any[]) {
        if (!!data && data.length > 0 && this.transformData) {
            const d = [];
            data.forEach(r => {
                const p = { ...r };
                this.columns.forEach((v, e) => {
                    const value = r[e];
                    let customTransform = null;
                    if (!!this.columnTransforms) {
                        customTransform = this.columnTransforms.get(e);
                    }
                    if (typeof customTransform === 'function') {
                        p[e] = customTransform(value);
                    } else if (Array.isArray(value)) {
                        const arrayValues = [];
                        value.forEach(x => {
                            arrayValues.push(this.defaultTransform(x));
                        });
                        p[e] = arrayValues.join(', ');
                    } else {
                        p[e] = this.defaultTransform(value);
                    }
                });
                d.push(p);
            });
            return d;
        }
        return data;
    }

    private defaultTransform(value: any): string {
        if (typeof value === 'boolean') {
            return value ? 'Yes' : 'No';
        }
        if (isDefaultDate(value)) {
            return '';
        }
        if (isDate(value)) {
            return formatDate(new Date(value));
        }
        if (this.isTransformableEnum(value)) {
            return this.withProgramSettings(value);
        }
        return value;
    }

    private withProgramSettings(value: any): string {
        if (!!!this.settings || !this.settings.hasOwnProperty('aliases')) {
            let transformedValue: string;
            this.programSettingsService.getProgramSettings({
                    institutionId: this.routerService.institutionId,
                    programId: this.routerService.programId
                })
                .subscribe(settings => {
                    this.settings = settings;
                    transformedValue = this.enumTransform(value);
                })
                .unsubscribe();
            return transformedValue;
        }
        return this.enumTransform(value);
    }

    private enumTransform(value: any): string {
        // Enrollment Status
        if (value === eEnrollmentStatus.RejectAndLocked) {
            return this.settings.enrollmentStatuses[eEnrollmentStatus.RejectAndLocked];
        }
        if (value === eMemberParticipationStatus.NotEnrolled) {
            return 'Not Enrolled';
        }
        if (value === eMemberParticipationStatus.PaymentDue) {
            return 'Payment Due';
        }
        if (value === eMemberParticipationStatus.ScreeningPendingApproval) {
            return 'Screening Pending';
        }
        if (value === eMemberParticipationStatus.Resubmit) {
            return 'Re-Submit';
        }
        if (value === eMemberParticipationStatus.DeclinedAndBlocked) {
            return 'Declined and Blocked';
        }
        if (value === eMemberParticipationStatus.AwaitingReview) {
            return 'Awaiting Review';
        }
        if (value === eMemberParticipationStatus.TrainingsNotCompleted) {
            return 'Trainings Not Complete';
        }
        if (value === eMemberParticipationStatus.NotEnrolledActiveLastYear) {
            return 'Not Enrolled - Active Last Year';
        }
        // Member Program Year Status
        if (value === eMemberProgramYearStatus.RequirementsNotMet) {
            return this.settings.memberProgramYearStatuses[eMemberProgramYearStatus.RequirementsNotMet];
        }
        let enumString = value;
        // Enrollment Roles
        this.enrollmentRoles.forEach(k => {
            if (value === k) {
                enumString = this.settings.aliases[eEnrollmentRoleType[k]].singular;
                return;
            }
        });
        return enumString;
    }

    private isTransformableEnum(value: any) {
        const regex = /(^Volunteer$|^CloverBud$|^ClubMember$|^RejectAndLocked$|^RequirementsNotMet$|^ScreeningPendingApproval$|^Resubmit$|^DeclinedAndBlocked$|^PaymentDue$|^AwaitingReview$|^TrainingsNotCompleted$|^NotEnrolled$)/;
        if (!value || value.length === 0) {
            return false;
        }
        return regex.test(value);
    }

    private toTitleCase(str: string) {
        if (!str || !this.headersToTitleCase) {
            return str;
        }
        return str.replace(
            /\w\S*/g,
            function(txt: any) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    }
}
