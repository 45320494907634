import { Action, createReducer, on } from '@ngrx/store';
import { IConsentVersionDto, IHealthFormDto } from 'app/core/models';
import { mergeImmutable } from 'app/shared/utils';

import { HealthFormActions } from '.';


export interface SystemManagerHealthState {
  healthForm: { [institutionId: string]: { [systemManagerId: string]: { [programId: string]: { [type: string]: IHealthFormDto } } } };
  consent: { [institutionId: string]: { [systemManagerId: string]: { [programId: string]: { [type: string]: IConsentVersionDto[] } } } };
}

const initialState: SystemManagerHealthState = {
  healthForm: undefined,
  consent: undefined
};

const reducer = createReducer(
  initialState,
  on(HealthFormActions.SystemManagerGetHealthFormSuccessAction, (state, { institutionId, systemManagerId, programId, healthFormType, healthForm }) => {
    const newHealthForms = mergeImmutable(
      { [institutionId]: { [systemManagerId]: { [programId]: { [healthFormType]: healthForm } } } },
      state.healthForm
    );
    return {
      ...state,
      healthForm: newHealthForms
    };
  }),
  on(HealthFormActions.SystemManagerGetConsentSuccessAction, (state, { institutionId, systemManagerId, programId, healthFormType, consent }) => {
    return {
      ...state,
      consent: mergeImmutable(
        { [institutionId]: { [systemManagerId]: { [programId]: { [healthFormType]: consent } } } },
        state.consent
      )
    };
  }),
  on(HealthFormActions.SystemManagerInvalidateHealthFormCacheAction, HealthFormActions.SystemManagerInvalidateHealthFormConsentCacheAction, (state, { }) => {
    return initialState;
  })
);

export function systemMangerHealthReducer(state: SystemManagerHealthState | undefined, actions: Action) {
  return reducer(state, actions);
}


export const healthForm = (state: SystemManagerHealthState) => state.healthForm;
export const healthFormConsent = (state: SystemManagerHealthState) => state.consent;

