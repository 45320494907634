import { eUserAccountStatus } from '../enums';
import { IAnyInstitutionProfile } from '../IAnyInstitutionProfile';


export interface IUserAccountDto {
  emailConfirmed: boolean;
  superuserId?: string;
  institutionProfiles?: IAnyInstitutionProfile[];
  userAccountId: string;
  token: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  userAccountStatus: eUserAccountStatus;
  profileTypes?: any[];
  god: boolean;
}
