import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AutoUnsubscribe, eFormControlType, eStandardFieldStatus } from 'app/core/models';
import { IStandardFormColumnDto } from 'app/core/models/serverDTOs/IStandardFormColumnDto';


@Component({
  selector: 'ng4h-display-standard-form',
  templateUrl: './display-standard-form.component.html',
  styleUrls: ['./display-standard-form.component.scss']
})
export class DisplayStandardFormComponent extends AutoUnsubscribe implements OnInit {

  public eFormControlType = eFormControlType;

  @Input() fields: { [filedId: string]: IStandardFormColumnDto[] };
  @Input() showHeader: boolean = true;

  // public healthFormOptions: Observable<IMemberHealthFormDto>;
  @Input() form: FormGroup;

  // @Output() healthFormChanged: EventEmitter<FormGroup>;
  public eStandardFieldStatus = eStandardFieldStatus;
  constructor() {
    super();

  }

  ngOnInit() {


  }

  // Here we go backwards until we hit a visible input
  public shouldBreakBefore(section: string, inputIndex: number) {
    if (this.fields == null) {
      return false;
    }
    const inputs = this.fields[section];
    for (let i = inputIndex - 1; i >= 0; i--) {
      if (inputs[i].inputState === eStandardFieldStatus.Visible) {
        return inputs[i].inputType !== eFormControlType.Checkbox;
      }
    }
    return false;
  }
  // Here we go forwards until we hit a visible input
  public shouldBreakAfter(section: string, inputIndex: number) {
    if (this.fields == null) {
      return false;
    }
    const inputs = this.fields[section];
    for (let i = inputIndex + 1; i < inputs.length; i++) {
      if (inputs[i].inputState === eStandardFieldStatus.Visible) {
        return inputs[i].inputType !== eFormControlType.Checkbox;
      }
    }
    return false;
  }
  public anyVisible(array: IStandardFormColumnDto[]) {
    return array.some(item => item.inputState !== eStandardFieldStatus.Hidden);
  }

  public retainOrder() {
    return 0;
  }
}
