<div class="modal-header">
    <h5 class="modal-title" id="enrollModalLabel">Edit Coupon</h5>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" *ngIf="form">
    <form [formGroup]="form">

        <div class="col-12 row mb-2">
            <label class="ng4h-required form-control-label col-3">Description</label>
            <div class="col-9">
                <input type="text" class="form-control" formControlName="description">
            </div>
        </div>

        <div class="col-12 row mb-2">
            <label class="ng4h-required form-control-label col-3">Status</label>
            <div class="col-9">
                <select formControlName="status" class="form-control">
                    <option class="form-control" [value]="type.key"
                        *ngFor="let type of eInstitutionDiscountStatus | keyvalue">
                        {{type.key | camelToTitleCase}}</option>
                </select>
            </div>
        </div>

    </form>
</div>

<div class="modal-footer">
    <button [disabled]="form.invalid" (click)="save()" class="btn btn-primary my-2 my-sm-0" role="button"
        aria-pressed="true">Save</button>
</div>