import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'month',
    pure: true
})

export class MonthPipe implements PipeTransform {
    transform(month: number): string {
      const months = new Map([
        [1, 'January'],
        [2, 'February'],
        [3, 'March'],
        [4, 'April'],
        [5, 'May'],
        [6, 'June'],
        [7, 'July'],
        [8, 'August'],
        [9, 'September'],
        [10, 'October'],
        [11, 'November'],
        [12, 'December']
      ]);

      return months.get(month);
    }
}
