import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DynamicItem, eFormType, IDynamicControl, IDynamicItem } from 'app/core/models';
import { eDynamicValidatorName } from 'app/core/models/dynamic-forms/eDynamicValidatorName';
import { eFormControlItemType } from 'app/core/models/dynamic-forms/eFormControlItemType';
import { DynamicFormService } from 'app/core/services';
import { uuidv4 } from 'app/shared/utils';

import { DynamicValidatorBaseComponent } from '../dynamic-validator-base/dynamic-validator-base.component';

@Component({
  selector: 'ng4h-dynamic-multiple-choice-question-options',
  templateUrl: './dynamic-multiple-choice-question-options.component.html',
  styleUrls: ['./dynamic-multiple-choice-question-options.component.scss']
})
export class DynamicMultipleChoiceQuestionOptionsComponent extends DynamicValidatorBaseComponent implements OnInit {

  @Input() control: IDynamicControl;
  @Output() controlModified: EventEmitter<Partial<IDynamicControl>>;
  @Input() enableLimitTo = true;

  @Output() addItem: EventEmitter<IDynamicItem>;
  @Output() removeItem: EventEmitter<string>;
  @Output() itemModified: EventEmitter<any>;

  @Input() formType: eFormType;

  public eFormType = eFormType;

  public eDynamicValidatorName = eDynamicValidatorName;
  public limitChoices: boolean;
  public enableDisable: boolean;
  public limitTo = 2;

  constructor(private dynamicFormService: DynamicFormService) {
    super();
    this.controlModified = new EventEmitter();
    this.addItem = new EventEmitter();
    this.removeItem = new EventEmitter();
    this.itemModified = new EventEmitter();

  }

  ngOnInit() {
    const foundValidator = this.control.validators?.find(v => v.name === eDynamicValidatorName.MaxAllowedSelections);
    if (foundValidator != null) {
      this.limitChoices = true;
      this.limitTo = foundValidator.arg;
    }
  }

  addDropDownOption() {
    this.addItem.emit(new DynamicItem(uuidv4(), eFormControlItemType.Checkbox));
  }

  removeDropDownOption(item: IDynamicItem) {
    this.removeItem.emit(item.itemId);
  }


  public changeItemLabel(itemId: string, label: string) {
    this.itemModified.emit({ itemId: itemId, label: label });
  }

  public changeQuantityAvailable(itemId: string, quantity: number) {
    this.itemModified.emit({ itemId: itemId, attributes: { quantityAvailable: quantity } });
  }

  public changeItemRate(itemId: string, rate: number) {
    this.itemModified.emit({ itemId: itemId, rate: rate });
  }
  trackByFn(index: number, item: IDynamicItem) {
    return item.itemId;
  }

  public limitChoicesChanged(limitChoices: boolean) {
    if (limitChoices) {
      this.validatorAddOrModify.emit({ name: eDynamicValidatorName.MaxAllowedSelections, arg: this.limitTo });
      this.controlModified.emit({ attributes: { multipleChoiceMaxAllowedSelection: this.limitTo } });
    } else {
      this.validatorRemove.emit({ name: eDynamicValidatorName.MaxAllowedSelections, arg: null });
      this.controlModified.emit({ attributes: { multipleChoiceMaxAllowedSelection: null } });
    }

  }
  reportLabelChange(reportValue: string, itemId: string) {
    this.itemModified.emit({ itemId, reportValue });
  }

  drop(event: CdkDragDrop<string[]>) {
    const items = [...this.control.items];
    moveItemInArray(items, event.previousIndex, event.currentIndex);
    this.controlModified.emit({
      items
    });
  }
}
