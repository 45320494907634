import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AutoUnsubscribe } from 'app/core/models';
import Quill from 'quill';
import { debounceTime, takeUntil } from 'rxjs';

@Component({
  selector: 'ng4h-markup-editor',
  templateUrl: './markup-editor.component.html',
  styleUrls: ['./markup-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarkupEditorComponent extends AutoUnsubscribe implements OnInit, OnChanges {

  public QuillEditor: Quill;

  @Input() public title: string;
  @Input() public content: string;
  @Input() public preview: string;

  @Input() public editMode: boolean;
  @Input() public insert: EventEmitter<string>;

  @Output() public contentChanged: EventEmitter<string> = new EventEmitter<string>();


  public form: FormGroup;

  constructor(private fb: FormBuilder) {
    super();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.form != null && changes?.preview?.currentValue != null) {
      this.form.controls.content.setValue(changes.preview.currentValue);
    }
  }

  public ngOnInit(): void {

    this.form = this.fb.group({
      content: this.content
    });

    this.form.get('content').valueChanges.pipe(debounceTime(500), takeUntil(this.autoUnsubscribe)).subscribe(val => {
      this.contentChanged.emit(val);
    });

    if (this.insert != null) {
      this.insert.pipe(takeUntil(this.autoUnsubscribe)).subscribe(valueToInsert => {
        var selection = this.QuillEditor.getSelection(true);
        this.QuillEditor.insertText(selection.index, valueToInsert, 'user');

      });
    }
  }

  public quillOnInit({ editor }: { editor: Quill }) {
    this.QuillEditor = editor;
  }
}
