import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ng4h-modal-edit-raw-text',
  templateUrl: './modal-edit-raw-text.component.html',
  styleUrls: ['./modal-edit-raw-text.component.scss']
})
export class ModalEditRawTextComponent implements OnInit {


  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) { }

  ngOnInit(): void {
    console.log(this.data)
  }

  public close(): void {
    this.dialogRef.close();
  }

  public save() {
    this.dialogRef.close(this.data);
  }

}
