import { Pipe, PipeTransform } from '@angular/core';
import { IInstitution } from 'app/core/models';
import { InstitutionService, RouterService } from 'app/core/services';
import { filter, Observable } from 'rxjs';

@Pipe({
    name: 'institution$',
    pure: true
})
export class InstitutionPipe implements PipeTransform {
    constructor(private institutionService: InstitutionService, private routerService: RouterService) {
    }

    public transform(institutionId: string = this.routerService.institutionId): Observable<IInstitution> {
        return this.institutionService.getInstitutionById({ institutionId }).pipe(filter(i => i != null));
    }
}
