import {
    eEnrollmentRoleType,
    eEnrollmentStatus,
    eMilitaryBranchType,
    eMilitaryComponentType,
    eMilitaryFamilyServiceType,
    eSchoolGradeType,
    eSchoolType
} from '../enums';

import {
    ICreditDescriptorDto,
    IDemographicsDto,
    IEnrollmentActivityDto,
    IEnrollmentAwardDto,
    IEnrollmentFeeWaiverRequestDto,
    IEnrollmentGroupDto,
    IEnrollmentProjectDto,
    IEnrollmentUnitDto,
    IIMessage,
    IInstitutionVolunteerDto,
    IInvoiceDto,
    IMemberProgramConsentDto,
    IMemberProgramYearApprovalStateDto,
    IProgramYouthDivisionDto
} from '.';

import { IEnrollmentApprovalStateDto } from '../IEnrollmentApprovalStateDto';
import { IHierarchyCountyArea } from './Hierarchy/IHierarchyCountyArea';

export interface IEnrollmentDto {
    enrollmentId: string;
    programYearId: string;
    enrollmentRole: eEnrollmentRoleType;
    active: boolean;
    canSubmit: boolean;
    canDelete: boolean;
    allowProjectEdits: boolean;
    allowUnitEdits: boolean;
    showRefundOption: boolean;
    submissionBlockedReason: IIMessage;
    hasFees: boolean;
    hasPendingPayment: boolean;
    paymentRequired: boolean;
    payableInvoice: IInvoiceDto;
    creditCollectionRequired: boolean;
    creditsToBeCollected?: ICreditDescriptorDto[];
    creditsEnabled: boolean;
    electronicPaymentsEnabled: boolean;
    approvalCounty: IHierarchyCountyArea;
    enrollmentCounty: IHierarchyCountyArea;
    demographics: IDemographicsDto;
    yearsInProgram: number;
    militaryService: eMilitaryFamilyServiceType;
    militaryBranch: eMilitaryBranchType;
    militaryComponent: eMilitaryComponentType;
    schoolCountyId: string;
    schoolDistrictId: string;
    schoolId: string;
    schoolType?: eSchoolType;
    schoolGrade?: eSchoolGradeType;
    schoolName?: string;
    projects?: IEnrollmentProjectDto[];
    units?: IEnrollmentUnitDto[];
    status: eEnrollmentStatus;
    feeWaiverRequest: IEnrollmentFeeWaiverRequestDto;
    youthDivision: IProgramYouthDivisionDto;
    comment?: string;
    invoices?: IInvoiceDto[];
    consents?: IMemberProgramConsentDto[];
    approvalState: IEnrollmentApprovalStateDto;
    memberProgramYearApprovalState: IMemberProgramYearApprovalStateDto;
    volunteerTypes?: IInstitutionVolunteerDto[];
    activities?: IEnrollmentActivityDto[];
    awards?: IEnrollmentAwardDto[];
    groups?: IEnrollmentGroupDto[];

    showExternalScreening?: boolean;
    externalScreening?: boolean;

}
