import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'ng4h-in-app-help',
  templateUrl: './in-app-help.component.html',
  styleUrls: ['./in-app-help.component.scss']
})
export class InAppHelpComponent implements OnInit {

  constructor() {

  }

  ngOnInit() {
  }

}
