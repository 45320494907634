import { IMemberDashboardProgramYear } from '..';

export interface IMemberDashboardProgram {
  memberProgramId: string;
  programId: string;
  consents: any[];
  eventRegistrations: any[];
  trainings: any[];
  memberProgramYears: IMemberDashboardProgramYear[];
}
