import { ChangeDetectorRef, Directive, EmbeddedViewRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AutoUnsubscribe, eHierarchyNodeType } from 'app/core/models';
import { HierarchyService } from 'app/core/services';
import { filter } from 'rxjs';

export class ManagerHierarchyTypeDirectiveContext {
  public $implicit: eHierarchyNodeType | eHierarchyNodeType[] = null;
  public ng4hManagerHierarchyNodeType: eHierarchyNodeType | eHierarchyNodeType[] = null;
}

@Directive({
  selector: '[ng4hManagerHierarchyNotType]'
})
export class ManagerHierarchyNotTypeDirective extends AutoUnsubscribe {

  private context: ManagerHierarchyTypeDirectiveContext = new ManagerHierarchyTypeDirectiveContext();
  private thenViewRef: EmbeddedViewRef<any> | null;

  @Input('ng4hManagerHierarchyNotType')
  set ng4hManagerPermissions(managerPermissions: eHierarchyNodeType | eHierarchyNodeType[]) {
    this.context.$implicit = this.context.ng4hManagerHierarchyNodeType = managerPermissions;
    this.updateView();
  }

  constructor(
    private thenTemplateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private hierarchyService: HierarchyService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  private updateView() {

    this.hierarchyService.managerHierarchyNode$.pipe(
      filter(managerHierarchyNode => managerHierarchyNode != null)
    ).subscribe(managerHierarchyNode => {
      this.thenViewRef = null;  // clear previous view if any.
      if (!(this.context.ng4hManagerHierarchyNodeType === managerHierarchyNode.hierarchyNodeType || (Array.isArray(this.context.ng4hManagerHierarchyNodeType) && this.context.ng4hManagerHierarchyNodeType.includes(managerHierarchyNode.hierarchyNodeType)))) {
        if (this.thenViewRef == null) {
          this.viewContainer.clear();
          if (this.thenTemplateRef) {
            this.thenViewRef =
              this.viewContainer.createEmbeddedView(this.thenTemplateRef, this.context);
          }
        } else {
          this.viewContainer.clear();
        }
      } else {
        this.viewContainer.clear();
      }
      this.cdr.markForCheck();
    });
  }
}
