<ng-container *ngIf="treeDataSource && treeControl">
  <cdk-tree [dataSource]="treeDataSource" [treeControl]="treeControl">
    <!-- This is the tree node template for leaf nodes -->
    <cdk-nested-tree-node *cdkTreeNodeDef="let node" class="example-tree-node">
      <!-- use a disabled button to provide padding for tree leaf -->
      <button class="tree-button" mat-icon-button disabled></button>
      <mat-checkbox class="checklist-leaf-node"
        [checked]="node.hierarchyNodeId == (hierarchyLeaf$ | async)?.hierarchyNodeId"
        (change)="todoItemSelectionToggle(node)">{{node.hierarchyNodeName}}</mat-checkbox>
    </cdk-nested-tree-node>
    <!-- This is the tree node template for expandable nodes -->
    <cdk-nested-tree-node *cdkTreeNodeDef="let node; when: isRegion" class="example-tree-node">
      <button class="tree-button" mat-icon-button [attr.aria-label]="'toggle ' + node.hierarchyNodeName"
        cdkTreeNodeToggle>
        <mat-icon class="mat-icon-rtl-mirror">
          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
        </mat-icon>
      </button>

      <mat-checkbox [checked]="node.hierarchyNodeId == (hierarchyLeaf$ | async)?.hierarchyNodeId"
        [indeterminate]="node.hierarchyNodeId == (selectedRegionArea | async)?.hierarchyNodeId && !(node.hierarchyNodeId == (hierarchyLeaf$ | async)?.hierarchyNodeId)"
        (change)="todoItemSelectionToggle(node)">{{node.hierarchyNodeName}}</mat-checkbox>
      <div [class.example-tree-invisible]="!treeControl.isExpanded(node)">
        <ng-container cdkTreeNodeOutlet></ng-container>
      </div>
    </cdk-nested-tree-node>
    <cdk-nested-tree-node *cdkTreeNodeDef="let node; when: isDistrict" class="example-tree-node">
      <button class="tree-button" mat-icon-button [attr.aria-label]="'toggle ' + node.hierarchyNodeName"
        cdkTreeNodeToggle>
        <mat-icon class="mat-icon-rtl-mirror">
          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
        </mat-icon>
      </button>

      <mat-checkbox [checked]="node.hierarchyNodeId == (hierarchyLeaf$ | async)?.hierarchyNodeId"
        [indeterminate]="node.hierarchyNodeId == (selectedDistrictArea | async)?.hierarchyNodeId  && !(node.hierarchyNodeId == (hierarchyLeaf$ | async)?.hierarchyNodeId)"
        (change)="todoItemSelectionToggle(node)">{{node.hierarchyNodeName}}</mat-checkbox>
      <div [class.example-tree-invisible]="!treeControl.isExpanded(node)">
        <ng-container cdkTreeNodeOutlet></ng-container>
      </div>
    </cdk-nested-tree-node>
    <!-- <cdk-nested-tree-node *cdkTreeNodeDef="let node; when: node.hierarchyNodeType == eHierarchyNodeType.CountyArea"
      class="example-tree-node">
      <button class="tree-button" mat-icon-button [attr.aria-label]="'toggle ' + node.hierarchyNodeName"
        cdkTreeNodeToggle>
        <mat-icon class="mat-icon-rtl-mirror">
          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
        </mat-icon>
      </button>

      <mat-checkbox [checked]="node.hierarchyNodeId == (hierarchyLeaf$ | async)?.hierarchyNodeId"
        (change)="todoItemSelectionToggle(node)">{{node.hierarchyNodeName}}</mat-checkbox>
      <div [class.example-tree-invisible]="!treeControl.isExpanded(node)">
        <ng-container cdkTreeNodeOutlet></ng-container>
      </div>
    </cdk-nested-tree-node> -->
  </cdk-tree>
</ng-container>