import { IProfileDescriptorDto } from './IProfileDescriptorDto';

export interface IConsentVersionDto {
    consentTypeId: string;
    consentVersionId: string;
    dateModified?: string;
    modifiedBy: IProfileDescriptorDto;
    consentName?: string;
    version: number;
    acceptanceRequired: boolean;
    dateEffective?: string;
    effective: boolean;
    electronicSignatureRequired: boolean;
    consentTexts?: object;
}
