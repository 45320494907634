import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import {
  eAccessControlListRoleType,
  EMAIL_REGEX,
  ePermissionState,
  IEventDto,
  IManagerInstitutionProfile,
} from 'app/core/models';
import { EventsManagerService, ManagerService, RouterService } from 'app/core/services';
import { combineLatest, filter, map, Observable, switchMap } from 'rxjs';

@Component({
  selector: 'ng4h-modal-event-add-manager',
  templateUrl: './modal-event-add-manager.component.html',
  styleUrls: ['./modal-event-add-manager.component.scss']
})
export class ModalEventAddManagerComponent implements OnInit {
  private event$: Observable<IEventDto>;

  public eAccessControlListRoleType = eAccessControlListRoleType;
  public addManager: FormGroup;
  public managerPermissions: FormGroup;
  public permissionRoleForm: FormGroup;
  public filteredUsers: Observable<IManagerInstitutionProfile[]>;
  public selectedManager: IManagerInstitutionProfile;
  public managers$: Observable<IManagerInstitutionProfile[]>;

  public managerPermissionRole$: Observable<eAccessControlListRoleType>;

  constructor(private routerService: RouterService,
    private dialogRef: MatDialogRef<ModalEventAddManagerComponent>,
    private managerService: ManagerService,
    private fb: FormBuilder,
    private eventsManagerService: EventsManagerService
  ) {

    this.event$ = this.eventsManagerService.getEvent({
      institutionId: this.routerService.institutionId,
      managerId: this.routerService.managerId,
      programId: this.routerService.programId,
      eventId: this.routerService.eventId
    });
    this.managerPermissionRole$ = this.event$.pipe(map(event => event.activeManagerPermission.permissionRole));
    this.managers$ = this.managerService.getManagers({ institutionId: this.routerService.institutionId });

    this.addManager = this.fb.group({
      email: ['', [Validators.required, Validators.email, Validators.pattern(EMAIL_REGEX)]],
    });
    this.managerPermissions = this.fb.group({
      EventSetup: [ePermissionState.None, [Validators.required]],
      RegistrationManagement: [ePermissionState.None, [Validators.required]],
      EventManagers: [ePermissionState.None, [Validators.required]],
    });
    this.permissionRoleForm = this.fb.group({
      permissionRole: [eAccessControlListRoleType.EventVolunteer, [Validators.required]]
    });

    this.addManager.controls.email.valueChanges.subscribe((managerEmail: string) => {
      if (this.selectedManager != null && managerEmail.length > 0) {
        this.addManager.controls['email'].setValue('');
      }
      this.selectedManager = null;

    });

    this.filteredUsers = this.addManager.controls['email'].valueChanges
      .pipe(
        // debounceTime(500),
        map((val: string) => val.toUpperCase()),
        switchMap(value => {
          return combineLatest([this.event$, this.managers$]).pipe(
            filter(([event, managers]) => event != null && Array.isArray(managers)),
            map(([event, managers]) => {
              return managers.filter(manager => {
                // Filter out current manager
                if (manager.managerId === this.routerService.managerId) {
                  return false;
                }
                // Filter out managers already on the event
                if (event.eventManagers != null && event.eventManagers[manager.managerId] != null) {
                  return false;
                }
                const testMatch = manager.email.toUpperCase().includes(value) ||
                  manager.firstName?.toUpperCase().includes(value) ||
                  manager.lastName?.toUpperCase().includes(value) ||
                  (manager.lastName + ' ' + manager.firstName).toUpperCase().includes(value) ||
                  (manager.lastName + ', ' + manager.firstName).toUpperCase().includes(value) ||
                  // This is more for devs to search by id, maybe remove?
                  manager.managerId.toUpperCase().includes(value);
                return testMatch;
              });
            }));
        })
      );
  }

  ngOnInit() {
  }

  public close(result?: any) {
    this.dialogRef.close(result);
  }

  public save() {
    this.eventsManagerService.updateEventPermissions({
      institutionId: this.routerService.institutionId,
      targetManagerId: this.selectedManager.managerId,
      programId: this.routerService.programId,
      eventId: this.routerService.eventId,
      managerId: this.routerService.managerId,
      permissions: {
        permissionRole: this.permissionRoleForm.value.permissionRole,
        grantedPermissions: this.managerPermissions.value
      }
    });
    this.close();
  }

  public managerSelected(manager) {
    this.selectedManager = manager;
  }

}
