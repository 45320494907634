<form [formGroup]="form">
    <div class="ng4h-border-bottom">
        <input type="text" placeholder="Search" formControlName="searchText">
        <!-- <i class="far fa-search"></i> -->
    </div>
    <div class="col-12 search-results ng4h-padding-top ng4h-padding-bottom">
        <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
        <div *ngIf="searchResults$ | async as searchResults" class="row">
            <div class="col-6">
                <div *ngFor="let result of searchResults; let i = index" (click)="selectedIndex = i"
                    [ngClass]="{'ng4h-bg-secondary': selectedIndex == i}" class="ng4h-list-row">
                    <!-- <pre>{{result | json}}</pre> -->
                    <ng-container [ngSwitch]="result.type">
                        <ng-container *ngSwitchCase="eFreeSearchResultPayloadType.Family">
                            <div>{{result.data.familyName}} - <b>Family</b></div>
                        </ng-container>
                        <ng-container *ngSwitchCase="eFreeSearchResultPayloadType.Member">
                            <div>{{result.data.firstName}} {{result.data.lastName}} - <b>Member</b></div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
            <div class="col-6">
                <ng-container *ngIf="searchResults[selectedIndex] as selectedResult">
                    <!-- <pre>{{selectedResult | json}}</pre> -->
                    <button class="btn btn-primary ng4h-margin-bottom" (click)="view(selectedResult)">View</button>
                    <ng-container [ngSwitch]="selectedResult.type">
                        <ng-container *ngSwitchCase="eFreeSearchResultPayloadType.Family">
                            <div class="ng4h-table">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-4 ng4h-bg-secondary"><b>Family Name</b></div>
                                        <div class="col-8">{{ selectedResult.data.familyName }}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4 ng4h-bg-secondary"><b>Members</b></div>
                                        <div class="col-8">{{ selectedResult.data.familyMembers.length }}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4 ng4h-bg-secondary"><b>Email</b></div>
                                        <div class="col-8">{{ selectedResult.data.email }}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4 ng4h-bg-secondary"><b>Address</b></div>
                                        <div class="col-8">{{ selectedResult.data.address.addressLine1 }}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4 ng4h-bg-secondary"><b>Address 2</b></div>
                                        <div class="col-8">{{ selectedResult.data.address.addressLine2 }}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4 ng4h-bg-secondary"><b>City</b></div>
                                        <div class="col-8">{{ selectedResult.data.address.city }}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4 ng4h-bg-secondary"><b>State</b></div>
                                        <div class="col-8">{{ selectedResult.data.address.state }}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4 ng4h-bg-secondary"><b>Postal Code</b></div>
                                        <div class="col-8">{{ selectedResult.data.address.postalCode}}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4 ng4h-bg-secondary"><b>Country</b></div>
                                        <div class="col-8">{{ selectedResult.data.address.country }}</div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="eFreeSearchResultPayloadType.Member">
                            <div class="ng4h-table">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-4 ng4h-bg-secondary"><b>First Name</b></div>
                                        <div class="col-8">{{ selectedResult.data.firstName }}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4 ng4h-bg-secondary"><b>Middle Name</b></div>
                                        <div class="col-8">{{ selectedResult.data.middleName }}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4 ng4h-bg-secondary"><b>Last Name</b></div>
                                        <div class="col-8">{{ selectedResult.data.lastName }}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4 ng4h-bg-secondary"><b>Preferred Name</b></div>
                                        <div class="col-8">{{ selectedResult.data.preferredName }}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4 ng4h-bg-secondary"><b>Email</b></div>
                                        <div class="col-8">{{ selectedResult.data.email }}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4 ng4h-bg-secondary"><b>Mobile Phone</b></div>
                                        <div class="col-8">{{ selectedResult.data.mobilePhone }}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4 ng4h-bg-secondary"><b>Birthdate</b></div>
                                        <div class="col-8">{{ selectedResult.data.birthDate | date}}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4 ng4h-bg-secondary"><b>Job Title</b></div>
                                        <div class="col-8">{{ selectedResult.data.jobTitle }}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4 ng4h-bg-secondary"><b>Employer</b></div>
                                        <div class="col-8">{{ selectedResult.data.employer }}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4 ng4h-bg-secondary"><b>Work Phone</b></div>
                                        <div class="col-8">{{ selectedResult.data.workPhone }}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4 ng4h-bg-secondary"><b>Work Extension</b></div>
                                        <div class="col-8">{{ selectedResult.data.workExt }}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4 ng4h-bg-secondary"><b>Emergency Contact</b></div>
                                        <div class="col-8">{{ selectedResult.data.emergencyContact }}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4 ng4h-bg-secondary"><b>Emergency Contact Phone</b></div>
                                        <div class="col-8">{{ selectedResult.data.emergencyContactPhone }}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4 ng4h-bg-secondary"><b>Emergency Contact Email</b></div>
                                        <div class="col-8">{{ selectedResult.data.emergencyContactEmail }}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4 ng4h-bg-secondary"><b>Emergency Contact Relationship</b></div>
                                        <div class="col-8">{{ selectedResult.data.emergencyContactRelationship }}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4 ng4h-bg-secondary"><b>Parent / Guardian 1 First Name</b></div>
                                        <div class="col-8">{{ selectedResult.data.guardian1FirstName }}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4 ng4h-bg-secondary"><b>Parent / Guardian 1 Last Name</b></div>
                                        <div class="col-8">{{ selectedResult.data.guardian1LastName }}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4 ng4h-bg-secondary"><b>Parent / Guardian 1 Phone</b></div>
                                        <div class="col-8">{{ selectedResult.data.guardian1Phone }}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4 ng4h-bg-secondary"><b>Parent / Guardian 1 Work Phone</b></div>
                                        <div class="col-8">{{ selectedResult.data.guardian1WorkPhone }}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4 ng4h-bg-secondary"><b>Parent / Guardian 1 Work Extension</b>
                                        </div>
                                        <div class="col-8">{{ selectedResult.data.guardian1WorkExt }}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4 ng4h-bg-secondary"><b>Parent / Guardian 2 First Name</b></div>
                                        <div class="col-8">{{ selectedResult.data.guardian2FirstName }}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4 ng4h-bg-secondary"><b>Parent / Guardian 2 Last Name</b></div>
                                        <div class="col-8">{{ selectedResult.data.guardian2LastName }}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4 ng4h-bg-secondary"><b>Parent / Guardian 2 Phone</b></div>
                                        <div class="col-8">{{ selectedResult.data.guardian2Phone }}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4 ng4h-bg-secondary"><b>Parent / Guardian 2 Work Phone</b></div>
                                        <div class="col-8">{{ selectedResult.data.guardian2WorkPhone }}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4 ng4h-bg-secondary"><b>Parent / Guardian 2 Work Extension</b>
                                        </div>
                                        <div class="col-8">{{ selectedResult.data.guardian2WorkExt }}</div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>

</form>