import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { RouterService, UserService, WindowService } from 'app/core/services';
import { DynamicExternalJavascriptService } from 'app/core/services/dynamic-external-javascript.service';
import { environment } from 'environments/environment';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { map, Observable, take } from 'rxjs';

import frontEndVersion from '../assets/version.json';
import { AutoUnsubscribe, IUserAccountDto } from './core/models';
import { ModalService } from './core/services/modal.service';
import { SystemService } from './core/services/system.service';
import {
  ModalDeveloperToolsComponent,
} from './shared/developer-tools/modal-developer-tools/modal-developer-tools.component';

@Component({
  selector: 'ng4h-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent extends AutoUnsubscribe implements OnInit {
  title = '4-H Online';

  public environment = environment;
  public user$: Observable<IUserAccountDto>;

  public frontEndVersion = frontEndVersion;

  @ViewChild(ToastContainerDirective, { static: true })
  toastContainer: ToastContainerDirective;

  public hide$: Observable<boolean>;

  constructor(
    // singleton services
    private modalService: ModalService,
    private windowService: WindowService,

    private userService: UserService,
    private routerService: RouterService,
    private dynamicExternalJavascriptService: DynamicExternalJavascriptService,
    private toastrService: ToastrService
  ) {
    super();

    this.hide$ = this.routerService.activeUrlArray$.pipe(map(arr => arr[0] == 'public'));

    this.routerService.init();
    this.windowService.init();

    this.user$ = this.userService.userAccount$;
  }

  ngOnInit() {
    this.toastrService.overlayContainer = this.toastContainer;
  }

  public debugClicked() {
    this.modalService.openModal(ModalDeveloperToolsComponent, { maxHeight: '100%', maxWidth: '100%', panelClass: 'col-12' });
  }


}
