import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AutoUnsubscribe, IDynamicForm } from 'app/core/models';
import { DynamicFormService, EnrollmentService, RouterService } from 'app/core/services';
import { filter, map, Observable, take, takeUntil } from 'rxjs';

@Component({
  selector: 'ng4h-modal-edit-dynamic-form-questions',
  templateUrl: './modal-edit-dynamic-form-questions.component.html',
  styleUrls: ['./modal-edit-dynamic-form-questions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalEditDynamicFormQuestionsComponent extends AutoUnsubscribe implements OnInit {

  public dynamicForm$: Observable<IDynamicForm>;
  public dynamicFormFormGroup: FormGroup;
  constructor(
    private dialogRef: MatDialogRef<any>,
    private routerService: RouterService,
    private enrollmentService: EnrollmentService,
    private dynamicFormService: DynamicFormService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.dynamicForm$ = this.enrollmentService.getDynamicForm({ institutionId: this.routerService.institutionId, memberId: this.routerService.selectedMemberId, programId: this.routerService.programId, enrollmentId: this.routerService.enrollmentId }).pipe(takeUntil(this.autoUnsubscribe));
    this.dynamicForm$.pipe(
      filter(inputs => inputs != null),
      map(dynamicInputs => {
        return this.dynamicFormService.toFormGroup(dynamicInputs.groups.reduce((pre, cur) => pre.concat(cur.controls), []));
      }),
      take(1)
    ).subscribe((dynamicFormFormGroup) => {
      this.dynamicFormFormGroup = dynamicFormFormGroup;
      this.cdr.detectChanges();
    });

  }

  public close(result?: any): void {
    this.dialogRef.close(result);
  }

  public save(): void {
    this.close(this.dynamicFormFormGroup.value);
  }
}
