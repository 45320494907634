export interface ILoginCredentials {
    accessToken: string;
    accountLockedUntil: string;
    expiresAt: number;
    failedLoginCount: number;
    loginAttemptsUntilLock: number;
    message: string;
    status: string;
    userAccountAuth: string;
    userAccountToken: string;
    '2FARequired'?: string;
    '2FAVerified': boolean;
    passwordChangeRequired: boolean;
    passwordExpired: boolean;
    userAccountId: string;
}
