<div class="modal-section">
  <div class="modal-header">
    <h5 class="modal-title">Edit Project Restriction</h5>
    <button (click)="cancel()" type="button" class="close" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body" *ngIf="form != null">
    <form [formGroup]="form">
      <div class="form-group col-sm-12 row">
        <label class="form-control-label col-4">Role</label>
        <div class="col-8">
          <select class="form-control" formControlName="restrictedToRoleType">
            <!-- no adults, club member, cloverbud only-->
            <option [ngValue]="null">No Restriction</option>
            <option [value]="eEnrollmentRoleType.ClubMember">
              {{ (eEnrollmentRoleType.ClubMember | programAlias$ | async)?.singular }}
            </option>
            <option [value]="eEnrollmentRoleType.CloverBud">
              {{ (eEnrollmentRoleType.CloverBud | programAlias$ | async)?.singular }}
            </option>
          </select>
        </div>
      </div>

      <div class="form-group col-sm-12 row">
        <label class="form-control-label col-4">Allow Members To Register</label>
        <div class="col-8">
          <mat-radio-group aria-label="Select an option" formControlName="allowMembersToRegister">
            <mat-radio-button [value]="true">Yes</mat-radio-button>
            <mat-radio-button [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="form-group col-sm-12 row">
        <label class="form-control-label col-4 ng4h-required">National Project Category</label>
        <div class="col-8">
          <select class="form-control" formControlName="nationalProjectCategoryId">
            <option *ngFor="let category of nationalProjectCategories$ | async" [value]="category.nationalProjectCategoryId">
              {{ category.category }}
            </option>

          </select>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer" *ngIf="form">
    <button id (click)="cancel()" class="btn btn-secondary my-2 my-sm-0" role="button" aria-pressed="true">Cancel
    </button>
    <button [disabled]="form.invalid" (click)="update()" class="btn btn-primary my-2 my-sm-0" role="button" aria-pressed="true">Update
    </button>
  </div>
</div>