import { Pipe, PipeTransform } from '@angular/core';
import { IProgramYouthDivisionDto } from 'app/core/models';
import { RouterService } from 'app/core/services';
import { map, Observable } from 'rxjs';

import { ProgramSettingsService } from '../services/program-settings.service';

@Pipe({
    name: 'youthDivision$',
    pure: true
})
export class YouthDivisionPipe implements PipeTransform {
    constructor(private programSettingsService: ProgramSettingsService, private routerService: RouterService) {
    }

    public transform(youthDivisionId: string, institutionId: string = this.routerService.institutionId, programId: string = this.routerService.programId): Observable<IProgramYouthDivisionDto> {
        return this.programSettingsService.getProgramSettings({ institutionId, programId }).pipe(
            map(programSettings => {
                return programSettings.youthDivisions.find(yd => yd.youthDivisionId === youthDivisionId);
            })
        );
    }
}
