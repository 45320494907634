<ng-progress [thick]="true" [color]="'#E0457B'" [spinner]="false"></ng-progress>
<div aria-live="assertive" role="alert" toastContainer></div>
<div id="dialog-container"></div>

<ng4h-manager-sidebar>
  <ng4h-header *ngIf="!(hide$ | async)">
  </ng4h-header>
  <span aria-live="polite">
    <router-outlet></router-outlet>
  </span>
</ng4h-manager-sidebar>

<ng-container *ngIf="user$ | async as user">
  <ng-container *ngIf="!environment.production || user?.superuserId != null">
    <div id="debug-info" (click)="debugClicked()">
      <i class="fa fa-tachometer"></i>
    </div>
  </ng-container>
</ng-container>
