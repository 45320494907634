import { eActivityStatus } from '..';

export interface IActivityUpdateDto {
    status: eActivityStatus;
    label?: string;
    description?: string;
    code?: string;
    hierarchyNodeId: string;
    projectId: string;
    allowMembersToRegister?: boolean;
}
