export interface IEnrollmentFormValidationFailure {
  type: string;
  body: {
    itemResponseValidationErrors: {
      itemId: string,
      validationErrorType: string,
      validationErrorMessage: string
    }[]
  }[];
}
