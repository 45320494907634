<div class="modal-header">
    <h5 class="modal-title">Add Volunteer Type</h5>
    <button (click)="close()" type="button" class="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <form [formGroup]="form" class="col-12">
            <div class="form-group">
                <label class="form-control-label">Volunteer Type</label>
                <ng-container *ngIf="enrollment$ | async as enrollment">
                    <select *ngIf="enrollment.enrollmentRole === eEnrollmentRoleType.Volunteer" class="form-control"
                        formControlName="volunteerType">
                        <option [ngValue]="null"></option>
                        <option *ngFor="let vt of availableVolunteerTypes$ | async" [value]="vt">
                            {{ vt | camelToTitleCase | replace: 'Adult ': '' | replace: 'Club' : ('Club' | programAlias$
                            | async)?.singular }}
                        </option>
                    </select>
                    <select *ngIf="enrollment.enrollmentRole === eEnrollmentRoleType.ClubMember" class="form-control"
                        formControlName="volunteerType">
                        <option [ngValue]="null"></option>
                        <option *ngFor="let vt of availableVolunteerTypes$ | async" [value]="vt">
                            {{ vt | camelToTitleCase | replace: 'Youth ': '' | replace: 'Club' : ('Club' | programAlias$
                            | async)?.singular }}
                        </option>
                    </select>
                </ng-container>
            </div>
        </form>
    </div>

    <div class="row">
        <div class="col-12">
            <div *ngIf="remainingVolunteerTypes$ | async as remainingVolunteerTypes" class="card project-card">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item" *ngFor="let volunteerType of remainingVolunteerTypes">
                        <div>
                            {{volunteerType.volunteerTypeLabel}}
                            <button type="button" class="btn btn-primary float-right add-project"
                                (click)="addVolunteerType(volunteerType)">Add</button>
                        </div>
                    </li>
                    <li class="list-group-item"
                        *ngIf="remainingVolunteerTypes.length == 0 && form.controls['volunteerType'].value != null">
                        No volunteer types available
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button (click)="close()" class="btn btn-secondary my-2 my-sm-0" role="button" aria-pressed="true">Done</button>
</div>