import { IFileType } from '.';
import { IFourHFileDto } from './serverDTOs/IFourHFileDto';

export interface IFileControl {
    managerOnly: boolean;
    hasFile: boolean;
    uploadedFile: IFourHFileDto;
    // registrationTypes?: object;
    fileControlId: string;
    fileControlName?: string;
    fileControlDescription?: string;
    acceptedFileExtensions?: IFileType[];
    templateFile: IFourHFileDto;
    hasTemplateFile: boolean;
    required?: boolean;
    enrollmentRoles?: any;
    registrationTypes?: any;
}
