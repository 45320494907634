import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IDeliveryModeDto, IInstitutionProgramParams } from 'app/core/models';


export const DeliveryModesLoadAction = createAction(
    '[delivery-modes] Load',
    props<IInstitutionProgramParams>()
);

export const DeliveryModesLoadSuccessAction = createAction(
    '[delivery-modes] Load Success',
    props<IInstitutionProgramParams & { deliveryModes: IDeliveryModeDto[] }>()
);

export const DeliveryModesLoadErrorAction = createAction(
    '[delivery-modes] Load Error',
    props<{ error: HttpErrorResponse }>()
);

export const GroupEnrollmentDeliveryModesLoadAction = createAction(
    '[delivery-modes] Load Group Enrollment',
    props<IInstitutionProgramParams>()
);

export const GroupEnrollmentDeliveryModesLoadSuccessAction = createAction(
    '[delivery-modes] Load Group Enrollment Success',
    props<IInstitutionProgramParams & { deliveryModes: IDeliveryModeDto[] }>()
);

export const GroupEnrollmentDeliveryModesLoadErrorAction = createAction(
    '[delivery-modes] Load Group Enrollment Error',
    props<{ error: HttpErrorResponse }>()
);
