import { IEventRegistrationSearchResultDto } from './IEventRegistrationSearchResultDto';
import { IEventRegistrationSearchParametersDto } from './IEventRegistrationSearchParametersDto';

export interface IEventRegistrationSearchResultsDto {
  searchParameters: IEventRegistrationSearchParametersDto;
  results?: IEventRegistrationSearchResultDto[];
  resultsCount: number;
  pageSize?: number;
  pageIndex?: number;
  pageCount: number;
  startingResultRangeRecordIndex?: number;
  endingResultRangeRecordIndex?: number;
}
