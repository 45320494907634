import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { AppState } from 'app/app.reducers';
import {
  getCountyManagerExcludedProjects,
  getManagerExcludedProjects,
  getManagerNationalProjectCategories,
  getManagerProgramYearHierarchyProjects,
  getManagerProgramYearProjects,
  getManagerProjects,
  getManagerProjectsById,
  getProjectCountyAliases
} from 'app/core/containers/admin/manager/manager-feature.reducer';
import { ManagerProjectsActions } from 'app/core/containers/admin/manager/store/projects';
import {
  ICountyProjectCreateDto,
  ICountyProjectUpdateDto,
  IInstitutionManagerProgramParams,
  IInstitutionProgramParams,
  INationalProjectCategoryDto,
  IProjectDto,
  IProjectProgramYearUpdateDto
} from 'app/core/models';
import { filter, map, Observable, of, take, tap } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ManagerProjectsService {

  constructor(private store: Store<AppState>, private httpClient: HttpClient, private dispatcher: ActionsSubject) {
  }

  public getProgramYearHierarchyProjects(params: IInstitutionManagerProgramParams & { programYearId: string, hierarchyNodeId: string }): Observable<IProjectDto[]> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }

    return this.store.pipe(
      select(getManagerProgramYearHierarchyProjects(params)),
      tap(projects => {
        if (projects === undefined) {
          this.store.dispatch(ManagerProjectsActions.ManagerGetProgramYearHierarchyProjectsAction(params));
        }
      }),
      filter(projects => projects != null)
    );
  }

  // Get projects by hierarchy
  public loadProgramYearHierarchyProjectsEffect({
    institutionId,
    managerId,
    programId,
    programYearId,
    hierarchyNodeId
  }: IInstitutionManagerProgramParams & { programYearId: string, hierarchyNodeId: string }): Observable<IProjectDto[]> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/projects/${programYearId}?hierarchyNodeId=${hierarchyNodeId}`) as Observable<IProjectDto[]>;
  }

  public getProgramYearProjects(params: IInstitutionManagerProgramParams & { programYearId: string }): Observable<IProjectDto[]> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }

    return this.store.pipe(
      select(getManagerProgramYearProjects(params)),
      tap(projects => {
        if (projects === undefined) {
          this.store.dispatch(ManagerProjectsActions.ManagerGetProgramYearProjectsAction(params));
        }
      }),
      filter(projects => projects != null)
    );
  }

  public loadProgramYearProjectsEffect({
    institutionId,
    managerId,
    programId,
    programYearId
  }: IInstitutionManagerProgramParams & { programYearId: string, }): Observable<IProjectDto[]> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/projects/${programYearId}`) as Observable<IProjectDto[]>;
  }

  // institution projects
  public getProjects(params: IInstitutionManagerProgramParams): Observable<IProjectDto[]> {

    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }

    return this.store.pipe(
      select(getManagerProjects(params)),
      tap(projects => {
        if (projects === undefined) {
          this.store.dispatch(ManagerProjectsActions.ManagerGetProjectsAction(params));
        }
      }),
      filter(projects => projects != null)
    );
  }

  public loadProjectsEffect({
    institutionId,
    managerId,
    programId
  }: IInstitutionManagerProgramParams): Observable<IProjectDto[]> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/projects`) as Observable<IProjectDto[]>;
  }

  public getProjectById(params: IInstitutionManagerProgramParams & { projectId: string, programYearId: string }): Observable<IProjectDto> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }

    return this.store.pipe(
      select(getManagerProjectsById(params)),
      tap(project => {
        if (project === undefined) {
          this.store.dispatch(ManagerProjectsActions.ManagerGetProjectByIdAction(params));
        }
      }),
      filter(project => project != null)
    );
  }

  public getProjectByIdEffect({
    institutionId,
    managerId,
    programId,
    projectId,
    programYearId
  }: IInstitutionManagerProgramParams & { projectId: string, programYearId: string }): Observable<IProjectDto> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/projects/${projectId}/program-years/${programYearId}`) as Observable<IProjectDto>;
  }

  public linkProjectToProgramYear(params: IInstitutionManagerProgramParams & { programYearId: string, projectIds: string[] }) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }

    this.store.dispatch(ManagerProjectsActions.ManagerLinkProjectToProgramYearAction(params));
  }

  public linkProjectToProgramYearEffect({
    institutionId,
    managerId,
    programId,
    programYearId,
    projectIds
  }: IInstitutionManagerProgramParams & { programYearId: string, projectIds: string[] }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/projects/program-years/${programYearId}`, projectIds);
  }

  public removeProjectFromProgramYear(params: IInstitutionManagerProgramParams & { programYearId: string, projectId: string }) {
    this.store.dispatch(ManagerProjectsActions.ManagerRemoveProjectLinkProgramYearAction(params));
  }

  public removeProjectFromProgramYearEffect({
    institutionId,
    managerId,
    programId,
    projectId,
    programYearId
  }: IInstitutionManagerProgramParams & { programYearId: string, projectId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/projects/${projectId}/program-years/${programYearId}`);
  }

  public removeCountyProject(params: IInstitutionManagerProgramParams & { programYearId: string, projectId: string, countyAreaId: string }) {
    this.store.dispatch(ManagerProjectsActions.ManagerRemoveCountyProjectAction(params));
  }

  public removeCountyProjectEffect({
    institutionId,
    managerId,
    projectId,
    programId,
    programYearId,
    countyAreaId
  }: IInstitutionManagerProgramParams & { programYearId: string, projectId: string, countyAreaId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/projects/${projectId}?programYearId=${programYearId}&countyAreaId=${countyAreaId}`);
  }

  public updateProjectEnrollmentRestriction(params: IInstitutionManagerProgramParams & { programYearId: string, projectId: string, projectProgramYearUpdateDto: IProjectProgramYearUpdateDto }) {
    this.store.dispatch(ManagerProjectsActions.ManagerUpdateProjectEnrollmentRestrictionAction(params));

    return this.dispatcher.pipe(
      ofType(ManagerProjectsActions.ManagerUpdateProjectEnrollmentRestrictionSuccessAction, ManagerProjectsActions.ManagerUpdateProjectEnrollmentRestrictionErrorAction),
      take(1),
      map(action => {
        if (action.type === ManagerProjectsActions.ManagerUpdateProjectEnrollmentRestrictionSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateProjectEnrollmentRestrictionEffect({
    institutionId,
    managerId,
    programId,
    projectId,
    programYearId,
    projectProgramYearUpdateDto
  }: IInstitutionManagerProgramParams & { programYearId: string, projectId: string, projectProgramYearUpdateDto: IProjectProgramYearUpdateDto }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/projects/${projectId}/program-years/${programYearId}`, projectProgramYearUpdateDto);
  }

  // county aliases
  public getProjectCountyAliases(params: IInstitutionManagerProgramParams & { countyAreaId: string, programYearId: string }) {

    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }

    return this.store.pipe(
      select(getProjectCountyAliases(params)),
      tap(projects => {
        if (projects === undefined) {
          this.store.dispatch(ManagerProjectsActions.GetProjectCountyAliasesAction(params));
        }
      }),
      filter(projects => projects != null)
    );

  }

  public getProjectCountyAliasesEffect({
    institutionId,
    managerId,
    programId,
    countyAreaId,
    programYearId
  }: IInstitutionManagerProgramParams & { countyAreaId: string, programYearId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/county-areas/${countyAreaId}/projects/${programYearId}`) as Observable<IProjectDto[]>;
  }

  // For the current year
  public createCountyProjectAlias(params: IInstitutionManagerProgramParams & { countyAreaId: string, projectCreate: ICountyProjectCreateDto }) {
    this.store.dispatch(ManagerProjectsActions.CreateProjectCountyAliasesAction(params));

    return this.dispatcher.pipe(
      ofType(ManagerProjectsActions.CreateProjectCountyAliasesSuccessAction, ManagerProjectsActions.CreateProjectCountyAliasesErrorAction),
      take(1),
      map(action => {
        if (action.type === ManagerProjectsActions.CreateProjectCountyAliasesSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public createCountyProjectAliasEffect({
    institutionId,
    managerId,
    programId,
    countyAreaId,
    projectCreate
  }: IInstitutionManagerProgramParams & { countyAreaId: string, projectCreate: ICountyProjectCreateDto }) {
    return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/county-areas/${countyAreaId}/projects`, projectCreate);
  }

  public updateCountyProjectAlias(params: IInstitutionManagerProgramParams & { projectId: string, countyAreaId: string, projectUpdate: ICountyProjectUpdateDto }) {
    this.store.dispatch(ManagerProjectsActions.UpdateProjectCountyAliasesAction(params));

    return this.dispatcher.pipe(
      ofType(ManagerProjectsActions.UpdateProjectCountyAliasesSuccessAction, ManagerProjectsActions.UpdateProjectCountyAliasesErrorAction),
      take(1),
      map(action => {
        if (action.type === ManagerProjectsActions.UpdateProjectCountyAliasesSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateCountyProjectAliasEffect({
    institutionId,
    managerId,
    programId,
    projectId,
    countyAreaId,
    projectUpdate
  }: IInstitutionManagerProgramParams & { projectId: string, countyAreaId: string, projectUpdate: ICountyProjectUpdateDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/county-areas/${countyAreaId}/projects/${projectId}`, projectUpdate);
  }

  public deleteCountyProjectAlias(params: IInstitutionManagerProgramParams & { projectId: string, countyAreaId: string }) {
    this.store.dispatch(ManagerProjectsActions.DeleteProjectCountyAliasesAction(params));

    return this.dispatcher.pipe(
      ofType(ManagerProjectsActions.DeleteProjectCountyAliasesSuccessAction, ManagerProjectsActions.DeleteProjectCountyAliasesErrorAction),
      take(1),
      map(action => {
        if (action.type === ManagerProjectsActions.DeleteProjectCountyAliasesSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteCountyProjectAliasEffect({
    institutionId,
    managerId,
    programId,
    projectId,
    countyAreaId
  }: IInstitutionManagerProgramParams & { projectId: string, countyAreaId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/county-areas/${countyAreaId}/projects/${projectId}`);
  }

  public getNationalProjectCategories(params: IInstitutionProgramParams & { programYearId: string }): Observable<INationalProjectCategoryDto[]> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }

    return this.store.pipe(
      select(getManagerNationalProjectCategories(params)),
      tap(project => {
        if (project === undefined) {
          this.store.dispatch(ManagerProjectsActions.GetNationalProjectCategoriesAction(params));
        }
      }),
      filter(project => project != null)
    );
  }

  public getNationalProjectCategoriesEffect({
    institutionId,
    programId,
    programYearId
  }: IInstitutionProgramParams & { programYearId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/programs/${programId}/national-project-categories/${programYearId}`) as Observable<INationalProjectCategoryDto[]>;
  }

  public getExcludedProjects(params: IInstitutionManagerProgramParams & { countyAreaId: string }) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }

    return this.store.pipe(
      select(getManagerExcludedProjects(params)),
      tap(projects => {
        if (projects === undefined) {
          this.store.dispatch(ManagerProjectsActions.GetExcludedProjectsAction(params));
        }
      }),
      filter(projects => projects != null)
    );
  }

  public getExcludedProjectsEffect({
    institutionId,
    managerId,
    programId,
    countyAreaId
  }: IInstitutionManagerProgramParams & { countyAreaId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/county-areas/${countyAreaId}/project-exclusions`) as Observable<IProjectDto[]>;
  }

  public updateExcludedProject(params: IInstitutionManagerProgramParams & { countyAreaId: string, projects: string[] }) {
    this.store.dispatch(ManagerProjectsActions.UpdateExcludedProjectAction(params));

    return this.dispatcher.pipe(
      ofType(ManagerProjectsActions.UpdateExcludedProjectSuccessAction, ManagerProjectsActions.UpdateExcludedProjectErrorAction),
      take(1),
      map(action => {
        if (action.type === ManagerProjectsActions.UpdateExcludedProjectSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateExcludedProjectEffect({
    institutionId,
    managerId,
    programId,
    countyAreaId,
    projects
  }: IInstitutionManagerProgramParams & { countyAreaId: string, projects: string[] }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/county-areas/${countyAreaId}/project-exclusions`, projects);
  }

  public deleteExcludedProject(params: IInstitutionManagerProgramParams & { countyAreaId: string, projectId: string }) {
    this.store.dispatch(ManagerProjectsActions.DeleteExcludedProjectAction(params));

    return this.dispatcher.pipe(
      ofType(ManagerProjectsActions.DeleteExcludedProjectSuccessAction, ManagerProjectsActions.DeleteExcludedProjectErrorAction),
      take(1),
      map(action => {
        if (action.type === ManagerProjectsActions.DeleteExcludedProjectSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteExcludedProjectEffect({
    institutionId,
    managerId,
    programId,
    countyAreaId,
    projectId
  }: IInstitutionManagerProgramParams & { countyAreaId: string, projectId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/county-areas/${countyAreaId}/project-exclusions/${projectId}`);
  }

  public getCountyExcludedProjects(params: IInstitutionManagerProgramParams & { unitId: string }) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }

    return this.store.pipe(
      select(getCountyManagerExcludedProjects(params)),
      tap(projects => {
        if (projects === undefined) {
          this.store.dispatch(ManagerProjectsActions.GetExcludedCountyProjectsAction(params));
        }
      }),
      filter(projects => projects != null)
    );
  }

  public getCountyExcludedProjectsEffect({
    institutionId,
    managerId,
    programId,
    unitId
  }: IInstitutionManagerProgramParams & { unitId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/units/${unitId}/project-exclusions`) as Observable<IProjectDto[]>;
  }

  public updateCountyExcludedProject(params: IInstitutionManagerProgramParams & { unitId: string, projects: string[] }) {
    this.store.dispatch(ManagerProjectsActions.UpdateExcludedCountyProjectAction(params));

    return this.dispatcher.pipe(
      ofType(ManagerProjectsActions.UpdateExcludedCountyProjectSuccessAction, ManagerProjectsActions.UpdateExcludedCountyProjectErrorAction),
      take(1),
      map(action => {
        if (action.type === ManagerProjectsActions.UpdateExcludedCountyProjectSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateCountyExcludedProjectEffect({
    institutionId,
    managerId,
    programId,
    unitId,
    projects
  }: IInstitutionManagerProgramParams & { unitId: string, projects: string[] }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/units/${unitId}/project-exclusions`, projects);
  }

  public deleteCountyExcludedProject(params: IInstitutionManagerProgramParams & { unitId: string, projectId: string }) {
    this.store.dispatch(ManagerProjectsActions.DeleteExcludedCountyProjectAction(params));

    return this.dispatcher.pipe(
      ofType(ManagerProjectsActions.DeleteExcludedCountyProjectSuccessAction, ManagerProjectsActions.DeleteExcludedCountyProjectErrorAction),
      take(1),
      map(action => {
        if (action.type === ManagerProjectsActions.DeleteExcludedCountyProjectSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteCountyExcludedProjectEffect({
    institutionId,
    managerId,
    programId,
    unitId,
    projectId
  }: IInstitutionManagerProgramParams & { unitId: string, projectId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/units/${unitId}/project-exclusions/${projectId}`);
  }
}
