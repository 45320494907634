import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { eAccessControlListRoleType, EMAIL_REGEX, IManagerInstitutionProfile } from 'app/core/models';
import {
  IManagerProgramHierarchyAssociationUpdateDto,
} from 'app/core/models/serverDTOs/IManagerProgramHierarchyAssociationUpdateDto';
import { ManagerService, RouterService } from 'app/core/services';
import { CommonToastrService } from 'app/core/services/common-toastr.service';
import { filter, map, Observable, switchMap } from 'rxjs';

@Component({
  selector: 'ng4h-modal-add-staff-permissions',
  templateUrl: './modal-add-staff-permissions.component.html',
  styleUrls: ['./modal-add-staff-permissions.component.scss']
})
export class ModalAddStaffPermissionsComponent implements OnInit {

  public addManager: FormGroup;
  public filteredUsers: Observable<IManagerInstitutionProfile[]>;
  public selectedManager: IManagerInstitutionProfile;
  public eAccessControlListRoleType = eAccessControlListRoleType;
  public wasValidated = false;
  constructor(private dialogRef: MatDialogRef<any>, private toastrService: CommonToastrService, private formBuilder: FormBuilder, private routerService: RouterService, private managerService: ManagerService) {
    this.addManager = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, Validators.pattern(EMAIL_REGEX)]],
      role: [eAccessControlListRoleType.HierarchyStaff, [Validators.required]]
    });

    this.addManager.controls.email.valueChanges.subscribe((managerEmail: string) => {
      this.selectedManager = null;
    });

    this.filteredUsers = this.addManager.controls['email'].valueChanges
      .pipe(
        // debounceTime(500),
        map((val: string) => val.toUpperCase()),
        switchMap(value => {
          return this.managerService.getManagers({ institutionId: this.routerService.institutionId }).pipe(
            filter(managers => Array.isArray(managers)),
            map(managers => {
              return managers.filter(manager => {
                const testMatch = manager.email.toUpperCase().includes(value) ||
                  manager.firstName?.toUpperCase().includes(value) ||
                  manager.lastName?.toUpperCase().includes(value) ||
                  (manager.lastName + ' ' + manager.firstName).toUpperCase().includes(value) ||
                  (manager.lastName + ', ' + manager.firstName).toUpperCase().includes(value) ||
                  // This is more for devs to search by id, maybe remove?
                  manager.managerId.toUpperCase().includes(value);

                let managerAlreadyExistsInHierarchy = false;
                const hierarchyAssociations = manager.managerPrograms[this.routerService.programId];
                if (hierarchyAssociations != null) {
                  const hierarchyAssociation = hierarchyAssociations.find(ha => ha.hierarchyNodeId === this.routerService.hierarchyNodeId);
                  if (hierarchyAssociation != null) {
                    managerAlreadyExistsInHierarchy = true;
                  }
                }

                return testMatch && !managerAlreadyExistsInHierarchy;
              });
            }));
        })
      );
  }

  ngOnInit() {
  }


  public close() {
    this.dialogRef.close();
  }

  public add() {
    if (this.addManager.invalid) {
      this.wasValidated = true;
      return;
    }
    const update: IManagerProgramHierarchyAssociationUpdateDto = {
      acl: {
        permissionRole: this.addManager.controls['role'].value,
        grantedPermissions: null
      },
      primary: false
    };
    this.managerService.updateManagerPermissionsAsSystemManager({
      institutionId: this.routerService.institutionId,
      systemManagerId: this.routerService.systemManagerId,
      programId: this.routerService.programId,
      managerId: this.selectedManager.managerId,
      hierarchyNodeId: this.routerService.hierarchyNodeId,
      update
    })
      .subscribe({
        next: () => {
          this.toastrService.success('permissions updated');
          this.close();
        }, error: (error) => {
          this.toastrService.error('permission update failed');
        }
      });
  }

  public managerSelected(manager) {
    this.selectedManager = manager;
  }
}
