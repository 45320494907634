import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IProgramSummaryDto } from 'app/core/models';
import { RouterService } from 'app/core/services';
import { ProgramService } from 'app/core/services/program.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'ng4h-program-dropdown',
  templateUrl: './program-dropdown.component.html',
  styleUrls: ['./program-dropdown.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ProgramDropdownComponent),
    multi: true
  }]
})
export class ProgramDropdownComponent implements ControlValueAccessor {

  public availablePrograms$: Observable<IProgramSummaryDto[]>;
  @Input() value = '';


  onChange: any = () => { };
  onTouched: any = () => { };

  constructor(private routerService: RouterService, private programService: ProgramService) {
    this.availablePrograms$ = this.programService.getPrograms({ institutionId: this.routerService.institutionId });
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  writeValue(value) {
    if (value != null) {
      this.value = value;
      this.onChange(value);
      this.onTouched();
    }
  }


}
