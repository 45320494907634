import { Action, createReducer, on } from '@ngrx/store';
import { IFairEntryIntegrationDto, IInstitutionManagerProgramHierarchyModel } from 'app/core/models';
import { mergeImmutable } from 'app/shared/utils';

import { ManagerFairEntryActions } from '.';

export interface ManagerFairEntryState {
    fairIntegrations: IInstitutionManagerProgramHierarchyModel<IFairEntryIntegrationDto[]>;
    pendingFairIntegrations: IInstitutionManagerProgramHierarchyModel<IFairEntryIntegrationDto[]>;
}

const initialState: ManagerFairEntryState = {
    fairIntegrations: undefined,
    pendingFairIntegrations: undefined
};

const reducer = createReducer(
    initialState,
    on(ManagerFairEntryActions.ManagerFairEntryGetIntegrationsSuccessAction, (state, { institutionId, managerId, programId, hierarchyNodeId, fairIntegrations }) => {
        const newFairIntegrations = mergeImmutable(
            { [institutionId]: { [managerId]: { [programId]: { [hierarchyNodeId]: fairIntegrations } } } },
            state.fairIntegrations
        );
        return {
            ...state,
            fairIntegrations: newFairIntegrations
        };
    }),
    on(ManagerFairEntryActions.ManagerFairEntryGetPendingIntegrationsSuccessAction, (state, { institutionId, managerId, programId, hierarchyNodeId, fairIntegrations }) => {
        const newPendingFairIntegrations = mergeImmutable(
            { [institutionId]: { [managerId]: { [programId]: { [hierarchyNodeId]: fairIntegrations } } } },
            state.pendingFairIntegrations
        );
        return {
            ...state,
            pendingFairIntegrations: newPendingFairIntegrations
        };
    }),
    on(ManagerFairEntryActions.ManagerFairEntryAcceptIntegrationSuccessAction,
        ManagerFairEntryActions.ManagerFairEntryRejectIntegrationSuccessAction,
        ManagerFairEntryActions.ManagerFairEntryCancelIntegrationSuccessAction, (state, { }) => {
            return initialState;
        })

);

export function managerFairEntryReducer(state: ManagerFairEntryState | undefined, action: Action) {
    return reducer(state, action);
}

export const fairIntegrations = (state: ManagerFairEntryState) => state.fairIntegrations;
export const pendingFairIntegrations = (state: ManagerFairEntryState) => state.pendingFairIntegrations;
