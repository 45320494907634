import { eConsentStatus } from '..';

export interface IPartialPaymentSubmitDto {
    paymentAmount: number;
    consentVersionId: string;
    recordedMemberName?: string;
    recordedParentGuardianName?: string;
    recordedManagerName?: string;
    consentStatus: eConsentStatus;
    // ipAddress?: string;
}
