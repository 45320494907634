import { IHierarchyNodeDto, IMemberDescriptorDto, IProfileDescriptorDto } from '.';
import { eEnrollmentFeeWaiverRequestStatus } from '..';
import { eEnrollmentRoleType } from '../enums';

export interface IEnrollmentFeeWaiverRequestSearchResultDto {
    enrollmentId: string;
    invoiceId: string;
    enrollmentCounty: IHierarchyNodeDto;
    member: IMemberDescriptorDto;
    status: eEnrollmentFeeWaiverRequestStatus;
    reviewedBy: IProfileDescriptorDto;
    requestFeeWaiver: boolean;
    explanation?: string;
    enrollmentRole: eEnrollmentRoleType;
}
