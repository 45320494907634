import { Component, OnInit } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject } from '@ngrx/store';
import { AutoUnsubscribe, eHierarchyNodeType } from 'app/core/models';
import { HierarchyService, RouterService } from 'app/core/services';
import { HierarchyActions } from 'app/shared/hierarchy';
import { Observable, switchMap, takeUntil } from 'rxjs';

@Component({
  selector: 'ng4h-hierarchy-breadcrumb',
  templateUrl: './hierarchy-breadcrumb.component.html',
  styleUrls: ['./hierarchy-breadcrumb.component.scss']
})
export class HierarchyBreadcrumbComponent extends AutoUnsubscribe implements OnInit {

  private hierarchyNodeId$: Observable<string>;

  public programId: string;

  public institutionName: string;
  public regionAreaName: string;
  public districtAreaName: string;
  public countyAreaName: string;
  constructor(private routerService: RouterService, private hierarchyService: HierarchyService, private dispatcher: ActionsSubject) {
    super();
    this.programId = this.routerService.programId;
  }

  ngOnInit() {
    this.dispatcher.pipe(
      ofType(HierarchyActions.HierarchyLoadSuccessAction),
      switchMap(() => {
        return this.hierarchyService.getHierarchyNodeAndParents({ institutionId: this.routerService.institutionId, hierarchyNodeId: this.routerService.hierarchyNodeId })
      }),
      takeUntil(this.autoUnsubscribe)
    ).subscribe(nodes => {
      if (nodes[eHierarchyNodeType.Institution] !== undefined) {
        this.institutionName = nodes[eHierarchyNodeType.Institution].hierarchyNodeName;
      }

      if (nodes[eHierarchyNodeType.RegionArea] !== undefined) {
        this.regionAreaName = nodes[eHierarchyNodeType.RegionArea].hierarchyNodeName;
      }

      if (nodes[eHierarchyNodeType.DistrictArea] !== undefined) {
        this.districtAreaName = nodes[eHierarchyNodeType.DistrictArea].hierarchyNodeName;
      }

      if (nodes[eHierarchyNodeType.CountyArea] !== undefined) {
        this.countyAreaName = nodes[eHierarchyNodeType.CountyArea].hierarchyNodeName;
      }
    });
  }

}
