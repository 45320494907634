<div class="modal-header">
    <div class="modal-title">Change Status</div>
    <button (click)="close()" type="button" class="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="form">

        <div class="form-group row">
            <label class="form-control-label col-md-3">Block Login</label>
            <div class="col-md-9">
                <mat-checkbox formControlName="down"></mat-checkbox>
            </div>
        </div>

        <div class="form-group row">
            <label class="form-control-label col-md-3">Message</label>
            <div class="col-md-9">
                <textarea formControlName="message" class="form-control">
                    </textarea>
            </div>
        </div>

        <div class="form-group row">
            <label class="form-control-label col-md-3">Allow System Managers</label>
            <div class="col-md-9">
                <mat-checkbox formControlName="allowSystemManagers"></mat-checkbox>
            </div>
        </div>

        <div class="form-group row">
            <label class="form-control-label col-md-3">Allow Managers</label>
            <div class="col-md-9">
                <mat-checkbox formControlName="allowManagers"></mat-checkbox>
            </div>
        </div>


    </form>
</div>
<div class="modal-footer">
    <button (click)="close()" class="btn btn-secondary" role="button" aria-pressed="true">Cancel</button>
    <button [disabled]="form.invalid" (click)="save()" class="btn btn-primary" role="button"
        aria-pressed="true">Save</button>
</div>