import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CardActionsComponent } from './card-actions/card-actions.component';
import { CardContentComponent } from './card-content/card-content.component';
import { CardFooterComponent } from './card-footer/card-footer.component';
import { CardHeaderTitleTextComponent } from './card-header-title-text/card-header-title-text.component';
import { CardHeaderComponent } from './card-header/card-header.component';
import { CardComponent } from './card.component';

const components = [
  CardComponent,
  CardHeaderComponent,
  CardContentComponent,
  CardActionsComponent,
  CardFooterComponent,
  CardHeaderTitleTextComponent
];
@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [
    CommonModule
  ]
})
export class CardModule { }
