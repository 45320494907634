import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import { IInstitutionMemberProgramEnrollmentParams } from 'app/core/models';

import * as test from './store/hours.reducer';


export const MEMBER_HOURS_FEATURE = 'MEMBER_HOURS_FEATURE';

export interface MemberHoursFeatureStore {
    memberHours: test.MemberHoursState;
}

export const memberHoursReducers: ActionReducerMap<MemberHoursFeatureStore> = {
    memberHours: test.memberHoursReducer
};

export const getMemberHoursFeatureStore = createFeatureSelector<MemberHoursFeatureStore>(MEMBER_HOURS_FEATURE);

export const getMemberHoursState = createSelector(getMemberHoursFeatureStore, (state: MemberHoursFeatureStore) => state.memberHours);

export const getMemberVolunteerHours = ({ institutionId, memberId, programId, enrollmentId }: IInstitutionMemberProgramEnrollmentParams) => createSelector(
    createSelector(getMemberHoursState, test.hours),
    hours => hours?.[institutionId]?.[memberId]?.[programId]?.[enrollmentId]
);



export const getMemberVolunteerHoursProjects = ({ institutionId, memberId, programId, enrollmentId }: IInstitutionMemberProgramEnrollmentParams) => createSelector(
    createSelector(getMemberHoursState, test.projects),
    projects => projects?.[institutionId]?.[memberId]?.[programId]?.[enrollmentId]
);
