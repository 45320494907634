import { Action, createReducer, on } from '@ngrx/store';
import { IStandardReportDescriptorDto } from 'app/core/models';
import { mergeImmutable } from 'app/shared/utils';

import { StandardReportActions } from '.';

export interface ManagerStandardReportsState {
    standardReports: IStandardReportDescriptorDto[];
}

const initialState: ManagerStandardReportsState = {
    standardReports: undefined
};

const reducer = createReducer(
    initialState,
    on(StandardReportActions.GetStandardReportsSuccessAction, (state, { institutionId, managerId, programId, reports }) => {
        return {
            ...state,
            standardReports: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: reports } } },
                state.standardReports
            )
        };
    })
);

export function standardReportsReducer(state: ManagerStandardReportsState | undefined, action: Action) {
    return reducer(state, action);
}

export const standardReports = (state: ManagerStandardReportsState) => state.standardReports;
