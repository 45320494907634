<h4>Hierarchy Area</h4>
<div class="ng4h-table col-12">

  <div class="row" *ngIf="programId">
    <div class="col-4 ng4h-bg-secondary"><b>Program</b></div>
    <div class="col-8">{{programId | program$ | async}}</div>
  </div>
  <div class="row">
    <div class="col-4 ng4h-bg-secondary"><b>Institution</b></div>
    <div class="col-8">{{institutionName}}</div>
  </div>
  <div class="row" *ngIf="regionAreaName">
    <div class="col-4 ng4h-bg-secondary"><b>Region</b></div>
    <div class="col-8">{{regionAreaName}}</div>
  </div>
  <div class="row" *ngIf="districtAreaName">
    <div class="col-4 ng4h-bg-secondary"><b>District</b></div>
    <div class="col-8">{{districtAreaName}}</div>
  </div>
  <div class="row" *ngIf="countyAreaName">
    <div class="col-4 ng4h-bg-secondary"><b>County</b></div>
    <div class="col-8">{{countyAreaName}}</div>
  </div>
</div>