export enum eEventRegistrationStepperSteps {
    Animal = 'Animal',
    Sessions = 'Session',
    Contests = 'Contest',
    Questions = 'Questions',
    HealthForm = 'Health Form',
    Consents = 'Consents',
    // Tickets = 'Tickets',
    Payment = 'Payment',
    Confirm = 'Confirm'
}
