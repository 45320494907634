import { AbstractControl, ValidatorFn } from '@angular/forms';

export function customRegexValidator(regex: string, errorMessage: string): ValidatorFn {
  const REGEX = new RegExp(regex);
  const ERROR_MESSAGE = errorMessage;
  return (control: AbstractControl): { [key: string]: any } => {
    if (control.value == null) {
      return null;
    }
    if (REGEX.test(control.value) === false) {
      return { [ERROR_MESSAGE]: true };
    }
    return null;
  };
}
