import { FormGroup } from '@angular/forms';

export function MatchValidator(ctrlOne: string, ctrlTwo: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[ctrlOne];
    const matchingControl = formGroup.controls[ctrlTwo];

    if (control.pristine || matchingControl.pristine) {
      return;
    }

    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ match: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}
