import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ModalService, RecoverAccountService, RouterService, UserService } from 'app/core/services';
import { stringKeys } from 'app/shared/rxjs.utils';
import { SidebarActions } from 'app/shared/sidebar';
import { anyNull } from 'app/shared/utils';
import { catchError, exhaustMap, groupBy, map, mergeMap, Observable, of, switchMap, take, tap } from 'rxjs';

import { UserActions } from '.';
import { LocalStorageService } from '../../services/local-storage.service';

@Injectable()
export class UserEffects {

    public authenticate$: Observable<Action> = createEffect(() => this.actions
    .pipe(
        ofType(UserActions.AuthenticateAction),
        switchMap(payload => {
            return this.userService.authenticateEffect(payload.credentials).pipe(
                take(1),
                map(credentials => {

                    const existingCredentials = this.localStorageService.getLoginCredentials(credentials.userAccountId);
                    if (existingCredentials != null) {
                        this.localStorageService.deleteLoginCredentials(credentials.userAccountId);
                    }

                    if (credentials.passwordChangeRequired) {
                        this.localStorageService.setLoginCredentials(credentials);
                        return UserActions.AuthenticationErrorAction({
                            error: new Error('Password change is required, please reset password'),
                            credentials
                        });
                    }
                    if (credentials.passwordExpired) {
                        return UserActions.AuthenticationErrorAction({
                            error: new Error('Password has expired, please reset password'),
                            credentials
                        });
                    }
                    if (credentials['2FARequired'] != null && credentials['2FAVerified'] === false) {
                        return UserActions.AuthenticationDoTwoFactorAction({ credentials: credentials });
                    }

                    if (anyNull(credentials.userAccountAuth, credentials.userAccountToken)) {
                        return UserActions.AuthenticationErrorAction({
                            error: new Error('Returned IInstitutionProfileAuthDto incomplete'),
                            credentials
                        });
                    }
                    return UserActions.AuthenticationSuccessAction({ credentials });
                }),
                catchError(error => of(UserActions.AuthenticationErrorAction({ error, credentials: null })))
            );
        })
    ));

    public magicLinkAuthenticate$: Observable<Action> = createEffect(() => this.actions
    .pipe(
        ofType(UserActions.MagicLinkAuthenticateAction),
        switchMap(payload => {
            return this.userService.magicLinkLoginEffect(payload).pipe(
                take(1),
                map(credentials => {

                    const existingCredentials = this.localStorageService.getLoginCredentials(credentials.userAccountId);
                    if (existingCredentials != null) {
                        this.localStorageService.deleteLoginCredentials(credentials.userAccountId);
                    }

                    if (credentials.passwordChangeRequired) {
                        this.localStorageService.setLoginCredentials(credentials);
                        return UserActions.AuthenticationErrorAction({
                            error: new Error('Password change is required, please reset password'),
                            credentials
                        });
                    }
                    if (credentials.passwordExpired) {
                        return UserActions.AuthenticationErrorAction({
                            error: new Error('Password has expired, please reset password'),
                            credentials
                        });
                    }
                    if (credentials['2FARequired'] != null && credentials['2FAVerified'] === false) {
                        return UserActions.AuthenticationDoTwoFactorAction({ credentials: credentials });
                    }

                    if (anyNull(credentials.userAccountAuth, credentials.userAccountToken)) {
                        return UserActions.AuthenticationErrorAction({
                            error: new Error('Returned IInstitutionProfileAuthDto incomplete'),
                            credentials
                        });
                    }
                    return UserActions.AuthenticationSuccessAction({ credentials });
                }),
                catchError(error => of(UserActions.AuthenticationErrorAction({ error, credentials: null })))
            );
        })
    ));

    public twoFactor$: Observable<Action> = createEffect(() => this.actions.pipe(
        ofType(UserActions.AuthenticationDoTwoFactorAction),
        tap(() => {
            return this.routerService.Go(['user', 'two-factor']);
        })
    ), { dispatch: false });

    public twoFactorResult$: Observable<Action> = createEffect(() => this.actions.pipe(
        ofType(UserActions.AuthenticationDoTwoFactorSuccessAction),
        map(payload => {
            return UserActions.AuthenticationSuccessAction({ credentials: payload.credentials });
        })
    ));

    public authenticateSuccess$: Observable<Action> = createEffect(() => this.actions
    .pipe(
        ofType(UserActions.AuthenticationSuccessAction),
        tap(payload => {
            this.localStorageService.setLoginCredentials(payload.credentials);
        }),
        map(payload =>
            UserActions.GetUserAction({ userAccountToken: payload.credentials.userAccountToken })
        )));

    public getUser$: Observable<Action> = createEffect(() => this.actions
    .pipe(
        ofType(UserActions.GetUserAction),
        groupBy(stringKeys),
        mergeMap(groupedBy => {
            return groupedBy.pipe(
                exhaustMap(payload => {
                    return this.userService.getUserEffect(payload.userAccountToken).pipe(
                        take(1),
                        map(user => {
                            return UserActions.GetUserSuccessAction({ user: user, userAccountToken: payload.userAccountToken });
                        }),
                        catchError(error => of(UserActions.GetUserErrorAction({ error })))
                    );
                })
            );
        })
    ));

    public createUser$: Observable<Action> = createEffect(() => this.actions
    .pipe(
        ofType(UserActions.SignUpAction),
        switchMap(payload => {
            return this.userService.createUserEffect(payload.familyAccount).pipe(
                take(1),
                map(user => UserActions.SignUpSuccessAction({ user: user })),
                catchError(error => of(UserActions.SignUpErrorAction({ error })))
            );
        })
    ));

    public signOut$: Observable<Action> = createEffect(() => this.actions
    .pipe(
        ofType(UserActions.SignOutAction, UserActions.GetUserErrorAction),
        switchMap(payload => {
            return this.userService.signOutEffect().pipe(
                mergeMap(value => [UserActions.SignOutSuccessAction(), SidebarActions.CloseSidebarAction()]),
                catchError(error => of(UserActions.SignOutErrorAction()))
            );
        })
    ));

    public signOutSuccess$: Observable<Action> = createEffect(() => this.actions
    .pipe(
        ofType(UserActions.SignOutSuccessAction),
        tap(() => {
            this.modalService.closeAll();
            if (!window.location.href.includes('public')) {
                this.routerService.Go(['user', 'sign-in']);
            }
        })
    ), { dispatch: false });

    public recoverAccount$: Observable<Action> = createEffect(() => this.actions
    .pipe(
        ofType(UserActions.RecoverAccountAction),
        switchMap(payload => {
            return this.recoverAccountService.recoverAccountEffect(payload.code, payload.securityStamp).pipe(
                take(1),
                map(value => UserActions.RecoverAccountSuccessAction({ recoverAccount: value })),
                catchError(error => of(UserActions.RecoverAccountErrorAction({ error })))
            );
        })
    ));

    public resetPassword$: Observable<Action> = createEffect(() => this.actions
    .pipe(
        ofType(UserActions.ResetPasswordAction),
        switchMap(payload => {
            return this.recoverAccountService.resetPasswordEffect(payload.email).pipe(
                take(1),
                map(value => UserActions.ResetPasswordSuccessAction()),
                catchError(error => of(UserActions.ResetPasswordErrorAction({ error })))
            );
        })
    ));

    public changePassword$: Observable<Action> = createEffect(() => this.actions
    .pipe(
        ofType(UserActions.ChangePasswordAction),
        switchMap(payload => {
            return this.userService.changePasswordEffect(payload.passwordChange).pipe(
                take(1),
                map(val => UserActions.ChangePasswordSuccessAction()),
                catchError(error => of(UserActions.ChangePasswordErrorAction({ error })))
            );
        })
    ));

    public recoveryHandler = createEffect(() => this.actions
    .pipe(
        ofType(
            UserActions.RecoverAccountErrorAction
        ),
        tap(() => this.routerService.Go(['user', 'sign-in']))
    ), { dispatch: false });

    public createFamily$: Observable<Action> = createEffect(() => this.actions.pipe(
        ofType(UserActions.FamilyCreateAction),
        groupBy(stringKeys),
        mergeMap(groupedBy => {
            return groupedBy.pipe(
                exhaustMap(payload => {
                    return this.userService.managerCreateFamilyEffect(payload).pipe(
                        map(family => UserActions.FamilyCreateSuccessAction({ ...payload, family })),
                        catchError(error => of(UserActions.FamilyCreateErrorAction({ error })))
                    );
                })
            );
        })
    ));

    public createNewFamilyForInstitution$: Observable<Action> = createEffect(() => this.actions.pipe(
        ofType(UserActions.CreateNewFamilyForInstitutionAction),
        groupBy(stringKeys),
        mergeMap(groupedBy => {
            return groupedBy.pipe(
                exhaustMap(payload => {
                    return this.userService.createNewFamilyForInstitutionEffect(payload).pipe(
                        map(() => UserActions.CreateNewFamilyForInstitutionSuccessAction(payload)),
                        catchError(error => of(UserActions.CreateNewFamilyForInstitutionErrorAction({ error })))
                    );
                })
            );
        })
    ));
    public emailOptIn$: Observable<Action> = createEffect(() => this.actions.pipe(
        ofType(UserActions.EmailOptInAction),
        groupBy(stringKeys),
        mergeMap(groupedBy => {
            return groupedBy.pipe(
                exhaustMap(payload => {
                    return this.userService.emailOptInEffect(payload).pipe(
                        map(() => UserActions.EmailOptInSuccessAction(payload)),
                        catchError(error => of(UserActions.EmailOptInErrorAction({ error })))
                    );
                })
            );
        })
    ));

    public emailOptOut$: Observable<Action> = createEffect(() => this.actions.pipe(
        ofType(UserActions.EmailOptOutAction),
        groupBy(stringKeys),
        mergeMap(groupedBy => {
            return groupedBy.pipe(
                exhaustMap(payload => {
                    return this.userService.emailOptOutEffect(payload).pipe(
                        map(() => UserActions.EmailOptOutSuccessAction(payload)),
                        catchError(error => of(UserActions.EmailOptOutErrorAction({ error })))
                    );
                })
            );
        })
    ));

    constructor(
        private actions: Actions,
        private userService: UserService,
        private recoverAccountService: RecoverAccountService,
        private routerService: RouterService,
        private localStorageService: LocalStorageService,
        private modalService: ModalService
    ) {
    }
}
