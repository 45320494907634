import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IEnrollmentDto, IEnrollmentUnitSubmissionDto, IFileControl } from 'app/core/models';
import { CommonToastrService, EnrollmentService, ModalService, RouterService } from 'app/core/services';
import { Observable, switchMap, take } from 'rxjs';

@Component({
  selector: 'ng4h-modal-edit-enrollment-clubs',
  templateUrl: './modal-edit-enrollment-clubs.component.html',
  styleUrls: ['./modal-edit-enrollment-clubs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalEditEnrollmentClubsComponent implements OnInit {

  public enrollment$: Observable<IEnrollmentDto>;

  constructor(private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public fileControl: IFileControl,
    private enrollmentService: EnrollmentService,
    private routerService: RouterService,
    private modalService: ModalService,
    private toastrService: CommonToastrService
  ) {

    this.enrollment$ = this.enrollmentService.getEnrollment({
      institutionId: this.routerService.institutionId,
      memberId: this.routerService.memberId ? this.routerService.memberId : this.routerService.selectedMemberId,
      programId: this.routerService.programId,
      enrollmentId: this.routerService.enrollmentId
    });
  }


  ngOnInit(): void {
  }

  public save() {


    return this.enrollment$
      .pipe(
        take(1),
        switchMap((enrollment) => {
          const unitSubmission: IEnrollmentUnitSubmissionDto[] = enrollment.units.map(club => {
            return {
              unitId: club.unitId,
              primaryClub: club.primary,
              volunteerTypeId: club.volunteerType ? club.volunteerType.volunteerTypeId : null
            };
          });

          return this.enrollmentService.saveClubs({
            institutionId: this.routerService.institutionId,
            memberId: this.routerService.memberId ? this.routerService.memberId : this.routerService.selectedMemberId,
            programId: this.routerService.programId,
            enrollmentId: this.routerService.enrollmentId,
            clubs: unitSubmission
          });
        }),
        take(1),
        switchMap(() => this.enrollmentService.refreshEnrollment()),
        take(1),
        this.modalService.showProgressSpinnerModalUntilComplete(),
      ).subscribe({
        next: () => {
          this.close();
          this.toastrService.success('Club Added.');
        }, error: (error) => {
          this.toastrService.error('Could not add Club.', error);
        }
      });

  }
  public cancel() {
    this.enrollmentService.refreshEnrollment().pipe(
      take(1),
      this.modalService.showProgressSpinnerModalUntilComplete()
    ).subscribe(() => this.close());
  }
  public close(result?: any): void {
    this.dialogRef.close(result);
  }

}
