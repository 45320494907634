<div class="modal-section">
    <div class="modal-header">
        <h5 class="modal-title">Associate Consent with Volunteer Type</h5>
        <button (click)="cancel()" type="button" class="close" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <form [formGroup]="form">
            <div class="form-group col-sm-12 row">
                <label class="form-control-label col-4">Role</label>
                <ng4h-enrollment-role-dropdown class="col-8" formControlName="enrollmentRole">
                </ng4h-enrollment-role-dropdown>
            </div>
            <div class="form-group col-sm-12 row">
                <label class="form-control-label col-4">Consent</label>
                <select formControlName="consentTypeId" class="form-control col-8">
                    <option *ngFor="let consent of consents$ | async" [value]="consent.consentTypeId">
                        {{ consent.consentName }}
                    </option>
                </select>
            </div>

        </form>
    </div>

    <div class="modal-footer" *ngIf="form">
        <button id (click)="cancel()" class="btn btn-secondary my-2 my-sm-0" role="button"
            aria-pressed="true">Cancel</button>
        <button [disabled]="form.invalid" (click)="save()" class="btn btn-primary my-2 my-sm-0" role="button"
            aria-pressed="true">Save</button>
    </div>

</div>
