import { ArrayDataSource } from '@angular/cdk/collections';
import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, OnInit } from '@angular/core';
import {
  AutoUnsubscribe,
  eHierarchyNodeType,
  IHierarchyAny,
  IHierarchyCountyArea,
  IHierarchyDistrictArea,
  IHierarchyInstitution,
  IHierarchyRegionArea,
} from 'app/core/models';
import { HierarchyService, RouterService } from 'app/core/services';
import { filter, Observable, take } from 'rxjs';

@Component({
  selector: 'ng4h-hierarchy-tree',
  templateUrl: './hierarchy-tree.component.html',
  styleUrls: ['./hierarchy-tree.component.scss']
})
export class HierarchyTreeComponent extends AutoUnsubscribe implements OnInit {

  public eHierarchyNodeType = eHierarchyNodeType;
  public hierarchyLeaf$: Observable<IHierarchyAny>;

  treeDataSource: ArrayDataSource<IHierarchyAny>;
  treeControl: NestedTreeControl<IHierarchyAny>;
  // checklistSelection: SelectionModel<IHierarchyAny>;

  public selectedInstitution: Observable<IHierarchyInstitution>;
  public selectedRegionArea: Observable<IHierarchyRegionArea>;
  public selectedDistrictArea: Observable<IHierarchyDistrictArea>;
  public selectedCountyArea: Observable<IHierarchyCountyArea>;

  constructor(private hierarchyService: HierarchyService, private routerService: RouterService) {
    super();
    // this.selectedInstitution = this.hierarchyService.selectedInstitution$.pipe(takeUntil(this.autoUnsubscribe));
    // this.selectedRegionArea = this.hierarchyService.selectedRegionArea$.pipe(takeUntil(this.autoUnsubscribe));
    // this.selectedDistrictArea = this.hierarchyService.selectedDistrictArea$.pipe(takeUntil(this.autoUnsubscribe));
    // this.selectedCountyArea = this.hierarchyService.selectedCountyArea$.pipe(takeUntil(this.autoUnsubscribe));

    // this.hierarchyLeaf$ = this.hierarchyService.hierarchyLeaf$.pipe(takeUntil(this.autoUnsubscribe));

    this.hierarchyService.getHierarchy({ institutionId: this.routerService.institutionId }).pipe(
      filter(hierarchy => hierarchy != null),
      take(1)
    ).subscribe(hierarchy => {
      // this.treeDataSource = new ArrayDataSource(hierarchy.regionAreas);
      // this.treeControl = new NestedTreeControl<IHierarchyAny>(node => {
      //   switch (node.hierarchyNodeType) {
      //     case eHierarchyNodeType.Institution: {
      //       return node.regionAreas;
      //     }
      //     case eHierarchyNodeType.RegionArea: {
      //       return node.districtAreas;
      //     }
      //     case eHierarchyNodeType.DistrictArea: {
      //       return node.countyAreas;
      //     }
      //   }
      // });
      // this.checklistSelection = new SelectionModel<IHierarchyAny>(false, []);

      // this.hierarchyLeaf.subscribe(leaf => {
      //   // const localRef = hierarchy.regionAreas.find(node => node.hierarchyNodeId === leaf.hierarchyNodeId);
      //   this.checklistSelection.select(leaf);
      // });
    });

  }

  public isRegion(_: number, node: IHierarchyAny): boolean {
    return node.hierarchyNodeType === eHierarchyNodeType.RegionArea;
  }
  public isDistrict(_: number, node: IHierarchyAny): boolean {
    return node.hierarchyNodeType === eHierarchyNodeType.DistrictArea;
  }
  public isCounty(_: number, node: IHierarchyAny): boolean {
    return node.hierarchyNodeType === eHierarchyNodeType.CountyArea;
  }

  // /** Whether part of the descendants are selected */
  // public descendantsPartiallySelected(node: IHierarchyAny): boolean {
  //   const descendants = this.treeControl.getDescendants(node);
  //   const result = descendants.some(child => this.checklistSelection.isSelected(child));
  //   return result;
  // }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  public todoItemSelectionToggle(node: IHierarchyAny): void {
    // this.checklistSelection.toggle(node);

    // switch (node.hierarchyNodeType) {
    //   // case eHierarchyNodeType.Institution: {
    //   //   this.hierarchyService.selectInstitution(node.institutionId);
    //   //   break;
    //   // }
    //   case eHierarchyNodeType.RegionArea: {
    //     this.hierarchyService.selectRegionArea(node);
    //     break;
    //   }
    //   case eHierarchyNodeType.DistrictArea: {
    //     this.hierarchyService.selectDistrictArea(node);
    //     break;
    //   }
    //   case eHierarchyNodeType.CountyArea: {
    //     this.hierarchyService.selectCountyArea(node);
    //   }
    // }
  }

  ngOnInit() {
  }

}
