import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IEnrollmentFamilyDiscountDto, IFamilyCountyDiscount, IInstitutionManagerProgramParams } from 'app/core/models';





export const ManagerLoadFamilyCountyDiscountsAction = createAction(
  '[manager discounts] load family county discounts',
  props<IInstitutionManagerProgramParams & { programYearId: string }>()
);

export const ManagerLoadFamilyCountyDiscountsSuccessAction = createAction(
  '[manager discounts] load family county discounts success',
  props<IInstitutionManagerProgramParams & { programYearId: string, discounts: IFamilyCountyDiscount[] }>()
);

export const ManagerLoadFamilyCountyDiscountsErrorAction = createAction(
  '[manager discounts] load family county discounts error',
  props<{ error: HttpErrorResponse }>()
);


export const ManagerUpdateFamilyCountyDiscountsAction = createAction(
  '[manager discounts] update family county discounts',
  props<IInstitutionManagerProgramParams & { programYearId: string, hierarchyNodeId: string, discounts: IEnrollmentFamilyDiscountDto[] }>()
);

export const ManagerUpdateFamilyCountyDiscountsSuccessAction = createAction(
  '[manager discounts] update family county discounts success',
  props<IInstitutionManagerProgramParams & { programYearId: string, hierarchyNodeId: string, discounts: IEnrollmentFamilyDiscountDto[] }>()
);

export const ManagerUpdateFamilyCountyDiscountsErrorAction = createAction(
  '[manager discounts] update family county discounts error',
  props<{ error: HttpErrorResponse }>()
);
