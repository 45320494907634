import { eFormControlType, eStandardFieldStatus } from '..';

export interface IStandardFormFieldConfigDto {
    label?: string;
    inputName?: string;
    inputType: eFormControlType;
    inputText?: string;
    inputState: eStandardFieldStatus;
    childInputName?: string;
    parentInputName?: string;
    highlight?: string;

    singleChoiceOptions: { [key: string]: string };
    validationRegex: string;
    validationViolationMessage: string;
}
