import { IManagerHierarchyAssociationCreateDto } from '.';
import { eProfileType } from '..';
import { IAddressDto } from './IAddressDto';
import { IManagerHierarchyAssociationDto } from './IManagerHierarchyAssociationDto';

export interface IManagerDto {
    managerId: string;
    profileToken: string;
    institutionProfileId: string;
    profileId: string;
    profileType: eProfileType;
    institutionId: string;
    address: IAddressDto;
    hierarchyAssociations?: IManagerHierarchyAssociationDto[];
    initialHierarchyNode: IManagerHierarchyAssociationCreateDto;
    useraccountToken?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
}
