import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs';

import { ToastrActions } from '.';


@Injectable()
export class ToastrEffects {

  showSuccess = createEffect(() => this.actions
    .pipe(
      ofType(ToastrActions.ToastrShowSuccessAction),
      tap(payload => {
        this.toastr.success(payload.message, '', { progressBar: true, closeButton: true });
      })
    ), { dispatch: false });

  showError = createEffect(() => this.actions
    .pipe(
      ofType(ToastrActions.ToastrShowErrorAction),
      tap(payload => {
        this.toastr.error(payload.message, '', { timeOut: 30000, progressBar: true, closeButton: true });
      })
    ), { dispatch: false });

  constructor(private actions: Actions, private toastr: ToastrService) { }
}
