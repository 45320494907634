import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState, getAllCounties, getCounties, getInstitutions } from 'app/app.reducers';
import { ICountyDescriptorDto, IInstitution, IInstitutionDescriptorDto } from 'app/core/models';
import { InstitutionActions } from 'app/shared/institution';
import { filter, map, Observable, of, shareReplay, tap } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InstitutionService {

  public institutions$: Observable<IInstitution[]>;

  constructor(private store: Store<AppState>, private httpClient: HttpClient) {
    this.institutions$ = this.store.pipe(
      select(getInstitutions),
      tap(institution => {
        if (institution === undefined) {
          this.store.dispatch(InstitutionActions.InstitutionsLoadAction());
        }
      }),
      filter(institutions => institutions != null),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  // cached for 10min
  public getInstitutionLogoSrc({ institutionId }: { institutionId: string }) {
    if (institutionId == null) {
      return null;
    }

    return `${environment.apiUri}/api/institutions/${institutionId}/logo`;
  }

  public getInstitutionsEffect(): Observable<IInstitution[]> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions`) as Observable<IInstitution[]>;
  }

  public getInstitutionById({ institutionId }: { institutionId: string }): Observable<IInstitution> {
    return this.institutions$.pipe(filter(data => data !== null && data !== undefined), map(institutions => institutions.find(institution => institution.institutionId === institutionId)));

  }

  public getInstitution({ institutionId }: { institutionId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}`) as Observable<IInstitutionDescriptorDto>;
  }

  public getInstitutionCounties(params: { institutionId: string }): Observable<ICountyDescriptorDto[]> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getCounties(params)),
      tap(counties => {
        if (counties === undefined) {
          this.store.dispatch(InstitutionActions.InstitutionLoadCountiesAction(params));
        }
      }),
      filter(counties => counties != null)
    );
  }

  public loadInstitutionCountiesEffect({ institutionId }: { institutionId: string }): Observable<ICountyDescriptorDto[]> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/counties`) as Observable<ICountyDescriptorDto[]>;
  }

  public getAllCounties(params: { institutionId: string }) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getAllCounties(params)),
      tap(counties => {
        if (counties === undefined) {
          this.store.dispatch(InstitutionActions.InstitutionLoadAllCountiesAction(params));
        }
      }),
      filter(counties => counties != null)
    );
  }

  public loadAllCountiesEffect({ institutionId }: { institutionId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/all-counties`) as Observable<ICountyDescriptorDto[]>;
  }
}
