<ng-container *ng4hPlaceholder="(ready$ | async) === true; size: [100, 400]">
  <form>
    <div class="form-group">
      <label for="regmax-vantiv-cardholdername">Cardholder Name</label>
      <input class="form-control" type="text" id="regmax-vantiv-cardholdername" required autocomplete="cc-name">
    </div>
    <div class="form-group">
      <label>Card Number</label>
      <input type="text" autocomplete="cc-number" class="regmax-vantiv-cardnumber form-control" required>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-7">
        <div class="row">
          <div class="col-7">
            <div class="form-group">
              <label class="text-right middle">Expiration Month</label>
              <select id="regmax-vantiv-cardexpirationmonth" class="form-control" required autocomplete="cc-exp-month">
                <option value="1">01 - January</option>
                <option value="2">02 - February</option>
                <option value="3">03 - March</option>
                <option value="4">04 - April</option>
                <option value="5">05 - May</option>
                <option value="6">06 - June</option>
                <option value="7">07 - July</option>
                <option value="8">08 - August</option>
                <option value="9">09 - September</option>
                <option value="10">10 - October</option>
                <option value="11">11 - November</option>
                <option value="12">12 - December</option>
              </select>
            </div>
          </div>
          <div class="col-5">
            <div class="form-group">
              <label class="text-right middle">Year</label>
              <select class="form-control" id="regmax-vantiv-cardexpirationyear" required autocomplete="cc-exp-year">
                <option *ngFor="let year of cardExpirationYear" [value]="year">{{year}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-5">
        <div class="form-group">
          <label>Validation (CVV)</label>
          <input type="text" class="regmax-vantiv-cvv form-control" required>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="message.length > 0">
      <div class="col-12">
        <b style="color:red">{{message}}</b>
      </div>
    </div>
    <button class="btn btn-primary" [disabled]="buttonDisabled" (click)="tokenizeCardAccount($event)">
      {{buttonText}}
    </button>
  </form>
</ng-container>