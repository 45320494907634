export enum eFilterOperator {
    GreaterThan = 'GreaterThan',
    GreaterThanOrEqual = 'GreaterThanOrEqual',
    LessThan = 'LessThan',
    LessThanOrEqual = 'LessThanOrEqual',
    Equal = 'Equal',
    EqualAvailableOptions = 'EqualAvailableOptions',
    NotEqual = 'NotEqual',
    NotEqualAvailableOptions = 'NotEqualAvailableOptions',
    Between = 'Between',
    Like = 'Like',
    IsNull = 'IsNull',
    NotNull = 'NotNull',
    IsTrue = 'IsTrue',
    NotTrue = 'NotTrue',
}
