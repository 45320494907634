import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { eProfileType } from 'app/core/models';
import { ProfileService, RouterService } from 'app/core/services';
import { map, Observable, take, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MemberGuard implements CanActivate {
  constructor(private routerService: RouterService, private profileService: ProfileService) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.profileService.actingAsInstitutionProfile.pipe(
      take(1),
      map(profile => profile && profile.profileType === eProfileType.Member),
      tap(isMember => {
        if (!isMember) {
          console.error('Non member tried to access member area');
        }
      })
    );
  }
}
