import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
    eFormControlType,
    eLocalizationType,
    eStandardFieldStatus,
    IStandardFormConfig,
    IStandardFormFieldConfigDto,
} from 'app/core/models';
import { mergeImmutable } from 'app/shared/utils';

@Component({
    selector: 'ng4h-standard-form-field-config',
    templateUrl: './standard-form-field-config.component.html',
    styleUrls: ['./standard-form-field-config.component.scss']
})
export class StandardFormFieldConfigComponent implements OnInit, OnChanges {

    @Input() standardFormConfig: IStandardFormConfig;
    @Output() localizationTextChanged: EventEmitter<{ localizationUpdate: { [inputName: string]: string }, newConfig: IStandardFormConfig }> = new EventEmitter();
    @Input() form: FormGroup;
    @Input() localizationTypes: eLocalizationType[];
    @Input() localizationType: eLocalizationType;
    @Output() localizationChanged: EventEmitter<eLocalizationType> = new EventEmitter();

    @Output() editSection: EventEmitter<any>;
    @Output() save: EventEmitter<null> = new EventEmitter();

    public eLocalizationType = eLocalizationType;
    public eStandardFieldStatus = eStandardFieldStatus;

    public selectedSection = null;
    public selectedInputIndex = -1;
    public selectedInputName = null;
    constructor() {
        this.editSection = new EventEmitter();
    }

    private localizationUpdate = {};

    ngOnInit() {
    }
    ngOnChanges(simpleChanges: SimpleChanges) {
        if (simpleChanges.standardFormConfig) {
            this.standardFormConfig = simpleChanges.standardFormConfig.currentValue;
            this.localizationUpdate = {};
        } else if (simpleChanges.localizationType) {
            this.localizationType = simpleChanges.localizationType.currentValue;
        }
    }

    public aliasChange(inputName, inputText) {

        const existingArr = this.standardFormConfig.fields[this.localizationType][this.selectedSection];

        const newArr = [
            ...existingArr.slice(0, this.selectedInputIndex),
            {
                ...this.standardFormConfig.fields[this.localizationType][this.selectedSection][this.selectedInputIndex],
                inputText
            },
            ...existingArr.slice(this.selectedInputIndex + 1)
        ];

        const newConfig = mergeImmutable(
            { fields: { [this.localizationType]: { [this.selectedSection]: newArr } } },
            this.standardFormConfig
        );

        this.localizationUpdate[inputName] = inputText;

        this.localizationTextChanged.emit({ localizationUpdate: this.localizationUpdate, newConfig });
    }

    public retainOrder() {
        return 0;
    }

    public selectPanel(sectionKey: string, inputIndex: number, inputName: string) {
        this.selectedSection = sectionKey;
        this.selectedInputIndex = inputIndex;
        this.selectedInputName = inputName;
    }

    // Here we go backwards until we hit a visible input
    public shouldBreakBefore(section: string, inputIndex: number) {
        if (this.standardFormConfig == null) {
            return false;
        }
        const inputs = this.standardFormConfig.fields[this.localizationType][section];
        for (let i = inputIndex - 1; i >= 0; i--) {
            if (inputs[i].inputState === eStandardFieldStatus.Visible) {
                return inputs[i].inputType !== eFormControlType.Checkbox;
            }
        }
        return false;
    }
    // Here we go forwards until we hit a visible input
    public shouldBreakAfter(section: string, inputIndex: number) {
        if (this.standardFormConfig == null) {
            return false;
        }
        const inputs = this.standardFormConfig.fields[this.localizationType][section];
        for (let i = inputIndex + 1; i < inputs.length; i++) {
            if (inputs[i].inputState === eStandardFieldStatus.Visible) {
                return inputs[i].inputType !== eFormControlType.Checkbox;
            }
        }
        return false;
    }

    public anyVisible(array: IStandardFormFieldConfigDto[]) {
        return array.some(item => item.inputState !== eStandardFieldStatus.Hidden);
    }
    editClicked(sectionKey: string) {
        this.editSection.emit(sectionKey);
    }

    public fieldsTrackBy(index, item) {
        return item.key;
    }

    public inputTrackBy(index, item) {
        return item.inputName;
    }
}
