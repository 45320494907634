import { eFamilyMemberStatus } from '../enums/eFamilyMemberStatus';

export interface IFamilyMemberDto {
    familyMemberId: string;
    primaryFamily: boolean;
    familyMemberStatus: eFamilyMemberStatus;
    memberId: string;
    birthDate: string;
    firstName?: string;
    lastName?: string;
}
