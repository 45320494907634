import { IMemberProgramConsentDto } from '.';
import { IMemberVolunteerDto } from '..';

export interface IEnrollmentProjectDto {
  unitId: string;
  unitName?: string;
  projectId: string;
  projectName?: string;
  yearsInProject: number;
  volunteerType?: IMemberVolunteerDto;
  consents?: IMemberProgramConsentDto[];
  parentProjectName?: string;
}
