import { eEthnicity, eGender, eResidence } from '..';

export interface IDemographicsDto {
    birthGender: eGender;
    identifyingGender?: eGender;
    ethnicity: eEthnicity;
    residence: eResidence;
    racialBreakdownWhite?: boolean;
    racialBreakdownBlack?: boolean;
    racialBreakdownAmericanIndianOrAlaskanNative?: boolean;
    racialBreakdownNativeHawaiianOrPacificIslander?: boolean;
    racialBreakdownAsian?: boolean;
    racialBreakdownBalanceOfOtherCombinations?: boolean;
    racialBreakdownUndetermined?: boolean;
}
