import { Action, createReducer, on } from '@ngrx/store';
import { MemberActions } from 'app/core/containers/member';
import { IMemberSearchResultsDto } from 'app/core/models/serverDTOs/IMemberSearchResultsDto';

import { AnimalsSearchActions } from '.';

export interface AnimalSearchState {

    animalSearchSearching: boolean;
    animalsSearchResult: IMemberSearchResultsDto;
}

const initialState: AnimalSearchState = {
    animalSearchSearching: false,
    animalsSearchResult: undefined
};

const reducer = createReducer(
    initialState,
    on(AnimalsSearchActions.GetAnimalSearchResultsAction, (state, payload) => {
        return {
            ...state,
            animalSearchSearching: true,
        };
    }),
    on(AnimalsSearchActions.GetAnimalSearchResultsSuccessAction, (state, payload) => {
        return {
            ...state,
            animalSearchSearching: false,
            animalsSearchResult: payload.animalsSearchResult
        };
    }),
    on(AnimalsSearchActions.GetAnimalSearchResultsErrorAction, (state, payload) => {
        return {
            ...state,
            animalSearchSearching: false,
        };
    }),

    on(AnimalsSearchActions.SearchAnimalsAction, (state, payload) => {
        return {
            ...state,
            animalSearchSearching: true,
        };
    }),
    on(AnimalsSearchActions.SearchAnimalsSuccessAction, (state, payload) => {
        return {
            ...state,
            animalSearchSearching: false,
            animalsSearchResult: payload.animalsSearchResult
        };
    }),
    on(AnimalsSearchActions.SearchAnimalsErrorAction, (state, payload) => {
        return {
            ...state,
            animalSearchSearching: false,
        };
    }),

    on(AnimalsSearchActions.ClearAnimalSearchAction, (state, payload) => {
        return {
            ...state,
            animalSearchSearching: true,
        };
    }),
    on(AnimalsSearchActions.ClearAnimalSearchErrorAction, (state, payload) => {
        return {
            ...state,
            animalSearchSearching: false,
        };
    }),
    on(MemberActions.MemberFlagAction, MemberActions.MemberFlagDeleteErrorAction, (state, { institutionId, managerId, programId, hierarchyNodeId, memberId }) => {
        if (state.animalsSearchResult?.results == null) {
            return state;
        }
        const updatedResults = state.animalsSearchResult.results.map(result => {
            if (result.memberId === memberId) {
                return {
                    ...result,
                    flagged: true
                };
            }
            return result;
        });
        return {
            ...state,
            animalsSearchResult: {
                ...state.animalsSearchResult,
                results: updatedResults
            }
        };
    }),
    on(MemberActions.MemberFlagErrorAction, MemberActions.MemberFlagDeleteAction, (state, { institutionId, managerId, programId, hierarchyNodeId, memberId }) => {
        if (state.animalsSearchResult?.results == null) {
            return state;
        }
        const updatedResults = state.animalsSearchResult.results.map(result => {
            if (result.memberId === memberId) {
                return {
                    ...result,
                    flagged: false
                };
            }
            return result;
        });
        return {
            ...state,
            animalsSearchResult: {
                ...state.animalsSearchResult,
                results: updatedResults
            }
        };
    }),

);

export function animalSearchReducer(state: AnimalSearchState | undefined, action: Action) {
    return reducer(state, action);
}

export const animalsSearchResult = (state: AnimalSearchState) => state.animalsSearchResult;
export const animalSearchSearching = (state: AnimalSearchState) => state.animalSearchSearching;