import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  IFamilyCreateDto,
  IFamilyDto,
  IFamilyUserAccountCreateDto,
  IInstitutionManagerProgramParams,
  ILoginCredentials,
  IRecoverAccount,
  IUserAccountCreationResponseDto,
  IUserAccountDto,
} from 'app/core/models';
import { UserAccountSetPasswordDto } from 'app/core/models/serverDTOs/UserAccountSetPasswordDto';


export const SignUpAction = createAction(
  '[user] Sign up',
  props<{ familyAccount: IFamilyUserAccountCreateDto }>()
);
export const SignUpErrorAction = createAction(
  '[user] Sign up error',
  props<{ error: HttpErrorResponse }>()
);
export const SignUpSuccessAction = createAction(
  '[user] Sign up success',
  props<{ user: IUserAccountCreationResponseDto }>()
);

export const AuthenticateAction = createAction(
  '[user] Authenticate',
  props<{ credentials: { email: string, password: string } | IRecoverAccount }>()
);
export const AuthenticationSuccessAction = createAction(
  '[user] Authentication success',
  props<{ credentials: ILoginCredentials }>()
);
export const AuthenticationErrorAction = createAction(
  '[user] Authentication error',
  props<{ error: HttpErrorResponse | Error, credentials: ILoginCredentials }>()
);

export const MagicLinkAuthenticateAction = createAction(
  '[user] MagicLink Authenticate',
  props<{ securityStamp: string, code: string }>()
);
export const MagicLinkAuthenticationSuccessAction = createAction(
  '[user] MagicLink Authentication success',
  props<{ credentials: ILoginCredentials }>()
);
export const MagicLinkAuthenticationErrorAction = createAction(
  '[user] MagicLink Authentication error',
  props<{ error: HttpErrorResponse | Error, credentials: ILoginCredentials }>()
);

export const AuthenticationDoTwoFactorAction = createAction(
  '[user] Authentication do two factor',
  props<{ credentials: ILoginCredentials }>()
);
export const AuthenticationDoTwoFactorSuccessAction = createAction(
  '[user] Authentication do two factor success',
  props<{ credentials: ILoginCredentials }>()
);
export const AuthenticationDoTwoFactorErrorAction = createAction(
  '[user] Authentication do two factor error'
);

export const GetUserAction = createAction(
  '[user] Get User',
  props<{ userAccountToken: string }>()
);
export const GetUserSuccessAction = createAction(
  '[user] Get user success',
  props<{ user: IUserAccountDto, userAccountToken: string }>()
);
export const GetUserErrorAction = createAction(
  '[user] Get user error',
  props<{ error: HttpErrorResponse }>()
);

export const SignOutAction = createAction(
  '[user] Sign out',
  props<{ userAccountId: string }>()
);
export const SignOutErrorAction = createAction(
  '[user] Sign out error'
);
export const SignOutSuccessAction = createAction(
  '[user] Sign out success'
);

export const RecoverAccountAction = createAction(
  '[user] Recover account',
  props<{ code: string, securityStamp: string }>()
);
export const RecoverAccountSuccessAction = createAction(
  '[user] Recover account success',
  props<{ recoverAccount: IRecoverAccount }>()
);
export const RecoverAccountErrorAction = createAction(
  '[user] Recover account error',
  props<{ error: HttpErrorResponse }>()
);

export const ResetPasswordAction = createAction(
  '[user] Reset password',
  props<{ email: string }>()
);
export const ResetPasswordSuccessAction = createAction(
  '[user] Reset password success'
);
export const ResetPasswordErrorAction = createAction(
  '[user] Reset password error',
  props<{ error: HttpErrorResponse }>()
);

export const ChangePasswordAction = createAction(
  '[user] Change Password',
  props<{ passwordChange: UserAccountSetPasswordDto }>()
);
export const ChangePasswordSuccessAction = createAction(
  '[user] Change Password Success'
);
export const ChangePasswordErrorAction = createAction(
  '[user] Change Password Error',
  props<{ error: HttpErrorResponse }>()
);



export const FamilyCreateAction = createAction(
  '[family] Create',
  props<IInstitutionManagerProgramParams & { familyCreateDto: IFamilyCreateDto }>()
);
export const FamilyCreateSuccessAction = createAction(
  '[family] Create success',
  props<IInstitutionManagerProgramParams & { familyCreateDto: IFamilyCreateDto, family: IFamilyDto }>()
);
export const FamilyCreateErrorAction = createAction(
  '[family] Create error',
  props<{ error: HttpErrorResponse }>()
);


export const CreateNewFamilyForInstitutionAction = createAction(
  '[user] Create family for institution',
  props<{ institutionId: string, familyCreate: IFamilyCreateDto }>()
);
export const CreateNewFamilyForInstitutionSuccessAction = createAction(
  '[user] Create family for institution success',
  props<{ institutionId: string, familyCreate: IFamilyCreateDto }>()
);
export const CreateNewFamilyForInstitutionErrorAction = createAction(
  '[user] Create family for institution error',
  props<{ error: HttpErrorResponse }>()
);


export const EmailOptInAction = createAction(
  '[user] Email Opt in',
  props<{ institutionId: string, institutionProfileId: string }>()
);
export const EmailOptInSuccessAction = createAction(
  '[user] Email Opt in success',
  props<{ institutionId: string, institutionProfileId: string }>()
);
export const EmailOptInErrorAction = createAction(
  '[user] Email Opt in error',
  props<{ error: HttpErrorResponse }>()
);

export const EmailOptOutAction = createAction(
  '[user] Email Opt out',
  props<{ institutionId: string, institutionProfileId: string }>()
);
export const EmailOptOutSuccessAction = createAction(
  '[user] Email Opt out success',
  props<{ institutionId: string, institutionProfileId: string }>()
);
export const EmailOptOutErrorAction = createAction(
  '[user] Email Opt out error',
  props<{ error: HttpErrorResponse }>()
);
