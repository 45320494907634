import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AnimalsService } from 'app/core/services/member/animals/animals.service';
import { stringKeys } from 'app/shared/rxjs.utils';
import { catchError, exhaustMap, groupBy, map, mergeMap, of } from 'rxjs';

import { MemberAnimalsActions } from '.';

@Injectable()
export class MemberAnimalsEffects {

    getAnimalTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MemberAnimalsActions.LoadAnimalTypesAction),
            groupBy(stringKeys),
            mergeMap(groupedBy => {
                return groupedBy.pipe(
                    exhaustMap(payload => {
                        return this.animalsService.getAnimalTypesEffect(payload).pipe(
                            map(animalTypes => MemberAnimalsActions.LoadAnimalTypesSuccessAction({ ...payload, animalTypes })),
                            catchError(error => of(MemberAnimalsActions.LoadAnimalTypesErrorAction({ error })))
                        );
                    })
                );
            })
        ));

    getAnimalFiles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MemberAnimalsActions.LoadAnimalFilesAction),
            groupBy(stringKeys),
            mergeMap(groupedBy => {
                return groupedBy.pipe(
                    exhaustMap(payload => {
                        return this.animalsService.getEnrollmentAnimalCustomFilesEffect(payload).pipe(
                            map(animalFiles => MemberAnimalsActions.LoadAnimalFilesSuccessAction({ ...payload, animalFiles })),
                            catchError(error => of(MemberAnimalsActions.LoadAnimalFilesErrorAction({ error })))
                        );
                    })
                );
            })
        ));

    getAnimalForm$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MemberAnimalsActions.LoadAnimalFormAction),
            groupBy(stringKeys),
            mergeMap(groupedBy => {
                return groupedBy.pipe(
                    exhaustMap(payload => {
                        return this.animalsService.getFormEffect(payload).pipe(
                            map(animalForm => MemberAnimalsActions.LoadFormSuccessAction({ ...payload, animalForm })),
                            catchError(error => of(MemberAnimalsActions.LoadFormErrorAction({ error })))
                        );
                    })
                );
            })
        ));

    createAnimal$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MemberAnimalsActions.CreateAnimalAction),
            groupBy(stringKeys),
            mergeMap(groupedBy => {
                return groupedBy.pipe(
                    exhaustMap(payload => {
                        return this.animalsService.createAnimalEffect(payload).pipe(
                            map((animal) => MemberAnimalsActions.CreateAnimalSuccessAction({ ...payload, animal })),
                            catchError(error => of(MemberAnimalsActions.CreateAnimalErrorAction({ error })))
                        );
                    })
                );
            })
        ));
    addAnimalToEnrollment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MemberAnimalsActions.AddAnimalToEnrollmentAction),
            groupBy(stringKeys),
            mergeMap(groupedBy => {
                return groupedBy.pipe(
                    exhaustMap(payload => {
                        return this.animalsService.addExistingAnimalToEnrollmentEffect(payload).pipe(
                            map((animal) => MemberAnimalsActions.AddAnimalToEnrollmentSuccessAction({ ...payload, animal })),
                            catchError(error => of(MemberAnimalsActions.AddAnimalToEnrollmentErrorAction({ error })))
                        );
                    })
                );
            })
        ));


    loadEnrollmentAnimals$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MemberAnimalsActions.LoadAEnrollmentAnimalsAction),
            groupBy(stringKeys),
            mergeMap(groupedBy => {
                return groupedBy.pipe(
                    exhaustMap(payload => {
                        return this.animalsService.getSelectedAnimalsForEnrollmentEffect(payload).pipe(
                            map((animals) => animals == null ? [] : animals),
                            map((animals) => MemberAnimalsActions.LoadAEnrollmentAnimalsSuccessAction({ ...payload, animals })),
                            catchError(error => of(MemberAnimalsActions.LoadAEnrollmentAnimalsErrorAction({ error })))
                        );
                    })
                );
            })
        ));

    loadEnrollmentAnimal$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MemberAnimalsActions.LoadAEnrollmentAnimalAction),
            groupBy(stringKeys),
            mergeMap(groupedBy => {
                return groupedBy.pipe(
                    exhaustMap(payload => {
                        return this.animalsService.getEnrollmentAnimalEffect(payload).pipe(
                            map((animal) => MemberAnimalsActions.LoadAEnrollmentAnimalSuccessAction({ ...payload, animal })),
                            catchError(error => of(MemberAnimalsActions.LoadAEnrollmentAnimalErrorAction({ error })))
                        );
                    })
                );
            })
        ));

    loadAvailableFamilyAnimals$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MemberAnimalsActions.LoadAvailableAnimalsAction),
            groupBy(stringKeys),
            mergeMap(groupedBy => {
                return groupedBy.pipe(
                    exhaustMap(payload => {
                        return this.animalsService.getAvailableAnimalsEffect(payload).pipe(
                            map((animals) => MemberAnimalsActions.LoadAvailableAnimalsSuccessAction({ ...payload, animals })),
                            catchError(error => of(MemberAnimalsActions.LoadAvailableAnimalsErrorAction({ error })))
                        );
                    })
                );
            })
        ));

    submitAnimal$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MemberAnimalsActions.SubmitAnimalAction),
            groupBy(stringKeys),
            mergeMap(groupedBy => {
                return groupedBy.pipe(
                    exhaustMap(payload => {
                        return this.animalsService.submitAnimalEffect(payload).pipe(
                            map(() => MemberAnimalsActions.SubmitAnimalSuccessAction(payload)),
                            catchError(error => of(MemberAnimalsActions.SubmitAnimalErrorAction({ error })))
                        );
                    })
                );
            })
        ));

    updateAnimalForm$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MemberAnimalsActions.UpdateEnrollmentAnimalFormAction),
            groupBy(stringKeys),
            mergeMap(groupedBy => {
                return groupedBy.pipe(
                    exhaustMap(payload => {
                        return this.animalsService.updateEnrollmentAnimalFormEffect(payload).pipe(
                            map(() => MemberAnimalsActions.UpdateEnrollmentAnimalFormSuccessAction(payload)),
                            catchError(error => of(MemberAnimalsActions.UpdateEnrollmentAnimalFormErrorAction({ error })))
                        );
                    })
                );
            })
        ));

    saveCustomFile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MemberAnimalsActions.SaveEnrollmentAnimalCustomFileAction),
            groupBy(stringKeys),
            mergeMap(groupedBy => {
                return groupedBy.pipe(
                    exhaustMap(payload => {
                        return this.animalsService.saveEnrollmentAnimalCustomFileEffect(payload).pipe(
                            map(() => MemberAnimalsActions.SaveEnrollmentAnimalCustomFileSuccessAction(payload)),
                            catchError(error => of(MemberAnimalsActions.SaveEnrollmentAnimalCustomFileErrorAction({ error })))
                        );
                    })
                );
            })
        ));

    deleteCustomFile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MemberAnimalsActions.DeleteEnrollmentAnimalCustomFileAction),
            groupBy(stringKeys),
            mergeMap(groupedBy => {
                return groupedBy.pipe(
                    exhaustMap(payload => {
                        return this.animalsService.deleteEnrollmentCustomFileEffect(payload).pipe(
                            map(() => MemberAnimalsActions.DeleteEnrollmentAnimalCustomFileSuccessAction(payload)),
                            catchError(error => of(MemberAnimalsActions.DeleteEnrollmentAnimalCustomFileErrorAction({ error })))
                        );
                    })
                );
            })
        ));

    deleteEnrollmentAnimal$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MemberAnimalsActions.DeleteEnrollmentAnimalAction),
            groupBy(stringKeys),
            mergeMap(groupedBy => {
                return groupedBy.pipe(
                    exhaustMap(payload => {
                        return this.animalsService.deleteEnrollmentAnimalEffect(payload).pipe(
                            map(() => MemberAnimalsActions.DeleteEnrollmentAnimalSuccessAction(payload)),
                            catchError(error => of(MemberAnimalsActions.DeleteEnrollmentAnimalErrorAction({ error })))
                        );
                    })
                );
            })
        ));

    applyPayment$ = createEffect(() => this.actions$.pipe(
        ofType(MemberAnimalsActions.EnrollmentAnimalApplyPaymentAction),
        groupBy(stringKeys),
        mergeMap(groupedBy => {
            return groupedBy.pipe(
                exhaustMap(payload => {
                    return this.animalsService.applyPaymentEffect(payload).pipe(
                        map(() => MemberAnimalsActions.EnrollmentAnimalApplyPaymentSuccessAction(payload)),
                        catchError(error => of(MemberAnimalsActions.EnrollmentAnimalApplyPaymentErrorAction({ error })))
                    );
                })
            );
        })
    ));

    refreshAnimal$ = createEffect(() => this.actions$.pipe(
        ofType(MemberAnimalsActions.EnrollmentAnimalApplyPaymentSuccessAction),
        mergeMap(payload => {
            return [
                MemberAnimalsActions.EnrollmentAnimalInvalidateCacheAction(),
            ];
        })
    ));

    public applyAdditionalPayment$ = createEffect(() => this.actions$.pipe(
        ofType(MemberAnimalsActions.EnrollmentAnimalApplyAdditionalPaymentAction),
        groupBy(stringKeys),
        mergeMap(groupedBy => {
            return groupedBy.pipe(
                exhaustMap((payload) => {
                    return this.animalsService.applyAdditionalPaymentEffect(payload).pipe(
                        map(() => MemberAnimalsActions.EnrollmentAnimalApplyAdditionalPaymentSuccessAction(payload)),
                        catchError(error => of(MemberAnimalsActions.EnrollmentAnimalApplyAdditionalPaymentErrorAction({ error })))
                    );
                })
            );
        })
    ));

    constructor(private actions$: Actions, private animalsService: AnimalsService) { }
}
