import { Action, createReducer, on } from '@ngrx/store';
import { ICountyDescriptorDto, IInstitution } from 'app/core/models';

import { InstitutionActions } from '.';
import { mergeImmutable } from '../utils';

export interface InstitutionState {
  institutions: IInstitution[];
  counties: { [institutionId: string]: ICountyDescriptorDto[] };
  allCounties: { [institutionId: string]: ICountyDescriptorDto[] };
}
const initialState: InstitutionState = {
  institutions: undefined,
  counties: undefined,
  allCounties: undefined
};

const reducer = createReducer(
  initialState,
  on(InstitutionActions.InstitutionsLoadSuccessAction, (state, { institutions }) => {
    return {
      ...state,
      institutions
    };
  }),
  on(InstitutionActions.InstitutionLoadCountiesSuccessAction, (state, { institutionId, counties }) => {
    return {
      ...state,
      counties: mergeImmutable(
        { [institutionId]: counties },
        state.counties
      )
    };
  }),
  on(InstitutionActions.InstitutionLoadAllCountiesSuccessAction, (state, { institutionId, allCounties }) => {
    return {
      ...state,
      allCounties: mergeImmutable(
        { [institutionId]: allCounties },
        state.allCounties
      )
    };
  }),
);

export function institutionReducer(state: InstitutionState | undefined, actions: Action) {
  return reducer(state, actions);
}

export const institutions = (state: InstitutionState) => state.institutions;
export const counties = (state: InstitutionState) => state.counties;
export const allCounties = (state: InstitutionState) => state.allCounties;
