import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { getMemberActivities, MemberFeatureStore } from 'app/core/containers/member/member-feature.reducers';
import { MemberActivitiesActions } from 'app/core/containers/member/store/activities';
import { IActivityDto, IInstitutionMemberProgramEnrollmentParams } from 'app/core/models';
import { IEnrollmentActivitySubmissionDto } from 'app/core/models/serverDTOs/IEnrollmentActivitySubmissionDto';
import { filter, map, Observable, of, take, tap } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MemberActivitiesService {

  constructor(private httpClient: HttpClient, private store: Store<MemberFeatureStore>, private dispatcher: ActionsSubject) {
  }

  public getEligibleActivitiesForEnrollment(params: IInstitutionMemberProgramEnrollmentParams) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getMemberActivities(params)),
      tap(activities => {
        if (activities === undefined) {
          this.store.dispatch(MemberActivitiesActions.LoadActivitiesForEnrollmentAction(params));
        }
      }),
      filter(activities => activities != null)
    );
  }

  public getEligibleActivitiesForEnrollmentEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId
  }: IInstitutionMemberProgramEnrollmentParams) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/activities`) as Observable<IActivityDto[]>;
  }

  public saveActivitiesEnrollment(params: IInstitutionMemberProgramEnrollmentParams & { enrollmentActivitiesSubmission: IEnrollmentActivitySubmissionDto }) {

    this.store.dispatch(MemberActivitiesActions.SaveActivitiesForEnrollmentAction(params));
    return this.dispatcher.pipe(
      ofType(MemberActivitiesActions.SaveActivitiesForEnrollmentSuccessAction, MemberActivitiesActions.SaveActivitiesForEnrollmentErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberActivitiesActions.SaveActivitiesForEnrollmentSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public saveActivitiesEnrollmentEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    enrollmentActivitiesSubmission
  }: IInstitutionMemberProgramEnrollmentParams & { enrollmentActivitiesSubmission: IEnrollmentActivitySubmissionDto }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/activities`, enrollmentActivitiesSubmission);
  }

  public deleteActivity(params: IInstitutionMemberProgramEnrollmentParams & { activityId: string }) {
    this.store.dispatch(MemberActivitiesActions.DeleteActivityForEnrollmentAction(params));
    return this.dispatcher.pipe(
      ofType(MemberActivitiesActions.DeleteActivityForEnrollmentSuccessAction, MemberActivitiesActions.DeleteActivityForEnrollmentErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberActivitiesActions.DeleteActivityForEnrollmentSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteActivityEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    activityId
  }: IInstitutionMemberProgramEnrollmentParams & { activityId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/activities/${activityId}`);
  }
}
