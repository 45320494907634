import { eLocalizationType } from '..';

export interface IConsentCreateDto {
    acceptanceRequired: boolean;
    electronicSignatureRequired: boolean;
    consentName: string;
    consentText: string;
    consentTitle: string;
    agreeOptionText: string;
    disagreeOptionText: string;
    dateEffective: Date;
    localization: eLocalizationType;
}
