import { Action, createReducer, on } from '@ngrx/store';
import { IAwardDto, IInstitutionMemberProgramEnrollmentModel } from 'app/core/models';
import { mergeImmutable } from 'app/shared/utils';

import { MemberAwardsActions } from '.';

export interface MemberAwardsState {
    awards: IInstitutionMemberProgramEnrollmentModel<IAwardDto[]>;
}

const initialState: MemberAwardsState = {
    awards: undefined
};

const reducer = createReducer(
    initialState,
    on(MemberAwardsActions.LoadAwardsForEnrollmentSuccessAction, (state, { institutionId, memberId, programId, enrollmentId, awards }) => {
        const newAwards = mergeImmutable(
            { [institutionId]: { [memberId]: { [programId]: { [enrollmentId]: awards } } } },
            state.awards
        );
        return {
            ...state,
            awards: newAwards
        };
    })
);

export function awardsReducer(state: MemberAwardsState | undefined, actions: Action) {
    return reducer(state, actions);
}

export const awards = (state: MemberAwardsState) => state.awards;
