import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IStandardFormColumnDto } from 'app/core/models';
import { MemberHealthFormService } from 'app/core/services';

@Component({
  selector: 'ng4h-modal-edit-medication',
  templateUrl: './modal-edit-medication.component.html',
  styleUrls: ['./modal-edit-medication.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalEditMedicationComponent implements OnInit {

  public form: FormGroup;
  public medicationFields: { [medicationId: string]: IStandardFormColumnDto[] };

  get medicationForm() {
    return this.form.get('Medication') as FormGroup;
  }

  constructor(private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) private data: { medicationFields: IStandardFormColumnDto[], medication: IStandardFormColumnDto & { userResponse: string } },
    private healthFormService: MemberHealthFormService) { }

  public ngOnInit() {
    const medication = this.data.medication;
    this.medicationFields = { Medication: this.data.medicationFields };

    this.form = this.healthFormService.toFormGroup(this.medicationFields);

    Object.keys(this.medicationForm.controls).forEach(key => {
      this.medicationForm.controls[key].setValue(medication[key]);
    });

    this.form.disable();
  }

  public close() {
    this.dialogRef.close();
  }

  public delete() {
    this.dialogRef.close({ delete: true });
  }

  public edit(): void {
    this.form.enable();
  }

  public cancel(): void {
    this.form.disable();
  }

  public save() {
    this.dialogRef.close({ delete: false, med: this.form.value.Medication });
  }
}
