import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'ng4h-sidebar-accordion-header',
  templateUrl: './sidebar-accordion-header.component.html',
  styleUrls: ['./sidebar-accordion-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarAccordionHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
