import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { IFamilyInstitutionProfile, IGatewayConfigurationDto, ITouchNetAddCardResponseDto } from 'app/core/models';
import { CreditCardService, ModalService, ProfileService, RouterService } from 'app/core/services';
import { switchMap, take } from 'rxjs';

@Component({
  selector: 'ng4h-touchnet-credit-card-input-component',
  templateUrl: './touchnet-credit-card-input-component.component.html',
  styleUrls: ['./touchnet-credit-card-input-component.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TouchnetCreditCardInputComponentComponent implements OnInit {

  @ViewChild('touchnetForm', { static: true }) touchnetForm;

  public touchnetResponse: ITouchNetAddCardResponseDto;
  @Input() gatewayConfig: IGatewayConfigurationDto;
  public loading = false;

  constructor(
    private routerService: RouterService,
    private creditCardService: CreditCardService,
    private profileService: ProfileService,
    private cdr: ChangeDetectorRef,
    private modalService: ModalService
  ) { }

  ngOnInit(): void {

    this.profileService.loggedInAsInstitutionProfile.pipe(
      take(1),
      switchMap((family: IFamilyInstitutionProfile) => {
        return this.creditCardService.getTouchNetRedirectEffect({
          institutionId: this.routerService.institutionId,
          familyId: family.familyId,
          programId: this.routerService.programId,
          redirectRequest: {
            redirectUrl: window.location.href + '?wizardStep=Payment'
          }
        });
      }),
      take(1),
      this.modalService.showProgressSpinnerModalUntilComplete()
    ).subscribe((res: ITouchNetAddCardResponseDto) => {

      this.touchnetResponse = res;

      this.cdr.detectChanges();

      this.touchnetForm.nativeElement.submit();

    });
  }


}
