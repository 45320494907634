import { IMemberProgramConsentDto } from '.';
import { IStandardFormColumnDto } from './IStandardFormColumnDto';


export interface IMemberHealthFormDto {
    consent: IMemberProgramConsentDto;
    fields: { [filedId: string]: IStandardFormColumnDto[] };
    medicationFields: Array<any>;
    medications: { [medicationId: string]: (IStandardFormColumnDto & { userResponse: string })[] };
}
