import { Action, createReducer, on } from '@ngrx/store';
import {
    IFamilyDashboardSummaryDto,
    IFamilyDescriptorDto,
    IFamilyDto,
    IFamilyEmailDto,
    IFamilyEventDto,
    IInstitutionFamilyModel,
    IManagerNoteDto,
    IUserAccountLogin
} from 'app/core/models';
import { EventsManagerActions } from 'app/shared/events-manager';
import { mergeImmutable } from 'app/shared/utils';

import { FamilyActions } from '.';
import { EnrollmentActions } from '../member/enrollment';
import { UserActions } from '../user';

export interface FamilyState {
    families: IInstitutionFamilyModel<IFamilyDto>;

    familiesDashboard: IInstitutionFamilyModel<IFamilyDashboardSummaryDto>;
    familiesDescriptor: IInstitutionFamilyModel<IFamilyDescriptorDto>;
    eventsCatalog: IInstitutionFamilyModel<IFamilyEventDto[]>;

    familyLogins: IInstitutionFamilyModel<IUserAccountLogin[]>;
    familyEmails: IInstitutionFamilyModel<IFamilyEmailDto[]>;
    familyNotes: { [institutionId: string]: { [memberId: string]: { [program: string]: IManagerNoteDto[] } } };
}

const initialState: FamilyState = {
    families: undefined,
    familiesDashboard: undefined,
    familiesDescriptor: undefined,
    eventsCatalog: undefined,

    familyLogins: undefined,
    familyEmails: undefined,
    familyNotes: undefined
};

const reducer = createReducer(
    initialState,
    on(
        FamilyActions.FamilyInvalidateCacheAction,
        EventsManagerActions.EventsManagerApproveSuccessAction,
        EventsManagerActions.CancelRegistrationSuccessAction,
        EventsManagerActions.EventsManagerSendBackSuccessAction,
        EventsManagerActions.EventsManagerBlockSuccessAction,
        EnrollmentActions.EnrollmentInvalidateCacheAction,
        FamilyActions.FamilyUpdateCountySuccessAction,
        FamilyActions.FamilyUpdateSuccessAction,
        (state, {}) => {
            return initialState;
        }),
    on(FamilyActions.FamilyLoadSuccessAction, (state, { institutionId, familyId, family }) => {
        const newFamilies = mergeImmutable(
            { [institutionId]: { [familyId]: family } },
            state.families
        );
        return {
            ...state,
            families: newFamilies
        };
    }),
    on(UserActions.EmailOptInSuccessAction, UserActions.EmailOptOutSuccessAction, (state, {}) => {
        return {
            ...state,
            families: undefined
        };
    }),
    on(FamilyActions.FamilyLoadDashboardSuccessAction, (state, { institutionId, familyId, familyDashboard }) => {
        const newFamiliesDashboards = mergeImmutable(
            { [institutionId]: { [familyId]: familyDashboard } },
            state.familiesDashboard
        );
        return {
            ...state,
            familiesDashboard: newFamiliesDashboards
        };
    }),
    on(FamilyActions.FamilyLoadDescriptorSuccessAction, (state, { institutionId, familyId, familyDescriptor }) => {
        const newFamilyDescriptor = mergeImmutable(
            { [institutionId]: { [familyId]: familyDescriptor } },
            state.familiesDescriptor
        );
        return {
            ...state,
            familiesDescriptor: newFamilyDescriptor
        };
    }),

    on(FamilyActions.FamilyLoadEventCatalogSuccessAction, (state, { institutionId, familyId, events }) => {
        const newEventsCatalog = mergeImmutable(
            { [institutionId]: { [familyId]: events } },
            state.eventsCatalog
        );
        return {
            ...state,
            eventsCatalog: newEventsCatalog
        };
    }),
    on(FamilyActions.LoadFamilyLoginsSuccessAction, (state, { institutionId, familyId, logins }) => {
        const newFamilyLogins = mergeImmutable(
            { [institutionId]: { [familyId]: logins } },
            state.familyLogins
        );
        return {
            ...state,
            familyLogins: newFamilyLogins
        };
    }),
    on(FamilyActions.LoadFamilyEmailsSuccessAction, (state, { institutionId, familyId, emails }) => {
        const newFamilyEmails = mergeImmutable(
            { [institutionId]: { [familyId]: emails } },
            state.familyEmails
        );
        return {
            ...state,
            familyEmails: newFamilyEmails
        };
    }),
    on(FamilyActions.GetFamilyNotesSuccessAction, (state, { institutionId, familyId, programId, profileNotes }) => {
        const newNotes = mergeImmutable(
            { [institutionId]: { [familyId]: { [programId]: profileNotes } } },
            state.familyNotes
        );
        return {
            ...state,
            familyNotes: newNotes
        };
    }),
    on(FamilyActions.CreateFamilyNotesSuccessAction, FamilyActions.DeleteFamilyNotesSuccessAction, FamilyActions.UpdateFamilyNotesSuccessAction, FamilyActions.AddFamilyNotesFileSuccessAction, (state, {}) => {
        return {
            ...state,
            familyNotes: undefined
        };
    })
);

export function familyReducer(state: FamilyState | undefined, actions: Action) {
    return reducer(state, actions);
}

export const families = (state: FamilyState) => state && state.families;
export const familiesDashboard = (state: FamilyState) => state && state.familiesDashboard;
export const familiesDescriptor = (state: FamilyState) => state && state.familiesDescriptor;
export const familyEventCatalog = (state: FamilyState) => state && state.eventsCatalog;

export const familyLogins = (state: FamilyState) => state && state.familyLogins;
export const familyEmails = (state: FamilyState) => state && state.familyEmails;
export const familyNotes = (state: FamilyState) => state && state.familyNotes;