import { eEmailDeliveryStatus } from '..';
import { eEmailType } from '../enums/eEmailType';

export interface IFamilyEmailDto {
    emailId: string;
    emailType: eEmailType;
    emailAddress?: string;
    status: eEmailDeliveryStatus;
    statusMessage?: string;
    processedDate?: string;
}
