import { Action, createReducer, on } from '@ngrx/store';
import { IDocumentationController } from 'app/core/models';

import { DocumentationActions } from '.';


export interface DocumentationState {
    documentation: IDocumentationController[];
}
const initialState: DocumentationState = {
    documentation: undefined
};

const reducer = createReducer(
    initialState,
    on(DocumentationActions.DocumentationLoadSuccessAction, (state, { documentation }) => {
        return {
            ...state,
            documentation
        };
    })
);

export function documentationReducer(state: DocumentationState | undefined, actions: Action) {
    return reducer(state, actions);
}
export const documentation = (state: DocumentationState) => state.documentation;
