import { eDocumentationPropertyType } from '.';

export interface IDocumentationTypeDefinition {
    type: eDocumentationPropertyType | [eDocumentationPropertyType, 'null'];
    // format for the string (mostly id's)
    format?: string;
    enum?: string[];
    properties?: {
        [propertyName: string]: {
            $ref: string
        } | IDocumentationTypeDefinition
    };
    additionalProperties?: IDocumentationTypeDefinition;
    items?: IDocumentationTypeDefinition;
    required?: string[];
    definitions: {
        [definitionName: string]: IDocumentationTypeDefinition
    };
    description?: string;
    $ref?: any;
}
