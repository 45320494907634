import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { AppState, getPageMessages } from 'app/app.reducers';
import { PageMessagesActions } from 'app/shared/page-message';
import { filter, map, Observable, of, take, tap } from 'rxjs';

import {
  IInstitutionSystemManagerProgramParams,
  IProgramPageMessageDto,
  IProgramPageMessageUpdateDto
} from '../models';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PageMessageService {

  constructor(private store: Store<AppState>, private http: HttpClient, private dispatcher: ActionsSubject) {

  }

  public getPageMessage({
    institutionId,
    systemManagerId,
    programId,
    pageMessageId
  }: IInstitutionSystemManagerProgramParams & { pageMessageId: number }): Observable<IProgramPageMessageDto> {
    return this.getPageMessages({ institutionId, systemManagerId, programId }).pipe(
      filter(messages => messages != null),
      map(messages => {
        return messages.find(message => message.pageMessageId === pageMessageId);
      })
    );
  }

  public getPageMessages(params: IInstitutionSystemManagerProgramParams): Observable<IProgramPageMessageDto[]> {

    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getPageMessages(params)),
      tap(enrollment => {
        if (enrollment === undefined) {
          this.store.dispatch(PageMessagesActions.PageMessagesLoadAction(params));
        }
      })
    );
  }

  public loadEmailTemplateEffect({
    institutionId,
    systemManagerId,
    programId
  }: IInstitutionSystemManagerProgramParams): Observable<IProgramPageMessageDto[]> {
    return this.http.get(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/page-messages`) as Observable<IProgramPageMessageDto[]>;
  }

  public updatePageMessage(params: IInstitutionSystemManagerProgramParams & { typeId: string, message: IProgramPageMessageUpdateDto }) {
    this.store.dispatch(PageMessagesActions.PageMessageUpdateAction(params));

    return this.dispatcher.pipe(
      ofType(PageMessagesActions.PageMessageUpdateSuccessAction, PageMessagesActions.PageMessageUpdateErrorAction),
      take(1),
      map(action => {
        if (action.type === PageMessagesActions.PageMessageUpdateSuccessAction.type) {
          return action.message;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updatePageMessageEffect({
    institutionId,
    systemManagerId,
    programId,
    typeId,
    message
  }: IInstitutionSystemManagerProgramParams & { typeId: string, message: IProgramPageMessageUpdateDto }) {
    return this.http.patch(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/page-messages/${typeId}`, message);
  }
}
