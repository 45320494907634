import { IMemberSearchParametersDto, ISearchDateRangeDto } from '.';

export interface IPaymentSearchParametersDto extends IMemberSearchParametersDto {
    invoiceId: string;
    invoiceNumber?: number;
    paymentId: string;
    paymentNumber?: number;
    paymentMethodId: string;
    paymentTransactionId: string;
    paymentTransactionNumber?: number;
    invoiceStatus?: any[];
    paymentStatus?: any[];
    transactionDates: ISearchDateRangeDto;
    eventIds?: any[];
    searchId: string;
    programId: string;
    managerInstitutionProfileId: string;
    programYear: string;
    searchTerm?: string;
    flagged?: boolean;
    hierarchyNodeIds?: any[];
    memberRoles?: any[];
    enrollmentRole?: any[];
    enrollmentStatus?: any[];
    enrollmentDates: ISearchDateRangeDto;
    memberProgramStatus?: any[];
    memberProgramYearStatus?: any[];
}
