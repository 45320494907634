import {
  IEnrollmentDescriptorDto,
  IInvoiceDto,
  IProfileTrainingDescriptorDto,
  IProfileVolunteerScreeningDescriptorDto,
} from '.';
import { eEnrollmentStatus, eMemberParticipationStatus, eMemberProgramYearStatus } from '../enums';

export interface IMemberProgramYearApprovalStateDto {
  participationStatus: eMemberParticipationStatus;
  enrollmentStatus?: eEnrollmentStatus;
  programYearStatus?: eMemberProgramYearStatus;
  canApprove: boolean;
  enrollmentApproved: boolean;
  pendingEnrollment: IEnrollmentDescriptorDto;
  feeWaiverPending: boolean;
  invoicesPaid: boolean;
  paymentNotReceivedByInstitution?: boolean;
  payableInvoice: IInvoiceDto;
  screeningApproved: boolean;
  screeningPendingApproval: IProfileVolunteerScreeningDescriptorDto;
  trainingsCompleted: boolean;
  trainingsNotCompleted?: IProfileTrainingDescriptorDto[];
}
