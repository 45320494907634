import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DistrictService } from 'app/core/services';
import { catchError, exhaustMap, groupBy, map, mergeMap, of } from 'rxjs';

import { DistrictActions } from '.';
import { stringKeys } from '../rxjs.utils';

@Injectable()
export class DistrictEffects {

  loadDistricts = createEffect(() => this.actions
  .pipe(
    ofType(DistrictActions.DistrictLoadDistrictsAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.districtService.loadDistrictsEffect(payload.institutionId).pipe(
            map(districts => DistrictActions.DistrictLoadDistrictsSuccessAction({ ...payload, districts })),
            catchError(error => of(DistrictActions.DistrictLoadDistrictsErrorAction({ error })))
          );
        })
      );
    })
  ));

  constructor(private actions: Actions, private districtService: DistrictService) {
  }
}
