import { Action, createReducer, on } from '@ngrx/store';
import { IFamilyDto, IFileControl, IManagerNoteDto, IMemberDashboardSummaryDto, IMemberDto } from 'app/core/models';
import { EventsManagerActions } from 'app/shared/events-manager';
import { mergeImmutable } from 'app/shared/utils';

import { MemberActions } from '.';
import { FamilyActions } from '../family';
import { UserActions } from '../user';
import { EnrollmentActions } from './enrollment';
import { MemberActivitiesActions } from './store/activities';
import { MemberAwardsActions } from './store/awards';
import { MemberGroupsActions } from './store/groups';

export interface MemberState {
  member: { [institutionId: string]: { [memberId: string]: IMemberDto } };
  memberProgramYear: { [institutionId: string]: { [memberId: string]: string } };
  membersDashboard: { [institutionId: string]: { [memberId: string]: IMemberDashboardSummaryDto } };
  familiesByCounty: { [institutionId: string]: { [countyAreaId: string]: IFamilyDto[] } };
  profileNotes: { [institutionId: string]: { [memberId: string]: { [program: string]: IManagerNoteDto[] } } };
  v1Files: { [institutionId: string]: { [memberId: string]: { [program: string]: IFileControl[] } } };
}

const initialState: MemberState = {
  member: undefined,
  memberProgramYear: undefined,
  membersDashboard: undefined,
  familiesByCounty: undefined,
  profileNotes: undefined,
  v1Files: undefined
};

const reducer = createReducer(
  initialState,
  on(
    MemberGroupsActions.SaveGroupsForEnrollmentSuccessAction,
    MemberGroupsActions.DeleteGroupsForEnrollmentSuccessAction,
    MemberAwardsActions.SaveAwardsForEnrollmentSuccessAction,
    MemberAwardsActions.DeleteAwardsForEnrollmentSuccessAction,
    MemberActivitiesActions.SaveActivitiesForEnrollmentSuccessAction,
    MemberActivitiesActions.DeleteActivityForEnrollmentSuccessAction,
    MemberActions.MemberInvalidateMemberCache,
    EventsManagerActions.EventsManagerApproveSuccessAction,
    EventsManagerActions.CancelRegistrationSuccessAction,
    EventsManagerActions.EventsManagerSendBackSuccessAction,
    EventsManagerActions.EventsManagerBlockSuccessAction,
    EnrollmentActions.EnrollmentSubmitSuccessAction,
    EnrollmentActions.EnrollmentInvalidateCacheAction,
    MemberActions.TogglePrimaryFamilySuccessAction,
    MemberActions.UpdateYearsInProgramSuccessAction,
    MemberActions.MoveToFamilySuccessAction,
    MemberActions.RemoveMemberFromNonPrimaryFamilySuccessAction,
    FamilyActions.FamilyUpdateCountySuccessAction,
    MemberActions.UpdateMemberProgramVolunteerHoursSuccessAction,
    (state, { }) => {
      return initialState;
    }),
  on(MemberActions.MemberLoadSuccessAction, MemberActions.MemberCreateSuccessAction, (state, { institutionId, memberId, member }) => {
    const newMembers = mergeImmutable(
      { [institutionId]: { [memberId]: member } },
      state.member
    );
    return {
      ...state,
      member: newMembers
    };
  }),
  on(UserActions.EmailOptInSuccessAction, UserActions.EmailOptOutSuccessAction, (state, { }) => {
    return {
      ...state,
      member: undefined
    }
  }),
  on(MemberActions.MemberLoadDashboardSuccessAction, (state, { institutionId, memberId, memberDashboard }) => {
    const newMemberDashboards = mergeImmutable(
      { [institutionId]: { [memberId]: memberDashboard } },
      state.membersDashboard
    );
    return {
      ...state,
      membersDashboard: newMemberDashboards
    };
  }),
  on(MemberActions.MemberSetProgramYearAction, (state, { institutionId, memberId, programYearId }) => {
    const newMemberProgramYears = mergeImmutable(
      { [institutionId]: { [memberId]: programYearId } },
      state.memberProgramYear
    );
    return {
      ...state,
      memberProgramYear: newMemberProgramYears
    };
  }),
  on(MemberActions.GetFamiliesForCountySuccessAction, (state, { institutionId, countyAreaId, families }) => {
    const newFamilies = mergeImmutable(
      { [institutionId]: { [countyAreaId]: families } },
      state.familiesByCounty
    );
    return {
      ...state,
      familiesByCounty: newFamilies
    };
  }),
  on(MemberActions.GetProfileNotesSuccessAction, (state, { institutionId, memberId, programId, profileNotes }) => {
    const newNotes = mergeImmutable(
      { [institutionId]: { [memberId]: { [programId]: profileNotes } } },
      state.profileNotes
    );
    return {
      ...state,
      profileNotes: newNotes
    };
  }),
  on(MemberActions.MemberLoadV1FilesSuccessAction, (state, { institutionId, memberId, programId, files }) => {
    const newFiles = mergeImmutable(
      { [institutionId]: { [memberId]: { [programId]: files } } },
      state.v1Files
    );
    return {
      ...state,
      v1Files: newFiles
    };
  }),
  on(MemberActions.CreateProfileNotesSuccessAction, MemberActions.DeleteProfileNotesSuccessAction, MemberActions.UpdateProfileNotesSuccessAction, MemberActions.AddProfileNotesFileSuccessAction, (state, { }) => {
    return {
      ...state,
      profileNotes: undefined
    };
  })
);

export function memberReducer(state: MemberState | undefined, action: Action) {
  return reducer(state, action);
}

export const members = (state: MemberState) => state.member;
export const v1Files = (state: MemberState) => state.v1Files;
export const membersDashboard = (state: MemberState) => state.membersDashboard;
export const memberProgramYear = (state: MemberState) => state.memberProgramYear;
export const familiesByCounty = (state: MemberState) => state.familiesByCounty;
export const profileNotes = (state: MemberState) => state.profileNotes;

