import { Action, createReducer, on } from '@ngrx/store';
import { MemberAnimalsActions } from 'app/core/containers/member/store/animals';
import { IInstitutionManagerProgramModel } from 'app/core/models';
import { IEnrollmentAnimalDescriptorDto } from 'app/core/models/serverDTOs/IEnrollmentAnimalDescriptorDto';
import { mergeImmutable } from 'app/shared/utils';

import { ManagerAnimalActions } from '.';

export interface ManagerAnimalState {
    animalTypes: any;
    submittedAnimals: IInstitutionManagerProgramModel<{ [countyAreaId: string]: IEnrollmentAnimalDescriptorDto[] }>;
}

const initialState: ManagerAnimalState = {
    animalTypes: undefined,
    submittedAnimals: undefined
};

const reducer = createReducer(
    initialState,
    on(ManagerAnimalActions.ManagerGetAnimalTypesSuccessAction, (state, { institutionId, programId, animalTypes }) => {
        const newAnimalTypes = mergeImmutable(
            { [institutionId]: { [programId]: animalTypes } },
            state.animalTypes
        );
        return {
            ...state,
            animalTypes: newAnimalTypes
        };
    }),
    on(ManagerAnimalActions.GetSubmittedEnrollmentAnimalsSuccessAction, (state, { institutionId, managerId, programId, countyAreaId, enrollmentAnimals }) => {
        const newEnrollmentAnimals = mergeImmutable(
            {
                [institutionId]: { [managerId]: { [programId]: { [countyAreaId]: enrollmentAnimals } } }
            },
            state.submittedAnimals
        );
        return {
            ...state,
            submittedAnimals: newEnrollmentAnimals
        };
    }),
    on(MemberAnimalsActions.SaveEnrollmentAnimalCustomFileSuccessAction,
        MemberAnimalsActions.DeleteEnrollmentAnimalCustomFileSuccessAction,
        ManagerAnimalActions.ApproveAnimalSuccessAction,
        ManagerAnimalActions.SendBackAnimalSuccessAction,
        // ManagerAnimalActions.RejectAnimalSuccessAction,
        MemberAnimalsActions.SubmitAnimalSuccessAction,
        ManagerAnimalActions.UpdateAnimalCommentSuccessAction,
        (state, { }) => {
            return {
                ...state,
                submittedAnimals: undefined
            };
        })
);

export function animalReducer(state: ManagerAnimalState | undefined, action: Action) {
    return reducer(state, action);
}

export const animalTypes = (state: ManagerAnimalState) => state.animalTypes;
export const submittedAnimals = (state: ManagerAnimalState) => state.submittedAnimals;