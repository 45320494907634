import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { eEnrollmentRoleType, eHierarchyNodeType, IHierarchyCountyArea } from 'app/core/models';
import { CountyService, RouterService } from 'app/core/services';
import { EnrollmentFeesValidators } from 'app/core/validators/enrollment-fees.validator';
import { Observable } from 'rxjs';

@Component({
  selector: 'ng4h-modal-edit-enrollment-fee',
  templateUrl: './modal-edit-enrollment-fee.component.html',
  styleUrls: ['./modal-edit-enrollment-fee.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalEditEnrollmentFeeComponent implements OnInit {
  public form: FormGroup;

  public hierarchyName: string;
  public hierarchyType: eHierarchyNodeType;

  public eEnrollmentRoleType = eEnrollmentRoleType;
  public countyArea$: Observable<IHierarchyCountyArea>;

  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data,
    private fb: FormBuilder,
    private countyAreaService: CountyService,
    private routerService: RouterService
  ) { }

  public ngOnInit() {
    if (this.data.countyAreaId != null) {
      this.countyArea$ = this.countyAreaService.getCountyAreaById({ institutionId: this.routerService.institutionId, programId: this.routerService.programId, countyAreaId: this.data.countyAreaId });
    } else {
      this.countyArea$ = this.countyAreaService.getCountyArea({ institutionId: this.routerService.institutionId, programId: this.routerService.programId, hierarchyNodeId: this.routerService.hierarchyNodeId });
    }

    this.hierarchyName = this.data.fee.hierarchyNode.hierarchyNodeName;
    this.hierarchyType = this.data.fee.hierarchyNode.hierarchyNodeType;

    this.form = this.fb.group({
      id: this.data.fee.hierarchyNode.hierarchyNodeId,
      cloverNormal: [this.data.fee.cloverBud.normalFee === undefined ? 0 : this.data.fee.cloverBud.normalFee.rate, [Validators.required, Validators.min(0)]],
      cloverLate: [this.data.fee.cloverBud.lateFee === undefined ? 0 : this.data.fee.cloverBud.lateFee.rate, [Validators.required, Validators.min(0)]],
      youthNormal: [this.data.fee.youth.normalFee === undefined ? 0 : this.data.fee.youth.normalFee.rate, [Validators.required, Validators.min(0)]],
      youthLate: [this.data.fee.youth.lateFee === undefined ? 0 : this.data.fee.youth.lateFee.rate, [Validators.required, Validators.min(0)]],
      adultNormal: [this.data.fee.adult.normalFee === undefined ? 0 : this.data.fee.adult.normalFee.rate, [Validators.required, Validators.min(0)]],
      adultLate: [this.data.fee.adult.lateFee === undefined ? 0 : this.data.fee.adult.lateFee.rate, [Validators.required, Validators.min(0)]]
    }, { validators: [EnrollmentFeesValidators.CloverBud, EnrollmentFeesValidators.Youth, EnrollmentFeesValidators.Volunteer] });
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public update(): void {
    this.dialogRef.close(this.form.value);
  }
}
