import { ISearchDateRangeDto } from './ISearchDateRangeDto';

export interface IRegistrationPaymentSearchParametersDto {
    eventId: string;
    invoiceId: string;
    invoiceNumber?: number;
    paymentId: string;
    paymentNumber?: number;
    paymentMethodId: string;
    paymentTransactionId: string;
    paymentTransactionNumber?: number;
    invoiceStatus?: any[];
    paymentStatus?: any[];
    transactionDates: ISearchDateRangeDto;
    searchId: string;
    programId: string;
    managerInstitutionProfileId: string;
    programYear: string;
    searchTerm?: string;
    flagged?: boolean;
    hierarchyNodeIds?: any[];
    memberRoles?: any[];
    enrollmentRole?: any[];
    enrollmentStatus?: any[];
    enrollmentDates: ISearchDateRangeDto;
    memberProgramStatus?: any[];
    memberProgramYearStatus?: any[];
    schoolGrade?: any[];
}