import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState, getSchoolDistricts, getSchoolsByDistrict } from 'app/app.reducers';
import { ISchoolDistrictDto, ISchoolDto } from 'app/core/models';
import { SchoolActions } from 'app/shared/school';
import { Observable, tap } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class SchoolService {
  constructor(private httpClient: HttpClient, private store: Store<AppState>) {

  }

  public getSchoolDistricts(params: { institutionId: string, countyId: string }): Observable<ISchoolDistrictDto[]> {
    return this.store.pipe(
      select(getSchoolDistricts(params)),
      tap(schools => {
        if (schools === undefined) {
          this.store.dispatch(SchoolActions.SchoolDistrictsLoadByCountyAction(params));
        }
      })
    );
  }

  public loadSchoolDistrictsByCountyEffect({
    institutionId,
    countyId
  }: { institutionId: string, countyId: string }): Observable<ISchoolDistrictDto[]> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/schools/districts?countyId=${countyId}`) as Observable<ISchoolDistrictDto[]>;
  }

  public getSchoolsByDistrict(params: { institutionId: string, schoolDistrictId: string }): Observable<ISchoolDto[]> {
    return this.store.pipe(
      select(getSchoolsByDistrict(params)),
      tap(schools => {
        if (schools === undefined) {
          this.store.dispatch(SchoolActions.SchoolsLoadByDistrictAction(params));
        }
      })
    );
  }

  public loadSchoolsByDistrictEffect({
    institutionId,
    schoolDistrictId
  }: { institutionId: string, schoolDistrictId: string }): Observable<ISchoolDistrictDto> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/schools/districts/${schoolDistrictId}`) as Observable<ISchoolDistrictDto>;
  }
}
