<div class="modal-header">
  <h5 class="modal-title">Server Error Occurred</h5>
  <button (click)="close()" type="button" class="close" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-12">
      <p *ngIf="modalData.error?.error?.body as errorBody">"{{errorBody}}"</p>

      <p>
        <b>{{modalData.req?.method}}</b> {{modalData.error?.url}}
        <br>
        Returned <b>{{modalData.error?.status}}</b>
      </p>

      <ng-container *ngIf="modalData.req?.body as requestData">
        <b>Request Data:</b>
        <pre>{{requestData | json}}</pre>
      </ng-container>

    </div>
  </div>
  <div *ngIf="modalData.error?.status === 500 && (superUserId$ | async) != null" class="row">
    <div class="col-12">
      <h4>Error:</h4>
      <pre>
      {{(backendError$ | async)?.toFrontEndDto?.data}}
    </pre>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button (click)="close()" class="btn btn-secondary my-2 my-sm-0" role="button" aria-pressed="true">Ok</button>
</div>