import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { filter, map, Observable, of, take, tap } from 'rxjs';

import { getMemberGroups, MemberFeatureStore } from '../containers/member/member-feature.reducers';
import { MemberGroupsActions } from '../containers/member/store/groups';
import { IEnrollmentGroupSubmissionDto, IGroupDto, IInstitutionMemberProgramEnrollmentParams } from '../models';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MemberGroupsService {

  constructor(private httpClient: HttpClient, private store: Store<MemberFeatureStore>, private dispatcher: ActionsSubject) {
  }

  public getEligibleGroupsForEnrollment(params: IInstitutionMemberProgramEnrollmentParams & { countyAreaId?: string }) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getMemberGroups(params)),
      tap(groups => {
        if (groups === undefined) {
          this.store.dispatch(MemberGroupsActions.LoadGroupsForEnrollmentAction(params));
        }
      }),
      filter(groups => groups != null)
    );
  }

  public getEligibleGroupsForEnrollmentEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId
  }: IInstitutionMemberProgramEnrollmentParams) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/groups`) as Observable<IGroupDto[]>;
  }

  public saveGroupsEnrollment(params: IInstitutionMemberProgramEnrollmentParams & { enrollmentGroupsSubmission: IEnrollmentGroupSubmissionDto }) {

    this.store.dispatch(MemberGroupsActions.SaveGroupsForEnrollmentAction(params));
    return this.dispatcher.pipe(
      ofType(MemberGroupsActions.SaveGroupsForEnrollmentSuccessAction, MemberGroupsActions.SaveGroupsForEnrollmentErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberGroupsActions.SaveGroupsForEnrollmentSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public saveGroupsEnrollmentEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    enrollmentGroupsSubmission
  }: IInstitutionMemberProgramEnrollmentParams & { enrollmentGroupsSubmission: IEnrollmentGroupSubmissionDto }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/groups`, enrollmentGroupsSubmission);
  }

  public deleteGroup(params: IInstitutionMemberProgramEnrollmentParams & { groupId: string }) {

    this.store.dispatch(MemberGroupsActions.DeleteGroupsForEnrollmentAction(params));
    return this.dispatcher.pipe(
      ofType(MemberGroupsActions.DeleteGroupsForEnrollmentSuccessAction, MemberGroupsActions.DeleteGroupsForEnrollmentErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberGroupsActions.DeleteGroupsForEnrollmentSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteGroupsEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    groupId
  }: IInstitutionMemberProgramEnrollmentParams & { groupId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/groups/${groupId}`);
  }
}
