import { Action, createReducer, on } from '@ngrx/store';
import { IEnrollmentHierarchyInvoiceItemsDto, IInstitutionSystemManagerProgramModel } from 'app/core/models';
import { IEnrollmentConsentTypeDto } from 'app/core/models/serverDTOs/IEnrollmentConsentTypeDto';
import { mergeImmutable } from 'app/shared/utils';

import { SystemManagerEnrollmentActions } from '.';


export interface SystemManagerEnrollmentState {
  enrollmentConsents: { [institutionId: string]: { [systemManagerId: string]: { [programId: string]: IEnrollmentConsentTypeDto[] } } };
  invoices: IInstitutionSystemManagerProgramModel<{ [programYearId: string]: IEnrollmentHierarchyInvoiceItemsDto[] }>;
  clubInvoices: IInstitutionSystemManagerProgramModel<{ [countyAreaId: string]: { [programYearId: string]: IEnrollmentHierarchyInvoiceItemsDto[] } }>;
}

const initialState: SystemManagerEnrollmentState = {
  enrollmentConsents: undefined,
  invoices: undefined,
  clubInvoices: undefined
};


const reducer = createReducer(
  initialState,
  on(SystemManagerEnrollmentActions.SystemManagerLoadEnrollmentSuccessAction, (state, { institutionId, systemManagerId, programId, consents }) => {
    return {
      ...state,
      enrollmentConsents: mergeImmutable(
        { [institutionId]: { [systemManagerId]: { [programId]: consents } } },
        state.enrollmentConsents
      )
    };
  }),
  on(SystemManagerEnrollmentActions.SystemManagerInvalidateEnrollmentConsentsCacheAction, (state, { institutionId, systemManagerId, programId }) => {
    return {
      ...state,
      enrollmentConsents: mergeImmutable(
        { [institutionId]: { [systemManagerId]: { [programId]: undefined } } },
        state.enrollmentConsents
      )
    };
  }),
  on(SystemManagerEnrollmentActions.SystemManagerLoadInvoicesSuccessAction, (state, { institutionId, systemManagerId, programId, programYearId, invoices }) => {
    const newInvoices = mergeImmutable(
      { [institutionId]: { [systemManagerId]: { [programId]: { [programYearId]: invoices } } } },
      state.invoices
    );
    return {
      ...state,
      invoices: newInvoices
    };
  }),
  on(SystemManagerEnrollmentActions.SystemManagerUpdateInvoiceSuccessAction, (state, { institutionId, systemManagerId, programId, programYearId }) => {
    const newInvoices = mergeImmutable(
      { [institutionId]: { [systemManagerId]: { [programId]: { [programYearId]: undefined } } } },
      state.invoices
    );
    return {
      ...state,
      invoices: newInvoices
    };
  }),
  on(SystemManagerEnrollmentActions.SystemManagerLoadClubInvoicesSuccessAction, (state, { institutionId, systemManagerId, programId, countyAreaId, programYearId, invoices }) => {
    const newClubInvoices = mergeImmutable(
      { [institutionId]: { [systemManagerId]: { [programId]: { [countyAreaId]: { [programYearId]: invoices } } } } },
      state.clubInvoices
    );
    return {
      ...state,
      clubInvoices: newClubInvoices
    };
  }),
  on(SystemManagerEnrollmentActions.SystemManagerUpdateClubInvoiceSuccessAction, (state, { institutionId, systemManagerId, programId, countyAreaId, programYearId }) => {
    const newClubInvoices = mergeImmutable(
      { [institutionId]: { [systemManagerId]: { [programId]: { [countyAreaId]: { [programYearId]: undefined } } } } },
      state.clubInvoices
    );
    return {
      ...state,
      clubInvoices: newClubInvoices
    };
  }),
);

export function systemManagerEnrollmentReducer(state: SystemManagerEnrollmentState | undefined, actions: Action) {
  return reducer(state, actions);
}


export const enrollmentConsents = (state: SystemManagerEnrollmentState) => state.enrollmentConsents;
export const invoices = (state: SystemManagerEnrollmentState) => state.invoices;
export const clubInvoices = (state: SystemManagerEnrollmentState) => state.clubInvoices;


