import { Action, createReducer, on } from '@ngrx/store';
import { ManagerAnimalActions } from 'app/core/containers/admin/manager/store/animals';
import { IAnimalTypeDto, IInstitutionMemberProgramEnrollmentModel, IInstitutionMemberProgramModel } from 'app/core/models';
import { mergeImmutable } from 'app/shared/utils';

import { MemberAnimalsActions } from '.';

export interface MemberAnimalsState {
    animalTypes: IInstitutionMemberProgramModel<IAnimalTypeDto[]>;
    animalFiles: IInstitutionMemberProgramEnrollmentModel<IAnimalTypeDto[]>;
    animalForm: IInstitutionMemberProgramModel<any>;
    enrollmentAnimals: any;
    enrollmentAnimal: any;
    availableFamilyAnimals: any;
}

const initialState: MemberAnimalsState = {
    animalTypes: undefined,
    animalFiles: undefined,
    animalForm: undefined,
    enrollmentAnimals: undefined,
    enrollmentAnimal: undefined,
    availableFamilyAnimals: undefined,
};

const reducer = createReducer(
    initialState,
    on(MemberAnimalsActions.LoadAnimalTypesSuccessAction, (state, { institutionId, memberId, programId, animalTypes }) => {
        const newAnimalTypes = mergeImmutable(
            { [institutionId]: { [memberId]: { [programId]: animalTypes } } },
            state.animalTypes
        );
        return {
            ...state,
            animalTypes: newAnimalTypes
        };
    }),
    on(MemberAnimalsActions.LoadAnimalFilesSuccessAction, (state, { institutionId, memberId, programId, enrollmentId, enrollmentAnimalId, animalFiles }) => {
        const newAnimalFiles = mergeImmutable(
            { [institutionId]: { [memberId]: { [programId]: { [enrollmentId]: { [enrollmentAnimalId]: animalFiles } } } } },
            state.animalFiles
        );
        return {
            ...state,
            animalFiles: newAnimalFiles
        };
    }),
    on(MemberAnimalsActions.LoadFormSuccessAction, (state, { institutionId, memberId, programId, animalTypeId, animalForm }) => {
        const newAnimalForm = mergeImmutable(
            { [institutionId]: { [memberId]: { [programId]: { [animalTypeId]: animalForm } } } },
            state.animalForm
        );
        return {
            ...state,
            animalForm: newAnimalForm
        };
    }),
    on(MemberAnimalsActions.LoadAEnrollmentAnimalsSuccessAction, (state, { institutionId, memberId, programId, enrollmentId, animals }) => {
        const newEnrollmentAnimals = mergeImmutable(
            { [institutionId]: { [memberId]: { [programId]: { [enrollmentId]: animals } } } },
            state.enrollmentAnimals
        );
        return {
            ...state,
            enrollmentAnimals: newEnrollmentAnimals
        };
    }),
    on(MemberAnimalsActions.LoadAEnrollmentAnimalSuccessAction, (state, { institutionId, memberId, programId, enrollmentAnimalId, animal }) => {
        const newEnrollmentAnimal = mergeImmutable(
            { [institutionId]: { [memberId]: { [programId]: { [enrollmentAnimalId]: animal } } } },
            state.enrollmentAnimal
        );
        return {
            ...state,
            enrollmentAnimal: newEnrollmentAnimal
        };
    }),
    on(MemberAnimalsActions.LoadAvailableAnimalsSuccessAction, (state, { institutionId, memberId, programId, animals }) => {
        const newAvailableFamilyAnimals = mergeImmutable(
            { [institutionId]: { [memberId]: { [programId]: animals } } },
            state.availableFamilyAnimals
        );
        return {
            ...state,
            availableFamilyAnimals: newAvailableFamilyAnimals
        };
    }),
    on(MemberAnimalsActions.DeleteEnrollmentAnimalSuccessAction, (state, { institutionId, memberId, programId, enrollmentId, enrollmentAnimalId }) => {
        const newEnrollmentAnimal = mergeImmutable(
            { [institutionId]: { [memberId]: { [programId]: { [enrollmentAnimalId]: null } } } },
            state.enrollmentAnimal
        );
        const newAnimalFiles = mergeImmutable(
            { [institutionId]: { [memberId]: { [programId]: { [enrollmentId]: { [enrollmentAnimalId]: animalFiles } } } } },
            state.animalFiles
        );

        return {
            ...state,
            enrollmentAnimal: newEnrollmentAnimal,
            animalFiles: newAnimalFiles,
            animalTypes: undefined,
            animalForm: undefined,
            enrollmentAnimals: undefined,
            availableFamilyAnimals: undefined,
        };
    }),
    on(MemberAnimalsActions.EnrollmentAnimalInvalidateCacheAction, (state, { }) => {
        return {
            ...state,
            enrollmentAnimal: undefined
        }
    }),

    on(MemberAnimalsActions.UpdateEnrollmentAnimalFormSuccessAction,
        MemberAnimalsActions.SubmitAnimalSuccessAction,

        MemberAnimalsActions.CreateAnimalSuccessAction,
        MemberAnimalsActions.AddAnimalToEnrollmentSuccessAction,
        MemberAnimalsActions.SaveEnrollmentAnimalCustomFileSuccessAction,
        MemberAnimalsActions.DeleteEnrollmentAnimalCustomFileSuccessAction,
        ManagerAnimalActions.SendBackAnimalSuccessAction,
        ManagerAnimalActions.ApproveAnimalSuccessAction,
        ManagerAnimalActions.UpdateAnimalCommentSuccessAction,
        // ManagerAnimalActions.RejectAnimalSuccessAction,
        (state) => {
            return initialState;
        })
);

export function animalsReducer(state: MemberAnimalsState | undefined, actions: Action) {
    return reducer(state, actions);
}

export const animalTypes = (state: MemberAnimalsState) => state.animalTypes;
export const animalFiles = (state: MemberAnimalsState) => state.animalFiles;
export const animalForm = (state: MemberAnimalsState) => state.animalForm;
export const enrollmentAnimals = (state: MemberAnimalsState) => state.enrollmentAnimals;
export const enrollmentAnimal = (state: MemberAnimalsState) => state.enrollmentAnimal;
export const availableFamilyAnimals = (state: MemberAnimalsState) => state.availableFamilyAnimals;
