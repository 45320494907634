import { eFormControlItemType } from '.';
import { IDynamicOption } from './IDynamicOption';

export interface IDynamicItem {
  itemId: string;
  label: string;
  type: eFormControlItemType;
  userResponse: string;
  attributes: {
    placeholder?: string,
    rows?: number,
    quantityAvailable?: number,
    allowWaitList?: boolean;
    minDate?: string;
    maxDate?: string;
  };
  rate: number;
  options: IDynamicOption[];
  reportValue?: string;
}
