import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'yesNoNullable',
    pure: true
})
export class YesNoNullablePipe implements PipeTransform {
    public transform(value?: boolean): string {
        if (value === true) {
            return 'Yes';
        } else if (value === false) {
            return 'No';
        }
        return '';
    }
}
