export enum eCharterStatusType {
  Active = 'Active',
  Closed = 'Closed',
  Open = 'Open',
  Submitted = 'Submitted',
  UnderReview = 'UnderReview',
  Provisional = 'Provisional',
  Probationary = 'Probationary',
  Revoked = 'Revoked',
  Inactive = 'Inactive',
}
