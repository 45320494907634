<div class="modal-header">
  <h5 class="modal-title">Add Registration Type</h5>
  <button (click)="close()" type="button" class="close" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form *ng4hPlaceholder="form; size: [100,858]" [formGroup]="form">
    <div class="form-group row">
      <label class="form-control-label col-md-4 ng4h-required ">Name</label>
      <div class="col-md-8">
        <input formControlName="registrationTypeName" type="text" class="form-control">
      </div>
    </div>
    <div class="form-group row">
      <label class="form-control-label col-md-4">Role</label>
      <div class="col-md-8">
        <ng4h-registration-role-dropdown formControlName="registrationRole"></ng4h-registration-role-dropdown>
      </div>
    </div>
    <div class="form-group row">
      <label class="form-control-label col-md-4">Health Form</label>
      <div class="col-md-8">
        <select class="form-control" formControlName="healthFormType">
          <option [ngValue]="null">No Health Form</option>
          <option [value]="type.key" *ngFor="let type of eHealthFormType | keyvalue">
            {{type.value | camelToTitleCase}}</option>
        </select>
      </div>
    </div>
    <!-- <div class="form-group row">
      <label class="form-control-label col-md-4 ng4h-required">Program Year</label>
      <div class="col-md-8">
        <ng4h-program-year-dropdown class="form-group" formControlName="enrollmentRoleActiveProgramYear">
        </ng4h-program-year-dropdown>
      </div>
    </div> -->
    <div class="form-group row">
      <label class="form-control-label col-md-4 ng4h-required">Maximum Registrations</label>
      <div class="col-md-8">
        <input formControlName="maximumRegistrations" type="number" class="form-control">
      </div>
    </div>
    <div class="form-group row">
      <mat-checkbox class="offset-md-4 col-md-8" formControlName="allowActiveInPreviousProgramYear">Allow Active in
        Previous Program Year</mat-checkbox>
    </div>
    <div class="form-group row">
      <mat-checkbox class="offset-md-4 col-md-8" formControlName="initiateRegistrationByManagerOnly">Initiate
        Registration By Manager Only</mat-checkbox>
    </div>
    <div class="form-group row">
      <mat-checkbox class="offset-md-4 col-md-8" formControlName="depositRequired">Deposit Required</mat-checkbox>
    </div>
    <div class="form-group row">
      <label class="form-control-label col-md-4"
        [ngClass]="{'ng4h-required': form.controls['depositRequired'].value == true}">Deposit Amount</label>
      <div class="col-md-8">
        <input formControlName="depositAmount" type="number" class="form-control">
      </div>
    </div>
    <div class="form-group row">
      <label class="form-control-label col-md-4"
        [ngClass]="{'ng4h-required': form.controls['depositRequired'].value == true}">Deposit Full Payment
        Due Date</label>
      <div class="input-group col-md-8">
        <input class="form-control" formControlName="depositFullPaymentDueDate"
          [matDatepicker]="depositFullPaymentDueDate">
        <mat-datepicker-toggle matSuffix [for]="depositFullPaymentDueDate"></mat-datepicker-toggle>
      </div>
      <mat-datepicker #depositFullPaymentDueDate></mat-datepicker>
    </div>
    <div class="form-group row">
      <label class="form-control-label col-md-4 ng4h-required">Registration Fee</label>
      <div class="col-md-8">
        <input formControlName="registrationFee" type="number" class="form-control">
      </div>
    </div>
    <div class="form-group row">
      <label class="form-control-label col-md-4">Registration Start Date</label>
      <div class="input-group col-md-8">
        <input class="form-control" formControlName="registrationStartDate" [matDatepicker]="startDate"
          placeholder="Registration start date">
        <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
      </div>
      <mat-datepicker #startDate></mat-datepicker>
    </div>
    <div class="form-group row">
      <label class="form-control-label col-md-4">Registration End Date</label>
      <div class="input-group col-md-8">
        <input class="form-control" formControlName="registrationEndDate" [matDatepicker]="endDate"
          placeholder="Registration end date">
        <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
      </div>
      <mat-datepicker #endDate></mat-datepicker>
    </div>
    <div class="form-group row">
      <mat-checkbox class="offset-md-4 col-md-8" formControlName="allowEarlyRegistration">Allow Early Registration
      </mat-checkbox>
    </div>
    <div class="form-group row">
      <label class="form-control-label col-md-4"
        [ngClass]="{'ng4h-required': form.controls.allowEarlyRegistration.value === true}">Early Registration
        Fee</label>
      <div class="col-md-8">
        <input formControlName="earlyRegistrationFee" type="number" class="form-control">
      </div>
    </div>
    <div class="form-group row">
      <label class="form-control-label col-md-4"
        [ngClass]="{'ng4h-required': form.controls.allowEarlyRegistration.value === true}">Early Registration Start
        Date</label>
      <div class="input-group col-md-8">
        <input class="form-control" formControlName="earlyRegistrationStartDate" [matDatepicker]="earlyStartDate"
          [max]="form.controls.earlyRegistrationEndDate.value">
        <mat-datepicker-toggle matSuffix [for]="earlyStartDate"></mat-datepicker-toggle>
      </div>
      <mat-datepicker #earlyStartDate></mat-datepicker>
    </div>
    <div class="form-group row">
      <label class="form-control-label col-md-4"
        [ngClass]="{'ng4h-required': form.controls.allowEarlyRegistration.value === true}">Early Registration End
        Date</label>
      <div class="input-group col-md-8">
        <input class="form-control" formControlName="earlyRegistrationEndDate" [matDatepicker]="earlyEndDate"
          [max]="form.controls.registrationStartDate.value">
        <mat-datepicker-toggle matSuffix [for]="earlyEndDate"></mat-datepicker-toggle>
      </div>
      <mat-datepicker #earlyEndDate></mat-datepicker>
    </div>
    <div class="form-group row">
      <mat-checkbox class="offset-md-4 col-md-8" formControlName="allowLateRegistration">Allow Late Registration
      </mat-checkbox>
    </div>
    <div class="form-group row">
      <label class="form-control-label col-md-4"
        [ngClass]="{'ng4h-required': form.controls.allowLateRegistration.value === true}">Late Registration Fee</label>
      <div class="col-md-8">
        <input formControlName="lateRegistrationFee" type="number" class="form-control">
      </div>
    </div>
    <div class="form-group row">
      <label class="form-control-label col-md-4"
        [ngClass]="{'ng4h-required': form.controls.allowLateRegistration.value === true}">Late Registration Start
        Date</label>
      <div class="input-group col-md-8">
        <input class="form-control" formControlName="lateRegistrationStartDate" [matDatepicker]="lateStartDate"
          [min]="form.controls.registrationEndDate.value">
        <mat-datepicker-toggle matSuffix [for]="lateStartDate"></mat-datepicker-toggle>
      </div>
      <mat-datepicker #lateStartDate></mat-datepicker>
    </div>
    <div class="form-group row">
      <label class="form-control-label col-md-4"
        [ngClass]="{'ng4h-required': form.controls.allowLateRegistration.value === true}">Late Registration End
        Date</label>
      <div class="input-group col-md-8">
        <input class="form-control" formControlName="lateRegistrationEndDate" [matDatepicker]="lateEndDate"
          [min]="form.controls.lateRegistrationStartDate.value">
        <mat-datepicker-toggle matSuffix [for]="lateEndDate"></mat-datepicker-toggle>
      </div>
      <mat-datepicker #lateEndDate></mat-datepicker>
    </div>



    <div class="form-group row" *ngIf="animalTypes$ | async as animalTypes">
      <label class="form-control-label col-md-4">Animal Type</label>

      <div class="col-md-8">
        <ng4h-multiselect-autocomplete (selectedChanged)="animalTypesChanged($event)" [options]="animalTypes"
          valueSelector="animalTypeId" displaySelector="animalTypeLabel" [selected]="form.controls.animalTypes.value">
        </ng4h-multiselect-autocomplete>
      </div>
    </div>


    <!-- Registration Qualifications-->

    <ng4h-card>
      <ng4h-card-header>Registration Qualifications</ng4h-card-header>
      <ng4h-card-content>


        <!-- Youth-->

        <ng-container
          *ngIf="form.controls.registrationRole.value == eRegistrationRoleType.ClubMember || form.controls.registrationRole.value == eRegistrationRoleType.CloverBud">

          <div class="form-group row">
            <label class="form-control-label col-md-4">Youth Qualifier Type</label>
            <div class="col-md-8">
              <select class="form-control" formControlName="restrictionType">
                <option [ngValue]="null"></option>
                <option [value]="eEventRegistrationTypeYouthRestrictionType.ProgramAgeGrade">Age/Grade</option>
                <option [value]="eEventRegistrationTypeYouthRestrictionType.ProgramYouthDivision">Program Youth Division
                </option>
              </select>
            </div>
          </div>



          <ng-container
            *ngIf="form.controls.restrictionType.value === eEventRegistrationTypeYouthRestrictionType.ProgramAgeGrade">
            <div class="form-group row">
              <label class="form-control-label col-md-4">Grades</label>
              <div class="col-md-8">
                <ng4h-multiselect-autocomplete (selectedChanged)="schoolGradeChanged($event)"
                  [options]="schoolGradeOptions" valueSelector="key" displaySelector="display"
                  [selected]="form.controls.schoolGrades.value">
                </ng4h-multiselect-autocomplete>
              </div>
            </div>


            <div class="form-group row">
              <label class="form-control-label col-md-4">Program Ages</label>
              <div class="col-md-8">
                <ng4h-multiselect-autocomplete [sort]="false" (selectedChanged)="programAgeChanged($event)"
                  [options]="ageOptions" valueSelector="key" displaySelector="display"
                  [selected]="form.controls.programAges.value">
                </ng4h-multiselect-autocomplete>
              </div>
            </div>


          </ng-container>

          <ng-container
            *ngIf="form.controls.restrictionType.value === eEventRegistrationTypeYouthRestrictionType.ProgramYouthDivision">
            <div class="form-group row" *ngIf="youthDivisionOptions$ | async as youthDivisionOptions">

              <label class="form-control-label col-md-4">Program Youth Divisions</label>
              <div class="col-md-8">
                <ng4h-multiselect-autocomplete (selectedChanged)="youthDivisionChanged($event)"
                  [options]="youthDivisionOptions" valueSelector="key" displaySelector="display"
                  [selected]="form.controls.programYouthDivisions.value">
                </ng4h-multiselect-autocomplete>
              </div>
            </div>
          </ng-container>

        </ng-container>



        <!-- Participant-->


        <ng-container *ngIf="form.controls.registrationRole.value == eRegistrationRoleType.Participant">

          <div class="form-group row">
            <label class="form-control-label col-md-4">Age Qualifier Type</label>
            <div class="col-md-8">
              <select class="form-control" formControlName="restrictionType">
                <option [ngValue]="null"></option>
                <option [value]="eEventRegistrationTypeYouthRestrictionType.Age">Age</option>
                <option [value]="eEventRegistrationTypeYouthRestrictionType.ProgramAgeGrade">Program Age</option>
              </select>
            </div>
          </div>

          <div class="form-group row"
            *ngIf="form.controls.restrictionType.value === eEventRegistrationTypeYouthRestrictionType.ProgramAgeGrade">
            <label class="form-control-label col-md-4">Program Ages</label>
            <div class="col-md-8">
              <ng4h-multiselect-autocomplete [sort]="false" (selectedChanged)="programAgeChanged($event)"
                [options]="ageOptions" valueSelector="key" displaySelector="display"
                [selected]="form.controls.programAges.value">
              </ng4h-multiselect-autocomplete>
            </div>
          </div>

          <div class="form-group row"
            *ngIf="form.controls.restrictionType.value === eEventRegistrationTypeYouthRestrictionType.Age">
            <label class="form-control-label col-md-4">Ages</label>
            <div class="col-md-8">
              <ng4h-multiselect-autocomplete [sort]="false" (selectedChanged)="ageChanged($event)"
                [options]="ageOptions" valueSelector="key" displaySelector="display"
                [selected]="form.controls.ages.value">
              </ng4h-multiselect-autocomplete>
            </div>
          </div>



        </ng-container>
      </ng4h-card-content>
    </ng4h-card>
    <!-- End Registration Qualifications-->

  </form>
</div>
<div class="modal-footer">
  <button [disabled]="form == null || form.invalid" (click)="add()" class="btn btn-primary my-2 my-sm-0" role="button"
    aria-pressed="true">Add</button>
</div>