import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AutoUnsubscribe, IHierarchyCountyArea } from 'app/core/models';
import { CountyService, HierarchyService, RouterService } from 'app/core/services';
import { filter, map, Observable, switchMap, takeUntil } from 'rxjs';

@Component({
    selector: 'ng4h-county-area-select',
    templateUrl: './county-area-select.component.html',
    styleUrls: ['./county-area-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CountyAreaSelectComponent),
            multi: true
        }
    ]
})
export class CountyAreaSelectComponent extends AutoUnsubscribe implements OnInit, OnChanges, AfterViewInit, ControlValueAccessor {

    @Input() programId?: string = this.routerService.programId;
    @Input() reset: EventEmitter<void>;
    @Input() disabled: boolean;
    @Input() onlyIncludeUnderHierarchyId: string;
    @Input() allowDeselect = false;

    public countyAreaId: string;

    public countyAreas$: Observable<IHierarchyCountyArea[]>;

    // START ControlValueAccessor
    onChange: any = () => {
    }
    onTouched: any = () => {
    }

    writeValue(countyAreaId: string): void {
        if (this.allowDeselect || countyAreaId != null) {
            this.countyAreaId = countyAreaId;
            this.onChange(countyAreaId);
            this.onTouched();
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    // END ControlValueAccessor
    constructor(private countyAreaService: CountyService, private routerService: RouterService, private hierarchyService: HierarchyService, private cdref: ChangeDetectorRef) {
        super();
        if (this.reset != null) {
            this.reset.pipe(takeUntil(this.autoUnsubscribe)).subscribe(() => this.countyAreaId = null);
        }
    }

    ngOnInit() {
        if (this.onlyIncludeUnderHierarchyId == null) {
            this.countyAreas$ = this.countyAreaService.getCountyAreas({
                institutionId: this.routerService.institutionId,
                programId: this.programId
            }).pipe(
                filter(counties => Array.isArray(counties)),
                map(counties => {
                    return counties.slice().sort((a, b) => a.hierarchyNodeName.localeCompare(b.hierarchyNodeName));
                })
            );
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        this.onlyIncludeUnderHierarchyId = changes.onlyIncludeUnderHierarchyId ? changes.onlyIncludeUnderHierarchyId.currentValue : null;

        let ca: Observable<IHierarchyCountyArea[]>;
        if (this.onlyIncludeUnderHierarchyId == null) {
            ca = this.countyAreaService.getCountyAreas({ institutionId: this.routerService.institutionId, programId: this.programId });
        } else {
            ca = this.hierarchyService.getHierarchyNode({
                institutionId: this.routerService.institutionId,
                hierarchyNodeId: this.onlyIncludeUnderHierarchyId
            }).pipe(
                switchMap(hierarchyNode => this.hierarchyService.getCountyAreas({ institutionId: this.routerService.institutionId, hierarchyNode })
                )
            );

            this.countyAreas$ = ca.pipe(
                filter(counties => Array.isArray(counties)),
                map(counties => {
                    return counties.sort((a, b) => a.hierarchyNodeName.localeCompare(b.hierarchyNodeName));
                })
            );
            // If there in a county area, they'll only be one to choose from so select it for them
            this.countyAreas$.pipe(
                    filter(countyAreas => {
                        return Array.isArray(countyAreas);
                    }),
                    takeUntil(this.autoUnsubscribe))
                .subscribe(countyAreas => {
                    if (countyAreas.length === 1) {
                        this.countyAreaId = countyAreas[0].countyAreaId;
                        this.onChange(this.countyAreaId);
                        this.disabled = true;
                    } else {
                        this.countyAreaId = null;
                        this.disabled = false;
                    }

                });
        }
    }

    ngAfterViewInit() {
        if (this.countyAreaId) {
            // This is a total hack but I can't get it to not throw ExpressionChangedAfterItHasBeenCheckedError without it
            setTimeout(() => {
                this.onChange(this.countyAreaId);
                this.cdref.detectChanges();
            }, 0);
        }
    }
}
