import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { eAppSettingsAlias, eEnrollmentRoleType } from 'app/core/models';
import { ProgramSettingsService, RouterService } from 'app/core/services';
import { map, Observable } from 'rxjs';

@Component({
  selector: 'ng4h-modal-add-custom-file',
  templateUrl: './modal-add-custom-file.component.html',
  styleUrls: ['./modal-add-custom-file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalAddCustomFileComponent implements OnInit {

  public eEnrollmentRoleType = eEnrollmentRoleType;
  public form: FormGroup;

  public aliases$: Observable<string[]>;
  constructor(
    private dialogRef: MatDialogRef<any>,
    private fb: FormBuilder,
    private routerService: RouterService,
    private programSettingsService: ProgramSettingsService
  ) { }

  public ngOnInit() {
    this.form = this.fb.group({
      name: [null, [Validators.required]],
      // type: [null, [Validators.required]],
      description: [null],
      managerOnly: [false, [Validators.required]],

      enrollmentRoles: this.fb.group({
        Volunteer: [null],
        ClubMember: [null],
        CloverBud: [null],
      }),
    });

    this.aliases$ = this.programSettingsService.getProgramSettings({
      institutionId: this.routerService.institutionId,
      programId: this.routerService.programId
    }).pipe(
      map(programSettings => {

        return Object.keys(eEnrollmentRoleType)
          .map(key => eEnrollmentRoleType[key])
          .filter(role => role !== eEnrollmentRoleType.Participant)
          .map((enrollmentRole: eAppSettingsAlias) => programSettings.aliases[enrollmentRole].singular)
          .sort();

      })
    );


  }

  public close(): void {
    this.dialogRef.close();
  }

  public add(): void {
    this.dialogRef.close(this.form.value);
  }
}
