import { DynamicControl } from './DynamicControl';
import { IDynamicGroup } from './IDynamicGroup';

export class DynamicGroup implements IDynamicGroup {
  groupId: string;
  label: string;
  controls: DynamicControl[];
  constructor(_controls: DynamicControl[] = []) {
    this.controls = _controls;
  }
}
