import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IAddressDto } from 'app/core/models';

@Component({
  selector: 'ng4h-address-display',
  templateUrl: './address-display.component.html',
  styleUrls: ['./address-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressDisplayComponent implements OnInit {

  @Input() address: IAddressDto;

  constructor() { }

  ngOnInit() {
  }
}
