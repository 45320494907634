import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IInstitutionMemberProgramEnrollmentParams, IProjectDto } from 'app/core/models';


export const ProjectsLoadAction = createAction(
  '[projects] Load',
  props<IInstitutionMemberProgramEnrollmentParams>()
);
export const ProjectsLoadSuccessAction = createAction(
  '[projects] Load success',
  props<IInstitutionMemberProgramEnrollmentParams & { projects: IProjectDto[] }>()
);
export const ProjectsLoadErrorAction = createAction(
  '[projects] Load error',
  props<{ error: HttpErrorResponse }>()
);

export const ProjectsLoadByClubAction = createAction(
  '[projects] Load by club',
  props<IInstitutionMemberProgramEnrollmentParams & { unitId: string }>()
);
export const ProjectsLoadByClubSuccessAction = createAction(
  '[projects] Load by club success',
  props<IInstitutionMemberProgramEnrollmentParams & { unitId: string, projects: IProjectDto[] }>()
);
export const ProjectsLoadByClubErrorAction = createAction(
  '[projects] Load by club error',
  props<{ error: HttpErrorResponse }>()
);