import { IFileType } from '.';
import { eAnimalFileType } from '..';
import { IFourHFileDto } from './IFourHFileDto';

export interface IAnimalTypeFileControlDto {
    managerOnly: boolean;
    required: boolean;
    persistFile?: boolean;
    hasFile: boolean;
    uploadedFile: IFourHFileDto;
    animalFileType: eAnimalFileType;
    fileControlId: string;
    fileControlName?: string;
    fileControlDescription?: string;
    acceptedFileExtensions?: IFileType[];
    templateFile: IFourHFileDto;
    hasTemplateFile: boolean;
}
