import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  IInstitutionFamilyParams,
  IInstitutionFamilyProgramParams,
  IPaymentGatewayPaymentMethodsDto,
} from 'app/core/models';

export enum PaymentsActionTypes {
  LOAD = '[payment] Load',
  LOAD_SUCCESS = '[payment] Load success',
  LOAD_ERROR = '[payment] Load error',
  INVALIDATE_CACHE = '[payment] Invalidate cache'
}

export const PaymentsLoadAction = createAction(
  '[payment] Load',
  props<IInstitutionFamilyParams>()
);
export const PaymentsLoadSuccessAction = createAction(
  '[payment] Load success',
  props<IInstitutionFamilyParams & { payments: IPaymentGatewayPaymentMethodsDto[] }>()
);
export const PaymentsLoadErrorAction = createAction(
  '[payment] Load error',
  props<{ error: HttpErrorResponse }>()
);

export const PaymentsByProgramLoadAction = createAction(
  '[payment] Load by program',
  props<IInstitutionFamilyProgramParams>()
);
export const PaymentsByProgramLoadSuccessAction = createAction(
  '[payment] Load by program success',
  props<IInstitutionFamilyProgramParams & { payments: IPaymentGatewayPaymentMethodsDto }>()
);
export const PaymentsByProgramLoadErrorAction = createAction(
  '[payment] Load by program error',
  props<{ error: HttpErrorResponse }>()
);
export const PaymentsInvalidateCache = createAction(
  '[payment] Invalidate cache',
  props<any>()
);
