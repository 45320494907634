import { createAction, props } from '@ngrx/store';
import { IInstitutionMemberProgramParams, IProfileTrainingRecordCreateDto } from 'app/core/models';
import { IProfileTrainingDto } from 'app/core/models/serverDTOs/IProfileTrainingDto';
import { IProfileTrainingLessonLaunchDto } from 'app/core/models/serverDTOs/IProfileTrainingLessonLaunchDto';

export const MemberTrainingLoadAvailableTrainingsAction = createAction(
    '[member trainings] Load available trainings',
    props<IInstitutionMemberProgramParams>()
);
export const MemberTrainingLoadAvailableTrainingsSuccessAction = createAction(
    '[member trainings] Load available trainings success',
    props<IInstitutionMemberProgramParams & { availableTrainings: IProfileTrainingDto[] }>()
);
export const MemberTrainingLoadAvailableTrainingsErrorAction = createAction(
    '[member trainings] Load available trainings error',
    props<{ error?: any }>()
);
export const MemberTrainingRegisterForTrainingAction = createAction(
    '[member trainings] Register for training',
    props<IInstitutionMemberProgramParams & { trainingId: string }>()
);
export const MemberTrainingRegisterForTrainingSuccessAction = createAction(
    '[member trainings] Register for training success',
    props<IInstitutionMemberProgramParams & { trainingId: string }>()
);
export const MemberTrainingRegisterForTrainingErrorAction = createAction(
    '[member trainings] Register for training error',
    props<{ error?: any }>()
);

export const MemberTrainingLoadTrainingsAction = createAction(
    '[member trainings] Load trainings',
    props<IInstitutionMemberProgramParams>()
);
export const MemberTrainingLoadTrainingsSuccessAction = createAction(
    '[member trainings] Load trainings success',
    props<IInstitutionMemberProgramParams & { trainings: any }>()
);
export const MemberTrainingLoadTrainingsErrorAction = createAction(
    '[member trainings] Load trainings error',
    props<{ error?: any }>()
);

export const MemberTrainingLoadTrainingAction = createAction(
    '[member trainings] Load training',
    props<IInstitutionMemberProgramParams & { profileTrainingId: string }>()
);
export const MemberTrainingLoadTrainingSuccessAction = createAction(
    '[member trainings] Load training success',
    props<IInstitutionMemberProgramParams & { profileTrainingId: string, training: any }>()
);
export const MemberTrainingLoadTrainingErrorAction = createAction(
    '[member trainings] Load training error',
    props<{ error?: any }>()
);

export const MemberTrainingLoadLessonAction = createAction(
    '[member trainings] Load lesson',
    props<IInstitutionMemberProgramParams & { profileTrainingId: string, trainingLessonId: string, dto: IProfileTrainingLessonLaunchDto }>()
);
export const MemberTrainingLoadLessonSuccessAction = createAction(
    '[member trainings] Load lesson success',
    props<IInstitutionMemberProgramParams & { profileTrainingId: string, trainingLessonId: string, lesson: any }>()
);
export const MemberTrainingLoadLessonErrorAction = createAction(
    '[member trainings] Load lesson error',
    props<IInstitutionMemberProgramParams & { profileTrainingId: string, trainingLessonId: string, dto: IProfileTrainingLessonLaunchDto, error?: any }>()
);

export const MemberTrainingInvalidateCacheAction = createAction(
    '[member trainings] Invalidate cache'
);


export const MemberTrainingManagerRecordTrainingAction = createAction(
    '[member trainings] Manager record training',
    props<IInstitutionMemberProgramParams & { trainingId: string, profileTrainingRecordCreate: IProfileTrainingRecordCreateDto }>()
);
export const MemberTrainingManagerRecordTrainingSuccessAction = createAction(
    '[member trainings] Manager record training success',
    props<IInstitutionMemberProgramParams & { trainingId: string, profileTrainingRecordCreate: IProfileTrainingRecordCreateDto }>()
);
export const MemberTrainingManagerRecordTrainingErrorAction = createAction(
    '[member trainings] Manager record training error',
    props<{ error?: any }>()
);

export const ManagerMarkTrainingAsCompletedAction = createAction(
    '[member trainings] Mark as completed',
    props<IInstitutionMemberProgramParams & { profileTrainingId: string }>()
);
export const ManagerMarkTrainingAsCompletedSuccessAction = createAction(
    '[member trainings] Mark as completed success',
    props<IInstitutionMemberProgramParams & { profileTrainingId: string }>()
);
export const ManagerMarkTrainingAsCompletedErrorAction = createAction(
    '[member trainings] Mark as completed error',
    props<{ error?: any }>()
);


export const MemberTrainingDeleteAction = createAction(
    '[member trainings] Delete training',
    props<IInstitutionMemberProgramParams & { profileTrainingId: string }>()
);
export const MemberTrainingDeleteSuccessAction = createAction(
    '[member trainings] Delete training success',
    props<IInstitutionMemberProgramParams & { profileTrainingId: string }>()
);
export const MemberTrainingDeleteErrorAction = createAction(
    '[member trainings] Delete training error',
    props<{ error?: any }>()
);