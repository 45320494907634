import { Action, createReducer, on } from '@ngrx/store';
import { IInstitutionMemberProgramEnrollmentModel, IVolunteerHoursDto, IVolunteerHoursProjectsDto } from 'app/core/models';
import { mergeImmutable } from 'app/shared/utils';

import { MemberHoursActions } from '.';


export interface MemberHoursState {
    hours: IInstitutionMemberProgramEnrollmentModel<IVolunteerHoursDto[]>;
    projects: IInstitutionMemberProgramEnrollmentModel<IVolunteerHoursProjectsDto>;
}

const initialState: MemberHoursState = {
    hours: undefined,
    projects: undefined
};

const reducer = createReducer(
    initialState,
    on(MemberHoursActions.GetVolunteerHoursSuccessAction, (state, { institutionId, memberId, programId, enrollmentId, hours }) => {
        return {
            ...state,
            hours: mergeImmutable(
                { [institutionId]: { [memberId]: { [programId]: { [enrollmentId]: hours } } } },
                state.hours
            )
        };
    }),
    on(MemberHoursActions.GetVolunteerHoursProjectsSuccessAction, (state, { institutionId, memberId, programId, enrollmentId, projects }) => {
        return {
            ...state,
            projects: mergeImmutable(
                { [institutionId]: { [memberId]: { [programId]: { [enrollmentId]: projects } } } },
                state.projects
            )
        };
    }),
    on(MemberHoursActions.AddVolunteerHoursSuccessAction,
        MemberHoursActions.UpdateVolunteerHoursSuccessAction,
        MemberHoursActions.DeleteVolunteerHoursSuccessAction,
        (state, { }) => {
            return {
                ...state,
                hours: undefined
            };
        })
);

export function memberHoursReducer(state: MemberHoursState | undefined, action: Action) {
    return reducer(state, action);
}

export const hours = (state: MemberHoursState) => state.hours;
export const projects = (state: MemberHoursState) => state.projects;
