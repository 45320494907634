import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
    ModalChangeProfileComponent
} from 'app/core/containers/admin/manager/modals/modal-change-profile/modal-change-profile.component';
import { ModalSelectProgramComponent } from 'app/core/containers/admin/modal-select-program/modal-select-program.component';
import {
    ModalSuperuserPermissionsComponent
} from 'app/core/containers/institution/modal-superuser-permissions/modal-superuser-permissions.component';
import {
    ModalChangeInstitutionComponent
} from 'app/core/containers/superuser/modal-change-institution/modal-change-institution.component';
import {
    AutoUnsubscribe,
    eProfileTrainingStatus,
    eProfileType,
    eProfileVolunteerScreeningStatus,
    IAnyInstitutionProfile,
    IHierarchyAny,
    IManagerInstitutionProfile,
    ISystemManagerInstitutionProfile,
    IUserAccountDto
} from 'app/core/models';
import {
    HierarchyService,
    InstitutionService,
    MemberService,
    ModalService,
    ProfileService,
    RouterService,
    SidebarService,
    UserService
} from 'app/core/services';
import { ProgramSettingsService } from 'app/core/services/program-settings.service';
import { rankProfileLevel } from 'app/shared/utils';
import { combineLatest, filter, map, Observable, of, switchMap, take, takeUntil } from 'rxjs';

import { ModalChangeHierarchyComponent } from '../modal-change-hierarchy/modal-change-hierarchy.component';
import { ModalGlobalSearchComponent } from '../modal-global-search/modal-global-search.component';
import { ModalInteractionComponent } from '../modal-interaction/modal-interaction.component';
import { environment } from 'environments/environment';

@Component({
    selector: 'ng4h-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent extends AutoUnsubscribe implements OnInit, OnDestroy {
    public environment = environment;
    public showHamburger: Observable<boolean>;

    public managerHierarchyNode$: Observable<IHierarchyAny>;

    public eProfileType = eProfileType;
    public loggedInInstitutionProfileId$: Observable<string>;
    public superuserId$: Observable<string>;
    public activeUserInstitutionProfile$: Observable<IAnyInstitutionProfile>;
    public usersInstitutionProfiles$: Observable<IAnyInstitutionProfile[]>;
    public actingOnBehalfOf$: Observable<IAnyInstitutionProfile>;

    public logoResource$: Observable<string>;

    public userAccount$: Observable<IUserAccountDto>;

    public activeProfileHistory$: Observable<{ actingOnBehalfOfProfile: IAnyInstitutionProfile, route: string[] }[]>;

    public showActionBadge$: Observable<boolean>;

    public programId$: Observable<string>;
    public institutionId$: Observable<string>;

    public isExtension: boolean = location.hostname == 'extension.online' ? true : false;

    constructor(
        private userService: UserService,
        private profileService: ProfileService,
        private sideBarService: SidebarService,
        private routerService: RouterService,
        private modalService: ModalService,
        private hierarchyService: HierarchyService,
        private route: ActivatedRoute,
        private institutionService: InstitutionService,
        private programSettingsService: ProgramSettingsService,
        private memberService: MemberService
    ) {
        super();
        this.loggedInInstitutionProfileId$ = this.routerService.loggedInInstitutionProfileId$;
        this.superuserId$ = this.routerService.superuserId$;
        this.usersInstitutionProfiles$ = this.profileService.usersInstitutionProfiles$;
        this.activeUserInstitutionProfile$ = this.profileService.loggedInAsInstitutionProfile;
        this.actingOnBehalfOf$ = this.profileService.actingAsInstitutionProfile;
        this.showHamburger = this.sideBarService.sidebarMode$.pipe(map(mode => mode === 'over'), takeUntil(this.autoUnsubscribe));
        this.managerHierarchyNode$ = this.hierarchyService.managerHierarchyNode$;
        this.userAccount$ = this.userService.userAccount$.pipe(takeUntil(this.autoUnsubscribe));

    }

    ngOnInit() {

        this.programId$ = this.routerService.programId$;
        this.institutionId$ = this.routerService.institutionId$;

        this.activeProfileHistory$ = this.profileService.activeProfileHistory$;

        this.logoResource$ = combineLatest([this.routerService.institutionId$, this.routerService.programId$]).pipe(
            map(([institutionId, programId]) => {
                if (institutionId != null && programId != null) {
                    return this.programSettingsService.getProgramLogoSrc({ institutionId, programId });
                } else if (institutionId != null) {
                    return this.institutionService.getInstitutionLogoSrc({ institutionId });
                }
                return null;
            }),
            filter(src => src != null)
        );

        this.showActionBadge$ = combineLatest([this.routerService.memberId$, this.routerService.programId$]).pipe(
            switchMap(([memberId, programId]) => {
                if (memberId == null || programId == null) {
                    return of(false);
                } else {
                    return this.memberService.getMemberDashboard({
                        institutionId: this.routerService.institutionId,
                        memberId: this.routerService.memberId
                    }).pipe(
                        map(member => {
                            if (member?.memberPrograms?.[programId]?.activeMemberProgramYear == null) {
                                return false;
                            }
                            const activeMemberProgramYear = member.memberPrograms[programId].activeMemberProgramYear;
                            if (activeMemberProgramYear.screening != null && activeMemberProgramYear.screening.status === eProfileVolunteerScreeningStatus.Incomplete) {
                                return true;
                            } else if (activeMemberProgramYear.trainings.filter(t => t.status === eProfileTrainingStatus.Incomplete && t.required === true).length > 0) {
                                return true;
                            }
                            return false;
                        })
                    );
                }
            })
        );

        // this.logoResource$ = this.routerService.institutionId$.pipe(map(institutionId => this.institutionService.getInstitutionLogoSrc({ institutionId })));
    }

    public hamburgerClicked() {
        this.sideBarService.toggleSideBar();
    }

    public managerHamburgerClicked() {
        this.sideBarService.toggleManagerSideBar();
    }

    public signOut(): void {
        this.userService.signOut();
    }

    public changeProfile() {
        this.modalService.openModal(ModalChangeProfileComponent, { width: '700px' });
    }

    // James Youngblood
    // evaluate as 4 levels: family/member being 1 level, manager being another and then superuser as institution and finally superuser outside of institution
    // the up arrow takes you to the level above from which you ended up on that record
    // so if you are a superuser and log in to an institution then a manager and then the Jones family
    // Jones family up arrow takes you back to the manager
    // Manager up arrow takes you back to the institution
    // Insitution up arrow take you back to the superuser home

    // Ben Wilson
    // what about member
    // member => family?

    // James Youngblood
    // member and family would both take you to the manager

    public profileUp(actingOnBehalfOfProfile: IAnyInstitutionProfile, activeUserInstitutionProfile: IAnyInstitutionProfile) {
        const actingOnBehalfOfProfileRank = rankProfileLevel(actingOnBehalfOfProfile.profileType);

        this.activeProfileHistory$.pipe(take(1)).subscribe(profileHistory => {
            let backToMainProfile = true;
            for (let i = 0; i < profileHistory.length; i++) {
                const historicProfile = profileHistory[i].actingOnBehalfOfProfile;
                // if (historicProfile.profileType === activeUserInstitutionProfile.profileType) {
                //   continue;
                // }
                const historyRank = rankProfileLevel(historicProfile.profileType);
                if (historyRank < actingOnBehalfOfProfileRank) {
                    if (profileHistory[i].route) {
                        this.routerService.Go(profileHistory[i].route);
                    } else {
                        this.profileService.changeToProfile(historicProfile);
                    }
                    backToMainProfile = false;
                    break;
                }
            }

            if (backToMainProfile) {
                const inHistoryProfile = profileHistory.find(history => history.actingOnBehalfOfProfile.institutionProfileId === activeUserInstitutionProfile.institutionProfileId);
                if (inHistoryProfile) {
                    this.routerService.Go(inHistoryProfile.route);
                } else {
                    this.profileService.changeToProfile(activeUserInstitutionProfile);
                }
            }
        });
    }

    // public backToManager() {
    //   // if (this.profileService.managerHome != null) {
    //   //   this.routerService.Go(this.profileService.managerHome.split('/'));
    //   // } else {
    //   //   this.modalService.openModal(ModalChangeProfileComponent);
    //   // }

    // }
    // public backToSuperUserInstitution() {
    //   this.userAccount$.pipe(take(1)).subscribe(user => {
    //     this.routerService.Go([user.superuserId, 'superuser', this.routerService.institutionId, this.routerService.loggedInInstitutionProfileId, 'institution']);
    //   });
    // }

    public backToSuperUser() {
        this.userAccount$.pipe(take(1)).subscribe(user => {
            this.routerService.Go([user.userAccountId, user.superuserId, 'superuser']);
        });
    }

    public changeHierarchy() {
        this.modalService.openModal(ModalChangeHierarchyComponent);
    }

    public loadInteractions() {
        this.modalService.openModal(ModalInteractionComponent);
    }

    public globalSearch() {
        this.modalService.openModal(ModalGlobalSearchComponent);
    }

    public changeInstitution() {
        this.modalService.openModal(ModalChangeInstitutionComponent);
    }

    public changeFamilyInstitution() {
        this.routerService.Go(['user', this.routerService.userAccountId, 'profile-selection']);
    }

    public changeSystemManagerProgram(profile: ISystemManagerInstitutionProfile) {
        const programs = Object.keys(profile.acl);
        this.modalService.openModal(ModalSelectProgramComponent, {
            data: {
                institutionId: profile.institutionId,
                programIds: programs
            }
        }).pipe(take(1), filter(programId => programId != null)).subscribe(programId => {
            this.routerService.Go([this.routerService.userAccountId, this.routerService.institutionId, this.routerService.loggedInInstitutionProfileId, 'admin', programId, 'system-manager', this.routerService.systemManagerId]);
        });
    }

    public changeManagerProgram(profile: IManagerInstitutionProfile) {
        const programs = Object.keys(profile.managerPrograms);
        this.modalService.openModal(ModalSelectProgramComponent, {
            data: {
                institutionId: profile.institutionId,
                programIds: programs
            }
        }).pipe(take(1), filter(programId => programId != null)).subscribe(programId => {
            this.routerService.Go([this.routerService.userAccountId, this.routerService.institutionId, this.routerService.loggedInInstitutionProfileId, 'admin', programId, 'manager', this.routerService.managerId, this.routerService.managerHierarchyId]);
        });
    }

    public superuserClickedManagerName() {
        this.modalService.openModal(ModalSuperuserPermissionsComponent);
    }

    public superuserClickedSystemManagerName() {
        this.modalService.openModal(ModalSuperuserPermissionsComponent);
    }

    public asSystemManager(val: IAnyInstitutionProfile): ISystemManagerInstitutionProfile {
        return val as ISystemManagerInstitutionProfile;
    }

    public asManager(val: IAnyInstitutionProfile): IManagerInstitutionProfile {
        return val as IManagerInstitutionProfile;
    }

    public contactUs() {
        window.location.href = 'mailto:support@4honline.com';
    }
}
