import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DebugService {

  public debugMode: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private httpClient: HttpClient) {
  }

  public getRequest(url: string) {
    return this.httpClient.get(url);
  }

  public postRequest(url: string, body: object) {
    return this.httpClient.post(url, body);
  }

  public putRequest(url: string, body: object) {
    return this.httpClient.put(url, body);
  }

  public patchRequest(url: string, body: object) {
    return this.httpClient.patch(url, body);
  }

  public deleteRequest(url: string, body: object) {
    return this.httpClient.delete(url, body);
  }

  public encodeId(id: string) {
    return this.httpClient.get(`${environment.apiUri}/api/system/id-encode/${id}`, { responseType: 'text' });
  }

  public decodeId(id: string) {
    return this.httpClient.get(`${environment.apiUri}/api/system/id-decode/${id}`, { responseType: 'text' });
  }
}
