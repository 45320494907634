import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IAddressDto } from 'app/core/models';
import { CommonToastrService, ManagerClubsService, RouterService } from 'app/core/services';

@Component({
  selector: 'ng4h-modal-edit-address',
  templateUrl: './modal-edit-address.component.html',
  styleUrls: ['./modal-edit-address.component.scss']
})
export class ModalEditAddressComponent implements OnInit {

  public form: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) private address: IAddressDto,
    private fb: FormBuilder,
    private routerService: RouterService,
    private managerUnitsService: ManagerClubsService,
    private toastrService: CommonToastrService
  ) { }

  public ngOnInit() {

    this.form = this.fb.group({
      addressLine1: [this.address.addressLine1],
      addressLine2: [this.address.addressLine2],
      city: [this.address.city],
      postalCode: [this.address.postalCode],
      country: [this.address.country],
      state: [this.address.state]
    });
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public save(): void {
    this.dialogRef.close(this.form.value);
  }
}
