import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  eUnitType,
  IEnrollmentDto,
  IEnrollmentProjectDto,
  IEnrollmentUnitDto,
  IHierarchyCountyArea,
  IProgramSettingsDto,
  IProjectDto,
} from 'app/core/models';
import {
  ClubsService,
  CommonToastrService,
  EnrollmentService,
  HierarchyService,
  MemberService,
  ModalService,
  ProfileService,
  ProgramSettingsService,
  ProjectsService,
  RouterService,
} from 'app/core/services';
import { combineLatest, filter, map, Observable, of, switchMap, take } from 'rxjs';

@Component({
  selector: 'ng4h-modal-change-project-club',
  templateUrl: './modal-change-project-club.component.html',
  styleUrls: ['./modal-change-project-club.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalChangeProjectClubComponent implements OnInit {

  public eUnitType = eUnitType;
  private enrollment$: Observable<IEnrollmentDto>;

  public remainingUnits$: Observable<IEnrollmentUnitDto[]>;
  public projectTransfers$: Observable<{
    transferableProjects: IEnrollmentProjectDto[]
    nonTransferableProjects: IEnrollmentProjectDto[]
  }>;
  private targetUnitProjects$: Observable<IProjectDto[]>;
  public form: FormGroup;
  public programSettings$: Observable<IProgramSettingsDto>;
  public isManager$: Observable<boolean>;

  public allUnits$: Observable<IEnrollmentUnitDto[]>;

  constructor(
    private dialogRef: MatDialogRef<any>,
    private routerService: RouterService,
    private enrollmentService: EnrollmentService,
    private fb: FormBuilder,
    private projectsService: ProjectsService,
    private modalService: ModalService,
    private toastrService: CommonToastrService,
    private clubsService: ClubsService,
    private programSettingsService: ProgramSettingsService,
    private hierarchyService: HierarchyService,
    private memberService: MemberService,
    private profileService: ProfileService,
    @Inject(MAT_DIALOG_DATA) public unit: IEnrollmentUnitDto
  ) { }

  ngOnInit(): void {

    this.programSettings$ = this.programSettingsService.getProgramSettings({
      institutionId: this.routerService.institutionId,
      programId: this.routerService.programId
    });
    this.isManager$ = this.profileService.activeUserInstitutionProfileIsManager$;

    this.isManager$.pipe(take(1)).subscribe(isManager => {


      this.memberService.getMember({
        institutionId: this.routerService.institutionId,
        memberId: this.routerService.memberId ? this.routerService.memberId : this.routerService.selectedMemberId,
      }).pipe(
        filter(member => member != null),
        take(1),
        map(member => member.families.find(family => family.primary).countyAreaHierarchyNodeId),
        switchMap(countyAreaHierarchyNodeId => this.hierarchyService.getHierarchyNode({ institutionId: this.routerService.institutionId, hierarchyNodeId: countyAreaHierarchyNodeId })),
        filter(node => node != null),
        take(1),
        map((node: IHierarchyCountyArea) => node.countyAreaId)
      ).subscribe(countyAreaId => {
        this.programSettings$.pipe(take(1)).subscribe(programSettings => {
          this.form = this.fb.group({
            sourceUnitId: [{ value: this.unit.unitId, disabled: true }],
            targetUnitId: [],
            selectedCountyAreaId: [{ value: countyAreaId, disabled: programSettings.member_RestrictClubSelectionToFamilyCounty === true && isManager === false }]
          });
        });
      });
    });
    this.enrollment$ = this.enrollmentService.getEnrollment({
      institutionId: this.routerService.institutionId,
      memberId: this.routerService.memberId ? this.routerService.memberId : this.routerService.selectedMemberId,
      programId: this.routerService.programId,
      enrollmentId: this.routerService.enrollmentId
    });


    this.allUnits$ = this.form.controls.selectedCountyAreaId.valueChanges.pipe(
      switchMap(countyAreaId => {
        return this.clubsService.getClubs({
          institutionId: this.routerService.institutionId,
          memberId: this.routerService.memberId ? this.routerService.memberId : this.routerService.selectedMemberId,
          programId: this.routerService.programId,
          enrollmentId: this.routerService.enrollmentId,
          countyAreaId
        });
      })
    );

    this.remainingUnits$ = combineLatest([this.enrollment$.pipe(map(e => e.units)), this.allUnits$]).pipe(
      map(([enrollmentUnits, allUnits]) => {
        const remaining = allUnits.filter(unit => {
          const found = enrollmentUnits.find(enrollmentUnit => {
            return enrollmentUnit.unitId === unit.unitId;
          });
          return found == null;
        });
        return remaining;

      })
    );

    this.targetUnitProjects$ = this.form.controls.targetUnitId.valueChanges.pipe(
      switchMap(targetUnitId => {
        if (targetUnitId == null) {
          return of(null);
        }
        return this.projectsService.getProjectsByClub({
          institutionId: this.routerService.institutionId,
          memberId: this.routerService.memberId ? this.routerService.memberId : this.routerService.selectedMemberId,
          programId: this.routerService.programId,
          enrollmentId: this.routerService.enrollmentId,
          unitId: targetUnitId
        });
      })
    );

    this.projectTransfers$ = combineLatest(
      [
        this.enrollment$,
        this.form.controls.targetUnitId.valueChanges,
        this.targetUnitProjects$
      ]
    ).pipe(
      map(([enrollment, targetUnitId, targetUnitProjects]) => {
        if (enrollment == null || targetUnitId == null || targetUnitProjects == null) {
          return null;
        }


        const sourceProjects = enrollment.projects.filter(project => project.unitId === this.unit.unitId);


        const transferableProjects: IEnrollmentProjectDto[] = [];
        const nonTransferableProjects: IEnrollmentProjectDto[] = [];
        sourceProjects.forEach(sourceProject => {
          if (targetUnitProjects.find(targetProject => targetProject.id === sourceProject.projectId) == null) {
            nonTransferableProjects.push(sourceProject);
          } else {
            transferableProjects.push(sourceProject);
          }
        });

        return {
          transferableProjects,
          nonTransferableProjects
        };


      })
    );
  }

  public close(res?: any) {
    this.dialogRef.close(res);
  }

  public save() {

    const formValue = this.form.getRawValue();

    this.enrollmentService.swapUnits({
      institutionId: this.routerService.institutionId,
      memberId: this.routerService.memberId ? this.routerService.memberId : this.routerService.selectedMemberId,
      programId: this.routerService.programId,
      enrollmentId: this.routerService.enrollmentId,
      unitChange: {
        sourceUnitId: formValue.sourceUnitId,
        targetUnitId: formValue.targetUnitId,
      }
    }).pipe(
      take(1),
      switchMap(() => this.enrollmentService.refreshEnrollment()),
      take(1),
      this.modalService.showProgressSpinnerModalUntilComplete(),
    ).subscribe({
      next: () => {
        this.close();
        this.toastrService.success('Saved');
      }, error: (error) => {
        this.toastrService.error('Could not save.', error);
      }
    });


  }
}
