import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import {
    IInstitutionReportConsumerParams,
    IReportConsumerCreateDto,
    IReportConsumerInstitutionProfile,
    IReportConsumerUpdateDto,
    ISuperuserInstitutionParams,
} from '../../models';

export const GetReportConsumerAction = createAction(
    '[report-consumer] Get',
    props<IInstitutionReportConsumerParams>()
);
export const GetReportConsumerSuccessAction = createAction(
    '[report-consumer] Get success',
    props<IInstitutionReportConsumerParams & { reportConsumer: IReportConsumerInstitutionProfile }>()
);
export const GetReportConsumerErrorAction = createAction(
    '[report-consumer] Get error',
    props<{ error: HttpErrorResponse }>()
);
export const CreateReportConsumerAction = createAction(
    '[report-consumer] Create',
    props<ISuperuserInstitutionParams & { reportConsumerCreateDto: IReportConsumerCreateDto }>()
);
export const CreateReportConsumerSuccessAction = createAction(
    '[report-consumer] Create success',
    props<ISuperuserInstitutionParams & { reportConsumerCreateDto: IReportConsumerCreateDto }>()
);
export const CreateReportConsumerErrorAction = createAction(
    '[report-consumer] Create error',
    props<{ error: HttpErrorResponse }>()
);

export const GetReportConsumersAction = createAction(
    '[report-consumer] Get report consumers',
    props<ISuperuserInstitutionParams>()
);
export const GetReportConsumersSuccessAction = createAction(
    '[report-consumer] Get report consumers success',
    props<ISuperuserInstitutionParams & { reportConsumers: IReportConsumerInstitutionProfile[] }>()
);
export const GetReportConsumersErrorAction = createAction(
    '[report-consumer] Get report consumers error',
    props<{ error: HttpErrorResponse }>()
);

export const UpdateReportConsumerAction = createAction(
    '[report-consumer] Update report consumer',
    props<IInstitutionReportConsumerParams & { reportConsumerUpdate: IReportConsumerUpdateDto }>()
);
export const UpdateReportConsumerSuccessAction = createAction(
    '[report-consumer] Update report consumer success',
    props<IInstitutionReportConsumerParams & { reportConsumerUpdate: IReportConsumerUpdateDto }>()
);
export const UpdateReportConsumerErrorAction = createAction(
    '[report-consumer] Update report consumer error',
    props<{ error: HttpErrorResponse }>()
);

export const DeleteReportConsumerAction = createAction(
    '[report-consumer] Delete report consumer',
    props<IInstitutionReportConsumerParams>()
);
export const DeleteReportConsumerSuccessAction = createAction(
    '[report-consumer] Delete report consumer success',
    props<IInstitutionReportConsumerParams>()
);
export const DeleteReportConsumerErrorAction = createAction(
    '[report-consumer] Delete report consumer error',
    props<{ error: HttpErrorResponse }>()
);

export const ReinstateReportConsumerAction = createAction(
    '[report-consumer] Reinstate report consumer',
    props<IInstitutionReportConsumerParams>()
);
export const ReinstateReportConsumerSuccessAction = createAction(
    '[report-consumer] Reinstate report consumer success',
    props<IInstitutionReportConsumerParams>()
);
export const ReinstateReportConsumerErrorAction = createAction(
    '[report-consumer] Reinstate report consumer error',
    props<{ error: HttpErrorResponse }>()
);