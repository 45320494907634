<div class="modal-header">
    <div class="modal-title">Select Program</div>
    <button (click)="close()" type="button" class="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-12">
            <a *ngFor="let program of programsToDisplay$ | async" (click)="programSelected(program)"
                class="ng4h-list-row">
                {{program.programId | program$: data.institutionId | async}}
            </a>
        </div>
    </div>
</div>