import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { eCharterStatusType, IClubCharterDto, IUnitDto, IUnitProgramYearDto } from 'app/core/models';
import { CommonToastrService, ManagerClubsService, RouterService } from 'app/core/services';
import { removeNulls } from 'app/shared/utils';
import { take } from 'rxjs';

@Component({
  selector: 'ng4h-modal-edit-club-charter',
  templateUrl: './modal-edit-club-charter.component.html',
  styleUrls: ['./modal-edit-club-charter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalEditClubCharterComponent implements OnInit {

  public form: FormGroup;
  public eCharterStatusType = eCharterStatusType;

  constructor(
    private mangerUnitsService: ManagerClubsService,
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) private data: { charterProgramYear: IUnitProgramYearDto, unit: IUnitDto },
    private fb: FormBuilder,
    private routerService: RouterService,
    private toastrService: CommonToastrService
  ) { }

  ngOnInit() {
    const charter: IClubCharterDto | any = this.data.charterProgramYear.charterInfo ? this.data.charterProgramYear.charterInfo : {};

    this.form = this.fb.group({
      status: [charter.status],
      charterNumber: [charter.charterNumber],
      charteredDate: [charter.charteredDate],
      provisionalDate: [charter.provisionalDate],
      probationalDate: [charter.probationalDate],
      revokedDate: [charter.revokedDate],
      renewalDate: [charter.renewalDate],
      inactiveDate: [charter.inactiveDate],
      closedDate: [charter.closedDate],
      charteringComment: [charter.charteringComment],
    });
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public save(): void {

    this.mangerUnitsService.updateClubChartering({
      institutionId: this.routerService.institutionId,
      managerId: this.routerService.managerId,
      programId: this.routerService.programId,
      programYearId: this.data.charterProgramYear.programYearId,
      unitId: this.data.unit.unitId,
      club: removeNulls(this.form.value)
    }).pipe(take(1)).subscribe({
      next: () => {
        this.toastrService.success('Chartering updated');
        this.cancel();
      }, error: error => {
        this.toastrService.error('Could not update chartering', error);
      }
    });


  }
}
