import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';

import { IInstitution } from 'app/core/models';
import { InstitutionService } from 'app/core/services';

@Component({
  selector: 'ng4h-institution-dropdown',
  templateUrl: './institution-dropdown.component.html',
  styleUrls: ['./institution-dropdown.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InstitutionDropdownComponent),
    multi: true
  }]
})
export class InstitutionDropdownComponent implements ControlValueAccessor {

  // @Input for ngModel otherwise writeValue is called with default for reactive forms
  @Input() value: string;
  public institutions: Observable<IInstitution[]>;

  onChange: any = () => { };
  onTouched: any = () => { };

  constructor(public institutionService: InstitutionService) {
    this.institutions = this.institutionService.institutions$;
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  writeValue(value) {
    if (value != null) {
      this.value = value;
      this.onChange(value);
      this.onTouched();
    }
  }

}
