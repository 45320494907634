import { eAccessControlListRoleType } from './enums';
import { IManagerPermissions } from './IManagerPermissions';

export interface IHierarchyManager {
    email: string;
    firstName: string;
    lastName: string;
    managerId: string;
    // role: eEnrollmentRoleType;
    programPermissions: {
        [programId: string]: {
            acl: {
                permissionRole: eAccessControlListRoleType,
                grantedPermissions: IManagerPermissions
            },
            primary?: boolean
        }
    };
}
