import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IProjectDto } from 'app/core/models';
import { ManagerProjectsService, ProgramYearService, RouterService } from 'app/core/services';
import { filter, Observable, switchMap, take } from 'rxjs';

@Component({
  selector: 'ng4h-project-dropdown',
  templateUrl: './project-dropdown.component.html',
  styleUrls: ['./project-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ProjectDropdownComponent),
    multi: true
  }]
})
export class ProjectDropdownComponent implements ControlValueAccessor {

  public availableProjects$: Observable<IProjectDto[]>;
  @Input() value: IProjectDto = null;
  @Input() disabled: boolean = false;


  onChange: any = () => { };
  onTouched: any = () => { };

  constructor(
    private routerService: RouterService,
    private programYearService: ProgramYearService,
    private projectsService: ManagerProjectsService) {
    this.availableProjects$ = this.programYearService.getCurrentProgramYear({ institutionId: this.routerService.institutionId, programId: this.routerService.programId }).pipe(
      filter(currentProgramYear => currentProgramYear != null),
      take(1),
      switchMap(currentProgramYear => {
        return this.projectsService.getProgramYearHierarchyProjects({
          institutionId: this.routerService.institutionId,
          managerId: this.routerService.managerId,
          programId: this.routerService.programId,
          hierarchyNodeId: this.routerService.managerHierarchyId,
          programYearId: currentProgramYear.programYearId
        });
      }),
      take(1)
    );

  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  writeValue(value) {
    if (value != null) {
      this.value = value;
      this.onChange(value);
      this.onTouched();
    }
  }
}
