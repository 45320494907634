<div class="modal-header">
  <h5 class="modal-title">Add {{(eUnitType.Club | programAlias$ | async)?.singular}}</h5>
  <button (click)="close()" type="button" class="close" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form [formGroup]="form">
    <!-- <div class="form-group row">
      <label class="form-control-label col-md-3">Is {{(eUnitType.Club | programAlias$ | async)?.singular}}</label>
      <div class="col-md-9">
        <mat-checkbox formControlName="isClub"></mat-checkbox>
      </div>
    </div> -->
    <!-- <div class="form-group row">
      <label class="form-control-label col-md-3">Charge Fee</label>
      <div class="col-md-9">
        <mat-checkbox formControlName="chargeFee"></mat-checkbox>
      </div>
    </div> -->
    <div class="form-group row">
      <label class="form-control-label col-md-3 ng4h-required">{{(eUnitType.Club | programAlias$ | async)?.singular}}
        Name</label>
      <div class="col-md-9">
        <input formControlName="unitName" type="text" class="form-control">
      </div>
    </div>
    <div class="form-group row">
      <label class="form-control-label col-md-3 ng4h-required">County</label>
      <div class="col-md-9">
        <ng4h-county-area-select [onlyIncludeUnderHierarchyId]="managerHierarchyId$ | async"
          formControlName="countyAreaId">
        </ng4h-county-area-select>
      </div>
    </div>
    <div class="form-group row">
      <div class="form-control-label col-md-3">Delivery Mode</div>
      <div class="col-md-9">
        <ng4h-delivery-mode-dropdown formControlName="deliveryModeId"></ng4h-delivery-mode-dropdown>
      </div>
    </div>
    <div class="form-group row">
      <label class="form-control-label col-md-3">EIN</label>
      <div class="col-md-9">
        <input formControlName="ein" type="text" class="form-control">
      </div>
    </div>
    <div class="form-group row">
      <div class="form-control-label col-md-3">Meeting Location</div>
      <div class="col-md-9">
        <input formControlName="meetingLocation" type="text" class="form-control">
      </div>
    </div>


    <div class="form-group row">
      <h6 class="form-control-label col-md-3"><b>Contact Address</b></h6>
      <div class="col-md-9">
        <ng4h-address-input [form]="contactAddress"></ng4h-address-input>
      </div>
    </div>

    <div class="form-group row">
      <h6 class="form-control-label col-md-3"><b>Meeting Address</b></h6>
      <div class="col-md-9">
        <ng4h-address-input [form]="meetingAddress"></ng4h-address-input>
      </div>
    </div>


  </form>
</div>

<div class="modal-footer">
  <button [disabled]="form.invalid" (click)="add()" class="btn btn-primary my-2 my-sm-0" role="button"
    aria-pressed="true">Add</button>
</div>