import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AutoUnsubscribe } from 'app/core/models';

@Component({
  selector: 'ng4h-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginatorComponent extends AutoUnsubscribe implements OnInit {

  @Input() resultCount: number;
  @Input() pageCount: number;
  @Input() pageIndex: number;
  @Input() startIndex: number;
  @Input() endIndex: number;

  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

  public forwardDisabled: boolean;
  public backDisabled: boolean;

  ngOnInit() {

  }

  public back(): void {
    this.pageIndex--;
    this.pageChange.emit(this.pageIndex);
  }

  public forward(): void {
    this.pageIndex++;
    this.pageChange.emit(this.pageIndex);
  }


}
