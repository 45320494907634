import { Action, createReducer, on } from '@ngrx/store';

import { PublicActions } from '.';
import { EventMemberActions } from '../../../shared/events-member';
import { mergeImmutable } from '../../../shared/utils';
import { IEventDto, IFamilyEventDto, IInstitutionFamilyModel } from '../../models';

export interface PublicState {
    publicEvent: { [institutionId: string]: { [eventId: string]: IEventDto } };
    eventsEligibility: IInstitutionFamilyModel<{ [eventId: string]: IFamilyEventDto }>;
}

const initialState: PublicState = {
    publicEvent: undefined,
    eventsEligibility: undefined,
}

const reducer = createReducer(
    initialState,
    on(PublicActions.PublicLoadEventSuccessAction, (state, { institutionId, eventId, event }) => {
        const newEvent = mergeImmutable(
            { [institutionId]: { [eventId]: event } },
            state.publicEvent
        );
        return {
            ...state,
            publicEvent: newEvent
        }
    }),
    on(PublicActions.PublicLoadEventEligibilitySuccessAction, (state, { institutionId, familyId, eventId, events }) => {
        const newEventsEligibility = mergeImmutable(
            { [institutionId]: { [familyId]: { [eventId]: events } } },
            state.eventsEligibility
        );
        return {
            ...state,
            eventsEligibility: newEventsEligibility
        };
    }),
    on(PublicActions.MemberCreateSuccessAction,
        PublicActions.MemberCreateErrorAction,
        EventMemberActions.EventsMemberSubmitSuccessAction,
        EventMemberActions.EventsMemberRegisterForEventSuccessAction,
        (state, { }) => {
            return {
                ...state,
                eventsEligibility: null
            };
        })
);

export function publicReducer(state: PublicState | undefined, actions: Action) {
    return reducer(state, actions);
}

export const publicEvent = (state: PublicState) => state && state.publicEvent;
export const familyEventEligibility = (state: PublicState) => state && state.eventsEligibility;