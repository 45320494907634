<div class="modal-section">
  <div class="modal-header">
    <h5 class="modal-title">Edit Address</h5>
    <button (click)="cancel()" type="button" class="close" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="form">
      <div class="form-group col-sm-12 row">
        <label class="form-control-label col-6">Address Line 1</label>
        <input formControlName="addressLine1" class="form-control col-sm-6">
      </div>
      <div class="form-group col-sm-12 row">
        <label class="form-control-label col-6">Address Line 2</label>
        <input formControlName="addressLine2" class="form-control col-sm-6">
      </div>

      <div class="form-group col-sm-12 row">
        <label class="form-control-label col-6">City</label>
        <input formControlName="city" class="form-control col-sm-6">
      </div>

      <div class="form-group col-sm-12 row">
        <label class="form-control-label col-6">Country</label>
        <input formControlName="country" class="form-control col-sm-6">
      </div>

      <div class="form-group col-sm-12 row">
        <label class="form-control-label col-6">Postal Code</label>
        <input formControlName="postalCode" class="form-control col-sm-6">
      </div>

      <div class="form-group col-sm-12 row">
        <label class="form-control-label col-6">State</label>
        <input formControlName="state" class="form-control col-sm-6">
      </div>

    </form>
  </div>

  <div class="modal-footer" *ngIf="form">
    <button id (click)="cancel()" class="btn btn-secondary my-2 my-sm-0" role="button"
      aria-pressed="true">Cancel</button>
    <button [disabled]="form.invalid" (click)="save()" class="btn btn-primary my-2 my-sm-0" role="button"
      aria-pressed="true">Save</button>
  </div>
</div>
