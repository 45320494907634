<div class="modal-header">
  <h5 class="modal-title">Verify Email</h5>
  <button (click)="close()" type="button" class="close" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form" [ngClass]="wasValidated ? 'was-validated' : 'needs-validation'" novalidate>
    <div class="form-group col-sm-12">
      <label class="form-control-label">Email</label>
      <input class="form-control" formControlName="email" type="email">
      <div class="alert alert-danger" role="alert" *ngIf="errorMessage">{{errorMessage}}</div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" (click)="add()">Continue</button>
</div>
