import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { HierarchyTreeComponent } from '../hierarchy-tree/hierarchy-tree.component';

@Component({
  selector: 'ng4h-hierarchy-tree-overlay',
  templateUrl: './hierarchy-tree-overlay.component.html',
  styleUrls: ['./hierarchy-tree-overlay.component.scss']
})
export class HierarchyTreeOverlayComponent implements OnInit {

  @ViewChild('menuButton', { static: true }) menuButton: ElementRef;

  constructor(private overlay: Overlay) { }

  ngOnInit() {
  }

  public open() {
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo(this.menuButton
      ).withPositions([{
        overlayX: 'start',
        overlayY: 'top',
        originX: 'start',
        originY: 'bottom'
      }]);
    const overlayRef = this.overlay.create({
      panelClass: ['hierarchy-tree-overlay'],
      positionStrategy
    });
    const hierarchyTreePortal = new ComponentPortal(HierarchyTreeComponent);
    overlayRef.attach(hierarchyTreePortal);
  }
}
