<div class="modal-header">
  <h5 class="modal-title">Edit Family</h5>
  <button (click)="close()" type="button" class="close" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form *ngIf="form != null" [formGroup]="form">
    <div class="form-group row">
      <label class="form-control-label col-md-3 ng4h-required">Family Name</label>
      <div class="col-md-9">
        <input formControlName="familyName" class="form-control" type="text">
      </div>
    </div>
    <div class="form-group row">
      <label class="form-control-label col-md-3"
        [ngClass]="{'ng4h-required': (isManger$ | async) === false}">Email</label>
      <div class="col-md-9">
        <input formControlName="email" class="form-control" type="text">
      </div>
    </div>

    <div class="offset-3 col-md-9 alert alert-danger" *ngIf="duplicateFamily != null">
      <b>Duplicate Family</b>
      <div>
        <a target="_blank"
          [routerLink]="['/', routerService.userAccountId, routerService.institutionId, routerService.loggedInInstitutionProfileId, 'family', duplicateFamily.familyId, 'profile']">Go
          To Duplicate Family:
          {{duplicateFamily.familyName}}</a>
      </div>

    </div>



    <div class="form-group row">
      <label class="form-control-label col-md-3 ng4h-required">Mobile Phone</label>
      <div class="col-md-9">
        <input formControlName="primaryMobilePhone" type="tel" class="form-control" [maxLength]="12" type="tel"
          ng4hPhoneMask>
        <small class="form-text text-muted">Format ###-###-#### </small>
        <div *ngIf="form.controls['primaryMobilePhone'].errors">
          <small class="text-danger">
            Invalid phone format
          </small>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="form-control-label col-md-3 ng4h-required">County</label>
      <div class="col-md-9">
        <select class="form-control" formControlName="countyId">
          <option *ngFor="let county of counties$ | async" [value]="county.countyId">{{county.countyName}}</option>
        </select>
      </div>
    </div>
    <ng-container *ngIf="countyAreas$ | async as countyAreas">
      <div *ngIf="countyAreas?.length > 0" class="form-group row">
        <label class="form-control-label col-md-3 ng4h-required">County Area</label>
        <div class="col-md-9">
          <select class="form-control" formControlName="countyAreaId">
            <option *ngFor="let countyArea of countyAreas" [value]="countyArea.countyAreaId">
              {{countyArea.countyAreaName}}
            </option>
          </select>
        </div>
      </div>
    </ng-container>


  </form>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" [disabled]="form?.invalid" (click)="save()">Save</button>
  <button class="btn btn-secondary" (click)="close()">Cancel</button>
</div>