import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { SystemManagerFinanceActions } from 'app/core/containers/admin/system-manager/store/finances';
import {
  getSystemManagerFundById,
  getSystemManagerFunds,
  SystemManagerFeatureStore
} from 'app/core/containers/admin/system-manager/system-manager-feature.reducers';
import {
  IDiscountFundCreateDto,
  IDiscountFundDescriptorDto,
  IDiscountFundUpdateDto,
  IInstitutionSystemManagerProgramParams
} from 'app/core/models';
import { filter, map, Observable, of, take, tap } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SystemManagerFinancesService {

  constructor(private store: Store<SystemManagerFeatureStore>, private httpClient: HttpClient, private dispatcher: ActionsSubject) {
  }

  public getFunds(params: IInstitutionSystemManagerProgramParams) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }

    return this.store.pipe(
      select(getSystemManagerFunds(params)),
      tap(funds => {
        if (funds === undefined) {
          this.store.dispatch(SystemManagerFinanceActions.SystemManagerGetFundsAction(params));
        }
      }),
      filter(funds => funds != null)
    );
  }

  public getFundsEffect({ institutionId, systemManagerId, programId }: IInstitutionSystemManagerProgramParams) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/discount-funds`) as Observable<IDiscountFundDescriptorDto>;
  }

  public getFundById(params: IInstitutionSystemManagerProgramParams & { discountFundId: string }) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }

    return this.store.pipe(
      select(getSystemManagerFundById(params)),
      tap(fund => {
        if (fund === undefined) {
          this.store.dispatch(SystemManagerFinanceActions.SystemManagerGetFundByIdAction(params));
        }
      }),
      filter(fund => fund != null)
    );
  }

  public getFundByIdEffect({
    institutionId,
    systemManagerId,
    programId,
    discountFundId
  }: IInstitutionSystemManagerProgramParams & { discountFundId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/discount-funds/${discountFundId}`) as Observable<IDiscountFundDescriptorDto>;
  }

  public addFund(params: IInstitutionSystemManagerProgramParams & { fund: IDiscountFundCreateDto }) {
    this.store.dispatch(SystemManagerFinanceActions.SystemManagerAddFundAction(params));
  }

  public addFundEffect({
    institutionId,
    systemManagerId,
    programId,
    fund
  }: IInstitutionSystemManagerProgramParams & { fund: IDiscountFundCreateDto }) {
    return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/discount-funds`, fund);
  }

  public updateFund(params: IInstitutionSystemManagerProgramParams & { discountFundId: string, fund: IDiscountFundUpdateDto }) {
    this.store.dispatch(SystemManagerFinanceActions.SystemManagerUpdateFundAction(params));

    return this.dispatcher.pipe(
      ofType(SystemManagerFinanceActions.SystemManagerUpdateFundSuccessAction, SystemManagerFinanceActions.SystemManagerUpdateFundErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerFinanceActions.SystemManagerUpdateFundSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );

  }

  public updateFundEffect({
    institutionId,
    systemManagerId,
    programId,
    discountFundId,
    fund
  }: IInstitutionSystemManagerProgramParams & { discountFundId: string, fund: IDiscountFundUpdateDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/discount-funds/${discountFundId}`, fund);
  }

  public deleteFund(params: IInstitutionSystemManagerProgramParams & { discountFundId: string }) {
    this.store.dispatch(SystemManagerFinanceActions.SystemManagerDeleteFundAction(params));

    return this.dispatcher.pipe(
      ofType(SystemManagerFinanceActions.SystemManagerDeleteFundSuccessAction, SystemManagerFinanceActions.SystemManagerDeleteFundErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerFinanceActions.SystemManagerDeleteFundSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteFundEffect({
    institutionId,
    systemManagerId,
    programId,
    discountFundId
  }: IInstitutionSystemManagerProgramParams & { discountFundId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/discount-funds/${discountFundId}`);
  }
}
