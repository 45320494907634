import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  IBulkDownloadCreateDto,
  IConsentTypeDto,
  ICreditDto,
  ICreditsCollectionDto,
  IEventACLDto,
  IEventCouponDto,
  IEventCreateDto,
  IEventDescriptorDto,
  IEventDiscountCreateDto,
  IEventDiscountUpdateDto,
  IEventDto,
  IEventEmailConfigurationDto,
  IEventGroupEnrollmentCreateDto,
  IEventHierarchyRestrictionUpdateDto, IEventInstructionsUpdateDto,
  IEventRegistrationManagerReviewDto,
  IEventRegistrationSearchParametersDto,
  IEventRegistrationSearchResultsDto,
  IEventRegistrationTypeCreateDto,
  IEventRegistrationTypeUpdateDto,
  IEventSessionCreateDto,
  IEventSessionUpdateDto, IEventStatusUpdateDto,
  IEventUpdateDto,
  IEventVanityDomainCreateDto,
  IEventVanityDomainUpdateDto,
  IInstitutionManagerProgramEventParams,
  IInstitutionManagerProgramHierarchyParams,
  IInstitutionManagerProgramParams,
  IInstitutionMemberProgramParams
} from 'app/core/models';
import {
  IInstitutionMemberProgramEventRegistrationParams
} from 'app/core/models/function-parameters/institution-member-program-event-registration';

export const EventsManagerLoadAction = createAction(
  '[events-manager] Get',
  props<IInstitutionManagerProgramEventParams>()
);
export const EventsManagerLoadSuccessAction = createAction(
  '[events-manager] Get success',
  props<IInstitutionManagerProgramEventParams & { event: IEventDto }>()
);
export const EventsManagerLoadErrorAction = createAction(
  '[events-manager] Get error',
  props<{ error: HttpErrorResponse }>()
);
export const EventsManagerInvalidateEventCacheAction = createAction(
  '[events-manager] Invalidate event cache'
);
export const EventsManagerLoadManyAction = createAction(
  '[events-manager] Get many',
  props<IInstitutionManagerProgramHierarchyParams & { programYearId: string }>()
);
export const EventsManagerLoadManySuccessAction = createAction(
  '[events-manager] Get many success',
  props<IInstitutionManagerProgramHierarchyParams & { programYearId: string, events: IEventDescriptorDto[] }>()
);
export const EventsManagerLoadManyErrorAction = createAction(
  '[events-manager] Get many error',
  props<{ error: HttpErrorResponse }>()
);
export const EventsManagerInvalidateEventsCacheAction = createAction(
  '[events-manager] Invalidate events cache'
);

export const EventsManagerCreateAction = createAction(
  '[events-manager] Create',
  props<IInstitutionManagerProgramHierarchyParams & { eventCreateDto: IEventCreateDto }>()
);
export const EventsManagerCreateSuccessAction = createAction(
  '[events-manager] Create success',
  props<IInstitutionManagerProgramHierarchyParams & { eventCreateDto: IEventCreateDto, event: any }>()
);
export const EventsManagerCreateErrorAction = createAction(
  '[events-manager] Create error',
  props<{ error: HttpErrorResponse }>()
);
export const EventsManagerUpdateAction = createAction(
  '[events-manager] Update',
  props<IInstitutionManagerProgramEventParams & { eventUpdateDto: IEventUpdateDto }>()
);
export const EventsManagerUpdateSuccessAction = createAction(
  '[events-manager] Update success',
  props<IInstitutionManagerProgramEventParams & { eventUpdateDto: IEventUpdateDto, event: any }>()
);
export const EventsManagerUpdateInstructionsAction = createAction(
  '[events-manager] Update instructions',
  props<IInstitutionManagerProgramEventParams & { eventInstructionsUpdateDto: IEventInstructionsUpdateDto }>()
);
export const EventsManagerUpdateInstructionsSuccessAction = createAction(
  '[events-manager] Update instructions success',
  props<IInstitutionManagerProgramEventParams & {
    eventInstructionsUpdateDto: IEventInstructionsUpdateDto,
    event: any
  }>()
);
export const EventsManagerUpdateStatusAction = createAction(
  '[events-manager] Update status',
  props<IInstitutionManagerProgramEventParams & { eventStatusUpdateDto: IEventStatusUpdateDto }>()
);
export const EventsManagerUpdateStatusSuccessAction = createAction(
  '[events-manager] Update status success',
  props<IInstitutionManagerProgramEventParams & {
    eventStatusUpdateDto: IEventStatusUpdateDto,
    event: any
  }>()
);
export const EventsManagerUpdateErrorAction = createAction(
  '[events-manager] Update error',
  props<{ error: HttpErrorResponse }>()
);

export const EventsManagerCreateRegistrationTypeAction = createAction(
  '[events-manager] Create registration type',
  props<IInstitutionManagerProgramEventParams & { eventRegistrationTypeCreateDto: IEventRegistrationTypeCreateDto }>()
);
export const EventsManagerCreateRegistrationTypeSuccessAction = createAction(
  '[events-manager] Create registration type success',
  props<IInstitutionManagerProgramEventParams & { eventRegistrationTypeCreateDto: IEventRegistrationTypeCreateDto }>()
);
export const EventsManagerCreateRegistrationTypeErrorAction = createAction(
  '[events-manager] Create registration type error',
  props<{ error: HttpErrorResponse }>()
);

export const EventsManagerUpdateRegistrationTypeAction = createAction(
  '[events-manager] Update registration type',
  props<IInstitutionManagerProgramEventParams & {
    eventRegistrationTypeId: string,
    eventRegistrationTypeUpdateDto: IEventRegistrationTypeUpdateDto
  }>()
);
export const EventsManagerUpdateRegistrationTypeSuccessAction = createAction(
  '[events-manager] Update registration type success',
  props<IInstitutionManagerProgramEventParams & {
    eventRegistrationTypeId: string,
    eventRegistrationTypeUpdateDto: IEventRegistrationTypeUpdateDto
  }>()
);
export const EventsManagerUpdateRegistrationTypeErrorAction = createAction(
  '[events-manager] Update registration type error',
  props<{ error: HttpErrorResponse }>()
);

// export const EventsManagerLoadSubmittedRegistrationsAction = createAction(
//     '[events-manager] Load submitted event registrations',
//     props<IInstitutionManagerProgramHierarchyParams>()
// );
// export const EventsManagerLoadSubmittedRegistrationsSuccessAction = createAction(
//     '[events-manager] Load submitted event registrations success',
//     props<IInstitutionManagerProgramHierarchyParams & { submittedEventRegistrations: any[] }>()
// );
// export const EventsManagerLoadSubmittedRegistrationsErrorAction = createAction(
//     '[events-manager] Load submitted event registrations error',
//     props<{ error: HttpErrorResponse }>()
// );
export const EventsManagerInvalidateEventRegistrationsCacheAction = createAction(
  '[events-manager] Invalidate submitted event registrations'
);

export const EventsManagerLoadEventRegistrations = createAction(
  '[events-manager] Load Event Registrations',
  props<IInstitutionManagerProgramEventParams & { pagesize: string, pageindex: string }>()
);
export const EventsManagerLoadEventRegistrationsSuccess = createAction(
  '[events-manager] Load Event Registrations success',
  props<IInstitutionManagerProgramEventParams & {
    pagesize: string,
    pageindex: string,
    eventRegistrations: IEventRegistrationSearchResultsDto
  }>()
);
export const EventsManagerLoadEventRegistrationsError = createAction(
  '[events-manager] Load Event Registrations error',
  props<{ error?: any }>()
);

export const EventsManagerLoadCrossEventRegistrations = createAction(
  '[events-manager] Load Cross Event Registrations',
  props<IInstitutionManagerProgramHierarchyParams & { pagesize: string, pageindex: string }>()
);
export const EventsManagerLoadCrossEventRegistrationsSuccess = createAction(
  '[events-manager] Load Cross Event Registrations success',
  props<IInstitutionManagerProgramHierarchyParams & {
    pagesize: string,
    pageindex: string,
    eventRegistrations: IEventRegistrationSearchResultsDto
  }>()
);
export const EventsManagerLoadCrossEventRegistrationsError = createAction(
  '[events-manager] Load Cross Event Registrations error',
  props<{ error?: any }>()
);

export const EventsManagerApproveAction = createAction(
  '[events-manager] Approve',
  props<IInstitutionMemberProgramParams & {
    eventRegistrationId: string,
    managerId: string,
    review: IEventRegistrationManagerReviewDto
  }>()
);
export const EventsManagerApproveSuccessAction = createAction(
  '[events-manager] Approve success',
  props<IInstitutionMemberProgramParams & {
    eventRegistrationId: string,
    managerId: string,
    review: IEventRegistrationManagerReviewDto
  }>()
);
export const EventsManagerApproveErrorAction = createAction(
  '[events-manager] Approve error',
  props<{ error: HttpErrorResponse }>()
);

export const EventsManagerSendBackAction = createAction(
  '[events-manager] Send back',
  props<IInstitutionMemberProgramParams & {
    eventRegistrationId: string,
    managerId: string,
    review: IEventRegistrationManagerReviewDto
  }>()
);
export const EventsManagerSendBackSuccessAction = createAction(
  '[events-manager] Send back success',
  props<IInstitutionMemberProgramParams & {
    eventRegistrationId: string,
    managerId: string,
    review: IEventRegistrationManagerReviewDto
  }>()
);
export const EventsManagerSendBackErrorAction = createAction(
  '[events-manager] Send back error',
  props<{ error: HttpErrorResponse }>()
);

export const EventsManagerBlockAction = createAction(
  '[events-manager] Block',
  props<IInstitutionMemberProgramParams & {
    eventRegistrationId: string,
    managerId: string,
    review: IEventRegistrationManagerReviewDto
  }>()
);
export const EventsManagerBlockSuccessAction = createAction(
  '[events-manager] Block success',
  props<IInstitutionMemberProgramParams & {
    eventRegistrationId: string,
    managerId: string,
    review: IEventRegistrationManagerReviewDto
  }>()
);
export const EventsManagerBlockErrorAction = createAction(
  '[events-manager] Block error',
  props<{ error: HttpErrorResponse }>()
);

export const EventsManagerLoadConsentsAction = createAction(
  '[events manager] load event consents',
  props<IInstitutionManagerProgramEventParams & { eventRegistrationTypeId: string }>()
);

export const EventsManagerLoadConsentsSuccessAction = createAction(
  '[events manager] load event consents success',
  props<IInstitutionManagerProgramEventParams & { eventRegistrationTypeId: string, consents: IConsentTypeDto[] }>()
);

export const EventsManagerLoadConsentsErrorAction = createAction(
  '[events manager] load event consents error',
  props<{ error: HttpErrorResponse }>()
);

export const EventsManagerAddConsentAction = createAction(
  '[events manager] add event consent',
  props<IInstitutionManagerProgramEventParams & { eventRegistrationTypeId: string, consentTypeId: string }>()
);

export const EventsManagerAddConsentSuccessAction = createAction(
  '[events manager] add event consent success',
  props<IInstitutionManagerProgramEventParams & { eventRegistrationTypeId: string, consentTypeId: string }>()
);

export const EventsManagerAddConsentErrorAction = createAction(
  '[events manager] add event consent error',
  props<{ error: HttpErrorResponse }>()
);

export const EventManagerInvalidateConsentsAction = createAction(
  '[events manager] invalidate event consents'
);
export const EventsManagerDeleteConsentAction = createAction(
  '[events manager] delete event consent',
  props<IInstitutionManagerProgramEventParams & { eventRegistrationTypeId: string, consentTypeId: string }>()
);

export const EventsManagerDeleteConsentSuccessAction = createAction(
  '[events manager] delete event consent success',
  props<IInstitutionManagerProgramEventParams & { eventRegistrationTypeId: string, consentTypeId: string }>()
);

export const EventsManagerDeleteConsentErrorAction = createAction(
  '[events manager] delete event consent error',
  props<{ error: HttpErrorResponse }>()
);

export const EventsManagerUpdateEventPermissions = createAction(
  '[events manager] update permissions',
  props<IInstitutionManagerProgramEventParams & { targetManagerId: string, permissions: IEventACLDto }>()
);
export const EventsManagerUpdateEventSuccessPermissions = createAction(
  '[events manager] update permissions success',
  props<IInstitutionManagerProgramEventParams & { targetManagerId: string, permissions: IEventACLDto }>()
);
export const EventsManagerUpdateEventErrorPermissions = createAction(
  '[events manager] update permissions error',
  props<{ error: HttpErrorResponse }>()
);
export const RemoveManagerFromEventPermissions = createAction(
  '[events manager] Remove manager from event',
  props<IInstitutionManagerProgramEventParams & { adminManagerId: string }>()
);
export const RemoveManagerFromEventSuccessPermissions = createAction(
  '[events manager] Remove manager from event success',
  props<IInstitutionManagerProgramEventParams & { adminManagerId: string }>()
);
export const RemoveManagerFromEventErrorPermissions = createAction(
  '[events manager] Remove manager from event error',
  props<{ error: HttpErrorResponse }>()
);

export const EventRegistrationSearchAction = createAction(
  '[events-manager] Event Registration search',
  props<IInstitutionManagerProgramEventParams & { searchParams: IEventRegistrationSearchParametersDto }>()
);

export const EventRegistrationSearchSuccessAction = createAction(
  '[events-manager] Event Registration search success',
  props<IInstitutionManagerProgramEventParams & {
    searchParams: IEventRegistrationSearchParametersDto,
    eventRegistrations: IEventRegistrationSearchResultsDto
  }>()
);

export const EventRegistrationSearchErrorAction = createAction(
  '[events-manager] Event Registration search error',
  props<{ error: HttpErrorResponse }>()
);

export const CrossEventRegistrationSearchAction = createAction(
  '[events-manager] Cross Event Registration search',
  props<IInstitutionManagerProgramHierarchyParams & { searchParams: IEventRegistrationSearchParametersDto }>()
);

export const CrossEventRegistrationSearchSuccessAction = createAction(
  '[events-manager] Cross Event Registration search success',
  props<IInstitutionManagerProgramHierarchyParams & {
    searchParams: IEventRegistrationSearchParametersDto,
    eventRegistrations: IEventRegistrationSearchResultsDto
  }>()
);

export const CrossEventRegistrationSearchErrorAction = createAction(
  '[events-manager] Cross Event Registration search error',
  props<{ error: HttpErrorResponse }>()
);

export const EventRegistrationClearSearchAction = createAction(
  '[events-manager] event registration clear search',
  props<IInstitutionManagerProgramParams & { eventId: string }>()
);

export const EventRegistrationClearSearchSuccessAction = createAction(
  '[events-manager] event registration clear search success',
  props<IInstitutionManagerProgramParams & { eventId: string }>()
);

export const EventRegistrationClearSearchErrorAction = createAction(
  '[events-manager] event registration clear search error',
  props<{ error: HttpErrorResponse }>()
);

export const CrossEventRegistrationClearSearchAction = createAction(
  '[events-manager] event cross registration clear search',
  props<IInstitutionManagerProgramHierarchyParams>()
);

export const CrossEventRegistrationClearSearchSuccessAction = createAction(
  '[events-manager] event cross registration clear search success',
  props<IInstitutionManagerProgramHierarchyParams>()
);

export const CrossEventRegistrationClearSearchErrorAction = createAction(
  '[events-manager] event cross registration clear search error',
  props<{ error: HttpErrorResponse }>()
);

export const EventsManagerDeleteEventAction = createAction(
  '[events manager] delete event',
  props<IInstitutionManagerProgramParams & { eventId: string }>()
);

export const EventsManagerDeleteEventSuccessAction = createAction(
  '[events manager] delete event success',
  props<IInstitutionManagerProgramParams & { eventId: string }>()
);

export const EventsManagerDeleteEventErrorAction = createAction(
  '[events manager] delete event error',
  props<{ error: HttpErrorResponse }>()
);

export const EventsManagerDeleteRegistrationTypeAction = createAction(
  '[events manager] delete event registration type',
  props<IInstitutionManagerProgramEventParams & { eventRegistrationTypeId: string }>()
);

export const EventsManagerDeleteRegistrationTypeSuccessAction = createAction(
  '[events manager] delete event success registration type',
  props<IInstitutionManagerProgramEventParams & { eventRegistrationTypeId: string }>()
);

export const EventsManagerDeleteRegistrationTypeErrorAction = createAction(
  '[events manager] delete event error registration type',
  props<{ error: HttpErrorResponse }>()
);

export const EventsManagerCreateSessionAction = createAction(
  '[events manager] create session',
  props<IInstitutionManagerProgramEventParams & { sessionCreateDto: IEventSessionCreateDto }>()
);
export const EventsManagerCreateSessionSuccessAction = createAction(
  '[events manager] create session success',
  props<IInstitutionManagerProgramEventParams & { sessionCreateDto: IEventSessionCreateDto }>()
);
export const EventsManagerCreateSessionErrorAction = createAction(
  '[events manager] create session error',
  props<{ error: HttpErrorResponse }>()
);

export const EventsManagerUpdateSessionAction = createAction(
  '[events manager] update session',
  props<IInstitutionManagerProgramEventParams & { eventSessionId: string, sessionUpdateDto: IEventSessionUpdateDto }>()
);
export const EventsManagerUpdateSessionSuccessAction = createAction(
  '[events manager] update session success',
  props<IInstitutionManagerProgramEventParams & { eventSessionId: string, sessionUpdateDto: IEventSessionUpdateDto }>()
);
export const EventsManagerUpdateSessionErrorAction = createAction(
  '[events manager] update session error',
  props<{ error: HttpErrorResponse }>()
);

export const EventsManagerDeleteSessionAction = createAction(
  '[events manager] delete session',
  props<IInstitutionManagerProgramEventParams & { eventSessionId: string }>()
);
export const EventsManagerDeleteSessionSuccessAction = createAction(
  '[events manager] delete session success',
  props<IInstitutionManagerProgramEventParams & { eventSessionId: string }>()
);
export const EventsManagerDeleteSessionErrorAction = createAction(
  '[events manager] delete session error',
  props<{ error: HttpErrorResponse }>()
);

export const GetOutstandingCreditAction = createAction(
  '[events manager] Get outstanding credits',
  props<IInstitutionManagerProgramEventParams>()
);
export const GetOutstandingCreditSuccessAction = createAction(
  '[events manager] Get outstanding credits success',
  props<IInstitutionManagerProgramEventParams & { outstandingCredits: ICreditDto[] }>()
);
export const GetOutstandingCreditErrorAction = createAction(
  '[events manager] Get outstanding credits error',
  props<{ error: HttpErrorResponse }>()
);
export const GetOutstandingCountyCreditsAction = createAction(
  '[events manager] Get outstanding county credits',
  props<IInstitutionManagerProgramParams & { countyAreaId: string }>()
);
export const GetOutstandingCountyCreditsSuccessAction = createAction(
  '[events manager] Get outstanding county credits success',
  props<IInstitutionManagerProgramParams & { countyAreaId: string, outstandingCredits: ICreditDto[] }>()
);
export const GetOutstandingCountyCreditsErrorAction = createAction(
  '[events manager] Get outstanding county credits error',
  props<{ error: HttpErrorResponse }>()
);

export const ConfirmCreditCollectedAction = createAction(
  '[events manager] Confirm credit collected',
  props<IInstitutionManagerProgramEventParams & { collectedCredits: ICreditsCollectionDto }>()
);
export const ConfirmCreditCollectedSuccessAction = createAction(
  '[events manager] Confirm credit collected success',
  props<IInstitutionManagerProgramEventParams & { collectedCredits: ICreditsCollectionDto }>()
);
export const ConfirmCreditCollectedErrorAction = createAction(
  '[events manager] Confirm credit collected error',
  props<{ error: HttpErrorResponse }>()
);

export const CreateCountyBillForEventAction = createAction(
  '[events manager] Create county bill for event',
  props<IInstitutionManagerProgramEventParams & { countyAreaId: string }>()
);
export const CreateCountyBillForEventSuccessAction = createAction(
  '[events manager] Create county bill for event success',
  props<IInstitutionManagerProgramEventParams & { countyAreaId: string }>()
);
export const CreateCountyBillForEventErrorAction = createAction(
  '[events manager] Create county bill for event error',
  props<{ error: HttpErrorResponse }>()
);

export const GetEventSearchFileDownloadAction = createAction(
  '[events manager] Get event search file download',
  props<IInstitutionManagerProgramEventParams & { bulkDownloadCreate: IBulkDownloadCreateDto }>()
);
export const GetEventSearchFileDownloadSuccessAction = createAction(
  '[events manager] Get event search file download success',
  props<IInstitutionManagerProgramEventParams & { bulkDownloadCreate: IBulkDownloadCreateDto }>()
);
export const GetEventSearchFileDownloadErrorAction = createAction(
  '[events manager] Get event search file download error',
  props<{ error: HttpErrorResponse }>()
);

export const UpdateEventHierarchyRestrictionsAction = createAction(
  '[events manager] Update hierarchy Restrictions',
  props<IInstitutionManagerProgramEventParams & { restrictionUpdate: IEventHierarchyRestrictionUpdateDto }>()
);
export const UpdateEventHierarchyRestrictionsSuccessAction = createAction(
  '[events manager] Update hierarchy Restrictions success',
  props<IInstitutionManagerProgramEventParams & { restrictionUpdate: IEventHierarchyRestrictionUpdateDto }>()
);
export const UpdateEventHierarchyRestrictionsErrorAction = createAction(
  '[events manager] Update hierarchy Restrictions error',
  props<{ error: HttpErrorResponse }>()
);

export const CreateCountyBillFromCreditsAction = createAction(
  '[events manager] Create county bill from credits',
  props<IInstitutionManagerProgramParams & { countyAreaId: string, eventId: string, invoiceDiscounts: string[] }>()
);
export const CreateCountyBillFromCreditsSuccessAction = createAction(
  '[events manager] Create county bill from credits success',
  props<IInstitutionManagerProgramParams & { countyAreaId: string, eventId: string, invoiceDiscounts: string[] }>()
);
export const CreateCountyBillFromCreditsErrorAction = createAction(
  '[events manager] Create county bill from credits error',
  props<{ error: HttpErrorResponse }>()
);

export const RefundPaymentAction = createAction(
  '[events manager] Refund payment',
  props<IInstitutionMemberProgramEventRegistrationParams & { managerId: string }>()
);
export const RefundPaymentSuccessAction = createAction(
  '[events manager] Refund payment success',
  props<IInstitutionMemberProgramEventRegistrationParams & { managerId: string }>()
);
export const RefundPaymentErrorAction = createAction(
  '[events manager] Refund payment error',
  props<{ error: HttpErrorResponse }>()
);

export const CancelRegistrationAction = createAction(
  '[events manager] Cancel registration',
  props<IInstitutionMemberProgramEventRegistrationParams>()
);
export const CancelRegistrationSuccessAction = createAction(
  '[events manager] Cancel registration success',
  props<IInstitutionMemberProgramEventRegistrationParams>()
);
export const CancelRegistrationErrorAction = createAction(
  '[events manager] Cancel registration error',
  props<{ error: HttpErrorResponse }>()
);

export const GetEventCouponsAction = createAction(
  '[events manager] Get event coupons',
  props<IInstitutionManagerProgramEventParams>()
);
export const GetEventCouponsSuccessAction = createAction(
  '[events manager] Get event coupons success',
  props<IInstitutionManagerProgramEventParams & { coupons: IEventCouponDto[] }>()
);
export const GetEventCouponsErrorAction = createAction(
  '[events manager] Get event coupons error',
  props<{ error: HttpErrorResponse }>()
);

export const GetEventCouponAction = createAction(
  '[events manager] Get event coupon',
  props<IInstitutionManagerProgramEventParams & { institutionDiscountId: string }>()
);
export const GetEventCouponSuccessAction = createAction(
  '[events manager] Get event coupon success',
  props<IInstitutionManagerProgramEventParams & { institutionDiscountId: string, coupon: IEventCouponDto }>()
);
export const GetEventCouponErrorAction = createAction(
  '[events manager] Get event coupon error',
  props<{ error: HttpErrorResponse }>()
);

export const DeleteEventCouponAction = createAction(
  '[events manager] Delete event coupon',
  props<IInstitutionManagerProgramEventParams & { institutionDiscountId: string }>()
);
export const DeleteEventCouponSuccessAction = createAction(
  '[events manager] Delete event coupon success',
  props<IInstitutionManagerProgramEventParams & { institutionDiscountId: string }>()
);
export const DeleteEventCouponErrorAction = createAction(
  '[events manager] Delete event coupon error',
  props<{ error: HttpErrorResponse }>()
);
export const UpdateEventCouponAction = createAction(
  '[events manager] Update event coupon',
  props<IInstitutionManagerProgramEventParams & { institutionDiscountId: string, update: IEventDiscountUpdateDto }>()
);
export const UpdateEventCouponSuccessAction = createAction(
  '[events manager] Update event coupon success',
  props<IInstitutionManagerProgramEventParams & { institutionDiscountId: string, update: IEventDiscountUpdateDto }>()
);
export const UpdateEventCouponErrorAction = createAction(
  '[events manager] Update event coupon error',
  props<{ error: HttpErrorResponse }>()
);

export const UpdateEventEmailConfigurationAction = createAction(
  '[events manager] Update event EmailConfiguration',
  props<IInstitutionManagerProgramEventParams & { configuration: IEventEmailConfigurationDto }>()
);
export const UpdateEventEmailConfigurationSuccessAction = createAction(
  '[events manager] Update event EmailConfiguration success',
  props<IInstitutionManagerProgramEventParams & { configuration: IEventEmailConfigurationDto }>()
);
export const UpdateEventEmailConfigurationErrorAction = createAction(
  '[events manager] Update event EmailConfiguration error',
  props<{ error: HttpErrorResponse }>()
);

export const CreateEventCouponAction = createAction(
  '[events manager] Create event coupon',
  props<IInstitutionManagerProgramEventParams & { discountCreate: IEventDiscountCreateDto }>()
);
export const CreateEventCouponSuccessAction = createAction(
  '[events manager] Create event coupon success',
  props<IInstitutionManagerProgramEventParams & { discountCreate: IEventDiscountCreateDto, coupon: IEventCouponDto }>()
);
export const CreateEventCouponErrorAction = createAction(
  '[events manager] Create event coupon error',
  props<{ error: HttpErrorResponse }>()
);

export const EventsManagerCopyAction = createAction(
  '[events manager] Copy event',
  props<IInstitutionManagerProgramHierarchyParams & { fromEventId: string, eventCreateDto: IEventCreateDto }>()
);
export const EventsManagerCopySuccessAction = createAction(
  '[events manager] Copy event success',
  props<IInstitutionManagerProgramHierarchyParams & {
    fromEventId: string,
    eventCreateDto: IEventCreateDto,
    event: IEventDto
  }>()
);
export const EventsManagerCopyErrorAction = createAction(
  '[events manager] Copy event error',
  props<{ error: HttpErrorResponse }>()
);

export const EventsManagerCreateGroupEnrollmentAction = createAction(
  '[events-manager] Create Group Enrollment',
  props<IInstitutionManagerProgramEventParams & { eventGroupEnrollmentCreate: IEventGroupEnrollmentCreateDto }>()
);
export const EventsManagerCreateGroupEnrollmentSuccessAction = createAction(
  '[events-manager] Create Group Enrollment success',
  props<IInstitutionManagerProgramEventParams & { eventGroupEnrollmentCreate: IEventGroupEnrollmentCreateDto }>()
);
export const EventsManagerCreateGroupEnrollmentErrorAction = createAction(
  '[events-manager] Create Group Enrollment error',
  props<{ error: HttpErrorResponse }>()
);

export const EventsManagerGetGroupEnrollmentAction = createAction(
  '[events-manager] Get Group Enrollment',
  props<IInstitutionManagerProgramEventParams>()
);
export const EventsManagerGetGroupEnrollmentSuccessAction = createAction(
  '[events-manager] Get Group Enrollment success',
  props<IInstitutionManagerProgramEventParams & { groupEnrollments: any }>()
);
export const EventsManagerGetGroupEnrollmentErrorAction = createAction(
  '[events-manager] Get Group Enrollment error',
  props<{ error: HttpErrorResponse }>()
);

export const EventsManagerAddVanityDomainAction = createAction(
  '[events-manager] Add vanity domain',
  props<IInstitutionManagerProgramEventParams & { vanityDomainCreateDto: IEventVanityDomainCreateDto }>()
);
export const EventsManagerAddVanityDomainSuccessAction = createAction(
  '[events-manager] Add vanity domain success',
  props<IInstitutionManagerProgramEventParams & { vanityDomainCreateDto: IEventVanityDomainCreateDto }>()
);
export const EventsManagerAddVanityDomainErrorAction = createAction(
  '[events-manager] Add vanity domain error',
  props<{ error: HttpErrorResponse }>()
);

export const EventsManagerUpdateVanityDomainAction = createAction(
  '[events-manager] Update vanity domain',
  props<IInstitutionManagerProgramEventParams & {
    vanityDomainId: string,
    vanityDomainUpdateDto: IEventVanityDomainUpdateDto
  }>()
);
export const EventsManagerUpdateVanityDomainSuccessAction = createAction(
  '[events-manager] Update vanity domain success',
  props<IInstitutionManagerProgramEventParams & {
    vanityDomainId: string,
    vanityDomainUpdateDto: IEventVanityDomainUpdateDto
  }>()
);
export const EventsManagerUpdateVanityDomainErrorAction = createAction(
  '[events-manager] Update vanity domain error',
  props<{ error: HttpErrorResponse }>()
);

export const EventsManagerDeleteVanityDomainAction = createAction(
  '[events-manager] Delete vanity domain',
  props<IInstitutionManagerProgramEventParams & { vanityDomainId: string }>()
);
export const EventsManagerDeleteVanityDomainSuccessAction = createAction(
  '[events-manager] Delete vanity domain success',
  props<IInstitutionManagerProgramEventParams & { vanityDomainId: string }>()
);
export const EventsManagerDeleteVanityDomainErrorAction = createAction(
  '[events-manager] Delete vanity domain error',
  props<{ error: HttpErrorResponse }>()
);
