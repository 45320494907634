import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IProjectDto } from 'app/core/models';

@Component({
  selector: 'ng4h-modal-unlink-project',
  templateUrl: './modal-unlink-project.component.html',
  styleUrls: ['./modal-unlink-project.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalUnlinkProjectComponent implements OnInit {

  public project: IProjectDto;

  constructor(private dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) private data) { }

  ngOnInit() {
    this.project = this.data.project;
  }

  public cancel(): void {
    this.dialogRef.close(false);
  }

  public delete(): void {
    this.dialogRef.close(true);
  }
}
