import { Action, createReducer, on } from '@ngrx/store';
import { IInstitutionSystemManagerProgramModel, IVolunteerScreeningDto } from 'app/core/models';
import { mergeImmutable } from 'app/shared/utils';

import { SystemManagerScreeningActions } from '.';

export interface SystemManagerScreeningsState {
  screening: IInstitutionSystemManagerProgramModel<IVolunteerScreeningDto>;

}

const initialState: SystemManagerScreeningsState = {
  screening: undefined
};

const reducer = createReducer(
  initialState,

  on(SystemManagerScreeningActions.SystemManagerScreeningLoadSuccessAction, (state, { institutionId, systemManagerId, programId, screeningType, screening }) => {
    const newScreenings = mergeImmutable(
      { [institutionId]: { [systemManagerId]: { [programId]: { [screeningType]: screening } } } },
      state.screening
    );
    return {
      ...state,
      screening: newScreenings
    };
  }),
  on(SystemManagerScreeningActions.SystemManagerScreeningsUpdateSuccessAction, SystemManagerScreeningActions.SystemManagerScreeningsFormUpdateSuccessAction, (state, { }) => {
    return initialState;
  })
);

export function systemManagerScreeningsReducer(state: SystemManagerScreeningsState | undefined, actions: Action) {
  return reducer(state, actions);
}
export const screening = (state: SystemManagerScreeningsState) => state.screening;

