import { Pipe, PipeTransform } from '@angular/core';
import { IUnitDto } from 'app/core/models';
import { ManagerClubsService, RouterService } from 'app/core/services';
import { filter, map, Observable } from 'rxjs';

@Pipe({
    name: 'hierarchyUnit$',
    pure: true
})
export class HierarchyUnitPipe implements PipeTransform {
    constructor(private managerClubsService: ManagerClubsService, private routerService: RouterService) {
    }

    public transform(unitHierarchyNodeId: string, institutionId: string = this.routerService.institutionId, managerId: string = this.routerService.managerId, programId: string = this.routerService.programId): Observable<IUnitDto> {
        return this.managerClubsService.getClubs({
            institutionId: institutionId,
            managerId,
            programId,
        }).pipe(
            filter(c => Array.isArray(c)),
            map(units => units.find(units => units.hierarchyNodeId === unitHierarchyNodeId))
        );
    }
}
