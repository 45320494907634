import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AutoUnsubscribe,
  eEthnicity,
  eGender,
  eGenderIdentity,
  EMAIL_REGEX,
  eMilitaryBranchType,
  eMilitaryComponentType,
  eMilitaryFamilyServiceType,
  eResidence,
  IEnrollmentDto,
  IMemberDto,
  IProgramSettingsDto,
  PHONE_REGEX,
} from 'app/core/models';
import { DemographicValidators } from 'app/core/validators';
import { filter, startWith, takeUntil } from 'rxjs';

@Component({
  selector: 'ng4h-adult-demographics-form',
  templateUrl: './adult-demographics-form.component.html',
  styleUrls: ['./adult-demographics-form.component.scss']
})
export class AdultDemographicsFormComponent extends AutoUnsubscribe implements OnInit {


  public eMilitaryFamilyServiceType = eMilitaryFamilyServiceType;
  public eMilitaryBranchType = eMilitaryBranchType;
  public eMilitaryComponentType = eMilitaryComponentType;
  public eEthnicity = eEthnicity;
  public eGender = eGender;
  public eGenderIdentity = eGenderIdentity;
  public eResidence = eResidence;

  @Input() demographicsForm: FormGroup;
  @Output() demographicsFormChange: EventEmitter<FormGroup>;

  @Input() member: IMemberDto;
  @Input() enrollment: IEnrollmentDto;
  @Input() programSettings: IProgramSettingsDto;


  constructor(
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder
  ) {
    super();
    this.demographicsFormChange = new EventEmitter<FormGroup>();
  }

  ngOnInit() {
    const enrollment = this.enrollment;
    const member = this.member;
    const programSettings = this.programSettings;
    this.demographicsForm = this.fb.group({

      birthGender: [enrollment.demographics.birthGender, programSettings.gender_Birth_On ? Validators.required : null],
      identifyingGender: [enrollment.demographics.identifyingGender, programSettings.gender_Identity_On ? Validators.required : null],

      jobTitle: [member.jobTitle],
      employer: [member.employer],
      workPhone: [member.workPhone],
      workExt: [member.workExt],

      ethnicity: [enrollment.demographics.ethnicity, Validators.required],
      residence: [enrollment.demographics.residence, Validators.required],

      racialBreakdownWhite: [enrollment.demographics.racialBreakdownWhite],
      racialBreakdownBlack: [enrollment.demographics.racialBreakdownBlack],
      racialBreakdownAmericanIndianOrAlaskanNative: [enrollment.demographics.racialBreakdownAmericanIndianOrAlaskanNative],
      racialBreakdownNativeHawaiianOrPacificIslander: [enrollment.demographics.racialBreakdownNativeHawaiianOrPacificIslander],
      racialBreakdownAsian: [enrollment.demographics.racialBreakdownAsian],
      racialBreakdownBalanceOfOtherCombinations: [enrollment.demographics.racialBreakdownBalanceOfOtherCombinations],
      racialBreakdownUndetermined: [enrollment.demographics.racialBreakdownUndetermined],


      militaryService: [enrollment.militaryService, Validators.required],
      militaryBranch: [{
        value: enrollment.militaryBranch, disabled: enrollment.militaryService == null
          || enrollment.militaryService === eMilitaryFamilyServiceType.NoOneInMyFamilyIsServingInTheMilitary
      }],
      militaryComponent: [{
        value: enrollment.militaryComponent, disabled: enrollment.militaryService == null
          || enrollment.militaryService === eMilitaryFamilyServiceType.NoOneInMyFamilyIsServingInTheMilitary
      }],


      emergencyContact: programSettings.member_Show_Second_EmergencyContact === true ? this.fb.group({
        emergencyContact: [member.emergencyContact, [Validators.required, Validators.maxLength(50)]],
        emergencyContactPhone: [member.emergencyContactPhone, [Validators.required, Validators.pattern(PHONE_REGEX)]],
        emergencyContactRelationship: [member.emergencyContactRelationship, [Validators.required, Validators.maxLength(200)]],
        emergencyContactEmail: [member.emergencyContactEmail, [Validators.pattern(EMAIL_REGEX)]],

        emergencyContact2: [member.emergencyContact2, [Validators.maxLength(50)]],
        emergencyContact2Relationship: [member.emergencyContact2Relationship, [Validators.maxLength(200)]],
        emergencyContact2Phone: [member.emergencyContact2Phone, [Validators.pattern(PHONE_REGEX)]],
        emergencyContact2Email: [member.emergencyContact2Email, [Validators.pattern(EMAIL_REGEX)]]
      }) : this.fb.group({
        emergencyContact: [member.emergencyContact, [Validators.required, Validators.maxLength(50)]],
        emergencyContactPhone: [member.emergencyContactPhone, [Validators.required, Validators.pattern(PHONE_REGEX)]],
        emergencyContactRelationship: [member.emergencyContactRelationship, [Validators.required, Validators.maxLength(200)]],
        emergencyContactEmail: [member.emergencyContactEmail, [Validators.pattern(EMAIL_REGEX)]],
      })

    }, { validators: [DemographicValidators.Race] });



    this.demographicsForm.controls.militaryService.valueChanges
      .pipe(
        startWith(this.demographicsForm.controls.militaryService.value),
        filter(militaryService => militaryService != null),
        takeUntil(this.autoUnsubscribe))
      .subscribe((militaryService: eMilitaryFamilyServiceType) => {
        this.setDisabilityOnMilitaryService(militaryService);
      });
    //#endregion

    //#region Racial checkboxes
    this.demographicsForm.controls.racialBreakdownUndetermined.valueChanges.pipe(
      startWith(this.demographicsForm.controls.racialBreakdownUndetermined.value),
      filter(racialBreakdownUndetermined => racialBreakdownUndetermined != null && racialBreakdownUndetermined === true),
      takeUntil(this.autoUnsubscribe))
      .subscribe(racialBreakdownUndetermined => {
        this.demographicsForm.controls.racialBreakdownWhite.setValue(null);
        this.demographicsForm.controls.racialBreakdownBlack.setValue(null);
        this.demographicsForm.controls.racialBreakdownAmericanIndianOrAlaskanNative.setValue(null);
        this.demographicsForm.controls.racialBreakdownNativeHawaiianOrPacificIslander.setValue(null);
        this.demographicsForm.controls.racialBreakdownAsian.setValue(null);
        this.demographicsForm.controls.racialBreakdownBalanceOfOtherCombinations.setValue(null);
      });

    this.demographicsForm.controls.racialBreakdownWhite.valueChanges.pipe(
      startWith(this.demographicsForm.controls.racialBreakdownWhite.value),
      filter(racialBreakdownWhite => racialBreakdownWhite != null && this.demographicsForm.controls.racialBreakdownUndetermined.value === true),
      takeUntil(this.autoUnsubscribe))
      .subscribe(val => {
        this.demographicsForm.controls.racialBreakdownUndetermined.setValue(null);
        this.demographicsForm.controls.racialBreakdownUndetermined.updateValueAndValidity();
      });
    this.demographicsForm.controls.racialBreakdownBlack.valueChanges.pipe(
      startWith(this.demographicsForm.controls.racialBreakdownBlack.value),
      filter(racialBreakdownBlack => racialBreakdownBlack != null && this.demographicsForm.controls.racialBreakdownUndetermined.value === true),
      takeUntil(this.autoUnsubscribe))
      .subscribe(val => {
        this.demographicsForm.controls.racialBreakdownUndetermined.setValue(null);
        this.demographicsForm.controls.racialBreakdownUndetermined.updateValueAndValidity();
      });
    this.demographicsForm.controls.racialBreakdownAmericanIndianOrAlaskanNative.valueChanges.pipe(
      startWith(this.demographicsForm.controls.racialBreakdownAmericanIndianOrAlaskanNative.value),
      filter(racialBreakdownAmericanIndianOrAlaskanNative => racialBreakdownAmericanIndianOrAlaskanNative != null && this.demographicsForm.controls.racialBreakdownUndetermined.value === true),
      takeUntil(this.autoUnsubscribe))
      .subscribe(val => {
        this.demographicsForm.controls.racialBreakdownUndetermined.setValue(null);
        this.demographicsForm.controls.racialBreakdownUndetermined.updateValueAndValidity();
      });
    this.demographicsForm.controls.racialBreakdownNativeHawaiianOrPacificIslander.valueChanges.pipe(
      startWith(this.demographicsForm.controls.racialBreakdownNativeHawaiianOrPacificIslander.value),
      filter(racialBreakdownNativeHawaiianOrPacificIslander => racialBreakdownNativeHawaiianOrPacificIslander != null && this.demographicsForm.controls.racialBreakdownUndetermined.value === true),
      takeUntil(this.autoUnsubscribe))
      .subscribe(val => {
        this.demographicsForm.controls.racialBreakdownUndetermined.setValue(null);
        this.demographicsForm.controls.racialBreakdownUndetermined.updateValueAndValidity();
      });
    this.demographicsForm.controls.racialBreakdownAsian.valueChanges.pipe(
      startWith(this.demographicsForm.controls.racialBreakdownAsian.value),
      filter(racialBreakdownAsian => racialBreakdownAsian != null && this.demographicsForm.controls.racialBreakdownUndetermined.value === true),
      takeUntil(this.autoUnsubscribe))
      .subscribe(val => {
        this.demographicsForm.controls.racialBreakdownUndetermined.setValue(null);
        this.demographicsForm.controls.racialBreakdownUndetermined.updateValueAndValidity();
      });
    this.demographicsForm.controls.racialBreakdownBalanceOfOtherCombinations.valueChanges.pipe(
      startWith(this.demographicsForm.controls.racialBreakdownBalanceOfOtherCombinations.value),
      filter(racialBreakdownBalanceOfOtherCombinations => racialBreakdownBalanceOfOtherCombinations != null && this.demographicsForm.controls.racialBreakdownUndetermined.value === true),
      takeUntil(this.autoUnsubscribe))
      .subscribe(val => {
        this.demographicsForm.controls.racialBreakdownUndetermined.setValue(null);
        this.demographicsForm.controls.racialBreakdownUndetermined.updateValueAndValidity();
      });
    this.demographicsFormChange.emit(this.demographicsForm);
  }


  public retainOrder() {
    return 0;
  }

  private setDisabilityOnMilitaryService(militaryService: eMilitaryFamilyServiceType) {
    if (militaryService === eMilitaryFamilyServiceType.NoOneInMyFamilyIsServingInTheMilitary) {
      this.demographicsForm.controls.militaryBranch.setValue(null);
      this.demographicsForm.controls.militaryComponent.setValue(null);
      this.demographicsForm.controls.militaryBranch.disable();
      this.demographicsForm.controls.militaryComponent.disable();

      this.demographicsForm.controls.militaryBranch.clearValidators();
      this.demographicsForm.controls.militaryComponent.clearValidators();
    } else {
      this.demographicsForm.controls.militaryBranch.enable();
      this.demographicsForm.controls.militaryComponent.enable();

      this.demographicsForm.controls.militaryBranch.setValidators([Validators.required]);
      this.demographicsForm.controls.militaryBranch.updateValueAndValidity();
      this.demographicsForm.controls.militaryComponent.setValidators([Validators.required]);
      this.demographicsForm.controls.militaryComponent.updateValueAndValidity();
    }
  }

}
