import { Action, createReducer, on } from '@ngrx/store';
import {
  IInstitutionManagerProgramModel,
  IInstitutionProgramModel,
  INationalProjectCategoryDto,
  IProjectDto,
} from 'app/core/models';
import { mergeImmutable } from 'app/shared/utils';

import { ManagerProjectsActions } from '.';

export interface ManagerProjectsState {
  projects: IInstitutionManagerProgramModel<IProjectDto[]>;
  programYearHierarchyProjects: IInstitutionManagerProgramModel<{ [programYearId: string]: { [hierarchyNodeId: string]: IProjectDto[] } }>;
  programYearProjects: IInstitutionManagerProgramModel<{ [programYearId: string]: IProjectDto[] }>;
  project: IInstitutionManagerProgramModel<{ [projectId: string]: { [programYearId: string]: IProjectDto } }>;
  countyAliasProjects: IInstitutionManagerProgramModel<{ [countyAreaId: string]: { [programYearId: string]: IProjectDto[] } }>;
  nationalProjectCategories: IInstitutionProgramModel<{ [programYearId: string]: INationalProjectCategoryDto[] }>;
  excludedProjects: IInstitutionManagerProgramModel<{ [countyAreaId: string]: IProjectDto[] }>;
  countyExcludedProjects: IInstitutionManagerProgramModel<{ [unitId: string]: IProjectDto[] }>;
}

const initialState: ManagerProjectsState = {
  projects: undefined,
  programYearHierarchyProjects: undefined,
  programYearProjects: undefined,
  project: undefined,
  countyAliasProjects: undefined,
  nationalProjectCategories: undefined,
  excludedProjects: undefined,
  countyExcludedProjects: undefined
};

const reducer = createReducer(
  initialState,
  on(ManagerProjectsActions.ManagerGetProjectsSuccessAction, (state, { institutionId, managerId, programId, projects }) => {
    return {
      ...state,
      projects: mergeImmutable(
        { [institutionId]: { [managerId]: { [programId]: projects } } },
        state.projects
      )
    };
  }),

  on(ManagerProjectsActions.ManagerGetProgramYearHierarchyProjectsSuccessAction, (state, { institutionId, managerId, programId, programYearId, hierarchyNodeId, projects }) => {
    return {
      ...state,
      programYearHierarchyProjects: mergeImmutable(
        { [institutionId]: { [managerId]: { [programId]: { [programYearId]: { [hierarchyNodeId]: projects } } } } },
        state.programYearHierarchyProjects
      )
    };
  }),
  on(ManagerProjectsActions.ManagerInvalidateProgramYearProjectsAction, (state, { institutionId, managerId, programId, programYearId }) => {
    return {
      ...state,
      programYearHierarchyProjects: mergeImmutable(
        { [institutionId]: { [managerId]: { [programId]: { [programYearId]: undefined } } } },
        state.programYearHierarchyProjects
      )
    };
  }),

  on(ManagerProjectsActions.ManagerGetProgramYearProjectsSuccessAction, (state, { institutionId, managerId, programId, programYearId, projects }) => {
    return {
      ...state,
      programYearProjects: mergeImmutable(
        { [institutionId]: { [managerId]: { [programId]: { [programYearId]: projects } } } },
        state.programYearProjects
      )
    };
  }),
  on(ManagerProjectsActions.ManagerInvalidateProgramYearProjectsAction, (state, { institutionId, managerId, programId, programYearId }) => {
    return {
      ...state,
      programYearProjects: mergeImmutable(
        { [institutionId]: { [managerId]: { [programId]: { [programYearId]: undefined } } } },
        state.programYearProjects
      )
    };
  }),

  on(ManagerProjectsActions.ManagerGetProjectByIdSuccessAction, (state, { institutionId, managerId, programId, projectId, programYearId, project }) => {
    return {
      ...state,
      project: mergeImmutable(
        { [institutionId]: { [managerId]: { [programId]: { [projectId]: { [programYearId]: project } } } } },
        state.project
      )
    };
  }),



  on(ManagerProjectsActions.ManagerUpdateProjectEnrollmentRestrictionSuccessAction, (state, { }) => {
    return initialState;
  }),
  on(ManagerProjectsActions.GetProjectCountyAliasesSuccessAction, (state, { institutionId, managerId, programId, countyAreaId, programYearId, projects }) => {
    return {
      ...state,
      countyAliasProjects: mergeImmutable(
        { [institutionId]: { [managerId]: { [programId]: { [countyAreaId]: { [programYearId]: projects } } } } },
        state.countyAliasProjects
      )
    };
  }),
  on(ManagerProjectsActions.GetNationalProjectCategoriesSuccessAction, (state, { institutionId, programId, programYearId, nationalCategories }) => {
    return {
      ...state,
      nationalProjectCategories: mergeImmutable(
        { [institutionId]: { [programId]: { [programYearId]: nationalCategories } } },
        state.nationalProjectCategories
      )
    };
  }),
  on(ManagerProjectsActions.GetExcludedProjectsSuccessAction, (state, { institutionId, managerId, programId, countyAreaId, projects }) => {
    return {
      ...state,
      excludedProjects: mergeImmutable(
        { [institutionId]: { [managerId]: { [programId]: { [countyAreaId]: projects } } } },
        state.excludedProjects
      )
    };
  }),
  on(ManagerProjectsActions.GetExcludedCountyProjectsSuccessAction, (state, { institutionId, managerId, programId, unitId, projects }) => {
    return {
      ...state,
      countyExcludedProjects: mergeImmutable(
        { [institutionId]: { [managerId]: { [programId]: { [unitId]: projects } } } },
        state.countyExcludedProjects
      )
    };
  }),
  on(ManagerProjectsActions.CreateProjectCountyAliasesSuccessAction,
    ManagerProjectsActions.UpdateProjectCountyAliasesSuccessAction,
    ManagerProjectsActions.DeleteProjectCountyAliasesSuccessAction,
    ManagerProjectsActions.UpdateExcludedCountyProjectSuccessAction,
    ManagerProjectsActions.DeleteExcludedCountyProjectSuccessAction,
    ManagerProjectsActions.UpdateExcludedProjectSuccessAction,
    ManagerProjectsActions.DeleteExcludedProjectSuccessAction,
    (state, { }) => {
      return initialState;
    })
);

export function managerProjectsReducer(state: ManagerProjectsState | undefined, action: Action) {
  return reducer(state, action);
}

export const projects = (state: ManagerProjectsState) => state.projects;
export const programYearHierarchyProjects = (state: ManagerProjectsState) => state.programYearHierarchyProjects;
export const programYearProjects = (state: ManagerProjectsState) => state.programYearProjects;
export const project = (state: ManagerProjectsState) => state.project;
export const countyAliasProjects = (state: ManagerProjectsState) => state.countyAliasProjects;
export const nationalProjectCategories = (state: ManagerProjectsState) => state.nationalProjectCategories;
export const excludedProjects = (state: ManagerProjectsState) => state.excludedProjects;
export const countyExcludedProjects = (state: ManagerProjectsState) => state.countyExcludedProjects;
