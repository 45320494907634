import {
    IAnimalBreedDto,
    IAnimalTypeFeeDto,
    IAnimalTypeFileControlDto,
    IProfileDescriptorDto,
    IStandardFormColumnDto,
} from '.';
import { eActiveRecordModificationRule, eAnimalMarketCategory } from '..';
import { eAnimalSpecies } from '../enums/eAnimalSpecies';

export interface IAnimalTypeDto {
    animalTypeId: string;
    fileControls?: IAnimalTypeFileControlDto[];
    breeds?: IAnimalBreedDto[];
    modifiedBy: IProfileDescriptorDto;
    dateModified: string;
    identifierField: IStandardFormColumnDto;
    memberModificationStartDate: string;
    memberModificationEndDate: string;
    managerModificationStartDate: string;
    managerModificationEndDate: string;
    fees?: IAnimalTypeFeeDto[];
    animalSpecies: eAnimalSpecies;
    marketCategory: eAnimalMarketCategory;
    animalTypeLabel?: string;
    animalTypeDescription?: string;
    autoApproveOnSubmit: boolean;
    animalMaxPerMember?: number;
    weightIn: boolean;
    persistAcrossYears: boolean;
    turnOnStateShowEligibility: boolean;
    allowMembersToAddRecords: boolean;
    allowMemberToModifyRecords: boolean;
    activeRecordsMayNotBeModifiedByMember: boolean;
    activeRecordsMayNotBeModifiedByMemberRule?: eActiveRecordModificationRule;
    allowMemberToChangeAssociatedMember: boolean;
    memberModificationStartMonth: number;
    memberModificationStartDay: number;
    memberModificationEndMonth: number;
    memberModificationEndDay: number;
    activeRecordsMayNotBeModifiedByManager: boolean;
    activeRecordsMayNotBeModifiedByManagerRule?: eActiveRecordModificationRule;
    allowManagerToChangeAssociatedMember: boolean;
    managerModificationStartMonth: number;
    managerModificationStartDay: number;
    managerModificationEndMonth: number;
    managerModificationEndDay: number;
    pageMessage_Manager?: string;
    pageMessage_Member?: string;

}
