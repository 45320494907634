<div class="row">
  <div class="col-12">
    <div class="ng4h-panel ng4h-bg-secondary-light">
      <div class="col-12 ng4h-panel-header">
        <h4>Order</h4>
      </div>
      <div class="ng4h-margin">
        <div class="col-12">
          <div class="row">
            <div class="col-6">
              <div>{{ member?.firstName }} {{ member?.lastName }}</div>
              <ng-container *ngIf="member | primaryFamily as primaryFamily">
                <div>{{ primaryFamily.familyName }}</div>
                <div>{{ primaryFamily.county.countyName }}, {{ primaryFamily.county.state }}</div>
                <div class="ng4h-margin-top" *ngIf="primaryFamily.familyAddress as address">
                  <div>{{ address.addressLine1 }}</div>
                  <div *ngIf="address.addressLine2">
                    {{ address.addressLine2 }}
                  </div>
                  <div *ngIf="address.city && address.state && address.postalCode">{{ address.city }},
                    {{ address.state }}
                    {{ address.postalCode }}
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="col-6">
              <div class="ng4h-margin-bottom" *ngFor="let invoice of invoices">
                <div class="row ng4h-border-bottom ng4h-padding-bottom"
                     *ngFor="let lineItem of invoice?.lineItems">
                  <div class="col-10">{{ lineItem.description }}</div>
                  <div class="col-2">{{ lineItem.amount | currency }}</div>
                </div>
                <div class="row">
                  <div class="col-10"><b>Total:</b></div>
                  <div class="col-2">
                    <b>{{ invoice?.amountDue | enrollmentFee | currency }}</b>
                  </div>
                </div>
                <div [ngSwitch]="invoice.status">
                  <div *ngSwitchCase="eInvoiceStatus.Payable">
                    <div class="row">
                      <div class="col-10"><b>Amount To Be Processed:</b></div>
                      <div class="col-2">
                        <b>{{ invoice?.amountOutstanding | enrollmentFee | currency }}</b>
                      </div>
                    </div>
                  </div>
                  <div *ngSwitchCase="eInvoiceStatus.Paid">
                    <div class="row">
                      <div class="col-10"><b>Paid:</b></div>
                      <div class="col-2">
                        <b>{{ invoice?.amountPaid | enrollmentFee | currency }}</b>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-10"><b>Outstanding:</b></div>
                      <div class="col-2">
                        <b>{{ invoice?.amountOutstanding | enrollmentFee | currency }}</b>
                      </div>
                    </div>
                  </div>
                  <div *ngSwitchCase="eInvoiceStatus.Draft">

                  </div>
                  <div *ngSwitchCase="eInvoiceStatus.Canceled">

                  </div>
                </div>


              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>