import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { eProfileType } from 'app/core/models';
import { ProfileService, RouterService } from 'app/core/services';
import { filter, map, Observable, take, tap } from 'rxjs';



@Injectable({ providedIn: 'root' })
export class AdminGuard implements CanActivate {
  constructor(private profileService: ProfileService, private routerService: RouterService) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.profileService.actingAsInstitutionProfile.pipe(
      filter(actingOnBehalfOf => actingOnBehalfOf != null),
      take(1),
      map((userProfile) => {
        return [eProfileType.Superuser, eProfileType.SystemManager, eProfileType.Manager, eProfileType.ReportConsumer].indexOf(userProfile.profileType) !== -1;
      }),
      tap(isAdmin => {
        if (!isAdmin) {
          console.error('Non admin tried to access admin area');
          if (state.url.indexOf('lastRouteParams') === -1) {
            this.routerService.Go(['user', 'sign-in'], { queryParams: { lastRouteParams: state.url } });
          } else {
            this.routerService.Go(['user', 'sign-in']);
          }
        }
      })
    );
  }

}
