import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IProgramYearDto, IUnitProgramYearDto } from 'app/core/models';
import { CommonToastrService, ManagerClubsService, ProgramYearService, RouterService } from 'app/core/services';
import { filter, Observable, switchMap, take } from 'rxjs';

@Component({
  selector: 'ng4h-modal-edit-club-enrollment-restrictions',
  templateUrl: './modal-edit-club-enrollment-restrictions.component.html',
  styleUrls: ['./modal-edit-club-enrollment-restrictions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalEditClubEnrollmentRestrictionsComponent implements OnInit {

  public form: FormGroup;
  private selectedProgramYear$: Observable<IProgramYearDto>;
  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) private data: IUnitProgramYearDto,
    private routerService: RouterService,
    private fb: FormBuilder,
    private programYearService: ProgramYearService,
    private clubsService: ManagerClubsService,
    private toastrService: CommonToastrService
  ) {

    this.selectedProgramYear$ = this.programYearService.getUsersProgramYear({
      institutionId: this.routerService.institutionId,
      programId: this.routerService.programId,
      institutionProfileId: this.routerService.loggedInInstitutionProfileId
    });
  }

  ngOnInit() {
    this.form = this.fb.group({
      maximumEnrollments: [this.data.maximumEnrollments],
      allowMembersToRegister: [this.data.allowMembersToRegister],
      allowMembersToSeeTheirRegistration: [this.data.allowMembersToSeeTheirRegistration]
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  public save() {

    this.selectedProgramYear$.pipe(
      filter(py => py != null),
      take(1),
      switchMap(programYear => {
        return this.clubsService.updateEnrollmentRestrictions({
          institutionId: this.routerService.institutionId,
          managerId: this.routerService.managerId,
          programId: this.routerService.programId,
          unitId: this.routerService.unitId,
          programYearId: programYear.programYearId,
          update: this.form.value
        });
      }),
      take(1)
    ).subscribe({
      next: () => {
        this.toastrService.success('Restrictions Updated');
        this.close();
      }, error: (error) => this.toastrService.error('Could not update restrictions ' + error.error.body)
    });

  }

}
