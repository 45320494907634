import { Action, createReducer, on } from '@ngrx/store';
import {
  IInstitutionProgramRolloverDescriptorDto,
  IInstitutionSystemManagerProgramModel,
  ISystemManagerInstitutionProfile,
} from 'app/core/models';
import { IPermissionType } from 'app/core/models/IPermissionType';
import { mergeImmutable } from 'app/shared/utils';

import { SystemManagerActions } from '.';



export interface SystemManagerState {
  systemManager: { [superuserId: string]: { [institutionId: string]: { [systemManagerId: string]: ISystemManagerInstitutionProfile } } };
  systemManagers: { [institutionId: string]: ISystemManagerInstitutionProfile[] };
  systemManagerPermissionTypes: IPermissionType[];
  systemManagerRollover: IInstitutionSystemManagerProgramModel<IInstitutionProgramRolloverDescriptorDto[]>;
}

const initialState: SystemManagerState = {
  systemManager: undefined,
  systemManagers: undefined,
  systemManagerPermissionTypes: undefined,
  systemManagerRollover: undefined
};

const reducer = createReducer(
  initialState,
  on(SystemManagerActions.GetSystemManagerSuccessAction, (state, { superuserId, institutionId, systemManagerId, systemManager }) => {
    const newSystemManager = mergeImmutable(
      { [superuserId]: { [institutionId]: { [systemManagerId]: systemManager } } },
      state.systemManager
    );
    return {
      ...state,
      systemManager: newSystemManager
    };
  }),
  on(SystemManagerActions.GetSystemManagersSuccessAction, (state, { institutionId, systemManagers }) => {
    const newSystemManagers = mergeImmutable(
      { [institutionId]: systemManagers },
      state.systemManagers
    );
    return {
      ...state,
      systemManagers: newSystemManagers
    };
  }),
  on(
    SystemManagerActions.UpdateSystemManagerSuccessAction,
    SystemManagerActions.UpdateSystemManagerPermissionsSuccessAction,
    SystemManagerActions.CreateSystemManagerSuccessAction,
    SystemManagerActions.RemoveFromProgramSuccessAction,
    (state, { }) => {
      return {
        ...state,
        systemManagers: undefined
      };
    }),
  on(SystemManagerActions.GetSystemManagerPermissionTypesSuccessAction, (state, { types }) => {
    return {
      ...state,
      systemManagerPermissionTypes: types
    };
  }),
  on(SystemManagerActions.GetSystemManagerRolloversSuccessAction, (state, { institutionId, systemManagerId, programId, rollovers }) => {
    const newRollovers = mergeImmutable(
      { [institutionId]: { [systemManagerId]: { [programId]: rollovers } } },
      state.systemManagerRollover
    );
    return {
      ...state,
      systemManagerRollover: newRollovers
    };
  }),
  on(SystemManagerActions.StartSystemManagerRolloverSuccessAction, (state, { }) => {
    return {
      ...state,
      systemManagerRollover: undefined
    };
  })
);

export function systemManagerReducer(state: SystemManagerState | undefined, actions: Action) {
  return reducer(state, actions);
}

export const systemManager = (state: SystemManagerState) => state.systemManager;
export const systemManagers = (state: SystemManagerState) => state.systemManagers;
export const systemManagerPermissionTypes = (state: SystemManagerState) => state.systemManagerPermissionTypes;
export const systemManagerRollover = (state: SystemManagerState) => state.systemManagerRollover;

