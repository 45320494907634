import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  ICountyCreditBillMemberDto,
  ICountyCreditBillPaidDto,
  ICountyCreditBillPreviewDto,
  ICreditDto,
  ICreditsCollectionDto,
  ICreditSearchDto,
  ICreditSearchResultsDto,
  IEnrollmentApprovalDto,
  IEnrollmentCommentDto,
  IEnrollmentFeeWaiverRequestDeclinedDto,
  IEnrollmentUnitAdditionRequestDto,
  IEnrollmentUnitAdditionRequestUpdateDto,
  IGroupEnrollmentCreateDto,
  IGroupEnrollmentDto,
  IInstitutionManagerProgramParams,
  IInstitutionMemberProgramEnrollmentParams,
  IMemberEnrollmentEntityDto,
  IProgramCountyAreaSettingsDto,
  IProgramCountyAreaSettingsUpdateDto,
} from 'app/core/models';
import { ICountyCreditBillDto } from 'app/core/models/serverDTOs/ICountyCreditBillDto';
import { IGroupEnrollmentUpdateDto } from 'app/core/models/serverDTOs/IGroupEnrollmentUpdateDto';

export const GetManagerGetEnrollments = createAction(
    '[manager-enrollment] Get submitted enrollments',
    props<IInstitutionManagerProgramParams & { countyAreaId: string }>()
);
export const GetManagerGetEnrollmentsSuccess = createAction(
    '[manager-enrollment] Get submitted enrollments success',
    props<IInstitutionManagerProgramParams & { countyAreaId: string, enrollments: IMemberEnrollmentEntityDto[] }>()
);
export const GetManagerGetEnrollmentsError = createAction(
    '[manager-enrollment] Get submitted enrollments error',
    props<{ error: HttpErrorResponse }>()
);

export const EnrollmentApproveAction = createAction(
    '[manager-enrollment] Approve',
    props<IInstitutionMemberProgramEnrollmentParams & { managerId: string, approval: IEnrollmentApprovalDto }>()
);
export const EnrollmentApproveSuccessAction = createAction(
    '[manager-enrollment] Approve success',
    props<IInstitutionMemberProgramEnrollmentParams & { managerId: string, approval: IEnrollmentApprovalDto }>()
);
export const EnrollmentApproveErrorAction = createAction(
    '[manager-enrollment] Approve error',
    props<{ error: HttpErrorResponse }>()
);

export const EnrollmentSendBackAction = createAction(
    '[manager-enrollment] Send back',
    props<IInstitutionMemberProgramEnrollmentParams & { managerId: string, comment: IEnrollmentCommentDto }>()
);
export const EnrollmentSendBackSuccessAction = createAction(
    '[manager-enrollment] Send back success',
    props<IInstitutionMemberProgramEnrollmentParams & { managerId: string, comment: IEnrollmentCommentDto }>()
);
export const EnrollmentSendBackErrorAction = createAction(
    '[manager-enrollment] Send back error',
    props<{ error: HttpErrorResponse }>()
);

export const EnrollmentBlockAction = createAction(
    '[manager-enrollment] Block',
    props<IInstitutionMemberProgramEnrollmentParams & { managerId: string, comment: IEnrollmentCommentDto }>()
);
export const EnrollmentBlockSuccessAction = createAction(
    '[manager-enrollment] Block success',
    props<IInstitutionMemberProgramEnrollmentParams & { managerId: string, comment: IEnrollmentCommentDto }>()
);
export const EnrollmentBlockErrorAction = createAction(
    '[manager-enrollment] Block error',
    props<{ error: HttpErrorResponse }>()
);


export const GetGroupEnrollmentsAction = createAction(
    '[manager-enrollment] Load group enrollments',
    props<IInstitutionManagerProgramParams & { countyAreaId: string, programYearId: string }>()
);
export const GetGroupEnrollmentsSuccessAction = createAction(
    '[manager-enrollment] Load group enrollments success',
    props<IInstitutionManagerProgramParams & { countyAreaId: string, programYearId: string, groupEnrollments: IGroupEnrollmentDto[] }>()
);
export const GetGroupEnrollmentsErrorAction = createAction(
    '[manager-enrollment] Load group enrollments error',
    props<{ error: HttpErrorResponse }>()
);

export const CreateGroupEnrollmentsAction = createAction(
    '[manager-enrollment] Create group enrollments',
    props<IInstitutionManagerProgramParams & { countyAreaId: string, groupEnrollment: IGroupEnrollmentCreateDto }>()
);
export const CreateGroupEnrollmentsSuccessAction = createAction(
    '[manager-enrollment] Create group enrollments success',
    props<IInstitutionManagerProgramParams & { countyAreaId: string, groupEnrollment: IGroupEnrollmentCreateDto }>()
);
export const CreateGroupEnrollmentsErrorAction = createAction(
    '[manager-enrollment] Create group enrollments error',
    props<{ error: HttpErrorResponse }>()
);

export const UpdateGroupEnrollmentsAction = createAction(
    '[manager-enrollment] Update group enrollments',
    props<IInstitutionManagerProgramParams & { countyAreaId: string, groupEnrollmentId: string, groupEnrollmentUpdate: IGroupEnrollmentUpdateDto }>()
);
export const UpdateGroupEnrollmentsSuccessAction = createAction(
    '[manager-enrollment] Update group enrollments success',
    props<IInstitutionManagerProgramParams & { countyAreaId: string, groupEnrollmentId: string, groupEnrollmentUpdate: IGroupEnrollmentUpdateDto }>()
);
export const UpdateGroupEnrollmentsErrorAction = createAction(
    '[manager-enrollment] Update group enrollments error',
    props<{ error: HttpErrorResponse }>()
);

export const DeleteGroupEnrollmentsAction = createAction(
    '[manager-enrollment] Delete group enrollments',
    props<IInstitutionManagerProgramParams & { countyAreaId: string, groupEnrollmentId: string }>()
);
export const DeleteGroupEnrollmentsSuccessAction = createAction(
    '[manager-enrollment] Delete group enrollments success',
    props<IInstitutionManagerProgramParams & { countyAreaId: string, groupEnrollmentId: string }>()
);
export const DeleteGroupEnrollmentsErrorAction = createAction(
    '[manager-enrollment] Delete group enrollments error',
    props<{ error: HttpErrorResponse }>()
);

export const ManagerGetOutstandingCreditsAction = createAction(
    '[manager-enrollment] Get outstanding credits',
    props<IInstitutionManagerProgramParams & { countyAreaId: string, programYearId: string }>()
);
export const ManagerGetOutstandingCreditsSuccessAction = createAction(
    '[manager-enrollment] Get outstanding credits success',
    props<IInstitutionManagerProgramParams & { countyAreaId: string, programYearId: string, outstandingCredits: ICreditDto[] }>()
);
export const ManagerGetOutstandingCreditsErrorAction = createAction(
    '[manager-enrollment] Get outstanding credits error',
    props<{ error: HttpErrorResponse }>()
);

export const ManagerGetCollectedCreditsAction = createAction(
    '[manager-enrollment] Get collected credits',
    props<IInstitutionManagerProgramParams & { countyAreaId: string, programYearId: string }>()
);
export const ManagerGetCollectedCreditsSuccessAction = createAction(
    '[manager-enrollment] Get collected credits success',
    props<IInstitutionManagerProgramParams & { countyAreaId: string, programYearId: string, collectedCredits: ICreditDto[] }>()
);
export const ManagerGetCollectedCreditsErrorAction = createAction(
    '[manager-enrollment] Get collected credits error',
    props<{ error: HttpErrorResponse }>()
);


export const ManagerOutstandingCreditSearchAction = createAction(
    '[manager-enrollment] Search outstanding credits',
    props<IInstitutionManagerProgramParams & { programYearId: string, pagesize: string, pageindex: string, searchDto: ICreditSearchDto }>()
);
export const ManagerOutstandingCreditSearchSuccessAction = createAction(
    '[manager-enrollment] Search outstanding credits success',
    props<IInstitutionManagerProgramParams & { programYearId: string, pagesize: string, pageindex: string, searchDto: ICreditSearchDto, outstandingCredits: ICreditSearchResultsDto }>()
);
export const ManagerOutstandingCreditSearchErrorAction = createAction(
    '[manager-enrollment] Search outstanding credits error',
    props<{ error: HttpErrorResponse }>()
);


export const ManagerConfirmCollectedCreditsAction = createAction(
    '[manager-enrollment] Confirm collected credits',
    props<IInstitutionManagerProgramParams & { countyAreaId: string, programYearId: string, creditsCollected: ICreditsCollectionDto }>()
);
export const ManagerConfirmCollectedCreditsSuccessAction = createAction(
    '[manager-enrollment] Confirm collected credits success',
    props<IInstitutionManagerProgramParams & { countyAreaId: string, programYearId: string, creditsCollected: ICreditsCollectionDto }>()
);
export const ManagerConfirmCollectedCreditsErrorAction = createAction(
    '[manager-enrollment] Confirm collected credits error',
    props<{ error: HttpErrorResponse }>()
);

export const ManagerConfirmInstitutionCollectedCreditsAction = createAction(
    '[manager-enrollment] Confirm institution collected credits',
    props<IInstitutionManagerProgramParams & { programYearId: string, creditsCollected: ICreditsCollectionDto }>()
);
export const ManagerConfirmInstitutionCollectedCreditsSuccessAction = createAction(
    '[manager-enrollment] Confirm institution collected credits success',
    props<IInstitutionManagerProgramParams & { programYearId: string, creditsCollected: ICreditsCollectionDto }>()
);
export const ManagerConfirmInstitutionCollectedCreditsErrorAction = createAction(
    '[manager-enrollment] Confirm institution collected credits error',
    props<{ error: HttpErrorResponse }>()
);


// export const GetAllCountyCreditBillsAction = createAction(
//     '[manager-enrollment] Get all county credit bills',
//     props<IInstitutionManagerProgramParams>()
// );
// export const GetAllCountyCreditBillsSuccessAction = createAction(
//     '[manager-enrollment] Get all county credit bills success',
//     props<IInstitutionManagerProgramParams & { bills: ICountyCreditBillDto[] }>()
// );
// export const GetAllCountyCreditBillsErrorAction = createAction(
//     '[manager-enrollment] Get all county credit bills error',
//     props<{ error: HttpErrorResponse }>()
// );

export const GetCountyCreditBillsForProgramYearAction = createAction(
    '[manager-enrollment] Get county credit bills for program year',
    props<IInstitutionManagerProgramParams & { programYearId: string }>()
);
export const GetCountyCreditBillsForProgramYearSuccessAction = createAction(
    '[manager-enrollment] Get county credit bills for program year success',
    props<IInstitutionManagerProgramParams & { programYearId: string, bills: ICountyCreditBillDto[] }>()
);
export const GetCountyCreditBillsForProgramYearErrorAction = createAction(
    '[manager-enrollment] Get county credit bills for program year error',
    props<{ error: HttpErrorResponse }>()
);

export const GetCountyCreditBillsAction = createAction(
    '[manager-enrollment] Get county credit bills',
    props<IInstitutionManagerProgramParams & { countyAreaId: string }>()
);
export const GetCountyCreditBillsSuccessAction = createAction(
    '[manager-enrollment] Get county credit bills success',
    props<IInstitutionManagerProgramParams & { countyAreaId: string, bills: ICountyCreditBillDto[] }>()
);
export const GetCountyCreditBillsErrorAction = createAction(
    '[manager-enrollment] Get county credit bills error',
    props<{ error: HttpErrorResponse }>()
);

export const GetCountyCreditBillAction = createAction(
    '[manager-enrollment] Get county credit bill',
    props<IInstitutionManagerProgramParams & { countyCreditBillId: string }>()
);
export const GetCountyCreditBillSuccessAction = createAction(
    '[manager-enrollment] Get county credit bill success',
    props<IInstitutionManagerProgramParams & { countyCreditBillId: string, bill: ICountyCreditBillDto }>()
);
export const GetCountyCreditBillErrorAction = createAction(
    '[manager-enrollment] Get county credit bill error',
    props<{ error: HttpErrorResponse }>()
);

export const CreateCountyBillFromApprovedAction = createAction(
    '[manager-enrollment] Create county bill from approved',
    props<IInstitutionManagerProgramParams & { countyAreaId: string }>()
);
export const CreateCountyBillFromApprovedSuccessAction = createAction(
    '[manager-enrollment] Create county bill from approved success',
    props<IInstitutionManagerProgramParams & { countyAreaId: string }>()
);
export const CreateCountyBillFromApprovedErrorAction = createAction(
    '[manager-enrollment] Create county bill from approved error',
    props<{ error: HttpErrorResponse }>()
);


export const ConfirmCountyCreditAction = createAction(
    '[manager-enrollment] Confirm County Credit',
    props<IInstitutionManagerProgramParams & { countyCreditBillId: string, countyCreditPaid: ICountyCreditBillPaidDto }>()
);
export const ConfirmCountyCreditSuccessAction = createAction(
    '[manager-enrollment] Confirm County Credit success',
    props<IInstitutionManagerProgramParams & { countyCreditBillId: string, countyCreditPaid: ICountyCreditBillPaidDto }>()
);
export const ConfirmCountyCreditErrorAction = createAction(
    '[manager-enrollment] Confirm County Credit error',
    props<{ error: HttpErrorResponse }>()
);

export const GetMemberCreditsOnBillAction = createAction(
    '[manager-enrollment] Get member credits on bill',
    props<IInstitutionManagerProgramParams & { countyCreditBillId: string }>()
);
export const GetMemberCreditsOnBillSuccessAction = createAction(
    '[manager-enrollment] Get member credits on bill success',
    props<IInstitutionManagerProgramParams & { countyCreditBillId: string, memberCredits: ICountyCreditBillMemberDto[] }>()
);
export const GetMemberCreditsOnBillErrorAction = createAction(
    '[manager-enrollment] Get member credits on bill error',
    props<{ error: HttpErrorResponse }>()
);

export const GetEnrollmentBillPreviewAction = createAction(
    '[manager-enrollment] Get enrollment bill preview',
    props<IInstitutionManagerProgramParams & { countyAreaId: string }>()
);
export const GetEnrollmentBillPreviewSuccessAction = createAction(
    '[manager-enrollment] Get enrollment bill preview success',
    props<IInstitutionManagerProgramParams & { countyAreaId: string, memberCredits: ICountyCreditBillPreviewDto }>()
);
export const GetEnrollmentBillPreviewErrorAction = createAction(
    '[manager-enrollment] Get enrollment bill preview error',
    props<{ error: HttpErrorResponse }>()
);

export const GetRegistrationBillPreviewAction = createAction(
    '[manager-enrollment] Get registration bill preview',
    props<IInstitutionManagerProgramParams & { countyAreaId: string }>()
);
export const GetRegistrationBillPreviewSuccessAction = createAction(
    '[manager-enrollment] Get registration bill preview success',
    props<IInstitutionManagerProgramParams & { countyAreaId: string, memberCredits: ICountyCreditBillPreviewDto }>()
);
export const GetRegistrationBillPreviewErrorAction = createAction(
    '[manager-enrollment] Get registration bill preview error',
    props<{ error: HttpErrorResponse }>()
);

export const LoadEnrollmentFeeWaiverRequests = createAction(
    '[manager-enrollment] Load enrollment fee waiver requests',
    props<IInstitutionManagerProgramParams & { programYearId: string, pagesize: string, pageindex: string }>()
);
export const LoadEnrollmentFeeWaiverSuccessRequests = createAction(
    '[manager-enrollment] Load enrollment fee waiver requests success',
    props<IInstitutionManagerProgramParams & { programYearId: string, pagesize: string, pageindex: string, waiverRequests }>()
);
export const LoadEnrollmentFeeWaiverErrorRequests = createAction(
    '[manager-enrollment] Load enrollment fee waiver requests error',
    props<{ error: HttpErrorResponse }>()
);

export const WaiveEnrollmentFeesAction = createAction(
    '[manager-enrollment] Waive enrollment fees',
    props<IInstitutionMemberProgramEnrollmentParams>()
);
export const WaiveEnrollmentFeesSuccessAction = createAction(
    '[manager-enrollment] Waive enrollment fees success',
    props<IInstitutionMemberProgramEnrollmentParams>()
);
export const WaiveEnrollmentFeesErrorAction = createAction(
    '[manager-enrollment] Waive enrollment fees error',
    props<{ error: HttpErrorResponse }>()
);

export const DeclineWaiverRequestAction = createAction(
    '[manager-enrollment] Decline Waiver Request',
    props<IInstitutionMemberProgramEnrollmentParams & { declineDto: IEnrollmentFeeWaiverRequestDeclinedDto }>()
);
export const DeclineWaiverRequestSuccessAction = createAction(
    '[manager-enrollment] Decline Waiver Request success',
    props<IInstitutionMemberProgramEnrollmentParams & { declineDto: IEnrollmentFeeWaiverRequestDeclinedDto }>()
);
export const DeclineWaiverRequestErrorAction = createAction(
    '[manager-enrollment] Decline Waiver Request error',
    props<{ error: HttpErrorResponse }>()
);

export const LoadCountyAreaEnrollmentSettings = createAction(
    '[manager-enrollment] Load county area enrollment settings',
    props<IInstitutionManagerProgramParams & { countyAreaId: string }>()
);
export const LoadCountyAreaEnrollmentSuccessSettings = createAction(
    '[manager-enrollment] Load county area enrollment settings success',
    props<IInstitutionManagerProgramParams & { countyAreaId: string, settings: IProgramCountyAreaSettingsDto }>()
);
export const LoadCountyAreaEnrollmentErrorSettings = createAction(
    '[manager-enrollment] Load county area enrollment settings error',
    props<{ error: HttpErrorResponse }>()
);


export const UpdateCountyAreaEnrollmentSettings = createAction(
    '[manager-enrollment] Update county area enrollment settings',
    props<IInstitutionManagerProgramParams & { countyAreaId: string, update: IProgramCountyAreaSettingsUpdateDto }>()
);
export const UpdateCountyAreaEnrollmentSuccessSettings = createAction(
    '[manager-enrollment] Update county area enrollment settings success',
    props<IInstitutionManagerProgramParams & { countyAreaId: string, update: IProgramCountyAreaSettingsUpdateDto }>()
);
export const UpdateCountyAreaEnrollmentErrorSettings = createAction(
    '[manager-enrollment] Update county area enrollment settings error',
    props<{ error: HttpErrorResponse }>()
);

export const RefundEnrollmentPaymentAction = createAction(
    '[manager-enrollment] Refund enrollment payment',
    props<IInstitutionMemberProgramEnrollmentParams & { paymentId: string, managerId: string }>()
);
export const RefundEnrollmentPaymentSuccessAction = createAction(
    '[manager-enrollment] Refund enrollment payments success',
    props<IInstitutionMemberProgramEnrollmentParams & { paymentId: string, managerId: string }>()
);
export const RefundEnrollmentPaymentErrorAction = createAction(
    '[manager-enrollment] Refund enrollment payments error',
    props<{ error: HttpErrorResponse }>()
);
export const CreateCountyBillFromCreditsAction = createAction(
    '[manager-enrollment] Create county bill from credits',
    props<IInstitutionManagerProgramParams & { countyAreaId: string, invoiceDiscounts: string[] }>()
);
export const CreateCountyBillFromCreditsSuccessAction = createAction(
    '[manager-enrollment] Create county bill from credits success',
    props<IInstitutionManagerProgramParams & { countyAreaId: string, invoiceDiscounts: string[] }>()
);
export const CreateCountyBillFromCreditsErrorAction = createAction(
    '[manager-enrollment] Create county bill from credits error',
    props<{ error: HttpErrorResponse }>()
);

export const CancelEnrollmentAction = createAction(
    '[manager-enrollment] Cancel enrollment',
    props<IInstitutionMemberProgramEnrollmentParams & { managerId: string }>()
);
export const CancelEnrollmentSuccessAction = createAction(
    '[manager-enrollment] Cancel enrollment success',
    props<IInstitutionMemberProgramEnrollmentParams & { managerId: string }>()
);
export const CancelEnrollmentErrorAction = createAction(
    '[manager-enrollment] Cancel enrollment error',
    props<{ error: HttpErrorResponse }>()
);
export const NotParticipatingEnrollmentAction = createAction(
    '[manager-enrollment] NotParticipating enrollment',
    props<IInstitutionMemberProgramEnrollmentParams & { managerId: string }>()
);
export const NotParticipatingEnrollmentSuccessAction = createAction(
    '[manager-enrollment] NotParticipating enrollment success',
    props<IInstitutionMemberProgramEnrollmentParams & { managerId: string }>()
);
export const NotParticipatingEnrollmentErrorAction = createAction(
    '[manager-enrollment] NotParticipating enrollment error',
    props<{ error: HttpErrorResponse }>()
);

export const ApproveUnitAddRequestAction = createAction(
    '[manager-enrollment] Approve unit add request',
    props<IInstitutionMemberProgramEnrollmentParams & { enrollmentUnitProgramYearRequestId: string, requestUpdate: IEnrollmentUnitAdditionRequestUpdateDto }>()
);
export const ApproveUnitAddRequestSuccessAction = createAction(
    '[manager-enrollment] Approve unit add request success',
    props<IInstitutionMemberProgramEnrollmentParams & { enrollmentUnitProgramYearRequestId: string, requestUpdate: IEnrollmentUnitAdditionRequestUpdateDto }>()
);
export const ApproveUnitAddRequestErrorAction = createAction(
    '[manager-enrollment] Approve unit add request error',
    props<{ error: HttpErrorResponse }>()
);

export const GetUnitRequestsAction = createAction(
    '[manager-enrollment] Get unit requests',
    props<IInstitutionManagerProgramParams & { countyAreaId: string }>()
);
export const GetUnitRequestsSuccessAction = createAction(
    '[manager-enrollment] Get unit requests success',
    props<IInstitutionManagerProgramParams & { countyAreaId: string, unitRequest: IEnrollmentUnitAdditionRequestDto[] }>()
);
export const GetUnitRequestsErrorAction = createAction(
    '[manager-enrollment] Get unit requests error',
    props<{ error: HttpErrorResponse }>()
);



export const InvalidateManagerEnrollmentsCache = createAction(
    '[manager-enrollment] Invalidate submitted enrollments cache'
);
