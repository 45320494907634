import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnInit,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { eEnrollmentRoleType } from 'app/core/models';

@Component({
  selector: 'ng4h-enrollment-role-dropdown',
  templateUrl: './enrollment-role-dropdown.component.html',
  styleUrls: ['./enrollment-role-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EnrollmentRoleDropdownComponent),
    multi: true
  }]
})
export class EnrollmentRoleDropdownComponent implements OnInit, ControlValueAccessor, AfterViewInit {

  @Input() value = '';
  @Input() formClass = '';
  @Input() limitTo?: eEnrollmentRoleType[];
  private eEnrollmentRoleType = eEnrollmentRoleType;

  public roles: any;

  onChange: any = () => { };
  onTouched: any = () => { };


  constructor(private cdref: ChangeDetectorRef) { }

  ngOnInit() {
    if (this.limitTo != null) {
      this.roles = this.limitTo.reduce((pre, cur) => {
        return {
          ...pre,
          [cur]: cur
        };
      }, {});
    } else {
      this.roles = this.eEnrollmentRoleType;
    }
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  writeValue(value) {
    if (value != null) {
      this.value = value;
      this.onChange(value);
      this.onTouched();
    }
  }

  ngAfterViewInit() {
    if (this.value) {
      // This is a total hack but I can't get it to not throw ExpressionChangedAfterItHasBeenCheckedError without it
      setTimeout(() => {
        this.onChange(this.value);
        this.cdref.detectChanges();
      }, 0);
    }
  }

}
