import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params, RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';
import { eHierarchyNodeType, eProfileType } from 'app/core/models';
import { IBreadCrumb } from 'app/core/models/IBreadCrumb';
import { CommonToastrService } from 'app/core/services';
import * as FileSaver from 'file-saver';
import { fromJS, List } from 'immutable';
import { map, Observable, take } from 'rxjs';

import { eStateAbbreviation } from './../core/models/enums/eStateAbbreviation';
import { IProgramYearDto } from './../core/models/serverDTOs/IProgramYearDto';

/**
 * The RouterStateSerializer takes the current RouterStateSnapshot
 * and returns any pertinent information needed. The snapshot contains
 * all information about the state of the router at the given point in time.
 * The entire snapshot is complex and not always needed. In this case, you only
 * need the URL and query parameters from the snapshot in the store. Other items could be
 * returned such as route parameters and static route data.
 */

export function retainOrder() {
    return 0;
}
export interface RouterStateUrl {
    url: string;
    params: Params;
    queryParams: Params;
    breadcrumbs: IBreadCrumb[];
}

@Injectable()
export class CustomRouterStateSerializer implements RouterStateSerializer<RouterStateUrl> {
    serialize(routerState: RouterStateSnapshot): RouterStateUrl {
        let route = routerState.root;
        let params = { ...route.params };


        const breadcrumbs: IBreadCrumb[] = [];
        let nextUrl = '/';
        while (route.firstChild) {
            route = route.firstChild;
            params = { ...params, ...route.params };

            const label = route?.routeConfig?.data?.['breadcrumb'];
            const path = route?.routeConfig?.path && route.routeConfig.path !== '' ? route.routeConfig.path : null;
            if (path != null) {
                nextUrl = `${nextUrl}${path}/`;
            }
            Object.keys(params).forEach(key => {
                nextUrl = nextUrl.replace(`:${key}`, params[key]);
            });

            if (label != null) {
                const breadcrumb = {
                    label: label,
                    url: nextUrl
                };
                breadcrumbs.push(breadcrumb);
            }
        }
        const { url, root: { queryParams } } = routerState;

        // Only return an object including the URL, params and query params
        // instead of the entire snapshot
        return {
            url,
            params,
            queryParams,
            breadcrumbs: breadcrumbs
        };
    }
}



export function anyNull(...path: any[]): boolean {
    return path.some(value => value === null || value === undefined);
}




const isList = List.isList;
export function mergeImmutable<T>(fromNew: Partial<T> | object, toOld: T): T {
    return merger(fromJS(fromNew), fromJS(toOld)).toJS();
}

function merger(fromNew, toOld) {
    if (fromNew && fromNew.mergeWith && !isList(fromNew) && !isList(toOld)) {
        return fromNew.mergeWith(merger, toOld);
    }
    return fromNew;
}

export function removeNulls(obj: any) {
    Object.keys(obj).forEach(key => {
        if (obj[key] == null) {
            delete obj[key];
        }
        if (typeof obj[key] === 'object') {
            obj = {
                ...obj,
                [key]: removeNulls(obj[key])
            };
        }
    });
    return obj;
}

export const programYears: IProgramYearDto[] =
    [
        {
            'programYearId': 'pyr_1',
            'programYear': 2001,
            'programYearForDisplay': '2000-2001'
        },
        {
            'programYearId': 'pyr_2',
            'programYear': 2002,
            'programYearForDisplay': '2001-2002'
        },
        {
            'programYearId': 'pyr_3',
            'programYear': 2003,
            'programYearForDisplay': '2002-2003'
        },
        {
            'programYearId': 'pyr_4',
            'programYear': 2004,
            'programYearForDisplay': '2003-2004'
        },
        {
            'programYearId': 'pyr_5',
            'programYear': 2005,
            'programYearForDisplay': '2004-2005'
        },
        {
            'programYearId': 'pyr_6',
            'programYear': 2006,
            'programYearForDisplay': '2005-2006'
        },
        {
            'programYearId': 'pyr_7',
            'programYear': 2007,
            'programYearForDisplay': '2006-2007'
        },
        {
            'programYearId': 'pyr_8',
            'programYear': 2008,
            'programYearForDisplay': '2007-2008'
        },
        {
            'programYearId': 'pyr_9',
            'programYear': 2009,
            'programYearForDisplay': '2008-2009'
        },
        {
            'programYearId': 'pyr_a',
            'programYear': 2010,
            'programYearForDisplay': '2009-2010'
        },
        {
            'programYearId': 'pyr_b',
            'programYear': 2011,
            'programYearForDisplay': '2010-2011'
        },
        {
            'programYearId': 'pyr_c',
            'programYear': 2012,
            'programYearForDisplay': '2011-2012'
        },
        {
            'programYearId': 'pyr_d',
            'programYear': 2013,
            'programYearForDisplay': '2012-2013'
        },
        {
            'programYearId': 'pyr_e',
            'programYear': 2014,
            'programYearForDisplay': '2013-2014'
        },
        {
            'programYearId': 'pyr_f',
            'programYear': 2015,
            'programYearForDisplay': '2014-2015'
        },
        {
            'programYearId': 'pyr_g',
            'programYear': 2016,
            'programYearForDisplay': '2015-2016'
        },
        {
            'programYearId': 'pyr_h',
            'programYear': 2017,
            'programYearForDisplay': '2016-2017'
        },
        {
            'programYearId': 'pyr_i',
            'programYear': 2018,
            'programYearForDisplay': '2017-2018'
        },
        {
            'programYearId': 'pyr_j',
            'programYear': 2019,
            'programYearForDisplay': '2018-2019'
        },
        {
            'programYearId': 'pyr_k',
            'programYear': 2020,
            'programYearForDisplay': '2019-2020'
        },
        {
            'programYearId': 'pyr_l',
            'programYear': 2021,
            'programYearForDisplay': '2020-2021'
        },
        {
            'programYearId': 'pyr_m',
            'programYear': 2022,
            'programYearForDisplay': '2021-2022'
        },
        {
            'programYearId': 'pyr_n',
            'programYear': 2023,
            'programYearForDisplay': '2022-2023'
        },
        {
            'programYearId': 'pyr_o',
            'programYear': 2024,
            'programYearForDisplay': '2023-2024'
        },
        {
            'programYearId': 'pyr_p',
            'programYear': 2025,
            'programYearForDisplay': '2024-2025'
        },
        {
            'programYearId': 'pyr_q',
            'programYear': 2026,
            'programYearForDisplay': '2025-2026'
        },
        {
            'programYearId': 'pyr_r',
            'programYear': 2027,
            'programYearForDisplay': '2026-2027'
        },
        {
            'programYearId': 'pyr_s',
            'programYear': 2028,
            'programYearForDisplay': '2027-2028'
        },
        {
            'programYearId': 'pyr_t',
            'programYear': 2029,
            'programYearForDisplay': '2028-2029'
        },
        {
            'programYearId': 'pyr_u',
            'programYear': 2030,
            'programYearForDisplay': '2029-2030'
        },
        {
            'programYearId': 'pyr_v',
            'programYear': 2031,
            'programYearForDisplay': '2030-2031'
        },
        {
            'programYearId': 'pyr_w',
            'programYear': 2032,
            'programYearForDisplay': '2031-2032'
        },
        {
            'programYearId': 'pyr_x',
            'programYear': 2033,
            'programYearForDisplay': '2032-2033'
        },
        {
            'programYearId': 'pyr_y',
            'programYear': 2034,
            'programYearForDisplay': '2033-2034'
        },
        {
            'programYearId': 'pyr_z',
            'programYear': 2035,
            'programYearForDisplay': '2034-2035'
        },
        {
            'programYearId': 'pyr_10',
            'programYear': 2036,
            'programYearForDisplay': '2035-2036'
        },
        {
            'programYearId': 'pyr_11',
            'programYear': 2037,
            'programYearForDisplay': '2036-2037'
        },
        {
            'programYearId': 'pyr_12',
            'programYear': 2038,
            'programYearForDisplay': '2037-2038'
        },
        {
            'programYearId': 'pyr_13',
            'programYear': 2039,
            'programYearForDisplay': '2038-2039'
        },
        {
            'programYearId': 'pyr_14',
            'programYear': 2040,
            'programYearForDisplay': '2039-2040'
        }
    ];

export function programYearLookup(programYear: number | string): IProgramYearDto {
    return programYears.find(py => py.programYearId === programYear || py.programYear === programYear);
}

export const stateList = {
    'AL': 'Alabama',
    'AK': 'Alaska',
    'AZ': 'Arizona',
    'AR': 'Arkansas',
    'CA': 'California',
    'CO': 'Colorado',
    'CT': 'Connecticut',
    'DE': 'Delaware',
    'DC': 'District Of Columbia',
    'FL': 'Florida',
    'GA': 'Georgia',
    'HI': 'Hawaii',
    'ID': 'Idaho',
    'IL': 'Illinois',
    'IN': 'Indiana',
    'IA': 'Iowa',
    'KS': 'Kansas',
    'KY': 'Kentucky',
    'LA': 'Louisiana',
    'ME': 'Maine',
    'MD': 'Maryland',
    'MA': 'Massachusetts',
    'MI': 'Michigan',
    'MN': 'Minnesota',
    'MS': 'Mississippi',
    'MO': 'Missouri',
    'MT': 'Montana',
    'NE': 'Nebraska',
    'NV': 'Nevada',
    'NH': 'New Hampshire',
    'NJ': 'New Jersey',
    'NM': 'New Mexico',
    'NY': 'New York',
    'NC': 'North Carolina',
    'ND': 'North Dakota',
    'OH': 'Ohio',
    'OK': 'Oklahoma',
    'OR': 'Oregon',
    'PA': 'Pennsylvania',
    'RI': 'Rhode Island',
    'SC': 'South Carolina',
    'SD': 'South Dakota',
    'TN': 'Tennessee',
    'TX': 'Texas',
    'UT': 'Utah',
    'VT': 'Vermont',
    'VA': 'Virginia',
    'WA': 'Washington',
    'WV': 'West Virginia',
    'WI': 'Wisconsin',
    'WY': 'Wyoming',

    'AS': 'American Samoa',
    'FM': 'Federated States Of Micronesia',
    'GU': 'Guam',
    'MH': 'Marshall Islands',
    'MP': 'Northern Mariana Islands',
    'PW': 'Palau',
    'PR': 'Puerto Rico',
    'VI': 'Virgin Islands'
};

export function stateNameLookup(abbr: eStateAbbreviation): string {
    return stateList[abbr];
}

export function isDate(date: any) {
    const regex = /^((19|20)[0-9][0-9])[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])[T]([01][0-9]|[2][0-3])[:]([0-5][0-9])[:]([0-5][0-9])([.](\d+)){0,1}([+|-]([01][0-9]|[2][0-3])[:]([0-5][0-9])){0,1}$/;
    if (!date || date.length === 0) {
        return false;
    }
    return (regex.test(date));
}

export function formatDate(value: Date, padWithZero?: boolean): string {
    const mm = value.getMonth() + 1; // getMonth() is zero-based
    const dd = value.getDate();
    return (padWithZero
        ? [
            (mm > 9 ? '' : '0') + mm,
            (dd > 9 ? '' : '0') + dd,
            value.getFullYear()
        ]
        : [mm, dd, value.getFullYear()])
        .join('/');
}


export function isDefaultDate(date: any) {
    const regex = /^(0001-01-01T00:00:00)/;
    if (!date || date.length === 0) {
        return false;
    }
    return (regex.test(date));
}



export function getFile(httpClient: HttpClient, url: string): Observable<{ fileName: string, file: Blob }> {
    return httpClient.get(url, {
        headers: new HttpHeaders({
            'Content-Type': 'application/octet-stream'
        }),
        observe: 'response',
        responseType: 'blob',
    }).pipe(
        map(response => {
            if (response.status === 202) {
                throw new Error('Queued');
            } else if (response.status !== 200) {
                throw new Error('Retrieving file unsuccessful');
            }
            return {
                fileName: response.headers.get('content-disposition')?.split(';').find(cd => cd.includes('filename=')).split('=')[1].replace(/"/g, ''),
                file: response.body
            };
        })
    );
}

export function putFile(httpClient: HttpClient, url: string, body: any): Observable<{ fileName: string, file: Blob }> {
    return httpClient.put(url, body, {
        headers: new HttpHeaders({
            'Content-Type': 'application/octet-stream'
        }),
        observe: 'response',
        responseType: 'blob',
    }
    ).pipe(
        map(response => {
            return {
                fileName: response.headers.get('content-disposition').split(';').find(cd => cd.includes('filename=')).split('=')[1].replace(/"/g, ''),
                file: response.body
            };
        })
    );
}

export function downloadFile(httpClient: HttpClient, url: string, toastrService?: CommonToastrService): void {
    getFile(httpClient, url).pipe(take(1)).subscribe({
        next: fileInfo => {
            FileSaver.saveAs(fileInfo.file, fileInfo.fileName);
        }, error: (error: Error | HttpErrorResponse) => {
            if (toastrService != null) {
                if (error.message === 'Queued') {
                    toastrService.success('Your report has been queued, you will receive an email once it has completed');
                } else {
                    toastrService.error('Could not retrieve report', error);
                }
            } else {
                console.error(error + ' ' + url);
            }
        }
    });
}
export function downloadFileWithBody(httpClient: HttpClient, url: string, body: any, toastrService?: CommonToastrService): void {
    putFile(httpClient, url, body).pipe(take(1)).subscribe({
        next: fileInfo => {
            FileSaver.saveAs(fileInfo.file, fileInfo.fileName);
        }, error: (error: Error | HttpErrorResponse) => {
            if (toastrService != null) {
                if (error.message === 'Queued') {
                    toastrService.success('Your report has been queued, you will receive an email once it has completed');
                } else {
                    toastrService.error('Could not retrieve report', error);
                }
            } else {
                console.error(error + ' ' + url);
            }
        }
    });
}

export function rankHierarchyAssociation(hierarchyNodeType: eHierarchyNodeType): number {
    switch (hierarchyNodeType) {
        case eHierarchyNodeType.Institution: {
            return 0;
        }
        case eHierarchyNodeType.RegionArea: {
            return 1;
        }
        case eHierarchyNodeType.DistrictArea: {
            return 2;
        }
        case eHierarchyNodeType.CountyArea: {
            return 3;
        }
        case eHierarchyNodeType.Unit: {
            return 4;
        }
    }
}

export function rankProfileLevel(profileType: eProfileType) {
    switch (profileType) {
        case eProfileType.Superuser: {
            return 0;
        }
        case eProfileType.SystemManager: {
            return 1;
        }
        case eProfileType.Manager: {
            return 2;
        }
        case eProfileType.Member:
        case eProfileType.Family: {
            return 3;
        }
        // case eProfileType.Member: {
        //     return 4;
        // }
    }
}

export function rankProfileLevelWithFamily(profileType: eProfileType) {
    switch (profileType) {
        case eProfileType.Superuser: {
            return 0;
        }
        case eProfileType.SystemManager: {
            return 1;
        }
        case eProfileType.Manager: {
            return 2;
        }
        case eProfileType.Family: {
            return 3;
        }
        case eProfileType.Member: {
            return 4;
        }
    }
}

export function luhnChecksum(number: string): boolean {
    const length = number.length;
    const parity = length % 2;
    let sum = 0;
    for (let i = length - 1; i >= 0; i--) {
        let digit = parseInt(number.charAt(i), 10);
        if (i % 2 === parity) { digit *= 2; }
        if (digit > 9) { digit -= 9; }
        sum += digit;
    }
    return sum % 10 === 0;
}
export const ONE_HOUR_IN_SECONDS = 3600;

export function resolveObjectPath(path, obj) {
    const properties = Array.isArray(path) ? path : path.split('.');
    return properties.reduce((prev, curr) => prev && prev[curr], obj);
}
// it would be more preformat to do this on a column-by-column basis but it's easy to use this one function
export function nestedObjectSortFix(item: any, property): string | number {
    let result = null;
    if (property.includes('.')) {
        result = resolveObjectPath(property, item);
    } else {
        result = item[property];
    }
    if (isDate(result)) {
        return new Date(result).getTime();
    }
    return result;
};

// Good enough for client side

export function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
