import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  IInstitutionMemberProgramEnrollmentParams,
  IVolunteerHoursCreateDto,
  IVolunteerHoursDto,
  IVolunteerHoursProjectsDto,
  IVolunteerHoursUpdateDto,
} from 'app/core/models';


export const GetVolunteerHoursAction = createAction(
    '[member-hours] Get hours',
    props<IInstitutionMemberProgramEnrollmentParams>()
);
export const GetVolunteerHoursSuccessAction = createAction(
    '[member-hours] Get hours success',
    props<IInstitutionMemberProgramEnrollmentParams & { hours: IVolunteerHoursDto[] }>()
);
export const GetVolunteerHoursErrorAction = createAction(
    '[member-hours] Get hours error',
    props<{ error: HttpErrorResponse }>()
);


export const AddVolunteerHoursAction = createAction(
    '[member-hours] Add hours',
    props<IInstitutionMemberProgramEnrollmentParams & { volunteerHoursCreate: IVolunteerHoursCreateDto }>()
);
export const AddVolunteerHoursSuccessAction = createAction(
    '[member-hours] Add hours success',
    props<IInstitutionMemberProgramEnrollmentParams & { volunteerHoursCreate: IVolunteerHoursCreateDto }>()
);
export const AddVolunteerHoursErrorAction = createAction(
    '[member-hours] Add hours error',
    props<{ error: HttpErrorResponse }>()
);


export const UpdateVolunteerHoursAction = createAction(
    '[member-hours] Update hours',
    props<IInstitutionMemberProgramEnrollmentParams & { enrollmentVolunteerHoursId: string, update: IVolunteerHoursUpdateDto }>()
);
export const UpdateVolunteerHoursSuccessAction = createAction(
    '[member-hours] Update hours success',
    props<IInstitutionMemberProgramEnrollmentParams & { enrollmentVolunteerHoursId: string, update: IVolunteerHoursUpdateDto }>()
);
export const UpdateVolunteerHoursErrorAction = createAction(
    '[member-hours] Update hours error',
    props<{ error: HttpErrorResponse }>()
);

export const DeleteVolunteerHoursAction = createAction(
    '[member-hours] Delete hours',
    props<IInstitutionMemberProgramEnrollmentParams & { enrollmentVolunteerHoursId: string }>()
);
export const DeleteVolunteerHoursSuccessAction = createAction(
    '[member-hours] Delete hours success',
    props<IInstitutionMemberProgramEnrollmentParams & { enrollmentVolunteerHoursId: string }>()
);
export const DeleteVolunteerHoursErrorAction = createAction(
    '[member-hours] Delete hours error',
    props<{ error: HttpErrorResponse }>()
);




export const GetVolunteerHoursProjectsAction = createAction(
    '[member-hours] Get hours projects',
    props<IInstitutionMemberProgramEnrollmentParams>()
);
export const GetVolunteerHoursProjectsSuccessAction = createAction(
    '[member-hours] Get hours projects success',
    props<IInstitutionMemberProgramEnrollmentParams & { projects: IVolunteerHoursProjectsDto }>()
);
export const GetVolunteerHoursProjectsErrorAction = createAction(
    '[member-hours] Get hours projects error',
    props<{ error: HttpErrorResponse }>()
);


