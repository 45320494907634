import { eMailingLabelType } from '../enums/eMailingLabelType';

export interface ICustomReportMailingLabelOptionsDto {
    type: eMailingLabelType;
    labelCountToSkip: number;
    applyUpperCase: boolean;
    includeOrCurrentResident: boolean;
    removeCommaBetweenCityAndState: boolean;
    sortByZip: boolean;
}
