<div class="modal-header">
    <h5 class="modal-title"></h5>
    <button (click)="close()" type="button" class="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div>
    <ng-template [cdkPortalOutlet]="selectedPortal"></ng-template>
</div>
<div class="modal-footer">
    <button (click)="close()" class="btn btn-secondary">Close</button>
</div>