import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Observable, take } from 'rxjs';

import { ModalConfirmComponent } from '../components/modal-confirm/modal-confirm.component';
import {
    ProgressSpinnerDialogComponent
} from '../components/progress-spinner-dialog/progress-spinner-dialog.component';
import { IModalConfirm } from '../models';
import { ModalAlertComponent } from '../components/modal-alert/modal-alert.component';

@Injectable({
    providedIn: 'root'
})
export class ModalService {

    constructor(private rootDialog: MatDialog) {

    }

    public alert(data: IModalConfirm) {
        return this.openModal(ModalAlertComponent, { data: data }).pipe(take(1));
    }

    public confirm(data: IModalConfirm) {
        return this.openModal(ModalConfirmComponent, { data: data }).pipe(take(1));
    }

    /**
     * Opens a dialog
     * @param component the component to be opened as a dialog
     * @param config dialog configuration (MatDialogConfig)
     * @param  dialog So _dialog only has the entryComponent references from root modules (shared.module)
     In order to prevent the main.js from inflating with modals, we can put them on their closest lazy loaded shared module
     declaration+entryComponent and pass in MatDialog injected from the opening component.
     The MatDialog passed in from the opening component will be able to access the entryComponent's on that lazy loaded module

     7/8/2020
     Since Angular Ivy, entryComponents is no longer necessary and providing a MatDialog from a component in a particular module is no longer necessary
     */
    public openModal<T>(component: ComponentType<T>, config?: MatDialogConfig) {
        config = {
            minWidth: '360px',
            maxWidth: '1000px',
            panelClass: ['col-12', 'col-sm-10', 'col-md-9', 'col-lg-8', 'col-xl-8'],
            ...config
        };
        const modalRef = this.rootDialog.open(component, config).afterClosed().pipe(take(1));
        document.querySelectorAll('.mat-dialog-container').forEach(element => {
            if (element.getAttribute('aria-live') == null) {
                element.setAttribute('aria-live', 'polite');
            }
        });
        return modalRef;
    }

    showProgressSpinnerModalUntilComplete = () => <T>(source: Observable<T>): Observable<T> => {
        const dialogRef: MatDialogRef<ProgressSpinnerDialogComponent> = this.rootDialog.open(ProgressSpinnerDialogComponent, {
            panelClass: 'transparent',
            disableClose: true

        });
        return new Observable(observer => {
            return source.subscribe({
                next(any: any) {
                    observer.next(any);
                },
                error(err) {
                    dialogRef.close();
                    observer.error(err);
                },
                complete() {
                    dialogRef.close();
                    observer.complete();
                }
            });
        });
    }

    public closeAll() {
        this.rootDialog.closeAll();
    }
}

