import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { eAddressVerificationStatus, IAddressDto, IAddressVerificationResultDto, IFamilyDto } from 'app/core/models';
import { FamilyService, RouterService } from 'app/core/services';
import { AddressVerificationService } from 'app/core/services/address-verification.service';
import { filter, Observable, Subject } from 'rxjs';

@Component({
  selector: 'ng4h-modal-edit-family-address',
  templateUrl: './modal-edit-family-address.component.html',
  styleUrls: ['./modal-edit-family-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalEditFamilyAddressComponent implements OnInit {
  public family$: Observable<IFamilyDto>;
  public familyAddress: FormGroup;

  public showMultipleAddressMatches = false;
  public addressMatches: IAddressDto[] = [];

  public addressErrors$: Subject<any> = new Subject<any>();

  constructor(
    private dialogRef: MatDialogRef<any>,
    private familyService: FamilyService,
    private routerService: RouterService,
    private fb: FormBuilder,
    private addressVerificationService: AddressVerificationService,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {

    this.addressErrors$.next(null);
    this.family$ = this.familyService.getFamily({ institutionId: this.routerService.institutionId, familyId: this.routerService.familyId }).pipe(filter(family => family != null));

    this.family$.subscribe(family => {
      this.familyAddress = this.fb.group({
        addressLine1: [family.address.addressLine1],
        addressLine2: [family.address.addressLine2],
        city: [family.address.city],
        state: [family.address.state],
        postalCode: [family.address.postalCode],
        country: [family.address.country],
      });
    });
  }

  public save() {
    this.familyAddress.disable();
    this.addressVerificationService.verify({ institutionId: this.routerService.institutionId, familyId: this.routerService.familyId, address: this.familyAddress.value }).subscribe({
      next: (res: IAddressVerificationResultDto) => {
        if (res.status === eAddressVerificationStatus.Verified) {
          this.familyService.refreshFamily();
          this.close();
        } else if (res.status === eAddressVerificationStatus.DuplicateFamilyAddressFound) {
        } else if (res.status === eAddressVerificationStatus.MultipleAddressMatches) {
          this.showMultipleAddressMatches = true;
          this.addressMatches = res.addressMatches;
        } else if (res.status === eAddressVerificationStatus.ExactMatchNotFound) {
          this.addressErrors$.next('Address Match Not Found');
          this.familyAddress.setErrors({ matchNotFound: true });
        } else if (res.status === eAddressVerificationStatus.NoMatchesFound) {
          this.addressErrors$.next('No Matches Found, Contact Your County Administrator.');
          this.familyAddress.setErrors({ matchNotFound: true });
        }
        this.familyAddress.enable();
        this.cdr.markForCheck();
      }, error: error => {
        this.familyAddress.enable();
      }
    });
  }

  public chooseAddress(address: IAddressDto) {
    this.showMultipleAddressMatches = false;
    this.addressMatches = [];

    this.addressVerificationService.verify({ institutionId: this.routerService.institutionId, familyId: this.routerService.familyId, address }).subscribe((res: IAddressVerificationResultDto) => {
      this.familyService.refreshFamily();
      this.close();
    });
  }

  public close() {
    this.dialogRef.close();
  }
}
