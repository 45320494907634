export enum eBroadcastEmailDeliveryStatus {
    Processed = 'Processed',
    Dropped = 'Dropped',
    Delivered = 'Delivered',
    Deferred = 'Deferred',
    Bounce = 'Bounce',
    Blocked = 'Blocked',
    Open = 'Open',
    Click = 'Click',
    SpamReport = 'SpamReport',
    Unsubscribe = 'Unsubscribe',
}
