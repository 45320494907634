<ng4h-dynamic-options-root [formDisplayTypes]="formDisplayTypes" [control]="control"
  (rootInputModified)="controlModified.emit($event)" (validatorAddOrModify)="validatorAddOrModify.emit($event)"
  (validatorRemove)="validatorRemove.emit($event)">
  <div class="row">
    <div class="col-12">
      <label class="form-control-label">Control Properties</label>
      <button class="btn btn-primary btn-sm float-right" (click)="addDropDownOption()">Add Item</button>
    </div>
  </div>
  <br>
  <table class="col-12">
    <thead>
      <th>Order</th>
      <th>Label</th>
      <th>Report Label</th>
      <th># Available</th>
      <th *ngIf="formType == eFormType.Registration">Fee</th>
      <th>Remove</th>
    </thead>
    <tbody cdkDropList (cdkDropListDropped)="drop($event)">
      <tr *ngFor="let item of control.items;let i = index; trackBy: trackByFn" cdkDrag>
        <td>
          <div cdkDragHandle class="drag-handle" style="display: inline-block;">
            <i class="ng4h-margin-left far fa-sort"></i>
          </div>
        </td>
        <td>
          <input type="text" class="form-control" [ngModel]="item.label"
            (ngModelChange)="changeItemLabel(item.itemId, $event)" aria-label="add-option-to-dropdown"
            aria-describedby="trash">
        </td>
        <td>
          <input type="text" class="form-control" [ngModel]="item.reportValue"
            (ngModelChange)="reportLabelChange($event, item.itemId)" aria-label="add-option-to-dropdown"
            aria-describedby="trash">
        </td>
        <td>
          <input class="form-control" type="number" [ngModel]="item.attributes.quantityAvailable"
            (ngModelChange)="changeQuantityAvailable(item.itemId, $event)">
        </td>
        <td *ngIf="formType == eFormType.Registration">
          <input class="form-control" type="number" [ngModel]="item.rate" step="0.01"
            (ngModelChange)="changeItemRate(item.itemId, $event)">
        </td>
        <td class="center">
          <div style="cursor: pointer;" *ngIf="i != 0" id="trash" (click)="removeDropDownOption(item)">
            <i class="fa fa-trash-alt"></i>
          </div>
        </td>
      </tr>
    </tbody>
  </table>


  <div *ngIf="enableLimitTo" class="form-group row">
    <mat-checkbox class="offset-md-3 col-md-9" [(ngModel)]="limitChoices" (ngModelChange)="limitChoicesChanged($event)">
      Limit Choices
    </mat-checkbox>
  </div>

  <div *ngIf="limitChoices" class="form-group row">

    <label class="col-md-3 form-control-label">Limit to</label>
    <div class="input-group col-md-9">
      <input [(ngModel)]="limitTo"
        (ngModelChange)="validatorAddOrModify.emit({ name: eDynamicValidatorName.MaxAllowedSelections, arg: $event })"
        class="form-control" type="number" [min]="1" [max]="control.items.length - 1">

    </div>
    <div class="offset-md-3 col-md-9"> of {{control.items.length}}</div>
  </div>

</ng4h-dynamic-options-root>