import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { eFormControlItemType, eFormControlType, IDynamicControl } from 'app/core/models';

@Component({
  selector: 'ng4h-dynamic-form-input',
  templateUrl: './dynamic-form-input.component.html',
  styleUrls: ['./dynamic-form-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicFormInputComponent implements OnInit, OnChanges {

  @Input() control: IDynamicControl;
  @Input() form: FormGroup;
  @Input() readOnly: boolean;

  public eFormControlType = eFormControlType;
  public eFormControlItemType = eFormControlItemType;

  constructor() {

  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.readOnly?.currentValue === true && this.form.controls?.[this.control.controlId]?.enabled === true) {
      this.form.controls?.[this.control.controlId]?.disable();
    } else if (changes.readOnly?.currentValue === false && this.form.controls?.[this.control.controlId]?.disabled === true) {
      this.form.controls?.[this.control.controlId]?.enable();
    }
  }

  ngOnInit() {
  }
}
