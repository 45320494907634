import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ng4h-rendered',
  templateUrl: './rendered.component.html',
  styleUrls: ['./rendered.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RenderedComponent implements OnInit, AfterViewInit {

  @Output() rendered: EventEmitter<void> = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.rendered.emit();
  }
}
