import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  IFamilyAssociateWithUserAccountEmailDto,
  IFamilyDashboardSummaryDto,
  IFamilyDescriptorDto,
  IFamilyDto,
  IFamilyEmailDto,
  IFamilyEventDto,
  IFamilyUpdateDto,
  IInstitutionFamilyParams,
  IInstitutionFamilyProgramParams,
  IManagerNoteCreateDto,
  IManagerNoteDto,
  IManagerNoteUpdateDto,
  IUserAccountLogin,
} from 'app/core/models';

export const FamilyLoadAction = createAction(
  '[family] Load',
  props<IInstitutionFamilyParams>()
);
export const FamilyLoadSuccessAction = createAction(
  '[family] Loaded Successfully',
  props<IInstitutionFamilyParams & { family: IFamilyDto }>()
);
export const FamilyLoadErrorAction = createAction(
  '[family] Error loading family ',
  props<{ error: HttpErrorResponse }>()
);

export const FamilyLoadDashboardAction = createAction(
  '[family] Load dashboard',
  props<IInstitutionFamilyParams>()
);
export const FamilyLoadDashboardSuccessAction = createAction(
  '[family] Loaded dashboard Successfully',
  props<IInstitutionFamilyParams & { familyDashboard: IFamilyDashboardSummaryDto }>()
);
export const FamilyLoadDashboardErrorAction = createAction(
  '[family] Error loading family dashboard',
  props<{ error: HttpErrorResponse }>()
);

export const FamilyLoadDescriptorAction = createAction(
  '[family] Load descriptor',
  props<IInstitutionFamilyParams>()
);
export const FamilyLoadDescriptorSuccessAction = createAction(
  '[family] Loaded descriptor Successfully',
  props<IInstitutionFamilyParams & { familyDescriptor: IFamilyDescriptorDto }>()
);
export const FamilyLoadDescriptorErrorAction = createAction(
  '[family] Error loading family descriptor',
  props<{ error: HttpErrorResponse }>()
);

export const FamilyInvalidateCacheAction = createAction(
  '[family] Invalidate cache'
);

export const FamilyUpdateAction = createAction(
  '[family] Update',
  props<IInstitutionFamilyParams & { familyUpdate: IFamilyUpdateDto }>()
);
export const FamilyUpdateSuccessAction = createAction(
  '[family] Update success',
  props<IInstitutionFamilyParams & { familyUpdate: IFamilyUpdateDto }>()
);
export const FamilyUpdateErrorAction = createAction(
  '[family] Update error',
  props<{ error: HttpErrorResponse }>()
);

export const FamilyUpdateCountyAction = createAction(
  '[family] Update county',
  props<IInstitutionFamilyParams & { countyId: string, countyAreaId: string }>()
);
export const FamilyUpdateCountySuccessAction = createAction(
  '[family] Update county success',
  props<IInstitutionFamilyParams & { countyId: string, countyAreaId: string }>()
);
export const FamilyUpdateCountyErrorAction = createAction(
  '[family] Update county error',
  props<{ error: HttpErrorResponse }>()
);

export const FamilyLoadEventCatalogAction = createAction(
  '[family] Load family event catalog',
  props<IInstitutionFamilyParams>()
);
export const FamilyLoadEventCatalogSuccessAction = createAction(
  '[family] Load family event catalog success',
  props<IInstitutionFamilyParams & { events: IFamilyEventDto[] }>()
);
export const FamilyLoadEventCatalogErrorAction = createAction(
  '[family] Load family event catalog error',
  props<{ error: HttpErrorResponse }>()
);


export const FamilyAssociateWithUserAccountAction = createAction(
  '[family] Associate with user account',
  props<IInstitutionFamilyParams & { associateWithUserAccountEmail: IFamilyAssociateWithUserAccountEmailDto }>()
);
export const FamilyAssociateWithUserAccountSuccessAction = createAction(
  '[family] Associate with user account success',
  props<IInstitutionFamilyParams & { associateWithUserAccountEmail: IFamilyAssociateWithUserAccountEmailDto }>()
);
export const FamilyAssociateWithUserAccountErrorAction = createAction(
  '[family] Associate with user account error',
  props<{ error: HttpErrorResponse }>()
);

export const LoadFamilyLoginsAction = createAction(
  '[family] Load family logins',
  props<IInstitutionFamilyParams>()
);
export const LoadFamilyLoginsSuccessAction = createAction(
  '[family] Load family logins success',
  props<IInstitutionFamilyParams & { logins: IUserAccountLogin[] }>()
);
export const LoadFamilyLoginsErrorAction = createAction(
  '[family] Load family logins error',
  props<{ error: HttpErrorResponse }>()
);

export const LoadFamilyEmailsAction = createAction(
  '[family] Load family emails',
  props<IInstitutionFamilyParams>()
);
export const LoadFamilyEmailsSuccessAction = createAction(
  '[family] Load family emails success',
  props<IInstitutionFamilyParams & { emails: IFamilyEmailDto[] }>()
);
export const LoadFamilyEmailsErrorAction = createAction(
  '[family] Load family emails error',
  props<{ error: HttpErrorResponse }>()
);


////

export const GetFamilyNotesAction = createAction(
  '[family] Get family notes',
  props<IInstitutionFamilyProgramParams>()
);
export const GetFamilyNotesSuccessAction = createAction(
  '[family] Get family notes success',
  props<IInstitutionFamilyProgramParams & { profileNotes: IManagerNoteDto[] }>()
);
export const GetFamilyNotesErrorAction = createAction(
  '[family] Get family notes error',
  props<{ error: HttpErrorResponse }>()
);

export const CreateFamilyNotesAction = createAction(
  '[family] Create family notes',
  props<IInstitutionFamilyProgramParams & { noteCreateDto: IManagerNoteCreateDto }>()
);
export const CreateFamilyNotesSuccessAction = createAction(
  '[family] Create family notes success',
  props<IInstitutionFamilyProgramParams & { noteCreateDto: IManagerNoteCreateDto }>()
);
export const CreateFamilyNotesErrorAction = createAction(
  '[family] Create family notes error',
  props<{ error: HttpErrorResponse }>()
);

export const UpdateFamilyNotesAction = createAction(
  '[family] Update family notes',
  props<IInstitutionFamilyProgramParams & { profileNoteId: string, noteUpdateDto: IManagerNoteUpdateDto }>()
);
export const UpdateFamilyNotesSuccessAction = createAction(
  '[family] Update family notes success',
  props<IInstitutionFamilyProgramParams & { profileNoteId: string, noteUpdateDto: IManagerNoteUpdateDto }>()
);
export const UpdateFamilyNotesErrorAction = createAction(
  '[family] Update family notes error',
  props<{ error: HttpErrorResponse }>()
);

export const DeleteFamilyNotesAction = createAction(
  '[family] Delete family notes',
  props<IInstitutionFamilyProgramParams & { profileNoteId: string }>()
);
export const DeleteFamilyNotesSuccessAction = createAction(
  '[family] Delete family notes success',
  props<IInstitutionFamilyProgramParams & { profileNoteId: string }>()
);
export const DeleteFamilyNotesErrorAction = createAction(
  '[family] Delete family notes error',
  props<{ error: HttpErrorResponse }>()
);

export const DownloadFamilyNotesFileAction = createAction(
  '[family] Download family notes file',
  props<IInstitutionFamilyProgramParams & { profileNoteId: string }>()
);
export const DownloadFamilyNotesFileSuccessAction = createAction(
  '[family] Download family notes file success',
  props<IInstitutionFamilyProgramParams & { profileNoteId: string, file: Blob }>()
);
export const DownloadFamilyNotesFileErrorAction = createAction(
  '[family] Download family notes file error',
  props<{ error: HttpErrorResponse }>()
);

export const DeleteFamilyNotesFileAction = createAction(
  '[family] Delete family notes file',
  props<IInstitutionFamilyProgramParams & { profileNoteId: string }>()
);
export const DeleteFamilyNotesFileSuccessAction = createAction(
  '[family] Delete family notes file success',
  props<IInstitutionFamilyProgramParams & { profileNoteId: string }>()
);
export const DeleteFamilyNotesFileErrorAction = createAction(
  '[family] Delete family notes file error',
  props<{ error: HttpErrorResponse }>()
);

export const AddFamilyNotesFileAction = createAction(
  '[family] Add family notes file',
  props<IInstitutionFamilyProgramParams & { profileNoteId: string, formData: FormData }>()
);
export const AddFamilyNotesFileSuccessAction = createAction(
  '[family] Add family notes file success',
  props<IInstitutionFamilyProgramParams & { profileNoteId: string, formData: FormData }>()
);
export const AddFamilyNotesFileErrorAction = createAction(
  '[family] Add family notes file error',
  props<{ error: HttpErrorResponse }>()
);

