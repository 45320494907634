<div class="modal-header">
  <div class="modal-title">Add Manager</div>
  <button (click)="close()" type="button" class="close" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <mat-horizontal-stepper>
    <mat-step [stepControl]="userForm">
      <ng-template matStepLabel>Account Details</ng-template>

      <form [formGroup]="userForm">
        <div class="form-group col-sm-12">
          <label class="form-control-label">Email <span *ngIf="data.useraccounttoken" class="ng4h-danger"> (Existing
              User)</span></label>
          <input formControlName="email" type="text" class="form-control">
        </div>
        <div class="form-group col-sm-12">
          <label class="form-control-label">First Name</label>
          <input formControlName="firstName" type="text" class="form-control">
        </div>
        <div class="form-group col-sm-12">
          <label class="form-control-label">Last Name</label>
          <input formControlName="lastName" type="text" class="form-control">
        </div>
        <div class="form-group col-sm-12">
          <label class="form-control-label">Role</label>
          <select class="form-control" formControlName="role">
            <option [ngValue]="eAccessControlListRoleType.HierarchyAdmin">Admin</option>
            <option [ngValue]="eAccessControlListRoleType.HierarchyStaff">Staff</option>
          </select>
        </div>
      </form>

      <button [disabled]="userForm.invalid" class="btn btn-primary float-right" matStepperNext>Next</button>


    </mat-step>

    <mat-step>
      <ng-template matStepLabel>Choose Hierarchy Area</ng-template>

      <ng4h-hierarchy-picker></ng4h-hierarchy-picker>

      <button [disabled]="userForm.invalid" class="btn btn-primary float-right" (click)="add()">Add</button>

      <button class="btn btn-secondary float-right ng4h-margin-right" matStepperPrevious>Back</button>

    </mat-step>

    <!-- <mat-step>
      <form [formGroup]="permissionForm">
        <ng-template matStepLabel>Permissions</ng-template>

        <ng4h-permission-input [form]="permissionForm.controls['animalIdentification']"
          [placeholder]="'Animal Identification'"></ng4h-permission-input>
        <ng4h-permission-input [form]="permissionForm.controls['healthForm']" [placeholder]="'Health Form'">
        </ng4h-permission-input>
        <ng4h-permission-input [form]="permissionForm.controls['volunteerScreening']"
          [placeholder]="'Volunteer Screening'"></ng4h-permission-input>
        <ng4h-permission-input [form]="permissionForm.controls['enrollmentForm']" [placeholder]="'Enrollment Form'">
        </ng4h-permission-input>
        <ng4h-permission-input [form]="permissionForm.controls['programSettings']" [placeholder]="'Program Settings'">
        </ng4h-permission-input>
        <ng4h-permission-input [form]="permissionForm.controls['managers']" [placeholder]="'Managers'">
        </ng4h-permission-input>
        <ng4h-permission-input [form]="permissionForm.controls['consents']" [placeholder]="'Consents'">
        </ng4h-permission-input>
        <ng4h-permission-input [form]="permissionForm.controls['trainings']" [placeholder]="'Trainings'">
        </ng4h-permission-input>
        <ng4h-permission-input [form]="permissionForm.controls['units']" [placeholder]="'Units'">
        </ng4h-permission-input>
        <ng4h-permission-input [form]="permissionForm.controls['projects']" [placeholder]="'Projects'">
        </ng4h-permission-input>

      </form>
    </mat-step> -->
  </mat-horizontal-stepper>
</div>

<!-- <div class="modal-footer">
  <button [disabled]="userForm.invalid" (click)="add()" class="btn btn-primary my-2 my-sm-0" role="button"
    aria-pressed="true">Add</button>
</div> -->
