import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  AutoUnsubscribe,
  eHierarchyNodeType,
  ePermissionState,
  eProfileVolunteerScreeningStatus,
  eStandardFieldStatus,
  IProfileVolunteerScreeningDto,
} from 'app/core/models';
import { CommonToastrService, MemberScreeningsService, ModalService, RouterService } from 'app/core/services';
import { ManagerScreeningsService } from 'app/core/services/manager/screenings/manager-screenings.service';
import { filter, Observable, take, takeUntil } from 'rxjs';

import { ModalChangeScreeningLevelComponent } from '../modal-change-screening-level/modal-change-screening-level.component';

@Component({
  selector: 'ng4h-modal-update-screening',
  templateUrl: './modal-update-screening.component.html',
  styleUrls: ['./modal-update-screening.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalUpdateScreeningComponent extends AutoUnsubscribe implements OnInit {

  public ePermissionState = ePermissionState;
  public eHierarchyNodeType = eHierarchyNodeType;
  public screening$: Observable<IProfileVolunteerScreeningDto>;
  public eProfileVolunteerScreeningStatus = eProfileVolunteerScreeningStatus;

  public form: FormGroup;
  public eStandardFieldStatus = eStandardFieldStatus;

  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: { memberId: string, profileScreeningId: string },
    private fb: FormBuilder,
    private memberScreeningService: MemberScreeningsService,
    private routerService: RouterService,
    private modalService: ModalService,
    private managerScreeningsService: ManagerScreeningsService,
    private toastrService: CommonToastrService
  ) {
    super();
  }

  ngOnInit() {
    this.screening$ = this.memberScreeningService.getVolunteerScreening({ institutionId: this.routerService.institutionId, memberId: this.data.memberId, profileScreeningId: this.data.profileScreeningId }).pipe(
      filter(screening => screening != null));

    this.screening$.pipe(
      take(1)
    ).subscribe(screening => {

      this.form = this.fb.group({
        restrictions: [{ value: screening.restrictions, disabled: screening.restrictions === eProfileVolunteerScreeningStatus.Restricted }],
        managerComment: [{ value: screening.managerComment, disabled: (screening.status !== eProfileVolunteerScreeningStatus.Submitted && screening.status !== eProfileVolunteerScreeningStatus.Incomplete) }],
        status: [{ value: screening.status, disabled: screening.status !== eProfileVolunteerScreeningStatus.Submitted }, [Validators.required]],
        nextScreeningYear: [{ disabled: true, value: screening.nextScreeningYear }]
      });
      // if (disabled) {
      //   this.form.disable();
      // }

      this.form.controls.status.valueChanges.pipe(takeUntil(this.autoUnsubscribe)).subscribe(status => {
        if (status !== eProfileVolunteerScreeningStatus.Restricted) {
          this.form.controls.restrictions.setValue(null);
        }
      });
    });

  }

  public changeScreeningStatus() {
    this.form.controls.restrictions.enable();
    this.form.controls.managerComment.enable();
    this.form.controls.status.enable();
  }

  public changeNextScreeningYear() {
    this.form.controls.managerComment.enable();
    this.form.controls.nextScreeningYear.enable();
  }

  public changeScreeningLevel() {
    this.modalService.openModal(ModalChangeScreeningLevelComponent, { data: this.data });
  }

  public close(): void {
    this.dialogRef.close();
  }

  public update(): void {
    this.dialogRef.close(this.form.value);
  }

  public retainOrder() {
    return 0;
  }

  public sendBack(screening: IProfileVolunteerScreeningDto) {
    this.modalService.confirm({ header: 'Send Back?', body: 'Are you sure you want to send back this screening?' }).pipe(
      take(1),
      filter(res => res === true)
    ).subscribe(() => {

      this.managerScreeningsService.sendBackScreening({
        institutionId: this.routerService.institutionId,
        managerId: this.routerService.managerId,
        programId: this.routerService.programId,
        profileScreeningId: screening.profileScreeningId,
        update: {
          status: eProfileVolunteerScreeningStatus.Incomplete,
          managerComment: this.form.controls.managerComment.getRawValue(),
          restrictions: this.form.controls.restrictions.getRawValue(),
          nextScreeningYear: this.form.controls.nextScreeningYear.getRawValue()
        }
      }).pipe(
        take(1),
        this.modalService.showProgressSpinnerModalUntilComplete()
      ).subscribe({
        next: () => {
          this.toastrService.success('Sent Back');
          this.close();
        }, error: (error) => {
          this.toastrService.error('Could not send back', error);
        }
      });

    });
  }

  public delete(screening: IProfileVolunteerScreeningDto) {
    this.modalService.confirm({ header: 'Delete?', body: 'Are you sure you want to delete this screening?' }).pipe(
      take(1),
      filter(res => res === true)
    ).subscribe(() => {
      this.close();
      this.managerScreeningsService.deleteScreening({
        institutionId: this.routerService.institutionId,
        managerId: this.routerService.managerId,
        programId: this.routerService.programId,
        profileScreeningId: screening.profileScreeningId,
      }).pipe(
        take(1),
        this.modalService.showProgressSpinnerModalUntilComplete()
      ).subscribe({
        next: () => {
          this.toastrService.success('Screening Deleted');
        }, error: (error) => {
          this.toastrService.error('Could not delete screening', error);
        }
      });

    });
  }
}
