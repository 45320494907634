<div class="modal-header">
    <h5 class="modal-title">Edit Second Family</h5>
    <button (click)="close()" type="button" class="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form *ngIf="form != null" [formGroup]="form">
        <div class="form-group row">
            <label class="form-control-label col-md-3">Name</label>
            <div class="col-md-9">
                <input formControlName="secondFamilyName" class="form-control" type="text">
            </div>
        </div>
        <div class="form-group row">
            <label class="form-control-label col-md-3">First Names</label>
            <div class="col-md-9">
                <input formControlName="secondFamilyFirstNames" class="form-control" type="text">
            </div>
        </div>
        <div class="form-group row">
            <label class="form-control-label col-md-3">Email</label>
            <div class="col-md-9">
                <input formControlName="secondFamilyEmail" class="form-control" type="text">
            </div>
        </div>
        <div class="form-group row">
            <label class="form-control-label col-md-3">Phone</label>
            <div class="col-md-9">
                <input formControlName="secondFamilyPhone" class="form-control" type="text">
            </div>
        </div>

        <div class="form-group row">
            <label class="form-control-label col-md-3">Address</label>
            <div class="col-md-9">
                <input formControlName="secondFamilyAddressLine1" class="form-control" type="text">
            </div>
        </div>
        <div class="form-group row">
            <label class="form-control-label col-md-3">Address 2</label>
            <div class="col-md-9">
                <input formControlName="secondFamilyAddressLine2" class="form-control" type="text">
            </div>
        </div>
        <div class="form-group row">
            <label class="form-control-label col-md-3">City</label>
            <div class="col-md-9">
                <input formControlName="secondFamilyCity" class="form-control" type="email">
            </div>
        </div>
        <div class="form-group row">
            <label class="form-control-label col-md-3">State</label>
            <div class="col-md-9">
                <!-- <input formControlName="state" class="form-control" type="text"> -->
                <ng4h-state-dropdown formControlName="secondFamilyState"></ng4h-state-dropdown>
            </div>
        </div>
        <div class="form-group row">
            <label class="form-control-label col-md-3">Postal Code</label>
            <div class="col-md-9">
                <input formControlName="secondFamilyPostalCode" class="form-control" type="text">
            </div>
        </div>


    </form>
</div>
<div class="modal-footer">
    <button class="btn btn-primary" (click)="save()">Save</button>
    <button class="btn btn-secondary" (click)="close()">Cancel</button>
</div>