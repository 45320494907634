import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'ng4h-admin-page-header',
  templateUrl: './admin-page-header.component.html',
  styleUrls: ['./admin-page-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminPageHeaderComponent implements OnInit {


  @Input() title: string;

  constructor() { }

  ngOnInit() {
  }

}
