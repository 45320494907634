<div class="modal-header">
    <h5 class="modal-title">{{(eUnitType.Club | programAlias$ | async)?.singular}} Swap</h5>
    <button (click)="close()" type="button" class="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">

    <form [formGroup]="form" class="row">
        <div class="col-12">
            <div class="form-group">
                <label class="form-control-label">{{(eUnitType.Club | programAlias$ | async)?.singular}} To Transfer
                    Projects From</label>
                <select class="form-control" formControlName="sourceUnitId">
                    <option class="form-control" [value]="unit.unitId">
                        {{unit.unitName}}</option>
                </select>
            </div>
            <div class="form-group ng4h-margin-top" *ngIf="programSettings$ | async as programSettings">
                <label class="form-control-label">{{(eUnitType.Club | programAlias$ | async)?.singular}} To Transfer
                    Projects To County</label>
                <ng4h-county-area-select formControlName="selectedCountyAreaId">
                </ng4h-county-area-select>
            </div>
            <div class="form-group">
                <label class="form-control-label">{{(eUnitType.Club | programAlias$ | async)?.singular}} To Transfer
                    Projects To</label>
                <select class="form-control" formControlName="targetUnitId">
                    <option [ngValue]="null"></option>
                    <option *ngFor="let unit of remainingUnits$ | async" class="form-control" [value]="unit.unitId">
                        {{unit.unitName}}</option>
                </select>
            </div>

            <div class="ng4h-border-right ng4h-border-top ng4h-border-left"
                *ngIf="projectTransfers$ | async as projectTransfers">
                <div class="ng4h-border-bottom ng4h-padding ng4h-bg-danger"
                    *ngFor="let nonTransferableProject of projectTransfers.nonTransferableProjects">
                    <mat-checkbox [checked]="false" [disabled]="true" class="ng4h-margin-right"></mat-checkbox>
                    {{nonTransferableProject.projectName}} (Cannot Move)
                </div>
                <div class="ng4h-border-bottom ng4h-padding"
                    *ngFor="let transferableProject of projectTransfers.transferableProjects; let i =  index;">
                    <mat-checkbox [checked]="true" [disabled]="true" class="ng4h-margin-right"></mat-checkbox>
                    {{transferableProject.projectName}}
                </div>
            </div>
        </div>
    </form>


</div>
<div class="modal-footer">
    <button class="btn btn-primary" (click)="save()">Save</button>
    <button class="btn btn-secondary" (click)="close()">Cancel</button>
</div>