<span class="ql-formats">
    <select class="ql-font"></select>
    <select class="ql-size">
        <option value="10px">Small</option>
        <option selected>Normal</option>
        <option value="18px">Large</option>
        <option value="32px">Huge</option>
    </select>
</span>
<span class="ql-formats">
    <button class="ql-bold"></button>
    <button class="ql-italic"></button>
    <button class="ql-underline"></button>
    <button class="ql-strike"></button>
</span>
<span class="ql-formats">
    <select class="ql-color">
        <option selected></option>
        <option value="red"></option>
        <option value="orange"></option>
        <option value="yellow"></option>
        <option value="green"></option>
        <option value="blue"></option>
        <option value="purple"></option>
    </select>
    <select class="ql-background">
        <option selected></option>
        <option value="red"></option>
        <option value="orange"></option>
        <option value="yellow"></option>
        <option value="green"></option>
        <option value="blue"></option>
        <option value="purple"></option>
    </select>
</span>
<span class="ql-formats">
    <button class="ql-script" value="sub"></button>
    <button class="ql-script" value="super"></button>
</span>
<span class="ql-formats">
    <button class="ql-header" value="1"></button>
    <button class="ql-header" value="2"></button>
    <button class="ql-blockquote"></button>
    <button class="ql-code-block"></button>
</span>
<span class="ql-formats">
    <button class="ql-list" value="ordered"></button>
    <button class="ql-list" value="bullet"></button>
</span>
<span class="ql-formats">
    <button class="ql-direction" value="rtl"></button>
    <select class="ql-align"></select>
</span>
<span class="ql-formats">
    <button class="ql-link"></button>
    <button class="ql-image"></button>
    <button class="ql-video"></button>
    <button class="ql-formula"></button>
</span>
<span class="ql-formats">
    <button class="ql-clean"></button>
</span>

<span class="ql-formats">
    <button class="ql-html" (click)="editHtml()">HTML</button>
</span>