import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import {
  getSystemManagerScreening,
  SystemManagerFeatureStore
} from 'app/core/containers/admin/system-manager/system-manager-feature.reducers';
import {
  SystemManagerScreeningActions
} from 'app/core/containers/admin/system-manager/system-manager-screenings/store';
import {
  eScreeningType,
  IInstitutionSystemManagerProgramParams,
  ISuperuserInstitutionProgramParams,
  IVolunteerScreeningDto,
  IVolunteerScreeningUpdateDto,
  IVolunteerScreeningUpdateFormDto
} from 'app/core/models';
import { filter, map, Observable, of, take, tap } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SystemManagerScreeningsService {

  constructor(
    private store: Store<SystemManagerFeatureStore>,
    private httpClient: HttpClient,
    private dispatcher: ActionsSubject) {
  }

  // public getScreenings(params: IInstitutionProgramParams): Observable<IVolunteerScreeningDto[]> {
  //   if (Object.keys(params).find(key => params[key] == null) != null) {
  //     return of(null);
  //   }
  //   return this.store.pipe(
  //     select(getSystemManagerScreenings(params)),
  //     tap(screenings => {
  //       if (screenings === undefined) {
  //         this.store.dispatch(SystemManagerScreeningActions.SystemManagerScreeningsLoadAction(params));
  //       }
  //     }),
  //     filter(screenings => screenings != null),
  //   );
  // }

  // public loadScreeningsEffect({ institutionId, programId }: IInstitutionProgramParams): Observable<IVolunteerScreeningDto[]> {
  //   return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/programs/${programId}/screenings`) as Observable<IVolunteerScreeningDto[]>;
  // }

  public getScreening(params: IInstitutionSystemManagerProgramParams & { screeningType: eScreeningType }): Observable<IVolunteerScreeningDto> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getSystemManagerScreening(params)),
      tap(screening => {
        if (screening === undefined) {
          this.store.dispatch(SystemManagerScreeningActions.SystemManagerScreeningLoadAction(params));
        }
      }),
      filter(screening => screening != null)
    );
  }

  public loadScreeningEffect({
    institutionId,
    systemManagerId,
    programId,
    screeningType
  }: IInstitutionSystemManagerProgramParams & { screeningType: eScreeningType }): Observable<IVolunteerScreeningDto> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/volunteer-screening/${screeningType}`) as Observable<IVolunteerScreeningDto>;
  }

  public updateScreenings(params: ISuperuserInstitutionProgramParams & { screeningType: eScreeningType, screeningUpdate: IVolunteerScreeningUpdateDto }) {
    this.store.dispatch(SystemManagerScreeningActions.SystemManagerScreeningsUpdateAction(params));

    return this.dispatcher.pipe(
      ofType(SystemManagerScreeningActions.SystemManagerScreeningsUpdateSuccessAction, SystemManagerScreeningActions.SystemManagerScreeningsUpdateErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerScreeningActions.SystemManagerScreeningsUpdateSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateScreeningsEffect({
    superuserId,
    institutionId,
    programId,
    screeningType,
    screeningUpdate
  }: ISuperuserInstitutionProgramParams & { screeningType: eScreeningType, screeningUpdate: IVolunteerScreeningUpdateDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/super-users/${superuserId}/institutions/${institutionId}/programs/${programId}/volunteer-screening/${screeningType}`, screeningUpdate);
  }

  public updateScreeningsForm(params: IInstitutionSystemManagerProgramParams & { screeningType: eScreeningType, screeningFormUpdate: IVolunteerScreeningUpdateFormDto }) {
    this.store.dispatch(SystemManagerScreeningActions.SystemManagerScreeningsFormUpdateAction(params));

    return this.dispatcher.pipe(
      ofType(SystemManagerScreeningActions.SystemManagerScreeningsFormUpdateSuccessAction, SystemManagerScreeningActions.SystemManagerScreeningsFormUpdateErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerScreeningActions.SystemManagerScreeningsFormUpdateSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateScreeningsFormEffect({
    institutionId,
    systemManagerId,
    programId,
    screeningType,
    screeningFormUpdate
  }: IInstitutionSystemManagerProgramParams & { screeningType: eScreeningType, screeningFormUpdate: IVolunteerScreeningUpdateFormDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/volunteer-screening-form/${screeningType}`, screeningFormUpdate);
  }

  public updateScreeningsFormInternationalization(params: IInstitutionSystemManagerProgramParams & { screeningType: eScreeningType, localization: string, localizationUpdate: any }) {
    this.store.dispatch(SystemManagerScreeningActions.SystemManagerScreeningsFormInternationalizationUpdateAction(params));

    return this.dispatcher.pipe(
      ofType(SystemManagerScreeningActions.SystemManagerScreeningsFormInternationalizationUpdateSuccessAction, SystemManagerScreeningActions.SystemManagerScreeningsFormInternationalizationUpdateErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerScreeningActions.SystemManagerScreeningsFormInternationalizationUpdateSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateScreeningsFormInternationalizationEffect({
    institutionId,
    systemManagerId,
    programId,
    screeningType,
    localization,
    localizationUpdate
  }: IInstitutionSystemManagerProgramParams & { screeningType: eScreeningType, localization: string, localizationUpdate: any }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/volunteer-screening-form/${screeningType}/${localization}`, localizationUpdate);
  }
}
