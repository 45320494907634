import {
  IAnimalDescriptorDto,
  IEnrollmentDto,
  IMemberProgramYearApprovalStateDto,
  IProgramYearDto,
  IProjectLeaderDto,
} from '.';
import { eMemberProgramYearStatus, eProgramType, IMemberVolunteerDto } from '..';
import { IUnitLeaderDto } from './IUnitLeaderDto';

export interface IMemberProgramYearDto {
    memberProgramYearId: string;
    programId: string;
    programType: eProgramType;
    programName?: string;
    active: boolean;
    memberProgramId: string;
    programYear: IProgramYearDto;
    enrollment: IEnrollmentDto;
    volunteerTypes?: IMemberVolunteerDto[];
    projectLeaders?: IProjectLeaderDto[];
    unitLeaders?: IUnitLeaderDto[];
    status: eMemberProgramYearStatus;
    animals?: IAnimalDescriptorDto[];
    appliedTrainingIds?: string[];
    appliedScreeningId: string;
    trainingsRequired: boolean;
    screeningRequired: boolean;
    paymentRequired: boolean;
    approvalState: IMemberProgramYearApprovalStateDto;
}
