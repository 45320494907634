<div class="ng4h-panel">
  <div class="col-12 ng4h-panel-header">
    <!--<i class="fa fa-shopping-cart float-right" aria-hidden="true" style="margin-top: 3px;"></i>-->
    <div class="row">
      <div [ngClass]="fullView ? 'col-6' : 'col-8'" style="font-size: 20px;margin-left: -5px;">Invoice
        <span *ngIf="showStatus"> <b> - {{ invoice.status }}</b></span>
      </div>
      <div  [ngClass]="fullView ? 'col-2' : 'd-none'" style="text-align: right;">Quantity</div>
      <div  [ngClass]="fullView ? 'col-2' : 'd-none'" style="text-align: right;">Rate</div>
      <div  [ngClass]="fullView ? 'col-2' : 'col-4'" style="text-align: right;">Total</div>
    </div>
  </div>
  <div class="col-12 ng4h-bg-secondary-light">
    <div *ngFor="let lineItem of invoice?.lineItems" class="ng4h-border-bottom-not-last ng4h-padding">
      <div class="row">
        <div [ngClass]="fullView ? 'col-6' : 'col-8'">{{ lineItem.description }}</div>
        <div [ngClass]="fullView ? 'col-2' : 'd-none'" style="text-align: right;border-right: 1px solid #efefef;">{{ lineItem.quantity | number }}</div>
        <div [ngClass]="fullView ? 'col-2' : 'd-none'" 
             style="text-align: right; border-right: 1px solid #efefef;white-space: nowrap">{{ (lineItem.invoiceItemType == 'Discount' ? -lineItem.rate : lineItem.rate) | currency }}
        </div>
        <div [ngClass]="fullView ? 'col-2' : 'col-4'" style="text-align: right;white-space: nowrap">{{ lineItem.amount | currency }}</div>
      </div>
    </div>
    <div class="ng4h-padding" style="margin-top: 8px;">
      <div class="row">
        <div class="col-8" style="text-align: right;"><b>Total:</b></div>
        <div class="col-4 ng4h-border-bottom-light" style="text-align: right;">
          <b>{{ (invoice?.amountDue) | enrollmentFee | currency }}</b>
        </div>
      </div>
    </div>
    <div class="ng4h-padding">
      <div class="row">
        <div class="col-8" style="text-align: right;"><b>Paid:</b></div>
        <div class="col-4 ng4h-border-bottom-light" style="text-align: right;">
          <b>{{ ((invoice?.amountPaid ?? 0) + (invoice?.creditsCollectedAmount ?? 0)) | enrollmentFee | currency }}</b>
        </div>
      </div>
    </div>
    <div class="ng4h-padding">
      <div class="row">
        <div class="col-8" style="text-align: right;"><b>Amount Due:</b></div>
        <div class="col-4 ng4h-border-bottom-light" style="text-align: right;">
          <b>{{ ((invoice?.amountOutstanding ?? 0) - (invoice?.creditsCollectedAmount ?? 0)) | enrollmentFee | currency }}</b>
        </div>
      </div>
    </div>
    <div *ngIf="invoice?.payments?.length > 0 || invoice?.credits?.length">
      <div *ngIf="invoice?.payments?.length > 0" class="invoice-header ng4h-padding-top ng4h-padding-left">
        <div class="row">
          <div class="col-12" style="padding-bottom: 8px;"><b>Payments:</b></div>
        </div>
      </div>
      <div class="ng4h-padding" *ngFor="let payment of invoice?.payments">
        <div class="row ng4h-border-bottom-light">
          <div class="col-4">{{ payment.paymentMethod.type }} - {{ payment.paymentMethod.maskedAccountNumber }}</div>
          <div class="col-2" [ngStyle]="{'color': (payment.status === 'Declined') ? '#df2e3eff' : ''}">{{ payment.status | camelToTitleCase }}
          </div>
          <div class="col-4">{{ payment.reasonForDecline }}</div>
          <div class="col-2" [ngStyle]="{'text-decoration': (payment.status === 'Declined') ? 'line-through' : ''}" style="text-align: right">
            <ng-container *ngIf="payment.status === 'Refunded'; else normal">
              <b>({{ payment.amount | currency }})</b>
            </ng-container>
            <ng-template #normal>
              <b #normal>{{ payment.amount | currency }}</b>
            </ng-template>
          </div>
        </div>
      </div>
      <ng-container *ngIf="invoice?.credits?.length > 0">
        <div class="invoice-header ng4h-padding-top ng4h-padding-left">
          <div class="row">
            <div class="col-12" style="padding-bottom: 8px;color: #555;"><b>Checks:</b></div>
          </div>
        </div>
        <div class="ng4h-padding">
          <div class="row ng4h-border-bottom-light">
            <div class="col-8">Owed to {{ invoice.creditsPayableTo?.hierarchyNodeName }}</div>
            <div class="col-4" style="text-align: right"><b>{{ (invoice.creditsTotalAmount) | enrollmentFee | currency }}</b>
            </div>
            <div *ngIf="invoice?.nonElectronicPaymentInstructions != null" class="col-12 payment-instructions">
              {{ invoice.nonElectronicPaymentInstructions }}
            </div>
          </div>
        </div>
        <div class="ng4h-padding" style="margin-top: 8px;">
          <div class="row">
            <div class="col-8" style="text-align: right;"><b>Collected:</b></div>
            <div class="col-4 ng4h-border-bottom-light" style="text-align: right;">
              <b>{{ invoice?.creditsCollectedAmount | enrollmentFee | currency }}</b>
            </div>
          </div>
        </div>
        <div class="ng4h-padding" style="margin-top: 8px;">
          <div class="row">
            <div class="col-8" style="text-align: right;"><b>Amount Due:</b></div>
            <div class="col-4 ng4h-border-bottom-light" style="text-align: right;">
              <b>{{ invoice?.nonElectronicPaymentAmountDue | enrollmentFee | currency }}</b>
            </div>
          </div>
        </div>

        <ng-container *ngIf="fullView && hasCreditCollections">
          <div class="invoice-header ng4h-padding-top ng4h-padding-left">
            <div class="row">
              <div class="col-12" style="padding-bottom: 8px;color: #555;"><b>Check Collections:</b></div>
            </div>
          </div>
          <ng-container *ngFor="let credit of invoice.credits">
            <div *ngFor="let collection of credit.collections">
              <div class="ng4h-padding">
                <div class="row ng4h-border-bottom-light">
                  <div class="col-2">Collected: {{ collection.collected ? 'Yes' : 'No' }}</div>
                  <div class="col-2">{{ collection.collectedDate | date : 'M/d/yyy' }}</div>
                  <div class="col-6">{{ collection.notes }}</div>
                  <div class="col-2" style="text-align: right">{{ collection.collectionAmount | currency }}</div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <div class="ng4h-padding" style="margin-top: 8px;">
          <div class="row">
            <div class="col-8" style="text-align: right;"><b>Total Collected:</b></div>
            <div class="col-4 ng4h-border-bottom-light" style="text-align: right;">
              <b>{{ invoice?.creditsCollectedAmount | enrollmentFee | currency }}</b>
            </div>
          </div>
        </div>

      </ng-container>
    </div>
  </div>
</div> 