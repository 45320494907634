<div class="modal-header">
  <h5 class="modal-title">{{header}}</h5>
  <button (click)="close()" type="button" class="close" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>{{body}}</p>
</div>
<div class="modal-footer">
  <button (click)="confirm()" class="btn btn-primary">Confirm</button>
  <button (click)="close()" class="btn btn-secondary">Cancel</button>
</div>
