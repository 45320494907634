import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { eUnitType, IUnitDto, IUnitUpdateDto } from 'app/core/models';
import { CommonToastrService, ManagerClubsService, RouterService } from 'app/core/services';
import { uuidv4 } from 'app/shared/utils';
import { take } from 'rxjs';

@Component({
  selector: 'ng4h-modal-edit-club',
  templateUrl: './modal-edit-club.component.html',
  styleUrls: ['./modal-edit-club.component.scss']
})

export class ModalEditClubComponent implements OnInit {
  public form: FormGroup;
  public eUnitType = eUnitType;
  constructor(
    private dialogRef: MatDialogRef<any>,
    private fb: FormBuilder,
    private managerUnitsService: ManagerClubsService,
    private routerService: RouterService,
    private toastrService: CommonToastrService,
    @Inject(MAT_DIALOG_DATA) public unit: IUnitDto
  ) {

  }

  public ngOnInit(): void {


    this.form = this.fb.group({
      unitName: [this.unit.unitName],
      isClub: [this.unit.isClub],
      chargeFee: [this.unit.chargeFee],
      deliveryModeId: [this.unit.deliveryModeId],
      leaderLoginCode: [this.unit.leaderLoginCode, Validators.maxLength(20)],
      stateWide: [this.unit.stateWide],
      notes: [this.unit.notes]
    });

  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public save(): void {

    const updatedUnit: IUnitUpdateDto = {
      isClub: this.unit.isClub,
      ein: this.unit.ein,
      integrated: this.unit.integrated,
      raciallyMixed: this.unit.raciallyMixed,
      stateWide: this.unit.stateWide,
      chartered: this.unit.chartered,
      chargeFee: this.unit.chargeFee,
      deliveryModeId: this.unit.deliveryModeId,
      countyAreaId: this.unit.countyAreaId,
      unitName: this.unit.unitName,
      contactName: this.unit.contactName,
      contactEmailAddress: this.unit.contactEmailAddress,
      contactAddress: this.unit.contactAddress,
      primaryPhone: this.unit.primaryPhone,
      mobilePhone: this.unit.mobilePhone,
      websiteUrl: this.unit.websiteUrl,
      meetingAddress: this.unit.meetingAddress,
      meetingLocation: this.unit.meetingLocation,
      meetingDay: this.unit.meetingDay,
      meetingTime: this.unit.meetingTime,
      restrictedToRoleType: this.unit.restrictedToRoleType,
      formResponses: this.unit.formResponses,

      ...this.form.value
    };

    this.managerUnitsService.updateUnit({
      institutionId: this.routerService.institutionId,
      managerId: this.routerService.managerId,
      programId: this.routerService.programId,
      unitId: this.unit.unitId,
      unit: updatedUnit
    }).pipe(
      take(1)
    ).subscribe({
      next: () => {
        this.toastrService.success('Update unit success');
        this.dialogRef.close();
      }, error: (error) => {
        this.toastrService.error('Unit update failed', error);
      }
    });
  }

  public generate() {
    this.form.controls.leaderLoginCode.setValue(uuidv4().slice(0, 6));
  }
}
