<div class="quick-help">
  <div class="row">
    <h4 class="col-6">
      <ng-content select="[ng4h-in-app-help-header]"></ng-content>
    </h4>
    <div class="col-6 quick-help-link">Quick Help</div>
  </div>
  <br>
  <ng-content select="[ng4h-in-app-help-body]"></ng-content>
</div>
