<div class="modal-header">
  <h5 class="modal-title">Add Units</h5>
  <button (click)="close()" type="button" class="close" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body" *ngIf="discount$ | async as discount">
  <form [formGroup]="form">
    <div class="form-group ng4h-margin-bottom">
      <label class="form-control-label">Delivery Mode</label>
      <ng4h-delivery-mode-dropdown formControlName="deliveryModeId">
      </ng4h-delivery-mode-dropdown>
    </div>
    <div *ngIf="discount.fund?.hierarchyNode?.hierarchyNodeType !== eHierarchyNodeType.CountyArea">
      <label class="form-control-label">County Filter</label>
      <ng4h-county-area-select [allowDeselect]="true" formControlName="countyAreaId"></ng4h-county-area-select>
    </div>
  </form>

  <div class="ng4h-margin-bottom"
    *ngIf="discount.fund?.hierarchyNode?.hierarchyNodeType == eHierarchyNodeType.CountyArea">
    <b class="ng4h-danger">Units Restricted to {{discount.fund.hierarchyNode.hierarchyNodeName}} County due to the
      '{{discount.fund.fundCode}}' fund.</b>
  </div>


  <table *ngIf="(clubs$ | async)?.length > 0" mat-table [dataSource]="dataSource" [trackBy]="trackBy">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <div style="margin-right: 20px">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </div>

      </td>
    </ng-container>

    <ng-container matColumnDef="unitName">
      <th mat-header-cell *matHeaderCellDef>Unit Name</th>
      <td mat-cell *matCellDef="let element"> {{element.unitName}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <!-- <ng-template #loading>
    <div class="col-12" style="text-align: center; margin-top: 20px;">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  </ng-template> -->

</div>
<div class="modal-footer">
  <button id (click)="close()" class="btn btn-secondary my-2 my-sm-0" role="button" aria-pressed="true">Cancel</button>
  <button (click)="save()" class="btn btn-primary my-2 my-sm-0" role="button" aria-pressed="true">Save</button>
</div>