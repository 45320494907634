import { Action, createReducer, on } from '@ngrx/store';

import { ProgramSettingsActions } from '.';
import { mergeImmutable } from '../utils';


export interface ProgramSettingsState {
    settings: { [institutionId: string]: { [programId: string]: any } };
}

const initialState: ProgramSettingsState = {
    settings: undefined
};

const reducer = createReducer(
    initialState,
    on(ProgramSettingsActions.ProgramSettingsLoadSuccessAction, (state, { institutionId, programId, settings }) => {
        const newProgramSettings = mergeImmutable(
            { [institutionId]: { [programId]: settings } },
            state.settings
        );
        return {
            ...state,
            settings: newProgramSettings
        };
    }),
    on(ProgramSettingsActions.ProgramSettingsInvalidateCacheAction, (state, { institutionId, programId }) => {
        const newProgramSettings = mergeImmutable(
            { [institutionId]: { [programId]: undefined } },
            state.settings
        );
        return {
            ...state,
            settings: newProgramSettings
        };
    })
);

export function programSettingsReducer(state: ProgramSettingsState | undefined, actions: Action) {
    return reducer(state, actions);
}

export const settings = (state: ProgramSettingsState) => state.settings;
