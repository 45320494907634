<div class="modal-header">
    <h5 class="modal-title"></h5>
    <button (click)="close()" type="button" class="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">

    <textarea rows="15" [(ngModel)]="data"></textarea>

</div>
<div class="modal-footer">
    <button id (click)="close()" class="btn btn-secondary my-2 my-sm-0" role="button"
        aria-pressed="true">Cancel</button>
    <button (click)="save()" class="btn btn-primary my-2 my-sm-0" role="button" aria-pressed="true">Save</button>
</div>