import { Action, createReducer, on } from '@ngrx/store';
import {
  IInstitutionProgramTrainingDto,
  IInstitutionSystemManagerProgramModel,
  ITrainingCourseDto,
  ITrainingDto,
  ITrainingLessonDto,
} from 'app/core/models';
import { mergeImmutable } from 'app/shared/utils';

import { SystemManagerTrainingActions } from '.';



export interface SystemManagerTrainingState {
  trainings: IInstitutionSystemManagerProgramModel<ITrainingDto[]>;
  trainingCourse: IInstitutionSystemManagerProgramModel<{ [trainingId: string]: { [trainingCourseId: string]: ITrainingCourseDto } }>;
  trainingLesson: IInstitutionSystemManagerProgramModel<{ [trainingId: string]: { [trainingCourseId: string]: { [trainingLessonId: string]: ITrainingLessonDto } } }>;
  programAssociations: IInstitutionSystemManagerProgramModel<IInstitutionProgramTrainingDto[]>;
}

const initialState: SystemManagerTrainingState = {
  trainings: undefined,
  trainingCourse: undefined,
  trainingLesson: undefined,
  programAssociations: undefined
};

const reducer = createReducer(
  initialState,
  on(SystemManagerTrainingActions.SystemManagerLoadTrainingsSuccessAction, (state, { institutionId, systemManagerId, programId, trainings }) => {
    return {
      ...state,
      trainings: mergeImmutable(
        { [institutionId]: { [systemManagerId]: { [programId]: trainings } } },
        state.trainings
      )
    };
  }),
  on(SystemManagerTrainingActions.SystemManagerInvalidateTrainingCacheAction, (state, { }) => {
    return initialState;
  }),
  on(SystemManagerTrainingActions.SystemManagerTrainingCourseLoadSuccessAction, (state, { institutionId, systemManagerId, programId, trainingId, trainingCourseId, trainingCourse }) => {
    return {
      ...state,
      trainingCourse: mergeImmutable(
        { [institutionId]: { [systemManagerId]: { [programId]: { [trainingId]: { [trainingCourseId]: trainingCourse } } } } },
        state.trainingCourse
      )
    };
  }),
  on(SystemManagerTrainingActions.SystemManagerTrainingLessonLoadSuccessAction, (state, { institutionId, systemManagerId, programId, trainingId, trainingCourseId, trainingLessonId, trainingLesson }) => {
    return {
      ...state,
      trainingLesson: mergeImmutable(
        { [institutionId]: { [systemManagerId]: { [programId]: { [trainingId]: { [trainingCourseId]: { [trainingLessonId]: trainingLesson } } } } } },
        state.trainingLesson
      )
    };
  }),
  on(SystemManagerTrainingActions.SystemManagerTrainingProgramLoadSuccessAction, (state, { institutionId, systemManagerId, programId, programAssociations }) => {
    return {
      ...state,
      programAssociations: mergeImmutable(
        { [institutionId]: { [systemManagerId]: { [programId]: programAssociations } } },
        state.programAssociations
      )
    };
  })
);

export function systemManagerTrainingsReducer(state: SystemManagerTrainingState | undefined, actions: Action) {
  return reducer(state, actions);
}


export const trainings = (state: SystemManagerTrainingState) => state.trainings;
export const trainingLesson = (state: SystemManagerTrainingState) => state.trainingLesson;
export const trainingCourse = (state: SystemManagerTrainingState) => state.trainingCourse;

export const programAssociations = (state: SystemManagerTrainingState) => state.programAssociations;