import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { eProfileType, IInstitution, IInstitutionDescriptorDto, IUserAccountDto } from 'app/core/models';
import { ModalService, RouterService, UserService } from 'app/core/services';
import { SuperuserService } from 'app/core/services/superuser.service';
import { filter, Observable, switchMap, take } from 'rxjs';

import { ModalSelectProgramComponent } from '../../admin/modal-select-program/modal-select-program.component';

@Component({
  selector: 'ng4h-modal-change-institution',
  templateUrl: './modal-change-institution.component.html',
  styleUrls: ['./modal-change-institution.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalChangeInstitutionComponent implements OnInit {

  public userAccount$: Observable<IUserAccountDto>;
  public institutions$: Observable<IInstitutionDescriptorDto[]>;
  public disableClose: boolean;
  public currentInstitutionId: string;
  constructor(
    private dialogRef: MatDialogRef<any>,
    private userService: UserService,
    private superuserService: SuperuserService,
    private routerService: RouterService,
    private modalService: ModalService
  ) {
    this.userAccount$ = this.userService.userAccount$;
    this.institutions$ = this.userAccount$.pipe(
      switchMap(userAccount => {
        return this.superuserService.getInstitutionsEffect({ superuserId: userAccount.superuserId });
      })
    );
    this.disableClose = this.dialogRef.disableClose;
    this.currentInstitutionId = this.routerService.institutionId;
  }

  ngOnInit(): void {

  }
  public close(): void {
    this.dialogRef.close();
  }

  public institutionSelected(institution: IInstitution) {
    this.userAccount$.pipe(take(1)).subscribe(user => {
      const superUserInstitutionProfile = user.institutionProfiles.find(profile => profile.profileType === eProfileType.Superuser && profile.institutionId === institution.institutionId);
      if (superUserInstitutionProfile != null) {
        this.routerService.Go([this.routerService.userAccountId, user.superuserId, 'superuser', superUserInstitutionProfile.institutionId, superUserInstitutionProfile.institutionProfileId, 'institution']);
      } else {
        console.error('Cant find superuser profile for institution:' + institution.institutionId);
      }
      this.close();
    });
  }

  public systemManagerClick(institution: IInstitutionDescriptorDto) {
    this.userAccount$.pipe(
      take(1),
    ).subscribe(user => {
      const programs = Object.keys(institution.systemManager.acl);
      if (programs.length > 1) {
        this.modalService.openModal(ModalSelectProgramComponent, { data: { institutionId: institution.institutionId, programIds: Object.keys(institution.systemManager.acl) } }).pipe(
          filter(programId => programId != null)
        ).subscribe(programId => {
          const superUserInstitutionProfile = user.institutionProfiles.find(profile => profile.profileType === eProfileType.Superuser && profile.institutionId === institution.institutionId);
          this.routerService.Go([this.routerService.userAccountId, institution.institutionId, superUserInstitutionProfile.institutionProfileId, 'admin', programId, 'system-manager', institution.systemManager.systemManagerId]);
          this.close();
        });
      } else {
        const superUserInstitutionProfile = user.institutionProfiles.find(profile => profile.profileType === eProfileType.Superuser && profile.institutionId === institution.institutionId);
        this.routerService.Go([this.routerService.userAccountId, institution.institutionId, superUserInstitutionProfile.institutionProfileId, 'admin', programs[0], 'system-manager', institution.systemManager.systemManagerId]);
        this.close();
      }
    });
  }
  public managerClick(institution: IInstitutionDescriptorDto) {

    this.userAccount$.pipe(
      take(1),
    ).subscribe(user => {
      const programs = Object.keys(institution.manager.managerPrograms);

      if (programs.length > 1) {
        this.modalService.openModal(ModalSelectProgramComponent, { data: { institutionId: institution.institutionId, programIds: programs } }).pipe(
          filter(programId => programId != null)
        ).subscribe(programId => {
          const superUserInstitutionProfile = user.institutionProfiles.find(profile => profile.profileType === eProfileType.Superuser && profile.institutionId === institution.institutionId);
          this.routerService.Go([this.routerService.userAccountId, institution.institutionId, superUserInstitutionProfile.institutionProfileId, 'admin', programId, 'manager', institution.manager.managerId, institution.manager.managerPrograms[programId][0].hierarchyNodeId]);
          this.close();
        });
      } else {
        const superUserInstitutionProfile = user.institutionProfiles.find(profile => profile.profileType === eProfileType.Superuser && profile.institutionId === institution.institutionId);
        this.routerService.Go([this.routerService.userAccountId, institution.institutionId, superUserInstitutionProfile.institutionProfileId, 'admin', programs[0], 'manager', institution.manager.managerId, institution.manager.managerPrograms[programs[0]][0].hierarchyNodeId]);
        this.close();
      }
    });
  }
}
