import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import {
  AutoUnsubscribe,
  eFormControlType,
  eFormType,
  IDynamicControl,
  IDynamicItem,
  IDynamicOption,
  IDynamicValidator,
} from 'app/core/models';
import { DynamicFormService, RouterService } from 'app/core/services';
import { combineLatest, map, Observable, take, takeUntil } from 'rxjs';

import { DynamicFormActions } from '..';
import {
  AdminFeatureStore,
  getControlBeingEdited,
  getControlBeingEditedIndex,
  getFormType,
  getGroupBeingEditedIndex,
} from '../../../admin-feature.reducers';

@Component({
  selector: 'ng4h-modal-dynamic-edit-control',
  templateUrl: './modal-dynamic-edit-control.component.html',
  styleUrls: ['./modal-dynamic-edit-control.component.scss']
})
export class ModalDynamicEditControlComponent extends AutoUnsubscribe implements OnInit {

  public eFormControlType = eFormControlType;
  public controlBeingEdited$: Observable<IDynamicControl>;

  public formDisplayTypes: Observable<{ id: number, name: string }[]>;

  public formType$: Observable<eFormType>;
  public groupBeingEditedIndex$: Observable<number>;
  public controlBeingEditedIndex$: Observable<number>;

  public valid$: Observable<boolean>;

  public savingForm = false;

  constructor(private dialogRef: MatDialogRef<any>, private dynamicFormService: DynamicFormService, private store: Store<AdminFeatureStore>, private routerService: RouterService) {
    super();
  }

  ngOnInit() {
    this.formDisplayTypes = this.dynamicFormService.form$.pipe(map(dynamicForm => dynamicForm.formDisplayTypes));

    this.controlBeingEditedIndex$ = this.store.pipe(select(getControlBeingEditedIndex), takeUntil(this.autoUnsubscribe));
    this.groupBeingEditedIndex$ = this.store.pipe(select(getGroupBeingEditedIndex), takeUntil(this.autoUnsubscribe));


    this.formType$ = this.store.pipe(select(getFormType));
    this.controlBeingEdited$ = this.store.pipe(select(getControlBeingEdited));

    this.valid$ = this.controlBeingEdited$.pipe(
      map(control => {
        if (control.displayTo.length === 0) {
          return false;
        }
        if (control.managerField) {
          return control.managerField_MinimumViewHierarchyType?.length > 0 || control.managerField_MinimumEditHierarchyType?.length > 0;
        }
        return true;
      })
    );
    // combineLatest(
    //   this.dynamicFormService.groups,
    //   this.groupBeingEditedIndex$,
    //   this.controlBeingEditedIndex$,
    // ).pipe(
    //   takeUntil(this.autoUnsubscribe),
    //   map(combined => {
    //     if (Array.isArray(combined[0]) && combined[0].length > 0 && combined[1] != null && combined[1] > -1 && combined[2] != null && combined[2] > -1 && combined[0][combined[1]] != null) {
    //       return combined[0][combined[1]].controls[combined[2]];
    //     }
    //     return null;
    //   })
    // );
  }

  public validatorLogicModified(validator: IDynamicValidator) {
    this.store.dispatch(DynamicFormActions.DynamicValidatorAddOrModifyAction({ validator }));
  }

  public modifyControl(event: any) {
    this.store.dispatch(DynamicFormActions.DynamicModifyControlAction(event));
  }

  public validatorLogicRemove(validator: IDynamicValidator) {
    this.store.dispatch(DynamicFormActions.DynamicValidatorRemoveAction({ validator }));
  }
  public modifyItem(partialItem: Partial<IDynamicItem>) {
    this.store.dispatch(DynamicFormActions.DynamicItemModifyOptionAction({ partialItem }));
  }

  public modifyOption(partialOption: Partial<IDynamicOption>) {
    this.store.dispatch(DynamicFormActions.DynamicOptionModifyAction({ partialOption }));
  }

  public removeItem(itemId: string) {
    this.store.dispatch(DynamicFormActions.DynamicItemRemoveAction({ itemId }));
  }

  public removeOption(optionId: string) {
    this.store.dispatch(DynamicFormActions.DynamicOptionRemoveAction({ optionId }));
  }

  public addItem(item: IDynamicItem) {
    this.store.dispatch(DynamicFormActions.DynamicItemAddAction({ item }));
  }
  public addOption(option: IDynamicOption) {
    this.store.dispatch(DynamicFormActions.DynamicOptionAddAction({ option }));
  }

  public close(dialogResult?: any) {
    this.dialogRef.close(dialogResult);
  }

  public save() {
    this.store.dispatch(DynamicFormActions.DynamicSaveControlEditsAction());
    this.dialogRef.close({ save: true });
    // this.savingForm = true;
    // this.formType$.pipe(
    //   take(1),
    //   switchMap(formType => {
    //     switch (formType) {
    //       case eFormType.Registration: {
    //         return this.dynamicFormService.saveDynamicEventForm(this.routerService.institutionId, this.routerService.programId, this.routerService.eventId);
    //       }
    //       case eFormType.Enrollment: {
    //         return this.dynamicFormService.saveEnrollmentForm(this.routerService.institutionId, this.routerService.programId);
    //       }
    //     }
    //   })
    // ).subscribe(() => {
    //   this.savingForm = false;
    //   this.close();
    // });
  }
  public delete() {
    combineLatest([this.controlBeingEditedIndex$, this.groupBeingEditedIndex$, this.dynamicFormService.form$]).pipe(
      take(1)
    ).subscribe(combined => {
      const controlIndex = combined[0];
      const groupIndex = combined[1];
      const groups = combined[2].groups;

      const newControls = [...groups[groupIndex].controls];
      newControls.splice(controlIndex, 1);
      this.store.dispatch(
        DynamicFormActions.DynamicSelectedControlsChangedAction({ controls: newControls, groupIndex: groupIndex })
      );
    });
    this.close();
  }

}
