import {
    eMemberProgramStatus,
    eMemberRole,
    eProgramType,
    IEnrollmentActivityDto,
    IEnrollmentAwardDto,
    IEnrollmentGroupDto,
    IMemberProgramConsentDto,
} from '..';
import { IMemberProgramYearDto } from './IMemberProgramYearDto';

export interface IMemberProgramDto {
  memberProgramId: string;
  programAge: number;
  totalYearsInProgram?: number;
  youthStartingYearsInProgram?: number;
  youthYearsInProgram?: number;
  adultStartingYearsInProgram?: number;
  adultYearsInProgram?: number;
  cloverBudYearsInProgram?: number;
  programId: string;
  programName?: string;
  programLogo?: string;
  status: eMemberProgramStatus;
  consents?: IMemberProgramConsentDto[];
  activeMemberProgramYear: IMemberProgramYearDto;
  memberProgramYears?: IMemberProgramYearDto[];
  screeningRequired: boolean;
  appliedScreeningId: string;
  paymentRequired: boolean;
  activities?: { [programYear: string]: IEnrollmentActivityDto[] };
  awards?: { [programYear: string]: IEnrollmentAwardDto[] };
  groups?: { [programYear: string]: IEnrollmentGroupDto[] };

  totalYearsInProgramExcludingCloverbud: number;
  programType: eProgramType;

  youthEnrollmentsInSystem?: number;
  adultEnrollmentsInSystem?: number;
  cloverBudEnrollmentsInSystem?: number;
  role: eMemberRole;
}
