import { createAction, props } from '@ngrx/store';

export const ToastrShowSuccessAction = createAction(
  '[toastr] show success',
  props<{ message: string }>()
);

export const ToastrShowErrorAction = createAction(
  '[toastr] show error',
  props<{ message: string }>()
);
