import { Pipe, PipeTransform } from '@angular/core';

import { IDocumentationParameter } from '../models';

@Pipe({
    name: 'documentationQueryString',
    pure: true
})

export class DocumentationQueryStringPipe implements PipeTransform {

    transform(path: string, parameters: IDocumentationParameter[]): any {
        const queryString = parameters.filter(param => param.source === 'Query').map(param => param.parameter + '=${' + param.parameter + '}').join('&');
        if (queryString !== '') {
            return path + '?' + queryString;
        }
        return path;
    }
}
