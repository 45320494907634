import { Action, createReducer, on } from '@ngrx/store';

import { PageMessagesActions } from '.';
import { mergeImmutable } from '../utils';


export interface PageMessageState {
  messages: { [institutionId: string]: { [systemManagerId: string]: { [programId: string]: any } } };
}

const initialState: PageMessageState = {
  messages: undefined,
  // message: undefined
};

const reducer = createReducer(
  initialState,
  on(PageMessagesActions.PageMessagesLoadSuccessAction, (state, { institutionId, systemManagerId, programId, messages }) => {
    return {
      ...state,
      messages: mergeImmutable(
        { [institutionId]: { [systemManagerId]: { [programId]: messages } } },
        state.messages
      )
    };
  }),
  on(PageMessagesActions.PageMessageInvalidateCacheAction, (state, { }) => {
    return {
      ...state,
      messages: undefined
    };
  })
);

export function pageMessageReducer(state: PageMessageState | undefined, actions: Action) {
  return reducer(state, actions);
}

export const messages = (state: PageMessageState) => state.messages;
