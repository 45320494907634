import { IHierarchyNodeDto, IProjectDto, IVolunteerTypeDto } from '.';

export interface IMemberVolunteerDto {
    unit: IHierarchyNodeDto;
    project: IProjectDto;
    volunteerTypeId: string;
    volunteerTypeDescriptor: IVolunteerTypeDto;
    volunteerTypeLabel?: string;
    volunteerTypeDescription?: string;
}
