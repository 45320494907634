import { Action, createReducer, on } from '@ngrx/store';
import { IInstitutionMemberProgramEnrollmentModel, IProjectDto } from 'app/core/models';

import { ProjectsActions } from '.';
import { mergeImmutable } from '../utils';


export interface ProjectsState {
  projects: IInstitutionMemberProgramEnrollmentModel<IProjectDto[]>;
  projectsByClub: IInstitutionMemberProgramEnrollmentModel<{ [clubId: string]: IProjectDto[] }>;
}

const initialState: ProjectsState = {
  projects: undefined,
  projectsByClub: undefined
};

const reducer = createReducer(
  initialState,
  on(ProjectsActions.ProjectsLoadSuccessAction, (state, { institutionId, memberId, programId, enrollmentId, projects }) => {
    const newProjects = mergeImmutable(
      {
        [institutionId]: { [memberId]: { [programId]: { [enrollmentId]: projects } } }
      },
      state.projects
    );
    return {
      ...state,
      projects: newProjects
    };
  }),
  on(ProjectsActions.ProjectsLoadByClubSuccessAction, (state, { institutionId, memberId, programId, enrollmentId, unitId, projects }) => {
    const newProjectsByProgram = mergeImmutable(
      { [institutionId]: { [memberId]: { [programId]: { [enrollmentId]: { [unitId]: projects } } } } },
      state.projectsByClub
    );
    return {
      ...state,
      projectsByClub: newProjectsByProgram
    };
  })
);

export function projectsReducer(state: ProjectsState | undefined, actions: Action) {
  return reducer(state, actions);
}
export const projectsByClub = (state: ProjectsState) => state.projectsByClub;
export const projects = (state: ProjectsState) => state.projects;
