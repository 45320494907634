import { Action, createReducer, on } from '@ngrx/store';
import { IInstitutionFamilyModel, IInstitutionFamilyProgramModel, IPaymentGatewayPaymentMethodsDto } from 'app/core/models';

import { PaymentsActions } from '.';
import { mergeImmutable } from '../utils';

export interface PaymentsState {
  payments: IInstitutionFamilyModel<IPaymentGatewayPaymentMethodsDto[]>;
  paymentsByProgram: IInstitutionFamilyProgramModel<IPaymentGatewayPaymentMethodsDto>;
}

const initialState: PaymentsState = {
  payments: undefined,
  paymentsByProgram: undefined
};

const reducer = createReducer(
  initialState,
  on(PaymentsActions.PaymentsLoadSuccessAction, (state, { institutionId, familyId, payments }) => {
    const newPaymentsByFamilyId = mergeImmutable(
      { [institutionId]: { [familyId]: payments } },
      state.payments
    );
    return {
      ...state,
      payments: newPaymentsByFamilyId
    };
  }),
  on(PaymentsActions.PaymentsByProgramLoadSuccessAction, (state, { institutionId, familyId, programId, payments }) => {
    const newPaymentsByProgram = mergeImmutable(
      { [institutionId]: { [familyId]: { [programId]: payments } } },
      state.paymentsByProgram
    );
    return {
      ...state,
      paymentsByProgram: newPaymentsByProgram
    };
  }),
  on(PaymentsActions.PaymentsInvalidateCache, (state, { }) => {
    return initialState;
  })
);

export function paymentsReducer(state: PaymentsState | undefined, actions: Action) {
  return reducer(state, actions);
}

export const payments = (state: PaymentsState) => state.payments;
export const paymentsByProgram = (state: PaymentsState) => state.paymentsByProgram;
