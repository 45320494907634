import { IFileType } from '.';
import { IFourHFileDto } from './IFourHFileDto';

export interface IMemberEnrollmentFileControlDto {
    managerOnly: boolean;
    hasFile: boolean;
    uploadedFile: IFourHFileDto;
    enrollmentRoles?: object;
    fileControlId: string;
    fileControlName?: string;
    fileControlDescription?: string;
    acceptedFileExtensions?: IFileType[];
    templateFile: IFourHFileDto;
    hasTemplateFile: boolean;
    required?: boolean;
}
