import { AbstractControl, ValidatorFn } from '@angular/forms';

import { IDynamicControl } from '../models';

export function DynamicFormsMultiItemRequiredValidator(dynamicControl: IDynamicControl): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const controlValue = control.value;
    if (controlValue == null) {
      return {
        'required': true,
        'controlId': dynamicControl.controlId
      };
    }
    // const itemsNull = Object.keys(controlValue).every(itemKey => controlValue[itemKey] == null || controlValue[itemKey] === false);
    const hasOneResponse = Object.keys(controlValue).find(itemKey => controlValue[itemKey]);
    if (hasOneResponse == null) {
      return {
        'required': true,
        'controlId': dynamicControl.controlId
      };
    }
    return null;
  };
}
