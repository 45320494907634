import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IMemberDto } from 'app/core/models';
import { MemberService, RouterService } from 'app/core/services';

@Component({
  selector: 'ng4h-modal-edit-second-family',
  templateUrl: './modal-edit-second-family.component.html',
  styleUrls: ['./modal-edit-second-family.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalEditSecondFamilyComponent implements OnInit {

  public member: IMemberDto;
  public form: FormGroup;

  constructor(private dialogRef: MatDialogRef<any>,
    private memberService: MemberService,
    private routerService: RouterService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) private data
  ) {
  }


  ngOnInit(): void {
    this.member = this.data.member;

    this.form = this.fb.group({
      secondFamilyName: [this.member.secondFamilyName],
      secondFamilyFirstNames: [this.member.secondFamilyFirstNames],
      secondFamilyEmail: [this.member.secondFamilyEmail],
      secondFamilyPhone: [this.member.secondFamilyPhone],
      secondFamilyAddressLine1: [this.member.secondFamilyAddressLine1],
      secondFamilyAddressLine2: [this.member.secondFamilyAddressLine2],
      secondFamilyCity: [this.member.secondFamilyCity],
      secondFamilyState: [this.member.secondFamilyState],
      secondFamilyPostalCode: [this.member.secondFamilyPostalCode],
    });

  }

  public save() {
    const memberUpdate: IMemberDto = {
      ...this.member,
      ...this.form.value
    };
    this.memberService.updateMember({ institutionId: this.routerService.institutionId, memberId: this.routerService.memberId, memberUpdate }).subscribe(() => {
      this.close();
    });
  }

  public close() {
    this.dialogRef.close();
  }

}
