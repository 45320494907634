<ng4h-dynamic-options-root [formDisplayTypes]="formDisplayTypes" [control]="control"
  (rootInputModified)="controlModified.emit($event)" (validatorAddOrModify)="validatorAddOrModify.emit($event)"
  (validatorRemove)="validatorRemove.emit($event)">

  <!-- <div class="form-group row">
    <label class="form-control-label col-md-3">Report Label</label>
    <div class="input-group col-md-9">
      <input class="form-control" type="text" [ngModel]="control.items[0].reportValue"
        (ngModelChange)="reportLabelChange($event, control.items[0].itemId)">
    </div>
  </div> -->
  <div class="form-group row">
    <label class="form-control-label col-md-3">Min Date</label>
    <div class="input-group col-md-9">
      <input class="form-control" [ngModel]="control.items[0].attributes.minDate"
        (ngModelChange)="minDateChange($event, control.items[0].itemId);" [matDatepicker]="minPicker"
        placeholder="Choose a date">
      <mat-datepicker-toggle matSuffix [for]="minPicker"></mat-datepicker-toggle>
    </div>
    <mat-datepicker #minPicker></mat-datepicker>
  </div>
  <div class="form-group row">
    <label class="form-control-label col-md-3">Max Date</label>
    <div class="input-group col-md-9">
      <input class="form-control" [ngModel]="control.items[0].attributes.maxDate"
        (ngModelChange)="maxDateChange($event, control.items[0].itemId);" [matDatepicker]="maxPicker"
        placeholder="Choose a date">
      <mat-datepicker-toggle matSuffix [for]="maxPicker"></mat-datepicker-toggle>
    </div>
    <mat-datepicker #maxPicker></mat-datepicker>
  </div>

</ng4h-dynamic-options-root>