import { IBulkDownloadFileDto } from '.';
import { eBulkDownloadStatus } from '..';

export interface IBulkDownloadDto {
    bulkDownloadId: string;
    token: string;
    status: eBulkDownloadStatus;
    files?: IBulkDownloadFileDto[];
    label?: string;
}
