import { Action, createReducer, on } from '@ngrx/store';
import {
    eFilterGroupOperator,
    ICustomReportDto,
    ICustomReportFilterDto,
    ICustomReportFilterGroupDto,
    IInstitutionManagerProgramEventModel,
    IQueuedReportDto,
    IReportColumnDto,
    IReportFolderDto,
} from 'app/core/models';
import { mergeImmutable } from 'app/shared/utils';

import { EventCustomReportActions } from '.';

export interface EventCustomReportState {
    eventCustomReports: IInstitutionManagerProgramEventModel<IReportFolderDto[]>;
    eventCustomReport: IInstitutionManagerProgramEventModel<{ [customReportId: string]: ICustomReportDto }>;
    eventAvailableColumns: IInstitutionManagerProgramEventModel<{ [customReportId: string]: IReportColumnDto[] }>;
    eventAvailableFilterValues: IInstitutionManagerProgramEventModel<{ [customReportId: string]: { [reportColumnId: string]: { [hierarchyNodeId: string]: { [key: string]: string } } } }>;
    eventQueuedReports: IInstitutionManagerProgramEventModel<IQueuedReportDto[]>;
    // eventQueuedReport: IInstitutionManagerProgramEventModel<{ [queueToken: string]: IQueuedReportDto }>;
}

const initialState: EventCustomReportState = {
    eventCustomReport: undefined,
    eventCustomReports: undefined,
    eventAvailableColumns: undefined,
    eventAvailableFilterValues: undefined,
    eventQueuedReports: undefined,
    // eventQueuedReport: undefined,
};

const reducer = createReducer(
    initialState,
    on(EventCustomReportActions.CustomReportInvalidateCacheAction, (state) => {
        return initialState;
    }),
    on(EventCustomReportActions.CustomReportsLoadReportsSuccessAction, (state, { institutionId, managerId, programId, eventId, reports }) => {
        return {
            ...state,
            eventCustomReports: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [eventId]: reports } } } },
                state.eventCustomReports
            )
        };
    }),
    on(EventCustomReportActions.UpdateReportFolderStateAction, (state, { institutionId, managerId, programId, eventId, customReportFolderId, folderState }) => {
        const reports = state.eventCustomReports[institutionId][managerId][programId][eventId].map((reportFolder: IReportFolderDto) => {
            if (reportFolder.reportFolderId === customReportFolderId) {
                return {
                    ...reportFolder,
                    reportFolderState: folderState.reportFolderState
                };
            } else {
                return reportFolder;
            }
        });
        return {
            ...state,
            eventCustomReports: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [eventId]: reports } } } },
                state.eventCustomReports
            )
        };
    }),
    on(EventCustomReportActions.CustomReportsLoadReportSuccessAction, (state, { institutionId, managerId, programId, eventId, customReportId, report }) => {
        return {
            ...state,
            eventCustomReport: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [eventId]: { [customReportId]: report } } } } },
                state.eventCustomReport
            )
        };
    }),
    on(EventCustomReportActions.CustomReportsCreateReportSuccessAction, (state, { institutionId, managerId, programId, eventId, report }) => {
        return {
            ...state,
            eventCustomReports: undefined,
            eventCustomReport: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [eventId]: { [report.reportId]: report } } } } },
                state.eventCustomReport
            )
        };
    }),
    on(EventCustomReportActions.CreateReportFolderSuccessAction,
        EventCustomReportActions.UpdateReportFolderSuccessAction,
        EventCustomReportActions.DeleteReportFolderSuccessAction, (state, { }) => {
            return {
                ...state,
                eventCustomReports: undefined
            };
        }),
    on(EventCustomReportActions.CustomReportsLoadAvailableColumnsSuccessAction, (state, { institutionId, managerId, programId, eventId, customReportId, availableColumns }) => {
        return {
            ...state,
            eventAvailableColumns: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [eventId]: { [customReportId]: availableColumns } } } } },
                state.eventAvailableColumns
            )
        };
    }),
    on(EventCustomReportActions.CustomReportsAddColumnToReport, (state, { institutionId, managerId, programId, eventId, customReportId, column }) => {
        let newReport = state.eventCustomReport[institutionId][managerId][programId][eventId][customReportId];
        newReport = {
            ...newReport,
            columns: [...newReport.columns, column]
        };
        return {
            ...state,
            eventCustomReport: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [eventId]: { [customReportId]: newReport } } } } },
                state.eventCustomReport
            )
        };
    }),
    on(EventCustomReportActions.CustomReportsDeleteColumnToReport, (state, { institutionId, managerId, programId, eventId, customReportId, column }) => {
        let newReport = state.eventCustomReport[institutionId][managerId][programId][eventId][customReportId];
        newReport = {
            ...newReport,
            columns: newReport.columns.filter(existingColumn => existingColumn.columnId !== column.columnId)
        };
        return {
            ...state,
            eventCustomReport: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [eventId]: { [customReportId]: newReport } } } } },
                state.eventCustomReport
            )
        };
    }),
    on(EventCustomReportActions.CustomReportsUpdateReportSuccessAction,
        EventCustomReportActions.CustomReportsDeleteReportSuccessAction,
        EventCustomReportActions.CustomReportsRestoreReportSuccessAction,
        EventCustomReportActions.CustomReportsCopyReportSuccessAction,
        EventCustomReportActions.CustomReportsShareReportSuccessAction,
        EventCustomReportActions.CustomReportsUnShareReportSuccessAction,
        (state, { institutionId, managerId, programId, eventId, customReportId }) => {
            return {
                ...state,
                eventCustomReports: undefined,
                eventCustomReport: mergeImmutable(
                    { [institutionId]: { [managerId]: { [programId]: { [eventId]: { [customReportId]: undefined } } } } },
                    state.eventCustomReport
                )
            };
        }),
    on(EventCustomReportActions.CustomReportsAddFilterGroup, (state, { institutionId, managerId, programId, eventId, customReportId, filterGroup }) => {

        let newReport = state.eventCustomReport[institutionId][managerId][programId][eventId][customReportId];
        const filterGroups = nullLastFilterGroupOperator([...newReport.filterGroups, filterGroup]);
        newReport = {
            ...newReport,
            filterGroups
        };
        return {
            ...state,
            eventCustomReport: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [eventId]: { [customReportId]: newReport } } } } },
                state.eventCustomReport
            )
        };
    }),
    on(EventCustomReportActions.CustomReportsRemoveFilterGroup, (state, { institutionId, managerId, programId, eventId, customReportId, filterGroupIndex }) => {
        let newReport = state.eventCustomReport[institutionId][managerId][programId][eventId][customReportId];
        const filterGroups = nullLastFilterGroupOperator(newReport.filterGroups.filter((filterGroup, index) => index !== filterGroupIndex));
        newReport = {
            ...newReport,
            filterGroups
        };
        return {
            ...state,
            eventCustomReport: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [eventId]: { [customReportId]: newReport } } } } },
                state.eventCustomReport
            )
        };
    }),
    on(EventCustomReportActions.CustomReportsReorderColumns, (state, { institutionId, managerId, programId, eventId, customReportId, columns }) => {
        const newReport = {
            ...state.eventCustomReport[institutionId][managerId][programId][eventId][customReportId],
            columns
        };
        return {
            ...state,
            eventCustomReport: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [eventId]: { [customReportId]: newReport } } } } },
                state.eventCustomReport
            )
        };
    }),
    on(EventCustomReportActions.CustomReportsReorderFilterGroup, (state, { institutionId, managerId, programId, eventId, customReportId, filterGroups }) => {

        filterGroups = nullLastFilterGroupOperator(filterGroups);
        let newReport = state.eventCustomReport[institutionId][managerId][programId][eventId][customReportId];

        newReport = {
            ...newReport,
            filterGroups: filterGroups
        };
        return {
            ...state,
            eventCustomReport: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [eventId]: { [customReportId]: newReport } } } } },
                state.eventCustomReport
            )
        };
    }),
    on(EventCustomReportActions.CustomReportsAddFilter, (state, { institutionId, managerId, programId, eventId, customReportId, filterGroupIndex, filter }) => {
        let newReport = state.eventCustomReport[institutionId][managerId][programId][eventId][customReportId];
        const filterGroup = newReport.filterGroups[filterGroupIndex];

        const filters = nullLastFilterOperator([...filterGroup.filters, filter]);

        const newFilterGroups = newReport.filterGroups.map((gf, fgi) => {
            if (filterGroupIndex !== fgi) {
                return gf;
            } else {
                return {
                    ...gf,
                    filters
                };
            }
        });
        newReport = {
            ...newReport,
            filterGroups: newFilterGroups
        };
        return {
            ...state,
            eventCustomReport: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [eventId]: { [customReportId]: newReport } } } } },
                state.eventCustomReport
            )
        };
    }),
    on(EventCustomReportActions.CustomReportsUpdateFilterValue, (state, { institutionId, managerId, programId, eventId, customReportId, filterGroupIndex, filterIndex, filter }) => {
        let newReport = state.eventCustomReport[institutionId][managerId][programId][eventId][customReportId];
        const filterGroup = newReport.filterGroups[filterGroupIndex];

        // const filters = [
        //     ...filterGroup.filters.slice(0, filterIndex),
        //     filter,
        //     ...filterGroup.filters.slice(filterIndex + 1)
        // ];

        const filters = filterGroup.filters.map((f, index) => {
            if (index !== filterIndex) {
                return f;
            }
            return filter;
        });

        const newFilterGroups = newReport.filterGroups.map((gf, fgi) => {
            if (filterGroupIndex !== fgi) {
                return gf;
            } else {
                return {
                    ...gf,
                    filters
                };
            }
        });
        newReport = {
            ...newReport,
            filterGroups: newFilterGroups
        };
        return {
            ...state,
            eventCustomReport: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [eventId]: { [customReportId]: newReport } } } } },
                state.eventCustomReport
            )
        };
    }),
    on(EventCustomReportActions.CustomReportsRemoveFilter, (state, { institutionId, managerId, programId, eventId, customReportId, filterGroupIndex, filterIndex }) => {
        let newReport = state.eventCustomReport[institutionId][managerId][programId][eventId][customReportId];
        const filterGroup = newReport.filterGroups[filterGroupIndex];

        const filters = nullLastFilterOperator(filterGroup.filters.filter((f, index) => index !== filterIndex));

        const newFilterGroups = newReport.filterGroups.map((gf, fgi) => {
            if (filterGroupIndex !== fgi) {
                return gf;
            } else {
                return {
                    ...gf,
                    filters
                };
            }
        });
        newReport = {
            ...newReport,
            filterGroups: newFilterGroups
        };
        return {
            ...state,
            eventCustomReport: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [eventId]: { [customReportId]: newReport } } } } },
                state.eventCustomReport
            )
        };
    }),
    on(EventCustomReportActions.CustomReportsReorderFilters, (state, { institutionId, managerId, programId, eventId, customReportId, filterGroupIndex, filters }) => {

        filters = nullLastFilterOperator(filters);

        let newReport = state.eventCustomReport[institutionId][managerId][programId][eventId][customReportId];

        const newFilterGroups = newReport.filterGroups.map((gf, fgi) => {
            if (fgi !== filterGroupIndex) {
                return gf;
            } else {
                return {
                    ...gf,
                    filters: filters
                };
            }
        });
        newReport = {
            ...newReport,
            filterGroups: newFilterGroups
        };

        return {
            ...state,
            eventCustomReport: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [eventId]: { [customReportId]: newReport } } } } },
                state.eventCustomReport
            )
        };
    }),
    on(EventCustomReportActions.CustomReportsLoadAvailableFilterValuesSuccessAction, (state, { institutionId, managerId, programId, eventId, customReportId, reportColumnId, hierarchyNodeId, eventAvailableFilterValues }) => {
        return {
            ...state,
            eventAvailableFilterValues: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [eventId]: { [customReportId]: { [reportColumnId]: { [hierarchyNodeId]: eventAvailableFilterValues } } } } } } },
                state.eventAvailableFilterValues
            )
        };
    }),
    on(EventCustomReportActions.CustomReportsUpdateReportSuccessAction, (state, { institutionId, managerId, programId, eventId, customReportId }) => {
        return {
            ...state,
            eventAvailableFilterValues: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [eventId]: { [customReportId]: undefined } } } } },
                state.eventAvailableFilterValues
            )
        };
    }),
    on(EventCustomReportActions.CustomReportsAddOrderByAction, (state, { institutionId, managerId, programId, eventId, customReportId, orderBy }) => {
        let newReport = state.eventCustomReport[institutionId][managerId][programId][eventId][customReportId];
        newReport = {
            ...newReport,
            orderBy: [...newReport.orderBy, orderBy]
        };
        return {
            ...state,
            eventCustomReport: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [eventId]: { [customReportId]: newReport } } } } },
                state.eventCustomReport
            )
        };
    }),
    on(EventCustomReportActions.CustomReportsUpdateOrderByAction, (state, { institutionId, managerId, programId, eventId, customReportId, orderByIndex, orderBy }) => {
        let newReport = state.eventCustomReport[institutionId][managerId][programId][eventId][customReportId];
        newReport = {
            ...newReport,
            orderBy: newReport.orderBy.map((ob, obi) => {
                if (obi !== orderByIndex) {
                    return ob;
                }
                return orderBy;
            })
        };
        return {
            ...state,
            eventCustomReport: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [eventId]: { [customReportId]: newReport } } } } },
                state.eventCustomReport
            )
        };
    }),
    on(EventCustomReportActions.CustomReportsReorderOrderByAction, (state, { institutionId, managerId, programId, eventId, customReportId, orderBys }) => {
        let newReport = state.eventCustomReport[institutionId][managerId][programId][eventId][customReportId];
        newReport = {
            ...newReport,
            orderBy: orderBys
        };
        return {
            ...state,
            eventCustomReport: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [eventId]: { [customReportId]: newReport } } } } },
                state.eventCustomReport
            )
        };
    }),
    on(EventCustomReportActions.CustomReportsRemoveOrderByAction, (state, { institutionId, managerId, programId, eventId, customReportId, orderByIndex }) => {
        let newReport = state.eventCustomReport[institutionId][managerId][programId][eventId][customReportId];
        newReport = {
            ...newReport,
            orderBy: newReport.orderBy.filter((ob, index) => index !== orderByIndex)
        };
        return {
            ...state,
            eventCustomReport: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [eventId]: { [customReportId]: newReport } } } } },
                state.eventCustomReport
            )
        };
    }),

    on(EventCustomReportActions.CustomReportsAddGroupByAction, (state, { institutionId, managerId, programId, eventId, customReportId, groupBy }) => {
        let newReport = state.eventCustomReport[institutionId][managerId][programId][eventId][customReportId];
        newReport = {
            ...newReport,
            groupBy: [...newReport.groupBy, groupBy]
        };
        return {
            ...state,
            eventCustomReport: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [eventId]: { [customReportId]: newReport } } } } },
                state.eventCustomReport
            )
        };
    }),
    on(EventCustomReportActions.CustomReportsUpdateGroupByAction, (state, { institutionId, managerId, programId, eventId, customReportId, groupByIndex, groupBy }) => {
        let newReport = state.eventCustomReport[institutionId][managerId][programId][eventId][customReportId];
        newReport = {
            ...newReport,
            groupBy: newReport.groupBy.map((ob, obi) => {
                if (obi !== groupByIndex) {
                    return ob;
                }
                return groupBy;
            })
        };
        return {
            ...state,
            eventCustomReport: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [eventId]: { [customReportId]: newReport } } } } },
                state.eventCustomReport
            )
        };
    }),
    on(EventCustomReportActions.CustomReportsReorderGroupByAction, (state, { institutionId, managerId, programId, eventId, customReportId, groupBys }) => {
        let newReport = state.eventCustomReport[institutionId][managerId][programId][eventId][customReportId];
        newReport = {
            ...newReport,
            groupBy: groupBys
        };
        return {
            ...state,
            eventCustomReport: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [eventId]: { [customReportId]: newReport } } } } },
                state.eventCustomReport
            )
        };
    }),
    on(EventCustomReportActions.CustomReportsRemoveGroupByAction, (state, { institutionId, managerId, programId, eventId, customReportId, groupByIndex }) => {
        let newReport = state.eventCustomReport[institutionId][managerId][programId][eventId][customReportId];
        newReport = {
            ...newReport,
            groupBy: newReport.groupBy.filter((ob, index) => index !== groupByIndex)
        };
        return {
            ...state,
            eventCustomReport: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: { [eventId]: { [customReportId]: newReport } } } } },
                state.eventCustomReport
            )
        };
    }),
    on(EventCustomReportActions.CustomReportsLoadQueuedReportsSuccessAction, (state, { institutionId, managerId, programId, eventQueuedReports }) => {
        return {
            ...state,
            eventQueuedReports: mergeImmutable(
                { [institutionId]: { [managerId]: { [programId]: eventQueuedReports } } },
                state.eventQueuedReports
            )
        };
    }),
    // on(EventCustomReportActions.CustomReportsLoadQueuedReportSuccessAction, (state, { institutionId, managerId, programId, queueToken, eventQueuedReport }) => {
    //     return {
    //         ...state,
    //         eventQueuedReport: mergeImmutable(
    //             { [institutionId]: { [managerId]: { [programId]: { [queueToken]: eventQueuedReport } } } },
    //             state.eventQueuedReport
    //         )
    //     };
    // })
);

function nullLastFilterGroupOperator(filterGroups: ICustomReportFilterGroupDto[]) {
    return filterGroups.map((filterGroup, index) => {
        const isLast = (index + 1) === filterGroups.length;
        if (isLast) {
            return {
                ...filterGroup,
                outerJoinOperator: null
            };
        } else {
            return {
                ...filterGroup,
                outerJoinOperator: filterGroup.outerJoinOperator == null ? eFilterGroupOperator.OR : filterGroup.outerJoinOperator
            };
        }
    });
}

function nullLastFilterOperator(filters: ICustomReportFilterDto[]) {
    return filters.map((f, index) => {
        const isLast = (index + 1) === filters.length;
        if (isLast) {
            return {
                ...f,
                joinOperator: null
            };
        } else {
            return {
                ...f,
                joinOperator: f.joinOperator == null ? eFilterGroupOperator.OR : f.joinOperator
            };
        }
    });
}

export function eventCustomReportsReducer(state: EventCustomReportState | undefined, action: Action) {
    return reducer(state, action);
}

export const eventCustomReports = (state: EventCustomReportState) => state.eventCustomReports;
export const eventCustomReport = (state: EventCustomReportState) => state.eventCustomReport;
export const eventAvailableColumns = (state: EventCustomReportState) => state.eventAvailableColumns;
export const eventAvailableFilterValues = (state: EventCustomReportState) => state.eventAvailableFilterValues;
export const eventQueuedReports = (state: EventCustomReportState) => state.eventQueuedReports;
// export const eventQueuedReport = (state: EventCustomReportState) => state.eventQueuedReport;