import { ISearchDateRangeDto } from '.';

export interface IStandardReportFiltersDto {
    includeAllYears?: boolean;
    memberFlagged?: boolean;
    gender?: any[];
    schoolGrade?: any[];
    memberParticipationStatus?: any[];
    enrollmentRoles?: any[];
    dateRange: ISearchDateRangeDto;
    groupByCounty: boolean;
}
