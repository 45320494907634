export enum eEventRegistrationStatus {

    Incomplete = 'Incomplete',
    PendingApproval = 'PendingApproval',
    Approved = 'Approved',
    Declined = 'Declined',
    Resubmit = 'Resubmit',
    Canceled = 'Canceled',
    Completed = 'Completed'
}
