import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'replace',
    pure: true
})

export class ReplacePipe implements PipeTransform {
    transform(arg: string, searchValue: string, replaceValue: string) {
        return arg.replace(new RegExp(searchValue, 'g'), replaceValue);
    }
}
