import { Directive, Host, Input, OnInit, Optional, Self } from '@angular/core';
import { Editor } from 'primeng/editor';
import * as QuillNamespace from 'quill';
import { lastValueFrom, map } from 'rxjs';

import { ManagerBroadcastService, RouterService } from '../../core/services';

const Quill: any = QuillNamespace;

@Directive({
  selector: '[pEditorDefaultModules]'
})
export class EditorDefaultModulesDirective implements OnInit {
  @Input('pEditorDefaultModules') modules: any;

  constructor(
    private managerBroadcastService: ManagerBroadcastService,
    private routerService: RouterService,
    @Host() @Self() @Optional() public editor: Editor
  ) {

  }

  ngOnInit(): void {

    this.editor.modules = {
      imageResize: {
        parchment: Quill.import('parchment')
        // See optional "config" below
      },
      imageUploader: {
        upload: (file: File) => {
          const formData = new FormData();
          formData.append('file', file, file.name);

          return lastValueFrom(
            this.managerBroadcastService.uploadEmailImage({
              institutionId: this.routerService.institutionId,
              programId: this.routerService.programId,
              image: formData
            }).pipe(map(res => res.url))
          );
        }
      }
    };
  }
}
