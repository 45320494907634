const PASSWORD_REGEX_PATTERN = '(?=.*[^a-z0-9])(?=.*[0-9]).{10,}';
export const PASSWORD_REGEX = new RegExp(PASSWORD_REGEX_PATTERN);


const EMAIL_REGEX_PATTERN = /^[-a-zA-Z0-9~!$%^&*_=+}{\'?]+(\.[-a-zA-Z0-9~!$%^&*_=+}{\'?]+)*@([a-zA-Z0-9_][-a-zA-Z0-9_]*(\.[-a-zA-Z0-9_]+)*\.([a-zA-Z][a-zA-Z]+)|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;
export const EMAIL_REGEX = new RegExp(EMAIL_REGEX_PATTERN);

const PHONE_REGEX_PATTERN = '^[0-9]{3}-[0-9]{3}-[0-9]{4}$';
export const PHONE_REGEX = new RegExp(PHONE_REGEX_PATTERN);

