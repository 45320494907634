import { eProfileType } from '../enums/eProfileType';

export interface IInstitutionProfile {
  profileType: eProfileType;
  email?: string;
  institutionId: string;
  institutionProfileId: string;
  profileToken: string;
  name?: string;
}
