<div class="modal-header">
    <h5 class="modal-title">Select System Manager</h5>
    <button (click)="close()" type="button" class="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <mat-list>
        <ng-container *ngFor="let systemManager of systemManagers$ | async">
            <mat-list-item (click)="rowClicked(systemManager)"
                [ngClass]="{'selected': selectedSystemManager?.systemManagerId === systemManager.systemManagerId}">
                <h3 matListItemLine>{{systemManager.lastName}}, {{systemManager.firstName}}
                </h3>
                <h6 matListItemLine>{{systemManager.email}}</h6>
                <!--<p matListItemLine class="text-muted"> Enrolled: {{event.dateOfEvent | date}}, Approved:{{event.dateApproved | date}}</p> -->

            </mat-list-item>
            <mat-divider></mat-divider>
        </ng-container>
    </mat-list>
</div>
<div class="modal-footer">
    <button class="btn btn-secondary" (click)="close()">Close</button>
    <button [disabled]="selectedSystemManager == null" class="btn btn-primary" (click)="add()">Add</button>

</div>