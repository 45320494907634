import { Action, createReducer, on } from '@ngrx/store';
import {
  eHierarchyNodeType,
  IHierarchyAny,
  IHierarchyCountyArea,
  IHierarchyDistrictArea,
  IHierarchyDto,
  IHierarchyInstitution,
  IHierarchyRegionArea,
} from 'app/core/models';
import { mergeImmutable } from 'app/shared/utils';

import { HierarchyActions } from '.';
import { mergeAcl } from '../permission.utils';

export interface HierarchyState {
  hierarchy: { [institutionId: string]: IHierarchyDto };
  managerHierarchy: { [institutionId: string]: { [programId: string]: { [managerId: string]: IHierarchyDto } } };

  selectedCountyArea: IHierarchyCountyArea;
  selectedDistrictArea: IHierarchyDistrictArea;
  selectedRegionArea: IHierarchyRegionArea;
  selectedInstitutionArea: IHierarchyInstitution;
}
const initialState: HierarchyState = {
  hierarchy: undefined,
  managerHierarchy: undefined,

  selectedCountyArea: undefined,
  selectedDistrictArea: undefined,
  selectedRegionArea: undefined,
  selectedInstitutionArea: undefined,
};

const reducer = createReducer(
  initialState,
  on(HierarchyActions.HierarchyInvalidateCacheAction,
    HierarchyActions.UpdateHierarchyNodeSuccessAction,
    HierarchyActions.ChangeHierarchyNodeParentSuccessAction,
    HierarchyActions.AddCountySuccessAction,
    HierarchyActions.AddDistrictSuccessAction,
    HierarchyActions.AddRegionSuccessAction, (state, { }) => {
      return {
        ...state,
        hierarchy: undefined
      };
    }),
  on(HierarchyActions.HierarchyLoadSuccessAction, (state, { institutionId, hierarchy }) => {
    const newHierarchy = mergeImmutable(
      { [institutionId]: hierarchy },
      state.hierarchy
    );
    return {
      ...state,
      hierarchy: newHierarchy
    };
  }),
  on(HierarchyActions.HierarchySetManagerAction, (state, { institutionId, programId, managerId, hierarchyAssociations }) => {
    if (hierarchyAssociations == null) {
      const newManagerHierarchys = mergeImmutable(
        { [institutionId]: { [programId]: { [managerId]: null } } },
        state.managerHierarchy
      );
      return {
        ...state,
        managerHierarchy: newManagerHierarchys
      };
    }
    let fullManagerHierarchy = null;
    for (let i = 0; i < hierarchyAssociations.length; i++) {
      const hierarchyAssociation = hierarchyAssociations[i];

      const rootHierarchyAssociationNode = state.hierarchy[institutionId][hierarchyAssociation.hierarchyNodeId];

      // Get all the children of the hierarchy association
      const partialManagerHierarchy = {
        ...{ [hierarchyAssociation.hierarchyNodeId]: rootHierarchyAssociationNode },
        ...getAllChildren(rootHierarchyAssociationNode?.childrenHierarchyNodeIds, state.hierarchy[institutionId])
      };

      // Inherit the acl
      Object.keys(partialManagerHierarchy).forEach(key => {
        partialManagerHierarchy[key] = {
          ...partialManagerHierarchy[key],
          acl: mergeAcl(hierarchyAssociation.acl, partialManagerHierarchy[key]?.acl),
          primary: hierarchyAssociation.primary
        };
      });


      // Get the parents if it's not an institution account
      if (rootHierarchyAssociationNode?.hierarchyNodeType !== eHierarchyNodeType.Institution) {
        const parents = getParents(rootHierarchyAssociationNode, state.hierarchy[institutionId]);
        // We don't want to overwrite existing nodes because they might contain acl's so check before adding
        if (parents != null) {
          Object.keys(parents).forEach(key => {
            if (fullManagerHierarchy == null || fullManagerHierarchy[key] == null) {
              partialManagerHierarchy[key] = parents[key];
            }
          });
        }
      }

      fullManagerHierarchy = {
        ...fullManagerHierarchy,
        ...partialManagerHierarchy
      };
    }
    const newManagerHierarchys = mergeImmutable(
      { [institutionId]: { [programId]: { [managerId]: fullManagerHierarchy } } },
      state.managerHierarchy
    );
    return {
      ...state,
      managerHierarchy: newManagerHierarchys
    };
  }),
  on(HierarchyActions.HierarchyInstitutionChangedAction, (state, { }) => {
    return {
      ...state,
      selectedCountyArea: undefined,
      selectedDistrictArea: undefined,
      selectedRegionArea: undefined,
      selectedInstitutionArea: undefined
    };
  }),
  on(HierarchyActions.HierarchySelectInstitutionAction, (state, { selectedInstitutionArea }) => {
    return {
      ...state,
      selectedCountyArea: undefined,
      selectedDistrictArea: undefined,
      selectedRegionArea: undefined,
      selectedInstitutionArea
    };
  }),
  on(HierarchyActions.HierarchySelectRegionAreaAction, (state, { institutionId, programId, managerId, selectedRegionArea }) => {
    const hierarchy = managerId ? state.managerHierarchy[institutionId][programId][managerId] : state.hierarchy[institutionId];
    const region = selectedRegionArea;
    const institution = hierarchy[region.parentHierarchyNodeId] as IHierarchyInstitution;
    return {
      ...state,
      selectedCountyArea: undefined,
      selectedDistrictArea: undefined,
      selectedRegionArea: region,
      selectedInstitutionArea: institution
    };
  }),
  on(HierarchyActions.HierarchySelectDistrictAreaAction, (state, { institutionId, programId, managerId, selectedDistrictArea }) => {
    const hierarchy = managerId ? state.managerHierarchy[institutionId][programId][managerId] : state.hierarchy[institutionId];
    const district = selectedDistrictArea;
    const region = hierarchy[district.parentHierarchyNodeId] as IHierarchyRegionArea;
    const institution = hierarchy[region.parentHierarchyNodeId] as IHierarchyInstitution;
    return {
      ...state,
      selectedCountyArea: undefined,
      selectedDistrictArea: district,
      selectedRegionArea: region,
      selectedInstitutionArea: institution
    };
  }),
  on(HierarchyActions.HierarchySelectCountyAreaAction, (state, { institutionId, programId, managerId, selectedCountyArea }) => {
    const hierarchy = managerId ? state.managerHierarchy[institutionId][programId][managerId] : state.hierarchy[institutionId];
    const county = selectedCountyArea;
    const district = hierarchy[county.parentHierarchyNodeId] as IHierarchyDistrictArea;
    const region = hierarchy[district.parentHierarchyNodeId] as IHierarchyRegionArea;
    const institution = hierarchy[region.parentHierarchyNodeId] as IHierarchyInstitution;
    return {
      ...state,
      selectedCountyArea: county,
      selectedDistrictArea: district,
      selectedRegionArea: region,
      selectedInstitutionArea: institution
    };
  })
);


function getParents(hierarchyNode: IHierarchyAny, hierarchy: { [hierarchyId: string]: IHierarchyAny }): { [hierarchyId: string]: IHierarchyAny } | null {
  if (hierarchyNode?.parentHierarchyNodeId != null) {
    const parent = hierarchy[hierarchyNode.parentHierarchyNodeId];
    return {
      [hierarchyNode.parentHierarchyNodeId]: parent,
      ...getParents(parent, hierarchy)
    };
  } else {
    return null;
  }
}
function getAllChildren(hierarchyNodeIds: string[], hierarchy: { [hierarchyId: string]: IHierarchyAny }): { [hierarchyId: string]: IHierarchyAny } {
  if (!Array.isArray(hierarchyNodeIds)) {
    return null;
  }
  let nodes = {};
  hierarchyNodeIds.forEach(hierarchyNodeId => {
    const node = hierarchy[hierarchyNodeId];
    nodes = {
      ...nodes,
      [hierarchyNodeId]: node,
      ...getAllChildren(node.childrenHierarchyNodeIds, hierarchy)
    };
  });
  return nodes;
}


export function hierarchyReducer(state: HierarchyState | undefined, actions: Action) {
  return reducer(state, actions);
}
export const hierarchy = (state: HierarchyState) => state.hierarchy;
export const managerHierarchyNode = (state: HierarchyState) => state.managerHierarchy;

export const selectedCountyArea = (state: HierarchyState) => state.selectedCountyArea;
export const selectedDistrictArea = (state: HierarchyState) => state.selectedDistrictArea;
export const selectedRegionArea = (state: HierarchyState) => state.selectedRegionArea;
export const selectedInstitutionArea = (state: HierarchyState) => state.selectedInstitutionArea;
// export const selectedClub = (state: HierarchyState) => state.selectedClub;