import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { IProjectDto } from 'app/core/models';
import { Observable } from 'rxjs';

@Component({
  selector: 'ng4h-modal-add-projects',
  templateUrl: './modal-add-projects.component.html',
  styleUrls: ['./modal-add-projects.component.scss']
})
export class ModalManagerAddProjectsComponent implements OnInit {

  public projects$: Observable<IProjectDto[]>;

  public dataSource = new MatTableDataSource();
  public displayedColumns = ['select', 'projectName', 'projectDescription'];
  public selection = new SelectionModel<IProjectDto>(true, []);

  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: { projects: any[], existingProjects: any[] },
  ) { }

  ngOnInit() {

    const existing = this.data.existingProjects.map(project => project.id);
    this.dataSource.data = this.data.projects.filter((project) => {
      return !existing.includes(project.id);
    });

  }

  public isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  public masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach((row: IProjectDto) => {
        this.selection.select(row);
      });
  }

  public close(): void {
    this.dialogRef.close();
  }

  public save(): void {
    this.dialogRef.close(this.selection.selected);
  }
}
