import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import { IInstitutionMemberProgramEnrollmentParams, IInstitutionMemberProgramParams } from 'app/core/models';

import * as memberActivities from './store/activities/member-activities.reducer';
import * as memberAwards from './store/awards/member-awards.reducer';
import * as memberGroups from './store/groups/member-groups.reducer';
import * as memberTrainings from './store/trainings/member-trainings.reducer';

export const MEMBER_FEATURE = 'MEMBER_FEATURE';

export interface MemberFeatureStore {

    memberTrainings: memberTrainings.MemberTrainingState;
    memberActivities: memberActivities.MemberActivitiesState;
    memberAwards: memberAwards.MemberAwardsState;
    memberGroups: memberGroups.MemberGroupsState;

}

export const memberReducers: ActionReducerMap<MemberFeatureStore> = {

    memberTrainings: memberTrainings.memberTrainingsReducer,
    memberActivities: memberActivities.activitiesReducer,
    memberAwards: memberAwards.awardsReducer,
    memberGroups: memberGroups.memberGroupsReducer,

};

export const getMemberFeatureStore = createFeatureSelector<MemberFeatureStore>(MEMBER_FEATURE);



export const getMemberTrainingsState = createSelector(getMemberFeatureStore, (state: MemberFeatureStore) => state.memberTrainings);

export const getAvailableTrainings = ({ institutionId, memberId, programId }: IInstitutionMemberProgramParams) => createSelector(
    createSelector(getMemberTrainingsState, memberTrainings.availableTrainings),
    availableTrainings => availableTrainings?.[institutionId]?.[memberId]?.[programId]
);
export const getMemberTrainings = ({ institutionId, memberId, programId }: IInstitutionMemberProgramParams) => createSelector(
    createSelector(getMemberTrainingsState, memberTrainings.trainings),
    trainings => trainings?.[institutionId]?.[memberId]?.[programId]
);
// export const getMemberTraining = ({ institutionId, memberId,programId, profileTrainingId }: IInstitutionMemberProgramParams & { profileTrainingId: string }) => createSelector(
//     createSelector(getMemberTrainingsState, memberTrainings.training),
//     training => training?.[institutionId]?.[memberId]?.[profileTrainingId]
// );
export const getMemberLessons = ({ institutionId, memberId, programId, profileTrainingId, trainingLessonId }: IInstitutionMemberProgramParams & { profileTrainingId: string, trainingLessonId: string }) => createSelector(
    createSelector(getMemberTrainingsState, memberTrainings.lessons),
    lessions => lessions?.[institutionId]?.[memberId]?.[programId]?.[profileTrainingId]?.[trainingLessonId]
);


export const getMemberActivitiesState = createSelector(getMemberFeatureStore, (state: MemberFeatureStore) => state.memberActivities);

export const getMemberActivities = ({ institutionId, memberId, programId, enrollmentId }: IInstitutionMemberProgramEnrollmentParams) => createSelector(
    createSelector(getMemberActivitiesState, memberActivities.activities),
    activities => activities?.[institutionId]?.[memberId]?.[programId]?.[enrollmentId]
);

export const getMemberAwardsState = createSelector(getMemberFeatureStore, (state: MemberFeatureStore) => state.memberAwards);

export const getMemberAwards = ({ institutionId, memberId, programId, enrollmentId }: IInstitutionMemberProgramEnrollmentParams) => createSelector(
    createSelector(getMemberAwardsState, memberAwards.awards),
    awards => awards?.[institutionId]?.[memberId]?.[programId]?.[enrollmentId]
);

export const getMemberGroupsState = createSelector(getMemberFeatureStore, (state: MemberFeatureStore) => state.memberGroups);

export const getMemberGroups = ({ institutionId, memberId, programId, enrollmentId }: IInstitutionMemberProgramEnrollmentParams) => createSelector(
    createSelector(getMemberGroupsState, memberGroups.memberGroups),
    groups => groups?.[institutionId]?.[memberId]?.[programId]?.[enrollmentId]
);


