import { Action, createReducer, on } from '@ngrx/store';
import { IDiscountFundDescriptorDto, IInstitutionSystemManagerProgramModel } from 'app/core/models';
import { mergeImmutable } from 'app/shared/utils';

import { SystemManagerFinanceActions } from '.';

export interface SystemManagerFinancesState {
  funds: IInstitutionSystemManagerProgramModel<IDiscountFundDescriptorDto>;
  fund: IInstitutionSystemManagerProgramModel<{ [discountFundId: string]: IDiscountFundDescriptorDto }>;
}

const initialState: SystemManagerFinancesState = {
  funds: undefined,
  fund: undefined
};

const reducer = createReducer(
  initialState,
  on(SystemManagerFinanceActions.SystemManagerGetFundsSuccessAction, (state, { institutionId, systemManagerId, programId, funds }) => {
    return {
      ...state,
      funds: mergeImmutable(
        { [institutionId]: { [systemManagerId]: { [programId]: funds } } },
        state.funds
      )
    };
  }),

  on(SystemManagerFinanceActions.SystemManagerGetFundByIdSuccessAction, (state, { institutionId, systemManagerId, programId, discountFundId, fund }) => {
    return {
      ...state,
      fund: mergeImmutable(
        { [institutionId]: { [systemManagerId]: { [programId]: { [discountFundId]: fund } } } },
        state.fund
      )
    };
  }),

  // Invalidate store cache on updates
  on(SystemManagerFinanceActions.SystemManagerAddFundSuccessAction,
    SystemManagerFinanceActions.SystemManagerUpdateFundSuccessAction,
    SystemManagerFinanceActions.SystemManagerDeleteFundSuccessAction,
    (state, { institutionId, systemManagerId, programId }) => {
      return {
        ...state,
        funds: mergeImmutable(
          { [institutionId]: { [systemManagerId]: { [programId]: undefined } } },
          state.funds
        ),
        fund: mergeImmutable(
          { [institutionId]: { [systemManagerId]: { [programId]: undefined } } },
          state.fund
        )
      };
    }),
);

export function systemManagerFinanceReducer(state: SystemManagerFinancesState | undefined, action: Action) {
  return reducer(state, action);
}

export const funds = (state: SystemManagerFinancesState) => state.funds;
export const fund = (state: SystemManagerFinancesState) => state.fund;
