import { Action, createReducer, on } from '@ngrx/store';

import { ConsentsActions } from '.';
import { mergeImmutable } from '../utils';

export interface ConsentsState {
  consents: { [institutionId: string]: { [programId: string]: any } };
}

const initialState: ConsentsState = {
  consents: undefined
};

const reducer = createReducer(
  initialState,
  on(ConsentsActions.ConsentsGetConsentSuccessAction, (state, { institutionId, programId, consents }) => {
    return {
      ...state,
      consents: mergeImmutable(
        { [institutionId]: { [programId]: consents } },
        state.consents
      )
    };
  }),
  on(ConsentsActions.ConsentsInvalidateCacheAction, (state, {}) => {
    return {
      ...state,
      consents: undefined
    };
  })
);

export function consentsReducer(state: ConsentsState | undefined, actions: Action) {
  return reducer(state, actions);
}

export const consents = (state: ConsentsState) => state.consents;
