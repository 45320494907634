import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'ng4h-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardComponent {

  @Input() header: boolean = true;

  constructor() { }
}
