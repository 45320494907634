import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ng4h-modal-add-project',
  templateUrl: './modal-add-project.component.html',
  styleUrls: ['./modal-add-project.component.scss']
})
export class ModalAddProjectComponent implements OnInit {

  public form: FormGroup;
  public wasValidated = false;
  constructor(private dialogRef: MatDialogRef<any>, private fb: FormBuilder) { }

  ngOnInit() {
    this.form = this.fb.group({
      projectName: ['', Validators.required],
      projectDescription: ['', Validators.required],
      url: [''],
    });
  }

  public add() {
    if (this.form.invalid) {
      this.wasValidated = true;
      return;
    }
    this.dialogRef.close(this.form.value);
  }

  public close() {
    this.dialogRef.close();
  }
}
