import { IDemographicsDto } from '.';
import { eEnrollmentRoleType, eSchoolGradeType } from '..';

export interface IMemberUpdateDto {
  email?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  preferredName?: string;
  primaryPhone?: string;
  mobilePhone?: string;
  birthDate?: string;
  jobTitle?: string;
  employer?: string;
  workPhone?: string;
  workExt?: string;
  emergencyContact?: string;
  emergencyContactPhone?: string;
  emergencyContactEmail?: string;
  emergencyContactRelationship?: string;
  guardian1FirstName?: string;
  guardian1LastName?: string;
  guardian1Phone?: string;
  guardian1WorkPhone?: string;
  guardian1WorkExt?: string;
  guardian2FirstName?: string;
  guardian2LastName?: string;
  guardian2Phone?: string;
  guardian2WorkPhone?: string;
  guardian2WorkExt?: string;

  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  postalCode?: string;

  emergencyContact2?: string;
  emergencyContact2Phone?: string;
  emergencyContact2Email?: string;
  emergencyContact2Relationship?: string;

  secondFamilyName?: string;
  secondFamilyFirstNames?: string;
  secondFamilyEmail?: string;
  secondFamilyPhone?: string;
  secondFamilyAddressLine1?: string;
  secondFamilyAddressLine2?: string;
  secondFamilyCity?: string;
  secondFamilyState?: string;
  secondFamilyPostalCode?: string;
  demographics: IDemographicsDto;
  youthYearsInProgram?: number;
  adultYearsInProgram?: number;
  enrollmentRoleType?: eEnrollmentRoleType;
  schoolGrade?: eSchoolGradeType;
}
