<ng-container *ngIf="programSettings$ | async as programSettings">
    <div class="modal-header">
        <h5 class="modal-title">Add Volunteer Projects</h5>
        <button (click)="close()" type="button" class="close" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row" *ngIf="selectedProject == null">
            <div class="col-12">
                <ng-container *ngIf="selectedUnits$ | async as selectedUnits">
                    <ng-container *ngIf="selectedUnits.length > 0">
                        <ng-container *ngIf="showClubsStep$ | async">
                            <label id="radio-group-label">{{(eUnitType.Club | programAlias$ | async)?.singular}} project
                                volunteer or County wide project
                                volunteer</label>
                            <mat-radio-group aria-labelledby="radio-group-label" class="radio-group"
                                [(ngModel)]="showClubSelection" (ngModelChange)="showClubSelectionChange($event)">
                                <mat-radio-button class="radio-button" [value]="true">
                                    {{(eUnitType.Club | programAlias$ | async)?.singular}}
                                </mat-radio-button>
                                <mat-radio-button class="radio-button" [value]="false">
                                    County
                                </mat-radio-button>
                            </mat-radio-group>


                            <div class="form-group" *ngIf="showClubSelection">
                                <ng-container
                                    *ng4hVar="alwaysShowUnitAndProjects$ | async as alwaysShowUnitAndProjects">
                                    <label class="form-control-label "
                                        [ngClass]="{'ng4h-required': alwaysShowUnitAndProjects === false}">{{(eUnitType.Club
                                        | programAlias$ | async)?.singular}}</label>
                                    <select class="form-control" [(ngModel)]="selectedUnitId"
                                        (ngModelChange)="clubSelectionChanged$.emit($event)">
                                        <option *ngIf="alwaysShowUnitAndProjects === true" [ngValue]="null"></option>
                                        <option *ngFor="let unit of selectedUnits" class="form-control"
                                            [ngValue]="unit.unitId">
                                            {{unit.unitName}}</option>
                                    </select>
                                </ng-container>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
                <div class="form-group" *ngIf="!showClubSelection">
                    <label class="form-control-label ng4h-required">County</label>
                    <ng4h-county-area-select
                        [disabled]="programSettings.member_RestrictClubSelectionToFamilyCounty == true"
                        [ngModel]="selectedCountyAreaId" (ngModelChange)="countyAreaIdChanged($event)">
                    </ng4h-county-area-select>
                </div>

                <div class="form-group">
                    <!-- <label class="form-control-label">Project filter</label> -->
                    <input [(ngModel)]="projectFilterString" class="form-control" type="text"
                        placeholder="Type To Search...">
                </div>
            </div>
        </div>

        <div class="row" [hidden]="selectedProject != null">
            <div class="col-12">
                <div *ngIf="(remainingProjects$ | async) as remainingProjects" class="card project-card">
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item"
                            *ngFor="let project of remainingProjects | searchfilter: 'projectName' : projectFilterString">
                            <div>
                                {{project.projectName}}
                                <button type="button" class="btn btn-primary float-right add-project"
                                    (click)="selectProject(project)"
                                    [disabled]="volunteerType == null && programSettings.enrollment_Always_Show_Unit_And_Project_Selection !== true">Select</button>
                            </div>
                        </li>
                        <li class="list-group-item" *ngIf="remainingProjects.length == 0">
                            No projects available
                        </li>
                    </ul>
                </div>

            </div>
        </div>

        <ng-container *ngIf="selectedProject != null">

            <div class="row">
                <div class="col-12">

                    <h4>Selected Project </h4>
                    <button class="btn btn-secondary ng4h-margin-bottom"
                        (click)="selectedProject = null">Deselect</button>
                    <div class="ng4h-table ng4h-margin-bottom">
                        <div class="col-12">


                            <div class="row">
                                <div class="col-4 ng4h-bg-secondary"><b>Project Alias</b></div>
                                <div class="col-8">{{selectedProject.projectName}}</div>
                            </div>
                            <div class="row">
                                <div class="col-4 ng4h-bg-secondary"><b>Description</b></div>
                                <div class="col-8">{{selectedProject.projectDescription}}</div>
                            </div>
                            <div class="row" *ngIf="selectedProject.parentProject != null">
                                <div class="col-4 ng4h-bg-secondary"><b>State Project</b></div>
                                <div class="col-8">{{selectedProject.parentProject.projectName}}</div>
                            </div>
                        </div>
                    </div>



                    <div class="form-group">
                        <label class="form-control-label ng4h-required">Years In Project</label>
                        <ng4h-number-select [(ngModel)]="yearsInProject" [maxNumber]="99"></ng4h-number-select>
                    </div>

                    <div class="form-group">
                        <label class="form-control-label"
                            [ngClass]="{'ng4h-required': programSettings.enrollment_Always_Show_Unit_And_Project_Selection !== true}">Volunteer
                            Type</label>
                        <select class="form-control" [ngModel]="volunteerType"
                            (ngModelChange)="volunteerTypeChanged($event)">
                            <option *ngFor="let vt of volunteerTypes$ | async" [ngValue]="vt">
                                {{ vt.volunteerTypeLabel }}
                            </option>
                        </select>
                    </div>



                </div>
            </div>

        </ng-container>
    </div>
    <div class="modal-footer">
        <button
            [disabled]="selectedProject == null || yearsInProject == null || (volunteerType?.volunteerTypeId == null && programSettings.enrollment_Always_Show_Unit_And_Project_Selection !== true)"
            class="btn btn-primary ng4h-margin-left" (click)="addProject(selectedProject)">Add</button>
        <button (click)="close()" class="btn btn-secondary my-2 my-sm-0" role="button"
            aria-pressed="true">Cancel</button>
    </div>
</ng-container>