import { ePermissionState, eProfileType } from '.';
import { IInstitutionProfile } from './serverDTOs';

export interface ISystemManagerInstitutionProfile extends IInstitutionProfile {
    systemManagerId: string;
    profileType: eProfileType.SystemManager;
    acl: {
        [programId: string]: {
            grantedPermissions: {
                AnimalIdentification: ePermissionState,
                HealthForm: ePermissionState,
                VolunteerScreening: ePermissionState,
                EnrollmentForm: ePermissionState,
                ProgramSettings: ePermissionState,
                Managers: ePermissionState,
                Consents: ePermissionState,
                Trainings: ePermissionState,
                Units: ePermissionState,
                Projects: ePermissionState
            }
        }
    };
    firstName: string;
    lastName: string;
}

