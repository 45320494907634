import { Action, createReducer } from '@ngrx/store';

export interface TicketingState {

}

const initialState: TicketingState = {

};


const reducer = createReducer(
    initialState
);


export function ticketingReducer(state: TicketingState | undefined, action: Action) {
    return reducer(state, action);
}