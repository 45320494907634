<button class="btn btn-secondary" [matMenuTriggerFor]="matMenu">
    Customize
    <mat-menu #matMenu>

        <button class="btn btn-secondary" (click)="toggleLayout($event)">Layout:
            {{currentLayout}}</button>
        <br>
        <button class="btn btn-secondary ng4h-margin-top-small" (click)="reset()">Reset</button>

        <mat-selection-list [formControl]="selectedColumns" (click)="$event.stopPropagation()">
            <mat-list-option *ngFor="let topping of columns | excludeByPropertyValue: 'show': false"
                [value]="topping.key">
                {{topping.value}}
            </mat-list-option>
        </mat-selection-list>
    </mat-menu>

</button>