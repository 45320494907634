import { eAnimalFileType } from '..';

export interface IAnimalFileControlCreateDto {
    animalFileType: eAnimalFileType;
    managerOnly?: boolean;
    required?: boolean;
    persistFile?: boolean;
    fileControlName?: string;
    fileControlDescription?: string;
}
