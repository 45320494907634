import { IHierarchyNodeDto } from '.';
import { IAddressDto } from './serverDTOs/IAddressDto';

export interface IGroup extends IHierarchyNodeDto {
  groupId: string;
  groupProgramYearId: string;
  name: string;
  countyAreaId: string;
  contactAddress: IAddressDto;
  meetingAddress: IAddressDto;
  meetingLocation: string;

  primary?: boolean;
  projects?: any[];
}
