import { CdkAccordionItem } from '@angular/cdk/accordion';
import { UniqueSelectionDispatcher } from '@angular/cdk/collections';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  SkipSelf,
} from '@angular/core';
import { MAT_ACCORDION, MatAccordionBase, matExpansionAnimations } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import { RouterService } from 'app/core/services';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'ng4h-sidebar-accordion',
  templateUrl: './sidebar-accordion.component.html',
  styleUrls: ['./sidebar-accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [matExpansionAnimations.bodyExpansion],
})
export class SidebarAccordionComponent extends CdkAccordionItem implements OnInit, OnDestroy {

  protected autoUnsubscribe = new Subject<void>();
  @Input() openWhenRouteContains: string;

  constructor(@Optional() @SkipSelf() @Inject(MAT_ACCORDION) accordion: MatAccordionBase,
    _changeDetectorRef: ChangeDetectorRef, _expansionDispatcher: UniqueSelectionDispatcher,
    private routerService: RouterService,
    private route: ActivatedRoute) {
    super(accordion,
      _changeDetectorRef,
      _expansionDispatcher);
  }
  ngOnInit() {
    this.routerService.activeUrl$.pipe(
      takeUntil(this.autoUnsubscribe)
    ).subscribe(url => {
      if (url.includes(this.openWhenRouteContains) && this.closed) {
        this.open();
      } else if (!url.includes(this.openWhenRouteContains) && this.open) {
        this.close();
      }
    });
  }

  ngOnDestroy(): void {
    this.autoUnsubscribe.next();
    this.autoUnsubscribe.complete();
  }

}
