import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState, getManagerSidebarOpen, getSidebarMode, getSidebarOpen } from 'app/app.reducers';
import { SidebarActions } from 'app/shared/sidebar';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  public sidebarOpen$: Observable<boolean>;
  public managerSidebarOpen$: Observable<boolean>;
  public sidebarMode$: Observable<string>;

  constructor(private store: Store<AppState>) {
    this.sidebarOpen$ = this.store.pipe(select(getSidebarOpen));
    this.managerSidebarOpen$ = this.store.pipe(select(getManagerSidebarOpen));
    this.sidebarMode$ = this.store.pipe(select(getSidebarMode));

  }

  public toggleSideBar() {
    this.store.dispatch(SidebarActions.ToggleSideBarAction());
  }

  public close() {
    this.store.dispatch(SidebarActions.CloseSidebarAction());
  }

  public toggleManagerSideBar() {
    this.store.dispatch(SidebarActions.ToggleManagerSideBarAction());
  }

  public closeManager() {
    this.store.dispatch(SidebarActions.CloseManagerSidebarAction());
  }
}
