<div class="modal-header">
    <h5 class="modal-title">Edit Family</h5>
    <button (click)="close()" type="button" class="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <ng-container *ngIf="familyAddress != null && showMultipleAddressMatches == false">
        <ng4h-address-input [form]="familyAddress"></ng4h-address-input>
    </ng-container>
    <ng-container *ngIf="showMultipleAddressMatches == true">
        <h4>Multiple Addresses Found:</h4>
        <div class="address" *ngFor="let address of addressMatches">
            <ng4h-address-display (click)="chooseAddress(address)" [address]="address"></ng4h-address-display>
        </div>
    </ng-container>
    <div *ngIf="addressErrors$ | async as addressErrors">
        <div class="text-danger">
            {{addressErrors}}
        </div>
    </div>
</div>
<div class="modal-footer">
    <button class="btn btn-primary" (click)="save()">Save</button>
    <button class="btn btn-secondary" (click)="close()">Cancel</button>
</div>