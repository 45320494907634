import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { eTrainingMode } from 'app/core/models';
import { RouterService, SystemManagerTrainingsService } from 'app/core/services';
import { CommonToastrService } from 'app/core/services/common-toastr.service';

@Component({
  selector: 'ng4h-modal-add-training',
  templateUrl: './modal-add-training.component.html',
  styleUrls: ['./modal-add-training.component.scss']
})
export class ModalAddTrainingComponent implements OnInit {

  public form: FormGroup;
  public eTradingMode = eTrainingMode;
  public wasValidated = false;
  constructor(private dialogRef: MatDialogRef<any>, private fb: FormBuilder, private toastrService: CommonToastrService,
    private trainingService: SystemManagerTrainingsService, private routerService: RouterService) { }

  ngOnInit() {
    this.form = this.fb.group({
      trainingName: ['', Validators.required],
      trainingMode: ['', Validators.required],
    });
  }

  public add() {
    if (this.form.invalid) {
      this.wasValidated = true;
      return;
    }
    this.trainingService.createSystemManagerTraining({ institutionId: this.routerService.institutionId, systemManagerId: this.routerService.systemManagerId, programId: this.routerService.programId, training: this.form.value })
      .subscribe({
        next: () => {
          this.toastrService.success('training created');
        }, error: (error) => {
          this.toastrService.error('training creation failed');
        }
      });
    this.close();
  }

  public close() {
    this.dialogRef.close();
  }
}
