<div class="modal-header">
  <h5 class="modal-title">Edit Custom File</h5>
  <button (click)="close()" type="button" class="close" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <div class="form-group row">
      <label class="col-md-3 col-form-label ng4h-required"> Name </label>
      <div class="col-md-9 form-input">
        <input formControlName="fileControlName" type="text" class="form-control">
      </div>
    </div>

    <div class="form-group row">
      <label class="col-md-3 col-form-label"> Description </label>
      <div class="col-md-9 form-input">
        <textarea formControlName="fileControlDescription" type="text" class="form-control"></textarea>
      </div>
    </div>

    <div class="form-group row">
      <label class="form-control-label col-md-3">Manager Only</label>
      <div class="col-md-9">
        <mat-checkbox formControlName="managerOnly"></mat-checkbox>
      </div>
    </div>

    <div formGroupName="enrollmentRoles">
      <!-- <div class="form-group row">
            <label class="form-control-label col-md-3">Volunteer</label>
            <div class="col-md-9">
              <mat-checkbox formControlName="Volunteer"></mat-checkbox>
            </div>
          </div> -->
      <div class="form-group row">
        <label
          class="form-control-label col-md-3">{{(eEnrollmentRoleType['Volunteer'] | programAlias$ | async)?.singular}}</label>
        <div class="col-md-9">
          <mat-radio-group formControlName="Volunteer">
            <mat-radio-button [value]="null" class="pr-2">
              Not Visible
            </mat-radio-button>
            <mat-radio-button [value]="false" class="p-2">
              Visible
            </mat-radio-button>
            <mat-radio-button [value]="true" class="p-2">
              Required
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="form-group row">
        <label
          class="form-control-label col-md-3">{{(eEnrollmentRoleType['CloverBud'] | programAlias$ | async)?.singular}}</label>
        <div class="col-md-9">
          <mat-radio-group formControlName="CloverBud">
            <mat-radio-button [value]="null" class="pr-2">
              Not Visible
            </mat-radio-button>
            <mat-radio-button [value]="false" class="p-2">
              Visible
            </mat-radio-button>
            <mat-radio-button [value]="true" class="p-2">
              Required
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="form-group row">
        <label
          class="form-control-label col-md-3">{{(eEnrollmentRoleType['ClubMember'] | programAlias$ | async)?.singular}}</label>
        <div class="col-md-9">
          <mat-radio-group formControlName="ClubMember">
            <mat-radio-button [value]="null" class="pr-2">
              Not Visible
            </mat-radio-button>
            <mat-radio-button [value]="false" class="p-2">
              Visible
            </mat-radio-button>
            <mat-radio-button [value]="true" class="p-2">
              Required
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>


    </div>
  </form>
</div>
<div class="modal-footer">
  <button class="btn btn-danger" (click)="delete()">Delete</button>
  <button [disabled]="form.invalid" (click)="update()" class="btn btn-primary my-2 my-sm-0" role="button"
    aria-pressed="true">Update</button>
</div>