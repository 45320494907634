import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'ng4h-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileUploaderComponent implements OnInit {

  @ViewChild('file', { static: true }) file;

  @Input() uploading: boolean;
  @Input() disabled = false;
  @Input() accept = '';
  @Input() hasFile = false;
  @Output() uploadFile: EventEmitter<File> = new EventEmitter<File>();

  public form: FormGroup;

  constructor(private fb: FormBuilder) { }

  public ngOnInit(): void {
    this.form = this.fb.group({
      file: ['']
    });
  }


  public addFiles() {
    this.file.nativeElement.click();
  }

  public onFileChange(files: FileList): void {
    const formData = new FormData();

    if (files.length > 0) {
      const file = files.item(0);
      formData.append('file', file, file.name);
      this.uploadFile.emit(file);
    }
  }
}
