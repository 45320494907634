import { eUnitType, IHierarchyNodeDto, IMemberVolunteerDto } from '..';

export interface IEnrollmentUnitDto extends IHierarchyNodeDto {
  unitId: string;
  unitName?: string;
  unitType: eUnitType;
  primary: boolean;
  countyArea: IHierarchyNodeDto;
  volunteerType: IMemberVolunteerDto;
  clubLeaderLoginCode?: string;
}
