import {
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
  RepositionScrollStrategy,
  ScrollDispatcher,
  ViewportRuler
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Directive, ElementRef, HostListener, NgZone, OnInit } from '@angular/core';
import { HierarchyTreeComponent } from 'app/core/components/hierarchy-tree/hierarchy-tree.component';

@Directive({
  selector: '[ng4hHierarchyTreeOverlay]'
})
export class HierarchyTreeOverlayDirective implements OnInit {

  private overlayRef: OverlayRef;

  constructor(private overlayPositionBuilder: OverlayPositionBuilder, private elementRef: ElementRef, private scroll: ScrollDispatcher, private viewport: ViewportRuler, private zone: NgZone, private overlay: Overlay) {
  }

  ngOnInit() {
    const positionStrategy = this.overlayPositionBuilder
    // Create position attached to the elementRef
    .flexibleConnectedTo(this.elementRef)
    // Describe how to connect overlay to the elementRef
    // Means, attach overlay's center bottom point to the
    // top center point of the elementRef.
    .withPositions([
      {
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'top'
      }
    ]);
    this.overlayRef = this.overlay.create({
      panelClass: ['hierarchy-tree-overlay'],
      positionStrategy: positionStrategy,
      scrollStrategy: new RepositionScrollStrategy(this.scroll, this.viewport, this.zone)
    });
  }

  @HostListener('click')
  show() {
    const hierarchyTreePortal = new ComponentPortal(HierarchyTreeComponent);
    this.overlayRef.attach(hierarchyTreePortal);
  }

  @HostListener('document:click', ['$event.target'])
  hide(targetElement) {

    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    const clickedInsideOverlay = this.overlayRef.overlayElement.contains(targetElement);
    if (!clickedInside && !clickedInsideOverlay) {
      this.overlayRef.detach();
    }
  }
}
