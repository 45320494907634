<div class="modal-header">
  <h5 class="modal-title" id="enrollModalLabel">Select Profile</h5>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-12" *ngIf="userAccount$ | async as userAccount">
      <ng-container *ngFor="let profile of userAccount.institutionProfiles">
        <ng-container [ngSwitch]="profile.profileType">
          <!-- <a *ngSwitchCase="eProfileType.Superuser" (click)="superUserProfileSelected(profile)" class="ng4h-list-row">
            Superuser
          </a> -->
          <ng-container *ngSwitchCase="eProfileType.SystemManager">
            <!-- <ng-container *ngFor="let systemManagerKeyValue of asSystemManager(profile).acl | keyvalue"> -->
            <a (click)="systemManagerSelected(profile)" class="ng4h-list-row">
              System Manager
            </a>
            <!-- </ng-container> -->
          </ng-container>
          <ng-container *ngSwitchCase="eProfileType.ReportConsumer">
            <!-- <ng-container *ngFor="let systemManagerKeyValue of asSystemManager(profile).acl | keyvalue"> -->
            <a (click)="reportConsumerSelected(profile)" class="ng4h-list-row">
              Report Consumer
            </a>
            <!-- </ng-container> -->
          </ng-container>
          <ng-container *ngSwitchCase="eProfileType.Manager">
            <!-- <ng-container *ngFor="let managerKeyValue of asManager(profile).managerPrograms | keyvalue"> -->
            <a (click)="managerProfileSelected(profile)" class="ng4h-list-row">
              Manager
            </a>
            <!-- </ng-container> -->
          </ng-container>
          <a *ngSwitchCase="eProfileType.Family" (click)="familyProfileSelected(profile)" class="ng4h-list-row">
            {{asFamily(profile).familyName}}
          </a>
        </ng-container>

      </ng-container>
      <a *ngIf="userAccount.superuserId" (click)="superUserSelected()" class="ng4h-list-row">
        Superuser
      </a>
    </div>
  </div>
</div>