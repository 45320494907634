import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EMAIL_REGEX, IMemberDto, PHONE_REGEX } from 'app/core/models';
import { MemberService, RouterService } from 'app/core/services';

@Component({
  selector: 'ng4h-modal-edit-member',
  templateUrl: './modal-edit-member.component.html',
  styleUrls: ['./modal-edit-member.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalEditMemberComponent implements OnInit {

  public member: IMemberDto;
  public form: FormGroup = null;

  constructor(private dialogRef: MatDialogRef<any>,
    private memberService: MemberService,
    private routerService: RouterService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) private data: { member: IMemberDto }) {
  }

  ngOnInit() {
    this.member = this.data.member;

    this.form = this.fb.group({

      firstName: [this.member.firstName, Validators.required],
      middleName: [this.member.middleName],
      lastName: [this.member.lastName, Validators.required],
      preferredName: [this.member.preferredName],
      email: [this.member.email, [Validators.email, Validators.pattern(EMAIL_REGEX)]],
      mobilePhone: [this.member.mobilePhone, [Validators.pattern(PHONE_REGEX)]],
      birthDate: [this.member.birthDate],

      addressLine1: [this.member.addressLine1],
      addressLine2: [this.member.addressLine2],
      city: [this.member.city],
      state: [this.member.state],
      postalCode: [this.member.postalCode],
    });
  }

  public close() {
    this.dialogRef.close();
  }

  public save() {
    const memberUpdate: IMemberDto = {
      ...this.member,
      ...this.form.value,

    };
    this.memberService.updateMember({ institutionId: this.routerService.institutionId, memberId: this.routerService.memberId, memberUpdate }).subscribe(() => {
      this.close();
    });
  }
}
