<div class="modal-header">
  <div class="modal-title">Move Under New Parent</div>
  <button (click)="close()" type="button" class="close" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <div class="form-group row">
      <label class="form-control-label col-md-12">Move {{(hierarchyNode$ | async)?.hierarchyNodeName}} under new
        parent:</label>
    </div>
    <div class="form-group row">
      <label class="form-control-label col-md-3">New Parent:</label>
      <div class="col-md-9">
        <select class="form-control" formControlName="moveToParentHierarchyNodeId">
          <option [ngValue]="null"></option>
          <option *ngFor="let node of parentListToPickFrom$ | async" [value]="node.hierarchyNodeId">
            {{node.hierarchyNodeName}}
          </option>
        </select>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button [disabled]="form.invalid" (click)="save()" class="btn btn-primary my-2 my-sm-0">Save</button>
</div>