import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
    IDeliveryModeCreateDto,
    IDeliveryModeDto,
    IDeliveryModeUpdateDto,
    IInstitutionSystemManagerProgramParams,
} from 'app/core/models';

export const SystemDeliveryModeLoadAction = createAction(
  '[system-manager-delivery-mode] system delivery modes load',
  props<IInstitutionSystemManagerProgramParams>()
);

export const SystemDeliveryModeLoadSuccessAction = createAction(
  '[system-manager-delivery-mode] system delivery modes load success',
  props<IInstitutionSystemManagerProgramParams & { systemDeliveryModes: IDeliveryModeDto[] }>()
);

export const SystemDeliveryModeLoadErrorAction = createAction(
  '[system-manager-delivery-mode] system delivery modes load error',
  props<{ error: HttpErrorResponse }>()
);

export const DeliveryModeLoadAction = createAction(
  '[system-manager-delivery-mode] delivery modes load',
  props<IInstitutionSystemManagerProgramParams>()
);

export const DeliveryModeLoadSuccessAction = createAction(
  '[system-manager-delivery-mode] delivery modes load success',
  props<IInstitutionSystemManagerProgramParams & { deliveryModes: IDeliveryModeDto[] }>()
);

export const DeliveryModeLoadErrorAction = createAction(
  '[system-manager-delivery-mode] delivery modes load error',
  props<{ error: HttpErrorResponse }>()
);

export const DeliveryModeLoadByIdAction = createAction(
  '[system-manager-delivery-mode] delivery mode load by id',
  props<IInstitutionSystemManagerProgramParams & { deliveryModeId: string }>()
);

export const DeliveryModeLoadByIdSuccessAction = createAction(
  '[system-manager-delivery-mode] delivery mode load by id success',
  props<IInstitutionSystemManagerProgramParams & { deliveryModeId: string, deliveryMode: IDeliveryModeDto }>()
);

export const DeliveryModeLoadByIdErrorAction = createAction(
  '[system-manager-delivery-mode] delivery mode load by id error',
  props<{ error: HttpErrorResponse }>()
);

export const DeliveryModeAddAction = createAction(
  '[system-manager-delivery-mode] add delivery mode',
  props<IInstitutionSystemManagerProgramParams & { deliveryMode: IDeliveryModeCreateDto }>()
);

export const DeliveryModeAddSuccessAction = createAction(
  '[system-manager-delivery-mode] add delivery mode success',
  props<IInstitutionSystemManagerProgramParams & { deliveryMode: IDeliveryModeCreateDto }>()
);

export const DeliveryModeAddErrorAction = createAction(
  '[system-manager-delivery-mode] add delivery mode error',
  props<{ error: HttpErrorResponse }>()
);

export const DeliveryModeUpdateAction = createAction(
  '[system-manager-delivery-mode] update delivery mode',
  props<IInstitutionSystemManagerProgramParams & { deliveryModeId: string, deliveryMode: IDeliveryModeUpdateDto }>()
);

export const DeliveryModeUpdateSuccessAction = createAction(
  '[system-manager-delivery-mode] update delivery mode success',
  props<IInstitutionSystemManagerProgramParams & { deliveryModeId: string, deliveryMode: IDeliveryModeUpdateDto }>()
);

export const DeliveryModeUpdateErrorAction = createAction(
  '[system-manager-delivery-mode] update delivery mode error',
  props<{ error: HttpErrorResponse }>()
);

export const DeliveryModeDeleteAction = createAction(
  '[system-manager-delivery-mode] delete delivery mode',
  props<IInstitutionSystemManagerProgramParams & { deliveryModeId: string }>()
);

export const DeliveryModeDeleteSuccessAction = createAction(
  '[system-manager-delivery-mode] delete delivery mode success',
  props<IInstitutionSystemManagerProgramParams & { deliveryModeId: string }>()
);

export const DeliveryModeDeleteErrorAction = createAction(
  '[system-manager-delivery-mode] delete delivery mode error',
  props<{ error: HttpErrorResponse }>()
);

