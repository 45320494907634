import { eInvoiceStatus, IMemberSearchResultDto, IProgramYearDto } from '..';
import { ePaymentMethodType, ePaymentStatus, ePaymentTransactionStatus, ePaymentTransactionType } from '../enums';


export interface IPaymentSearchResultDto extends IMemberSearchResultDto {


    registrationId: string;
    refundable: boolean;
    paymentId: string;
    invoiceNumber: number;
    invoiceStatus: eInvoiceStatus;
    paymentAmount: number;
    paymentMethodType: ePaymentMethodType;
    paymentType: string;
    paymentStatus: ePaymentStatus;
    paymentTransactionDate: string;
    paymentTransactionNumber: number;
    paymentTransactionStatus: ePaymentTransactionStatus;
    paymentTransactionType: ePaymentTransactionType;


    invoiceDiscountId: string;
    invoiceId: string;

    memberCreditCollectionId: string;

    membershipId: number;
    membershipIdent: number;
    orderId: string;
    orderNumber: number;

    paymentNumber: number;

    paymentTransactionId: string;

    profileIdent: number;

    programYear: IProgramYearDto;

    searchResultType: string;
    source: string;

    enrollmentAnimalId: string;

}
