import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { AppState, getEventEmailTemplates, getProgramEmailTemplates } from 'app/app.reducers';
import { EmailTemplateActions } from 'app/shared/email-template';
import { filter, map, Observable, of, take, tap } from 'rxjs';

import {
  eEventEmailType,
  eProgramEmailType,
  IEventEmailTemplatesDto,
  IInstitutionManagerProgramEventParams,
  IInstitutionSystemManagerProgramParams,
  IProgramEmailTemplatesDto,
  IProgramEmailTemplateUpdateDto
} from '../models';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmailTemplateService {

  constructor(private store: Store<AppState>, private httpClient: HttpClient, private dispatcher: ActionsSubject) {

  }

  public getProgramEmailTemplates(params: IInstitutionSystemManagerProgramParams): Observable<IProgramEmailTemplatesDto> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getProgramEmailTemplates(params)),
      tap(templates => {
        if (templates === undefined) {
          this.store.dispatch(EmailTemplateActions.EmailTemplateProgramLoadAction(params));
        }
      }),
      filter(templates => templates != null)
    );
  }

  public loadProgramEmailTemplatesEffect({
    institutionId,
    systemManagerId,
    programId
  }: IInstitutionSystemManagerProgramParams): Observable<any> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/email-templates`) as Observable<any>;
  }

  public updateProgramEmailTemplate(parmas: IInstitutionSystemManagerProgramParams & { typeId: eProgramEmailType, template: IProgramEmailTemplateUpdateDto }) {
    this.store.dispatch(EmailTemplateActions.EmailTemplateProgramUpdateAction(parmas));

    return this.dispatcher.pipe(
      ofType(EmailTemplateActions.EmailTemplateProgramUpdateSuccessAction, EmailTemplateActions.EmailTemplateProgramUpdateErrorAction),
      take(1),
      map(action => {
        if (action.type === EmailTemplateActions.EmailTemplateProgramUpdateSuccessAction.type) {
          return action.template;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateProgramEmailTemplateEffect({
    institutionId,
    systemManagerId,
    programId,
    typeId,
    template
  }: IInstitutionSystemManagerProgramParams & { typeId: eProgramEmailType, template: IProgramEmailTemplateUpdateDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/email-templates/${typeId}`, template);
  }

  public getEventEmailTemplates(params: IInstitutionManagerProgramEventParams): Observable<IEventEmailTemplatesDto> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getEventEmailTemplates(params)),
      tap(templates => {
        if (templates === undefined) {
          this.store.dispatch(EmailTemplateActions.EmailTemplateEventLoadAction(params));
        }
      }),
      filter(templates => templates != null)
    );
  }

  public loadEventEmailTemplatesEffect({
    institutionId,
    managerId,
    programId,
    eventId
  }: IInstitutionManagerProgramEventParams): Observable<any> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/email-templates`) as Observable<any>;
  }

  public updateEventEmailTemplate(params: IInstitutionManagerProgramEventParams & { eventType: eEventEmailType, template: IProgramEmailTemplateUpdateDto }) {
    this.store.dispatch(EmailTemplateActions.EmailTemplateEventUpdateAction(params));

    return this.dispatcher.pipe(
      ofType(EmailTemplateActions.EmailTemplateEventUpdateSuccessAction, EmailTemplateActions.EmailTemplateEventUpdateErrorAction),
      take(1),
      map(action => {
        if (action.type === EmailTemplateActions.EmailTemplateEventUpdateSuccessAction.type) {
          return action.template;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateEventEmailTemplateEffect({
    institutionId,
    managerId,
    programId,
    eventId,
    template,
    eventType
  }: IInstitutionManagerProgramEventParams & { eventType: eEventEmailType, template: IProgramEmailTemplateUpdateDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/email-templates/${eventType}`, template);
  }

  public deleteEventEmailTemplate(params: IInstitutionManagerProgramEventParams & { eventType: eEventEmailType }) {
    this.store.dispatch(EmailTemplateActions.EmailTemplateEventDeleteAction(params));

    return this.dispatcher.pipe(
      ofType(EmailTemplateActions.EmailTemplateEventDeleteSuccessAction, EmailTemplateActions.EmailTemplateEventDeleteErrorAction),
      take(1),
      map(action => {
        if (action.type === EmailTemplateActions.EmailTemplateEventDeleteSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteEventEmailTemplateEffect({
    institutionId,
    managerId,
    programId,
    eventId,
    eventType
  }: IInstitutionManagerProgramEventParams & { eventType: eEventEmailType }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/email-templates/${eventType}`);
  }

}
