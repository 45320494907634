export interface IFamilyUserAccountCreateDto {
  institutionId: string;
  countyId: string;
  countyAreaId: string;
  email?: string;
  password?: string;
  familyName?: string;
  primaryMobilePhone?: string;
}

