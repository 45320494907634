import { IAddressDto } from '.';
import { eDayOfWeek } from '..';

export interface IUnitUpdateContactAndAddressDto {
    contactName?: string;
    contactEmailAddress?: string;
    contactAddress: IAddressDto;
    primaryPhone?: string;
    mobilePhone?: string;
    websiteUrl?: string;
    meetingAddress: IAddressDto;
    meetingLocation?: string;
    meetingDay?: eDayOfWeek;
    meetingTime?: string;
}
