<div class="modal-section">
  <div class="modal-header">
    <h5 class="modal-title">Edit Enrollment Fee</h5>
    <button (click)="cancel()" type="button" class="close" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row justify-content-center">
      <h4>{{ hierarchyName }} - {{ hierarchyType | camelToTitleCase }}</h4>
      <br>
    </div>
    <div class="row justify-content-center" *ngIf="countyArea$ | async as countyArea">
      <b>
        {{ countyArea.hierarchyNodeName }}
      </b>
    </div>
    <form [formGroup]="form">
      <div class="row">
        <div class="col-md-12">
          <h5 class="enrollment-fee-role-header"><b>{{ (eEnrollmentRoleType.CloverBud | programAlias$ | async)?.singular }}</b></h5>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="form-control-label">Normal Fee</label>
            <input type="number" ng4hDecimalPrecision precision="2" class="form-control" aria-label="Small"
                   aria-describedby="inputGroup-sizing-sm" formControlName="cloverNormal">
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="form-control-label">Late Fee</label>
            <input type="number" class="form-control" ng4hDecimalPrecision precision="2" aria-label="Small"
                   aria-describedby="inputGroup-sizing-sm" formControlName="cloverLate">
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <h5 class="enrollment-fee-role-header"><b>{{ (eEnrollmentRoleType.ClubMember | programAlias$ | async)?.singular }}</b></h5>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="form-control-label">Normal Fee</label>
            <input type="number" ng4hDecimalPrecision precision="2" class="form-control" aria-label="Small"
                   aria-describedby="inputGroup-sizing-sm" formControlName="youthNormal">
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="form-control-label">Late Fee</label>
            <input type="number" class="form-control" ng4hDecimalPrecision precision="2" aria-label="Small"
                   aria-describedby="inputGroup-sizing-sm" formControlName="youthLate">
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <h5 class="enrollment-fee-role-header"><b>{{ (eEnrollmentRoleType.Volunteer | programAlias$ | async)?.singular }}</b></h5>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label class="form-control-label">Normal Fee</label>
            <input type="number" ng4hDecimalPrecision precision="2" class="form-control" aria-label="Small"
                   aria-describedby="inputGroup-sizing-sm" formControlName="adultNormal">
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label class="form-control-label">Late Fee</label>
            <input type="number" class="form-control" ng4hDecimalPrecision precision="2" aria-label="Small"
                   aria-describedby="inputGroup-sizing-sm" formControlName="adultLate">
          </div>
        </div>
      </div>
    </form>
  </div>

  <div *ngIf="form.errors" class="ng4h-padding ng4h-danger">
    <div *ngIf="form.errors.cloverBudLateFeeHigherThanNormal">
      {{ (eEnrollmentRoleType.CloverBud | programAlias$ | async)?.singular }} normal fee higher than late fee.
    </div>
    <div *ngIf="form.errors.YouthLateFeeHigherThanNormal">
      {{ (eEnrollmentRoleType.ClubMember | programAlias$ | async)?.singular }} normal fee higher than late fee.
    </div>
    <div *ngIf="form.errors.VolunteerLateFeeHigherThanNormal">
      {{ (eEnrollmentRoleType.Volunteer | programAlias$ | async)?.singular }} normal fee higher than late fee.
    </div>
  </div>

  <div class="modal-footer" *ngIf="form">
    <button id (click)="cancel()" class="btn btn-secondary my-2 my-sm-0" role="button"
            aria-pressed="true">Cancel
    </button>
    <button [disabled]="form.invalid" (click)="update()" class="btn btn-primary my-2 my-sm-0" role="button"
            aria-pressed="true">Update
    </button>
  </div>
</div>