import { IEmailSummaryDto, IFamilyMemberSummaryDto } from '.';
import { IAddressDto } from './IAddressDto';

export interface IFamilyDashboardSummaryDto {
  familyId: string;
  countyId: string;
  county?: string;
  countyHierarchyNodeId: string;
  familyName?: string;
  email?: string;
  address: IAddressDto;
  recentEmails?: IEmailSummaryDto[];
  familyMembers?: IFamilyMemberSummaryDto[];
  additionalPaymentRequired: boolean;
  maxMembersForEventsExceeded: boolean;
}
