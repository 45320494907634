import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import {
  eFormControlType,
  eFormType,
  IDynamicControl,
  IDynamicForm,
  IDynamicGroup,
  IDynamicItem,
  IInstitutionManagerProgramEventParams,
  IInstitutionManagerProgramParams,
  IInstitutionSystemManagerProgramParams
} from 'app/core/models';
import { mergeImmutable } from 'app/shared/utils';
import { map, Observable, take } from 'rxjs';

import { AdminFeatureStore, getDynamicForm } from '../containers/admin/admin-feature.reducers';
import { DynamicFormActions } from '../containers/admin/shared/dynamic-forms';
import { eDynamicValidatorName, IDynamicValidator } from '../models';
import { DynamicFormsMultiItemRequiredValidator } from '../validators';
import { limitChoicesValidator } from '../validators/limit-choices.validator';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class DynamicFormService {

  public form$: Observable<IDynamicForm>;
  public formType$: Observable<eFormType>;

  constructor(private store: Store<AdminFeatureStore>, private httpClient: HttpClient, private dispatcher: ActionsSubject) {
    this.form$ = this.store.pipe(select(getDynamicForm));
    this.formType$ = this.form$.pipe(map(form => form == null ? null : form.formType));
  }

  public loadManagerEnrollmentForm(params: IInstitutionManagerProgramParams) {
    this.store.dispatch(DynamicFormActions.DynamicFormLoadManagerEnrollmentFormAction(params));
  }

  public loadManagerEnrollmentFormEffect({
    institutionId: institutionId,
    managerId,
    programId
  }: IInstitutionManagerProgramParams): Observable<IDynamicForm> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/enrollment-form`) as Observable<IDynamicForm>;
  }

  public loadSystemManagerEnrollmentForm(params: IInstitutionSystemManagerProgramParams) {
    this.store.dispatch(DynamicFormActions.DynamicFormLoadSystemManagerEnrollmentFormAction(params));
  }

  public loadSystemManagerEnrollmentFormEffect({
    institutionId,
    systemManagerId,
    programId
  }: IInstitutionSystemManagerProgramParams): Observable<IDynamicForm> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/enrollment-form`) as Observable<IDynamicForm>;
  }

  public saveEnrollmentForm({ institutionId, systemManagerId, programId }: IInstitutionSystemManagerProgramParams) {
    this.form$.pipe(take(1), map(form => form.groups)).subscribe(groups => {
      const dynamicForm: IDynamicForm = {
        formId: null,
        formType: eFormType.Enrollment,
        formDisplayTypes: [],
        groups: groups
      };
      this.store.dispatch(DynamicFormActions.DynamicFormSubmitEnrollmentAction({
        institutionId,
        systemManagerId,
        programId,
        form: dynamicForm
      }));
    });
    // I'm waiting on the form to re-load for a 'full save' rather than just the save so we get back correct ids
    return this.dispatcher.pipe(
      ofType(DynamicFormActions.DynamicFormLoadSystemManagerEnrollmentFormSuccessAction, DynamicFormActions.DynamicFormLoadSystemManagerEnrollmentFormErrorAction),
      take(1),
      map(action => {
        if (action.type === DynamicFormActions.DynamicFormLoadSystemManagerEnrollmentFormSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateEnrollmentFormEffect({
    institutionId,
    systemManagerId,
    programId,
    form
  }: IInstitutionSystemManagerProgramParams & { form: IDynamicForm }): Observable<IDynamicForm> {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/enrollment-form`, form) as Observable<IDynamicForm>;
  }

  public loadDynamicEventForm(params: IInstitutionManagerProgramParams & { eventId: string }) {
    this.store.dispatch(DynamicFormActions.DynamicFormLoadEventFormAction(params));
  }

  public loadDynamicEventFormEffect({
    institutionId,
    managerId,
    programId,
    eventId
  }: IInstitutionManagerProgramParams & { eventId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/registration-form`) as Observable<IDynamicForm>;
  }

  public saveDynamicEventForm({
    institutionId,
    managerId,
    programId,
    eventId
  }: IInstitutionManagerProgramParams & { eventId: string }) {
    this.form$.pipe(take(1), map(form => form.groups)).subscribe(groups => {
      const dynamicForm: IDynamicForm = {
        formId: null,
        formType: eFormType.Registration,
        formDisplayTypes: [],
        groups: groups
      };
      this.store.dispatch(DynamicFormActions.DynamicFormSubmitEventAction({
        institutionId,
        managerId,
        programId,
        eventId: eventId,
        form: dynamicForm
      }));
    });

    // I'm waiting on the form to re-load for a 'full save' rather than just the save so we get back correct ids
    return this.dispatcher.pipe(
      ofType(DynamicFormActions.DynamicFormLoadEventFormSuccessAction, DynamicFormActions.DynamicFormLoadEventFormErrorAction),
      take(1),
      map(action => {
        if (action.type === DynamicFormActions.DynamicFormLoadEventFormSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateDynamicEventFormEffect({
    institutionId,
    managerId,
    programId,
    eventId,
    form
  }: IInstitutionManagerProgramEventParams & { eventId: string, form: IDynamicForm }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/registration-form`, form) as Observable<IDynamicForm>;
  }

  public toFormGroup(controls: IDynamicControl[]): FormGroup {
    const controlForm: FormGroup = new FormGroup({});
    controls.forEach(control => {
      const itemForm: FormGroup = new FormGroup({});
      // userResponse comes back as a string always, in the case of multi choice, checkbox and time slot we have to convert to boolean
      if (control.type === eFormControlType.MultipleChoice) {
        control.items.forEach(item => {
          const val = item?.attributes?.quantityAvailable === 0 ? null : (item.userResponse === 'true');
          const dis = item?.attributes?.quantityAvailable === 0;
          itemForm.setControl(item.itemId, new FormControl({ value: val, disabled: dis }));
        });
      } else if (control.type === eFormControlType.Checkbox || control.type === eFormControlType.TimeSlot) {
        control.items.forEach(item => {
          itemForm.setControl(item.itemId, new FormControl(item?.attributes?.quantityAvailable === 0 ? null : (item.userResponse === 'true'), this.getControlValidators(control)));
        });
      } else if (control.type === eFormControlType.Quantity) {
        control.items.forEach(item => {
          itemForm.setControl(item.itemId, new FormControl(item?.attributes?.quantityAvailable === 0 ? null : item.userResponse, [...this.getControlValidators(control), ...this.getItemQuantityValidator(item)]));
        });
      } else if (control.type === eFormControlType.SingleChoice) {
        control.items.forEach(item => {
          const val = item?.attributes?.quantityAvailable === 0 ? null : item.userResponse;
          const dis = item?.attributes?.quantityAvailable === 0;
          itemForm.setControl(item.itemId, new FormControl({
            value: val,
            disabled: dis
          }, this.getControlValidators(control)));
        });
      } else {
        control.items.forEach(item => {
          itemForm.setControl(item.itemId, new FormControl(item?.attributes?.quantityAvailable === 0 ? null : item.userResponse, this.getControlValidators(control)));
        });
      }
      controlForm.setControl(control.controlId, itemForm);
      if (control.type === eFormControlType.MultipleChoice) {
        const controlValidators = this.getControlValidators(control);
        if (Array.isArray(controlValidators) && controlValidators.length > 0) {
          controlForm.controls[control.controlId].setValidators(controlValidators);
        }
      }
    });
    return controlForm;
  }

  public trimDynamicForm(responses: IDynamicControl[][]) {
    let trimmedResponse: { [controlId: string]: { [itemId: string]: any } } = {};
    Object.keys(responses).forEach(controlId => {
      Object.keys(responses[controlId]).forEach(itemId => {
        if (responses?.[controlId]?.[itemId] != null) {
          trimmedResponse = mergeImmutable(
            { [controlId]: { [itemId]: responses[controlId][itemId] } },
            trimmedResponse
          );
        }
      });
    });
    return trimmedResponse;
  }

  // public toFormControl(value: any, control: DynamicControl): FormControl {
  //   const validators = this.addValidators(control);
  //   return DynamicFormActions.FormControl(value, validators);
  // }

  private getItemQuantityValidator(item: IDynamicItem) {
    const validators: ValidatorFn[] = [];
    if (item.attributes?.quantityAvailable != null) {
      validators.push(Validators.max(Number(item.attributes.quantityAvailable)));
    }
    return validators;
  }

  private getControlValidators(control: IDynamicControl): ValidatorFn[] {
    const validators: ValidatorFn[] = [];
    if (!Array.isArray(control.validators) || control.validators.length < 1) {
      return [];
    }
    control.validators.forEach(validator => {
      const validatorFn = this.getValidator(control, validator);
      if (validatorFn != null) {
        validators.push(validatorFn);
      }
    });
    return validators;
  }

  private getValidator(control: IDynamicControl, validator: IDynamicValidator): ValidatorFn {
    if (control.type === eFormControlType.MultipleChoice) {
      switch (validator.name) {
        case eDynamicValidatorName.Required: {
          if (validator.arg === true) {
            return DynamicFormsMultiItemRequiredValidator(control);
          }
          return null;
        }
        case eDynamicValidatorName.MaxAllowedSelections: {
          return limitChoicesValidator(Number(validator.arg));
        }
        default: {
          return null;
        }
      }
    }

    switch (validator.name) {
      case eDynamicValidatorName.Required: {
        if (validator.arg === true) {
          if (control.type === eFormControlType.Checkbox) {
            return Validators.requiredTrue;
          }
          return Validators.required;
        }
        return null;
      }
      case eDynamicValidatorName.Min: {
        return Validators.min(Number(validator.arg));
      }
      case eDynamicValidatorName.Max: {
        return Validators.max(Number(validator.arg));
      }
      default: {
        return null;
      }
    }
  }

  public calculateFees(formValue: any, groups: IDynamicGroup[]): number {

    let totalFees = 0;
    const formValueElements = Object.keys(formValue);

    formValueElements.forEach(valueElementKey => {
      if (formValue[valueElementKey] != null) {
        const childrenKeys = Object.keys(formValue[valueElementKey]);
        if (childrenKeys.length > 0 && typeof formValue[valueElementKey] === 'object') {
          childrenKeys.forEach(childKey => {
            if (formValue[valueElementKey][childKey] != null) {
              totalFees += this.getFee(groups, childKey);
            }
          });
        } else if (formValue[valueElementKey] != null) {
          if (typeof formValue[valueElementKey] === 'number') {
            totalFees += (formValue[valueElementKey] * this.getFee(groups, valueElementKey));
          } else {
            totalFees += this.getFee(groups, valueElementKey);
          }
        }
      }
    });
    return totalFees;
  }

  private getFee(groups: IDynamicGroup[], key: string): number {
    const element = this.getElementFromFormTemplate(groups, key);
    if (element != null && element.rate != null) {
      return element.rate;
    }
    return 0;
  }

  private getElementFromFormTemplate(groups: IDynamicGroup[], key: string): any {
    for (let k = 0; k < groups.length; k++) {
      for (let i = 0; i < groups[k].controls.length; i++) {
        if (groups[k].controls[i].controlId === key) {
          return groups[k].controls[i];
        } else if (groups[k].controls[i].items != null && Array.isArray(groups[k].controls[i].items)) {
          for (let j = 0; j < groups[k].controls[i].items.length; j++) {
            if (groups[k].controls[i].items[j].itemId === key) {
              return groups[k].controls[i].items[j];
            }
          }
        }
      }
    }
    return null;
  }

  public undo() {
    this.store.dispatch(DynamicFormActions.DynamicFormUndoAction());
  }

  public redo() {
    this.store.dispatch(DynamicFormActions.DynamicFormRedoAction());
  }
}
