import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit } from '@angular/core';
import { ePageMessage, IInstitutionVolunteerDto, IProgramPageMessageDto } from 'app/core/models';
import { EnrollmentService, ModalService, ProfileService, ProgramSettingsService, RouterService } from 'app/core/services';
import { combineLatest, filter, map, Observable, startWith, switchMap } from 'rxjs';

import {
  ModalAddEnrollmentVolunteerTypesComponent,
} from '../modal-add-enrollment-volunteer-types/modal-add-enrollment-volunteer-types.component';

@Component({
  selector: 'ng4h-add-volunteer-types',
  templateUrl: './add-volunteer-types.component.html',
  styleUrls: ['./add-volunteer-types.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddVolunteerTypesComponent implements OnInit {

  @Input() allowEdit = true;
  public isManager$: Observable<boolean>;
  public volunteerTypes$: Observable<IInstitutionVolunteerDto[]>;

  public closeVolunteerTypesPageMessage: EventEmitter<boolean> = new EventEmitter<boolean>();
  public volunteerTypesPageMessage$: Observable<IProgramPageMessageDto>;
  constructor(
    private routerService: RouterService,
    private enrollmentService: EnrollmentService,
    private modalService: ModalService,
    private profileService: ProfileService,
    private programSettingsService: ProgramSettingsService
  ) {

    this.volunteerTypes$ = this.routerService.enrollmentId$.pipe(
      switchMap(enrollmentId => {
        return this.enrollmentService.getEnrollment({
          institutionId: this.routerService.institutionId,
          memberId: this.routerService.memberId ? this.routerService.memberId : this.routerService.selectedMemberId,
          programId: this.routerService.programId,
          enrollmentId
        });
      }),
      filter(enrollment => enrollment != null),
      map(enrollment => enrollment.volunteerTypes)
    );


    this.isManager$ = this.profileService.activeUserInstitutionProfileIsManager$;

    this.volunteerTypesPageMessage$ = combineLatest([
      this.isManager$,
      this.programSettingsService.getPageMessageByType({ institutionId: this.routerService.institutionId, programId: this.routerService.programId, typeId: ePageMessage.Enrollment_VolunteerType }),
      this.closeVolunteerTypesPageMessage.pipe(startWith(false)),
      this.volunteerTypes$
    ]).pipe(
      map(([isManager, volunteerTypesPageMessage, closeVolunteerTypesPageMessage, selectedVolunteerTypes]) => {
        if (isManager || volunteerTypesPageMessage == null || closeVolunteerTypesPageMessage || (Array.isArray(selectedVolunteerTypes) && selectedVolunteerTypes.length > 0)) {
          return null;
        }
        return volunteerTypesPageMessage;
      })
    );
  }

  ngOnInit() {
  }


  public removeVolunteerType(volunteerType: IInstitutionVolunteerDto) {
    this.enrollmentService.removeVolunteerType({
      institutionId: this.routerService.institutionId,
      memberId: this.routerService.memberId ? this.routerService.memberId : this.routerService.selectedMemberId,
      programId: this.routerService.programId,
      enrollmentId: this.routerService.enrollmentId,
      volunteerType: volunteerType
    });
  }
  public addVolunteerTypes() {
    this.modalService.openModal(ModalAddEnrollmentVolunteerTypesComponent);
  }

  public closeHelp() {
    this.closeVolunteerTypesPageMessage.emit(true);
    this.addVolunteerTypes();
  }

}
