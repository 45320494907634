export interface IProgramCountyAreaSettingsUpdateDto {
    enrollment_CountyFamilyDiscount_On: boolean;
    enrollment_FamilyDiscount_CloverBud_On: boolean;
    enrollment_FamilyDiscount_AdultVolunteer_On: boolean;
    paymentMethod_Credits_On: boolean;
    paymentMethod_Electronic_On: boolean;
    payments_nonElectronicPaymentSubmissionDetails?: string;

    memberEditProjects: boolean;
    memberEditProjectsEndDay: number;
    memberEditProjectsEndMonth: number;
    memberEditUnits: boolean;
    memberEditUnitsEndDay: number;
    memberEditUnitsEndMonth: number;
    memberProjectEditEndDate: string;
    memberUnitEditEndDate: string;
}
