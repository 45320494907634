import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EMAIL_REGEX, eMemberRole, IMemberDto, PHONE_REGEX } from 'app/core/models';
import { MemberService, RouterService } from 'app/core/services';

@Component({
  selector: 'ng4h-modal-edit-member-emergency-contact',
  templateUrl: './modal-edit-member-emergency-contact.component.html',
  styleUrls: ['./modal-edit-member-emergency-contact.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalEditMemberEmergencyContactComponent implements OnInit {

  public eMemberRole = eMemberRole;
  public form: FormGroup = null;
  public member: IMemberDto;
  constructor(private dialogRef: MatDialogRef<any>,
    private memberService: MemberService,
    private routerService: RouterService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) private data) {
  }

  ngOnInit() {
    this.member = this.data.member;


    this.form = this.fb.group({
      jobTitle: [this.member.jobTitle],
      employer: [this.member.employer],
      workPhone: [this.member.workPhone],
      workExt: [this.member.workExt],
      emergencyContact: [this.member.emergencyContact, Validators.required],
      emergencyContactPhone: [this.member.emergencyContactPhone, [Validators.required, Validators.pattern(PHONE_REGEX)]],
      emergencyContactEmail: [this.member.emergencyContactEmail, [Validators.pattern(EMAIL_REGEX)]],
      emergencyContactRelationship: [this.member.emergencyContactRelationship, [Validators.required, Validators.maxLength(200)]],
      emergencyContact2: [this.member.emergencyContact2],
      emergencyContact2Phone: [this.member.emergencyContact2Phone, [Validators.pattern(PHONE_REGEX)]],
      emergencyContact2Email: [this.member.emergencyContact2Email, [Validators.pattern(EMAIL_REGEX)]],
      emergencyContact2Relationship: [this.member.emergencyContact2Relationship, [Validators.maxLength(200)]],
      guardian1FirstName: [this.member.guardian1FirstName],
      guardian1LastName: [this.member.guardian1LastName],
      guardian1Phone: [this.member.guardian1Phone, [Validators.pattern(PHONE_REGEX)]],
      guardian1WorkPhone: [this.member.guardian1WorkPhone, [Validators.pattern(PHONE_REGEX)]],
      guardian1WorkExt: [this.member.guardian1WorkExt],
      guardian2FirstName: [this.member.guardian2FirstName],
      guardian2LastName: [this.member.guardian2LastName],
      guardian2Phone: [this.member.guardian2Phone, [Validators.pattern(PHONE_REGEX)]],
      guardian2WorkPhone: [this.member.guardian2WorkPhone, [Validators.pattern(PHONE_REGEX)]],
      guardian2WorkExt: [this.member.guardian2WorkExt]
    });
  }

  public close() {
    this.dialogRef.close();
  }

  public save() {
    const memberUpdate: IMemberDto = {
      ...this.member,
      ...this.form.value
    };
    this.memberService.updateMember({ institutionId: this.routerService.institutionId, memberId: this.routerService.memberId, memberUpdate }).subscribe(() => {
      this.close();
    });
  }

}
