import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IManagerInstitutionProfile, IModalConfirm } from 'app/core/models';

import { ModalConfirmComponent } from '../modal-confirm/modal-confirm.component';

@Component({
  selector: 'ng4h-modal-manager-search',
  templateUrl: './modal-manager-search.component.html',
  styleUrls: ['./modal-manager-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalManagerSearchComponent implements OnInit {

  public selectedManager: IManagerInstitutionProfile;

  constructor(private dialogRef: MatDialogRef<ModalConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IModalConfirm) {

  }

  ngOnInit() {
  }

  public close() {
    this.dialogRef.close();
  }
  public select() {
    this.dialogRef.close(this.selectedManager);
  }

  public managerSelected(manager: IManagerInstitutionProfile | null) {
    this.selectedManager = manager;
  }

}
