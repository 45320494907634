import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { eEnrollmentRoleType, eMimeType, IFileControl } from 'app/core/models';
import { ModalService } from 'app/core/services';
import * as FileSaver from 'file-saver';
import { take } from 'rxjs';

@Component({
  selector: 'ng4h-file-control',
  templateUrl: './file-control.component.html',
  styleUrls: ['./file-control.component.scss']
})
export class FileControlComponent implements OnInit {

  public eMimeType = eMimeType;
  // public uploading = false;
  @Input() public fileControls: IFileControl[];
  @Input() enrollmentRole?: eEnrollmentRoleType;
  @Input() registrationTypeId?: string;
  @Input() readonly = false;

  @Output() uploadFile: EventEmitter<{ file: IFileControl, control: IFileControl }> = new EventEmitter<{ file: IFileControl, control: IFileControl }>();
  @Output() deleteFile: EventEmitter<IFileControl> = new EventEmitter<IFileControl>();

  // public mimeTypes: string[];

  constructor(private modalService: ModalService, private httpClient: HttpClient) { }

  ngOnInit() {
  }
  // ngOnChanges(changes: SimpleChanges): void {
  //   if (changes.fileControls) {
  //     if (Array.isArray(changes.fileControls.currentValue)) {
  //       this.mimeTypes = changes.fileControls.currentValue.map((fileControl: IFileControl) => {
  //         return fileControl.acceptedFileExtensions.map(extension => extension.mimeType).join(',');
  //       });
  //     } else {
  //       this.mimeTypes = [];
  //     }
  //     // this.uploading = false;
  //   }
  // }

  public onUpload(file: IFileControl, control: IFileControl): void {
    if (control.hasFile) {
      this.modalService.confirm({ header: 'Overwrite file', body: 'Are you sure you want to overwrite the existing file?' }).pipe(take(1)).subscribe(res => {
        if (res) {
          this.uploadFile.emit({ file, control });
          // this.uploading = true;
        }
      });
    } else {
      this.uploadFile.emit({ file, control });
      // this.uploading = true;
    }

  }

  public downloadTemplate(control: IFileControl) {
    this.httpClient.get(control.templateFile.downloadResourceUrl, { responseType: 'blob' }).subscribe(file => {
      FileSaver.saveAs(file, control.templateFile.fileName);
    });
  }
  public download(control: IFileControl) {

    this.httpClient.get(control.uploadedFile.downloadResourceUrl, { responseType: 'blob' }).subscribe(file => {
      FileSaver.saveAs(file, control.uploadedFile.fileName);
    });
  }
  public clear(control: IFileControl) {
    this.modalService.confirm({ header: 'Delete file', body: 'Are you sure you want to delete this file?' }).pipe(take(1)).subscribe(res => {
      if (res) {
        this.deleteFile.emit(control);
      }
    });
  }
}
