import { Params } from '@angular/router';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from 'environments/environment';
import { createSelector } from 'reselect';

import * as enrollment from './core/containers/member/enrollment/enrollment.reducer';
import * as member from './core/containers/member/member.reducer';
import * as memberAnimals from './core/containers/member/store/animals/member-animals.reducer';
import { UserActions } from './core/containers/user';
import * as profile from './core/containers/user/profile.reducer';
import * as user from './core/containers/user/user.reducer';
import {
  IInstitutionFamilyParams,
  IInstitutionFamilyProgramParams,
  IInstitutionManagerProgramEventParams,
  IInstitutionManagerProgramHierarchyParams,
  IInstitutionManagerProgramParams,
  IInstitutionMemberEventParams,
  IInstitutionMemberParams,
  IInstitutionMemberProgramEnrollmentParams,
  IInstitutionMemberProgramParams,
  IInstitutionProgramParams,
  IInstitutionSystemManagerProgramParams
} from './core/models/function-parameters';
import {
  IInstitutionMemberProgramEventRegistrationParams
} from './core/models/function-parameters/institution-member-program-event-registration';
import * as consents from './shared/consents/consents.reducer';
import * as countyAreas from './shared/county-areas/county-areas.reducer';
import * as creditCard from './shared/credit-card/credit-card.reducer';
import * as deliveryMode from './shared/delivery-mode/delivery-mode.reducer';
import * as districts from './shared/district/district.reducer';
import * as documentation from './shared/documentation/documentation.reducer';
import * as emailTemplates from './shared/email-template/email-template.reducer';
import * as eventCustomFiles from './shared/event-custom-files/event-custom-files.reducer';
import * as eventsManager from './shared/events-manager/events-manager.reducer';
import * as eventsMember from './shared/events-member/events-member.reducer';
import * as hierarchy from './shared/hierarchy/hierarchy.reducer';
import * as institution from './shared/institution/institution.reducer';
import * as healthForms from './shared/member-health-form/member-health-form.reducer';
import * as pageMessages from './shared/page-message/page-message.reducer';
import * as payments from './shared/payments/payments.reducer';
import * as programSettings from './shared/program-settings/program-settings.reducer';
import * as programYear from './shared/program-year/program-year.reducer';
import * as program from './shared/program/program.reducer';
import * as projects from './shared/projects/projects.reducer';
import * as school from './shared/school/school.reducer';
import * as memberScreenings from './shared/screenings/screenings.reducer';
import * as sidebar from './shared/sidebar/sidebar.reducer';
import * as systemManager from './shared/system-manager/system-managers.reducer';
import * as ticketing from './shared/ticketing/ticketing.reducer';
import * as units from './shared/units/units.reducer';
import { RouterStateUrl } from './shared/utils';

// import * as trainings from './shared/trainings/trainings.reducer';
export interface AppState {
  program: program.ProgramState;
  appSettings: programSettings.ProgramSettingsState;
  sidebar: sidebar.SidebarState;
  router: RouterReducerState<RouterStateUrl>;
  user: user.UserState;
  profile: profile.ProfileState;
  member: member.MemberState;
  enrollment: enrollment.EnrollmentState;
  eventsManager: eventsManager.EventsManagerState;
  eventsMember: eventsMember.EventsMemberState;
  units: units.UnitsState;
  projects: projects.ProjectsState;
  countyAreas: countyAreas.CountyAreasState;
  healthForms: healthForms.MemberHealthFormsState;
  consents: consents.ConsentsState;
  institution: institution.InstitutionState;
  hierarchy: hierarchy.HierarchyState;
  creditCard: creditCard.CreditCardState;
  programYear: programYear.ProgramYearState;
  payments: payments.PaymentsState;
  // trainings: trainings.TrainingsState;
  documentation: documentation.DocumentationState;
  school: school.SchoolState;
  emailTemplates: emailTemplates.EmailTemplateState;
  pageMessages: pageMessages.PageMessageState;
  systemManager: systemManager.SystemManagerState;
  districts: districts.DistrictState;
  eventCustomFiles: eventCustomFiles.EventCustomFilesState;
  deliveryMode: deliveryMode.DeliveryModeState;
  memberScreenings: memberScreenings.MemberScreeningsState;
  memberAnimals: memberAnimals.MemberAnimalsState;
  ticketing: ticketing.TicketingState;
}

export const reducers: ActionReducerMap<AppState> = {
  program: program.programReducer,
  appSettings: programSettings.programSettingsReducer,
  sidebar: sidebar.sidebarReducer,
  router: routerReducer,
  user: user.userReducer,
  profile: profile.profileReducer,
  member: member.memberReducer,
  enrollment: enrollment.enrollmentReducer,
  eventsManager: eventsManager.eventsManagerReducer,
  eventsMember: eventsMember.memberEventReducer,
  units: units.memberUnitsReducer,
  projects: projects.projectsReducer,
  countyAreas: countyAreas.countyAreasReducer,
  healthForms: healthForms.memberHealthFormReducer,
  consents: consents.consentsReducer,
  institution: institution.institutionReducer,
  hierarchy: hierarchy.hierarchyReducer,
  creditCard: creditCard.creditCardReducer,
  programYear: programYear.programYearReducer,
  payments: payments.paymentsReducer,
  // trainings: trainings.reducer,
  documentation: documentation.documentationReducer,
  school: school.schoolReducer,
  emailTemplates: emailTemplates.emailTemplateReducer,
  pageMessages: pageMessages.pageMessageReducer,
  systemManager: systemManager.systemManagerReducer,
  districts: districts.districtsReducer,
  eventCustomFiles: eventCustomFiles.eventCustomFileReducer,
  deliveryMode: deliveryMode.deliveryModeReducer,
  memberScreenings: memberScreenings.screeningsReducer,
  memberAnimals: memberAnimals.animalsReducer,
  ticketing: ticketing.ticketingReducer
};

export const APP_INITIAL_STATE = {
  router: {
    navigationId: -1,
    state: {
      params: {},
      queryParams: {},
      url: '/',
      breadcrumbs: []
    }
  }
};

// make sure you export for AoT
export function stateSetter(reducer: ActionReducer<any>): ActionReducer<any> {
  return function(state: any, action: any) {
    const NGRX_STATE = '___NGRX_STATE___';
    const newState = reducer(state, action);
    if (action.type !== '@ngrx/store/init') {
      return window[NGRX_STATE] = newState;
    } else {
      if (window[NGRX_STATE]) {
        return window[NGRX_STATE];
      }
      return newState;
    }
  };
}

export function logoutReset(reducer: ActionReducer<any>): ActionReducer<any> {
  return function(state: any, action: any) {
    if (action.type === UserActions.SignOutSuccessAction.type) {
      return APP_INITIAL_STATE;
    } else {
      return reducer(state, action);
    }
  };
}

export const metaReducers: MetaReducer<AppState>[] = environment.production ? [] : [stateSetter];
export const getProgramState = (appState: AppState) => appState.program;
export const getAppSettingsState = (appState: AppState) => appState.appSettings;
export const getUnitsState = (appState: AppState) => appState.units;
export const getProjectsState = (appState: AppState) => appState.projects;
export const getCountyAreasState = (appState: AppState) => appState.countyAreas;
export const getSidebarState = (appState: AppState) => appState.sidebar;
export const getUsersState = (appState: AppState) => appState.user;
export const getProfilesState = (appState: AppState) => appState.profile;
export const getRouterState = (appState: AppState) => appState.router.state;
export const getMemberState = (appState: AppState) => appState.member;
export const getDistrictsState = (appState: AppState) => appState.districts;
export const getEventCustomFileState = (appState: AppState) => appState.eventCustomFiles;
export const getEnrollmentState = (appState: AppState) => appState.enrollment;
export const getEventsManagerState = (appState: AppState) => appState.eventsManager;
export const getEventsMemberState = (appState: AppState) => appState.eventsMember;
export const getHealthFormsState = (appState: AppState) => appState.healthForms;
export const getConsentsState = (appState: AppState) => appState.consents;
export const getInstitutionState = (appState: AppState) => appState.institution;
export const getHierarchyState = (appState: AppState) => appState.hierarchy;
export const getCreditCardState = (appState: AppState) => appState.creditCard;
export const getProgramYearState = (appState: AppState) => appState.programYear;
export const getPaymentsState = (appState: AppState) => appState.payments;
// export const getTrainingsState = (appState: AppState) => appState.trainings;
export const getDocumentationState = (appState: AppState) => appState.documentation;
export const getSchoolState = (appState: AppState) => appState.school;
export const getEmailTemplateState = (appState: AppState) => appState.emailTemplates;
export const getPageMessageState = (appState: AppState) => appState.pageMessages;
export const getSystemManagerState = (appState: AppState) => appState.systemManager;
export const getDeliveryModeState = (appState: AppState) => appState.deliveryMode;
export const getMemberScreeningsState = (appState: AppState) => appState.memberScreenings;
export const getMemberAnimalsState = (appState: AppState) => appState.memberAnimals;

export const getPrograms = ({ institutionId }: { institutionId: string }) => createSelector(
  createSelector(getProgramState, program.institutionPrograms),
  institutionPrograms => institutionPrograms?.[institutionId]
);

export const getCountyAreaPrograms = ({
  institutionId,
  countyAreaId
}: { institutionId: string, countyAreaId: string }) => createSelector(
  createSelector(getProgramState, program.countyAreaPrograms),
  institutionPrograms => institutionPrograms?.[institutionId]?.[countyAreaId]
);

export const getProgram = ({ institutionId, programId }: IInstitutionProgramParams) => createSelector(
  createSelector(getProgramState, program.institutionProgram),
  institutionProgram => institutionProgram?.[institutionId]?.[programId]
);

export const getAppSettings = ({ institutionId, programId }: IInstitutionProgramParams) => createSelector(
  createSelector(getAppSettingsState, programSettings.settings),
  settings => settings?.[institutionId]?.[programId]
);

export const getSidebarOpen = createSelector(getSidebarState, sidebar.sidebarOpen);
export const getManagerSidebarOpen = createSelector(getSidebarState, sidebar.managerSidebarOpen);
export const getSidebarMode = createSelector(getSidebarState, sidebar.sidebarMode);

export const getCurrentUrl = createSelector(getRouterState, (state: RouterStateUrl) => state.url);
export const getRouterBreadcrumbs = createSelector(getRouterState, (state: RouterStateUrl) => state.breadcrumbs);

export const getRouterParams = createSelector(getRouterState, (state: RouterStateUrl) => state?.params);

export const getSuperuserId = createSelector(getRouterParams, (params: Params) => params?.superuserId);
export const getLoggedInInstitutionProfileId = createSelector(getRouterParams, (params: Params) => params?.loggedInInstitutionProfileId);
export const getRouterManagerId = createSelector(getRouterParams, (params: Params) => params?.managerId);
export const getRouterSystemManagerId = createSelector(getRouterParams, (params: Params) => params?.systemManagerId);
export const getRouterMemberId = createSelector(getRouterParams, (params: Params) => params?.memberId);
export const getRouterSelectedMemberId = createSelector(getRouterParams, (params: Params) => params?.selectedMemberId);
export const getRouterFamilyId = createSelector(getRouterParams, (params: Params) => params?.familyId);
export const getRouterReportConsumerId = createSelector(getRouterParams, (params: Params) => params?.reportConsumerId);
export const getRouterSelectedFamilyId = createSelector(getRouterParams, (params: Params) => params?.selectedFamilyId);
export const getRouterHierarchyNodeId = createSelector(getRouterParams, (params: Params) => params?.hierarchyNodeId);
export const getRouterProgramId = createSelector(getRouterParams, (params: Params) => params?.programId);
export const getRouterProgramYearId = createSelector(getRouterParams, (params: Params) => params?.programYearId);
export const getRouterInstitutionId = createSelector(getRouterParams, (params: Params) => params?.institutionId);
export const getRouterManagerHierarchyId = createSelector(getRouterParams, (params: Params) => params?.managerHierarchyId);
export const getRouterEnrollmentId = createSelector(getRouterParams, (params: Params) => params?.enrollmentId);
export const getRouterEventId = createSelector(getRouterParams, (params: Params) => params?.eventId);
export const getRouterEventRegistrationId = createSelector(getRouterParams, (params: Params) => params?.eventRegistrationId);
export const getRouterRegistrationTypeId = createSelector(getRouterParams, (params: Params) => params?.registrationTypeId);
export const getRouterPageMessageId = createSelector(getRouterParams, (params: Params) => params?.pageMessageId);
export const getRouterUnitId = createSelector(getRouterParams, (params: Params) => params?.unitId);
export const getRouterEmailTemplateId = createSelector(getRouterParams, (params: Params) => params?.emailTemplateId);
export const getRouterProjectId = createSelector(getRouterParams, (params: Params) => params?.projectId);
export const getRouterConsentTypeId = createSelector(getRouterParams, (params: Params) => params?.consentTypeId);
export const getRouterConsentVersionId = createSelector(getRouterParams, (params: Params) => params?.consentVersionId);
export const getRouterTrainingId = createSelector(getRouterParams, (params: Params) => params?.trainingId);
export const getRouterProfileTrainingId = createSelector(getRouterParams, (params: Params) => params?.profileTrainingId);
export const getRouterFIleId = createSelector(getRouterParams, (params: Params) => params?.fileId);
export const getRouterProfileScreeningId = createSelector(getRouterParams, (params: Params) => params?.profileScreeningId);
export const getRouterBatchId = createSelector(getRouterParams, (params: Params) => params?.batchId);
export const getRouterCustomReportId = createSelector(getRouterParams, (params: Params) => params?.customReportId);
export const getTrainingLessonId = createSelector(getRouterParams, (params: Params) => params?.trainingLessonId);
export const getTrainingCourseId = createSelector(getRouterParams, (params: Params) => params?.trainingCourseId);
export const getRouterBroadcastEmailId = createSelector(getRouterParams, (params: Params) => params?.broadcastEmailId);
export const getRouterDiscountFundId = createSelector(getRouterParams, (params: Params) => params?.discountFundId);
export const getRouterDiscountFundDonorId = createSelector(getRouterParams, (params: Params) => params?.discountFundDonorId);
export const getRouterDonationId = createSelector(getRouterParams, (params: Params) => params?.donationId);
export const getRouterEventEmailTemplateType = createSelector(getRouterParams, (params: Params) => params?.eventEmailTemplateType);
export const getRouterVolunteerTypeId = createSelector(getRouterParams, (params: Params) => params?.volunteerTypeId);
export const getRouterDeliveryModeId = createSelector(getRouterParams, (params: Params) => params?.deliveryModeId);
export const getRouterInstitutionDiscountId = createSelector(getRouterParams, (params: Params) => params?.institutionDiscountId);
export const getRouterBankAccountId = createSelector(getRouterParams, (params: Params) => params?.bankAccountId);
export const getGroupEnrollmentId = createSelector(getRouterParams, (params: Params) => params?.groupEnrollmentId);
export const getEventSessionId = createSelector(getRouterParams, (params: Params) => params?.eventSessionId);
export const getUserAccountId = createSelector(getRouterParams, (params: Params) => params?.userAccountId);
export const getAwardId = createSelector(getRouterParams, (params: Params) => params?.awardId);
export const getActivityId = createSelector(getRouterParams, (params: Params) => params?.activityId);
export const getAnimalTypeId = createSelector(getRouterParams, (params: Params) => params?.animalTypeId);
export const getScreeningType = createSelector(getRouterParams, (params: Params) => params?.screeningType);
export const getGroupId = createSelector(getRouterParams, (params: Params) => params?.groupId);
export const getCountyCreditBillId = createSelector(getRouterParams, (params: Params) => params?.countyCreditBillId);
export const getEnrollmentAnimalId = createSelector(getRouterParams, (params: Params) => params?.enrollmentAnimalId);
export const getFileControlId = createSelector(getRouterParams, (params: Params) => params?.fileControlId);
export const getStandardReportId = createSelector(getRouterParams, (params: Params) => params?.standardReportId);
export const getFundsTransferId = createSelector(getRouterParams, (params: Params) => params?.fundsTransferId);
export const getFairEntryIntegrationId = createSelector(getRouterParams, (params: Params) => params?.fairEntryIntegrationId);
export const getCountyAreaId = createSelector(getRouterParams, (params: Params) => params?.countyAreaId);
export const getSchoolId = createSelector(getRouterParams, (params: Params) => params?.schoolId);
export const getSchoolDistrictId = createSelector(getRouterParams, (params: Params) => params?.schoolDistrictId);
export const getTicketingSystemId = createSelector(getRouterParams, (params: Params) => params?.ticketingSystemId);

const getRouterQueryParams = createSelector(getRouterState, (state: RouterStateUrl) => state?.queryParams);

export const getRouterRole = createSelector(getRouterQueryParams, (queryParams: Params) => queryParams?.role);
export const getRouterParticipation = createSelector(getRouterQueryParams, (queryParams: Params) => queryParams?.participation);
export const getLastRouteParams = createSelector(getRouterQueryParams, (queryParams: Params) => queryParams?.lastRouteParams);
export const getRouterRecoveryAccountId = createSelector(getRouterQueryParams, (queryParams: Params) => queryParams?.accountId);
export const getRouterRecoveryAccountCode = createSelector(getRouterQueryParams, (queryParams: Params) => queryParams?.code);
export const getRouterWizardStep = createSelector(getRouterQueryParams, (queryParams: Params) => queryParams?.wizardStep);
export const getRouterLoginModalTitle = createSelector(getRouterQueryParams, (queryParams: Params) => queryParams?.loginModalTitle);
export const getRouterLoginModalBody = createSelector(getRouterQueryParams, (queryParams: Params) => queryParams?.loginModalBody);
export const getSwitchInstitution = createSelector(getRouterQueryParams, (queryParams: Params) => queryParams?.switchInstitution);
export const getAccountId = createSelector(getRouterQueryParams, (queryParams: Params) => queryParams?.accountId);
export const getCode = createSelector(getRouterQueryParams, (queryParams: Params) => queryParams?.code);
export const getUserAccount = createSelector(getUsersState, user.userAccount);
export const getLoginCredentials = createSelector(getUsersState, user.loginCredentials);
export const getTwoFactorTempCredentials = createSelector(getUsersState, user.twoFactorTempCredentials);
export const getProfileCredentials = createSelector(getProfilesState, profile.profileCredentials);
export const getActiveProfileHistory = createSelector(getProfilesState, profile.routeHistory);

export const getUnitLeaderPIN = ({
  institutionProfileId,
  unitId
}: { institutionProfileId: string, unitId: string }) => createSelector(
  createSelector(getProfilesState, profile.clubLeaderPins),
  pins => pins?.[institutionProfileId]?.[unitId]
);

export const getInstitutionProfileAuthTokenCache = (institutionProfileId: string) => createSelector(
  createSelector(getProfilesState, profile.institutionProfileAuthTokenCache),
  institutionProfileAuthTokenCaches => institutionProfileAuthTokenCaches?.[institutionProfileId]
);

export const getMember = ({ institutionId, memberId }: IInstitutionMemberParams) => createSelector(
  createSelector(getMemberState, member.members),
  members => members?.[institutionId]?.[memberId]
);

export const getMemberV1Files = ({
  institutionId,
  memberId,
  programId
}: IInstitutionMemberProgramParams) => createSelector(
  createSelector(getMemberState, member.v1Files),
  files => files?.[institutionId]?.[memberId]?.[programId]
);

export const getFamiliesByCounty = ({
  institutionId,
  countyAreaId
}: { institutionId: string, countyAreaId: string }) => createSelector(
  createSelector(getMemberState, member.familiesByCounty),
  families => families?.[institutionId]?.[countyAreaId]
);

export const getMemberProfileNotes = ({
  institutionId,
  memberId,
  programId
}: IInstitutionMemberProgramParams) => createSelector(
  createSelector(getMemberState, member.profileNotes),
  profileNotes => profileNotes?.[institutionId]?.[memberId]?.[programId]
);

export const getMemberProgramYear = ({ institutionId, memberId }: IInstitutionMemberParams) => createSelector(
  createSelector(getMemberState, member.memberProgramYear),
  members => members?.[institutionId]?.[memberId]
);

export const getMemberDashboard = ({ institutionId, memberId }: IInstitutionMemberParams) => createSelector(
  createSelector(getMemberState, member.membersDashboard),
  getMemberDashboards => getMemberDashboards?.[institutionId]?.[memberId]
);

export const getEnrollmentDynamicFormInputs = ({
  institutionId,
  memberId,
  programId,
  enrollmentId
}: IInstitutionMemberProgramEnrollmentParams) => createSelector(
  createSelector(getEnrollmentState, enrollment.dynamicQuestionFormInputs),
  dynamicQuestionFormInputs => dynamicQuestionFormInputs?.[institutionId]?.[memberId]?.[programId]?.[enrollmentId]
);

export const getEnrollment = ({
  institutionId,
  memberId,
  programId,
  enrollmentId
}: IInstitutionMemberProgramEnrollmentParams) => createSelector(
  createSelector(getEnrollmentState, enrollment.enrollment),
  enrollments => enrollments?.[institutionId]?.[memberId]?.[programId]?.[enrollmentId]
);

export const getEnrollmentCustomFiles = ({
  institutionId,
  memberId,
  programId,
  enrollmentId
}: IInstitutionMemberProgramEnrollmentParams) => createSelector(
  createSelector(getEnrollmentState, enrollment.enrollmentCustomFiles),
  enrollmentCustomFiles => enrollmentCustomFiles?.[institutionId]?.[memberId]?.[programId]?.[enrollmentId]
);

export const getEnrollmentVolunteerTypes = ({
  institutionId,
  memberId,
  programId,
  enrollmentId
}: IInstitutionMemberProgramEnrollmentParams) => createSelector(
  createSelector(getEnrollmentState, enrollment.enrollmentVolunteerTypes),
  enrollmentVolunteerTypes => enrollmentVolunteerTypes?.[institutionId]?.[memberId]?.[programId]?.[enrollmentId]
);

export const getManagerEvent = ({
  institutionId,
  managerId,
  programId,
  eventId
}: IInstitutionManagerProgramEventParams) => createSelector(
  createSelector(getEventsManagerState, eventsManager.event),
  event => event?.[institutionId]?.[managerId]?.[programId]?.[eventId]
);

export const getManagerGroupEnrollments = ({
  institutionId,
  managerId,
  programId,
  eventId
}: IInstitutionManagerProgramEventParams) => createSelector(
  createSelector(getEventsManagerState, eventsManager.groupEnrollments),
  groupEnrollments => groupEnrollments?.[institutionId]?.[managerId]?.[programId]?.[eventId]
);

export const getManagerEvents = ({
  institutionId,
  managerId,
  programId,
  hierarchyNodeId,
  programYearId
}: IInstitutionManagerProgramHierarchyParams & { programYearId: string }) => createSelector(
  createSelector(getEventsManagerState, eventsManager.events),
  events => events?.[institutionId]?.[managerId]?.[programId]?.[hierarchyNodeId]?.[programYearId]
);

export const getManagerEventConsents = ({
  institutionId,
  managerId,
  programId,
  eventId,
  eventRegistrationTypeId
}: IInstitutionManagerProgramEventParams & { eventRegistrationTypeId: string }) => createSelector(
  createSelector(getEventsManagerState, eventsManager.eventConsents),
  eventConsents => eventConsents?.[institutionId]?.[managerId]?.[programId]?.[eventId]?.[eventRegistrationTypeId]
);

export const getManagerSubmittedEventRegistrations = ({
  institutionId,
  managerId,
  programId,
  hierarchyNodeId,
  eventId
}: IInstitutionManagerProgramHierarchyParams & { eventId: string }) => createSelector(
  createSelector(getEventsManagerState, eventsManager.submittedEventRegistrations),
  submittedEventRegistrations => submittedEventRegistrations?.[institutionId]?.[managerId]?.[programId]?.[hierarchyNodeId]
);

export const getManagerEventRegistrations = ({
  institutionId,
  managerId,
  programId,
  eventId
}: IInstitutionManagerProgramEventParams) => createSelector(
  createSelector(getEventsManagerState, eventsManager.eventRegistrations),
  eventRegistrations => eventRegistrations?.[institutionId]?.[managerId]?.[programId]?.[eventId]
);

export const getRegistrationSearchSearching = createSelector(getEventsManagerState, eventsManager.registrationSearchSearching);

export const getManagerCrossEventRegistrations = ({
  institutionId,
  managerId,
  programId,
  hierarchyNodeId
}: IInstitutionManagerProgramHierarchyParams) => createSelector(
  createSelector(getEventsManagerState, eventsManager.crossEventRegistrations),
  eventRegistrations => eventRegistrations?.[institutionId]?.[managerId]?.[programId]?.[hierarchyNodeId]
);

export const getManagerEventOutstandingCredits = ({
  institutionId,
  managerId,
  programId,
  eventId
}: IInstitutionManagerProgramEventParams) => createSelector(
  createSelector(getEventsManagerState, eventsManager.outstandingCredits),
  eventRegistrations => eventRegistrations?.[institutionId]?.[managerId]?.[programId]?.[eventId]
);

export const getManagerCountyOutstandingCredits = ({
  institutionId,
  managerId,
  programId,
  countyAreaId
}: IInstitutionManagerProgramParams & { countyAreaId: string }) => createSelector(
  createSelector(getEventsManagerState, eventsManager.countyCredits),
  eventRegistrations => eventRegistrations?.[institutionId]?.[managerId]?.[programId]?.[countyAreaId]
);

export const getManagerEventCoupons = ({
  institutionId,
  managerId,
  programId,
  eventId
}: IInstitutionManagerProgramEventParams & { eventId: string }) => createSelector(
  createSelector(getEventsManagerState, eventsManager.eventCoupons),
  eventRegistrations => eventRegistrations?.[institutionId]?.[managerId]?.[programId]?.[eventId]
);

export const getManagerEventCoupon = ({
  institutionId,
  managerId,
  programId,
  eventId,
  institutionDiscountId
}: IInstitutionManagerProgramParams & { eventId: string, institutionDiscountId: string }) => createSelector(
  createSelector(getEventsManagerState, eventsManager.eventCoupon),
  eventRegistrations => eventRegistrations?.[institutionId]?.[managerId]?.[programId]?.[eventId]?.[institutionDiscountId]
);

export const getMemberEvent = ({ institutionId, memberId, eventId }: IInstitutionMemberEventParams) => createSelector(
  createSelector(getEventsMemberState, eventsMember.event),
  events => events?.[institutionId]?.[memberId]?.[eventId]
);

export const getMemberEvents = ({ institutionId, memberId }: IInstitutionMemberParams) => createSelector(
  createSelector(getEventsMemberState, eventsMember.events),
  events => events?.[institutionId]?.[memberId]
);

export const getEventRegistrations = ({
  institutionId,
  memberId,
  programId,
  eventRegistrationId
}: IInstitutionMemberProgramEventRegistrationParams) => createSelector(
  createSelector(getEventsMemberState, eventsMember.eventRegistrations),
  eventRegistrations => eventRegistrations?.[institutionId]?.[memberId]?.[programId]?.[eventRegistrationId]
);

export const getEventsMemberCustomFiles = ({
  institutionId,
  memberId,
  programId,
  eventRegistrationId
}: IInstitutionMemberProgramEventRegistrationParams) => createSelector(
  createSelector(getEventsMemberState, eventsMember.customFiles),
  files => files?.[institutionId]?.[memberId]?.[programId]?.[eventRegistrationId]
);

export const getEventRegistrationAnimals = ({
  institutionId,
  memberId,
  programId,
  eventRegistrationId
}: IInstitutionMemberProgramEventRegistrationParams) => createSelector(
  createSelector(getEventsMemberState, eventsMember.registrationAnimals),
  files => files?.[institutionId]?.[memberId]?.[programId]?.[eventRegistrationId]
);

// export const getPayableInvoice = ({ institutionId, memberId, programId, eventRegistrationId }: IInstitutionMemberProgramEventRegistrationParams) => createSelector(
//   createSelector(getEventsMemberState, eventsMember.payableInvoice),
//   payableInvoices => payableInvoices?.[institutionId]?.[memberId]?.[programId]?.[eventRegistrationId]
// );
export const getDynamicQuestionFormInputs = ({
  institutionId,
  memberId,
  programId,
  eventRegistrationId
}: IInstitutionMemberProgramEventRegistrationParams) => createSelector(
  createSelector(getEventsMemberState, eventsMember.dynamicQuestionFormInputs),
  dynamicFormInputs => dynamicFormInputs?.[institutionId]?.[memberId]?.[programId]?.[eventRegistrationId]
);

export const getUnits = ({
  institutionId,
  memberId,
  programId,
  enrollmentId,
  countyAreaId
}: IInstitutionMemberProgramEnrollmentParams & { countyAreaId: string }) => createSelector(
  createSelector(getUnitsState, units.clubs),
  clubs => clubs?.[institutionId]?.[memberId]?.[programId]?.[enrollmentId]?.[countyAreaId]
);

export const getUnitMembers = ({
  institutionId,
  memberId,
  programId,
  unitId
}: IInstitutionMemberProgramParams & { unitId: string }) => createSelector(
  createSelector(getUnitsState, units.leaderClubMembers),
  clubMembers => clubMembers?.[institutionId]?.[memberId]?.[programId]?.[unitId]
);

export const getProjects = ({
  institutionId,
  memberId,
  programId,
  enrollmentId
}: IInstitutionMemberProgramEnrollmentParams) => createSelector(
  createSelector(getProjectsState, projects.projects),
  p => p?.[institutionId]?.[memberId]?.[programId]?.[enrollmentId]
);
export const getProjectsByUnit = ({
  institutionId,
  memberId,
  programId,
  enrollmentId,
  unitId
}: IInstitutionMemberProgramEnrollmentParams & { unitId: string }) => createSelector(
  createSelector(getProjectsState, projects.projectsByClub),
  projectsByUnit => projectsByUnit?.[institutionId]?.[memberId]?.[programId]?.[enrollmentId]?.[unitId]
);

export const getCountyAreas = ({
  institutionId,
  programId
}: { institutionId: string, programId: string }) => createSelector(
  createSelector(getCountyAreasState, countyAreas.countyAreas),
  ca => ca?.[institutionId]?.[programId]
);

export const getHealthForms = ({
  institutionId,
  memberId,
  programId,
  healthFormType
}: IInstitutionMemberProgramParams & { healthFormType: string }) => createSelector(
  createSelector(getHealthFormsState, healthForms.healthForms),
  hf => hf?.[institutionId]?.[memberId]?.[programId]?.[healthFormType]
);

export const getDistricts = ({ institutionId }: { institutionId: string }) => createSelector(
  createSelector(getDistrictsState, districts.districts),
  d => d?.[institutionId]
);

export const getEventCustomFiles = ({
  institutionId,
  managerId,
  programId,
  eventId
}: IInstitutionManagerProgramEventParams) => createSelector(
  createSelector(getEventCustomFileState, eventCustomFiles.customFiles),
  files => files?.[institutionId]?.[managerId]?.[programId]?.[eventId]
);

export const getInstitutions = createSelector(getInstitutionState, institution.institutions);
export const getCounties = ({ institutionId }: { institutionId: string }) => createSelector(
  createSelector(getInstitutionState, institution.counties),
  counties => counties?.[institutionId]
);

export const getAllCounties = ({ institutionId }: { institutionId: string }) => createSelector(
  createSelector(getInstitutionState, institution.allCounties),
  allCounties => allCounties?.[institutionId]
);

export const getHierarchy = ({ institutionId }: { institutionId: string }) => createSelector(
  createSelector(getHierarchyState, hierarchy.hierarchy),
  h => h?.[institutionId]
);

// export const getSelectedUnit = createSelector(getHierarchyState, hierarchy.selectedUnit);
export const getSelectedCountyArea = createSelector(getHierarchyState, hierarchy.selectedCountyArea);
export const getSelectedDistrictArea = createSelector(getHierarchyState, hierarchy.selectedDistrictArea);
export const getSelectedRegionArea = createSelector(getHierarchyState, hierarchy.selectedRegionArea);
export const getSelectedInstitutionArea = createSelector(getHierarchyState, hierarchy.selectedInstitutionArea);

export const getManagerHierarchyNode = ({
  institutionId,
  programId,
  managerId
}: { institutionId: string, programId: string, managerId: string, }) => createSelector(
  createSelector(getHierarchyState, hierarchy.managerHierarchyNode),
  managerHierarchy => managerHierarchy?.[institutionId]?.[programId]?.[managerId]
);

// export const getGatewayConfiguration = ({ institutionId, programId }: IInstitutionProgramParams) => createSelector(
//   createSelector(getCreditCardState, creditCard.creditCardGatewayConfiguration),
//   gatewayConfig => gatewayConfig?.[institutionId]?.[programId]
// );
export const getAuthorizeToken = ({
  institutionId,
  familyId,
  programId
}: IInstitutionFamilyProgramParams) => createSelector(
  createSelector(getCreditCardState, creditCard.authorizeToken),
  authorizeToken => authorizeToken?.[institutionId]?.[familyId]?.[programId]
);

export const getUsersProgramYear = ({
  institutionId,
  programId,
  institutionProfileId
}: IInstitutionProgramParams & { institutionProfileId: string }) => createSelector(
  createSelector(getProgramYearState, programYear.usersProgramYear),
  usersProgramYear => usersProgramYear?.[institutionId]?.[programId]?.[institutionProfileId]
);

export const getCurrentProgramYear = ({ institutionId, programId }: IInstitutionProgramParams) => createSelector(
  createSelector(getProgramYearState, programYear.currentProgramYear),
  currentProgramYear => currentProgramYear?.[institutionId]?.[programId]
);

export const getAvailableProgramYears = ({ institutionId, programId }: IInstitutionProgramParams) => createSelector(
  createSelector(getProgramYearState, programYear.availableProgramYears),
  availableProgramYears => availableProgramYears?.[institutionId]?.[programId]
);

export const getPayments = ({ institutionId, familyId }: IInstitutionFamilyParams) => createSelector(
  createSelector(getPaymentsState, payments.payments),
  p => p?.[institutionId]?.[familyId]
);

export const getPaymentsByProgram = ({
  institutionId,
  familyId,
  programId
}: IInstitutionFamilyProgramParams) => createSelector(
  createSelector(getPaymentsState, payments.paymentsByProgram),
  p => p?.[institutionId]?.[familyId]?.[programId]
);

// export const getTraining = createSelector(getTrainingsState, trainings.training);
// export const getTrainings = createSelector(getTrainingsState, trainings.trainings);
// export const getTrainingCourse = createSelector(getTrainingsState, trainings.trainingCourse);
// export const getTrainingLesson = createSelector(getTrainingsState, trainings.trainingLesson);

export const getDocumentation = createSelector(getDocumentationState, documentation.documentation);

export const getSchoolDistricts = ({
  institutionId,
  countyId
}: { institutionId: string, countyId: string }) => createSelector(
  createSelector(getSchoolState, school.schoolDistricts),
  schools => schools?.[institutionId]?.[countyId]
);

export const getSchoolsByDistrict = ({
  institutionId,
  schoolDistrictId
}: { institutionId: string, schoolDistrictId: string }) => createSelector(
  createSelector(getSchoolState, school.schoolsByDistrict),
  schools => schools?.[institutionId]?.[schoolDistrictId]
);

export const getProgramEmailTemplates = ({
  institutionId,
  systemManagerId,
  programId
}: IInstitutionSystemManagerProgramParams) => createSelector(
  createSelector(getEmailTemplateState, emailTemplates.programEmailTemplates),
  templates => templates?.[institutionId]?.[systemManagerId]?.[programId]
);

export const getEventEmailTemplates = ({
  institutionId,
  managerId,
  programId,
  eventId
}: IInstitutionManagerProgramEventParams) => createSelector(
  createSelector(getEmailTemplateState, emailTemplates.eventEmailTemplates),
  templates => templates?.[institutionId]?.[managerId]?.[programId]?.[eventId]
);

export const getEmailWrapper = createSelector(getEmailTemplateState, emailTemplates.wrapper);
export const getPageMessages = ({
  institutionId,
  systemManagerId,
  programId
}: IInstitutionSystemManagerProgramParams) => createSelector(
  createSelector(getPageMessageState, pageMessages.messages),
  m => m?.[institutionId]?.[systemManagerId]?.[programId]
);

export const getSystemManager = ({ superuserId, institutionId, systemManagerId }) => createSelector(
  createSelector(getSystemManagerState, systemManager.systemManager),
  sm => sm?.[superuserId]?.[institutionId]?.[systemManagerId]
);

export const getSystemManagers = ({ institutionId }) => createSelector(
  createSelector(getSystemManagerState, systemManager.systemManagers),
  systemManagers => systemManagers?.[institutionId]
);

export const getSystemManagerPermissionTypes = ({}) => createSelector(
  createSelector(getSystemManagerState, systemManager.systemManagerPermissionTypes),
  permissionTypes => permissionTypes
);

export const getSystemManagerRollovers = ({
  institutionId,
  systemManagerId,
  programId
}: IInstitutionSystemManagerProgramParams) => createSelector(
  createSelector(getSystemManagerState, systemManager.systemManagerRollover),
  rollover => rollover?.[institutionId]?.[systemManagerId]?.[programId]
);

export const getConsents = ({ institutionId, programId }: IInstitutionProgramParams) => createSelector(
  createSelector(getConsentsState, consents.consents),
  c => c?.[institutionId]?.[programId]
);

export const getDeliveryModes = ({ institutionId, programId }: IInstitutionProgramParams) => createSelector(
  createSelector(getDeliveryModeState, deliveryMode.deliveryModes),
  deliveryModes => deliveryModes?.[institutionId]?.[programId]
);

export const getGroupEnrollmentDeliveryModes = ({
  institutionId,
  programId
}: IInstitutionProgramParams) => createSelector(
  createSelector(getDeliveryModeState, deliveryMode.groupEnrollmentDeliveryModes),
  deliveryModes => deliveryModes?.[institutionId]?.[programId]
);

export const getMemberScreenings = ({
  institutionId,
  memberId,
  profileScreeningId
}: IInstitutionMemberParams & { profileScreeningId: string }) => createSelector(
  createSelector(getMemberScreeningsState, memberScreenings.screenings),
  deliveryModes => deliveryModes?.[institutionId]?.[memberId]?.[profileScreeningId]
);

// export const getMemberAnimalsState = createSelector(getMemberFeatureStore, (state: MemberFeatureStore) => state.memberAnimals);

export const getAnimalTypes = ({
  institutionId,
  memberId,
  programId
}: IInstitutionMemberProgramParams) => createSelector(
  createSelector(getMemberAnimalsState, memberAnimals.animalTypes),
  groups => groups?.[institutionId]?.[memberId]?.[programId]
);

export const getAnimalCustomFiles = ({
  institutionId,
  memberId,
  programId,
  enrollmentId,
  enrollmentAnimalId
}: IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string }) => createSelector(
  createSelector(getMemberAnimalsState, memberAnimals.animalFiles),
  files => files?.[institutionId]?.[memberId]?.[programId]?.[enrollmentId]?.[enrollmentAnimalId]
);

export const getAnimalForm = ({
  institutionId,
  memberId,
  programId,
  animalTypeId
}: IInstitutionMemberProgramParams & { animalTypeId: string }) => createSelector(
  createSelector(getMemberAnimalsState, memberAnimals.animalForm),
  form => form?.[institutionId]?.[memberId]?.[programId]?.[animalTypeId]
);

export const getEnrollmentAnimals = ({
  institutionId,
  memberId,
  programId,
  enrollmentId
}: IInstitutionMemberProgramEnrollmentParams) => createSelector(
  createSelector(getMemberAnimalsState, memberAnimals.enrollmentAnimals),
  animals => animals?.[institutionId]?.[memberId]?.[programId]?.[enrollmentId]
);

export const getAvailableFamilyAnimals = ({
  institutionId,
  memberId,
  programId
}: IInstitutionMemberProgramParams) => createSelector(
  createSelector(getMemberAnimalsState, memberAnimals.availableFamilyAnimals),
  animals => animals?.[institutionId]?.[memberId]?.[programId]
);

export const getEnrollmentAnimal = ({
  institutionId,
  memberId,
  programId,
  enrollmentAnimalId
}: IInstitutionMemberProgramParams & { enrollmentAnimalId: string }) => createSelector(
  createSelector(getMemberAnimalsState, memberAnimals.enrollmentAnimal),
  animals => animals?.[institutionId]?.[memberId]?.[programId]?.[enrollmentAnimalId]
);

const getTicketingState = (appState: AppState) => appState.ticketing;
