import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
    eScreeningType,
    IInstitutionSystemManagerProgramParams,
    ISuperuserInstitutionProgramParams,
    IVolunteerScreeningDto,
    IVolunteerScreeningUpdateDto,
    IVolunteerScreeningUpdateFormDto,
} from 'app/core/models';


export const SystemManagerScreeningLoadAction = createAction(
    '[system-manager-screenings] load screening',
    props<IInstitutionSystemManagerProgramParams & { screeningType: eScreeningType }>()
);

export const SystemManagerScreeningLoadSuccessAction = createAction(
    '[system-manager-screenings] load screening success',
    props<IInstitutionSystemManagerProgramParams & { screeningType: eScreeningType, screening: IVolunteerScreeningDto }>()
);

export const SystemManagerScreeningLoadErrorAction = createAction(
    '[system-manager-screenings] load screening error',
    props<{ error: HttpErrorResponse }>()
);

export const SystemManagerScreeningsUpdateAction = createAction(
    '[system-manager-screenings] Update screening',
    props<ISuperuserInstitutionProgramParams & { screeningType: eScreeningType, screeningUpdate: IVolunteerScreeningUpdateDto }>()
);

export const SystemManagerScreeningsUpdateSuccessAction = createAction(
    '[system-manager-screenings] Update screening success',
    props<ISuperuserInstitutionProgramParams & { screeningType: eScreeningType, screeningUpdate: IVolunteerScreeningUpdateDto }>()
);

export const SystemManagerScreeningsUpdateErrorAction = createAction(
    '[system-manager-screenings] Update screening error',
    props<{ error: HttpErrorResponse }>()
);


export const SystemManagerScreeningsFormUpdateAction = createAction(
    '[system-manager-screenings] Update screening form',
    props<IInstitutionSystemManagerProgramParams & { screeningType: eScreeningType, screeningFormUpdate: IVolunteerScreeningUpdateFormDto }>()
);

export const SystemManagerScreeningsFormUpdateSuccessAction = createAction(
    '[system-manager-screenings] Update screening success form',
    props<IInstitutionSystemManagerProgramParams & { screeningType: eScreeningType, screeningFormUpdate: IVolunteerScreeningUpdateFormDto }>()
);

export const SystemManagerScreeningsFormUpdateErrorAction = createAction(
    '[system-manager-screenings] Update screening error form',
    props<{ error: HttpErrorResponse }>()
);

export const SystemManagerScreeningsFormInternationalizationUpdateAction = createAction(
    '[system-manager-screenings] Update screening internationalization form',
    props<IInstitutionSystemManagerProgramParams & { screeningType: eScreeningType, localization: string, localizationUpdate: any }>()
);

export const SystemManagerScreeningsFormInternationalizationUpdateSuccessAction = createAction(
    '[system-manager-screenings] Update screening internationalization success form',
    props<IInstitutionSystemManagerProgramParams & { screeningType: eScreeningType, localization: string, localizationUpdate: any }>()
);

export const SystemManagerScreeningsFormInternationalizationUpdateErrorAction = createAction(
    '[system-manager-screenings] Update screening internationalization error form',
    props<{ error: HttpErrorResponse }>()
);
