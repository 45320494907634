import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { eFormType, IDynamicControl, IDynamicForm, IDynamicItem, IDynamicOption, IDynamicValidator } from 'app/core/models';

export const DynamicSaveControlEditsAction = createAction(
  '[dynamic-form] Save Edit',
);

export const DynamicSelectedControlsChangedAction = createAction(
  '[dynamic-form] Selected inputs changed',

  props<{ controls: IDynamicControl[], groupIndex: number }>()
);

export const DynamicFormChangeActiveGroupAction = createAction(
  '[dynamic-form] Change Group',
  props<{ groupIndex: number }>()
);
export const DynamicEditControlAction = createAction(
  '[dynamic-form] Edit Control',
  props<{ groupIndex: number, controlIndex: number, formType: eFormType }>()
);

export const DynamicModifyControlAction = createAction(
  '[dynamic-form] Modify control',
  props<{ attributes: any }>()
);

export const DynamicItemAddAction = createAction(
  '[dynamic-form] add item',
  props<{ item: IDynamicItem }>()
);

export const DynamicItemRemoveAction = createAction(
  '[dynamic-form] remove item',
  props<{ itemId: string }>()
);

export const DynamicItemModifyOptionAction = createAction(
  '[dynamic-form] modify item',
  props<{ partialItem: Partial<IDynamicItem> }>()
);
export const DynamicOptionAddAction = createAction(
  '[dynamic-form] add option',
  props<{ option: IDynamicOption }>()
);
export const DynamicOptionRemoveAction = createAction(
  '[dynamic-form] remove option',
  props<{ optionId: string }>()
);
export const DynamicOptionModifyAction = createAction(
  '[dynamic-form] modify option',
  props<{ partialOption: Partial<IDynamicOption> }>()
);
export const DynamicValueChangedAction = createAction(
  '[dynamic-form] Value Change',
  props<{ value: any }>()
);
export const DynamicFormSubmitEnrollmentAction = createAction(
  '[dynamic-form] Submit enrollment custom form',
  props<{ institutionId: string, systemManagerId: string, programId: string, form: IDynamicForm }>()
);
export const DynamicFormSubmitEnrollmentSuccessAction = createAction(
  '[dynamic-form] Submit enrollment custom form success',
  props<{ institutionId: string, systemManagerId: string, programId: string, form: IDynamicForm }>()
);
export const DynamicFormSubmitEnrollmentErrorAction = createAction(
  '[dynamic-form] Submit enrollment custom form error',
  props<{ error: HttpErrorResponse }>()
);

export const DynamicValidatorRemoveAction = createAction(
  '[dynamic-form] Remove validator',
  props<{ validator: IDynamicValidator }>()
);
export const DynamicValidatorAddOrModifyAction = createAction(
  '[dynamic-form] Modify Validator',
  props<{ validator: IDynamicValidator }>()
);

export const DynamicFormResetAction = createAction(
  '[dynamic-form] Reset'
);

export const DynamicFormAddPageBreakAction = createAction(
  '[dynamic-form] Add page'
);
export const DynamicFormRemovePageBreakAction = createAction(
  '[dynamic-form] Remove page',
  props<{ groupIndex: number }>()
);
export const DynamicFormReorderGroupsAction = createAction(
  '[dynamic form] Reorder groups',
  props<{ order: number[] }>()
);


export const DynamicFormLoadManagerEnrollmentFormAction = createAction(
  '[dynamic form] Get manager enrollment form',
  props<{ institutionId: string, managerId: string, programId: string }>()
);
export const DynamicFormLoadManagerEnrollmentFormSuccessAction = createAction(
  '[dynamic form] Get manager enrollment form success',
  props<{ institutionId: string, managerId: string, programId: string, form: IDynamicForm }>()
);
export const DynamicFormLoadManagerEnrollmentFormErrorAction = createAction(
  '[dynamic form] Get manager enrollment form error',
  props<{ error: HttpErrorResponse }>()
);


export const DynamicFormLoadSystemManagerEnrollmentFormAction = createAction(
  '[dynamic form] Get system manager enrollment form',
  props<{ institutionId: string, systemManagerId: string, programId: string }>()
);
export const DynamicFormLoadSystemManagerEnrollmentFormSuccessAction = createAction(
  '[dynamic form] Get system manager enrollment form success',
  props<{ institutionId: string, systemManagerId: string, programId: string, form: IDynamicForm }>()
);
export const DynamicFormLoadSystemManagerEnrollmentFormErrorAction = createAction(
  '[dynamic form] Get system manager enrollment form error',
  props<{ error: HttpErrorResponse }>()
);

export const DynamicFormLoadEventFormAction = createAction(
  '[dynamic form] Get event form',
  props<{ institutionId: string, managerId: string, programId: string, eventId: string }>()
);
export const DynamicFormLoadEventFormSuccessAction = createAction(
  '[dynamic form] Get event form success',
  props<{ institutionId: string, managerId: string, programId: string, eventId: string, form: IDynamicForm }>()
);
export const DynamicFormLoadEventFormErrorAction = createAction(
  '[dynamic form] Get event form error',
  props<{ error: HttpErrorResponse }>()
);

export const DynamicFormSubmitEventAction = createAction(
  '[dynamic-form] Submit event custom form',
  props<{ institutionId: string, managerId: string, programId: string, eventId: string, form: IDynamicForm }>()
);
export const DynamicFormSubmitEventSuccessAction = createAction(
  '[dynamic-form] Submit event custom form success',
  props<{ institutionId: string, managerId: string, programId: string, eventId: string, form: IDynamicForm }>()
);
export const DynamicFormSubmitEventErrorAction = createAction(
  '[dynamic-form] Submit event custom form error',
  props<{ institutionId: string, managerId: string, programId: string, eventId: string, form: IDynamicForm, error: HttpErrorResponse }>()
);

export const DynamicFormUndoAction = createAction(
  '[dynamic-form] UNDO',
);
export const DynamicFormRedoAction = createAction(
  '[dynamic-form] REDO',
);

