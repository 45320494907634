import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActionsSubject, Store } from '@ngrx/store';
import { map, Observable, tap } from 'rxjs';

import { environment } from '../../../environments/environment';
import { AppState } from '../../app.reducers';
import { EventsManagerActions } from '../../shared/events-manager';
import { IInstitutionFamilyParams, IInstitutionManagerProgramEventParams } from '../models';

@Injectable({
  providedIn: 'root'
})
export class RegistrationmaxTicketingService {

  constructor(
    private httpClient: HttpClient,
    private store: Store<AppState>,
    private dispatcher: ActionsSubject
  ) {
  }

  public getMangerIframeSrc({ institutionId, managerId, programId, eventId }: IInstitutionManagerProgramEventParams) {
    return (this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/ticketing-partner-authentication`) as Observable<{ access_token: string }>).pipe(
      map(({ access_token }) => `${environment.ticketingUri}/integration/manager/login?jwt=${access_token}`)
    );
  }

  public getMemberTicketDisplayIframeSrc({ institutionId, familyId }: IInstitutionFamilyParams) {
    return (this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/families/${familyId}/ticketing-user-authentication`) as Observable<{ access_token: string }>).pipe(
      map(({ access_token }) => `${environment.ticketingUri}/integration/member/login?mode=displayTicketsOnly&jwt=${access_token}`)
    );
  }

  public getMemberCheckoutIframeSrc({
    institutionId,
    familyId,
    ticketingEventId
  }: IInstitutionFamilyParams & { ticketingEventId: string }) {
    return (this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/families/${familyId}/ticketing-user-authentication`) as Observable<{ access_token: string }>).pipe(
      map(({ access_token }) => `${environment.ticketingUri}/integration/member/login?mode=checkoutOnly&eventId=${ticketingEventId}&jwt=${access_token}`)
    );
  }

  // public getItems(params: { ticketEventId: string }) {
  //   if (Object.keys(params).find(key => params[key] == null) != null) {
  //     return of(null);
  //   }
  //   return this.store.pipe(
  //     select(getTicketingItems(params)),
  //     tap(items => {
  //       if (items === undefined) {
  //         this.store.dispatch(TicketingActions.LoadItemsAction(params));
  //       }
  //     }),
  //     filter(e => e != null),
  //   );
  // }

  // public getItemsEffect({ ticketEventId }: { ticketEventId: string }) {
  //   return this.httpClient.get(`http://localhost:5000/api/events/${ticketEventId}/items`) as Observable<IItemDto[]>;
  // }

  // public addItem(params: IInstitutionManagerProgramEventParams & { itemCreateDto: IItemCreateDto }) {
  //   this.store.dispatch(TicketingActions.CreateItemsAction(params));
  //   return this.dispatcher.pipe(
  //     ofType(TicketingActions.CreateItemsSuccessAction, TicketingActions.CreateItemsErrorAction),
  //     take(1),
  //     map(action => {
  //       if (action.type === TicketingActions.CreateItemsSuccessAction.type) {
  //         return action.itemCreateDto;
  //       } else {
  //         throw action;
  //       }
  //     })
  //   );
  // }

  // public addItemEffect({ institutionId, managerId, programId, eventId, itemCreateDto }: IInstitutionManagerProgramEventParams & { itemCreateDto: IItemCreateDto }) {
  //   return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/items`, itemCreateDto);
  // }

  public reserveTickets(ticketEventId: string, formResponse: any) {
    return this.httpClient.post(`${environment.apiUri}/api/${ticketEventId}`, formResponse);
  }

  public enableTicketing({ institutionId, managerId, programId, eventId }: IInstitutionManagerProgramEventParams) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/enable-ticketing`, {}).pipe(
      tap(() => this.store.dispatch(EventsManagerActions.EventsManagerInvalidateEventCacheAction()))
    );
  }

  public disableTicketing({ institutionId, managerId, programId, eventId }: IInstitutionManagerProgramEventParams) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/disable-ticketing`, {}).pipe(
      tap(() => this.store.dispatch(EventsManagerActions.EventsManagerInvalidateEventCacheAction()))
    );
  }
}
