import { IGroupEnrollmentRaceSummaryDto } from '.';

export interface IGroupEnrollmentDto {
    groupEnrollmentId: string;
    dateCreated: string;
    projects?: any[];
    hispanicParticipant: IGroupEnrollmentRaceSummaryDto;
    nonHispanicParticipant: IGroupEnrollmentRaceSummaryDto;
    hispanicYouthVolunteer: IGroupEnrollmentRaceSummaryDto;
    nonHispanicYouthVolunteer: IGroupEnrollmentRaceSummaryDto;
    hispanicAdultVolunteer: IGroupEnrollmentRaceSummaryDto;
    nonHispanicAdultVolunteer: IGroupEnrollmentRaceSummaryDto;
    submitted: boolean;
    initialSubmitDate?: string;
    dateSubmitted?: string;
    foodNutritionProgram: boolean;
    raciallyMixedCommunity: boolean;
    integrated: boolean;
    youthsFromMilitaryFamilies: number;
    residenceFarm: number;
    residenceTownUnder10000: number;
    residenceTown10000To50000: number;
    residenceSuburbMoreThan50000: number;
    residenceCityMoreThan50000: number;
    genderTotalMales: number;
    gender4HMemberMales: number;
    genderTotalFemales: number;
    gender4HMemberFemales: number;
    genderTotalOther: number;
    gender4HMemberOther: number;
    gradeKindergarten: number;
    grade1: number;
    grade2: number;
    grade3: number;
    grade4: number;
    grade5: number;
    grade6: number;
    grade7: number;
    grade8: number;
    grade9: number;
    grade10: number;
    grade11: number;
    grade12: number;
    gradePostHighSchool: number;
    gradeNotInSchool: number;
    gradeSpecial: number;
    adultDirectMaleVolunteers: number;
    adultIndirectMaleVolunteers: number;
    adultDirectFemaleVolunteers: number;
    adultIndirectFemaleVolunteers: number;
    youthDirectMaleVolunteers: number;
    youthIndirectMaleVolunteers: number;
    youthDirectFemaleVolunteers: number;
    youthIndirectFemaleVolunteers: number;
    youthVolunteerLeadership: number;
    youthVolunteerParenting: number;
    youthVolunteerOther: number;
    adultVolunteerLeadership: number;
    adultVolunteerParenting: number;
    adultVolunteerOther: number;
    otherAdultLeadership: number;
    otherAdultParenting: number;
    otherAdultOther: number;
    countyAreaId: string;
    managerId: string;
    programYearId: string;
    unitId: string;
    deliveryModeId: string;
    programName?: string;
    programDate: string;
    programEndDate?: string;
    presenter?: string;
    location?: string;
    postalCode?: string;
    programHours: number;
    unitsCompleted: number;
    description?: string;


    genderUnidentified: number;
    racialDistributionUnidentified: number;
    residenceUnidentified: number;
    gradeUnidentified: number;
    hostingOrganizationName?: string;

    allowYearChange: boolean;

    gender4HMemberNonBinary: number;
    genderTotalNonBinary: number;
    gender4HMemberNotListed: number;
    genderTotalNotListed: number;
    gender4HMemberPreferNotToState: number;
    genderTotalPreferNotToState: number;

    adultVolunteerRacialDistributionUnidentified: number;
    youthVolunteerRacialDistributionUnidentified: number;
    adultVolunteerGenderDistributionUnidentified: number;
    youthVolunteerGenderDistributionUnidentified: number;

}
