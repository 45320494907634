import {
    ICustomReportFilterGroupDto,
    ICustomReportGroupByDto,
    ICustomReportOrderByDto,
    IReportColumnDto,
    ISearchDateRangeDto,
} from '.';
import { eCustomReportCategory } from '..';
import { eCustomReportGroupingType } from '../enums';

export interface ICustomReportDto {
  reportId: string;
  reportCategory: eCustomReportCategory;
  inTrash: boolean;
  shared: boolean;
  columns?: IReportColumnDto[];
  filterGroups?: ICustomReportFilterGroupDto[];
  orderBy?: ICustomReportOrderByDto[];
  groupBy?: ICustomReportGroupByDto[];
  calculations?: object;
  reportFolderId: string;
  reportName?: string;
  description?: string;
  excelWorksheetTitle?: string;


  // Standard filters
  includeAllYears: boolean;
  enrollmentDateRange: ISearchDateRangeDto;
  memberFlagged?: boolean;
  hispanic?: boolean;
  fourHAge?: any[];
  gender?: any[];
  schoolGrade?: any[];
  race?: any[];
  militaryService?: any[];
  residence?: any[];
  memberRoles?: any[];
  memberProgramStatuses?: any[];
  memberProgramYearStatuses?: any[];
  memberParticipationStatus?: any[];
  enrollmentRoles?: any[];


  hierarchyNodeId: string;
  programYearId: string;
  primaryUnit?: boolean;
  familyCounties?: any[];
  enrollmentCounties?: any[];
  units?: any[];
  projects?: any[];
  groupingType: eCustomReportGroupingType;

}
