import { Injectable } from '@angular/core';
import { anyNull, ONE_HOUR_IN_SECONDS } from 'app/shared/utils';

import { eTableLayout, IInstitutionManagerParams, IInstitutionProfile, IInstitutionProfileAuthDto, ILoginCredentials } from '../models';
import { IAnyInstitutionProfile } from '../models';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

    private AUTH = 'ng4h-login-credentials';
    // private LAST_AUTH = 'ng4h-login-credentials-last';
    // private PROGRAM_YEAR = 'ng4h-program-year';
    private HIERARCHY_NODE_ID = 'ng4h-hierarchy-node-id';
    private PROFILE_HISTORY = 'ng4h-profile-history';
    private TABLE_LAYOUT = 'TABLE_LAYOUT';
    // private LAST_AD_DISPLAY = 'LAST_AD_DISPLAY';
    private MEMBER_AD = 'MEMBER_AD';

    constructor() {
    }

    private get(key: string): string | null {
        return localStorage.getItem(key);
    }

    private set(key: string, value: string) {
        localStorage.setItem(key, value);
    }

    private remove(key: string): void {
        localStorage.removeItem(key);
    }

    private isUserAccountId(userAccountId: string): boolean {
        const userAccountIdRegex = new RegExp('^ua_\\w*$', 'i');
        return userAccountIdRegex.test(userAccountId);
    }

    public removeExpiredAuthCredentials() {
        const keysToRemove = [];
        for (let i = 0; i < localStorage.length; i++) {
            const nthKey = localStorage.key(i);
            if (keysToRemove.includes(this.AUTH)) {
                const credentials = JSON.parse(this.get(nthKey)) as ILoginCredentials;
                if ((((new Date()).getTime() / 1000) + ONE_HOUR_IN_SECONDS) > credentials.expiresAt) {
                    keysToRemove.push(nthKey);
                }
            }
        }

        for (let i = 0; i < keysToRemove.length; i++) {
            this.remove(keysToRemove[i]);
        }
    }

    public getLoginCredentials(userAccountId: string): ILoginCredentials {
        if (!this.isUserAccountId(userAccountId)) {
            // return JSON.parse(this.get(this.LAST_AUTH));
            for (let i = 0; i < localStorage.length; i++) {
                const nthKey = localStorage.key(i);
                if (nthKey.includes(this.AUTH)) {
                    return JSON.parse(this.get(nthKey));
                }
            }
            return null;
        }
        return JSON.parse(this.get(this.AUTH + ':' + userAccountId));
    }

    public deleteLoginCredentials(userAccountId: string): void {
        this.remove(this.AUTH + ':' + userAccountId);
    }

    public setProfileHistory(userAccountId: string, profileHistory: { actingOnBehalfOfProfile: IAnyInstitutionProfile, route: string[] }[]) {
        if (this.isUserAccountId(userAccountId)) {
            this.set(this.PROFILE_HISTORY + ':' + userAccountId, JSON.stringify(profileHistory));
        }
    }

    public getProfileHistory(userAccountId: string): { actingOnBehalfOfProfile: IAnyInstitutionProfile, route: string[] }[] {
        if (this.isUserAccountId(userAccountId)) {
            const profileHistoryRaw = this.get(this.PROFILE_HISTORY + ':' + userAccountId);
            return JSON.parse(profileHistoryRaw);
        }
        return null;
    }

    public removeProfileHistory(userAccountId: string): any {
        if (!this.isUserAccountId(userAccountId)) {
            for (let i = 0; i < localStorage.length; i++) {
                if (localStorage.key(i).substring(0, this.PROFILE_HISTORY.length - 1) === this.PROFILE_HISTORY) {
                    this.remove(localStorage.key(i));
                }
            }
        } else {
            this.remove(this.PROFILE_HISTORY + ':' + userAccountId);
        }
    }

    public setLoginCredentials(credentials: ILoginCredentials) {
        if (this.isUserAccountId(credentials?.userAccountId)) {
            this.set(this.AUTH + ':' + credentials.userAccountId, JSON.stringify(credentials));
        }
    }

    public removeLoginCredentials(userAccountId: string) {
        if (!this.isUserAccountId(userAccountId)) {
            localStorage.clear();
        } else {
            this.remove(this.AUTH + ':' + userAccountId);
        }
    }

    public getHierarchyNodeId({ institutionId, managerId }: IInstitutionManagerParams): string {
        if (anyNull(institutionId, managerId)) {
            return null;
        }
        return JSON.parse(this.get(this.HIERARCHY_NODE_ID + ':' + institutionId + ':' + managerId));
    }

    public setHierarchyNodeId({
        institutionId,
        managerId,
        hierarchyNodeId
    }: IInstitutionManagerParams & { hierarchyNodeId: string }) {
        this.set(this.HIERARCHY_NODE_ID + ':' + institutionId + ':' + managerId, JSON.stringify(hierarchyNodeId));
    }

    public setTableColumns(tableKey: string, userAccountId: string, columns: string[]) {
        this.set(tableKey + ':' + userAccountId, JSON.stringify(columns));
    }

    public getTableColumns(tableKey: string, userAccountId: string): string[] {
        return JSON.parse(this.get(tableKey + ':' + userAccountId));
    }

    public setTableLayout(userAccountId: string, layout: eTableLayout) {
        this.set(this.TABLE_LAYOUT + ':' + userAccountId, JSON.stringify(layout));
    }

    public getTableLayout(userAccountId: string): eTableLayout {
        return JSON.parse(this.get(this.TABLE_LAYOUT + ':' + userAccountId));
    }

    // public setLastAdDisplay(userAccountId: string, dateTime: Date): void {
    //   this.set(this.LAST_AD_DISPLAY + ':' + userAccountId, dateTime.toISOString());
    // }

    // public getLastAdDisplay(userAccountId: string): Date | null {
    //   const dateString = this.get(this.LAST_AD_DISPLAY + ':' + userAccountId);
    //   return dateString ? new Date(dateString) : null;
    // }

    public hideMemberAd(userAccountId: string) {
        this.set(this.MEMBER_AD + ':' + userAccountId, 'false');
    }

    public memberAdStatus(userAccountId: string) {
        return this.get(this.MEMBER_AD + ':' + userAccountId) !== 'false';
    }
}
