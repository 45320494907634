import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  DynamicValidatorBaseComponent,
} from 'app/core/containers/admin/shared/dynamic-forms/dynamic-validator-base/dynamic-validator-base.component';
import { eFormType, IDynamicControl } from 'app/core/models';

@Component({
  selector: 'ng4h-dynamic-checkbox-options',
  templateUrl: './dynamic-checkbox-options.component.html',
  styleUrls: ['./dynamic-checkbox-options.component.scss']
})
export class DynamicCheckboxOptionsComponent extends DynamicValidatorBaseComponent implements OnInit {

  public eFormType = eFormType;
  @Input() control: IDynamicControl;
  @Input() enableLimitTo = true;
  @Output() controlModified: EventEmitter<{ itemId: any, value: any }>;
  @Output() itemModified: EventEmitter<any>;

  constructor() {
    super();
    this.controlModified = new EventEmitter();
    this.itemModified = new EventEmitter();
  }

  ngOnInit() {
  }

  public changeItemLabel(itemId: string, label: string) {
    this.itemModified.emit({ itemId: itemId, label: label });
  }

  public changeItemRate(itemId: string, rate: number) {
    this.itemModified.emit({ itemId: itemId, rate: rate });
  }

  reportLabelChange(reportValue: string, itemId: string) {
    this.itemModified.emit({ itemId, reportValue });
  }
}
