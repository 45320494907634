import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AutoUnsubscribe, eTableLayout, IConfigurableTableColumns } from 'app/core/models';
import { RouterService } from 'app/core/services';
import { LocalStorageService } from 'app/core/services/local-storage.service';
import { takeUntil } from 'rxjs';

@Component({
    selector: 'ng4h-table-column-manager',
    templateUrl: './table-column-manager.component.html',
    styleUrls: ['./table-column-manager.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableColumnManagerComponent extends AutoUnsubscribe implements OnInit {

    @Input() storageKey: string;
    @Input() columns: IConfigurableTableColumns[];
    @Output() columnChanged: EventEmitter<string[]> = new EventEmitter();
    @Output() layoutChanged: EventEmitter<string> = new EventEmitter();
    selectedColumns = new FormControl();

    public currentLayout: eTableLayout;

    constructor(
        private localStorageService: LocalStorageService,
        private routerService: RouterService
    ) {
        super();
    }

    ngOnInit(): void {

        this.selectedColumns.valueChanges.pipe(
            takeUntil(this.autoUnsubscribe)
        ).subscribe(val => {
            const newColumns = this.columns.filter(c => c.show === false || val.includes(c.key)).map(c => c.key);
            this.columnChanged.emit(newColumns);
            if (this.storageKey != null) {
                this.localStorageService.setTableColumns(this.storageKey, this.routerService.userAccountId, newColumns);
            }
        });

        if (this.storageKey != null) {
            const columns = this.localStorageService.getTableColumns(this.storageKey, this.routerService.userAccountId)?.filter(c => c != null);
            if (columns == null) {
                this.reset();
            } else {
                this.selectedColumns.setValue(columns);
            }
        } else {
            this.reset();
        }

        const layout = this.localStorageService.getTableLayout(this.routerService.userAccountId);
        if (layout == null) {
            this.currentLayout = eTableLayout.Normal;
        } else {
            this.currentLayout = layout;
        }
        this.layoutChanged.emit(this.currentLayout);
    }

    public reset() {
        this.currentLayout = eTableLayout.Normal;
        this.layoutChanged.emit(this.currentLayout);
        this.selectedColumns.setValue(this.columns.filter(c => c != null && c.default !== false).map(c => c.key));
    }

    public toggleLayout($event) {
        $event.stopPropagation();
        this.currentLayout = this.currentLayout === eTableLayout.Normal ? eTableLayout.Compact : eTableLayout.Normal;
        this.layoutChanged.emit(this.currentLayout);
        this.localStorageService.setTableLayout(this.routerService.userAccountId, this.currentLayout);
    }
}
