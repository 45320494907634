import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ISystemManagerInstitutionProfile } from 'app/core/models';
import { RouterService, SystemManagerService } from 'app/core/services';
import { systemManager } from 'app/shared/system-manager/system-managers.reducer';
import { filter, map, Observable } from 'rxjs';

@Component({
  selector: 'ng4h-modal-select-system-manager-institution-profile',
  templateUrl: './modal-select-system-manager-institution-profile.component.html',
  styleUrls: ['./modal-select-system-manager-institution-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalSelectSystemManagerInstitutionProfileComponent implements OnInit {

  public systemManagers$: Observable<ISystemManagerInstitutionProfile[]>;

  public selectedSystemManager: ISystemManagerInstitutionProfile;
  constructor(
    private dialogRef: MatDialogRef<any>,
    private systemManagerService: SystemManagerService,
    private routerService: RouterService
  ) { }

  ngOnInit() {

    this.systemManagers$ = this.systemManagerService.getSystemManagers({ institutionId: this.routerService.institutionId })
      .pipe(
        filter(data => data !== null),
        map(systemManagers => {
          return systemManagers.filter(systemManager => {
            return Object.keys(systemManager.acl).find(program => program === this.routerService.programId) == null;
          });
        })
      );
  }

  public rowClicked(systemManager: ISystemManagerInstitutionProfile) {
    this.selectedSystemManager = systemManager;
  }

  public close(systemManager?: ISystemManagerInstitutionProfile): void {
    this.dialogRef.close(systemManager);
  }

  public add() {
    this.close(this.selectedSystemManager);
  }
}
