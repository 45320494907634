import { ChangeDetectorRef, Directive, EmbeddedViewRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import {
  eAccessControlListRoleType,
  eProfileType,
  IAnyInstitutionProfile,
  IEventDto,
  IEventPermissions
} from 'app/core/models';
import { EventsManagerService, ProfileService, RouterService } from 'app/core/services';
import { combineLatest, filter, take } from 'rxjs';

import { rankPermissionState } from '../permission.utils';

export class ManagerEventPermissionsDirectiveContext {
  public $implicit: Partial<IEventPermissions> = null;
  public ng4hManagerEventPermissions: Partial<IEventPermissions> = null;
}

@Directive({
  selector: '[ng4hManagerEventPermissions]'
})
export class ManagerEventPermissionsDirective {

  private context: ManagerEventPermissionsDirectiveContext = new ManagerEventPermissionsDirectiveContext();
  private thenViewRef: EmbeddedViewRef<any> | null;

  @Input('ng4hManagerEventPermissions')
  set ng4hManagerEventPermissions(eventPermissions: Partial<IEventPermissions>) {
    this.context.$implicit = this.context.ng4hManagerEventPermissions = eventPermissions;
    this.thenViewRef = null;
    this.updateView();
  }

  constructor(
    private thenTemplateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private profileService: ProfileService,
    private routerService: RouterService,
    private eventManagerService: EventsManagerService,
    private cdr: ChangeDetectorRef
  ) {
  }

  private updateView() {
    combineLatest([
        this.profileService.actingAsInstitutionProfile, this.eventManagerService.getEvent({
        institutionId: this.routerService.institutionId,
        managerId: this.routerService.managerId,
        programId: this.routerService.programId,
        eventId: this.routerService.eventId
      })
    ]).pipe(
      filter(combined => combined.every(c => c != null)),
      take(1)
    ).subscribe(([actingOnBehalfOf, event]) => {
      if (this.context.ng4hManagerEventPermissions == null || this.hasPermissions(this.context.ng4hManagerEventPermissions, actingOnBehalfOf, event)) {
        this.thenViewRef = null;
        this.viewContainer.clear();
        if (this.thenTemplateRef) {
          this.thenViewRef =
            this.viewContainer.createEmbeddedView(this.thenTemplateRef, this.context);
        }

      } else {
        this.viewContainer.clear();
      }
      this.cdr.markForCheck();
    });
  }

  private hasPermissions(requiredPermissions: Partial<IEventPermissions>, profile: IAnyInstitutionProfile, event: IEventDto): boolean {
    if (profile.profileType !== eProfileType.Manager || event.activeManagerPermission == null) {
      return false;
    }

    if (event.activeManagerPermission.permissionRole === eAccessControlListRoleType.EventAdmin) {
      return true;
    }

    const requiredPermissionKeys = Object.keys(requiredPermissions);

    for (let i = 0; i < requiredPermissionKeys.length; i++) {

      const managerPermission = event.activeManagerPermission.grantedPermissions[requiredPermissionKeys[i]];

      if (managerPermission == null) {
        return false;
      }
      const requiredPermission = requiredPermissions[requiredPermissionKeys[i]];

      if (rankPermissionState(requiredPermission) < rankPermissionState(managerPermission)) {
        return false;
      }
    }
    return true;
  }

}
