import { Action, createReducer, on } from '@ngrx/store';
import { IInstitutionMemberModel, IInstitutionMemberProgramModel, IProfileTrainingLessonDto } from 'app/core/models';
import { IProfileTrainingDto } from 'app/core/models/serverDTOs/IProfileTrainingDto';
import { mergeImmutable } from 'app/shared/utils';

import { MemberTrainingsActions } from '.';

export interface MemberTrainingState {
    availableTrainings: IInstitutionMemberProgramModel<IProfileTrainingDto[]>;
    trainings: IInstitutionMemberModel<IProfileTrainingDto[]>;
    // training: IInstitutionMemberModel<{ [profileTrainingId: string]: IProfileTrainingDto }>;
    lessons: IInstitutionMemberModel<{ [profileTrainingId: string]: { [trainingLessonId: string]: IProfileTrainingLessonDto } }>;
}

const initialState: MemberTrainingState = {
    availableTrainings: undefined,
    trainings: undefined,
    // training: undefined,
    lessons: undefined
};

const reducer = createReducer(
    initialState,
    on(MemberTrainingsActions.MemberTrainingLoadAvailableTrainingsSuccessAction, (state, { institutionId, memberId, programId, availableTrainings }) => {
        const newAvailableTrainings = mergeImmutable(
            { [institutionId]: { [memberId]: { [programId]: availableTrainings } } },
            state.availableTrainings
        );
        return {
            ...state,
            availableTrainings: newAvailableTrainings
        };
    }),
    on(MemberTrainingsActions.MemberTrainingLoadTrainingsSuccessAction, (state, { institutionId, memberId, programId, trainings }) => {
        const newTrainings = mergeImmutable(
            { [institutionId]: { [memberId]: { [programId]: trainings } } },
            state.trainings
        );
        return {
            ...state,
            trainings: newTrainings
        };
    }
    ),
    on(MemberTrainingsActions.MemberTrainingLoadTrainingSuccessAction, (state, { institutionId, memberId, programId, profileTrainingId, training }) => {
        const newTrainings = mergeImmutable(
            { [institutionId]: { [memberId]: { [programId]: { [profileTrainingId]: training } } } },
            state.trainings
        );
        return {
            ...state,
            trainings: newTrainings
        };
    }),
    on(MemberTrainingsActions.MemberTrainingLoadLessonSuccessAction, (state, { institutionId, memberId, programId, profileTrainingId, trainingLessonId, lesson }) => {
        const newLessons = mergeImmutable(
            { [institutionId]: { [memberId]: { [programId]: { [profileTrainingId]: { [trainingLessonId]: lesson } } } } },
            state.lessons
        );
        return {
            ...state,
            lessons: newLessons
        };
    }),
    on(MemberTrainingsActions.MemberTrainingLoadLessonErrorAction, (state, { institutionId, memberId, programId, profileTrainingId, trainingLessonId }) => {
        const newLessons = mergeImmutable(
            { [institutionId]: { [memberId]: { [programId]: { [profileTrainingId]: { [trainingLessonId]: null } } } } },
            state.lessons
        );
        return {
            ...state,
            lessons: newLessons
        };
    }),
    on(MemberTrainingsActions.MemberTrainingInvalidateCacheAction,
        MemberTrainingsActions.MemberTrainingRegisterForTrainingSuccessAction,
        MemberTrainingsActions.MemberTrainingManagerRecordTrainingSuccessAction,
        MemberTrainingsActions.ManagerMarkTrainingAsCompletedSuccessAction,
        MemberTrainingsActions.MemberTrainingDeleteSuccessAction,
        (state, { }) => {
            return initialState;
        })
);

export function memberTrainingsReducer(state: MemberTrainingState | undefined, actions: Action) {
    return reducer(state, actions);
}

export const availableTrainings = (state: MemberTrainingState) => state.availableTrainings;
export const trainings = (state: MemberTrainingState) => state.trainings;
// export const training = (state: MemberTrainingState) => state.training;
export const lessons = (state: MemberTrainingState) => state.lessons;
