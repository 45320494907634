import { IEventRegistrationTypeDescriptorDto } from './IEventRegistrationTypeDescriptorDto';
import { ISearchDateRangeDto } from './ISearchDateRangeDto';

export interface IEventRegistrationSearchParametersDto {
  searchId: string;
  programId: string;
  eventIds?: any[];
  searchTerm?: string;
  availableRegistrationTypes?: IEventRegistrationTypeDescriptorDto[];
  registrationStatus?: any[];
  registrationTypes?: any[];
  registrationDates: ISearchDateRangeDto;
  certificationDates: ISearchDateRangeDto;
  programYouthDivisionIds?: string[];
}
