<mat-sidenav-container (swiperight)="swiperight()" style="overflow:unset">

  <mat-sidenav [fixedTopGap]="83" [fixedInViewport]="true" position="end" #drawer [mode]="sidebarMode | async"
               [opened]="managerSidebarOpen$ | async" (closedStart)="sidebarClosing()"
               [ngClass]="{'background-dark': (sidebarMode | async) == 'side', 'background-light': (sidebarMode | async) == 'over'}"
               (closedStart)="sidebarClosing()">

    <div (swiperight)="swiperight()">
      
      <ng-container *ngIf="institutionId$ | async as institutionId">

        <mat-nav-list *ng4hVar="programId$ | async as programId">

          <ng-container *ngIf="activeUserInstitutionProfile$ | async as activeUserInstitutionProfile">

            <ng-container [ngSwitch]="activeUserInstitutionProfile.profileType">

              <h3 mat-subheader class="profile-list-header">Logged in as</h3>

              <ng-container *ngSwitchCase="eProfileType.Manager">
                <mat-list-item (click)="yourProfileClicked(activeUserInstitutionProfile)" class="historical-list-item">
                  <div class="historical-icon-wrapper manager" matListItemIcon  *ng4hVar="asManager(activeUserInstitutionProfile).managerProgramsPrimaryHierarchy | managerProgramHierarchyType:programId as managerHierarchyType">
                    <ng-container *ngIf="managerHierarchyType let nodeType else hierarchyNotKnown">
                      <ng-container *ngIf="nodeType == 'CountyArea'">
                        <div class="historical-icon">C</div>
                      </ng-container>
                      <ng-container *ngIf="nodeType == 'DistrictArea'">
                        <div class="historical-icon">D</div>
                      </ng-container>
                      <ng-container *ngIf="nodeType == 'RegionArea'">
                        <div class="historical-icon">R</div>
                      </ng-container>
                      <ng-container *ngIf="nodeType == 'Institution'">
                        <div class="historical-icon">P</div>
                      </ng-container>
                    </ng-container>
                    <ng-template #hierarchyNotKnown>
                      <div class="historical-icon">A</div>
                    </ng-template>
                  </div>
                  <h4 matListItemLine class="historical-profile-name">
                    {{ asManager(activeUserInstitutionProfile).firstName }}
                    {{ asManager(activeUserInstitutionProfile).lastName }}
                  </h4>
                </mat-list-item>
              </ng-container>

              <ng-container *ngSwitchCase="eProfileType.SystemManager">
                <mat-list-item (click)="yourProfileClicked(activeUserInstitutionProfile)" class="historical-list-item">
                  <div class="historical-icon-wrapper system-manager" matListItemIcon>
                    <div class="historical-icon">S</div>
                  </div>
                  <h4 matListItemLine class="historical-profile-name">
                    {{ asSystemManager(activeUserInstitutionProfile).firstName }}
                    {{ asSystemManager(activeUserInstitutionProfile).lastName }}
                  </h4>
                </mat-list-item>
              </ng-container>

              <ng-container *ngSwitchCase="eProfileType.Superuser">
                <mat-list-item (click)="yourProfileClicked(activeUserInstitutionProfile)" class="historical-list-item">
                  <div class="historical-icon-wrapper superuser" matListItemIcon>
                    <div class="historical-icon">G</div>
                  </div>
                  <h4 matListItemLine class="historical-profile-name">
                    {{ asSuperuser(activeUserInstitutionProfile).firstName }}
                    {{ asSuperuser(activeUserInstitutionProfile).lastName }}
                  </h4>
                  <small matListItemLine class="historical-profile-hierarchy">
                    RegistrationMax
                  </small>
                </mat-list-item>
              </ng-container>

            </ng-container>

            <ng-container *ngIf="actingOnBehalfOf$ | async as actingOnBehalfOf">

              <ng-container *ngIf="activeUserInstitutionProfile.institutionProfileId != actingOnBehalfOf.institutionProfileId">

                <h3 mat-subheader class="profile-list-header">Acting As</h3>

                <div class="acting-as" [ngSwitch]="actingOnBehalfOf.profileType">

                  <ng-container *ngSwitchCase="eProfileType.Member">
                    <mat-list-item class="historical-list-item">
                      <div class="historical-icon-wrapper member" matListItemIcon>
                        <div class="historical-icon">M</div>
                      </div>
                      <h4 matListItemLine class="historical-profile-name">
                        {{ asMember(actingOnBehalfOf).firstName }}
                        {{ asMember(actingOnBehalfOf).lastName }}
                      </h4>
                      <small matListItemLine class="historical-profile-hierarchy">
                        {{ (getMemberPrimaryCountyAreaId(actingOnBehalfOf) | hierarchyNode$ | async)?.hierarchyNodeName }}
                        County
                      </small>
                    </mat-list-item>
                  </ng-container>

                  <ng-container *ngSwitchCase="eProfileType.Family">
                    <mat-list-item class="historical-list-item">
                      <div class="historical-icon-wrapper family" matListItemIcon>
                        <div class="historical-icon">F</div>
                      </div>
                      <h4 matListItemLine class="historical-profile-name">
                        {{ asFamily(actingOnBehalfOf).familyName }}
                      </h4>
                      <small matListItemLine class="historical-profile-hierarchy">
                        {{ (asFamily(actingOnBehalfOf).countyHierarchyNodeId | hierarchyNode$ | async)?.hierarchyNodeName  }}
                        County
                      </small>
                    </mat-list-item>
                  </ng-container>

                  <ng-container *ngSwitchCase="eProfileType.Manager">
                    <mat-list-item class="historical-list-item">
                      <div class="historical-icon-wrapper manager" matListItemIcon *ng4hVar="asManager(actingOnBehalfOf).managerProgramsPrimaryHierarchy | managerProgramHierarchyType:programId as managerHierarchyType">
                        <ng-container *ngIf="managerHierarchyType let nodeType else hierarchyNotKnown">
                          <ng-container *ngIf="nodeType == 'CountyArea'">
                            <div class="historical-icon">C</div>
                          </ng-container>
                          <ng-container *ngIf="nodeType == 'DistrictArea'">
                            <div class="historical-icon">D</div>
                          </ng-container>
                          <ng-container *ngIf="nodeType == 'RegionArea'">
                            <div class="historical-icon">R</div>
                          </ng-container>
                          <ng-container *ngIf="nodeType == 'Institution'">
                            <div class="historical-icon">P</div>
                          </ng-container>
                        </ng-container>
                        <ng-template #hierarchyNotKnown>
                          <div class="historical-icon">A</div>
                        </ng-template>
                      </div>
                      <h4 matListItemLine class="historical-profile-name">
                        {{ asManager(actingOnBehalfOf).firstName }}
                        {{ asManager(actingOnBehalfOf).lastName }}
                      </h4>
                      <small matListItemLine class="historical-profile-hierarchy">
                        {{ (asManager(actingOnBehalfOf).institutionId | institution$ | async)?.institutionName }}
                      </small>
                    </mat-list-item>
                  </ng-container>

                  <ng-container *ngSwitchCase="eProfileType.SystemManager">
                    <mat-list-item class="historical-list-item">
                      <div class="historical-icon-wrapper system-manager" matListItemIcon>
                        <div class="historical-icon">S</div>
                      </div>
                      <h4 matListItemLine class="historical-profile-name">
                        {{ asSystemManager(actingOnBehalfOf).firstName }}
                        {{ asSystemManager(actingOnBehalfOf).lastName }}
                      </h4>
                      <small matListItemLine class="historical-profile-hierarchy">
                        {{
                          (asSystemManager(actingOnBehalfOf).institutionId | institution$ |
                            async)?.institutionName
                        }}
                      </small>
                    </mat-list-item>
                  </ng-container>

                  <ng-container *ngSwitchCase="eProfileType.Superuser">

                    <mat-list-item class="historical-list-item">
                      <div class="historical-icon-wrapper superuser" matListItemIcon>
                        <div class="historical-icon">G</div>
                      </div>
                      <h4 matListItemLine class="historical-profile-name"> {{ asSuperuser(actingOnBehalfOf).firstName }}
                        {{ asSuperuser(actingOnBehalfOf).lastName }}
                      </h4>
                      <small matListItemLine class="historical-profile-hierarchy">
                        RegistrationMax
                      </small>
                    </mat-list-item>
                  </ng-container>

                </div>

              </ng-container>

              <h3 mat-subheader class="profile-list-header">
                Recent Profiles
              </h3>

              <ng-container *ngFor="let historicProfile of (sortedActiveProfileHistory$ | async); let i = index;">

                <ng-container
                  *ngIf="activeUserInstitutionProfile.institutionProfileId != historicProfile.actingOnBehalfOfProfile.institutionProfileId && actingOnBehalfOf.institutionProfileId != historicProfile.actingOnBehalfOfProfile.institutionProfileId">

                  <ng-container [ngSwitch]="historicProfile.actingOnBehalfOfProfile.profileType">

                    <ng-container *ngSwitchCase="eProfileType.Member">
                      <mat-list-item (click)="historicalProfileClicked(historicProfile)" class="historical-list-item">
                        <div class="historical-icon-wrapper member" matListItemIcon>
                          <div class="historical-icon">M</div>
                        </div>
                        <h4 matListItemLine class="historical-profile-name">
                          {{ asMember(historicProfile.actingOnBehalfOfProfile).firstName }}
                          {{ asMember(historicProfile.actingOnBehalfOfProfile).lastName }}
                        </h4>
                        <small matListItemLine class="historical-profile-hierarchy">
                          {{
                            (getMemberPrimaryCountyAreaId(historicProfile.actingOnBehalfOfProfile) | hierarchyNode$ | async)?.hierarchyNodeName
                          }}
                          County
                        </small>
                      </mat-list-item>
                    </ng-container>

                    <ng-container *ngSwitchCase="eProfileType.Family">

                      <mat-list-item (click)="historicalProfileClicked(historicProfile)" class="historical-list-item">
                        <div class="historical-icon-wrapper family" matListItemIcon>
                          <div class="historical-icon">F</div>
                        </div>
                        <h4 matListItemLine class="historical-profile-name">
                          {{ asFamily(historicProfile.actingOnBehalfOfProfile).familyName }}
                        </h4>
                        <small matListItemLine class="historical-profile-hierarchy">
                          {{
                            (asFamily(historicProfile.actingOnBehalfOfProfile).countyHierarchyNodeId | hierarchyNode$ | async)?.hierarchyNodeName
                          }}
                          County
                        </small>
                      </mat-list-item>
                    </ng-container>

                    <ng-container *ngSwitchCase="eProfileType.Manager">
                      <mat-list-item (click)="historicalProfileClicked(historicProfile)" class="historical-list-item">
                        <div class="historical-icon-wrapper manager" matListItemIcon *ng4hVar="asManager(historicProfile.actingOnBehalfOfProfile).managerProgramsPrimaryHierarchy | managerProgramHierarchyType:programId as managerHierarchyType">
                          <ng-container *ngIf="managerHierarchyType let nodeType else hierarchyNotKnown">
                            <ng-container *ngIf="nodeType == 'CountyArea'">
                              <div class="historical-icon">C</div>
                            </ng-container>
                            <ng-container *ngIf="nodeType == 'DistrictArea'">
                              <div class="historical-icon">D</div>
                            </ng-container>
                            <ng-container *ngIf="nodeType == 'RegionArea'">
                              <div class="historical-icon">R</div>
                            </ng-container>
                            <ng-container *ngIf="nodeType == 'Institution'">
                              <div class="historical-icon">P</div>
                            </ng-container>
                          </ng-container>
                          <ng-template #hierarchyNotKnown>
                            <div class="historical-icon">A</div>
                          </ng-template>
                        </div>
                        <h4 matListItemLine class="historical-profile-name">
                          {{ asManager(historicProfile.actingOnBehalfOfProfile).firstName }}
                          {{ asManager(historicProfile.actingOnBehalfOfProfile).lastName }}
                        </h4>
                        <small matListItemLine class="historical-profile-hierarchy">
                          {{
                            (asManager(historicProfile.actingOnBehalfOfProfile).institutionId | institution$ | async)?.institutionName
                          }}
                        </small>
                      </mat-list-item>
                    </ng-container>

                    <ng-container *ngSwitchCase="eProfileType.SystemManager">

                      <mat-list-item (click)="historicalProfileClicked(historicProfile)" class="historical-list-item">
                        <div class="historical-icon-wrapper system-manager" matListItemIcon>
                          <div class="historical-icon">S</div>
                        </div>
                        <h4 matListItemLine class="historical-profile-name">
                          {{ asSystemManager(historicProfile.actingOnBehalfOfProfile).firstName }}
                          {{ asSystemManager(historicProfile.actingOnBehalfOfProfile).lastName }}
                        </h4>
                        <small matListItemLine class="historical-profile-hierarchy">
                          {{
                            (asSystemManager(historicProfile.actingOnBehalfOfProfile).institutionId | institution$ |
                              async)?.institutionName
                          }}
                        </small>
                      </mat-list-item>
                    </ng-container>

                    <ng-container *ngSwitchCase="eProfileType.Superuser">
                      <mat-list-item (click)="historicalProfileClicked(historicProfile)" class="historical-list-item">
                        <div class="historical-icon-wrapper superuser" matListItemIcon>
                          <div class="historical-icon">G</div>
                        </div>
                        <h4 matListItemLine class="historical-profile-name">
                          {{ asSuperuser(historicProfile.actingOnBehalfOfProfile).firstName }}
                          {{ asSuperuser(historicProfile.actingOnBehalfOfProfile).lastName }}
                        </h4>
                        <small matListItemLine class="historical-profile-hierarchy">
                          RegistrationMax
                        </small>
                      </mat-list-item>
                    </ng-container>

                  </ng-container>
                </ng-container>

              </ng-container>
            </ng-container>
          </ng-container>

          <ng4h-manager-sidebar-ad></ng4h-manager-sidebar-ad>

        </mat-nav-list>
      </ng-container>
    </div>

  </mat-sidenav>
  <mat-sidenav-content style="max-height: 100vh; overflow:unset; padding-top: 80px; background-color: white;">
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>