import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { eDynamicValidatorName, eFormType, IDynamicControl, IDynamicItem, IDynamicOption } from 'app/core/models';
import { DynamicFormService } from 'app/core/services';

import { DynamicValidatorBaseComponent } from '../dynamic-validator-base/dynamic-validator-base.component';

@Component({
  selector: 'ng4h-dynamic-quantity-control-options',
  templateUrl: './dynamic-quantity-control-options.component.html',
  styleUrls: ['./dynamic-quantity-control-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicQuantityControlOptionsComponent extends DynamicValidatorBaseComponent implements OnInit {

  @Input() control: IDynamicControl;
  @Output() controlModified: EventEmitter<Partial<IDynamicControl>>;

  @Input() formType: eFormType;
  @Output() itemModified: EventEmitter<Partial<IDynamicItem>>;

  public eFormType = eFormType;
  constructor(private dynamicFormService: DynamicFormService) {
    super();
    this.controlModified = new EventEmitter();
    this.itemModified = new EventEmitter();
  }

  ngOnInit() {
  }


  changeItemRate(itemId, rate) {
    this.itemModified.emit({ itemId, rate });
  }


  trackByFn(index: number, item: IDynamicOption) {
    return item.optionId;
  }


  public numberAvailableChanged(number: number, itemId: string) {
    this.itemModified.emit({ itemId: itemId, attributes: { quantityAvailable: number } });
    this.validatorMax(number);
  }


  private validatorMax(number) {
    if (number == null) {
      this.validatorRemove.emit({ name: eDynamicValidatorName.Max });
    } else {
      this.validatorAddOrModify.emit({ name: eDynamicValidatorName.Max, arg: number });
    }
  }

  reportLabelChange(reportValue: string, itemId: string) {
    this.itemModified.emit({ itemId, reportValue });
  }

}
