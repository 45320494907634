<div class="modal-header">
    <h5 class="modal-title">Anti-Discrimination Statement</h5>
    <button (click)="close()" type="button" class="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <p>{{(institution$ | async)?.nonDiscriminationDisclosure}}</p>
</div>

<div class="modal-footer">
    <button (click)="close()" class="btn btn-secondary" role="button" aria-pressed="true">Close</button>
</div>