import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AutoUnsubscribe, eBroadcastEmailRecipientType, eHierarchyNodeType } from 'app/core/models';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'ng4h-modal-email-title',
  templateUrl: './modal-email-title.component.html',
  styleUrls: ['./modal-email-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalEmailTitleComponent extends AutoUnsubscribe implements OnInit {

  public eHierarchyNodeType = eHierarchyNodeType;
  public eBroadcastEmailRecipientType = eBroadcastEmailRecipientType;
  public form: FormGroup;
  public optionsForm: FormGroup;

  constructor(private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) private recipientType: eBroadcastEmailRecipientType,
    private fb: FormBuilder) {
    super();
  }

  ngOnInit() {

    this.form = this.fb.group({
      title: [null, Validators.required],
      subject: [null, Validators.required]
    });

    this.form.controls.subject.valueChanges.pipe(takeUntil(this.autoUnsubscribe)).subscribe(newVal => {
      if (newVal?.[0] === ' ') {
        this.form.controls.subject.setValue(newVal.trim());
      }
    });
    this.form.controls.title.valueChanges.pipe(takeUntil(this.autoUnsubscribe)).subscribe(newVal => {
      if (newVal?.[0] === ' ') {
        this.form.controls.title.setValue(newVal.trim());
      }
    });
    this.optionsForm = this.fb.group({
      recipientType: [this.recipientType ? this.recipientType : eBroadcastEmailRecipientType.Family, Validators.required],
      // familyOnly: [false, Validators.required],
      userManagerEmailForReplyTo: [false, Validators.required],
      sendTextMessageNotification: [false, Validators.required],
      // includePrimaryFamily: [false, Validators.required],
      includeAllFamilies: [false, Validators.required],
      includeEmergencyContact: [false, Validators.required],
      includeGuardian: [false, Validators.required],
      includeUnsubscribed: [false, Validators.required],


      managersIncludeRegions: [false, Validators.required],
      managersIncludeDistricts: [false, Validators.required],
      managersIncludeCounties: [false, Validators.required],
      managersIncludeClubs: [false, Validators.required],
    });
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public create(): void {
    this.dialogRef.close({
      ...this.form.value,
      options: this.optionsForm.value
    });
  }
}
