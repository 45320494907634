import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  IDynamicForm,
  IEnrollmentCreateDto,
  IEnrollmentDto,
  IEnrollmentFeeWaiverRequestSubmitDto,
  IEnrollmentProjectSubmissionDto,
  IEnrollmentSubmitDto,
  IEnrollmentUnitAdditionRequestCreateDto,
  IEnrollmentUnitChangeDto,
  IEnrollmentUnitDto,
  IEnrollmentUnitSubmissionDto,
  IEnrollmentUpdateDto,
  IEnrollmentVolunteerTypeSubmissionDto,
  IInstitutionVolunteerDto,
  IMemberConsentSubmitDto,
  IPayableInvoiceDto,
} from 'app/core/models';
import {
  IInstitutionMemberProgramEnrollmentParams,
  IInstitutionMemberProgramParams,
} from 'app/core/models/function-parameters';

export const EnrollmentLoadAvailableVolunteerTypesAction = createAction(
  '[enrollment] Load volunteer types',
  props<IInstitutionMemberProgramEnrollmentParams>()
);
export const EnrollmentLoadAvailableVolunteerTypesSuccessAction = createAction(
  '[enrollment] Load volunteer types success',
  props<IInstitutionMemberProgramEnrollmentParams & { volunteerTypes: IInstitutionVolunteerDto[] }>()
);
export const EnrollmentLoadAvailableVolunteerTypesErrorAction = createAction(
  '[enrollment] Load volunteer types error',
  props<{ error: HttpErrorResponse }>()
);

export const EnrollmentAddVolunteerTypesAction = createAction(
  '[enrollment] Add volunteer types',
  props<IInstitutionMemberProgramEnrollmentParams & { volunteerType: IInstitutionVolunteerDto }>()
);
export const EnrollmentRemoveVolunteerTypesAction = createAction(
  '[enrollment] Remove volunteer types',
  props<IInstitutionMemberProgramEnrollmentParams & { volunteerType: IInstitutionVolunteerDto }>()
);

export const EnrollmentSaveVolunteerTypesAction = createAction(
  '[enrollment] Save volunteer types',
  props<IInstitutionMemberProgramEnrollmentParams & { volunteerTypeSubmission: IEnrollmentVolunteerTypeSubmissionDto[] }>()
);
export const EnrollmentSaveVolunteerTypesSuccessAction = createAction(
  '[enrollment] Save volunteer types success',
  props<IInstitutionMemberProgramEnrollmentParams & { volunteerTypeSubmission: IEnrollmentVolunteerTypeSubmissionDto[] }>()
);
export const EnrollmentSaveVolunteerTypesErrorAction = createAction(
  '[enrollment] Save volunteer types error',
  props<{ error: HttpErrorResponse }>()
);

export const EnrollmentAddClubAction = createAction(
  '[enrollment] Add club',
  props<IInstitutionMemberProgramEnrollmentParams & { club: IEnrollmentUnitDto }>()
);
export const EnrollmentRemoveClubAction = createAction(
  '[enrollment] Remove club',
  props<IInstitutionMemberProgramEnrollmentParams & { unitId: string }>()
);
// export const EnrollmentAddProjectAction = createAction(
//   '[enrollment] Add project',
//   props<IInstitutionMemberProgramEnrollmentParams & { enrollmentProject: IEnrollmentProjectDto }>()
// );
// export const EnrollmentRemoveProjectAction = createAction(
//   '[enrollment] Remove project',
//   props<IInstitutionMemberProgramEnrollmentParams & { projectId: string }>()
// );
export const EnrollmentResetProjectsAction = createAction(
  '[enrollment] Reset Projects'
);
export const EnrollmentChangePrimaryClubAction = createAction(
  '[enrollment] Change primary club',
  props<IInstitutionMemberProgramEnrollmentParams & { club: IEnrollmentUnitDto }>()
);
export const EnrollmentCreateAction = createAction(
  '[enrollment] Create',
  props<IInstitutionMemberProgramParams & IEnrollmentCreateDto>()
);
export const EnrollmentCreateSuccessAction = createAction(
  '[enrollment] Create success',
  props<IInstitutionMemberProgramParams & IEnrollmentCreateDto & { enrollment: IEnrollmentDto }>()
);
export const EnrollmentCreateErrorAction = createAction(
  '[enrollment] Create error',
  props<{ error: HttpErrorResponse }>()
);
export const EnrollmentSaveClubsAction = createAction(
  '[enrollment] Save clubs',
  props<{ institutionId: string, memberId: string, programId: string, enrollmentId: string, clubSubmission: IEnrollmentUnitSubmissionDto[] }>()
);
export const EnrollmentSaveClubsSuccessAction = createAction(
  '[enrollment] Save clubs success',
  props<{ institutionId: string, memberId: string, programId: string, enrollmentId: string }>()
);
export const EnrollmentSaveClubsErrorAction = createAction(
  '[enrollment] Save clubs error',
  props<{ error: HttpErrorResponse }>()
);
export const EnrollmentSaveProjectAction = createAction(
  '[enrollment] Save projects',
  props<{ institutionId: string, memberId: string, programId: string, enrollmentId: string, projectSubmission: IEnrollmentProjectSubmissionDto }>()
);
export const EnrollmentSaveProjectSuccessAction = createAction(
  '[enrollment] Save projects success'
);
export const EnrollmentSaveProjectErrorAction = createAction(
  '[enrollment] Save projects error',
  props<{ error: HttpErrorResponse }>()
);
export const EnrollmentDeleteProjectAction = createAction(
  '[enrollment] Delete projects',
  props<IInstitutionMemberProgramEnrollmentParams & { projectId: string }>()
);
export const EnrollmentDeleteProjectSuccessAction = createAction(
  '[enrollment] Delete projects success',
  props<IInstitutionMemberProgramEnrollmentParams & { projectId: string }>()
);
export const EnrollmentDeleteProjectErrorAction = createAction(
  '[enrollment] Delete projects error',
  props<{ error: HttpErrorResponse }>()
);
export const EnrollmentLoadAction = createAction(
  '[enrollment] Load',
  props<IInstitutionMemberProgramEnrollmentParams>()
);
export const EnrollmentLoadSuccessAction = createAction(
  '[enrollment] Load success',
  props<IInstitutionMemberProgramEnrollmentParams & { enrollment: IEnrollmentDto }>()
);
export const EnrollmentLoadErrorAction = createAction(
  '[enrollment] Load error',
  props<{ error: HttpErrorResponse }>()
);
export const EnrollmentLoadDynamicFormAction = createAction(
  '[enrollment] Load dynamic inputs',
  props<IInstitutionMemberProgramEnrollmentParams>()
);
export const EnrollmentLoadDynamicFormSuccessAction = createAction(
  '[enrollment] Load dynamic inputs success',
  props<IInstitutionMemberProgramEnrollmentParams & { dynamicForm: IDynamicForm }>()
);
export const EnrollmentLoadDynamicFormErrorAction = createAction(
  '[enrollment] Load dynamic inputs error',
  props<{ error: HttpErrorResponse }>()
);
export const EnrollmentSaveDynamicFormResponsesAction = createAction(
  '[enrollment] Dynamic save responses',
  props<IInstitutionMemberProgramEnrollmentParams & { responses: any }>()
);

export const EnrollmentSaveDynamicFormResponsesSuccessAction = createAction(
  '[enrollment] Dynamic save responses success',
  props<IInstitutionMemberProgramEnrollmentParams & { responses: any }>()
);
export const EnrollmentSaveDynamicFormResponsesErrorAction = createAction(
  '[enrollment] Dynamic save responses error',
  props<{ error: HttpErrorResponse }>()
);
export const EnrollmentSaveDemographicsFormResponsesAction = createAction(
  '[enrollment] Dynamic save responses',
  props<IInstitutionMemberProgramEnrollmentParams & { responses: any }>()
);
export const EnrollmentSaveDemographicsFormResponsesSuccessAction = createAction(
  '[enrollment] Dynamic save responses success',
  props<IInstitutionMemberProgramEnrollmentParams & { responses: any }>()
);
export const EnrollmentSaveDemographicsFormResponsesErrorAction = createAction(
  '[enrollment] Dynamic save responses error',
  props<{ error: HttpErrorResponse }>()
);
export const EnrollmentSaveDemographicsAction = createAction(
  '[enrollment] Save demographics',
  props<IInstitutionMemberProgramEnrollmentParams & { enrollmentUpdate: IEnrollmentUpdateDto }>()
);
export const EnrollmentSaveDemographicsSuccessAction = createAction(
  '[enrollment] Save demographics success',
  props<IInstitutionMemberProgramEnrollmentParams & { enrollmentUpdate: IEnrollmentUpdateDto }>()
);
export const EnrollmentSaveDemographicsErrorAction = createAction(
  '[enrollment] Save demographics error',
  props<{ error: HttpErrorResponse }>()
);
export const EnrollmentSaveConsentsAction = createAction(
  '[enrollment] Save consent',
  props<IInstitutionMemberProgramEnrollmentParams & { consent: IMemberConsentSubmitDto[] }>()
);
export const EnrollmentSaveConsentsSuccessAction = createAction(
  '[enrollment] Save consent success',
  props<IInstitutionMemberProgramEnrollmentParams & { consent: IMemberConsentSubmitDto[] }>()
);
export const EnrollmentSaveConsentsErrorAction = createAction(
  '[enrollment] Save consent error',
  props<{ error: HttpErrorResponse }>()
);
export const EnrollmentLoadPayableInvoiceAction = createAction(
  '[enrollment] Load payable invoice',
  props<IInstitutionMemberProgramEnrollmentParams>()
);
export const EnrollmentLoadPayableInvoiceSuccessAction = createAction(
  '[enrollment] Load payable invoice success',
  props<IInstitutionMemberProgramEnrollmentParams & { invoice: IPayableInvoiceDto }>()
);
export const EnrollmentLoadPayableInvoiceErrorAction = createAction(
  '[enrollment] Load payable invoice error',
  props<{ error: HttpErrorResponse }>()
);
export const EnrollmentApplyPaymentAction = createAction(
  '[enrollment] Apply payment',
  props<IInstitutionMemberProgramEnrollmentParams & { invoiceId: string, paymentMethodId: string, memberProgramConsentSubmitDto: IMemberConsentSubmitDto }>()
);
export const EnrollmentApplyPaymentSuccessAction = createAction(
  '[enrollment] Apply payment success',
  props<IInstitutionMemberProgramEnrollmentParams & { invoiceId: string, paymentMethodId: string, memberProgramConsentSubmitDto: IMemberConsentSubmitDto }>()
);
export const EnrollmentApplyPaymentErrorAction = createAction(
  '[enrollment] Apply payment error',
  props<{ error: HttpErrorResponse }>()
);

export const EnrollmentApplyAdditionalPaymentAction = createAction(
  '[enrollment] Apply additional payment',
  props<IInstitutionMemberProgramEnrollmentParams & { invoiceId: string, paymentMethodId: string, paymentConsentResponse: IMemberConsentSubmitDto }>()
);
export const EnrollmentApplyAdditionalPaymentSuccessAction = createAction(
  '[enrollment] Apply additional payment success',
  props<IInstitutionMemberProgramEnrollmentParams & { invoiceId: string, paymentMethodId: string, paymentConsentResponse: IMemberConsentSubmitDto }>()
);
export const EnrollmentApplyAdditionalPaymentErrorAction = createAction(
  '[enrollment] Apply additional payment error',
  props<{ error: HttpErrorResponse }>()
);

export const EnrollmentApplyCouponCodeAction = createAction(
  '[enrollment] Apply coupon code',
  props<IInstitutionMemberProgramEnrollmentParams & { couponCode: string }>()
);
export const EnrollmentApplyCouponCodeSuccessAction = createAction(
  '[enrollment] Apply coupon code success',
  props<IInstitutionMemberProgramEnrollmentParams & { couponCode: string }>()
);
export const EnrollmentApplyCouponCodeErrorAction = createAction(
  '[enrollment] Apply coupon code error',
  props<{ error: HttpErrorResponse }>()
);

export const EnrollmentRequestFeeWaiverAction = createAction(
  '[enrollment] Request fee waiver',
  props<IInstitutionMemberProgramEnrollmentParams & { submitDto: IEnrollmentFeeWaiverRequestSubmitDto }>()
);
export const EnrollmentRequestFeeWaiverSuccessAction = createAction(
  '[enrollment] Request fee waiver success',
  props<IInstitutionMemberProgramEnrollmentParams & { submitDto: IEnrollmentFeeWaiverRequestSubmitDto }>()
);
export const EnrollmentRequestFeeWaiverErrorAction = createAction(
  '[enrollment] Request fee waiver error',
  props<{ error: HttpErrorResponse }>()
);

export const EnrollmentApplyCreditAction = createAction(
  '[enrollment] Apply credit',
  props<IInstitutionMemberProgramEnrollmentParams & { consent: IMemberConsentSubmitDto }>()
);
export const EnrollmentApplyCreditSuccessAction = createAction(
  '[enrollment] Apply credit success',
  props<IInstitutionMemberProgramEnrollmentParams & { consent: IMemberConsentSubmitDto }>()
);
export const EnrollmentApplyCreditErrorAction = createAction(
  '[enrollment] Apply credit error',
  props<{ error: HttpErrorResponse }>()
);

export const EnrollmentSubmitAction = createAction(
  '[enrollment] Submit',
  props<IInstitutionMemberProgramEnrollmentParams & { submitDto: IEnrollmentSubmitDto }>()
);
export const EnrollmentSubmitSuccessAction = createAction(
  '[enrollment] Submit success',
  props<IInstitutionMemberProgramEnrollmentParams & { submitDto: IEnrollmentSubmitDto }>()
);
export const EnrollmentSubmitErrorAction = createAction(
  '[enrollment] Submit error',
  props<{ error: HttpErrorResponse }>()
);
export const EnrollmentUploadFileAction = createAction(
  '[enrollment] Upload file',
  props<IInstitutionMemberProgramEnrollmentParams & { fileControlId: string, formData: FormData }>()
);
export const EnrollmentUploadFileSuccessAction = createAction(
  '[enrollment] Upload file success',
  props<IInstitutionMemberProgramEnrollmentParams & { fileControlId: string, formData: FormData }>()
);
export const EnrollmentUploadFileErrorAction = createAction(
  '[enrollment] Upload file error',
  props<{ error: HttpErrorResponse }>()
);
export const EnrollmentDeleteFileAction = createAction(
  '[enrollment] Delete file',
  props<IInstitutionMemberProgramEnrollmentParams & { fileControlId: string }>()
);
export const EnrollmentDeleteFileSuccessAction = createAction(
  '[enrollment] Delete file success',
  props<IInstitutionMemberProgramEnrollmentParams & { fileControlId: string }>()
);
export const EnrollmentDeleteFileErrorAction = createAction(
  '[enrollment] Delete file error',
  props<{ error: HttpErrorResponse }>()
);
export const EnrollmentInvalidateCacheAction = createAction(
  '[enrollment] Invalidate cache'
);
export const EnrollmentInvalidateDynamicFormsCacheAction = createAction(
  '[enrollment] Invalidate dynamic forms cache'
);
export const EnrollmentLoadCustomFileAction = createAction(
  '[enrollment] load custom files',
  props<IInstitutionMemberProgramEnrollmentParams>()
);
export const EnrollmentLoadCustomFileSuccessAction = createAction(
  '[enrollment] load custom files success',
  props<IInstitutionMemberProgramEnrollmentParams & { customFiles: any[] }>()
);
export const EnrollmentLoadCustomFileErrorAction = createAction(
  '[enrollment] load custom files error',
  props<{ error: HttpErrorResponse }>()
);
export const EnrollmentInvalidateCustomFilesCache = createAction(
  '[enrollment] Invalidate custom files'
);


export const DeleteEnrollmentAction = createAction(
  '[manager-enrollment] Delete enrollment',
  props<IInstitutionMemberProgramEnrollmentParams>()
);
export const DeleteEnrollmentSuccessAction = createAction(
  '[manager-enrollment] Delete enrollment success',
  props<IInstitutionMemberProgramEnrollmentParams>()
);
export const DeleteEnrollmentErrorAction = createAction(
  '[manager-enrollment] Delete enrollment error',
  props<{ error: HttpErrorResponse }>()
);

export const SwapUnitsAction = createAction(
  '[enrollment] Swap units',
  props<IInstitutionMemberProgramEnrollmentParams & { unitChange: IEnrollmentUnitChangeDto }>()
);
export const SwapUnitsSuccessAction = createAction(
  '[enrollment] Swap units success',
  props<IInstitutionMemberProgramEnrollmentParams & { unitChange: IEnrollmentUnitChangeDto }>()
);
export const SwapUnitsErrorAction = createAction(
  '[enrollment] Swap units error',
  props<{ error: HttpErrorResponse }>()
);

export const RequestAddUnitAction = createAction(
  '[enrollment] Request add unit',
  props<IInstitutionMemberProgramEnrollmentParams & { unitRequest: IEnrollmentUnitAdditionRequestCreateDto }>()
);
export const RequestAddUnitSuccessAction = createAction(
  '[enrollment] Request add unit success',
  props<IInstitutionMemberProgramEnrollmentParams & { unitRequest: IEnrollmentUnitAdditionRequestCreateDto }>()
);
export const RequestAddUnitErrorAction = createAction(
  '[enrollment] Request add unit error',
  props<{ error: HttpErrorResponse }>()
);