import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import * as reportConsumer from './report-consumer.reducer';

export const REPORT_CONSUMER_FEATURE = 'REPORT_CONSUMER_FEATURE'

export interface ReportConsumerStore {
    reportConsumer: reportConsumer.ReportConsumerState
}

export const reportConsumerReducers: ActionReducerMap<ReportConsumerStore> = {
    reportConsumer: reportConsumer.reportConsumerReducers
}

export const getReportConsumerFeatureStore = createFeatureSelector<ReportConsumerStore>(REPORT_CONSUMER_FEATURE);
export const getReportConsumerState = createSelector(getReportConsumerFeatureStore, (state: ReportConsumerStore) => state?.reportConsumer)

export const getReportConsumer = ({ institutionId, reportConsumerId }) => createSelector(
    createSelector(getReportConsumerState, reportConsumer.reportConsumer),
    reportConsumer => reportConsumer?.[institutionId]?.[reportConsumerId]
);
export const getReportConsumers = ({ superuserId, institutionId }) => createSelector(
    createSelector(getReportConsumerState, reportConsumer.reportConsumers),
    reportConsumers => reportConsumers?.[superuserId]?.[institutionId]
);