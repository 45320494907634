import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ClubsService } from 'app/core/services';
import { catchError, exhaustMap, groupBy, map, mergeMap, of } from 'rxjs';

import { MemberUnitActions } from '.';
import { stringKeys } from '../rxjs.utils';

@Injectable()
export class UnitEffects {

  loadClubs = createEffect(() => this.actions
    .pipe(
      ofType(MemberUnitActions.UnitsLoadAction),
      groupBy(stringKeys),
      mergeMap(groupedBy => {
        return groupedBy.pipe(
          exhaustMap(payload => {
            return this.clubsService.loadClubsEffect(payload).pipe(
              // groups will be null if api returns with 204 No Content
              map(clubs => clubs == null ? [] : clubs),
              map(clubs => MemberUnitActions.UnitsLoadSuccessAction({ ...payload, units: clubs })),
              catchError(error => of(MemberUnitActions.UnitsLoadErrorAction({ error })))
            );
          })
        );
      })
    ));

  loadClubMembers = createEffect(() => this.actions
    .pipe(
      ofType(MemberUnitActions.UnitLeaderLoadMembersAction),
      groupBy(stringKeys),
      mergeMap(groupedBy => {
        return groupedBy.pipe(
          exhaustMap(payload => {
            return this.clubsService.clubLeaderGetMembersEffect(payload).pipe(
              // groups will be null if api returns with 204 No Content
              map(members => members == null ? [] : members),
              map(members => MemberUnitActions.UnitLeaderLoadMembersSuccessAction({ ...payload, members })),
              catchError(error => of(MemberUnitActions.UnitLeaderLoadMembersErrorAction({ error })))
            );
          })
        );
      })
    ));

  constructor(private actions: Actions, private clubsService: ClubsService) { }
}
