<div class="container ">
    <div [hidden]="token == null || loading == true">
        <form #authorizeForm method="post" [action]="gatewayConfig.tokenizationRequestSubmissionUrl" target="AuthorizeNetIFrame">
            <input type="hidden" name="token" [value]="token" />
        </form>
        <iframe id="AuthorizeNetIFrame" name="AuthorizeNetIFrame" style="border: none; width: 100%; height: 900px;" (load)="onLoad()">
        </iframe>
    </div>
    <div *ngIf="loading">
        <mat-spinner></mat-spinner>
    </div>
</div>