import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { eHierarchyNodeType, IDynamicControl } from 'app/core/models';
import { eDynamicValidatorName } from 'app/core/models/dynamic-forms/eDynamicValidatorName';
import { debounceTime, Subject } from 'rxjs';

import { DynamicValidatorBaseComponent } from '../dynamic-validator-base/dynamic-validator-base.component';

@Component({
  selector: 'ng4h-dynamic-options-root',
  templateUrl: './dynamic-options-root.component.html',
  styleUrls: ['./dynamic-options-root.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicOptionsRootComponent extends DynamicValidatorBaseComponent implements OnInit, OnChanges {

  @Input() showRequired = true;
  @Input() showReportLabel = true;

  @Output() rootInputModified: EventEmitter<Partial<IDynamicControl>>;

  private rootInputModifiedDebounce: Subject<Partial<IDynamicControl>> = new Subject();

  public required: boolean;

  constructor() {
    super();
    this.rootInputModified = new EventEmitter();
    this.rootInputModifiedDebounce.pipe(debounceTime(100)).subscribe(control => {
      this.rootInputModified.emit(control);
    });
  }

  ngOnInit() { }

  ngOnChanges(changes) {
    if (changes.control.currentValue.validators !== undefined) {
      this.required = changes.control.currentValue.attributes.required;
    } else {
      this.required = false;
    }
  }

  labelChange(value: string) {
    this.rootInputModifiedDebounce.next({ label: value });
  }

  widthChange(value: number) {
    this.rootInputModified.emit({ attributes: { class: 'col-' + value } });
  }

  archivedChange(value: boolean) {
    this.rootInputModified.emit({ archived: value });
  }

  displayToChange(checked: boolean, value: number) {
    const list = [...this.control.displayTo];
    if (checked) {
      if (list.indexOf(value) < 0) {
        list.push(value);
      }
    } else {
      const indexOf = list.indexOf(value);
      list.splice(indexOf, 1);
    }
    this.rootInputModified.emit({ displayTo: list });
  }

  public logicRequiredChanged(required: boolean) {
    if (required) {
      this.validatorAddOrModify.emit({ name: eDynamicValidatorName.Required, arg: true });
    } else {
      this.validatorRemove.emit({ name: eDynamicValidatorName.Required });
    }
    this.rootInputModified.emit({ attributes: { required: required } });
  }

  public managerOnly(checked: boolean) {
    if (checked) {
      this.rootInputModified.emit({ managerField: checked });
    } else {
      this.rootInputModified.emit({
        managerField: checked,
        managerField_MinimumViewHierarchyType: null,
        managerField_MinimumEditHierarchyType: null
      });
    }
  }

  public managerMinView(type: eHierarchyNodeType) {
    this.rootInputModified.emit({ managerField_MinimumViewHierarchyType: type });
  }

  public managerMinEdit(type: eHierarchyNodeType) {
    this.rootInputModified.emit({ managerField_MinimumEditHierarchyType: type });
  }

  public reportLabelChange(reportValue: string) {
    this.rootInputModified.emit({ reportValue });
  }
}
