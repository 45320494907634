import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { AppState } from 'app/app.reducers';
import {
  getBatchScreenings,
  getScreeningBatch,
  getScreeningsAvailableForBatch,
  getScreeningSearchSearching,
  getScreeningsSearchResult,
  getSubmittedScreenings
} from 'app/core/containers/admin/manager/manager-feature.reducer';
import { ManagerScreeningsActions } from 'app/core/containers/admin/manager/store/screenings';
import {
  IBatchProfileScreeningDto,
  IInstitutionManagerProgramHierarchyParams,
  IInstitutionManagerProgramParams,
  IMemberSearchResultsDto,
  IPendingScreeningDto,
  IProfileScreeningUpdateDto,
  IProfileScreeningUpdateTypeDto,
  IProfileVolunteerScreeningDto,
  IScreeningBatchDto
} from 'app/core/models';
import { IScreeningSearchParametersDto } from 'app/core/models/serverDTOs/IScreeningSearchParametersDto';
import { anyNull, downloadFile, downloadFileWithBody } from 'app/shared/utils';
import { filter, map, Observable, of, switchMap, take, tap } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ManagerScreeningsService {

  public screeningsSearchResult$: Observable<IMemberSearchResultsDto>;
  public screeningSearchSearching$: Observable<boolean>;

  constructor(private httpClient: HttpClient, private store: Store<AppState>, private dispatcher: ActionsSubject) {
    this.screeningsSearchResult$ = this.store.pipe(select(getScreeningsSearchResult));
    this.screeningSearchSearching$ = this.store.pipe(select(getScreeningSearchSearching));
  }

  public getManagerScreeningBatch(params: IInstitutionManagerProgramParams): Observable<IScreeningBatchDto[]> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getScreeningBatch(params)),
      tap(batch => {
        if (batch === undefined) {
          this.store.dispatch(ManagerScreeningsActions.ManagerGetBatchesAction(params));
        }
      }),
      filter(batch => batch != null)
    );
  }

  public loadManagerScreeningsBatchEffect({
    institutionId,
    managerId,
    programId
  }: IInstitutionManagerProgramParams): Observable<IScreeningBatchDto[]> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/volunteer-screenings/batches`) as Observable<IScreeningBatchDto[]>;
  }

  public getScreeningsAvailableForBatch(params: IInstitutionManagerProgramParams): Observable<IPendingScreeningDto[]> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getScreeningsAvailableForBatch(params)),
      tap(screenings => {
        if (screenings === undefined) {
          this.store.dispatch(ManagerScreeningsActions.ManageGetScreeningsAvailableForBatchAction(params));
        }
      }),
      filter(screenings => screenings != null)
    );
  }

  public loadScreeningsAvailableForBatchEffect({
    institutionId,
    managerId,
    programId
  }: IInstitutionManagerProgramParams) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/volunteer-screenings/available-for-batch`) as Observable<IPendingScreeningDto[]>;
  }

  public getSubmittedScreenings(params: IInstitutionManagerProgramHierarchyParams): Observable<IProfileVolunteerScreeningDto[]> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getSubmittedScreenings(params)),
      tap(screenings => {
        if (screenings === undefined) {
          this.store.dispatch(ManagerScreeningsActions.ManageGetSubmittedScreeningsAction(params));
        }
      }),
      filter(screenings => screenings != null)
    );
  }

  public loadSubmittedScreeningsEffect({
    institutionId,
    managerId,
    programId,
    hierarchyNodeId,
    pagesize,
    pageindex
  }: IInstitutionManagerProgramHierarchyParams & { pagesize: string, pageindex: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/${hierarchyNodeId}/volunteer-screenings/submitted?pagesize=${pagesize}&pageindex=${pageindex}`) as Observable<IProfileVolunteerScreeningDto[]>;
  }

  // TODO: remove any when post type is known
  public updateScreeningBatchEffect({
    institutionId,
    managerId,
    programId,
    batch
  }: IInstitutionManagerProgramParams & { batch: any }) {
    return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/volunteer-screenings/batches`, batch);
  }

  public createScreeningBatch(params: IInstitutionManagerProgramParams & { screenings: string[] }) {
    this.store.dispatch(ManagerScreeningsActions.ManagerCreateScreeningsBatchAction(params));

    return this.dispatcher.pipe(
      ofType(ManagerScreeningsActions.ManagerCreateScreeningsBatchSuccessAction, ManagerScreeningsActions.ManagerCreateScreeningsBatchErrorAction),
      take(1),
      map(action => {
        if (action.type === ManagerScreeningsActions.ManagerCreateScreeningsBatchSuccessAction.type) {
          return action.screenings;
        } else {
          throw action.error;
        }
      })
    );
  }

  public createScreeningBatchEffect({
    institutionId,
    managerId,
    programId,
    screenings
  }: IInstitutionManagerProgramParams & { screenings: string[] }) {
    return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/volunteer-screenings/batches`, screenings);
  }

  public getBatchScreenings(params: IInstitutionManagerProgramParams & { profileScreeningBatchId: string }): Observable<IProfileVolunteerScreeningDto[]> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getBatchScreenings(params)),
      tap(screenings => {
        if (screenings === undefined) {
          this.store.dispatch(ManagerScreeningsActions.ManagerGetBatchScreeningsAction(params));
        }
      }),
      filter(screenings => screenings != null)
    );
  }

  public loadBatchScreeningsEffect({
    institutionId,
    managerId,
    programId,
    profileScreeningBatchId
  }: IInstitutionManagerProgramParams & { profileScreeningBatchId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/volunteer-screenings/batches/${profileScreeningBatchId}`) as Observable<IProfileVolunteerScreeningDto[]>;
  }

  public updateScreeningsStatus(params: IInstitutionManagerProgramParams & { screenings: IBatchProfileScreeningDto[] }) {
    this.store.dispatch(ManagerScreeningsActions.ManagerScreeningsUpdateScreeningsStatusAction(params));

    return this.dispatcher.pipe(
      ofType(ManagerScreeningsActions.ManagerScreeningsUpdateScreeningsStatusSuccessAction, ManagerScreeningsActions.ManagerScreeningsUpdateScreeningsStatusErrorAction),
      take(1),
      map(action => {
        if (action.type === ManagerScreeningsActions.ManagerScreeningsUpdateScreeningsStatusSuccessAction.type) {
          return action.screenings;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateScreeningsStatusEffect({
    institutionId,
    managerId,
    programId,
    screenings
  }: IInstitutionManagerProgramParams & { screenings: IBatchProfileScreeningDto[] }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/volunteer-screenings`, screenings);
  }

  public updateScreeningStatus(params: IInstitutionManagerProgramParams & { profileScreeningId: string, update: IProfileScreeningUpdateDto }) {
    this.store.dispatch(ManagerScreeningsActions.ManagerScreeningsUpdateScreeningStatusAction(params));

    return this.dispatcher.pipe(
      ofType(ManagerScreeningsActions.ManagerScreeningsUpdateScreeningStatusSuccessAction, ManagerScreeningsActions.ManagerScreeningsUpdateScreeningStatusErrorAction),
      take(1),
      map(action => {
        if (action.type === ManagerScreeningsActions.ManagerScreeningsUpdateScreeningStatusSuccessAction.type) {
          return action.update;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateScreeningStatusEffect({
    institutionId,
    managerId,
    programId,
    profileScreeningId,
    update
  }: IInstitutionManagerProgramParams & { profileScreeningId: string, update: IProfileScreeningUpdateDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/volunteer-screenings/${profileScreeningId}`, update);
  }

  public getScreeningSearchResults({
    institutionId,
    managerId,
    programId,
    hierarchyNodeId,
    pageSize,
    pageIndex
  }: IInstitutionManagerProgramHierarchyParams & { pageSize: string, pageIndex: string }) {
    if (anyNull(institutionId, managerId, programId, hierarchyNodeId)) {
      return;
    }
    this.store.dispatch(ManagerScreeningsActions.GetScreeningSearchResultsAction({
      institutionId,
      managerId,
      programId,
      hierarchyNodeId,
      pageSize,
      pageIndex
    }));
  }

  public getScreeningSearchResultsEffect({
    institutionId,
    managerId,
    programId,
    hierarchyNodeId,
    pageSize,
    pageIndex
  }: IInstitutionManagerProgramHierarchyParams & { pageSize: string, pageIndex: string }): Observable<IMemberSearchResultsDto> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/${hierarchyNodeId}/search-screenings?pagesize=${pageSize}&pageindex=${pageIndex}`) as Observable<IMemberSearchResultsDto>;
  }

  public searchScreenings(params: IInstitutionManagerProgramHierarchyParams & { searchParameters: IScreeningSearchParametersDto }) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    this.store.dispatch(ManagerScreeningsActions.SearchScreeningsAction(params));
  }

  public searchScreeningsEffect({
    institutionId,
    managerId,
    programId,
    hierarchyNodeId,
    searchParameters
  }: IInstitutionManagerProgramHierarchyParams & { searchParameters: IScreeningSearchParametersDto }): Observable<IMemberSearchResultsDto> {
    return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/${hierarchyNodeId}/search-screenings`, searchParameters, { observe: 'response' }).pipe(
      switchMap((res: HttpResponse<any>) => this.httpClient.get(res.headers.get('location')))
    ) as Observable<IMemberSearchResultsDto>;
  }

  public clearScreeningSearch(params: IInstitutionManagerProgramHierarchyParams) {
    this.store.dispatch(ManagerScreeningsActions.ClearScreeningSearchAction(params));
  }

  public clearScreeningSearchEffect({
    institutionId,
    managerId,
    programId,
    hierarchyNodeId
  }: IInstitutionManagerProgramHierarchyParams) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/${hierarchyNodeId}/search-screenings`);
  }

  public downloadExcel({
    institutionId,
    managerId,
    programId,
    hierarchyNodeId
  }: IInstitutionManagerProgramHierarchyParams) {
    downloadFileWithBody(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/${hierarchyNodeId}/search-screenings/excel`, {});
  }

  public downloadFlagged({ institutionId, managerId, programId }: IInstitutionManagerProgramParams) {
    downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/quick-exports/screening-flagged`);
  }

  public downloadFullBatch({
    institutionId,
    managerId,
    programId,
    profileScreeningBatchId
  }: IInstitutionManagerProgramParams & { profileScreeningBatchId: string }) {
    downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/quick-exports/screening-batches/${profileScreeningBatchId}/full`);
  }

  public downloadBatchEffect({
    institutionId,
    managerId,
    programId,
    profileScreeningBatchId
  }: IInstitutionManagerProgramParams & { profileScreeningBatchId: string }) {
    downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/quick-exports/screening-batches/${profileScreeningBatchId}`);
  }

  public changeScreeningLevel(params: IInstitutionManagerProgramParams & { profileScreeningId: string, update: IProfileScreeningUpdateTypeDto }) {
    this.store.dispatch(ManagerScreeningsActions.ManagerScreeningsChangeScreeningLevelAction(params));

    return this.dispatcher.pipe(
      ofType(ManagerScreeningsActions.ManagerScreeningsChangeScreeningLevelSuccessAction, ManagerScreeningsActions.ManagerScreeningsChangeScreeningLevelErrorAction),
      take(1),
      map(action => {
        if (action.type === ManagerScreeningsActions.ManagerScreeningsChangeScreeningLevelSuccessAction.type) {
          return action.update;
        } else {
          throw action.error;
        }
      })
    );
  }

  public changeScreeningLevelEffect({
    institutionId,
    managerId,
    programId,
    profileScreeningId,
    update
  }: IInstitutionManagerProgramParams & { profileScreeningId: string, update: IProfileScreeningUpdateTypeDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/volunteer-screenings/${profileScreeningId}/screening-type`, update);
  }

  public sendBackScreening(params: IInstitutionManagerProgramParams & { profileScreeningId: string, update: IProfileScreeningUpdateDto }) {
    this.store.dispatch(ManagerScreeningsActions.SendBackScreeningAction(params));

    return this.dispatcher.pipe(
      ofType(ManagerScreeningsActions.SendBackScreeningSuccessAction, ManagerScreeningsActions.SendBackScreeningErrorAction),
      take(1),
      map(action => {
        if (action.type === ManagerScreeningsActions.SendBackScreeningSuccessAction.type) {
          return action.update;
        } else {
          throw action.error;
        }
      })
    );
  }

  public sendBackScreeningEffect({
    institutionId,
    managerId,
    programId,
    profileScreeningId,
    update
  }: IInstitutionManagerProgramParams & { profileScreeningId: string, update: IProfileScreeningUpdateDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/volunteer-screenings/${profileScreeningId}/send-back`, update);
  }

  public deleteScreening(params: IInstitutionManagerProgramParams & { profileScreeningId: string }) {
    this.store.dispatch(ManagerScreeningsActions.DeleteScreeningAction(params));

    return this.dispatcher.pipe(
      ofType(ManagerScreeningsActions.DeleteScreeningSuccessAction, ManagerScreeningsActions.DeleteScreeningErrorAction),
      take(1),
      map(action => {
        if (action.type === ManagerScreeningsActions.DeleteScreeningSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteScreeningEffect({
    institutionId,
    managerId,
    programId,
    profileScreeningId
  }: IInstitutionManagerProgramParams & { profileScreeningId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/volunteer-screenings/${profileScreeningId}`);
  }
}

