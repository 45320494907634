import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AdminPageActionsComponent } from './admin-page-actions/admin-page-actions.component';
import { AdminPageContentComponent } from './admin-page-content/admin-page-content.component';
import { AdminPageHeaderComponent } from './admin-page-header/admin-page-header.component';
import { AdminPageComponent } from './admin-page.component';
import { AdminPageIconComponent } from './admin-page-icon/admin-page-icon.component';

const components = [AdminPageComponent, AdminPageHeaderComponent, AdminPageActionsComponent, AdminPageContentComponent, AdminPageIconComponent];
@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [...components],
  exports: [...components],
})
export class AdminPageModule { }
