import { ePaymentGatewayType } from '..';
import { IAddressDto } from './IAddressDto';

export interface ITokenizedCardDto {
  gateway: ePaymentGatewayType;
  cardBrand?: string;
  vaultTokenRegistrationId?: string;
  cardholderName?: string;
  expirationMonth: number;
  expirationYear: number;
  address: IAddressDto;
}
