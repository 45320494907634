import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  IFairEntryIntegrationDto,
  IInstitutionManagerProgramHierarchyParams,
  IInstitutionManagerProgramParams,
  IManagerCommentDto,
} from 'app/core/models';

export const ManagerFairEntryGetIntegrationsAction = createAction(
    '[manager-fair-entry] Get integrations',
    props<IInstitutionManagerProgramHierarchyParams>()
);
export const ManagerFairEntryGetIntegrationsSuccessAction = createAction(
    '[manager-fair-entry] Get integrations success',
    props<IInstitutionManagerProgramHierarchyParams & { fairIntegrations: IFairEntryIntegrationDto[] }>()
);
export const ManagerFairEntryGetIntegrationsErrorAction = createAction(
    '[manager-fair-entry] Get integrations error',
    props<{ error: HttpErrorResponse }>()
);

export const ManagerFairEntryGetPendingIntegrationsAction = createAction(
    '[manager-fair-entry] Get pending integrations',
    props<IInstitutionManagerProgramHierarchyParams>()
);
export const ManagerFairEntryGetPendingIntegrationsSuccessAction = createAction(
    '[manager-fair-entry] Get pending integrations success',
    props<IInstitutionManagerProgramHierarchyParams & { fairIntegrations: IFairEntryIntegrationDto[] }>()
);
export const ManagerFairEntryGetPendingIntegrationsErrorAction = createAction(
    '[manager-fair-entry] Get pending integrations error',
    props<{ error: HttpErrorResponse }>()
);


export const ManagerFairEntryAcceptIntegrationAction = createAction(
    '[manager-fair-entry] Accept integration',
    props<IInstitutionManagerProgramParams & { fairEntryIntegrationId: string, managerComment: IManagerCommentDto }>()
);
export const ManagerFairEntryAcceptIntegrationSuccessAction = createAction(
    '[manager-fair-entry] Accept integration success',
    props<IInstitutionManagerProgramParams & { fairEntryIntegrationId: string, managerComment: IManagerCommentDto }>()
);
export const ManagerFairEntryAcceptIntegrationErrorAction = createAction(
    '[manager-fair-entry] Accept integration error',
    props<{ error: HttpErrorResponse }>()
);


export const ManagerFairEntryRejectIntegrationAction = createAction(
    '[manager-fair-entry] Reject integration',
    props<IInstitutionManagerProgramParams & { fairEntryIntegrationId: string, managerComment: IManagerCommentDto }>()
);
export const ManagerFairEntryRejectIntegrationSuccessAction = createAction(
    '[manager-fair-entry] Reject integration success',
    props<IInstitutionManagerProgramParams & { fairEntryIntegrationId: string, managerComment: IManagerCommentDto }>()
);
export const ManagerFairEntryRejectIntegrationErrorAction = createAction(
    '[manager-fair-entry] Reject integration error',
    props<{ error: HttpErrorResponse }>()
);

export const ManagerFairEntryCancelIntegrationAction = createAction(
    '[manager-fair-entry] Cancel integration',
    props<IInstitutionManagerProgramParams & { fairEntryIntegrationId: string, managerComment: IManagerCommentDto }>()
);
export const ManagerFairEntryCancelIntegrationSuccessAction = createAction(
    '[manager-fair-entry] Cancel integration success',
    props<IInstitutionManagerProgramParams & { fairEntryIntegrationId: string, managerComment: IManagerCommentDto }>()
);
export const ManagerFairEntryCancelIntegrationErrorAction = createAction(
    '[manager-fair-entry] Cancel integration error',
    props<{ error: HttpErrorResponse }>()
);