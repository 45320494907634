import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ng4h-loading-placeholder',
  templateUrl: './loading-placeholder.component.html',
  styleUrls: ['./loading-placeholder.component.scss']
}

) export class LoadingPlaceholderComponent implements OnInit {
  @Input() width = 100;
  @Input() height = 100;
  constructor() { }
  ngOnInit() { }
}
