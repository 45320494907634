import { createAction, props } from '@ngrx/store'; import { IInstitutionMemberProgramParams, IActivityDto, IInstitutionMemberProgramEnrollmentParams } from 'app/core/models';
import { IEnrollmentActivitySubmissionDto } from 'app/core/models/serverDTOs/IEnrollmentActivitySubmissionDto';
import { HttpErrorResponse } from '@angular/common/http';

export const LoadActivitiesForEnrollmentAction = createAction(
    '[member-activities] Load activities for enrollment',
    props<IInstitutionMemberProgramEnrollmentParams>()
);
export const LoadActivitiesForEnrollmentSuccessAction = createAction(
    '[member-activities] Load activities for enrollment success',
    props<IInstitutionMemberProgramEnrollmentParams & { activities: IActivityDto[] }>()
);
export const LoadActivitiesForEnrollmentErrorAction = createAction(
    '[member-activities] Load activities for enrollment error',
    props<{ error: HttpErrorResponse }>()
);

export const SaveActivitiesForEnrollmentAction = createAction(
    '[member-activities] Save activities for enrollment',
    props<IInstitutionMemberProgramEnrollmentParams & { enrollmentActivitiesSubmission: IEnrollmentActivitySubmissionDto }>()
);
export const SaveActivitiesForEnrollmentSuccessAction = createAction(
    '[member-activities] Save activities for enrollment success',
    props<IInstitutionMemberProgramEnrollmentParams & { enrollmentActivitiesSubmission: IEnrollmentActivitySubmissionDto }>()
);
export const SaveActivitiesForEnrollmentErrorAction = createAction(
    '[member-activities] Save activities for enrollment error',
    props<{ error: HttpErrorResponse }>()
);

export const DeleteActivityForEnrollmentAction = createAction(
    '[member-activities] Delete activity',
    props<IInstitutionMemberProgramEnrollmentParams & { activityId: string }>()
);
export const DeleteActivityForEnrollmentSuccessAction = createAction(
    '[member-activities] Delete activity success',
    props<IInstitutionMemberProgramEnrollmentParams & { activityId: string }>()
);
export const DeleteActivityForEnrollmentErrorAction = createAction(
    '[member-activities] Delete activity error',
    props<{ error: HttpErrorResponse }>()
);