<div class="modal-section">
  <div class="modal-header">
    <h5 class="modal-title">Edit Unit</h5>
    <button (click)="cancel()" type="button" class="close" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="form">

      <div class="form-group row">
        <label class="form-control-label col-md-3">Unit Name</label>
        <div class="col-md-9">
          <input formControlName="unitName" type="text" class="form-control">
        </div>
      </div>
      <div class="form-group row">
        <div class="form-control-label col-md-3">Delivery Mode</div>
        <div class="col-md-9">
          <ng4h-delivery-mode-dropdown formControlName="deliveryModeId"></ng4h-delivery-mode-dropdown>
        </div>
      </div>

      <div class="form-group row">
        <label class="form-control-label col-md-3">Is {{(eUnitType.Club | programAlias$ | async)?.singular}}</label>
        <div class="col-md-9">
          <mat-checkbox formControlName="isClub"></mat-checkbox>
        </div>
      </div>
      <div class="form-group row">
        <label class="form-control-label col-md-3">Charge Fee</label>
        <mat-checkbox class="col-md-9" formControlName="chargeFee">
        </mat-checkbox>
      </div>
      <div class="form-group row">
        <label class="form-control-label col-md-3">Leader Login Code</label>
        <div class="col-md-6">
          <input formControlName="leaderLoginCode" type="text" class="form-control" [maxLength]="20">
        </div>
        <div class="col-md-3">
          <button class="btn btn-secondary" (click)="generate()">Generate New</button>
        </div>
      </div>
      <div class="form-group row">
        <label class="form-control-label col-md-3">State Wide</label>
        <mat-checkbox class="col-md-9" formControlName="stateWide">
        </mat-checkbox>
      </div>
      <div class="form-group row">
        <label class="form-control-label col-md-3">Notes</label>
        <div class="col-md-9">
          <textarea class="form-control" formControlName="notes">
        </textarea>
        </div>
      </div>
    </form>

  </div>

  <div class="modal-footer" *ngIf="form">
    <button id (click)="cancel()" class="btn btn-secondary my-2 my-sm-0" role="button"
      aria-pressed="true">Cancel</button>
    <button [disabled]="form.invalid" (click)="save()" class="btn btn-primary my-2 my-sm-0" role="button"
      aria-pressed="true">Save</button>
  </div>
</div>