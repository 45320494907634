<ng4h-dynamic-options-root [formDisplayTypes]="formDisplayTypes" [control]="control"
  (rootInputModified)="controlModified.emit($event)" (validatorAddOrModify)="validatorAddOrModify.emit($event)"
  (validatorRemove)="validatorRemove.emit($event)">
  <div class="add-options-row">
    <label class="form-control-label">Options</label>
    <button class="btn btn-primary btn-sm float-right" (click)="addDropDownOption()">Add Option</button>
  </div>
  <!-- <div class="input-group mb-3 options" *ngFor="let option of control.items[0].options; let i = index; trackBy: trackByFn">
    <input type="text" class="form-control" [ngModel]="option.label" (ngModelChange)="modifyOptionValue(option.optionId, $event)"
      aria-label="add-option-to-dropdown" aria-describedby="trash">
    <div class="input-group-append" *ngIf="i != 0">
      <button class="input-group-text" id="trash" (click)="removeDropDownOption(option)">
        <i class="fa fa-trash-alt"></i>
      </button>
    </div>
  </div> -->
  <table class="col-12">
    <thead>
      <th>Order</th>
      <th>Options</th>
      <th>Report Label</th>
      <th *ngIf="formType == eFormType.Registration">Fee</th>
      <th>Remove</th>
    </thead>
    <tbody cdkDropList (cdkDropListDropped)="drop($event)">
      <tr *ngFor="let option of control.items[0].options; let i = index; trackBy: trackByFn" cdkDrag>
        <td>
          <div cdkDragHandle class="drag-handle" style="display: inline-block;">
            <i class="ng4h-margin-left far fa-sort"></i>
          </div>
        </td>
        <td>
          <input type="text" class="form-control" [ngModel]="option.label"
            (ngModelChange)="modifyOptionValue(option.optionId, $event)" aria-label="add-option-to-dropdown">
        </td>
        <td>
          <input type="text" class="form-control" [ngModel]="option.reportValue"
            (ngModelChange)="reportLabelChange($event, option.optionId)" aria-label="add-report-label-to-dropdown">
        </td>
        <td *ngIf="formType == eFormType.Registration">
          <input class="form-control" type="number" [ngModel]="option.rate" step="0.01"
            (ngModelChange)="changeOptionRate(option.optionId, $event)">
        </td>
        <td class="center">
          <div aria-label="Remove" style="cursor: pointer;" *ngIf="i != 0" id="trash"
            (click)="removeDropDownOption(option)">
            <i class="fa fa-trash-alt"></i>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</ng4h-dynamic-options-root>