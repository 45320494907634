import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { LoggingService } from 'app/core/services';


@Injectable()
export class AppErrorHandler implements ErrorHandler {

  private errorService: LoggingService;
  constructor(private injector: Injector) { }

  handleError(error: any): void {
    if (this.errorService == null) {
      this.errorService = this.injector.get(LoggingService);
    }
    console.error(error);
    this.errorService.report(error);
  }
}
