import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ng4h-modal-add-fund',
  templateUrl: './modal-add-fund.component.html',
  styleUrls: ['./modal-add-fund.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalAddFundComponent implements OnInit {

  public form: FormGroup;

  constructor(private dialogRef: MatDialogRef<any>, private fb: FormBuilder) { }

  ngOnInit() {
    this.form = this.fb.group({
      fundCode: [],
      description: [],
      countyAreaId: []
    });
  }

  public close() {
    this.dialogRef.close();
  }

  public add() {
    this.dialogRef.close(this.form.value);
  }
}
