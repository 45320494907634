import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  DynamicValidatorBaseComponent,
} from 'app/core/containers/admin/shared/dynamic-forms/dynamic-validator-base/dynamic-validator-base.component';
import { DynamicOption, eFormType, IDynamicControl, IDynamicOption } from 'app/core/models';
import { DynamicFormService } from 'app/core/services';
import { uuidv4 } from 'app/shared/utils';

@Component({
  selector: 'ng4h-dynamic-dropdown-input-options',
  templateUrl: './dynamic-dropdown-input-options.component.html',
  styleUrls: ['./dynamic-dropdown-input-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicDropdownInputOptionsComponent extends DynamicValidatorBaseComponent implements OnInit {

  @Input() control: IDynamicControl;
  @Output() controlModified: EventEmitter<Partial<IDynamicControl>>;
  @Output() addOption: EventEmitter<IDynamicOption>;
  @Output() removeOption: EventEmitter<string>;
  @Output() optionModified: EventEmitter<Partial<IDynamicOption>>;
  @Input() formType: eFormType;

  public eFormType = eFormType;
  constructor(private dynamicFormService: DynamicFormService) {
    super();
    this.controlModified = new EventEmitter();
    this.addOption = new EventEmitter();
    this.removeOption = new EventEmitter();
    this.optionModified = new EventEmitter();
  }

  ngOnInit() {
  }

  addDropDownOption() {
    this.addOption.emit(new DynamicOption(uuidv4(), ''));
  }

  removeDropDownOption(option: IDynamicOption) {
    this.removeOption.emit(option.optionId);
  }

  modifyOptionValue(optionId: string, label: string) {
    this.optionModified.emit({ optionId: optionId, label: label });
  }

  changeOptionRate(optionId, rate) {
    this.optionModified.emit({ optionId: optionId, rate: rate });
  }

  trackByFn(index: number, item: IDynamicOption) {
    return item.optionId;
  }
  reportLabelChange(reportValue: string, optionId: string) {
    this.optionModified.emit({ optionId, reportValue });
  }

  drop(event: CdkDragDrop<string[]>) {
    const options = [...this.control.items[0].options];
    moveItemInArray(options, event.previousIndex, event.currentIndex);
    this.controlModified.emit({
      items: [
        {
          ...this.control.items[0],
          options: [...options]
        }
      ]
    });
  }
}
