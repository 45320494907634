import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { filter, map, Observable, of, take, tap } from 'rxjs';

import { SystemManagerAnimalsActions } from '../../../containers/admin/system-manager/animals/store';
import { animalForm } from '../../../containers/admin/system-manager/animals/store/system-manager-animals.reducer';
import {
  getSystemManagerAnimalBreeds,
  getSystemManagerAnimalFileControlTypes,
  getSystemManagerAnimalForm,
  getSystemManagerAnimalType,
  getSystemManagerAnimalTypes,
  SystemManagerFeatureStore
} from '../../../containers/admin/system-manager/system-manager-feature.reducers';
import {
  eAnimalFileType,
  eLocalizationType,
  IAnimalBreedDto,
  IAnimalBreedUpdateDto,
  IAnimalFileControlCreateDto,
  IAnimalFileControlUpdateDto,
  IAnimalFormDto,
  IAnimalFormFieldStatusUpdateDto,
  IAnimalTypeCreateDto,
  IAnimalTypeDto,
  IAnimalTypeFeeUpdateDto,
  IAnimalTypeUpdateDto,
  IInstitutionProgramParams,
  IInstitutionSystemManagerProgramParams
} from '../../../models';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SystemManagerAnimalsService {

  constructor(private store: Store<SystemManagerFeatureStore>, private httpClient: HttpClient, private dispatcher: ActionsSubject) {
  }

  public getAnimalTypes(params: IInstitutionSystemManagerProgramParams): Observable<IAnimalTypeDto[]> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getSystemManagerAnimalTypes(params)),
      tap(animalTypes => {
        if (animalTypes === undefined) {
          this.store.dispatch(SystemManagerAnimalsActions.SystemManagerAnimalsGetTypesAction(params));
        }
      }),
      filter(animalTypes => animalTypes != null)
    );
  }

  public getAnimalTypesEffect({ institutionId, systemManagerId, programId }: IInstitutionSystemManagerProgramParams) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/animal-types`) as Observable<IAnimalTypeDto[]>;
  }

  public getAnimalBreeds(params: IInstitutionProgramParams & { animalTypeId: string }) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getSystemManagerAnimalBreeds(params)),
      tap(breeds => {
        if (breeds === undefined) {
          this.store.dispatch(SystemManagerAnimalsActions.SystemManagerAnimalsGetBreedsAction(params));
        }
      }),
      filter(breeds => breeds != null)
    );
  }

  public getAnimalBreedsEffect({
    institutionId,
    programId,
    animalTypeId
  }: IInstitutionProgramParams & { animalTypeId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/programs/${programId}/animal-types${animalTypeId}/breeds`) as Observable<IAnimalBreedDto[]>;
  }

  public updateAnimalBreeds(params: IInstitutionSystemManagerProgramParams & { animalTypeId: string, animalBreedUpdate: IAnimalBreedUpdateDto }) {
    this.store.dispatch(SystemManagerAnimalsActions.SystemManagerAnimalsUpdateBreedsAction(params));
    return this.dispatcher.pipe(
      ofType(SystemManagerAnimalsActions.SystemManagerAnimalsUpdateBreedsSuccessAction, SystemManagerAnimalsActions.SystemManagerAnimalsUpdateBreedsErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerAnimalsActions.SystemManagerAnimalsUpdateBreedsSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateAnimalBreedsEffect({
    institutionId,
    systemManagerId,
    programId,
    animalTypeId,
    animalBreedUpdate
  }: IInstitutionSystemManagerProgramParams & { animalTypeId: string, animalBreedUpdate: IAnimalBreedUpdateDto }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/animal-types/${animalTypeId}/breeds`, animalBreedUpdate);
  }

  public deleteAnimalBreeds(params: IInstitutionSystemManagerProgramParams & { animalTypeId: string, animalBreedId: string }) {
    this.store.dispatch(SystemManagerAnimalsActions.SystemManagerAnimalsDeleteBreedsAction(params));
    return this.dispatcher.pipe(
      ofType(SystemManagerAnimalsActions.SystemManagerAnimalsDeleteBreedsSuccessAction, SystemManagerAnimalsActions.SystemManagerAnimalsDeleteBreedsErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerAnimalsActions.SystemManagerAnimalsDeleteBreedsSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteAnimalBreedsEffect({
    institutionId,
    systemManagerId,
    programId,
    animalTypeId,
    animalBreedId
  }: IInstitutionSystemManagerProgramParams & { animalTypeId: string, animalBreedId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/animal-types/${animalTypeId}/breeds/${animalBreedId}`);
  }

  public getAnimalType(params: IInstitutionSystemManagerProgramParams & { animalTypeId: string }): Observable<IAnimalTypeDto> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getSystemManagerAnimalType(params)),
      tap(animalType => {
        if (animalType === undefined) {
          this.store.dispatch(SystemManagerAnimalsActions.SystemManagerAnimalsGetTypeAction(params));
        }
      }),
      filter(animalType => animalType != null)
    );
  }

  public getAnimalTypeEffect({
    institutionId,
    systemManagerId,
    programId,
    animalTypeId
  }: IInstitutionSystemManagerProgramParams & { animalTypeId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/animal-types/${animalTypeId}`) as Observable<IAnimalTypeDto>;
  }

  public createAnimalType(params: IInstitutionSystemManagerProgramParams & { animalTypeCreate: IAnimalTypeCreateDto }) {
    this.store.dispatch(SystemManagerAnimalsActions.SystemManagerAnimalsCreateTypeAction(params));
    return this.dispatcher.pipe(
      ofType(SystemManagerAnimalsActions.SystemManagerAnimalsCreateTypeSuccessAction, SystemManagerAnimalsActions.SystemManagerAnimalsCreateTypeErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerAnimalsActions.SystemManagerAnimalsCreateTypeSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public createAnimalTypeEffect({
    institutionId,
    systemManagerId,
    programId,
    animalTypeCreate
  }: IInstitutionSystemManagerProgramParams & { animalTypeCreate: IAnimalTypeCreateDto }) {
    return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/animal-types`, animalTypeCreate);
  }

  public updateAnimalType(params: IInstitutionSystemManagerProgramParams & { animalTypeId: string, animalUpdate: IAnimalTypeUpdateDto }) {
    this.store.dispatch(SystemManagerAnimalsActions.SystemManagerAnimalsUpdateTypeAction(params));
    return this.dispatcher.pipe(
      ofType(SystemManagerAnimalsActions.SystemManagerAnimalsUpdateTypeSuccessAction, SystemManagerAnimalsActions.SystemManagerAnimalsUpdateTypeErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerAnimalsActions.SystemManagerAnimalsUpdateTypeSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateAnimalTypeEffect({
    institutionId,
    systemManagerId,
    programId,
    animalTypeId,
    animalUpdate
  }: IInstitutionSystemManagerProgramParams & { animalTypeId: string, animalUpdate: IAnimalTypeUpdateDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/animal-types/${animalTypeId}`, animalUpdate);
  }

  public deleteAnimalType(params: IInstitutionSystemManagerProgramParams & { animalTypeId: string }) {
    this.store.dispatch(SystemManagerAnimalsActions.SystemManagerAnimalsDeleteTypeAction(params));
    return this.dispatcher.pipe(
      ofType(SystemManagerAnimalsActions.SystemManagerAnimalsDeleteTypeSuccessAction, SystemManagerAnimalsActions.SystemManagerAnimalsDeleteTypeErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerAnimalsActions.SystemManagerAnimalsDeleteTypeSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteAnimalTypeEffect({
    institutionId,
    systemManagerId,
    programId,
    animalTypeId
  }: IInstitutionSystemManagerProgramParams & { animalTypeId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/animal-types/${animalTypeId}`);
  }

  public getAnimalForm(params: IInstitutionSystemManagerProgramParams & { animalTypeId: string }): Observable<IAnimalFormDto> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getSystemManagerAnimalForm(params)),
      tap(form => {
        if (form === undefined) {
          this.store.dispatch(SystemManagerAnimalsActions.SystemManagerAnimalsGetFormAction(params));
        }
      }),
      filter(form => form != null)
    );
  }

  public getAnimalFormEffect({
    institutionId,
    systemManagerId,
    programId,
    animalTypeId
  }: IInstitutionSystemManagerProgramParams & { animalTypeId: string }) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/animal-forms/${animalTypeId}`) as Observable<IAnimalFormDto>;
  }

  public saveAnimalForm(params: IInstitutionSystemManagerProgramParams & { animalTypeId: string, animalFormUpdate: IAnimalFormFieldStatusUpdateDto }) {
    this.store.dispatch(SystemManagerAnimalsActions.SystemManagerAnimalsSaveFormAction(params));
    return this.dispatcher.pipe(
      ofType(SystemManagerAnimalsActions.SystemManagerAnimalsSaveFormSuccessAction, SystemManagerAnimalsActions.SystemManagerAnimalsSaveFormErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerAnimalsActions.SystemManagerAnimalsSaveFormSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public saveAnimalFormEffect({
    institutionId,
    systemManagerId,
    programId,
    animalTypeId,
    animalFormUpdate
  }: IInstitutionSystemManagerProgramParams & { animalTypeId: string, animalFormUpdate: IAnimalFormFieldStatusUpdateDto }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/animal-forms/${animalTypeId}`, animalFormUpdate);
  }

  public saveAnimalFormLocalization(params: IInstitutionSystemManagerProgramParams & { animalTypeId: string, localization: eLocalizationType, update: any }) {
    this.store.dispatch(SystemManagerAnimalsActions.SystemManagerAnimalsSaveFormLocalizationsAction(params));
    return this.dispatcher.pipe(
      ofType(SystemManagerAnimalsActions.SystemManagerAnimalsSaveFormLocalizationsSuccessAction, SystemManagerAnimalsActions.SystemManagerAnimalsSaveFormLocalizationsErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerAnimalsActions.SystemManagerAnimalsSaveFormLocalizationsSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public saveAnimalFormLocalizationsEffect({
    institutionId,
    systemManagerId,
    programId,
    animalTypeId,
    localization,
    update
  }: IInstitutionSystemManagerProgramParams & { animalTypeId: string, localization: eLocalizationType, update: any }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/animal-forms/${animalTypeId}/${localization}`, update);
  }

  public getAnimalFileControlTypes(params: IInstitutionSystemManagerProgramParams): Observable<{ fileType: eAnimalFileType, caption: string }> {

    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getSystemManagerAnimalFileControlTypes(params)),
      tap(fileControls => {
        if (fileControls === undefined) {
          this.store.dispatch(SystemManagerAnimalsActions.SystemManagerAnimalsGetFileControlsAction(params));
        }
      }),
      filter(fileControls => fileControls != null)
    );

  }

  public getAnimalFileControlTypesEffect({
    institutionId,
    systemManagerId,
    programId
  }: IInstitutionSystemManagerProgramParams) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/animal-file-control-types`);
  }

  public addAnimalFileControl(params: IInstitutionSystemManagerProgramParams & { animalTypeId: string, fileControlCreate: IAnimalFileControlCreateDto }) {
    this.store.dispatch(SystemManagerAnimalsActions.SystemManagerAnimalsAddFileControlAction(params));
    return this.dispatcher.pipe(
      ofType(SystemManagerAnimalsActions.SystemManagerAnimalsAddFileControlSuccessAction, SystemManagerAnimalsActions.SystemManagerAnimalsAddFileControlErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerAnimalsActions.SystemManagerAnimalsAddFileControlSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public addAnimalFileControlEffect({
    institutionId,
    systemManagerId,
    programId,
    animalTypeId,
    fileControlCreate
  }: IInstitutionSystemManagerProgramParams & { animalTypeId: string, fileControlCreate: IAnimalFileControlCreateDto }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/animal-types/${animalTypeId}/file-controls`, fileControlCreate);
  }

  public deleteAnimalFileControl(params: IInstitutionSystemManagerProgramParams & { animalTypeId: string, fileControlId: string }) {
    this.store.dispatch(SystemManagerAnimalsActions.SystemManagerAnimalsDeleteFileControlAction(params));
    return this.dispatcher.pipe(
      ofType(SystemManagerAnimalsActions.SystemManagerAnimalsDeleteFileControlSuccessAction, SystemManagerAnimalsActions.SystemManagerAnimalsDeleteFileControlErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerAnimalsActions.SystemManagerAnimalsDeleteFileControlSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteAnimalFileControlEffect({
    institutionId,
    systemManagerId,
    programId,
    animalTypeId,
    fileControlId
  }: IInstitutionSystemManagerProgramParams & { animalTypeId: string, fileControlId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/animal-types/${animalTypeId}/file-controls/${fileControlId}`);
  }

  public updateAnimalFileControl(params: IInstitutionSystemManagerProgramParams & { animalTypeId: string, fileControlId: string, fileControlUpdate: IAnimalFileControlUpdateDto }) {
    this.store.dispatch(SystemManagerAnimalsActions.SystemManagerAnimalsUpdateFileControlAction(params));
    return this.dispatcher.pipe(
      ofType(SystemManagerAnimalsActions.SystemManagerAnimalsUpdateFileControlSuccessAction, SystemManagerAnimalsActions.SystemManagerAnimalsUpdateFileControlErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerAnimalsActions.SystemManagerAnimalsUpdateFileControlSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateAnimalFileControlEffect({
    institutionId,
    systemManagerId,
    programId,
    animalTypeId,
    fileControlId,
    fileControlUpdate
  }: IInstitutionSystemManagerProgramParams & { animalTypeId: string, fileControlId: string, fileControlUpdate: IAnimalFileControlUpdateDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/animal-types/${animalTypeId}/file-controls/${fileControlId}`, fileControlUpdate);
  }

  public setFileControlDisplayOrder(params: IInstitutionSystemManagerProgramParams & { animalTypeId: string, fileControlIds: string[] }) {
    this.store.dispatch(SystemManagerAnimalsActions.SystemManagerAnimalsSetFileControlOrderAction(params));
    return this.dispatcher.pipe(
      ofType(SystemManagerAnimalsActions.SystemManagerAnimalsSetFileControlOrderSuccessAction, SystemManagerAnimalsActions.SystemManagerAnimalsSetFileControlOrderErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerAnimalsActions.SystemManagerAnimalsSetFileControlOrderSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public setFileControlDisplayOrderEffect({
    institutionId,
    systemManagerId,
    programId,
    animalTypeId,
    fileControlIds
  }: IInstitutionSystemManagerProgramParams & { animalTypeId: string, fileControlIds: string[] }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/animal-types/${animalTypeId}/file-controls/set-order`, fileControlIds);
  }

  public saveAnimalTypeTemplateFile(params: IInstitutionSystemManagerProgramParams & { animalTypeId: string, fileControlId: string, formData: FormData }) {
    this.store.dispatch(SystemManagerAnimalsActions.SystemManagerAnimalsSaveTemplateAction(params));
    return this.dispatcher.pipe(
      ofType(SystemManagerAnimalsActions.SystemManagerAnimalsSaveTemplateSuccessAction, SystemManagerAnimalsActions.SystemManagerAnimalsSaveTemplateErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerAnimalsActions.SystemManagerAnimalsSaveTemplateSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public saveAnimalTypeTemplateFileEffect({
    institutionId,
    systemManagerId,
    programId,
    animalTypeId,
    fileControlId,
    formData
  }: IInstitutionSystemManagerProgramParams & { animalTypeId: string, fileControlId: string, formData: FormData }) {
    return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/animal-types/${animalTypeId}/file-controls/${fileControlId}/template-file`, formData);
  }

  public deleteAnimalTypeTemplateFile(params: IInstitutionSystemManagerProgramParams & { animalTypeId: string, fileControlId: string }) {
    this.store.dispatch(SystemManagerAnimalsActions.SystemManagerAnimalsDeleteTemplateAction(params));
    return this.dispatcher.pipe(
      ofType(SystemManagerAnimalsActions.SystemManagerAnimalsDeleteTemplateSuccessAction, SystemManagerAnimalsActions.SystemManagerAnimalsDeleteTemplateErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerAnimalsActions.SystemManagerAnimalsDeleteTemplateSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteAnimalTypeTemplateFileEffect({
    institutionId,
    systemManagerId,
    programId,
    animalTypeId,
    fileControlId
  }: IInstitutionSystemManagerProgramParams & { animalTypeId: string, fileControlId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/animal-types/${animalTypeId}/file-controls/${fileControlId}/template-file`);
  }

  public updateAnimalTypeFees(params: IInstitutionSystemManagerProgramParams & { animalTypeId: string, programYearId: string, animalTypeFee: IAnimalTypeFeeUpdateDto }) {
    this.store.dispatch(SystemManagerAnimalsActions.SystemManagerAnimalsUpdateFeesAction(params));
    return this.dispatcher.pipe(
      ofType(SystemManagerAnimalsActions.SystemManagerAnimalsUpdateFeesSuccessAction, SystemManagerAnimalsActions.SystemManagerAnimalsUpdateFeesErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerAnimalsActions.SystemManagerAnimalsUpdateFeesSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateAnimalTypeFeesEffect({
    institutionId,
    systemManagerId,
    programId,
    animalTypeId,
    programYearId,
    animalTypeFee
  }: IInstitutionSystemManagerProgramParams & { animalTypeId: string, programYearId: string, animalTypeFee: IAnimalTypeFeeUpdateDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/animal-types/${animalTypeId}/fees/${programYearId}`, animalTypeFee);
  }

  public deleteAnimalTypeFees(params: IInstitutionSystemManagerProgramParams & { animalTypeId: string, programYearId: string }) {
    this.store.dispatch(SystemManagerAnimalsActions.SystemManagerAnimalsDeleteFeesAction(params));
    return this.dispatcher.pipe(
      ofType(SystemManagerAnimalsActions.SystemManagerAnimalsDeleteFeesSuccessAction, SystemManagerAnimalsActions.SystemManagerAnimalsDeleteFeesErrorAction),
      take(1),
      map(action => {
        if (action.type === SystemManagerAnimalsActions.SystemManagerAnimalsDeleteFeesSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteAnimalTypeFeesEffect({
    institutionId,
    systemManagerId,
    programId,
    animalTypeId,
    programYearId
  }: IInstitutionSystemManagerProgramParams & { animalTypeId: string, programYearId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/system-managers/${systemManagerId}/programs/${programId}/animal-types/${animalTypeId}/fees/${programYearId}`);
  }
}
