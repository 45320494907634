import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ProgramYearService, RouterService } from 'app/core/services';
import { LocalStorageService } from 'app/core/services/local-storage.service';
import { catchError, exhaustMap, filter, forkJoin, groupBy, map, mergeMap, of, take } from 'rxjs';

import { ProgramYearActions } from '.';
import { stringKeys } from '../rxjs.utils';


@Injectable()
export class ProgramYearEffects {


  loadActiveProgramYears = createEffect(() => this.actions
    .pipe(
      ofType(ProgramYearActions.ProgramYearLoadAvailableAction),
      groupBy(stringKeys),
      mergeMap(groupedBy => {
        return groupedBy.pipe(
          exhaustMap(payload => {
            return this.programYearService.loadAvailableProgramYearsEffect(payload).pipe(
              map(programYears => ProgramYearActions.ProgramYearLoadAvailableSuccessAction({ ...payload, programYears: programYears })),
              catchError(error => of(ProgramYearActions.ProgramYearLoadAvailableErrorAction({ error })))
            );
          })
        );
      })
    ));

  loadCurrentProgramYear = createEffect(() => this.actions
    .pipe(
      ofType(ProgramYearActions.ProgramYearCurrentLoadAction),
      groupBy(stringKeys),
      mergeMap(groupedBy => {
        return groupedBy.pipe(
          exhaustMap(payload => {
            return this.programYearService.loadCurrentProgramYearEffect(payload).pipe(
              map(programYear => ProgramYearActions.ProgramYearCurrentLoadSuccessAction({ ...payload, programYear: programYear })),
              catchError(error => of(ProgramYearActions.ProgramYearCurrentLoadErrorAction({ error })))
            );
          })
        );
      })
    ));

  setUserProgramYear = createEffect(() => this.actions
    .pipe(
      ofType(ProgramYearActions.ProgramYearUsersLoadAction),
      map(payload => {
        return ProgramYearActions.ProgramYearUsersSetErrorAction(payload);
      })
    ));

  setUserProgramYearError = createEffect(() => this.actions
    .pipe(
      ofType(ProgramYearActions.ProgramYearUsersSetErrorAction),
      exhaustMap(payload => {
        return forkJoin([
          of(payload),
          this.programYearService.getCurrentProgramYear(payload).pipe(filter(py => py != null), take(1))
        ]);
      }),
      map(combined => {
        return ProgramYearActions.ProgramYearUsersSetAction({
          institutionId: combined[0].institutionId,
          programId: combined[0].programId,
          institutionProfileId: combined[0].institutionProfileId,
          programYear: combined[1]
        });
      })
    ));


  // updateLocalStorageWithUserProgramYear = createEffect(() => this.actions
  //   .pipe(
  //     ofType<ProgramYearUsersSetAction>(ProgramYearActions.SET_USER),
  //     map(action => action.payload),
  //     tap(payload => {
  //       this.localStorageService.setProgramYear(payload.institutionId, payload.programId, payload.institutionProfileId, payload.programYear);
  //     })
  //   ), { dispatch: false });

  constructor(private actions: Actions, private programYearService: ProgramYearService, private localStorageService: LocalStorageService, private routerService: RouterService) { }
}
