import { Action, createReducer, on } from '@ngrx/store';
import { mergeImmutable } from 'app/shared/utils';

import { ManagerFundingActions } from '.';

export interface ManagerFundingState {
    funds: any;
    fund: any;
    credits: any;
}

const initialState: ManagerFundingState = {
    funds: undefined,
    fund: undefined,
    credits: undefined
};

const reducer = createReducer(
    initialState,
    on(ManagerFundingActions.GetMangerFundTransfersSuccessAction, (state, { institutionId, managerId, programId, hierarchyNodeId, funds }) => {
        const newFunds = mergeImmutable(
            { [institutionId]: { [managerId]: { [programId]: { [hierarchyNodeId]: funds } } } },
            state.funds
        );
        return {
            ...state,
            funds: newFunds
        };
    }),
    on(ManagerFundingActions.GetMangerFundTransferSuccessAction, (state, { institutionId, managerId, programId, fundsTransferId, fund }) => {
        const newFund = mergeImmutable(
            { [institutionId]: { [managerId]: { [programId]: { [fundsTransferId]: fund } } } },
            state.fund
        );
        return {
            ...state,
            fund: newFund
        };
    }),
    on(ManagerFundingActions.GetMangerFundCreditsSuccessAction, (state, { institutionId, managerId, programId, hierarchyNodeId,  credits }) => {
        const newCredits = mergeImmutable(
            { [institutionId]: { [managerId]: { [programId]: { [hierarchyNodeId]: credits } } } },
            state.credits
        );
        return {
            ...state,
            credits: newCredits
        };
    })
);

export function managerFundingReducer(state: ManagerFundingState | undefined, action: Action) {
    return reducer(state, action);
}

export const funds = (state: ManagerFundingState) => state.funds;
export const fund = (state: ManagerFundingState) => state.fund;
export const credits = (state: ManagerFundingState) => state.credits;
