<ng-container *ngIf="selectedUnits$ | async as selectedUnits">
  <ng-container *ngIf="(clubsPageMessage$ | async) == null">
    <div class="col-12">
      <table>
        <thead>
          <th>Primary</th>
          <th>{{(eUnitType.Club | programAlias$ | async)?.singular}}</th>
          <th>Type</th>
          <th>County</th>
          <th *ngIf="allowEdit" style="text-align: center;width: 1rem;">Remove</th>
        </thead>
        <tbody *ngIf="selectedUnits.length > 0">
          <tr *ngFor="let unit of selectedUnits">
            <td class="center" *ngIf="allowEdit; else readonly">
              <button disabled *ngIf="unit.primary" class="btn btn-sm btn-primary">Primary</button>
              <button *ngIf="!unit.primary" class="btn btn-sm btn-secondary" (click)="primaryClubChange(unit)">Change
                to Primary</button>
            </td>
            <ng-template #readonly>
              <td>
                <span *ngIf="unit.primary">Primary</span>
              </td>
            </ng-template>
            <td>{{unit.unitName}}</td>
            <td>{{unit.unitType}}</td>
            <td>{{unit.countyArea.hierarchyNodeName}}</td>
            <td class="center" *ngIf="allowEdit">
              <button aria-label="Remove Club" class="btn remove-club" (click)="removeClub(unit)">
                <i class="fal fa-trash-alt"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-12" *ngIf="allowEdit">
      <ng-container *ngIf="programSettings$ | async as programSettings">
        <button
          [disabled]="programSettings?.enrollment_MaxClubs !== 0 && selectedUnits.length >= programSettings?.enrollment_MaxClubs"
          class="btn add-clubs" [ngClass]="selectedUnits.length > 0 ? 'btn-secondary' : 'btn-primary'"
          (click)="openAddClubModal()">Select
          Units</button>
      </ng-container>
    </div>
  </ng-container>

</ng-container>
<ng-container *ngIf="clubsPageMessage$ | async as message">
  <ng4h-in-app-help class="selection-intro">
    <div ng4h-in-app-help-header>{{(eUnitType.Club | programAlias$ | async)?.plural}}</div>
    <div ng4h-in-app-help-body>
      <div [innerHtml]="message.message | safeHtml"></div>
    </div>
    <div ng4h-in-app-help-footer>
      <button (click)="closeHelp()" class="btn btn-primary">Select
        {{(eUnitType.Club | programAlias$ | async)?.plural}}</button>
    </div>
  </ng4h-in-app-help>
</ng-container>