import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IUserAccountDto } from 'app/core/models';

export const EmailLookup = createAction(
  '[user account] email look up',
  props<{ email: string }>()
);

export const EmailLookupSuccess = createAction(
  '[user account] email look up success',
  props<{ email: string, user: IUserAccountDto }>()
);

export const EmailLookupError = createAction(
  '[user account] email look up error',
  props<{ error: HttpErrorResponse }>()
);
