import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState, getDistricts } from 'app/app.reducers';
import { DistrictActions } from 'app/shared/district';
import { filter, Observable, of, tap } from 'rxjs';

import { IHierarchyDistrictArea } from '../models';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DistrictService {

  constructor(private httpClient: HttpClient, private store: Store<AppState>) {

  }

  public getDistricts(params: { institutionId: string }): Observable<IHierarchyDistrictArea[]> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getDistricts(params)),
      tap(enrollment => {
        if (enrollment === undefined) {
          this.store.dispatch(DistrictActions.DistrictLoadDistrictsAction(params));
        }
      }),
      filter(enrollment => enrollment != null)
    );
  }

  public loadDistrictsEffect(institutionId: string): Observable<IHierarchyDistrictArea[]> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/districts`) as Observable<IHierarchyDistrictArea[]>;
  }
}
