import { Action, createReducer, on } from '@ngrx/store';
import { IProjectDto } from 'app/core/models';
import { mergeImmutable } from 'app/shared/utils';

import { SystemManagerProjectActions } from '.';
import { SystemManagerTrainingActions } from '../../../training/store';


export interface SystemManagerProjectsState {
  projects: { [institutionId: string]: { [systemManagerId: string]: { [programId: string]: IProjectDto[] } } };
  project: { [institutionId: string]: { [systemManagerId: string]: { [programId: string]: { [projectId: string]: IProjectDto } } } };
}

const initialState: SystemManagerProjectsState = {
  projects: undefined,
  project: undefined
};

const reducer = createReducer(
  initialState,
  on(SystemManagerProjectActions.SystemManagerGetProjectsSuccessAction, (state, { institutionId, systemManagerId, programId, projects }) => {
    return {
      ...state,
      projects: mergeImmutable(
        { [institutionId]: { [systemManagerId]: { [programId]: projects } } },
        state.projects
      )
    };
  }),
  on(SystemManagerProjectActions.SystemManagerGetProjectSuccessAction, (state, { institutionId, systemManagerId, programId, projectId, project }) => {
    return {
      ...state,
      project: mergeImmutable(
        { [institutionId]: { [systemManagerId]: { [programId]: { [projectId]: project } } } },
        state.project
      )
    };
  }),
  on(SystemManagerProjectActions.SystemManagerAddProjectConsentSuccessAction,
    SystemManagerProjectActions.SystemManagerDeleteProjectConsentSuccessAction,
    SystemManagerProjectActions.SystemManagerUpdateProjectSuccessAction,
    SystemManagerProjectActions.SystemManagerCreateProjectSuccessAction,
    SystemManagerTrainingActions.SystemManagerTrainingProjectAddSuccessAction,
    SystemManagerTrainingActions.SystemManagerTrainingProjectDeleteSuccessAction,
    (state, { }) => {
      return {
        ...state,
        projects: undefined,
        project: undefined
      };
    }),
  on(SystemManagerProjectActions.SystemManagerDeleteInstitutionProjectSuccessAction, (state, { institutionId, systemManagerId, programId, projectId }) => {
    return {
      ...state,
      project: mergeImmutable(
        { [institutionId]: { [systemManagerId]: { [programId]: { [projectId]: null } } } },
        state.project
      ),
      projects: undefined
    };
  })
);

export function systemManagerProjectsReducer(state: SystemManagerProjectsState | undefined, actions: Action) {
  return reducer(state, actions);
}

export const projects = (state: SystemManagerProjectsState) => state.projects;
export const project = (state: SystemManagerProjectsState) => state.project;
