import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IDynamicControl, IDynamicItem } from 'app/core/models';

import { DynamicValidatorBaseComponent } from '../dynamic-validator-base/dynamic-validator-base.component';

@Component({
  selector: 'ng4h-dynamic-text-area-input-options',
  templateUrl: './dynamic-text-area-input-options.component.html',
  styleUrls: ['./dynamic-text-area-input-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicTextAreaInputOptionsComponent extends DynamicValidatorBaseComponent implements OnInit {

  @Input() control: IDynamicControl;
  @Output() controlModified: EventEmitter<Partial<IDynamicControl>>;
  @Output() itemModified: EventEmitter<Partial<IDynamicItem>>;
  constructor() {
    super();
    this.controlModified = new EventEmitter();
    this.itemModified = new EventEmitter();
  }

  ngOnInit() {
  }
  reportLabelChange(reportValue: string, itemId: string) {
    this.itemModified.emit({ itemId, reportValue });
  }
  rowChange(value: string, itemId: string) {
    const rowNumber = parseInt(value, 10);
    this.itemModified.emit({ itemId: itemId, attributes: { rows: rowNumber } });
  }
}
