<ng-container *ngIf="selectedLeafNode$ | async as selectedLeafNode">
  <div class="modal-header">
    <h5 class="modal-title">Choose Hierarchy Area</h5>
    <button (click)="close()" type="button" class="close" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <ng4h-hierarchy-picker></ng4h-hierarchy-picker>
    <div class="ng4h-danger" *ngIf="selectedLeafNode.acl == null">You do not have
      permissions for this area.</div>
  </div>

  <div class="modal-footer">
    <button (click)="close()" class="btn btn-secondary" role="button" aria-pressed="true">Close</button>
    <button [disabled]="selectedLeafNode.acl == null" (click)="save()" class="btn btn-primary" role="button"
      aria-pressed="true">Save</button>
  </div>
</ng-container>
