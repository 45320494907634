import { ChangeDetectorRef, Directive, EmbeddedViewRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import {
    AutoUnsubscribe,
    eAccessControlListRoleType,
    eProfileType,
    IAnyInstitutionProfile,
    IManagerPermissions
} from 'app/core/models';
import { HierarchyService, ProfileService, RouterService } from 'app/core/services';
import { combineLatest, filter, map, Observable, of, switchMap, takeUntil } from 'rxjs';

import { rankPermissionState } from '../permission.utils';

export class PermissionsDirectiveContext {
    public $implicit: Partial<IManagerPermissions> = null;
    public ng4hManagerPermissions: Partial<IManagerPermissions> = null;
}

@Directive({
    selector: '[ng4hManagerPermissions]'
})
export class ManagerPermissionsDirective extends AutoUnsubscribe {

    private context: PermissionsDirectiveContext = new PermissionsDirectiveContext();
    private thenViewRef: EmbeddedViewRef<any> | null;

    @Input('ng4hManagerPermissions')
    set ng4hManagerPermissions(managerPermissions: Partial<IManagerPermissions>) {
        this.context.$implicit = this.context.ng4hManagerPermissions = managerPermissions;
        this.thenViewRef = null;  // clear previous view if any.
        this.updateView();
    }

    constructor(
        private thenTemplateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private profileService: ProfileService,
        private routerService: RouterService,
        private hierarchyService: HierarchyService,
        private cdr: ChangeDetectorRef
    ) {
        super();
    }

    private updateView() {
        this.profileService.actingAsInstitutionProfile.pipe(
            filter(profile => profile != null),
            switchMap(actingOnBehalfOf => this.hasPermissions(this.context.ng4hManagerPermissions, actingOnBehalfOf)),
            takeUntil(this.autoUnsubscribe)
        ).subscribe(hasPermissions => {
            if (hasPermissions) {
                this.thenViewRef = null;
                this.viewContainer.clear();
                if (this.thenTemplateRef) {
                    this.thenViewRef =
                        this.viewContainer.createEmbeddedView(this.thenTemplateRef, this.context);
                }
            } else {
                this.viewContainer.clear();
            }
            this.cdr.markForCheck();
        });
    }

    private hasPermissions(requiredPermissions: Partial<IManagerPermissions>, profile: IAnyInstitutionProfile): Observable<boolean> {

        if (profile.profileType !== eProfileType.Manager) {
            return of(false);
        }
        if (requiredPermissions == null) {
            return of(false);
        }
        const programId = this.routerService.programId;

        return combineLatest([
            this.routerService.managerHierarchyId$, this.hierarchyService.getManagerHierarchy({
                institutionId: profile.institutionId,
                programId,
                managerId: profile.managerId,
                hierarchyAssociations: profile.managerPrograms[programId]
            })
        ]).pipe(
            filter(([managerHierarchyId, hierarchy]) => {
                return hierarchy != null;
            }),
            map(([managerHierarchyId, managerHierarchy]) => {
                if (managerHierarchy?.[managerHierarchyId]?.acl == null) {
                    return false;
                }

                const requiredPermissionKeys = Object.keys(requiredPermissions);

                if (requiredPermissionKeys.find(key => key === 'primaryHierarchyAdmin') != null) {
                    return managerHierarchy?.[managerHierarchyId]?.primary === true;
                }

                if (managerHierarchy?.[managerHierarchyId]?.primary === true) {
                    return true;
                }

                const managerPermissions = managerHierarchy[managerHierarchyId].acl;

                if (managerPermissions == null) {
                    return false;
                } else if (managerPermissions.permissionRole === eAccessControlListRoleType.HierarchyAdmin) {
                    return true;
                }

                for (let i = 0; i < requiredPermissionKeys.length; i++) {
                    const managerPermission = managerPermissions.grantedPermissions[requiredPermissionKeys[i]];
                    if (managerPermission == null) {
                        return false;
                    }
                    const requiredPermission = requiredPermissions[requiredPermissionKeys[i]];

                    if (rankPermissionState(requiredPermission) < rankPermissionState(managerPermission)) {
                        return false;
                    }
                }

                return true;
            })
        );
    }
}
