import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ModalService } from 'app/core/services';
import { debounceTime, distinct, filter, map, Observable, startWith, take, takeUntil, tap } from 'rxjs';

import { AutoUnsubscribe } from '../../models';
import {
  ModalMultiselectAutocompleteComponent,
} from './modal-multiselect-autocomplete/modal-multiselect-autocomplete.component';


@Component({
  selector: 'ng4h-multiselect-autocomplete',
  templateUrl: './multiselect-autocomplete.component.html',
  styleUrls: ['./multiselect-autocomplete.component.scss']
})
export class MultiselectAutocompleteComponent extends AutoUnsubscribe implements OnInit, OnChanges {

  @Input() options: any[] = [];
  @Input() displaySelector: string;
  @Input() displayCamelCaseToTitleCase: boolean;
  @Input() valueSelector: string;
  @Input() placeholder: string;
  @Input() disabled: boolean;
  @Input() reset: EventEmitter<void>;
  @Input() selected: any[] = [];
  @Input() sort = true;
  // @Input() debug = false;

  @Output() selectedChanged: EventEmitter<any[]>;
  @Output() searchCharged: EventEmitter<string>;
  @ViewChild('autocompleteInput', { static: true }) autocompleteInput: ElementRef;
  @ViewChild('autocompleteInput', { read: MatAutocompleteTrigger, static: true }) autocompleteInputTrigger: MatAutocompleteTrigger;

  public formControl = new FormControl('');
  public filteredOptions$: Observable<any[]>;
  public selectedOptions: any[] = [];

  public realInputFocused = false;
  constructor(
    private modalService: ModalService
  ) {
    super();
    this.selectedChanged = new EventEmitter();
    this.searchCharged = new EventEmitter();
  }

  ngOnInit() {

    if (this.displaySelector == null) {
      this.displaySelector = this.valueSelector;
    }
    if (this.valueSelector == null) {
      this.valueSelector = this.displaySelector;
    }

    this.formControl.valueChanges.pipe(
      distinct(),
      debounceTime(1000),
      filter(value => typeof value === 'string'),
      takeUntil(this.autoUnsubscribe)
    ).subscribe(value => {
      this.searchCharged.emit(value);
    });

    this.filteredOptions$ = this.formControl.valueChanges
      .pipe(
        startWith(''),
        tap(value => {
          if (typeof value !== 'string') {
            this.optionAdded(value);
          }
        }),
        filter(value => typeof value === 'string'),
        map(value => value.trim() === '' ? '' : value),
        map(value => this.filterValue(value)),
      );

    if (this.reset != null) {
      this.reset.pipe(takeUntil(this.autoUnsubscribe)).subscribe(() => this.resetControl());
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.displaySelector?.currentValue == null && changes.valueSelector?.currentValue != null) {
      this.displaySelector = changes.valueSelector?.currentValue;
    }
    if (changes.valueSelector?.currentValue == null && changes.displaySelector?.currentValue != null) {
      this.valueSelector = changes.displaySelector?.currentValue;
    }

    this.formControl.setValue('');
    if (changes.disabled != null) {
      switch (changes.disabled.currentValue) {
        case true: {
          this.disabled = true;
          if (this.formControl.enabled) {
            this.formControl.disable();
          }
          break;
        }
        case false: {
          this.disabled = false;
          if (this.formControl.disabled) {
            this.formControl.enable();
          }
          break;
        }
      }
    }



    if (changes.options != null && changes.options.currentValue != null) {
      const optionsToRemove = [];
      this.selectedOptions.forEach(selectedOption => {
        const exists = changes.options.currentValue.find(curSelectedOption => {
          return curSelectedOption[this.valueSelector] === selectedOption[this.valueSelector];
        });
        if (exists === null) {
          optionsToRemove.push(selectedOption);
        }
      });
      optionsToRemove.forEach(option => this.removeOption(option));
    } else if (changes.options != null && changes.options.currentValue == null) {
      this.selectedOptions = [];
    }
    if (changes.options && Array.isArray(changes.options.currentValue)) {
      this.options = changes.options.currentValue;

    }
    if (changes.selected && Array.isArray(changes.selected.currentValue)) {
      this.selected = changes.selected.currentValue;
    }


    if (Array.isArray(this.selected) && Array.isArray(this.options)) {

      if (this.sort === true) {
        this.selectedOptions = this.options
          .filter(option => this.selected.includes(option[this.valueSelector]))
          .sort((a, b) => a[this.displaySelector].localeCompare(b[this.displaySelector]));
      } else {
        this.selectedOptions = this.options
          .filter(option => this.selected.includes(option[this.valueSelector]));
      }
      this.formControl.setValue(this.formControl.value);


    }



  }

  private filterValue(value: string): { display: string, key: string }[] {

    if (this.options == null || this.options.includes(o => o == null)) {
      return [];
    }
    const filterValue = value.toLowerCase();
    const options = this.options.filter(option => {
      return option[this.displaySelector] && option[this.displaySelector].toLowerCase().indexOf(filterValue) !== -1 && this.selectedOptions.indexOf(option) === -1;
    });
    if (this.sort === true) {
      return options.sort((a, b) => a?.[this.displaySelector].localeCompare(b?.[this.displaySelector]));
    } else {
      return options;
    }

  }

  public removeOption(option: any) {
    this.selected = [...this.selected].splice(this.selectedOptions.indexOf(option), 1);
    this.selectedOptions.splice(this.selectedOptions.indexOf(option), 1);

    this.formControl.setValue('');
    this.selectedChanged.emit(this.selectedOptions);
  }

  public optionAdded(option: any) {
    if (this.selectedOptions.includes(option)) {
      return;
    }
    this.selectedOptions.push(option);
    this.formControl.setValue('');
    this.blur();
    this.selectedChanged.emit(this.selectedOptions);
  }

  public blur() {
    this.autocompleteInput.nativeElement.blur();
  }
  public fakeClicked($event) {
    $event.stopPropagation();
    if (!this.disabled) {
      this.autocompleteInput.nativeElement.focus();
    }
  }
  public sortNull() {
  }
  public resetControl() {
    this.selectedOptions = [];
    this.formControl.setValue('');
    this.selectedChanged.emit(this.selectedOptions);
  }

  public openExternal($event) {
    $event.stopPropagation();


    this.modalService.openModal(ModalMultiselectAutocompleteComponent, {
      data: {
        sort: this.sort,
        options: this.options,
        selected: this.selectedOptions,
        displaySelector: this.displaySelector,
        valueSelector: this.valueSelector,
      }
    }
    ).pipe(
      take(1),
      filter(selected => selected != null)
    ).subscribe(selected => {
      this.selectedOptions = selected;
      this.formControl.setValue('');
      this.blur();
      this.selectedChanged.emit(this.selectedOptions);
    });



  }

}
