<form [formGroup]="form">

  <div [formGroupName]="sectionName">

    <div [ngSwitch]="input.inputType">

      <div *ngSwitchCase="eFormControlType.TextContentBlock">
        <div
          *ngIf="input.inputState != eStandardFieldStatus.Hidden && input.inputName[1] == '_'  && input.inputText?.length > 0">
          <h5 class="ng4h-border-bottom"><b>{{ input.inputText }}</b></h5>
        </div>
        <div
          *ngIf="input.inputState != eStandardFieldStatus.Hidden && input.inputName[1] != '_' && input.inputText?.length > 0"
          class="alert alert-info">
          <p>{{ input.inputText }}</p>
        </div>
      </div>
      <div *ngSwitchCase="eFormControlType.Checkbox" class="form-group full-width">

        <label [for]="input.inputName" [ngClass]="{'ng4h-required': input.inputState == eStandardFieldStatus.Required}">{{ input.inputText }}</label>

        <mat-radio-group [id]="input.inputName" class="radio-group" [formControlName]="input.inputName" (change)="checkboxChanged($event)">
          <mat-radio-button class="radio-button" [value]="true">
            Yes
          </mat-radio-button>
          <mat-radio-button class="radio-button" [value]="false">
            No
          </mat-radio-button>
        </mat-radio-group>
        <small class="form-text text-muted" *ngIf="input.label != null && input.inputText != input.label">{{ input.label | camelToTitleCase }}</small>
      </div>

      <div *ngSwitchCase="eFormControlType.Date" class="form-group full-width">
        <label [for]="input.inputName" class="form-control-label"
               [ngClass]="{'ng4h-required': input.inputState == eStandardFieldStatus.Required}">{{ input.inputText }}</label>

        <div class="input-group">
          <input matInput class="form-control" [id]="input.inputName" [formControlName]="input.inputName"
                 [matDatepicker]="picker" placeholder="Choose a date">
          <mat-datepicker-toggle [for]="picker"></mat-datepicker-toggle>
        </div>
        <mat-datepicker #picker></mat-datepicker>
        <small class="form-text text-muted" *ngIf="input.label != null && input.inputText != input.label">{{ input.label | camelToTitleCase }}</small>
      </div>

      <div *ngSwitchCase="eFormControlType.SingleLineText" class="form-group">
        <label [for]="input.inputName" class="form-control-label"
               [ngClass]="{'ng4h-required': input.inputState == eStandardFieldStatus.Required}">{{ input.inputText }}</label>

        <input class="form-control" type="text" [id]="input.inputName" [formControlName]="input.inputName">
        <small class="form-text text-muted" *ngIf="input.label != null && input.inputText != input.label">{{ input.label | camelToTitleCase }}</small>
      </div>

      <div *ngSwitchCase="eFormControlType.Phone" class="form-group">
        <label [for]="input.inputName" class="form-control-label"
               [ngClass]="{'ng4h-required': input.inputState == eStandardFieldStatus.Required}">{{ input.inputText }}</label>

        <input class="form-control" [maxLength]="12" type="tel" ng4hPhoneMask [id]="input.inputName"
               [formControlName]="input.inputName">
        <small class="form-text text-muted"
               *ngIf="input.label != null && input.inputText != input.label">{{ input.label | camelToTitleCase }}</small>
      </div>

      <div *ngSwitchCase="eFormControlType.Email" class="form-group">
        <label [for]="input.inputName" class="form-control-label"
               [ngClass]="{'ng4h-required': input.inputState == eStandardFieldStatus.Required}">{{ input.inputText }}</label>

        <input class="form-control" type="email" [id]="input.inputName" [formControlName]="input.inputName">
        <small class="form-text text-muted"
               *ngIf="input.label != null && input.inputText != input.label">{{ input.label | camelToTitleCase }}</small>
      </div>

      <div *ngSwitchCase="eFormControlType.Decimal" class="form-group">
        <label [for]="input.inputName" class="form-control-label"
               [ngClass]="{'ng4h-required': input.inputState == eStandardFieldStatus.Required}">{{ input.inputText }}</label>

        <input class="form-control" type="number" [min]="0" [id]="input.inputName" [formControlName]="input.inputName">
        <small class="form-text text-muted"
               *ngIf="input.label != null && input.inputText != input.label">{{ input.label | camelToTitleCase }}</small>
      </div>

      <div OnlyNumber="true" *ngSwitchCase="eFormControlType.WholeNumber" class="form-group">
        <label [for]="input.inputName" class="form-control-label"
               [ngClass]="{'ng4h-required': input.inputState == eStandardFieldStatus.Required}">{{ input.inputText }}</label>

        <input class="form-control" type="number" [min]="0" [id]="input.inputName" [formControlName]="input.inputName"
               max="120" step="1">
        <small class="form-text text-muted"
               *ngIf="input.label != null && input.inputText != input.label">{{ input.label | camelToTitleCase }}</small>
      </div>

      <div *ngSwitchCase="eFormControlType.MultipleLineText" class="form-group">

        <label [for]="input.inputName" class="form-control-label"
               [ngClass]="{'ng4h-required': input.inputState == eStandardFieldStatus.Required}">{{ input.inputText }}</label>


        <textarea class="form-control" rows="3" [id]="input.inputName" [formControlName]="input.inputName"></textarea>
        <small class="form-text text-muted"
               *ngIf="input.label != null && input.inputText != input.label">{{ input.label | camelToTitleCase }}</small>
      </div>

      <div *ngSwitchCase="eFormControlType.Currency" class="form-group">
        <label [for]="input.inputName" class="form-control-label"
               [ngClass]="{'ng4h-required': input.inputState == eStandardFieldStatus.Required}">{{ input.inputText }}</label>

        <input OnlyNumber="true" class="form-control" type="number" [min]="0" [id]="input.inputName"
               [formControlName]="input.inputName" step="0.01">
        <small class="form-text text-muted"
               *ngIf="input.label != null && input.inputText != input.label">{{ input.label | camelToTitleCase }}</small>
      </div>

      <div *ngSwitchCase="eFormControlType.DependentMultipleLineText" class="form-group">
        <div *ngIf="showFakeCheckbox">
          <label
            [ngClass]="{'ng4h-required': input.inputState == eStandardFieldStatus.Required}">{{ input.inputText }}</label>

          <mat-radio-group class="radio-group" [id]="input.inputName" [formControlName]="input.inputName">
            <mat-radio-button class="radio-button" [value]="true">
              Yes
            </mat-radio-button>
            <mat-radio-button class="radio-button" [value]="false">
              No
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <ng-container *ngIf="readOnly || form.controls[this.sectionName]['controls'][this.input.parentInputName].value">
          <div class="ng4h-required"></div>
          <textarea [placeholder]="input.inputText" class="form-control" rows="3" [id]="input.inputName"
                    [formControlName]="input.inputName"></textarea>
        </ng-container>
        <small class="form-text text-muted"
               *ngIf="input.label != null && input.inputText != input.label">{{ input.label | camelToTitleCase }}</small>
      </div>

      <div *ngSwitchCase="eFormControlType.SingleChoice" class="form-group">
        <label [for]="input.inputName" class="form-control-label"
               [ngClass]="{'ng4h-required': input.inputState == eStandardFieldStatus.Required}">{{ input.inputText }}</label>


        <select class="form-control" [id]="input.inputName" [formControlName]="input.inputName">
          <option [ngValue]="null"></option>
          <option *ngFor="let option of input.singleChoiceOptions | keyvalue" [value]="option.key">{{ option.value }}
          </option>
        </select>
        <small class="form-text text-muted"
               *ngIf="input.label != null && input.inputText != input.label">{{ input.label | camelToTitleCase }}</small>
      </div>

      <div *ngSwitchDefault>
        Unimplemented {{ input.inputType }}
      </div>

    </div>
  </div>

</form>