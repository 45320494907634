import { IEnrollmentRoleInvoiceItemDto, IHierarchyNodeDto } from '.';

export interface IClubEnrollmentHierarchyInvoiceItemsDto {
    unitId: string;
    programYearId: string;
    hierarchyNode: IHierarchyNodeDto;
    cloverBud: IEnrollmentRoleInvoiceItemDto;
    youth: IEnrollmentRoleInvoiceItemDto;
    adult: IEnrollmentRoleInvoiceItemDto;
}
