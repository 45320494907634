import { Pipe, PipeTransform } from '@angular/core';
import { eFilterOperator } from 'app/core/models';
import { CustomReportsService, EventCustomReportsService, RouterService } from 'app/core/services';
import { map, Observable, of } from 'rxjs';

@Pipe({
    name: 'filterOperatorColumnFilter$',
    pure: true
})
export class FilterOperatorColumnFilterPipe implements PipeTransform {
    constructor(private customReportsService: CustomReportsService, private eventCustomReportService: EventCustomReportsService, private routerService: RouterService) {
    }

    public transform(
        columnIdToFilter: string,
        institutionId: string = this.routerService.institutionId,
        managerId: string = this.routerService.managerId,
        programId: string = this.routerService.programId,
        eventId: string = this.routerService.eventId,
        customReportId: string = this.routerService.customReportId
    ): Observable<eFilterOperator[]> {
        if (columnIdToFilter == null) {
            return of([]);
        }
        if (eventId != null) {
            return this.eventCustomReportService.getAvailableColumns({
                institutionId,
                managerId,
                programId,
                eventId,
                customReportId
            }).pipe(
                map(availableColumns => {
                    return availableColumns.find(column => column.columnId === columnIdToFilter).availableFilterOperators;
                })
            );
        }
        return this.customReportsService.getAvailableColumns({
            institutionId,
            managerId,
            programId,
            customReportId
        }).pipe(
            map(availableColumns => {
                return availableColumns.find(column => column.columnId === columnIdToFilter).availableFilterOperators;
            })
        );
    }
}
