import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState, getAvailableProgramYears, getCurrentProgramYear, getUsersProgramYear } from 'app/app.reducers';
import { IInstitutionProgramParams, IProgramYearDto } from 'app/core/models';
import { ProgramYearActions } from 'app/shared/program-year';
import { filter, map, Observable, of, tap } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProgramYearService {

  constructor(private store: Store<AppState>, private httpClient: HttpClient) {

  }

  public getAvailableProgramYears(params: IInstitutionProgramParams): Observable<IProgramYearDto[]> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getAvailableProgramYears(params)),
      tap(availableProgramYears => {
        if (availableProgramYears === undefined) {
          this.store.dispatch(ProgramYearActions.ProgramYearLoadAvailableAction(params));
        }
      }),
      filter(availableProgramYears => availableProgramYears != null)
    );
  }

  public loadAvailableProgramYearsEffect({
    institutionId,
    programId
  }: IInstitutionProgramParams): Observable<IProgramYearDto[]> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/programs/${programId}/available-program-years`) as Observable<IProgramYearDto[]>;
  }

  public setUserProgramYear(params: IInstitutionProgramParams & { institutionProfileId: string, programYear: IProgramYearDto }) {
    this.store.dispatch(ProgramYearActions.ProgramYearUsersSetAction(params));
  }

  public getUsersProgramYear(params: IInstitutionProgramParams & { institutionProfileId: string }): Observable<IProgramYearDto> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getUsersProgramYear(params)),
      tap(usersProgramYear => {
        if (usersProgramYear === undefined) {
          this.store.dispatch(ProgramYearActions.ProgramYearUsersLoadAction(params));
        }
      }),
      filter(usersProgramYear => usersProgramYear != null)
    );
  }

  public getCurrentProgramYear(params: IInstitutionProgramParams): Observable<IProgramYearDto> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getCurrentProgramYear(params)),
      tap(usersCurrentProgramYear => {
        if (usersCurrentProgramYear === undefined) {
          this.store.dispatch(ProgramYearActions.ProgramYearCurrentLoadAction(params));
        }
      }),
      filter(usersCurrentProgramYear => usersCurrentProgramYear != null)
    );
  }

  public loadCurrentProgramYearEffect({
    institutionId,
    programId
  }: IInstitutionProgramParams): Observable<IProgramYearDto> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/programs/${programId}/program-year`) as Observable<IProgramYearDto>;
  }

  public getProgramYear(params: IInstitutionProgramParams & { programYearId: string }): Observable<IProgramYearDto> {
    return this.getAvailableProgramYears({ institutionId: params.institutionId, programId: params.programId }).pipe(
      filter(availableProgramYears => availableProgramYears != null),
      map(availableProgramYears => availableProgramYears.find(apy => apy.programYearId === params.programYearId))
    );
  }
}
