import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  IInstitutionManagerProgramHierarchyParams,
  IPaymentSearchParametersDto,
  IPaymentSearchResultDto,
} from 'app/core/models';


export const SearchPaymentsAction = createAction(
    '[payments-search] Search payments',
    props<IInstitutionManagerProgramHierarchyParams & { searchParameters: IPaymentSearchParametersDto }>()
);

export const SearchPaymentsSuccessAction = createAction(
    '[payments-search] Search payments success',
    props<IInstitutionManagerProgramHierarchyParams & { searchParameters: IPaymentSearchParametersDto, paymentsSearchResult: IPaymentSearchResultDto }>()
);

export const SearchPaymentsErrorAction = createAction(
    '[payments-search] Search payments error',
    props<{ error: HttpErrorResponse }>()
);


export const GetPaymentSearchResultsAction = createAction(
    '[payments-search] get payments search results',
    props<IInstitutionManagerProgramHierarchyParams & { pageSize: string, pageIndex: string }>()
);

export const GetPaymentSearchResultsSuccessAction = createAction(
    '[payments-search] get payments search results success',
    props<IInstitutionManagerProgramHierarchyParams & { pageSize: string, pageIndex: string, paymentsSearchResult: IPaymentSearchResultDto }>()
);

export const GetPaymentSearchResultsErrorAction = createAction(
    '[payments-search] get payments search results error',
    props<{ error: HttpErrorResponse }>()
);


export const ClearPaymentSearchAction = createAction(
    '[payments-search] clear payments search',
    props<IInstitutionManagerProgramHierarchyParams>()
);

export const ClearPaymentSearchSuccessAction = createAction(
    '[payments-search] clear payments search success',
    props<IInstitutionManagerProgramHierarchyParams>()
);

export const ClearPaymentSearchErrorAction = createAction(
    '[payments-search] clear payments search error',
    props<{ error: HttpErrorResponse }>()
);
