import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ProjectsService } from 'app/core/services';
import { catchError, exhaustMap, groupBy, map, mergeMap, of } from 'rxjs';

import { ProjectsActions } from '.';
import { stringKeys } from '../rxjs.utils';


@Injectable()
export class ProjectsEffects {

  loadProjects = createEffect(() => this.actions
    .pipe(
      ofType(ProjectsActions.ProjectsLoadAction),
      groupBy(stringKeys),
      mergeMap(groupedBy => {
        return groupedBy.pipe(
          exhaustMap(payload => {
            return this.projectsService.loadProjectsEffect(payload).pipe(
              // groups will be null if api returns with 204 No Content
              map(projects => projects == null ? [] : projects),
              map(projects => ProjectsActions.ProjectsLoadSuccessAction({ ...payload, projects: projects })),
              catchError(error => of(ProjectsActions.ProjectsLoadErrorAction({ error })))
            );
          })
        );
      })
    ));
  loadProjectsByClub = createEffect(() => this.actions
    .pipe(
      ofType(ProjectsActions.ProjectsLoadByClubAction),
      groupBy(stringKeys),
      mergeMap(groupedBy => {
        return groupedBy.pipe(
          exhaustMap(payload => {
            return this.projectsService.loadProjectsByClubEffect(payload).pipe(
              // groups will be null if api returns with 204 No Content
              map(projects => projects == null ? [] : projects),
              map(projects => ProjectsActions.ProjectsLoadByClubSuccessAction({ ...payload, projects: projects })),
              catchError(error => of(ProjectsActions.ProjectsLoadByClubErrorAction({ error })))
            );
          })
        );
      })
    ));

  constructor(private actions: Actions, private projectsService: ProjectsService) { }
}
