import { Pipe, PipeTransform } from '@angular/core';
import { IDeliveryModeDto } from 'app/core/models';
import { RouterService } from 'app/core/services';
import { Observable } from 'rxjs';

import { DeliveryModeService } from '../services/delivery-mode.service';

@Pipe({
    name: 'deliveryMode$',
    pure: true
})
export class DeliveryModePipe implements PipeTransform {
    constructor(private deliveryModeService: DeliveryModeService, private routerService: RouterService) {
    }

    public transform(deliveryModeId: string, institutionId: string = this.routerService.institutionId, programId: string = this.routerService.programId): Observable<IDeliveryModeDto> {
        return this.deliveryModeService.getDeliveryMode({ institutionId, programId, deliveryModeId });
    }
}
