export interface IGroupEnrollmentRaceSummaryDto {
    hispanic: boolean;
    white: number;
    black: number;
    americanIndianOrAlaskanNative: number;
    asian: number;
    nativeHawaiianOrPacificIslander: number;
    whiteAndBlack: number;
    whiteAndAmericanIndianOrAlaskanNative: number;
    blackAndAmericanIndianOrAlaskanNative: number;
    whiteAndAsian: number;
    balanceOfOtherCombinations: number;
}
