<div class="modal-header">
  <h5 class="modal-title">Add Projects</h5>
  <button (click)="close()" type="button" class="close" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <div style="margin-right: 20px">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </div>

      </td>
    </ng-container>

    <ng-container matColumnDef="projectName">
      <th mat-header-cell *matHeaderCellDef>State Project</th>
      <td mat-cell *matCellDef="let element"> {{element.projectName}} </td>
    </ng-container>
    <ng-container matColumnDef="projectDescription">
      <th mat-header-cell *matHeaderCellDef>Description</th>
      <td mat-cell *matCellDef="let element"> {{element.projectDescription}} </td>
    </ng-container>
    <!-- <ng-container matColumnDef="url">
      <th mat-header-cell *matHeaderCellDef>Url</th>
      <td mat-cell *matCellDef="let element"> {{element.url}} </td>
    </ng-container> -->

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

</div>
<div class="modal-footer">
  <button id (click)="close()" class="btn btn-secondary my-2 my-sm-0" role="button" aria-pressed="true">Cancel</button>
  <button (click)="save()" class="btn btn-primary my-2 my-sm-0" role="button" aria-pressed="true">Save</button>
</div>