import { Pipe, PipeTransform } from '@angular/core';
import { filter, map, Observable } from 'rxjs';

import { RouterService } from '../services';
import { ProgramService } from '../services/program.service';

@Pipe({
    name: 'program$',
    pure: true
})

export class ProgramPipe implements PipeTransform {

    constructor(private programService: ProgramService, private routerService: RouterService) {
    }

    transform(programId: string, institutionId: string = this.routerService.institutionId): Observable<string> {
        return this.programService.getPrograms({ institutionId: institutionId }).pipe(
            filter(programs => Array.isArray(programs)),
            map(programs => {
                return programs.find(program => program.programId === programId)?.institutionProgramName;
            }));
    }
}
