import { createAction, props } from '@ngrx/store'; import { IInstitutionMemberProgramParams, IActivityDto, IInstitutionMemberProgramEnrollmentParams, IAwardDto, IEnrollmentAwardSubmissionDto } from 'app/core/models';
import { HttpErrorResponse } from '@angular/common/http';

export const LoadAwardsForEnrollmentAction = createAction(
    '[member-awards] Load awards for enrollment',
    props<IInstitutionMemberProgramEnrollmentParams>()
);
export const LoadAwardsForEnrollmentSuccessAction = createAction(
    '[member-awards] Load awards for enrollment success',
    props<IInstitutionMemberProgramEnrollmentParams & { awards: IAwardDto[] }>()
);
export const LoadAwardsForEnrollmentErrorAction = createAction(
    '[member-awards] Load awards for enrollment error',
    props<{ error: HttpErrorResponse }>()
);

export const SaveAwardsForEnrollmentAction = createAction(
    '[member-awards] Save awards for enrollment',
    props<IInstitutionMemberProgramEnrollmentParams & { enrollmentAwardSubmission: IEnrollmentAwardSubmissionDto }>()
);
export const SaveAwardsForEnrollmentSuccessAction = createAction(
    '[member-awards] Save awards for enrollment success',
    props<IInstitutionMemberProgramEnrollmentParams & { enrollmentAwardSubmission: IEnrollmentAwardSubmissionDto }>()
);
export const SaveAwardsForEnrollmentErrorAction = createAction(
    '[member-awards] Save awards for enrollment error',
    props<{ error: HttpErrorResponse }>()
);

export const DeleteAwardsForEnrollmentAction = createAction(
    '[member-awards] Delete awards for enrollment',
    props<IInstitutionMemberProgramEnrollmentParams & { awardId: string }>()
);
export const DeleteAwardsForEnrollmentSuccessAction = createAction(
    '[member-awards] Delete awards for enrollment success',
    props<IInstitutionMemberProgramEnrollmentParams & { awardId: string }>()
);
export const DeleteAwardsForEnrollmentErrorAction = createAction(
    '[member-awards] Delete awards for enrollment error',
    props<{ error: HttpErrorResponse }>()
);
