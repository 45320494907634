import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { ePermissionState } from '../../models/enums/ePermissionState';
import { eAccessControlListRoleType } from '../../models';

@Component({
  selector: 'ng4h-permission-input',
  templateUrl: './permission-input.component.html',
  styleUrls: ['./permission-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PermissionInputComponent),
    multi: true
  }]
})
export class PermissionInputComponent implements ControlValueAccessor {
  @Input() value = '';
  @Input() label = '';
  public ePermissionState = ePermissionState;

  onChange: any = () => { };
  onTouched: any = () => { };


  constructor() {

  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  writeValue(value) {
    if (value != null) {
      this.value = value;
      this.onChange(value);
      this.onTouched();
    }
  }

  protected readonly eAccessControlListRoleType = eAccessControlListRoleType;
}
