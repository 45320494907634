import { createAction, props } from '@ngrx/store'; import { IInstitutionMemberProgramParams, IActivityDto, IInstitutionMemberProgramEnrollmentParams, IAnimalTypeDto, IMemberEnrollmentFileControlDto, IAnimalFormDto, IEnrollmentAnimalSubmitDto, IEnrollmentAnimalDto, IEnrollmentAnimalUpdateDto, IMemberConsentSubmitDto } from 'app/core/models';
import { IEnrollmentActivitySubmissionDto } from 'app/core/models/serverDTOs/IEnrollmentActivitySubmissionDto';
import { HttpErrorResponse } from '@angular/common/http';
import { IEnrollmentAnimalDescriptorDto } from 'app/core/models/serverDTOs/IEnrollmentAnimalDescriptorDto';
import { IInstitutionMemberProgramEventRegistrationParams } from 'app/core/models/function-parameters/institution-member-program-event-registration';

export const LoadAnimalTypesAction = createAction(
    '[member-animals] Load animalTypes',
    props<IInstitutionMemberProgramParams>()
);
export const LoadAnimalTypesSuccessAction = createAction(
    '[member-animals] Load animalTypes success',
    props<IInstitutionMemberProgramParams & { animalTypes: IAnimalTypeDto[] }>()
);
export const LoadAnimalTypesErrorAction = createAction(
    '[member-animals] Load animalTypes error',
    props<{ error: HttpErrorResponse }>()
);

export const LoadAnimalFilesAction = createAction(
    '[member-animals] Load animal files',
    props<IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string }>()
);
export const LoadAnimalFilesSuccessAction = createAction(
    '[member-animals] Load animal files success',
    props<IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string, animalFiles: IMemberEnrollmentFileControlDto[] }>()
);
export const LoadAnimalFilesErrorAction = createAction(
    '[member-animals] Load animal files error',
    props<{ error: HttpErrorResponse }>()
);

export const LoadAnimalFormAction = createAction(
    '[member-animals] from load',
    props<IInstitutionMemberProgramParams & { animalTypeId: string }>()
);
export const LoadFormSuccessAction = createAction(
    '[member-animals] from load success',
    props<IInstitutionMemberProgramParams & { animalTypeId: string, animalForm: IAnimalFormDto }>()
);
export const LoadFormErrorAction = createAction(
    '[member-animals] from load error',
    props<{ error: HttpErrorResponse }>()
);

export const CreateAnimalAction = createAction(
    '[member-animals] create animal',
    props<IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalSubmit: IEnrollmentAnimalSubmitDto }>()
);
export const CreateAnimalSuccessAction = createAction(
    '[member-animals] create animal success',
    props<IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalSubmit: IEnrollmentAnimalSubmitDto, animal: IEnrollmentAnimalDto }>()
);
export const CreateAnimalErrorAction = createAction(
    '[member-animals] create animal error',
    props<{ error: HttpErrorResponse }>()
);
export const AddAnimalToEnrollmentAction = createAction(
    '[member-animals] add animal to enrollment',
    props<IInstitutionMemberProgramEnrollmentParams & { animalId: string }>()
);
export const AddAnimalToEnrollmentSuccessAction = createAction(
    '[member-animals] add animal to enrollment success',
    props<IInstitutionMemberProgramEnrollmentParams & { animalId: string, animal: IEnrollmentAnimalDto }>()
);
export const AddAnimalToEnrollmentErrorAction = createAction(
    '[member-animals] add animal to enrollment error',
    props<{ error: HttpErrorResponse }>()
);

export const LoadAEnrollmentAnimalsAction = createAction(
    '[member-animals] Load enrollment animals',
    props<IInstitutionMemberProgramEnrollmentParams>()
);
export const LoadAEnrollmentAnimalsSuccessAction = createAction(
    '[member-animals] Load enrollment animals success',
    props<IInstitutionMemberProgramEnrollmentParams & { animals: IEnrollmentAnimalDto[] }>()
);
export const LoadAEnrollmentAnimalsErrorAction = createAction(
    '[member-animals] Load enrollment animals error',
    props<{ error: HttpErrorResponse }>()
);

export const LoadAEnrollmentAnimalAction = createAction(
    '[member-animals] Load enrollment animal',
    props<IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string }>()
);
export const LoadAEnrollmentAnimalSuccessAction = createAction(
    '[member-animals] Load enrollment animal success',
    props<IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string, animal: IEnrollmentAnimalDto }>()
);
export const LoadAEnrollmentAnimalErrorAction = createAction(
    '[member-animals] Load enrollment animal error',
    props<{ error: HttpErrorResponse }>()
);

export const LoadAvailableAnimalsAction = createAction(
    '[member-animals] Load available family',
    props<IInstitutionMemberProgramEnrollmentParams>()
);
export const LoadAvailableAnimalsSuccessAction = createAction(
    '[member-animals] Load available family success',
    props<IInstitutionMemberProgramEnrollmentParams & { animals: IEnrollmentAnimalDescriptorDto[] }>()
);
export const LoadAvailableAnimalsErrorAction = createAction(
    '[member-animals] Load available family error',
    props<{ error: HttpErrorResponse }>()
);
export const SubmitAnimalAction = createAction(
    '[member-animals] Submit animal',
    props<IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string }>()
);
export const SubmitAnimalSuccessAction = createAction(
    '[member-animals] Submit animal success',
    props<IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string }>()
);
export const SubmitAnimalErrorAction = createAction(
    '[member-animals] Submit animal error',
    props<{ error: HttpErrorResponse }>()
);
export const UpdateEnrollmentAnimalFormAction = createAction(
    '[member-animals] Update animal form',
    props<IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string, enrollmentAnimalSubmit: IEnrollmentAnimalUpdateDto }>()
);
export const UpdateEnrollmentAnimalFormSuccessAction = createAction(
    '[member-animals] Update animal form success',
    props<IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string, enrollmentAnimalSubmit: IEnrollmentAnimalUpdateDto }>()
);
export const UpdateEnrollmentAnimalFormErrorAction = createAction(
    '[member-animals] Update animal form error',
    props<{ error: HttpErrorResponse }>()
);

export const SaveEnrollmentAnimalCustomFileAction = createAction(
    '[member-animals] Save enrollment animal custom file action',
    props<IInstitutionMemberProgramEnrollmentParams & IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string, fileControlId: string, formData: FormData }>()
);
export const SaveEnrollmentAnimalCustomFileSuccessAction = createAction(
    '[member-animals] Save enrollment animal custom file action success',
    props<IInstitutionMemberProgramEnrollmentParams & IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string, fileControlId: string, formData: FormData }>()
);
export const SaveEnrollmentAnimalCustomFileErrorAction = createAction(
    '[member-animals] Save enrollment animal custom file action error',
    props<{ error: HttpErrorResponse }>()
);
export const DeleteEnrollmentAnimalCustomFileAction = createAction(
    '[member-animals] Delete enrollment animal custom file action',
    props<IInstitutionMemberProgramEnrollmentParams & IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string, fileControlId: string }>()
);
export const DeleteEnrollmentAnimalCustomFileSuccessAction = createAction(
    '[member-animals] Delete enrollment animal custom file action success',
    props<IInstitutionMemberProgramEnrollmentParams & IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string, fileControlId: string }>()
);
export const DeleteEnrollmentAnimalCustomFileErrorAction = createAction(
    '[member-animals] Delete enrollment animal custom file action error',
    props<{ error: HttpErrorResponse }>()
);
export const DeleteEnrollmentAnimalAction = createAction(
    '[member-animals] Delete enrollment animal action',
    props<IInstitutionMemberProgramEnrollmentParams & IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string }>()
);
export const DeleteEnrollmentAnimalSuccessAction = createAction(
    '[member-animals] Delete enrollment animal action success',
    props<IInstitutionMemberProgramEnrollmentParams & IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string }>()
);
export const DeleteEnrollmentAnimalErrorAction = createAction(
    '[member-animals] Delete enrollment animal action error',
    props<{ error: HttpErrorResponse }>()
);


export const EnrollmentAnimalApplyPaymentAction = createAction(
    '[member-animals] Apply payment',
    props<IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string, invoiceId: string, paymentMethodId: string, memberProgramConsentSubmitDto: IMemberConsentSubmitDto }>()
);
export const EnrollmentAnimalApplyPaymentSuccessAction = createAction(
    '[member-animals] Apply payment success',
    props<IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string, invoiceId: string, paymentMethodId: string, memberProgramConsentSubmitDto: IMemberConsentSubmitDto }>()
);
export const EnrollmentAnimalApplyPaymentErrorAction = createAction(
    '[member-animals] Apply payment error',
    props<{ error: HttpErrorResponse }>()
);


export const EnrollmentAnimalApplyAdditionalPaymentAction = createAction(
    '[member-animals] Apply additional payment',
    props<IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string, invoiceId: string, paymentMethodId: string, paymentConsentResponse: IMemberConsentSubmitDto }>()
);
export const EnrollmentAnimalApplyAdditionalPaymentSuccessAction = createAction(
    '[member-animals] Apply additional payment success',
    props<IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string, invoiceId: string, paymentMethodId: string, paymentConsentResponse: IMemberConsentSubmitDto }>()
);
export const EnrollmentAnimalApplyAdditionalPaymentErrorAction = createAction(
    '[member-animals] Apply additional payment error',
    props<{ error: HttpErrorResponse }>()
);


export const EnrollmentAnimalInvalidateCacheAction = createAction(
    '[member-animals] Invalidate cache'
);
