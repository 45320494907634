import { IAddressDto } from '.';

export interface IDiscountFundDonorDto {
  donorId: string;
  donorName?: string;
  businessName?: string;
  phone?: string;
  email?: string;
  address: IAddressDto;
  notes?: string;
}
