import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  IAnimalManagerCommentDto,
  IAnimalTypeDto,
  IInstitutionManagerProgramParams,
  IInstitutionMemberProgramEnrollmentParams,
  IInstitutionProgramParams,
} from 'app/core/models';
import { IEnrollmentAnimalDescriptorDto } from 'app/core/models/serverDTOs/IEnrollmentAnimalDescriptorDto';


export const ManagerGetAnimalTypesAction = createAction(
    '[manager-animals] get animal types',
    props<IInstitutionProgramParams>()
);

export const ManagerGetAnimalTypesSuccessAction = createAction(
    '[manager-animals] get animal types success',
    props<IInstitutionProgramParams & { animalTypes: IAnimalTypeDto[] }>()
);

export const ManagerGetAnimalTypesErrorAction = createAction(
    '[manager-animals] get animal types error',
    props<{ error: HttpErrorResponse }>()
);

export const ApproveAnimalAction = createAction(
    '[manager-animals] Approve animal',
    props<IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string, managerId: string, comments: IAnimalManagerCommentDto }>()
);

export const ApproveAnimalSuccessAction = createAction(
    '[manager-animals] Approve animal success',
    props<IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string, managerId: string, comments: IAnimalManagerCommentDto }>()
);

export const ApproveAnimalErrorAction = createAction(
    '[manager-animals] Approve animal error',
    props<{ error: HttpErrorResponse }>()
);

export const SendBackAnimalAction = createAction(
    '[manager-animals] SendBack animal',
    props<IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string, managerId: string, comments: IAnimalManagerCommentDto }>()
);

export const SendBackAnimalSuccessAction = createAction(
    '[manager-animals] SendBack animal success',
    props<IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string, managerId: string, comments: IAnimalManagerCommentDto }>()
);

export const SendBackAnimalErrorAction = createAction(
    '[manager-animals] SendBack animal error',
    props<{ error: HttpErrorResponse }>()
);

// export const RejectAnimalAction = createAction(
//     '[manager-animals] Reject animal',
//     props<IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string, managerId: string }>()
// );

// export const RejectAnimalSuccessAction = createAction(
//     '[manager-animals] Reject animal success',
//     props<IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string, managerId: string }>()
// );

// export const RejectAnimalErrorAction = createAction(
//     '[manager-animals] Reject animal error',
//     props<{ error: HttpErrorResponse }>()
// );


export const GetSubmittedEnrollmentAnimalsAction = createAction(
    '[manager-animals] Get submitted enrollment animal',
    props<IInstitutionManagerProgramParams & { countyAreaId: string }>()
);

export const GetSubmittedEnrollmentAnimalsSuccessAction = createAction(
    '[manager-animals] Get submitted enrollment animal success',
    props<IInstitutionManagerProgramParams & { countyAreaId: string, enrollmentAnimals: IEnrollmentAnimalDescriptorDto[] }>()
);

export const GetSubmittedEnrollmentAnimalsErrorAction = createAction(
    '[manager-animals] Get submitted enrollment animal error',
    props<{ error: HttpErrorResponse }>()
);

export const UpdateAnimalCommentAction = createAction(
    '[manager-animals] Update animal comment',
    props<IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string, managerComment: IAnimalManagerCommentDto }>()
);

export const UpdateAnimalCommentSuccessAction = createAction(
    '[manager-animals] Update animal comment success',
    props<IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string, managerComment: IAnimalManagerCommentDto }>()
);

export const UpdateAnimalCommentErrorAction = createAction(
    '[manager-animals] Update animal comment error',
    props<{ error: HttpErrorResponse }>()
);


export const RefundAnimalAction = createAction(
    '[manager-animals] Refund animal',
    props<IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string, paymentId: string }>()
);

export const RefundAnimalSuccessAction = createAction(
    '[manager-animals] Refund animal success',
    props<IInstitutionMemberProgramEnrollmentParams & { enrollmentAnimalId: string, paymentId: string }>()
);

export const RefundAnimalErrorAction = createAction(
    '[manager-animals] Refund animal error',
    props<{ error: HttpErrorResponse }>()
);
