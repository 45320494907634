<mat-sidenav-container (swipeleft)="swipeleft()" style="overflow:unset">
  <mat-sidenav #drawer class="drawer-sidenav" [mode]="sidebarMode | async" [opened]="sidebarOpen | async"
    (closedStart)="sidebarClosing()" [ngClass]="{'ng4h-bg-secondary': ['side', 'over'].includes(sidebarMode | async)}">
    <ul (swipeleft)="swipeleft()" class="nav nav-pills flex-column">
      <ng-content select="[ng4h-sidebar-nav]"></ng-content>
    </ul>
  </mat-sidenav>
  <mat-sidenav-content style="max-height: calc(100vh - 80px)">
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>