import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { IInstitutionDescriptorDto } from '../../models';
import { InstitutionService, RouterService } from '../../services';

@Component({
  selector: 'ng4h-modal-anti-discrimination',
  templateUrl: './modal-anti-discrimination.component.html',
  styleUrls: ['./modal-anti-discrimination.component.scss']
})
export class ModalAntiDiscriminationComponent implements OnInit {

  public institution$: Observable<IInstitutionDescriptorDto>;

  constructor(
    private routerService: RouterService,
    private institutionService: InstitutionService,
    private dialogRef: MatDialogRef<any>,
  ) { }
  ngOnInit(): void {
    this.institution$ = this.institutionService.getInstitution({ institutionId: this.routerService.institutionId });
  }


  public close(): void {
    this.dialogRef.close();
  }


}
