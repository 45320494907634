import { eInstitutionDiscountType, eInvoiceDiscountType } from '..';
import { ICreditCollectionDto } from './ICreditCollectionDto';

export interface ICreditDescriptorDto {
    invoiceDiscountId: string;
    institutionDiscountId: string;
    creditType: eInstitutionDiscountType;
    amount: number;
    amountDue: number;
    description?: string;
    collected: boolean;
    collectedDate?: string;
    notes?: string;
    appliedToInvoiceItemId: string;
    invoiceDiscountType: eInvoiceDiscountType;
    collections?: ICreditCollectionDto[];
}
