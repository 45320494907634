import { Action, createReducer, on } from '@ngrx/store';
import { IInstitutionProgramDto, IInstitutionProgramModel, IProgramSummaryDto } from 'app/core/models';

import { ProgramActions } from '.';
import { mergeImmutable } from '../utils';

export interface ProgramState {
    institutionPrograms: { [institutionId: string]: IProgramSummaryDto[] };
    countyAreaPrograms: { [institutionId: string]: { [countyAreaId: string]: IProgramSummaryDto[] } };
    institutionProgram: IInstitutionProgramModel<IInstitutionProgramDto>;
}

const initialState: ProgramState = {
    institutionPrograms: undefined,
    countyAreaPrograms: undefined,
    institutionProgram: undefined
};

const reducer = createReducer(
    initialState,
    on(ProgramActions.ProgramsLoadSuccessAction, (state, { institutionId, programs }) => {
        const newInstitutionPrograms = mergeImmutable(
            { [institutionId]: programs },
            state.institutionPrograms
        );
        return {
            ...state,
            institutionPrograms: newInstitutionPrograms
        };
    }),
    on(ProgramActions.ProgramsLoadCountyAreaSuccessAction, (state, { institutionId, countyAreaId, programs }) => {
        const newCountyAreaPrograms = mergeImmutable(
            { [institutionId]: { [countyAreaId]: programs } },
            state.countyAreaPrograms
        );
        return {
            ...state,
            countyAreaPrograms: newCountyAreaPrograms
        };
    }),
    on(ProgramActions.ProgramLoadSuccessAction, (state, { institutionId, programId, institutionProgram }) => {
        const newInstitutionProgram = mergeImmutable(
            { [institutionId]: { [programId]: institutionProgram } },
            state.institutionProgram
        );
        return {
            ...state,
            institutionProgram: newInstitutionProgram
        };
    }),
    on(ProgramActions.ClearProgramCacheAction, (state, { }) => {
        return initialState;
    })
);

export function programReducer(state: ProgramState | undefined, actions: Action) {
    return reducer(state, actions);
}

export const institutionPrograms = (state: ProgramState) => state.institutionPrograms;
export const institutionProgram = (state: ProgramState) => state.institutionProgram;
export const countyAreaPrograms = (state: ProgramState) => state.countyAreaPrograms;
