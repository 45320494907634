import { Pipe, PipeTransform } from '@angular/core';
import { IManagerInstitutionProfile } from 'app/core/models';
import { ManagerService, RouterService } from 'app/core/services';
import { Observable } from 'rxjs';

@Pipe({
    name: 'manager$',
    pure: true
})
export class ManagerPipe implements PipeTransform {
    constructor(private managerService: ManagerService, private routerService: RouterService) {
    }

    public transform(managerId: string, institutionId: string = this.routerService.institutionId): Observable<IManagerInstitutionProfile> {
        return this.managerService.getManager({ institutionId: institutionId, managerId: managerId });
    }
}
