import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IFileControl } from 'app/core/models';

@Component({
  selector: 'ng4h-modal-manager-edit-enrollment-projects',
  templateUrl: './modal-manager-edit-enrollment-projects.component.html',
  styleUrls: ['./modal-manager-edit-enrollment-projects.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalManagerEditEnrollmentProjectsComponent implements OnInit {



  constructor(private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public fileControl: IFileControl,
  ) {
  }


  ngOnInit(): void {
  }

  public close(result?: any): void {
    this.dialogRef.close(result);
  }

}
