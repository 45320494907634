<div class="modal-section">
    <div class="modal-header">
      <h5 class="modal-title">Project</h5>
      <button (click)="cancel()" type="button" class="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <div class="ng4h-panel-body ng4h-table">
        <div class="col-12">
          <div class="row">
            <div class="col-4 ng4h-bg-secondary"><b>Project Name</b></div>
            <div class="col-8">{{ project.projectName }}</div>
          </div>
          <div class="row">
            <div class="col-4 ng4h-bg-secondary"><b>Description</b></div>
            <div class="col-8">{{ project.projectDescription }}</div>
          </div>
          <div class="row">
            <div class="col-4 ng4h-bg-secondary"><b>Url</b></div>
            <div class="col-8">{{project.url}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <button id (click)="cancel()" class="btn btn-secondary my-2 my-sm-0" role="button"
        aria-pressed="true">Cancel</button>
      <button (click)="delete()" class="btn btn-danger my-2 my-sm-0" role="button"
        aria-pressed="true">Delete</button>
    </div>
  </div>
