import { FormControl, ValidationErrors } from '@angular/forms';

export class DateValidators {

  static AppMin(formControl: FormControl): ValidationErrors {
    let date = formControl.value;
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    if (date.getFullYear() > 1753) {
      return null;
    }
    return { 'app_min': 'Date is below min app date' };
  }

  static AppMax(formControl: FormControl): ValidationErrors {
    let date = formControl.value;
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    if (date.getFullYear() < 9999) {
      return null;
    }
    return { 'app_max': 'Date is above max app date' };
  }
}
