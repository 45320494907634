import { Pipe, PipeTransform } from '@angular/core';
import { RouterService } from 'app/core/services';

import { ProgramSettingsService } from '../services/program-settings.service';

@Pipe({
    name: 'programLogoSrc',
    pure: true
})
export class ProgramLogoSrcPipe implements PipeTransform {
    constructor(private programSettingsService: ProgramSettingsService, private routerService: RouterService) {
    }

    public transform(programId: string, institutionId: string = this.routerService.institutionId): string {
        return this.programSettingsService.getProgramLogoSrc({ institutionId, programId });
    }
}
