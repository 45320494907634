<div *ng4hPlaceholder="(payments$ | async)?.paymentMethods as paymentMethods; size: [100, 500]">
  <ng-container *ngIf="invoice.amountDue <= 0">
    <h3 class="section-title">No Payment Necessary</h3>
  </ng-container>


  <ng-container *ngIf="invoice.status == eInvoiceStatus.Paid">
    <h3 class="section-title">Payment Pending</h3>
  </ng-container>

  <ng-container *ngIf="invoice.status == eInvoiceStatus.Payable">
    <ng-container *ng4hPlaceholder="gatewayConfig$ | async as gatewayConfig; size: [100, 400]">

      <ng-container [ngSwitch]="gatewayConfig.gateway">
        <ng-container *ngSwitchCase="ePaymentGatewayType.TouchNet">

          <ng-container>
            <div class="col-12">
              <h4 class="ng4h-margin-bottom">Select a Card
                <button class="btn btn-sm btn-secondary float-right" (click)="addNewCard()">Add New Card</button>
              </h4>
            </div>

            <div class="section-item card" *ngFor="let payment of paymentMethods" (click)="paymentClicked(payment)"
              [ngClass]="{'selected': payment.paymentMethodId == paymentMethod?.paymentMethodId }">
              <div class="col-12">{{payment.cardHolderName}} </div>
              <b class="col-12">{{payment.type}}</b>
              <div class="col-12">{{payment.maskedAccountNumber}}</div>
              <div class="col-12"
                *ngIf="payment.expirationDate != null && payment.expirationDate != '9999-01-01T00:00:00'">
                {{payment.expirationDate | date: 'MM/yyyy'}}</div>
            </div>
            <div class="section-item col-12" *ngIf="paymentMethods?.length < 1">
              <b>No Cards</b>
            </div>


          </ng-container>

          <div class="section-item" *ngIf="init == false">


            <div class="col-12">


              <ng4h-touchnet-credit-card-input-component [gatewayConfig]="gatewayConfig">
              </ng4h-touchnet-credit-card-input-component>




            </div>

          </div>

        </ng-container>
        <ng-container *ngSwitchDefault>

          <ng-container *ngIf="paymentMethods?.length > 0">
            <div class="col-12">
              <h4 class="ng4h-margin-bottom">Select a Card
                <button *ngIf="paymentMethod?.paymentMethodId != null || init == true"
                  class="btn btn-sm btn-secondary float-right" (click)="addNewCard()">Add New Card</button>
              </h4>
            </div>

            <div class="section-item card" *ngFor="let payment of paymentMethods" (click)="paymentClicked(payment)"
              [ngClass]="{'selected': payment.paymentMethodId == paymentMethod?.paymentMethodId }">
              <div class="col-12">{{payment.cardHolderName}} </div>
              <b class="col-12">{{payment.type}}</b>
              <div class="col-12">{{payment.maskedAccountNumber}}</div>
              <div class="col-12"
                *ngIf="payment.expirationDate != null && payment.expirationDate != '9999-01-01T00:00:00'">
                {{payment.expirationDate | date: 'MM/yyyy'}}</div>
            </div>

            <div class="col-12" style="text-align: center;"
              *ngIf="paymentMethod?.paymentMethodId  != null || init == true">

            </div>
          </ng-container>

          <div class="section-item"
            *ngIf="(paymentMethod?.paymentMethodId  == null && !init) || paymentMethods?.length < 1">
            <div [hidden]="savingCard">
              <b class="col-12">Add New Card</b>
              <div class="col-12">

                <ng-container [ngSwitch]="gatewayConfig.gateway">
                  <ng-container *ngSwitchCase="ePaymentGatewayType.Vantiv">
                    <ng4h-vantiv-credit-card-input [gatewayConfig]="gatewayConfig"
                      (tokenizationSuccess)="creditCardSuccessfullyTokenized($event)" class="col-12">
                    </ng4h-vantiv-credit-card-input>
                  </ng-container>
                  <ng-container *ngSwitchCase="ePaymentGatewayType.AuthorizeNet">
                    <ng4h-authorize-net-credit-card-input [gatewayConfig]="gatewayConfig"
                      (tokenizationSuccess)="creditCardSuccessfullyTokenized($event)">
                    </ng4h-authorize-net-credit-card-input>
                  </ng-container>
                  <ng-container *ngSwitchCase="ePaymentGatewayType.Stripe">
                    <ng4h-stripe-credit-card-input [gatewayConfig]="gatewayConfig"
                      (tokenizationSuccess)="creditCardSuccessfullyTokenized($event)">
                    </ng4h-stripe-credit-card-input>
                  </ng-container>

                  <ng-container *ngSwitchDefault>
                    <h4>Payment Gateway Not Found</h4>
                  </ng-container>
                </ng-container>


              </div>
            </div>
            <div class="col-12" *ngIf="savingCard">
              <b>Saving card...</b>
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
          </div>

        </ng-container>
      </ng-container>



    </ng-container>
  </ng-container>
</div>