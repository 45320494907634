export enum eEventRegistrationStateStatus {
    Incomplete = 'Incomplete',
    PendingApproval = 'PendingApproval',
    Approved = 'Approved',
    Declined = 'Declined',
    Resubmit = 'Resubmit',
    Canceled = 'Canceled',
    Completed = 'Completed',
    OutstandingInvoice = 'OutstandingInvoice',
    PaymentNotReceivedByInstitution = 'PaymentNotReceivedByInstitution'
}
