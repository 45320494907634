<ng-container *ngIf="(loggedInInstitutionProfileId$ | async) || (superuserId$ | async)">
  <nav aria-label="Header" class="ng4h-navbar" style="z-index: 999;">

    <ul class="navbar-nav mr-auto">
      <li class="nav-item hamburger" *ngIf="showHamburger | async" (click)="hamburgerClicked()">
        <button style="border:none; background:none;">
          <i [matBadge]="(showActionBadge$ | async) == true ? '!': null" matBadgeColor="warn" matBadgePosition="after" alt="menu" matBadgeOverlap="false" class="fa fa-bars"></i>
        </button>
      </li>

    </ul>

    <div class="sidebar-header">
      <ng-container *ngIf="!(showHamburger | async)">
        <ng-container *ngIf="logoResource$ | async as logoResource">
          <img [src]="logoResource" class="institution-logo" alt="institution-logo">
        </ng-container>
      </ng-container>
    </div>

    <div id="program-name">{{ programId$ | async | program$:(institutionId$ | async) | async }}</div>

    <span class="spacer"></span>

    <ng-container *ngIf="activeUserInstitutionProfile$ | async as activeUserInstitutionProfile; else noInstitutionProfile">
      <ng-container *ngIf="actingOnBehalfOf$ | async as actingOnBehalfOf">
        
        <div style="display: flex;padding-right: 10px;" *ng4hVar="((actingOnBehalfOf.profileType == eProfileType.Family || actingOnBehalfOf.profileType == eProfileType.Manager || actingOnBehalfOf.profileType == eProfileType.Member) && (activeUserInstitutionProfile.profileType == eProfileType.Manager || activeUserInstitutionProfile.profileType == eProfileType.Superuser)) as showManagerHeaderOptions">
        
          <ng-container *ngIf="actingOnBehalfOf.profileType == eProfileType.Manager">
            <button class="global-search" (click)="globalSearch()">
              <i class="far fa-2x fa-search"></i>
            </button>
          </ng-container>

          <ng-container *ngIf="showManagerHeaderOptions && !environment.production">
            <button class="quick-interaction" (click)="loadInteractions()">
              <i class="fas fa-md fa-mail-bulk"></i>
            </button>
          </ng-container>

          <ng-container *ngIf="actingOnBehalfOf.profileType == eProfileType.Manager">
            <button class="hierarchy-selection" (click)="changeHierarchy()">
              <i class="fas fa-md fa-sitemap"></i>
            </button>
          </ng-container>

          <ng-container *ngIf="showManagerHeaderOptions && activeUserInstitutionProfile.institutionProfileId != actingOnBehalfOf.institutionProfileId">
            <button class="back-to-role" (click)="profileUp(actingOnBehalfOf, activeUserInstitutionProfile)">
              <i class="fas fa-3x fa-arrow-alt-circle-up"></i>
            </button>
          </ng-container>

          <ng-container *ngIf="activeUserInstitutionProfile.profileType == eProfileType.Superuser && actingOnBehalfOf.profileType == eProfileType.Superuser">
            <button class="back-to-role" (click)="backToSuperUser()">
              <i style="color: red;" class="fas fa-3x fa-arrow-alt-circle-up"></i>
            </button>
          </ng-container>
          
        </div>

        <button class="profile-dropdown ng4h-border-right ng4h-border-left ng4h-padding-right ng4h-padding-left" [matMenuTriggerFor]="signOutMenu">
          <div class="row">
            <div class="col-12">
              <ng-container [ngSwitch]="activeUserInstitutionProfile.profileType">
                <ng-container *ngSwitchCase="eProfileType.ReportConsumer">
                  <b class="primary">{{ activeUserInstitutionProfile.firstName }}&nbsp;{{ activeUserInstitutionProfile.lastName }} </b>
                </ng-container>
                <ng-container *ngSwitchCase="eProfileType.Superuser">
                  <b class="primary">{{ activeUserInstitutionProfile.firstName }}&nbsp;{{ activeUserInstitutionProfile.lastName }} </b>
                </ng-container>
                <ng-container *ngSwitchCase="eProfileType.SystemManager">
                  <ng-container *ngIf="activeUserInstitutionProfile.profileType != eProfileType.Superuser; else superuserSystemManager">
                    <b class="primary">{{ activeUserInstitutionProfile.firstName }}&nbsp;{{ activeUserInstitutionProfile.lastName }}</b>
                  </ng-container>
                  <ng-template #superuserSystemManager>
                    <a class="link" (click)="superuserClickedSystemManagerName()"> <b class="primary">{{ activeUserInstitutionProfile.firstName }}&nbsp;{{ activeUserInstitutionProfile.lastName }}</b> </a>
                  </ng-template>
                </ng-container>
                <ng-container *ngSwitchCase="eProfileType.Manager">
                  <ng-container *ngIf="activeUserInstitutionProfile.profileType != eProfileType.Superuser; else superuserManager">
                    <b class="primary">{{ activeUserInstitutionProfile.firstName }}&nbsp;{{ activeUserInstitutionProfile.lastName }} </b>
                  </ng-container>
                  <ng-template #superuserManager>
                    <a class="link" (click)="superuserClickedManagerName()"> <b class="primary">{{ activeUserInstitutionProfile.firstName }}&nbsp;{{ activeUserInstitutionProfile.lastName }} - </b> </a>
                  </ng-template>
                </ng-container>
                <ng-container *ngSwitchCase="eProfileType.Member">
                  <b class="primary">{{ activeUserInstitutionProfile.firstName }}&nbsp;{{ activeUserInstitutionProfile.lastName }}</b>
                </ng-container>
                <ng-container *ngSwitchCase="eProfileType.Family">
                  <b class="primary">{{ activeUserInstitutionProfile.familyName }}&nbsp;Family</b>
                </ng-container>
              </ng-container>

              <ng-container [ngSwitch]="actingOnBehalfOf.profileType">
                <ng-container *ngSwitchCase="eProfileType.Superuser">
                  <ng-container>
                    <div class="secondary" *ngIf="actingOnBehalfOf.institutionProfileId != activeUserInstitutionProfile.institutionProfileId">
                      as&nbsp;{{ actingOnBehalfOf.firstName }}&nbsp;{{ actingOnBehalfOf.lastName }}
                    </div>
                    <div class="secondary">
                      <!-- {{actingOnBehalfOf.institutionId}}  -->Superuser
                    </div>
                    <div class="secondary"> {{ (undefined | institution$ | async)?.institutionName }}</div>
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="eProfileType.SystemManager">
                  <ng-container>
                    <div class="secondary" *ngIf="actingOnBehalfOf.institutionProfileId != activeUserInstitutionProfile.institutionProfileId">
                      as&nbsp;{{ actingOnBehalfOf.firstName }}&nbsp;{{ actingOnBehalfOf.lastName }}
                    </div>
                    <div class="secondary">
                      System Manager
                    </div>
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="eProfileType.Manager">
                  <ng-container *ngIf="managerHierarchyNode$ | async as managerHierarchyNode">
                    <div class="secondary" *ngIf="actingOnBehalfOf.institutionProfileId != activeUserInstitutionProfile.institutionProfileId">
                      as&nbsp;{{ actingOnBehalfOf.firstName }}&nbsp;{{ actingOnBehalfOf.lastName }} - Manager
                    </div>
                    <div class="secondary"> {{ managerHierarchyNode.hierarchyNodeName }},{{ managerHierarchyNode.hierarchyNodeType | hierarchyNodeType }}</div>
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="eProfileType.Member">
                  <div class="secondary" *ngIf="actingOnBehalfOf.institutionProfileId != activeUserInstitutionProfile.institutionProfileId">
                    as&nbsp;{{ actingOnBehalfOf.firstName }}&nbsp;{{ actingOnBehalfOf.lastName }}
                  </div>
                  <div class="secondary">Member</div>
                </ng-container>
                <ng-container *ngSwitchCase="eProfileType.Family">
                  <div class="secondary" *ngIf="actingOnBehalfOf.institutionProfileId != activeUserInstitutionProfile.institutionProfileId">
                    as&nbsp;{{ actingOnBehalfOf.familyName }}&nbsp;-&nbsp;Family
                  </div>
                  <div class="secondary" *ngIf="actingOnBehalfOf.countyHierarchyNodeId | hierarchyNode$ | async as familyCounty">
                    {{ familyCounty.hierarchyNodeName }},&nbsp;{{ familyCounty.hierarchyNodeType | hierarchyNodeType }}
                  </div>
                </ng-container>
              </ng-container>

            </div>
            <div class="header-chevron">
              <i class="fas fa-chevron-down" style="color: #0C6E6E;"></i>
            </div>
          </div>
        </button>
        <mat-menu #signOutMenu="matMenu">

          <button *ngIf="activeUserInstitutionProfile.profileType == eProfileType.Superuser" mat-menu-item (click)="changeInstitution()">
            <mat-icon>swap_horiz</mat-icon>
            <span>Change Institution</span>
          </button>

          <button *ngIf="activeUserInstitutionProfile.profileType == eProfileType.Family && (usersInstitutionProfiles$ | async)?.length > 1" mat-menu-item (click)="changeFamilyInstitution()">
            <mat-icon>swap_horiz</mat-icon>
            <span>Change Institution</span>
          </button>

          <button *ngIf="actingOnBehalfOf.profileType == eProfileType.SystemManager && asSystemManager(actingOnBehalfOf.acl | keyvalue)?.length > 1" mat-menu-item (click)="changeSystemManagerProgram(actingOnBehalfOf)">
            <i style="margin-right: 16px; margin-left: 5px;" class="fas fa-sitemap"></i> <span>Change Program</span>
          </button>

          <button *ngIf="actingOnBehalfOf.profileType == eProfileType.Manager && asManager(actingOnBehalfOf.managerPrograms | keyvalue)?.length > 1" mat-menu-item (click)="changeManagerProgram(actingOnBehalfOf)">
            <i style="margin-right: 16px; margin-left: 5px;" class="fas fa-sitemap"></i> <span>Change Program</span>
          </button>

          <button *ngIf="actingOnBehalfOf.profileType == eProfileType.Manager" mat-menu-item (click)="changeHierarchy()">
            <i style="margin-right: 16px; margin-left: 5px;" class="fas fa-sitemap"></i> <span>Change Hierarchy</span>
          </button>
          <button *ngIf="(activeUserInstitutionProfile.profileType == eProfileType.Manager || activeUserInstitutionProfile.profileType == eProfileType.SystemManager || activeUserInstitutionProfile.profileType == eProfileType.Superuser) && (usersInstitutionProfiles$ | async)?.length > 1" mat-menu-item (click)="changeProfile()">
            <mat-icon>swap_horiz</mat-icon>
            <span>Change Profile</span>
          </button>

          <button *ngIf="isExtension" mat-menu-item (click)="contactUs()">
            <mat-icon>alternate_email</mat-icon>
            <span>Contact Us</span>
          </button>

          <button mat-menu-item (click)="signOut()">
            <mat-icon>exit_to_app</mat-icon>
            <span>Sign Out</span>
          </button>

        </mat-menu>

        <ng-container *ngIf="actingOnBehalfOf.profileType == eProfileType.Manager">
          <div class="nav-item hamburger float-right" *ngIf="showHamburger | async" (click)="managerHamburgerClicked()">
            <i class="fa fa-bars"></i>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-template #noInstitutionProfile>
      <ng-container *ngIf="superuserId$ | async">
        <ng-container *ngIf="userAccount$ | async as userAccount">

          <button class="profile-dropdown ng4h-border-right ng4h-border-left ng4h-padding-right ng4h-padding-left" [matMenuTriggerFor]="signOutMenu">
            <div class="row">
              <div class="col-12">

                <b class="primary">{{ userAccount.firstName }}{{ userAccount.lastName }} </b>
                <div class="secondary">
                  Superuser
                </div>
                <div class="secondary"> No Institution</div>

              </div>
              <div class="header-chevron">
                <i class="fas fa-chevron-down" style="color: #0C6E6E;"></i>
              </div>
            </div>
          </button>
          <mat-menu #signOutMenu="matMenu">

            <button *ngIf="(usersInstitutionProfiles$ | async)?.length > 1" mat-menu-item (click)="changeProfile()">
              <mat-icon>swap_horiz</mat-icon>
              <span>Change Profile</span>
            </button>

            <button mat-menu-item (click)="signOut()">
              <mat-icon>exit_to_app</mat-icon>
              <span>Sign Out</span>
            </button>

          </mat-menu>
        </ng-container>
      </ng-container>
    </ng-template>

  </nav>
</ng-container>