import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MemberHealthFormService } from 'app/core/services';

@Component({
  selector: 'ng4h-modal-add-medication',
  templateUrl: './modal-add-medication.component.html',
  styleUrls: ['./modal-add-medication.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalAddMedicationComponent implements OnInit {

  public form: FormGroup;
  public medicationFields: any;

  constructor(private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) private data,
    private healthFormService: MemberHealthFormService) { }

  public ngOnInit() {

    this.medicationFields = { Medication:  this.data.medicationFields };

    this.form = this.healthFormService.toFormGroup(this.medicationFields);
  }

  public close() {
    this.dialogRef.close();
  }

  public add() {
    this.dialogRef.close(this.form.value.Medication);
  }
}
