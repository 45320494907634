import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  IEnrollmentDto,
  IEnrollmentVolunteerTypeSubmissionDto,
  IFileControl,
  IMemberConsentSubmitDto,
} from 'app/core/models';
import { CommonToastrService, EnrollmentService, ModalService, RouterService } from 'app/core/services';
import { Observable, switchMap, take } from 'rxjs';

@Component({
  selector: 'ng4h-modal-edit-enrollment-volunteer-types',
  templateUrl: './modal-edit-enrollment-volunteer-types.component.html',
  styleUrls: ['./modal-edit-enrollment-volunteer-types.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalEditEnrollmentVolunteerTypesComponent implements OnInit {


  public enrollment$: Observable<IEnrollmentDto>;

  constructor(private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public fileControl: IFileControl,
    private enrollmentService: EnrollmentService,
    private routerService: RouterService,
    private modalService: ModalService,
    private toastrService: CommonToastrService
  ) {

    this.enrollment$ = this.enrollmentService.getEnrollment({
      institutionId: this.routerService.institutionId,
      memberId: this.routerService.memberId ? this.routerService.memberId : this.routerService.selectedMemberId,
      programId: this.routerService.programId,
      enrollmentId: this.routerService.enrollmentId
    });
  }


  ngOnInit(): void {
  }

  public save() {

    this.enrollment$.pipe(
      take(1),
      switchMap(enrollment => {
        const volunteerTypeSubmission = enrollment.volunteerTypes.map(volunteerType => {
          const submission: IEnrollmentVolunteerTypeSubmissionDto = {
            volunteerTypeId: volunteerType.volunteerTypeId,
            volunteerTypeConsents: volunteerType.consents ? volunteerType.consents.map(consent => {
              const submitConsent: IMemberConsentSubmitDto = {
                consentVersionId: consent.consentVersion.consentVersionId,
                recordedMemberName: consent.recordedMemberName,
                recordedParentGuardianName: consent.recordedParentGuardianName,
                recordedManagerName: consent.recordedManagerName ?? '',
                consentStatus: consent.consentStatus
              };
              return submitConsent;
            }) : null
          };
          return submission;
        });
        return this.enrollmentService.saveVolunteerTypes({
          institutionId: this.routerService.institutionId,
          programId: this.routerService.programId,
          enrollmentId: this.routerService.enrollmentId,
          memberId: this.routerService.memberId ? this.routerService.memberId : this.routerService.selectedMemberId,
          volunteerTypeSubmission: volunteerTypeSubmission
        });
      }),
      take(1),
      switchMap(() => this.enrollmentService.refreshEnrollment()),
      take(1),
      this.modalService.showProgressSpinnerModalUntilComplete()
    ).subscribe(() => this.close(), error => {
      this.toastrService.error('Could not save volunteer types', error);
    });
  }
  public cancel() {
    this.enrollmentService.refreshEnrollment().pipe(
      take(1),
      this.modalService.showProgressSpinnerModalUntilComplete()
    ).subscribe(() => this.close());
  }
  public close(result?: any): void {
    this.dialogRef.close(result);
  }

}
