import { IProjectDto } from '.';
import { eActivityStatus } from '..';

export interface IActivityDto {
    activityId: string;
    status: eActivityStatus;
    label?: string;
    description?: string;
    code?: string;
    hierarchyNodeId: string;
    project: IProjectDto;
    allowMembersToRegister: boolean;
}
