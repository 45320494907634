import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { IDeliveryModeDto } from 'app/core/models';
import { RouterService } from 'app/core/services';
import { DeliveryModeService } from 'app/core/services/delivery-mode.service';
import { combineLatest, Observable, switchMap } from 'rxjs';


@Component({
  selector: 'ng4h-delivery-mode-dropdown',
  templateUrl: './delivery-mode-dropdown.component.html',
  styleUrls: ['./delivery-mode-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DeliveryModeDropdownComponent),
    multi: true
  }]
})
export class DeliveryModeDropdownComponent implements OnInit {

  @Input() value = null;

  public deliveryModes$: Observable<IDeliveryModeDto[]>;

  onChange: any = () => { };
  onTouched: any = () => { };

  constructor(
    private deliveryModeService: DeliveryModeService,
    private routerService: RouterService
  ) {

  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  ngOnInit() {
    this.deliveryModes$ = combineLatest([this.routerService.institutionId$, this.routerService.programId$]).pipe(
      switchMap(([institutionId, programId]) => {
        return this.deliveryModeService.getDeliveryModes({ institutionId, programId });
      }));
  }

  changed(value) {
    this.writeValue(value);
    this.onTouched();
  }

  writeValue(value) {
    this.value = value;
    this.onChange(value);
  }

}
