import { eCustomReportCategory } from '..';

export interface ICustomReportDescriptorDto {
    reportId: string;
    category: eCustomReportCategory;
    reportName?: string;
    description?: string;
    inTrash: boolean;
    shared: boolean;
    isActingManagersReport: boolean;
}
