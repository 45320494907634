import { eEnrollmentRoleType, eEnrollmentStatus } from '..';

export interface IEnrollmentDescriptorDto {
    enrollmentId: string;
    active: boolean;
    programYearId: string;
    programId: string;
    enrollmentRole: eEnrollmentRoleType;
    enrollmentStatus: eEnrollmentStatus;
    dateSubmitted?: string;
    dateApproved?: string;
    dateReSubmitted?: string;
}
