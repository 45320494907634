<form [formGroup]="form">
  <div class="form-group row">
    <label for="addressLine1" class="form-control-label col-md-3">Address</label>
    <div class="col-md-9">
      <input name="addressLine1" formControlName="addressLine1" class="form-control" type="text">
    </div>
  </div>
  <div class="form-group row">
    <label for="addressLine2" class="form-control-label col-md-3">Address 2</label>
    <div class="col-md-9">
      <input name="addressLine2" formControlName="addressLine2" class="form-control" type="text">
    </div>
  </div>
  <div class="form-group row">
    <label for="city" class="form-control-label col-md-3">City</label>
    <div class="col-md-9">
      <input name="city" formControlName="city" class="form-control" type="text">
    </div>
  </div>
  <div class="form-group row">
    <label for="state" class="form-control-label col-md-3">State</label>
    <div class="col-md-9">
      <!-- <input formControlName="state" class="form-control" type="text"> -->
      <ng4h-state-dropdown name="state" formControlName="state"></ng4h-state-dropdown>
    </div>
  </div>
  <div class="form-group row">
    <label for="postalCode" class="form-control-label col-md-3">Postal Code</label>
    <div class="col-md-9">
      <input name="postalCode" formControlName="postalCode" class="form-control" type="text">
    </div>
  </div>
  <div class="form-group row">
    <label for="country" class="form-control-label col-md-3">Country</label>
    <div class="col-md-9">
      <input name="country" formControlName="country" class="form-control" type="text">
    </div>
  </div>
</form>