import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dynamicDisplayToPipe',
    pure: true
})
export class DynamicDisplayToPipe implements PipeTransform {
    public transform(value: number[], formDisplayTypes: { id: number, name: string }[]): string {
        if (value.length === 0) {
            return 'None';
        }
        return value.map(num => {
            const displayType = formDisplayTypes.find(type => {
                return type.id === num;
            });
            if (displayType != null) {
                return displayType.name;
            } else {
                return null;
            }
        }).join(', ');
    }
}
