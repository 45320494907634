<div class="modal-header">
    <h5 class="modal-title">Change Screening Level</h5>
    <button (click)="close()" type="button" class="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>


<div class="modal-body">
    <form [formGroup]="form" *ngIf="form != null">

        <div class="form-group row">
            <label class="ng4h-required form-control-label col-md-3">Type</label>
            <div class="col-md-9">
                <select formControlName="screeningType" class="form-control">
                    <option [value]="type" *ngFor="let type of availableScreeningTypes$ | async"> {{type}}
                    </option>
                </select>
            </div>
        </div>

    </form>
</div>

<div class="modal-footer">
    <button style="margin-left: auto;" class="btn btn-secondary" (click)="close()">Cancel</button>
    <button [disabled]="form == null || form?.invalid" (click)="save()" class="btn btn-primary my-2 my-sm-0"
        role="button" aria-pressed="true">Save</button>
</div>