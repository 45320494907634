import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ICustomReportDto } from 'app/core/models';

@Component({
  selector: 'ng4h-modal-edit-custom-report',
  templateUrl: './modal-edit-custom-report.component.html',
  styleUrls: ['./modal-edit-custom-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalEditCustomReportComponent implements OnInit {

  public form: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<any>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public report: ICustomReportDto
  ) { }

  public ngOnInit() {
    this.form = this.fb.group({
      reportName: [this.report.reportName],
      description: [this.report.description],
      excelWorksheetTitle: [this.report.excelWorksheetTitle]
    });
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public update(): void {
    this.dialogRef.close(this.form.value);
  }
}
