import { IInvoiceDto, IMemberDescriptorDto, IMemberProgramConsentDto, IStandardFormColumnDto } from '.';
import { eAnimalMarketCategory, eAnimalSpecies, eAvailableAnimalCategory, eEnrollmentAnimalStatus } from '..';

export interface IEnrollmentAnimalDto {
    consents?: IMemberProgramConsentDto[];
    fields?: { [category: string]: IStandardFormColumnDto[] };
    identifierField: IStandardFormColumnDto;
    managerComment?: string;
    publicComment?: string;
    sentBackReason?: string;
    pageMessage_Manager?: string;
    pageMessage_Member?: string;
    dateApproved?: string;
    dateCreated: string;
    dateModified: string;
    canEdit: boolean;
    canDelete: boolean;
    canSubmit: boolean;
    memberModificationStartDate: string;
    memberModificationEndDate: string;
    managerModificationStartDate: string;
    managerModificationEndDate: string;
    invoices?: IInvoiceDto[];
    hasFees: boolean;
    hasPendingPayment: boolean;
    paymentRequired: boolean;
    electronicPaymentsEnabled: boolean;
    payableInvoice: IInvoiceDto;
    enrollmentAnimalId: string;
    enrollmentId: string;
    availableAnimalCategory?: eAvailableAnimalCategory;
    status: eEnrollmentAnimalStatus;
    animalIdentifier?: string;
    dateSubmitted?: string;
    member: IMemberDescriptorDto;
    animalId: string;
    animalTypeId: string;
    animalSpecies: eAnimalSpecies;
    marketCategory: eAnimalMarketCategory;
    animalTypeLabel?: string;
}
