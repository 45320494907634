<div class="template-row" [formGroup]="form">

  <ng-container *ngIf="editMode; else showPreview">
    <ng4h-card>
      <ng4h-card-header>
        <h5>Template</h5>
      </ng4h-card-header>

      <ng4h-card-content>
        <p-editor #editor formControlName="content" [style]="{'height':'320px'}" (onInit)="quillOnInit($event)"
          pEditorDefaultModules>
          <ng-template pTemplate="header">
            <ng4h-quill-editor-header-full [QuillEditor]="editor.quill"></ng4h-quill-editor-header-full>
          </ng-template>
        </p-editor>
      </ng4h-card-content>
    </ng4h-card>
  </ng-container>
  <ng-template #showPreview>
    <ng4h-card>
      <ng4h-card-header>
        <h5>Template Preview</h5>
      </ng4h-card-header>

      <ng4h-card-content>
        <div [innerHTML]="preview"></div>
      </ng4h-card-content>
    </ng4h-card>
  </ng-template>

</div>

<!-- <div class="d-flex flex-row template-row justify-content-center" *ngIf="editMode">
  <button type="button" class="btn btn-primary btn-sm" (click)="save()">Save</button>
</div> -->