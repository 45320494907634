<div class="row">
  <div class="col-6">
    <div *ngFor="let keyvalue of standardFormConfig.fields[localizationType] | keyvalue; trackBy: fieldsTrackBy">
      <ng4h-card>
        <ng4h-card-header>
          <h5>{{ keyvalue.key }}</h5>
          <ng4h-card-actions>
            <button class="btn btn-sm btn-secondary" (click)="editClicked(keyvalue.key)">Edit</button>
          </ng4h-card-actions>
        </ng4h-card-header>
        <ng4h-card-content>
          <div *ngIf="!anyVisible(keyvalue.value)">No Fields Selected</div>
          <ng-container *ngFor="let input of keyvalue.value; let inputIndex = index; trackBy: inputTrackBy">
            <ng-container *ngIf="input.inputState !== eStandardFieldStatus.Hidden">
              <div class="col-12 ng4h-panel selectable" (click)="selectPanel(keyvalue.key, inputIndex, input.inputName)" [ngClass]="{'selected': input.inputName === selectedInputName}">
                <ng4h-standard-form-input [readOnly]="true" class="col-12" [input]="input" [form]="form" [sectionName]="keyvalue.key"></ng4h-standard-form-input>
              </div>
            </ng-container>
          </ng-container>
        </ng4h-card-content>
      </ng4h-card>
    </div>
  </div>
  <div class="col-6">
    <ng4h-card style="position: fixed; width: 39%;">
      <ng4h-card-header>
        <h5>Internationalization</h5>
      </ng4h-card-header>
      <ng4h-card-content>
        <ng-container *ngIf="selectedInputIndex >= 0; else noSelectedInput">
          <div class="row">
            <ng4h-card class="col-4" *ngFor="let type of eLocalizationType | keyvalue: retainOrder">
              <ng4h-card-content class="selectable" [ngClass]="{'selected': localizationType === type.value}">
                {{ type.key }}
              </ng4h-card-content>
            </ng4h-card>
          </div>
          <div class="row">
            <div class="col-12">
              <ng4h-card>
                <ng4h-card-content>
                  <div class="form-group">
                    <label
                      class="form-control-label">{{ standardFormConfig.fields[eLocalizationType.US_English][selectedSection][selectedInputIndex].label }}</label>
                    <div class="input-group">
                      <textarea class="form-control" rows="4"
                                [ngModel]="standardFormConfig.fields[localizationType][selectedSection][selectedInputIndex].inputText"
                                (ngModelChange)="aliasChange(standardFormConfig.fields[localizationType][selectedSection][selectedInputIndex].inputName, $event)"></textarea>
                    </div>
                    <mat-datepicker #lateStartDate></mat-datepicker>
                  </div>
                </ng4h-card-content>
              </ng4h-card>
            </div>
          </div>
          <button class="btn btn-primary" (click)="save.emit()">Save</button>
        </ng-container>
        <ng-template #noSelectedInput>
          <div>
            <b>Select an input to edit its aliases / internationalization</b>
          </div>
        </ng-template>
      </ng4h-card-content>
    </ng4h-card>
  </div>
</div>