<div class="modal-header">
    <h5 class="modal-title">Apply Coupon Code</h5>
    <button (click)="close()" type="button" class="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="form">
        <div class="form-group row">
            <label class="form-control-label col-md-3 ng4h-required">Coupon Code</label>
            <div class="col-md-9">
                <input formControlName="couponCode" class="form-control" type="text">
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button class="btn btn-primary" [disabled]="form.invalid" (click)="save()">Save</button>
    <button class="btn btn-secondary" (click)="close()">Cancel</button>
</div>
