import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'ng4h-admin-page-actions',
  templateUrl: './admin-page-actions.component.html',
  styleUrls: ['./admin-page-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminPageActionsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
