import { eInstitutionDiscountStatus, eInvoiceDiscountFeePercentage, eInvoiceDiscountType } from '..';

export interface IEventDiscountCreateDto {
    fundId: string;
    feePercentage?: eInvoiceDiscountFeePercentage;
    invoiceDiscountType: eInvoiceDiscountType;
    registrationTypes?: any[];
    description?: string;
    status: eInstitutionDiscountStatus;
}
