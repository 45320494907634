import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ICountyDescriptorDto } from 'app/core/models';
import { InstitutionService, RouterService } from 'app/core/services';
import { BehaviorSubject, combineLatest, filter, map, Observable } from 'rxjs';

@Component({
  selector: 'ng4h-county-multiselect-autocomplete',
  templateUrl: './county-multiselect-autocomplete.component.html',
  styleUrls: ['./county-multiselect-autocomplete.component.scss']
})
export class CountyMultiselectAutocompleteComponent implements OnInit {

  @Input() selectedCounties: string[]; // hierarchy node ids

  @Input() disabled: string;
  @Input() allCounties = false;
  @Input() reset: EventEmitter<void>;
  @Output() selectedChanged: EventEmitter<ICountyDescriptorDto[]> = new EventEmitter();

  public counties$: Observable<ICountyDescriptorDto[]>;
  public selectCounties$: Observable<string[]>;

  private selectedCounties$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  constructor(private institutionService: InstitutionService, private routerService: RouterService) {
  }

  ngOnInit() {
    if (this.allCounties === true) {
      this.counties$ = this.institutionService.getAllCounties({ institutionId: this.routerService.institutionId });
    } else {
      this.counties$ = this.institutionService.getInstitutionCounties({ institutionId: this.routerService.institutionId });
    }

    this.selectCounties$ = combineLatest([this.counties$, this.selectedCounties$]).pipe(
      filter(([counties, selectedCounties]) => Array.isArray(counties) && Array.isArray(selectedCounties)),
      map(([counties, selectedCounties]) => {
        const selected = counties.filter(x => selectedCounties.includes(x.countyId));
        this.selectedChanged.emit(selected);
        return selected.map(county => county.countyId);
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedCounties) {
      if (changes.selectedCounties?.currentValue?.length != null && changes.selectedCounties?.currentValue?.length === changes.selectedCounties?.previousValue?.length && changes.selectedCounties.currentValue.every((id, index) => id === changes.selectedCounties.previousValue[index])) {
        return;
      }
      this.selectedCounties$.next(changes.selectedCounties.currentValue);
    }
  }
}
