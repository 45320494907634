import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AutoUnsubscribe,
  eEthnicity,
  eGender,
  eGenderIdentity,
  EMAIL_REGEX,
  eMilitaryBranchType,
  eMilitaryComponentType,
  eMilitaryFamilyServiceType,
  eResidence,
  eSchoolGradeType,
  eSchoolType,
  IEnrollmentDto,
  IMemberDto,
  IProgramSettingsDto,
  ISchoolDistrictDto,
  ISchoolDto,
  PHONE_REGEX,
} from 'app/core/models';
import { RouterService, SchoolService } from 'app/core/services';
import { DemographicValidators } from 'app/core/validators';
import { schoolDistricts } from 'app/shared/school/school.reducer';
import { filter, map, Observable, startWith, takeUntil } from 'rxjs';

@Component({
  selector: 'ng4h-demographics-form',
  templateUrl: './demographics-form.component.html',
  styleUrls: ['./demographics-form.component.scss']
})
export class DemographicsFormComponent extends AutoUnsubscribe implements OnInit {


  public eMilitaryFamilyServiceType = eMilitaryFamilyServiceType;
  public eMilitaryBranchType = eMilitaryBranchType;
  public eMilitaryComponentType = eMilitaryComponentType;
  public eEthnicity = eEthnicity;
  public eGender = eGender;
  public eGenderIdentity = eGenderIdentity;
  public eResidence = eResidence;
  public eSchoolType = eSchoolType;
  public eSchoolGradeType = eSchoolGradeType;

  @Input() demographicsForm: FormGroup;
  @Output() demographicsFormChange: EventEmitter<FormGroup>;

  public schoolDistricts$: Observable<ISchoolDistrictDto[]>;
  public schoolsByDistrict$: Observable<ISchoolDto[]>;


  @Input() member: IMemberDto;
  @Input() enrollment: IEnrollmentDto;
  @Input() programSettings: IProgramSettingsDto;

  public programId$: Observable<string>;
  constructor(
    private routerService: RouterService,
    private fb: FormBuilder,
    private schoolService: SchoolService,
    private cdr: ChangeDetectorRef
  ) {
    super();
    this.demographicsFormChange = new EventEmitter();
    this.programId$ = this.routerService.programId$;
  }

  ngOnInit() {

    const enrollment = this.enrollment;
    const member = this.member;
    const programSettings = this.programSettings;
    this.demographicsForm = this.fb.group({

      schoolGrade: [enrollment.schoolGrade, Validators.required],
      birthGender: [enrollment.demographics.birthGender, programSettings.gender_Birth_On ? Validators.required : null],
      identifyingGender: [enrollment.demographics.identifyingGender, programSettings.gender_Identity_On ? Validators.required : null],

      ethnicity: [enrollment.demographics.ethnicity, Validators.required],
      residence: [enrollment.demographics.residence, Validators.required],

      racialBreakdownWhite: [enrollment.demographics.racialBreakdownWhite],
      racialBreakdownBlack: [enrollment.demographics.racialBreakdownBlack],
      racialBreakdownAmericanIndianOrAlaskanNative: [enrollment.demographics.racialBreakdownAmericanIndianOrAlaskanNative],
      racialBreakdownNativeHawaiianOrPacificIslander: [enrollment.demographics.racialBreakdownNativeHawaiianOrPacificIslander],
      racialBreakdownAsian: [enrollment.demographics.racialBreakdownAsian],
      racialBreakdownBalanceOfOtherCombinations: [enrollment.demographics.racialBreakdownBalanceOfOtherCombinations],
      racialBreakdownUndetermined: [enrollment.demographics.racialBreakdownUndetermined],

      schoolCountyId: [enrollment.schoolCountyId],
      schoolDistrictId: [enrollment.schoolDistrictId],
      schoolId: [enrollment.schoolId],

      schoolName: [
        { value: enrollment.schoolName, disabled: enrollment.schoolName == null ? true : false, },
        enrollment.schoolName == null ? [] : [Validators.required]
      ],
      schoolType: [
        { value: enrollment.schoolType, disabled: enrollment.schoolType == null ? true : false, },
        enrollment.schoolType == null ? [] : [Validators.required]
      ],

      militaryService: [enrollment.militaryService, Validators.required],
      militaryBranch: [
        {
          value: enrollment.militaryBranch,
          disabled: enrollment.militaryService == null
            || enrollment.militaryService === eMilitaryFamilyServiceType.NoOneInMyFamilyIsServingInTheMilitary,
        },
        enrollment.militaryService === eMilitaryFamilyServiceType.NoOneInMyFamilyIsServingInTheMilitary ? [] : [Validators.required]
      ],
      militaryComponent: [{
        value: enrollment.militaryComponent,
        disabled: enrollment.militaryService == null
          || enrollment.militaryService === eMilitaryFamilyServiceType.NoOneInMyFamilyIsServingInTheMilitary
      },
      enrollment.militaryService === eMilitaryFamilyServiceType.NoOneInMyFamilyIsServingInTheMilitary ? [] : [Validators.required]
      ],

      schoolInputSelectionRadio: [enrollment.schoolName == null && enrollment.schoolType == null ? true : false],

      guardian: this.fb.group({
        guardian1FirstName: [member.guardian1FirstName, [Validators.required, Validators.maxLength(50)]],
        guardian1LastName: [member.guardian1LastName, [Validators.required, Validators.maxLength(50)]],
        guardian1Phone: [member.guardian1Phone, [Validators.required, Validators.maxLength(12)]],
        guardian1WorkPhone: [member.guardian1WorkPhone, [Validators.maxLength(12)]],
        guardian1WorkExt: [member.guardian1WorkExt, [Validators.maxLength(12)]],

        guardian2FirstName: [member.guardian2FirstName, [Validators.maxLength(50)]],
        guardian2LastName: [member.guardian2LastName, [Validators.maxLength(50)]],
        guardian2Phone: [member.guardian2Phone, [Validators.maxLength(12)]],
        guardian2WorkPhone: [member.guardian2WorkPhone, [Validators.maxLength(12)]],
        guardian2WorkExt: [member.guardian2WorkExt, [Validators.maxLength(12)]],
      }),

      emergencyContact: programSettings.member_Show_Second_EmergencyContact === true ? this.fb.group({
        emergencyContact: [member.emergencyContact, [Validators.required, Validators.maxLength(50)]],
        emergencyContactPhone: [member.emergencyContactPhone, [Validators.required, Validators.pattern(PHONE_REGEX)]],
        emergencyContactRelationship: [member.emergencyContactRelationship, [Validators.required, Validators.maxLength(200)]],
        emergencyContactEmail: [member.emergencyContactEmail, [Validators.pattern(EMAIL_REGEX)]],

        emergencyContact2: [member.emergencyContact2, [Validators.maxLength(50)]],
        emergencyContact2Relationship: [member.emergencyContact2Relationship, [Validators.maxLength(200)]],
        emergencyContact2Phone: [member.emergencyContact2Phone, [Validators.pattern(PHONE_REGEX)]],
        emergencyContact2Email: [member.emergencyContact2Email, [Validators.pattern(EMAIL_REGEX)]]
      }) : this.fb.group({
        emergencyContact: [member.emergencyContact, [Validators.required, Validators.maxLength(50)]],
        emergencyContactPhone: [member.emergencyContactPhone, [Validators.required, Validators.pattern(PHONE_REGEX)]],
        emergencyContactRelationship: [member.emergencyContactRelationship, [Validators.required, Validators.maxLength(200)]],
        emergencyContactEmail: [member.emergencyContactEmail, [Validators.pattern(EMAIL_REGEX)]],
      }),

      address: programSettings.member_Show_Address === true ? this.fb.group({
        addressLine1: [member.addressLine1],
        addressLine2: [member.addressLine2],
        city: [member.city],
        state: [member.state],
        postalCode: [member.postalCode],
      }) : null,

      secondFamily: programSettings.member_Show_Second_Family === true ? this.fb.group({
        secondFamilyName: [member.secondFamilyName],
        secondFamilyFirstNames: [member.secondFamilyFirstNames],
        secondFamilyEmail: [member.secondFamilyEmail],
        secondFamilyPhone: [member.secondFamilyPhone],
        secondFamilyAddressLine1: [member.secondFamilyAddressLine1],
        secondFamilyAddressLine2: [member.secondFamilyAddressLine2],
        secondFamilyCity: [member.secondFamilyCity],
        secondFamilyState: [member.secondFamilyState],
        secondFamilyPostalCode: [member.secondFamilyPostalCode],
      }) : null

    }, { validators: [DemographicValidators.Race, DemographicValidators.School] });


    this.demographicsFormChange.emit(this.demographicsForm);
    this.cdr.markForCheck();

    //#region School enable / disable
    this.demographicsForm.controls.schoolInputSelectionRadio.valueChanges.pipe(
      startWith(this.demographicsForm.controls.schoolInputSelectionRadio.value),
      takeUntil(this.autoUnsubscribe)).
      subscribe((value: boolean) => {
        this.setDisabilityOnSchoolControls(value);
      });
    //#endregion


    //#region Military Drop downs
    this.demographicsForm.controls.militaryService.valueChanges
      .pipe(
        startWith(this.demographicsForm.controls.militaryService.value),
        filter(militaryService => militaryService != null),
        takeUntil(this.autoUnsubscribe))
      .subscribe((militaryService: eMilitaryFamilyServiceType) => {
        this.setDisabilityOnMilitaryService(militaryService);
      });
    //#endregion

    //#region LOADING SCHOOL DROP DOWNS
    this.demographicsForm.controls.schoolCountyId.valueChanges
      .pipe(
        startWith(this.demographicsForm.controls.schoolCountyId.value),
        filter(schoolCountyId => schoolCountyId != null),
        takeUntil(this.autoUnsubscribe))
      .subscribe(countyId => {
        this.schoolDistricts$ = this.schoolService.getSchoolDistricts({ institutionId: this.routerService.institutionId, countyId }).pipe(
          filter(schoolDistricts => Array.isArray(schoolDistricts)),
          map(schoolDistricts => {
            const names = schoolDistricts.map(d => d.name);
            return schoolDistricts.filter(({ name }, index) => !names.includes(name, index + 1));
          })
        );
      });

    this.demographicsForm.controls.schoolDistrictId.valueChanges
      .pipe(
        startWith(this.demographicsForm.controls.schoolDistrictId.value),
        filter(schoolDistrictId => schoolDistrictId != null),
        takeUntil(this.autoUnsubscribe))
      .subscribe(schoolDistrictId => {
        this.schoolsByDistrict$ = this.schoolService.getSchoolsByDistrict({ institutionId: this.routerService.institutionId, schoolDistrictId });
      });
    //#endregion

    //#region Racial checkboxes
    this.demographicsForm.controls.racialBreakdownUndetermined.valueChanges.pipe(
      startWith(this.demographicsForm.controls.racialBreakdownUndetermined.value),
      filter(racialBreakdownUndetermined => racialBreakdownUndetermined != null && racialBreakdownUndetermined === true),
      takeUntil(this.autoUnsubscribe))
      .subscribe(racialBreakdownUndetermined => {
        this.demographicsForm.controls.racialBreakdownWhite.setValue(null);
        this.demographicsForm.controls.racialBreakdownBlack.setValue(null);
        this.demographicsForm.controls.racialBreakdownAmericanIndianOrAlaskanNative.setValue(null);
        this.demographicsForm.controls.racialBreakdownNativeHawaiianOrPacificIslander.setValue(null);
        this.demographicsForm.controls.racialBreakdownAsian.setValue(null);
        this.demographicsForm.controls.racialBreakdownBalanceOfOtherCombinations.setValue(null);

      });

    this.demographicsForm.controls.racialBreakdownWhite.valueChanges.pipe(
      startWith(this.demographicsForm.controls.racialBreakdownWhite.value),
      filter(racialBreakdownWhite => racialBreakdownWhite != null && this.demographicsForm.controls.racialBreakdownUndetermined.value === true),
      takeUntil(this.autoUnsubscribe))
      .subscribe(val => {
        this.demographicsForm.controls.racialBreakdownUndetermined.setValue(null);
        this.demographicsForm.controls.racialBreakdownUndetermined.updateValueAndValidity();
      });
    this.demographicsForm.controls.racialBreakdownBlack.valueChanges.pipe(
      startWith(this.demographicsForm.controls.racialBreakdownBlack.value),
      filter(racialBreakdownBlack => racialBreakdownBlack != null && this.demographicsForm.controls.racialBreakdownUndetermined.value === true),
      takeUntil(this.autoUnsubscribe))
      .subscribe(val => {
        this.demographicsForm.controls.racialBreakdownUndetermined.setValue(null);
        this.demographicsForm.controls.racialBreakdownUndetermined.updateValueAndValidity();
      });
    this.demographicsForm.controls.racialBreakdownAmericanIndianOrAlaskanNative.valueChanges.pipe(
      startWith(this.demographicsForm.controls.racialBreakdownAmericanIndianOrAlaskanNative.value),
      filter(racialBreakdownAmericanIndianOrAlaskanNative => racialBreakdownAmericanIndianOrAlaskanNative != null && this.demographicsForm.controls.racialBreakdownUndetermined.value === true),
      takeUntil(this.autoUnsubscribe))
      .subscribe(val => {
        this.demographicsForm.controls.racialBreakdownUndetermined.setValue(null);
        this.demographicsForm.controls.racialBreakdownUndetermined.updateValueAndValidity();
      });
    this.demographicsForm.controls.racialBreakdownNativeHawaiianOrPacificIslander.valueChanges.pipe(
      startWith(this.demographicsForm.controls.racialBreakdownNativeHawaiianOrPacificIslander.value),
      filter(racialBreakdownNativeHawaiianOrPacificIslander => racialBreakdownNativeHawaiianOrPacificIslander != null && this.demographicsForm.controls.racialBreakdownUndetermined.value === true),
      takeUntil(this.autoUnsubscribe))
      .subscribe(val => {
        this.demographicsForm.controls.racialBreakdownUndetermined.setValue(null);
        this.demographicsForm.controls.racialBreakdownUndetermined.updateValueAndValidity();
      });
    this.demographicsForm.controls.racialBreakdownAsian.valueChanges.pipe(
      startWith(this.demographicsForm.controls.racialBreakdownAsian.value),
      filter(racialBreakdownAsian => racialBreakdownAsian != null && this.demographicsForm.controls.racialBreakdownUndetermined.value === true),
      takeUntil(this.autoUnsubscribe))
      .subscribe(val => {
        this.demographicsForm.controls.racialBreakdownUndetermined.setValue(null);
        this.demographicsForm.controls.racialBreakdownUndetermined.updateValueAndValidity();
      });
    this.demographicsForm.controls.racialBreakdownBalanceOfOtherCombinations.valueChanges.pipe(
      startWith(this.demographicsForm.controls.racialBreakdownBalanceOfOtherCombinations.value),
      filter(racialBreakdownBalanceOfOtherCombinations => racialBreakdownBalanceOfOtherCombinations != null && this.demographicsForm.controls.racialBreakdownUndetermined.value === true),
      takeUntil(this.autoUnsubscribe))
      .subscribe(val => {
        this.demographicsForm.controls.racialBreakdownUndetermined.setValue(null);
        this.demographicsForm.controls.racialBreakdownUndetermined.updateValueAndValidity();
      });

  }

  public retainOrder() {
    return 0;
  }

  public schoolFalseClicked() {
    this.demographicsForm.controls['schoolInputSelectionRadio'].setValue(false);
    this.demographicsForm.controls['schoolInputSelectionRadio'].updateValueAndValidity();
  }

  public schoolTrueClicked() {
    this.demographicsForm.controls['schoolInputSelectionRadio'].setValue(true);
    this.demographicsForm.controls['schoolInputSelectionRadio'].updateValueAndValidity();
  }

  private setDisabilityOnSchoolControls(value: boolean) {
    if (value === true) {
      this.demographicsForm.controls.schoolName.disable();
      this.demographicsForm.controls.schoolType.disable();
      this.demographicsForm.controls.schoolName.clearValidators();
      this.demographicsForm.controls.schoolType.clearValidators();


      this.demographicsForm.controls.schoolCountyId.enable();
      this.demographicsForm.controls.schoolDistrictId.enable();
      this.demographicsForm.controls.schoolId.enable();
      this.demographicsForm.controls.schoolCountyId.setValidators(Validators.required);
      this.demographicsForm.controls.schoolDistrictId.setValidators(Validators.required);
      this.demographicsForm.controls.schoolId.setValidators(Validators.required);

    } else {
      this.demographicsForm.controls.schoolName.enable();
      this.demographicsForm.controls.schoolType.enable();
      this.demographicsForm.controls.schoolName.setValidators(Validators.required);
      this.demographicsForm.controls.schoolType.setValidators(Validators.required);

      this.demographicsForm.controls.schoolCountyId.disable();
      this.demographicsForm.controls.schoolDistrictId.disable();
      this.demographicsForm.controls.schoolId.disable();
      this.demographicsForm.controls.schoolCountyId.clearValidators();
      this.demographicsForm.controls.schoolDistrictId.clearValidators();
      this.demographicsForm.controls.schoolId.clearValidators();
    }
  }

  private setDisabilityOnMilitaryService(militaryService: eMilitaryFamilyServiceType) {
    if (militaryService === eMilitaryFamilyServiceType.NoOneInMyFamilyIsServingInTheMilitary) {
      this.demographicsForm.controls.militaryBranch.setValue(null);
      this.demographicsForm.controls.militaryComponent.setValue(null);
      this.demographicsForm.controls.militaryBranch.disable();
      this.demographicsForm.controls.militaryComponent.disable();

      this.demographicsForm.controls.militaryBranch.clearValidators();
      this.demographicsForm.controls.militaryComponent.clearValidators();
    } else {
      this.demographicsForm.controls.militaryBranch.enable();
      this.demographicsForm.controls.militaryComponent.enable();

      this.demographicsForm.controls.militaryBranch.setValidators([Validators.required]);
      this.demographicsForm.controls.militaryBranch.updateValueAndValidity();
      this.demographicsForm.controls.militaryComponent.setValidators([Validators.required]);
      this.demographicsForm.controls.militaryComponent.updateValueAndValidity();
    }
  }


}
