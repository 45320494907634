import { IHierarchyNodeDto } from '.';
import { eCurrencyType, eFundsTransferStatus } from '..';

export interface IFundsTransferSummaryDto {
    fundsTransferId: string;
    fundsTransferIdent: number;
    bankAccountId: string;
    transferAmount: number;
    payoutAmount: number;
    currencyType: eCurrencyType;
    hierarchyNode: IHierarchyNodeDto;
    status: eFundsTransferStatus;
    dateCreated: string;
    dateInitiated?: string;
    dateAccepted?: string;
    dateFinalized?: string;
    errorMessage?: string;
}
