import { Action, createReducer, on } from '@ngrx/store';
import { IAwardDto, IInstitutionManagerProgramModel } from 'app/core/models';
import { mergeImmutable } from 'app/shared/utils';

import { ManagerAwardsActions } from '.';

export interface ManagerAwardsState {
    awards: IInstitutionManagerProgramModel<{ [hierarchyNodeId: string]: IAwardDto[] }>;
    award: IInstitutionManagerProgramModel<{ [hierarchyNodeId: string]: IAwardDto }>;
}

const initialState: ManagerAwardsState = {
    awards: undefined,
    award: undefined
};

const reducer = createReducer(
    initialState,
    on(ManagerAwardsActions.LoadAwardsSuccessAction, (state, { institutionId, managerId, programId, hierarchyNodeId, awards }) => {
        const newAwards = mergeImmutable(
            { [institutionId]: { [managerId]: { [programId]: { [hierarchyNodeId]: awards } } } },
            state.awards
        );
        return {
            ...state,
            awards: newAwards
        };
    }),
    on(ManagerAwardsActions.LoadAwardSuccessAction, (state, { institutionId, managerId, programId, awardId, award }) => {
        const newAward = mergeImmutable(
            { [institutionId]: { [managerId]: { [programId]: { [awardId]: award } } } },
            state.award
        );
        return {
            ...state,
            award: newAward
        };
    }),
    on(ManagerAwardsActions.CreateAwardSuccessAction,
        ManagerAwardsActions.UpdateAwardSuccessAction,
        ManagerAwardsActions.DeleteAwardSuccessAction, (state, { }) => {
            return initialState;
        })
);

export function managerAwardsReducer(state: ManagerAwardsState | undefined, action: Action) {
    return reducer(state, action);
}

export const awards = (state: ManagerAwardsState) => state.awards;
export const award = (state: ManagerAwardsState) => state.award;

