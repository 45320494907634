import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PageMessageService } from 'app/core/services';
import { catchError, exhaustMap, groupBy, map, mergeMap, of } from 'rxjs';

import { PageMessagesActions } from '.';
import { stringKeys } from '../rxjs.utils';

@Injectable()
export class PageMessageEffects {

  public loadPageMessages = createEffect(() => this.actions
    .pipe(
      ofType(PageMessagesActions.PageMessagesLoadAction),
      groupBy(stringKeys),
      mergeMap(groupedBy => {
        return groupedBy.pipe(
          exhaustMap(payload => {
            return this.pageMessageService.loadEmailTemplateEffect(payload).pipe(
              map(messages => messages === null ? [] : messages),
              map(messages => PageMessagesActions.PageMessagesLoadSuccessAction({ ...payload, messages })),
              catchError(error => of(PageMessagesActions.PageMessagesLoadErrorAction({ error })))
            );
          })
        );
      })
    ));

  public updatePageMessage = createEffect(() => this.actions
    .pipe(
      ofType(PageMessagesActions.PageMessageUpdateAction),
      groupBy(stringKeys),
      mergeMap(groupedBy => {
        return groupedBy.pipe(
          exhaustMap(payload => {
            return this.pageMessageService.updatePageMessageEffect(payload).pipe(
              map(() => PageMessagesActions.PageMessageUpdateSuccessAction(payload)),
              catchError(error => of(PageMessagesActions.PageMessageUpdateErrorAction({ error })))
            );
          })
        );
      })
    ));
  public updatePageMessageSuccess = createEffect(() => this.actions
    .pipe(
      ofType(PageMessagesActions.PageMessageUpdateSuccessAction),
      map(payload => PageMessagesActions.PageMessageInvalidateCacheAction())
    ));

  constructor(private actions: Actions, private pageMessageService: PageMessageService) { }
}
