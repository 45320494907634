import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CountyService } from 'app/core/services';
import { catchError, exhaustMap, groupBy, map, mergeMap, of } from 'rxjs';

import { CountyAreasActions } from '.';
import { stringKeys } from '../rxjs.utils';

@Injectable()
export class CountyAreasEffects {

  loadCountyAreas = createEffect(() => this.actions
  .pipe(
    ofType(CountyAreasActions.CountyAreasLoadAction),
    groupBy(stringKeys),
    mergeMap(groupedBy => {
      return groupedBy.pipe(
        exhaustMap(payload => {
          return this.countyAreaService.loadCountyAreasEffect(payload).pipe(
            map(countyAreas => CountyAreasActions.CountyAreasLoadSuccessAction({ ...payload, countyAreas })),
            catchError(error => of(CountyAreasActions.CountyAreasLoadErrorAction({ error })))
          );
        })
      );
    })
  ));

  constructor(private actions: Actions, private countyAreaService: CountyService) {
  }
}
