import { eHierarchyNodeType } from '.';
import { eProfileType } from '../enums';

export interface IManagerDescriptorDto {
    managerId?: string;
    firstName?: string;
    lastName?: string;
    name?: string;
    highestActingHierarchyType?: eHierarchyNodeType;
    deleted: boolean;
    managerPrograms?: object;
    profileId: string;
    profileType: eProfileType;
    email?: string;
    institutionId: string;
    institutionProfileId: string;
    profileToken: string;
    institutionProfileIdent: number;
}
