<div class="modal-section">
  <div class="modal-header">
    <h5 class="modal-title">Link Training</h5>
    <button (click)="cancel()" type="button" class="close" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="form">


      <div class="form-group row">
        <label class="form-control-label col-md-3">Training</label>
        <div class="col-md-9">
          <select class="form-control" formControlName="trainingId">
            <option *ngFor="let training of trainings$ | async" [value]="training.trainingId">
              {{ training.trainingName }}
            </option>
          </select>
        </div>
      </div>

      <div class="form-group row">
        <label class="form-control-label col-md-3">Renewal Interval</label>
        <div class="col-md-9">
          <input class="form-control" type="number" step="1" formControlName="renewalInterval">
        </div>
      </div>

      <div class="form-group row">
        <label class="form-control-label col-md-3">Required</label>
        <div class="col-md-9">
          <mat-checkbox formControlName="required"></mat-checkbox>
        </div>
      </div>


      <div class="form-group row">
        <label class="form-control-label col-md-3">Role</label>
        <div class="col-md-9">
          <ng4h-enrollment-role-dropdown
            [limitTo]="[eEnrollmentRoleType.ClubMember, eEnrollmentRoleType.Volunteer, eEnrollmentRoleType.CloverBud]"
            formControlName="enrollmentRole"></ng4h-enrollment-role-dropdown>
        </div>
      </div>

    </form>

  </div>

  <div class="modal-footer" *ngIf="form">
    <button id (click)="cancel()" class="btn btn-secondary my-2 my-sm-0" role="button"
      aria-pressed="true">Cancel</button>
    <button [disabled]="form.invalid" (click)="save()" class="btn btn-primary my-2 my-sm-0" role="button"
      aria-pressed="true">Save</button>
  </div>
</div>