import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { getMemberAwards, MemberFeatureStore } from 'app/core/containers/member/member-feature.reducers';
import { MemberAwardsActions } from 'app/core/containers/member/store/awards';
import { IAwardDto, IEnrollmentAwardSubmissionDto, IInstitutionMemberProgramEnrollmentParams } from 'app/core/models';
import { filter, map, Observable, of, take, tap } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MemberAwardsService {

  constructor(private httpClient: HttpClient, private store: Store<MemberFeatureStore>, private dispatcher: ActionsSubject) {
  }

  public getEligibleAwardsForEnrollment(params: IInstitutionMemberProgramEnrollmentParams) {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getMemberAwards(params)),
      tap(activities => {
        if (activities === undefined) {
          this.store.dispatch(MemberAwardsActions.LoadAwardsForEnrollmentAction(params));
        }
      }),
      filter(activities => activities != null)
    );
  }

  public getEligibleAwardsForEnrollmentEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId
  }: IInstitutionMemberProgramEnrollmentParams) {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/awards`) as Observable<IAwardDto[]>;
  }

  public saveAwardToEnrollment(params: IInstitutionMemberProgramEnrollmentParams & { enrollmentAwardSubmission: IEnrollmentAwardSubmissionDto }) {
    this.store.dispatch(MemberAwardsActions.SaveAwardsForEnrollmentAction(params));
    return this.dispatcher.pipe(
      ofType(MemberAwardsActions.SaveAwardsForEnrollmentSuccessAction, MemberAwardsActions.SaveAwardsForEnrollmentErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberAwardsActions.SaveAwardsForEnrollmentSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public saveAwardToEnrollmentEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    enrollmentAwardSubmission
  }: IInstitutionMemberProgramEnrollmentParams & { enrollmentAwardSubmission: IEnrollmentAwardSubmissionDto }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/awards`, enrollmentAwardSubmission);
  }

  public deleteAward(params: IInstitutionMemberProgramEnrollmentParams & { awardId: string }) {
    this.store.dispatch(MemberAwardsActions.DeleteAwardsForEnrollmentAction(params));
    return this.dispatcher.pipe(
      ofType(MemberAwardsActions.DeleteAwardsForEnrollmentSuccessAction, MemberAwardsActions.DeleteAwardsForEnrollmentErrorAction),
      take(1),
      map(action => {
        if (action.type === MemberAwardsActions.DeleteAwardsForEnrollmentSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteAwardEffect({
    institutionId,
    memberId,
    programId,
    enrollmentId,
    awardId
  }: IInstitutionMemberProgramEnrollmentParams & { awardId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/awards/${awardId}`);
  }
}
