import { Pipe, PipeTransform } from '@angular/core';
import { RouterService, SchoolService } from 'app/core/services';
import { filter, map, Observable } from 'rxjs';

import { ISchoolDto } from '../models';



@Pipe({
    name: 'school$',
    pure: true
})
export class SchoolPipe implements PipeTransform {
    constructor(private schoolService: SchoolService, private routerService: RouterService) {
    }

    public transform(schoolId: string, schoolDistrictId: string, institutionId: string = this.routerService.institutionId): Observable<ISchoolDto> {
        return this.schoolService.getSchoolsByDistrict({ institutionId, schoolDistrictId }).pipe(
            filter(s => Array.isArray(s)),
            map(schools => {
                return schools.find(school => school.schoolId === schoolId);
            })
        );
    }
}
