import { Action, createReducer, on } from '@ngrx/store';
import { MemberActions } from 'app/core/containers/member';
import { IMemberSearchResultsDto } from 'app/core/models/serverDTOs/IMemberSearchResultsDto';

import { MemberSearchActions } from '.';

export interface MemberSearchState {

    memberSearchSearching: boolean;
    membersSearchResult: IMemberSearchResultsDto;
}

const initialState: MemberSearchState = {
    memberSearchSearching: false,
    membersSearchResult: undefined
};

const reducer = createReducer(
    initialState,

    on(MemberSearchActions.ClearMemberSearchErrorAction,
        MemberSearchActions.SearchMembersErrorAction,
        MemberSearchActions.GetMemberSearchResultsErrorAction, (state, payload) => {
            return {
                ...state,
                memberSearchSearching: false
            };
        }),

    on(MemberSearchActions.ClearMemberSearchAction,
        MemberSearchActions.GetMemberSearchResultsAction,
        MemberSearchActions.SearchMembersAction, (state, payload) => {
            return {
                ...state,
                memberSearchSearching: true
            };
        }),
    on(MemberSearchActions.GetMemberSearchResultsSuccessAction,
        MemberSearchActions.SearchMembersSuccessAction, (state, payload) => {
            return {
                ...state,
                memberSearchSearching: false,
                membersSearchResult: payload.membersSearchResult
            };
        }),

    on(MemberActions.MemberFlagAction, MemberActions.MemberFlagDeleteErrorAction, (state, {
        institutionId,
        managerId,
        programId,
        hierarchyNodeId,
        memberId
    }) => {
        if (state.membersSearchResult?.results == null) {
            return state;
        }
        const updatedResults = state.membersSearchResult.results.map(result => {
            if (result.memberId === memberId) {
                return {
                    ...result,
                    flagged: true
                };
            }
            return result;
        });
        return {
            ...state,
            membersSearchResult: {
                ...state.membersSearchResult,
                results: updatedResults
            }
        };
    }),
    on(MemberActions.MemberFlagErrorAction, MemberActions.MemberFlagDeleteAction, (state, {
        institutionId,
        managerId,
        programId,
        hierarchyNodeId,
        memberId
    }) => {
        if (state.membersSearchResult?.results == null) {
            return state;
        }
        const updatedResults = state.membersSearchResult.results.map(result => {
            if (result.memberId === memberId) {
                return {
                    ...result,
                    flagged: false
                };
            }
            return result;
        });
        return {
            ...state,
            membersSearchResult: {
                ...state.membersSearchResult,
                results: updatedResults
            }
        };
    })
);

export function memberSearchReducer(state: MemberSearchState | undefined, action: Action) {
    return reducer(state, action);
}

export const membersSearchResult = (state: MemberSearchState) => state.membersSearchResult;
export const memberSearchSearching = (state: MemberSearchState) => state.memberSearchSearching;
