<div class="modal-header">
  <h5 class="modal-title">Link Managers to Hierarchy</h5>
  <button (click)="close()" type="button" class="close" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="addManager" [ngClass]="wasValidated ? 'was-validated' : 'needs-validation'" novalidate>
    <div class="form-group col-sm-12">
      <label class="form-control-label">Existing Account Email or Name</label>
      <input class="form-control" [matAutocomplete]="auto" formControlName="email" type="email">
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let user of filteredUsers | async; let i = index" [value]="user.email"
          style="height: 65px; line-height: 25px" (click)="managerSelected(user)">
          <div>{{user.email}}</div>
          <small>{{user.lastName}}, {{user.firstName}}</small>
        </mat-option>
      </mat-autocomplete>
    </div>
    <div class="form-group col-sm-12">
      <label class="form-control-label">Role</label>
      <select class="form-control" formControlName="role">
        <option [ngValue]="eAccessControlListRoleType.HierarchyAdmin">Admin</option>
        <option [ngValue]="eAccessControlListRoleType.HierarchyStaff">Staff</option>
      </select>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button [disabled]="selectedManager == null" class="btn btn-primary" (click)="add()">Link to Hierarchy</button>
</div>