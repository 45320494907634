import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dayOfWeek',
    pure: true
})

export class DayOfWeekPipe implements PipeTransform {
    transform(day: number): string {
        const days = new Map([
            [1, 'Sunday'],
            [2, 'Monday'],
            [3, 'Tuesday'],
            [4, 'Wednesday'],
            [5, 'Thursday'],
            [6, 'Friday'],
            [7, 'Saturday'],
        ]);

        return days.get(day);
    }
}
