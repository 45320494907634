import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IEnrollmentFamilyDiscountDto } from 'app/core/models';

@Component({
  selector: 'ng4h-modal-edit-discounts',
  templateUrl: './modal-edit-discounts.component.html',
  styleUrls: ['./modal-edit-discounts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalEditDiscountsComponent implements OnInit {

  public form: FormGroup;

  constructor(private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) private discount: IEnrollmentFamilyDiscountDto[],
    private fb: FormBuilder) { }

  ngOnInit() {
    const discount: any = {};

    for (const item of this.discount) {
      discount[item.familyDiscountType] = item.rate !== undefined ? item.rate : 0;
    }

    this.form = this.fb.group({
      DiscountFor2: discount.DiscountFor2,
      DiscountFor3: discount.DiscountFor3,
      DiscountFor4: discount.DiscountFor4,
      DiscountFor5orMore: discount.DiscountFor5orMore
    });
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public update(): void {
    this.dialogRef.close(this.form.value);
  }
}
