import { ICountyCountyAreaDto } from '.';
import { eStateAbbreviation } from '../enums';

export interface ICountyDescriptorDto {
  countyId: string;
  countyName?: string;
  state: eStateAbbreviation;
  hasMultipleCountyAreas: boolean;
  countyAreas?: ICountyCountyAreaDto[];
}
