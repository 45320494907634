import { routerNavigatedAction } from '@ngrx/router-store';
import { Action, createReducer, on } from '@ngrx/store';
import { eProfileType, IAnyInstitutionProfile, IInstitutionProfileAuthDto } from 'app/core/models';
import { mergeImmutable } from 'app/shared/utils';
import { ProfileActions, UserActions } from '.';

export interface ProfileState {
    profileCredentials: IInstitutionProfileAuthDto;
    routeHistory: { actingOnBehalfOfProfile: IAnyInstitutionProfile, route: string[] }[];
    institutionProfileAuthTokenCache: { [institutionProfileId: string]: IInstitutionProfileAuthDto };
    clubLeaderPins: { [institutionProfileId: string]: { [unitId: string]: string } };
}

const initialState: ProfileState = {
    profileCredentials: undefined,
    routeHistory: [],
    institutionProfileAuthTokenCache: undefined,
    clubLeaderPins: undefined
};

const reducer = createReducer(
    initialState,
    on(UserActions.AuthenticateAction, (state, {}) => {
        return {
            ...state,
            profileCredentials: null,
            institutionProfileAuthTokenCache: null
        };
    }),
    on(ProfileActions.GetProfileAuthSuccessAction, (state, { institutionProfileId, institutionProfileAuth }) => {
        const newInstitutionProfileAuthCache = mergeImmutable(
            { [institutionProfileId]: institutionProfileAuth },
            state.institutionProfileAuthTokenCache);
        return {
            ...state,
            profileCredentials: institutionProfileAuth,
            institutionProfileAuthTokenCache: newInstitutionProfileAuthCache
        };
    }),
    on(ProfileActions.HistoryAddAction, (state, { currentActingOnBehalfOfProfile, route, savedState }) => {
        const validRoute = validateRoute(route, currentActingOnBehalfOfProfile);
        if (validRoute === true) {
            const merge = [...state.routeHistory].concat(savedState)
                .filter(x => !!x && !!x.actingOnBehalfOfProfile);
            const newRouteHistory = [
                ...new Map(merge.map(item =>
                    [item.actingOnBehalfOfProfile.institutionProfileId, item]))
                    .values()
            ];
            // If the profile already exists, we want to remove it from the array and put it at the beginning
            const currentActingOnBehalfOfProfileIndex = newRouteHistory.findIndex(profile => {
                return profile.actingOnBehalfOfProfile.institutionProfileId === currentActingOnBehalfOfProfile.institutionProfileId;
            });
            if (currentActingOnBehalfOfProfileIndex > -1) {
                newRouteHistory.splice(currentActingOnBehalfOfProfileIndex, 1);
            }
            const actingOnBehalfOf = { ...currentActingOnBehalfOfProfile };
            try {
                // Nuke sensitive information
                delete actingOnBehalfOf['apiKey'];
                delete actingOnBehalfOf['sitePassword'];
                delete actingOnBehalfOf['eventRegistrations'];
                delete actingOnBehalfOf['managerPrograms'];
                delete actingOnBehalfOf['memberPrograms'];
                delete actingOnBehalfOf['screenings'];
                delete actingOnBehalfOf['trainings'];
            } catch {
                // ignore
            }
            newRouteHistory.unshift({ actingOnBehalfOfProfile: actingOnBehalfOf, route: route });
            return {
                ...state,
                routeHistory: newRouteHistory
            };
        }
        return { ...state };

        function validateRoute(rtx: string[], onBehalfOf: IAnyInstitutionProfile) {
            const newRoute = [...rtx];

            // Transform routes, if needed
            const memberTrainings = new RegExp('^ua_\\w*/ins_\\w*/ipf_\\w*/member/mbr_\\w*/prg_\\w*/trainings$', 'i');
            if (matchRouteProfile(memberTrainings, rtx, eProfileType.Member, onBehalfOf.profileType)) {
                newRoute.pop();
                newRoute.push('dashboard');
            }

            // Allowed routes to save
            const memberDashboard = new RegExp('^ua_\\w*/ins_\\w*/ipf_\\w*/member/mbr_\\w*/prg_\\w*/dashboard$', 'i');
            const familyList = new RegExp('^ua_\\w*/ins_\\w*/ipf_\\w*/family/fam_\\w*$', 'i');
            const systemManager = new RegExp('^ua_\\w*/ins_\\w*/ipf_\\w*/admin/prg_\\w*/system-manager/smgr_\\w*$', 'i');
            const superUser = new RegExp('^ua_\\w*/su_\\w*/superuser$', 'i');

            const managerSearch = new RegExp('^ua_\\w*/ins_\\w*/ipf_\\w*/admin/prg_\\w*/manager/mgr_\\w*/hrn_\\w*/members$', 'i');
            // const managerParticipationSearch = new RegExp('^ua_\\w*/ins_\\w*/ipf_\\w*/admin/prg_\\w*/manager/mgr_\\w*/hrn_\\w*/participation$', 'i');
            // const managerTrainingSearch = new RegExp('^ua_\\w*/ins_\\w*/ipf_\\w*/admin/prg_\\w*/manager/mgr_\\w*/hrn_\\w*/trainings$', 'i');
            const managerHome = new RegExp('^ua_\\w*/ins_\\w*/ipf_\\w*/admin/prg_\\w*/manager/mgr_\\w*/hrn_\\w*$', 'i');

            function matchRouteProfile(routeRegEx: RegExp, inputRoute: string[], expectedProfile: eProfileType, actualProfile: eProfileType): boolean {
                const routeString = inputRoute.join('/');
                return routeRegEx.test(routeString) && actualProfile === expectedProfile;
            }

            return matchRouteProfile(memberDashboard, newRoute, eProfileType.Member, onBehalfOf.profileType)
                || matchRouteProfile(familyList, newRoute, eProfileType.Family, onBehalfOf.profileType)
                || matchRouteProfile(managerHome, newRoute, eProfileType.Manager, onBehalfOf.profileType)
                || matchRouteProfile(managerSearch, newRoute, eProfileType.Manager, onBehalfOf.profileType)
                // || matchRouteProfile(managerParticipationSearch, newRoute, eProfileType.Manager, onBehalfOf.profileType)
                // || matchRouteProfile(managerTrainingSearch, newRoute, eProfileType.Manager, onBehalfOf.profileType)
                || matchRouteProfile(systemManager, newRoute, eProfileType.SystemManager, onBehalfOf.profileType)
                || matchRouteProfile(superUser, newRoute, eProfileType.Superuser, onBehalfOf.profileType);
        }
    }),
    // on(routerNavigatedAction, (state, { payload }) => {
    //     const newRouteHistory = state.routeHistory.map((history, index) => {
    //         if (index === 0) {
    //             return {
    //                 ...history,
    //                 route: payload.routerState.url.split('/').filter(s => s !== '')
    //             };
    //         }
    //         return history;
    //     });
    //     return {
    //         ...state,
    //         routeHistory: newRouteHistory
    //     };
    // }),
    on(UserActions.SignOutSuccessAction, (state, payload) => {
        return initialState;
    }),
    on(ProfileActions.AddClubPINAction, (state, { institutionProfileId, unitId, PIN }) => {
        const newPins = mergeImmutable(
            { [institutionProfileId]: { [unitId]: PIN } },
            state.clubLeaderPins
        );
        return {
            ...state,
            clubLeaderPins: newPins
        };
    })
);

export function profileReducer(state: ProfileState | undefined, action: Action) {
    return reducer(state, action);
}

export const profileCredentials = (state: ProfileState) => state.profileCredentials;
export const routeHistory = (state: ProfileState) => state.routeHistory;
export const institutionProfileAuthTokenCache = (state: ProfileState) => state.institutionProfileAuthTokenCache;
export const clubLeaderPins = (state: ProfileState) => state.clubLeaderPins;


