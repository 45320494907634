<div *ngIf="invalidError" style="color:red">
    {{ invalidError.message }}
</div>
<div class="row  ng4h-margin-top-small">
    <form class="col-12" [formGroup]="form">
        <div class="form-group">
            <input formControlName="name" style="border-width: 1px; border-color:#ddd" id="name" class="form-control"
                type="text" placeholder="Name On Card">
        </div>
    </form>
</div>
<div class="ng4h-padding-small ng4h-border-radius ng4h-margin-bottom-small ng4h-border">

    <stripe-card [options]="options" #stripeCard (catch)="onStripeError($event)" [(complete)]="cardDetailsFilledOut"
        [(invalid)]="invalidError" (cardMounted)="cardCaptureReady = true" (tokenChange)="setStripeToken($event)">
    </stripe-card>
</div>
<button class="btn btn-primary" [disabled]="form.invalid || cardDetailsFilledOut !== true || cardCaptureReady !== true"
    (click)="stripeCard.createToken({name: form.value.name})">
    {{form.valid && cardDetailsFilledOut ? 'Add Credit Card' : 'Enter card information...'}}
</button>
<!-- <button type="button" (click)="stripeCard.createPaymentMethod(extraData)">createPaymentMethod</button>
  <button type="button" (click)="stripeCard.createSource(extraData)">createSource</button>
  <button type="button" (click)="stripeCard.createToken(extraData)">createToken</button> -->