<div class="modal-header">
  <h5 class="modal-title" id="enrollModalLabel">Add Control</h5>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-4">
      <div *ngFor="let item of availableControlsDisplay; let i = index;" (click)="selectedIndex = i">
        <div class="control" [ngClass]="{'active': selectedIndex == i}">
          <i [ngClass]="item.class"></i>{{item.name}}
          <i *ngIf="item.hasRate" class="fas fa-usd-circle float-right" aria-hidden="true"></i>
        </div>
      </div>
      <i class="fas fa-usd-circle" aria-hidden="true"></i> = Fee available
    </div>
    <div class="col-8">
      <ng4h-dynamic-form-input [form]="form" [control]="availableControls[selectedIndex]"></ng4h-dynamic-form-input>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-primary float-right" (click)="addControl()">Add</button>
</div>