import {
    ICreditDescriptorDto,
    IDiscountDescriptorDto,
    IHierarchyNodeDto,
    IInvoiceLineItemDto,
    IMemberProgramConsentDto
} from '.';
import { eInvoiceStatus } from '..';
import { IPaymentDto } from './IPaymentDto';

export interface IPayableInvoiceDto {
    invoiceId: string;
    invoiceIdentifier: number;
    amountDue: number;
    amountPaid: number;
    amountOutstanding: number;
    feeTotal: number;
    lineItemsTotal: number;
    discountTotal: number;
    creditCardFeeRecovery: number;
    description?: string;
    status: eInvoiceStatus;
    statusPendingPayment: eInvoiceStatus;
    lineItems?: IInvoiceLineItemDto[];
    payments?: IPaymentDto[];
    credits?: ICreditDescriptorDto[];
    discounts?: IDiscountDescriptorDto[];
    dateCreated: string;
    datePaid?: string;
    termsOfPaymentConsent: IMemberProgramConsentDto;
    refundable: boolean;
    overCharge: boolean;
    creditsPayableTo: IHierarchyNodeDto;
    nonElectronicPaymentInstructions?: string;
    nonElectronicPaymentAmountDue?: number;
    creditsCollectedAmount?: number;
    creditsTotalAmount?: number;
    creditsDueMatchOutstandingAmount: boolean;
    canUpdateTransaction: boolean;
}
