<div class="modal-header">
  <h5 class="modal-title">Update Screening</h5>
  <button (click)="close()" type="button" class="close" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<ng-container *ngIf="screening$ | async as screening">
  <div class="modal-body">

    <div class="ng4h-panel-body ng4h-table">
      <h4>Submitted Screening Information</h4>
      <div class="col-12">
        <div class="row">
          <div class="col-4 ng4h-bg-secondary"><b>First Name</b></div>
          <div class="col-8">{{ screening.firstName }}</div>
        </div>
        <div class="row">
          <div class="col-4 ng4h-bg-secondary"><b>Last Name</b></div>
          <div class="col-8">{{ screening.lastName }}</div>
        </div>
        <div class="row">
          <div class="col-4 ng4h-bg-secondary"><b>County</b></div>
          <div class="col-8">{{ screening.county }}</div>
        </div>
        <div class="row">
          <div class="col-4 ng4h-bg-secondary"><b>Date Submitted</b></div>
          <div class="col-8">{{ screening.dateSubmitted | date:'short' }}</div>
        </div>
      </div>
    </div>

    <ng-container *ngFor="let fieldKeyValue of screening.fields | keyvalue: retainOrder">

      <div class="ng4h-panel-body ng4h-table">
        <h4>{{ fieldKeyValue.key }}</h4>
        <div class="col-12">

          <ng-container *ngFor="let input of fieldKeyValue.value">

            <div class="row" *ngIf="input.inputState !== eStandardFieldStatus.Hidden">
              <div class="col-4 ng4h-bg-secondary"><b>{{ input.inputText }}</b></div>
              <div class="col-8">{{ input.userResponse }}</div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>

    <div class="ng4h-panel-body ng4h-table" *ngIf="screening.consent != null">
      <h4>Consent</h4>
      <div class="col-12">
        <div class="row">
          <div class="col-4 ng4h-bg-secondary"><b>{{ screening.consent.consentVersion.consentName }}</b></div>
          <div class="col-8">{{ screening.consent.consentStatus }}</div>
        </div>
      </div>
    </div>

    <div class="ng4h-panel-body ng4h-table" *ngIf="screening.comment != null">
      <h4>Comment</h4>
      <div class="col-12">
        <div class="row">
          <div class="col-4 ng4h-bg-secondary"><b>Member Comment</b></div>
          <div class="col-8">{{ screening.comment }}</div>
        </div>
      </div>
    </div>

    <div class="ng4h-panel-body ng4h-table">
      <h4>Status & Configuration </h4>

      <div class="col-12">
        <div class="row">
          <div class="col-4 ng4h-bg-secondary"><b>Screening Level</b></div>
          <div class="col-8">{{ screening.type }}</div>
        </div>
      </div>

    </div>

    <form [formGroup]="form" *ngIf="form" class="ng4h-panel-body">

      <div class="form-group row">
        <label class="form-control-label col-md-3">Status</label>
        <div class="col-md-4">
          <select class="form-control" formControlName="status">
            <option *ngIf="screening.status === eProfileVolunteerScreeningStatus.Incomplete" [value]="eProfileVolunteerScreeningStatus.Incomplete">{{ eProfileVolunteerScreeningStatus.Incomplete }}
            </option>
            <option *ngIf="screening.status === eProfileVolunteerScreeningStatus.Submitted" [value]="eProfileVolunteerScreeningStatus.Submitted">{{ eProfileVolunteerScreeningStatus.Submitted }}
            </option>
            <option [value]="eProfileVolunteerScreeningStatus.Approved">{{ eProfileVolunteerScreeningStatus.Approved }}
            </option>
            <option [value]="eProfileVolunteerScreeningStatus.Rejected">{{ eProfileVolunteerScreeningStatus.Rejected }}
            </option>
            <option [value]="eProfileVolunteerScreeningStatus.Restricted">
              {{ eProfileVolunteerScreeningStatus.Restricted }}
            </option>
          </select>
        </div>
        <div class="col-md-5">
          <ng-container *ng4hManagerPermissions="{VolunteerScreening_Approval: ePermissionState.Write}">
            <button class="btn btn-secondary" *ngIf="screening.status === eProfileVolunteerScreeningStatus.Approved || screening.status === eProfileVolunteerScreeningStatus.Restricted || screening.status === eProfileVolunteerScreeningStatus.Rejected || screening.status === eProfileVolunteerScreeningStatus.Incomplete" (click)="changeScreeningStatus()">
              Change Screening Status
            </button>
          </ng-container>
        </div>

      </div>

      <div class="form-group row" *ngIf="form.controls.status.value === eProfileVolunteerScreeningStatus.Restricted || form.controls.restrictions.value != null">
        <label class="form-control-label col-md-3">Restrictions</label>
        <div class="col-md-9">
          <input formControlName="restrictions" type="text" class="form-control">
        </div>
      </div>

      <div class="form-group row">
        <label class="form-control-label col-md-3">Screening Renewal</label>
        <div class="col-md-4">

          <select class="form-control" formControlName="nextScreeningYear">
            <option *ngFor="let year of screening.nextScreeningYearRange" [value]="year.programYearId">
              {{ year.programYearForDisplay }}
            </option>

          </select>

        </div>
        <div class="col-md-5">
          <button class="btn btn-secondary" (click)="changeNextScreeningYear()">Change Next Screening Year</button>
        </div>
      </div>

      <div class="form-group row">
        <label class="form-control-label col-md-3">Private Comment</label>
        <div class="col-md-9">
          <textarea formControlName="managerComment" class="form-control"></textarea>
        </div>
      </div>

    </form>

  </div>

  <div class="modal-footer">
    <div *ng4hManagerPermissions="{VolunteerScreening_Approval: ePermissionState.Write}">
      <button class="btn btn-secondary ng4h-margin-right" *ngIf="screening.status === eProfileVolunteerScreeningStatus.Submitted" (click)="sendBack(screening)">Send Back</button>
      <button class="btn btn-secondary" *ngIf="screening.status === eProfileVolunteerScreeningStatus.Submitted || screening.status === eProfileVolunteerScreeningStatus.Incomplete" (click)="changeScreeningLevel()">Change Screening Level</button>

      <ng-container *ng4hManagerHierarchyType="eHierarchyNodeType.Institution">
        <button *ngIf="screening.canDelete" class="btn btn-danger ng4h-margin-left" (click)="delete(screening)">Delete</button>
      </ng-container>
    </div>

    <button style="margin-left: auto;" class="btn btn-secondary" (click)="close()">Cancel</button>
    <button [disabled]="form == null || form.invalid || form.disabled" (click)="update()" class="btn btn-primary my-2 my-sm-0" role="button" aria-pressed="true">Save</button>
  </div>
</ng-container>