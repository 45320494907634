export enum eVolunteerType {
    ActivityVolunteer = 'ActivityVolunteer',
    CampVolunteer = 'CampVolunteer',
    AdultClubVolunteer = 'AdultClubVolunteer',
    YouthClubVolunteer = 'YouthClubVolunteer',
    Coach = 'Coach',
    ProgramVolunteer = 'ProgramVolunteer',
    AdultProjectVolunteer = 'AdultProjectVolunteer',
    YouthProjectVolunteer = 'YouthProjectVolunteer',
    NewApplicant = 'NewApplicant'
}

export enum eAdultVolunteerType {
    ActivityVolunteer = 'ActivityVolunteer',
    CampVolunteer = 'CampVolunteer',
    AdultClubVolunteer = 'AdultClubVolunteer',
    Coach = 'Coach',
    ProgramVolunteer = 'ProgramVolunteer',
    AdultProjectVolunteer = 'AdultProjectVolunteer',
    NewApplicant = 'NewApplicant'
}

export enum eYouthVolunteerType {
    YouthClubVolunteer = 'YouthClubVolunteer',
    YouthProjectVolunteer = 'YouthProjectVolunteer'
}

