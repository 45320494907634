export enum eAppSettingsAlias {
    Volunteer = 'Volunteer',
    CloverBud = 'CloverBud',
    Club = 'Club',
    CountyArea = 'CountyArea',
    DistrictArea = 'DistrictArea',
    Family = 'Family',
    Group = 'Group',
    RegionArea = 'RegionArea',
    ClubMember = 'ClubMember'
}
