<ng4h-dynamic-options-root [formDisplayTypes]="formDisplayTypes" [control]="control"
  (rootInputModified)="controlModified.emit($event)" (validatorAddOrModify)="validatorAddOrModify.emit($event)"
  (validatorRemove)="validatorRemove.emit($event)">

  <!-- <div class="form-group row">
    <label class="form-control-label col-md-3">Report Label</label>
    <div class="input-group col-md-9">
      <input class="form-control" type="text" [ngModel]="control.items[0].reportValue"
        (ngModelChange)="reportLabelChange($event, control.items[0].itemId)">
    </div>
  </div> -->
  <ng-container *ngIf="formType == eFormType.Registration">
    <div class="form-group row">
      <div class="input-group">
        <label class="form-control-label col-md-3">Fee</label>
        <div class="col-md-9">

          <input class="form-control" type="number" [ngModel]="control.items[0].rate" step="0.01"
            (ngModelChange)="changeItemRate( control.items[0].itemId,$event)">
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="input-group">
        <label class="form-control-label col-md-3">Number Available</label>
        <div class="col-md-9">
          <input class="form-control" type="number" [ngModel]="control.items[0].attributes.quantityAvailable"
            (ngModelChange)="numberAvailableChanged($event, control.items[0].itemId)">
        </div>
      </div>
    </div>
  </ng-container>

</ng4h-dynamic-options-root>