<div class="modal-header">
  <h5 class="modal-title">Edit Lesson</h5>
  <button (click)="close()" type="button" class="close" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form" class="row" [ngClass]="wasValidated ? 'was-validated' : 'needs-validation'" novalidate>
    <div class="form-group col-sm-12 row">
      <label class="form-control-label col-4">Lesson Title</label>
      <input formControlName="trainingLessonTitle" type="email" class="form-control col-8">
    </div>
    <div class="form-group col-sm-12 row">
      <label class="form-control-label col-4">Required</label>
      <select formControlName="required" class="form-control col-8">
        <option [value]="requiredFor.key" *ngFor="let requiredFor of eRequiredForCourseCompletionType | keyvalue">
          {{requiredFor.value}}</option>
      </select>
    </div>
  </form>

</div>
<div class="modal-footer">
  <button (click)="save()" class="btn btn-primary my-2 my-sm-0" role="button" aria-pressed="true">Save</button>
</div>