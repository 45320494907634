import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';


export const ErrorHttpAction = createAction(
  '[error] http',
  props<{ error: HttpErrorResponse, req: HttpRequest<any> }>()
);


