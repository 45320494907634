import { Pipe, PipeTransform } from '@angular/core';

import { eMemberParticipationStatus } from '../models';

@Pipe({
  name: 'participationStatus',
  pure: true
})

export class ParticipationStatusPipe implements PipeTransform {
  transform(status: eMemberParticipationStatus): string {
    switch (status) {
      case eMemberParticipationStatus.Approved: {
        return 'Approved';
      }
      case eMemberParticipationStatus.AwaitingReview: {
        return 'Awaiting Review';
      }
      case eMemberParticipationStatus.DeclinedAndBlocked: {
        return 'Declined';
      }
      case eMemberParticipationStatus.Deleted: {
        return 'Deleted';
      }
      case eMemberParticipationStatus.Dismissed: {
        return 'Dismissed';
      }
      case eMemberParticipationStatus.Incomplete: {
        return 'Incomplete';
      }
      case eMemberParticipationStatus.NotEnrolled: {
        return 'Approved';
      }
      case eMemberParticipationStatus.Participant: {
        return 'Not Enrolled';
      }
      case eMemberParticipationStatus.PaymentDue: {
        return 'Payment Due';
      }
      case eMemberParticipationStatus.Resubmit: {
        return 'Resubmit';
      }
      case eMemberParticipationStatus.Submitted: {
        return 'Submitted';
      }
      case eMemberParticipationStatus.ScreeningPendingApproval: {
        return 'Screening Pending Approval';
      }
      case eMemberParticipationStatus.TrainingsNotCompleted: {
        return 'Trainings Not Completed';
      }
      case eMemberParticipationStatus.PaymentNotReceivedByInstitution: {
        return 'Payment Processing';
      }
      case eMemberParticipationStatus.Archived: {
        return 'Archived';
      }
      case eMemberParticipationStatus.NotEnrolledActiveLastYear: {
        return 'Not Enrolled - Active Last Year';
      }
      case eMemberParticipationStatus.NotParticipating: {
        return 'Not Participating';
      }
    }
  }
}
