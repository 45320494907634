import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { eEventEmailType, IEventEmailTemplateDto } from 'app/core/models';

@Component({
  selector: 'ng4h-modal-add-event-email-template',
  templateUrl: './modal-add-event-email-template.component.html',
  styleUrls: ['./modal-add-event-email-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalAddEventEmailTemplateComponent implements OnInit {

  public form: FormGroup;
  public eEventEmailType = eEventEmailType;

  public emailTypes: string[];
  constructor(
    private dialogRef: MatDialogRef<ModalAddEventEmailTemplateComponent>,
    @Inject(MAT_DIALOG_DATA) public templates: IEventEmailTemplateDto[],
    private fb: FormBuilder
  ) {

  }

  ngOnInit() {
    this.form = this.fb.group({
      displayCaption: [],
      type: []
    });

    this.emailTypes = Object.keys(eEventEmailType).filter(emailType => {
      return this.templates.find(template => template.type === emailType) == null;
    });
  }

  public close(result?: any) {
    this.dialogRef.close(result);
  }

  public add() {
    this.close(this.form.value);
  }
}
