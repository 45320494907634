<div class="modal-section">
  <div class="modal-header">
    <h5 class="modal-title">Edit Charter</h5>
    <button (click)="cancel()" type="button" class="close" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="form">
      <div class="form-group col-sm-12 row">
        <label class="form-control-label col-6">Club Charter Status</label>
        <select class="form-control col-sm-6" formControlName="status">
          <option value=""></option>
          <option [value]="status.key" *ngFor="let status of eCharterStatusType | keyvalue">
            {{ status.key | camelToTitleCase}}
          </option>
        </select>

      </div>
      <div class="form-group col-sm-12 row">
        <label class="form-control-label col-6">Charter Comments</label>
        <input formControlName="charteringComment" class="form-control col-sm-6">
      </div>

      <div class="form-group col-sm-12 row">
        <label class="form-control-label col-6">Provisional Date</label>
        <input class="form-control col-sm-6" formControlName="provisionalDate" [matDatepicker]="provisionalDate">
        <mat-datepicker-toggle matSuffix [for]="provisionalDate"></mat-datepicker-toggle>
        <mat-datepicker #provisionalDate></mat-datepicker>
      </div>

      <div class="form-group col-sm-12 row">
        <label class="form-control-label col-6">Charter Date</label>
        <input class="form-control col-sm-6" formControlName="charteredDate" [matDatepicker]="charteredDate">
        <mat-datepicker-toggle matSuffix [for]="charteredDate"></mat-datepicker-toggle>
        <mat-datepicker #charteredDate></mat-datepicker>
      </div>

      <div class="form-group col-sm-12 row">
        <label class="form-control-label col-6">Renewal Date</label>

        <input class="form-control col-sm-6" formControlName="renewalDate" [matDatepicker]="renewalDate">
        <mat-datepicker-toggle matSuffix [for]="renewalDate"></mat-datepicker-toggle>

        <mat-datepicker #renewalDate></mat-datepicker>
      </div>

      <div class="form-group col-sm-12 row">
        <label class="form-control-label col-6">Probational Date</label>

        <input class="form-control col-sm-6" formControlName="probationalDate" [matDatepicker]="probationalDate">
        <mat-datepicker-toggle matSuffix [for]="probationalDate"></mat-datepicker-toggle>

        <mat-datepicker #probationalDate></mat-datepicker>
      </div>

      <div class="form-group col-sm-12 row">
        <label class="form-control-label col-6">Revoked Date</label>

        <input class="form-control col-sm-6" formControlName="revokedDate" [matDatepicker]="revokedDate">
        <mat-datepicker-toggle matSuffix [for]="revokedDate"></mat-datepicker-toggle>

        <mat-datepicker #revokedDate></mat-datepicker>
      </div>
      <div class="form-group col-sm-12 row">
        <label class="form-control-label col-6">Inactive Date</label>

        <input class="form-control col-sm-6" formControlName="inactiveDate" [matDatepicker]="inactiveDate">
        <mat-datepicker-toggle matSuffix [for]="inactiveDate"></mat-datepicker-toggle>

        <mat-datepicker #inactiveDate></mat-datepicker>
      </div>
      <div class="form-group col-sm-12 row">
        <label class="form-control-label col-6">Closed Date</label>

        <input class="form-control col-sm-6" formControlName="closedDate" [matDatepicker]="closedDate">
        <mat-datepicker-toggle matSuffix [for]="closedDate"></mat-datepicker-toggle>
        <mat-datepicker #closedDate></mat-datepicker>
      </div>
    </form>
  </div>

  <div class="modal-footer" *ngIf="form">
    <button id (click)="cancel()" class="btn btn-secondary my-2 my-sm-0" role="button"
      aria-pressed="true">Cancel</button>
    <button [disabled]="form.invalid" (click)="save()" class="btn btn-primary my-2 my-sm-0" role="button"
      aria-pressed="true">Save</button>
  </div>
</div>