<div style="margin-left: -16px;">
    <button mat-button (click)="changeHierarchy()">
        <ng-container *ngIf="leaf$ | async as leaf">
            <span class="d-xl-none">... ></span>

            <div *ngIf="selectedInstitution$ | async as selectedInstitution" [ngClass]="{
                'leaf': leaf.hierarchyNodeId == selectedInstitution.hierarchyNodeId,
                'd-none' : leaf.hierarchyNodeId != selectedInstitution.hierarchyNodeId
            }" class="hierarchy-section d-xl-inline">
                {{selectedInstitution.hierarchyNodeName}}
            </div>

            <div *ngIf="selectedRegionArea$ | async as selectedRegionArea" [ngClass]="{
                'leaf': leaf.hierarchyNodeId == selectedRegionArea.hierarchyNodeId,
                'd-none' : leaf.hierarchyNodeId != selectedRegionArea.hierarchyNodeId
            }" class="hierarchy-section d-lg-inline">
                <span class="d-none d-xl-inline">></span>
                {{selectedRegionArea.hierarchyNodeName}}
            </div>

            <div *ngIf="selectedDistrictArea$ | async as selectedDistrictArea" [ngClass]="{
                'leaf': leaf.hierarchyNodeId == selectedDistrictArea.hierarchyNodeId,
                'd-none' : leaf.hierarchyNodeId != selectedDistrictArea.hierarchyNodeId
            }" class="hierarchy-section d-md-inline">
                <span class="d-none d-lg-inline">></span>
                {{selectedDistrictArea.hierarchyNodeName}}
            </div>

            <div *ngIf="selectedCountyArea$ | async as selectedCountyArea" [ngClass]="{
                'leaf': leaf.hierarchyNodeId == selectedCountyArea.hierarchyNodeId
            }" class="hierarchy-section">
                <span class="d-none d-md-inline">></span>
                {{selectedCountyArea.hierarchyNodeName}}
            </div>

            <!-- <div *ngIf="selectedClub$ | async as selectedClub" [ngClass]="{
                'leaf': leaf.hierarchyNodeId == selectedClub.hierarchyNodeId
            }" class="hierarchy-section">
                <span class="d-xs-inline">></span>
                {{selectedClub.hierarchyNodeName}}
            </div> -->
        </ng-container>
    </button>
</div>