import { IEventDescriptorDto } from '.';
import { eEventRegistrationStatus } from '../enums';
import { eHierarchyNodeType } from './Hierarchy/eHierarchyNodeType';
import { IEventRegistrationStateDto } from './IEventRegistrationStateDto';
import { IEventRegistrationTypeDescriptorDto } from './IEventRegistrationTypeDescriptorDto';

export interface IEventRegistrationSearchResultDto {
  institutionProfileId: string;
  memberId: string;
  profileId: string;
  registrationId: string;
  birthDate: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  enrollmentCounty?: string;
  event: IEventDescriptorDto;
  registrationType: IEventRegistrationTypeDescriptorDto;
  status: eEventRegistrationStatus;
  registrationDate: string;
  approvalDate?: string;
  currentlyPendingApprovalLevel?: eHierarchyNodeType;
  registrationState: IEventRegistrationStateDto;


  registrationNumber?: number;
  eventName?: string;
  sessions?: string;

  registrationTypeName?: string;

}
