import { eProgramFeature } from '..';

export interface IBankAccountDto {
    bankAccountId: string;
    programFeature: eProgramFeature;
    bankAccountToken?: string;
    bankAccountName?: string;
    description?: string;
    defaultBankAccount: boolean;
    active: boolean;
    hierarchyNodeId: string;
    programDefault?: boolean;
}
