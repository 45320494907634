import { eProgramFeature } from '..';

export interface IBankAccountCreateDto {
    programFeature: eProgramFeature;
    bankAccountToken?: string;
    bankAccountName?: string;
    description?: string;
    defaultBankAccount: boolean;
    active: boolean;
}
