import { Component, Input, OnInit } from '@angular/core';
import ImageResize from '@deedmob/quill-image-resize-alt-module';
import ImageUploader from '@writergate/quill-image-uploader-nextjs';
import { Quill as QuillEditor } from 'quill';
import * as QuillNamespace from 'quill';
import { filter } from 'rxjs';

import { ModalService } from '../../services';
import { ModalEditRawTextComponent } from '../modal-edit-raw-text/modal-edit-raw-text.component';

const Quill: any = QuillNamespace;

Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageUploader', ImageUploader);

class IndentAttributor extends Quill.import('parchment').Attributor.Style {
    multiplier = 2;

    constructor(name: string, style: string, params: any) {
        super(name, style, params);
    }

    add(node, value) {
        return super.add(node, `${value * this.multiplier}rem`);
    }

    value(node) {
        return parseFloat(super.value(node)) / this.multiplier || undefined;
    }
}

const levels = [1, 2, 3, 4, 5];
const multiplier = 2;
const indentStyle = new IndentAttributor('indent', 'margin-left', {
    scope: Quill.import('parchment').Scope.BLOCK,
    whitelist: levels.map(value => `${value * multiplier}rem`)
});

Quill.register(indentStyle);

const DirectionAttribute = Quill.import('attributors/attribute/direction');
Quill.register(DirectionAttribute, true);

const AlignClass = Quill.import('attributors/class/align');
Quill.register(AlignClass, true);

const BackgroundClass = Quill.import('attributors/class/background');
Quill.register(BackgroundClass, true);

const ColorClass = Quill.import('attributors/class/color');
Quill.register(ColorClass, true);

const DirectionClass = Quill.import('attributors/class/direction');
Quill.register(DirectionClass, true);

const FontClass = Quill.import('attributors/class/font');
Quill.register(FontClass, true);

const SizeClass = Quill.import('attributors/class/size');
Quill.register(SizeClass, true);

const AlignStyle = Quill.import('attributors/style/align');
Quill.register(AlignStyle, true);

const BackgroundStyle = Quill.import('attributors/style/background');
Quill.register(BackgroundStyle, true);

const ColorStyle = Quill.import('attributors/style/color');
Quill.register(ColorStyle, true);

const DirectionStyle = Quill.import('attributors/style/direction');
Quill.register(DirectionStyle, true);

const FontStyle = Quill.import('attributors/style/font');
Quill.register(FontStyle, true);

const SizeStyle = Quill.import('attributors/style/size');
Quill.register(SizeStyle, true);

@Component({
    selector: 'ng4h-quill-editor-header-full',
    templateUrl: './quill-editor-header-full.component.html',
    styleUrls: ['./quill-editor-header-full.component.scss']
})
export class QuillEditorHeaderFullComponent implements OnInit {

    @Input('QuillEditor') QuillEditor: QuillEditor;

    constructor(private modalService: ModalService) {
    }

    ngOnInit(): void {

    }

    public editHtml() {

        this.modalService.openModal(ModalEditRawTextComponent, { data: this.QuillEditor.root.innerHTML })
            .pipe(filter(result => result != null))
            .subscribe({
                next: (result) => {
                    this.QuillEditor.root.innerHTML = result;
                },
                error: (err) => {

                }
            });
    }
}
