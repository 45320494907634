<div class="d-flex flex-column ng4h-card-container">
  <ng-content select="ng4h-card-header"></ng-content>

  <div class="d-flex flex-column ng4h-card-content">
    <ng-content select="ng4h-card-content"></ng-content>
  </div>

  <div class="d-flex flex-column ng4h-card-footer">
    <ng-content select="ng4h-card-footer"></ng-content>
  </div>
</div>