import { FormGroup, ValidationErrors } from '@angular/forms';

export class DemographicValidators {
  static Race(formGroup: FormGroup): ValidationErrors {
    if (formGroup.controls.racialBreakdownWhite.value ||
      formGroup.controls.racialBreakdownBlack.value ||
      formGroup.controls.racialBreakdownAmericanIndianOrAlaskanNative.value ||
      formGroup.controls.racialBreakdownNativeHawaiianOrPacificIslander.value ||
      formGroup.controls.racialBreakdownAsian.value ||
      formGroup.controls.racialBreakdownBalanceOfOtherCombinations.value ||
      formGroup.controls.racialBreakdownUndetermined.value ||
      formGroup.controls.ethnicity.value === 'Hispanic') {
      return null;
    } else {
      return { noRaceSelected: true };
    }
  }

  static School(formGroup: FormGroup): ValidationErrors {
    if (formGroup.controls.schoolInputSelectionRadio.value) {
      if (formGroup.controls.schoolCountyId.value == null ||
        formGroup.controls.schoolDistrictId.value == null ||
        formGroup.controls.schoolId.value == null) {
        return { mustSelectAllSchoolDropdowns: true };
      }
    } else {
      if (formGroup.controls.schoolName.value == null ||
        formGroup.controls.schoolName.value === '' ||
        formGroup.controls.schoolType.value == null ||
        formGroup.controls.schoolType.value === 'Unknown') {
        return { mustInputSchoolNameAndType: true };
      }
    }
  }

  // static MilitaryService(formGroup: FormGroup): ValidationErrors {
  //     if (formGroup.controls.militaryService.value !== eMilitaryFamilyServiceType.NoOneInMyFamilyIsServingInTheMilitary) {
  //         if (formGroup.controls.militaryBranch.value == null || formGroup.controls.militaryComponent.value == null) {
  //             return { militaryServiceInvalid: true };
  //         }
  //     }
  //     return null;
  // }
}
