import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { IOfflineDto } from 'app/core/models';
import { RouterService } from 'app/core/services';
import { SystemService } from 'app/core/services/system.service';
import { take } from 'rxjs';

@Component({
  selector: 'ng4h-modal-offline-schedule',
  templateUrl: './modal-offline-schedule.component.html',
  styleUrls: ['./modal-offline-schedule.component.scss']
})
export class ModalOfflineScheduleComponent implements OnInit {

  public form: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<any>,
    private fb: FormBuilder,
    private systemService: SystemService,
    private routerService: RouterService
  ) { }

  ngOnInit(): void {
    this.systemService.getInstitutionStatus({ institutionId: this.routerService.institutionId }).pipe(take(1)).subscribe(status => {
      this.form = this.fb.group({
        message: [status.message],
        allowSystemManagers: [status.allowSystemManagers],
        allowManagers: [status.allowManagers],
        offlineDate: [status.offlineDate],
        onlineDate: [status.onlineDate]
      });
    });
  }

  public save() {
    this.close(this.form.value);
  }
  public close(res?: IOfflineDto): void {
    this.dialogRef.close(res);
  }
}
