import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { RouterService, UserService } from 'app/core/services';
import { map, Observable, take, tap } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class AuthenticationGuard implements CanActivate {

  constructor(private userService: UserService, private routerService: RouterService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.userService.getLoginCredentials().pipe(
      map(credentials => {
        return credentials != null;
      }),
      tap(hasCredentials => {
        if (!hasCredentials) {
          if (state.url !== '/404' && state.url.indexOf('lastRouteParams') === -1) {
            this.routerService.Go(['user', 'sign-in'], { queryParams: { lastRouteParams: state.url } });
          } else {
            this.routerService.Go(['user', 'sign-in'], { queryParams: { lastRouteParams: decodeURIComponent(state.url.split('lastRouteParams=')[1]) } });
          }
        }
      }),
      take(1),
    );
  }
}
