import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[ng4hDecimalPrecision]'
})
export class DecimalPrecisionDirective {

  @Input() public precision: number;

  constructor(private el: ElementRef, private ngControl: NgControl) {
  }

  @HostListener('input', ['$event'])
  public onEvent($event) {
    const parsedValue = this.parse(this.el.nativeElement.value);
    this.ngControl.control.setValue(parsedValue);
  }

  private parse(value: string): string {
    let decimal = parseFloat(value);
    if (value === undefined || value === '') {
      return null;
    }

    if (decimal > Math.floor(decimal)) {
      decimal = parseFloat(decimal.toFixed(this.precision));
    }

    return decimal.toString();
  }
}
