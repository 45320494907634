export enum eAdultEnrollmentStepperSteps {
    VolunteerType = 'Volunteer Type',

    Clubs = 'Clubs',
    Projects = 'Projects',

    Questions = 'Questions',
    HealthForm = 'Health Form',
    Consents = 'Consents',
    Payment = 'Payment',
    Confirm = 'Confirm'
}
