<ng-container *ngIf="enrollment$ | async as enrollment">
    <ng-container *ngIf="member$ | async as member">
        <div class="row">
            <h2 class="col-12">{{member.firstName}}'s Enrollment</h2>

            <div class="col-12">
                <ng4h-card>
                    <ng4h-card-content>
                        <div class="row">
                            <div class="col-2">
                                <i class="fa-5x fas fa-user-circle"></i>
                            </div>
                            <div class="col-10">
                                <div class="row">
                                    <div class="col-12">
                                        <ng-container *ngIf="member.birthDate"><b>Birth Date:</b>
                                            {{member.birthDate | date: 'M/d/yyyy'}}</ng-container>
                                        <ng-container *ngIf="member.age">, <b>Age:</b>
                                            {{member.age}}</ng-container>

                                        <ng-container *ngIf="enrollment.schoolGrade">, <b>Grade:</b>
                                            {{enrollment.schoolGrade |
                                            camelToTitleCase}}</ng-container>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <ng-container *ngIf="enrollment.enrollmentRole"><b>Role:</b>
                                            {{enrollment.enrollmentRole | camelToTitleCase}}</ng-container>
                                        <ng-container *ngIf="enrollment?.youthDivision?.youthDivisionLabel">, <b>Youth
                                                Division:</b>
                                            {{enrollment.youthDivision.youthDivisionLabel}}</ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng4h-card-content>
                </ng4h-card>
            </div>

            <div class="col-12">
                <div class="row" *ngIf="enrollment.units?.length > 0">
                    <table class="col-8 offset-2">
                        <th>Selected Units</th>
                        <tbody>
                            <tr *ngFor="let unit of enrollment.units">
                                <td>{{unit.unitName}}, {{unit.countyArea.hierarchyNodeName}} {{unit.primary ? '-
                                    Primary':''}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-12" *ngIf="enrollment.projects?.length > 0">
                <div class="row">
                    <table class="col-8 offset-2">
                        <th>Selected Projects</th>
                        <tbody>
                            <tr *ngFor="let project of enrollment.projects">
                                <td>{{project.projectName}} - {{project.unitName}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>


            <!-- <h3 class="col-12">Invoices</h3>P
    <div class="col-12">
        {{(invoice | async).}}
    </div> -->
            <!-- <pre>{{invoice | async | json}}</pre> -->
            <!-- <pre>{{enrollment | async | json}}</pre> -->
        </div>
    </ng-container>
</ng-container>