import { IProgramSettingsDto, IProgramYearDto } from '.';
import { eProgramType } from '..';

export interface IInstitutionProgramDto {
    institutionProgramId: string;
    programId: string;
    programType: eProgramType;
    programName?: string;
    programDescription?: string;
    enrollmentYear: IProgramYearDto;
    programYear: IProgramYearDto;
    settings: IProgramSettingsDto;
    activeProgramYears?: IProgramYearDto[];
    availableProgramYears?: IProgramYearDto[];
}
