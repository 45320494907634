import { Action, createReducer, on } from '@ngrx/store';
import { IInstitutionProgramModel, IInstitutionVolunteerDto } from 'app/core/models';
import { mergeImmutable } from 'app/shared/utils';

import { VolunteerTypesActions } from '.';
import { SystemManagerTrainingActions } from '../../../training/store';

export interface SystemManagerVolunteerTypesState {
    volunteerTypes: IInstitutionProgramModel<IInstitutionVolunteerDto[]>;
}

const initialState: SystemManagerVolunteerTypesState = {
    volunteerTypes: undefined
};

const reducer = createReducer(
    initialState,
    on(VolunteerTypesActions.VolunteerTypesLoadSuccessAction, (state, { institutionId, systemManagerId, programId, volunteerTypes }) => {
        const newVolunteerTypes = mergeImmutable(
            { [institutionId]: { [systemManagerId]: { [programId]: volunteerTypes } } },
            state.volunteerTypes
        );
        return {
            ...state,
            volunteerTypes: newVolunteerTypes
        };
    }),
    on(VolunteerTypesActions.VolunteerTypesCreateSuccessAction,
        VolunteerTypesActions.VolunteerTypesUpdateSuccessAction,
        VolunteerTypesActions.VolunteerTypesDeleteSuccessAction,
        VolunteerTypesActions.VolunteerTypesAssociateConsentSuccessAction,
        VolunteerTypesActions.VolunteerTypesRemoveConsentAssociationSuccessAction,
        SystemManagerTrainingActions.SystemManagerLinkTrainingForVolunteerRoleSuccessAction,
        SystemManagerTrainingActions.SystemManagerDeleteTrainingForVolunteerRoleSuccessAction,
        (state, { institutionId, systemManagerId, programId }) => {
            const newVolunteerTypes = mergeImmutable(
                { [institutionId]: { [systemManagerId]: { [programId]: undefined } } },
                state.volunteerTypes
            );
            return {
                ...state,
                volunteerTypes: newVolunteerTypes
            };
        }),

);

export function enrollmentReducer(state: SystemManagerVolunteerTypesState | undefined, actions: Action) {
    return reducer(state, actions);
}

export const volunteerTypes = (state: SystemManagerVolunteerTypesState) => state.volunteerTypes;
