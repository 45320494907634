import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { eProfileType } from 'app/core/models';
import { ProfileService, RouterService } from 'app/core/services';
import { filter, map, Observable, take, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FamilyGuard implements CanActivate {
  constructor(private routerService: RouterService, private profileService: ProfileService) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.profileService.actingAsInstitutionProfile.pipe(
      take(1),
      filter(profile => profile != null),
      map(profile => {
        return profile && profile.profileType === eProfileType.Family;
      }),
      tap(isFamily => {
        if (!isFamily) {
          console.error('Non family tried to access family area');
        }
      })
    );
  }
}
