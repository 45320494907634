import { eProfileType } from '../enums';

export interface IProfileDescriptorDto {
  profileId: string;
  profileType: eProfileType;
  email?: string;
  institutionId: string;
  institutionProfileId: string;
  profileToken: string;
  name?: string;
}
