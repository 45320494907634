import { eGroupStatus } from '..';

export interface IGroupUpdateDto {
    status: eGroupStatus;
    label?: string;
    description?: string;
    hierarchyNodeId: string;
    deliveryModeId: string;
    allowMembersToRegister: boolean;
}
