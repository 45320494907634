<div class="modal-header">
    <h5 class="modal-title">Permissions</h5>
    <button class="close" (click)="close()" type="button" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <pre>{{acl$ | async | json}}</pre>
</div>
<div class="modal-footer">
    <button class="btn btn-secondary" (click)="close()">Close</button>
</div>
