import { eEventRegistrationStatus, IEventDescriptorDto, IEventRegistrationTypeDescriptorDto } from '..';
import { IEventRegistrationStateDto } from './IEventRegistrationStateDto';

export interface IEventRegistrationDescriptorDto {
    registrationId: string;
    registrationIdentifier: number;
    registrationStatus: eEventRegistrationStatus;
    eventDescription: IEventDescriptorDto;
    registrationType: IEventRegistrationTypeDescriptorDto;
    registrationState: IEventRegistrationStateDto;
    additionalPaymentRequired: boolean;
}
