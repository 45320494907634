import 'hammerjs';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from 'app/app.module';
import { environment } from 'environments/environment';


if (environment.production) {
  enableProdMode();
}

// Internet Explorer 6-11
var isIE = /*@cc_on!@*/false || !!(document as any).documentMode;

if (!isIE) {

  const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

  bootstrap().catch(err => console.log(err));

} else {
  alert('Browser is unsupported');
}
