import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IInstitutionManagerProgramHierarchyParams, IMemberSearchParametersDto } from 'app/core/models';
import { IMemberSearchResultsDto } from 'app/core/models/serverDTOs/IMemberSearchResultsDto';


export const SearchEnrollmentsAction = createAction(
    '[enrollment-search] Search enrollments',
    props<IInstitutionManagerProgramHierarchyParams & { searchParameters: IMemberSearchParametersDto }>()
);

export const SearchEnrollmentsSuccessAction = createAction(
    '[enrollment-search] Search enrollments success',
    props<IInstitutionManagerProgramHierarchyParams & { searchParameters: IMemberSearchParametersDto, enrollmentsSearchResult: IMemberSearchResultsDto }>()
);

export const SearchEnrollmentsErrorAction = createAction(
    '[enrollment-search] Search enrollments error',
    props<{ error: HttpErrorResponse }>()
);

export const GetEnrollmentSearchResultsAction = createAction(
    '[enrollment-search] get enrollment search results',
    props<IInstitutionManagerProgramHierarchyParams & { pageSize: string, pageIndex: string }>()
);

export const GetEnrollmentSearchResultsSuccessAction = createAction(
    '[enrollment-search] get enrollment search results success',
    props<IInstitutionManagerProgramHierarchyParams & { pageSize: string, pageIndex: string, enrollmentsSearchResult: IMemberSearchResultsDto }>()
);

export const GetEnrollmentSearchResultsErrorAction = createAction(
    '[enrollment-search] get enrollment search results error',
    props<{ error: HttpErrorResponse }>()
);

export const ClearEnrollmentSearchAction = createAction(
    '[enrollment-search] clear enrollment search',
    props<IInstitutionManagerProgramHierarchyParams>()
);

export const ClearEnrollmentSearchSuccessAction = createAction(
    '[enrollment-search] clear enrollment search success',
    props<IInstitutionManagerProgramHierarchyParams>()
);

export const ClearEnrollmentSearchErrorAction = createAction(
    '[enrollment-search] clear enrollment search error',
    props<{ error: HttpErrorResponse }>()
);