<div class="modal-header">
    <h5 class="modal-title">Invoice</h5>
    <button (click)="close()" type="button" class="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" *ngIf="invoice$ | async as invoice">
    <ng-container *ngIf="member$ | async as member">
        <h2 class="ng4h-center-text">{{ member.firstName }} {{ member.lastName }}</h2>

        <ng-container *ngIf="enrollment$ | async as enrollment">
            <!-- <ng-container *ngIf="primaryClub$ | async as primaryClub"> -->
            <h3 class="ng4h-center-text">{{ (enrollment.programYearId | programYear).programYearForDisplay }}{{ invoice.description }}</h3>
            <h4 class="ng4h-center-text ng4h-margin-bottom">{{ invoice.dateCreated | date }}</h4>
          <!-- </ng-container> -->

            <div class="row">
                <div class="col-6">
                    <h2><b>Balance Due: {{ invoice.amountOutstanding | currency }}</b></h2>
                </div>
                <div class="col-6">
                    <ng-container *ngIf="isInstitutionManager$ | async">
                        <ng-container *ng4hManagerPermissions="{Financial_Waive_Fees: ePermissionState.Write}">
                            <button *ngIf="enrollment.creditsEnabled == true" class="btn btn-primary float-right"
                                    (click)="applyCredit(invoice, member)">Pay By Check</button>
                            <button class="btn btn-secondary float-right ng4h-margin-right"
                                    (click)="waiveFees()">Waive
                                Fees</button>
                        </ng-container>
                    </ng-container>

                </div>
            </div>
        </ng-container>

        <div class="ng4h-margin-top">
            <h4>Line Items</h4>
            <ng4h-table [columns]="lineItemColumns" [dataSource]="lineItems$" (rowClicked)="rowClicked($event)"
                        [columnTransforms]="lineItemColumnTransforms"></ng4h-table>
        </div>

        <div class="ng4h-margin-top">
            <h4>Payments</h4>
            <ng4h-table [columns]="paymentsColumns" [dataSource]="payments$" (rowClicked)="rowClicked($event)"
                        [columnTransforms]="paymentsColumnTransforms"></ng4h-table>
        </div>

        <form *ngIf="invoice.amountOutstanding > 0" [formGroup]="couponCodeForm" class="ng4h-margin-top">
            <div class="form-group row">
                <div class="col-12">
                    <div class="row no-gutters coupon-input-row">
                        <label class="form-control-label col-md-3">Coupon Code</label>
                        <div class="col-md-6">
                            <input formControlName="couponCode" class="form-control" type="text">
                        </div>
                        <div class="col-3">
                            <button class="btn btn-primary float-right" (click)="applyCoupon()"
                                    [disabled]="couponCodeForm.invalid">Apply</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
      
      <!-- <form [formGroup]="form">
      <div class="form-group row">
          <label class="form-control-label col-md-3 ng4h-required">Coupon Code</label>
          <div class="col-md-9">
              <input formControlName="couponCode" class="form-control" type="text">
          </div>
      </div>
  </form> -->
      <!-- <pre>
  {{invoice$ | async | json}}
</pre> -->
    </ng-container>
</div>
<div class="modal-footer">
    <button *ngIf="pendingTransactions$ | async" class="btn btn-primary" (click)="processPayment()">Process Payment</button>
    <button class="btn btn-secondary" (click)="close()">Close</button>
</div>