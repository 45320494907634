import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  IInstitutionFamilyParams,
  IInstitutionFamilyProgramParams,
  IInstitutionProgramParams,
  IPaymentMethodDto,
  ITokenizedCardDto,
} from 'app/core/models';

// export const CreditCardLoadGatewayConfigurationAction = createAction(
//   '[credit-card] Load gateway config',
//   props<IInstitutionProgramParams>()
// );
// export const CreditCardLoadGatewayConfigurationSuccessAction = createAction(
//   '[credit-card] Load gateway config success',
//   props<IInstitutionProgramParams & { config: IGatewayConfiguration }>()
// );
// export const CreditCardLoadGatewayConfigurationErrorAction = createAction(
//   '[credit-card] Load gateway config error',
//   props<{ error: HttpErrorResponse }>()
// );

export const CreditCardSaveFamilyCardAction = createAction(
  '[credit-card] Save family card',
  props<IInstitutionProgramParams & { familyId: string, cardSubmission: ITokenizedCardDto }>()
);
export const CreditCardSaveFamilyCardSuccessAction = createAction(
  '[credit-card] Save family card success',
  props<IInstitutionProgramParams & { familyId: string, cardSubmission: ITokenizedCardDto, paymentMethod: IPaymentMethodDto }>()
);
export const CreditCardSaveFamilyCardErrorAction = createAction(
  '[credit-card] Save family card error',
  props<{ error: HttpErrorResponse }>()
);


export const CreditCardRemoveFamilyPaymentMethodAction = createAction(
  '[credit card] remove family payment method',
  props<IInstitutionFamilyParams & { paymentMethodId: string }>()
);
export const CreditCardRemoveFamilyPaymentMethodSuccessAction = createAction(
  '[credit card] remove family payment method success',
  props<IInstitutionFamilyParams & { paymentMethodId: string }>()
);
export const CreditCardRemoveFamilyPaymentMethodErrorAction = createAction(
  '[credit card] remove family payment method error',
  props<{ error: HttpErrorResponse }>()
);

export const GetAuthorizeNetToken = createAction(
  '[credit card] Load authorize net token',
  props<IInstitutionFamilyProgramParams>()
);
export const GetAuthorizeNetSuccessToken = createAction(
  '[credit card] Load authorize net token success',
  props<IInstitutionFamilyProgramParams & { token: any }>()
);
export const GetAuthorizeNetErrorToken = createAction(
  '[credit card] Load authorize net token error',
  props<{ error: HttpErrorResponse }>()
);