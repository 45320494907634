<div class="modal-header">
    <h5 class="modal-title">Edit Unit</h5>
    <button (click)="close()" type="button" class="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <table *ngIf="enrollment$ | async as enrollment">
        <thead>
            <th>Primary</th>
            <th>{{(eUnitType.Club | programAlias$ | async)?.singular}}</th>
            <th>Type</th>
            <th>County</th>
            <th>Volunteer Type</th>

        </thead>
        <tbody>
            <tr>
                <td>
                    <span *ngIf="data.unit.primary">Primary</span>
                </td>

                <td>{{data.unit.unitName}}</td>
                <td>{{data.unit.unitType}}</td>
                <td>{{data.unit.countyArea.hierarchyNodeName}}</td>
                <td>
                    <ng-container *ngIf="enrollment.enrollmentRole !== eEnrollmentRoleType.CloverBud">
                        <ng-container *ngIf="volunteerTypes$ | async as vts">
                            <ng-container *ngIf="vts.length > 0">
                                <div class="form-group">
                                    <select class="form-control" [ngModel]="enrollment.enrollmentRole"
                                        (ngModelChange)="volunteerTypeChanged($event.volunteerTypeId)">
                                        <option *ngIf="enrollment.enrollmentRole !== eEnrollmentRoleType.Volunteer"
                                            [ngValue]="null"></option>
                                        <option *ngFor="let vt of vts" [ngValue]="vt">
                                            {{ vt.volunteerTypeLabel }}
                                        </option>
                                    </select>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </td>

            </tr>
        </tbody>
    </table>

    <button *ngIf="(isManager$ | async) === true" (click)="changeClubs()" class="btn btn-secondary ng4h-margin-top">Swap
        {{(eUnitType.Club | programAlias$ |
        async)?.singular}}</button>
</div>
<div class="modal-footer">
    <button class="btn btn-primary" (click)="save()">Save</button>
    <button class="btn btn-secondary" (click)="close()">Cancel</button>
</div>