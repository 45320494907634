import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { eEnrollmentRoleType, IConsentTypeDto } from 'app/core/models';
import { ConsentsService, RouterService } from 'app/core/services';
import { filter, map, Observable } from 'rxjs';

@Component({
  selector: 'ng4h-modal-link-project-consent',
  templateUrl: './modal-link-project-consent.component.html',
  styleUrls: ['./modal-link-project-consent.component.scss']
})
export class ModalLinkProjectConsentComponent implements OnInit {

  public consents$: Observable<IConsentTypeDto[]>;
  public form: FormGroup;
  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data,
    private fb: FormBuilder,
    private consentsService: ConsentsService,
    private routerService: RouterService) {
  }

  public ngOnInit() {

    this.consents$ = this.consentsService.getNonSystemConsents({ institutionId: this.routerService.institutionId, programId: this.routerService.programId })
      .pipe(
        filter(consents => consents !== null),
        map(consents => {
          return consents.filter(c => c.system === false);
        })
      );

    this.form = this.fb.group({
      consentTypeId: ['', Validators.required],
      enrollmentRole: [eEnrollmentRoleType.ClubMember, Validators.required],
    });
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public save(): void {
    this.dialogRef.close(this.form.value);
  }
}
