import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import { eHealthFormType, eScreeningType } from 'app/core/models';
import {
    IInstitutionProgramParams,
    IInstitutionSystemManagerParams,
    IInstitutionSystemManagerProgramParams,
} from 'app/core/models/function-parameters';

import * as systemManagerAnimals from './animals/store/system-manager-animals.reducer';
import * as systemManagerDeliveryModes from './enrollment/enrollment-delivery-modes/store/enrollment-delivery-modes.reducer';
import * as systemManagerVolunteerTypes from './enrollment/enrollment-volunteer-types/store/enrollment-volunteer-types.reducer';
import * as systemManagerProjects from './enrollment/project/store/projects.reducer';
import * as systemManagerEnrollment from './enrollment/store/system-manager-enrollment.reducer';
import * as systemManagerHealth from './health/store/health.reducer';
import * as systemManagerCustomFiles from './store/custom-files/custom-files.reducer';
import * as systemManagerFinances from './store/finances/system-manager-finances.reducer';
import * as systemManagerSchools from './store/schools/system-manager-schools.reducer';
import * as systemManagerScreenings from './system-manager-screenings/store/system-manager-screenings.reducer';
import * as systemManagerTraining from './training/store/system-manager-trainings.reducer';


export const SYSTEM_MANAGER_FEATURE = 'SYSTEM_MANAGER_FEATURE';

export interface SystemManagerFeatureStore {
  systemManagerCustomFiles: systemManagerCustomFiles.SystemManagerCustomFilesState;
  systemManagerHealth: systemManagerHealth.SystemManagerHealthState;
  systemManagerProjects: systemManagerProjects.SystemManagerProjectsState;
  systemManagerTraining: systemManagerTraining.SystemManagerTrainingState;
  systemManagerScreenings: systemManagerScreenings.SystemManagerScreeningsState;
  systemManagerEnrollment: systemManagerEnrollment.SystemManagerEnrollmentState;
  systemManagerFinances: systemManagerFinances.SystemManagerFinancesState;
  systemManagerVolunteerTypes: systemManagerVolunteerTypes.SystemManagerVolunteerTypesState;
  systemManagerDeliveryModes: systemManagerDeliveryModes.SystemManagerDeliveryModesState;
  systemManagerAnimals: systemManagerAnimals.SystemManagerAnimalsState;
  systemManagerSchools: systemManagerSchools.SystemManagerSchoolsState;
}

export const systemManagerReducers: ActionReducerMap<SystemManagerFeatureStore> = {
  systemManagerCustomFiles: systemManagerCustomFiles.systemManagerCustomFilesReducer,
  systemManagerHealth: systemManagerHealth.systemMangerHealthReducer,
  systemManagerProjects: systemManagerProjects.systemManagerProjectsReducer,
  systemManagerTraining: systemManagerTraining.systemManagerTrainingsReducer,
  systemManagerScreenings: systemManagerScreenings.systemManagerScreeningsReducer,
  systemManagerEnrollment: systemManagerEnrollment.systemManagerEnrollmentReducer,
  systemManagerFinances: systemManagerFinances.systemManagerFinanceReducer,
  systemManagerVolunteerTypes: systemManagerVolunteerTypes.enrollmentReducer,
  systemManagerDeliveryModes: systemManagerDeliveryModes.deliveryModesReducer,
  systemManagerAnimals: systemManagerAnimals.systemManagerAnimalTypesReducer,
  systemManagerSchools: systemManagerSchools.systemManagerSchoolsReducer
};

export const getSystemManagerFeatureStore = createFeatureSelector<SystemManagerFeatureStore>(SYSTEM_MANAGER_FEATURE);

export const getHealthState = createSelector(getSystemManagerFeatureStore, (state: SystemManagerFeatureStore) => state.systemManagerHealth);
export const getSystemManagerHealth = ({ institutionId, systemManagerId, programId, healthFormType }: IInstitutionSystemManagerProgramParams & { healthFormType: eHealthFormType }) => createSelector(
  createSelector(getHealthState, systemManagerHealth.healthForm),
  healthForm => healthForm?.[institutionId]?.[systemManagerId]?.[programId]?.[healthFormType]
);
export const getSystemManagerConsent = ({ institutionId, systemManagerId, programId, healthFormType }: IInstitutionSystemManagerProgramParams & { healthFormType: string }) => createSelector(
  createSelector(getHealthState, systemManagerHealth.healthFormConsent),
  consentVersions => consentVersions?.[institutionId]?.[systemManagerId]?.[programId]?.[healthFormType]
);

export const getProjectState = createSelector(getSystemManagerFeatureStore, (state: SystemManagerFeatureStore) => state.systemManagerProjects);
export const getSystemManagerProjects = ({ institutionId, systemManagerId, programId }: IInstitutionSystemManagerProgramParams) => createSelector(
  createSelector(getProjectState, systemManagerProjects.projects),
  projects => projects?.[institutionId]?.[systemManagerId]?.[programId]
);
export const getSystemManagerProject = ({ institutionId, systemManagerId, programId, projectId }: IInstitutionSystemManagerProgramParams & { projectId: string }) => createSelector(
  createSelector(getProjectState, systemManagerProjects.project),
  project => project?.[institutionId]?.[systemManagerId]?.[programId]?.[projectId]
);

export const getTrainingState = createSelector(getSystemManagerFeatureStore, (state: SystemManagerFeatureStore) => state.systemManagerTraining);
export const getSystemManagerTrainings = ({ institutionId, systemManagerId, programId }: IInstitutionSystemManagerProgramParams) => createSelector(
  createSelector(getTrainingState, systemManagerTraining.trainings),
  trainings => trainings?.[institutionId]?.[systemManagerId]?.[programId]
);
export const getSystemManagerTrainingCourse = ({ institutionId, systemManagerId, programId, trainingId, trainingCourseId }: IInstitutionSystemManagerProgramParams & { trainingId: string, trainingCourseId: string }) => createSelector(
  createSelector(getTrainingState, systemManagerTraining.trainingCourse),
  course => course?.[institutionId]?.[systemManagerId]?.[programId]?.[trainingId]?.[trainingCourseId]
);
export const getSystemManagerTrainingLesson = ({ institutionId, systemManagerId, programId, trainingId, trainingCourseId, trainingLessonId }: IInstitutionSystemManagerProgramParams & { trainingId: string, trainingCourseId: string, trainingLessonId: string }) => createSelector(
  createSelector(getTrainingState, systemManagerTraining.trainingLesson),
  lesson => lesson?.[institutionId]?.[systemManagerId]?.[programId]?.[trainingId]?.[trainingCourseId]?.[trainingLessonId]
);
export const getProgramAssociations = ({ institutionId, systemManagerId, programId }: IInstitutionSystemManagerProgramParams) => createSelector(
  createSelector(getTrainingState, systemManagerTraining.programAssociations),
  programAssociations => programAssociations?.[institutionId]?.[systemManagerId]?.[programId]
);

export const getCustomFilesState = createSelector(getSystemManagerFeatureStore, (state: SystemManagerFeatureStore) => state.systemManagerCustomFiles);

export const getSystemManagerCustomFiles = ({ institutionId, systemManagerId, programId }: IInstitutionSystemManagerProgramParams) => createSelector(
  createSelector(getCustomFilesState, systemManagerCustomFiles.customFiles),
  (customFiles) => customFiles?.[institutionId]?.[systemManagerId]?.[programId]
);

export const getScreeningsState = createSelector(getSystemManagerFeatureStore, (state: SystemManagerFeatureStore) => state.systemManagerScreenings);

export const getSystemManagerScreening = ({ institutionId, systemManagerId, programId, screeningType }: IInstitutionSystemManagerProgramParams & { screeningType: eScreeningType }) => createSelector(
  createSelector(getScreeningsState, systemManagerScreenings.screening),
  screening => screening?.[institutionId]?.[systemManagerId]?.[programId]?.[screeningType]
);


export const getEnrollmentState = createSelector(getSystemManagerFeatureStore, (state: SystemManagerFeatureStore) => state.systemManagerEnrollment);

export const getSystemManagerEnrollmentConsents = ({ institutionId, systemManagerId, programId }: IInstitutionSystemManagerProgramParams) => createSelector(
  createSelector(getEnrollmentState, systemManagerEnrollment.enrollmentConsents),
  consents => consents?.[institutionId]?.[systemManagerId]?.[programId]
);
export const getSystemManagerInvoices = ({ institutionId, systemManagerId, programId, programYearId }: IInstitutionSystemManagerProgramParams & { programYearId: string }) => createSelector(
  createSelector(getEnrollmentState, systemManagerEnrollment.invoices),
  invoices => invoices?.[institutionId]?.[systemManagerId]?.[programId]?.[programYearId]
);
export const getSystemManagerClubInvoices = ({ institutionId, systemManagerId, programId, countyAreaId, programYearId }: IInstitutionSystemManagerProgramParams & { countyAreaId: string, programYearId: string }) => createSelector(
  createSelector(getEnrollmentState, systemManagerEnrollment.clubInvoices),
  invoices => invoices?.[institutionId]?.[systemManagerId]?.[programId]?.[countyAreaId]?.[programYearId]
);

export const getSystemManagerFinancesState = createSelector(getSystemManagerFeatureStore, (state: SystemManagerFeatureStore) => state.systemManagerFinances);

export const getSystemManagerFunds = ({ institutionId, systemManagerId, programId }: IInstitutionSystemManagerProgramParams) => createSelector(
  createSelector(getSystemManagerFinancesState, systemManagerFinances.funds),
  (funds) => funds?.[institutionId]?.[systemManagerId]?.[programId]
);

export const getSystemManagerFundById = ({ institutionId, systemManagerId, programId, discountFundId }: IInstitutionSystemManagerProgramParams & { discountFundId: string }) => createSelector(
  createSelector(getSystemManagerFinancesState, systemManagerFinances.fund),
  (fund) => fund?.[institutionId]?.[systemManagerId]?.[programId]?.[discountFundId]
);

export const getSystemManagerVolunteerTypes = createSelector(getSystemManagerFeatureStore, (state: SystemManagerFeatureStore) => state.systemManagerVolunteerTypes);

export const getVolunteerTypes = ({ institutionId, systemManagerId, programId }: IInstitutionSystemManagerProgramParams) => createSelector(
  createSelector(getSystemManagerVolunteerTypes, systemManagerVolunteerTypes.volunteerTypes),
  volunteerTypes => volunteerTypes?.[institutionId]?.[systemManagerId]?.[programId]
);

export const getSystemManagerDeliveryModesState = createSelector(getSystemManagerFeatureStore, (state: SystemManagerFeatureStore) => state.systemManagerDeliveryModes);

export const getSystemManagerDeliveryModes = ({ institutionId, systemManagerId, programId }: IInstitutionSystemManagerProgramParams) => createSelector(
  createSelector(getSystemManagerDeliveryModesState, systemManagerDeliveryModes.deliveryModes),
  (deliveryModes) => deliveryModes?.[institutionId]?.[systemManagerId]?.[programId]
);
export const getSystemManagerSystemDeliveryModes = ({ institutionId, systemManagerId, programId }: IInstitutionSystemManagerProgramParams) => createSelector(
  createSelector(getSystemManagerDeliveryModesState, systemManagerDeliveryModes.systemDeliveryModes),
  (systemDeliveryModes) => systemDeliveryModes?.[institutionId]?.[systemManagerId]?.[programId]
);

export const getSystemManagerDeliveryMode = ({ institutionId, systemManagerId, programId, deliveryModeId }: IInstitutionSystemManagerProgramParams & { deliveryModeId: string }) => createSelector(
  createSelector(getSystemManagerDeliveryModesState, systemManagerDeliveryModes.deliveryMode),
  (deliveryMode) => deliveryMode?.[institutionId]?.[systemManagerId]?.[programId]?.[deliveryModeId]
);

export const getSystemManagerAnimalsState = createSelector(getSystemManagerFeatureStore, (state: SystemManagerFeatureStore) => state.systemManagerAnimals);

export const getSystemManagerAnimalTypes = ({ institutionId, systemManagerId, programId }: IInstitutionSystemManagerProgramParams) => createSelector(
  createSelector(getSystemManagerAnimalsState, systemManagerAnimals.animalTypes),
  (animalTypes) => animalTypes?.[institutionId]?.[systemManagerId]?.[programId]
);
export const getSystemManagerAnimalBreeds = ({ institutionId, programId, animalTypeId }: IInstitutionProgramParams & { animalTypeId: string }) => createSelector(
  createSelector(getSystemManagerAnimalsState, systemManagerAnimals.animalBreeds),
  (animalBreeds) => animalBreeds?.[institutionId]?.[programId]?.[animalTypeId]
);

export const getSystemManagerAnimalType = ({ institutionId, systemManagerId, programId, animalTypeId }: IInstitutionSystemManagerProgramParams & { animalTypeId: string }) => createSelector(
  createSelector(getSystemManagerAnimalsState, systemManagerAnimals.animalType),
  (animalType) => animalType?.[institutionId]?.[systemManagerId]?.[programId]?.[animalTypeId]
);


export const getSystemManagerAnimalForm = ({ institutionId, systemManagerId, programId, animalTypeId }: IInstitutionSystemManagerProgramParams & { animalTypeId: string }) => createSelector(
  createSelector(getSystemManagerAnimalsState, systemManagerAnimals.animalForm),
  (animalForm) => animalForm?.[institutionId]?.[systemManagerId]?.[programId]?.[animalTypeId]
);
export const getSystemManagerAnimalFileControlTypes = ({ institutionId, systemManagerId, programId }: IInstitutionSystemManagerProgramParams) => createSelector(
  createSelector(getSystemManagerAnimalsState, systemManagerAnimals.animalFileControlTypes),
  (animalForm) => animalForm?.[institutionId]?.[systemManagerId]?.[programId]
);

export const getSystemManagerSchoolsState = createSelector(getSystemManagerFeatureStore, (state: SystemManagerFeatureStore) => state.systemManagerSchools);

// export const getSystemManagerSchools = ({ institutionId, systemManagerId }: IInstitutionSystemManagerParams) => createSelector(
//   createSelector(getSystemManagerSchoolsState, systemManagerSchools.districtsWithSchools),
//   (schools) => schools?.[institutionId]?.[systemManagerId]
// );
// export const getSystemManagerSchool = ({ institutionId, systemManagerId, schoolId }: IInstitutionSystemManagerParams & { schoolId: string }) => createSelector(
//   createSelector(getSystemManagerSchoolsState, systemManagerSchools.school),
//   (school) => school?.[institutionId]?.[systemManagerId]?.[schoolId]
// );

export const getSystemManagerSchoolDistricts = ({ institutionId, systemManagerId }: IInstitutionSystemManagerParams) => createSelector(
  createSelector(getSystemManagerSchoolsState, systemManagerSchools.districts),
  (districts) => districts?.[institutionId]?.[systemManagerId]
);
export const getSystemManagerSchoolDistrict = ({ institutionId, systemManagerId, schoolDistrictId }: IInstitutionSystemManagerParams & { schoolDistrictId: string }) => createSelector(
  createSelector(getSystemManagerSchoolsState, systemManagerSchools.district),
  (districts) => districts?.[institutionId]?.[systemManagerId]?.[schoolDistrictId]
);
