<div class="modal-header">
    <h5 class="modal-title">Event Instructions</h5>
    <button (click)="close()" type="button" class="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="eventForm">
        <p-editor #editor1 formControlName="eventInstructions" [style]="{'height':'320px'}" pEditorDefaultModules>
            <ng-template pTemplate="header">
                <ng4h-quill-editor-header-full [QuillEditor]="editor1.quill"></ng4h-quill-editor-header-full>
            </ng-template>
        </p-editor>
    </form>
</div>
<div class="modal-footer">
    <button class="btn btn-secondary" (click)="close()">Cancel</button>
    <button class="btn btn-primary" [disabled]="eventForm.invalid" (click)="save()">Save</button>
</div>