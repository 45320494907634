import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs';

import { ModalService } from '../../services';
import { PublicEventsService } from '../../services/public-events.service';

@Component({
  selector: 'ng4h-root-routing',
  templateUrl: './root-routing.component.html',
  styleUrls: ['./root-routing.component.scss']
})
export class RootRoutingComponent implements OnInit {

  constructor(
    private router: Router,
    private publicEventsService: PublicEventsService,
    private modalService: ModalService
  ) { }

  ngOnInit(): void {

    this.publicEventsService.getEventFromVanityDomain().pipe(
      take(1),
      this.modalService.showProgressSpinnerModalUntilComplete()
    ).subscribe((event) => {
      if (event == null) {
        this.router.navigate(['user', 'sign-in']);
      } else {
        this.router.navigate(['public', event.institutionId, event.eventId]);
      }
    })

  }

}
