import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import {
  AutoUnsubscribe,
  eHierarchyNodeType,
  EMAIL_REGEX,
  eProfileType,
  ICountyCountyAreaDto,
  ICountyDescriptorDto,
  IFamilyDto,
  PHONE_REGEX,
} from 'app/core/models';
import { CommonToastrService, FamilyService, InstitutionService, ProfileService, RouterService } from 'app/core/services';
import { combineLatest, filter, forkJoin, map, Observable, skip, startWith, switchMap, take, takeUntil } from 'rxjs';

@Component({
  selector: 'ng4h-modal-edit-family',
  templateUrl: './modal-edit-family.component.html',
  styleUrls: ['./modal-edit-family.component.scss']
})
export class ModalEditFamilyComponent extends AutoUnsubscribe implements OnInit {

  public form: FormGroup;

  public family$: Observable<IFamilyDto>;

  public allowCountyEdit$: Observable<boolean>;
  public counties$: Observable<ICountyDescriptorDto[]>;

  public duplicateFamily;
  public countyAreas$: Observable<ICountyCountyAreaDto[]>;

  public isManger$: Observable<boolean>;

  constructor(
    private dialogRef: MatDialogRef<any>,
    private familyService: FamilyService,
    public routerService: RouterService,
    private fb: FormBuilder,
    private profileService: ProfileService,
    private institutionService: InstitutionService,
    private toastrService: CommonToastrService
  ) {
    super();
    this.family$ = this.familyService.getFamily({
      institutionId: this.routerService.institutionId,
      familyId: this.routerService.familyId
    }).pipe(filter(family => family != null), take(1));

    this.isManger$ = this.profileService.activeUserInstitutionProfileIsManager$;

    // any manager profile acl in any program that's an institution
    this.allowCountyEdit$ = this.profileService.loggedInAsInstitutionProfile.pipe(
      filter(profile => profile != null),
      map(activeUserInstitutionProfile => {
        if (activeUserInstitutionProfile.profileType === eProfileType.Superuser) {
          return true;
        }
        if (activeUserInstitutionProfile.profileType === eProfileType.Manager) {
          return Object.keys(activeUserInstitutionProfile.managerPrograms).find(program => {
            return activeUserInstitutionProfile.managerPrograms[program].find(managerProgram => {
              return [eHierarchyNodeType.Institution, eHierarchyNodeType.DistrictArea, eHierarchyNodeType.RegionArea].includes(managerProgram.hierarchyNodeType);
            }) != null;
          }) != null;
        }
        return false;
      })
    );



    this.counties$ = this.institutionService.getInstitutionCounties({ institutionId: this.routerService.institutionId });

    combineLatest([this.allowCountyEdit$, this.family$, this.isManger$]).subscribe(([allowCountyEdit, family, isManager]) => {
      this.form = this.fb.group({
        familyName: [family.familyName, Validators.required],
        email: [family.email, isManager ? [Validators.email, Validators.pattern(EMAIL_REGEX)] : [Validators.email, Validators.pattern(EMAIL_REGEX), Validators.required]],
        primaryMobilePhone: [family.primaryMobilePhone,
        [
          Validators.required,
          Validators.pattern(PHONE_REGEX)
        ],
        ],
        countyId: [{ value: family.countyId, disabled: allowCountyEdit === false }, Validators.required],
        countyAreaId: [family.countyAreaId, family.countyAreaId == null ? [] : [Validators.required]],
      });

      this.countyAreas$ = this.form.controls.countyId.valueChanges.pipe(
        startWith(family.countyId),
        switchMap(countyId => {
          return this.counties$.pipe(
            filter(counties => counties !== null),
            map(counties => counties.find(county => county.countyId === countyId).countyAreas),
            map(countyAreas => countyAreas?.length > 1 ? countyAreas : [])
          );
        }),
        takeUntil(this.autoUnsubscribe),
      );


      this.countyAreas$.pipe(skip(1)).subscribe(countyAreas => {
        this.form.controls.countyAreaId.setValue(null);
        if (countyAreas?.length > 0) {
          this.form.controls.countyAreaId.setValidators([Validators.required]);
        } else {
          this.form.controls.countyAreaId.clearValidators();
        }
        this.form.controls.countyAreaId.updateValueAndValidity();
      });
    });

  }

  ngOnInit() {
  }


  public save() {
    combineLatest([this.family$, this.allowCountyEdit$]).pipe(take(1)).subscribe(([family, allowCountyEdit]) => {

      const updateOperations = [];
      const { countyId, countyAreaId, ...familyUpdateData } = this.form.value;

      updateOperations.push(this.familyService.updateFamily({ institutionId: this.routerService.institutionId, familyId: this.routerService.familyId, familyUpdate: this.form.value }));
      if ((countyId !== family.countyId || countyAreaId !== family.countyAreaId) && allowCountyEdit) {
        updateOperations.push(this.familyService.updateFamilyCounty({ institutionId: this.routerService.institutionId, familyId: this.routerService.familyId, countyId, countyAreaId }));
      }

      this.duplicateFamily = null;
      forkJoin(updateOperations).subscribe({
        next: res => {
          this.close();
        }, error: (error) => {
          if (error instanceof HttpErrorResponse && error.status === 400 && error.error.type === 'EmailIsAlreadyInUse' && typeof error.error.body !== 'string') {
            this.toastrService.error('Duplicate Family');
            this.duplicateFamily = error.error.body;
          } else {
            this.toastrService.error('Could not update family', error);
          }
        }
      });

    });

  }
  public close() {
    this.dialogRef.close();
  }
}
