import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AutoUnsubscribe, IDynamicForm } from 'app/core/models';
import { DynamicFormService, EventsMemberService, RouterService } from 'app/core/services';
import { filter, map, Observable, take, takeUntil } from 'rxjs';

@Component({
  selector: 'ng4h-modal-edit-event-registration',
  templateUrl: './modal-edit-event-registration.component.html',
  styleUrls: ['./modal-edit-event-registration.component.scss']
})
export class ModalEditEventRegistrationComponent extends AutoUnsubscribe implements OnInit {
  public dynamicFormInputs$: Observable<IDynamicForm>;

  public dynamicFormFormGroup: FormGroup;


  public editDynamicForm: boolean;

  constructor(
    private dialogRef: MatDialogRef<ModalEditEventRegistrationComponent>,
    private routerService: RouterService,
    private eventsMemberService: EventsMemberService,
    private dynamicFormService: DynamicFormService
  ) {
    super();
    this.dynamicFormInputs$ = this.eventsMemberService.getDynamicForm({ institutionId: this.routerService.institutionId, memberId: this.routerService.selectedMemberId, programId: this.routerService.programId, eventRegistrationId: this.routerService.eventRegistrationId }).pipe(takeUntil(this.autoUnsubscribe));
    this.dynamicFormInputs$.pipe(
      filter(inputs => inputs != null),
      map(dynamicInputs => {
        return this.dynamicFormService.toFormGroup(dynamicInputs.groups.reduce((pre, cur) => pre.concat(cur.controls), []));
      }),
      take(1)
    ).subscribe((dynamicFormFormGroup) => {
      this.dynamicFormFormGroup = dynamicFormFormGroup;
    });
  }

  ngOnInit() {
  }

  public close(result?: any) {
    this.dialogRef.close(result);
  }

  public save() {
    this.close(this.dynamicFormFormGroup.value);
  }
}
