<div class="modal-header">
    <div class="modal-title">Select institution</div>
    <button *ngIf="!disableClose" (click)="close()" type="button" class="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row ng4h-list-row" [ngClass]="{'selected': currentInstitutionId === institution.institutionId}"
        *ngFor="let institution of institutions$ | async">
        <div class="col-6">
            <button class="btn btn-secondary" (click)="institutionSelected(institution)"
                [ngClass]="{'selected': currentInstitutionId === institution.institutionId}">{{institution.state}} -
                {{institution.institutionName}}
            </button>
        </div>
        <ng-container *ngIf="currentInstitutionId !== institution.institutionId">
            <div class="col-3"><button (click)="systemManagerClick(institution)" class="btn btn-secondary">System
                    Manager</button></div>
            <div class="col-3"><button (click)="managerClick(institution)" class="btn btn-secondary">Manager</button>
            </div>
        </ng-container>
    </div>
</div>