<div class="modal-header">
  <h5 class="modal-title">Edit Member</h5>
  <button (click)="close()" type="button" class="close" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form *ngIf="form != null" [formGroup]="form">
    <div class="form-group row">
      <label class="form-control-label col-md-3 ng4h-required">First Name</label>
      <div class="col-md-9">
        <input formControlName="firstName" class="form-control" type="text">
      </div>
    </div>
    <div class="form-group row">
      <label class="form-control-label col-md-3">Middle Name</label>
      <div class="col-md-9">
        <input formControlName="middleName" class="form-control" type="text">
      </div>
    </div>
    <div class="form-group row">
      <label class="form-control-label col-md-3 ng4h-required">Last Name</label>
      <div class="col-md-9">
        <input formControlName="lastName" class="form-control" type="text">
      </div>
    </div>
    <div class="form-group row">
      <label class="form-control-label col-md-3">Preferred Name</label>
      <div class="col-md-9">
        <input formControlName="preferredName" class="form-control" type="text">
      </div>
    </div>
    <div class="form-group row">
      <label class="form-control-label col-md-3">Member Email</label>
      <div class="col-md-9">
        <input formControlName="email" class="form-control" type="email">
        <small class="form-text text-muted" style="color: darkgreen !important">Member's
          personal
          email (if left blank, the family email will be used for direct
          communication)</small>
      </div>
    </div>
    <div class="form-group row">
      <label class="form-control-label col-md-3">Mobile Phone</label>
      <div class="col-md-9">
        <input formControlName="mobilePhone" class="form-control" type="text" [maxLength]="12" type="tel" ng4hPhoneMask>
      </div>
    </div>
    <div class="form-group row">
      <label class="form-control-label col-md-3">Birthdate</label>
      <div class="input-group col-md-9">
        <input class="form-control" formControlName="birthDate" [matDatepicker]="birthDate">
        <mat-datepicker-toggle matSuffix [for]="birthDate"></mat-datepicker-toggle>
      </div>
      <mat-datepicker #birthDate></mat-datepicker>
    </div>

    <br>
    <div class="form-group row">
      <label class="form-control-label col-md-3">Address</label>
      <div class="col-md-9">
        <input formControlName="addressLine1" class="form-control" type="text">
      </div>
    </div>
    <div class="form-group row">
      <label class="form-control-label col-md-3">Address 2</label>
      <div class="col-md-9">
        <input formControlName="addressLine2" class="form-control" type="text">
      </div>
    </div>
    <div class="form-group row">
      <label class="form-control-label col-md-3">City</label>
      <div class="col-md-9">
        <input formControlName="city" class="form-control" type="email">
      </div>
    </div>
    <div class="form-group row">
      <label class="form-control-label col-md-3">State</label>
      <div class="col-md-9">
        <!-- <input formControlName="state" class="form-control" type="text"> -->
        <ng4h-state-dropdown formControlName="state"></ng4h-state-dropdown>
      </div>
    </div>
    <div class="form-group row">
      <label class="form-control-label col-md-3">Postal Code</label>
      <div class="col-md-9">
        <input formControlName="postalCode" class="form-control" type="text">
      </div>
    </div>

  </form>
</div>
<div class="modal-footer">
  <button [disabled]="form.invalid" class="btn btn-primary" (click)="save()">Save</button>
  <button class="btn btn-secondary" (click)="close()">Cancel</button>
</div>