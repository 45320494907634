import { Pipe, PipeTransform } from '@angular/core';
import { map, Observable, of, take } from 'rxjs';

import { eEnrollmentRoleType } from '../models';
import { RouterService } from '../services';
import { ProgramSettingsService } from '../services/program-settings.service';


@Pipe({
    name: 'enrollmentRolesToCommaDelimitedList$',
    pure: true
})


export class EnrollmentRolesToCommaDelimitedListPipe implements PipeTransform {

    constructor(private programSettingsService: ProgramSettingsService, private routerService: RouterService) {
    }

    transform(enrollmentStatuses: { [role in eEnrollmentRoleType]: boolean } | eEnrollmentRoleType[], institutionId: string = this.routerService.institutionId, programId: string = this.routerService.programId): Observable<string> {
        if (enrollmentStatuses == null) {
            return of('');
        }
        let enrollmentRoles;
        if (Array.isArray(enrollmentStatuses)) {
            enrollmentRoles = enrollmentStatuses;
            if (enrollmentRoles.length < 1) {
                return of('');
            }
        } else {
            enrollmentRoles = Object.keys(enrollmentStatuses);
            if (!Array.isArray(enrollmentRoles) || enrollmentRoles.length < 1) {
                return of('');
            }
        }
        return this.programSettingsService.getProgramSettings({ institutionId, programId }).pipe(
            take(1),
            map(programSettings => {
                return enrollmentRoles.map(enrollmentRole => {
                    const alias = programSettings?.aliases?.[enrollmentRole]?.singular;
                    if (alias == null) {
                        return enrollmentRole;
                    }
                    return alias;
                }).join(', ');
            })
        );
    }
}
