import { eCountryCode } from '../enums/eCountryCode';
import { eStateAbbreviation } from '../enums/eStateAbbreviation';

export interface IAddressDto {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: eStateAbbreviation;
  postalCode: string;
  country: eCountryCode;
}
