<div class="modal-header">
  <h5 class="modal-title">Verify Email</h5>
  <button (click)="close()" type="button" class="close" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <div class="form-group col-sm-12">
      <label class="form-control-label">Email</label>
      <input class="form-control" formControlName="email" type="email">

    </div>

    <ng-container *ngIf="requireAdditionalInformation">
      <div class="form-group col-sm-12">
        <label class="form-control-label">First Name</label>
        <input class="form-control" formControlName="firstName" type="text">
      </div>
      <div class="form-group col-sm-12">
        <label class="form-control-label">Last Name</label>
        <input class="form-control" formControlName="lastName" type="text">
      </div>
    </ng-container>

    <div class="alert alert-danger" role="alert" *ngIf="errorMessage$ | async as errorMessage">
      {{ errorMessage }}
    </div>
  </form>
</div>
<div class="modal-footer">
  <button [disabled]="form.invalid" class="btn btn-primary" (click)="verify()">Continue</button>
</div>