import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  IClubEnrollmentHierarchyInvoiceItemsDto,
  IEnrollmentHierarchyInvoiceItemsDto,
  IEnrollmentInvoiceItemSubmitDto,
  IInstitutionManagerProgramParams,
  IInstitutionSystemManagerProgramParams,
  IInvoiceDto,
} from 'app/core/models';
import { IPaymentTransactionUpdateDto } from 'app/core/models/serverDTOs/IPaymentTransactionUpdateDto';

export const ManagerLoadEnrollmentFeesAction = createAction(
  '[manager-invoice] load enrollment Fees',
  props<IInstitutionManagerProgramParams & { programYearId: string }>()
);
export const ManagerLoadEnrollmentFeesSuccessAction = createAction(
  '[manager-invoice] load enrollment Fees success',
  props<IInstitutionManagerProgramParams & { programYearId: string, invoices: IEnrollmentHierarchyInvoiceItemsDto[] }>()
);
export const ManagerLoadEnrollmentFeesErrorAction = createAction(
  '[manager-invoice] load enrollment Fees error',
  props<{ error: HttpErrorResponse }>()
);

export const SystemManagerLoadEnrollmentFeesAction = createAction(
  '[system-manager-fees] load enrollment fees',
  props<IInstitutionSystemManagerProgramParams & { programYearId: string }>()
);
export const SystemManagerLoadEnrollmentFeesSuccessAction = createAction(
  '[system-manager-fees] load enrollment fees success',
  props<IInstitutionSystemManagerProgramParams & { programYearId: string, invoices: IEnrollmentHierarchyInvoiceItemsDto[] }>()
);
export const SystemManagerLoadEnrollmentFeesErrorAction = createAction(
  '[system-manager-fees] load enrollment fees error',
  props<{ error: HttpErrorResponse }>()
);


export const ManagerUpdateEnrollmentFeesAction = createAction(
  '[manager-invoice] update enrollment fee',
  props<IInstitutionManagerProgramParams & { programYearId: string, hierarchyNodeId: string, invoice: IEnrollmentInvoiceItemSubmitDto }>()
);
export const ManagerUpdateEnrollmentFeesSuccessAction = createAction(
  '[manager-invoice] update enrollment fee success',
  props<IInstitutionManagerProgramParams & { programYearId: string, hierarchyNodeId: string, invoice: IEnrollmentInvoiceItemSubmitDto }>()
);
export const ManagerUpdateEnrollmentFeesErrorAction = createAction(
  '[manager-invoice] update enrollment fee error',
  props<{ error: HttpErrorResponse }>()
);






export const ManagerLoadClubInvoicesAction = createAction(
  '[manager-invoice] load club invoices',
  props<IInstitutionManagerProgramParams & { countyAreaId: string, programYearId: string }>()
);
export const ManagerLoadClubInvoicesSuccessAction = createAction(
  '[manager-invoice] load club invoices success',
  props<IInstitutionManagerProgramParams & { countyAreaId: string, programYearId: string, clubInvoices: IClubEnrollmentHierarchyInvoiceItemsDto[] }>()
);
export const ManagerLoadClubInvoicesErrorAction = createAction(
  '[manager-invoice] load club invoices error',
  props<{ error: HttpErrorResponse }>()
);


export const ManagerUpdateClubInvoiceAction = createAction(
  '[manager-invoice] update club invoice',
  props<IInstitutionManagerProgramParams & { countyAreaId: string, programYearId: string, unitId: string, clubInvoice: IEnrollmentInvoiceItemSubmitDto }>()
);
export const ManagerUpdateClubInvoiceSuccessAction = createAction(
  '[manager-invoice] update club invoice success',
  props<IInstitutionManagerProgramParams & { countyAreaId: string, programYearId: string, unitId: string, clubInvoice: IEnrollmentInvoiceItemSubmitDto }>()
);
export const ManagerUpdateClubInvoiceErrorAction = createAction(
  '[manager-invoice] update club invoice error',
  props<{ error: HttpErrorResponse }>()
);


export const ManagerLoadInvoiceAction = createAction(
  '[manager-invoice] Load invoice',
  props<IInstitutionManagerProgramParams & { invoiceId: string }>()
);
export const ManagerLoadInvoiceSuccessAction = createAction(
  '[manager-invoice] Load invoice success',
  props<IInstitutionManagerProgramParams & { invoiceId: string, invoice: IInvoiceDto }>()
);
export const ManagerLoadInvoiceErrorAction = createAction(
  '[manager-invoice] Load invoice error',
  props<{ error: HttpErrorResponse }>()
);


export const ManagerUpdateTransactionAction = createAction(
  '[manager-invoice] Update invoice',
  props<IInstitutionManagerProgramParams & { invoiceId: string, paymentTransactionId: string, update: IPaymentTransactionUpdateDto }>()
);
export const ManagerUpdateTransactionSuccessAction = createAction(
  '[manager-invoice] Update invoice success',
  props<IInstitutionManagerProgramParams & { invoiceId: string, paymentTransactionId: string, update: IPaymentTransactionUpdateDto }>()
);
export const ManagerUpdateTransactionErrorAction = createAction(
  '[manager-invoice] Update invoice error',
  props<{ error: HttpErrorResponse }>()
);
