import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'yesNo',
    pure: true
})
export class YesNoPipe implements PipeTransform {
    public transform(value: boolean): string {
        if (value == null) {
            return 'No';
        } else if (value) {
            return 'Yes';
        }
        return 'No';
    }
}
