import { eHierarchyNodeType, IAddressDto, IHierarchyNodeDto, IUnitLeaderDto, IUnitProgramYearDto } from '.';
import { eCharterStatusType, eDayOfWeek, eEnrollmentRoleType, eUnitType } from '..';

export interface IUnitDto extends IHierarchyNodeDto {
    unitId: string;
    unitName?: string;
    unitType: eUnitType;
    isClub: boolean;
    hierarchyNodeId: string;
    hierarchy?: string;
    hierarchyNodeType: eHierarchyNodeType;
    hierarchyNodeName?: string;
    managers?: any[];
    projects?: any[];
    parentHierarchyNodeId: string;
    countyAreaName?: string;
    leaderLoginCode?: string;
    unitProgramYears?: IUnitProgramYearDto[];
    unitLeaders?: IUnitLeaderDto[];
    ein?: string;
    integrated?: boolean;
    raciallyMixed?: boolean;
    stateWide?: boolean;
    chartered?: boolean;
    chargeFee?: boolean;
    deliveryModeId: string;
    countyAreaId: string;
    contactName?: string;
    contactEmailAddress?: string;
    contactAddress: IAddressDto;
    primaryPhone?: string;
    mobilePhone?: string;
    websiteUrl?: string;
    meetingAddress: IAddressDto;
    meetingLocation?: string;
    meetingDay?: eDayOfWeek;
    meetingTime?: string;
    restrictedToRoleType?: eEnrollmentRoleType;
    formResponses?: object;

    allowMembersToRegister: boolean;

    charterStatus?: eCharterStatusType;

    notes?: string;

}

