export enum eEventEmailType {
    RegistrationSubmitted = 'RegistrationSubmitted',
    RegistrationMovedToNextLevel = 'RegistrationMovedToNextLevel',
    RegistrationApproved = 'RegistrationApproved',
    RegistrationDeclined = 'RegistrationDeclined',
    RegistrationSentBack = 'RegistrationSentBack',
    // CheckPaymentDue = 'CheckPaymentDue',
    // PaymentDeclined = 'PaymentDeclined',
    // PaymentApproved = 'PaymentApproved',
    // PartialPaymentApplied = 'PartialPaymentApplied',
    // PaymentRefunded = 'PaymentRefunded'
}
