<div class="d-flex ng4h-card-header">
  <div class="">
    <div class="card-header-title">
      <h4>
        <ng-content></ng-content>
      </h4>
    </div>
    <div class="card-header-title-text">
      <ng-content select="ng4h-card-header-title-text"></ng-content>
    </div>
  </div>
  <div class="p-2 ml-auto">
    <ng-content select="ng4h-card-actions"></ng-content>
  </div>
</div>