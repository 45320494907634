import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  IEnrollmentAwardSubmissionDto,
  IEnrollmentGroupSubmissionDto,
  IFamilyDto,
  IFileControl,
  IInstitutionFamilyParams,
  IInstitutionManagerProgramHierarchyParams,
  IInstitutionManagerProgramParams,
  IInstitutionMemberParams,
  IInstitutionMemberProgramEnrollmentParams,
  IInstitutionMemberProgramParams,
  IManagerCommentDto,
  IManagerNoteCreateDto,
  IManagerNoteDto,
  IManagerNoteUpdateDto,
  IMemberCreateDto,
  IMemberDashboard,
  IMemberDashboardSummaryDto,
  IMemberDto,
  IMemberMergeManagerSubmitDto,
  IMemberProgramDismissalDto,
  IMemberProgramReinstatementDto,
  IMemberProgramVolunteerHoursUpdateDto,
  IMemberProgramYearsInProgramUpdateDto,
  IMemberUpdateDto,
  IProfileTrainingRecordCreateDto,
} from 'app/core/models';

import { IEnrollmentActivitySubmissionDto } from '../../models/serverDTOs/IEnrollmentActivitySubmissionDto';

export const MemberLoadAction = createAction(
  '[member] Load',
  props<IInstitutionMemberParams>()
);
export const MemberLoadSuccessAction = createAction(
  '[member] Load success',
  props<IInstitutionMemberParams & { member: IMemberDto }>()
);
export const MemberLoadErrorAction = createAction(
  '[member] Load error',
  props<{ error: HttpErrorResponse }>()
);
export const MemberDashboardLoadAction = createAction(
  '[member] Dashboard load',
  props<{ institutionId: string, memberId: string }>()
);
export const MemberDashboardLoadSuccessAction = createAction(
  '[member] Dashboard load success',
  props<{ institutionId: string, memberId: string, memberDashboard: IMemberDashboard }>()
);
export const MemberDashboardLoadErrorAction = createAction(
  '[member] Dashboard load error',
  props<{ error: HttpErrorResponse }>()
);
export const MemberCreateAction = createAction(
  '[member] Create member',
  props<{ institutionId: string, familyId: string, member: IMemberCreateDto }>()
);
export const MemberCreateSuccessAction = createAction(
  '[member] Create member success',
  props<{ institutionId: string, familyId: string, memberId: string, member: IMemberDto }>()
);
export const MemberCreateErrorAction = createAction(
  '[member] Create member error',
  props<{ error: HttpErrorResponse }>()
);
export const MemberArchiveAction = createAction(
  '[member] Archive',
  props<{ institutionId: string, memberId: string }>()
);
export const MemberArchiveSuccessAction = createAction(
  '[member] Archive success',
  props<{ institutionId: string, memberId: string }>()
);
export const MemberArchiveErrorAction = createAction(
  '[member] Archive error',
  props<{ error: HttpErrorResponse }>()
);

export const MemberUnArchiveAction = createAction(
  '[member] UnArchive',
  props<{ institutionId: string, memberId: string }>()
);
export const MemberUnArchiveSuccessAction = createAction(
  '[member] UnArchive success',
  props<{ institutionId: string, memberId: string }>()
);
export const MemberUnArchiveErrorAction = createAction(
  '[member] UnArchive error',
  props<{ error: HttpErrorResponse }>()
);


export const MemberRemoveAction = createAction(
  '[member] Remove',
  props<{ institutionId: string, memberId: string }>()
);
export const MemberRemoveSuccessAction = createAction(
  '[member] Remove success',
  props<{ institutionId: string, memberId: string }>()
);
export const MemberRemoveErrorAction = createAction(
  '[member] Remove error',
  props<{ error: HttpErrorResponse }>()
);

export const MemberRestoreAction = createAction(
  '[member] Restore',
  props<{ institutionId: string, memberId: string }>()
);
export const MemberRestoreSuccessAction = createAction(
  '[member] Restore success',
  props<{ institutionId: string, memberId: string }>()
);
export const MemberRestoreErrorAction = createAction(
  '[member] Restore error',
  props<{ error: HttpErrorResponse }>()
);

export const MemberDismissAction = createAction(
  '[member] Dismiss',
  props<IInstitutionMemberProgramParams & { dismissal: IMemberProgramDismissalDto }>()
);
export const MemberDismissSuccessAction = createAction(
  '[member] Dismiss success',
  props<IInstitutionMemberProgramParams & { dismissal: IMemberProgramDismissalDto }>()
);
export const MemberDismissErrorAction = createAction(
  '[member] Dismiss error',
  props<{ error: HttpErrorResponse }>()
);

export const MemberReinstateAction = createAction(
  '[member] Reinstate',
  props<IInstitutionMemberProgramParams & { reinstate: IMemberProgramReinstatementDto }>()
);
export const MemberReinstateSuccessAction = createAction(
  '[member] Reinstate success',
  props<IInstitutionMemberProgramParams & { reinstate: IMemberProgramReinstatementDto }>()
);
export const MemberReinstateErrorAction = createAction(
  '[member] Reinstate error',
  props<{ error: HttpErrorResponse }>()
);

export const MemberMarkDeceasedAction = createAction(
  '[member] Member mark deceased',
  props<IInstitutionMemberParams & { deceasedDate: string }>()
);
export const MemberMarkDeceasedSuccessAction = createAction(
  '[member] Member mark deceased success',
  props<IInstitutionMemberParams & { deceasedDate: string }>()
);
export const MemberMarkDeceasedErrorAction = createAction(
  '[member] Member mark deceased error',
  props<{ error: HttpErrorResponse }>()
);

export const MemberDeleteAction = createAction(
  '[member] Delete',
  props<{ institutionId: string, memberId: string }>()
);
export const MemberDeleteSuccessAction = createAction(
  '[member] Delete success',
  props<{ institutionId: string, memberId: string }>()
);
export const MemberDeleteErrorAction = createAction(
  '[member] Delete error',
  props<{ error: HttpErrorResponse }>()
);
export const MemberUpdateAction = createAction(
  '[member] Update ',
  props<{ institutionId: string, memberId: string, member: IMemberUpdateDto }>()
);
export const MemberUpdateSuccessAction = createAction(
  '[member] Update success',
  props<{ institutionId: string, memberId: string, member: IMemberUpdateDto }>()
);
export const MemberUpdateErrorAction = createAction(
  '[member] Update error',
  props<{ error: HttpErrorResponse }>()
);
export const MemberInvalidateMemberCache = createAction(
  '[member] Invalidate member cache'
);
export const MemberLoadDashboardAction = createAction(
  '[member] Load dashboard',
  props<{ institutionId: string, memberId: string }>()
);
export const MemberLoadDashboardSuccessAction = createAction(
  '[member] Load dashboard success',
  props<{ institutionId: string, memberId: string, memberDashboard: IMemberDashboardSummaryDto }>()
);
export const MemberLoadDashboardErrorAction = createAction(
  '[member] Load dashboard error',
  props<{ error: HttpErrorResponse }>()
);


export const MemberFlagAction = createAction(
  '[member] flag',
  props<IInstitutionManagerProgramHierarchyParams & { memberId: string }>()
);
export const MemberFlagSuccessAction = createAction(
  '[member] flag success',
  props<IInstitutionManagerProgramHierarchyParams & { memberId: string }>()
);
export const MemberFlagErrorAction = createAction(
  '[member] flag error',
  props<IInstitutionManagerProgramHierarchyParams & { memberId: string, error: HttpErrorResponse }>()
);

export const MemberFlagDeleteAction = createAction(
  '[member] flag delete',
  props<IInstitutionManagerProgramHierarchyParams & { memberId: string }>()
);
export const MemberFlagDeleteSuccessAction = createAction(
  '[member] flag delete success',
  props<IInstitutionManagerProgramHierarchyParams & { memberId: string }>()
);
export const MemberFlagDeleteErrorAction = createAction(
  '[member] flag delete error',
  props<IInstitutionManagerProgramHierarchyParams & { memberId: string, error: HttpErrorResponse }>()
);

export const MemberClearAllFlagsAction = createAction(
  '[member] clear all flags',
  props<IInstitutionManagerProgramHierarchyParams>()
);
export const MemberClearAllFlagsSuccessAction = createAction(
  '[member] clear all flags success',
  props<IInstitutionManagerProgramHierarchyParams>()
);
export const MemberClearAllFlagsErrorAction = createAction(
  '[member] clear all flags error',
  props<IInstitutionManagerProgramHierarchyParams & { error: HttpErrorResponse }>()
);


export const MemberFlagAllResultsAction = createAction(
  '[member] flag all results',
  props<IInstitutionManagerProgramHierarchyParams>()
);
export const MemberFlagAllResultsSuccessAction = createAction(
  '[member] flag all results success',
  props<IInstitutionManagerProgramHierarchyParams>()
);
export const MemberFlagAllResultsErrorAction = createAction(
  '[member] flag all results error',
  props<IInstitutionManagerProgramHierarchyParams & { error: HttpErrorResponse }>()
);

export const MemberAddFlaggedToActivityAction = createAction(
  '[member] add flagged to activity',
  props<IInstitutionManagerProgramHierarchyParams & { activityId: string, activitySubmission: IEnrollmentActivitySubmissionDto }>()
);
export const MemberAddFlaggedToActivitySuccessAction = createAction(
  '[member] add flagged to activity success',
  props<IInstitutionManagerProgramHierarchyParams & { activityId: string, activitySubmission: IEnrollmentActivitySubmissionDto }>()
);
export const MemberAddFlaggedToActivityErrorAction = createAction(
  '[member] add flagged to activity error',
  props<IInstitutionManagerProgramHierarchyParams & { activityId: string, activitySubmission: IEnrollmentActivitySubmissionDto } & { error: HttpErrorResponse }>()
);

export const MemberAddFlaggedToAwardAction = createAction(
  '[member] add flagged to award',
  props<IInstitutionManagerProgramHierarchyParams & { awardId: string, awardSubmission: IEnrollmentAwardSubmissionDto }>()
);
export const MemberAddFlaggedToAwardSuccessAction = createAction(
  '[member] add flagged to award success',
  props<IInstitutionManagerProgramHierarchyParams & { awardId: string, awardSubmission: IEnrollmentAwardSubmissionDto }>()
);
export const MemberAddFlaggedToAwardErrorAction = createAction(
  '[member] add flagged to award error',
  props<IInstitutionManagerProgramHierarchyParams & { awardId: string, awardSubmission: IEnrollmentAwardSubmissionDto } & { error: HttpErrorResponse }>()
);

export const MemberAddFlaggedToGroupAction = createAction(
  '[member] add flagged to group',
  props<IInstitutionManagerProgramHierarchyParams & { groupId: string, groupSubmission: IEnrollmentGroupSubmissionDto }>()
);
export const MemberAddFlaggedToGroupSuccessAction = createAction(
  '[member] add flagged to group success',
  props<IInstitutionManagerProgramHierarchyParams & { groupId: string, groupSubmission: IEnrollmentGroupSubmissionDto }>()
);
export const MemberAddFlaggedToGroupErrorAction = createAction(
  '[member] add flagged to group error',
  props<IInstitutionManagerProgramHierarchyParams & { groupId: string, groupSubmission: IEnrollmentGroupSubmissionDto } & { error: HttpErrorResponse }>()
);

export const MemberAddFlaggedToTrainingAction = createAction(
  '[member] add flagged to training',
  props<IInstitutionManagerProgramHierarchyParams & { trainingId: string, trainingSubmission: IProfileTrainingRecordCreateDto }>()
);
export const MemberAddFlaggedToTrainingSuccessAction = createAction(
  '[member] add flagged to training success',
  props<IInstitutionManagerProgramHierarchyParams & { trainingId: string, trainingSubmission: IProfileTrainingRecordCreateDto }>()
);
export const MemberAddFlaggedToTrainingErrorAction = createAction(
  '[member] add flagged to training error',
  props<IInstitutionManagerProgramHierarchyParams & { trainingId: string, trainingSubmission: IProfileTrainingRecordCreateDto } & { error: HttpErrorResponse }>()
);


export const MemberFlagPageResultsAction = createAction(
  '[member] flag page results',
  props<IInstitutionManagerProgramHierarchyParams>()
);
export const MemberFlagPageResultsSuccessAction = createAction(
  '[member] flag page results success',
  props<IInstitutionManagerProgramHierarchyParams>()
);
export const MemberFlagPageResultsErrorAction = createAction(
  '[member] flag page results error',
  props<IInstitutionManagerProgramHierarchyParams & { error: HttpErrorResponse }>()
);

export const MergeMemberAction = createAction(
  '[member] merge',
  props<IInstitutionManagerProgramParams & { memberMerge: IMemberMergeManagerSubmitDto }>()
);
export const MergeMemberSuccessAction = createAction(
  '[member] merge success',
  props<IInstitutionManagerProgramParams & { memberMerge: IMemberMergeManagerSubmitDto }>()
);
export const MergeMemberErrorAction = createAction(
  '[member] merge error',
  props<{ error: HttpErrorResponse }>()
);

export const TogglePrimaryFamilyAction = createAction(
  '[member] toggle primary family',
  props<IInstitutionFamilyParams & { memberId: string, isPrimaryFamily: boolean }>()
);
export const TogglePrimaryFamilySuccessAction = createAction(
  '[member] toggle primary family success',
  props<IInstitutionFamilyParams & { memberId: string, isPrimaryFamily: boolean }>()
);
export const TogglePrimaryFamilyErrorAction = createAction(
  '[member] toggle primary family error',
  props<{ error: HttpErrorResponse }>()
);

export const RemoveMemberFromNonPrimaryFamilyAction = createAction(
  '[member] remove member from non primary family',
  props<IInstitutionFamilyParams & { memberId: string }>()
);
export const RemoveMemberFromNonPrimaryFamilySuccessAction = createAction(
  '[member] remove member from non primary family success',
  props<IInstitutionFamilyParams & { memberId: string }>()
);
export const RemoveMemberFromNonPrimaryFamilyErrorAction = createAction(
  '[member] remove member from non primary family error',
  props<{ error: HttpErrorResponse }>()
);

export const GetFamiliesForCountyAction = createAction(
  '[member] Get Families For County',
  props<{ institutionId: string, countyAreaId: string }>()
);
export const GetFamiliesForCountySuccessAction = createAction(
  '[member] Get Families For County success',
  props<{ institutionId: string, countyAreaId: string, families: IFamilyDto[] }>()
);
export const GetFamiliesForCountyErrorAction = createAction(
  '[member] Get Families For County error',
  props<{ error: HttpErrorResponse }>()
);

export const UpdateYearsInProgramAction = createAction(
  '[member] Update years in program',
  props<IInstitutionMemberProgramParams & { yearsInProgramUpdate: IMemberProgramYearsInProgramUpdateDto }>()
);
export const UpdateYearsInProgramSuccessAction = createAction(
  '[member] Update years in program success',
  props<IInstitutionMemberProgramParams & { yearsInProgramUpdate: IMemberProgramYearsInProgramUpdateDto }>()
);
export const UpdateYearsInProgramErrorAction = createAction(
  '[member] Update years in program error',
  props<{ error: HttpErrorResponse }>()
);
export const MoveToFamilyAction = createAction(
  '[member] Move to family',
  props<IInstitutionFamilyParams & { memberId: string }>()
);
export const MoveToFamilySuccessAction = createAction(
  '[member] Move to family success',
  props<IInstitutionFamilyParams & { memberId: string }>()
);
export const MoveToFamilyErrorAction = createAction(
  '[member] Move to family error',
  props<{ error: HttpErrorResponse }>()
);

export const ParticipatingEnrollmentAction = createAction(
  '[member] Participating enrollment',
  props<IInstitutionMemberProgramEnrollmentParams>()
);
export const ParticipatingEnrollmentSuccessAction = createAction(
  '[member] Participating enrollment success',
  props<IInstitutionMemberProgramEnrollmentParams>()
);
export const ParticipatingEnrollmentErrorAction = createAction(
  '[member] Participating enrollment error',
  props<{ error: HttpErrorResponse }>()
);

export const EnrollmentUnBlockAction = createAction(
  '[member] UnBlock',
  props<IInstitutionMemberProgramEnrollmentParams & { comment: IManagerCommentDto }>()
);
export const EnrollmentUnBlockSuccessAction = createAction(
  '[member] UnBlock success',
  props<IInstitutionMemberProgramEnrollmentParams & { comment: IManagerCommentDto }>()
);
export const EnrollmentUnBlockErrorAction = createAction(
  '[member] UnBlock error',
  props<{ error: HttpErrorResponse }>()
);

export const MemberSetProgramYearAction = createAction(
  '[member] set program year',
  props<IInstitutionMemberParams & { programYearId: string }>()
);


export const GetProfileNotesAction = createAction(
  '[member] Get profile notes',
  props<IInstitutionMemberProgramParams>()
);
export const GetProfileNotesSuccessAction = createAction(
  '[member] Get profile notes success',
  props<IInstitutionMemberProgramParams & { profileNotes: IManagerNoteDto[] }>()
);
export const GetProfileNotesErrorAction = createAction(
  '[member] Get profile notes error',
  props<{ error: HttpErrorResponse }>()
);

export const CreateProfileNotesAction = createAction(
  '[member] Create profile notes',
  props<IInstitutionMemberProgramParams & { noteCreateDto: IManagerNoteCreateDto }>()
);
export const CreateProfileNotesSuccessAction = createAction(
  '[member] Create profile notes success',
  props<IInstitutionMemberProgramParams & { noteCreateDto: IManagerNoteCreateDto }>()
);
export const CreateProfileNotesErrorAction = createAction(
  '[member] Create profile notes error',
  props<{ error: HttpErrorResponse }>()
);

export const UpdateProfileNotesAction = createAction(
  '[member] Update profile notes',
  props<IInstitutionMemberProgramParams & { profileNoteId: string, noteUpdateDto: IManagerNoteUpdateDto }>()
);
export const UpdateProfileNotesSuccessAction = createAction(
  '[member] Update profile notes success',
  props<IInstitutionMemberProgramParams & { profileNoteId: string, noteUpdateDto: IManagerNoteUpdateDto }>()
);
export const UpdateProfileNotesErrorAction = createAction(
  '[member] Update profile notes error',
  props<{ error: HttpErrorResponse }>()
);

export const DeleteProfileNotesAction = createAction(
  '[member] Delete profile notes',
  props<IInstitutionMemberProgramParams & { profileNoteId: string }>()
);
export const DeleteProfileNotesSuccessAction = createAction(
  '[member] Delete profile notes success',
  props<IInstitutionMemberProgramParams & { profileNoteId: string }>()
);
export const DeleteProfileNotesErrorAction = createAction(
  '[member] Delete profile notes error',
  props<{ error: HttpErrorResponse }>()
);

export const DownloadProfileNotesFileAction = createAction(
  '[member] Download profile notes file',
  props<IInstitutionMemberProgramParams & { profileNoteId: string }>()
);
export const DownloadProfileNotesFileSuccessAction = createAction(
  '[member] Download profile notes file success',
  props<IInstitutionMemberProgramParams & { profileNoteId: string, file: Blob }>()
);
export const DownloadProfileNotesFileErrorAction = createAction(
  '[member] Download profile notes file error',
  props<{ error: HttpErrorResponse }>()
);

export const DeleteProfileNotesFileAction = createAction(
  '[member] Delete profile notes file',
  props<IInstitutionMemberProgramParams & { profileNoteId: string }>()
);
export const DeleteProfileNotesFileSuccessAction = createAction(
  '[member] Delete profile notes file success',
  props<IInstitutionMemberProgramParams & { profileNoteId: string }>()
);
export const DeleteProfileNotesFileErrorAction = createAction(
  '[member] Delete profile notes file error',
  props<{ error: HttpErrorResponse }>()
);

export const AddProfileNotesFileAction = createAction(
  '[member] Add profile notes file',
  props<IInstitutionMemberProgramParams & { profileNoteId: string, formData: FormData }>()
);
export const AddProfileNotesFileSuccessAction = createAction(
  '[member] Add profile notes file success',
  props<IInstitutionMemberProgramParams & { profileNoteId: string, formData: FormData }>()
);
export const AddProfileNotesFileErrorAction = createAction(
  '[member] Add profile notes file error',
  props<{ error: HttpErrorResponse }>()
);

export const MemberLoadV1FilesAction = createAction(
  '[member] Load v1 files',
  props<IInstitutionMemberProgramParams>()
);
export const MemberLoadV1FilesSuccessAction = createAction(
  '[member] Load v1 files success',
  props<IInstitutionMemberProgramParams & { files: IFileControl[] }>()
);
export const MemberLoadV1FilesErrorAction = createAction(
  '[member] Load v1 files error',
  props<{ error: HttpErrorResponse }>()
);



export const UpdateMemberProgramVolunteerHoursAction = createAction(
  '[member] Update member program volunteer hours',
  props<IInstitutionMemberProgramParams & { memberProgramVolunteerHoursUpdate: IMemberProgramVolunteerHoursUpdateDto }>()
);
export const UpdateMemberProgramVolunteerHoursSuccessAction = createAction(
  '[member] Update member program volunteer hours success',
  props<IInstitutionMemberProgramParams & { memberProgramVolunteerHoursUpdate: IMemberProgramVolunteerHoursUpdateDto }>()
);
export const UpdateMemberProgramVolunteerHoursErrorAction = createAction(
  '[member] Update member program volunteer hours error',
  props<{ error: HttpErrorResponse }>()
);
