import { Component, OnInit } from '@angular/core';
import { AutoUnsubscribe, eHierarchyNodeType, eProfileType } from 'app/core/models';
import { MemberService, RouterService, SidebarService, UserService } from 'app/core/services';
import { combineLatest, Observable, take } from 'rxjs';

@Component({
  selector: 'ng4h-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent extends AutoUnsubscribe implements OnInit {

  public eProfileType = eProfileType;
  public eHierarchyNodeType = eHierarchyNodeType;

  public sidebarOpen: Observable<boolean>;
  public sidebarMode: Observable<string>;

  constructor(private userService: UserService, private sidebarService: SidebarService, private routerService: RouterService, private memberService: MemberService) {
    super();

    this.sidebarOpen = this.sidebarService.sidebarOpen$;
    this.sidebarMode = this.sidebarService.sidebarMode$;
  }

  ngOnInit() {
  }

  public swipeleft() {
    combineLatest([this.sidebarOpen, this.sidebarMode]).pipe(take(1)).subscribe(combined => {
      if (combined[0] && combined[1] === 'over') {
        this.sidebarService.close();
      }
    });
  }

  public signOut($event) {
    $event.stopPropagation();
    this.userService.signOut();
  }

  public sidebarClosing() {
    this.sidebarService.close();
  }
}
