import { Component, OnInit } from '@angular/core';
import { AutoUnsubscribe, IEnrollmentDto, IMemberDto } from 'app/core/models';
import { EnrollmentService, MemberService, RouterService } from 'app/core/services';
import { Observable } from 'rxjs';

@Component({
  selector: 'ng4h-add-confirm',
  templateUrl: './add-confirm.component.html',
  styleUrls: ['./add-confirm.component.scss']
})
export class AddConfirmComponent extends AutoUnsubscribe implements OnInit {

  public enrollment$: Observable<IEnrollmentDto>;
  public member$: Observable<IMemberDto>;
  constructor(private enrollmentService: EnrollmentService, private memberService: MemberService, private routerService: RouterService) {
    super();
  }

  ngOnInit() {
    this.enrollment$ = this.enrollmentService.getEnrollment({ institutionId: this.routerService.institutionId, memberId: this.routerService.memberId, programId: this.routerService.programId, enrollmentId: this.routerService.enrollmentId });
    this.member$ = this.memberService.getMember({ institutionId: this.routerService.institutionId, memberId: this.routerService.memberId });
  }

  public quickHelp() {

  }

}
