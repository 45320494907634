import { IDocumentationParameter, IDocumentationTypeDefinition } from '.';

export interface IDocumentationController {
    controllerName: string;
    actions: {
        verb: string;
        endpoint: string;
        parameters: IDocumentationParameter[];
        responseTypes: {
            httpStatusCode: number,
            description?: string,
            type: string;
            typeDefinition: IDocumentationTypeDefinition;
        }[];
        summary?: string;
        example?: string
    }[];
}
