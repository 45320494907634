import { Action, createReducer, on } from '@ngrx/store';
import { IInstitutionManagerProgramEventModel, IRegistrationTypeFileControlDto } from 'app/core/models';
import { mergeImmutable } from 'app/shared/utils';

import { EventCustomFilesActions } from '.';

export interface EventCustomFilesState {
  customFiles: IInstitutionManagerProgramEventModel<IRegistrationTypeFileControlDto[]>;
}

const initialState: EventCustomFilesState = {
  customFiles: undefined
};

const reducer = createReducer(
  initialState,
  on(EventCustomFilesActions.EventGetCustomFilesSuccessAction, (state, payload) => {
    return {
      ...state,
      customFiles: mergeImmutable(
        { [payload.institutionId]: { [payload.managerId]: { [payload.programId]: { [payload.eventId]: payload.customFiles } } } },
        state.customFiles
      )
    };
  }),
  on(EventCustomFilesActions.EventInvalidateEventCustomFileCache,
    EventCustomFilesActions.EventManagerUploadFileTemplateSuccessAction,
    EventCustomFilesActions.EventManagerDeleteFileTemplateSuccessAction, (state, payload) => {
      return {
        ...state,
        customFiles: undefined
      };
    })
);

export function eventCustomFileReducer(state: EventCustomFilesState | undefined, action: Action) {
  return reducer(state, action);
}

export const customFiles = (state: EventCustomFilesState) => state.customFiles;
