import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IDiscountFundCreateDto, IDiscountFundDescriptorDto, IDiscountFundUpdateDto } from 'app/core/models';
import { IInstitutionSystemManagerProgramParams } from 'app/core/models/function-parameters';

export const SystemManagerGetFundsAction = createAction(
  '[system manager finances] get system manager funds',
  props<IInstitutionSystemManagerProgramParams>()
);

export const SystemManagerGetFundsSuccessAction = createAction(
  '[system manager finances] get system manager funds success',
  props<IInstitutionSystemManagerProgramParams & { funds: IDiscountFundDescriptorDto }>()
);

export const SystemManagerGetFundsErrorAction = createAction(
  '[system manager finances] get system manager funds error',
  props<{ error: HttpErrorResponse }>()
);

export const SystemManagerAddFundAction = createAction(
  '[system manager finances] add system manager fund',
  props<IInstitutionSystemManagerProgramParams & { fund: IDiscountFundCreateDto }>()
);

export const SystemManagerAddFundSuccessAction = createAction(
  '[system manager finances] add system manager fund success',
  props<IInstitutionSystemManagerProgramParams & { fund: IDiscountFundCreateDto }>()
);

export const SystemManagerAddFundErrorAction = createAction(
  '[system manager finances] add system manager fund error',
  props<{ error: HttpErrorResponse }>()
);

export const SystemManagerUpdateFundAction = createAction(
  '[system manager finances] update system manager fund',
  props<IInstitutionSystemManagerProgramParams & { discountFundId: string, fund: IDiscountFundUpdateDto }>()
);

export const SystemManagerUpdateFundSuccessAction = createAction(
  '[system manager finances] update system manager fund success',
  props<IInstitutionSystemManagerProgramParams & { discountFundId: string, fund: IDiscountFundUpdateDto }>()
);

export const SystemManagerUpdateFundErrorAction = createAction(
  '[system manager finances] update system manager fund error',
  props<{ error: HttpErrorResponse }>()
);

export const SystemManagerDeleteFundAction = createAction(
  '[system manager finances] delete system manager fund',
  props<IInstitutionSystemManagerProgramParams & { discountFundId: string }>()
);

export const SystemManagerDeleteFundSuccessAction = createAction(
  '[system manager finances] delete system manager fund success',
  props<IInstitutionSystemManagerProgramParams & { discountFundId: string }>()
);

export const SystemManagerDeleteFundErrorAction = createAction(
  '[system manager finances] delete system manager fund error',
  props<{ error: HttpErrorResponse }>()
);

export const SystemManagerGetFundByIdAction = createAction(
  '[system manager finances] get system manager fund by id',
  props<IInstitutionSystemManagerProgramParams & { discountFundId: string }>()
);

export const SystemManagerGetFundByIdSuccessAction = createAction(
  '[system manager finances] get system manager fund by id success',
  props<IInstitutionSystemManagerProgramParams & { discountFundId: string, fund: IDiscountFundDescriptorDto }>()
);

export const SystemManagerGetFundByIdErrorAction = createAction(
  '[system manager finances] get system manager fund by id error',
  props<{ error: HttpErrorResponse }>()
);
