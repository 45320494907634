import {
  ChangeDetectorRef,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  EmbeddedViewRef,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { LoadingPlaceholderComponent } from 'app/core/components/loading-placeholder/loading-placeholder.component';

export class PlaceholderDirectiveContext {
  public $implicit: any = null;
  public ng4hPlaceholder: any = null;
}

// https://github.com/angular/angular/blob/master/packages/common/src/directives/ng_if.ts
@Directive({
  selector: '[ng4hPlaceholder]'
})
export class PlaceholderDirective {

  private context: PlaceholderDirectiveContext = new PlaceholderDirectiveContext();
  private elseTemplateRef: ComponentFactory<LoadingPlaceholderComponent>;
  private thenViewRef: EmbeddedViewRef<any> | null;
  private elseViewRef: ComponentRef<LoadingPlaceholderComponent>;

  private width: number;
  private height: number;

  constructor(
    private thenTemplateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private vcRef: ViewContainerRef,
    private cdr: ChangeDetectorRef
  ) {
    this.elseTemplateRef = this.componentFactoryResolver.resolveComponentFactory(LoadingPlaceholderComponent);
  }

  @Input('ng4hPlaceholder')
  set ng4hPlaceholder(condition: any) {
    this.context.$implicit = this.context.ng4hPlaceholder = condition;
    this.updateView();
  }

  @Input()
  set ng4hPlaceholderSize(placeholderSize: [number, number]) {
    this.width = placeholderSize[0];
    this.height = placeholderSize[1];
    this.updateView();
  }

  @Output()
  myCustomEvent = new EventEmitter();

  private updateView() {
    if (this.context.ng4hPlaceholder != null) {
      if (this.thenViewRef == null) {
        this.viewContainer.clear();
        this.elseViewRef = null;
        if (this.thenTemplateRef) {
          this.thenViewRef =
            this.viewContainer.createEmbeddedView(this.thenTemplateRef, this.context);
          this.myCustomEvent.emit();
        }
      }
    } else {
      if (this.elseViewRef == null && this.width && this.height) {
        this.viewContainer.clear();
        this.thenViewRef = null;
        if (this.elseTemplateRef) {
          this.elseViewRef = this.vcRef.createComponent(this.elseTemplateRef);
          this.elseViewRef.instance.width = this.width;
          this.elseViewRef.instance.height = this.height;
        }
      }
    }
    this.cdr.markForCheck();
  }
}
