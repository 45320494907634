import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AutoUnsubscribe, IHierarchyAny } from 'app/core/models';
import { HierarchyService, RouterService } from 'app/core/services';
import { Observable, take, takeUntil } from 'rxjs';

@Component({
    selector: 'ng4h-modal-change-hierarchy',
    templateUrl: './modal-change-hierarchy.component.html',
    styleUrls: ['./modal-change-hierarchy.component.scss']
})
export class ModalChangeHierarchyComponent extends AutoUnsubscribe implements OnInit {

    public selectedLeafNode$: Observable<IHierarchyAny>;

    constructor(private dialogRef: MatDialogRef<any>, private routerService: RouterService, private route: ActivatedRoute, private hierarchyService: HierarchyService) {
        super();

        this.selectedLeafNode$ = this.hierarchyService.selectedLeafNode$.pipe(takeUntil(this.autoUnsubscribe));
    }

    ngOnInit() {
    }

    public close(): void {
        this.dialogRef.close();
    }

    public save() {
        this.selectedLeafNode$.pipe(take(1)).subscribe(leafNode => {
            this.routerService.Go([this.routerService.userAccountId, this.routerService.institutionId, this.routerService.loggedInInstitutionProfileId, 'admin', this.routerService.programId, 'manager', this.routerService.managerId, leafNode.hierarchyNodeId, 'members']);
            this.close();
        });
    }
}
