import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { eEnrollmentRoleType, INationalProjectCategoryDto, IProgramYearDto } from 'app/core/models';
import { ManagerProjectsService, ProgramYearService, RouterService } from 'app/core/services';
import { filter, Observable, switchMap, take } from 'rxjs';

@Component({
  selector: 'ng4h-modal-edit-project-restriction',
  templateUrl: './modal-edit-project-restriction.component.html',
  styleUrls: ['./modal-edit-project-restriction.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalEditProjectRestrictionComponent implements OnInit {

  private selectedProgramYear$: Observable<IProgramYearDto>;
  public form: FormGroup;
  public eEnrollmentRoleType = eEnrollmentRoleType;

  public nationalProjectCategories$: Observable<INationalProjectCategoryDto[]>;
  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data,
    private fb: FormBuilder,
    private routerService: RouterService,
    private programYearService: ProgramYearService,
    private projectService: ManagerProjectsService,
  ) { }

  ngOnInit() {
    this.selectedProgramYear$ = this.programYearService.getUsersProgramYear({
      institutionId: this.routerService.institutionId,
      programId: this.routerService.programId,
      institutionProfileId: this.routerService.loggedInInstitutionProfileId
    });

    this.nationalProjectCategories$ = this.selectedProgramYear$.pipe(
      filter(programYear => programYear != null),
      switchMap(programYear => {
        return this.projectService.getNationalProjectCategories({
          institutionId: this.routerService.institutionId,
          programId: this.routerService.programId,
          programYearId: programYear.programYearId
        });
      }));

    this.selectedProgramYear$.pipe(
      switchMap(programYear => {
        return this.projectService.getProjectById({
          institutionId: this.routerService.institutionId,
          managerId: this.routerService.managerId,
          programId: this.routerService.programId,
          projectId: this.routerService.projectId,
          programYearId: programYear.programYearId
        });
      }),
      filter(project => project != null),
      take(1)
    ).subscribe(project => {
      this.form = this.fb.group({
        restrictedToRoleType: [project.restrictedToRoleType],
        allowMembersToRegister: [project.allowMembersToRegister === true],
        nationalProjectCategoryId: [project.nationalProjectCategoryId, Validators.required]
      });
    });

  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public update(): void {
    this.selectedProgramYear$.pipe(
      filter(programYear => programYear != null),
      switchMap(programYear => {
        return this.projectService.updateProjectEnrollmentRestriction({
          institutionId: this.routerService.institutionId,
          managerId: this.routerService.managerId,
          programId: this.routerService.programId,
          programYearId: programYear.programYearId,
          projectId: this.routerService.projectId,
          projectProgramYearUpdateDto:
          {
            restrictedToRoleType: this.form.value.restrictedToRoleType,
            allowMembersToRegister: this.form.value.allowMembersToRegister,
            nationalProjectCategoryId: this.form.value.nationalProjectCategoryId
          }
        });
      }),
      take(1)
    ).subscribe(() => {
      this.dialogRef.close(this.form.value);
    });


  }
}
