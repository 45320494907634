import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  eEnrollmentRoleType,
  IInstitutionProgramTrainingCreateDto,
  IInstitutionProgramTrainingDto,
  IInstitutionSystemManagerProgramParams,
  ITrainingCourseDto,
  ITrainingDto,
  ITrainingLessonDto,
  ITrainingLessonUpdateDto,
  ITrainingUpdateDto,
} from 'app/core/models';



export const SystemManagerCreateTrainingAction = createAction(
  '[system manager trainings] create training',
  props<{ institutionId: string, systemManagerId: string, programId: string, training: ITrainingUpdateDto }>()
);

export const SystemManagerCreateTrainingSuccessAction = createAction(
  '[system manager trainings] create training success',
  props<{ institutionId: string, systemManagerId: string, programId: string, training: ITrainingUpdateDto }>()
);

export const SystemManagerCreateTrainingErrorAction = createAction(
  '[system manager trainings] create training error',
  props<{ error: HttpErrorResponse }>()
);

export const SystemManagerLoadTrainingsAction = createAction(
  '[system manager trainings] Load',
  props<{ institutionId: string, systemManagerId: string, programId: string }>()
);

export const SystemManagerLoadTrainingsSuccessAction = createAction(
  '[system manager trainings] Load success',
  props<{ institutionId: string, systemManagerId: string, programId: string, trainings: ITrainingDto[] }>()
);

export const SystemManagerLoadTrainingsErrorAction = createAction(
  '[system manager trainings] Load error',
  props<{ error: HttpErrorResponse }>()
);

export const SystemManagerCreateTrainingLessonAction = createAction(
  '[system manager trainings] create training lesson',
  props<{ institutionId: string, systemManagerId: string, programId: string, trainingId: string, trainingCourseId: string, lesson: ITrainingLessonDto }>()
);

export const SystemManagerCreateTrainingLessonSuccessAction = createAction(
  '[system manager trainings] create training lesson success',
  props<{ institutionId: string, systemManagerId: string, programId: string, trainingId: string, trainingCourseId: string, lesson: ITrainingLessonDto }>()
);

export const SystemManagerCreateTrainingLessonErrorAction = createAction(
  '[system manager trainings] create training lesson error',
  props<{ error: HttpErrorResponse }>()
);

export const SystemManagerUpdateTrainingAction = createAction(
  '[system manager trainings]  update training',
  props<{ institutionId: string, systemManagerId: string, programId: string, trainingId: string, update: ITrainingUpdateDto }>()
);

export const SystemManagerUpdateTrainingSuccessAction = createAction(
  '[system manager trainings]  update training success',
  props<{ institutionId: string, systemManagerId: string, programId: string, trainingId: string, update: ITrainingUpdateDto }>()
);

export const SystemManagerUpdateTrainingErrorAction = createAction(
  '[system manager trainings]  update training error',
  props<{ error: HttpErrorResponse }>()
);


export const SystemManagerDeleteTrainingAction = createAction(
  '[system manager trainings]  delete training',
  props<{ institutionId: string, systemManagerId: string, programId: string, trainingId: string }>()
);

export const SystemManagerDeleteTrainingSuccessAction = createAction(
  '[system manager trainings]  delete training success',
  props<{ institutionId: string, systemManagerId: string, programId: string, trainingId: string }>()
);

export const SystemManagerDeleteTrainingErrorAction = createAction(
  '[system manager trainings]  delete training error',
  props<{ error: HttpErrorResponse }>()
);


export const SystemManagerUpdateTrainingLessonAction = createAction(
  '[system manager trainings]  update training lesson',
  props<{ institutionId: string, systemManagerId: string, programId: string, trainingId: string, trainingCourseId: string, trainingLessonId: string, trainingLesson: ITrainingLessonUpdateDto }>()
);

export const SystemManagerUpdateTrainingLessonSuccessAction = createAction(
  '[system manager trainings]  update training lesson success',
  props<{ institutionId: string, systemManagerId: string, programId: string, trainingId: string, trainingCourseId: string, trainingLessonId: string, trainingLesson: ITrainingLessonUpdateDto }>()
);

export const SystemManagerUpdateTrainingLessonErrorAction = createAction(
  '[system manager trainings]  update training lesson error',
  props<{ error: HttpErrorResponse }>()
);



export const SystemManagerUpdateTrainingLessonsOrderAction = createAction(
  '[system manager trainings] update training lessons order',
  props<{ institutionId: string, systemManagerId: string, programId: string, trainingId: string, trainingCourseId: string, lessonIds: string[] }>()
);

export const SystemManagerUpdateTrainingLessonsOrderSuccessAction = createAction(
  '[system manager trainings] update training lessons order success',
  props<{ institutionId: string, systemManagerId: string, programId: string, trainingId: string, trainingCourseId: string, lessonIds: string[] }>()
);

export const SystemManagerUpdateTrainingLessonsOrderErrorAction = createAction(
  '[system manager trainings] update training lessons order error',
  props<{ error: HttpErrorResponse }>()
);


export const SystemManagerInvalidateTrainingCacheAction = createAction(
  '[system manager trainings] invalidate trainings cache'
);

export const SystemManagerTrainingCourseCreateAction = createAction(
  '[system manager trainings] Create course',
  props<{ institutionId: string, systemManagerId: string, programId: string, trainingId: string, course: ITrainingCourseDto }>()
);
export const SystemManagerTrainingCourseCreateSuccessAction = createAction(
  '[system manager trainings] Create success course',
  props<{ institutionId: string, systemManagerId: string, programId: string, trainingId: string, course: ITrainingCourseDto }>()
);
export const SystemManagerTrainingCourseCreateErrorAction = createAction(
  '[system manager trainings] Create error course',
  props<{ error: HttpErrorResponse }>()
);


export const SystemManagerTrainingCourseLoadAction = createAction(
  '[system manager trainings] Load course',
  props<{ institutionId: string, systemManagerId: string, programId: string, trainingId: string, trainingCourseId: string }>()
);
export const SystemManagerTrainingCourseLoadSuccessAction = createAction(
  '[system manager trainings] Load success course',
  props<{ institutionId: string, systemManagerId: string, programId: string, trainingId: string, trainingCourseId: string, trainingCourse: ITrainingCourseDto, }>()
);
export const SystemManagerTrainingCourseLoadErrorAction = createAction(
  '[system manager trainings] Load error course',
  props<{ error: HttpErrorResponse }>()
);

export const SystemManagerTrainingCourseChangeLessonOrderAction = createAction(
  '[system manager trainings] Change course lesson order',
  props<{ institutionId: string, systemManagerId: string, programId: string, trainingId: string, trainingCourseId: string, lessonIds: string[] }>()
);
export const SystemManagerTrainingCourseChangeLessonOrderSuccessAction = createAction(
  '[system manager trainings] Change course lesson order success',
  props<{ institutionId: string, systemManagerId: string, programId: string, trainingId: string, trainingCourseId: string, lessonIds: string[] }>()
);
export const SystemManagerTrainingCourseChangeLessonOrderErrorAction = createAction(
  '[system manager trainings] Change course lesson order error',
  props<{ error: HttpErrorResponse }>()
);


export const SystemManagerTrainingLessonFileUploadAction = createAction(
  '[system manager trainings] File upload lesson',
  props<{ institutionId: string, systemManagerId: string, programId: string, trainingId: string, trainingCourseId: string, trainingLessonId: string, file: File }>()
);
export const SystemManagerTrainingLessonFileUploadSuccessAction = createAction(
  '[system manager trainings] File upload success lesson',
  props<{ institutionId: string, systemManagerId: string, programId: string, trainingId: string, trainingCourseId: string, trainingLessonId: string, file: File }>()
);
export const SystemManagerTrainingLessonFileUploadErrorAction = createAction(
  '[system manager trainings] File upload error lesson',
  props<{ error: HttpErrorResponse }>()
);

export const SystemManagerTrainingLessonLoadAction = createAction(
  '[training] Load lesson',
  props<{ institutionId: string, systemManagerId: string, programId: string, trainingId: string, trainingCourseId: string, trainingLessonId: string }>()
);
export const SystemManagerTrainingLessonLoadSuccessAction = createAction(
  '[training] Load success lesson',
  props<{ institutionId: string, systemManagerId: string, programId: string, trainingId: string, trainingCourseId: string, trainingLessonId: string, trainingLesson: ITrainingLessonDto, }>()
);
export const SystemManagerTrainingLessonLoadErrorAction = createAction(
  '[training] Load error lesson',
  props<{ error: HttpErrorResponse }>()
);

export const SystemManagerTrainingProgramLoadAction = createAction(
  '[system manager trainings] Load training program association',
  props<{ institutionId: string, systemManagerId: string, programId: string }>()
);
export const SystemManagerTrainingProgramLoadSuccessAction = createAction(
  '[system manager trainings] Load training program association success',
  props<{ institutionId: string, systemManagerId: string, programId: string, programAssociations: IInstitutionProgramTrainingDto[] }>()
);
export const SystemManagerTrainingProgramLoadErrorAction = createAction(
  '[system manager trainings] Load training program association error',
  props<{ error: HttpErrorResponse }>()
);

export const SystemManagerTrainingProgramAddAction = createAction(
  '[system manager trainings] Add training program association',
  props<{ institutionId: string, systemManagerId: string, programId: string, enrollmentRole: eEnrollmentRoleType, trainingId: string, programTraining: IInstitutionProgramTrainingCreateDto }>()
);
export const SystemManagerTrainingProgramAddSuccessAction = createAction(
  '[system manager trainings] Add training program association success',
  props<{ institutionId: string, systemManagerId: string, programId: string, enrollmentRole: eEnrollmentRoleType, trainingId: string, programTraining: IInstitutionProgramTrainingCreateDto }>()
);
export const SystemManagerTrainingProgramAddErrorAction = createAction(
  '[system manager trainings] Add training program association error',
  props<{ error: HttpErrorResponse }>()
);

export const SystemManagerTrainingProgramDeleteAction = createAction(
  '[system manager trainings] Delete training program association',
  props<{ institutionId: string, systemManagerId: string, programId: string, enrollmentRole: eEnrollmentRoleType, trainingId: string }>()
);
export const SystemManagerTrainingProgramDeleteSuccessAction = createAction(
  '[system manager trainings] Delete training program association success',
  props<{ institutionId: string, systemManagerId: string, programId: string, enrollmentRole: eEnrollmentRoleType, trainingId: string }>()
);
export const SystemManagerTrainingProgramDeleteErrorAction = createAction(
  '[system manager trainings] Delete training program association error',
  props<{ error: HttpErrorResponse }>()
);



export const SystemManagerTrainingProjectAddAction = createAction(
  '[system manager trainings] Add training project association',
  props<{ institutionId: string, systemManagerId: string, programId: string, projectId: string, enrollmentRole: eEnrollmentRoleType, trainingId: string, programTraining: IInstitutionProgramTrainingCreateDto }>()
);
export const SystemManagerTrainingProjectAddSuccessAction = createAction(
  '[system manager trainings] Add training project association success',
  props<{ institutionId: string, systemManagerId: string, programId: string, projectId: string, enrollmentRole: eEnrollmentRoleType, trainingId: string, programTraining: IInstitutionProgramTrainingCreateDto }>()
);
export const SystemManagerTrainingProjectAddErrorAction = createAction(
  '[system manager trainings] Add training project association error',
  props<{ error: HttpErrorResponse }>()
);

export const SystemManagerTrainingProjectDeleteAction = createAction(
  '[system manager trainings] Delete training project association',
  props<{ institutionId: string, systemManagerId: string, programId: string, projectId: string, enrollmentRole: eEnrollmentRoleType, trainingId: string }>()
);
export const SystemManagerTrainingProjectDeleteSuccessAction = createAction(
  '[system manager trainings] Delete training project association success',
  props<{ institutionId: string, systemManagerId: string, programId: string, projectId: string, enrollmentRole: eEnrollmentRoleType, trainingId: string }>()
);
export const SystemManagerTrainingProjectDeleteErrorAction = createAction(
  '[system manager trainings] Delete training project association error',
  props<{ error: HttpErrorResponse }>()
);

export const SystemManagerLinkTrainingForVolunteerRoleAction = createAction(
  '[system manager trainings] Link training for volunteer role',
  props<IInstitutionSystemManagerProgramParams & { institutionVolunteerId: string, trainingId: string, createDto: IInstitutionProgramTrainingCreateDto }>()
);
export const SystemManagerLinkTrainingForVolunteerRoleSuccessAction = createAction(
  '[system manager trainings] Link training for volunteer role success',
  props<IInstitutionSystemManagerProgramParams & { institutionVolunteerId: string, trainingId: string, createDto: IInstitutionProgramTrainingCreateDto }>()
);
export const SystemManagerLinkTrainingForVolunteerRoleErrorAction = createAction(
  '[system manager trainings] Link training for volunteer role error',
  props<{ error: HttpErrorResponse }>()
);


export const SystemManagerDeleteTrainingForVolunteerRoleAction = createAction(
  '[system manager trainings] Delete training for volunteer role',
  props<IInstitutionSystemManagerProgramParams & { institutionVolunteerId: string, trainingId: string }>()
);
export const SystemManagerDeleteTrainingForVolunteerRoleSuccessAction = createAction(
  '[system manager trainings] Delete training for volunteer role success',
  props<IInstitutionSystemManagerProgramParams & { institutionVolunteerId: string, trainingId: string }>()
);
export const SystemManagerDeleteTrainingForVolunteerRoleErrorAction = createAction(
  '[system manager trainings] Delete training for volunteer role error',
  props<{ error: HttpErrorResponse }>()
);

