import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, Store } from '@ngrx/store';
import { AppState } from 'app/app.reducers';
import { map, Observable, take } from 'rxjs';

import { UserAccountFeatureStoreActions } from '../containers/shared/user-account-feature-store';
import { IUserAccountDto } from '../models';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserAccountService {

  constructor(private store: Store<AppState>, private httpClient: HttpClient, private dispatcher: ActionsSubject) {
  }

  public emailLookup(email: string): Observable<IUserAccountDto> {
    this.store.dispatch(UserAccountFeatureStoreActions.EmailLookup({ email }));

    return this.dispatcher.pipe(
      ofType(UserAccountFeatureStoreActions.EmailLookupSuccess),
      take(1),
      map(action => {
        return action.user;
      })
    );
  }

  public emailLookupEffect(email: string): Observable<IUserAccountDto> {
    return this.httpClient.get(`${environment.apiUri}/api/user-accounts/lookup?email=${email}`) as Observable<IUserAccountDto>;
  }
}
