import { eManagerNoteDirection, eManagerNoteType } from '..';

export interface IManagerNoteCreateDto {
    sendEmail: boolean;
    noteType: eManagerNoteType;
    direction: eManagerNoteDirection;
    noteDate?: string;
    subject?: string;
    publicNote?: string;
    privateNote?: string;
}