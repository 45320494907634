import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'maybeYesNo',
    pure: true
})
export class MaybeYesNoPipe implements PipeTransform {
    public transform(value: string): string {
        if (value == null || value === '') {
            return '';
        }
        if (value === 'true') {
            return 'Yes';
        }
        if (value === 'false') {
            return 'No';
        }
        return value;
    }
}
