import { Action, createReducer, on } from '@ngrx/store';

import { ReportConsumerActions } from '.';
import { mergeImmutable } from '../../../shared/utils';
import { IReportConsumerInstitutionProfile } from '../../models';

export interface ReportConsumerState {
    reportConsumer: { [institutionId: string]: { [reportConsumerId: string]: IReportConsumerInstitutionProfile } };
    reportConsumers: { [superUserId: string]: { [institutionId: string]: IReportConsumerInstitutionProfile[] } };
}

const initialState: ReportConsumerState = {
    reportConsumer: undefined,
    reportConsumers: undefined
}

const reducer = createReducer(
    initialState,
    on(ReportConsumerActions.GetReportConsumerSuccessAction, (state, { institutionId, reportConsumerId, reportConsumer }) => {
        const newReportConsumer = mergeImmutable(
            { [institutionId]: { [reportConsumerId]: reportConsumer } },
            state.reportConsumer
        );

        return {
            ...state,
            reportConsumer: newReportConsumer
        }
    }),
    on(ReportConsumerActions.GetReportConsumersSuccessAction, (state, { superuserId, institutionId, reportConsumers }) => {
        const newReportConsumers = mergeImmutable(
            { [superuserId]: { [institutionId]: reportConsumers } },
            state.reportConsumers
        );

        return {
            ...state,
            reportConsumers: newReportConsumers
        }
    }),
    on(ReportConsumerActions.UpdateReportConsumerSuccessAction,
        ReportConsumerActions.CreateReportConsumerSuccessAction,
        ReportConsumerActions.DeleteReportConsumerSuccessAction,
        ReportConsumerActions.ReinstateReportConsumerSuccessAction,
        (state, { }) => {
            return {
                ...state,
                reportConsumer: undefined,
                reportConsumers: undefined
            }
        }),
);

export function reportConsumerReducers(state: ReportConsumerState | undefined, action: Action) {
    return reducer(state, action);
};

export const reportConsumer = (state: ReportConsumerState) => state?.reportConsumer;
export const reportConsumers = (state: ReportConsumerState) => state?.reportConsumers;