<div class="ng4h-panel">
  <div class="ng4h-panel-body ng4h-table">
    <div class="col-12">
      <div class="row">
        <div class="col-4 ng4h-bg-secondary"><b>Address</b></div>
        <div class="col-8">{{ address.addressLine1 }}</div>
      </div>
      <div class="row">
        <div class="col-4 ng4h-bg-secondary"><b>Address 2</b></div>
        <div class="col-8">{{ address.addressLine2 }}</div>
      </div>
      <div class="row">
        <div class="col-4 ng4h-bg-secondary"><b>City</b></div>
        <div class="col-8">{{ address.city }}</div>
      </div>
      <div class="row">
        <div class="col-4 ng4h-bg-secondary"><b>State</b></div>
        <div class="col-8">{{ address.state }}</div>
      </div>
      <div class="row">
        <div class="col-4 ng4h-bg-secondary"><b>Postal Code</b></div>
        <div class="col-8">{{ address.postalCode}}</div>
      </div>
      <div class="row">
        <div class="col-4 ng4h-bg-secondary"><b>Country</b></div>
        <div class="col-8">{{ address.country }}</div>
      </div>
    </div>
  </div>