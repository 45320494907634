import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { AppState, getEventCustomFiles } from 'app/app.reducers';
import { EventCustomFilesActions } from 'app/shared/event-custom-files';
import { filter, map, Observable, of, tap } from 'rxjs';

import {
  IInstitutionManagerProgramEventParams,
  IRegistrationTypeFileControlCreateDto,
  IRegistrationTypeFileControlDto,
  IRegistrationTypeFileControlUpdateDto
} from '../models';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EventCustomFilesService {

  constructor(
    private httpClient: HttpClient,
    private store: Store<AppState>,
    private dispatcher: ActionsSubject
  ) {
  }

  public getManagerCustomFiles(params: IInstitutionManagerProgramEventParams): Observable<IRegistrationTypeFileControlDto[]> {
    if (Object.keys(params).find(key => params[key] == null) != null) {
      return of(null);
    }
    return this.store.pipe(
      select(getEventCustomFiles(params)),
      tap(files => {
        if (files === undefined) {
          this.store.dispatch(EventCustomFilesActions.EventGetCustomFilesAction(params));
        }
      }),
      filter(e => e != null)
    );
  }

  public loadCustomFilesEffect({
    institutionId,
    managerId,
    programId,
    eventId
  }: IInstitutionManagerProgramEventParams): Observable<IRegistrationTypeFileControlDto[]> {
    return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/file-controls`) as Observable<IRegistrationTypeFileControlDto[]>;
  }

  public addCustomFile(params: IInstitutionManagerProgramEventParams & { customFile: IRegistrationTypeFileControlCreateDto }) {
    this.store.dispatch(EventCustomFilesActions.EventAddEventCustomFileAction(params));

    return this.dispatcher.pipe(
      ofType(EventCustomFilesActions.EventAddEventCustomFileSuccessAction, EventCustomFilesActions.EventAddEventCustomFileErrorAction),
      map(action => {
        if (action.type === EventCustomFilesActions.EventAddEventCustomFileSuccessAction.type) {
          return action.customFile;
        } else {
          throw action.error;
        }
      })
    );
  }

  public addCustomFileEffect({
    institutionId,
    managerId,
    programId,
    eventId,
    customFile
  }: IInstitutionManagerProgramEventParams & { customFile: IRegistrationTypeFileControlCreateDto }) {
    return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/file-controls`, customFile);
  }

  public updateEventFileControl(params: IInstitutionManagerProgramEventParams & { fileControlId: string, fileControlUpdate: IRegistrationTypeFileControlUpdateDto }) {
    this.store.dispatch(EventCustomFilesActions.EventUpdateCustomFileAction(params));

    return this.dispatcher.pipe(
      ofType(EventCustomFilesActions.EventUpdateCustomFileSuccessAction, EventCustomFilesActions.EventUpdateCustomFileErrorAction),
      map(action => {
        if (action.type === EventCustomFilesActions.EventUpdateCustomFileSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public updateEventFileControlEffect({
    institutionId,
    managerId,
    programId,
    eventId,
    fileControlId,
    fileControlUpdate
  }: IInstitutionManagerProgramEventParams & { fileControlId: string, fileControlUpdate: IRegistrationTypeFileControlUpdateDto }) {
    return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/file-controls/${fileControlId}`, fileControlUpdate);
  }

  public deleteEventFileControl(params: IInstitutionManagerProgramEventParams & { fileControlId: string }) {
    this.store.dispatch(EventCustomFilesActions.EventDeleteCustomFileAction(params));

    return this.dispatcher.pipe(
      ofType(EventCustomFilesActions.EventDeleteCustomFileSuccessAction, EventCustomFilesActions.EventDeleteCustomFileErrorAction),
      map(action => {
        if (action.type === EventCustomFilesActions.EventDeleteCustomFileSuccessAction.type) {
          return action;
        } else {
          throw action.error;
        }
      })
    );
  }

  public deleteEventFileControlEffect({
    institutionId,
    managerId,
    programId,
    eventId,
    fileControlId
  }: IInstitutionManagerProgramEventParams & { fileControlId: string }): Observable<any> {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/file-controls/${fileControlId}`) as Observable<any>;
  }

  public managerUploadFileTemplate(params: IInstitutionManagerProgramEventParams & { fileControlId: string, formData: FormData }) {
    this.store.dispatch(EventCustomFilesActions.EventManagerUploadFileTemplateAction(params));
  }

  public managerUploadFileTemplateEffect({
    institutionId,
    managerId,
    programId,
    eventId,
    fileControlId,
    formData
  }: IInstitutionManagerProgramEventParams & { fileControlId: string, formData: FormData }) {
    return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/file-controls/${fileControlId}/template-file`, formData);
  }

  public managerDeleteFileTemplate(params: IInstitutionManagerProgramEventParams & { fileControlId: string }) {
    this.store.dispatch(EventCustomFilesActions.EventManagerDeleteFileTemplateAction(params));
  }

  public managerDeleteFileTemplateEffect({
    institutionId,
    managerId,
    programId,
    eventId,
    fileControlId
  }: IInstitutionManagerProgramEventParams & { fileControlId: string }) {
    return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/file-controls/${fileControlId}/template-file`, {});
  }
}
