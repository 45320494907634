export enum eAnimalSpecies {
    Cattle = 'Cattle',
    Dogs = 'Dogs',
    Goats = 'Goats',
    Horses = 'Horses',
    Llamas = 'Llamas',
    Poultry = 'Poultry',
    Rabbits = 'Rabbits',
    Sheep = 'Sheep',
    Swine = 'Swine',
    Other = 'Other',
}
