import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IEnrollmentFileControlDto, ITrainingLessonDto } from 'app/core/models';

@Component({
  selector: 'ng4h-modal-reorder-custom-files',
  templateUrl: './modal-reorder-custom-files.component.html',
  styleUrls: ['./modal-reorder-custom-files.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalReorderCustomFilesComponent implements OnInit {

  public customFiles: IEnrollmentFileControlDto[];

  constructor(private dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: { customFiles: IEnrollmentFileControlDto[] }) { }

  public ngOnInit(): void {
    this.customFiles = this.data.customFiles.slice();
  }

  public drop(event: CdkDragDrop<ITrainingLessonDto[]>) {
    moveItemInArray(this.customFiles, event.previousIndex, event.currentIndex);
  }

  public save(): void {
    this.dialogRef.close(this.customFiles);
  }
  public close(): void {
    this.dialogRef.close();
  }

}
