import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { AutoUnsubscribe, eFormControlType, eLanguage, eStandardFieldStatus } from 'app/core/models';
import { IStandardFormColumnDto } from 'app/core/models/serverDTOs/IStandardFormColumnDto';
import { Observable } from 'rxjs';


@Component({
  selector: 'ng4h-standard-form-input',
  templateUrl: './standard-form-input.component.html',
  styleUrls: ['./standard-form-input.component.scss']
})
export class StandardFormInputComponent extends AutoUnsubscribe implements OnInit {
  public showFakeCheckbox: boolean;
  public shouldShow$: Observable<boolean>;
  @Input() input: IStandardFormColumnDto;
  @Input() language: eLanguage = eLanguage.enUs;
  @Input() form: FormGroup;
  @Input() readOnly: boolean;
  @Input() sectionName: string;

  public eStandardFieldStatus = eStandardFieldStatus;
  public eFormControlType = eFormControlType;

  public showAllergies: boolean;
  public showAuthorizedMedications: boolean;
  public showCare: boolean;
  public showConditions: boolean;
  public showDevices: boolean;
  public showExposure: boolean;
  public showFemale: boolean;
  public showHealthInsurance: boolean;
  public showHistory: boolean;
  public showRemarks: boolean;
  public showVaccinations: boolean;

  constructor() {
    super();
  }

  ngOnInit() {
    if (this.readOnly) {
      this.form.disable();
    }

    if (this.input.inputType === eFormControlType.DependentMultipleLineText) {
      this.showFakeCheckbox = this.asFormGroup(this.form.controls[this.sectionName]).controls[this.input.parentInputName] == null;
    }
  }

  public checkboxChanged(event: MatRadioChange) {
    if (this.input.childInputName == null) {
      return;
    }

    if (event.value === true) {
      this.asFormGroup(this.form.controls[this.sectionName]).controls[this.input.childInputName].setValidators(Validators.required);
      this.asFormGroup(this.form.controls[this.sectionName]).controls[this.input.childInputName].updateValueAndValidity();
    } else if (event.value === false) {
      this.asFormGroup(this.form.controls[this.sectionName]).controls[this.input.childInputName].setValue(null);
      this.asFormGroup(this.form.controls[this.sectionName]).controls[this.input.childInputName].clearValidators();
      this.asFormGroup(this.form.controls[this.sectionName]).controls[this.input.childInputName].updateValueAndValidity();
    }

  }


  public asFormGroup(any: any): FormGroup {
    return any as FormGroup;
  }

}
