<div class="modal-header">
  <h5 class="modal-title">Event Details</h5>
  <button (click)="close()" type="button" class="close" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form *ng4hPlaceholder="event$ | async; size: [100,512]" [formGroup]="eventForm">

    <div class="form-group row">
      <label class="form-control-label col-md-4">Event Name</label>
      <div class="col-md-8">
        <input formControlName="eventName" type="text" class="form-control">
      </div>
    </div>
    <div class="form-group row">
      <label class="form-control-label col-md-4">Event Description</label>
      <div class="col-md-8">
        <textarea formControlName="eventDescription" class="form-control"></textarea>
      </div>
    </div>
    <!-- <div class="form-group row">
      <label class="form-control-label col-md-4">Status</label>
      <div class="col-md-8">
        <select class="form-control" formControlName="status">
          <option [value]="status.value" *ngFor="let status of eEventStatus | keyvalue">{{status.value}}</option>
        </select>
      </div>
    </div> -->
    <!-- <div class="form-group row">
      <label class="form-control-label col-md-4">Bank Account Id</label>
      <div class="col-md-8">
      <input formControlName="bankAccountId" type="text" class="form-control">
      </div>
    </div> -->

    <!-- <div class="form-group row">
      <mat-checkbox class="offset-4 col-8" formControlName="enableTicketing">Enable Ticketing
      </mat-checkbox>
    </div> -->

    <div class="form-group row">
      <mat-checkbox class="offset-4 col-8" formControlName="creditCardFeeRecovery">Enable Credit Card Fee Recovery
      </mat-checkbox>
    </div>

    <div class="form-group row">
      <label class="form-control-label col-md-4">Ticketing</label>
      <div class="col-md-8">
        <div *ngIf="eventForm.controls['enableTicketing'].value === true; else optionEnable">
          <b>Enabled</b>
          <div class="float-right">
            <button class="btn btn-danger" (click)="disableTicketing()">Disable Ticketing</button>

          </div>
        </div>
        <ng-template #optionEnable>
          <b>Disabled</b>
          <div class="float-right">
            <button class="btn btn-primary" (click)="openTicketingModal()">Enable Ticketing</button>
            <span class="help ng4h-margin-left-small" (click)="openTicketingModal()">What's This?</span>
          </div>
        </ng-template>
      </div>
    </div>

    <div class="form-group row">
      <mat-checkbox class="offset-4 col-8" formControlName="enableSessions">Enable Sessions
      </mat-checkbox>
    </div>
    <div class="form-group row">
      <mat-checkbox class="offset-4 col-8" formControlName="allowMultipleRegistrations">Allow Multiple Registrations
      </mat-checkbox>
    </div>

    <div class="form-group row">
      <mat-checkbox class="offset-4 col-8" formControlName="allowRegistrationByManagerOnly">Allow Registration By
        Manager Only
      </mat-checkbox>
    </div>
    <div class="form-group row">
      <mat-checkbox class="offset-4 col-8" formControlName="paymentMethod_Credits_On">Payment Credits On
      </mat-checkbox>
    </div>
    <div class="form-group row">
      <mat-checkbox class="offset-4 col-8" formControlName="paymentMethod_Electronic_On">Payment Electronic On
      </mat-checkbox>
    </div>
    <div class="form-group row">
      <label class="form-control-label col-md-4">Session Type</label>
      <div class="col-md-8">
        <select class="form-control" formControlName="sessionType">
          <option [value]="eEventSessionType.Session">{{ eEventSessionType.Session }}</option>
          <option [value]="eEventSessionType.Contest">{{ eEventSessionType.Contest }}</option>
        </select>
      </div>
    </div>
    <!-- <div class="form-group row">
      <label class="form-control-label col-md-4">Bank Account</label>
      <div class="col-md-8">
        <select class="form-control" formControlName="bankAccountId">
          <option *ngFor="let bankAccount of bankAccounts$ | async" [value]="bankAccount.bankAccountId">
            {{bankAccount.bankAccountName}} - {{bankAccount.description}}</option>
        </select>
      </div>
    </div> -->
    <div class="form-group row">
      <mat-checkbox class="offset-4 col-8" formControlName="requireCountyAreaApproval">Require County Area Approval
      </mat-checkbox>
    </div>
    <div class="form-group row">
      <mat-checkbox class="offset-4 col-8" formControlName="requireDistrictAreaApproval">Require District Area
        Approval
      </mat-checkbox>
    </div>
    <div class="form-group row">
      <mat-checkbox class="offset-4 col-8" formControlName="requireRegionAreaApproval">Require Region Area Approval
      </mat-checkbox>
    </div>
    <div class="form-group row">
      <mat-checkbox class="offset-4 col-8" formControlName="requireProgramLevelApproval">Require Program Level
        Approval
      </mat-checkbox>
    </div>
    <div class="form-group row">
      <label class="form-control-label col-md-4">Program Year</label>
      <div class="col-md-8">
        <ng4h-program-year-dropdown class="form-group" formControlName="programYear">
        </ng4h-program-year-dropdown>
      </div>
    </div>
    <div class="form-group row">
      <label class="form-control-label col-md-4">Start Date</label>
      <div class="input-group col-md-8">
        <input class="form-control" formControlName="startDate" [matDatepicker]="startDate"
               placeholder="Event start date">
        <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
      </div>
      <mat-datepicker #startDate></mat-datepicker>
    </div>
    <div class="form-group row">
      <label class="form-control-label col-md-4">End Date</label>
      <div class="input-group col-md-8">
        <input class="form-control" formControlName="endDate" [matDatepicker]="endDate" placeholder="Event end date">
        <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
      </div>
      <mat-datepicker #endDate></mat-datepicker>
    </div>

    <div class="form-group row">
      <mat-checkbox class="offset-4 col-8" formControlName="disableAutomatedEmails">Disable Automated Emails
      </mat-checkbox>
    </div>


  </form>
</div>
<div class="modal-footer">
  <button class="btn btn-secondary" (click)="close()">Cancel</button>
  <button class="btn btn-primary" [disabled]="eventForm.invalid" (click)="save()">Save</button>
</div>