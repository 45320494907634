import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
    eLocalizationType,
    IAnimalBreedDto,
    IAnimalBreedUpdateDto,
    IAnimalFileControlCreateDto,
    IAnimalFileControlUpdateDto,
    IAnimalFormDto,
    IAnimalFormFieldStatusUpdateDto,
    IAnimalTypeCreateDto,
    IAnimalTypeDto,
    IAnimalTypeFeeUpdateDto,
    IAnimalTypeUpdateDto,
    IInstitutionProgramParams,
    IInstitutionSystemManagerProgramParams,
} from 'app/core/models';

export const SystemManagerAnimalsGetTypesAction = createAction(
    '[system-manager-animals] Get types',
    props<IInstitutionSystemManagerProgramParams>()
);

export const SystemManagerAnimalsGetTypesSuccessAction = createAction(
    '[system-manager-animals] Get types success',
    props<IInstitutionSystemManagerProgramParams & { animalTypes: IAnimalTypeDto[] }>()
);

export const SystemManagerAnimalsGetTypesErrorAction = createAction(
    '[system-manager-animals] Get types error',
    props<{ error: HttpErrorResponse }>()
);

export const SystemManagerAnimalsGetTypeAction = createAction(
    '[system-manager-animals] Get type',
    props<IInstitutionSystemManagerProgramParams & { animalTypeId: string }>()
);

export const SystemManagerAnimalsGetTypeSuccessAction = createAction(
    '[system-manager-animals] Get type success',
    props<IInstitutionSystemManagerProgramParams & { animalTypeId: string, animalType: IAnimalTypeDto }>()
);

export const SystemManagerAnimalsGetTypeErrorAction = createAction(
    '[system-manager-animals] Get type error',
    props<{ error: HttpErrorResponse }>()
);

export const SystemManagerAnimalsCreateTypeAction = createAction(
    '[system-manager-animals] Create type',
    props<IInstitutionSystemManagerProgramParams & { animalTypeCreate: IAnimalTypeCreateDto }>()
);

export const SystemManagerAnimalsCreateTypeSuccessAction = createAction(
    '[system-manager-animals] Create type success',
    props<IInstitutionSystemManagerProgramParams & { animalTypeCreate: IAnimalTypeCreateDto }>()
);

export const SystemManagerAnimalsCreateTypeErrorAction = createAction(
    '[system-manager-animals] Create type error',
    props<{ error: HttpErrorResponse }>()
);

export const SystemManagerAnimalsUpdateTypeAction = createAction(
    '[system-manager-animals] Update type',
    props<IInstitutionSystemManagerProgramParams & { animalTypeId: string, animalUpdate: IAnimalTypeUpdateDto }>()
);

export const SystemManagerAnimalsUpdateTypeSuccessAction = createAction(
    '[system-manager-animals] Update type success',
    props<IInstitutionSystemManagerProgramParams & { animalTypeId: string, animalUpdate: IAnimalTypeUpdateDto }>()
);

export const SystemManagerAnimalsUpdateTypeErrorAction = createAction(
    '[system-manager-animals] Update type error',
    props<{ error: HttpErrorResponse }>()
);


export const SystemManagerAnimalsDeleteTypeAction = createAction(
    '[system-manager-animals] Delete type',
    props<IInstitutionSystemManagerProgramParams & { animalTypeId: string }>()
);

export const SystemManagerAnimalsDeleteTypeSuccessAction = createAction(
    '[system-manager-animals] Delete type success',
    props<IInstitutionSystemManagerProgramParams & { animalTypeId: string }>()
);

export const SystemManagerAnimalsDeleteTypeErrorAction = createAction(
    '[system-manager-animals] Delete type error',
    props<{ error: HttpErrorResponse }>()
);



export const SystemManagerAnimalsGetFormAction = createAction(
    '[system-manager-animals] Get form',
    props<IInstitutionSystemManagerProgramParams & { animalTypeId: string }>()
);

export const SystemManagerAnimalsGetFormSuccessAction = createAction(
    '[system-manager-animals] Get form success',
    props<IInstitutionSystemManagerProgramParams & { animalTypeId: string, animalForm: IAnimalFormDto }>()
);

export const SystemManagerAnimalsGetFormErrorAction = createAction(
    '[system-manager-animals] Get form error',
    props<{ error: HttpErrorResponse }>()
);

export const SystemManagerAnimalsSaveFormAction = createAction(
    '[system-manager-animals] Save form',
    props<IInstitutionSystemManagerProgramParams & { animalTypeId: string, animalFormUpdate: IAnimalFormFieldStatusUpdateDto }>()
);

export const SystemManagerAnimalsSaveFormSuccessAction = createAction(
    '[system-manager-animals] Save form success',
    props<IInstitutionSystemManagerProgramParams & { animalTypeId: string, animalFormUpdate: IAnimalFormFieldStatusUpdateDto }>()
);

export const SystemManagerAnimalsSaveFormErrorAction = createAction(
    '[system-manager-animals] Save form error',
    props<{ error: HttpErrorResponse }>()
);

export const SystemManagerAnimalsSaveFormLocalizationsAction = createAction(
    '[system-manager-animals] Save form localizations',
    props<IInstitutionSystemManagerProgramParams & { animalTypeId: string, localization: eLocalizationType, update: any }>()
);

export const SystemManagerAnimalsSaveFormLocalizationsSuccessAction = createAction(
    '[system-manager-animals] Save form localizations success',
    props<IInstitutionSystemManagerProgramParams & { animalTypeId: string, localization: eLocalizationType, update: any }>()
);

export const SystemManagerAnimalsSaveFormLocalizationsErrorAction = createAction(
    '[system-manager-animals] Save form localizations error',
    props<{ error: HttpErrorResponse }>()
);


export const SystemManagerAnimalsGetFileControlsAction = createAction(
    '[system-manager-animals] Get file control types',
    props<IInstitutionSystemManagerProgramParams>()
);

export const SystemManagerAnimalsGetFileControlsSuccessAction = createAction(
    '[system-manager-animals] Get file control types success',
    props<IInstitutionSystemManagerProgramParams & { fileControls: any }>()
);

export const SystemManagerAnimalsGetFileControlsErrorAction = createAction(
    '[system-manager-animals] Get file control types error',
    props<{ error: HttpErrorResponse }>()
);


export const SystemManagerAnimalsAddFileControlAction = createAction(
    '[system-manager-animals] Add file control',
    props<IInstitutionSystemManagerProgramParams & { animalTypeId: string, fileControlCreate: IAnimalFileControlCreateDto }>()
);

export const SystemManagerAnimalsAddFileControlSuccessAction = createAction(
    '[system-manager-animals] Add file control success',
    props<IInstitutionSystemManagerProgramParams & { animalTypeId: string, fileControlCreate: IAnimalFileControlCreateDto }>()
);

export const SystemManagerAnimalsAddFileControlErrorAction = createAction(
    '[system-manager-animals] Add file control error',
    props<{ error: HttpErrorResponse }>()
);


export const SystemManagerAnimalsDeleteFileControlAction = createAction(
    '[system-manager-animals] Delete file control',
    props<IInstitutionSystemManagerProgramParams & { animalTypeId: string, fileControlId: string }>()
);

export const SystemManagerAnimalsDeleteFileControlSuccessAction = createAction(
    '[system-manager-animals] Delete file control success',
    props<IInstitutionSystemManagerProgramParams & { animalTypeId: string, fileControlId: string }>()
);

export const SystemManagerAnimalsDeleteFileControlErrorAction = createAction(
    '[system-manager-animals] Delete file control error',
    props<{ error: HttpErrorResponse }>()
);


export const SystemManagerAnimalsUpdateFileControlAction = createAction(
    '[system-manager-animals] Update file control',
    props<IInstitutionSystemManagerProgramParams & { animalTypeId: string, fileControlId: string, fileControlUpdate: IAnimalFileControlUpdateDto }>()
);

export const SystemManagerAnimalsUpdateFileControlSuccessAction = createAction(
    '[system-manager-animals] Update file control success',
    props<IInstitutionSystemManagerProgramParams & { animalTypeId: string, fileControlId: string, fileControlUpdate: IAnimalFileControlUpdateDto }>()
);

export const SystemManagerAnimalsUpdateFileControlErrorAction = createAction(
    '[system-manager-animals] Update file control error',
    props<{ error: HttpErrorResponse }>()
);

export const SystemManagerAnimalsSetFileControlOrderAction = createAction(
    '[system-manager-animals] Set file control order',
    props<IInstitutionSystemManagerProgramParams & { animalTypeId: string, fileControlIds: string[] }>()
);

export const SystemManagerAnimalsSetFileControlOrderSuccessAction = createAction(
    '[system-manager-animals] Set file control order success',
    props<IInstitutionSystemManagerProgramParams & { animalTypeId: string, fileControlIds: string[] }>()
);

export const SystemManagerAnimalsSetFileControlOrderErrorAction = createAction(
    '[system-manager-animals] Set file control order error',
    props<{ error: HttpErrorResponse }>()
);

export const SystemManagerAnimalsGetBreedsAction = createAction(
    '[system-manager-animals] Get breeds',
    props<IInstitutionProgramParams & { animalTypeId: string }>()
);

export const SystemManagerAnimalsGetBreedsSuccessAction = createAction(
    '[system-manager-animals] Get breeds success',
    props<IInstitutionProgramParams & { animalTypeId: string, breeds: IAnimalBreedDto[] }>()
);

export const SystemManagerAnimalsGetBreedsErrorAction = createAction(
    '[system-manager-animals] Get breeds error',
    props<{ error: HttpErrorResponse }>()
);

export const SystemManagerAnimalsUpdateBreedsAction = createAction(
    '[system-manager-animals] Update breeds',
    props<IInstitutionSystemManagerProgramParams & { animalTypeId: string, animalBreedUpdate: IAnimalBreedUpdateDto }>()
);

export const SystemManagerAnimalsUpdateBreedsSuccessAction = createAction(
    '[system-manager-animals] Update breeds success',
    props<IInstitutionSystemManagerProgramParams & { animalTypeId: string, animalBreedUpdate: IAnimalBreedUpdateDto }>()
);

export const SystemManagerAnimalsUpdateBreedsErrorAction = createAction(
    '[system-manager-animals] Update breeds error',
    props<{ error: HttpErrorResponse }>()
);


export const SystemManagerAnimalsDeleteBreedsAction = createAction(
    '[system-manager-animals] Delete breeds',
    props<IInstitutionSystemManagerProgramParams & { animalTypeId: string, animalBreedId: string }>()
);

export const SystemManagerAnimalsDeleteBreedsSuccessAction = createAction(
    '[system-manager-animals] Delete breeds success',
    props<IInstitutionSystemManagerProgramParams & { animalTypeId: string, animalBreedId: string }>()
);

export const SystemManagerAnimalsDeleteBreedsErrorAction = createAction(
    '[system-manager-animals] Delete breeds error',
    props<{ error: HttpErrorResponse }>()
);

export const SystemManagerAnimalsSaveTemplateAction = createAction(
    '[system-manager-animals] Save animal type template file',
    props<IInstitutionSystemManagerProgramParams & { animalTypeId: string, fileControlId: string, formData: FormData }>()
);

export const SystemManagerAnimalsSaveTemplateSuccessAction = createAction(
    '[system-manager-animals] Save animal type template file success',
    props<IInstitutionSystemManagerProgramParams & { animalTypeId: string, fileControlId: string, formData: FormData }>()
);

export const SystemManagerAnimalsSaveTemplateErrorAction = createAction(
    '[system-manager-animals] Save animal type template file error',
    props<{ error: HttpErrorResponse }>()
);
export const SystemManagerAnimalsDeleteTemplateAction = createAction(
    '[system-manager-animals] Delete animal type template file',
    props<IInstitutionSystemManagerProgramParams & { animalTypeId: string, fileControlId: string }>()
);

export const SystemManagerAnimalsDeleteTemplateSuccessAction = createAction(
    '[system-manager-animals] Delete animal type template file success',
    props<IInstitutionSystemManagerProgramParams & { animalTypeId: string, fileControlId: string }>()
);

export const SystemManagerAnimalsDeleteTemplateErrorAction = createAction(
    '[system-manager-animals] Delete animal type template file error',
    props<{ error: HttpErrorResponse }>()
);


export const SystemManagerAnimalsUpdateFeesAction = createAction(
    '[system-manager-animals] Update fees',
    props<IInstitutionSystemManagerProgramParams & { animalTypeId: string, programYearId: string, animalTypeFee: IAnimalTypeFeeUpdateDto }>()
);

export const SystemManagerAnimalsUpdateFeesSuccessAction = createAction(
    '[system-manager-animals] Update fees success',
    props<IInstitutionSystemManagerProgramParams & { animalTypeId: string, programYearId: string, animalTypeFee: IAnimalTypeFeeUpdateDto }>()
);

export const SystemManagerAnimalsUpdateFeesErrorAction = createAction(
    '[system-manager-animals] Update fees error',
    props<{ error: HttpErrorResponse }>()
);


export const SystemManagerAnimalsDeleteFeesAction = createAction(
    '[system-manager-animals] Delete fees',
    props<IInstitutionSystemManagerProgramParams & { animalTypeId: string, programYearId: string }>()
);

export const SystemManagerAnimalsDeleteFeesSuccessAction = createAction(
    '[system-manager-animals] Delete fees success',
    props<IInstitutionSystemManagerProgramParams & { animalTypeId: string, programYearId: string }>()
);

export const SystemManagerAnimalsDeleteFeesErrorAction = createAction(
    '[system-manager-animals] Delete fees error',
    props<{ error: HttpErrorResponse }>()
);
