import { Pipe, PipeTransform } from '@angular/core';
import { IHierarchyNodeDto } from 'app/core/models';
import { HierarchyService, RouterService } from 'app/core/services';
import { Observable } from 'rxjs';

@Pipe({
    name: 'hierarchyNode$',
    pure: true
})
export class HierarchyNodePipe implements PipeTransform {
    constructor(private hierarchyService: HierarchyService, private routerService: RouterService) {
    }

    public transform(hierarchyNodeId: string, institutionId: string = this.routerService.institutionId): Observable<IHierarchyNodeDto> {
        return this.hierarchyService.getHierarchyNode({ institutionId, hierarchyNodeId });
    }
}
