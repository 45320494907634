import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ProgramService } from 'app/core/services/program.service';
import { catchError, exhaustMap, groupBy, map, mergeMap, Observable, of } from 'rxjs';

import { ProgramActions } from '.';
import { stringKeys } from '../rxjs.utils';


@Injectable()
export class ProgramEffects {

    public loadPrograms$: Observable<Action> = createEffect(() => this.actions.pipe(
        ofType(ProgramActions.ProgramsLoadAction),
        groupBy(stringKeys),
        mergeMap(groupedBy => {
            return groupedBy.pipe(
                exhaustMap(payload => {
                    return this.programService.loadProgramsEffect(payload).pipe(
                        map(programs => ProgramActions.ProgramsLoadSuccessAction({ ...payload, programs })),
                        catchError(error => of(ProgramActions.ProgramsLoadErrorAction({ error })))
                    );
                })
            );
        })
    ));

    public loadCountyAreaPrograms$: Observable<Action> = createEffect(() => this.actions.pipe(
        ofType(ProgramActions.ProgramsLoadCountyAreaAction),
        groupBy(stringKeys),
        mergeMap(groupedBy => {
            return groupedBy.pipe(
                exhaustMap(payload => {
                    return this.programService.getProgramsByCountyAreaEffect(payload).pipe(
                        map(programs => ProgramActions.ProgramsLoadCountyAreaSuccessAction({ ...payload, programs })),
                        catchError(error => of(ProgramActions.ProgramsLoadCountyAreaErrorAction({ error })))
                    );
                })
            );
        })
    ));


    public loadProgram$: Observable<Action> = createEffect(() => this.actions.pipe(
        ofType(ProgramActions.ProgramLoadAction),
        groupBy(stringKeys),
        mergeMap(groupedBy => {
            return groupedBy.pipe(
                exhaustMap(payload => {
                    return this.programService.loadProgramEffect(payload).pipe(
                        map(institutionProgram => ProgramActions.ProgramLoadSuccessAction({ ...payload, institutionProgram })),
                        catchError(error => of(ProgramActions.ProgramLoadErrorAction({ error })))
                    );
                })
            );
        })
    ));

    constructor(private actions: Actions, private programService: ProgramService) { }
}
