import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
    IActivityDto,
    IActivityUpdateDto,
    IInstitutionManagerProgramHierarchyParams,
    IInstitutionManagerProgramParams,
} from 'app/core/models';
import { IActivityCreateDto } from 'app/core/models/serverDTOs/IActivityCreateDto';

export const LoadActivitiesAction = createAction(
    '[manager activities] Load activities',
    props<IInstitutionManagerProgramHierarchyParams>()
);
export const LoadActivitiesSuccessAction = createAction(
    '[manager activities] Load activities success',
    props<IInstitutionManagerProgramHierarchyParams & { activities: IActivityDto[] }>()
);
export const LoadActivitiesErrorAction = createAction(
    '[manager activities] Load activities error',
    props<{ error: HttpErrorResponse }>()
);


export const LoadActivityAction = createAction(
    '[manager activities] Load activity',
    props<IInstitutionManagerProgramParams & { activityId: string }>()
);
export const LoadActivitySuccessAction = createAction(
    '[manager activities] Load activity success',
    props<IInstitutionManagerProgramParams & { activityId: string, activity: IActivityDto }>()
);
export const LoadActivityErrorAction = createAction(
    '[manager activities] Load activity error',
    props<{ error: HttpErrorResponse }>()
);


export const CreateActivityAction = createAction(
    '[manager activities] Create activity',
    props<IInstitutionManagerProgramParams & { activityCreate: IActivityCreateDto }>()
);
export const CreateActivitySuccessAction = createAction(
    '[manager activities] Create activity success',
    props<IInstitutionManagerProgramParams & { activityCreate: IActivityCreateDto }>()
);
export const CreateActivityErrorAction = createAction(
    '[manager activities] Create activity error',
    props<{ error: HttpErrorResponse }>()
);

export const UpdateActivityAction = createAction(
    '[manager activities] Update activity',
    props<IInstitutionManagerProgramParams & { activityId: string, activityUpdate: IActivityUpdateDto }>()
);
export const UpdateActivitySuccessAction = createAction(
    '[manager activities] Update activity success',
    props<IInstitutionManagerProgramParams & { activityId: string, activityUpdate: IActivityUpdateDto }>()
);
export const UpdateActivityErrorAction = createAction(
    '[manager activities] Update activity error',
    props<{ error: HttpErrorResponse }>()
);

export const DeleteActivityAction = createAction(
    '[manager activities] Delete activity',
    props<IInstitutionManagerProgramParams & { activityId: string }>()
);
export const DeleteActivitySuccessAction = createAction(
    '[manager activities] Delete activity success',
    props<IInstitutionManagerProgramParams & { activityId: string }>()
);
export const DeleteActivityErrorAction = createAction(
    '[manager activities] Delete activity error',
    props<{ error: HttpErrorResponse }>()
);
