import { eHierarchyNodeType } from '..';
import { IHierarchyACLDto } from './IHierarchyACLDto';

export interface IManagerProgramHierarchyForDisplayDto {
    hierarchyNodeId: string;
    hierarchy?: string;
    hierarchyNodeType: eHierarchyNodeType;
    hierarchyNodeName?: string;
    primary: boolean;
    acl: IHierarchyACLDto;
}
