import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IEnrollmentGroupSubmissionDto, IGroupDto, IInstitutionMemberProgramEnrollmentParams } from 'app/core/models';

export const LoadGroupsForEnrollmentAction = createAction(
  '[member-groups] Load groups for enrollment',
  props<IInstitutionMemberProgramEnrollmentParams>()
);
export const LoadGroupsForEnrollmentSuccessAction = createAction(
  '[member-groups] Load groups for enrollment success',
  props<IInstitutionMemberProgramEnrollmentParams & { groups: IGroupDto[] }>()
);
export const LoadGroupsForEnrollmentErrorAction = createAction(
  '[member-groups] Load groups for enrollment error',
  props<{ error: HttpErrorResponse }>()
);

export const SaveGroupsForEnrollmentAction = createAction(
  '[member-groups] Save groups for enrollment',
  props<IInstitutionMemberProgramEnrollmentParams & { enrollmentGroupsSubmission: IEnrollmentGroupSubmissionDto }>()
);
export const SaveGroupsForEnrollmentSuccessAction = createAction(
  '[member-groups] Save groups for enrollment success',
  props<IInstitutionMemberProgramEnrollmentParams & { enrollmentGroupsSubmission: IEnrollmentGroupSubmissionDto }>()
);
export const SaveGroupsForEnrollmentErrorAction = createAction(
  '[member-groups] Save groups for enrollment error',
  props<{ error: HttpErrorResponse }>()
);


export const DeleteGroupsForEnrollmentAction = createAction(
  '[member-groups] Delete groups for enrollment',
  props<IInstitutionMemberProgramEnrollmentParams & { groupId: string }>()
);
export const DeleteGroupsForEnrollmentSuccessAction = createAction(
  '[member-groups] Delete groups for enrollment success',
  props<IInstitutionMemberProgramEnrollmentParams & { groupId: string }>()
);
export const DeleteGroupsForEnrollmentErrorAction = createAction(
  '[member-groups] Delete groups for enrollment error',
  props<{ error: HttpErrorResponse }>()
);