<div class="modal-section" *ngIf="form">
    <div class="modal-header">
        <h5 class="modal-title">Edit Volunteer Type</h5>
        <button (click)="close()" type="button" class="close" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" *ngIf="volunteerType$ | async as volunteerType">
        <form [formGroup]="form">
            <div class="form-group col-sm-12 row">
                <label class="form-control-label col-4">Member Role</label>
                <label class="form-control-label col-8">{{volunteerType.volunteerTypeDescriptor.memberRole}}</label>
            </div>
            <div class="form-group col-sm-12 row">
                <label class="form-control-label col-4">Volunteer Type</label>
                <label class="form-control-label col-8">{{volunteerType.volunteerTypeDescriptor.volunteerType |
                    camelToTitleCase}}</label>
            </div>

            <div class="form-group col-sm-12 row">
                <label class="form-control-label col-4">Volunteer Role</label>
                <input formControlName="volunteerTypeLabel" type="text" class="form-control col-8">
            </div>

            <div class="form-group col-sm-12 row">
                <label class="form-control-label col-4">Description</label>
                <input formControlName="volunteerTypeDescription" type="text" class="form-control col-8">
            </div>

            <div class="form-group col-sm-12 row">
                <label class="form-control-label col-4">Screening Level</label>
                <select formControlName="screeningId" type="text" class="form-control col-8">
                    <option [value]="screening.screeningId" *ngFor="let screening of screenings$ | async">
                        {{screening.screeningName}} ({{screening.screeningType}})
                    </option>
                </select>
            </div>
        </form>
    </div>

    <div class="modal-footer">
        <button id (click)="close()" class="btn btn-secondary my-2 my-sm-0" role="button"
            aria-pressed="true">Cancel</button>
        <button [disabled]="form.invalid" (click)="save()" class="btn btn-primary my-2 my-sm-0" role="button"
            aria-pressed="true">Save</button>
    </div>
</div>