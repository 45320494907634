import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import {
  ModalManagerEditEnrollmentProjectComponent,
} from 'app/core/containers/admin/manager/enrollments/modal-manager-edit-enrollment-project/modal-manager-edit-enrollment-project.component';
import { project } from 'app/core/containers/admin/manager/store/projects/manager-projects.reducer';
import {
  AutoUnsubscribe,
  eEnrollmentRoleType,
  ePageMessage,
  eUnitType,
  IEnrollmentDto,
  IEnrollmentProjectDto,
  IProgramPageMessageDto,
  IProgramSettingsDto,
} from 'app/core/models';
import { CommonToastrService, EnrollmentService, ModalService, ProfileService, RouterService } from 'app/core/services';
import { ProgramSettingsService } from 'app/core/services/program-settings.service';
import { combineLatest, filter, map, Observable, startWith, switchMap, take, takeUntil } from 'rxjs';

import {
  ModalAddProjectsVolunteerComponent,
} from '../../modal-add-projects-volunteer/modal-add-projects-volunteer.component';
import { ModalAddProjectsComponent } from '../../modal-add-projects/modal-add-projects.component';



// import { EnrollmentRemoveProjectAction } from '../../../enrollment/enrollment.actions';
@Component({
  selector: 'ng4h-add-projects',
  templateUrl: './add-projects.component.html',
  styleUrls: ['./add-projects.component.scss']
})
export class AddProjectsComponent extends AutoUnsubscribe implements OnInit {

  public eEnrollmentRoleType = eEnrollmentRoleType;
  public enrollmentProjectsColumns = ['projectName', 'yearsInProject', 'parentProjectName', 'unitName', 'volunteerType'];
  public eUnitType = eUnitType;
  @Input() allowEdit = true;


  public selectedProjects$: Observable<IEnrollmentProjectDto[]>;


  public closeProjectsPageMessage: EventEmitter<boolean> = new EventEmitter<boolean>();
  public projectsPageMessage$: Observable<IProgramPageMessageDto>;

  public isManager$: Observable<boolean>;

  public programSettings$: Observable<IProgramSettingsDto>;

  public enrollment$: Observable<IEnrollmentDto>;

  constructor(
    private enrollmentService: EnrollmentService,
    private modalService: ModalService,
    private routerService: RouterService,
    private profileService: ProfileService,
    private programSettingsService: ProgramSettingsService,
    private toastrService: CommonToastrService) {
    super();





    this.enrollment$ = this.routerService.enrollmentId$.pipe(
      switchMap(enrollmentId => {
        return this.enrollmentService.getEnrollment({
          institutionId: this.routerService.institutionId,
          memberId: this.routerService.memberId ? this.routerService.memberId : this.routerService.selectedMemberId,
          programId: this.routerService.programId,
          enrollmentId
        });
      }),
      filter(enrollment => enrollment != null)
    );

    this.programSettings$ = this.programSettingsService.getProgramSettings({
      institutionId: this.routerService.institutionId,
      programId: this.routerService.programId
    });
    this.selectedProjects$ = this.enrollment$.pipe(
      map(enrollment => enrollment.projects),
      takeUntil(this.autoUnsubscribe));

    this.isManager$ = this.profileService.activeUserInstitutionProfileIsManager$;

    this.projectsPageMessage$ = combineLatest([
      this.isManager$,
      this.programSettingsService.getPageMessageByType({
        institutionId: this.routerService.institutionId,
        programId: this.routerService.programId,
        typeId: ePageMessage.EnrollmentProjects
      }),
      this.closeProjectsPageMessage.pipe(startWith(false)),
      this.selectedProjects$
    ]).pipe(
      map(([isManager, projectsPageMessage, closeProjectsPageMessage, selectedProjects]) => {
        if (isManager || projectsPageMessage == null || closeProjectsPageMessage || (Array.isArray(selectedProjects) && selectedProjects.length > 0)) {
          return null;
        }
        return projectsPageMessage;
      })
    );

  }

  ngOnInit() {
    if (this.allowEdit === true) {
      this.enrollmentProjectsColumns.push('allowEdit');
    }
  }


  addProject() {
    this.enrollment$.pipe(take(1)).subscribe(enrollment => {
      if (enrollment.enrollmentRole === eEnrollmentRoleType.Volunteer) {
        this.modalService.openModal(ModalAddProjectsVolunteerComponent);
      } else {
        this.modalService.openModal(ModalAddProjectsComponent);
      }
    });
  }

  removeProject($event, project: IEnrollmentProjectDto) {
    $event.stopPropagation();
    this.enrollmentService.deleteProject({
      institutionId: this.routerService.institutionId,
      memberId: this.routerService.memberId ? this.routerService.memberId : this.routerService.selectedMemberId,
      programId: this.routerService.programId,
      enrollmentId: this.routerService.enrollmentId,
      projectId: project.projectId
    }).pipe(
      take(1),
      this.modalService.showProgressSpinnerModalUntilComplete()
    ).subscribe({
      next: () => {
        this.toastrService.success('Project Deleted');
      }, error: (error) => {
        this.toastrService.error('Could not delete project', error);
      }
    });
  }

  public closeHelp() {
    this.closeProjectsPageMessage.emit(true);

    this.addProject();
  }

  public projectClicked(project: IEnrollmentProjectDto) {
    this.modalService.openModal(ModalManagerEditEnrollmentProjectComponent, { data: { project } });
  }


}
