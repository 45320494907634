import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camelToTitleCase',
  pure: true
})
export class CamelToTitleCasePipe implements PipeTransform {
  public transform(input: string): string {
    if (input != null) {
      return input.replace(/([A-Z])/g, ' $1').replace('4 H', ' 4-H').replace(/_/g, '').replace(/^./, (str) => str.toUpperCase());
    }
    return null;
  }
}
