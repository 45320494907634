import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ITrainingDto, ITrainingLessonDto } from 'app/core/models';

@Component({
  selector: 'ng4h-training',
  templateUrl: 'training.component.html',
  styleUrls: ['./training.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class TrainingComponent implements OnInit {
  @Input() public readonly: boolean;
  @Input() public training: ITrainingDto;

  @Output() public lessonClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() public cancelClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() public saveClicked: EventEmitter<any> = new EventEmitter<any>();

  public form: FormGroup;

  constructor(private fb: FormBuilder) { }

  public ngOnInit(): void {

    this.form = this.fb.group({
      trainingId: [this.training.trainingId],
      trainingName: [this.training.trainingName],
      trainingMode: [this.training.trainingMode]
    });

    if (this.readonly) {
      this.form.disable();
    }
  }

  public cancel(): void {
    this.cancelClick.emit();
  }

  public lessonRowClick(lesson: ITrainingLessonDto): void {

    const value = {
      lesson: lesson,
      course: this.training.courses[0],
      training: this.training
    }
    this.lessonClick.emit(value);
  }

  public save(): void {
    this.saveClicked.emit(this.form.value);
  }
}
