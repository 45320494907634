<div class="modal-section">
    <div class="modal-header">
        <h5 class="modal-title">Add Delivery Mode</h5>
        <button (click)="close()" type="button" class="close" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="form">
            <div class="form-group col-sm-12 row">
                <label class="form-control-label col-4 ng4h-required">Type</label>
                <select class="form-control col-md-8" formControlName="type">
                    <option *ngFor="let keyValue of eDeliveryModeType | keyvalue" [value]="keyValue.key">
                        {{ keyValue.value | camelToTitleCase }}
                    </option>
                </select>
            </div>

            <div class="form-group col-sm-12 row">
                <label class="form-control-label col-4">Description</label>
                <input formControlName="description" type="text" class="form-control col-8">
            </div>
            <div class="form-group col-sm-12 row">
                <label class="form-control-label col-4">Type Description</label>
                <input formControlName="typeDescription" type="text" class="form-control col-8">
            </div>
            <div class="form-group col-sm-12 row">
                <label class="form-control-label col-4">Available For Group Enrollment</label>
                <mat-checkbox formControlName="availableForGroupEnrollment"></mat-checkbox>
            </div>
        </form>
    </div>

    <div class="modal-footer">
        <button id (click)="close()" class="btn btn-secondary my-2 my-sm-0" role="button"
            aria-pressed="true">Cancel</button>
        <button [disabled]="form.invalid" (click)="save()" class="btn btn-primary my-2 my-sm-0" role="button"
            aria-pressed="true">Save</button>
    </div>
</div>