import { Pipe, PipeTransform } from '@angular/core';

import { eConsentStatus, IMemberProgramConsentDto } from '../models';

@Pipe({
    name: 'consentsSigned',
    pure: false
})

export class ConsentsSignedPipe implements PipeTransform {
    public transform(consents: IMemberProgramConsentDto[]): boolean {
        return consents.every(consent => consent.consentStatus === eConsentStatus.Accepted || consent.consentVersion.acceptanceRequired === false);
    }
}
