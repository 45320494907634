import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
    AppState,
    getActiveProfileHistory,
    getInstitutionProfileAuthTokenCache,
    getProfileCredentials,
    getUnitLeaderPIN
} from 'app/app.reducers';
import { mergeImmutable, rankHierarchyAssociation } from 'app/shared/utils';
import {
    combineLatest,
    distinctUntilChanged,
    filter,
    fromEvent,
    map,
    Observable,
    of,
    shareReplay,
    switchMap,
    take, takeUntil,
    tap
} from 'rxjs';

import { ModalService, RouterService, SystemManagerService } from '.';
import { ModalSelectProgramComponent } from '../containers/admin/modal-select-program/modal-select-program.component';
import { ProfileActions } from '../containers/user';
import {
    AutoUnsubscribe,
    eProfileType,
    IAnyInstitutionProfile,
    IFamilyDto,
    IFamilyInstitutionProfile,
    IInstitutionProfileAuthDto,
    IManagerInstitutionProfile, IManagerProgramHierarchyForDisplayDto,
    ISuperUserInstitutionProfile,
    ISystemManagerInstitutionProfile
} from '../models';
import { IReportConsumerInstitutionProfile } from '../models';
import { FamilyService } from './family.service';
import { LocalStorageService } from './local-storage.service';
import { ManagerService } from './manager.service';
import { MemberService } from './member.service';
import { ReportConsumerService } from './report-consumer/report-consumer.service';
import { UserService } from './user.service';
import { WindowService } from './window.service';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ProfileService extends AutoUnsubscribe {

    public profileCredentials$: Observable<IInstitutionProfileAuthDto>;

    public usersInstitutionProfiles$: Observable<IAnyInstitutionProfile[]>;
    public loggedInAsInstitutionProfile: Observable<IAnyInstitutionProfile>;
    public activeUserInstitutionProfileIsManager$: Observable<boolean>;
    public activeUserInstitutionProfileIsFamily$: Observable<boolean>;
    public activeUserInstitutionProfileIsSuperuser$: Observable<boolean>;

    public actingAsInstitutionProfile: Observable<IAnyInstitutionProfile>;
    public activeProfileHistory$: Observable<{ actingOnBehalfOfProfile: IAnyInstitutionProfile, route: string[] }[]>;
    private authTokenStream: Observable<{ [institutionProfileId: string]: any }>;

    constructor(
        private store: Store<AppState>,
        private httpClient: HttpClient,
        private userService: UserService,
        private routerService: RouterService,
        private systemManagerService: SystemManagerService,
        private managerService: ManagerService,
        private familyService: FamilyService,
        private memberService: MemberService,
        private windowService: WindowService,
        private modalService: ModalService,
        private localStorageService: LocalStorageService,
        private reportConsumerService: ReportConsumerService
    ) {
        super();

        this.activeProfileHistory$ = this.store.pipe(select(getActiveProfileHistory));

        this.profileCredentials$ = this.store.pipe(select(getProfileCredentials));

        this.loggedInAsInstitutionProfile = combineLatest([this.userService.userAccount$, this.routerService.loggedInInstitutionProfileId$]).pipe(
            map(([userAccount, loggedInInstitutionProfileId]) => {
                if (userAccount == null || loggedInInstitutionProfileId == null) {
                    return null;
                }
                return userAccount.institutionProfiles
                    // .filter(profile => {
                    //   // Family profiles that haven't had address verification are not really valid
                    //   return !(profile.profileType === eProfileType.Family && (profile.addressVerified == null || profile.addressVerified === false));
                    // })
                    .find(profile => {
                        return profile.institutionProfileId === loggedInInstitutionProfileId;
                    });

                // if (userProfile != null) {
                //   return userProfile;
                // } else {
                //   return userAccount.institutionProfiles.find(profile => profile.profileType === eProfileType.Superuser && profile.institutionId === this.routerService.institutionId);
                // }
            })
        );

        this.activeUserInstitutionProfileIsManager$ = this.loggedInAsInstitutionProfile.pipe(
            map(activeUserInstitutionProfile => [eProfileType.Superuser, eProfileType.SystemManager, eProfileType.Manager].includes(activeUserInstitutionProfile.profileType)),
            distinctUntilChanged(),
            shareReplay({ refCount: true, bufferSize: 1 })
        );

        this.activeUserInstitutionProfileIsFamily$ = this.loggedInAsInstitutionProfile.pipe(
            map(activeUserInstitutionProfile => activeUserInstitutionProfile.profileType === eProfileType.Family),
            distinctUntilChanged(),
            shareReplay({ refCount: true, bufferSize: 1 })
        );

        this.activeUserInstitutionProfileIsSuperuser$ = this.loggedInAsInstitutionProfile.pipe(
            map(activeUserInstitutionProfile => activeUserInstitutionProfile.profileType === eProfileType.Superuser),
            distinctUntilChanged(),
            shareReplay({ refCount: true, bufferSize: 1 })
        );

        this.actingAsInstitutionProfile = combineLatest(
            [
                this.routerService.superuserId$,
                this.routerService.systemManagerId$,
                this.routerService.managerId$,
                this.routerService.reportConsumerId$,
                this.routerService.familyId$,
                this.routerService.memberId$,
                this.loggedInAsInstitutionProfile,
                this.routerService.loggedInInstitutionProfileId$
            ]
        ).pipe(
            switchMap((
                [
                    superuserId,
                    rootSystemManagerId,
                    rootManagerId,
                    rootReportConsumerId,
                    rootFamilyId,
                    rootMemberId,
                    activeUserInstitutionProfile,
                    loggedInInstitutionProfileId
                ]:
                    [string, string, string, string, string, string, IAnyInstitutionProfile, string]) => {
                if (loggedInInstitutionProfileId == null) {
                    return of(null);
                }
                if (superuserId == null && rootSystemManagerId == null && rootManagerId == null && rootReportConsumerId == null && rootFamilyId == null && rootMemberId == null) {
                    return of(null);
                } else if (superuserId != null) {
                    return of(activeUserInstitutionProfile);
                } else if (rootSystemManagerId != null) {
                    if (activeUserInstitutionProfile != null && activeUserInstitutionProfile.profileType === eProfileType.SystemManager && activeUserInstitutionProfile.systemManagerId === rootSystemManagerId) {
                        return of(activeUserInstitutionProfile);
                    }
                    // Only superuser can "act as" system managers so we can assume the user has a superuserId
                    return this.userService.userAccount$.pipe(
                        filter(ua => ua != null),
                        switchMap(userAccount => {
                            return this.systemManagerService.getSystemManager({
                                superuserId: userAccount.superuserId,
                                institutionId: this.routerService.institutionId,
                                systemManagerId: rootSystemManagerId
                            });
                        }));
                } else if (rootManagerId != null) {
                    if (activeUserInstitutionProfile != null && activeUserInstitutionProfile.profileType === eProfileType.Manager && activeUserInstitutionProfile.managerId === rootManagerId) {
                        return of(activeUserInstitutionProfile);
                    }
                    return this.managerService.getManager({
                        institutionId: this.routerService.institutionId,
                        managerId: rootManagerId
                    });
                } else if (rootReportConsumerId != null) {
                    if (activeUserInstitutionProfile != null && activeUserInstitutionProfile.profileType === eProfileType.ReportConsumer && activeUserInstitutionProfile.reportConsumerId === rootReportConsumerId) {
                        return of(activeUserInstitutionProfile);
                    }
                    return this.reportConsumerService.getReportConsumer({
                        institutionId: this.routerService.institutionId,
                        reportConsumerId: rootReportConsumerId
                    });
                } else if (rootFamilyId != null) {
                    if (activeUserInstitutionProfile != null && activeUserInstitutionProfile.profileType === eProfileType.Family && activeUserInstitutionProfile.familyId === rootFamilyId) {
                        return of(activeUserInstitutionProfile);
                    }
                    return this.familyService.getFamily({
                        institutionId: this.routerService.institutionId,
                        familyId: rootFamilyId
                    });
                } else if (rootMemberId != null) {
                    if (activeUserInstitutionProfile != null && activeUserInstitutionProfile.profileType === eProfileType.Member && activeUserInstitutionProfile.memberId === rootMemberId) {
                        return of(activeUserInstitutionProfile);
                    }
                    return this.memberService.getMember({
                        institutionId: this.routerService.institutionId,
                        memberId: rootMemberId
                    });
                }
            })
        );

        this.usersInstitutionProfiles$ = this.userService.userAccount$.pipe(
            map(user => {
                if (user == null) {
                    return null;
                }
                return user.institutionProfiles;
            }));

        this.actingAsInstitutionProfile.pipe(
            filter(actingOnBehalfOf => actingOnBehalfOf != null)
        ).subscribe(actingOnBehalfOf => {

            if (!!!actingOnBehalfOf) {
                return;
            }
            if (window?.UserWay == null) {
                fromEvent(document, 'userway:init_completed').pipe(take(1)).subscribe(() => {
                    if ([eProfileType.Family, eProfileType.Member].includes(actingOnBehalfOf.profileType)) {
                        window?.UserWay?.iconVisibilityOn();
                    } else {
                        window?.UserWay?.iconVisibilityOff();
                    }
                });
            }
            if ([eProfileType.Family, eProfileType.Member].includes(actingOnBehalfOf.profileType)) {
                window?.UserWay?.iconVisibilityOn();
            } else {
                window?.UserWay?.iconVisibilityOff();
            }

            this.routerService.activeUrlArray$
                .pipe(filter(x => x != null),
                    take(1),
                    takeUntil(this.autoUnsubscribe))
                .subscribe(route => {
                    this.routerService.userAccountId$
                        .pipe(
                            filter(x => x != null),
                            take(1),
                            (takeUntil(this.autoUnsubscribe)))
                        .subscribe(userAccountId => {
                            const savedState: { actingOnBehalfOfProfile: IAnyInstitutionProfile, route: string[] }[] = this.localStorageService.getProfileHistory(userAccountId);
                            this.store.dispatch(ProfileActions.HistoryAddAction({
                                currentActingOnBehalfOfProfile: actingOnBehalfOf,
                                route: route,
                                savedState: savedState
                            }));
                            this.activeProfileHistory$.subscribe(history => {
                                this.localStorageService.setProfileHistory(userAccountId, history);
                            });
                        });
                });
        });
    }

    public getInstitutionProfileAuthToken(institutionProfileId: string): Observable<IInstitutionProfileAuthDto> {
        if (institutionProfileId == null) {
            return of(null);
        }
        if (this.authTokenStream?.[institutionProfileId] == null) {
            this.authTokenStream = this.createAuthTokenStream(institutionProfileId);
        }
        return this.authTokenStream[institutionProfileId];
    }

    private createAuthTokenStream(institutionProfileId: string): any {
        const stream$ = this.store.pipe(
            select(getInstitutionProfileAuthTokenCache(institutionProfileId)),
            tap(token => {
                if (token === undefined) {
                    this.store.dispatch(ProfileActions.GetProfileAuthAction({ institutionProfileId }));
                }
            }),
            shareReplay({ refCount: true, bufferSize: 1 })
        );
        return mergeImmutable({ [institutionProfileId]: stream$ }, this.authTokenStream);
    }

    public authenticateProfileEffect(institutionProfileId: string): Observable<IInstitutionProfileAuthDto> {
        return this.httpClient.get(`${environment.apiUri}/api/auths/institution-profiles/${institutionProfileId}`) as Observable<IInstitutionProfileAuthDto>;
    }

    public changeToProfile(profile: IAnyInstitutionProfile) {
        switch (profile.profileType) {
            case eProfileType.Superuser: {
                this.superUserSelected(profile);
                break;
            }
            case eProfileType.SystemManager: {
                this.systemManagerSelected(profile);
                break;
            }
            case eProfileType.Manager: {
                this.managerProfileSelected(profile);
                break;
            }
            case eProfileType.Family: {
                this.familyProfileSelected(profile);
                break;
            }
            case eProfileType.ReportConsumer: {
                this.reportConsumerProfileSelected(profile);
                break;
            }
        }
    }

    private reportConsumerProfileSelected(profile: IReportConsumerInstitutionProfile) {
        this.userService.userAccount$.pipe(take(1)).subscribe(userAccount => {
            this.routerService.Go([userAccount.userAccountId, profile.institutionId, profile.institutionProfileId, 'report-consumer', profile.reportConsumerId]);
        });
    }

    private superUserSelected(profile: ISuperUserInstitutionProfile) {
        this.userService.userAccount$.pipe(take(1)).subscribe(userAccount => {
            this.routerService.Go([userAccount.userAccountId, userAccount.superuserId, 'superuser', profile.institutionId, profile.institutionProfileId, 'institution']);
            // this.routerService.Go([userAccount.userAccountId, userAccount.superuserId, 'superuser']);
            this.windowService.openSidebarsIfDesktop();
        });
    }

    private systemManagerSelected(profile: ISystemManagerInstitutionProfile) {
        const programs = Object.keys(profile.acl);
        if (programs.length === 1) {
            this.changeToSystemManagerProfile(profile, programs[0]);
            return;
        }
        this.modalService.openModal(ModalSelectProgramComponent, {
            data: {
                institutionId: profile.institutionId,
                programIds: programs
            }
        }).pipe(take(1), filter(programId => programId != null)).subscribe(programId => {
            this.changeToSystemManagerProfile(profile, programId);
        });
    }

    private changeToSystemManagerProfile(profile: ISystemManagerInstitutionProfile, programId: string) {
        this.userService.userAccount$.pipe(take(1)).subscribe(userAccount => {
            this.routerService.Go([userAccount.userAccountId, profile.institutionId, profile.institutionProfileId, 'admin', programId, 'system-manager', profile.systemManagerId]);
            this.windowService.openSidebarsIfDesktop();
        });
    }

    private managerProfileSelected(profile: IManagerInstitutionProfile) {
        const programs = Object.keys(profile.managerPrograms);
        if (programs.length === 1) {
            this.changeToManagerProfile(profile, programs[0]);
            return;
        }
        this.modalService.openModal(ModalSelectProgramComponent, {
            data: {
                institutionId: profile.institutionId,
                programIds: programs
            }
        }).pipe(take(1), filter(programId => programId != null)).subscribe(programId => {
            this.changeToManagerProfile(profile, programId);
        });
    }

    private changeToManagerProfile(profile: IManagerInstitutionProfile, programId: string): void {
        const managerPrograms = [...profile.managerPrograms[programId]];
        let selectedHierarchyNodeId = this.localStorageService.getHierarchyNodeId({
            institutionId: profile.institutionId,
            managerId: profile.managerId
        });

        if (selectedHierarchyNodeId == null) {
            selectedHierarchyNodeId = this.selectManagerProgramWithHighestRank(managerPrograms);
        }

        this.localStorageService.setHierarchyNodeId({
            institutionId: profile.institutionId,
            managerId: profile.managerId,
            hierarchyNodeId: selectedHierarchyNodeId
        });

        this.userService.userAccount$.pipe(take(1)).subscribe(userAccount => {
            const userAccountRoute = [userAccount.userAccountId, profile.institutionId, profile.institutionProfileId, 'admin', programId, 'manager', profile.managerId, selectedHierarchyNodeId, 'members'];
            this.routerService.Go(userAccountRoute);
        });

        this.windowService.openSidebarsIfDesktop();
    }

    private selectManagerProgramWithHighestRank(managerPrograms: IManagerProgramHierarchyForDisplayDto[]): string {
        managerPrograms.sort((a, b) => rankHierarchyAssociation(a.hierarchyNodeType) - rankHierarchyAssociation(b.hierarchyNodeType));
        return managerPrograms[0].hierarchyNodeId;
    }

    private familyProfileSelected(profile: IFamilyInstitutionProfile | IFamilyDto) {
        this.userService.userAccount$.pipe(take(1)).subscribe(userAccount => {
            if (profile.addressVerified) {
                this.routerService.Go([userAccount.userAccountId, profile.institutionId, profile.institutionProfileId, 'family', profile.familyId]);
                this.windowService.openSidebarsIfDesktop();
            } else {
                this.routerService.Go(['user', userAccount.userAccountId, profile.institutionId, profile.institutionProfileId, 'family', profile.familyId, 'address-verification']);
            }
        });
    }

    public setClubLeaderPIN(params: { institutionProfileId: string, unitId: string, PIN: string }) {
        this.store.dispatch(ProfileActions.AddClubPINAction(params));
    }

    public getClubLeaderPIN(params: { institutionProfileId: string, unitId: string }) {
        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getUnitLeaderPIN(params))
        );
    }
}
