import { IEmailAddress, IUserAccountLoginDto } from '.';
import { eLoginStatus, eLoginType } from '..';

export interface IUserAccountLogin {
    LoginAuditId: string;
    LoginType: eLoginType;
    LoginStatus: eLoginStatus;
    Email: IEmailAddress;
    IpAddress?: string;
    DateStamp: string;
    ToDto: IUserAccountLoginDto;
}
