<div class="modal-header">
    <h5 class="modal-title">Edit Event Permissions</h5>
    <button (click)="close()" type="button" class="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" *ngIf="permissionRoleForm && permissionForm">
    <form [formGroup]="permissionRoleForm">
        <div class="form-group col-12">
          <div class="row">
            <label class="col col-4 form-control-label">Role</label>
            <div class="col col-8">
              <select formControlName="permissionRole" class="form-control">
                <option [value]="eAccessControlListRoleType.EventAdmin">Admin</option>
                <option [value]="eAccessControlListRoleType.EventStaff">Staff</option>
                <option [value]="eAccessControlListRoleType.EventVolunteer">Volunteer</option>
              </select>
            </div>
          </div>
        </div>
    </form>
    <form [formGroup]="permissionForm">
        <ng4h-permission-input formControlName="EventSetup" label="Event Setup"></ng4h-permission-input>
        <ng4h-permission-input formControlName="RegistrationManagement" label="Registration Management">
        </ng4h-permission-input>
        <ng4h-permission-input formControlName="EventManagers" label="Edit Event Managers"></ng4h-permission-input>
    </form>
</div>
<div class="modal-footer" *ngIf="event$ | async as event">
    <button [disabled]="event.eventManagers == null || (event.eventManagers | keyvalue).length < 2"
        (click)="removeManager()" class="btn btn-danger my-2 my-sm-0" role="button" aria-pressed="true">Remove</button>
    <button (click)="save()" class="btn btn-primary my-2 my-sm-0" role="button" aria-pressed="true">Save</button>
</div>