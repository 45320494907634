import { Action, createReducer, on } from '@ngrx/store';
import { IDeliveryModeDto, IInstitutionSystemManagerProgramModel } from 'app/core/models';
import { mergeImmutable } from 'app/shared/utils';

import { DeliveryModesActions } from '.';
import { DeliveryModeDeleteSuccessAction, DeliveryModeUpdateSuccessAction } from './enrollment-delivery-modes.actions';

export interface SystemManagerDeliveryModesState {
  deliveryModes: IInstitutionSystemManagerProgramModel<IDeliveryModeDto[]>;
  systemDeliveryModes: IInstitutionSystemManagerProgramModel<IDeliveryModeDto[]>;
  deliveryMode: IInstitutionSystemManagerProgramModel<{ [deliveryModeId: string]: IDeliveryModeDto }>;
}

const initialState: SystemManagerDeliveryModesState = {
  deliveryModes: undefined,
  systemDeliveryModes: undefined,
  deliveryMode: undefined
};

const reducer = createReducer(
  initialState,
  on(DeliveryModesActions.SystemDeliveryModeLoadSuccessAction, (state, { institutionId, systemManagerId, programId, systemDeliveryModes }) => {
    return {
      ...state,
      systemDeliveryModes: mergeImmutable(
        { [institutionId]: { [systemManagerId]: { [programId]: systemDeliveryModes } } },
        state.systemDeliveryModes
      )
    };
  }),
  on(DeliveryModesActions.DeliveryModeLoadSuccessAction, (state, { institutionId, systemManagerId, programId, deliveryModes }) => {
    return {
      ...state,
      deliveryModes: mergeImmutable(
        { [institutionId]: { [systemManagerId]: { [programId]: deliveryModes } } },
        state.deliveryModes
      )
    };
  }),
  on(DeliveryModesActions.DeliveryModeLoadByIdSuccessAction, (state, { institutionId, systemManagerId, programId, deliveryModeId, deliveryMode }) => {
    return {
      ...state,
      deliveryMode: mergeImmutable(
        { [institutionId]: { [systemManagerId]: { [programId]: { [deliveryModeId]: deliveryMode } } } },
        state.deliveryMode
      )
    };
  }),
  on(DeliveryModesActions.DeliveryModeAddSuccessAction, (state, payload) => {
    return {
      ...state,
      deliveryModes: undefined
    };
  }),
  on(DeliveryModeDeleteSuccessAction, (state, payload) => {
    return {
      ...state,
      deliveryModes: undefined
    };
  }),
  on(DeliveryModeUpdateSuccessAction, (state, payload) => {
    return {
      ...state,
      deliveryModes: undefined,
      deliveryMode: undefined
    };
  })
);

export function deliveryModesReducer(state: SystemManagerDeliveryModesState | undefined, actions: Action) {
  return reducer(state, actions);
}

export const deliveryModes = (state: SystemManagerDeliveryModesState) => state.deliveryModes;
export const systemDeliveryModes = (state: SystemManagerDeliveryModesState) => state.systemDeliveryModes;
export const deliveryMode = (state: SystemManagerDeliveryModesState) => state.deliveryMode;
