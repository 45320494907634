import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'highlight',
    pure: true
})

export class HighlightPipe implements PipeTransform {

    transform(value: any, args: any): any {
        if (args == null || args === '') {
            return value;
        }
        args = args?.replace(/[^\w\s]/g, '');
        return value.replace(new RegExp(args, 'gi'), '<mark>$&</mark>');
    }
}
