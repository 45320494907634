import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IInstitutionProgramDto, IInstitutionProgramParams, IProgramSummaryDto } from 'app/core/models';

export const ProgramsLoadAction = createAction(
    '[program] Load programs',
    props<{ institutionId: string }>()
);
export const ProgramsLoadSuccessAction = createAction(
    '[program] Load programs success',
    props<{ institutionId: string, programs: IProgramSummaryDto[] }>()
);
export const ProgramsLoadErrorAction = createAction(
    '[program] Load programs error',
    props<{ error: HttpErrorResponse }>()
);

export const ProgramsLoadCountyAreaAction = createAction(
    '[program] Load programs county area',
    props<{ institutionId: string, countyAreaId: string }>()
);
export const ProgramsLoadCountyAreaSuccessAction = createAction(
    '[program] Load programs county area success',
    props<{ institutionId: string, countyAreaId: string, programs: IProgramSummaryDto[] }>()
);
export const ProgramsLoadCountyAreaErrorAction = createAction(
    '[program] Load programs county area error',
    props<{ error: HttpErrorResponse }>()
);


export const ProgramLoadAction = createAction(
    '[program] Load',
    props<IInstitutionProgramParams>()
);
export const ProgramLoadSuccessAction = createAction(
    '[program] Load success',
    props<IInstitutionProgramParams & { institutionProgram: IInstitutionProgramDto }>()
);
export const ProgramLoadErrorAction = createAction(
    '[program] Load error',
    props<{ error: HttpErrorResponse }>()
);

export const ClearProgramCacheAction = createAction(
    '[program] Clear cache',
    props<any>()
);
