import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IInstitutionManagerProgramHierarchyParams, IInstitutionManagerProgramParams } from 'app/core/models';

export const GetMangerFundTransfersAction = createAction(
    '[manager-funds] Get manager funds',
    props<IInstitutionManagerProgramHierarchyParams>()
);
export const GetMangerFundTransfersSuccessAction = createAction(
    '[manager-funds] Get manager funds success',
    props<IInstitutionManagerProgramHierarchyParams & { funds: any }>()
);
export const GetMangerFundTransfersErrorAction = createAction(
    '[manager-funds] Get manager funds error',
    props<{ error: HttpErrorResponse }>()
);
export const GetMangerFundCreditsAction = createAction(
    '[manager-funds] Get manager credits',
    props<IInstitutionManagerProgramHierarchyParams>()
);
export const GetMangerFundCreditsSuccessAction = createAction(
    '[manager-funds] Get manager credits success',
    props<IInstitutionManagerProgramHierarchyParams & { credits: any }>()
);
export const GetMangerFundCreditsErrorAction = createAction(
    '[manager-funds] Get manager credits error',
    props<{ error: HttpErrorResponse }>()
);
export const GetMangerFundTransferAction = createAction(
    '[manager-funds] Get manager fund',
    props<IInstitutionManagerProgramParams & { fundsTransferId: string }>()
);
export const GetMangerFundTransferSuccessAction = createAction(
    '[manager-funds] Get manager fund success',
    props<IInstitutionManagerProgramParams & { fundsTransferId: string, fund: any }>()
);
export const GetMangerFundTransferErrorAction = createAction(
    '[manager-funds] Get manager fund error',
    props<{ error: HttpErrorResponse }>()
);
