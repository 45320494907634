import { IInstitutionVolunteerDto, IMemberDescriptorDto, IVolunteerACLDto } from '.';
import { eMemberProgramYearStatus, eUnitLeaderStatus } from '../enums';

export interface IUnitLeaderDto {
    unitLeaderId: string;
    member: IMemberDescriptorDto;
    unitId: string;
    unitName?: string;
    unitYearStatuses?: { [programYearId: string]: eMemberProgramYearStatus };
    permissions: IVolunteerACLDto;


    currentStatus: eUnitLeaderStatus;
    currentRoles?: string;
    volunteerRoles?: { [key: string]: IInstitutionVolunteerDto[] };
    participationStatus: eMemberProgramYearStatus;
}
