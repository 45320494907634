import { eLessonCompletionStatus, eLessonSuccess, eRequiredForCourseCompletionType } from '..';

export interface IProfileTrainingLessonDto {
    profileTrainingLessonId: string;
    lessonId: string;
    scormEngineCourseId: string;
    required: eRequiredForCourseCompletionType;
    trainingLessonTitle?: string;
    sequenceIndex: number;
    quizStatus?: eLessonSuccess;
    scoreScaled: number;
    totalSecondsTracked: number;
    completionStatus: eLessonCompletionStatus;
    launchLink?: string;
    minutesLeftInLockTimeout: number;
    lessonStatusText?: string;
    lockedDueToFailure: boolean;
}
