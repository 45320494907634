import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HealthFormActions } from 'app/core/containers/admin/system-manager/health/store';
import { ConsentsService } from 'app/core/services';
import { catchError, exhaustMap, groupBy, map, mergeMap, of, switchMap } from 'rxjs';

import { ConsentsActions } from '.';
import { stringKeys } from '../rxjs.utils';

@Injectable()
export class ConsentsEffects {

  createEnrollmentConsent = createEffect(() => this.actions
  .pipe(
    ofType(ConsentsActions.ConsentsCreateAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.consentsService.createConsentEffect(payload).pipe(
            map(() => ConsentsActions.ConsentsCreateSuccessAction(payload)),
            catchError(error => of(ConsentsActions.ConsentsCreateErrorAction({ error })))
          ))
      ))
  ));
  updateConsentType = createEffect(() => this.actions
  .pipe(
    ofType(ConsentsActions.UpdateConsentTypeAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.consentsService.updateConsentTypeEffect(payload).pipe(
            map(() => ConsentsActions.UpdateConsentTypeSuccessAction(payload)),
            catchError(error => of(ConsentsActions.UpdateConsentTypeErrorAction({ error })))
          ))
      ))
  ));

  deleteConsentType = createEffect(() => this.actions
  .pipe(
    ofType(ConsentsActions.DeleteConsentTypeAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.consentsService.deleteConsentTypeEffect(payload).pipe(
            map(() => ConsentsActions.DeleteConsentTypeSuccessAction(payload)),
            catchError(error => of(ConsentsActions.DeleteConsentTypeErrorAction({ error })))
          ))
      ))
  ));

  createConsentType = createEffect(() => this.actions
  .pipe(
    ofType(ConsentsActions.CreateConsentVersionAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.consentsService.createConsentVersionEffect(payload).pipe(
            map(() => ConsentsActions.CreateConsentVersionSuccessAction(payload)),
            catchError(error => of(ConsentsActions.CreateConsentVersionErrorAction({ error })))
          ))
      ))
  ));

  updateConsentVersion = createEffect(() => this.actions
  .pipe(
    ofType(ConsentsActions.UpdateConsentVersionAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.consentsService.updateConsentVersionEffect(payload).pipe(
            map(() => ConsentsActions.UpdateConsentVersionSuccessAction(payload)),
            catchError(error => of(ConsentsActions.UpdateConsentVersionErrorAction({ error })))
          ))
      ))
  ));

  // deleteEnrollmentConsent = createEffect(() => this.actions
  //     .pipe(
  //         ofType(ConsentsActions.ConsentsDeleteEnrollmentAction),
  //         groupBy(stringKeys),
  //         mergeMap(groupedBy => {
  //             return groupedBy.pipe(
  //                 exhaustMap(payload => {
  //                     return this.consentsService.deleteEnrollmentConsentEffect(payload).pipe(
  //                         map(() => ConsentsActions.ConsentsDeleteEnrollmentSuccessAction(payload)),
  //                         catchError(error => of(ConsentsActions.ConsentsDeleteEnrollmentErrorAction({ error })))
  //                     );
  //                 })
  //             );
  //         })
  //     ));

  consentsInvalidate = createEffect(() => this.actions
  .pipe(
    ofType(ConsentsActions.ConsentsCreateSuccessAction,
      ConsentsActions.UpdateConsentTypeSuccessAction,
      ConsentsActions.UpdateConsentVersionSuccessAction,
      ConsentsActions.DeleteConsentTypeSuccessAction,
      ConsentsActions.CreateConsentVersionSuccessAction,
      // ConsentsActions.ConsentsDeleteEnrollmentSuccessAction,
      ConsentsActions.ConsentsDeleteVersionSuccessAction),
    switchMap(() => [
      ConsentsActions.ConsentsInvalidateCacheAction(),
      HealthFormActions.SystemManagerInvalidateHealthFormConsentCacheAction()
    ])
  ));

  getConsents$ = createEffect(() => this.actions
  .pipe(
    ofType(ConsentsActions.ConsentsGetConsentAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.consentsService.loadConsentsEffect(payload).pipe(
            map((consents) => ConsentsActions.ConsentsGetConsentSuccessAction({ ...payload, consents })),
            catchError(error => of(ConsentsActions.ConsentsGetConsentErrorAction({ error })))
          ))
      ))
  ));

  addLocalization$ = createEffect(() => this.actions
  .pipe(
    ofType(ConsentsActions.ConsentsAddLocalizationAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.consentsService.addLocalizationEffect(payload).pipe(
            map(() => ConsentsActions.ConsentsAddLocalizationSuccessAction(payload)),
            catchError(error => of(ConsentsActions.ConsentsAddLocalizationErrorAction({ error })))
          ))
      ))
  ));

  deleteConsent$ = createEffect(() => this.actions.pipe(
    ofType(ConsentsActions.ConsentsDeleteVersionAction),
    groupBy(stringKeys),
    mergeMap(groupedBy =>
      groupedBy.pipe(
        exhaustMap(payload =>
          this.consentsService.deleteConsentVersionEffect(payload).pipe(
            map(() => ConsentsActions.ConsentsDeleteVersionSuccessAction(payload)),
            catchError(error => of(ConsentsActions.ConsentsDeleteVersionErrorAction({ error })))
          ))
      ))
  ));

  constructor(private actions: Actions, private consentsService: ConsentsService) {
  }
}
