import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  ModalChangeInstitutionComponent,
} from 'app/core/containers/superuser/modal-change-institution/modal-change-institution.component';
import {
  AutoUnsubscribe,
  eProfileType,
  IFamilyInstitutionProfile,
  IManagerInstitutionProfile,
  IReportConsumerInstitutionProfile,
  ISuperUserInstitutionProfile,
  ISystemManagerInstitutionProfile,
  IUserAccountDto,
} from 'app/core/models';
import { ModalService, RouterService, UserService } from 'app/core/services';
import { LocalStorageService } from 'app/core/services/local-storage.service';
import { SystemService } from 'app/core/services/system.service';
import { rankHierarchyAssociation } from 'app/shared/utils';
import { Observable, take } from 'rxjs';

@Component({
  selector: 'ng4h-modal-change-profile',
  templateUrl: './modal-change-profile.component.html',
  styleUrls: ['./modal-change-profile.component.scss']
})
export class ModalChangeProfileComponent extends AutoUnsubscribe implements OnInit {

  public eProfileType = eProfileType;
  public userAccount$: Observable<IUserAccountDto>;

  constructor(private dialogRef: MatDialogRef<any>,
    private routerService: RouterService,
    private localStorageService: LocalStorageService,
    private userService: UserService,
    private modalService: ModalService,
    private systemService: SystemService,
  ) {
    super();
    this.userAccount$ = this.userService.userAccount$;
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

  public asFamily(profile): IFamilyInstitutionProfile {
    return profile;
  }
  public asManager(profile): IManagerInstitutionProfile {
    return profile;
  }
  public asSystemManager(profile): ISystemManagerInstitutionProfile {
    return profile;
  }
  public asSuperuser(profile): ISuperUserInstitutionProfile {
    return profile;
  }
  public superUserSelected() {
    this.modalService.openModal(ModalChangeInstitutionComponent).pipe(
      take(1)
    ).subscribe();

    this.close();
  }
  public systemManagerSelected(profile: ISystemManagerInstitutionProfile) {
    this.systemService.getInstitutionStatus({ institutionId: profile.institutionId }).pipe(take(1), this.modalService.showProgressSpinnerModalUntilComplete()).subscribe((status) => {
      if (status.down === false || (status.down === true && status.allowSystemManagers === true)) {
        this.routerService.Go([this.routerService.userAccountId, profile.institutionId, profile.institutionProfileId, 'admin', Object.keys(profile.acl)[0], 'system-manager', profile.systemManagerId]);
        this.close();
      } else {
        this.modalService.confirm({ header: 'Institution Offline', body: 'You cannot login to this profile because the institution is offline. Message: ' + status.message }).subscribe(() => { })
      }
    });
  }
  public reportConsumerSelected(profile: IReportConsumerInstitutionProfile) {
    this.systemService.getInstitutionStatus({ institutionId: profile.institutionId }).pipe(take(1), this.modalService.showProgressSpinnerModalUntilComplete()).subscribe((status) => {
      if (status.down === false || (status.down === true && status.allowSystemManagers === true)) {
        this.routerService.Go([this.routerService.userAccountId, profile.institutionId, profile.institutionProfileId, 'report-consumer', profile.reportConsumerId])
        this.close();
      } else {
        this.modalService.confirm({ header: 'Institution Offline', body: 'You cannot login to this profile because the institution is offline. Message: ' + status.message }).subscribe(() => { })
      }
    });
  }
  public managerProfileSelected(profile: IManagerInstitutionProfile) {

    this.systemService.getInstitutionStatus({ institutionId: profile.institutionId }).pipe(take(1), this.modalService.showProgressSpinnerModalUntilComplete()).subscribe((status) => {
      if (status.down === false || (status.down === true && status.allowManagers === true)) {
        const programId = Object.keys(profile.managerPrograms)[0];

        const managerPrograms = [...profile.managerPrograms[programId]];
        let validHierarchyNodeId = this.localStorageService.getHierarchyNodeId({ institutionId: profile.institutionId, managerId: profile.managerId });
        if (validHierarchyNodeId == null) {
          // Find the node with the highest ranking
          managerPrograms.sort((a, b) => {
            return rankHierarchyAssociation(a.hierarchyNodeType) - rankHierarchyAssociation(b.hierarchyNodeType);
          });
          validHierarchyNodeId = managerPrograms[0].hierarchyNodeId;
        }
        this.localStorageService.setHierarchyNodeId({ institutionId: profile.institutionId, managerId: profile.managerId, hierarchyNodeId: validHierarchyNodeId });
        this.routerService.Go([this.routerService.userAccountId, profile.institutionId, profile.institutionProfileId, 'admin', programId, 'manager', profile.managerId, validHierarchyNodeId]);
        this.close();
      } else {
        this.modalService.confirm({ header: 'Institution Offline', body: 'You cannot login to this profile because the institution is offline. Message: ' + status.message }).subscribe(() => { })
      }
    });
  }
  public familyProfileSelected(profile: IFamilyInstitutionProfile) {
    this.systemService.getInstitutionStatus({ institutionId: profile.institutionId }).pipe(take(1), this.modalService.showProgressSpinnerModalUntilComplete()).subscribe((status) => {
      if (status.down === false) {
        this.routerService.Go([this.routerService.userAccountId, profile.institutionId, profile.institutionProfileId, 'family', profile.familyId]);
        this.close();
      } else {
        this.modalService.confirm({ header: 'Institution Offline', body: 'You cannot login to this profile because the institution is offline. Message: ' + status.message }).subscribe(() => { })
      }
    });
  }
}
