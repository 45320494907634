<ng4h-dynamic-options-root [formDisplayTypes]="formDisplayTypes" [control]="control"
  (rootInputModified)="controlModified.emit($event)" (validatorAddOrModify)="validatorAddOrModify.emit($event)"
  (validatorRemove)="validatorRemove.emit($event)">
  <!-- <div class="form-group row">
    <label class="form-control-label col-md-3">Report Label</label>
    <div class="input-group col-md-9">
      <input class="form-control" type="text" [ngModel]="control.items[0].reportValue"
        (ngModelChange)="reportLabelChange($event, control.items[0].itemId)">
    </div>
  </div> -->
  <div class="form-group row">
    <label class="form-control-label col-md-3">Placeholder</label>
    <div class="col-md-9">
      <input [ngModel]="control.items[0].attributes.placeholder" type="text" class="form-control"
        (ngModelChange)="placeholderChange($event, control.items[0].itemId)">
    </div>
  </div>

</ng4h-dynamic-options-root>