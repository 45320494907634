import { ICouponFundingStateDto, IUnitDto } from '.';
import {
  eInstitutionDiscountStatus,
  eInstitutionDiscountType,
  eInvoiceDiscountFeePercentage,
  eInvoiceDiscountType,
} from '../enums';
import { IDiscountFundDescriptorDto } from './IDiscountFundDescriptorDto';

export interface IInstitutionDiscountDto {
  institutionDiscountId: string;
  code?: string;
  fundId: string;
  programYearId: string;
  institutionDiscountType: eInstitutionDiscountType;
  feePercentage?: eInvoiceDiscountFeePercentage;
  invoiceDiscountType: eInvoiceDiscountType;
  fund: IDiscountFundDescriptorDto;
  fundingState: ICouponFundingStateDto;
  enrollmentRoles?: any[];
  description?: string;
  status: eInstitutionDiscountStatus;
  clubs?: IUnitDto[];
}
