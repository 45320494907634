import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { IInstitutionDiscountDto } from 'app/core/models/serverDTOs/IInstitutionDiscountDto';

@Component({
  selector: 'ng4h-modal-update-coupon',
  templateUrl: './modal-update-coupon.component.html',
  styleUrls: ['./modal-update-coupon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalUpdateCouponComponent implements OnInit {

  public coupon: IInstitutionDiscountDto;

  constructor(private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder) { }

  ngOnInit() {
  }
}
