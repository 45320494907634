<div class="modal-header">
    <h5 class="modal-title">Edit Other Questions</h5>
    <button (click)="close()" type="button" class="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <ng4h-dynamic-form-display [dynamicFormInputs]="dynamicForm$ | async" [dynamicForm]="dynamicFormFormGroup">
    </ng4h-dynamic-form-display>
</div>
<div class="modal-footer">
    <button [disabled]="dynamicFormFormGroup.invalid" class="btn btn-primary" (click)="save()">Save</button>
    <button class="btn btn-secondary" (click)="close()">Cancel</button>
</div>
