import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IInstitutionManagerProgramHierarchyParams, ITrainingDescriptorDto } from 'app/core/models';

export const LoadTrainingsAction = createAction(
    '[manager trainings] Load trainings',
    props<IInstitutionManagerProgramHierarchyParams>()
);
export const LoadTrainingsSuccessAction = createAction(
    '[manager trainings] Load trainings success',
    props<IInstitutionManagerProgramHierarchyParams & { trainings: ITrainingDescriptorDto[] }>()
);
export const LoadTrainingsErrorAction = createAction(
    '[manager trainings] Load trainings error',
    props<{ error: HttpErrorResponse }>()
);