import { ePermissionState } from '.';

export interface IManagerPermissions {
    MemberData_PersonalData: ePermissionState;
    Health_HealthData: ePermissionState;
    Enrollment_Approval: ePermissionState;
    Enrollment_Units: ePermissionState;
    Enrollment_Projects: ePermissionState;
    Enrollment_Management: ePermissionState;
    Event_Creation: ePermissionState;
    Connect_BroadcastEmail: ePermissionState;
    Chartering_Approval: ePermissionState;
    VolunteerScreening_Approval: ePermissionState;
    Financial_FeeSchedule: ePermissionState;
    Financial_Invoicing: ePermissionState;
    AnimalIdentification_Approval: ePermissionState;
    Manager_Management: ePermissionState;
    FamilyData_FamilyData: ePermissionState;
    Enrollment_UnitsCreate: ePermissionState;
    Enrollment_ProjectsCreate: ePermissionState;
    Event_Registration: ePermissionState;
    Financial_EnrollmentFees: ePermissionState;
    Financial_Discounts: ePermissionState;
    Financial_Waive_Fees: ePermissionState;

    // this isn't part of the acl, but I can use it in the manger-permissions.directive
    primaryHierarchyAdmin: boolean;
}
